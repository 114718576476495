export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  Date: Date;
  /** An ISO 8601-encoded datetime */
  DateTime: Date;
  URL: any;
  Upload: any;
  _Any: any;
};

export enum ActiveStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export type AgreementPage = {
  __typename?: 'AgreementPage';
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  position: Scalars['Int'];
};

export type Alert = {
  __typename?: 'Alert';
  message: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type Amenity = {
  __typename?: 'Amenity';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Announcement = {
  __typename?: 'Announcement';
  createdAt: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  link: Maybe<Scalars['URL']>;
  location: Location;
  title: Scalars['String'];
};

export type ApprovalStatus = {
  __typename?: 'ApprovalStatus';
  autoApproved: Scalars['Boolean'];
  reviewNote: Maybe<Scalars['String']>;
  reviewedAt: Maybe<Scalars['DateTime']>;
  reviewerId: Maybe<Scalars['ID']>;
  reviewerName: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

/** Wrapper for returning available floors for location. Errors only populated if desks are requested but not available. */
export type AvailableDesksOnFloor = {
  __typename?: 'AvailableDesksOnFloor';
  desks: Array<Desk>;
  errors: Maybe<Array<EnvoyError>>;
};

/** Wrapper for returning available floors for location. Errors only populated if desks are requested but not available. */
export type AvailableFloors = {
  __typename?: 'AvailableFloors';
  errors: Maybe<Array<EnvoyError>>;
  floors: Array<Floor>;
};

export type BeaconIdInput = {
  major: Scalars['Int'];
  minor: Scalars['Int'];
};

export type BeaconInput = {
  id: BeaconIdInput;
  proximity: BeaconProximity;
  rssi: Scalars['Int'];
};

export enum BeaconProximity {
  Far = 'far',
  Immediate = 'immediate',
  Near = 'near',
  Unknown = 'unknown',
}

export type Booking = {
  __typename?: 'Booking';
  endTime: Scalars['DateTime'];
  room: Room;
  startTime: Scalars['DateTime'];
};

export type BulkEmployeesByEmail = {
  __typename?: 'BulkEmployeesByEmail';
  employees: Array<Employee>;
};

export type BulkRegistrationPdResponse = {
  __typename?: 'BulkRegistrationPDResponse';
  errors: Maybe<Array<EnvoyError>>;
  registrationDates: Array<RegistrationDatePartialDay>;
};

export type BulkRegistrationResponse = {
  __typename?: 'BulkRegistrationResponse';
  errors: Maybe<Array<EnvoyError>>;
  registrationDates: Array<RegistrationDate>;
};

/** Represents either an Invite, Entry or Delivery */
export type CardUnion = Delivery | Entry | Invite;

export type Carrier = {
  __typename?: 'Carrier';
  id: Scalars['ID'];
  /** Full carrier name, like 'US Postal Service' */
  name: Scalars['String'];
  /** Short, unique identifer, like 'usps' */
  slug: Maybe<Scalars['String']>;
};

export type Color = {
  __typename?: 'Color';
  hexValue: Scalars['String'];
};

export type ColorPalette = {
  __typename?: 'ColorPalette';
  accent: Color;
  accentText: Color;
};

export type Delivery = {
  __typename?: 'Delivery';
  alias: Maybe<Scalars['String']>;
  canEdit: Scalars['Boolean'];
  carrier: Maybe<Carrier>;
  deliveryArea: DeliveryArea;
  id: Scalars['ID'];
  imageFull: Maybe<ImageUriSource>;
  imageThumb: Maybe<ImageUriSource>;
  lastNotificationSentAt: Maybe<Scalars['DateTime']>;
  links: DeliveryLinks;
  meta: Maybe<DeliveryMeta>;
  notes: Maybe<Scalars['String']>;
  notificationSentAt: Maybe<Scalars['DateTime']>;
  pickedUpAt: Maybe<Scalars['DateTime']>;
  receivedAt: Scalars['DateTime'];
  recipient: Maybe<Recipient>;
  skipRecipientNotification: Maybe<Scalars['Boolean']>;
  status: Scalars['String'];
  trackingNumber: Maybe<Scalars['String']>;
};

export type DeliveryArea = {
  __typename?: 'DeliveryArea';
  canAdd: Scalars['Boolean'];
  deliveries: Array<Delivery>;
  failedDeliveriesCount: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  pendingDeliveriesCount: Scalars['Int'];
  pendingOwnDeliveriesCount: Scalars['Int'];
  /** @deprecated Legacy concept from Identity */
  realm: Maybe<Realm>;
  requireSignature: Scalars['Boolean'];
  status: ActiveStatus;
  totalDeliveriesCount: Scalars['Int'];
};

export type DeliveryAreaDeliveriesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  page: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Scalars['String']>;
  status: Array<DeliveryStatus>;
};

export type DeliveryLinks = {
  __typename?: 'DeliveryLinks';
  updateImage: Scalars['URL'];
  uploadImage: Scalars['URL'];
};

export type DeliveryMeta = {
  __typename?: 'DeliveryMeta';
  permissions: DeliveryPermissions;
};

export type DeliveryPermissions = {
  __typename?: 'DeliveryPermissions';
  createNotes: Scalars['Boolean'];
  deleteRecipientEmployeeId: Scalars['Boolean'];
};

/**
 * Delivery statuses have the following lifecycle
 *
 * ```
 * new --> processing +--------> pending --> collected
 *                    |             ^
 *                    |             |
 *                    +--> failed --+
 * ```
 *
 * When the deliveries mobile app takes a photo of a label, the client
 * creates a "shell" Delivery with the initial status `new` as a
 * container to upload the label image to. Once the image has been
 * uploaded, it changes status to `processing` while it waits for OCR to
 * complete. Once it receives OCR results, the backend attempts to find
 * a match. If successful, it goes to `pending` state and notifications are
 * sent out. If no match, it transitions to `failed`. Finally, when an
 * employee picks up their package, the delivery transitions to its final
 * resting state, `collected`.
 */
export enum DeliveryStatus {
  /** Picked up deliveries */
  Collected = 'collected',
  /** Failed to find a match */
  Failed = 'failed',
  /** Initial status */
  New = 'new',
  /** Deliveries ready to be collected */
  Pending = 'pending',
  /** Waiting for match results */
  Processing = 'processing',
}

export type Desk = {
  __typename?: 'Desk';
  accessible: Scalars['Boolean'];
  amenities: Maybe<Array<Amenity>>;
  assignedEmployee: Maybe<Employee>;
  assignedTo: Maybe<Scalars['String']>;
  /** @deprecated Use accessible and reservations fields instead */
  deskAvailability: Maybe<DeskAvailabilityEnum>;
  /** @deprecated Use the MapFeature and MapFloor types instead */
  floor: Floor;
  floorId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  neighborhood: Maybe<Scalars['String']>;
  reservations: Array<Reservation>;
  /** @deprecated Use accessible and reservations fields instead */
  status: Maybe<DeskStatusEnum>;
  /** @deprecated Desk geometry has been moved to the MapFeature type */
  x: Maybe<Scalars['Float']>;
  /** @deprecated Desk geometry has been moved to the MapFeature type */
  y: Maybe<Scalars['Float']>;
};

export type DeskReservationsArgs = {
  endTime: InputMaybe<Scalars['DateTime']>;
  startTime: InputMaybe<Scalars['DateTime']>;
};

/** Desk availability values */
export enum DeskAvailabilityEnum {
  /** Desk available for reservation */
  Available = 'available',
  /** Desk not available for reservation */
  Unavailable = 'unavailable',
  /** Desk currently assigned to this user */
  UserAssigned = 'userAssigned',
}

/** Desk Status */
export enum DeskStatusEnum {
  /**
   * a desk that has been reserved by an employee who has hidden their schedule, if
   * a location has disabled schedule sharing then all desks that are booked should
   * be returned as anonymous
   */
  Anonymous = 'anonymous',
  /** a desk that does not have a reservation for the given time */
  Available = 'available',
  /** a desk that has been reserved by an employee with a shared schedule */
  Booked = 'booked',
  /** A desk that has been permanently assigned to someone with schedule sharing on */
  PermanentlyAssigned = 'permanentlyAssigned',
  /** A desk that has been permanently assigned to someone with schedule sharing off */
  PermanentlyAssignedPrivate = 'permanentlyAssignedPrivate',
  /** a desk that cannot be used by the user querying because of neighborhood restrictions */
  Unavailable = 'unavailable',
}

export type DesksConfig = {
  __typename?: 'DesksConfig';
  companyId: Scalars['ID'];
  desksLocations: Maybe<Array<DesksLocation>>;
};

export type DesksLocation = {
  __typename?: 'DesksLocation';
  active: Scalars['Boolean'];
  autoAssignDesk: Scalars['Boolean'];
  checkInDuration: Maybe<Scalars['Int']>;
  checkInRequiredBy: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  workplaceDays: Maybe<Array<WorkplaceDay>>;
};

export type DesksOnFloor = {
  __typename?: 'DesksOnFloor';
  desks: Array<Desk>;
  errors: Maybe<Array<EnvoyError>>;
};

/** Direct upload attributes */
export type DirectUpload = {
  __typename?: 'DirectUpload';
  /** Created blob record ID */
  blobId: Scalars['ID'];
  byteSize: Maybe<Scalars['Int']>;
  checksum: Maybe<Scalars['String']>;
  contentType: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['String']>;
  filename: Maybe<Scalars['String']>;
  /** HTTP request headers (JSON-encoded) */
  headers: Array<Scalars['String']>;
  key: Maybe<Scalars['String']>;
  /** Created blob record signed ID */
  signedBlobId: Scalars['ID'];
  /** Upload URL */
  url: Scalars['String'];
};

export type DocumentAttachmentSignedBlob = {
  file: Scalars['String'];
  userDocumentTemplateAttachmentId: Scalars['ID'];
};

export type Door = {
  __typename?: 'Door';
  /** This is used to store beacon-related data properties. Used by mobile clients to match a nearby beacon */
  doorBeacon: DoorBeacon;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type DoorBeacon = {
  __typename?: 'DoorBeacon';
  major: Maybe<Scalars['Int']>;
  minor: Maybe<Scalars['Int']>;
  uuids: Maybe<Array<Scalars['String']>>;
};

export type Employee = {
  __typename?: 'Employee';
  email: Maybe<Scalars['String']>;
  favorite: Scalars['Boolean'];
  hideFromEmployeeSchedule: Scalars['Boolean'];
  id: Scalars['Int'];
  locations: Array<Location>;
  name: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
  profilePictureUrl: Maybe<Scalars['String']>;
  signInStatus: Scalars['Boolean'];
  workplaceGroups: Array<WorkplaceGroup>;
};

/** Wrapper for returning employee desks. Errors only populated if reservation request. */
export type EmployeeDesks = {
  __typename?: 'EmployeeDesks';
  errors: Maybe<Array<EnvoyError>>;
  reservations: Array<Reservation>;
};

export type EmployeeFavorite = {
  __typename?: 'EmployeeFavorite';
  favorite: Employee;
  id: Scalars['ID'];
  owner: Employee;
};

/** Invite for employee registration only, no visitor invites. */
export type EmployeeInvite = {
  __typename?: 'EmployeeInvite';
  delegatedBooker: Maybe<Employee>;
  id: Scalars['ID'];
};

/** DEPRECATED: Use EmployeeRegistrationPartialDayType instead */
export type EmployeeRegistration = {
  __typename?: 'EmployeeRegistration';
  /**
   * (DEPRECATED) Future dates within the location's open registration window
   * @deprecated Use EmployeeRegistrationPartialDayType instead
   */
  registrationDates: Array<RegistrationDate>;
};

/** Wrapper for multi-day registration and invites data */
export type EmployeeRegistrationPartialDay = {
  __typename?: 'EmployeeRegistrationPartialDay';
  /** Future dates within the location's open registration window */
  registrationDates: Array<RegistrationDatePartialDay>;
};

export type Entry = {
  __typename?: 'Entry';
  additionalGuests: Scalars['Int'];
  agreementRefused: Maybe<Scalars['Boolean']>;
  desk: Maybe<Desk>;
  employee: Maybe<Employee>;
  flow: Maybe<Flow>;
  flowName: Scalars['String'];
  /** @deprecated Moved into Employee type, use `Employee.name` instead. */
  host: Maybe<Scalars['String']>;
  /** @deprecated Moved into Employee type, use `Employee.email` instead. */
  hostEmail: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDelivery: Scalars['Boolean'];
  location: Maybe<Location>;
  nda: Maybe<Scalars['String']>;
  passportStamp: Maybe<PassportStamp>;
  photoUrl: Maybe<Scalars['String']>;
  privateNotes: Maybe<Scalars['String']>;
  signedInAt: Scalars['DateTime'];
  signedInVia: Scalars['String'];
  signedOutAt: Maybe<Scalars['DateTime']>;
  userData: Array<FormField>;
  visitor: Visitor;
};

/** Available entry scopes are 'mine' or 'boss' */
export enum EntryScopeEnum {
  /** This scope is for fetching my entries and my bosses entries given I am an assistant */
  Boss = 'boss',
  /** This value is for fetching only my entries if I am an assistant */
  Mine = 'mine',
}

export enum EntryStatusEnum {
  SignedIn = 'signedIn',
  SignedOut = 'signedOut',
}

/** Wrapper for account configs for various envoy products */
export type EnvoyAccountConfig = {
  __typename?: 'EnvoyAccountConfig';
  desksConfig: DesksConfig;
};

export type EnvoyError = {
  __typename?: 'EnvoyError';
  code: Scalars['Int'];
  domain: Scalars['String'];
  meta: Maybe<Array<ErrorMetaData>>;
};

export type ErrorMetaData = {
  __typename?: 'ErrorMetaData';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Feed = {
  __typename?: 'Feed';
  group: Scalars['String'];
  id: Scalars['ID'];
  token: Scalars['String'];
};

export type Floor = {
  __typename?: 'Floor';
  building: Maybe<Scalars['String']>;
  desks: Array<Desk>;
  floorNumber: Scalars['Int'];
  floorPlanUrl: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FloorReservationCount = {
  __typename?: 'FloorReservationCount';
  id: Scalars['ID'];
  reservationCount: Scalars['Int'];
};

export type FloorReservationCounts = {
  __typename?: 'FloorReservationCounts';
  errors: Maybe<Array<EnvoyError>>;
  floorReservationCounts: Array<FloorReservationCount>;
};

export type Flow = {
  __typename?: 'Flow';
  additionalGuests: Scalars['Boolean'];
  agreementPage: Maybe<AgreementPage>;
  employeeCentric: Maybe<Scalars['Boolean']>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  localizedName: Maybe<Scalars['String']>;
  name: Scalars['String'];
  position: Scalars['Int'];
  signInFieldPage: SignInFieldPage;
};

export type FormField = {
  __typename?: 'FormField';
  field: Scalars['String'];
  value: Maybe<Scalars['String']>;
};

/** Invite form field */
export type FormFieldInput = {
  /** Field */
  field: Scalars['String'];
  /** Value */
  value: Scalars['String'];
};

export type GenericResponse = {
  __typename?: 'GenericResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Wrapper for returning reservations matching the given filters */
export type GetReservations = {
  __typename?: 'GetReservations';
  errors: Maybe<Array<EnvoyError>>;
  reservations: Array<Reservation>;
};

export type ImageUriSource = {
  __typename?: 'ImageURISource';
  uri: Scalars['URL'];
};

/** User document input field */
export type InputField = {
  __typename?: 'InputField';
  /** For date types, this will be in the YYYY-MM-DD format */
  defaultValue: Maybe<Scalars['String']>;
  identifier: Scalars['String'];
  required: Scalars['Boolean'];
  title: Scalars['String'];
  type: InputFieldTypeEnum;
};

/** User document input field data */
export type InputFieldData = {
  __typename?: 'InputFieldData';
  /** Field identifier */
  identifier: Scalars['String'];
  /** Field value. See InputFieldTypeEnum for formatting. */
  value: Scalars['String'];
};

/** User document input field data */
export type InputFieldDataInput = {
  /** Field identifier */
  identifier: Scalars['String'];
  /** Field value. See InputFieldTypeEnum for formatting. */
  value: Scalars['String'];
};

/** User document input field types */
export enum InputFieldTypeEnum {
  /** Date field values format: YYYY-MM-DD */
  Date = 'date',
}

export type IntegrationSetting = {
  __typename?: 'IntegrationSetting';
  id: Scalars['ID'];
  integrationDescription: Scalars['String'];
  integrationId: Scalars['String'];
  integrationName: Scalars['String'];
  integrationStatus: Scalars['String'];
  purpose: Scalars['String'];
};

export type Invite = {
  __typename?: 'Invite';
  additionalGuests: Scalars['Int'];
  approvalStatus: Maybe<ApprovalStatus>;
  arrived: Scalars['Boolean'];
  /** @deprecated Moved into Visitor type, use `Visitor.email` instead. */
  email: Maybe<Scalars['String']>;
  employee: Maybe<Employee>;
  employeeInvite: Maybe<EmployeeInvite>;
  entry: Maybe<Entry>;
  entryId: Maybe<Scalars['ID']>;
  entrySignedOutAt: Maybe<Scalars['DateTime']>;
  expectedArrivalTime: Scalars['DateTime'];
  flow: Maybe<Flow>;
  /** @deprecated Moved into Visitor type, use `Visitor.fullName` instead. */
  fullName: Scalars['String'];
  guestUpdatedAt: Maybe<Scalars['DateTime']>;
  hostName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPresigned: Scalars['Boolean'];
  location: Maybe<Location>;
  phone: Maybe<Scalars['String']>;
  preregistrationLink: Maybe<Scalars['String']>;
  /**
   * A pre-reg link for the invite's approval status.
   * Currently this page only displays employee registration denial messaging. In
   * the future it may include more general status info. Unlike the
   * `preregistrationLink` this page can be accessed after preregistration is complete.
   */
  preregistrationStatusLink: Maybe<Scalars['String']>;
  privateNotes: Maybe<Scalars['String']>;
  touchlessSignInAvailableTime: Maybe<Scalars['DateTime']>;
  touchlessSignInPresenceRequired: Scalars['Boolean'];
  userData: Array<FormField>;
  userDocumentList: Array<InviteUserDocumentData>;
  visitor: Visitor;
};

export type InviteReservationResponse = {
  __typename?: 'InviteReservationResponse';
  errors: Maybe<Array<EnvoyError>>;
  invite: Maybe<Invite>;
  reservation: Maybe<Reservation>;
};

export type InviteUserDocumentData = {
  __typename?: 'InviteUserDocumentData';
  documentApprovalStatusForLocation: Maybe<Scalars['String']>;
  title: Scalars['String'];
  userDocumentId: Maybe<Scalars['ID']>;
  userDocumentTemplateId: Scalars['ID'];
};

export type Location = {
  __typename?: 'Location';
  address: Maybe<Scalars['String']>;
  addressLineOne: Maybe<Scalars['String']>;
  addressLineTwo: Maybe<Scalars['String']>;
  capacityLimit: Maybe<Scalars['Int']>;
  companyName: Maybe<Scalars['String']>;
  deliveryAreas: Maybe<Array<DeliveryArea>>;
  employeeScheduleEnabled: Scalars['Boolean'];
  employeeScreeningEnabled: Maybe<Scalars['Boolean']>;
  feeds: Array<Feed>;
  flows: Array<Flow>;
  id: Scalars['ID'];
  latitude: Maybe<Scalars['Float']>;
  logoUrl: Maybe<Scalars['String']>;
  longitude: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  nearVisitScreeningEnabled: Scalars['Boolean'];
  preRegistrationEnabled: Scalars['Boolean'];
  role: Maybe<UserRole>;
  scheduleEligibilityOffset: Maybe<Scalars['Int']>;
  subscription: ProductSubscription;
  theme: LocationTheme;
  ticketConfiguration: TicketConfiguration;
  timezone: Scalars['String'];
};

export type LocationTheme = {
  __typename?: 'LocationTheme';
  logo: Maybe<Scalars['URL']>;
  palette: ColorPalette;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accepts the current privacy policy for the signed in user */
  acceptPrivacyPolicy: GenericResponse;
  acceptWorkplaceVisitRequest: Invite;
  /** Adds an alias to a recipient so that future transcriptions that match the alias will find the right recipient */
  addAlias: Delivery;
  /** Add a new delivery to a delivery area */
  addDelivery: Delivery;
  addInvite: Invite;
  addMembersToWorkplaceGroup: WorkplaceGroup;
  addTicket: Ticket;
  addUser: NewUser;
  /** Book a room now til a time that's suggested by backend */
  bookNow: Booking;
  bulkCreateInviteReservation: BulkRegistrationResponse;
  bulkCreateInviteReservationPartialDay: BulkRegistrationPdResponse;
  bulkUpdateUserNotificationPreference: User;
  changeDeskReservation: Reservation;
  checkInReservation: Reservation;
  createDeskReservation: ReservationResponse;
  /** Direct upload mutation to allow clients to directly upload files */
  createDirectUpload: DirectUpload;
  createInviteReservation: InviteReservationResponse;
  /** Creates user's notification preference */
  createNotificationPreference: NotificationPreference;
  createUserDocument: UserDocument;
  createWorkplaceGroup: WorkplaceGroup;
  createWorkplaceVisitRequest: WorkplaceVisitRequestCreateResponse;
  deactivateUserAccount: GenericResponse;
  declineWorkplaceVisitRequest: GenericResponse;
  /** Deletes multiple deliveries */
  deleteDeliveries: Array<Delivery>;
  /** Deletes a delivery */
  deleteDelivery: Delivery;
  /** Deletes an invite */
  deleteInvite: Invite;
  deleteUserPhoto: User;
  deleteWorkplaceGroup: GenericResponse;
  /** Edit a delivery */
  editDelivery: Delivery;
  editInvite: Invite;
  /** Updates user's profile */
  editProfile: User;
  editWorkplaceGroup: WorkplaceGroup;
  /** Mark one announcement as seen */
  markAnnouncementAsSeen: GenericResponse;
  /** Mark multiple deliveries as picked up */
  markDeliveriesAsPickedUp: Array<Delivery>;
  /** Mark a single delivery as picked up */
  markDeliveryAsPickedUp: Delivery;
  markEmployeeAsFavorite: EmployeeFavorite;
  notifyBuilding: GenericResponse;
  registerAndCheckIn: SimplifiedSignInUnion;
  registerAndCheckInToInvite: SimplifiedSignInUnion;
  registerPushNotificationToken: GenericResponse;
  releaseDeskReservation: GenericResponse;
  releaseDeskReservations: GenericResponse;
  removeMembersFromWorkplaceGroup: WorkplaceGroup;
  /** Forces a notification to be resent to the recipient */
  resendDeliveryNotification: Delivery;
  /** Triggers reset password email */
  resetPassword: PasswordReset;
  signInInvite: Array<Entry>;
  signOutEntry: Entry;
  unlockDoor: GenericResponse;
  unmarkEmployeeAsFavorite: GenericResponse;
  updateAvatar: User;
  /** @deprecated Use ChangeDeskReservation instead */
  updateDeskReservation: GenericResponse;
  updateEmployeeSchedulePreference: User;
  updateHostPushPreference: User;
  updateInvitesPushPreference: User;
  /** Updates user's notification preference */
  updateNotificationPreference: NotificationPreference;
  updatePassportOwnLocations: User;
  updateUserDocument: UserDocument;
  /** @deprecated use Mutations::BulkUpdateUserNotificationPreference instead */
  updateUserNotificationPreference: User;
  updateUserPhoto: User;
  uploadTicketAttachments: GenericResponse;
};

export type MutationAcceptWorkplaceVisitRequestArgs = {
  workplaceVisitRequestId: Scalars['ID'];
};

export type MutationAddAliasArgs = {
  delivery: Scalars['ID'];
  nickname: Scalars['String'];
  recipient: Scalars['String'];
};

export type MutationAddDeliveryArgs = {
  carrier: InputMaybe<Scalars['ID']>;
  deliveryArea: Scalars['ID'];
  notes: InputMaybe<Scalars['String']>;
  recipient: Scalars['ID'];
  skipRecipientNotification: InputMaybe<Scalars['Boolean']>;
  trackingNumber: InputMaybe<Scalars['String']>;
};

export type MutationAddInviteArgs = {
  additionalGuests: InputMaybe<Scalars['Int']>;
  email: InputMaybe<Scalars['String']>;
  employee: InputMaybe<Scalars['ID']>;
  expectedArrivalTime: Scalars['DateTime'];
  fullName: Scalars['String'];
  location: Scalars['ID'];
  notifyVisitor: InputMaybe<Scalars['Boolean']>;
  phone: InputMaybe<Scalars['String']>;
  privateNotes: InputMaybe<Scalars['String']>;
  userData: Array<FormFieldInput>;
};

export type MutationAddMembersToWorkplaceGroupArgs = {
  employeeIds: Array<Scalars['ID']>;
  groupId: Scalars['ID'];
};

export type MutationAddTicketArgs = {
  attachments: InputMaybe<Array<Scalars['String']>>;
  categoryId: Scalars['ID'];
  description: Scalars['String'];
  locationDescription: InputMaybe<TicketLocationDescriptionInput>;
  locationId: Scalars['ID'];
  priority: TicketPriorityEnum;
  requestType: TicketRequestTypeEnum;
  subcategory: InputMaybe<Scalars['String']>;
};

export type MutationAddUserArgs = {
  acceptedPrivacyPolicy: InputMaybe<Scalars['Boolean']>;
  companyName: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  password: InputMaybe<Scalars['String']>;
  phoneNumber: InputMaybe<Scalars['String']>;
};

export type MutationBookNowArgs = {
  roomId: Scalars['ID'];
  sendInviteTo: Array<Scalars['String']>;
};

export type MutationBulkCreateInviteReservationArgs = {
  invites: Array<NewInviteInput>;
};

export type MutationBulkCreateInviteReservationPartialDayArgs = {
  invites: Array<NewInviteInput>;
};

export type MutationBulkUpdateUserNotificationPreferenceArgs = {
  features: Array<Scalars['String']>;
  settings: Array<Scalars['String']>;
  values: Array<Scalars['Boolean']>;
};

export type MutationChangeDeskReservationArgs = {
  currentDeskId: InputMaybe<Scalars['ID']>;
  endTime: InputMaybe<Scalars['DateTime']>;
  inviteId: InputMaybe<Scalars['ID']>;
  newDeskId: InputMaybe<Scalars['ID']>;
  reservationId: Scalars['ID'];
  startTime: InputMaybe<Scalars['DateTime']>;
};

export type MutationCheckInReservationArgs = {
  reservationId: Scalars['ID'];
  signInMethod: InputMaybe<Scalars['String']>;
};

export type MutationCreateDeskReservationArgs = {
  deskId: InputMaybe<Scalars['ID']>;
  invite: InputMaybe<NewInviteInput>;
  inviteId: InputMaybe<Scalars['ID']>;
};

export type MutationCreateDirectUploadArgs = {
  byteSize: Scalars['Int'];
  checksum: Scalars['String'];
  contentType: Scalars['String'];
  fileName: Scalars['String'];
  prefix: InputMaybe<Scalars['String']>;
};

export type MutationCreateInviteReservationArgs = {
  deskId: InputMaybe<Scalars['ID']>;
  invite: InputMaybe<NewInviteInput>;
  inviteId: InputMaybe<Scalars['ID']>;
};

export type MutationCreateNotificationPreferenceArgs = {
  notificationPlatformKey: Scalars['String'];
  notificationType: Scalars['String'];
  preferenceValue: Scalars['Boolean'];
  relationId: Scalars['ID'];
  relationType: Scalars['String'];
};

export type MutationCreateUserDocumentArgs = {
  inputFieldsData: InputMaybe<Array<InputFieldDataInput>>;
  signedBlobs: Array<DocumentAttachmentSignedBlob>;
  userDocumentTemplateId: Scalars['ID'];
};

export type MutationCreateWorkplaceGroupArgs = {
  members: Array<Scalars['ID']>;
  name: Scalars['String'];
};

export type MutationCreateWorkplaceVisitRequestArgs = {
  date: Scalars['Date'];
  employeeIds: Array<Scalars['ID']>;
  locationId: Scalars['ID'];
};

export type MutationDeclineWorkplaceVisitRequestArgs = {
  workplaceVisitRequestId: Scalars['ID'];
};

export type MutationDeleteDeliveriesArgs = {
  id: Array<Scalars['ID']>;
};

export type MutationDeleteDeliveryArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteInviteArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteWorkplaceGroupArgs = {
  groupId: Scalars['ID'];
};

export type MutationEditDeliveryArgs = {
  carrier: InputMaybe<Scalars['ID']>;
  deliveryArea: Scalars['ID'];
  id: Scalars['ID'];
  notes: InputMaybe<Scalars['String']>;
  recipient: Scalars['ID'];
  skipRecipientNotification: InputMaybe<Scalars['Boolean']>;
  trackingNumber: InputMaybe<Scalars['String']>;
};

export type MutationEditInviteArgs = {
  additionalGuests: InputMaybe<Scalars['Int']>;
  email: InputMaybe<Scalars['String']>;
  expectedArrivalTime: InputMaybe<Scalars['DateTime']>;
  fullName: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  privateNotes: InputMaybe<Scalars['String']>;
  userData: InputMaybe<Array<FormFieldInput>>;
};

export type MutationEditProfileArgs = {
  formattedName: InputMaybe<Scalars['String']>;
  phoneNumber: InputMaybe<Scalars['String']>;
};

export type MutationEditWorkplaceGroupArgs = {
  id: Scalars['ID'];
  name: InputMaybe<Scalars['String']>;
};

export type MutationMarkAnnouncementAsSeenArgs = {
  announcementId: Scalars['ID'];
};

export type MutationMarkDeliveriesAsPickedUpArgs = {
  id: Array<Scalars['ID']>;
  pickedUpAt: InputMaybe<Scalars['DateTime']>;
};

export type MutationMarkDeliveryAsPickedUpArgs = {
  id: Scalars['ID'];
  pickedUpAt: InputMaybe<Scalars['DateTime']>;
};

export type MutationMarkEmployeeAsFavoriteArgs = {
  targetEmployeeId: Scalars['ID'];
};

export type MutationNotifyBuildingArgs = {
  locationId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationRegisterAndCheckInArgs = {
  invite: NewInviteInput;
};

export type MutationRegisterAndCheckInToInviteArgs = {
  inviteId: Scalars['ID'];
};

export type MutationRegisterPushNotificationTokenArgs = {
  appVersion: InputMaybe<Scalars['String']>;
  deviceName: Scalars['String'];
  id: Scalars['ID'];
  tokenType: InputMaybe<Scalars['String']>;
};

export type MutationReleaseDeskReservationArgs = {
  reservationId: Scalars['ID'];
};

export type MutationReleaseDeskReservationsArgs = {
  reservationIds: Array<Scalars['ID']>;
};

export type MutationRemoveMembersFromWorkplaceGroupArgs = {
  employeeIds: Array<Scalars['ID']>;
  groupId: Scalars['ID'];
};

export type MutationResendDeliveryNotificationArgs = {
  id: Scalars['ID'];
};

export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};

export type MutationSignInInviteArgs = {
  inviteId: Scalars['ID'];
  notifyHost: InputMaybe<Scalars['Boolean']>;
  printBadges: InputMaybe<Scalars['Boolean']>;
  reservationId: InputMaybe<Scalars['ID']>;
  signInMethod: InputMaybe<Scalars['String']>;
};

export type MutationSignOutEntryArgs = {
  id: Scalars['ID'];
  signedOutAt: Scalars['DateTime'];
  signedOutBy: InputMaybe<Scalars['String']>;
};

export type MutationUnlockDoorArgs = {
  doorId: InputMaybe<Scalars['ID']>;
  localName: InputMaybe<Scalars['String']>;
  locationId: Scalars['ID'];
  major: InputMaybe<Scalars['Int']>;
  minor: InputMaybe<Scalars['Int']>;
  type: Scalars['String'];
  uuid: InputMaybe<Scalars['String']>;
};

export type MutationUnmarkEmployeeAsFavoriteArgs = {
  targetEmployeeId: Scalars['ID'];
};

export type MutationUpdateAvatarArgs = {
  avatar: Scalars['Upload'];
};

export type MutationUpdateDeskReservationArgs = {
  deskId: Scalars['ID'];
  entryId: Scalars['ID'];
};

export type MutationUpdateEmployeeSchedulePreferenceArgs = {
  value: Scalars['Boolean'];
};

export type MutationUpdateHostPushPreferenceArgs = {
  value: Scalars['Boolean'];
};

export type MutationUpdateInvitesPushPreferenceArgs = {
  value: Scalars['Boolean'];
};

export type MutationUpdateNotificationPreferenceArgs = {
  id: Scalars['ID'];
  value: Scalars['Boolean'];
};

export type MutationUpdatePassportOwnLocationsArgs = {
  value: Scalars['Boolean'];
};

export type MutationUpdateUserDocumentArgs = {
  inputFieldsData: InputMaybe<Array<InputFieldDataInput>>;
  signedBlobs: Array<DocumentAttachmentSignedBlob>;
  userDocumentId: Scalars['ID'];
};

export type MutationUpdateUserNotificationPreferenceArgs = {
  feature: Scalars['String'];
  setting: Scalars['String'];
  value: Scalars['Boolean'];
};

export type MutationUpdateUserPhotoArgs = {
  signedBlobId: Scalars['ID'];
};

export type MutationUploadTicketAttachmentsArgs = {
  attachments: Array<Scalars['Upload']>;
  ticketId: Scalars['ID'];
};

export type NewInviteInput = {
  additionalGuests: InputMaybe<Scalars['Int']>;
  email: InputMaybe<Scalars['String']>;
  /** Employee ID */
  employee: InputMaybe<Scalars['ID']>;
  expectedArrivalTime: Scalars['DateTime'];
  fullName: Scalars['String'];
  /** Existing Invite ID */
  inviteId: InputMaybe<Scalars['ID']>;
  /** Location ID */
  location: Scalars['ID'];
  notifyVisitor: InputMaybe<Scalars['Boolean']>;
  phone: InputMaybe<Scalars['String']>;
  privateNotes: InputMaybe<Scalars['String']>;
  reservationEndTime: InputMaybe<Scalars['DateTime']>;
  reservationStartTime: InputMaybe<Scalars['DateTime']>;
  userData: Array<FormFieldInput>;
};

export type NewUser = {
  __typename?: 'NewUser';
  acceptedPrivacyPolicy: Maybe<Scalars['Boolean']>;
  companyName: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  phoneNumber: Maybe<Scalars['String']>;
};

export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  icon: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  notificationPlatformKey: Scalars['String'];
  notificationType: Scalars['String'];
  preferenceValue: Scalars['Boolean'];
  relationId: Scalars['ID'];
  relationType: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID'];
  locationBilling: Scalars['Boolean'];
  locations: Array<Location>;
  name: Scalars['String'];
  role: Maybe<UserRole>;
  subscription: ProductSubscription;
};

export type Pagination = {
  __typename?: 'Pagination';
  currentPage: Scalars['Int'];
  nextPage: Maybe<Scalars['Int']>;
  size: Scalars['Int'];
  total: Scalars['Int'];
};

export type PaginationDetails = {
  __typename?: 'PaginationDetails';
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type PassportStamp = {
  __typename?: 'PassportStamp';
  large: Maybe<Scalars['String']>;
  small: Maybe<Scalars['String']>;
};

export type PasswordReset = {
  __typename?: 'PasswordReset';
  message: Scalars['String'];
};

export enum PrivacyPolicyAcceptanceEnum {
  Accepted = 'accepted',
  Expired = 'expired',
  NotAccepted = 'not_accepted',
}

export type ProductSubscription = {
  __typename?: 'ProductSubscription';
  deliveries: Maybe<SubscriptionDetails>;
  desks: Maybe<SubscriptionDetails>;
  rooms: Maybe<SubscriptionDetails>;
  vr: Maybe<SubscriptionDetails>;
};

/** The query root of this schema */
export type Query = {
  __typename?: 'Query';
  _entities: Array<Maybe<_Entity>>;
  _service: _Service;
  /** All amenities at a locations */
  amenitiesForLocation: Array<Amenity>;
  /** Announcements by ID */
  announcement: Announcement;
  /** Announcements for a location */
  announcements: Array<Announcement>;
  /**
   * Available desks for a location, user is pulled from jwt
   * @deprecated Migrate to loading desks by floor with `availableDesksOnFloorForLocation` for improved error reporting
   */
  availableDesksForLocation: Array<Desk>;
  /** Available desks for a location, user is pulled from jwt */
  availableDesksOnFloorForLocation: AvailableDesksOnFloor;
  /** List of floors for the location, optionally eager loading desks for a floor */
  availableFloorsForLocation: AvailableFloors;
  /** Available rooms */
  availableRooms: Array<Room>;
  /** List of employees at a given company */
  bulkEmployeesByEmail: BulkEmployeesByEmail;
  /** Office cards */
  cards: Array<CardUnion>;
  /** All carriers */
  carriers: Array<Carrier>;
  /** Deliveries viewable by user */
  deliveries: Array<Delivery>;
  /** Delivery by ID */
  delivery: Delivery;
  /** Delivery Area by ID */
  deliveryArea: DeliveryArea;
  /** Delivery Areas by status */
  deliveryAreas: Array<DeliveryArea>;
  /** Desks for a location on the floor */
  desksOnFloorForLocation: DesksOnFloor;
  /** Doors integration setting to determine availability */
  doorsIntegrationSetting: Maybe<IntegrationSetting>;
  /** Employee by ID */
  employee: Employee;
  /**
   * Returns one desk reservation for a fellow employee on a given date
   * @deprecated Migrate to `employee_desks` since an employee can have multiple reservations for a day
   */
  employeeDesk: Reservation;
  /** Returns desk reservations for a fellow employee for a given start and end time */
  employeeDesks: EmployeeDesks;
  /** List of favorited employees */
  employeeFavorites: Array<EmployeeFavorite>;
  /** Registration information for multi-day registration */
  employeeRegistration: EmployeeRegistration;
  /** Registration information for multi-day registration and partial day reservations */
  employeeRegistrationPartialDay: EmployeeRegistrationPartialDay;
  /** Employee screening cards for self-certification */
  employeeScreeningCards: Array<ScreeningCardUnion>;
  /** All employees viewable by user */
  employees: Array<Employee>;
  /** All entries */
  entries: Array<Entry>;
  /** Entry by ID */
  entry: Entry;
  /** Configurations for various envoy products */
  envoyAccountConfig: EnvoyAccountConfig;
  /** List of floors with reservation count */
  floorReservationCounts: FloorReservationCounts;
  /** List of floors with desks */
  floorsAndDesksForLocation: Array<Floor>;
  /** Get reservations that match the provided filters */
  getReservations: GetReservations;
  /** Invite by ID */
  invite: Invite;
  /** All invites */
  invites: Array<Invite>;
  /** List of doors by location_id */
  listDoors: Array<Door>;
  /** All locations user in session has access to */
  locations: Array<Location>;
  /** User scoped by the current JWT */
  me: User;
  /** Notification preferences */
  notificationPreferences: Array<NotificationPreference>;
  /** Returns single Reservation, with Desk included */
  reservationById: Reservation;
  /** List of dates an given employee is scheduled at including the location */
  scheduledDatesForEmployee: Array<ScheduledResources>;
  /** List of protect scheduled employees on a given date */
  scheduledEmployees: ScheduledEmployeeResults;
  /** List of configured ticket categories by location ID */
  ticketCategories: Array<TicketCategory>;
  /** List of the active user documents */
  userDocumentTemplates: Maybe<Array<UserDocumentTemplate>>;
  /** Visitors collection and pagination data */
  visitors: Visitors;
  /** WorkplaceGroup by ID */
  workplaceGroup: WorkplaceGroup;
  /** List of workplace groups an employee belongs to */
  workplaceGroups: Array<WorkplaceGroup>;
};

/** The query root of this schema */
export type Query_EntitiesArgs = {
  representations: Array<Scalars['_Any']>;
};

/** The query root of this schema */
export type QueryAmenitiesForLocationArgs = {
  locationId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryAnnouncementArgs = {
  id: Scalars['ID'];
};

/** The query root of this schema */
export type QueryAnnouncementsArgs = {
  locationId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryAvailableDesksForLocationArgs = {
  date: Scalars['DateTime'];
  locationId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryAvailableDesksOnFloorForLocationArgs = {
  date: Scalars['DateTime'];
  floorId: Scalars['ID'];
  locationId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryAvailableFloorsForLocationArgs = {
  date: InputMaybe<Scalars['DateTime']>;
  floorId: InputMaybe<Scalars['ID']>;
  locationId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryAvailableRoomsArgs = {
  location: InputMaybe<Scalars['ID']>;
  nearbyBeacons: InputMaybe<Array<BeaconInput>>;
};

/** The query root of this schema */
export type QueryBulkEmployeesByEmailArgs = {
  employeeEmails: Array<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
  locationId: InputMaybe<Scalars['ID']>;
  page: InputMaybe<Scalars['Int']>;
};

/** The query root of this schema */
export type QueryCardsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  location: InputMaybe<Scalars['ID']>;
  scope: InputMaybe<ScopeFilterEnum>;
};

/** The query root of this schema */
export type QueryDeliveriesArgs = {
  deliveryArea: InputMaybe<Scalars['ID']>;
  deliveryAreas: InputMaybe<Array<Scalars['ID']>>;
  id: InputMaybe<Array<Scalars['ID']>>;
  limit: InputMaybe<Scalars['Int']>;
  page: InputMaybe<Scalars['Int']>;
  recipient: InputMaybe<Scalars['String']>;
  sort: InputMaybe<Scalars['String']>;
  status: InputMaybe<Array<DeliveryStatus>>;
};

/** The query root of this schema */
export type QueryDeliveryArgs = {
  id: Scalars['ID'];
};

/** The query root of this schema */
export type QueryDeliveryAreaArgs = {
  id: Scalars['ID'];
};

/** The query root of this schema */
export type QueryDeliveryAreasArgs = {
  limit: InputMaybe<Scalars['Int']>;
  page: InputMaybe<Scalars['Int']>;
  realms: InputMaybe<Array<Scalars['ID']>>;
  status: InputMaybe<Array<ActiveStatus>>;
};

/** The query root of this schema */
export type QueryDesksOnFloorForLocationArgs = {
  date: InputMaybe<Scalars['DateTime']>;
  endTime: InputMaybe<Scalars['DateTime']>;
  filters: Array<DeskStatusEnum>;
  floorId: Scalars['ID'];
  locationId: Scalars['ID'];
  startTime: InputMaybe<Scalars['DateTime']>;
};

/** The query root of this schema */
export type QueryDoorsIntegrationSettingArgs = {
  locationId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryEmployeeArgs = {
  employeeId: Scalars['ID'];
  locationId: InputMaybe<Scalars['ID']>;
};

/** The query root of this schema */
export type QueryEmployeeDeskArgs = {
  date: Scalars['DateTime'];
  employeeId: Scalars['ID'];
  locationId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryEmployeeDesksArgs = {
  employeeId: Scalars['ID'];
  endTime: Scalars['DateTime'];
  locationId: Scalars['ID'];
  startTime: Scalars['DateTime'];
};

/** The query root of this schema */
export type QueryEmployeeFavoritesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  page: InputMaybe<Scalars['Int']>;
};

/** The query root of this schema */
export type QueryEmployeeRegistrationArgs = {
  endDate: Scalars['DateTime'];
  locationId: Scalars['ID'];
  startDate: Scalars['DateTime'];
};

/** The query root of this schema */
export type QueryEmployeeRegistrationPartialDayArgs = {
  endDate: Scalars['DateTime'];
  locationId: Scalars['ID'];
  startDate: Scalars['DateTime'];
};

/** The query root of this schema */
export type QueryEmployeeScreeningCardsArgs = {
  locationId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryEmployeesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locations: InputMaybe<Array<Scalars['ID']>>;
  page: InputMaybe<Scalars['Int']>;
  query: InputMaybe<Scalars['String']>;
  sort: InputMaybe<Scalars['String']>;
  workplaceGroups: InputMaybe<Array<Scalars['ID']>>;
};

/** The query root of this schema */
export type QueryEntriesArgs = {
  employee: InputMaybe<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
  location: InputMaybe<Scalars['Int']>;
  page: InputMaybe<Scalars['Int']>;
  scope: InputMaybe<Scalars['String']>;
  status: InputMaybe<EntryStatusEnum>;
};

/** The query root of this schema */
export type QueryEntryArgs = {
  id: Scalars['ID'];
};

/** The query root of this schema */
export type QueryFloorReservationCountsArgs = {
  date: InputMaybe<Scalars['DateTime']>;
  locationId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryFloorsAndDesksForLocationArgs = {
  locationId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryGetReservationsArgs = {
  endTime: InputMaybe<Scalars['DateTime']>;
  floorId: InputMaybe<Scalars['ID']>;
  limit: InputMaybe<Scalars['Int']>;
  locationId: Scalars['ID'];
  page: InputMaybe<Scalars['Int']>;
  startTime: InputMaybe<Scalars['DateTime']>;
};

/** The query root of this schema */
export type QueryInviteArgs = {
  id: Scalars['ID'];
};

/** The query root of this schema */
export type QueryInvitesArgs = {
  employee: InputMaybe<Scalars['ID']>;
  limit: InputMaybe<Scalars['Int']>;
  location: Scalars['Int'];
  page: InputMaybe<Scalars['Int']>;
  scope: InputMaybe<Scalars['String']>;
  sort: InputMaybe<Scalars['String']>;
};

/** The query root of this schema */
export type QueryListDoorsArgs = {
  locationId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryLocationsArgs = {
  disabled: InputMaybe<Scalars['Boolean']>;
  disabledLocation: InputMaybe<Scalars['Boolean']>;
  id: InputMaybe<Array<Scalars['ID']>>;
};

/** The query root of this schema */
export type QueryReservationByIdArgs = {
  reservationId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryScheduledDatesForEmployeeArgs = {
  employeeId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryScheduledEmployeesArgs = {
  date: Scalars['Date'];
  locationId: Scalars['ID'];
  page: InputMaybe<Scalars['Int']>;
  query: InputMaybe<Scalars['String']>;
  scheduled: InputMaybe<Scalars['Boolean']>;
  size: InputMaybe<Scalars['Int']>;
  workplaceGroups: InputMaybe<Array<Scalars['ID']>>;
};

/** The query root of this schema */
export type QueryTicketCategoriesArgs = {
  locationId: Scalars['ID'];
};

/** The query root of this schema */
export type QueryUserDocumentTemplatesArgs = {
  locationId: Scalars['ID'];
  userDocumentTemplateId: InputMaybe<Scalars['ID']>;
};

/** The query root of this schema */
export type QueryVisitorsArgs = {
  endTime: InputMaybe<Scalars['DateTime']>;
  entriesScope: InputMaybe<EntryScopeEnum>;
  locationId: Scalars['ID'];
  page: InputMaybe<Scalars['Int']>;
  selection: InputMaybe<VisitorSelectionEnum>;
  startTime: InputMaybe<Scalars['DateTime']>;
};

/** The query root of this schema */
export type QueryWorkplaceGroupArgs = {
  id: Scalars['ID'];
  locationId: InputMaybe<Scalars['ID']>;
};

/** The query root of this schema */
export type QueryWorkplaceGroupsArgs = {
  employeeId: Scalars['ID'];
  locationId: InputMaybe<Scalars['ID']>;
};

export type Realm = {
  __typename?: 'Realm';
  id: Scalars['ID'];
  kind: Scalars['String'];
  name: Scalars['String'];
  parent: Maybe<Realm>;
  roles: Maybe<Array<Role>>;
};

/** A recipient is really just an Employee but with simplified fields */
export type Recipient = {
  __typename?: 'Recipient';
  /** First + last name */
  formattedName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Day to register to come into the office */
export type RegistrationDate = {
  __typename?: 'RegistrationDate';
  available: Scalars['Boolean'];
  date: Scalars['DateTime'];
  hasRequiredDocumentApproval: Scalars['Boolean'];
  id: Scalars['ID'];
  maxOccupancy: Maybe<Scalars['Int']>;
  peopleRegistered: Scalars['Int'];
  requiredDocumentApprovalExpiresAt: Maybe<Scalars['DateTime']>;
  requiredDocumentMessage: Maybe<Scalars['String']>;
  reservation: Maybe<Reservation>;
  screeningCard: ScreeningCardUnion;
};

/** Valid invite and reservations associated with an office visit on a given day */
export type RegistrationDatePartialDay = {
  __typename?: 'RegistrationDatePartialDay';
  available: Scalars['Boolean'];
  date: Scalars['DateTime'];
  hasRequiredDocumentApproval: Scalars['Boolean'];
  id: Scalars['ID'];
  maxOccupancy: Maybe<Scalars['Int']>;
  peopleRegistered: Scalars['Int'];
  requiredDocumentApprovalExpiresAt: Maybe<Scalars['DateTime']>;
  requiredDocumentMessage: Maybe<Scalars['String']>;
  reservations: Array<Reservation>;
  screeningCard: ScreeningCardUnion;
};

export type Reservation = {
  __typename?: 'Reservation';
  /** @deprecated booking_source is no longer used. */
  bookingSource: Maybe<Scalars['String']>;
  canceledAt: Maybe<Scalars['DateTime']>;
  /** @deprecated canceled_at field now used rather than cancelled. */
  cancelled: Maybe<Scalars['Boolean']>;
  checkInTime: Maybe<Scalars['DateTime']>;
  checkOutTime: Maybe<Scalars['DateTime']>;
  desk: Desk;
  employee: Maybe<Employee>;
  endTime: Maybe<Scalars['Int']>;
  entryId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  inviteId: Maybe<Scalars['ID']>;
  isPartialDay: Maybe<Scalars['Boolean']>;
  locationId: Scalars['ID'];
  startTime: Maybe<Scalars['Int']>;
  userEmail: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type ReservationResponse = {
  __typename?: 'ReservationResponse';
  invite: Maybe<Invite>;
  reservation: Reservation;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID'];
  name: Scalars['String'];
  realm: Maybe<Realm>;
};

/** Room id is usually in the email address format. id is globally unique */
export type Room = {
  __typename?: 'Room';
  /** Things like TV, Zoom, Whiteboard */
  amenities: Array<Scalars['String']>;
  backgroundImageUrl: Maybe<Scalars['URL']>;
  capacity: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** Room display name */
  name: Scalars['String'];
  /** Distance to the available rooms */
  proximity: Maybe<BeaconProximity>;
  /** Client will localize the duration to minutes/hours */
  timeAvailableInMinutes: Maybe<Scalars['Int']>;
  /** The length of the booking is determined by rooms backend based on rooms availibility and user preference */
  timeToBookInMinutes: Maybe<Scalars['Int']>;
};

export type ScheduledEmployee = {
  __typename?: 'ScheduledEmployee';
  date: Scalars['Date'];
  desk: Maybe<Desk>;
  email: Scalars['String'];
  employeeId: Scalars['ID'];
  favorite: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  profilePictureUrl: Maybe<Scalars['String']>;
  scheduled: Scalars['Boolean'];
  signInStatus: Scalars['Boolean'];
};

export type ScheduledEmployeeResults = {
  __typename?: 'ScheduledEmployeeResults';
  hiddenScheduledEmployeesCount: Scalars['Int'];
  pagination: Pagination;
  scheduledEmployees: Array<ScheduledEmployee>;
};

export type ScheduledResources = {
  __typename?: 'ScheduledResources';
  date: Scalars['Date'];
  deskReservations: Array<Reservation>;
  location: Location;
};

/** Available scopes are 'mine' or 'boss' */
export enum ScopeFilterEnum {
  /** Fetch only resources where I am the host or boss is the host */
  Boss = 'boss',
  /** Fetch only resources where I am the creator or host */
  Mine = 'mine',
}

/** Represents either an Invite or SelfCertify */
export type ScreeningCardUnion = Invite | SelfCertify | UserDocumentCard | WorkplaceVisitRequest;

export type SelfCertify = {
  __typename?: 'SelfCertify';
  capacityReached: Scalars['Boolean'];
  email: Scalars['String'];
  expectedArrivalTime: Scalars['DateTime'];
  fullName: Scalars['String'];
  locationId: Scalars['ID'];
  userData: Array<FormField>;
};

export type SignInField = {
  __typename?: 'SignInField';
  /** Whether or not this field is displayed to visitors */
  allowVisitorRespondents: Scalars['Boolean'];
  dbField: Scalars['String'];
  identifier: Maybe<Scalars['String']>;
  kind: SignInFieldKindEnum;
  localized: Maybe<Scalars['String']>;
  name: Scalars['String'];
  options: Array<Scalars['String']>;
  position: Scalars['Int'];
  required: Scalars['Boolean'];
};

export enum SignInFieldKindEnum {
  Email = 'email',
  Phone = 'phone',
  SingleSelection = 'singleSelection',
  Text = 'text',
}

export type SignInFieldPage = {
  __typename?: 'SignInFieldPage';
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  position: Scalars['Int'];
  signInFields: Array<SignInField>;
};

export type SimplifiedSignIn = {
  __typename?: 'SimplifiedSignIn';
  simplifiedSignIn: Array<RegistrationDatePartialDay>;
};

/** Represents either a SimplifiedSignIn or an Invite */
export type SimplifiedSignInUnion = Invite | SimplifiedSignIn;

export type SubscriptionDetails = {
  __typename?: 'SubscriptionDetails';
  limitAlert: Maybe<Alert>;
  limitExceeded: Maybe<Scalars['Boolean']>;
  onTrial: Scalars['Boolean'];
  plan: Scalars['String'];
  subscribed: Scalars['Boolean'];
  trialEndDate: Maybe<Scalars['DateTime']>;
};

export type Ticket = {
  __typename?: 'Ticket';
  attachments: Maybe<Array<TicketAttachment>>;
  description: Scalars['String'];
  fileAttachmentUrls: Maybe<Array<ImageUriSource>>;
  id: Scalars['ID'];
  location: Location;
  locationDescription: Maybe<TicketLocationDescription>;
  priority: TicketPriorityEnum;
  requestType: TicketRequestTypeEnum;
  requestor: User;
  subcategory: Maybe<Scalars['String']>;
  ticketCategory: TicketCategory;
};

export type TicketAttachment = {
  __typename?: 'TicketAttachment';
  /** Attachment's file */
  file: ImageUriSource;
  id: Scalars['ID'];
};

export type TicketCategory = {
  __typename?: 'TicketCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TicketConfiguration = {
  __typename?: 'TicketConfiguration';
  configurationCompleted: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  ticketingIntegrationInstalled: Scalars['Boolean'];
  ticketingIntegrationName: Maybe<Scalars['String']>;
};

/** Location description source metadata */
export type TicketLocationDescription = {
  __typename?: 'TicketLocationDescription';
  /** Source Display */
  sourceDisplay: Scalars['String'];
  /** Source ID */
  sourceId: Maybe<Scalars['ID']>;
  sourceType: TicketLocationDescriptionSourceTypeEnum;
};

/** Location description input data */
export type TicketLocationDescriptionInput = {
  /** Source ID */
  sourceDisplay: Scalars['String'];
  /** Source ID */
  sourceId: InputMaybe<Scalars['ID']>;
  /** Source type */
  sourceType: TicketLocationDescriptionSourceTypeEnum;
};

/** Ticket location description source_type enum */
export enum TicketLocationDescriptionSourceTypeEnum {
  /** Represents the Deliveries product */
  Deliveries = 'deliveries',
  /** Represents the Desks product */
  Desks = 'desks',
  /** Represents the Rooms product */
  Rooms = 'rooms',
  /** Represents free form text */
  Text = 'text',
}

/** Ticket priorities */
export enum TicketPriorityEnum {
  /** High tickets */
  High = 'high',
  /** Low tickets */
  Low = 'low',
  /** Normal tickets */
  Normal = 'normal',
  /** Urgent tickets */
  Urgent = 'urgent',
}

/** Ticket request type options */
export enum TicketRequestTypeEnum {
  /** Incident */
  Incident = 'incident',
  /** Problem */
  Problem = 'problem',
  /** Question */
  Question = 'question',
  /** Request */
  Request = 'request',
}

/** An Envoy User */
export type User = {
  __typename?: 'User';
  acceptedPrivacyPolicy: Scalars['Boolean'];
  /** Status of the auto sign-in push notification setting */
  autoSignInPushEnabled: Scalars['Boolean'];
  /**
   * Company name on the User Profile. Used by Passport to automatically fill in company name fields.
   * Not to be mistaken for the name of the company relationship the user belongs to.
   */
  companyName: Maybe<Scalars['String']>;
  /** User's deliveries */
  deliveries: Array<Delivery>;
  /** Deliveries scanned by the user */
  deliveriesScanned: Array<Delivery>;
  email: Maybe<Scalars['String']>;
  /** User's employee record */
  employee: Maybe<Employee>;
  /** Entries for the current user */
  entries: Array<Entry>;
  /** First + last name */
  formattedName: Maybe<Scalars['String']>;
  hideFromEmployeeSchedule: Scalars['Boolean'];
  id: Scalars['ID'];
  /** User's avatar */
  image: Maybe<ImageUriSource>;
  invites: Array<Invite>;
  /** Status of the invites push notification setting */
  invitesPushEnabled: Scalars['Boolean'];
  /**
   * The company the user belongs to. `null` if the user is a Passport-only user.
   *
   * Note, in the following query,
   *
   * ```
   * {
   *   me {
   *     organization {
   *       id
   *     }
   *   }
   * }
   * ```
   *
   * the organization will always reflect the company_id in the user's JWT. For Organizations deeper
   * in the graph, the organization will be indeterministic due to the implementation of `User#company`
   * on `envoy-web`.
   */
  organization: Maybe<Organization>;
  /** Status of the passport own locations setting */
  passportOwnLocations: Scalars['Boolean'];
  /** Phone number */
  phoneNumber: Maybe<Scalars['String']>;
  privacyPolicyAcceptance: Maybe<PrivacyPolicyAcceptanceEnum>;
  /** Status of the host push notification setting */
  pushNotificationEnabled: Scalars['Boolean'];
  /** @deprecated A legacy concept from Identity */
  realms: Array<Realm>;
  /** @deprecated A legacy concept from Identity */
  roles: Array<Role>;
};

/** An Envoy User */
export type UserDeliveriesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  page: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Scalars['String']>;
  status: Array<DeliveryStatus>;
};

/** An Envoy User */
export type UserDeliveriesScannedArgs = {
  limit: InputMaybe<Scalars['Int']>;
  page: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Scalars['String']>;
  status: InputMaybe<Array<DeliveryStatus>>;
};

/** An Envoy User */
export type UserEntriesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  page: InputMaybe<Scalars['Int']>;
};

/** An Envoy User */
export type UserInvitesArgs = {
  dateFrom: InputMaybe<Scalars['DateTime']>;
  dateTo: InputMaybe<Scalars['DateTime']>;
  limit: InputMaybe<Scalars['Int']>;
  location: InputMaybe<Scalars['ID']>;
  page: InputMaybe<Scalars['Int']>;
};

export type UserDocument = {
  __typename?: 'UserDocument';
  active: Scalars['Boolean'];
  approvalStatusForLocation: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inputFieldsData: Array<InputFieldData>;
  userDocumentAttachments: Array<UserDocumentAttachment>;
  userDocumentLinks: Array<UserDocumentLink>;
};

export type UserDocumentAttachment = {
  __typename?: 'UserDocumentAttachment';
  fileUrl: Scalars['String'];
  id: Scalars['ID'];
  userDocumentTemplateAttachment: Maybe<UserDocumentTemplateAttachment>;
};

export type UserDocumentCard = {
  __typename?: 'UserDocumentCard';
  /** Document status string: approved, denied, pending. Empty when not created */
  approvalStatus: Maybe<Scalars['String']>;
  expirationDate: Maybe<Scalars['Date']>;
  title: Scalars['String'];
  userDocumentId: Maybe<Scalars['ID']>;
  userDocumentTemplateId: Scalars['ID'];
};

export type UserDocumentLink = {
  __typename?: 'UserDocumentLink';
  approvalStatus: Maybe<Scalars['String']>;
  userDocumentLinkable: UserDocumentLinkable;
};

export type UserDocumentLinkable = {
  __typename?: 'UserDocumentLinkable';
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type UserDocumentTemplate = {
  __typename?: 'UserDocumentTemplate';
  active: Scalars['Boolean'];
  description: Scalars['String'];
  id: Scalars['ID'];
  identifier: Scalars['String'];
  illustrationImageUrl: Maybe<Scalars['URL']>;
  inputFields: Array<InputField>;
  title: Scalars['String'];
  userDocumentTemplateAttachments: Array<UserDocumentTemplateAttachment>;
  userDocuments: Array<UserDocument>;
};

export type UserDocumentTemplateUserDocumentsArgs = {
  userDocumentId: InputMaybe<Scalars['ID']>;
};

export type UserDocumentTemplateAttachment = {
  __typename?: 'UserDocumentTemplateAttachment';
  description: Scalars['String'];
  id: Scalars['ID'];
  identifier: Scalars['String'];
  position: Scalars['Int'];
  required: Scalars['Boolean'];
  title: Scalars['String'];
};

export type UserRole = {
  __typename?: 'UserRole';
  confirmedAt: Maybe<Scalars['DateTime']>;
  name: Maybe<UserRoleName>;
};

/** Represents all the possible user role values including company and location user roles */
export enum UserRoleName {
  Billing = 'billing',
  DeliveriesAdmin = 'deliveries_admin',
  Employee = 'employee',
  GlobalAdmin = 'global_admin',
  LocationAdmin = 'location_admin',
  Receptionist = 'receptionist',
  Security = 'security',
}

export type Visitor = {
  __typename?: 'Visitor';
  avatarUrl: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  fullName: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
};

/**
 * status: a filter by the status – enum of: all, expected, signedIn, signedOut
 * expected = invites
 * signedIn = entries that are currently on-premise
 * signedOut = past entries
 */
export enum VisitorSelectionEnum {
  All = 'all',
  Expected = 'expected',
  SignedIn = 'signedIn',
  SignedOut = 'signedOut',
}

/** Represents either an Invite or Entry */
export type VisitorUnion = Entry | Invite;

/** Wrapper for visitors query and pagination data */
export type Visitors = {
  __typename?: 'Visitors';
  /** Visitors (invites and entries) */
  collection: Array<VisitorUnion>;
  /** Pagination metadata */
  pagination: VisitorsPagination;
};

export type VisitorsPagination = {
  __typename?: 'VisitorsPagination';
  /** Pagination details for entries */
  entries: PaginationDetails;
  /** Pagination details for invites as host */
  invites: PaginationDetails;
  /** Current page */
  page: Scalars['Int'];
};

export type WorkplaceDay = {
  __typename?: 'WorkplaceDay';
  active: Scalars['Boolean'];
  dayOfWeek: Scalars['String'];
  endTime: Scalars['String'];
  id: Scalars['ID'];
  startTime: Scalars['String'];
};

export type WorkplaceGroup = {
  __typename?: 'WorkplaceGroup';
  canEdit: Scalars['Boolean'];
  canEditMembers: Scalars['Boolean'];
  creator: Employee;
  id: Scalars['ID'];
  locked: Scalars['Boolean'];
  name: Scalars['String'];
  private: Scalars['Boolean'];
  workplaceGroupMembersResponse: WorkplaceGroupMembersResponse;
};

export type WorkplaceGroupWorkplaceGroupMembersResponseArgs = {
  limit: InputMaybe<Scalars['Int']>;
  page: InputMaybe<Scalars['Int']>;
};

export type WorkplaceGroupMember = {
  __typename?: 'WorkplaceGroupMember';
  employee: Employee;
  id: Scalars['ID'];
  role: Scalars['String'];
  workplaceGroupId: Scalars['ID'];
};

/** Wrapper for workplace-group-members query and pagination data */
export type WorkplaceGroupMembersResponse = {
  __typename?: 'WorkplaceGroupMembersResponse';
  /** Workplace group members */
  collection: Array<WorkplaceGroupMember>;
  /** Pagination metadata */
  pagination: Pagination;
};

/** Workplace Visit Request allows employees to invites other employees to visit a physical space together */
export type WorkplaceVisitRequest = {
  __typename?: 'WorkplaceVisitRequest';
  createdAt: Scalars['DateTime'];
  date: Scalars['Date'];
  id: Scalars['ID'];
  invitee: Employee;
  inviters: Array<Employee>;
  location: Location;
  state: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Workplace Visit Request create response */
export type WorkplaceVisitRequestCreateResponse = {
  __typename?: 'WorkplaceVisitRequestCreateResponse';
  alreadyScheduledEmployeeIds: Maybe<Array<Scalars['ID']>>;
  createdWorkplaceVisitRequests: Array<WorkplaceVisitRequest>;
};

export type _Entity =
  | Amenity
  | Announcement
  | Carrier
  | Delivery
  | DeliveryArea
  | Desk
  | DesksLocation
  | DirectUpload
  | Door
  | Employee
  | EmployeeFavorite
  | EmployeeInvite
  | Entry
  | Feed
  | Floor
  | FloorReservationCount
  | Flow
  | IntegrationSetting
  | Invite
  | Location
  | NewUser
  | Organization
  | Realm
  | Recipient
  | RegistrationDate
  | RegistrationDatePartialDay
  | Reservation
  | Role
  | Room
  | ScheduledEmployee
  | Ticket
  | TicketAttachment
  | TicketCategory
  | TicketConfiguration
  | User
  | UserDocument
  | UserDocumentAttachment
  | UserDocumentLinkable
  | UserDocumentTemplate
  | UserDocumentTemplateAttachment
  | WorkplaceDay
  | WorkplaceGroup
  | WorkplaceGroupMember
  | WorkplaceVisitRequest;

/**
 * The sdl representing the federated service capabilities. Includes federation
 * directives, removes federation types, and includes rest of full schema after
 * schema directives have been applied
 */
export type _Service = {
  __typename?: '_Service';
  sdl: Maybe<Scalars['String']>;
};

export type DeskFragmentFragment = {
  __typename?: 'Desk';
  id: string;
  name: string;
  neighborhood: string | null;
  deskAvailability: DeskAvailabilityEnum | null;
  x: number | null;
  y: number | null;
  floor: {
    __typename?: 'Floor';
    id: string;
    name: string;
    building: string | null;
    floorPlanUrl: string | null;
    floorNumber: number;
  };
};

export type DeskWithAmenitiesFragmentFragment = {
  __typename?: 'Desk';
  id: string;
  name: string;
  assignedTo: string | null;
  neighborhood: string | null;
  deskAvailability: DeskAvailabilityEnum | null;
  x: number | null;
  y: number | null;
  amenities: Array<{ __typename?: 'Amenity'; id: string; name: string }> | null;
  floor: { __typename?: 'Floor'; id: string; name: string; building: string | null; floorPlanUrl: string | null };
};

export type DesksOnFloorFragmentFragment = {
  __typename?: 'DesksOnFloor';
  desks: Array<{
    __typename?: 'Desk';
    id: string;
    name: string;
    assignedTo: string | null;
    neighborhood: string | null;
    deskAvailability: DeskAvailabilityEnum | null;
    x: number | null;
    y: number | null;
    amenities: Array<{ __typename?: 'Amenity'; id: string; name: string }> | null;
    floor: { __typename?: 'Floor'; id: string; name: string; building: string | null; floorPlanUrl: string | null };
  }>;
  errors: Array<{ __typename?: 'EnvoyError'; code: number; domain: string }> | null;
};

export type EmployeeFragmentFragment = {
  __typename?: 'Employee';
  id: number;
  email: string | null;
  name: string;
  phoneNumber: string | null;
  profilePictureUrl: string | null;
};

export type EntryFragmentFragment = {
  __typename?: 'Entry';
  id: string;
  signedInAt: Date;
  signedOutAt: Date | null;
  nda: string | null;
  agreementRefused: boolean | null;
  isDelivery: boolean;
  photoUrl: string | null;
  privateNotes: string | null;
  additionalGuests: number;
  flowName: string;
  employee: {
    __typename?: 'Employee';
    id: number;
    email: string | null;
    name: string;
    phoneNumber: string | null;
    profilePictureUrl: string | null;
  } | null;
  visitor: {
    __typename?: 'Visitor';
    avatarUrl: string | null;
    email: string | null;
    fullName: string | null;
    phoneNumber: string | null;
  };
  userData: Array<{ __typename?: 'FormField'; field: string; value: string | null }>;
  location: {
    __typename?: 'Location';
    id: string;
    name: string;
    address: string | null;
    logoUrl: string | null;
    timezone: string;
  } | null;
  flow: {
    __typename?: 'Flow';
    id: string;
    name: string;
    employeeCentric: boolean | null;
    agreementPage: { __typename?: 'AgreementPage'; enabled: boolean } | null;
  } | null;
  desk: {
    __typename?: 'Desk';
    id: string;
    name: string;
    neighborhood: string | null;
    deskAvailability: DeskAvailabilityEnum | null;
    x: number | null;
    y: number | null;
    floor: {
      __typename?: 'Floor';
      id: string;
      name: string;
      building: string | null;
      floorPlanUrl: string | null;
      floorNumber: number;
    };
  } | null;
};

export type EnvoyErrorsFragmentFragment = { __typename?: 'EnvoyError'; code: number; domain: string };

export type GenericResponseFragmentFragment = { __typename?: 'GenericResponse'; message: string; success: boolean };

export type InviteFragmentFragment = {
  __typename?: 'Invite';
  id: string;
  expectedArrivalTime: Date;
  preregistrationLink: string | null;
  preregistrationStatusLink: string | null;
  privateNotes: string | null;
  additionalGuests: number;
  isPresigned: boolean;
  arrived: boolean;
  touchlessSignInAvailableTime: Date | null;
  touchlessSignInPresenceRequired: boolean;
  guestUpdatedAt: Date | null;
  entryId: string | null;
  entrySignedOutAt: Date | null;
  entry: {
    __typename?: 'Entry';
    desk: {
      __typename?: 'Desk';
      id: string;
      name: string;
      neighborhood: string | null;
      deskAvailability: DeskAvailabilityEnum | null;
      x: number | null;
      y: number | null;
      floor: {
        __typename?: 'Floor';
        id: string;
        name: string;
        building: string | null;
        floorPlanUrl: string | null;
        floorNumber: number;
      };
    } | null;
  } | null;
  employeeInvite?: {
    __typename?: 'EmployeeInvite';
    id: string;
    delegatedBooker: { __typename?: 'Employee'; id: number; name: string; email: string | null } | null;
  } | null;
  employee: {
    __typename?: 'Employee';
    id: number;
    email: string | null;
    name: string;
    phoneNumber: string | null;
    profilePictureUrl: string | null;
  } | null;
  visitor: {
    __typename?: 'Visitor';
    avatarUrl: string | null;
    email: string | null;
    fullName: string | null;
    phoneNumber: string | null;
  };
  userData: Array<{ __typename?: 'FormField'; field: string; value: string | null }>;
  location: {
    __typename?: 'Location';
    id: string;
    name: string;
    address: string | null;
    logoUrl: string | null;
    companyName: string | null;
    timezone: string;
  } | null;
  flow: { __typename?: 'Flow'; id: string; name: string; employeeCentric: boolean | null } | null;
  approvalStatus: { __typename?: 'ApprovalStatus'; status: string } | null;
};

export type RegistrationDateFragmentFragment = {
  __typename?: 'RegistrationDate';
  available: boolean;
  date: Date;
  hasRequiredDocumentApproval: boolean;
  maxOccupancy: number | null;
  peopleRegistered: number;
  requiredDocumentApprovalExpiresAt: Date | null;
  requiredDocumentMessage: string | null;
  reservation: {
    __typename?: 'Reservation';
    id: string;
    startTime: number | null;
    endTime: number | null;
    checkInTime: Date | null;
    checkOutTime: Date | null;
    isPartialDay: boolean | null;
    desk: {
      __typename?: 'Desk';
      id: string;
      name: string;
      neighborhood: string | null;
      deskAvailability: DeskAvailabilityEnum | null;
      x: number | null;
      y: number | null;
      floor: {
        __typename?: 'Floor';
        id: string;
        name: string;
        building: string | null;
        floorPlanUrl: string | null;
        floorNumber: number;
      };
    };
  } | null;
  screeningCard:
    | {
        __typename?: 'Invite';
        id: string;
        expectedArrivalTime: Date;
        preregistrationLink: string | null;
        preregistrationStatusLink: string | null;
        privateNotes: string | null;
        additionalGuests: number;
        isPresigned: boolean;
        arrived: boolean;
        touchlessSignInAvailableTime: Date | null;
        touchlessSignInPresenceRequired: boolean;
        guestUpdatedAt: Date | null;
        entryId: string | null;
        entrySignedOutAt: Date | null;
        entry: {
          __typename?: 'Entry';
          desk: {
            __typename?: 'Desk';
            id: string;
            name: string;
            neighborhood: string | null;
            deskAvailability: DeskAvailabilityEnum | null;
            x: number | null;
            y: number | null;
            floor: {
              __typename?: 'Floor';
              id: string;
              name: string;
              building: string | null;
              floorPlanUrl: string | null;
              floorNumber: number;
            };
          } | null;
        } | null;
        employeeInvite?: {
          __typename?: 'EmployeeInvite';
          id: string;
          delegatedBooker: { __typename?: 'Employee'; id: number; name: string; email: string | null } | null;
        } | null;
        employee: {
          __typename?: 'Employee';
          id: number;
          email: string | null;
          name: string;
          phoneNumber: string | null;
          profilePictureUrl: string | null;
        } | null;
        visitor: {
          __typename?: 'Visitor';
          avatarUrl: string | null;
          email: string | null;
          fullName: string | null;
          phoneNumber: string | null;
        };
        userData: Array<{ __typename?: 'FormField'; field: string; value: string | null }>;
        location: {
          __typename?: 'Location';
          id: string;
          name: string;
          address: string | null;
          logoUrl: string | null;
          companyName: string | null;
          timezone: string;
        } | null;
        flow: { __typename?: 'Flow'; id: string; name: string; employeeCentric: boolean | null } | null;
        approvalStatus: { __typename?: 'ApprovalStatus'; status: string } | null;
      }
    | {
        __typename?: 'SelfCertify';
        fullName: string;
        expectedArrivalTime: Date;
        email: string;
        locationId: string;
        capacityReached: boolean;
        userData: Array<{ __typename?: 'FormField'; field: string; value: string | null }>;
      }
    | { __typename?: 'UserDocumentCard' }
    | { __typename?: 'WorkplaceVisitRequest' };
};

export type RegistrationDatePartialDayFragmentFragment = {
  __typename?: 'RegistrationDatePartialDay';
  available: boolean;
  date: Date;
  hasRequiredDocumentApproval: boolean;
  maxOccupancy: number | null;
  peopleRegistered: number;
  requiredDocumentApprovalExpiresAt: Date | null;
  requiredDocumentMessage: string | null;
  reservations: Array<{
    __typename?: 'Reservation';
    id: string;
    startTime: number | null;
    endTime: number | null;
    checkInTime: Date | null;
    checkOutTime: Date | null;
    isPartialDay: boolean | null;
    desk: {
      __typename?: 'Desk';
      id: string;
      name: string;
      neighborhood: string | null;
      deskAvailability: DeskAvailabilityEnum | null;
      x: number | null;
      y: number | null;
      floor: {
        __typename?: 'Floor';
        id: string;
        name: string;
        building: string | null;
        floorPlanUrl: string | null;
        floorNumber: number;
      };
    };
  }>;
  screeningCard:
    | {
        __typename?: 'Invite';
        id: string;
        expectedArrivalTime: Date;
        preregistrationLink: string | null;
        preregistrationStatusLink: string | null;
        privateNotes: string | null;
        additionalGuests: number;
        isPresigned: boolean;
        arrived: boolean;
        touchlessSignInAvailableTime: Date | null;
        touchlessSignInPresenceRequired: boolean;
        guestUpdatedAt: Date | null;
        entryId: string | null;
        entrySignedOutAt: Date | null;
        entry: {
          __typename?: 'Entry';
          desk: {
            __typename?: 'Desk';
            id: string;
            name: string;
            neighborhood: string | null;
            deskAvailability: DeskAvailabilityEnum | null;
            x: number | null;
            y: number | null;
            floor: {
              __typename?: 'Floor';
              id: string;
              name: string;
              building: string | null;
              floorPlanUrl: string | null;
              floorNumber: number;
            };
          } | null;
        } | null;
        employeeInvite?: {
          __typename?: 'EmployeeInvite';
          id: string;
          delegatedBooker: { __typename?: 'Employee'; id: number; name: string; email: string | null } | null;
        } | null;
        employee: {
          __typename?: 'Employee';
          id: number;
          email: string | null;
          name: string;
          phoneNumber: string | null;
          profilePictureUrl: string | null;
        } | null;
        visitor: {
          __typename?: 'Visitor';
          avatarUrl: string | null;
          email: string | null;
          fullName: string | null;
          phoneNumber: string | null;
        };
        userData: Array<{ __typename?: 'FormField'; field: string; value: string | null }>;
        location: {
          __typename?: 'Location';
          id: string;
          name: string;
          address: string | null;
          logoUrl: string | null;
          companyName: string | null;
          timezone: string;
        } | null;
        flow: { __typename?: 'Flow'; id: string; name: string; employeeCentric: boolean | null } | null;
        approvalStatus: { __typename?: 'ApprovalStatus'; status: string } | null;
      }
    | {
        __typename?: 'SelfCertify';
        fullName: string;
        expectedArrivalTime: Date;
        email: string;
        locationId: string;
        capacityReached: boolean;
        userData: Array<{ __typename?: 'FormField'; field: string; value: string | null }>;
      }
    | { __typename?: 'UserDocumentCard' }
    | { __typename?: 'WorkplaceVisitRequest' };
};

export type ReservationFragmentFragment = {
  __typename?: 'Reservation';
  id: string;
  startTime: number | null;
  endTime: number | null;
  checkInTime: Date | null;
  checkOutTime: Date | null;
  isPartialDay: boolean | null;
  desk: {
    __typename?: 'Desk';
    id: string;
    name: string;
    neighborhood: string | null;
    deskAvailability: DeskAvailabilityEnum | null;
    x: number | null;
    y: number | null;
    floor: {
      __typename?: 'Floor';
      id: string;
      name: string;
      building: string | null;
      floorPlanUrl: string | null;
      floorNumber: number;
    };
  };
};

export type ReservationWithAmenitiesFragmentFragment = {
  __typename?: 'Reservation';
  id: string;
  startTime: number | null;
  endTime: number | null;
  isPartialDay: boolean | null;
  desk: {
    __typename?: 'Desk';
    id: string;
    name: string;
    assignedTo: string | null;
    neighborhood: string | null;
    deskAvailability: DeskAvailabilityEnum | null;
    x: number | null;
    y: number | null;
    amenities: Array<{ __typename?: 'Amenity'; id: string; name: string }> | null;
    floor: { __typename?: 'Floor'; id: string; name: string; building: string | null; floorPlanUrl: string | null };
  };
};

export type SelfCertifyFragmentFragment = {
  __typename?: 'SelfCertify';
  fullName: string;
  expectedArrivalTime: Date;
  email: string;
  locationId: string;
  capacityReached: boolean;
  userData: Array<{ __typename?: 'FormField'; field: string; value: string | null }>;
};

export type ChangeDeskReservationMutationVariables = Exact<{
  reservationID: Scalars['ID'];
  currentDeskID: Scalars['ID'];
  newDeskID: Scalars['ID'];
}>;

export type ChangeDeskReservationMutation = {
  __typename?: 'Mutation';
  changeDeskReservation: {
    __typename?: 'Reservation';
    id: string;
    startTime: number | null;
    endTime: number | null;
    checkInTime: Date | null;
    checkOutTime: Date | null;
    isPartialDay: boolean | null;
    desk: {
      __typename?: 'Desk';
      id: string;
      name: string;
      neighborhood: string | null;
      deskAvailability: DeskAvailabilityEnum | null;
      x: number | null;
      y: number | null;
      floor: {
        __typename?: 'Floor';
        id: string;
        name: string;
        building: string | null;
        floorPlanUrl: string | null;
        floorNumber: number;
      };
    };
  };
};

export type CheckInReservationMutationVariables = Exact<{
  reservationID: Scalars['ID'];
}>;

export type CheckInReservationMutation = {
  __typename?: 'Mutation';
  checkInReservation: {
    __typename?: 'Reservation';
    id: string;
    startTime: number | null;
    endTime: number | null;
    checkInTime: Date | null;
    checkOutTime: Date | null;
    isPartialDay: boolean | null;
    desk: {
      __typename?: 'Desk';
      id: string;
      name: string;
      neighborhood: string | null;
      deskAvailability: DeskAvailabilityEnum | null;
      x: number | null;
      y: number | null;
      floor: {
        __typename?: 'Floor';
        id: string;
        name: string;
        building: string | null;
        floorPlanUrl: string | null;
        floorNumber: number;
      };
    };
  };
};

export type CreateInviteReservationMutationVariables = Exact<{
  deskId: InputMaybe<Scalars['ID']>;
  invite: InputMaybe<NewInviteInput>;
  inviteId: InputMaybe<Scalars['ID']>;
  includeEmployeeInvite: Scalars['Boolean'];
}>;

export type CreateInviteReservationMutation = {
  __typename?: 'Mutation';
  createInviteReservation: {
    __typename?: 'InviteReservationResponse';
    invite: {
      __typename?: 'Invite';
      id: string;
      expectedArrivalTime: Date;
      preregistrationLink: string | null;
      preregistrationStatusLink: string | null;
      privateNotes: string | null;
      additionalGuests: number;
      isPresigned: boolean;
      arrived: boolean;
      touchlessSignInAvailableTime: Date | null;
      touchlessSignInPresenceRequired: boolean;
      guestUpdatedAt: Date | null;
      entryId: string | null;
      entrySignedOutAt: Date | null;
      entry: {
        __typename?: 'Entry';
        desk: {
          __typename?: 'Desk';
          id: string;
          name: string;
          neighborhood: string | null;
          deskAvailability: DeskAvailabilityEnum | null;
          x: number | null;
          y: number | null;
          floor: {
            __typename?: 'Floor';
            id: string;
            name: string;
            building: string | null;
            floorPlanUrl: string | null;
            floorNumber: number;
          };
        } | null;
      } | null;
      employeeInvite?: {
        __typename?: 'EmployeeInvite';
        id: string;
        delegatedBooker: { __typename?: 'Employee'; id: number; name: string; email: string | null } | null;
      } | null;
      employee: {
        __typename?: 'Employee';
        id: number;
        email: string | null;
        name: string;
        phoneNumber: string | null;
        profilePictureUrl: string | null;
      } | null;
      visitor: {
        __typename?: 'Visitor';
        avatarUrl: string | null;
        email: string | null;
        fullName: string | null;
        phoneNumber: string | null;
      };
      userData: Array<{ __typename?: 'FormField'; field: string; value: string | null }>;
      location: {
        __typename?: 'Location';
        id: string;
        name: string;
        address: string | null;
        logoUrl: string | null;
        companyName: string | null;
        timezone: string;
      } | null;
      flow: { __typename?: 'Flow'; id: string; name: string; employeeCentric: boolean | null } | null;
      approvalStatus: { __typename?: 'ApprovalStatus'; status: string } | null;
    } | null;
    reservation: {
      __typename?: 'Reservation';
      id: string;
      startTime: number | null;
      endTime: number | null;
      checkInTime: Date | null;
      checkOutTime: Date | null;
      isPartialDay: boolean | null;
      desk: {
        __typename?: 'Desk';
        id: string;
        name: string;
        neighborhood: string | null;
        deskAvailability: DeskAvailabilityEnum | null;
        x: number | null;
        y: number | null;
        floor: {
          __typename?: 'Floor';
          id: string;
          name: string;
          building: string | null;
          floorPlanUrl: string | null;
          floorNumber: number;
        };
      };
    } | null;
  };
};

export type DeleteInviteMutationVariables = Exact<{
  inviteId: Scalars['ID'];
}>;

export type DeleteInviteMutation = { __typename?: 'Mutation'; deleteInvite: { __typename?: 'Invite'; id: string } };

export type DeleteInviteReservationMutationVariables = Exact<{
  inviteId: Scalars['ID'];
  reservationId: Scalars['ID'];
}>;

export type DeleteInviteReservationMutation = {
  __typename?: 'Mutation';
  deleteInvite: { __typename?: 'Invite'; id: string };
  releaseDeskReservation: { __typename?: 'GenericResponse'; message: string; success: boolean };
};

export type DeleteInviteReservationsMutationVariables = Exact<{
  inviteId: Scalars['ID'];
  reservationIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type DeleteInviteReservationsMutation = {
  __typename?: 'Mutation';
  deleteInvite: { __typename?: 'Invite'; id: string };
  releaseDeskReservations: { __typename?: 'GenericResponse'; message: string; success: boolean };
};

export type ReleaseDeskReservationMutationVariables = Exact<{
  reservationId: Scalars['ID'];
}>;

export type ReleaseDeskReservationMutation = {
  __typename?: 'Mutation';
  releaseDeskReservation: { __typename?: 'GenericResponse'; message: string; success: boolean };
};

export type ReleaseDeskReservationsMutationVariables = Exact<{
  reservationIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type ReleaseDeskReservationsMutation = {
  __typename?: 'Mutation';
  releaseDeskReservations: { __typename?: 'GenericResponse'; message: string; success: boolean };
};

export type SignInInviteMutationVariables = Exact<{
  inviteID: Scalars['ID'];
  reservationID: InputMaybe<Scalars['ID']>;
}>;

export type SignInInviteMutation = {
  __typename?: 'Mutation';
  signInInvite: Array<{
    __typename?: 'Entry';
    id: string;
    signedInAt: Date;
    signedOutAt: Date | null;
    nda: string | null;
    agreementRefused: boolean | null;
    isDelivery: boolean;
    photoUrl: string | null;
    privateNotes: string | null;
    additionalGuests: number;
    flowName: string;
    employee: {
      __typename?: 'Employee';
      id: number;
      email: string | null;
      name: string;
      phoneNumber: string | null;
      profilePictureUrl: string | null;
    } | null;
    visitor: {
      __typename?: 'Visitor';
      avatarUrl: string | null;
      email: string | null;
      fullName: string | null;
      phoneNumber: string | null;
    };
    userData: Array<{ __typename?: 'FormField'; field: string; value: string | null }>;
    location: {
      __typename?: 'Location';
      id: string;
      name: string;
      address: string | null;
      logoUrl: string | null;
      timezone: string;
    } | null;
    flow: {
      __typename?: 'Flow';
      id: string;
      name: string;
      employeeCentric: boolean | null;
      agreementPage: { __typename?: 'AgreementPage'; enabled: boolean } | null;
    } | null;
    desk: {
      __typename?: 'Desk';
      id: string;
      name: string;
      neighborhood: string | null;
      deskAvailability: DeskAvailabilityEnum | null;
      x: number | null;
      y: number | null;
      floor: {
        __typename?: 'Floor';
        id: string;
        name: string;
        building: string | null;
        floorPlanUrl: string | null;
        floorNumber: number;
      };
    } | null;
  }>;
};

export type SignOutEntryMutationVariables = Exact<{
  id: Scalars['ID'];
  signedOutAt: Scalars['DateTime'];
  signedOutBy: InputMaybe<Scalars['String']>;
}>;

export type SignOutEntryMutation = {
  __typename?: 'Mutation';
  signOutEntry: {
    __typename?: 'Entry';
    id: string;
    signedInAt: Date;
    signedOutAt: Date | null;
    nda: string | null;
    agreementRefused: boolean | null;
    isDelivery: boolean;
    photoUrl: string | null;
    privateNotes: string | null;
    additionalGuests: number;
    flowName: string;
    employee: {
      __typename?: 'Employee';
      id: number;
      email: string | null;
      name: string;
      phoneNumber: string | null;
      profilePictureUrl: string | null;
    } | null;
    visitor: {
      __typename?: 'Visitor';
      avatarUrl: string | null;
      email: string | null;
      fullName: string | null;
      phoneNumber: string | null;
    };
    userData: Array<{ __typename?: 'FormField'; field: string; value: string | null }>;
    location: {
      __typename?: 'Location';
      id: string;
      name: string;
      address: string | null;
      logoUrl: string | null;
      timezone: string;
    } | null;
    flow: {
      __typename?: 'Flow';
      id: string;
      name: string;
      employeeCentric: boolean | null;
      agreementPage: { __typename?: 'AgreementPage'; enabled: boolean } | null;
    } | null;
    desk: {
      __typename?: 'Desk';
      id: string;
      name: string;
      neighborhood: string | null;
      deskAvailability: DeskAvailabilityEnum | null;
      x: number | null;
      y: number | null;
      floor: {
        __typename?: 'Floor';
        id: string;
        name: string;
        building: string | null;
        floorPlanUrl: string | null;
        floorNumber: number;
      };
    } | null;
  };
};

export type SignOutEntryReservationMutationVariables = Exact<{
  id: Scalars['ID'];
  signedOutAt: Scalars['DateTime'];
  signedOutBy: InputMaybe<Scalars['String']>;
  reservationId: Scalars['ID'];
}>;

export type SignOutEntryReservationMutation = {
  __typename?: 'Mutation';
  signOutEntry: {
    __typename?: 'Entry';
    id: string;
    signedInAt: Date;
    signedOutAt: Date | null;
    nda: string | null;
    agreementRefused: boolean | null;
    isDelivery: boolean;
    photoUrl: string | null;
    privateNotes: string | null;
    additionalGuests: number;
    flowName: string;
    employee: {
      __typename?: 'Employee';
      id: number;
      email: string | null;
      name: string;
      phoneNumber: string | null;
      profilePictureUrl: string | null;
    } | null;
    visitor: {
      __typename?: 'Visitor';
      avatarUrl: string | null;
      email: string | null;
      fullName: string | null;
      phoneNumber: string | null;
    };
    userData: Array<{ __typename?: 'FormField'; field: string; value: string | null }>;
    location: {
      __typename?: 'Location';
      id: string;
      name: string;
      address: string | null;
      logoUrl: string | null;
      timezone: string;
    } | null;
    flow: {
      __typename?: 'Flow';
      id: string;
      name: string;
      employeeCentric: boolean | null;
      agreementPage: { __typename?: 'AgreementPage'; enabled: boolean } | null;
    } | null;
    desk: {
      __typename?: 'Desk';
      id: string;
      name: string;
      neighborhood: string | null;
      deskAvailability: DeskAvailabilityEnum | null;
      x: number | null;
      y: number | null;
      floor: {
        __typename?: 'Floor';
        id: string;
        name: string;
        building: string | null;
        floorPlanUrl: string | null;
        floorNumber: number;
      };
    } | null;
  };
  releaseDeskReservation: { __typename?: 'GenericResponse'; message: string; success: boolean };
};

export type AvailableDesksForLocationQueryVariables = Exact<{
  date: Scalars['DateTime'];
  locationId: Scalars['ID'];
}>;

export type AvailableDesksForLocationQuery = {
  __typename?: 'Query';
  availableDesksForLocation: Array<{
    __typename?: 'Desk';
    id: string;
    name: string;
    neighborhood: string | null;
    deskAvailability: DeskAvailabilityEnum | null;
    x: number | null;
    y: number | null;
    floor: {
      __typename?: 'Floor';
      id: string;
      name: string;
      building: string | null;
      floorPlanUrl: string | null;
      floorNumber: number;
    };
  }>;
};

export type DesksOnFloorForLocationQueryVariables = Exact<{
  locationId: Scalars['ID'];
  floorId: Scalars['ID'];
  startTime: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
  filters: Array<DeskStatusEnum> | DeskStatusEnum;
}>;

export type DesksOnFloorForLocationQuery = {
  __typename?: 'Query';
  desksOnFloorForLocation: {
    __typename?: 'DesksOnFloor';
    desks: Array<{
      __typename?: 'Desk';
      id: string;
      name: string;
      assignedTo: string | null;
      neighborhood: string | null;
      deskAvailability: DeskAvailabilityEnum | null;
      x: number | null;
      y: number | null;
      amenities: Array<{ __typename?: 'Amenity'; id: string; name: string }> | null;
      floor: {
        __typename?: 'Floor';
        id: string;
        name: string;
        building: string | null;
        floorPlanUrl: string | null;
      };
    }>;
    errors: Array<{ __typename?: 'EnvoyError'; code: number; domain: string }> | null;
  };
};

export type EmployeeRegistrationDatesQueryVariables = Exact<{
  locationId: Scalars['ID'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  includeEmployeeInvite: Scalars['Boolean'];
}>;

export type EmployeeRegistrationDatesQuery = {
  __typename?: 'Query';
  employeeRegistration: {
    __typename?: 'EmployeeRegistration';
    registrationDates: Array<{
      __typename?: 'RegistrationDate';
      available: boolean;
      date: Date;
      hasRequiredDocumentApproval: boolean;
      maxOccupancy: number | null;
      peopleRegistered: number;
      requiredDocumentApprovalExpiresAt: Date | null;
      requiredDocumentMessage: string | null;
      reservation: {
        __typename?: 'Reservation';
        id: string;
        startTime: number | null;
        endTime: number | null;
        checkInTime: Date | null;
        checkOutTime: Date | null;
        isPartialDay: boolean | null;
        desk: {
          __typename?: 'Desk';
          id: string;
          name: string;
          neighborhood: string | null;
          deskAvailability: DeskAvailabilityEnum | null;
          x: number | null;
          y: number | null;
          floor: {
            __typename?: 'Floor';
            id: string;
            name: string;
            building: string | null;
            floorPlanUrl: string | null;
            floorNumber: number;
          };
        };
      } | null;
      screeningCard:
        | {
            __typename?: 'Invite';
            id: string;
            expectedArrivalTime: Date;
            preregistrationLink: string | null;
            preregistrationStatusLink: string | null;
            privateNotes: string | null;
            additionalGuests: number;
            isPresigned: boolean;
            arrived: boolean;
            touchlessSignInAvailableTime: Date | null;
            touchlessSignInPresenceRequired: boolean;
            guestUpdatedAt: Date | null;
            entryId: string | null;
            entrySignedOutAt: Date | null;
            entry: {
              __typename?: 'Entry';
              desk: {
                __typename?: 'Desk';
                id: string;
                name: string;
                neighborhood: string | null;
                deskAvailability: DeskAvailabilityEnum | null;
                x: number | null;
                y: number | null;
                floor: {
                  __typename?: 'Floor';
                  id: string;
                  name: string;
                  building: string | null;
                  floorPlanUrl: string | null;
                  floorNumber: number;
                };
              } | null;
            } | null;
            employeeInvite?: {
              __typename?: 'EmployeeInvite';
              id: string;
              delegatedBooker: { __typename?: 'Employee'; id: number; name: string; email: string | null } | null;
            } | null;
            employee: {
              __typename?: 'Employee';
              id: number;
              email: string | null;
              name: string;
              phoneNumber: string | null;
              profilePictureUrl: string | null;
            } | null;
            visitor: {
              __typename?: 'Visitor';
              avatarUrl: string | null;
              email: string | null;
              fullName: string | null;
              phoneNumber: string | null;
            };
            userData: Array<{ __typename?: 'FormField'; field: string; value: string | null }>;
            location: {
              __typename?: 'Location';
              id: string;
              name: string;
              address: string | null;
              logoUrl: string | null;
              companyName: string | null;
              timezone: string;
            } | null;
            flow: { __typename?: 'Flow'; id: string; name: string; employeeCentric: boolean | null } | null;
            approvalStatus: { __typename?: 'ApprovalStatus'; status: string } | null;
          }
        | {
            __typename?: 'SelfCertify';
            fullName: string;
            expectedArrivalTime: Date;
            email: string;
            locationId: string;
            capacityReached: boolean;
            userData: Array<{ __typename?: 'FormField'; field: string; value: string | null }>;
          }
        | { __typename?: 'UserDocumentCard' }
        | { __typename?: 'WorkplaceVisitRequest' };
    }>;
  };
};

export type EmployeeRegistrationPartialDayQueryVariables = Exact<{
  locationId: Scalars['ID'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  includeEmployeeInvite: Scalars['Boolean'];
}>;

export type EmployeeRegistrationPartialDayQuery = {
  __typename?: 'Query';
  employeeRegistrationPartialDay: {
    __typename?: 'EmployeeRegistrationPartialDay';
    registrationDates: Array<{
      __typename?: 'RegistrationDatePartialDay';
      available: boolean;
      date: Date;
      hasRequiredDocumentApproval: boolean;
      maxOccupancy: number | null;
      peopleRegistered: number;
      requiredDocumentApprovalExpiresAt: Date | null;
      requiredDocumentMessage: string | null;
      reservations: Array<{
        __typename?: 'Reservation';
        id: string;
        startTime: number | null;
        endTime: number | null;
        checkInTime: Date | null;
        checkOutTime: Date | null;
        isPartialDay: boolean | null;
        desk: {
          __typename?: 'Desk';
          id: string;
          name: string;
          neighborhood: string | null;
          deskAvailability: DeskAvailabilityEnum | null;
          x: number | null;
          y: number | null;
          floor: {
            __typename?: 'Floor';
            id: string;
            name: string;
            building: string | null;
            floorPlanUrl: string | null;
            floorNumber: number;
          };
        };
      }>;
      screeningCard:
        | {
            __typename?: 'Invite';
            id: string;
            expectedArrivalTime: Date;
            preregistrationLink: string | null;
            preregistrationStatusLink: string | null;
            privateNotes: string | null;
            additionalGuests: number;
            isPresigned: boolean;
            arrived: boolean;
            touchlessSignInAvailableTime: Date | null;
            touchlessSignInPresenceRequired: boolean;
            guestUpdatedAt: Date | null;
            entryId: string | null;
            entrySignedOutAt: Date | null;
            entry: {
              __typename?: 'Entry';
              desk: {
                __typename?: 'Desk';
                id: string;
                name: string;
                neighborhood: string | null;
                deskAvailability: DeskAvailabilityEnum | null;
                x: number | null;
                y: number | null;
                floor: {
                  __typename?: 'Floor';
                  id: string;
                  name: string;
                  building: string | null;
                  floorPlanUrl: string | null;
                  floorNumber: number;
                };
              } | null;
            } | null;
            employeeInvite?: {
              __typename?: 'EmployeeInvite';
              id: string;
              delegatedBooker: { __typename?: 'Employee'; id: number; name: string; email: string | null } | null;
            } | null;
            employee: {
              __typename?: 'Employee';
              id: number;
              email: string | null;
              name: string;
              phoneNumber: string | null;
              profilePictureUrl: string | null;
            } | null;
            visitor: {
              __typename?: 'Visitor';
              avatarUrl: string | null;
              email: string | null;
              fullName: string | null;
              phoneNumber: string | null;
            };
            userData: Array<{ __typename?: 'FormField'; field: string; value: string | null }>;
            location: {
              __typename?: 'Location';
              id: string;
              name: string;
              address: string | null;
              logoUrl: string | null;
              companyName: string | null;
              timezone: string;
            } | null;
            flow: { __typename?: 'Flow'; id: string; name: string; employeeCentric: boolean | null } | null;
            approvalStatus: { __typename?: 'ApprovalStatus'; status: string } | null;
          }
        | {
            __typename?: 'SelfCertify';
            fullName: string;
            expectedArrivalTime: Date;
            email: string;
            locationId: string;
            capacityReached: boolean;
            userData: Array<{ __typename?: 'FormField'; field: string; value: string | null }>;
          }
        | { __typename?: 'UserDocumentCard' }
        | { __typename?: 'WorkplaceVisitRequest' };
    }>;
  };
};

export type ReservationByIdQueryVariables = Exact<{
  reservationId: Scalars['ID'];
}>;

export type ReservationByIdQuery = {
  __typename?: 'Query';
  reservationById: {
    __typename?: 'Reservation';
    id: string;
    startTime: number | null;
    endTime: number | null;
    isPartialDay: boolean | null;
    desk: {
      __typename?: 'Desk';
      id: string;
      name: string;
      assignedTo: string | null;
      neighborhood: string | null;
      deskAvailability: DeskAvailabilityEnum | null;
      x: number | null;
      y: number | null;
      amenities: Array<{ __typename?: 'Amenity'; id: string; name: string }> | null;
      floor: { __typename?: 'Floor'; id: string; name: string; building: string | null; floorPlanUrl: string | null };
    };
  };
};

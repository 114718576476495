import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class UserDocumentListItemComponent extends Component {
  @tracked isOpen = false;

  get status() {
    return this.args.userDocument.isExpired
      ? 'Expired'
      : this.args.userDocument.userDocumentLink?.formattedApprovalStatus;
  }
}

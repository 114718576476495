import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { alias } from 'macro-decorators';

export default class VisitorsSettingsVisitorTypesFlowSignInFieldsController extends Controller {
  queryParams = ['showFieldOptions'];

  @service('global-setting-batch') globalSettingBatchService;
  @service currentAdmin;

  @tracked changeset = null;
  @tracked showFieldOptions = null;

  @alias('globalSettingBatchService.currentGlobalSettingBatch') globalSettingBatch;
  @alias('currentAdmin.isGlobalAdmin') isGlobalAdmin;

  get isLocationAdmin() {
    return this.currentAdmin.isLocationAdmin && !this.isGlobalAdmin;
  }
}

import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class VisitorsSettingsVisitorTypesFlowSecurityRoute extends Route {
  @service transitionConfirm;

  model() {
    const { currentLocation, flow, vrSubscription } = this.modelFor('visitors.settings.visitor-types.flow');
    return hash({
      currentLocation,
      flow,
      idScanPage: flow.idScanPage,
      inviteApprovalContacts: flow.inviteApprovalContacts,
      visualComplianceConfiguration: flow.visualComplianceConfiguration,
      vrSubscription,
    });
  }

  @routeEvent
  routeWillChange(transition) {
    const { visualComplianceConfiguration } = this.controller.model;
    const { idScanChangeset } = this.controller;
    const confirmTask = this.transitionConfirm.displayConfirmTask;
    const isDirty =
      (visualComplianceConfiguration && visualComplianceConfiguration.hasDirtyAttributes) || idScanChangeset?.isDirty;

    if (isDirty) {
      confirmTask.perform(transition, {
        continue() {
          idScanChangeset?.rollback();
          visualComplianceConfiguration?.rollbackAttributes();
        },
      });
    }
  }
}

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

export default class DeliveriesAnalyticsRoute extends Route {
  @service abilities;
  @service state;
  @service router;

  beforeModel() {
    if (this.abilities.cannot('visit-deliveries analytic')) {
      this.router.transitionTo('unauthorized');
    }
  }

  setupController(_controller, _model) {
    super.setupController(...arguments);
    set(this, 'state.showDeliveriesOnboarding', false);
  }
}

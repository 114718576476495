import Model, { attr } from '@ember-data/model';

export const ABSTRACT_SUMMARY_PAGE = Object.freeze({
  defaultMessage: '',
  kind: 'none',
  allowEmail: false,
  customTranslations: function () {
    return { message: {} };
  },
});

export default class AbstractSummaryPageModel extends Model {
  @attr('string', { defaultValue: ABSTRACT_SUMMARY_PAGE.kind }) declare kind: string;
  @attr('string') declare videoUrl?: string | null;
  @attr('string') declare message?: string | null;
  @attr('string', { defaultValue: ABSTRACT_SUMMARY_PAGE.defaultMessage }) declare defaultMessage: string;
  @attr('boolean', { defaultValue: ABSTRACT_SUMMARY_PAGE.allowEmail }) declare allowEmail: boolean;
  @attr('immutable', { defaultValue: ABSTRACT_SUMMARY_PAGE.customTranslations }) declare customTranslations: {
    message: Record<string, string>;
  };
}

import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import urlBuilder from 'garaje/utils/url-builder';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AnalyticsController extends Controller {
  @service state;
  @service messageBus;
  @service flashMessages;

  @tracked showModal = false;
  @tracked exportIframeUrl;

  constructor() {
    super(...arguments);
    this.messageBus.on('embedded-app-message', this.handleMessage);
  }

  /**
   * @return {String}
   */
  get iframeUrl() {
    const {
      currentCompany: { id: companyId },
    } = this.state;
    return urlBuilder.embeddedVisitorsGlobalAnalyticsUrl(companyId);
  }

  @action
  toggleShowModal() {
    this.showModal = !this.showModal;
  }

  @action
  handleMessage(message) {
    const locationId = message.query?.location_id;
    const companyId = message.query?.company_id;
    const flowId = null;
    const start = message.query?.start;
    const end = message.query?.end;
    const label = message.label;

    if (message.event === 'showEntryExportModal') {
      this.exportIframeUrl = urlBuilder.embeddedEntryExportModalUrl(companyId, locationId, flowId, start, end, label);
      this.toggleShowModal();
    } else if (message.event === 'showInviteExportModal') {
      this.exportIframeUrl = urlBuilder.embeddedInviteExportModalUrl(companyId, locationId, flowId, start, end, label);
      this.toggleShowModal();
    } else if (message.event === 'showScheduleReportsModal') {
      this.exportIframeUrl = urlBuilder.embeddedScheduleReportsModalUrl(companyId, locationId, flowId, start, end);
      this.toggleShowModal();
    } else if (message.event === 'closeExportModal' || message.event === 'closeScheduleReportsModal') {
      this.toggleShowModal();
    } else if (message.event === 'showToaster') {
      const type = message.type;
      const msg = message.msg;
      this.flashMessages.showAndHideFlash(type, msg);
    }
  }
}

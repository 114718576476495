import { action } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { and, gt, not } from '@ember/object/computed';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { all, task, dropTask, restartableTask } from 'ember-concurrency';
import type FlowModel from 'garaje/models/flow';
import type GlobalFlowModel from 'garaje/models/global-flow';
import type LocationModel from 'garaje/models/location';
import type UserModel from 'garaje/models/user';
import type VisualComplianceConfigurationModel from 'garaje/models/visual-compliance-configuration';
import type CurrentLocationService from 'garaje/services/current-location';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

const integration_name = 'Visual Compliance';
const message_code_type = 'integration';

interface VisualComplianceConfigureComponentSignature {
  Args: {
    configuration?: VisualComplianceConfigurationModel;
    location?: LocationModel;
    readOnly?: boolean;
  };
}

type Flow = GlobalFlowModel | FlowModel;

export default class VisualComplianceConfigureComponent extends Component<VisualComplianceConfigureComponentSignature> {
  @service declare currentLocation: CurrentLocationService;
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;
  @service declare store: Store;

  @and('secno', 'password') canConnect!: boolean;
  @and('args.configuration.secno', 'validCredentials') isConnected!: boolean;
  @not('args.configuration.credentialsInvalid') validCredentials!: boolean;
  @gt('connectTask.performCount', 0) didAttemptConnect!: boolean;

  @tracked contacts: UserModel[] = [];
  @tracked secno: string = '';
  @tracked confirmDisconnect: boolean = false;
  @tracked password: string = '';

  get configuration(): VisualComplianceConfigurationModel | null {
    return this.args.configuration || null;
  }

  get allFlows(): Flow[] {
    const { location } = this.args;

    return location ? location.flows.slice(0) : [];
  }

  get activeFlows(): Flow[] {
    return this.allFlows.filter((flow) => flow.visualComplianceEnabled);
  }

  get inactiveFlows(): Flow[] {
    return this.allFlows.filter((flow) => !flow.visualComplianceEnabled);
  }

  constructor(owner: unknown, args: VisualComplianceConfigureComponentSignature['Args']) {
    super(owner, args);
    this.secno = this.args.configuration?.secno || '';
    void this.loadContactsTask.perform();
  }

  uniqUsers(users: UserModel[]): UserModel[] {
    return [...new Map(users.map((u) => [u.id, u])).values()];
  }

  @action
  toggleReviewOnMatch(checked: boolean): void {
    const { configuration } = this;

    if (!configuration) return;

    configuration.reviewOnMatch = checked;
  }

  @action
  updateContacts(users: UserModel[]): void {
    const { configuration } = this;

    if (!configuration) return;

    this.contacts = users;

    configuration.contactIds = users.map((u) => u.id);
  }

  connectTask = dropTask(async () => {
    this.metrics.trackJobEvent('Security - Integration Login Button Clicked', {
      integration_name,
      button_text: 'Connect account',
    });

    const { configuration, canConnect } = this;

    if (!(canConnect && configuration)) return;

    try {
      configuration.secno = this.secno;
      configuration.password = this.password;

      this.metrics.trackJobEvent('Security - Integration Login Requested', {
        integration_name,
      });

      await configuration.save();

      if (this.validCredentials) {
        this.metrics.trackJobEvent('Security - Integration Account Connected', { integration_name });
        this.flashMessages.showAndHideFlash('success', 'Connected!');
        this.metrics.trackJobEvent('Viewed Flash Message', {
          type: 'success',
          integration_name,
          message_title: 'Connected!',
          message_code: 'integration_login_success',
          message_code_type,
        });
      }
    } catch (e) {
      configuration.secno = '';
      configuration.password = '';

      const message_title = 'Error connecting Visual Compliance';
      const message_code = parseErrorForDisplay(e);

      this.flashMessages.showFlash('error', message_title, message_code);
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'error',
        integration_name,
        message_title,
        message_code,
        message_code_type,
      });
    }
  });

  disconnectTask = dropTask(async () => {
    this.metrics.trackJobEvent('Security - Integration Logout Button Clicked', {
      integration_name,
      button_text: 'Connect Visual Compliance',
    });

    const { configuration } = this;

    if (!configuration) return;

    configuration.secno = '';
    configuration.password = '';
    this.secno = '';
    this.password = '';

    try {
      this.metrics.trackJobEvent('Security - Integration Logout Requested', {
        integration_name,
      });

      await configuration.save();

      this.metrics.trackJobEvent('Security - Integration Account Disconnected', { integration_name });
      this.flashMessages.showAndHideFlash('success', 'Disconnected!');
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'success',
        integration_name,
        message_title: 'Disconnected!',
        message_code: 'integration_logout_success',
        message_code_type,
      });
    } catch (e) {
      configuration.rollbackAttributes();
      this.secno = configuration.secno;

      const message_title = 'Error disconnecting Visual Compliance';
      const message_code = parseErrorForDisplay(e);

      this.flashMessages.showFlash('error', message_title, message_code);
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'error',
        integration_name,
        message_title,
        message_code,
        message_code_type,
      });
    } finally {
      this.confirmDisconnect = false;
    }
  });

  loadContactsTask = task(async () => {
    const { configuration } = this;

    if (!configuration) return;

    try {
      const contacts = await all(configuration.contactIds.map((id) => this.store.findRecord('user', id)));

      this.contacts = contacts;
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error loading contacts', parseErrorForDisplay(e));
    }
  });

  searchUsersTask = restartableTask(async (term: string) => {
    const { configuration, currentLocation } = this;

    if (!configuration) return;

    const re = new RegExp(`.*${term}.*`, 'i');
    const userRoles = await currentLocation.searchUsers.perform(term);
    const users = await all(userRoles.map((role) => role.user));

    // dedup since roles could point to same user
    return (
      this.uniqUsers(users)
        // remove existing contacts, compare as strings
        .filter(
          ({ id, fullName }) => !configuration.contactIds.map((n) => n.toString()).includes(id) && fullName.match(re),
        )
    );
  });
}

import Component from '@glimmer/component';
import { timeout, restartableTask } from 'ember-concurrency';

const INPUT_TIMEOUT = 300;

interface AdminRolesHeaderArgs {
  nameFilter: string;
  updateNameFilter: (name: string) => void;
  viewOnly: boolean;
}

export default class AdminRolesHeader extends Component<AdminRolesHeaderArgs> {
  searchTask = restartableTask(async (query: string) => {
    await timeout(INPUT_TIMEOUT);
    this.args.updateNameFilter(query.trim());
  });
}

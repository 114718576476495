import { belongsTo, hasMany } from 'ember-data/relationships';
import AbstractSignInFieldModel from 'garaje/models/abstract/abstract-sign-in-field';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('sign-in-field')
class GlobalSignInFieldModel extends AbstractSignInFieldModel {
  @belongsTo('global-sign-in-field-page', { inverse: 'signInFields' }) signInFieldPage;
  @hasMany('global-sign-in-field') actionableSignInFields;
  @hasMany('global-sign-in-field-action') signInFieldActions;
  @hasMany('global-sign-in-field-action', { inverse: 'actionableSignInField' }) actionableSignInFieldActions;
}

export default GlobalSignInFieldModel;

import { helper } from '@ember/component/helper';
import { isSameMonth, format } from 'date-fns';
/**
 * @returns {string} Returns a readable date range for an array of datetime objects
 */
export function dateRange([days]) {
  if (days.length == 1) {
    return `${format(days[0], 'MMM d')}`;
  }

  const firstDay = days[0];
  const lastDay = days[days.length - 1];
  const sameMonth = isSameMonth(firstDay, lastDay);
  const lastDayFormat = sameMonth ? 'd' : 'MMM d';

  return `${format(firstDay, 'MMM d')} - ${format(lastDay, lastDayFormat)}`;
}

export default helper(dateRange);

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { max, min, endOfDay, format, isEqual, startOfDay } from 'date-fns';
import moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';
import { localCopy } from 'tracked-toolbox';

/**
 * Date range selector component with dropdown of preset ranges.
 *
 * This component provides a date range selector component, along with a dropdown containing
 * preset date ranges. The date ranges are provided through the @presets and @presetsOrder arguments.
 * `@presets` should be an object mapping a string (the preset name) to a function that computes and returns
 * the date range. This function is called with one arguments, `today`, corresponding to the current date.
 * The expected return value is a two-element array, where the first element is the start date of the range
 * and the second is end date of the range.
 * The returned dates are automatically converted to the start of the given day (for start date) or end of
 * given day (for end date), so it's not necessary to explicitly call startOfDay/endOfDay on these values.
 *
 * The `@presetsOrder` argument controls the order in which the date presets are displayed in the dropdown.
 * The elements of this array should be string that correspond to the keys of `@presets`, and are used as
 * the display labels of the dropdown.
 *
 * If an item is present in `@presets` and not `@presetsOrder`, or vice versa, that range is not displayed
 * in the dropdown.
 */
type DateRangePresetFunc = (today: Date) => [Date, Date];
type DateRangePresets = { [key: string]: DateRangePresetFunc };

interface DateRangeSelectorWithPresetsArgs {
  onDatesChanged?: (startDate: Date, endDate: Date) => void;
  presets: DateRangePresets;
  presetsOrder: string[];
}

const DATE_FORMAT = 'yyyy-MM-dd';

function demomentify(date: Date | Moment): Date {
  if (date instanceof Date) {
    return date;
  }
  return moment(date).toDate();
}

export default class DateRangeSelectorWithPresets extends Component<DateRangeSelectorWithPresetsArgs> {
  @tracked showCalendar = false;
  #startDateChanged = false;

  @localCopy('args.startDate', startOfDay(new Date())) declare startDate: Date;
  @localCopy('args.endDate', endOfDay(new Date())) declare endDate: Date;

  get end(): Date {
    return endOfDay(max([this.startDate, this.endDate]));
  }
  set end(date: Date) {
    this.endDate = endOfDay(date);
  }

  get start(): Date {
    return startOfDay(min([this.startDate, this.endDate]));
  }
  set start(date: Date) {
    this.startDate = startOfDay(date);
  }

  get showDropdown(): boolean {
    return this.presetsOrder.length > 0;
  }

  get presets(): DateRangePresets {
    return Object.entries(this.args.presets ?? {}).reduce<DateRangePresets>((accum, [name, value]) => {
      if ((this.args.presetsOrder ?? []).includes(name)) {
        accum[name] = value;
      }
      return accum;
    }, {});
  }

  get presetsOrder(): string[] {
    const presetNames = Object.keys(this.args.presets ?? {});
    return (this.args.presetsOrder ?? []).filter((presetName) => presetNames.includes(presetName));
  }

  get dateRangeFilterOptions(): string[] {
    const options = this.presetsOrder;
    if (this.selectedDateRange === 'Custom') {
      return [...options, 'Custom'];
    }
    return options;
  }

  get selectedDateRange(): string {
    const today = new Date();
    for (const [option, func] of Object.entries(this.presets)) {
      let [rangeStart, rangeEnd] = func(today);
      rangeStart = startOfDay(rangeStart);
      rangeEnd = endOfDay(rangeEnd);
      if (isEqual(rangeStart, this.startDate) && isEqual(rangeEnd, this.endDate)) {
        return option;
      }
    }
    return 'Custom';
  }

  get endDateFormat(): string {
    return format(this.end, DATE_FORMAT);
  }

  get startDateFormat(): string {
    return format(this.start, DATE_FORMAT);
  }

  @action
  dateRangeSelected(/* startDate: Date | Moment, endDate: Date | Moment */): void {
    /*
     * Setting start & end dates is unnecessary here - <EnvoyTwoUpCalendar> just passes in whatever
     * was given to it as @startDate and @endDate, which come from this.startDate/this.endDate and are
     * updated by the setStartDate/setEndDate methods.
     */
    this.showCalendar = false;
    this.#onDatesChanged();
  }

  @action
  hideCalendar(): void {
    // If the calendar is being hidden and there's a change to the start date that we haven't
    // yet passed along to @onDatesChanged, do that now.
    if (this.showCalendar && this.#startDateChanged) {
      this.#onDatesChanged();
    }
    this.showCalendar = false;
  }

  @action
  toggleCalendar(): void {
    if (this.showCalendar) {
      this.hideCalendar();
    } else {
      this.showCalendar = true;
    }
  }

  @action
  selectPreset(option: string): void {
    const today = new Date();
    let start = today,
      end = today;
    const func = this.presets[option];
    if (func) {
      [start, end] = func(today);
    }
    this.start = startOfDay(start);
    this.end = endOfDay(end);
    this.args.onDatesChanged?.(start, end);
  }

  @action
  setEndDate(date: Date | Moment): void {
    this.end = demomentify(date);
  }

  @action
  setStartDate(date: Date | Moment): void {
    this.start = demomentify(date);
    this.#startDateChanged = true;
  }

  #onDatesChanged() {
    this.#startDateChanged = false;
    this.args.onDatesChanged?.(this.start, this.end);
  }
}

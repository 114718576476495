import { format } from 'date-fns';

function formatTimestampAbbreviated(timestamp: string): string {
  const date = new Date(timestamp);
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  });
}

function formatTimestamp(timestamp: string): string {
  const date = new Date(timestamp);
  return format(date, 'MMMM do, yyyy');
}

export { formatTimestamp, formatTimestampAbbreviated };

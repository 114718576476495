import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type { MessageType } from 'garaje/services/flash-messages';
import type LocalStorageService from 'garaje/services/local-storage';
import type MessageBusService from 'garaje/services/message-bus';
import type SigmaUrlService from 'garaje/services/sigma-url';
import type StateService from 'garaje/services/state';
import type UserFeatureFlagsService from 'garaje/services/user-feature-flags';
import urlBuilder from 'garaje/utils/url-builder';

type VisitorsGlobalMessage = {
  query?: {
    location_id?: string;
    company_id?: string;
    flow_id?: string;
    start?: string;
    end?: string;
  };
  label?: string;
  event:
    | 'showEntryExportModal'
    | 'showInviteExportModal'
    | 'closeExportModal'
    | 'showScheduleReportsModal'
    | 'showToaster'
    | 'closeScheduleReportsModal';
  type?: string; // optional, used when event is 'showToaster'
  msg?: string; // optional, used when event is 'showToaster'
};

export default class GlobalAnalyticsVisitorsController extends Controller {
  @service declare messageBus: MessageBusService;
  @service declare state: StateService;
  @service declare localStorage: LocalStorageService;
  @service declare flashMessages: FlashMessagesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare sigmaUrl: SigmaUrlService;
  @service declare userFeatureFlags: UserFeatureFlagsService;

  @tracked showModal = false;
  @tracked exportIframeUrl = '';

  constructor(properties: Record<string, unknown>) {
    super(properties);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.messageBus.on('embedded-app-message', this, this.handleMessage);
  }

  get iframeUrl(): Promise<string | undefined> {
    const companyId = this.state?.currentCompany?.id;

    if (!companyId) {
      return Promise.resolve(undefined);
    }

    return this.getSigmaIframeUrl(companyId);
  }

  get legacyIframeUrl(): string {
    const companyId = this.state?.currentCompany?.id;

    return urlBuilder.embeddedVisitorsGlobalAnalyticsUrl(companyId);
  }

  async getSigmaIframeUrl(companyId: string): Promise<string | undefined> {
    try {
      return this.sigmaUrl.getGlobalSigmaUrl(companyId, 'visitors');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error loading dashboard');
      // eslint-disable-next-line no-console
      console.error(e);
      return;
    }
  }

  @action
  toggleShowModal(): void {
    this.showModal = !this.showModal;
  }

  @action
  handleMessage(message: VisitorsGlobalMessage): void {
    const locationId = message.query?.location_id || '';
    const companyId = message.query?.company_id || '';
    const flowId = '';
    const start = message.query?.start || '';
    const end = message.query?.end || '';
    const label = message.label || '';

    if (message.event === 'showEntryExportModal') {
      this.exportIframeUrl = urlBuilder.embeddedEntryExportModalUrl(companyId, locationId, flowId, start, end, label);
      this.toggleShowModal();
    } else if (message.event === 'showInviteExportModal') {
      this.exportIframeUrl = urlBuilder.embeddedInviteExportModalUrl(companyId, locationId, flowId, start, end, label);
      this.toggleShowModal();
    } else if (message.event === 'showScheduleReportsModal') {
      this.exportIframeUrl = urlBuilder.embeddedScheduleReportsModalUrl(companyId, locationId, flowId, start, end);
      this.toggleShowModal();
    } else if (message.event === 'closeExportModal' || message.event === 'closeScheduleReportsModal') {
      this.toggleShowModal();
    } else if (message.event === 'showToaster') {
      const type = message.type as MessageType;
      const msg = message.msg || '';
      this.flashMessages.showAndHideFlash(type, msg);
    }
  }
}

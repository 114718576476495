import Service, { service } from '@ember/service';
import type Model from '@ember-data/model';
import type Store from '@ember-data/store';
import { pluralize } from 'ember-inflector';
import type BadgeModel from 'garaje/models/badge';
import type ConnectInviteModel from 'garaje/models/connect-invite';
import type InviteModel from 'garaje/models/invite';
import type PrinterModel from 'garaje/models/printer';
import type PropertyBadgeModel from 'garaje/models/property-badge';
import type PropertyPrinterModel from 'garaje/models/property-printer';
import urlBuilder from 'garaje/utils/url-builder';

import type AjaxService from './ajax';

type ModelPartial = { type: string; id: string };

export interface PrintOptions {
  badge: PropertyBadgeModel | BadgeModel | ModelPartial;
  printer: PrinterModel | PropertyPrinterModel | ModelPartial;
  visit: ConnectInviteModel | InviteModel | ModelPartial;
}

export default class BadgePrintingService extends Service {
  @service declare ajax: AjaxService;
  @service declare store: Store;

  async print(jobs: PrintOptions | PrintOptions[]): Promise<void> {
    jobs = Array.isArray(jobs) ? jobs : [jobs];

    const data = {
      data: jobs.map((job) => {
        return {
          type: 'badge-print-jobs',
          relationships: {
            badge: this.#getRelData(job.badge),
            printer: this.#getRelData(job.printer),
            visit: this.#getRelData(job.visit),
          },
        };
      }),
    };
    return this.ajax.post(urlBuilder.v3.printers.badgePrintJobs(), {
      headers: { accept: 'application/vnd.api+json' },
      contentType: 'application/vnd.api+json',
      data: data,
    });
  }

  #getRelData(model: Model | ModelPartial) {
    const type = 'isNew' in model ? (<typeof Model>model.constructor).modelName : model.type;
    return { data: { type: pluralize(`${type}`), id: model.id } };
  }
}

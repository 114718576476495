import Model, { attr } from '@ember-data/model';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { match } from '@ember/object/computed';

export default class InvoiceModel extends Model {
  @attr('string') pdf;
  @attr('string') status;
  @attr('boolean') refunded;
  @attr('date') date;
  @attr('number') amount;
  @attr('number') amountRefunded;

  @match('pdf', /billing\/v[1,2]\/payment_pdfs\/.+/) isChargebeeInvoice;

  /*
     TODO: Need to add
     @belongsTo('company') company;
     @hasMany('location') locations;
  */
}

import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { validatePresence } from 'ember-changeset-validations/validators';

/**
 * @type {object} SummaryPageChangeset validations
 */
const Validations = {
  message: validatePresence({ presence: true, ignoreBlank: true }),
};

/**
 * Changeset used to validate SummaryPageModels
 *
 * @class
 */
export class SummaryPageChangeset extends Changeset {
  constructor(model, validator = lookupValidator(Validations), validations = Validations) {
    super(model, validator, validations);
  }
}

/**
 * Factory for creating SummaryPageChangesets
 *
 * @returns {SummaryPageChangeset} Instance of a SummaryPageChangeset
 */
export default function () {
  return new SummaryPageChangeset(...arguments);
}

import ApplicationSerializer from './application';
import { service } from '@ember/service';

export default class GlobalIdScanPageSerializer extends ApplicationSerializer {
  @service featureFlags;

  modelNameFromPayloadKey(key) {
    if (key === 'flows') {
      return 'global-flow';
    } else if (key === 'id-scan-pages') {
      return 'global-id-scan-page';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }

  payloadKeyFromModelName(modelName) {
    if (modelName === 'global-flow') {
      return 'flows';
    } else if (modelName === 'global-id-scan-page') {
      return 'id-scan-pages';
    } else {
      return super.payloadKeyFromModelName(...arguments);
    }
  }

  serializeIntoHash(...args) {
    const [hash, , snapshot, options] = args;
    const payload = this.serialize(snapshot, options);

    if (!this.featureFlags.isEnabled('visitors-id-scan-images')) {
      Object.assign(hash, payload);
      delete payload.data.attributes['images-visible'];
      return hash;
    }

    return super.serializeIntoHash(...args);
  }
}

import { helper } from '@ember/component/helper';
import { fromUnixTime as _fromUnixTime } from 'date-fns';

/**
  Return the date given a timestamp.
  https://date-fns.org/v2.25.0/docs/format
  @method fromUnixTime
  @param {Number}                 unixTime    timestamp
  @return {Date}
  @public
*/
export function fromUnixTime(unixTime) {
  return _fromUnixTime(unixTime);
}

export default helper(fromUnixTime);

import { action } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { endOfDay, startOfDay } from 'date-fns';
import { task, timeout } from 'ember-concurrency';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type GroupModel from 'garaje/models/group';
import type VfdCallLogModel from 'garaje/models/vfd-call-log';
import { modifyDateInTimeZone } from 'garaje/utils/date-fns-tz-utilities';
import zft from 'garaje/utils/zero-for-tests';
import { reads } from 'macro-decorators';
import type Location from 'utils/location-record';

const DEFAULT_TIMEZONE = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'America/Los_Angeles';
const DEFAULT_LIMIT = 50;
const MAXIMUM_LIMIT = 200;

export interface VfdCallLogComponentSignature {
  Args: {
    endDate?: Date;
    groups?: GroupModel[];
    limit?: number;
    locations?: Location[];
    page?: number;
    selectedLocations?: string;
    sortBy?: string;
    sortDirection?: 'asc' | 'desc';
    startDate?: Date;
    timezone?: string;
    onDatesChanged?: (startDate: Date, endDate: Date) => void;
    onLocationsChanged?: (locationIds: string) => void;
    onPage?: (page: number) => void;
    onSort?: (sortBy: string, sortDirection: 'asc' | 'desc') => boolean | void;
  };

  Blocks: {
    default: [];
    empty?: [];
  };

  Element: HTMLElement;
}

export default class VfdCallLogComponent extends Component<VfdCallLogComponentSignature> {
  @service declare store: StoreService;

  @tracked limit = 50;
  @tracked page = 1;

  @tracked callLogTotal = 0;
  @tracked sortBy: string;
  @tracked sortDirection: 'asc' | 'desc';
  @tracked endDate: Date | null;
  @tracked startDate: Date | null;
  @tracked selectedLocations = '';

  @reads('args.timezone', DEFAULT_TIMEZONE) timezone!: string;

  constructor(owner: VfdCallLogComponent, args: VfdCallLogComponentSignature['Args']) {
    super(owner, args);

    this.sortBy = args.sortBy || 'started-at';
    this.sortDirection = args.sortDirection || 'desc';
    this.endDate = args.endDate || null;
    this.startDate = args.startDate || null;
    this.selectedLocations = args.selectedLocations || '';

    // Min 1 per page; Max 200 per page; default 50 per page
    this.limit = Math.min(Math.max(Number(args.limit), 0), MAXIMUM_LIMIT) || DEFAULT_LIMIT;

    // Replace nonsense page numbers with 1
    this.page = Math.max(Number(args.page), 1) || 1;
  }

  get loading(): boolean {
    const { isRunning, performCount } = this.loadCallLogTask;

    return performCount === 0 ? true : isRunning;
  }

  get loaderCount(): number {
    const { callLogTotal, limit } = this;

    return callLogTotal > 0 ? Math.min(callLogTotal, limit) : limit;
  }

  get empty(): boolean {
    const { loading, callLogTotal } = this;

    return loading ? false : callLogTotal === 0;
  }

  get startDateFilter(): string {
    const { timezone, startDate } = this;

    if (startDate) return startDate.toJSON();

    return modifyDateInTimeZone(new Date(), timezone, startOfDay).toJSON();
  }

  get endDateFilter(): string {
    const { timezone, endDate } = this;

    if (endDate) return endDate.toJSON();

    const result = modifyDateInTimeZone(new Date(), timezone, endOfDay);

    return result.toJSON();
  }

  get sort(): string {
    const { sortDirection, sortBy } = this;
    const sortAttrs = [sortDirection === 'asc' ? sortBy : `-${sortBy}`];

    // Fallback to `started-at` (desc) as a secondary sort param
    if (sortBy !== 'started-at') sortAttrs.push('-started-at');

    return sortAttrs.join(',');
  }

  get pageIndicator(): string {
    const { limit, page, callLogTotal } = this;
    const start = (page - 1) * limit + 1;
    const end = Math.min(callLogTotal, page * limit);

    return `${start}–${end} of ${callLogTotal}`;
  }

  get isPreviousPage(): boolean {
    return this.page > 1;
  }

  get isNextPage(): boolean {
    return this.page < this.maximumPage;
  }

  get maximumPage(): number {
    return Math.ceil(this.callLogTotal / this.limit);
  }

  @action
  updateDateRange(startDate: Date, endDate: Date): void {
    this.startDate = startDate;
    this.endDate = endDate;

    this.args.onDatesChanged?.(this.startDate, this.endDate);
    this.setPage(1);

    void this.loadCallLogTask.perform();
  }

  @action
  updateSelectedLocations(options: { id: string }[]): void {
    const selectedIds = this.selectedLocations.split(',').filter(isPresent);
    const optionIds = options.map((o) => o.id);

    // Finding the Symmetric Difference has a toggling effect.
    // Options that are already selected will be deselected.
    // Options that are not in the current selection will be added.
    this.selectedLocations = [
      ...selectedIds.filter((x) => !optionIds.includes(x)),
      ...optionIds.filter((x) => !selectedIds.includes(x)),
    ].join(',');

    this.args.onLocationsChanged?.(this.selectedLocations);
    this.setPage(1);

    void this.loadCallLogTask.perform();
  }

  @action
  sortCallLog(sortBy: string, sortDirection: 'asc' | 'desc'): void {
    if (this.args.onSort?.(sortBy, sortDirection) === false) return;

    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
    this.setPage(1);

    void this.loadCallLogTask.perform();
  }

  @action
  gotoPreviousPage(): void {
    this.setPage(Math.max(this.page - 1, 1));

    void this.loadCallLogTask.perform();
  }

  @action
  gotoNextPage(): void {
    this.setPage(Math.min(this.page + 1, this.maximumPage));

    void this.loadCallLogTask.perform();
  }

  gotoLastPage(): void {
    this.setPage(Math.max(this.maximumPage, 1));

    void this.loadCallLogTask.perform();
  }

  setPage(page: number): void {
    this.page = page;
    this.args.onPage?.(this.page);
  }

  loadCallLogTask = task({ restartable: true }, async (): Promise<PaginatedRecordArray<VfdCallLogModel>> => {
    // Slow down for rapid page/filter switching
    if (this.callLogTotal > 0) await timeout(zft(200));

    const { limit, page, sort, selectedLocations } = this;
    const offset = (page - 1) * limit;
    const filter: { [key: string]: string } = {};
    const include = 'transcription';

    filter['start-datetime'] = this.startDateFilter;
    filter['end-datetime'] = this.endDateFilter;
    if (isPresent(selectedLocations)) filter['location'] = selectedLocations;

    const params: { [key: string]: unknown } = {
      filter,
      include,
      page: { offset, limit },
    };

    if (sort) params['sort'] = sort;

    const calls = <PaginatedRecordArray<VfdCallLogModel>>await this.store.query('vfd-call-log', params);

    this.callLogTotal = calls.meta.total;

    if (this.maximumPage && this.page > this.maximumPage) this.gotoLastPage();

    return calls;
  });
}

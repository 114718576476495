import CompanyFragment from 'garaje/graphql/fragments/roomba/CompanyFragment';
import gql from 'graphql-tag';

export default gql`
  mutation UpdateCompanyConfig($input: CompanyConfigInput!) {
    updateCompanyConfig(input: $input) {
      ...CompanyFragment
    }
  }
  ${CompanyFragment}
`;

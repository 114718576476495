import Component from '@glimmer/component';
import { action } from '@ember/object';
import { formatMinutesAsTime, getMinutesInDayFromTime, TIMES } from 'garaje/utils/format-minutes-as-time';

/**
 * @param {Boolean}            isPushNotificationEnabled    Indicate the setting is toggled "on"
 * @param {Boolean}            isDisabled                   Indicate the inputs are disabled
 * @param {Function}           onToggle                     Action to call when toggle flipped
 * @param {Function}           onUpdateTime                 Action to call when time selection modified
 * @param {Integer}            minutesSinceMidnight         Time of day in number of minutes since midnight
 */
export default class ProtectSettingsPushNotificationsTimedReminderComponent extends Component {
  defaultTime = '12:00 am';
  times = TIMES;

  get time() {
    const minutesInDay = this.args.minutesSinceMidnight ?? getMinutesInDayFromTime(this.defaultTime);

    return formatMinutesAsTime(minutesInDay);
  }

  @action
  setTime(time) {
    const minutesInDay = getMinutesInDayFromTime(time);

    this.args.onUpdateTime(minutesInDay);
  }
}

import Component from '@glimmer/component';
import { action, set } from '@ember/object';

/**
 * @param {Model}               section
 * @param {Function}            onEdit
 * @param {Boolean}             isDisabled
 */
export default class VisitorEmailsVisitorGuideVgEditingVgSection extends Component {
  @action
  toggleEnabled() {
    set(this.args.section, 'enabled', !this.args.section.enabled);
    this.args.onEdit();
  }

  @action
  updateBody({ target: { value } }) {
    set(this.args.section, 'body', value);
    this.args.onEdit();
  }
}

/**
 *  Gives store access to the serializer `normalizeResponse` method to properly normalize responses with embedded records
 *
 * @param {Store}  store     - ember-data Store service
 * @param {String} modelName - name of model
 * @param {*}      payload   - response payload
 * @param {String} method    - method used to fetch the data
 * @returns {Record<string, unknown>} normalized response
 */
export default function normalizeResponse(store, modelName, payload, method = 'findRecord') {
  const serializer = store.serializerFor(modelName);
  return serializer.normalizeResponse(store, store.modelFor(modelName), payload, payload.data['id'], method);
}

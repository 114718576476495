import DesksOnFloorFragment from 'garaje/graphql/fragments/employee-schedule/DesksOnFloorFragment';
import gql from 'graphql-tag';
// import DeskWithAmenitiesFragment from 'garaje/graphql/fragments/DeskWithAmenitiesFragment';

export default gql`
  query DesksOnFloorForLocation(
    $locationId: ID!
    $floorId: ID!
    $startTime: DateTime!
    $endTime: DateTime!
    $filters: [DeskStatusEnum!]!
  ) {
    desksOnFloorForLocation(
      locationId: $locationId
      floorId: $floorId
      startTime: $startTime
      endTime: $endTime
      filters: $filters
    ) {
      ...DesksOnFloorFragment
    }
  }
  ${DesksOnFloorFragment}
`;

import fetch from 'fetch';
import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import config from 'garaje/config/environment';

export default class extends Service {
  @service cookies;

  @task
  *send(operations) {
    const csrfToken = this.cookies.read('csrf_token');
    const headers = {
      'Content-Type': 'application/vnd.api+json',
      'X-CSRF-Token': csrfToken,
    };

    const request = yield fetch(`${config.apiHost}/api/v3/operations`, {
      credentials: 'include',
      headers,
      method: 'POST',
      body: JSON.stringify({
        'atomic:operations': operations,
      }),
    });

    if (request.status !== 200 && request.status !== 204) {
      const json = yield request.json();
      throw json;
    }
  }

  removeOperation(model, modelName = '') {
    if (!modelName) {
      modelName = model.constructor.modelName;
    }

    return {
      op: 'remove',
      ref: {
        type: modelName,
        id: model.id,
      },
    };
  }
}

import Component from '@glimmer/component';
import { formatInTimeZone } from 'date-fns-tz';
import type ConnectionRequestInviteModel from 'garaje/models/connection-request-invite';
import type TenantModel from 'garaje/models/tenant';
import type ZoneModel from 'garaje/models/zone';

interface TenantCardComponentArgs {
  tenant: TenantModel;
  property: ZoneModel;
  confirmDeleteTenant: (tenant: TenantModel) => unknown;
  confirmDisconnectTenant: (tenant: TenantModel) => unknown;
  generateToken: (tenant: TenantModel) => unknown;
  inviteTenant: (tenant: TenantModel) => unknown;
}

const DATE_TIME_FORMAT = "MM/dd/yyyy 'at' hh:mmaaa z";

export default class TenantCardComponent extends Component<TenantCardComponentArgs> {
  get latestInvite(): ConnectionRequestInviteModel | undefined {
    return this.args.tenant.tenantConnectionRequest?.latestInvite;
  }

  get connectedAt(): string | undefined {
    return this.args.tenant.tenantConnectionRequest?.connectedAt
      ? formatInTimeZone(
          this.args.tenant.tenantConnectionRequest.connectedAt,
          this.args.property.timezone,
          DATE_TIME_FORMAT,
        )
      : undefined;
  }

  get connectionRequestUpdatedAt(): string | undefined {
    return this.latestInvite?.updatedAt
      ? formatInTimeZone(this.latestInvite.updatedAt, this.args.property.timezone, DATE_TIME_FORMAT)
      : undefined;
  }

  get expiresAt(): string | undefined {
    return this.args.tenant.tenantConnectionRequest?.expiresAt
      ? formatInTimeZone(
          this.args.tenant.tenantConnectionRequest.expiresAt,
          this.args.property.timezone,
          DATE_TIME_FORMAT,
        )
      : undefined;
  }

  get connectionRequestEmail(): string | undefined {
    return this.latestInvite?.email;
  }
}

import Service from '@ember/service';
import { setProperties } from '@ember/object';
import { task } from 'ember-concurrency';
import { defer } from 'rsvp';

export default class TransitionConfirmService extends Service {
  /**
   * Manage the state of transition confirmation modal to
   *   1. abort transition
   *   2. show a confirmation modal that has two buttons
   * Cancel button: close the confirmation modal, and user stay on current route
   * Continue button: close the confirmation modal, and continue the transition
   * Confirmation modal component is {{transition-confirm-modal}}
   *
   * If no transition is passed, this task acts as a double confirmation
   * allowing the user to re-confirm a decision when a transition to a new route is not applicable
   * For example, closing a settings panel which will rollback any existing changes
   *
   * Usage:
   *
   * In willTransition, perform "displayConfirmTask", and pass in the intended transition and optional options
   *
   * @param {Class<Transition>} transition optional. when null, a double confirmation task
   * @param {Object} options all options are optional
   *   header: header text of the modal
   *   body: body text of the modal
   *   confirmText: button confirmation text
   *   cancelText: button cancellation text
   *   abort: action to perform when user click cancel button
   *   continue: action to perform when user click continue button
   **/
  @task
  displayConfirmTask = {
    headerMessage: null,
    bodyMessage: null,
    confirmText: null,
    cancelText: null,

    *perform(transition, options = {}) {
      transition?.abort?.();

      setProperties(this, {
        headerMessage: options.header,
        bodyMessage: options.body,
        confirmText: options.confirmText,
        cancelText: options.cancelText,
      });

      const deferred = defer();

      // this action will be called when user click "cancel"
      this.abort = () => {
        deferred.resolve();
        options.abort?.();
      };

      // this action will be called when user click "continue"
      this.continue = () => {
        deferred.resolve();
        options.continue?.();

        // continue transition
        transition?.retry?.();
      };
      return yield deferred.promise;
    },
  };
}

import Model, { attr } from '@ember-data/model';

export default class AbstractAgreementPageModel extends Model {
  @attr('boolean') declare enabled: boolean;
  @attr('string') declare name: string;
  @attr('string') declare bccEmail: string;
  @attr('number') declare duration: number;
  @attr('boolean', { defaultValue: false }) declare optional: boolean;
  @attr('boolean', { defaultValue: false }) declare requireResign: boolean;
  @attr('boolean', { defaultValue: false }) declare sendToBcc: boolean;
  @attr('immutable', { defaultValue: () => ({ body: {} }) }) declare customTranslations: {
    body: Record<string, string>;
  };
}

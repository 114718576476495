import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type AbilitiesService from 'ember-can/services/abilities';

export default class NeighborhoodsDropdown extends Component {
  @service declare abilities: AbilitiesService;

  @action
  showManageLabel(neighborhoodId: string): boolean {
    if (this.abilities.can('edit maps')) {
      return false;
    }

    const mapAbility = this.abilities.abilityFor('map') as { editableNeighborhoodIds: string[] };

    return mapAbility?.editableNeighborhoodIds?.includes(neighborhoodId);
  }
}

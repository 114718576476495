import ImmutableTransform from 'garaje/transforms/immutable';
import { typeOf } from '@ember/utils';
import _identity from 'lodash/identity';

export default class ImmutableArrayTransform extends ImmutableTransform {
  deserialize(serialized, options = {}) {
    const deserializeItem = options.deserializeItem || _identity;
    let items = serialized;
    if (typeOf(items) === 'array') {
      items = serialized.map((item) => Object.freeze(deserializeItem(item)));
    }
    return super.deserialize(items);
  }

  serialize(deserialized, options = {}) {
    const serializeItem = options.serializeItem || _identity;
    let items = deserialized;
    if (typeOf(items) === 'array') {
      items = deserialized.map((item) => serializeItem(item));
    }
    return super.serialize(items);
  }
}

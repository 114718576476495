import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type ZoneModel from 'garaje/models/zone';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';

interface BlockListModel {
  hasUnsavedChanges: boolean;
  property: ZoneModel;
}

export default class PropertyVisitorsBlocklistIndexRoute extends Route {
  @service declare transitionConfirm: TransitionConfirmService;

  model(): BlockListModel {
    return {
      hasUnsavedChanges: false,
      property: <ZoneModel>this.modelFor('property.visitors'),
    };
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    // don't do anything when entering this route
    if (transition.to.name === this.routeName) return;

    const model = <BlockListModel>this.modelFor(this.routeName);

    // if we're leaving the page without any unsaved changes, let transition happen normally
    if (!model.hasUnsavedChanges) return;

    // otherwise, user changed something; prompt for confirmation
    void this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        // set "hasUnsavedChanges" to false so we don't end up back here when the transition gets retried
        model.hasUnsavedChanges = false;
      },
    });
  }
}

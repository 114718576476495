import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type { GetCompanyQuery, GetRoomsQuery } from 'garaje/graphql/generated/roomba-types';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import type StateService from 'garaje/services/state';

interface RoombaSettingsPermissionsRouteModel {
  company: GetCompanyQuery['company'];
  rooms: GetRoomsQuery['rooms'];
}

export default class RoombaSettingsPermissionsRoute extends Route {
  @service declare roombaGraphql: RoombaGraphqlService;
  @service declare state: StateService;

  async model(): Promise<RoombaSettingsPermissionsRouteModel> {
    const company = await this.roombaGraphql.getCompany();
    const rooms = await this.roombaGraphql.getRooms(this.state.currentLocation?.id);

    return {
      company,
      rooms,
    };
  }
}

import RegistrationDateFragment from 'garaje/graphql/fragments/employee-schedule/RegistrationDateFragment';
import gql from 'graphql-tag';

export default gql`
  query EmployeeRegistrationDates(
    $locationId: ID!
    $startDate: DateTime!
    $endDate: DateTime!
    $includeEmployeeInvite: Boolean!
  ) {
    employeeRegistration(locationId: $locationId, startDate: $startDate, endDate: $endDate) {
      registrationDates {
        ...RegistrationDateFragment
      }
    }
  }
  ${RegistrationDateFragment}
`;

import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { type DetailedChangeset } from 'ember-changeset/types';
import { type Task } from 'ember-concurrency';
import type ConnectFloorModel from 'garaje/models/connect-floor';

type FloorChangeset = DetailedChangeset<ConnectFloorModel>;

interface ConnectFloorFormArgs {
  floorChangeset: FloorChangeset;
  saveButtonLabel?: string;
  saveTask: Task<void, [FloorChangeset]>;
}

export default class ConnectFloorForm extends Component<ConnectFloorFormArgs> {
  get isSaveButtonDisabled(): boolean {
    return this.args.saveTask.isRunning || isEmpty(this.args.floorChangeset.name) || !this.args.floorChangeset.isDirty;
  }

  get saveButtonLabel(): string {
    return this.args.saveButtonLabel ?? 'Save';
  }
}

import Helper from '@ember/component/helper';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';

export default class LocalOrGlobalRouteName extends Helper {
  @service declare router: RouterService;

  compute([routeName]: [routeName: string]): string {
    const isGlobalOverview = this.router.currentRouteName?.includes('location-overview');
    return isGlobalOverview ? `location-overview.${routeName}` : routeName;
  }
}

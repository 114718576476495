import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { dropTask, task } from 'ember-concurrency';
import { APP } from 'garaje/utils/enums';
import { capitalize } from '@ember/string';
import { isPresent, isEmpty } from '@ember/utils';
import { subDays } from 'date-fns';
import { allSettled } from 'rsvp';
import _cloneDeep from 'lodash/cloneDeep';

const FEATURES = [
  {
    id: 'visitor-registration',
    label: 'Visitor registration',
    description: 'See who’s visiting and why',
    svg: '/assets/images/multi-product/visitor-registration.svg',
    product: APP.VISITORS,
  },
  {
    id: 'workplace-schedule',
    label: 'Workplace schedules',
    description: 'See when employees come in',
    svg: '/assets/images/multi-product/workplace-schedule.svg',
    product: APP.VISITORS,
  },
  {
    id: 'health-and-safety',
    label: 'Health & safety',
    description: 'Manage vaccine and test docs',
    svg: '/assets/images/multi-product/health-and-safety.svg',
    product: APP.VISITORS,
  },
  {
    id: 'desk-booking',
    label: 'Desk booking',
    description: 'Assign desks and/or hot desks',
    svg: '/assets/images/multi-product/desk-booking.svg',
    product: APP.DESKS,
  },
  {
    id: 'room-booking',
    label: 'Conference rooms',
    description: 'Schedule or book on demand',
    svg: '/assets/images/multi-product/room-booking.svg',
    product: APP.ROOMS,
  },
  {
    id: 'office-deliveries',
    label: 'Office deliveries',
    description: 'Notify employees of deliveries',
    svg: '/assets/images/multi-product/office-deliveries.svg',
    product: APP.DELIVERIES,
  },
];

const PRODUCTS = [
  {
    id: APP.VISITORS,
    label: 'Visitors & Protect',
    description: 'See who’s visiting and why',
    svg: `/assets/images/app-icons/visitors.svg`,
    order: 0,
  },
  {
    id: APP.DESKS,
    label: 'Desks',
    description: 'Assign desks and/or hot desks',
    svg: `/assets/images/app-icons/desks.svg`,
    order: 1,
  },
  {
    id: APP.ROOMS,
    label: 'Rooms',
    description: 'Schedule or book on demand',
    svg: `/assets/images/app-icons/rooms.svg`,
    order: 2,
  },
  {
    id: APP.DELIVERIES,
    label: 'Deliveries',
    description: 'Notify employees of deliveries',
    svg: `/assets/images/app-icons/deliveries.svg`,
    order: 3,
  },
];

const CTA_PROPERTIES = {
  cta_id: 'multi-product-sign-up',
  growth_team_project: true,
  experiment: 'multi-product-sign-up',
};

/**
 * @param {Function} onClose
 */
export default class MultiProductModal extends Component {
  @service metrics;
  @service flashMessages;
  @service state;
  @service productActivation;
  @service impressions;

  @tracked selectedFeatures = [];
  @tracked selectedProducts = [];
  @tracked originalRecommendedProducts = null;
  @tracked step = 1;

  features = FEATURES;
  products = PRODUCTS;

  get ctaSelections() {
    return this.step == 1
      ? { 'features-selected': this.selectedFeatures.mapBy('label') }
      : { 'trials-selected': this.selectedProducts.mapBy('label') };
  }

  @action
  toggleItem(list, item) {
    if (!list.includes(item)) {
      list.pushObject(item);
      if (this.step == 2 && !this.originalRecommendedProducts.includes(item)) {
        this.logItemToggled.perform('selected', item);
      }
    } else {
      list.removeObject(item);
      if (this.step == 2 && this.originalRecommendedProducts.includes(item)) {
        this.logItemToggled.perform('deselected', item);
      }
    }
  }

  @action
  recommendProducts() {
    this.selectedProducts = this.selectedFeatures
      .mapBy('product')
      .uniq()
      .map((productId) => this.products.findBy('id', productId));

    this.originalRecommendedProducts = _cloneDeep(this.selectedProducts);
    this.step = 2;
  }

  @task
  *logItemToggled(action, item) {
    yield this.impressions.postImpression.perform('mult-product-trial-selected ' + item.label + ' ' + action);
  }

  @action
  logViewed() {
    this.metrics.trackEvent('CTA Viewed', {
      ...CTA_PROPERTIES,
      cta_type: 'modal',
    });
  }

  @action
  logClicked(event) {
    const buttonText = event.target.textContent.trim();
    this.metrics.trackEvent('CTA Clicked', {
      ...CTA_PROPERTIES,
      ...this.ctaSelections,
      cta_type: 'modal',
      cta_clickable_type: 'button',
      cta_clickable_text: buttonText,
    });
  }

  @action
  logDismissed(event) {
    const buttonText = event.target.textContent.trim();
    this.metrics.trackEvent('CTA Dismissed', {
      ...CTA_PROPERTIES,
      ...this.ctaSelections,
      cta_type: 'modal',
      cta_clickable_type: 'button',
      cta_clickable_text: buttonText,
    });
  }

  @task
  *logSelectionsToGrowthService() {
    for (var selected of this.selectedFeatures) {
      yield this.impressions.postImpression.perform('multi-product-feature-selected ' + selected.id);
    }
  }

  @dropTask
  *startTrialsTask() {
    try {
      if (this.selectedProducts.isAny('id', APP.DESKS) && !this.selectedProducts.isAny('id', APP.VISITORS)) {
        this.selectedProducts.push(PRODUCTS.findBy('id', APP.VISITORS));
      }

      const productPromises = this.selectedProducts
        .sortBy('order')
        .map(({ id }) => this.startProductTrialTask.perform(id));

      const [trialsSuccessful, trialsFailed] = yield allSettled(productPromises).then((promises) => {
        return [
          promises.filterBy('state', 'fulfilled').mapBy('value'),
          promises.filterBy('state', 'rejected').mapBy('reason.message'),
        ];
      });

      this.logSelectionsToGrowthService.perform();

      const successMessages = `Started trials for: ${trialsSuccessful.join(', ')}.`;
      const failedMessages = isPresent(trialsFailed) ? `Failed to start trials for: ${trialsFailed.join(', ')}. ` : '';

      this.flashMessages.showAndHideFlash(
        isEmpty(trialsFailed) ? 'success' : 'warning',
        `${failedMessages}${successMessages}`,
      );
      yield this.state.initSubscriptionStateTask.perform();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error starting trials.');
    }
  }

  @task({ enqueue: true })
  *startProductTrialTask(product) {
    try {
      const { app, trialDaysLeft, trialEndDate, plan } =
        yield this.productActivation[`start${capitalize(product)}TrialTask`].perform();
      this.metrics.trackEvent('Started trial', {
        cta_type: 'modal',
        app,
        trial_tier_plan: plan,
        trial_start_date: subDays(trialEndDate, trialDaysLeft),
        trial_end_date: trialEndDate,
        ...CTA_PROPERTIES,
      });

      return product;
    } catch (e) {
      throw new Error(product);
    }
  }
}

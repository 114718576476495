import RSVP from 'rsvp';
import { validatePresence } from 'ember-changeset-validations/validators';

export default function buildGroupInviteValidations(options = {}) {
  const defaults = {
    hostFieldIsRequired: false,
    checkEmailRequired: false,
    store: null,
  };

  options = Object.assign({}, defaults, options);

  const validations = {
    flow(_key, _newValue, _oldValue, changes, content) {
      if (!content.flow && !changes?.flow) {
        return 'This is a required field';
      }

      return true;
    },
    employee(key, newValue) {
      return RSVP.resolve(newValue).then((employee) => {
        if (options.hostFieldIsRequired && !employee) {
          return 'Employee is required';
        }

        return true;
      });
    },
    expectedArrivalTime: [
      validatePresence({
        presence: true,
        message: 'This is a required field',
      }),
    ],
    groupName: [
      validatePresence({
        presence: true,
        message: 'This is a required field',
      }),
    ],
  };

  return validations;
}

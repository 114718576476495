import { action } from '@ember/object';
import Component from '@glimmer/component';
import type PrinterModel from 'garaje/models/printer';

interface GlobalDevicesPrintersPrinterRowComponentSignature {
  Args: {
    printer: PrinterModel;
    isSelected: boolean;
    unselectPrinter: (printer: PrinterModel) => void;
    selectPrinter: (printer: PrinterModel) => void;
    goToPrinterPage: () => void;
    goToIPadPage: () => void;
  };
}

export default class GlobalDevicesPrintersPrinterRowComponent extends Component<GlobalDevicesPrintersPrinterRowComponentSignature> {
  @action
  onPrinterCheckboxClick(event: Event): void {
    const printer = this.args.printer;

    if ((<HTMLInputElement>event.target).checked) {
      // add printer when user select the printer
      this.args.selectPrinter(printer);
    } else {
      // remove printer from selected printer
      this.args.unselectPrinter(printer);
    }
  }
}

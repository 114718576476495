import Component from '@glimmer/component';
import type { VfdContactMethodSlackMetadata } from 'garaje/models/vfd-contact-method';

type VirtualFrontDeskLocationsTableRowSlackChannelComponentArgs = {
  channel: VfdContactMethodSlackMetadata | null;
};

export default class VirtualFrontDeskLocationsTableRowSlackChannelComponent extends Component<VirtualFrontDeskLocationsTableRowSlackChannelComponentArgs> {
  get channelName(): string | null {
    if (this.args.channel) {
      return '#' + this.args.channel['channel-name'];
    }
    return null;
  }
}

import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';

// This controls what companies are allow to do with regards to
// external notifications. Primarily  to protect against spam / abuse
export default class ExternalNotificationAbility extends Ability {
  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;

  get canCustomizeSms(): boolean {
    const allowTextMods = this.featureFlags.isEnabled('allow-host-notification-text-mods-during-trial', {
      // eslint-disable-next-line ember/use-ember-get-and-set
      createdAt: this.state.currentCompany.createdAt?.getTime() ?? 0,
    });

    if (allowTextMods) return true;
    return false;
  }

  get canCustomizeEmail(): boolean {
    const allowEmailMods = this.featureFlags.isEnabled('email-customization', {
      // eslint-disable-next-line ember/use-ember-get-and-set
      createdAt: this.state.currentCompany.createdAt?.getTime() ?? 0,
    });

    if (allowEmailMods) return true;
    return false;
  }
}

import Controller from '@ember/controller';
import { action, get } from '@ember/object';

export default class DeliveriesLogDeliverySignOutPhotoController extends Controller {
  @action
  close() {
    const delivery = get(this.model, 'delivery');
    this.transitionToRoute('deliveries.log.delivery', get(delivery, 'id')); // eslint-disable-line ember/no-deprecated-router-transition-methods
  }
}

import Route from '@ember/routing/route';
import { findAllDeliveryAreasForLocation, findAllDeliveryAreasForMultipleLocations } from 'garaje/utils/delivery-area';
import { get } from '@ember/object';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';

export default class DeliveriesGetStartedRoute extends Route {
  @service locations;
  @service state;
  @service router;
  @service store;

  beforeModel() {
    const { currentLocation } = this.state;

    return findAllDeliveryAreasForLocation(this.store, currentLocation).then((deliveryAreas) => {
      if (get(deliveryAreas, 'length') > 0) {
        return this.router.transitionTo('deliveries.index');
      }
    });
  }

  model() {
    const activeLocation = this.findActiveLocation.perform();
    const { currentLocation, deliveriesSubscription } = this.state;

    return hash({ activeLocation, currentLocation, deliveriesSubscription });
  }

  @task({ cancelOn: 'deactivate' })
  *findActiveLocation() {
    const locations = get(this, 'locations.model').toArray();

    const deliveryAreas = yield findAllDeliveryAreasForMultipleLocations(this.store, locations);

    if (isPresent(deliveryAreas)) {
      const activeLocation = yield get(deliveryAreas, 'firstObject.location');
      return activeLocation;
    }
  }
}

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { TRUTHY } from 'garaje/utils/enums';
import naturalCompare from 'natural-compare';
import { inject as service } from '@ember/service';

const STATIC_COL_HEADERS_WITH_AMENITIES = ['Desk number', 'Neighborhood', 'Enabled', 'Assigned to', 'Amenities'];
const STATIC_COLUMNS_WITH_AMENITIES = [
  {
    data: 'name',
    type: 'text',
    readOnly: true,
  },
  {
    data: 'neighborhood',
    type: 'text',
    readOnly: true,
  },
  {
    data: 'enabled',
    type: 'dropdown',
    strict: true,
    allowInvalid: false,
    source: [TRUTHY.YES, TRUTHY.NO],
    readOnly: true,
  },
  {
    data: 'assignedTo',
    type: 'text',
    readOnly: true,
  },
  {
    data: 'amenities',
    type: 'text',
    readOnly: true,
  },
];

/**
 * @param {Array<Desk>} desks Required. List of desks on this floor
 * @param {Class<Handsontable>} handsontable Required. Handsontable table generator library
 * @param {Function} toggleEnabledTask Required. Task to handle the user changing a desk's enabled state via the dropdown
 */
export default class FloorTable extends Component {
  @service featureFlags;
  @service store;

  colHeaders = STATIC_COL_HEADERS_WITH_AMENITIES;
  columns = STATIC_COLUMNS_WITH_AMENITIES;

  get desksData() {
    return this.args.desks
      .map((desk) => {
        const amenities = desk.amenities.map((amenity) => amenity.name);

        return {
          name: desk.name,
          neighborhood: desk.neighborhoodName,
          enabled: desk.enabled ? TRUTHY.YES : TRUTHY.NO,
          assignedTo: desk.assignedTo,
          amenities: amenities.join(', '),
        };
      })
      .sort((a, b) => naturalCompare(a.name, b.name));
  }

  @action
  registerInstance(table) {
    this.hotInstance = table;
  }

  /**
   * @param {Array<Array>} changes 2D array containing list of changed cells. For now, can only update one row at a time (only care about first change)
   *                               Note: For performance reasons, the changes array is null for "loadData" source.
   */
  @action
  afterChange(hotInstance, changes) {
    if (changes) {
      const [row, prop, previousValue, newValue] = changes[0];
      const { id: deskId } = this.args.desks[row];

      // For beta, enabled is the only editable part of desk outside of csv uploading
      if (previousValue !== newValue && prop === 'enabled') {
        const enabled = newValue === TRUTHY.YES;
        this.args.toggleEnabledTask.perform(deskId, enabled);
      }
    }
  }
}

import Component from '@glimmer/component';
import type { GetCompanyQuery, GetRoomsQuery } from 'garaje/graphql/generated/roomba-types';

type RoomName = RoombaSettingsExcludedRoomsComponentArgs['rooms'][number]['name'];

interface RoombaSettingsExcludedRoomsComponentArgs {
  company: GetCompanyQuery['company'];
  rooms: GetRoomsQuery['rooms'];
  subtext?: string;
  alreadyExcludedRooms: GetRoomsQuery['rooms'];
  onSave: (selectedRooms: GetRoomsQuery['rooms']) => void;
}

export default class RoombaSettingsExcludedEmailsComponent extends Component<RoombaSettingsExcludedRoomsComponentArgs> {
  get validationOptions(): { allowBlank: boolean; in: RoomName[] } {
    return {
      allowBlank: false,
      in: this.args.rooms.map((room) => room.name),
    };
  }
}

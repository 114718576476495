import Component from '@glimmer/component';
import { action } from '@ember/object';
import { format } from 'date-fns';
import { DATE_FORMAT } from 'garaje/models/abstract/abstract-document';

export default class UserDocumentInputFieldComponent extends Component {
  max = format(new Date(), DATE_FORMAT);

  @action
  updateInputField(value) {
    const { inputField, onChange } = this.args;
    inputField.value = value;
    onChange && onChange(inputField.value);
  }
}

import { helper } from '@ember/component/helper';

/**
 * @returns {object} Returns the total count of keys on the object
 */
export function count(obj) {
  return Object.keys(obj).length;
}

export default helper(([obj]) => count(obj));

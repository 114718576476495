import Route from '@ember/routing/route';
import { service } from '@ember/service';
import RSVP from 'rsvp';

class EmployeesAdminRolesSingleDeleteRoute extends Route {
  @service abilities;
  @service router;
  @service store;

  beforeModel() {
    if (this.abilities.cannot('manage admin-roles')) {
      this.router.transitionTo('unauthorized');
    }
  }

  model({ user_id }) {
    const user = this.store.findRecord('user', user_id, { reload: true });
    const roleAssignments = this.store.query('role-assignment', { filter: { 'user-id': user_id } });
    return RSVP.hash({ user, roleAssignments });
  }
}

export default EmployeesAdminRolesSingleDeleteRoute;

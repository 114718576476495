import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { inject as service } from '@ember/service';

import config from 'garaje/config/environment';

export default class extends JSONAPIAdapter {
  namespace = 'a/visitors/api/v3';
  host = config.apiOverwrittenHost;
  useFetch = false;

  authorizer = 'authorizer:application';
  @service cookieAuth;
  @service cookies;
  @service session;

  handleResponse(status, _headers, _payload, _requestData) {
    if (status === 401 && this.session.isAuthenticated) {
      this.session.invalidate();
    }
    return super.handleResponse(...arguments);
  }

  shouldReloadAll() {
    return true;
  }

  shouldBackgroundReloadRecord() {
    return false;
  }

  ajaxOptions(url, type, options = {}) {
    let hash = super.ajaxOptions(url, type, options);
    hash = this.cookieAuth.decorate(type, hash);

    hash.headers['X-Forwarded-Host'] = config.hostName;
    if (this.cookies.read('X-Request-ID')) {
      hash.headers['X-Request-ID'] = this.cookies.read('X-Request-ID');
    }

    return hash;
  }

  ajax(url, method, hash = {}) {
    hash.xhrFields = { withCredentials: true };

    // Make sure all the URLs going out from this adapter have the correct namespace.
    // Relationships `self` and `related` links aren't processed through the full adapter
    // cycle
    if (!url.includes('a/visitors/api/v3')) {
      url = url.replace('api/v3', 'a/visitors/api/v3');
    }

    return super.ajax(url, method, hash);
  }

  /**
   * ember-data will sync the model from the response,
   * if an attribute is not present in the response, it will be null
   * in global-setting-batch the response contains only the id and type
   * so we would like to keep the existing attributes that used in some logic (e.g. show undo button)
   *
   * This adds the attributes from the snapshot to the response, and this could be restored
   * when normalize runs.
   *
   * Use as: model.save({ adapterOptions: restoreAttributes: true })
   * in the model's serializer extend the normalize function to merge `_attributes` to `attributes`
   * note: you might need to dasherize the attribute name (signInFields -> sign-in-fields)
   */
  createRecord(store, schema, snapshot) {
    const promise = super.createRecord(...arguments);

    if (!snapshot.adapterOptions?.restoreAttributes) return promise;

    const { _attributes } = snapshot;

    promise.then((response) => {
      response.data._attributes = _attributes;
    });

    return promise;
  }
}

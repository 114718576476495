import Component from '@glimmer/component';
import { type PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type ConnectEmployeeModel from 'garaje/models/connect-employee';
import type TenantModel from 'garaje/models/tenant';

interface DirectoryTableComponentArgs {
  tenants?: PaginatedRecordArray<TenantModel>;
  filteredTenants?: PaginatedRecordArray<TenantModel>;
  employees?: PaginatedRecordArray<ConnectEmployeeModel>;
  loading?: boolean;
  roleTypes: string[];
}

export default class DirectoryTableComponent extends Component<DirectoryTableComponentArgs> {
  get renderTenantDropdown(): boolean {
    return !!(this.args.tenants?.length || this.args.filteredTenants?.length);
  }

  get noTenants(): boolean {
    return this.args.tenants?.length === 0;
  }

  get showPagination(): boolean {
    return <number>this.args.employees?.length > 0 && !this.args.loading;
  }

  isDisabled(tenant: TenantModel): boolean {
    return !!tenant.notSharingEmployees;
  }

  disabledMessage(tenant: TenantModel): string | null {
    if (tenant.notSharingEmployees) {
      return 'Not Sharing';
    }
    return null;
  }
}

import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { restartableTask } from 'ember-concurrency';

export default class extends Controller {
  @service flashMessages;

  get hasPickUpKiosk() {
    return this.model.devices.length > 0;
  }

  @restartableTask
  *toggleRequirePhotoProofTask(value) {
    const deliveryArea = this.model.deliveryArea;
    deliveryArea.requirePhoto = value;
    yield this.saveDeliveryAreaTask.perform(deliveryArea);
  }

  @restartableTask
  *toggleRequireSignatureTask(value) {
    const deliveryArea = this.model.deliveryArea;
    deliveryArea.requireSignature = value;
    yield this.saveDeliveryAreaTask.perform(deliveryArea);
  }

  @restartableTask
  *saveDeliveryAreaTask(deliveryArea) {
    try {
      yield deliveryArea.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      deliveryArea.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', 'Error saving changes.');
    }
  }
}

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { localCopy } from 'tracked-toolbox';

/**
 * @param {String}            value
 * @param {String}            type
 */
export default class AutofocusInput extends Component {
  @localCopy('args.type', 'text') type;

  @action
  onInsert(el) {
    el.focus();
  }
}

import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type CompanyModel from 'garaje/models/company';
import type FlowModel from 'garaje/models/flow';
import type LocationModel from 'garaje/models/location';
import type MailerBlockModel from 'garaje/models/mailer-block';
import type MailerSectionModel from 'garaje/models/mailer-section';
import type MailerTemplateModel from 'garaje/models/mailer-template';
import type UiHookModel from 'garaje/models/ui-hook';
import type StateService from 'garaje/services/state';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';
import { TrackedArray } from 'tracked-built-ins';

import type { SettingsEmailTemplatesRouteModel } from '../route';

import type SettingsEmailTemplatesShowController from './controller';

interface SettingsEmailTemplatesShowRouteParams {
  envoy_default_id: string;
}

export interface SettingsEmailTemplatesShowRouteModel {
  currentCompany: CompanyModel;
  currentLocation: LocationModel;
  envoyDefaultTemplate: MailerTemplateModel;
  flows: RecordArray<FlowModel>;
  isWhiteLabelFrom: boolean;
  mailerBlocks?: MailerBlockModel[] | null;
  mailerTemplate?: MailerTemplateModel;
  mailerTemplates: ArrayProxy<MailerTemplateModel>;
  mailerSections?: ArrayProxy<MailerSectionModel> | null;
  uiHooks?: RecordArray<UiHookModel>;
}

export default class SettingsEmailTemplatesShowRoute extends Route {
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  beforeModel(): void {
    const { envoy_default_id } = <SettingsEmailTemplatesShowRouteParams>this.paramsFor(this.routeName);
    this.redirectIfNotValid(envoy_default_id);
  }

  async model({
    envoy_default_id,
  }: SettingsEmailTemplatesShowRouteParams): Promise<SettingsEmailTemplatesShowRouteModel> {
    const {
      store,
      state: { currentLocation, currentCompany },
    } = this;

    // Always reload mailer-template and associated mailer-block models.
    // The mailer-template's mailer-block model's settings are generated based on location and cannot be cached
    const envoyDefaultTemplate = store.findRecord('mailer-template', envoy_default_id, {
      reload: true,
      include: 'mailer-sections,mailer-sections.mailer-blocks',
    });
    const { mailerTemplates, isWhiteLabelFrom } = <SettingsEmailTemplatesRouteModel>(
      this.modelFor('visitors.settings.email-templates')
    );
    const flows = store.query('flow', {
      include: 'global-flow',
      filter: { location: currentLocation.id }, // filter includes only regular flows
    });

    return hash({
      currentCompany,
      currentLocation,
      envoyDefaultTemplate,
      flows,
      isWhiteLabelFrom,
      mailerTemplates,
    });
  }

  setupController(
    controller: SettingsEmailTemplatesShowController,
    model: SettingsEmailTemplatesShowRouteModel,
    transition: Transition,
  ): void {
    const previousLocation = controller.model?.currentLocation;

    super.setupController(controller, model, transition);

    const { currentLocation } = controller.model;
    const isNewLocation = previousLocation?.id !== currentLocation.id;

    // initialize mailerTemplates with a tracked array if not already set for this location
    if (!controller.mailerTemplates || isNewLocation)
      controller.mailerTemplates = new TrackedArray(model.mailerTemplates.toArray());
  }

  redirectIfNotValid(id: string): void {
    const { envoyDefaultTemplates } = <SettingsEmailTemplatesRouteModel>(
      this.modelFor('visitors.settings.email-templates')
    );
    const isValid = envoyDefaultTemplates.map((record) => record.id).includes(id);

    if (!isValid && envoyDefaultTemplates[0]?.id) {
      void this.router.replaceWith('visitors.settings.email-templates.show', envoyDefaultTemplates[0].id);
    }
  }
}

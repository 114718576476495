import Controller from '@ember/controller';

import { type AssistanceScreenRouteModel } from './route';

const DEFAULT =
  '![](https://envoyuserentries.s3.amazonaws.com/summary_attributes/defaults/connect/i_do_not_have_a_qr_code.png)\n\n# No problem!\n\n### Our front desk staff will be happy to help you. Please see them for assistance.\n';

export default class PropertySettingsKioskAssistanceScreenController extends Controller {
  declare model: AssistanceScreenRouteModel;

  get hasMultipleLanguages(): boolean {
    return this.model.property.enabledLocales.size > 0;
  }

  get locales(): string[] {
    const property = this.model.property;
    return [property.locale, ...property.enabledLocales];
  }

  defaultText = DEFAULT;
}

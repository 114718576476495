import type ArrayProxy from '@ember/array/proxy';
import { setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type DeviceModel from 'garaje/models/device';
import type LocationModel from 'garaje/models/location';
import type PrinterModel from 'garaje/models/printer';
import type UserModel from 'garaje/models/user';
import type StateService from 'garaje/services/state';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

import type DeviceAlertsController from './controller';

export interface DeviceAlertsRouteModel {
  currentLocation: LocationModel;
  ipads: RecordArray<DeviceModel> | DeviceModel[];
  printers: RecordArray<PrinterModel> | PrinterModel[];
  printerContacts: ArrayProxy<UserModel> | UserModel[];
}

export default class DeviceAlertsRoute extends Route {
  declare controller: DeviceAlertsController;

  @service declare state: StateService;
  @service declare store: StoreService;
  @service declare transitionConfirm: TransitionConfirmService;

  titleToken = 'Device Alerts';

  async model(): Promise<DeviceAlertsRouteModel> {
    const { currentLocation } = this.state;

    const printers = this.store.query('printer', { filter: { location: currentLocation.id } });
    const ipads = this.store.query('device', { filter: { location: currentLocation.id } });

    return hash({
      currentLocation,
      ipads,
      printers,
      printerContacts: currentLocation.printerContacts,
    });
  }

  setupController(controller: DeviceAlertsController, model: DeviceAlertsRouteModel, transition: Transition): void {
    super.setupController(controller, model, transition);

    setProperties(controller, {
      model: model.currentLocation,
      ipads: model.ipads,
      printers: model.printers,
      printerContacts: model.printerContacts,
    });
  }

  @routeEvent
  routeWillChange(transition: Transition): boolean | undefined {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const controller = this.controller;
    if (!controller.deviceNotificationsHasChanges) {
      return true;
    } else {
      return void this.transitionConfirm.displayConfirmTask.perform(transition, {
        continue() {
          controller.send('rollbackDeviceNotifications');
        },
      });
    }
  }
}

import ApplicationSerializer from './application';
import { service } from '@ember/service';

export default class IdScanPageSerializer extends ApplicationSerializer {
  @service featureFlags;

  serializeIntoHash(...args) {
    const [hash, , snapshot, options] = args;
    const payload = this.serialize(snapshot, options);

    if (!this.featureFlags.isEnabled('visitors-id-scan-images')) {
      Object.assign(hash, payload);
      delete payload.data.attributes['images-visible'];
      return hash;
    }

    return super.serializeIntoHash(...args);
  }
}

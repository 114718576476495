import { service } from '@ember/service';
import type StateService from 'garaje/services/state';

import ApplicationAdapter from './application';

// See https://github.com/emberjs/data/blob/v5.3.8/packages/adapter/src/rest.ts#L36-L39
type QueryState = {
  include?: unknown;
  since?: unknown;
  meta?: unknown;
};

export default class MailerTemplateAdapter extends ApplicationAdapter {
  @service declare state: StateService;

  buildQuery(...args: Parameters<ApplicationAdapter['buildQuery']>): QueryState {
    const query = super.buildQuery(...args);
    const { currentLocation, currentCompany } = this.state;

    query['meta'] = {
      location_id: currentLocation.id,
      company_id: currentCompany.id,
    };

    return query;
  }
}

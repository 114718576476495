import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type Location from 'garaje/models/location';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import type StateService from 'garaje/services/state';
import type { GetCompanyQuery } from 'garaje/utils/graphql/graphql-types';

export default class AnalyticsRoomsRoute extends Route {
  @service declare featureFlags: FeatureFlagsService;
  @service declare router: RouterService;
  @service declare roombaGraphql: RoombaGraphqlService;
  @service declare state: StateService;
  @service declare abilities: AbilitiesService;

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  redirect(): void | Promise<unknown> {
    if (this.abilities.cannot('visit rooms analytic')) {
      void this.router.transitionTo('unauthorized');
    }
  }
  async model(): Promise<{ location: Location | null; company: GetCompanyQuery['company'] }> {
    return { location: this.state.currentLocation, company: await this.roombaGraphql.getCompany() };
  }
}

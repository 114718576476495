/* eslint-disable no-undef */
import Service from '@ember/service';
import config from 'garaje/config/environment';
import $ from 'jquery';
import moment from 'moment-timezone';
import _pick from 'lodash/pick';

export default class WootricService extends Service {
  constructor() {
    super(...arguments);
    window.wootricSettings = {
      account_token: config.wootricAccountToken,
    };
  }

  run(userId, params) {
    window.wootricSettings.email = params.email;
    window.wootricSettings.created_at = moment(params.createdAt, 'x').unix();

    const customAttributes = _pick(params, [
      'current_location',
      'current_location_boss_link',
      'name',
      'phone_number',
      'role',
      'user_cohort',
    ]);

    if (params?.company) {
      customAttributes.company_cohort = params.company.company_cohort;
      customAttributes.company_id = params.company.id;
      customAttributes.company_name = params.company.name;
      customAttributes.company_plan = params.company.plan;
    }

    customAttributes.id = userId;

    window.wootricSettings.properties = customAttributes;

    this._getScript();
  }

  _getScript() {
    if (config.environment === 'production') {
      $.getScript('https://disutgh7q0ncc.cloudfront.net/beacon.js', function () {
        WootricSurvey.run(window.wootricSettings);
      });
    }
  }
}

import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type { DetailedChangeset } from 'ember-changeset/types';
import { dropTask } from 'ember-concurrency';
import type PropertyPrinterModel from 'garaje/models/property-printer';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

import type { PropertyDevicesRouteModel } from '../../route';

export default class PropertyDevicesPrintersIndexController extends Controller {
  declare model: PropertyDevicesRouteModel;

  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;

  @tracked editingPrinterId: string | null = null;

  @action
  cancelEdit(): void {
    this.editingPrinterId = null;
  }

  @action
  editPrinter(printerId: string): void {
    this.editingPrinterId = printerId;
  }

  @action
  goToIPadPage(): void {
    void this.router.transitionTo('property.devices.ipads');
  }

  deletePrinterTask = dropTask(async (printer: PropertyPrinterModel) => {
    try {
      await printer.destroyRecord();
      this.flashMessages.showAndHideFlash('success', 'Printer removed successfully');
      if (this.model.printers.length === 0) {
        void this.router.transitionTo('property.devices.printers.connections');
      }
    } catch (error) {
      const errorText = parseErrorForDisplay(error);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  savePrinterTask = dropTask(async (printerChangeset: DetailedChangeset<PropertyPrinterModel>) => {
    try {
      await printerChangeset.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.editingPrinterId = null;
    } catch (error) {
      const printer = this.model.printers.find((printer) => printer.id === this.editingPrinterId);
      printer?.rollbackAttributes();
      const errorText = parseErrorForDisplay(error);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });
}

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

const REVIEW_STATUS = 'review';

export default class EmployeesDocumentReviewIndexController extends Controller {
  queryParams = ['type'];

  @tracked type = null;

  @tracked currentUserDocumentLinkIndex = 0;

  get currentUserDocumentLink() {
    return this.model.objectAt(this.currentUserDocumentLinkIndex) ?? this.model.objectAt(0);
  }

  get nextUserDocumentLinkIndex() {
    // find the next UserDocumentLink in the queue.
    // that is, starting from the current UserDocumentLink and moving toward the end of the list, looping around,
    // find the nearest UserDocumentLink that still needs to be reviewed.
    // If no UserDocumentLinks (or none other than the current one) need review, return -1.

    const pendingUserDocumentLinks = this.model.toArray(); // Ember arrays don't have #findIndex

    // start by looking between the current item and the end of the list.
    let nextIndex = pendingUserDocumentLinks.findIndex((userDocumentLink, index) => {
      return index > this.currentUserDocumentLinkIndex && userDocumentLink.approvalStatus === REVIEW_STATUS;
    });

    if (nextIndex === -1) {
      // no unreviewed UserDocumentLink exists between the current one and the end of the list;
      // now try searching from the beginning of the list up to the current one.
      nextIndex = pendingUserDocumentLinks.findIndex((userDocumentLink, index) => {
        return index < this.currentUserDocumentLinkIndex && userDocumentLink.approvalStatus === REVIEW_STATUS;
      });
    }

    return nextIndex;
  }

  get nextButtonDisabled() {
    return this.nextUserDocumentLinkIndex === -1;
  }

  // this.model is a static snapshot of review-needed UserDocumentLinks at the time the page was loaded.
  // To cut down on extraneous requests, instead of reloading the whole query every time a document is reviewed,
  // use this property to keep track of UserDocumentLinks that were loaded initially and still have not been reviewed.
  // This is used to drive the "# of documents" remaining display.
  // #currentUserDocumentLink and #nextUserDocumentLink still have to use `this.model` because we need to keep displaying
  // the "current" one even after it's been reviewed, until the user clicks "Next".
  get stillPendingUserDocumentLinks() {
    return this.model.filterBy('approvalStatus', REVIEW_STATUS);
  }

  @action
  moveToNextDocument() {
    // if there is no "next" document, don't move.
    if (this.nextUserDocumentLinkIndex === -1) return;

    // otherwise, move the "current" index to the position of the "next" document.
    this.currentUserDocumentLinkIndex = this.nextUserDocumentLinkIndex;
  }
}

import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_VISIT_ROLES = Object.freeze([GLOBAL_ADMIN, LOCATION_ADMIN]);

// TODO: Remove this file once ./communication.ts is used everywhere.
export default class EmergencyNotification extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;
  @service declare featureFlags: FeatureFlagsService;

  // TODO: Incorporate FC instead here
  get canVisit(): boolean {
    return this.canSeeFeatures;
  }

  get canSeeFeatures(): boolean {
    return !!this.state.features?.canAccessEmergencyNotifications;
  }

  get canEditFeatures(): boolean {
    const { roleNames: roles } = this.currentAdmin;
    return this.canSeeFeatures && isPresent(_intersection(CAN_VISIT_ROLES, roles));
  }
}

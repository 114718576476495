import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class VisitorsSetupGuideController extends Controller {
  queryParams = ['referrer'];

  @tracked referrer: string | null = null;
  @tracked intakeFormClosed = false;

  get showIntakeForm(): boolean {
    return this.referrer === 'trial-start' && !this.intakeFormClosed;
  }
}

import type ArrayProxy from '@ember/array/proxy';
import Service, { service } from '@ember/service';
import type Store from '@ember-data/store';
import { task, type TaskInstance } from 'ember-concurrency';
import type BlacklistFilter from 'garaje/models/blacklist-filter';

export default class BlocklistFilterService extends Service {
  @service declare store: Store;

  get locationBlocklistFilters(): ArrayProxy<BlacklistFilter> | BlacklistFilter[] {
    return this.queryByCompanyTask.lastSuccessful?.value || [];
  }

  get propertyBlocklistFilters(): ArrayProxy<BlacklistFilter> | BlacklistFilter[] {
    return this.queryByPropertyTask.lastSuccessful?.value || [];
  }

  // legacy API
  query(companyId: string): TaskInstance<ArrayProxy<BlacklistFilter>> {
    return this.queryByCompanyTask.perform(companyId);
  }

  queryByCompanyTask = task(async (companyId: string) => {
    return await this.store.query('blacklist-filter', {
      filter: {
        company: companyId,
      },
    });
  });

  queryByPropertyTask = task(async (propertyId: string) => {
    return await this.store.query('blacklist-filter', {
      filter: {
        property: propertyId,
      },
    });
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    blocklistFilter: BlocklistFilterService;
  }
}

export const GLOBAL_ADMIN = 'Global Admin';
export const LOCATION_ADMIN = 'Location Admin';
export const RECEPTIONIST = 'Receptionist'; // aka Front Desk Admin
export const DELIVERIES_ADMIN = 'Deliveries Admin';
export const SECURITY = 'Security';
export const BILLING = 'Billing';
export const EMPLOYEE = 'Employee';
export const ZONE_SECURITY = 'Zone Security Admin';
export const ZONE_ADMIN = 'Zone Admin';

export const LOCATION_ROLES = Object.freeze([LOCATION_ADMIN, RECEPTIONIST, DELIVERIES_ADMIN, SECURITY]);

export const COMPANY_ROLES = Object.freeze([BILLING, GLOBAL_ADMIN]);

export const ZONE_ROLES = Object.freeze([ZONE_SECURITY, ZONE_ADMIN]);

export const isCompanyRole = (roleName) => {
  return roleName && COMPANY_ROLES.includes(roleName);
};

/**
 * @param {string} roleName
 * @returns {boolean}
 */
export const isLocationRole = (roleName) => {
  return roleName && LOCATION_ROLES.includes(roleName);
};

export default {
  COMPANY_ROLES,
  LOCATION_ROLES,
  ZONE_ROLES,
  isCompanyRole,
  isLocationRole,
};

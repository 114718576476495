import { isAfter, parseISO, subMinutes } from 'date-fns';
import { JackStatusType, LightedCaseType } from 'garaje/graphql/generated/roomba-types';
import type { Device } from 'garaje/graphql/generated/roomba-types';
import { DEVICE_STATUS } from 'garaje/pods/components/roomba/status-badge/component';

// Used to check if the device is online
const ACTIVITY_WINDOW_MINUTES = 5;

type DeviceForDeviceStatus = Pick<
  Device,
  'volume' | 'jackStatus' | 'ledAudioType' | 'lightbarDetected' | 'lastOnlineAt'
>;

export function getDeviceStatus(device: DeviceForDeviceStatus): DEVICE_STATUS {
  if (!isDeviceOnline(device)) {
    return DEVICE_STATUS.OFFLINE;
  }
  if (ledAudioEnabled(device)) {
    if (device.jackStatus === JackStatusType.Unplugged && device.ledAudioType !== LightedCaseType.None) {
      return DEVICE_STATUS.OUT_OF_CASE;
    }
    if (device.volume && device.volume >= 0 && device.volume < 1.0) {
      return DEVICE_STATUS.LOW_VOLUME;
    }
  }
  return DEVICE_STATUS.OPERATIONAL;
}

export function ledAudioEnabled(device: Pick<Device, 'ledAudioType' | 'lightbarDetected'>): boolean {
  const usesLedAudio = !!device.ledAudioType;
  // don't enable LED audio for devices with integrated cases (handled by the mobile app)
  const hasLightbar = !!device.lightbarDetected;
  return usesLedAudio && !hasLightbar;
}

export function isDeviceOnline(device: Pick<Device, 'lastOnlineAt'>): boolean {
  if (!device.lastOnlineAt) {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return isAfter(parseISO(device.lastOnlineAt), subMinutes(new Date(), ACTIVITY_WINDOW_MINUTES));
}

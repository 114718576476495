import Route from '@ember/routing/route';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { set, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { dropTask, restartableTask, timeout } from 'ember-concurrency';
import { isRecordYourFirstDeliveryCompleted } from 'garaje/utils/setup-guide-steps';
import config from 'garaje/config/environment';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';

const testing = config.environment === 'test';
const DATA_POLLING_TIMEOUT = 30000;
export default class DeliveriesLogIndexRoute extends Route {
  @service currentAdmin;
  @service router;
  @service store;
  @service impressions;

  queryParams = {
    deliveryAreaFilter: {
      as: 'deliveryArea',
      refreshModel: true,
    },
    pageNumber: {
      refreshModel: true,
    },
    pageSize: {
      refreshModel: true,
    },
    recipient: {
      refreshModel: true,
    },
    statusFilter: {
      as: 'status',
      refreshModel: true,
    },
    trackingNumberFilter: {
      as: 'trackingNumber',
      refreshModel: true,
    },
  };

  beforeModel() {
    if (this.isGlobalOrLocationAdmin) {
      return this.checkOnboardingReadiness.perform();
    }
  }

  model(params) {
    const controller = this.controllerFor(this.routeName);
    controller.fetchDataTask.perform(params);
    this.impressions.postImpression.perform(IMPRESSION_NAMES.DELIVERIES.DELIVERIES_LOG_VISITED);
  }

  setupController(controller, _model) {
    super.setupController(...arguments);
    const deliveriesModel = this.modelFor('deliveries');
    set(controller, 'deliveryLimit', deliveriesModel.deliveryLimit);
    this.pollServerForChanges.perform(controller, deliveriesModel);
  }

  @computed('currentAdmin.{isGlobalAdmin,isLocationAdmin}')
  get isGlobalOrLocationAdmin() {
    const { isGlobalAdmin, isLocationAdmin } = this.currentAdmin;
    return isGlobalAdmin || isLocationAdmin;
  }

  @restartableTask({
    cancelOn: 'deactivate',
  })
  *fetchDeliveryLimit(controller, deliveriesModel) {
    const deliveryLimit = deliveriesModel.deliveryLimit;

    if (deliveryLimit && deliveryLimit.enforceLimit && !deliveryLimit.limitExceeded) {
      const newDeliveryLimit = yield this.store.queryRecord('delivery-limit', {});
      set(deliveriesModel, 'deliveryLimit', newDeliveryLimit);
      set(controller, 'deliveryLimit', newDeliveryLimit);
    }
  }

  @restartableTask({
    cancelOn: 'deactivate',
  })
  *pollServerForChanges(controller, deliveriesModel) {
    if (testing) {
      return;
    }

    const fetchDeliveriesTask = controller.fetchDeliveriesTask;

    while (true) {
      yield timeout(DATA_POLLING_TIMEOUT);

      const params = this.paramsFor(this.routeName);

      if (fetchDeliveriesTask.isIdle && this.fetchDeliveryLimit.isIdle) {
        yield fetchDeliveriesTask.perform(params);
        yield this.fetchDeliveryLimit.perform(controller, deliveriesModel);
      }
    }
  }

  @dropTask({
    cancelOn: 'deactivate',
  })
  *checkOnboardingReadiness() {
    const { id, deliveriesOnboardingComplete } = this.modelFor('current-location');
    let done = deliveriesOnboardingComplete;
    if (!done) {
      done = yield isRecordYourFirstDeliveryCompleted(this.store, id);
    }
    if (!done) {
      this.router.transitionTo('deliveries.log.tutorial');
    }
    return done;
  }
}

import adapter from 'garaje/utils/decorators/adapter';

import EmployeeGroupModel from './employee-group';

@adapter('v3-coalesce')
class DivisionEmployeeGroupModel extends EmployeeGroupModel {}

export default DivisionEmployeeGroupModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'division-employee-group': DivisionEmployeeGroupModel;
  }
}

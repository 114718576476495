import { A } from '@ember/array';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type LocationModel from 'garaje/models/location';
import type CurrentAdminService from 'garaje/services/current-admin';
import { reads } from 'macro-decorators';

interface LocationsOrderedByNameComponentArgs {
  /**
   * Triggers the basic dropdown to close
   */
  closeMenu: () => void;
  currentLocation?: LocationModel;
  /**
   * Array of locations to show in a list
   */
  locations: LocationModel[];
  isDisabledLocations: boolean;
  /**
   * Action to transition to location
   */
  switchLocationFromMenu: (location: LocationModel) => void;
}

export default class LocationsOrderedByNameComponent extends Component<LocationsOrderedByNameComponentArgs> {
  @service declare currentAdmin: CurrentAdminService;

  @tracked showDisabledLocations = true;

  @reads('currentAdmin.isGlobalAdmin') isGlobalAdmin!: CurrentAdminService['isGlobalAdmin'];

  get sortedLocations(): LocationModel[] {
    return A(this.args.locations)
      .sortBy('nameWithCompanyName')
      .filter((location) => this.showLocationToEmployees(location));
  }

  showLocationToEmployees(location: LocationModel): boolean {
    return isBlank(location.disabledToEmployeesAt) || this.isGlobalAdmin;
  }
}

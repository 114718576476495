/* eslint-disable ember/no-get */
import { getProperties } from '@ember/object';
import { isPresent } from '@ember/utils';
import type CompanyModel from 'garaje/models/company';
import type LocationModel from 'garaje/models/location';
import type UserModel from 'garaje/models/user';
import type ZoneModel from 'garaje/models/zone';
import bugsnag from 'garaje/utils/bugsnag';

interface ConfigureBugsnagOptions {
  /**
   * user's location
   */
  currentLocation?: LocationModel | null;
  /**
   * current user model
   */
  currentUser: UserModel;
  /**
   * user's company
   */
  currentCompany: CompanyModel;
  /**
   * current zone model
   */
  currentZone?: ZoneModel | null;
}

export const configureBugsnag = ({
  currentLocation,
  currentUser,
  currentCompany,
  currentZone,
}: ConfigureBugsnagOptions): void => {
  const { fullName: name, id } = getProperties(currentUser, ['id', 'fullName']);
  const { id: locationId, name: locationName } = getProperties(<Partial<LocationModel>>(currentLocation ?? {}), [
    'id',
    'name',
  ]);
  const { id: zoneId, name: zoneName } = getProperties(<Partial<ZoneModel>>(currentZone ?? {}), ['id', 'name']);
  const { id: companyId, name: companyName } = getProperties(currentCompany, ['id', 'name']);

  bugsnag.user = {
    id,
    name,
  };

  bugsnag.metaData = {
    userData: {
      company: {
        id: companyId,
        name: companyName,
      },
    },
  };

  if (isPresent(locationId) && isPresent(locationName)) {
    bugsnag.metaData.userData.location = {
      id: locationId,
      name: locationName,
    };
  }

  if (isPresent(zoneId) && isPresent(zoneName)) {
    bugsnag.metaData.userData.zone = {
      id: zoneId,
      name: zoneName,
    };
  }
};

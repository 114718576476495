import ApplicationSerializer from './application';

export default class UserDocumentTemplateConfigurationSerializer extends ApplicationSerializer {
  attrs = {
    userDocumentTemplateConfigurableType: {
      serialize: false,
    },
    userDocumentTemplateConfigurableId: {
      serialize: false,
    },
    createdAt: {
      serialize: false,
    },
    updatedAt: {
      serialize: false,
    },
  };
}

import Service from '@ember/service';

type IconKeys = Exclude<keyof IpadIconService, keyof Service | 'getIconURI'>;

export default class IpadIconService extends Service {
  // need to explicitly list them all out here so that ember-cli
  // fingerprinting can rewrite these assets
  ipadAir2Icon = '/assets/images/ipad/ipad-air-2.png';
  ipadAir2SleepingIcon = '/assets/images/ipad/ipad-air-2-sleep.png';
  ipadAirIcon = '/assets/images/ipad/ipad-air.png';
  ipadAirSleepingIcon = '/assets/images/ipad/ipad-air-sleep.png';
  ipadMini2Icon = '/assets/images/ipad/ipad-mini-2.png';
  ipadMini2SleepingIcon = '/assets/images/ipad/ipad-mini-2-sleep.png';
  ipadMini3Icon = '/assets/images/ipad/ipad-mini-3.png';
  ipadMini3SleepingIcon = '/assets/images/ipad/ipad-mini-3-sleep.png';
  ipadProBigIcon = '/assets/images/ipad/ipad-pro-big.png';
  ipadProBigSleepingIcon = '/assets/images/ipad/ipad-pro-big-sleep.png';
  ipadProSmallIcon = '/assets/images/ipad/ipad-pro-small.png';
  ipadProSmallSleepingIcon = '/assets/images/ipad/ipad-pro-small-sleep.png';
  ipadSimulatorIcon = '/assets/images/ipad/ipad-simulator.png';
  ipadSimulatorSleepingIcon = '/assets/images/ipad/ipad-simulator-sleep.png';
  oldIpadIcon = '/assets/images/ipad/old-ipad.png';
  oldIpadSleepingIcon = '/assets/images/ipad/old-ipad-sleep.png';

  getIconURI(deviceType: string, isSleeping: boolean, isStatusBad: boolean): string {
    let iconProperty: string;

    switch (deviceType) {
      case 'iPad4,1':
      case 'iPad4,2':
      case 'iPad4,3':
        iconProperty = 'ipadAir';
        break;
      case 'iPad2,5':
      case 'iPad2,6':
      case 'iPad2,7':
      case 'iPad4,4':
      case 'iPad4,5':
      case 'iPad4,6':
        iconProperty = 'ipadMini2';
        break;
      case 'iPad4,7':
      case 'iPad4,8':
      case 'iPad4,9':
      case 'iPad5,1':
      case 'iPad5,2':
        iconProperty = 'ipadMini3';
        break;
      case 'iPad5,3':
      case 'iPad5,4':
      case 'iPad6,11':
      case 'iPad6,12':
        iconProperty = 'ipadAir2';
        break;
      case 'iPad6,3':
      case 'iPad6,4':
        iconProperty = 'ipadProSmall';
        break;
      case 'iPad6,7':
      case 'iPad6,8':
        iconProperty = 'ipadProBig';
        break;
      case 'x86_32':
      case 'x86_64':
        iconProperty = 'ipadSimulator';
        break;
      default:
        iconProperty = 'oldIpad';
    }

    if (isSleeping && !isStatusBad) {
      iconProperty += 'Sleeping';
    }

    iconProperty += 'Icon';

    return this[<IconKeys>iconProperty];
  }
}

/**
 *
 * @param {String} url
 * @returns {String}
 */
export default function fixVideoUrl(url) {
  let fixed = url;

  if (/youtu\.be/.test(url)) {
    const id = url.split('/').slice(-1)[0];
    fixed = `https://www.youtube.com/embed/${id}`;
  } else if (/youtube/.test(url) && !/embed/.test(url)) {
    let id;
    url
      .split('?')[1]
      .split('&')
      .find((param) => {
        const parts = param.split('=');
        let result;

        if (parts[0] === 'v') {
          id = parts[1];
        }

        return result;
      });

    if (id) {
      fixed = `https://www.youtube.com/embed/${id}`;
    }
  } else if (/vimeo/.test(url) && !/player/.test(url)) {
    const [id, hash] = url.split('vimeo.com/')[1].split('/');
    if (hash) {
      fixed = `https://player.vimeo.com/video/${id}?hash=${hash}`;
    } else {
      fixed = `https://player.vimeo.com/video/${id}`;
    }
  }

  return fixed;
}

import LocationFragment from 'garaje/graphql/fragments/roomba/LocationFragment';
import gql from 'graphql-tag';

export default gql`
  mutation UpdateRoomsLocationOnsiteRoomRecaptureEnabledOverride(
    $locationId: ID!
    $onsiteRoomRecaptureEnabledOverride: Boolean
  ) {
    updateRoomsLocationOnsiteRoomRecaptureEnabledOverride(
      locationId: $locationId
      onsiteRoomRecaptureEnabledOverride: $onsiteRoomRecaptureEnabledOverride
    ) {
      ...LocationFragment
    }
  }
  ${LocationFragment}
`;

import ApplicationSerializer from './application';

export default class SetupGuideStepSerializer extends ApplicationSerializer {
  attrs = {
    page: {
      serialize: false,
    },
    prerequisiteText: {
      serialize: false,
    },
    skippable: {
      serialize: false,
    },
    stepNumber: {
      serialize: false,
    },
    deliveriesSetupBarOrder: {
      serialize: false,
    },
    subtitle: {
      serialize: false,
    },
    title: {
      serialize: false,
    },
  };
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import type TenantModel from 'garaje/models/tenant';
import type TenantPermissionsConfigurationModel from 'garaje/models/tenant-permissions-configuration';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { type ModelAttrs } from 'garaje/utils/type-utils';
import { alias } from 'macro-decorators';

type ConfigurationKey = Exclude<ModelAttrs<TenantPermissionsConfigurationModel>, 'tenant'>;
interface ConnectConfigPermissionsManagerComponentArgs {
  tenant: TenantModel;
}

interface TenantPermissionDefinition {
  name: string;
  description: string;
  toggleWarning?: string;
  configurationKey?: ConfigurationKey;
  readonly?: boolean;
}

const PERMISSIONS: TenantPermissionDefinition[] = [
  {
    name: 'View invite and visitor logs',
    description: 'Property admins will see your invite and visitor log data.',
  },
  {
    name: 'Modify some visitor data',
    description: 'Property admins can modify your visitor data such as visitor check-ins.',
  },
  {
    name: 'View employee directory',
    description:
      'Property admins will see your employee directory data, specifically employee names and pictures. Contact information will be restricted.',
    toggleWarning:
      'You will revoke the property’s access to your employee information. This can affect the ability of the property to properly identify employees.',
    configurationKey: 'employeesRead',
  },
  {
    name: 'Create new invite and sign in records',
    description: 'Property admins can create new invites for visitors such as property walk-ups.',
  },
  {
    name: 'Apply property rules',
    description: 'Property admins can apply their rules that could block specific visitors from coming in.',
  },
];

export default class ConnectConfigPermissionsManagerComponent extends Component<ConnectConfigPermissionsManagerComponentArgs> {
  @service declare flashMessages: FlashMessagesService;

  @alias('args.tenant.tenantPermissionsConfiguration')
  declare permissionsConfiguration: TenantPermissionsConfigurationModel;

  @tracked pendingPermissionToggle?: TenantPermissionDefinition;

  get permissions(): TenantPermissionDefinition[] {
    return PERMISSIONS;
  }

  toggleTask = dropTask(async (key: ConfigurationKey) => {
    this.permissionsConfiguration[key] = !this.permissionsConfiguration[key];

    try {
      await this.permissionsConfiguration.save();
      this.flashMessages.showAndHideFlash('success', 'Saved');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      this.permissionsConfiguration.rollbackAttributes();
    }

    this.pendingPermissionToggle = undefined;
  });

  @action
  togglePermission(permission: TenantPermissionDefinition): void {
    if (permission.configurationKey && !this.permissionsConfiguration[permission.configurationKey]) {
      void this.toggleTask.perform(permission.configurationKey);
      return;
    }

    this.pendingPermissionToggle = permission;
  }
}

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 * @param {String}            deviceType
 * @param {Boolean}           isSleeping
 * @param {Boolean}           isStatusBad
 */
export default class IpadIcon extends Component {
  @service ipadIcon;

  get ipadIconURI() {
    return this.ipadIcon.getIconURI(this.args.deviceType, this.args.isSleeping, this.args.isStatusBad);
  }
}

import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';

/**
 * @param {Array}                   sections
 * @param {Function}                onEdit
 * @param {boolean}                 isDisabled
 */
export default class VisitorEmailsVisitorGuideVgEditing extends Component {
  get sortedSections() {
    return this.args.sections?.length ? this.args.sections.sortBy('position') : [];
  }

  @action
  reorderItems(sections) {
    sections.forEach((section, i) => set(section, 'position', i));
    this.args.onEdit();
  }

  @action
  onKeyUp(idx, evt) {
    if (!evt.target.classList.contains('handle')) {
      return;
    }
    let toNextPos;
    let toPreviousPos;
    let toFocus;
    switch (evt.key) {
      case 'ArrowDown':
        if (idx < this.args.sections.length - 1) {
          toNextPos = this.sortedSections.objectAt(idx).id;
          toPreviousPos = this.sortedSections.objectAt(idx + 1).id;
          toFocus = idx + 1;
        }
        break;
      case 'ArrowUp':
        if (idx > 0) {
          toPreviousPos = this.sortedSections.objectAt(idx).id;
          toNextPos = this.sortedSections.objectAt(idx - 1).id;
          toFocus = idx - 1;
        }
        break;
      default:
        return;
    }
    if (toPreviousPos && toNextPos) {
      let section = this.args.sections.findBy('id', toPreviousPos);
      set(section, 'position', section.position - 1);
      section = this.args.sections.findBy('id', toNextPos);
      set(section, 'position', section.position + 1);
      this.args.onEdit();
      /* eslint-disable ember/no-incorrect-calls-with-inline-anonymous-functions */
      scheduleOnce('afterRender', () => {
        document.querySelector(`#vg-editing .sortable-item:nth-child(${toFocus + 1}) .handle`).focus();
      });
      /* eslint-enable ember/no-incorrect-calls-with-inline-anonymous-functions */
    }
  }
}

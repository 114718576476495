import EntryFragment from 'garaje/graphql/fragments/employee-schedule/EntryFragment';
import gql from 'graphql-tag';

export default gql`
  mutation SignOutEntry($id: ID!, $signedOutAt: DateTime!, $signedOutBy: String) {
    signOutEntry(id: $id, signedOutAt: $signedOutAt, signedOutBy: $signedOutBy) {
      ...EntryFragment
    }
  }
  ${EntryFragment}
`;

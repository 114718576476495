import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StateService from 'garaje/services/state';

/**
 * Since legacy `/rooms/...` routes were replaced with `/roomba/...` and now we are changing the routes back,
 * all the existing bookmarked urls with `/roomba/...` will redirect to 404.
 * This will fix that by intercepting all possible `/roomba/...` routes and redirecting back to `/rooms/...`
 * Eventually this can be removed altogether, since it is just a temporary solution to avoid redirecting users to 404
 */
export default class RoomsRoute extends Route {
  @service declare state: StateService;
  @service declare router: RouterService;

  beforeModel(): void {
    const currentURL = this.router.currentURL;

    if (this.shouldPreservePath(currentURL)) {
      void this.router.replaceWith(currentURL.replace('/roomba/', '/rooms/'));
    } else {
      void this.router.replaceWith('rooms');
    }
  }

  /**
   * For some URLs, we want to preserve the rest of the path when loading.
   * @param url like `/roomba/settings/permissions`
   */
  private shouldPreservePath(url: string): boolean {
    return url.startsWith('/roomba/');
  }
}

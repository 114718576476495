import Component from '@glimmer/component';
import { SLACK_V2_PLUGIN_KEY, MSTEAMS_V2_PLUGIN_KEY } from 'garaje/utils/enums';
import { tracked } from '@glimmer/tracking';
import urlBuilder from 'garaje/utils/url-builder';

/**
 * @param {LocationModel} location
 * @param {Boolean}       isInactive
 */

export default class Notifications extends Component {
  /**
   * @property {Array<String>}
   */

  @tracked integrationsUrl = urlBuilder.appStoreUrl();

  slackV2PluginKey = SLACK_V2_PLUGIN_KEY;
  msTeamsV2PluginKey = MSTEAMS_V2_PLUGIN_KEY;
  allowedPlugins = [this.slackV2PluginKey, this.msTeamsV2PluginKey];
}

import { appVersionComparator, isAppVersionGreaterEqual } from 'garaje/utils/check-app-version';
import { computed, get } from '@ember/object';
import { isEmpty } from '@ember/utils';

const LOCALES = [
  { label: 'English', value: 'en' },
  { label: 'العربية (Arabic)', value: 'ar' },
  { label: 'Български (Bulgarian)', value: 'bg-BG' },
  { label: 'Čeština (Czech)', value: 'cs' },
  { label: 'Dansk (Danish)', value: 'da' },
  { label: 'Deutsch (German)', value: 'de' },
  { label: 'Español (Spanish)', value: 'es' },
  { label: 'Eesti (Estonian)', value: 'et-EE' },
  { label: 'Suomi (Finnish)', value: 'fi' },
  { label: 'Français (French)', value: 'fr' },
  { label: 'עִבְרִית‎ (Hebrew)', value: 'he' },
  { label: 'हिन्दी (Hindi)', value: 'hi-IN' },
  { label: 'Hrvatski (Croatian)', value: 'hr' },
  { label: 'Magyar (Hungarian)', value: 'hu' },
  { label: 'Italiano (Italian)', value: 'it' },
  { label: '日本語 (Japanese)', value: 'ja' },
  { label: '한국어 (Korean)', value: 'ko' },
  { label: 'Bahasa Melayu (Malay)', value: 'ms-MY' },
  { label: 'Norsk (Norwegian)', value: 'nb' },
  { label: 'Nederlands (Dutch)', value: 'nl' },
  { label: 'Polski (Polish)', value: 'pl' },
  { label: 'Português (Portuguese)', value: 'pt-BR' },
  { label: 'Română (Romanian)', value: 'ro' },
  { label: 'Pусский (Russian)', value: 'ru' },
  { label: 'Slovenčina (Slovak)', value: 'sk' },
  { label: 'Slovenščina (Slovenian)', value: 'sl-SI' },
  { label: 'Српски (Serbian)', value: 'sr-RS' },
  { label: 'Svenska (Swedish)', value: 'sv-SE' },
  { label: 'ภาษาไทย (Thai)', value: 'th' },
  { label: 'Українська (Ukrainian)', value: 'uk' },
  { label: '简体中文 (Chinese Simplified)', value: 'zh-CN' },
  { label: '繁体中文 (Chinese Traditional)', value: 'zh-TW' },
];
export default LOCALES;

const RTL_LOCALES = [
  { label: 'العربية (Arabic)', value: 'ar' },
  { label: 'עִבְרִית‎ (Hebrew)', value: 'he' },
];

/**
 *
 * @param {String} selectedLocale
 * @returns {Boolean}
 */
export function isRTLLocale(selectedLocale) {
  if (!selectedLocale) {
    return false;
  }

  return RTL_LOCALES.mapBy('value').includes(selectedLocale);
}

// Object that pairs locales with the app versions needed to support them
const APP_VERSIONED_LOCALES = {
  '3.4.0': [
    { localeCode: 'ar', name: 'Arabic' },
    { localeCode: 'he', name: 'Hebrew' },
  ],
  '3.5.0': [
    { localeCode: 'cs', name: 'Czech' },
    { localeCode: 'ms-MY', name: 'Malay' },
    { localeCode: 'pl', name: 'Polish' },
  ],
  '3.10.0': [
    { localeCode: 'bg-BG', name: 'Bulgarian' },
    { localeCode: 'hr', name: 'Croatian' },
    { localeCode: 'hu', name: 'Hungarian' },
    { localeCode: 'sk', name: 'Slovak' },
    { localeCode: 'sl-SI', name: 'Slovenian' },
    { localeCode: 'sr-RS', name: 'Serbian' },
    { localeCode: 'uk', name: 'Ukrainian' },
  ],
  '3.10.1': [{ localeCode: 'et-EE', name: 'Estonian' }],
};

/**
 * Return an array of locales (from the LOCALES array of this module) with the
 * addition of a `disabled` key indicated whether the locale is selectable based on
 * the app versions of the given list of devices. (For example, if one or more of the
 * devices is on a lower version than the one in which a locale is supported [see
 * APP_VERSIONED_LOCALES], then it will have `disabled: true`.)
 *
 * Note: this function is similar, but not identical, to the logic in the `disabledLocalesInfo`
 * computed property. That function returns only the disabled locales, not all locales with
 * a `disabled` boolean added.
 *
 * @param {Device[]} devices
 */
export function localeOptions(devices) {
  const ipads = devices.filter((device) => device.isIpad);
  const currentAppVersions = ipads.map((ipad) => ipad.appVersion).sort(appVersionComparator);

  if (isEmpty(currentAppVersions)) {
    return LOCALES;
  }

  const appVersionsNeeded = Object.keys(APP_VERSIONED_LOCALES).sort(appVersionComparator);

  // compare versions needed to the oldest version to get disabled locales
  const oldestVersion = currentAppVersions[0];
  const disabledLocales = [];
  for (const versionNeeded of appVersionsNeeded) {
    const updateNeeded = !isAppVersionGreaterEqual(oldestVersion, versionNeeded);
    if (updateNeeded) {
      disabledLocales.push(
        ...APP_VERSIONED_LOCALES[versionNeeded].map((versionedLocale) => versionedLocale.localeCode),
      );
    }
  }

  return LOCALES.map((localeItem) => ({
    ...localeItem,
    disabled: disabledLocales.includes(localeItem.value),
  }));
}

/**
 * Higher order function that returns a computed property for checking disabled locales
 *
 * @function
 * @param {string} dependentKey - dependent key where the iPads are location. `Ex: model.ipads.@each{isIpad,appVersion}`
 */
export function disabledLocalesInfo(dependentKey) {
  return computed(`${dependentKey}.@each{isIpad,appVersion}`, function () {
    const ascendingCurrentVersions = get(this, dependentKey)
      .filter((ipad) => get(ipad, 'isIpad'))
      .map((ipad) => get(ipad, 'appVersion'))
      .sort((a, b) => (isAppVersionGreaterEqual(a, b) ? 1 : -1));

    if (isEmpty(ascendingCurrentVersions)) {
      return null;
    }

    const ascendingVersionsNeeded = Object.keys(APP_VERSIONED_LOCALES).sort((a, b) =>
      isAppVersionGreaterEqual(a, b) ? 1 : -1,
    );

    // compare versions needed to the oldest version to get disabled locales
    const oldestVersion = ascendingCurrentVersions.firstObject;
    const disabledLocales = [];
    ascendingVersionsNeeded.forEach((versionNeeded) => {
      const updateNeeded = !isAppVersionGreaterEqual(oldestVersion, versionNeeded);
      if (updateNeeded) {
        disabledLocales.push(...APP_VERSIONED_LOCALES[versionNeeded]);
      }
    });
    if (isEmpty(disabledLocales)) {
      return null;
    }
    const highestVersionNeeded = ascendingVersionsNeeded.lastObject;
    return { disabledLocales, appVersionNeeded: highestVersionNeeded };
  });
}

/**
 * Higher order function that returns a computed property for displaying a locale update warning
 *
 * @function
 */
export function localeUpdateWarning() {
  return computed('disabledLocalesInfo.{disabledLocales.[],appVersionNeeded}', function () {
    if (!this.disabledLocalesInfo) {
      return null;
    }
    const { appVersionNeeded } = this.disabledLocalesInfo;

    return {
      header: 'App update required to support new languages',
      message: `Version ${appVersionNeeded} or higher of the Envoy Visitors iPad app is required.`,
    };
  });
}

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { isBlank, isEmpty, isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { type TaskInstance, restartableTask, timeout } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';
import { type PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type ConnectEmployeeModel from 'garaje/models/connect-employee';
import type TenantModel from 'garaje/models/tenant';
import zft from 'garaje/utils/zero-for-tests';

import type { DirectoryQueryParams, PropertyDirectoryIndexRouteModel } from './route';

const DEFAULT_PAGE_SIZE = 30;
const SEARCH_DEBOUNCE = 300;

export default class PropertyDirectoryIndexController extends Controller {
  declare model: PropertyDirectoryIndexRouteModel;

  queryParams = ['pageNumber', 'pageSize', 'sortBy', 'sortDirection', 'queryName', 'tenantIds', 'roleTypes'];

  @tracked pageNumber = 1;
  @tracked pageSize = DEFAULT_PAGE_SIZE;
  @tracked sortBy = 'Name';
  @tracked sortDirection = 'A-Z';
  @tracked queryName = '';
  @tracked tenantIds = '';
  @tracked roleTypes = '';

  @tracked searchingTenantsTaskInstance: TaskInstance<PaginatedRecordArray<TenantModel>> | null = null;

  get employeesPage(): PaginatedRecordArray<ConnectEmployeeModel> | null | undefined {
    return this.model.loadDirectoryTask.lastSuccessful?.value?.employees;
  }

  get currentPageParams(): DirectoryQueryParams | null | undefined {
    return this.model.loadDirectoryTask.lastSuccessful?.value?.params;
  }

  get parsedRoleTypes(): string[] {
    return this.roleTypes.split(',').filter(Boolean);
  }

  get tenantsPage(): PaginatedRecordArray<TenantModel> | null | undefined {
    return this.model.allTenantsTaskInstance.value;
  }

  get filteredTenantsPage(): PaginatedRecordArray<TenantModel> | null | undefined {
    return this.searchingTenantsTaskInstance?.value;
  }

  get recordCount(): number {
    return this.employeesPage?.meta.total ?? 0;
  }

  get pageTitle(): string | undefined {
    if (!this.employeesPage || !this.currentPageParams) return;

    const { queryName } = this.currentPageParams;

    let pageTitle = `${this.recordCount} tenant ${pluralize(this.recordCount, 'employee', {
      withoutCount: true,
    })}`;

    if (!isBlank(queryName)) {
      pageTitle += ` matching "${queryName}"`;
    }

    return pageTitle;
  }

  get loading(): boolean {
    return this.model.loadDirectoryTask.isRunning || this.model.allTenantsTaskInstance.isRunning;
  }

  get tenantsLoading(): boolean {
    return this.searchingTenantsTaskInstance?.isRunning || this.model.allTenantsTaskInstance.isRunning;
  }

  searchTask = restartableTask(async (search: string) => {
    await timeout(zft(SEARCH_DEBOUNCE));
    this.queryName = search;
    this.pageNumber = 1;
  });

  @action
  onSelectedTenant(tenant: TenantModel): void {
    let ids = this.tenantIds.split(',').filter((el) => isPresent(el));
    ids = ids.includes(tenant.id) ? ids.filter((id) => id !== tenant.id) : [...ids, tenant.id];
    this.tenantIds = ids.join(',');
    this.pageNumber = 1;
  }

  @action
  onDeselectAllTenants(): void {
    this.tenantIds = '';
    this.pageNumber = 1;
  }

  @action
  onChangeRoleTypes(roleTypes: string[]): void {
    this.roleTypes = roleTypes.join(',');
    this.pageNumber = 1;
  }

  @action
  onTenantSearch(search: string): void {
    this.searchingTenantsTaskInstance = !isEmpty(search) ? this.model.loadTenantsTask.perform(search) : null;
  }
}

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { alias, sum, mapBy } from 'macro-decorators';

export default class SubscriptionDetails extends Component {
  @service('location-billing') billingService;

  @alias('billingService.initialLocationSubscriptionEstimates') locationSubscriptionEstimates;
  @alias('billingService.period') period;
  @alias('billingService.renewalDate') renewalDate;

  @mapBy('locationSubscriptionEstimates', 'invoiceEstimatesTotal') invoiceEstimatesTotals;
  @sum('invoiceEstimatesTotals') invoiceEstimatesTotal;
}

import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

import type HomepageItem from './homepage-item';

@adapter('growth')
class MarketingItem extends Model {
  @attr('string')
  declare identifier: string;

  @attr('string')
  declare title: string;

  @attr('string')
  declare description: string;

  @attr('boolean')
  declare enabled: boolean;

  @attr('number')
  declare rank: number;

  @attr('immutable', {
    defaultValue: () => ({}),
  })
  declare metadata: { url?: string };

  @attr('string')
  declare completionMethod: string;

  @attr('boolean')
  declare completed: boolean;

  @belongsTo('homepage-item')
  declare homepageItem: AsyncBelongsTo<HomepageItem>;

  get isExternalLink(): boolean {
    return this.metadata.url !== undefined && this.metadata.url.startsWith('http');
  }
}

export default MarketingItem;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'marketing-item': MarketingItem;
  }
}

import config from '../config/environment';
import AjaxService from '../services/ajax';

/**
 * Allow custom API hostname for review application usage.
 *
 * If you visit a review application it will redirect to the staging deployment
 * with a hostname variable, that is then save to local storage. This checks
 * for it in staging and development and uses it as the API endpoints when
 * it is present.
 */
export function initialize(/* application */) {
  if (config.environment !== 'staging' && config.environment !== 'development') {
    return;
  }

  const hostname = window.localStorage.getItem('reviewHostname');

  if (!hostname) {
    return;
  }

  const host = `${hostname}.herokuapp.com`;
  const uri = `https://${host}`;

  config.apiHost = uri;
  config.envoyHost = uri;
  config.trustedHosts = [host];

  AjaxService.reopen({
    init() {
      this._super(...arguments);
      this.trustedHosts = [host];
    },
  });

  /* eslint-disable no-console */
  console.warn(`%cOverwriting default API hostname with ${hostname}`, 'color: #EF3934; font-weight: bold;');
  /* eslint-enable no-console */
}

export default {
  name: 'setup-review-host',
  initialize,
};

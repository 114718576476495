import Component from '@glimmer/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { equal } from '@ember/object/computed';
import { gt } from 'macro-decorators';

/**
 * @param {Boolean}           disableMonthlyPrice
 * @param {Boolean}           yearlyTextOnly
 * @param {Task}              fetchEstimateTask
 * @param {Boolean}           isLoading
 * @param {String}            period monthly|yearly
 * @param {Function<Action>}  setNewPeriod
 * @param {Number}            planUnitAmount
 * @param {Number}            planUnitAmountForOtherPeriod
 * @param {Number}            planDiscountAmount
 * @param {Number}            planDiscountAmountForOtherPeriod
 * @param {Number}            quantity
 * @param {String}            selectedPlan
 */
export default class OrderConfirmationBillingPeriod extends Component {
  @equal('args.selectedPlan', 'enterprise') isEnterprisePlan;
  @equal('args.period', 'monthly') periodIsMonthly;
  @equal('args.period', 'yearly') periodIsYearly;
  @gt('yearlySavingsInPercentage', 0) hasDiscounts;

  get hasNoYearlySavings() {
    return this.yearlySavingsInCents === 0;
  }

  get monthlyInvoiceEstimate() {
    return this.periodIsMonthly ? this.args.invoiceEstimate : this.args.invoiceEstimateForOtherPeriod;
  }

  get yearlyInvoiceEstimate() {
    return this.periodIsYearly ? this.args.invoiceEstimate : this.args.invoiceEstimateForOtherPeriod;
  }

  get monthlyPriceWithTaxInCents() {
    const amount = this.periodIsMonthly ? this.args.planUnitAmount : this.args.planUnitAmountForOtherPeriod;
    const taxAmount = this.periodIsMonthly ? this.args.planTaxAmount : this.args.planTaxAmountForOtherPeriod;
    return amount * this.args.quantity + (taxAmount || 0);
  }

  get yearlyPriceWithTaxInCents() {
    return this.hasDiscounts
      ? this.yearlyDiscountedPriceWithTaxInCents / (1 - this.yearlySavingsInPercentage / 100)
      : this.yearlyDiscountedPriceWithTaxInCents;
  }

  get yearlyDiscountedPriceWithTaxInCents() {
    const taxAmount = this.periodIsMonthly ? this.args.planTaxAmountForOtherPeriod : this.args.planTaxAmount;
    return this.yearlyDiscountedPriceInCents + (taxAmount || 0) / 12;
  }

  get yearlyDiscountedPriceInCents() {
    const amount = this.periodIsMonthly ? this.args.planUnitAmountForOtherPeriod : this.args.planUnitAmount;
    const discountAmount = this.periodIsMonthly
      ? this.args.planDiscountAmountForOtherPeriod
      : this.args.planDiscountAmount;

    return (amount * this.args.quantity - (discountAmount || 0)) / 12;
  }

  get yearlySavingsInCents() {
    return (this.monthlyPriceWithTaxInCents - this.yearlyPriceWithTaxInCents) * 12;
  }

  get yearlySavingsInDollars() {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
      .format(this.yearlySavingsInCents / 100)
      .replace(/.00$/, '');
  }

  get yearlySavingsInPercentage() {
    return this.yearlyInvoiceEstimate?.discounts.firstObject?.percentOff || 0;
  }
}

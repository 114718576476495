import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type GlobalAgreementModel from 'garaje/models/global-agreement';
import { hash } from 'rsvp';

import type { LocationOverviewSignInFlowsShowLegalDocumentsRouteModel } from '../route';

export interface LocationOverviewSignInFlowsShowLegalDocumentsEditRouteModel {
  agreements: LocationOverviewSignInFlowsShowLegalDocumentsRouteModel['agreements'];
  agreement: GlobalAgreementModel;
  agreementPage: LocationOverviewSignInFlowsShowLegalDocumentsRouteModel['agreementPage'];
  flow: LocationOverviewSignInFlowsShowLegalDocumentsRouteModel['flow'];
  tracking: LocationOverviewSignInFlowsShowLegalDocumentsRouteModel['tracking'];
  signInFields: LocationOverviewSignInFlowsShowLegalDocumentsRouteModel['signInFields'];
}

export default class LocationOverviewSignInFlowsShowLegalDocumentsEditRoute extends Route {
  @service declare store: StoreService;

  model({
    agreement_id,
  }: {
    agreement_id: string;
  }): Promise<LocationOverviewSignInFlowsShowLegalDocumentsEditRouteModel> {
    const { agreements, agreementPage, flow, tracking, signInFields } = <
      LocationOverviewSignInFlowsShowLegalDocumentsRouteModel
    >this.modelFor('location-overview.sign-in-flows.show.legal-documents');

    const agreement = this.store.findRecord('global-agreement', agreement_id);

    return hash({
      agreements,
      agreement,
      agreementPage,
      flow,
      tracking,
      signInFields,
    });
  }
}

import type ArrayProxy from '@ember/array/proxy';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { format } from 'date-fns';
import type AbilitiesService from 'ember-can/services/abilities';
import { enqueueTask } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';
import type ConnectInviteModel from 'garaje/models/connect-invite';
import type PropertyPrinterModel from 'garaje/models/property-printer';
import type TenantModel from 'garaje/models/tenant';
import type ZoneModel from 'garaje/models/zone';
import { API_DATE_FORMAT } from 'garaje/pods/components/property/visitors/invites/table/date-range-select/component';
import type AjaxService from 'garaje/services/ajax';
import type ConnectInvitesService from 'garaje/services/connect-invites';
import { type ColumnHeader } from 'garaje/services/connect-invites';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type LocalStorageService from 'garaje/services/local-storage';
import type MessageBusService from 'garaje/services/message-bus';
import type MetricsService from 'garaje/services/metrics';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { or, reads } from 'macro-decorators';

import { type InvitesModel } from './route';

const DEFAULT_PAGE_SIZE = 30;
const DEFAULT_DATE_RANGE = 'Today';

export const CURRENT_PRINTER_KEY = 'current_printer';

function isInvitePendingPropertyApproval(invite: ConnectInviteModel, abilities: AbilitiesService) {
  return (
    // invite requires some kind of approval
    invite.needsApprovalReview &&
    // the invite requires approval for a property blocklist rule
    invite.approvalStatus.failedReport
      .filter((report) => report.source === 'blacklist')
      .some((report) => report.blacklistFilterSource === 'Envoy::MultiTenancy::Zone' && report.status === 'review') &&
    // the invite can otherwise be approved by the current user
    abilities.can('review entry-approval', {
      context: 'property',
      report: invite.approvalStatus.failedReport,
    })
  );
}

function isInvitePendingOnlyPropertyApproval(invite: ConnectInviteModel, abilities: AbilitiesService) {
  return isInvitePendingPropertyApproval(invite, abilities) && invite.approvalStatus.failedReport.length === 1;
}

const QUERY_PARAMS_TO_RESET_WHEN_CHANGING_PROPERTIES: Array<keyof PropertyVisitorsInvitesController> = [
  'pageNumber',
  'search',
  'suiteIds',
  'tenantIds',
];

export default class PropertyVisitorsInvitesController extends Controller {
  queryParamDefaults?: Record<string, unknown>;

  declare model: InvitesModel;

  @service declare abilities: AbilitiesService;
  @service declare ajax: AjaxService;
  @service declare connectInvites: ConnectInvitesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare localStorage: LocalStorageService;
  @service declare messageBus: MessageBusService;
  @service declare metrics: MetricsService;

  queryParams = [
    'search',
    'dateTo',
    'tenantIds',
    'suiteIds',
    'pageNumber',
    'pageSize',
    'sortBy',
    'sortDirection',
    'status',
    'dateFrom',
    'selectedDateRange',
  ];

  defaultRecordCount = 0;

  @tracked pageSize = DEFAULT_PAGE_SIZE;
  @tracked sortBy?: string;
  @tracked sortDirection?: string;
  @tracked tenantIds = '';
  @tracked suiteIds = '';
  @tracked search = '';
  @tracked status = '';
  @tracked pageNumber = 1;
  @tracked selectedDateRange = DEFAULT_DATE_RANGE;
  @tracked dateFrom = format(new Date(), API_DATE_FORMAT);
  @tracked dateTo = format(new Date(), API_DATE_FORMAT);

  get selectedPrinter(): PropertyPrinterModel | null | undefined {
    const selectedPrinterId = this.localStorage.getItem(CURRENT_PRINTER_KEY);
    return selectedPrinterId ? this.printers?.findBy('id', selectedPrinterId) : null;
  }
  set selectedPrinter(printer: PropertyPrinterModel | null | undefined) {
    this.localStorage.setItem(CURRENT_PRINTER_KEY, printer?.id);
  }

  @reads('invites.meta.total') metaRecordCount: number | undefined;
  @reads('model.loadTenantsTask.value') tenants?: ArrayProxy<TenantModel>;
  @reads('model.loadPrintersTask.value') printers?: ArrayProxy<PropertyPrinterModel> | undefined;

  @or('metaRecordCount', 'defaultRecordCount') recordCount!: number;

  get invites(): ArrayProxy<ConnectInviteModel> | ConnectInviteModel[] {
    return this.model.loadDataTask.lastSuccessful?.value?.invites ?? [];
  }

  get extraColumnData(): Record<string, ColumnHeader[]> {
    return this.model.loadDataTask.lastSuccessful?.value?.extraColumnData ?? {};
  }

  get extraColumnHeaders(): ColumnHeader[] {
    return this.model.loadDataTask.lastSuccessful?.value?.extraColumnHeaders ?? [];
  }

  get pageTitle(): string {
    if (this.search && !this.showLoadingIndicator) {
      return `${pluralize(this.recordCount, 'Visitor')} matching "${this.search}"`;
    } else {
      return 'Invite log';
    }
  }

  get showLoadingIndicator(): boolean {
    return this.model.loadDataInForegroundTask.isRunning;
  }

  get showClearButton(): boolean {
    return this.search !== '';
  }

  get reviewableInvites(): ConnectInviteModel[] {
    return this.invites.filter((invite) => isInvitePendingPropertyApproval(invite, this.abilities));
  }

  get nonReviewableInvites(): ConnectInviteModel[] {
    return this.invites.filter((invite) => !isInvitePendingOnlyPropertyApproval(invite, this.abilities));
  }

  constructor(properties?: object) {
    super(properties);
    const queryParamDefaults = (<Array<keyof PropertyVisitorsInvitesController>>this.queryParams).reduce<
      Record<string, unknown>
    >((acc, key) => {
      if (QUERY_PARAMS_TO_RESET_WHEN_CHANGING_PROPERTIES.includes(key)) {
        acc[key] = this[key];
      }

      return acc;
    }, {});
    // capture query param defaults to reset them when needed
    this.queryParamDefaults = queryParamDefaults;
  }

  @action
  onSelectedDateRange(option: string, start: string, end: string): void {
    this.dateFrom = start;
    this.dateTo = end;
    this.selectedDateRange = option;
    this.pageNumber = 1;
  }

  @action
  onSelectedSuite(suite: ZoneModel): void {
    let ids = this.suiteIds.split(',').filter((el) => isPresent(el));
    ids = ids.includes(suite.id) ? ids.filter((id) => id !== suite.id) : [...ids, suite.id];
    this.suiteIds = ids.join(',');
    this.pageNumber = 1;
  }

  @action
  onSelectedTenant(tenant: TenantModel): void {
    let ids = this.tenantIds.split(',').filter((el) => isPresent(el));
    ids = ids.includes(tenant.id) ? ids.filter((id) => id !== tenant.id) : [...ids, tenant.id];
    this.tenantIds = ids.join(',');
    this.pageNumber = 1;
  }

  @action
  updateStatus(status: string): void {
    this.status = status;
    this.pageNumber = 1;
  }

  @action
  onDeselectAll(type: string): void {
    if (type === 'tenants') {
      this.tenantIds = '';
    } else {
      this.suiteIds = '';
    }
    this.pageNumber = 1;
  }

  @action
  onSearchInput(evt: Event): void {
    if (evt?.target instanceof HTMLInputElement) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      debounce(this, this._doSearch, evt.target.value, 200);
    }
  }

  @action
  clearSearch(): void {
    this.search = '';
  }

  @action
  goToEditCheckIn(invite: ConnectInviteModel): void {
    // router service transition to unnecessarily reloads the tenants route model
    this.transitionToRoute('property.visitors.invites.edit-checkin', invite.id); // eslint-disable-line ember/no-deprecated-router-transition-methods
  }

  @action
  goToEdit(invite: ConnectInviteModel): void {
    // router service transition to unnecessarily reloads the tenants route model
    this.transitionToRoute('property.visitors.invites.edit', invite.id); // eslint-disable-line ember/no-deprecated-router-transition-methods
  }

  @action
  selectPrinter(printer: PropertyPrinterModel): void {
    this.selectedPrinter = printer;
    this.flashMessages.showAndHideFlash('success', 'Printer changed');
  }

  _doSearch(search: string): void {
    this.search = search;
    this.pageNumber = 1;
  }

  resetQueryParams(): void {
    Object.assign(this, this.queryParamDefaults);
  }

  approveInviteTask = enqueueTask(async (invite: ConnectInviteModel) => {
    try {
      await invite.approveInvite();
      await invite.reload();

      let additionalFlashMessageText;
      if (invite.approved) {
        additionalFlashMessageText = 'Invitation sent!';
      } else {
        additionalFlashMessageText = 'The tenant will also need to approve this before the invitation is sent.';
      }

      this.flashMessages.showAndHideFlash('success', 'Access approved', additionalFlashMessageText);
      const sourcesForApprovalReviewMetrics = (invite.approvalStatus?.failedReport || []).reduce(
        (sources, report) => ({ ...sources, [report.source]: true }),
        {},
      );
      this.metrics.trackEvent('Property Invite - Reviewed', {
        action: 'approve',
        invite_id: invite.id,
        source: 'Invite Log',
        ...sourcesForApprovalReviewMetrics,
      });
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', 'Error approving invite', parseErrorForDisplay(error));
    }
  });
}

import { action } from '@ember/object';
import type ObjectProxy from '@ember/object/proxy';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';
import type LocationModel from 'garaje/models/location';
import type ScimIntegrationModel from 'garaje/models/scim-integration';
import type ScimPrimaryLocationConfigurationModel from 'garaje/models/scim-primary-location-configuration';
import type ScimPrimaryLocationMappingModel from 'garaje/models/scim-primary-location-mapping';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type ScimPrimaryLocationService from 'garaje/services/scim-primary-location';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { formatScimProvider } from 'garaje/utils/format-scim-provider';

interface ScimPrimaryLocationsBoxArgs {
  integration: string;
  openAction: (name: string) => void;
  closeAction: () => void;
  scimIntegration: ScimIntegrationModel;
  primaryLocationConfig: ScimPrimaryLocationConfigurationModel;
  primaryLocationMappings: Array<ScimPrimaryLocationMappingModel>;
  locations: Array<ObjectProxy<LocationModel>>;
}

export default class ScimPrimaryLocationsBox extends Component<ScimPrimaryLocationsBoxArgs> {
  @service declare currentAdmin: CurrentAdminService;
  @service declare scimPrimaryLocation: ScimPrimaryLocationService;
  @service declare store: StoreService;
  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;

  get connected(): boolean {
    return !isEmpty(this.args.scimIntegration);
  }

  get isOpen(): boolean {
    return this.args.integration === 'scim-primary-location';
  }

  get hasPrimaryLocConfig(): boolean {
    return !isEmpty(this.args.primaryLocationConfig);
  }

  get hasDistinctMappingValues(): boolean {
    return !isEmpty(this.scimPrimaryLocation.distinctMappingValues?.distinctValues);
  }

  get hasPrimaryLocMappings(): boolean {
    return !isEmpty(this.args.primaryLocationMappings);
  }

  get formattedProvider(): string {
    return formatScimProvider(this.args.scimIntegration.provider);
  }

  get pillText(): string {
    if (this.hasPrimaryLocConfig && this.hasDistinctMappingValues) {
      const unmappedValues = this.scimPrimaryLocation.unmappedDistinctValues(this.args.primaryLocationMappings);
      if (unmappedValues && unmappedValues.length > 0) {
        return pluralize(unmappedValues.length, 'unmapped value');
      } else {
        return 'Active';
      }
    } else {
      return '';
    }
  }

  startSync = task({ drop: true }, async () => {
    try {
      const primaryLocConfig = this.store.createRecord('scim-primary-location-configuration');
      primaryLocConfig.scimIntegration = this.args.scimIntegration;
      await primaryLocConfig.save();

      this.flashMessages.showAndHideFlash('success', 'Primary location sync has started');
      this.refreshRoute();
      this.close();
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showFlash('error', errorText);
    }
  });

  @action
  open(): void {
    this.args.openAction('scim-primary-location');
  }

  @action
  close(): void {
    this.args.closeAction();
  }

  @action
  refreshRoute(): void {
    this.flashMessages.showAndHideFlash('success', 'Refreshing SCIM primary location values');
    void this.router.refresh('employees.scim-settings');
  }
}

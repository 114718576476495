import { service } from '@ember/service';
import Component from '@glimmer/component';
import type MetricsService from 'garaje/services/metrics';
import { EXAMPLE_ANNOUNCEMENT_TEMPLATES } from 'garaje/utils/enums';

export default class NewAnnouncementTemplateModalComponent extends Component {
  @service declare metrics: MetricsService;

  get templates(): typeof EXAMPLE_ANNOUNCEMENT_TEMPLATES {
    return EXAMPLE_ANNOUNCEMENT_TEMPLATES;
  }
}

// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { restartableTask, timeout } from 'ember-concurrency';
import urlBuilder from 'garaje/utils/url-builder';

const DEBOUNCE_MS = 250;
const RESULTS_COUNT = 5;

/**
 * @param {Number|String}           inputId
 * @param {String}                  name
 * @param {Function}                updateName
 * @param {Number|String}           placeId
 * @param {Function}                updatePlaceId
 * @param {Boolean}                 isDisabled
 */
export default class LocalityAutocomplete extends Component {
  @service ajax;

  tagName = '';
  disabled = false;

  // arguments
  name = null;
  placeId = null;
  updateName = null;
  updatePlaceId = null;

  @computed('placeId', 'name')
  get selection() {
    const { placeId, name } = this;
    if (placeId && name) {
      return { id: placeId, name };
    } else {
      return null;
    }
  }

  @restartableTask
  *autocompleteTask(term) {
    if (!term) {
      return [];
    }
    yield timeout(DEBOUNCE_MS);
    const url = urlBuilder.v3.placesAutocomplete(term);
    try {
      const { data } = yield this.ajax.request(url);
      const formattedResults = data
        .slice(0, RESULTS_COUNT)
        .map((place) => ({ id: place.id, name: place.attributes.description }));
      return formattedResults;
    } catch (e) {
      return [];
    }
  }

  isValid(userInput, selection) {
    const isBlank = !userInput;
    const isSelection = userInput === selection;
    return isBlank || isSelection;
  }

  @action
  didSelect(option) {
    if (option && option.name && option.id) {
      this.updateName(option.name);
      this.updatePlaceId(option.id);
    }
  }

  @action
  didType(userInput) {
    if (this.selection && this.selection.name !== userInput) {
      this.updateName('');
      this.updatePlaceId('');
    }
    this.autocompleteTask.perform(userInput);
  }
}

import Controller from '@ember/controller';
import NotificationConfig from 'garaje/models/notification-config';
import zft from 'garaje/utils/zero-for-tests';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import { task, restartableTask, timeout } from 'ember-concurrency';
import { notEmpty } from 'macro-decorators';
import { tracked } from '@glimmer/tracking';
import { SLACK_V2_PLUGIN_KEY, MSTEAMS_V2_PLUGIN_KEY } from 'garaje/utils/enums';

const SAVE_NOTIFICATION_CONFIG_DEBOUNCE = zft(1000);

export default class DeliveriesSettingsNotificationsController extends Controller {
  @service featureFlags;
  @service flashMessages;
  @tracked slackV2PluginKey = SLACK_V2_PLUGIN_KEY;
  @tracked msTeamsV2PluginKey = MSTEAMS_V2_PLUGIN_KEY;

  notificationDelayOptions = NotificationConfig.NOTIFICATION_DELAY_OPTIONS;
  notificationReminderIntervalOptions = NotificationConfig.REMINDER_INTERVAL_OPTIONS;
  allowedPlugins = ['email', this.slackV2PluginKey, this.msTeamsV2PluginKey];

  @notEmpty('slackIntegration') slackIntegrationEnabled;

  get slackIntegration() {
    const installedPlugins = this.model.pluginInstalls.filterBy('status', 'active');
    return installedPlugins.filterBy('plugin.key', this.slackV2PluginKey);
  }

  @task
  *toggleNotificationSetting(type, value) {
    const { notificationConfig } = this.model;

    switch (type) {
      case 'delivery':
        set(notificationConfig, 'notificationsEnabled', value);
        break;
      case 'email':
        set(notificationConfig, 'emailNotificationsEnabled', value);
        break;
      case 'slack':
        set(notificationConfig, 'slackNotificationsEnabled', value);
        break;
    }

    yield this.saveNotificationConfig.perform();
  }

  @restartableTask
  *saveNotificationConfig() {
    const { notificationConfig } = this.model;

    yield timeout(SAVE_NOTIFICATION_CONFIG_DEBOUNCE);

    try {
      yield notificationConfig.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      notificationConfig.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', 'Error saving changes.');
    }
  }

  @task
  *setNotificationDelay({ value }) {
    const { notificationConfig } = this.model;
    set(notificationConfig, 'notificationDelay', value);
    yield this.saveNotificationConfig.perform();
  }

  @task
  *setnotificationReminderInterval({ value }) {
    const { notificationConfig } = this.model;
    set(notificationConfig, 'notificationReminderInterval', value);
    yield this.saveNotificationConfig.perform();
  }
}

import { helper } from '@ember/component/helper';

/**
 * The desks map uses leaflet latlng on a 1px equals 1 mapUnit scale
 * The backend saves coordinates on a
 */

/**
 * Converts envoy xPos/yPos to leaflet Latlng
 *
 * @returns {object}
 */
export function toLatLngArray([desk, mapDimensions]) {
  const [mapWidth, mapHeight] = mapDimensions;
  return [-desk.yPos * mapHeight, desk.xPos * mapWidth];
}

export function toLatLngObject(desk, mapDimensions) {
  const [mapWidth, mapHeight] = mapDimensions;
  return { lat: -desk.yPos * mapHeight, lng: desk.xPos * mapWidth };
}

/**
 * Converts leaflet Latlng to envoy xPos/yPos
 *
 * @param {latlng}    latlng            Leaflet latlng object. null to clear
 * @param {array}     mapDimensions     Dimensions of the floorplan map
 * @returns {object}                    Coordinates as x/y
 */
export function toCoords({ lng, lat }, [mapWidth, mapHeight]) {
  return {
    xPos: lng / mapWidth,
    yPos: Math.abs(lat / mapHeight),
  };
}

export default helper(toLatLngArray);

import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import type VisitorEntranceModel from 'garaje/models/visitor-entrance';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { localCopy } from 'tracked-toolbox';

interface VisitorEntranceFormComponentSignature {
  Args: {
    visitorEntrance: VisitorEntranceModel;
  };
}

export default class VisitorEntranceFormComponent extends Component<VisitorEntranceFormComponentSignature> {
  @service declare flashMessages: FlashMessagesService;

  @localCopy('args.visitorEntrance.name', '') updatedName!: string;

  /**
   *  Whether or not `visitorEntrance` has dirty changes
   */

  get changesOnFly(): boolean {
    return (
      <boolean>(<unknown>this.args.visitorEntrance.hasDirtyAttributes) ||
      this.updatedName != this.args.visitorEntrance.name
    );
  }

  /**
   * Whether or not `visitorEntrance` is in a valid state
   */
  get isValid(): boolean {
    if (!this.changesOnFly) {
      return true;
    }
    const { useLocationAddress, addressLineOne } = this.args.visitorEntrance;
    return isPresent(this.updatedName) && (useLocationAddress || isPresent(addressLineOne));
  }

  /**
   * @task `saveTask`
   */
  saveTask = dropTask(async (event: Event) => {
    event.preventDefault();
    try {
      this.args.visitorEntrance.name = this.updatedName.trim();
      await this.args.visitorEntrance.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });
}

import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type Link from 'ember-link/link';
import type LinkManager from 'ember-link/services/link-manager';
import type { GetRoomQuery } from 'garaje/graphql/generated/roomba-types';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import type RoombaMetricsService from 'garaje/services/roomba-metrics';
import type RoomsPollingService from 'garaje/services/rooms-polling';

export default class RoombaRoomController extends Controller {
  @service declare flashMessages: FlashMessagesService;
  @service declare roombaGraphql: RoombaGraphqlService;
  @service declare linkManager: LinkManager;
  @service declare roombaMetrics: RoombaMetricsService;
  @service declare roomsPolling: RoomsPollingService;
  @service declare featureConfig: FeatureConfigService;

  @tracked declare room: GetRoomQuery['room'];

  get backLink(): Link {
    return this.linkManager.createUILink({ route: 'roomba.list' });
  }

  get maskMeetingNamesSettingActive(): boolean {
    return !!this.featureConfig.isEnabled('roomMeetings.maskNames');
  }

  refetchRoom = task({ restartable: true }, async () => {
    this.room = await this.roomsPolling.fetchRoomById.perform(this.room.id);
  });

  toggleMaskMeetingNamesSettingTask = task({ drop: true }, async (maskMeetingNames: boolean) => {
    this.roombaMetrics.trackEvent(`mask_meeting_names_${this.room.maskMeetingNames ? 'enabled' : 'disabled'}`, {
      room: this.room.id,
    });

    try {
      await this.roombaGraphql.updateRoomConfig({
        id: this.room.id,
        maskMeetingNames,
      });
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (_e) {
      this.flashMessages.showAndHideFlash('error', 'Error saving configuration changes');
    }
  });

  toggleRoomClaimabilityTask = task({ drop: true }, async (claimable: boolean) => {
    this.roombaMetrics.trackEvent(`impromptu_meetings_${claimable ? 'enabled' : 'disabled'}`, {
      room: this.room.id,
    });

    try {
      await this.roombaGraphql.updateRoomConfig({
        id: this.room.id,
        claimable,
      });
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (_e) {
      this.flashMessages.showAndHideFlash('error', 'Error saving configuration changes');
    }
  });
}

import Model, { attr, hasMany, type AsyncHasMany } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

import type HelpResourcesItem from './help-resources-item';
import type HelpdeskItem from './helpdesk-item';
import type MarketingItem from './marketing-item';
import type SetupGuideItemModel from './setup-guide-item';

@adapter('growth')
class HomepageItem extends Model {
  @attr('string')
  declare identifier: string;

  @attr('string')
  declare title: string;

  @attr('string')
  declare description: string;

  @attr('immutable', {
    defaultValue: () => ({}),
  })
  declare metadata: object;

  @attr('boolean')
  declare userClosed: boolean;

  @attr('string')
  declare status: string;

  @attr('number')
  declare userDefinedRank: number;

  @attr('number')
  declare width: number;

  @attr('number')
  declare height: number;

  @hasMany('setup-guide-item')
  declare setupGuideItems: AsyncHasMany<SetupGuideItemModel>;

  @hasMany('marketing-item')
  declare marketingItems: AsyncHasMany<MarketingItem>;

  @hasMany('helpdesk-item')
  declare helpdeskItems: AsyncHasMany<HelpdeskItem>;

  @hasMany('help-resources-item')
  declare helpResourcesItems: AsyncHasMany<HelpResourcesItem>;

  get helpdeskItem(): HelpdeskItem | undefined {
    return this.helpdeskItems.firstObject;
  }

  get helpResourcesItem(): HelpResourcesItem | undefined {
    return this.helpResourcesItems.firstObject;
  }

  get componentName(): string | null {
    switch (this.identifier) {
      case 'setup_guide_items':
        return 'homepage/setup-guide';
      case 'marketing_items':
        return 'homepage/marketing';
      case 'helpdesk_items':
        return 'homepage/helpdesk';
      case 'help_resources_items':
        return 'homepage/help-resource';
    }

    return null;
  }
}

export default HomepageItem;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'homepage-item': HomepageItem;
  }
}

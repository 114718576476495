import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { filterBy } from 'macro-decorators';
import { dropTask } from 'ember-concurrency';

/**
 * @param {Function}                cancel
 * @param {Function}                chooseNewDeliveryArea
 * @param {Function<Task>}          confirmTransferTask
 * @param {Array<DeliveryArea>}     deliveryAreas
 * @param {Number}                  deliveryCount
 * @param {Array}                   origins
 */
export default class ChooseNewDeliveryArea extends Component {
  @tracked selectedArea;

  @filterBy('args.deliveryAreas', 'enabled') activeDeliveryAreas;

  @dropTask
  *selectNewAreaTask(deliveryArea) {
    this.selectedArea = deliveryArea;
    yield this.args.confirmTransferTask.perform({ destination: deliveryArea, origins: this.args.origins });
  }
}

import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class GlobalAnalyticsOccupancySettingsIndexRoute extends Route {
  title = 'Occupancy Global Analytics Settings · Envoy';

  @service abilities;
  @service router;
  @service state;

  redirect() {
    if (this.abilities.cannot('visit occupancy-global-settings')) {
      this.router.transitionTo('unauthorized');
    } else {
      this.router.transitionTo('location-overview.occupancy.settings');
    }
  }
}

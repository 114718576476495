import Model, { attr } from '@ember-data/model';

class CopyGlobalFlowModel extends Model {
  @attr('string') declare companyName: string;
  @attr('string') declare name: string;
  @attr('string') declare description: string;
}

export default CopyGlobalFlowModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'copy-global-flow': CopyGlobalFlowModel;
  }
}

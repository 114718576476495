import { service } from '@ember/service';
import type ConnectPropertyConfigurationModel from 'garaje/models/connect-property-configuration';
import ApplicationSerializer from 'garaje/serializers/application';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type { SinglePayload } from 'jsonapi/response';

export default class ConnectPropertyConfigurationSerializer extends ApplicationSerializer {
  @service declare featureFlags: FeatureFlagsService;

  serialize(...args: Parameters<ApplicationSerializer['serialize']>): SinglePayload<ConnectPropertyConfigurationModel> {
    const payload = <SinglePayload<ConnectPropertyConfigurationModel>>super.serialize(...args);

    if (!this.featureFlags.isEnabled('connect-pre-reg-acs-qr-code')) {
      delete payload.data.attributes['tenant-invite-acs-credentialing-enabled'];
    }

    return payload;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'connect-property-configuration': ConnectPropertyConfigurationSerializer;
  }
}

import Component from '@glimmer/component';

/**
 * @param {Array}               notifications
 * @param {Boolean}             isLoading
 * @param {Boolean}             isError
 * @param {Function}            retry
 * @param {Boolean}             noHost
 * @param {String}              signedInVia
 */
export default class NotificationActivity extends Component {
  get hasMultipleRecipients() {
    return this.args.notifications.length > this.args.notifications.uniqBy('title').length;
  }
}

import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { get } from '@ember/object';
import { apiAction } from '@mainmatter/ember-api-actions';
import { dependentKeyCompat } from '@ember/object/compat';

class GlobalSettingBatch extends Model {
  // Relations
  @belongsTo('propagable', { polymorphic: true }) parent;
  @hasMany('propagable', { polymorphic: true }) children;

  // Attributes to propagate
  // `location`, `config`, `flow`, `signInFields` are the models where we can propagate changes
  // on the API. These are represent by plain objects. Default values are provided at the time
  // `createRecord` is called on these - using `defaultValue` in the @attr call results in these
  // not being saved correctly (when the `updatedAttributes` array is changed, ember-data doesn't
  // recognize that, and doesn't serialize the new value.)
  /*
      "location": {
        "updatedAttributes": ["preRegistrationEnabled", "ccReceptionistEnabled", ...]
      }
  */
  @attr location;
  /*
      "config": {
        "updatedAttributes": ["beenHereBefore", "selfSignOut", ...]
      }
  */
  @attr config;
  /*
      "flow": {
        "updatedAttributes": ["name", ...],
        "agreementPage": {
          "updatedAttributes": ["requireResign", "sendToBcc", ...],
        },
        "photoPage": {
          "updatedAttributes": ["enabled", ...],
        },
        "summaryPage": {
          "updatedAttributes": ["videoUrl", ...],
        }
      }
  */
  @attr flow;
  /*
      "signInFields": [1, 2, 3, 4]
  */
  @attr('array', { defaultValue: () => [] }) signInFields;

  @dependentKeyCompat
  get totalSettingsChanged() {
    const totalUpdatedAttributes = this.allUpdatedAttributes.length;
    const totalSignInFields = this.signInFields.length;
    return totalUpdatedAttributes + totalSignInFields;
  }

  get allUpdatedAttributes() {
    const attributes = ['config', 'flow', 'flow.agreementPage', 'flow.summaryPage', 'flow.photoPage', 'location'];
    return attributes.reduce((updated, attr) => {
      const updatedAttributes = get(this, `${attr}.updatedAttributes`) ?? [];
      return [...updated, ...updatedAttributes];
    }, []);
  }

  async undo() {
    return await apiAction(this, { method: 'POST', path: 'undo' });
  }
}

export default GlobalSettingBatch;

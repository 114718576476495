import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { DetailedChangeset } from 'ember-changeset/types';
import { dropTask } from 'ember-concurrency';
import type ZoneModel from 'garaje/models/zone';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { localeOptions } from 'garaje/utils/locale-options';
import type LOCALES from 'garaje/utils/locale-options';
import { TrackedSet } from 'tracked-built-ins';

interface PropertyKioskLanguageSettingsComponentArgs {
  property: ZoneModel;
  changeset: DetailedChangeset<ZoneModel>;
}

type Locale = (typeof LOCALES)[number];

export default class PropertyKioskLanguageSettingsComponent extends Component<PropertyKioskLanguageSettingsComponentArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare transitionConfirm: TransitionConfirmService;

  @tracked isOpen = false;

  get currentLocale(): Locale | undefined {
    const property = this.args.property;
    return this.localeOptions.find(({ value }) => property.locale === value) ?? this.localeOptions[0];
  }

  get currentLocaleName(): string | undefined {
    return this.currentLocale?.label;
  }

  get enabledLocales(): string[] {
    const changeset = this.args.changeset;
    // add the default locale to the list from `enabledLocales` for display in the UI

    // use `.values()` because `changeset.enabledLocales` is a Proxy and not the actual TrackedSet, and thus not iterable
    return [changeset.locale, ...changeset.enabledLocales.values()];
  }

  get isSaveButtonDisabled(): boolean {
    return this.args.changeset.isPristine || this.saveTask.isRunning;
  }

  get localeOptions(): Locale[] {
    const propertyDevices = this.args.property.devices;

    // `@property.devices` is a PromiseManyArray; if @property is deleted (using the "Delete" button on the same
    // page that includes this component), this array will be torn down, and this getter will re-trigger with
    // a destroyed/destroying object. Using that leads to a "Cannot call filter before content is assigned."
    // error in the call to `localeOptions`, which breaks the page.
    const devices = propertyDevices.isDestroying || propertyDevices.isDestroyed ? [] : propertyDevices.toArray();

    return localeOptions(devices);
  }

  @action
  addLocale({ value: locale }: { value: string }): void {
    this.args.changeset.enabledLocales.add(locale);

    // ember-changeset doesn't detect changes to a TrackedSet, so we need to trick it
    this.#updateEmberChangeset();
  }

  @action
  cancel(): void {
    const performCancel = () => {
      // put this in a separate arrow function so it has the correct 'this'-binding
      this.isOpen = false;
      this.args.changeset.rollback();
    };
    void this.transitionConfirm.displayConfirmTask.perform(null, {
      header: 'Do you want to cancel?',

      continue() {
        performCancel();
      },
    });
  }

  @action
  deleteLocale(locale: string): void {
    this.args.changeset.enabledLocales.delete(locale);

    // ember-changeset doesn't detect changes to a TrackedSet, so we need to trick it
    this.#updateEmberChangeset();
  }

  @action
  open(): void {
    this.isOpen = true;
  }

  @action
  setDefaultLocale(locale: string): void {
    // for legacy reasons that are poorly understood, the backend expects (and REQUIRES) that the default locale
    // NOT be included in the `enabled-locales` array. This means that when we change the default locale, we
    // need to add the old default to the array and remove the new default from the array, in addition to updating
    // the `locale` attribute.
    const changeset = this.args.changeset;
    changeset.enabledLocales.add(changeset.locale);
    changeset.enabledLocales.delete(locale);
    changeset.locale = locale;
  }

  saveTask = dropTask(async () => {
    try {
      await this.args.changeset.save();
      this.flashMessages.showAndHideFlash('success', 'Kiosk language settings saved!');
      this.isOpen = false;
    } catch {
      this.flashMessages.showAndHideFlash('error', 'Something went wrong');
    }
  });

  #updateEmberChangeset() {
    // ember-changeset doesn't mark the changeset as dirty when a TrackedSet is changed.
    // (This is the case with version 3.15.0, which is what was in use in garaje at the time this code was writte,
    // as well as in version 4.1.1, which was the latest available at the time.)
    // As a result, we need to aassign a new value to the `enabledLocales` property for the changeset to
    // track the "dirty" state correctly. The use of `.values` is also needed because `changeset.enabledLocales`
    // returns a Proxy to the TrackedSet (not the TrackedSet itself), which is not iterable.
    const changeset = this.args.changeset;
    changeset.enabledLocales = new TrackedSet(changeset.enabledLocales.values());
  }
}

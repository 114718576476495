import gql from 'graphql-tag';

export default gql`
  query AnnouncementTemplateNamesForLocationByCategoryQuery($locationId: ID!) {
    announcementTemplateNamesForLocationByCategory(locationId: $locationId) {
      id
      name
      templates {
        id
        name
      }
    }
  }
`;

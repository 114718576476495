import { helper } from '@ember/component/helper';

// We have to list out the names here so the URL's get transformed
// to have the correct fingerprint attached
const brotherQL710wImage = '/assets/images/printers/brother-ql710w.png';
const brotherQL720nwImage = '/assets/images/printers/brother-ql720nw.png';
const brotherQL820nwbImage = '/assets/images/printers/brother-ql820nwb.png';
const integrationImage = '/assets/images/printers/integration.png';

const supportedModelsToImages = {
  'Brother QL700 Series': brotherQL710wImage,
  'Brother QL-710W': brotherQL710wImage,
  'Brother QL-720NW': brotherQL720nwImage,
  'Brother QL-800': brotherQL820nwbImage,
  'Brother QL-810W': brotherQL820nwbImage,
  'Brother QL-820NWB': brotherQL820nwbImage,
  Integration: integrationImage,
  printnode: integrationImage,
};

export function printerImage(modelName) {
  const filename = supportedModelsToImages[modelName];

  return filename || brotherQL710wImage;
}

export default helper(printerImage);

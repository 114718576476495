import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type DetailedChangeset } from 'ember-changeset/types';
import { dropTask, type Task } from 'ember-concurrency';
import type ZoneModel from 'garaje/models/zone';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

interface PropertyFormAccentColorArgs {
  changeset: DetailedChangeset<ZoneModel>;
  saveTask: Task<void, [DetailedChangeset<ZoneModel>]>;
}

export default class PropertyFormAccentColor extends Component<PropertyFormAccentColorArgs> {
  @tracked isOpen = false;
  @tracked accentColor = this.args.changeset.accentColor ?? 'ef3934';

  @action
  cancel(): void {
    this.isOpen = false;
    this.args.changeset.rollback();
  }

  saveTask = dropTask(async () => {
    await this.args.saveTask.perform(this.args.changeset).catch(throwUnlessTaskDidCancel);
    this.isOpen = false;
  });
}

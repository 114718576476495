import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';

export default class VisitorsSettingsVisitorTypesFlowVisitorEmailsController extends Controller {
  @service abilities;

  @tracked unchanged = true;

  get welcomeMailerTemplate() {
    return this.model.mailerTemplates.findBy('identifier', 'welcome');
  }

  get canManageWelcomeEmailTemplates() {
    return this.abilities.can('customize email for visitor-email') && isPresent(this.welcomeMailerTemplate);
  }

  @action
  onChange({ hasChanges }) {
    this.unchanged = !hasChanges;
  }
}

import Controller from '@ember/controller';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';

export default class ConnectPermissionsController extends Controller {
  @service declare router: RouterService;
  get backLinkUrl(): string {
    const currentRouteName = this.router.currentRouteName;

    if (currentRouteName?.startsWith('manage.location-settings')) {
      return 'manage.location-settings.index.connect';
    } else {
      return 'location-settings.index.connect';
    }
  }
}

// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import type ComputedProperty from '@ember/object/computed';
import Service, { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type GlobalSettingBatchModel from 'garaje/models/global-setting-batch';
import { reads } from 'macro-decorators';

export default class GlobalSettingBatchService extends Service {
  declare _globalSettingBatch: GlobalSettingBatchModel;

  @service declare store: StoreService;

  @reads('_globalSettingBatch') currentGlobalSettingBatch!: GlobalSettingBatchModel;

  #createModel(attrs: Record<string, unknown>): GlobalSettingBatchModel {
    return this.store.createRecord('global-setting-batch', {
      config: { updatedAttributes: [] },
      flow: {
        updatedAttributes: [],
        agreementPage: { updatedAttributes: [] },
        photoPage: { updatedAttributes: [] },
        summaryPage: { updatedAttributes: [] },
      },
      location: { updatedAttributes: [] },
      ...attrs,
    });
  }

  createGlobalSettingBatch(attrs: Record<string, unknown>): void {
    // Make sure there is only one instance of `globalSettingBatch`
    this.store.unloadAll('global-setting-batch');
    this._globalSettingBatch = this.#createModel(attrs);
  }

  // Althought this model is singleton, we need to create a copy everytime the propagation
  // has been done. So we can re-use the parent information if the user wants to propagate
  // again in the same page
  async makeACopy(): Promise<GlobalSettingBatchModel> {
    const currentGlobalSettingBatch = this.currentGlobalSettingBatch;
    const currentParent = await currentGlobalSettingBatch.parent;

    return this.#createModel({
      parent: currentParent,
      signInFields: [],
    });
  }

  switchWithExitingCopy(): void {
    const currentGlobalSettingBatch = this.currentGlobalSettingBatch;
    const store = this.store;
    const copy = store
      .peekAll('global-setting-batch')
      .filterBy('isNew', <ComputedProperty<boolean, boolean>>(<unknown>true)).firstObject;
    if (copy) {
      store.unloadRecord(currentGlobalSettingBatch);
      this._globalSettingBatch = copy;
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'global-setting-batch': GlobalSettingBatchService;
  }
}

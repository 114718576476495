import { service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

import type { SupportedAgreements } from '../component';

interface OnsiteRequiredComponentSignature {
  Args: {
    model: SupportedAgreements;
    trackLegalDocument: () => Promise<void>;
  };
}

export default class OnsiteRequiredComponent extends Component<OnsiteRequiredComponentSignature> {
  @service declare flashMessages: FlashMessagesService;

  toggleRequiredOnsiteTask = dropTask(async (value: boolean) => {
    const { model, trackLegalDocument } = this.args;
    model.requiredOnsite = value;
    try {
      await model.save();
      await trackLegalDocument();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });
}

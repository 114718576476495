import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class InteractiveDemoModal extends Component {
  @tracked isLoading = true;

  constructor() {
    super(...arguments);
  }

  @action
  iframeLoaded() {
    this.isLoading = false;
  }
}

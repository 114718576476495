import { helper } from '@ember/component/helper';
import leaflet from 'leaflet';
import { later } from '@ember/runloop';

// Fixes known Chrome scrolling bug
// https://github.com/Leaflet/Leaflet/issues/4125
leaflet.Control.include({
  _refocusOnMap: leaflet.Util.falseFn,
});

leaflet.Control.DeskMapZoomControl = leaflet.Control.Zoom.extend({
  onAdd: function (map) {
    later(this, 'addMouseoverHandler');
    return leaflet.Control.Zoom.prototype.onAdd.call(this, map);
  },

  onRemove: function (map) {
    this.removeMouseoverHandler(map);
    return leaflet.Control.Zoom.prototype.onRemove.call(this, map);
  },

  addMouseoverHandler: function () {
    const { onMouseover, onMouseout } = this.options;
    if (!onMouseover || !onMouseout) {
      // eslint-disable-next-line no-console
      console.error('Developer error: if using this extension, you must pass mouseover and mouseout events');
      return;
    }

    leaflet.DomEvent.on(this.getContainer(), 'mouseover', onMouseover);
    leaflet.DomEvent.on(this.getContainer(), 'mouseout', onMouseout);
  },

  removeMouseoverHandler: function () {
    const { onMouseover, onMouseout } = this.options;
    if (!onMouseover || !onMouseout) {
      return;
    }

    leaflet.DomEvent.off(this.getContainer(), 'mouseover', onMouseover);
    leaflet.DomEvent.off(this.getContainer(), 'mouseout', onMouseout);
  },

  container: function () {
    return this.getContainer();
  },
});

leaflet.control.deskMapZoomControl = function (opts) {
  return new leaflet.Control.DeskMapZoomControl(opts);
};

export const zoomControl = function ([map, position], hash) {
  if (!map) {
    return;
  }
  const options = Object.assign({ position: position || 'topright', desksMap: map }, hash);
  const zoom = leaflet.control.deskMapZoomControl(options);
  map.addControl(zoom);
};

export default helper(zoomControl);

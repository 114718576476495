import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type StatsigService from 'garaje/services/statsig';

export default class ActionItemTemplate extends Component<{
  header: string;
  headerInfo: string;
  subHeader: string;
  iconLink: string;
  iconAlt: string;
  pageLink: string;
  isUserLoggedIn: boolean;
  demoName: string;
  isDemoInstance: boolean;
}> {
  @service declare statsig: StatsigService;

  @action sendLinkClickEvent(): void {
    if (this.args.isUserLoggedIn) {
      window?.open(this.args.pageLink, '_blank');
      if (!this.args.isDemoInstance) {
        this.statsig.logEvent(`kiosk-demo-${this.args.demoName}-link-clicked`, this.args.header, {
          linkSrc: this.args.pageLink,
        });
      }
    }
  }
}

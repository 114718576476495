/* eslint-disable ember/no-component-lifecycle-hooks */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';

import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

/**
 * Modal form for deleting payment method
 * @param {Function}             onClose       Required - triggered when closing modal
 * @param {Class<PaymentSource>} paymentSource Required - A payment source model to delete
 * @param {String}               viaObject     Required - Type of CTA used to open this modal (button, hover, text_link)
 */

export default class DeletePaymentMethodModal extends Component {
  @service flashMessages;
  @service store;

  @dropTask
  *deletePaymentSource() {
    try {
      yield this.paymentSource.destroyRecord();

      if (this.paymentSource.isPrimary) {
        this.needReload();
        this.store.unloadAll('payment-source');
      }

      this.onClose();
      this.flashMessages.showAndHideFlash('success', 'Payment source is deleted!');
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showFlash('error', errorText);
    }
  }
}

import { action, set } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type DetailedChangeset } from 'ember-changeset/types';
import type TicketCategoryModel from 'garaje/models/ticket-category';
import type TicketCategoryEmailModel from 'garaje/models/ticket-category-email';

interface TicketCategoryEmailFormArgs {
  changeset: DetailedChangeset<TicketCategoryEmailModel>;
  ticketCategories: Array<TicketCategoryModel>;
  onDelete: (changeset: TicketCategoryEmailChangeset) => void;
}

type DefaultCategory = { name: string };

type TicketCategoryEmailChangeset = DetailedChangeset<TicketCategoryEmailModel>;

export default class TicketCategoryEmailFormComponent extends Component<TicketCategoryEmailFormArgs> {
  @tracked emailErrors: Array<unknown> = [];

  defaultCategory = { name: 'All Categories' };

  get categories(): Array<TicketCategoryModel | DefaultCategory> {
    return [this.defaultCategory, ...this.args.ticketCategories];
  }

  get selectedCategory(): TicketCategoryModel | DefaultCategory {
    return this.args.changeset['ticketCategory'] || this.defaultCategory;
  }

  @action
  focusInput(element: HTMLElement): void {
    element.focus();
  }

  @action
  async onTicketCategoryEmailChange(email: string): Promise<void> {
    set(this.args.changeset, 'email', email);
    await this.args.changeset.validate();

    const errors = this.args.changeset.errors[0];
    this.emailErrors = errors ? [errors.validation[0]] : [];
  }

  @action
  onCategorySelected(category: TicketCategoryModel): void {
    if (category == this.defaultCategory) {
      set(this.args.changeset, 'ticketCategory', null);
    } else {
      set(this.args.changeset, 'ticketCategory', category);
    }
  }
}

import Model, { attr } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';
import serializer from 'garaje/utils/decorators/serializer';

@adapter('v2')
@serializer('ams')
class SlideshowModel extends Model {
  @attr('string') declare url: string;
}

export default SlideshowModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    slideshow: SlideshowModel;
  }
}

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { set, action } from '@ember/object';
import { task, dropTask } from 'ember-concurrency';
import { formatMinutesAsTime } from 'garaje/utils/format-minutes-as-time';
import { typeOf } from '@ember/utils';

/**
 * @param {Model<Location>}           currentLocation
 * @param {Model<Config>}             config
 */
export default class VisitorsSettingsSignOutComponent extends Component {
  @service flashMessages;
  @service metrics;
  @service state;
  @service workplaceMetrics;

  @dropTask
  *toggleSelfSignOut(value) {
    this.workplaceMetrics.trackEvent('LOCATION_SETTINGS_SELF_SIGN_OUT_TOGGLED', { toggledSetting: value });
    const { config } = this.args;
    set(config, 'selfSignOut', value);

    try {
      yield config.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      config.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @task
  *saveTask() {
    const { currentLocation } = this.args;
    if (currentLocation.hasDirtyAttributes === false) {
      return;
    }
    const { autoSignOutAtMinutesSinceMidnight } = currentLocation.changedAttributes();
    const [original, changed] = autoSignOutAtMinutesSinceMidnight;
    let action = original === null ? 'Enabled' : 'Edited';
    action = changed === null ? 'Disabled' : action;
    try {
      yield currentLocation.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this._trackAutoSignOutEvent(action, currentLocation.id, autoSignOutAtMinutesSinceMidnight[1]);
    } catch (e) {
      currentLocation.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @action
  updateAutoSignOutAtMinutesSinceMidnight(mins) {
    const { currentLocation } = this.args;
    currentLocation.autoSignOutAtMinutesSinceMidnight = mins;
    currentLocation.autoSignOutAtMidnight = typeOf(mins) === 'number';
  }

  _trackAutoSignOutEvent(action, locationId, autoSignOutAtMinutesSinceMidnight) {
    this.metrics.trackEvent(`Auto Sign Out ${action}`, {
      location_id: locationId,
      sign_out_at:
        autoSignOutAtMinutesSinceMidnight === null ? null : formatMinutesAsTime(autoSignOutAtMinutesSinceMidnight),
    });
  }
}

import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type FlowModel from 'garaje/models/flow';
import type LocationModel from 'garaje/models/location';
import type TenantModel from 'garaje/models/tenant';
import type StateService from 'garaje/services/state';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

export interface VisitorTypesIndexRouteModel {
  connectedTenants: RecordArray<TenantModel>;
  allFlowsForCurrentLocation: RecordArray<FlowModel>;
  currentLocation: LocationModel;
  flowsWithNoPhoneNumberRequired?: RecordArray<FlowModel>;
}

export default class VisitorTypesIndexRoute extends Route {
  @service declare state: StateService;
  @service declare store: StoreService;

  async model(): Promise<VisitorTypesIndexRouteModel> {
    const { currentLocation } = this.state;
    const emergencyNotificationConfiguration = await currentLocation.getEmergencyNotificationConfiguration();

    const connectedTenants = await currentLocation.getPropertyConnections();
    const filter: Record<string, string> = { location: currentLocation.id, employee_centric: 'true,false' }; // filter includes both employee screening and regular flows

    // include property owned flows if the location is connected to a property
    if (<number>connectedTenants.length > 0) filter['property_owned'] = 'true,false';

    const allFlowsForCurrentLocation = this.store.query('flow', {
      include: 'global-flow',
      filter,
    });

    let flowsWithNoPhoneNumberRequired;
    if (emergencyNotificationConfiguration.emergencyNotificationsEnabledAt !== null) {
      flowsWithNoPhoneNumberRequired = this.store.query('flow', {
        include: 'global-flow',
        filter: {
          location: currentLocation.id,
          employee_centric: false,
          phone_number_required: false,
        },
      });
    }

    return hash({
      connectedTenants,
      allFlowsForCurrentLocation,
      currentLocation,
      flowsWithNoPhoneNumberRequired,
    });
  }
}

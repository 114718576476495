import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class EmployeesDirectoryAddEmployeeFromLocationController extends Controller {
  @service employeeDirectory;
  @service router;
  @service skinnyLocations;
  @service state;
  @service store;

  queryParams = ['employeeId'];

  @tracked isDirty = false;
  @tracked employeeId = null;

  @action
  searchEmployees(term, includeLocations) {
    const params = {
      filter: {
        query: term,
        deleted: false,
        locations: this.state.currentLocation.id,
      },
    };

    if (includeLocations) {
      params.include = 'employee-locations,locations';
      params.filter.locations = this.skinnyLocations.currentCompanyLocations.map(({ id }) => id).join(',');
    }

    return this.store.query('employee', params);
  }

  @action
  async addEmployee(newEmployee) {
    const locations = await get(newEmployee, 'locations');
    locations.pushObject(this.state.currentLocation);
    return await newEmployee.save();
  }

  afterAddEmployeeTask = task(async (employee) => {
    this.employeeDirectory.addEmployee(employee);
    await this.state.checkRestrictionsTask.perform(true);
    this.router.transitionTo('employees.directory.employee', employee.id);
  });
}

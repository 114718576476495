import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type AbilitiesService from 'ember-can/services/abilities';

import type { GetCanReviewOrReadBlocklistMatchModel } from './security';

const sourceToAbilityMap = {
  blacklist: 'blocklist',
  'property-invite-approval': 'approval-required-invites', // for property walk-in visitors that need approval; for now, use the same ability as for approving outgoing invites from Visitors
  'requires-approval': 'approval-required-invites',
};

/**
 * This is a quick hack to deal with the fact that both Locations and Properties (Zones)
 * can have blocklists, but (at the moment) no other screeners are available to Properties.
 *
 * If you are here because you are about to add another screener to the list of ones that are
 * allowed for properties, **DO NOT DO IT!** Now is the time to step back and handle this the
 * right way, probably by having the backend include something on _all_ screeners indicating
 * their source (location, property, etc).
 *
 * Why is this array/check here, rather than in the appropriate ability for the screener?
 * I'm glad you asked - it's because at the time this code was written, I wasn't able to get
 * a comprehensive list of all the screeners that exist.
 * PG 2022-06-30
 */
const SCREENERS_AVAILABLE_TO_PROPERTIES = ['blocklist'];

export default class EntryApprovalAbility extends Ability {
  declare model?: GetCanReviewOrReadBlocklistMatchModel;

  @service declare abilities: AbilitiesService;

  get canReview(): boolean {
    if (!Array.isArray(this.model?.report)) {
      return false;
    }
    return this.model.report
      .filter((report) => report.reviewable)
      .every((report) => {
        const source = sourceToAbilityMap[<keyof typeof sourceToAbilityMap>report.source] || report.source;
        if (this.model?.context === 'property' && !SCREENERS_AVAILABLE_TO_PROPERTIES.includes(source)) {
          return false;
        }
        return this.abilities.can(`review ${source}`, this.model);
      });
  }
}

import Component from '@glimmer/component';
import { isBlank } from '@ember/utils';

/**
 * @param {Array<Location|SkinnyLocation>}            selectedLocations  Current selected locations
 * @param {Object}                                    option
 * @param {Boolean}                                   subLevel
 * @param {Function}                                  onToggle
 * @param {Function}                                  employeeScreeningFlow
 */

export default class EmployeeMultiLocationsListLocationRowComponent extends Component {
  /**
   * @return {Boolean}
   */
  get isChecked() {
    if (isBlank(this.args.selectedLocations)) {
      return false;
    }
    return Boolean(this.args.selectedLocations.find((location) => location.id === this.args.option.location.id));
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { and, not } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';

/**
 * @param {String} accept                 Required. File accept type (png, jpg, .csv, etc.)
 * @param {String} displayType            Required. The display type
 * @param {Function} onFileSelect         Required. Callback when user makes selection. If not passed, use the upload url
 * @param {String} currentFileName        Optional. The current file name
 * @param {Function} onClean              Optional. Callback when clearing the file
 * @param {Boolean} processing            Optional. State of the processing, if applicable
 * @param {Number} maxFileSize            Optional. Maximum file size allowed, in mb
 */
export default class DragAndDropBox extends Component {
  @tracked dragging = false;
  @tracked fileTypeValid = true;
  @tracked fileSizeValid = true;
  @tracked dragCollection = [];

  @and('fileTypeValid', 'fileSizeValid') isClean;
  @not('isClean') isDirty;

  @action
  handleFile(file) {
    this.fileTypeValid = this.validateFileType(file.type);
    this.fileSizeValid = this.validateFileSize(file.size);

    if (this.isDirty) {
      return;
    }

    this.dragCollection = [];
    this.args.onFileSelect(file);
  }

  @action
  handleDragEnter(e) {
    if (!this.dragging) {
      this.dragging = true;

      if (e.dataTransfer.items !== null) {
        const { type: fileType } = e.dataTransfer.items[0];

        if (!this.validateFileType(fileType)) {
          this.fileTypeValid = false;
        }
      }
    }

    this.dragCollection.push(e.target);
  }

  @action
  handleDragLeave(e) {
    this.dragCollection = this.dragCollection.filter((el) => el !== e.target);

    if (isEmpty(this.dragCollection)) {
      this.fileSizeValid = true;
      this.fileTypeValid = true;
      this.dragging = false;
    }
  }

  @action
  handleDrop(e) {
    const [file] = e.dataTransfer.files;
    this.handleFile(file);
    this.dragging = false;
  }

  @action
  handleClick() {
    if (this.isDirty) {
      document.querySelector('#file-upload').click();
    }
  }

  @action
  validateFileType(fileType) {
    return this.args.accept.includes(fileType);
  }

  @action
  validateFileSize(fileSize) {
    if (!this.args.maxFileSize) {
      return true;
    }

    const maxFileSize = 1024 * 1024 * this.args.maxFileSize;
    return fileSize <= maxFileSize;
  }
}

import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import type ConnectInviteModel from 'garaje/models/connect-invite';
import type PropertyPrinterModel from 'garaje/models/property-printer';
import type TenantModel from 'garaje/models/tenant';
import type VisitorContactModel from 'garaje/models/visitor-contact';
import type ZoneModel from 'garaje/models/zone';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type { RecordArray } from 'garaje/utils/type-utils';
import { localCopy } from 'tracked-toolbox';

import type { MultiZonesSelectorArgs } from '../../../multi-zones-selector/component';

import type { DateRangeSelectComponentArgs } from './date-range-select/component';

interface PropertyVisitorsInvitesTableComponentArgs {
  dateFrom?: string;
  dateTo?: string;
  dateRangeFilter: string;
  /**
   * map from invite IDs to an array of objects with additional columns to display in the table
   */
  extraColumnData: Record<string, unknown[]>;
  /**
   * array of objects representing additional column headers to display in the table
   */
  extraColumnHeaders: unknown[];
  invites: ConnectInviteModel[];
  isLoadingTenants: boolean;
  loadingData: boolean;
  onDateRangeChange: DateRangeSelectComponentArgs['dateRangeFilter'];
  onDeselectAllSuites: MultiZonesSelectorArgs['onDeselectAll'];
  onDeselectAllTenants: MultiZonesSelectorArgs['onDeselectAll'];
  onEditCheckIn: (invite: ConnectInviteModel) => unknown;
  onEdit: (invite: ConnectInviteModel) => unknown;
  onNewInvite: () => unknown;
  onStatusChange: (status: string) => unknown;
  onSuiteSelected: MultiZonesSelectorArgs['onSelected'];
  onTenantSelected: MultiZonesSelectorArgs['onSelected'];
  pageCount: number;
  pageNumber: number;
  pageSize: number;
  printer?: PropertyPrinterModel;
  property: ZoneModel;
  recordCount: number;
  search: string;
  setPageNumber: (pageNumber: number) => unknown;
  setSortBy: (sortBy: string) => unknown;
  setSortDirection: (sortDirection: string) => unknown;
  selectedTenantIds: string[];
  selectedSuiteIds: string[];
  sortDirection: string;
  status: string;
  tenants: RecordArray<TenantModel>;
}

export default class PropertyVisitorsInvitesTableComponent extends Component<PropertyVisitorsInvitesTableComponentArgs> {
  @service declare store: Store;
  @service declare flashMessages: FlashMessagesService;

  textForStatus = {
    '': 'All statuses',
    'checked-in': 'Arrived',
    'not-checked-in': 'Not here yet',
  };

  @tracked suites: ZoneModel[] = [];
  @tracked visitorContacts: VisitorContactModel[] = [];

  @localCopy('args.tenants', []) tenants!: PropertyVisitorsInvitesTableComponentArgs['tenants'];

  constructor(owner: unknown, args: PropertyVisitorsInvitesTableComponentArgs) {
    super(owner, args);
    void this.loadSuitesTask.perform();
  }

  get visitorContactsByTenantId(): Record<string, VisitorContactModel[]> {
    return this.visitorContacts?.reduce<Record<string, VisitorContactModel[]>>((hash, contact) => {
      if (!hash[contact.tenant.id]) hash[contact.tenant.id] = [];
      hash[contact.tenant.id]?.push(contact);
      return hash;
    }, {});
  }

  loadSuitesTask = dropTask(async () => {
    const { property } = this.args;
    this.suites = (
      await this.store.query('zone', {
        filter: { parent: property.id },
        page: {
          limit: 100,
        },
        sort: 'name',
      })
    ).toArray();
  });

  loadContactsTask = dropTask(async () => {
    if (!this.tenants.length) return;
    this.visitorContacts = (
      await this.store.query('visitor-contact', {
        filter: { tenant: this.tenants.mapBy('id').join() },
        include: 'tenant',
      })
    ).toArray();
  });
}

import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class DevicesNewConnectionForm extends Component {
  get isGlobalConnection() {
    return !!this.printerPasscode?.belongsTo('company').id();
  }

  get printerPasscode() {
    return this.args.fetchOneTimePasscodeTask.lastSuccessful?.value;
  }

  @action
  toggleGlobalConnection(checked) {
    this.args.fetchOneTimePasscodeTask.perform(checked);
  }
}

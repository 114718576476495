import Component from '@glimmer/component';
import moment from 'moment-timezone';

/**
 * @param {String}            value
 * @param {Date}              closestTime
 */
export default class DropdownOption extends Component {
  get isClosest() {
    return moment(this.args.value, 'h:mm a').isSame(moment(this.args.closestTime));
  }
}

import Component from '@glimmer/component';

const MAX_RATIO = 1;
const HIGH_RATIO = 0.8;

export default class VisitorsCapacityIndicator extends Component {
  get capacityRatio() {
    const { capacityUsed, capacityLimit } = this.args;
    return capacityLimit >= 0 ? capacityUsed / capacityLimit : 0;
  }

  get isOverCapacity() {
    return this.capacityRatio > MAX_RATIO;
  }

  get isAtCapacity() {
    return this.capacityRatio == MAX_RATIO;
  }

  get isNearCapacity() {
    return this.capacityRatio >= HIGH_RATIO && this.capacityRatio < MAX_RATIO;
  }

  get isUnderCapacity() {
    return this.capacityRatio < HIGH_RATIO;
  }
}

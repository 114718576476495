import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { filter, sort } from '@ember/object/computed';
import Service, { service } from '@ember/service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { enqueueTask } from 'ember-concurrency';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type LocalStorageService from 'garaje/services/local-storage';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';
import { gt, bool, not, filterBy } from 'macro-decorators';

import type CurrentAdminService from './current-admin';

export default class SkinnyLocationsService extends Service {
  @tracked model;

  constructor(properties: Record<string, unknown>) {
    super(properties);
    this.model = A<SkinnyLocationModel>([]);
    void this.loadAllTask.perform();
  }

  @service declare currentAdmin: CurrentAdminService;
  @service declare localStorage: LocalStorageService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare store: Store;
  @service declare state: StateService;

  sortProperties = ['nameWithCompanyName'];
  @sort('model', 'sortProperties') content!: NativeArray<SkinnyLocationModel>;
  @gt('model.length', 1) hasMultipleLocations!: boolean;

  @bool('loadAllTask.last.isSuccessful') isLoaded!: boolean;
  @not('isLoaded') isLoading!: boolean;

  loadAllTask = enqueueTask(async (forceReload = false) => {
    if (this.loadAllTask.lastSuccessful && !forceReload) {
      return this.model;
    }

    // must always fetch VFD configuration since this request is before feature flags and initSubscriptionStateTask
    const include = ['company', 'groups', 'vfd-configuration', 'vfd-configuration.schedule'];

    const locationFields = [
      'address-line-one',
      'company',
      'company-name-override',
      'disabled',
      'disabled-to-employees-at',
      'enabled-locales',
      'groups',
      'latitude',
      'locale',
      'longitude',
      'name',
      'timezone', // For VFD Call Log
      'vfd-configuration',
    ];
    if (this.featureFlags.isEnabled('protectSchedulingLimits')) {
      locationFields.push('user-document-requirements');
    }

    // pull in property (zone) if property management is enabled
    if (this.state.connectSubscription) {
      include.push('property');
      locationFields.push('property');
    }

    const allLocations = await this.store.query('skinny-location', {
      include: include.join(','),
      fields: {
        locations: locationFields.join(','),
        companies: 'name',
        groups: 'name',
      },
    });
    this.model = A(allLocations.toArray());

    return;
  });

  /**
   * Reads localStorage and returns an object where
   * We store recent locations by `{ locationId: [array, of, location, ids] }`
   */
  #readRecentLocations(): Record<string, string[]> {
    // eslint-disable-next-line ember/no-get
    const recentLocationsIdsString = this.localStorage.getItem('recentLocations') ?? '{}';
    return <Record<string, string[]>>JSON.parse(recentLocationsIdsString);
  }

  /*
   * Array of recently visit location ids for the current company
   */
  get recentLocationsIds(): string[] {
    const { currentCompany } = this.state;
    const recentLocations = this.#readRecentLocations();
    return recentLocations[`${currentCompany.id}`] || [];
  }

  /**
   * Array of recently visited locations for the current company
   */
  @computed('recentLocationsIds.[]', 'content.[]')
  get recentLocations(): SkinnyLocationModel[] {
    const allLocations = this.content;
    const recentLocationsIds = this.recentLocationsIds || [];
    return allLocations.filter(({ id }) => recentLocationsIds.includes(id));
  }

  @filter('content', ['state.currentCompany.id'], function (this: SkinnyLocationsService, location) {
    return (<SkinnyLocationModel>location).belongsTo('company').id() === this.state.currentCompany?.id;
  })
  currentCompanyLocations!: SkinnyLocationModel[];

  @filterBy('currentCompanyLocations', 'disabled', false)
  active!: SkinnyLocationModel[];

  @computed('currentCompanyLocations.[]')
  get vfdActive(): SkinnyLocationModel[] {
    // eslint-disable-next-line ember/use-ember-get-and-set
    return this.currentCompanyLocations.filter((location) => location.vfdConfiguration?.get('enabled'));
  }

  @computed('currentCompanyLocations.[]', 'state.currentUser.{id,locationRoles.[]}')
  get readableByLocationAdmin(): SkinnyLocationModel[] {
    if (!this.state.currentUser?.id) {
      return [];
    }
    const locationIds = this.state.currentUser.locationRoles.reduce<string[]>((ids, locationRole) => {
      return locationRole.roleName === 'Location Admin' ? [...ids, locationRole.belongsTo('location').id()] : ids;
    }, []);
    return this.currentCompanyLocations.filter((location) => locationIds.includes(location.id));
  }

  /**
   * Returns all locations where the user has a location role or where
   * the location belongs to a company where the user is Global Admin or Billing.
   */
  @computed('content.[]', 'currentAdmin.{user.id,companyRoles.[],locationRoles.[]}')
  get readableByCurrentAdmin(): SkinnyLocationModel[] {
    if (!this.currentAdmin.user?.id) {
      return [];
    }
    const companiesWhereUserHasCompanyRole = this.currentAdmin.companyRoles
      .filter(Boolean)
      .map((companyRole) => companyRole.belongsTo('company').id());
    const locationsWhereUserHasLocationRole = this.currentAdmin.locationRoles
      .filter(Boolean)
      .map((locationRole) => locationRole.belongsTo('location').id());
    return this.content.filter((skinnyLocation) => {
      return (
        companiesWhereUserHasCompanyRole.includes(skinnyLocation.belongsTo('company').id()) ||
        locationsWhereUserHasLocationRole.includes(skinnyLocation.id)
      );
    });
  }

  /**
   * Returns all locations where the user has a *Location Admin role* or where
   * the location belongs to a company where the user is *Global Admin*.
   */
  @computed('content.[]', 'currentAdmin.{user.id,companyRoles.[],locationRoles.[]}')
  get manageableByCurrentAdmin(): SkinnyLocationModel[] {
    if (!this.currentAdmin.user?.id) {
      return [];
    }
    const companiesWhereUserHasCompanyRole = this.currentAdmin.companyRoles
      .filter((companyRole) => companyRole && companyRole.roleName === GLOBAL_ADMIN)
      .map((companyRole) => companyRole.belongsTo('company').id());
    const locationsWhereUserHasLocationRole = this.currentAdmin.locationRoles
      .filter((locationRole) => locationRole && locationRole.roleName === LOCATION_ADMIN)
      .map((locationRole) => locationRole.belongsTo('location').id());
    return this.content.filter((skinnyLocation) => {
      return (
        companiesWhereUserHasCompanyRole.includes(skinnyLocation.belongsTo('company').id()) ||
        locationsWhereUserHasLocationRole.includes(skinnyLocation.id)
      );
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'skinny-locations': SkinnyLocationsService;
  }
}

import Component from '@glimmer/component';
import { service } from '@ember/service';
import { alias } from 'macro-decorators';

export default class PairingTheIpad extends Component {
  @service visitorsOnboarding;

  @alias('visitorsOnboarding.hasIpad') hasIpad;
  @alias('visitorsOnboarding.s3Url') s3Url;

  constructor() {
    super(...arguments);
    this.visitorsOnboarding.loadIpadsTask.perform();
  }
}

import gql from 'graphql-tag';

import DeskFragment from './DeskFragment';

export default gql`
  fragment AssignedEmployeeFragment on AssignedEmployee {
    id
    name
    email
    department
    attendance {
      averageDaysPerWeek
    }
    assignedDesk {
      ...DeskFragment
    }
  }
  ${DeskFragment}
`;

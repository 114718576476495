import ApplicationSerializer from './application';

export default class LocationsSetupGuideStepSerializer extends ApplicationSerializer {
  attrs = {
    completed: {
      serialize: false,
    },
    pageResourceId: {
      serialize: false,
    },
    prerequisiteComplete: {
      serialize: false,
    },
    firstViewedAt: {
      serialize: false,
    },
  };
}

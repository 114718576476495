import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type { GetRoomsQuery } from 'garaje/graphql/generated/roomba-types';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import type RoombaMetricsService from 'garaje/services/roomba-metrics';
import type StateService from 'garaje/services/state';

export default class RoomsPollingService extends Service {
  @service declare roombaGraphql: RoombaGraphqlService;
  @service declare roombaMetrics: RoombaMetricsService;
  @service declare state: StateService;

  @tracked roomsList: GetRoomsQuery['rooms'] = [];

  fetchRooms = task(this, { restartable: true }, async () => {
    try {
      this.roomsList = await this.roombaGraphql.getRooms(this.state.currentLocation?.id);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error on fetchRooms', e);
      throw e;
    }

    return this.roomsList;
  });

  fetchRoomById = task(this, { restartable: true }, async (roomId: string) => {
    try {
      return await this.roombaGraphql.getRoom(roomId);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error on fetchRoomById', e);
      throw e;
    }
  });
}

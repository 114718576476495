import { computed, get } from '@ember/object';
import { isBlank } from '@ember/utils';

export default function falsyComputed(property) {
  return computed(property, function () {
    const attr = get(this, property);
    switch (typeof attr) {
      case 'boolean':
        return !attr;
      default:
        return isBlank(attr);
    }
  });
}

/* eslint-disable ember/no-computed-properties-in-native-classes */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action, computed, get, set } from '@ember/object';
import { equal, alias, empty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { resolve } from 'rsvp';
import { dropTask } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';

/**
 * @param {String}                    integration
 * @param {Array}                     filterOptions
 * @param {Array}                     directoryGroups
 * @param {Array}                     locations
 * @param {Model<Company>}            company
 * @param {Boolean}                   canEdit
 * @param {Function}                  save
 * @param {Function}                  handleFailedQuery
 * @param {Function}                  hideFlash
 * @param {Function}                  showFlash
 * @param {Function}                  addFilter
 * @param {Function}                  removeFilter
 * @param {Function}                  preview
 * @param {Boolean}                   previewEnabled
 */
export default class LocationsFilter extends Component {
  @service flashMessages;
  @service syncManager;

  @equal('integration.filterType', 'locations') isActive;
  @alias('isActive') isOpen;
  @empty('company.employeesLastSyncAt') neverSynced;

  @computed('locations.[]')
  get isVisible() {
    return get(this, 'locations.length') > 1;
  }

  setFilter() {
    return this.integration.save();
  }

  setPreviewResults(users) {
    const results = get(this, 'locations').map(function (location) {
      const locationId = parseInt(location.id);

      return {
        location,
        results: users.filter(function (user) {
          return user.locations.indexOf(locationId) >= 0;
        }),
      };
    });

    set(this, 'results', results);
  }

  @dropTask
  *saveTask() {
    return yield this.setFilter().then(() => {
      set(this, 'results', []);
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    });
  }

  @dropTask
  *testTask() {
    this.hideFlash?.();

    set(this, 'results', []);

    let promise;
    if (!get(this, 'integration.filters.length')) {
      this.flashMessages.showAndHideFlash(
        'error',
        'Enable at least 1 filter for any location, otherwise use the sync all filter',
      );

      promise = resolve(false);
    } else {
      promise = yield get(
        this,
        'preview',
      )(get(this, 'integration')).then(
        (users) => {
          this.setPreviewResults(users);
        },
        (response) => {
          get(this, 'handleFailedQuery')(response);
        },
      );
    }

    return promise;
  }

  @action
  activate() {
    set(this, 'results', []);
    if (get(this, 'canEdit') && !get(this, 'isActive')) {
      set(this, 'integration.filterType', 'locations');

      const attrs = get(this, 'integration').changedAttributes();
      if (get(this, 'integration.hasDirtyAttributes') && !attrs.filterType) {
        get(this, 'integration').rollbackAttributes();
      } else {
        set(this, 'integration.filters', new TrackedArray());
      }
    }
  }

  @action
  cancel() {
    get(this, 'integration').rollbackAttributes();
    set(this, 'results', []);
  }
}

// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import $ from 'jquery';

/**
 * @param {Boolean}             hasMorePages
 * @param {Boolean}             useWindow
 * @param {Function}            action
 * @param {String}              viewPortSelector
 * @param {Booelan}             useGetBoundingClientRect
 */
export default class LoadMore extends Component {
  // eslint-disable-next-line ember/no-component-lifecycle-hooks
  didInsertElement() {
    super.didInsertElement(...arguments);
    const offset = 500;
    let viewPort;

    let isElementInViewport = function (el) {
      const { top } = $(el).position();
      const viewPortHeight = viewPort.height();
      return top <= viewPortHeight + offset;
    };

    if (this.useWindow) {
      viewPort = $(window);
      isElementInViewport = this.isElementInViewport;
    } else {
      viewPort = $(this.element).parents(this.viewPortSelector);
    }

    if (this.useGetBoundingClientRect) {
      isElementInViewport = this.isElementInViewport;
    }

    const fireIfElementVisible = function (el, callback) {
      return function () {
        if (isElementInViewport(el)) {
          callback();
        }
      };
    };

    const handler = fireIfElementVisible(this.element, () => {
      if (this.action) {
        this.action();
      }
    });

    const namespace = `loadMore_${this.elementId}`;
    viewPort.on(`DOMContentLoaded.${namespace} load.${namespace} resize.${namespace} scroll.${namespace}`, handler);
  }

  // Use getBoundingClientRect to check if element in viewPort
  isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    const bottomOffset = 500;

    return rect.top >= 0 && rect.bottom - bottomOffset <= (window.innerHeight || document.documentElement.clientHeight);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    let viewPort;

    if (this.useWindow) {
      viewPort = $(window);
    } else {
      if (!$(this.element)) {
        return;
      }

      viewPort = $(this.element).parents(this.viewPortSelector);
    }

    const namespace = `loadMore_${this.elementId}`;

    viewPort.off(`.${namespace}`);
  }
}

import Component from '@glimmer/component';

/**
 *  The CSV Table is used in Blocklist, Desks.
 *  Displays the csv data when the user needs to review their csv details prior to uploading.
 *
 * @param {Array<String>} headers List of header column names
 * @param {Array<Object>} records List of csv records
 * @param {String} type The type of record being saved. Used pluralized for copy in table components
 * @param {Object} columns POJO of columnDataKey: columnName
 * @param {Function} saveTask Task to save the csv to the backend
 * @param {Integer} importedCount # of imported records, if polling for status
 */

export default class CsvTable extends Component {
  tagName = '';
}

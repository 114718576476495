import type ArrayProxy from '@ember/array/proxy';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import type PluginInstallModel from 'garaje/models/plugin-install';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import { alias } from 'macro-decorators';

export default class TeamsUpgrade extends Component {
  @service declare abilities: AbilitiesService;
  @service declare featureFlags: FeatureFlagsService;

  @alias('args.pluginInstalls') declare pluginInstalls: ArrayProxy<PluginInstallModel> | undefined;
  @tracked dismissed = false;

  get showBanner(): boolean {
    if (!this.pluginInstalls) {
      return false;
    }

    const installedPluginKeys = this.pluginInstalls
      .toArray()
      .filter((pluginInstall) => pluginInstall.status === 'active')
      // eslint-disable-next-line ember/no-get
      .map((pluginInstall) => get(pluginInstall, 'plugin.key'));

    const isTeamsInstalled = installedPluginKeys.includes('msteams');

    return this.abilities.can('see-upgrade-banner teams-upgrade') && isTeamsInstalled;
  }

  get showLiveMessage(): boolean {
    return this.featureFlags.isEnabled('ms-teams-v2');
  }

  @action
  dismiss(): void {
    this.dismissed = true;
  }
}

// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { reads } from '@ember/object/computed';

// eslint-disable-next-line ember/no-classic-classes
export default Component.extend({
  tagName: '',
  collection: null,

  total: reads('collection.length'),
});

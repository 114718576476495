import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { assert } from '@ember/debug';

export default class FlowsScreeningNegativeResultSkipperComponent extends Component {
  @service flashMessages;

  get isDisabled() {
    return this.args.isDisabled || this.toggle.isRunning;
  }

  @task
  *toggle() {
    const { model } = this.args;

    assert(
      'Flows::Screening::NegativeResultSkipperComponent - Must provide a model',
      typeof model?.save === 'function',
    );

    model.toggleProperty('skipEmployeeScreeningNegativeTestResult');

    try {
      yield model.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch {
      model.toggleProperty('skipEmployeeScreeningNegativeTestResult');
      this.flashMessages.showAndHideFlash('error', 'Error saving changes.');
    }
  }
}

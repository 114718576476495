import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { alias } from 'macro-decorators';

export default class LocationThemeComponent extends Component {
  @service state;

  @alias('state.currentLocation') currentLocation;

  get buttonColor() {
    return this.currentLocation.buttonColor;
  }

  get buttonTextColor() {
    return this.currentLocation.buttonTextColor;
  }

  get sidebarBackground() {
    const { slideUrl } = this.currentLocation;
    if (slideUrl) {
      return `url(${this.currentLocation.slideUrl})`;
    }
    return 'linear-gradient(to bottom, rgba(233, 233, 234, 0.2), rgba(40, 44, 54, 0.15))';
  }
}

import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * @param {Function}            requestTrial
 * @param {Boolean}             didRequestTrial
 * @param {Function}            closeModal
 */
export default class UpgradeModalContent extends Component {
  @action
  onKeyUp(e) {
    switch (e.key) {
      case 'Esc': // IE/Edge specific value
      case 'Escape':
        this.args.closeModal && this.args.closeModal();
        e.stopPropagation();
        e.preventDefault();
        break;
    }
  }
}

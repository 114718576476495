import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import LocationOverviewGlobalDevicesRoute from 'garaje/pods/location-overview/global-devices/route';

class LocationOverviewVirtualFrontDeskDevicesRoute extends LocationOverviewGlobalDevicesRoute {
  controllerName = 'location-overview/global-devices';
  templateName = 'location-overview/global-devices';

  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  beforeModel(): void {
    if (this.abilities.can('read global-devices')) {
      void this.router.transitionTo('location-overview.virtual-front-desk.devices.ipads');
    } else {
      void this.router.transitionTo('unauthorized');
    }
  }
}

export default LocationOverviewVirtualFrontDeskDevicesRoute;

import Transform from '@ember-data/serializer/transform';
import { TrackedObject } from 'tracked-built-ins';

export default class TrackedObjectTransform extends Transform {
  deserialize(value) {
    return new TrackedObject(value || {});
  }

  serialize(value) {
    return Object.keys(value) === 0 ? null : value;
  }
}

import { helper } from '@ember/component/helper';
/* eslint-disable camelcase */
const PRODUCT_MAP = {
  'iPad1,1': 'iPad',
  'iPad2,1': 'iPad 2',
  'iPad2,2': 'iPad 2 (GSM)',
  'iPad2,3': 'iPad 2 (CDMA)',
  'iPad2,4': 'iPad 2',
  'iPad2,5': 'iPad mini',
  'iPad2,6': 'iPad mini (GSM)',
  'iPad2,7': 'iPad mini (CDMA)',
  'iPad3,1': 'iPad (3rd gen)',
  'iPad3,2': 'iPad (3rd gen, LTE)',
  'iPad3,3': 'iPad (3rd gen, LTE)',
  'iPad3,4': 'iPad (4th gen)',
  'iPad3,5': 'iPad (4th gen, LTE)',
  'iPad3,6': 'iPad (4th gen, CDMA)',
  'iPad4,1': 'iPad Air',
  'iPad4,2': 'iPad Air (LTE)',
  'iPad4,3': 'iPad Air (TD-LTE, China)',
  'iPad4,4': 'iPad mini 2',
  'iPad4,5': 'iPad mini 2 (LTE)',
  'iPad4,6': 'iPad mini 2 (China)',
  'iPad4,7': 'iPad mini 3',
  'iPad4,8': 'iPad mini 3 (LTE)',
  'iPad4,9': 'iPad mini 3 (China)',
  'iPad5,1': 'iPad mini 4',
  'iPad5,2': 'iPad mini 4 (LTE)',
  'iPad5,3': 'iPad Air 2',
  'iPad5,4': 'iPad Air 2 (LTE)',
  'iPad6,11': 'iPad (9.7-inch)',
  'iPad6,12': 'iPad (9.7-inch LTE)',
  'iPad6,3': 'iPad Pro (9.7-inch)',
  'iPad6,4': 'iPad Pro (9.7-inch LTE)',
  'iPad6,7': 'iPad Pro (12.9-inch)',
  'iPad6,8': 'iPad Pro (12.9-inch LTE)',
  'iPad7,1': 'iPad Pro (12.9-inch, 2nd gen)',
  'iPad7,2': 'iPad Pro (12.9-inch, 2nd gen, LTE)',
  'iPad7,3': 'iPad Pro (10.5-inch)',
  'iPad7,4': 'iPad Pro (10.5-inch, LTE)',
  x86_32: 'iPad Simulator (32-bit)',
  x86_64: 'iPad Simulator (64-bit)',
};
/* eslint-enable camelcase */
export function appleProductName(deviceType) {
  return PRODUCT_MAP[deviceType] || deviceType;
}

export default helper(appleProductName);

import Component from '@glimmer/component';

/**
 * @param {Class}       model
 * @param {Task}        deleteTask
 * @param {Boolean}     hasGroupParent
 * @param {Function}    closeModal
 * @param {String}      userType
 */
export default class DeleteItemConfirmModal extends Component {
  get groupParents() {
    if (!this.args.hasGroupParent) {
      return [];
    }

    return this.args.model.filter((entry) => entry.hasAdditionalGuests);
  }
}

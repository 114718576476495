import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

const REVIEW_STATUS = 'review';

export default class EmployeesDocumentReviewIndexController extends Controller {
  queryParams = ['employeeId'];

  @tracked employeeId = null;

  get hasDocumentsToReview() {
    const { userDocumentLink, userDocumentLinks } = this.model;
    const thisDocNeedsReview = userDocumentLink.approvalStatus === REVIEW_STATUS;

    return thisDocNeedsReview || userDocumentLinks.isAny('approvalStatus', REVIEW_STATUS);
  }
}

import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { inject as service } from '@ember/service';

import config from 'garaje/config/environment';

export default class RmsAdapter extends JSONAPIAdapter {
  host = config.rmsHost;
  namespace = 'a/rms';
  useFetch = false;

  @service cookieAuth;
  @service session;

  handleResponse(status, _headers, _payload, _requestData) {
    if (status === 401 && this.session.isAuthenticated) {
      this.session.invalidate();
    }
    return super.handleResponse(...arguments);
  }

  ajaxOptions(url, type, options = {}) {
    let hash = super.ajaxOptions(url, type, options);
    hash = this.cookieAuth.decorate(type, hash);
    hash.headers['X-Forwarded-Host'] = config.hostName;

    return hash;
  }

  ajax(url, method, hash = {}) {
    hash.xhrFields = { withCredentials: true };
    if (config.proxyMode && url.includes('app.envoy.christmas')) {
      // Can be removed if rms returns relative paths in JSON API relationships
      url = url.replace('app.envoy.christmas', 'dashboard.envoy.dev');
    }
    return super.ajax(url, method, hash);
  }

  shouldBackgroundReloadAll() {
    return false;
  }

  shouldBackgroundReloadRecord() {
    return false;
  }
}

/* eslint-disable ember/no-computed-properties-in-native-classes */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action, computed, get, set } from '@ember/object';
import { equal, notEmpty, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { validatePresence } from 'ember-changeset-validations/validators';
import { dropTask } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import _intersection from 'lodash/intersection';

import urlBuilder from 'garaje/utils/url-builder';
import validateConditional from 'garaje/validators/conditional';

const Validations = {
  fingerprint: validatePresence({
    presence: true,
    message: "{description} can't be blank",
  }),
  url: validateConditional({
    if(key, newValue, oldValue, { required }) {
      return required;
    },
    then: validatePresence({
      presence: true,
      message: 'An Identity Provider HTTP SAML URL is required to enforce SAML',
    }),
    else() {
      return true;
    },
  }),
};

/**
 * @param {Object}              saml
 * @param {String}              integration
 * @param {Function}            openAction
 * @param {Function}            closeAction
 * @param {Function}            integrationDisconnected
 */
export default class SamlConfigBox extends Component {
  @service currentAdmin;
  @service flashMessages;

  tagName = '';

  @equal('integration', 'saml') isOpen;
  @notEmpty('saml') connected;
  @readOnly('changeset.enabled') enabled;

  @computed('saml')
  get changeset() {
    const validator = lookupValidator(Validations);
    return new Changeset(this.saml, validator, Validations);
  }

  get samlConsumeUrl() {
    return urlBuilder.samlConsumeUrl();
  }

  get samlMetadataUrl() {
    return urlBuilder.samlMetadataUrl();
  }

  get samlInitUrl() {
    return urlBuilder.samlInitUrl(get(this.saml, 'company.content.id'));
  }

  confirmAction() {
    return window.confirm('You will lose any unsaved changes. Are you sure you want to continue?');
  }

  destroyRecord() {
    return this.saml.destroyRecord();
  }

  @action
  close() {
    // Calling changeset.company.reload makes the changeset dirty
    // with company.isReloading: false
    const changedAttributes = get(this.changeset, 'changes').map((c) => c.key);
    const skippableAttributes = ['company.isReloading'];
    const skippableChanges =
      changedAttributes.length === skippableAttributes.length
        ? _intersection(changedAttributes, skippableAttributes)
        : [];

    if (!skippableChanges.length && get(this.changeset, 'isDirty') && !get(this.changeset, 'isNew')) {
      if (this.confirmAction()) {
        this.changeset.rollback();
      } else {
        return;
      }
    }

    if (get(this.changeset, 'isNew')) {
      if (this.confirmAction()) {
        this.destroyRecord().then(() => {
          this.integrationDisconnected();
        });
      } else {
        return;
      }
    }

    this.closeAction();
  }

  confirmDisconnect() {
    return window.confirm('Are you sure you want to disconnect this integration?');
  }

  @action
  toggleRequired(val) {
    const { changeset } = this;
    set(changeset, 'required', val);
    changeset.validate();
  }

  @dropTask
  *disconnectTask() {
    if (!this.confirmDisconnect()) {
      return;
    }

    try {
      const company = yield get(this.saml, 'company');

      yield this.destroyRecord();

      company.reload();
      this.close();
      this.integrationDisconnected();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));
    }
  }

  @dropTask
  *saveTask() {
    const { changeset } = this;

    try {
      yield changeset.validate();

      if (!this.changeset.isValid) {
        return;
      }

      yield changeset.save();

      const company = yield changeset._content.company;

      yield company.reload();
      this.close();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));
    }
  }
}

import { type AsyncBelongsTo, belongsTo } from '@ember-data/model';
import AbstractPrinter, { PrinterStatus } from 'garaje/models/abstract/printer';

import type ZoneModel from './zone';

export default class PropertyPrinterModel extends AbstractPrinter {
  @belongsTo('zone')
  declare property: AsyncBelongsTo<ZoneModel>;

  get isInactive(): boolean {
    return this.printerStatus === PrinterStatus.NOT_IN_USE || this.printerStatus === PrinterStatus.OFFLINE;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'property-printer': PropertyPrinterModel;
  }
}

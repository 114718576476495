import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_EDIT_LOCATION_SETTINGS = [GLOBAL_ADMIN, LOCATION_ADMIN];

export default class LocationSettingAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;

  get canEdit(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_EDIT_LOCATION_SETTINGS, roleNames));
  }
}

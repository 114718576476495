import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

export default class FlowsScreeningScreeningRequiredComponent extends Component {
  @service flashMessages;

  get isDisabled() {
    return this.args.isDisabled || this.toggleRequired.isRunning;
  }

  @task
  *toggleRequired() {
    const { flashMessages } = this;
    const { model } = this.args;

    model.toggleProperty('employeeScreeningRequired');

    try {
      yield model.save();
      flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      throw e;
    }
  }
}

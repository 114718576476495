import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import type LocationModel from 'garaje/models/location';
import adapter from 'garaje/utils/decorators/adapter';
import serializer from 'garaje/utils/decorators/serializer';

@adapter('v1')
@serializer('ams')
class WebhookModel extends Model {
  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;
  @attr('string', { defaultValue: '' }) declare url: string;
  @attr('boolean', { defaultValue: true }) declare enabled: boolean;
}

export default WebhookModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    webhook: WebhookModel;
  }
}

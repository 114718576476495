import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { or } from 'macro-decorators';

export default class SettingsVisitorEntrancesIndexController extends Controller {
  @service featureFlags;
  @service currentAdmin;

  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isLocationAdmin') isAdmin;

  get showSetupGuide() {
    return this.isAdmin && this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper');
  }
}

// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { get, action } from '@ember/object';

export default class SortableColumnHeader extends Component {
  @action
  selectSortBy(sortDirection, closeFn) {
    const sortBy = get(this, 'sortBy');
    const setSortDirection = get(this, 'setSortDirection');
    const setSortBy = get(this, 'setSortBy');

    setSortDirection(sortDirection);
    setSortBy(sortBy);
    closeFn();
  }
}

import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import { Permission } from 'garaje/utils/ui-permissions';

export default class NotificationAbility extends Ability {
  @service declare authz: AuthzService;

  get canUpdateDevice(): boolean {
    // User can update device notification settings if they have "update devices" permission
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_DEVICE_UPDATE);
  }

  get canUpdatePrinter(): boolean {
    // User can update printer notification settings if they have "update printers" permission
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_PRINTER_UPDATE);
  }
}

import Route from '@ember/routing/route';
import { set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { inject as controller } from '@ember/controller';
import InfinityModel from 'garaje/infinity-models/v3-offset';

class ExtendedInfinityModel extends InfinityModel {
  @controller('changelogs.index') controller;

  afterInfinityModel(changelogs) {
    changelogs.forEach((changelog) => get(changelog, 'user'));
  }

  infinityModelUpdated() {
    set(this.controller, 'hasClickedLoadMore', false);
  }
}

export default class ChangelogsIndexRoute extends Route {
  @service infinity;

  model() {
    return this.infinity.model(
      'changelog',
      {
        perPage: 10,
        perPageParam: 'page[limit]',
        pageParam: 'page[offset]',
        startingPage: 0,
        countParam: 'meta.total',
      },
      ExtendedInfinityModel,
    );
  }
}

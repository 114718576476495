import { service } from '@ember/service';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type { SyncHasMany } from '@ember-data/model';
import type LocationModel from 'garaje/models/location';
import type AjaxService from 'garaje/services/ajax';
import urlBuilder from 'garaje/utils/url-builder';

import type ConnectionRequestInviteModel from './connection-request-invite';
import type SkinnyLocationModel from './skinny-location';
import type TenantModel from './tenant';

class TenantConnectionRequestModel extends Model {
  @service declare ajax: AjaxService;

  @attr('string') declare token: string;
  @attr('date') declare expiresAt: Date;
  @attr('date') declare connectedAt: Date;

  @belongsTo('tenant', { async: false })
  declare tenant: TenantModel;

  @hasMany('connection-request-invite', { async: false })
  declare connectionRequestInvites: SyncHasMany<ConnectionRequestInviteModel>;

  get latestInvite(): ConnectionRequestInviteModel | undefined {
    return this.connectionRequestInvites.firstObject;
  }

  /**
   * initiates connection flow by claiming a connection token for a property
   *
   * @param location location chose to connect to property
   * @returns url to accept connection to location
   */
  async claimToken(location: SkinnyLocationModel | LocationModel): Promise<string | undefined> {
    // inconsumable requests do not contain the token
    if (!this.token) throw new Error('Connection request missing token');

    const response = await this.ajax.post<{ url: string }>(urlBuilder.multiTenancy.claimToken(this.token), {
      data: {
        location: location.id,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.url;
  }

  /**
   * store a pending location to re initiate the connection modal for a specific connection request
   */
  pendingLocation?: LocationModel | SkinnyLocationModel;
}

export default TenantConnectionRequestModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tenant-connection-request': TenantConnectionRequestModel;
  }
}

import ApplicationSerializer from './application';

export default class BadgeSerializer extends ApplicationSerializer {
  attrs = {
    defaultCss: {
      serialize: false,
    },
    defaultHtml: {
      serialize: false,
    },
    html: {
      serialize: false,
    },
    image: {
      serialize: false,
    },
    sampleLogoPath: {
      serialize: false,
    },
    samplePhotoPath: {
      serialize: false,
    },
  };
}

import type { AsyncBelongsTo } from '@ember-data/model';
import { belongsTo } from '@ember-data/model';
import BadgeModel from 'garaje/models/badge';
import type GlobalFlowModel from 'garaje/models/global-flow';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('badge')
class GlobalBadgeModel extends BadgeModel {
  // @ts-ignore
  @belongsTo('global-flow') declare flow: AsyncBelongsTo<GlobalFlowModel>;
}

export default GlobalBadgeModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'global-badge': GlobalBadgeModel;
  }
}

import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { endOfDay, startOfDay } from 'date-fns';
import { format, formatInTimeZone } from 'date-fns-tz';
import { keepLatestTask, task } from 'ember-concurrency';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type AnnouncementModel from 'garaje/models/announcement';
import type CurrentLocationService from 'garaje/services/current-location';
import type StateService from 'garaje/services/state';
import { DATE_FNS_YYYY_MM_DD, modifyDateInTimeZone, parseYyyyMmDdInTimeZone } from 'garaje/utils/date-fns-tz-utilities';
import { reads } from 'macro-decorators';

import { getAnnouncementQueryParams } from './announcement-queries';
import { PAGE_SIZE } from './route-base';
import type { AnnoucementsListModel } from './route-base';

const DEFAULT_TIMEZONE = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'America/Los_Angeles';
const MESSAGE_LOG_HEADERS = ['Sent at', 'Message', 'Recipients'] as const;

export interface MessageLogEntry {
  id: string;
  sentAt: string;
  sender: string;
  messageTitle: string;
  messageBody: string;
  location?: string;
  category?: string;
  employeeCount: number;
  visitorCount: number;
  markedAsSafeCount: number;
}

export default class CommunicationsMessageLogListControllerBase extends Controller {
  declare model: AnnoucementsListModel;
  @service declare store: Store;
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare currentLocation: CurrentLocationService;

  @tracked selectedLocationIds: string[] = [];
  @tracked messageLogHeaders = MESSAGE_LOG_HEADERS;
  @tracked messageLogEntries: MessageLogEntry[] = [];
  @tracked totalCount = 0;
  @tracked currentPage = 1;
  @tracked pageSize = PAGE_SIZE;

  @tracked startDate: Date = new Date();
  @tracked endDate: Date = new Date();
  @tracked showCalendar = false;
  @tracked isActiveInLastSixMonths = false;

  @reads('currentLocation.timezone', DEFAULT_TIMEZONE) timezone!: string;

  initializeData = task({ drop: true }, async (startDate: Date, endDate: Date, fetchCurrentLocation: boolean) => {
    if (!this.model.announcements) {
      throw new Error('No announcements found');
    }

    this.messageLogEntries = await Promise.all(
      this.model.announcements.map((announcement) => this.createMessageLogEntry(announcement)),
    );
    this.selectedLocationIds = fetchCurrentLocation ? [this.currentLocation.location.id] : [];
    this.currentPage = 1;
    this.totalCount = this.model.announcements?.meta?.total || 0;
    this.startDate = startDate;
    this.endDate = endDate;
    this.isActiveInLastSixMonths = this.totalCount > 0;
  });

  refreshData = task({ drop: true }, async () => {
    const queryParams = getAnnouncementQueryParams(this.selectedLocationIds, this.startDate, this.endDate);
    const announcements = (await this.store.query(
      'announcement',
      queryParams,
    )) as PaginatedRecordArray<AnnouncementModel>;
    this.messageLogEntries = await Promise.all(
      announcements.map((announcement) => this.createMessageLogEntry(announcement)),
    );
    this.totalCount = announcements?.meta?.total || 0;
  });

  get hasMorePages(): boolean {
    return this.pageSize * this.currentPage < this.totalCount;
  }

  @action
  setStartDate(date: Date): void {
    this.startDate = date;
  }

  @action
  setEndDate(date: Date): void {
    this.endDate = date;
  }

  get startDateFormat(): string {
    const { timezone, startDateWithDefault } = this;

    return formatInTimeZone(startDateWithDefault, timezone, 'MM/dd/yyyy');
  }

  get endDateFormat(): string {
    const { timezone, endDateWithDefault } = this;

    return formatInTimeZone(endDateWithDefault, timezone, 'MM/dd/yyyy');
  }

  get dateWithDefault(): string {
    return formatInTimeZone(new Date(), this.timezone, DATE_FNS_YYYY_MM_DD);
  }

  get startDateWithDefault(): Date {
    if (this.startDate) return new Date(this.startDate);

    const { dateWithDefault } = this;

    const date = parseYyyyMmDdInTimeZone(dateWithDefault, new Date(), this.timezone);
    const start = modifyDateInTimeZone(date, this.timezone, startOfDay);
    return start;
  }

  get endDateWithDefault(): Date {
    if (this.endDate) return new Date(this.endDate);

    const { dateWithDefault } = this;
    const date = parseYyyyMmDdInTimeZone(dateWithDefault, new Date(), this.timezone);
    const end = modifyDateInTimeZone(date, this.timezone, endOfDay);

    return end;
  }

  loadMoreData = keepLatestTask(async () => {
    if (!this.hasMorePages) {
      return;
    }
    const offset = this.currentPage * this.pageSize;
    this.currentPage++;
    const queryParams = getAnnouncementQueryParams(
      this.selectedLocationIds,
      this.startDate,
      this.endDate,
      this.pageSize,
      offset,
    );
    const newAnnouncements = await this.store.query('announcement', queryParams);

    if (Number(newAnnouncements.length) > 0) {
      const newMessagLogEntries = await Promise.all(
        newAnnouncements.map((announcement) => this.createMessageLogEntry(announcement)),
      );
      this.messageLogEntries = [...this.messageLogEntries, ...newMessagLogEntries];
    }
  });

  @action
  async onDatesChanged(startDate: Date, endDate: Date): Promise<void> {
    this.startDate = startDate;
    this.endDate = endDate;
    await this.refreshData.perform();
  }

  get totalMessages(): number {
    return this.messageLogEntries?.length ?? 0;
  }

  locationName = (locationId: string): string => {
    return this.store.peekRecord('skinny-location', locationId)?.name ?? '';
  };

  formatDate(date: Date, timezone?: string): string {
    const dateFormat = "MMM dd, yyyy 'at' h:mm a zzz";

    if (!timezone) {
      return format(date, dateFormat);
    }

    return formatInTimeZone(date, timezone, dateFormat);
  }

  protected async createMessageLogEntry(announcement: AnnouncementModel): Promise<MessageLogEntry> {
    const creator = await announcement.creatorEmployee;
    const locationId = announcement.belongsTo('location').id();
    const locationName = this.locationName(locationId);

    return {
      id: announcement.id,
      sentAt: this.formatDate(announcement.createdAt, this.state.currentLocation.timezone),
      sender: creator.name || 'Unknown',
      messageTitle: announcement.title,
      messageBody: announcement.message,
      location: locationName || '',
      employeeCount: announcement.statistics?.sent_employee_recipients ?? 0,
      visitorCount: announcement.statistics?.visitor_recipients ?? 0,
      markedAsSafeCount: announcement.statistics?.marked_as_safe_recipients ?? 0,
    };
  }
}

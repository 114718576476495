import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import ZoneModel from 'garaje/models/zone';
import { type PropertyFormChangesets } from 'garaje/pods/components/properties/property-form/component';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { defer } from 'rsvp';

import { type PropertyEditRouteModel } from './route';

export default class PropertyEditController extends Controller {
  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;

  declare model: PropertyEditRouteModel;

  @action
  saved(changeset: PropertyFormChangesets): void {
    if (changeset._content instanceof ZoneModel) {
      this.model.propertyName = changeset._content.name;
    }
  }

  deletePropertyTask = dropTask(async () => {
    const property = this.model.property;
    const propertyName = property.name;

    try {
      await property.destroyRecord();
      this.flashMessages.showAndHideFlash('success', `"${propertyName}" has been deleted`);
      void this.router.transitionTo('connect.properties.index');
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      this.flashMessages.showAndHideFlash('error', 'Something went wrong, please try again.');
    }
  });

  @dropTask
  showDeleteConfirmationTask: {
    perform(): Generator<Promise<unknown>, unknown, unknown>;
  } = {
    *perform(this: { context: PropertyEditController; abort?: () => void; continue?: () => Promise<void> }) {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = async () => {
        await this.context.deletePropertyTask.perform();
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };
}

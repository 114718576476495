import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import type { SafeString } from '@ember/template/-private/handlebars';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { or } from 'macro-decorators';

interface FlashMessageComponentArgs {
  action: (flashMessage: FlashMessageComponent) => void;
  ariaLive?: string;
  componentName?: string;
  details?: string;
  flashVisible: boolean;
  icon?: string;
  message: string;
  order: number;
  response?: Record<string, unknown>;
  shouldAnimate: boolean;
  showAboveModalOverlay?: boolean;
  type: string;
}

export default class FlashMessageComponent extends Component<FlashMessageComponentArgs> {
  /**
   * Since multiple flash messages are supported, the top position must be calculated,
   * The first message (success) will begin with the default value
   *
   * @property position - pixels from the top of the viewport
   */
  @tracked position = 16;
  @tracked isMouseOver = false;
  @tracked scrollOffset = 0;
  @tracked shouldAnimate;
  @tracked ariaLive;

  @service declare router: RouterService;

  constructor(owner: unknown, args: FlashMessageComponentArgs) {
    super(owner, args);
    this.calculatePosition();
    this.shouldAnimate = this.args.shouldAnimate;
    // Can set this to 'polite' for flash messages that the user
    // does not need to see immediately.
    this.ariaLive = this.args.ariaLive || 'assertive';
  }

  @or('args.flashVisible', 'isMouseOver') isFlashVisible!: boolean;

  get style(): SafeString {
    return htmlSafe(`top: ${this.position}px; z-index: 100000000;`);
  }

  clearMessage(): void {
    this.isMouseOver = false;
    this.shouldAnimate = true;
  }

  calculatePosition(): void {
    if (this.args.order > 0) {
      const precedingFlash = document.querySelector<HTMLElement>(`[data-order="${this.args.order - 1}"].messageBar`);
      if (precedingFlash) {
        this.position = precedingFlash.offsetTop + precedingFlash.offsetHeight + 16;
      }
    }
  }

  @action
  hideFlash(): void {
    this.clearMessage();
    // Since multiple flash messages are supported, we need to send this instance up
    this.args.action(this);
  }

  @action
  handleTextLinkClick(event: Event): void {
    const target = event.target as HTMLAnchorElement;
    if (target.tagName === 'A' && !target.getAttribute('target')) {
      event.preventDefault();
      const href = target.getAttribute('href');
      void this.router.transitionTo(href!);
    }
  }
}

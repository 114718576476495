import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type MomentService from 'ember-moment/services/moment';
import type ZoneModel from 'garaje/models/zone';
import type StateService from 'garaje/services/state';
import { configureBugsnag } from 'garaje/utils/configure-bugsnag';
import moment from 'moment-timezone';
import rome from 'rome';

export default class PropertyRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare moment: MomentService;

  beforeModel(): void {
    if (this.abilities.cannot('visit properties') || !this.state.connectSubscription) {
      void this.router.transitionTo('unauthorized');
    }
  }

  model(): ZoneModel {
    return (<{ currentZone: ZoneModel }>this.modelFor('current-zone')).currentZone;
  }

  afterModel(currentZone: ZoneModel): void {
    const { currentUser, currentCompany } = this.state;

    if (!currentZone || !currentUser || !currentCompany) {
      void this.router.replaceWith('profile');
      return;
    }

    configureBugsnag({ currentZone, currentUser, currentCompany });
    if (currentZone.address.time_zone) this._configureMomentTimeZone(currentZone.address.time_zone);
  }

  /**
   * @todo remove when we remove Moment from our major components like DateTimePicker
   *
   * @param {string} timezone timezone to configure globally
   */
  _configureMomentTimeZone(timezone: string): void {
    this.moment.changeTimeZone(timezone);

    // this sets global moment for scenarios  where we do moment()
    moment.tz.setDefault(timezone);

    // Configures rome calendar to use our moment instance
    rome.use(moment);
  }
}

import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type GlobalBadgeModel from 'garaje/models/global-badge';
import type UiHookModel from 'garaje/models/ui-hook';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import type StateService from 'garaje/services/state';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

import type { LocationOverviewSignInFlowsShowRouteModel } from '../route';

import type LocationOverviewSignInFlowsShowBadgeController from './controller';

export interface LocationOverviewSignInFlowsShowBadgeRouteModel extends LocationOverviewSignInFlowsShowRouteModel {
  badge: GlobalBadgeModel;
  uiHooks: RecordArray<UiHookModel> | UiHookModel[];
  enableBadgeCustomContent: boolean;
}

export default class LocationOverviewSignInFlowsShowBadgeRoute extends Route {
  declare controller: LocationOverviewSignInFlowsShowBadgeController;

  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;
  @service declare store: StoreService;
  @service declare transitionConfirm: TransitionConfirmService;
  @service declare skinnyLocations: SkinnyLocationsService;

  model(): Promise<LocationOverviewSignInFlowsShowBadgeRouteModel> {
    const model = <LocationOverviewSignInFlowsShowRouteModel>this.modelFor('location-overview.sign-in-flows.show');
    const enableBadgeCustomContent = this.featureFlags.isEnabled('badge-custom-content');
    const uiHooks = enableBadgeCustomContent
      ? this.store.query('ui-hook', {
          locationIds: this.skinnyLocations.currentCompanyLocations.map((l) => l.id),
          triggerNames: ['badge_text_field', 'badge_barcode', 'badge_qr_code'],
        })
      : [];

    return hash({
      ...model,
      badge: model.globalFlow.badge,
      uiHooks,
      enableBadgeCustomContent,
    });
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const { badge } = this.controller.model;
    const confirmTask = this.transitionConfirm.displayConfirmTask;

    if (<boolean>(<unknown>badge.hasDirtyAttributes)) {
      void confirmTask.perform(transition, {
        continue() {
          badge.rollbackAttributes();
        },
      });
    }
  }
}

import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { action } from '@ember/object';
import { or, reads } from 'macro-decorators';

const DEFAULT_PAGE_SIZE = 30;
const DEFAULT_DATE_RANGE = 'Today';

export default class MyLocationsVisitorLogController extends Controller {
  @service skinnyLocations;

  queryParams = [
    'endDate',
    'locationIds',
    'pageNumber',
    'pageSize',
    'query',
    'signedOut',
    'sortBy',
    'sortDirection',
    'startDate',
    'selectedDateRange',
  ];

  @reads('model.entries.meta.total') metaRecordCount;
  @or('metaRecordCount', 'defaultRecordCount') recordCount;

  @tracked pageNumber = 1;
  @tracked pageSize = DEFAULT_PAGE_SIZE;
  @tracked selectedDateRange = DEFAULT_DATE_RANGE;
  @tracked defaultRecordCount = 0;
  @tracked startDate = moment().startOf('day').format();
  @tracked endDate = moment().endOf('day').format();
  @tracked locationIds = '';

  @action
  setDateRange(option, start, end) {
    this.startDate = start;
    this.endDate = end;
    this.selectedDateRange = option;
  }
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type Task, task } from 'ember-concurrency';
import type AbstractBadge from 'garaje/models/abstract/abstract-badge';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import { alias } from 'macro-decorators';
import { localCopy } from 'tracked-toolbox';

interface BadgeCssConfigArgs {
  badge: AbstractBadge;
  isAuthorized?: boolean;
  readOnly?: boolean;
  saveTask?: Task<void, []>;
}

export default class BadgeCssConfig extends Component<BadgeCssConfigArgs> {
  @service declare flashMessages: FlashMessagesService;

  @alias('args.badge.hasDirtyAttributes') isDirty!: AbstractBadge['hasDirtyAttributes'];

  @localCopy('args.isAuthorized', true) isAuthorized!: boolean;

  @tracked isEditingBadgeCSS = false;

  get isCSSCustom(): boolean {
    return this.args.badge.css !== this.args.badge.defaultCss;
  }

  get isDisabled(): boolean {
    return !!this.args.readOnly || !this.isDirty || this.saveTask.isRunning;
  }

  saveTask = task({ drop: true }, async () => {
    try {
      await (this.args.saveTask?.perform().catch(throwUnlessTaskDidCancel) ?? this.args.badge.save());
      this.isEditingBadgeCSS = false;
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  @action
  resetBadgeCSS(): void {
    this.args.badge.css = this.args.badge.defaultCss;
  }

  @action
  rollback(): void {
    this.args.badge.rollbackAttributes();
  }

  @action
  close(): void {
    this.isEditingBadgeCSS = false;
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type AuthzService from 'garaje/services/authz';
import type RoleAssignmentsService from 'garaje/services/role-assignments';
import type StateService from 'garaje/services/state';
import type StoreService from 'garaje/services/store';
import { RoleId, RoleScopeType } from 'garaje/utils/custom-roles';
import type { Role } from 'garaje/utils/custom-roles';

import type ManageRolesController from './controller';

class ManageRolesRoute extends Route {
  @service declare authz: AuthzService;
  @service declare roleAssignments: RoleAssignmentsService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare abilities: AbilitiesService;
  @service declare store: StoreService;

  titleToken = 'Roles and Permissions';

  beforeModel(): void {
    if (this.abilities.cannot('visit-local-custom-roles manage-settings')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  async model(): Promise<unknown> {
    await this.authz.fetchRoles();
    const roles = this.authz.roles;

    await this.roleAssignments.fetchAssignmentCountsPerRole();
    const assignmentCountsPerRole = this.roleAssignments.assignmentCountsPerRole;

    const companyRoles: Role[] = [];
    const locationRoles: Role[] = [];
    const zoneRoles: Role[] = [];

    roles.forEach((role: Role) => {
      if (!role.deactivated) {
        if (role.id in assignmentCountsPerRole) {
          role.assignmentCounts = assignmentCountsPerRole[role.id];
        } else {
          role.assignmentCounts = {
            user_count: 0,
          };
        }
        if (role.roleScope === RoleScopeType.COMPANY) {
          if (role.id === RoleId.ANALYTICS_VIEWER) {
            // only show Analytics Viewer role for workplace premium accounts
            const { workplaceSubscription } = this.state;
            if (workplaceSubscription && !workplaceSubscription.isStandardPlan) {
              companyRoles.push(role);
            }
          } else if (role.id != RoleId.COMPANY_START_FROM_SCRATCH) {
            companyRoles.push(role);
          }
        } else if (
          role.roleScope === RoleScopeType.LOCATION &&
          role.id !== RoleId.EMPLOYEE &&
          role.id != RoleId.LOCATION_START_FROM_SCRATCH
        ) {
          locationRoles.push(role);
        } else if (role.roleScope === RoleScopeType.ZONE) {
          zoneRoles.push(role);
        }
      }
    });

    return { companyRoles, locationRoles, zoneRoles };
  }

  resetController(controller: ManageRolesController): void {
    controller.reset();
  }
}

export default ManageRolesRoute;

import gql from 'graphql-tag';

export default gql`
  query PopulateAnnouncementTemplateQuery($announcementTemplateId: ID!, $locationId: ID!) {
    populateAnnouncementTemplate(announcementTemplateId: $announcementTemplateId, locationId: $locationId) {
      defaultEmployeeAudiences
      defaultVisitorAudiences
      defaultEmployees {
        id
        name
        email
      }
      defaultEmployeeGroups {
        id
        name
      }
      defaultChannels
      id
      message
      title
      markAsSafe
    }
  }
`;

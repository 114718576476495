import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { typeOf } from '@ember/utils';
import { task, dropTask } from 'ember-concurrency';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { formatMinutesAsTime } from 'garaje/utils/format-minutes-as-time';

import type { VisitorsSettingsSignOutRouteModel } from './route';

export default class VisitorsSettingsSignOutController extends Controller {
  declare model: VisitorsSettingsSignOutRouteModel;

  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;

  toggleSelfSignOut = dropTask(async (value: boolean) => {
    const { config } = this.model;
    config.selfSignOut = value;

    try {
      await config.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      config.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  saveTask = task(async () => {
    const { currentLocation } = this.model;
    if (<boolean>(<unknown>currentLocation.hasDirtyAttributes) === false) {
      return;
    }
    const autoSignOutAtMinutesSinceMidnight = <[string, string]>(
      currentLocation.changedAttributes()['autoSignOutAtMinutesSinceMidnight']
    );
    const [original, changed] = autoSignOutAtMinutesSinceMidnight;
    let action = original === null ? 'Enabled' : 'Edited';
    action = changed === null ? 'Disabled' : action;
    try {
      await currentLocation.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this._trackAutoSignOutEvent(action, currentLocation.id, autoSignOutAtMinutesSinceMidnight[1]);
    } catch (e) {
      currentLocation.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  @action
  updateAutoSignOutAtMinutesSinceMidnight(mins: number): void {
    this.model.currentLocation.autoSignOutAtMinutesSinceMidnight = mins;
    this.model.currentLocation.autoSignOutAtMidnight = typeOf(mins) === 'number';
  }

  _trackAutoSignOutEvent(action: string, locationId: string, autoSignOutAtMinutesSinceMidnight: string): void {
    this.metrics.trackEvent(`Auto Sign Out ${action}`, {
      location_id: locationId,
      sign_out_at:
        autoSignOutAtMinutesSinceMidnight === null ? null : formatMinutesAsTime(autoSignOutAtMinutesSinceMidnight),
    });
  }
}

import { A } from '@ember/array';
import { action } from '@ember/object';
import { isBlank } from '@ember/utils';
import type { AsyncHasMany } from '@ember-data/model';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { timeout, task } from 'ember-concurrency';
import DropdownOption from 'garaje/models/dropdown-option';
import type FlowModel from 'garaje/models/flow';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import zft from 'garaje/utils/zero-for-tests';
import type Handsontable from 'handsontable';
import type { GridSettings } from 'handsontable';
import { filterBy, reads } from 'macro-decorators';

export interface InvitesSpreadsheetOutputArgs {
  csvHeaders: string[];
  data: unknown[][];
  emailCount: number;
  filledRows: unknown[][];
  hasEmail: boolean;
  headers: string[];
  rowCount: number;
  validRows: unknown[][];
}

interface InvitesSpreadsheetInputArgs {
  disabled?: boolean;
  enablePropertyNotes?: boolean;
  flow?: FlowModel;
  handsontable: Handsontable;
  isEmailRequired?: boolean;
  onChange?: (output: InvitesSpreadsheetOutputArgs) => void;
  onHotInstance?: (hotInstance: Handsontable) => void;
  options?: GridSettings;
  showRequiredOnly?: boolean;
}

interface ColumnDefinition extends GridSettings {
  csvHeader?: string;
}

const STATIC_COL_HEADERS = Object.freeze(['Full name', 'Visitor email address']);

const escapeHTML = (unsafeStr: string): string => {
  return unsafeStr
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/\./g, '&#46;');
};

const validateEmail = function (email: string): boolean {
  return /^[^@\s]+@[^@.\s]+(?:(?:\.|:{1,2})[^@.:\s]+)+$/.test(email);
};

const createTextColumnWithGenericValidation = function (settings: ColumnDefinition): ColumnDefinition {
  const validator = function (value: string, cb: (valid: boolean) => void): void | RegExp {
    cb(settings.allowEmpty || value?.length > 0);
  };

  return {
    type: 'text',
    validator,
    ...settings,
  };
};

const createIntegerColumnWithPositiveValidation = function (settings: ColumnDefinition): ColumnDefinition {
  const validator = function (value: string, cb: (valid: boolean) => void): void | RegExp {
    const number = Number(value);

    cb(number >= 0 && Math.floor(number) === number);
  };

  return {
    ...settings,
    type: 'text',
    validator,
  };
};

const createStaticColumns = (_handsontable: Handsontable, isEmailRequired: boolean = false): ColumnDefinition[] => {
  return [
    createTextColumnWithGenericValidation({
      allowEmpty: false,
      csvHeader: 'invitee_name', // eslint-disable-line camelcase
      data: 'fullName',
    }),
    {
      allowEmpty: !isEmailRequired,
      csvHeader: 'invitee_email', // eslint-disable-line camelcase
      data: 'email',
      type: 'text',
      trimWhitespace: true,
      validator(val: string, cb: (valid: boolean) => void): void | RegExp {
        if (isBlank(val)) {
          cb(isEmailRequired ? false : true);
        } else {
          cb(validateEmail(val));
        }
      },
    },
  ];
};

export default class InvitesSpreadsheetInput extends Component<InvitesSpreadsheetInputArgs> {
  @tracked hotInstance!: Handsontable;
  @tracked rowLength: number = 0;
  @tracked staticColumns: ColumnDefinition[];

  constructor(owner: unknown, args: InvitesSpreadsheetInputArgs) {
    super(owner, args);
    this.staticColumns = createStaticColumns(this.args.handsontable, this.args.isEmailRequired);
  }

  @reads('args.flow') selectedFlow!: FlowModel;
  @reads('args.showRequiredOnly', false) showRequiredOnly!: boolean;
  @reads('selectedFlow.signInFieldPage.signInFields') signInFields!: AsyncHasMany<SignInFieldModel>;
  @reads('selectedFlow.additionalGuests', false) additionalGuests!: boolean;
  @filterBy('sortedSignInFields', 'isHighPriorityField') highPrioritySignInFields!: SignInFieldModel[];
  @filterBy('sortedSignInFields', 'isHighPriorityField', false) lowPrioritySignInFields!: SignInFieldModel[];

  get colHeaders(): string[] {
    const colHeaders = [...STATIC_COL_HEADERS];
    const {
      args: { enablePropertyNotes },
      highPrioritySignInFields,
      lowPrioritySignInFields,
      showRequiredOnly,
      additionalGuests,
    } = this;

    highPrioritySignInFields.forEach((field) => {
      const h = this.headingForField(field);

      if (!isBlank(h)) colHeaders.push(h);
    });

    if (additionalGuests && !showRequiredOnly) {
      colHeaders.push('Additional visitors');
    }

    lowPrioritySignInFields.forEach((field) => {
      const h = this.headingForField(field);

      if (!isBlank(h)) colHeaders.push(h);
    });

    if (!showRequiredOnly) {
      colHeaders.push('Private notes');

      if (enablePropertyNotes) {
        colHeaders.push('Shared notes');
      }
    }

    return colHeaders.map((h) => escapeHTML(h));
  }

  get columns(): ColumnDefinition[] {
    const {
      args: { enablePropertyNotes, disabled },
      staticColumns,
      highPrioritySignInFields,
      lowPrioritySignInFields,
      showRequiredOnly,
      additionalGuests,
    } = this;
    const columns = [...staticColumns];

    highPrioritySignInFields.forEach((field) => {
      const c = this.cellForField(field);

      if (c) columns.push(c);
    });

    if (additionalGuests && !showRequiredOnly) {
      columns.push(
        createIntegerColumnWithPositiveValidation({
          allowEmpty: true,
          csvHeader: 'additional_guests',
          data: 'additionalGuests',
        }),
      );
    }

    lowPrioritySignInFields.forEach((field) => {
      const c = this.cellForField(field);

      if (c) columns.push(c);
    });

    if (!showRequiredOnly) {
      columns.push({
        allowEmpty: true,
        csvHeader: 'private_notes',
        data: 'privateNotes',
        type: 'text',
      });

      if (enablePropertyNotes) {
        columns.push({
          allowEmpty: true,
          csvHeader: 'property_notes',
          data: 'propertyNotes',
          type: 'text',
        });
      }
    }

    return columns.map((col) => {
      col.readOnly = disabled;

      return col;
    });
  }

  get spreadsheetClassNames(): string {
    return this.columns
      .map((col, i) => {
        return col.allowEmpty ? `col-optional-${i}` : `col-required-${i}`;
      })
      .join(' ');
  }

  get sortedSignInFields(): SignInFieldModel[] {
    const { showRequiredOnly, signInFields } = this;
    const resolved = (<SignInFieldModel[]>signInFields?.content)?.slice();
    const results = A(resolved).rejectBy('identifier', 'name').sortBy('fieldPriority', 'position');

    return showRequiredOnly ? results.filterBy('isRequestedFromEmployee') : results;
  }

  headingForField(field: SignInFieldModel): string {
    // Set const individually to avoid "Unsafe array destructuring of a
    // tuple element with an `any` value" lint error
    const name = field?.name ?? '';
    const localized = field?.localized ?? '';
    const isEmail = field?.isEmail ?? false;
    const isPhone = field?.isPhone ?? false;
    const isHost = field?.isHost ?? false;

    if (isHost) return ''; // required ? localized || name : `${localized || name} (optional)`;
    if (isEmail) return '';
    if (isPhone) return 'Visitor phone number';

    return localized || name;
  }

  cellForField(field: SignInFieldModel): ColumnDefinition | null {
    // Set const individually to avoid "Unsafe array destructuring of a
    // tuple element with an `any` value" lint error
    const name = field?.name ?? '';
    const isRequestedFromEmployee = field?.isRequestedFromEmployee ?? false;
    const kind = field?.kind ?? 'text';
    const isEmail = field?.isEmail ?? false;
    const isHost = field?.isHost ?? false;

    const opts = field.options;
    let options: string[] = [];

    if (opts.length && opts[0] instanceof DropdownOption) {
      options = opts.mapBy('value');
    }

    if (isHost) return null;
    if (isEmail) return null;

    return createTextColumnWithGenericValidation({
      allowEmpty: !isRequestedFromEmployee,
      csvHeader: name,
      data: escapeHTML(name),
      source: kind === 'single-selection' ? options : [],
      type: kind === 'single-selection' ? 'dropdown' : 'text',
    });
  }

  @action
  setHotInstance(hotInstance: Handsontable): void {
    this.hotInstance = hotInstance;
    this.args.onHotInstance?.(hotInstance);
  }

  onRowUpdateTask = task({ restartable: true }, async (hotInstance: Handsontable): Promise<void> => {
    await timeout(zft(1));

    const data = <unknown[][]>hotInstance.getData();
    const validRows: unknown[][] = [];
    const filledRows: unknown[][] = [];
    const emailColumnIndex = this.columns.findIndex((c) => c.csvHeader === 'invitee_email');
    const emails: string[] = [];
    const headers = this.colHeaders;
    const csvHeaders = A(this.columns).mapBy('csvHeader') as string[];

    await Promise.all(
      data.map((row: unknown[], i: number) => {
        if (hotInstance.isEmptyRow(i)) return;

        filledRows.push(row);

        const email = (row[emailColumnIndex] ?? '') as string;

        if (validateEmail(email)) emails.push(email);

        return new Promise((resolve) => {
          hotInstance.validateRows([i], (valid) => {
            if (valid) validRows.push(row);
            resolve(valid);
          });
        });
      }),
    );

    this.rowLength = filledRows.length;

    const hasEmail = this.rowLength > 0 && emails.length > 0;
    const output: InvitesSpreadsheetOutputArgs = {
      csvHeaders,
      data,
      emailCount: emails.length,
      filledRows,
      hasEmail,
      headers,
      rowCount: this.rowLength,
      validRows,
    };

    this.args.onChange?.(output);
  });
}

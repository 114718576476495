import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type LocationModel from 'garaje/models/location';
import type PrinterConnectionModel from 'garaje/models/printer-connection';
import type UserModel from 'garaje/models/user';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';

interface PrinterConnectionFilter {
  'location-id'?: string;
  'zone-id'?: string;
}

interface PrinterConnectionQuery {
  filter: PrinterConnectionFilter;
}

export interface PrinterConnectionsRouteModel {
  printerConnections: ArrayProxy<PrinterConnectionModel>;
  location: LocationModel | null;
  user: UserModel | null;
  printerConnection?: PrinterConnectionModel;
  query: PrinterConnectionQuery;
}

export default class VisitorsDevicesPrintersConnections extends Route {
  @service declare featureFlags: FeatureFlagsService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: Store;

  beforeModel(): void {
    const isNewPrinterExperience: boolean = this.featureFlags.isEnabled('expanded-printer-support-v1');

    if (!isNewPrinterExperience) {
      void this.router.transitionTo('visitors.devices.printers.new');
    }
  }

  async model(): Promise<PrinterConnectionsRouteModel> {
    const location: LocationModel | null = this.state.currentLocation;
    const user: UserModel | null = this.state.currentUser;
    const filter: PrinterConnectionFilter = {};

    if (location?.id) {
      filter['location-id'] = location.id;
    }

    const query: PrinterConnectionQuery = { filter };
    const printerConnections: ArrayProxy<PrinterConnectionModel> = await this.store.query('printer-connection', query);

    return { query, location, user, printerConnections };
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';
import moment from 'moment-timezone';
import rome from 'rome';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { run, next } from '@ember/runloop';
import { localCopy } from 'tracked-toolbox';
import { getOwner } from '@ember/application';

const TIME_FORMAT = 'h:mm a';

/**
 * @param {String}              format
 * @param {Date}                max
 * @param {Date}                min
 * @param {String}              timezone
 * @param {Boolean}             showDate
 * @param {Boolean}             showTime
 * @param {Date}                time
 * @param {Function}            timeSetter
 * @param {Boolean}             disabled
 * @param {Boolean}             isReadonly
 * @param {String}              inputClass
 * @param {String}              ariaLabel
 * @param {String}              placeholder
 * @param {Boolean}             isRequired
 */
export default class RomeCalendar extends Component {
  elementId = guidFor(this);

  @tracked targetOpacitySelector;
  @tracked calendar;
  @localCopy('args.isRequired', true) isRequired;

  get isTimepicker() {
    return !this.args.showDate && !!this.args.showTime;
  }

  @action
  onInsert() {
    this.targetOpacitySelector = `.rd-time-list.belongs-to-${this.elementId}`;
    this._updateCalendarOptions();
  }

  @action
  onDestroy() {
    this.calendar.destroy();
  }

  _updateCalendarOptions() {
    const calendarOptions = this._buildCalendarOptions();
    const { timeSetter, time, isReadonly } = this.args;
    let calendar = this.calendar;

    if (calendar) {
      if (calendar.destroyed) {
        calendar.restore();
      }
      calendar.options(calendarOptions);
    } else {
      calendar = rome(document.querySelector(`#${this.elementId} input`), calendarOptions);
      this.calendar = calendar;
    }

    calendar.on('data', function () {
      run(this, function () {
        timeSetter(time, this.getDate());
      });
    });

    calendar.on('show', () => {
      run(this, function () {
        const { container, associated } = calendar;
        const calRect = container.getBoundingClientRect();
        const inputRect = associated.getBoundingClientRect();

        // checks if element is in viewport
        if (calRect.bottom > window.innerHeight) {
          const top = parseInt(container.style.top, 10);
          container.style.top = `${top - (calRect.height + inputRect.height)}px`;
        }
      });
    });

    if (isReadonly) {
      this.calendar.destroy();
    }
  }

  _buildCalendarOptions() {
    const isTimepicker = this.isTimepicker;
    const { tz, format, showDate, showTime } = this.args;
    const time = moment(this.args.time);
    let { max, min } = this.args;

    const rootElement = getOwner(this).rootElement;

    const calendarOptions = {
      appendTo: document.querySelector(rootElement),
      autoClose: true,
      date: showDate,
      required: this.isRequired,
      initialValue: time,
      inputFormat: format,
      time: showTime,
      timeFormat: TIME_FORMAT,
      timeInterval: 900,
      styles: {
        timeList: `rd-time-list belongs-to-${this.elementId}`,
      },
    };

    if (isPresent(max)) {
      // passing in a timezone allows the datepicker to show the max time for a specific timezone instead of the default timezone
      max = tz ? moment.tz(max, tz) : moment(max);

      if (isTimepicker) {
        // rome doesn't always work correctly unless this matches `timeFormat`
        calendarOptions.max = max.format(TIME_FORMAT);
      } else {
        calendarOptions.max = max;
      }
    }

    if (isPresent(min)) {
      // passing in a timezone allows the datepicker to show the min time for a specific timezone instead of the default timezone
      min = tz ? moment.tz(min, tz) : moment(min);

      if (isTimepicker) {
        // rome doesn't always work correctly unless this matches `timeFormat`
        calendarOptions.min = min.format(TIME_FORMAT);
      } else {
        calendarOptions.min = min;
      }
    }

    return calendarOptions;
  }

  @action
  onClick() {
    next(() => {
      this._focusOnSelectedTime();
    });
  }

  @action
  onFocusout() {
    next(() => {
      this._hideListOfTimes();
    });
  }

  /*
   * Hides date drawer to prevent future flicker from selected
   * default date
   */
  _hideListOfTimes() {
    if (!this.args.showTime) {
      // If not showing time, do not need to focus on time
      return;
    }

    const list = document.querySelector(this.targetOpacitySelector);
    if (list && list.style) {
      list.style.opacity = 0;
    }
  }

  /*
   * Focuses/selects the currently selected time
   * Scrolls the timepicker menu to near the selected time
   * and then makes the date drawer visible to
   * prevent flicker
   */
  _focusOnSelectedTime() {
    if (!this.args.showTime) {
      // If not showing time, do not need to focus on time
      return;
    }

    const list = document.querySelector(this.targetOpacitySelector);
    const options = list ? [...list.querySelectorAll('.rd-time-option')] : [];
    const input = document.querySelector(`#${this.elementId} input`);
    const inputTime = input && input.value;

    // create a moment from the input time
    // if the input is blank then use the current time so we can still scroll to a good position
    const mInput = inputTime ? moment(inputTime, 'h:mm a') : moment();

    // round the time up to the next 15 minute interval so that we can scroll the time menu to it
    const roundedTime = mInput.minute(Math.ceil(mInput.minute() / 15) * 15).format('h:mm a');
    // get the dropdown option that is rounded up from the input time
    const matchedTimeOption = options.find((option) => option.innerText === roundedTime);
    const matchedTimeOptionIndex = options.indexOf(matchedTimeOption);

    options.forEach((option) => option.classList.remove('selected', 'matched'));

    // scroll the timepicker menu based on where the roundedTime is in the menu
    if (matchedTimeOption) {
      window.scrollTop = 0;

      matchedTimeOption.classList.add('matched');
      if (inputTime === matchedTimeOption.textContent) {
        // only add the selected class if the input time matches a dropdown time
        matchedTimeOption.classList.add('selected');
      }

      if (mInput.isAfter(moment('11:45 pm', 'h:mm a'))) {
        // if we're rounding up to 12am we want to scroll to the bottom of the list instead of the top
        options[options.length - 1].scrollIntoView();
      } else if (matchedTimeOptionIndex < 3) {
        // if we're in the top 3 times just scroll to the first
        options[0].scrollIntoView();
      } else {
        // otherwise scroll to the rounded time, but offset by 3 so that we show times before and after
        options[matchedTimeOptionIndex - 3].scrollIntoView();
      }
    }
    // we need to set the opacity to 1 to override the css rule in the template
    if (list && list.style) {
      list.style.opacity = 1;
    }
  }

  @action
  handleKeyDown(e) {
    const isEnterKey = e.which === 13;

    if (isEnterKey) {
      e.preventDefault();
      e.target.blur();
    }
  }

  @action
  handleInput(e) {
    if (e.target.value === '') {
      this.args.timeSetter(null, null);
    }
  }
}

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { setProperties } from '@ember/object';
import { all } from 'rsvp';

export default class DesksReservationsRoute extends Route {
  @service state;
  @service store;
  @service router;
  @service featureFlags;
  @service featureConfig;
  @service metrics;
  @service abilities;

  queryParams = {
    startDate: {
      replace: true,
    },
    endDate: {
      replace: true,
    },
    selectedDateRange: {
      as: 'dateRange',
      replace: true,
    },
    selectedEmployeesIds: {
      as: 'employees',
      replace: true,
    },
    selectedFloorsIds: {
      as: 'floors',
      replace: true,
    },
    selectedDesksIds: {
      as: 'desks',
      replace: true,
    },
    sort: {
      replace: true,
    },
  };

  beforeModel() {
    if (this.abilities.cannot('visit-reservations desks')) {
      this.router.transitionTo('unauthorized');
    }
  }

  async model() {
    const { currentLocation } = this.state;
    await this.store.findRecord('desk-location', this.state.currentLocationEnabledDesks.id, {
      reload: true,
      include: 'workplace-days',
    });
    const workplaceDays = this.store.peekAll('workplaceDay').filter((day) => {
      return day.belongsTo('deskLocation').id() === this.state.currentLocationEnabledDesks.id;
    });
    return { currentLocation, workplaceDays };
  }

  setSelectedResource(resourceIdArray, resourceArray) {
    return resourceArray.filter((resource) => {
      return resourceIdArray.includes(resource.id);
    });
  }

  async setupController(controller, model) {
    super.setupController(...arguments);
    setProperties(controller, {
      desks: [],
      desksPage: 0,
      desksCount: 0,
      employees: [],
      employeesPage: 0,
      employeesCount: 0,
      floors: [],
      floorsPage: 0,
      floorsCount: 0,
      reservationsPage: 0,
      reservationsCount: 0,
      reservations: [],
      showEmployeeFlowModal: true,
    });
    this.metrics.trackEvent('RezLog_Viewed');
    await model.currentLocation.reload();
    await all([
      controller.loadEmployeesTask.perform(),
      controller.loadFloorsTask.perform(),
      controller.loadDesksTask.perform(),
    ]);
    controller.setStartAndEndTime();
    controller.selectedEmployees = this.setSelectedResource(controller.selectedEmployeesIds, controller.employees);
    controller.selectedFloors = this.setSelectedResource(controller.selectedFloorsIds, controller.floors);
    controller.selectedDesks = this.setSelectedResource(controller.selectedDesksIds, controller.desks);

    await controller.loadReservationsDropTask.perform();
  }
}

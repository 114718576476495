import Model, { attr, hasMany, type AsyncHasMany } from '@ember-data/model';
import type DraftModel from 'garaje/models/draft';
import type MapFeatureModel from 'garaje/models/map-feature';
import type MapFloorModel from 'garaje/models/map-floor';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('maps')
class AreaMapModel extends Model {
  @attr('string') declare entityId: string;
  @attr('string', { defaultValue: 'LOCATION' }) declare entityType: 'ZONE' | 'LOCATION';
  @attr('string', { defaultValue: 'SIMPLE' }) declare mapType: string;

  @hasMany('draft', { inverse: 'draftAreaMap' }) declare drafts: AsyncHasMany<DraftModel>;
  @hasMany('map-floor') declare mapFloors: AsyncHasMany<MapFloorModel>;
  @hasMany('map-feature') declare mapFeatures: AsyncHasMany<MapFeatureModel>;
}

export default AreaMapModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'area-map': AreaMapModel;
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import type StateService from 'garaje/services/state';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class VisitorsRoute extends Route {
  @service declare router: RouterService;
  @service declare state: StateService;

  beforeModel(): void {
    this.redirectOnExpiredTrialOrNoSub();
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    if (transition.targetName.startsWith('visitors.')) {
      this.redirectOnExpiredTrialOrNoSub();
    }
  }

  redirectOnExpiredTrialOrNoSub(): void {
    const { vrSubscription } = this.state;
    if (vrSubscription?.cancelled) {
      void this.router.transitionTo('visitors.trial-ended');
    }
    if (isBlank(vrSubscription)) {
      void this.router.transitionTo('visitors.start-trial');
    }
  }
}

import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import type ZoneModel from 'garaje/models/zone';

export default class PrinterPasscode extends Model {
  @attr('string')
  declare code: string;

  @attr('boolean', { defaultValue: false })
  declare factoryRecord: boolean;

  @attr('date')
  declare consumedAt: Date;

  @attr('date')
  declare expiresAt: Date;

  @attr('date')
  declare createdAt: Date;

  @belongsTo('company')
  declare company: AsyncBelongsTo<Model>;

  @belongsTo('location')
  declare location: AsyncBelongsTo<Model>;

  @belongsTo('user')
  declare user: AsyncBelongsTo<Model>;

  @belongsTo('zone')
  declare zone: AsyncBelongsTo<ZoneModel>;

  @belongsTo('printer-connection', { async: false })
  declare printerConnection: AsyncBelongsTo<Model>;

  get printerConnectionId(): string | null {
    const record = this as PrinterPasscode;

    return record.belongsTo('printerConnection').id();
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'printer-passcode': PrinterPasscode;
  }
}

import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';

import type AnnouncementModel from './announcement';
import type EmployeeModel from './employee';

export default class AnnouncementRecipientModel extends Model {
  @belongsTo('announcement') declare announcement: AsyncBelongsTo<AnnouncementModel>;
  @belongsTo('employee') declare employee: AsyncBelongsTo<EmployeeModel>;

  // attribute
  @attr('string') declare name: string;
  @attr('string') declare phoneNumber?: string;
  @attr('string') declare status: string;
  @attr('boolean') declare smsSent: boolean;
  @attr('boolean') declare emailSent: boolean;
  @attr('boolean') declare pushSent: boolean;
  @attr('boolean') declare markedAsSafe: boolean;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'announcement-recipient': AnnouncementRecipientModel;
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { fromUnixTime, isToday, isYesterday, isTomorrow, format, subMinutes } from 'date-fns';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { defer } from 'rsvp';
import urlBuilder from 'garaje/utils/url-builder';

export default class ReservationFeedItem extends Component {
  @service metrics;
  @service state;
  @service ajax;
  @service store;

  @tracked showMenu = false;

  @dropTask
  reservationModalTask = {
    *perform() {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = async () => {
        this.context.args.loadReservationsTask.perform();
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  @dropTask
  *deleteReservationTask() {
    const { reservation } = this.args;

    const url = urlBuilder.rms.releaseDesk();
    yield this.ajax.request(url, {
      type: 'POST',
      contentType: 'application/vnd.api+json',
      data: {
        data: {
          attributes: {
            'reservation-id': reservation.id,
          },
        },
      },
    });
    this.store.unloadRecord(reservation);

    this.metrics.trackEvent('DelegatedBooking_Used', {
      action: 'delete',
      reservationId: reservation.id,
      partialDay: reservation.isPartialDay,
    });
  }

  @dropTask
  showDeleteConfirmationTask = {
    *perform() {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = async () => {
        this.context.args.loadReservationsTask.perform();
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  getLocationTime(time) {
    const currTime = fromUnixTime(time);
    return subMinutes(currTime, this.state.minutesBetweenTimezones(currTime));
  }

  get reservationDate() {
    const { reservation } = this.args;
    const date = this.getLocationTime(reservation.startTime);
    return this.getFormatedDate(date);
  }

  get reservationTime() {
    const { reservation } = this.args;
    if (reservation.isPartialDay) {
      const startTime = format(this.getLocationTime(reservation.startTime), 'h:mma');
      const endTime = format(this.getLocationTime(reservation.endTime), 'h:mma');

      return `${startTime} - ${endTime}`;
    }
    return 'All day';
  }

  get checkInTime() {
    const { reservation } = this.args;
    if (!reservation.checkInTime) {
      return null;
    }
    return format(this.getLocationTime(reservation.checkInTime), 'h:mma');
  }

  get checkOutTime() {
    const { reservation } = this.args;
    const displayTime = reservation.checkOutTime || reservation.canceledAt;
    if (!displayTime) {
      return null;
    }
    return format(this.getLocationTime(displayTime), 'h:mma');
  }

  getFormatedDate(date) {
    if (isToday(date)) {
      return 'Today';
    }
    if (isYesterday(date)) {
      return 'Yesterday';
    }
    if (isTomorrow(date)) {
      return 'Tomorrow';
    }
    return format(date, 'MM/dd/yyyy');
  }
}

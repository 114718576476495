import Component from '@glimmer/component';
import { restartableTask } from 'ember-concurrency';
import type PluginInstallModel from 'garaje/models/plugin';
import type { VfdContactMethodTeamsMetadata } from 'garaje/models/vfd-contact-method';
import urlBuilder from 'garaje/utils/url-builder';

type VirtualFrontDeskLocationsTableRowTeamsDropdownComponentArgs = {
  disabled?: boolean;
  selectedChannel?: VfdContactMethodTeamsMetadata;
  selectChannel: (channel: VfdContactMethodTeamsMetadata) => void;
  teamsPluginInstall: PluginInstallModel;
};

type TeamsChannelResponse = Array<{ id: string; name: string }>;

export default class VirtualFrontDeskLocationsTableRowTeamsDropdownComponent extends Component<VirtualFrontDeskLocationsTableRowTeamsDropdownComponentArgs> {
  searchChannelsTask = restartableTask(async (query: string) => {
    const pluginInstall = this.args.teamsPluginInstall;
    let url = urlBuilder.teams.v2.searchChannelsUrl(pluginInstall.id);
    if (query) {
      url = `${url}?search=${encodeURIComponent(query)}`;
    }
    try {
      const response = await fetch(url, {
        credentials: 'include',
      });

      if (response.ok) {
        const data = <TeamsChannelResponse>await response.json();
        return data.map((item) => ({
          'channel-id': item.id,
          'channel-name': item.name,
        }));
      } else {
        console.error('fetching list of Teams channels failed: [%s] %s', response.status, response.statusText); // eslint-disable-line no-console
      }
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
    return [];
  });
}

import { validateFormat, validatePresence } from 'ember-changeset-validations/validators';

import validateConfirmation from 'garaje/validators/confirmation';

export default {
  fullName: validatePresence({
    presence: true,
    message: `Full Name can't be blank`,
  }),
  email: [
    validatePresence({
      presence: true,
      message: 'is a required field',
    }),
    validateFormat({
      type: 'email',
      message: 'must be a valid email',
    }),
  ],
  newPasswordConfirmation: validateConfirmation({
    on: 'newPassword',
    message: `Passwords don\'t match`,
    onlyIf(changes) {
      return changes.newPassword;
    },
  }),
};

import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import { GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST } from 'garaje/utils/roles';
import { Permission } from 'garaje/utils/ui-permissions';
import _intersection from 'lodash/intersection';
import mergeWith from 'lodash/mergeWith';

// The choice of roles is derived from the ability file for entries (i.e. entries.ts)
const CAN_CREATE_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST];

const CAN_DELETE_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN];

const CAN_EDIT_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST];

type ReservationAction = 'create' | 'edit' | 'delete';
type ReservationActionAbilities = Record<ReservationAction, boolean>;

const legacyRolesByAction = {
  create: CAN_CREATE_ROLES,
  edit: CAN_EDIT_ROLES,
  delete: CAN_DELETE_ROLES,
};

const permissionsByAction = {
  create: [
    Permission.DESKS_RESERVATION_CREATE,
    Permission.DESKS_DESK_READ,
    Permission.DESKS_AMENITY_READ,
    Permission.DESKS_NEIGHBORHOOD_READ,
  ],
  edit: [
    Permission.DESKS_RESERVATION_UPDATE,
    Permission.DESKS_DESK_READ,
    Permission.DESKS_AMENITY_READ,
    Permission.DESKS_NEIGHBORHOOD_READ,
  ],
  delete: [
    Permission.DESKS_RESERVATION_DELETE,
    Permission.DESKS_DESK_READ,
    Permission.DESKS_AMENITY_READ,
    Permission.DESKS_NEIGHBORHOOD_READ,
  ],
};

export default class ReservationAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare featureConfig: FeatureConfigService;
  @service declare authz: AuthzService;

  get canCreate(): boolean {
    return this.canPerformAction('create');
  }

  get canDelete(): boolean {
    return this.canPerformAction('delete');
  }

  get canEdit(): boolean {
    return this.canPerformAction('edit');
  }

  private canPerformAction(action: ReservationAction): boolean {
    const abilities = this.reservationActionAbilities;
    return abilities[action];
  }

  private get reservationActionAbilities(): ReservationActionAbilities {
    const authzEnabled = this.featureFlags.isEnabled('desks-authz-spaces3');
    const abilitiesViaPermissions = {
      create: authzEnabled && this.authz.hasAllPermissionsAtCurrentLocation(permissionsByAction['create']),
      edit: authzEnabled && this.authz.hasAllPermissionsAtCurrentLocation(permissionsByAction['edit']),
      delete: authzEnabled && this.authz.hasAllPermissionsAtCurrentLocation(permissionsByAction['delete']),
    };

    const { roleNames } = this.currentAdmin;
    const abilitiesByLegacyRoles = {
      create: !authzEnabled && isPresent(_intersection(legacyRolesByAction['create'], roleNames)),
      edit: !authzEnabled && isPresent(_intersection(legacyRolesByAction['edit'], roleNames)),
      delete: !authzEnabled && isPresent(_intersection(legacyRolesByAction['delete'], roleNames)),
    };

    const canManageAsDelegate = this.featureConfig.isEnabled('adminConfig.canManageAllReservations');
    const abilitiesAsDelegate = {
      create: canManageAsDelegate,
      edit: canManageAsDelegate,
      delete: canManageAsDelegate,
    };

    const partial = mergeWith(
      abilitiesViaPermissions,
      abilitiesByLegacyRoles,
      (objValue: boolean, srcValue: boolean) => objValue || srcValue,
    );

    return mergeWith(partial, abilitiesAsDelegate, (objValue: boolean, srcValue: boolean) => objValue || srcValue);
  }
}

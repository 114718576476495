import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { all, dropTask, restartableTask } from 'ember-concurrency';
import type LocationModel from 'garaje/models/location';
import type VisualComplianceConfigurationModel from 'garaje/models/visual-compliance-configuration';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type ImpressionsService from 'garaje/services/impressions';
import type MetricsService from 'garaje/services/metrics';
import type { AnySubscriptionModel } from 'garaje/services/state';
import type StateService from 'garaje/services/state';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

const integration_name = 'Visual Compliance';
const message_code_type = 'integration';

interface VisualCompliancePanelComponentSignature {
  Args: {
    isOpen: boolean;
    location?: LocationModel;
    subscription?: AnySubscriptionModel; // Used by template
    visualComplianceConfiguration?: VisualComplianceConfigurationModel;
    onCreateConfiguration: (config: VisualComplianceConfigurationModel) => void;
    onUpgrade?: () => void;
    toggle: (isOpened: boolean) => void;
  };
}

export default class VisualCompliancePanelComponent extends Component<VisualCompliancePanelComponentSignature> {
  @service declare featureFlags: FeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare impressions: ImpressionsService;
  @service declare metrics: MetricsService;
  @service declare store: Store;
  @service declare state: StateService;

  @tracked confirmUninstall = false;
  @tracked configuration: VisualComplianceConfigurationModel | null;

  constructor(owner: unknown, args: VisualCompliancePanelComponentSignature['Args']) {
    super(owner, args);
    this.configuration = this.args.visualComplianceConfiguration || null;
  }

  get location(): LocationModel | null {
    return this.args.location || null;
  }

  get hasAccessToVisualCompliance(): boolean {
    return !!this.state.features?.canAccessVisualCompliance;
  }

  @action
  onCloseVisualCompliance(): void {
    this.args.toggle(false);
    this.metrics.trackJobEvent('Security - Integration Section Close Button Clicked', {
      integration_name,
      button_text: 'X',
    });
  }

  @action
  onClickConfigure(): void {
    const { configuration } = this;

    this.metrics.startJob();
    this.metrics.trackJobEvent('Security - Go to Modify Integration Settings Button Clicked', {
      integration_name,
      button_text: 'Configure',
      credentials_status: configuration?.credentialsInvalid && configuration?.secno ? 'invalid' : 'valid',
    });
    this.args.toggle(true);
  }

  @action
  onClickUpgrade(): void {
    this.metrics.startJob();
    this.metrics.trackJobEvent('Security - Integration Upgrade Button Clicked', {
      integration_name,
      button_text: 'Upgrade',
      plan_upgrade_target: 'enterprise',
    });
    this.args.onUpgrade?.();
  }

  installTask = dropTask(async () => {
    const { location } = this;

    if (!location) return;

    this.metrics.startJob();
    this.metrics.trackJobEvent('Security - Go to Integration Section Button Clicked', {
      integration_name,
      button_text: 'Install',
    });

    try {
      if (!this.configuration) {
        const config = this.store.createRecord('visual-compliance-configuration', { location });

        await config.save();

        this.configuration = config;
        this.args.onCreateConfiguration(config);
      }

      location.visualComplianceEnabled = true;

      this.metrics.trackJobEvent('Security - Integration Install Requested', { integration_name });

      await location.save();

      this.metrics.trackJobEvent('Security - Integration Installed', { integration_name });

      this.args.toggle(true);

      this.flashMessages.showAndHideFlash('success', 'Installed!');

      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'success',
        integration_name,
        message_title: 'Installed!',
        message_code: 'integration_install_success',
        message_code_type,
      });

      await this.impressions.postImpression.perform(
        IMPRESSION_NAMES.SECURITY_VR_SCREENING_OPTIONS_VISUAL_COMPLIANCE['ENABLED'],
      );
    } catch (e) {
      location.rollbackAttributes();
      this.configuration?.rollbackAttributes?.();
      this.args.toggle(false);

      const message_title = 'There was an issue installing Visual Compliance';
      const message_code = parseErrorForDisplay(e);

      this.flashMessages.showFlash('error', message_title, message_code);
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'error',
        integration_name,
        message_title,
        message_code,
        message_code_type,
      });
    }
  });

  uninstallTask = dropTask(async () => {
    const { location } = this;

    if (!location) return;

    this.confirmUninstall = false;
    this.metrics.trackJobEvent(`Security - Integration Uninstall Button Clicked`, {
      integration_name,
      button_text: 'Uninstall',
    });

    // Only reset local (not Global) visualComplianceConfigurations
    const flows = location.flows.filter((flow) => !flow.isGlobalChild);
    let flowConfigurations = await all(flows.map((flow) => flow.visualComplianceConfiguration));
    flowConfigurations = flowConfigurations.filter(Boolean);

    flowConfigurations.map((config) => (config.fieldsToCheck = ['name']));
    location.visualComplianceEnabled = false;
    this.args.toggle(false);

    try {
      this.metrics.trackJobEvent('Security - Integration Uninstall Requested', { integration_name });

      await all([location, ...flowConfigurations].map((record) => record.save()));

      this.metrics.trackJobEvent('Security - Integration Uninstalled', { integration_name });
      this.flashMessages.showAndHideFlash('success', 'Uninstalled!');
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'success',
        integration_name,
        message_title: 'Uninstalled!',
        message_code: 'integration_uninstall_success',
        message_code_type,
      });

      await this.impressions.postImpression.perform(
        IMPRESSION_NAMES.SECURITY_VR_SCREENING_OPTIONS_VISUAL_COMPLIANCE['DISABLED'],
      );
    } catch (e) {
      location.rollbackAttributes();
      flowConfigurations.map((config) => config.rollbackAttributes());
      this.args.toggle(true);

      const message_title = 'There was an issue uninstalling Visual Compliance';
      const message_code = parseErrorForDisplay(e);

      this.flashMessages.showAndHideFlash('error', message_title, message_code);
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'error',
        integration_name,
        message_title,
        message_code,
        message_code_type,
      });
    }
  });

  saveTask = restartableTask(async () => {
    const { configuration } = this;

    if (!configuration) return;

    try {
      this.metrics.trackJobEvent('Security - Integration Settings Update Requested', { integration_name });

      await configuration.save();

      this.metrics.trackJobEvent('Security - Integration Settings Updated', {
        integration_name,
        visual_compliance_contact_ids: configuration.contactIds,
        visual_compliance_contacts_modified: true,
      });

      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'success',
        integration_name,
        message_title: 'Saved!',
        message_code: 'integration_update_success',
        message_code_type,
      });
    } catch (e) {
      const message_title = 'Error';
      const message_code = parseErrorForDisplay(e);

      this.flashMessages.showFlash('error', message_title, message_code);
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'error',
        integration_name,
        message_title,
        message_code,
        message_code_type,
      });
    }
  });
}

import Transform from '@ember-data/serializer/transform';
import { TrackedSet } from 'tracked-built-ins';

export default class TrackedSetTransform extends Transform {
  deserialize(value) {
    return new TrackedSet(value || []);
  }

  serialize(value) {
    // serialize a TrackedSet as an array
    return [...value];
  }
}

import Service, { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type SetupGuideItemModel from 'garaje/models/setup-guide-item';
import type UserModel from 'garaje/models/user';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';

export default class SetupGuideStepperService extends Service {
  @service declare store: StoreService;
  @service declare state: StateService;
  @service declare flashMessages: FlashMessagesService;
  @service declare statsig: StatsigService;

  @tracked setupGuideItems: SetupGuideItemModel[] = [];
  @tracked allStepsComplete: boolean = false;

  loadSetupStepsTask = task({ drop: true }, async (app: string) => {
    const allSetupGuideItems = await this.store.query('setup-guide-item', { app, reload: true });
    this.setupGuideItems = allSetupGuideItems.filter((item) => item.metadata.show_on_stepper === true);
    this.checkAllStepsComplete();
  });

  toggleSetupGuideStepsEnabledTask = task({ drop: true }, async () => {
    this.currentUser.visitorsProductVideosEnabled = !this.visitorsSetupGuideEnabled;

    try {
      await this.currentUser.save();

      if (!this.currentUser.visitorsProductVideosEnabled)
        this.flashMessages.showFlash(
          'success',
          'Toggle the setup guide from your user profile to re-enable onboarding',
        );
    } catch (_e) {
      this.currentUser.rollbackAttributes();
    }
  });

  checkAllStepsComplete(): void {
    this.allStepsComplete = this.setupGuideItems.every((item) => item.completed);
    if (this.allStepsComplete) {
      this.statsig.logEvent('setup_guide_stepper_all_steps_complete');
    }
  }

  get displayForVisitors(): boolean {
    if (this.state.vrSubscription) {
      if (this.state.vrSubscription.cancelled || this.state.vrSubscription.isBasicUser) {
        return false;
      }
    } else {
      return false;
    }

    return true;
  }

  get showStepper(): boolean {
    return this.displayForVisitors && this.visitorsSetupGuideEnabled;
  }

  get currentUser(): UserModel {
    return this.state.currentUser;
  }

  get visitorsSetupGuideEnabled(): boolean {
    return this.state.currentUser.visitorsProductVideosEnabled;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'setup-guide-stepper': SetupGuideStepperService;
  }
}

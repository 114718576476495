const DISPLAY_ROLE_NAME_MAPPING = {
  Receptionist: 'Front Desk Admin',
  Security: 'Security Admin',
  Billing: 'Billing Admin',
};

/**
 * @returns {*}
 */
export default function (Class) {
  const DecoratedClass = class extends Class {
    get displayRoleName() {
      return DISPLAY_ROLE_NAME_MAPPING[this.roleName];
    }
  };

  // reassign the original class name as the name of the decorated class
  Object.defineProperty(DecoratedClass, 'name', { value: Class.name });

  return DecoratedClass;
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type TenantModel from 'garaje/models/tenant';
import type ZoneModel from 'garaje/models/zone';
import { hash } from 'rsvp';

interface PropertySettingsTenantsPermissionsRouteModel {
  tenant: TenantModel;
  property: ZoneModel;
}

export default class PropertySettingsTenantsPermissionsRoute extends Route {
  @service declare store: Store;
  @service declare router: RouterService;

  model(params: { tenant_id: string }): Promise<PropertySettingsTenantsPermissionsRouteModel> {
    return hash({
      property: <ZoneModel>this.modelFor('property'),
      tenant: this.store.findRecord('tenant', params.tenant_id, {
        include: 'tenant-permissions-configuration,property',
        reload: true,
      }),
    });
  }

  afterModel({ tenant, property }: PropertySettingsTenantsPermissionsRouteModel): void {
    const propertyId = tenant.belongsTo('property').id();

    // making sure that the tenant permissions being rendered are for a tenant under the current property
    if (propertyId !== property.id) {
      void this.router.replaceWith('property.settings.tenants.list');
    }
  }
}

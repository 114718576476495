import { momentOrMomentTimezone } from '../index.js';
import BaseHelper from './-base.js';
import '@embroider/macros';
import '@ember/runloop';
import '@ember/component/helper';
import '@ember/object';
import '@ember/service';

var unix = BaseHelper.extend({
  compute([unixTimeStamp]) {
    this._super(...arguments);

    return this.moment.moment(momentOrMomentTimezone.unix(unixTimeStamp));
  }

});

export { unix as default };

/* eslint-disable ember/no-computed-properties-in-native-classes */
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type InvoiceEstimateModel from 'garaje/models/invoice-estimate';
import type LocationSubscriptionModel from 'garaje/models/location-subscription';
import type SubscriptionModel from 'garaje/models/subscription';
import adapter from 'garaje/utils/decorators/adapter';
import { ITEM } from 'garaje/utils/enums';
import type { ValueOf } from 'type-fest';

@adapter('billing-v1')
class SubscriptionEstimateModel extends Model {
  @belongsTo('invoice-estimate', { async: false }) declare currentInvoiceEstimate: InvoiceEstimateModel;
  @belongsTo('invoice-estimate', { async: false }) declare nextInvoiceEstimate: InvoiceEstimateModel;
  @belongsTo('subscription') declare subscription: AsyncBelongsTo<SubscriptionModel>;
  @belongsTo('location-subscription') declare locationSubscription: AsyncBelongsTo<LocationSubscriptionModel>;

  @attr('string') declare app: string;
  @attr('string') declare couponCode: string;
  @attr('string') declare period: string;
  @attr('string') declare plan: string;
  @attr('date') declare nextBillingAt: Date;
  @attr('number') declare quantity: number;

  @alias('nextInvoiceEstimate.discounts') discounts!: InvoiceEstimateModel['discounts'];
  @alias('nextInvoiceEstimate.taxes') taxes!: InvoiceEstimateModel['taxes'];
  @alias('nextInvoiceEstimate.total') total!: InvoiceEstimateModel['total'];
  @alias('nextInvoiceEstimate.lineItems') lineItems!: InvoiceEstimateModel['lineItems'];

  @computed('app')
  get item(): ValueOf<typeof ITEM> {
    switch (this.app) {
      case 'deliveries':
        return ITEM.DELIVERIES;
      case 'rooms':
        return ITEM.ROOMS;
      default:
        return ITEM.VISITORS;
    }
  }
}

export default SubscriptionEstimateModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'subscription-estimate': SubscriptionEstimateModel;
  }
}

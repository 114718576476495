import { isEmpty } from '@ember/utils';
import { get, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class MappedField {
  @tracked changeset;

  constructor({ fieldData, isRequired }) {
    this.fieldData = fieldData;
    this.changeset = fieldData?.changeset;
    this.field = fieldData?.field;
    this.isRequired = isRequired;
  }

  get value() {
    return get(this.changeset, 'value');
  }

  set value(v) {
    set(this.changeset, 'value', v);
  }

  get isEmptyAndRequired() {
    return this.isRequired && isEmpty(this.value);
  }
}

import { action, set } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type DetailedChangeset } from 'ember-changeset/types';
import type SuperTextEditorManagerService from 'ember-super-prosemirror/services/super-text-editor-manager';
import type AbstractGdprConfigurationModel from 'garaje/models/abstract/abstract-gdpr-configuration';
import { optOutDefaultMessage } from 'garaje/models/abstract/abstract-gdpr-configuration';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { sanitizeMarkup } from 'garaje/utils/sanitize-markup';
import { localCopy } from 'tracked-toolbox';
import { isChangeset } from 'validated-changeset';

interface Preview {
  name: string;
  label: string;
}

interface GdprV2Args {
  color: string;
  gdprConfiguration: AbstractGdprConfigurationModel | DetailedChangeset<AbstractGdprConfigurationModel>;
  hasMultipleVisitorTypes?: boolean;
  savePrivacySetting: () => unknown;
  setDataPrivacySetting: (value: boolean) => unknown;
}

export default class GdprV2 extends Component<GdprV2Args> {
  @service declare superTextEditorManager: SuperTextEditorManagerService;
  @service declare flashMessages: FlashMessagesService;
  @service declare workplaceMetrics: WorkplaceMetricsService;

  @tracked isEditing = false;
  @tracked isPreviewing = false;
  @tracked userSelectedPreview = 'signIn';

  @tracked editorPreview?: string;

  @localCopy('args.gdprConfiguration') config!: AbstractGdprConfigurationModel;

  get configEnabledAndEditing(): boolean {
    return !!this.config?.enabled && this.isEditing;
  }

  get availablePreviews(): Preview[] {
    const { optOutVisitorProtocolEnabled: consentEnabled, policyDisclosureEnabled: disclosureEnabled } =
      this.config ?? {};

    let options = [{ name: 'signIn', label: 'Sign-in screen' }];

    if (disclosureEnabled) {
      options = [...options, { name: 'dataPolicy', label: 'Data policy' }];
    }

    if (consentEnabled) {
      options = [...options, { name: 'finalScreen', label: 'Final screen' }];
    }

    return options;
  }

  get selectedPreview(): string {
    const userSelectedPreview = this.userSelectedPreview;
    const availablePreviews = this.availablePreviews;

    if (availablePreviews.some((v) => v.name === userSelectedPreview)) {
      return userSelectedPreview;
    }

    return 'signIn';
  }

  get shouldShowResetToDefault(): boolean {
    const message = this.config?.optOutVisitorProtocol;

    return message !== optOutDefaultMessage;
  }

  rollback(): void {
    this.isEditing = false;

    if (isChangeset(this.config)) {
      (<DetailedChangeset<AbstractGdprConfigurationModel>>this.config)?.rollback();
    } else {
      this.config?.rollbackAttributes();
    }
  }

  @action
  toPreview(): void {
    this.isPreviewing = true;

    const editor = this.superTextEditorManager.getEditor();
    if (editor) this.editorPreview = sanitizeMarkup(editor.html, { permittedTags: ['p', 'em', 'strong'] });
  }

  @action
  cancel(): void {
    this.rollback();
  }

  @action
  disablePrivacySetting(): void {
    this.args.setDataPrivacySetting(false);
    // no need to save config if it doesn't exist and is being created by the FE
    if (!this.config.isNew) {
      void this.args.savePrivacySetting();
    }

    this.rollback();
  }

  @action
  enablePrivacySetting(): void {
    this.workplaceMetrics.trackEvent('LOCATION_SETTINGS_GDPR_SETTINGS_ENABLED');
    this.args.setDataPrivacySetting(true);
    this.isEditing = true;
  }

  @action
  resetFinalScreenMessageToDefault(): void {
    if (!this.config) return;
    set(this.config, 'optOutVisitorProtocol', optOutDefaultMessage);
  }

  @action
  save(): void {
    this.workplaceMetrics.trackEvent('LOCATION_SETTINGS_GDPR_SETTINGS_SAVE_BUTTON_CLICKED');
    const config = this.config;
    if (!config) return;

    const { optOutVisitorProtocolEnabled, optOutVisitorProtocol } = config;

    if (optOutVisitorProtocolEnabled && isEmpty(optOutVisitorProtocol)) {
      set(config, 'optOutVisitorProtocol', optOutDefaultMessage);
    }

    config
      .save()
      .then(() => {
        this.flashMessages.showAndHideFlash('success', 'Saved!');
        this.isEditing = false;
      })
      .catch((e) => {
        const errorText = parseErrorForDisplay(e);
        this.flashMessages.showAndHideFlash('error', errorText);
      });
  }

  @action
  toggleConfigField(fieldName: keyof AbstractGdprConfigurationModel): void {
    this.config?.toggleProperty(fieldName);
  }
}

import Component from '@glimmer/component';

export default class VisitorsFutureCapacity extends Component {
  get expectedCount() {
    return this.args.expectedCount || 0;
  }

  get capacityLimit() {
    return this.args.capacityLimit || 0;
  }

  get capacityRemaining() {
    return Math.max(this.capacityLimit - this.expectedCount, 0);
  }
}

import { FlowsTestTrackingBase } from '../test-tracking-base';
import { action, set } from '@ember/object';

export default class FlowsTestTrackingVisitor extends FlowsTestTrackingBase {
  @action
  configureOptional(checked) {
    const { templateConfigurationChangeset } = this;

    if (!templateConfigurationChangeset) return;

    set(templateConfigurationChangeset, 'optional', checked);

    this.saveTask.perform();
  }

  get rollbackProperties() {
    return [...super.rollbackProperties, 'optional'];
  }
}

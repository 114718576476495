import Controller from '@ember/controller';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Link from 'ember-link/link';
import type LinkManagerService from 'ember-link/services/link-manager';

interface Tab {
  label: string;
  link: Link;
}

export default class PropertySettingsKioskController extends Controller {
  @service declare linkManager: LinkManagerService;
  queryParams = ['backTo'];

  @tracked backTo: string | null = null;

  get backToLink(): string | undefined {
    return this.backTo
      ? {
          ipads: 'property.devices.ipads',
        }[this.backTo]
      : undefined;
  }

  get tabs(): Tab[] {
    return [
      {
        label: 'Welcome screen',
        link: this.linkManager.createUILink({
          route: 'property.settings.kiosk.welcome-screen',
        }),
      },
      {
        label: 'Final screen',
        link: this.linkManager.createUILink({
          route: 'property.settings.kiosk.final-screen',
        }),
      },
      {
        label: 'Assistance screen',
        link: this.linkManager.createUILink({
          route: 'property.settings.kiosk.assistance-screen',
        }),
      },
    ];
  }
}

// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';

export default class RecordSelectRowComponent extends Component {
  tagName = 'tr';

  @computed('selectedModels.[]', 'model')
  get isSelected() {
    return this.selectedModels.includes(this.model);
  }

  input(evt) {
    if (evt.target.checked) {
      this.onSelect(this.model);
    } else {
      this.onDeselect(this.model);
    }
  }
}

import Component from '@glimmer/component';
import { alias, reads } from 'macro-decorators';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { set } from '@ember/object';

import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

/**
 * @param {Number}    highlightedPlanId
 * @param {Boolean}   transitionAfterChange
 */
export default class ManageTrial extends Component {
  @service router;
  @service planFeatures;
  @service flashMessages;
  @service state;
  @service featureFlags;

  @alias('state.vrSubscription') vrSubscription;
  @alias('state.currentCompany') company;
  @reads('vrSubscription.plan') currentPlanName;

  get plans() {
    if (this.vrSubscription?.onTrial || this.featureFlags.isEnabled('hide_visitors_basic')) {
      // don't allow active trials to downgrade to Basic
      // OR need to hide basic if the gate is enabled
      return this.planFeatures.plans.filter((plan) => plan.id !== 'basic');
    }
    return this.planFeatures.plans;
  }

  @task
  *changeTrialTask(planIntent, msg, isUpgrade) {
    const company = this.company;
    const subscription = this.vrSubscription;
    try {
      if (isUpgrade) {
        set(subscription, 'plan', planIntent.id);
      } else {
        set(subscription, 'downgradePlan', planIntent);
      }
      yield subscription.save();
      set(company, 'planIntent', planIntent.id);
      yield company.save();
      yield subscription.reload();
      this.flashMessages.showAndHideFlash('success', msg);
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
    if (this.args.transitionAfterChange) {
      this.router.transitionTo('billing.change-plan', planIntent.id);
    }
  }
}

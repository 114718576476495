import { getOwner } from '@ember/application';

export function routeEvent(target, name, descriptor) {
  const { activate, deactivate } = target;
  let fn;

  target.activate = function (transition) {
    activate.call(this, transition);

    fn = (transition) => {
      // NOTE routeWillChange is called even when transition is aborted, so we need to catch that here
      // And check transition.targetName is present because it might not be which causes an error
      // this is different than the behavior in the previous willTransition route method
      const isAborted = transition.isAborted || transition.isCausedByAbortingTransition;
      if (isAborted || !transition.targetName) return;

      return descriptor.value.call(this, transition);
    };

    const router = getOwner(this).lookup('service:router');
    router.on(name, fn);
  };

  target.deactivate = function (transition) {
    deactivate.call(this, transition);

    const router = getOwner(this).lookup('service:router');
    router.off(name, fn);
  };
}

export class FetchError extends Error {
  payload: unknown;
  status: number;

  constructor(payload: unknown, message = 'Ajax operation failed', status: number) {
    super(message);

    this.payload = payload;
    this.status = status;
  }
}

export class InvalidError extends FetchError {
  constructor(payload: unknown) {
    super(payload, 'Request was rejected because it was invalid', 422);
  }
}

export class UnauthorizedError extends FetchError {
  constructor(payload: unknown) {
    super(payload, 'Ajax authorization failed', 401);
  }
}

export class ForbiddenError extends FetchError {
  constructor(payload: unknown) {
    super(payload, 'Request was rejected because user is not permitted to perform this operation.', 403);
  }
}

export class BadRequestError extends FetchError {
  constructor(payload: unknown) {
    super(payload, 'Request was formatted incorrectly.', 400);
  }
}

export class NotFoundError extends FetchError {
  constructor(payload: unknown) {
    super(payload, 'Resource was not found.', 404);
  }
}

export class GoneError extends FetchError {
  constructor(payload: unknown) {
    super(payload, 'Resource is no longer available.', 410);
  }
}

export class TimeoutError extends FetchError {
  constructor() {
    super(null, 'The ajax operation timed out', -1);
  }
}

export class AbortError extends FetchError {
  constructor() {
    super(null, 'The ajax operation was aborted', 0);

    this.name = 'AbortError';
  }
}

export class ConflictError extends FetchError {
  constructor(payload: unknown) {
    super(payload, 'The ajax operation failed due to a conflict', 409);
  }
}

export class ServerError extends FetchError {
  constructor(payload: unknown, status: number) {
    super(payload, 'Request was rejected due to server error', status);
  }
}

/**
 * Checks if the given error is or inherits from FetchError
 */
export function isFetchError(error: Error): error is FetchError {
  return error instanceof FetchError;
}

/**
 * Checks if the given object represents a "timeout" error
 */
export function isTimeoutError(error: Error): error is TimeoutError {
  return error instanceof TimeoutError;
}

import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import { Permission } from 'garaje/utils/ui-permissions';

export default class VirtualFrontDeskAbility extends Ability {
  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;

  get canUpdateGlobalSettings(): boolean {
    // Cannot access Virtual Front Desk if it's not enabled by subscription
    if (!this.state.features?.canAccessVirtualFrontDesk) return false;

    // Otherwise, access depends on having the "visitors.vfd-configuration.update" permission
    return this.authz.hasPermissionAtCurrentCompany(Permission.VISITORS_VFD_CONFIGURATION_UPDATE);
  }

  get canUpdateLocationSettings(): boolean {
    // Cannot access Virtual Front Desk if it's not enabled by subscription
    if (!this.state.features?.canAccessVirtualFrontDesk) return false;

    // Otherwise, access depends on having the "visitors.vfd-configuration.update" permission at the current location
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_VFD_CONFIGURATION_UPDATE);
  }

  get canViewCallLog(): boolean {
    return this.canViewGlobalSettings;
  }

  get canViewGlobalSettings(): boolean {
    // Cannot access Virtual Front Desk if it's not enabled by subscription
    if (!this.state.features?.canAccessVirtualFrontDesk) return false;

    // Otherwise, access depends on having the "visitors.vfd-configuration.read" permission at the company level
    return this.authz.hasPermissionAtCurrentCompany(Permission.VISITORS_VFD_CONFIGURATION_READ);
  }

  get canViewLocationSettings(): boolean {
    // Cannot access Virtual Front Desk if it's not enabled by subscription
    if (!this.state.features?.canAccessVirtualFrontDesk) return false;

    // Otherwise, access depends on having the "visitors.vfd-configuration.read" permission at the current location
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_VFD_CONFIGURATION_READ);
  }

  get canVisitGlobalPages(): boolean {
    // A user can "visit" virtual front desk if they have the appropriate AuthZ permission, regardless of subscription.
    // This is mostly useful for providing high-level access, where a user might see a landing page (if not subscribed)
    // or actual VFD settings.
    return this.authz.hasPermissionAtCurrentCompany(Permission.VISITORS_VFD_CONFIGURATION_READ);
  }

  get canVisitLocationPages(): boolean {
    // A user can "visit" virtual front desk if they have the appropriate AuthZ permission, regardless of subscription.
    // This is mostly useful for providing high-level access, where a user might see a landing page (if not subscribed)
    // or actual VFD settings.
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_VFD_CONFIGURATION_READ);
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { Changeset } from 'ember-changeset';
import { type DetailedChangeset } from 'ember-changeset/types';
import type ConnectFloorModel from 'garaje/models/connect-floor';
import type ZoneModel from 'garaje/models/zone';

type PropertySettingsFloorsEditParams = {
  id: string;
};

export type PropertySettingsFloorsEditRouteModel = {
  floor: ConnectFloorModel;
  floorChangeset: DetailedChangeset<ConnectFloorModel>;
  property: ZoneModel;
};

export default class PropertySettingsFloorsNewRoute extends Route {
  @service declare router: RouterService;
  @service declare store: StoreService;

  model(params: PropertySettingsFloorsEditParams): PropertySettingsFloorsEditRouteModel | null {
    const property = <ZoneModel>this.modelFor('property');
    const floor = this.store.peekRecord('connect-floor', params.id);
    if (!floor) {
      void this.router.transitionTo('property.settings.floors');
      return null;
    }
    const changeset = Changeset(floor);

    return {
      floor,
      floorChangeset: changeset,
      property,
    };
  }
}

import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import { hasMany, belongsTo } from '@ember-data/model';
import AbstractSignInFieldModel from 'garaje/models/abstract/abstract-sign-in-field';
import type EmployeeSignInFieldActionModel from 'garaje/models/employee-sign-in-field-action';
import type EmployeeSignInFieldPageModel from 'garaje/models/employee-sign-in-field-page';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('sign-in-field')
class EmployeeSignInFieldModel extends AbstractSignInFieldModel {
  @belongsTo('employee-sign-in-field-page', { inverse: 'signInFields' })
  declare signInFieldPage: AsyncBelongsTo<EmployeeSignInFieldPageModel>;
  @hasMany('employee-sign-in-field') declare actionableSignInFields: AsyncHasMany<EmployeeSignInFieldModel>;
  @hasMany('employee-sign-in-field-action') declare signInFieldActions: AsyncHasMany<EmployeeSignInFieldActionModel>;
  @hasMany('employee-sign-in-field-action', { inverse: 'actionableSignInField' })
  declare actionableSignInFieldActions: AsyncHasMany<EmployeeSignInFieldActionModel>;
}

export default EmployeeSignInFieldModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'employee-sign-in-field': EmployeeSignInFieldModel;
  }
}

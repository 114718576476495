import CalendarPermissionsStatusFragment from 'garaje/graphql/fragments/roomba/CalendarPermissionsStatusFragment';
import RoomFragment from 'garaje/graphql/fragments/roomba/RoomFragment';
import gql from 'graphql-tag';

export default gql`
  mutation CheckCalendarPermissions($roomId: String!) {
    checkCalendarPermissions(roomId: $roomId) {
      ...RoomFragment
      calendarPermissionsStatus {
        ...CalendarPermissionsStatusFragment
      }
    }
  }
  ${RoomFragment}
  ${CalendarPermissionsStatusFragment}
`;

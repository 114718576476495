import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type DetailedChangeset } from 'ember-changeset/types';
import type FlashMessagesService from 'garaje/services/flash-messages';
import droppableUpload from 'garaje/utils/decorators/droppable-upload';

import type { PageModel } from '../component';

interface SummaryPageImageBoxArgs {
  uploadImageUrl?: string;
  imgSrc?: string;
  isDisabled?: boolean;
  model: PageModel;
  changeset: DetailedChangeset<PageModel>;
  deleteImage(): void;
  setImage(): void;
  useDirectUploader?: boolean;
}

@droppableUpload
class SummaryPageImageBox extends Component<SummaryPageImageBoxArgs> {
  declare flashMessages: FlashMessagesService;

  imageBoxElement!: HTMLElement;

  validFileTypes = /image\/(gif|jpe?g|png)/;

  @tracked isImageUploading = false;

  get _disableDragAndDrop(): boolean {
    return !!this.args.isDisabled;
  }

  @action
  onError(): void {
    this.flashMessages.showAndHideFlash('error', 'Image upload failed', {
      append: false,
      showAboveModalOverlay: true,
    });
  }

  @action
  selectFile(): void {
    this.imageBoxElement.querySelector('input')?.click();
  }
}

export default SummaryPageImageBox;

import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { APP } from 'garaje/utils/enums';
import { set } from '@ember/object';

class BillingSubscribeRoute extends Route {
  @service router;
  @service store;
  @service locations;
  @service state;
  @service featureFlags;
  @service statsig;

  queryParams = {
    period: {
      replace: true,
    },
    ref: {
      replace: true,
    },
  };

  title = 'Subscribe · Billing · Envoy';

  beforeModel(transition) {
    const isMissingParams = !transition.to.queryParams.plan;
    const { paymentSource } = this.modelFor('billing');

    if (paymentSource.isACH) {
      return this.router.transitionTo('billing');
    }

    if (isMissingParams) {
      transition.abort();
      return this.router.transitionTo('billing.plans');
    }
  }

  async model({ plan: planId, ref }) {
    this.statsig.logEvent('billing-subscribe-page-viewed', null, {
      from: ref,
      planId,
    });
    const { paymentSource, paymentSources } = this.modelFor('billing');

    let quantity;
    let subscription;
    let product;
    let planLevel = planId;

    const plan = await this.store.findRecord('plan', planId.replace('workplace', 'empxp'));
    switch (plan?.id) {
      case 'vfd-standard':
        subscription = this.state.vfdSubscription;
        if (!this.featureFlags.isEnabled('growth_vfd_self_serve_purchase') || subscription?.subscribed) {
          this.router.transitionTo('billing');
        }
        quantity = 1;
        product = APP.VFD;
        planLevel = 'standard';
        break;

      case 'empxp-standard':
      case 'empxp-premium':
      case 'empxp-premium-plus':
        subscription = this.state.workplaceSubscription;
        if (subscription?.subscribed) {
          this.router.transitionTo('billing.product-plans', 'workplace');
        }
        quantity = 25;
        product = APP.WORKPLACE;
        planLevel = plan.id.replace('empxp-', '');
        break;

      default:
        subscription = this.state.vrSubscription;
        if (subscription?.subscribed) {
          this.router.transitionTo('billing.product-plans', 'visitors');
        }
        quantity = Math.max(this.locations.active.length, 1);
        product = APP.VISITORS;
        break;
    }

    return hash({ paymentSource, paymentSources, plan, quantity, subscription, product, planLevel });
  }

  setupController(controller) {
    super.setupController(...arguments);
    setProperties(controller, {
      isPaymentMethodComplete: false,
      lastValidCouponCode: controller.coupon,
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      set(controller, 'isPaymentDisabledMinSeats', false);
    }
  }
}

export default BillingSubscribeRoute;

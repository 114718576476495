import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type NotificationPreferenceModel from 'garaje/models/notification-preference';
import type PluginModel from 'garaje/models/plugin';
import type VfdCompanyConfiguration from 'garaje/models/vfd-company-configuration';
import type StateService from 'garaje/services/state';
import { hash } from 'rsvp';

import type { LocationOverviewVirtualFrontDeskModel } from '../route';

export type LocationOverviewVirtualFrontDeskSettingsModel = {
  notificationPreferences: NotificationPreferenceModel[];
  plugins: PluginModel[];
  vfdCompanyConfiguration: VfdCompanyConfiguration | undefined;
};

export default class LocationOverviewVirtualFrontDeskSettingsRoute extends Route {
  @service declare state: StateService;
  @service declare store: Store;

  async model(): Promise<LocationOverviewVirtualFrontDeskSettingsModel> {
    const { plugins } = <LocationOverviewVirtualFrontDeskModel>this.modelFor('location-overview.virtual-front-desk');

    return hash({
      notificationPreferences: this.store
        .query('notification-preference', {
          filter: {
            relation_id: this.state.currentCompany?.id,
            relation_type: 'company',
            notification_type: 'vr:vfd_call_started_direct_message',
          },
        })
        .then((res) => res.toArray()),
      vfdCompanyConfiguration: this.state.currentCompany?.vfdCompanyConfiguration,
      plugins,
    });
  }
}

import Component from '@glimmer/component';
import { alias } from 'macro-decorators';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';

export default class CreateEmployeeRegistrationFlowModal extends Component {
  @service abilities;
  @service flow;
  @service router;
  @service store;

  @alias('flow.openedModal') openedModal;
  @alias('flow.deletingEmployeeScreeningFlow') deletingEmployeeScreeningFlow;
  @alias('flow.selectedEmployeeScreeningFlow') selectedEmployeeScreeningFlow;
  @alias('flow.activeEmployeeScreeningFlow') activeEmployeeScreeningFlow;

  @dropTask
  *deleteEmployeeScreeningFlow() {
    this.selectedEmployeeScreeningFlow = this.activeEmployeeScreeningFlow;
    const associatedFlow = this.deletingEmployeeScreeningFlow.associatedFlow;
    yield this.deletingEmployeeScreeningFlow.destroyRecord();

    if (associatedFlow) {
      this.store.unloadRecord(associatedFlow);
    }

    const transitionRouteString = this.abilities.can('visit settings for workplace')
      ? 'workplace.settings.employees.registration'
      : 'protect.settings.employees.registration';
    this.router.transitionTo(transitionRouteString);

    this.openedModal = '';
  }
}

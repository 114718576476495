import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { MSTEAMS_V2_PLUGIN_KEY, SLACK_V2_PLUGIN_KEY } from 'garaje/utils/enums';

import { type LocationOverviewVirtualFrontDeskSettingsModel } from './route';

function platformKey(platformName: string): string {
  switch (platformName) {
    case 'msteams':
      return MSTEAMS_V2_PLUGIN_KEY;
    case 'slack':
      return SLACK_V2_PLUGIN_KEY;
    default:
      return platformName;
  }
}

// Email and SMS notifications do not require any plugin to be installed and so should always be
// toggle-able (assuming the user has the correct permission). Other forms of notification (e.g.,
// Slack and Teams) require that a plugin be installed. If the plugin _isn't_ installed, these
// should not be toggle-able and should always _show_ as "off", regardless of the value returned
// from the API.
// This list specifies notificationPlatformKey values that require a plugin.
const OFF_WHEN_NOT_INSTALLED = [MSTEAMS_V2_PLUGIN_KEY, SLACK_V2_PLUGIN_KEY];

export default class LocationOverviewVirtualFrontDeskSettingsController extends Controller {
  declare model: LocationOverviewVirtualFrontDeskSettingsModel;

  @service declare flashMessages: FlashMessagesService;

  @tracked showVfdTranscriptionConfirmation = false;

  get doesNotHaveSlackIntegration(): boolean {
    return !this.model.plugins.find((plugin) => plugin.key === SLACK_V2_PLUGIN_KEY);
  }

  get doesNotHaveTeamsIntegration(): boolean {
    return !this.model.plugins.find((plugin) => plugin.key === MSTEAMS_V2_PLUGIN_KEY);
  }

  @action
  isEnabled(platformName: string): boolean {
    const key = platformKey(platformName);

    if (OFF_WHEN_NOT_INSTALLED.includes(key)) {
      const hasPlugin = this.model.plugins.some((plugin) => plugin.key === key);
      if (!hasPlugin) return false;
    }

    const notificationPreference = this.model.notificationPreferences.find(
      (pref) => pref.notificationPlatformKey === key,
    );
    return notificationPreference?.preferenceValue ?? false;
  }

  updatePreference = dropTask(async (platformName: string) => {
    const key = platformKey(platformName);
    const notificationPreference = this.model.notificationPreferences.find(
      (pref) => pref.notificationPlatformKey === key,
    );
    if (!notificationPreference) return;
    notificationPreference.preferenceValue = !notificationPreference.preferenceValue;
    try {
      await notificationPreference.save();
    } catch {
      this.flashMessages.showAndHideFlash('error', 'Something went wrong', 'Please try again.');
      notificationPreference.rollbackAttributes();
    }
  });

  @action
  async toggleVfdTranscription(): Promise<void> {
    if (!this.model.vfdCompanyConfiguration) {
      return;
    }

    this.model.vfdCompanyConfiguration.callTranscriptionEnabled =
      !this.model.vfdCompanyConfiguration.callTranscriptionEnabled;

    try {
      await this.model.vfdCompanyConfiguration.save();
      if (this.model.vfdCompanyConfiguration.callTranscriptionEnabled) {
        this.flashMessages.showAndHideFlash('success', 'Transcriptions enabled', { stack: true });
      } else {
        this.flashMessages.showAndHideFlash('success', 'Transcriptions disabled', { stack: true });
      }
    } catch (_err: unknown) {
      this.model.vfdCompanyConfiguration.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', 'Something went wrong', 'Please try again.');
    }
  }

  @action
  async openVfdTranscriptionConfirmation(): Promise<void> {
    if (!this.model.vfdCompanyConfiguration?.callTranscriptionEnabled) {
      await this.toggleVfdTranscription();
      return;
    }

    this.showVfdTranscriptionConfirmation = true;
  }

  @action
  closeVfdTranscriptionConfirmation(): void {
    this.showVfdTranscriptionConfirmation = false;
  }
}

import gql from 'graphql-tag';

import CompanyFragment from './CompanyFragment';
import ConnectionFragment from './ConnectionFragment';
import LocationFragment from './LocationFragment';

export default gql`
  query GetCompany {
    company {
      ...CompanyFragment
      connections {
        ...ConnectionFragment
      }
      locations {
        ...LocationFragment
      }
    }
  }
  ${CompanyFragment}
  ${ConnectionFragment}
  ${LocationFragment}
`;

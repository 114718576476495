import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type StateService from 'garaje/services/state';

export default class VisitorTypesDefaultRoute extends Route {
  @service declare store: StoreService;
  @service declare state: StateService;
  @service declare router: RouterService;

  async beforeModel(transition: Transition): Promise<void> {
    void super.beforeModel(transition);
    const currentLocation = this.state.currentLocation;
    const allFlows = await this.store.query('flow', { filter: { location: currentLocation.id } });
    const defaultFlow = allFlows.firstObject;
    if (defaultFlow?.id) {
      void this.router.transitionTo('visitors.settings.visitor-types.flow', defaultFlow.id);
    } else {
      void this.router.transitionTo('visitors.settings.visitor-types.index');
    }
  }
}

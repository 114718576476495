import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

import { task, timeout } from 'ember-concurrency';

import config from 'garaje/config/environment';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';

/**
 * @param {Location} location        - the location for which to configure the blocklist
 * @param {Function} uploadCompleted - action triggered when upload process completes successfully. Receives no arguments.
 */
export default class BlocklistCsvImportLocationComponent extends Component {
  @service ajax;
  @service('blocklistFilter') blocklistFilterService;
  @service flashMessages;
  @service('blocklistFilterImporter') importer;
  @service uploaderFactory;

  @tracked importedCount = 0;

  @task
  *saveBlocklistFiltersTask(csvFile) {
    const locationId = this.args.location.id;
    const url = urlBuilder.v3.blocklistFilter.uploadCsv(locationId, true);
    try {
      const uploader = this.uploaderFactory.createUploader({
        url,
        headers: { Accept: 'application/vncsvDatad.api+json' },
      });
      yield uploader.upload(csvFile);
      yield this.waitOnStatusTask.perform();
    } catch (e) {
      this.blocklistFilterService.queryByCompanyTask.perform(locationId);
      // eslint-disable-next-line no-console
      console.error(e);
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      throw e; // re-throw the error so the base component can clear the upload
    }
  }

  @task
  *waitOnStatusTask() {
    const locationId = this.args.location.id;
    const url = urlBuilder.v3.blocklistFilter.uploadCsvStatus();
    const settings = {
      url,
      type: 'GET',
      contentType: false,
      headers: { Accept: 'application/vnd.api+json' },
      data: { filter: { location: locationId } },
    };
    while (config.environment !== 'test') {
      const {
        data: { attributes },
      } = yield this.ajax.request(url, settings);
      if (attributes['upload-status'] === 'in_progress') {
        if (attributes['imported-count']) {
          this.importedCount = attributes['imported-count'];
        }
        yield timeout(1250);
        continue;
      }
      if (attributes['upload-status'] === 'done') {
        yield this.blocklistFilterService.queryByCompanyTask.perform(locationId);
        this.args.uploadCompleted();
      } else {
        yield this.blocklistFilterService.queryByCompanyTask.perform(locationId);
        this.flashMessages.showFlash('error', attributes['message']);
      }
      break;
    }
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from '@ember/template';

/**
 * @param {String}            name
 * @param {String}            devicePhoto
 * @param {String}            originalDevicePhoto
 * @param {String}            passportPhoto
 * @param {String}            href
 * @param {String}            monogramClasses
 * @param {Boolean}           large
 * @param {Boolean}           medium
 * @param {Boolean}           borderBottom
 * @param {String}            align
 */
export default class MonogramOrPhoto extends Component {
  @tracked borderBottom;
  @tracked monogramClasses;

  constructor() {
    super(...arguments);
    this.borderBottom = this.args.borderBottom ?? true;
    this.monogramClasses = this.args.monogramClasses ?? '';
  }

  get thumbnailPath() {
    const { devicePhoto: smallThumbnail, passportPhoto: userAvatar } = this.args;

    if (smallThumbnail) {
      return htmlSafe(smallThumbnail);
    } else if (userAvatar && !/assets\/pixel.*png/.test(userAvatar)) {
      return userAvatar;
    }
    return false;
  }

  get originalDevicePhotoPath() {
    const originalDevicePhoto = this.args.originalDevicePhoto;

    if (originalDevicePhoto) {
      return htmlSafe(originalDevicePhoto);
    }

    return false;
  }

  get monogramInitials() {
    let nameAttr = this.args.name ?? '';

    if (/\+\d+/.test(nameAttr)) {
      nameAttr = nameAttr.split('+')[0];
    }

    const nameArray = nameAttr.split(' ');
    let name = '';
    for (let i = 0; i < nameArray.length; i++) {
      name += nameArray[i].slice(0, 1);
    }
    return name.slice(0, 4); // max 4 chars
  }

  get lengthClass() {
    const monogramInitials = this.monogramInitials;

    switch (monogramInitials.length) {
      case 2:
        return 'two';
      case 3:
        return 'three';
      case 4:
        return 'four';
    }
    return '';
  }

  get monogramHueValue() {
    let nameAttr = this.args.name ?? '';

    if (/\+\d+/.test(nameAttr)) {
      nameAttr = nameAttr.split('+')[0];
    }

    const strippedName = nameAttr.replace(/\s+/g, '');
    let asciiSum = 0;
    for (let i = 0; i < strippedName.length; i++) {
      asciiSum += strippedName.charCodeAt(i);
    }
    const hueValue = asciiSum % 360;
    return hueValue;
  }

  get safeStyle() {
    const bg = `background-color: hsl(${this.monogramHueValue}, 33%, 45%)`;

    return htmlSafe(bg);
  }

  get safeImageStyle() {
    const { medium } = this.args;
    const dimensions = medium ? `width: 40px; height: 40px;` : null;

    return htmlSafe(dimensions);
  }
}

import ReservationFragment from 'garaje/graphql/fragments/employee-schedule/ReservationFragment';
import gql from 'graphql-tag';

export default gql`
  mutation ChangeDeskReservation($reservationID: ID!, $currentDeskID: ID!, $newDeskID: ID!) {
    changeDeskReservation(reservationId: $reservationID, currentDeskId: $currentDeskID, newDeskId: $newDeskID) {
      ...ReservationFragment
    }
  }
  ${ReservationFragment}
`;

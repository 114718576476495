import { service } from '@ember/service';
import { isBlank, isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type AbilitiesService from 'ember-can/services/abilities';
import { isProductDisabled } from 'garaje/helpers/product-disabled';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import type UserFeatureFlagsService from 'garaje/services/user-feature-flags';
import { GLOBAL_ADMIN } from 'garaje/utils/roles';
import { Permission } from 'garaje/utils/ui-permissions';
import _intersection from 'lodash/intersection';

const CAN_VISIT_ROLES = [GLOBAL_ADMIN];

export default class GlobalAnalyticAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare skinnyLocations: SkinnyLocationModel;
  @service declare state: StateService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare userFeatureFlags: UserFeatureFlagsService;
  @service declare authz: AuthzService;
  @service declare abilities: AbilitiesService;

  get canVisitAnalytics(): boolean {
    return (
      this.canVisitVisitors ||
      this.canVisitProtect ||
      this.canVisitOccupancy ||
      this.canVisitAttendance ||
      this.canVisitDeliveries ||
      this.canVisitDesks ||
      this.canVisitRooms ||
      this.canVisitReports ||
      this.abilities.can('visit occupancy-global-settings')
    );
  }

  get canVisitVisitors(): boolean {
    const { vrSubscription } = this.state;
    // Company doesn't have VR
    if (isBlank(vrSubscription)) {
      return false;
    }

    return (
      !!vrSubscription?.canAccessGlobalAnalytics &&
      this.authz.hasPermissionAtCurrentCompany(Permission.ANALYTICS_GLOBAL_VISITORS_DASH_READ)
    );
  }

  get canVisitProtect(): boolean {
    const { roleNames } = this.currentAdmin;

    const canAccessProtectGlobalAnalytics =
      !!this.state.features?.canAccessProtectLegacyFeatures && !this.state.features?.canAccessOccupancyAnalytics;

    const unfilteredZoneRoles = this.state.currentUser?.zoneRoles.mapBy('roleName');
    const hasValidRole =
      isPresent(_intersection(CAN_VISIT_ROLES, roleNames)) ||
      isPresent(_intersection(CAN_VISIT_ROLES, unfilteredZoneRoles));

    return canAccessProtectGlobalAnalytics && hasValidRole;
  }

  get canVisitAnalyticsUpsell(): boolean {
    const { vrSubscription } = this.state;
    const hasCorrectRoles = isPresent(_intersection(CAN_VISIT_ROLES, this.currentAdmin.roleNames));

    return hasCorrectRoles && !vrSubscription?.canAccessGlobalAnalytics;
  }

  get canVisitDesks(): boolean {
    const canAccessAnalytics = !!this.state.features?.canAccessDesksAnalytics;

    return canAccessAnalytics && this.authz.hasPermissionAtCurrentCompany(Permission.ANALYTICS_GLOBAL_DESKS_DASH_READ);
  }

  get canVisitOccupancy(): boolean {
    if (isProductDisabled('occupancy')) {
      return false;
    }

    return (
      !!this.state.features?.canAccessOccupancyAnalytics &&
      this.authz.hasPermissionAtCurrentCompany(Permission.ANALYTICS_GLOBAL_OCCUPANCY_DASH_READ)
    );
  }

  get canVisitReports(): boolean {
    const { vrSubscription } = this.state;
    // Company doesn't have VR
    if (isBlank(vrSubscription)) {
      return false;
    }
    return this.canVisitVisitors && !!vrSubscription?.canAccessScheduledReports;
  }

  get canVisitAttendance(): boolean {
    const canAccessAnalytics = !!this.state.features?.canAccessAttendanceAnalytics;

    return canAccessAnalytics && this.authz.hasPermissionAtCurrentCompany(Permission.ANALYTICS_OFFICE_ATTENDANCE_READ);
  }

  get canVisitDeliveries(): boolean {
    const canAccessAnalytics =
      !!this.state.features?.canAccessDeliveriesAnalytics &&
      (this.featureFlags.isEnabled('sigma-embedded-analytics') ||
        this.userFeatureFlags.isEnabled('sigma-embedded-analytics-by-user'));
    return (
      canAccessAnalytics && this.authz.hasPermissionAtCurrentCompany(Permission.ANALYTICS_GLOBAL_DELIVERIES_DASH_READ)
    );
  }

  get canVisitRooms(): boolean {
    const canAccessAnalytics =
      !!this.state.features?.canAccessRoomsAnalytics &&
      (this.featureFlags.isEnabled('sigma-embedded-analytics') ||
        this.userFeatureFlags.isEnabled('sigma-embedded-analytics-by-user'));
    return canAccessAnalytics && this.authz.hasPermissionAtCurrentCompany(Permission.ANALYTICS_GLOBAL_ROOMS_DASH_READ);
  }
}

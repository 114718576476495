import ApplicationSerializer from './application';

export default class EmployeeAgreementPageSerializer extends ApplicationSerializer {
  // This is used for the `hasMany` relationship between flows and skinny-locations
  // envoy-web doesn't know anything about the skinny-locations model.
  modelNameFromPayloadKey(key) {
    if (key === 'flows') {
      return 'employee-screening-flow';
    } else if (key === 'agreements') {
      return 'employee-agreement';
    } else if (key === 'agreement-pages') {
      return 'employee-agreement-page';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }

  payloadKeyFromModelName(modelName) {
    if (modelName === 'employee-screening-flow') {
      return 'flows';
    } else if (modelName === 'employee-agreement') {
      return 'agreements';
    } else if (modelName === 'employee-agreement-page') {
      return 'agreement-pages';
    } else {
      return super.payloadKeyFromModelName(...arguments);
    }
  }
}

import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import UserDocumentTemplate from 'garaje/models/user-document-template';

interface DocumentCtaComponentArgs {
  onClose: (identifiers: string | string[]) => unknown;
  userDocumentTemplate: UserDocumentTemplate;
}

export default class DocumentCtaComponent extends Component<DocumentCtaComponentArgs> {
  @service declare router: RouterService;

  @action
  onClose(identifier: string): void {
    void this.router.transitionTo('profile.document.upload', identifier);
    // selectively dismiss all modals to avoid any irregular user experiences with modals
    // popping up during transitions
    this.args.onClose(UserDocumentTemplate.validIdentifiers);
  }
}

import type ArrayProxy from '@ember/array';
import { A } from '@ember/array';
import Component from '@glimmer/component';
import { PrinterStatus } from 'garaje/models/abstract/printer';
import type PropertyPrinterModel from 'garaje/models/property-printer';

interface PrinterSelectorComponentArgs {
  printers?: ArrayProxy<PropertyPrinterModel>;
  selected?: PropertyPrinterModel;
  onChange?: (printer?: PropertyPrinterModel) => unknown;
  disabled?: boolean;
}

export default class PrinterSelectorComponent extends Component<PrinterSelectorComponentArgs> {
  selectedPrinterId?: PropertyPrinterModel['id'];

  get selected(): PropertyPrinterModel | undefined {
    return this.args.selected;
  }
  set selected(value: PropertyPrinterModel | undefined) {
    this.args.onChange?.(value);
  }

  get sortedPrinters(): PropertyPrinterModel[] {
    const inactive =
      A(this.args.printers?.filter((printer) => printer.printerStatus !== PrinterStatus.OPERATIONAL)).sortBy('name') ??
      [];
    const active =
      A(this.args.printers?.filter((printer) => printer.printerStatus === PrinterStatus.OPERATIONAL)).sortBy('name') ??
      [];
    return [...active, ...inactive];
  }
}

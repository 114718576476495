import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type DS from 'ember-data';
import type {
  GetCompanyQuery,
  GetRoomsLocationWithConfigsQuery,
  GetRoomsQuery,
  GetSpaceSaverConfigQuery,
} from 'garaje/graphql/generated/roomba-types';
import type PluginModel from 'garaje/models/plugin';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import type StateService from 'garaje/services/state';
import type StoreService from 'garaje/services/store';

interface RoombaSettingsIndexRouteModel {
  company: GetCompanyQuery['company'];
  roomLocationConfig: GetRoomsLocationWithConfigsQuery['location'];
  currentLocation: StateService['currentLocation'];
  spaceSaverConfig: GetSpaceSaverConfigQuery['spaceSaverConfig'];
  plugins: DS.RecordArray<PluginModel>;
  rooms: GetRoomsQuery['rooms'];
}
export default class RoombaSettingsIndexRoute extends Route {
  @service declare roombaGraphql: RoombaGraphqlService;
  @service declare state: StateService;
  @service declare store: StoreService;

  async beforeModel(): Promise<void> {
    try {
      await this.roombaGraphql.getCompany();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('company does not exist yet. creating!', e);
      await this.roombaGraphql.createCompany();
    }
  }

  async model(): Promise<RoombaSettingsIndexRouteModel> {
    const { currentLocation } = this.state;
    if (!currentLocation) {
      throw new Error('no current location found while loading roomba settings');
    }

    const [company, roomLocationConfig, spaceSaverConfig, rooms] = await Promise.all([
      this.roombaGraphql.getCompany(),
      this.roombaGraphql.getRoomsLocationWithConfigs(currentLocation.id),
      this.roombaGraphql.getSpaceSaverConfig(),
      this.roombaGraphql.getRooms(this.state.currentLocation?.id),
    ]);

    const plugins = await this.store.query('plugin', {
      include: 'plugin',
      filter: {
        notification_channels: true,
      },
      page: {
        limit: 100,
      },
    });

    return {
      company,
      currentLocation,
      roomLocationConfig,
      spaceSaverConfig,
      plugins,
      rooms,
    };
  }
}

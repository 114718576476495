import MonogramOrPhoto from 'garaje/pods/components/monogram-or-photo/component';
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';

const DEFAULT_SIZE = 5;

/**
 * @param {String}            devicePhoto           URL of photo
 * @param {Boolean}           isDisabled            Indicate photo is display only (no editing)
 * @param {String}            monogramClasses       Classes for the monogram
 * @param {String}            name                  Name of the user/employee (to compute monogram)
 * @param {String}            originalDevicePhoto   URL of photo
 * @param {String}            passportPhoto         URL of photo
 * @param {Number|String}     size                  Diameter of photo element (in rem, default: 5)
 * @param {Function}          onError               Action for handling errors (passes through to modal/input)
 * @param {Number}            outputSize            Size of square output image in px (passes through to modal/input)
 * @param {String}            validationError       Custom validation failure message (passes through to modal/input)
 * @param {String|RegExp}     validType             Allowed file types (passes through to modal/input)
 */
export default class EmployeesProfilePhoto extends MonogramOrPhoto {
  @tracked size;
  @tracked showUploadModal = false;
  @tracked invalidThumbnailPaths;

  constructor() {
    super(...arguments);
    this.size = this.args.size ?? DEFAULT_SIZE;
    this.invalidThumbnailPaths = {};
  }

  get safeSize() {
    return Math.max(parseFloat(`${this.size}`) || DEFAULT_SIZE, 1);
  }

  get safeStyle() {
    const { safeSize, monogramHueValue, monogramInitials } = this;
    const monogramLength = Math.max(2, monogramInitials.length);
    const fontSize = safeSize / monogramLength;
    const sizeStyle = `width: ${safeSize}rem; height: ${safeSize}rem;`;
    const textStyle = `line-height: ${safeSize + 0.2}rem; font-size: ${fontSize}rem;`;
    const bgStyle = `background-color: hsl(${monogramHueValue}, 33%, 45%);`;

    return htmlSafe(`${sizeStyle} ${textStyle} ${bgStyle}`);
  }

  get isUploadModalVisible() {
    if (!this.showUploadModal) return false;
    if (this.args.isDisabled) return false;

    return true;
  }

  get svgIconName() {
    if (this.args.isDisabled) {
      return null;
    }

    if (this.args.isLoading) {
      return 'progress-small-white';
    }

    if (this.thumbnailPath) {
      return 'pencil-white-full';
    }

    return 'camera-white';
  }

  get isValidImage() {
    return !this.invalidThumbnailPaths[this.thumbnailPath.toString()];
  }

  @action
  openUploadModal() {
    if (this.args.isDisabled) return;
    if (this.args.isLoading) return;

    this.showUploadModal = true;
  }

  // Thumbnail path is marked invalid if image loading fails
  // (e.g. image src URL returns 404 not found)
  @action
  handleImageError() {
    const { thumbnailPath } = this;

    if (thumbnailPath) {
      this.invalidThumbnailPaths = { [thumbnailPath.toString()]: true };
    }
  }
}

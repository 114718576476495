import ApplicationSerializer from './application';

export default class AnnouncementSerializer extends ApplicationSerializer {
  attrs = {
    createdAt: {
      serialize: false,
    },
  };

  normalize(model, hash) {
    const { attributes } = hash;

    // created-at is sent from rms as unix time
    if (attributes['created-at'] && Number.isInteger(attributes['created-at'])) {
      attributes['created-at'] *= 1000;
    }

    return super.normalize(...arguments);
  }
}

import Component from '@glimmer/component';
import { gt } from 'macro-decorators';

/**
 * @param {Number}             pageCount
 * @param {Number}             pageNumber
 * @param {Number}             pageSize
 * @param {Number}             recordCount
 */
export default class DeliveryListItem extends Component {
  @gt('args.pageNumber', 1) hasPreviousPage;

  get end() {
    if (this.isLastPage) {
      return this.start + this.lastPageSize - 1;
    } else {
      return this.start + this.args.pageSize - 1;
    }
  }

  get isLastPage() {
    return this.args.pageNumber === this.args.pageCount;
  }

  get hasNextPage() {
    return this.args.pageNumber < this.args.pageCount;
  }

  get lastPageSize() {
    const remainder = this.args.recordCount % this.args.pageSize;

    if (remainder === 0) {
      return this.args.pageSize;
    } else {
      return remainder;
    }
  }

  get nextPageNumber() {
    return this.args.pageNumber + 1;
  }

  get previousPageNumber() {
    const previousPageNumber = this.args.pageNumber - 1;

    if (previousPageNumber > 0) {
      return previousPageNumber;
    } else {
      return 1;
    }
  }

  get start() {
    return this.args.pageNumber * this.args.pageSize - this.args.pageSize + 1;
  }
}

import { belongsTo } from '@ember-data/model';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type { ModelRegistry } from 'ember-data/model';

/**
 * @deprecated
 *
 * Decorator to define an embedded belongsTo relationship. You very likely do not need to use this. This was created
 * to migrate away from ember-data-model-fragments.
 *
 * @param modelName - string reference to the model
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function embeddedBelongsTo<K extends keyof ModelRegistry>(modelName: K) {
  // @ts-ignore
  return belongsTo<K>(modelName, { async: false, embedded: true });
}

import Component from '@glimmer/component';
import { all } from 'rsvp';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { dropTask, enqueueTask } from 'ember-concurrency';

/**
 * @param {Array<Entry>}            entries
 * @param {Function}                confirm
 * @param {Function}                abort
 */
export default class SignOutEntry extends Component {
  @service flashMessages;
  @service metrics;
  @service workplaceMetrics;

  get hasGroupParent() {
    return this.args.entries.any((entry) => entry.hasAdditionalGuests);
  }

  @enqueueTask({
    maxConcurrency: 10,
  })
  *signOutEntriesTask(entry, date) {
    if (!entry.signOutTime) {
      // _signOutTime is set in entry/controller
      const signOutTime = get(entry, '_signOutTime') ?? date;
      set(entry, 'signOutTime', signOutTime);
    }

    yield entry.save();
  }

  @dropTask
  *signOutTask(entries, signOutGroup) {
    const signOutDate = new Date();

    const promise = all(
      entries.map((entry) => {
        let promise;
        if (entry.hasAdditionalGuests && signOutGroup) {
          promise = get(entry, 'additionalGuestEntries').then((children) => {
            const promises = [this.signOutEntriesTask.perform(entry, signOutDate)];

            children.forEach((childEntry) => {
              promises.push(this.signOutEntriesTask.perform(childEntry, signOutDate));
            });

            return all(promises);
          });
        } else {
          promise = this.signOutEntriesTask.perform(entry, signOutDate);
        }

        return promise;
      }),
    );

    try {
      yield promise;

      if (entries.length > 1) {
        const entry_count = entries.reduce((count, entry) => count + entry.additionalGuests, entries.length);
        this.metrics.trackEvent('Bulk Entries Signed Out', { entry_count });
      }

      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.args.confirm({ entries, signOutGroup });
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @action
  closeModal() {
    this.args.abort();
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { guidFor } from '@ember/object/internals';
import { dropTask } from 'ember-concurrency';
import { copy } from 'ember-copy';

/**
 * @param {Model<Integration}           googleApp
 * @param {Object}                      filter
 * @param {Array}                       locations
 * @param {Model<Company>}              company
 * @param {Boolean}                     canEdit
 * @param {Function}                    showFlash
 * @param {Function}                    hideFlash
 * @param {Function}                    handleFailedQuery
 */
export default class GoogleAppsLocationsFilter extends Component {
  @service flashMessages;
  @service googleSyncManager;

  elementId = guidFor(this);

  @tracked filters = [];
  @tracked results;

  constructor() {
    super(...arguments);
    this.filters = this.defaultFilters();
  }

  get isActive() {
    return this.args.filter.type === 'locations';
  }

  get neverSynced() {
    return !get(this.args.company, 'employeesLastSyncAt');
  }

  get isVisible() {
    return this.args.locations.length > 1;
  }

  @action
  _resetResults() {
    if (!this.isActive) {
      this.results = [];
    }
  }

  defaultFilters() {
    let filters = this.args.filter.locations ?? [];
    filters = filters.map(function (query) {
      return copy(query);
    });

    this.args.locations.forEach((location) => {
      const filter = filters.findBy('locationId', parseInt(get(location, 'id')));

      if (!filter) {
        filters.push({
          locationId: parseInt(get(location, 'id')),
          query: '',
        });
      }
    });

    return filters;
  }

  setFilter(data = {}) {
    const filters = data.filters ?? this.filters;

    return this.googleSyncManager.setFilter(this.args.company, 'locations', filters);
  }

  filterFor(location) {
    const filters = this.filters;
    return filters.findBy('locationId', parseInt(get(location, 'id')));
  }

  resetFilters() {
    this.filters.setObjects(this.defaultFilters());
  }

  @dropTask
  *saveTask() {
    return yield this.setFilter().then(() => {
      this.results = [];
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    });
  }

  @dropTask
  *testTask() {
    this.args.hideFlash();
    this.results = [];

    const promise = yield this.googleSyncManager
      .locationsFilter(this.args.googleApp, this.args.locations, this.filters)
      .then(
        (data) => {
          this.results = data;
        },
        (response) => {
          this.args.handleFailedQuery(response);
        },
      );

    return promise;
  }

  @action
  activate() {
    if (!this.args.canEdit) {
      return false;
    }

    this.results = [];
    if (!this.isActive) {
      set(this.args.filter, 'type', 'locations');
      this.filters = this.defaultFilters();
    }
  }

  @action
  saveFilter(location, value) {
    this.results = [];
    const filter = this.filterFor(location);
    set(filter, 'query', value);
  }

  @action
  cancel() {
    this.args.filter.rollbackAttributes();
    this.results = [];
    this.resetFilters();
  }
}

import BaseHelper from './-base.js';
import '@ember/runloop';
import '@ember/component/helper';
import '@ember/object';
import '@ember/service';

var moment = BaseHelper.extend({
  compute(params, {
    locale,
    timeZone
  }) {
    this._super(...arguments);

    const moment = this.moment;
    return this.morphMoment(moment.moment(...params), {
      locale,
      timeZone
    });
  }

});

export { moment as default };

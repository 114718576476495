// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { set } from '@ember/object';

// eslint-disable-next-line ember/no-classic-classes
export default Component.extend({
  // eslint-disable-next-line ember/no-component-lifecycle-hooks
  didUpdateAttrs() {
    this._super();
    set(this, 'showSpinner', true);

    // <2015-07-23 Thu> - Adolfo Builes
    // We don't need an observer to identify changes in the attributes
    // see http://s.abuiles.com/0i0b00092M1b
    if (this.promise) {
      this.promise.finally(() => {
        set(this, 'showSpinner', false);
      });
    }
  },
});

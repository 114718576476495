import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import { dropTask } from 'ember-concurrency';
import type ConnectLocationConfigurationModel from 'garaje/models/connect-location-configuration';
import type LocationModel from 'garaje/models/location';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

interface ConnectHostNotificationsComponentArgs {
  location: LocationModel;
  connectLocationConfiguration: ConnectLocationConfigurationModel;
}

export default class ConnectHostNotificationsComponent extends Component<ConnectHostNotificationsComponentArgs> {
  @service declare flashMessages: FlashMessagesService;

  @tracked isOpen = false;
  @tracked locationChangeset!: DetailedChangeset<LocationModel>;
  @tracked connectLocationConfigurationChangeset!: DetailedChangeset<ConnectLocationConfigurationModel>;

  get canSave(): boolean {
    return (
      !this.saveTask.isRunning && (this.locationChangeset.isDirty || this.connectLocationConfigurationChangeset.isDirty)
    );
  }

  constructor(owner: unknown, args: ConnectHostNotificationsComponentArgs) {
    super(owner, args);
    this.#buildChangeset();
  }

  saveTask = dropTask(async () => {
    try {
      if (this.locationChangeset.isDirty) {
        await this.locationChangeset.save();
      }
      if (this.connectLocationConfigurationChangeset.isDirty) {
        await this.connectLocationConfigurationChangeset.save();
      }
      this.flashMessages.showAndHideFlash('success', 'Saved!');

      this.isOpen = false;
      this.#buildChangeset();
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));
    }
  });

  @action
  cancel(): void {
    this.isOpen = false;
    this.locationChangeset.rollback();
    this.connectLocationConfigurationChangeset.rollback();
  }

  #buildChangeset(): void {
    const { location, connectLocationConfiguration } = this.args;

    this.locationChangeset = Changeset(location);
    this.connectLocationConfigurationChangeset = Changeset(connectLocationConfiguration);
  }
}

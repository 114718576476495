import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import config from 'garaje/config/environment';

const ADOBE_PDF_SDK_CLIENT_ID = config.adobeApiKey || '';

export interface PdfViewerComponentSignature {
  Args: {
    url: string;
    file: File;
    fileName: string;
  };

  Element: HTMLElement;
}

export default class PdfViewerComponent extends Component<PdfViewerComponentSignature> {
  adobeDCView: AdobeViewer | null = null;
  divId = 'pdf-' + guidFor(this);

  @action
  onRender(): void {
    if (!this.args.url && !this.args.file) {
      throw new Error('Either url or file is required');
    }

    if (!this.args.fileName) {
      throw new Error('fileName is required');
    }

    this.#setupAndRenderPdf();
  }

  #setupAndRenderPdf(): void {
    if (typeof window.AdobeDC === 'undefined') {
      document.addEventListener('adobe_dc_view_sdk.ready', () => {
        this.#renderPdf();
      });
    } else {
      this.#renderPdf();
    }
  }

  #renderPdf(): void {
    const { divId } = this;
    if (!window?.AdobeDC) return;

    if (!this.adobeDCView) {
      this.adobeDCView = new window.AdobeDC.View({ clientId: ADOBE_PDF_SDK_CLIENT_ID, divId });
    }

    if (typeof this.adobeDCView?.previewFile === 'function') {
      const content = this.args.url ? { location: { url: this.args.url } } : { promise: this.args.file.arrayBuffer() };

      this.adobeDCView.previewFile(
        {
          content,
          metaData: { fileName: this.args.fileName },
        },
        {
          embedMode: 'IN_LINE',
          showZoomControl: false,
          showAnnotationTools: false,
          showFullScreen: false,
          showPrintPDF: false,
          showDownloadPDF: false,
          showBookmarks: false,
          showThumbnails: false,
        },
      );
    }
  }
}

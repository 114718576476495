import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import { apiAction } from '@mainmatter/ember-api-actions';

import type UserDocumentModel from './user-document';
import type UserDocumentLinkable from './user-document-linkable';

class UserDocumentLinkModel extends Model {
  @belongsTo('user-document') declare userDocument: AsyncBelongsTo<UserDocumentModel> | UserDocumentModel;
  @belongsTo('user-document-linkable', { polymorphic: true })
  declare userDocumentLinkable: AsyncBelongsTo<UserDocumentLinkable> | UserDocumentLinkable;

  @attr('string') declare approvalStatus: string;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;

  formattedStatuses: { [key: string]: string } = {
    approved: 'Approved',
    denied: 'Denied',
    review: 'Pending review',
  };

  get formattedApprovalStatus(): string {
    return this.formattedStatuses[this.approvalStatus] || 'Not submitted';
  }

  get statusText(): string {
    return this.formattedStatuses[this.approvalStatus] || '';
  }

  async approve(): Promise<UserDocumentLinkModel> {
    await apiAction(this, { method: 'POST', path: 'approve' });
    return this.reload();
  }

  async deny(): Promise<UserDocumentLinkModel> {
    await apiAction(this, { method: 'POST', path: 'deny' });
    return this.reload();
  }

  async reset(): Promise<UserDocumentLinkModel> {
    await apiAction(this, { method: 'POST', path: 'review' });
    return this.reload();
  }
}

export default UserDocumentLinkModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-document-link': UserDocumentLinkModel;
  }
}

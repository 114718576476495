import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { fromUnixTime, format } from 'date-fns';

/**
 * @param {Class<gqlDesk>}        desk                        Required. Graphql desk model the tooltip paired to
 * @param {Class<DeskMarker>}     marker                      Required. Desk marker
 * @param {Class<Reservation>}    currentReservation          Required. Current desk reservation
 * @param {Task}                  releaseDeskReservationTask  Required. Task to release the desk reservation
 * @param {Function}              setIsChangingDesk           Required. Set the tooltip's state
 * @param {Boolean}               isChangingDesk              Optional. If user is changing their desk
 */
export default class EmployeeDeskScheduleTooltip extends Component {
  @service flashMessages;
  @service metrics;
  @service registrations;
  @service router;
  @service featureConfig;

  @tracked isPermanent = false;
  @tracked isAmenitiesExpanded = false;

  @alias('args.currentReservation.desk.id') currentDeskId;
  @alias('args.currentReservation.id') currentReservationId;

  get date() {
    // backend returns in seconds since Jan 1 1970
    return fromUnixTime(this.args.currentReservation.startTime);
  }

  get formattedDate() {
    return format(this.date, 'MMMM dd, yyyy');
  }

  get formattedTime() {
    const startTime = format(fromUnixTime(this.args.currentReservation.startTime), 'h:mmaa');
    const endTime = format(fromUnixTime(this.args.currentReservation.endTime), 'h:mmaa');
    return `${startTime} - ${endTime}`;
  }

  get hasAmenityConfig() {
    return this.featureConfig.isEnabled('desks.amenities');
  }

  get hasNeighborhoodConfig() {
    return this.featureConfig.isEnabled('desks.neighborhood');
  }

  changeDeskTask = task({ drop: true }, async () => {
    try {
      let newReservation;
      if (this.args.isPartialDayEnabled) {
        newReservation = await this.registrations.changeDeskPartialDay(this.date, {
          currentDeskId: this.currentDeskId,
          newDeskId: this.args.desk.id,
          reservationId: this.currentReservationId,
        });
      } else {
        newReservation = await this.registrations.changeDesk(this.date, {
          currentDeskId: this.currentDeskId,
          newDeskId: this.args.desk.id,
          reservationId: this.currentReservationId,
        });
      }

      this.metrics.trackEvent('Web Employee Schedule - Change Desk', {
        current_desk_id: this.currentDeskId,
        current_reservation_id: this.currentReservationId,
        new_desk_id: newReservation.desk.id,
        new_reservation_id: newReservation.id,
      });

      this.args.setIsChangingDesk(false);
      this.router.transitionTo('index');
      this.flashMessages.showAndHideFlash('success', 'Desk changed');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}

/* eslint-disable ember/no-computed-properties-in-native-classes */
import { computed } from '@ember/object';
import { gt, alias, not } from '@ember/object/computed';
import { service } from '@ember/service';
import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import type BillingCompanyModel from 'garaje/models/billing-company';
import type CompanyModel from 'garaje/models/company';
import type LocationModel from 'garaje/models/location';
import type SubscriptionModificationModel from 'garaje/models/subscription-modification';
import type SubscriptionPlanModel from 'garaje/models/subscription-plan';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import containsComputed from 'garaje/utils/contains-computed';
import { App, DESK_COUNT, PLAN_LEVEL, PERIOD, SUBSCRIPTION_STATUS } from 'garaje/utils/enums';
import { getPlanDetails } from 'garaje/utils/plan-details';
import moment from 'moment-timezone';

export default class LocationSubscriptionModel extends Model {
  @service declare featureFlags: FeatureFlagsService;

  @belongsTo('company', { async: true, inverse: null }) declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('billing-company', { async: true, inverse: null })
  declare billingCompany: AsyncBelongsTo<BillingCompanyModel>;
  // @belongsTo('subscription-modification', { async: false }) subscriptionModification;
  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;
  @belongsTo('subscription-plan', { async: false }) declare subscriptionPlan: SubscriptionPlanModel | null;

  @attr('string') declare app: App;
  @attr('string') declare period: string;
  @attr('string') declare nextPeriod: string;
  @attr('string') declare plan: string;
  @alias('plan') declare level: this['plan'];
  @attr('string') declare status: string;
  /* Chargebee status ^^^
      active
      non_renewing
      in_trial (end date)
      cancelled (cancelled date)
      paused (dates? none)
  */
  @attr('string', { defaultValue: '' }) declare referral: string;
  @attr('string', { defaultValue: 'Infinity' }) declare employeeLimit: string;
  /**
   * @type {string}
   */
  @attr('string', { defaultValue: 'Infinity' }) declare locationLimit: string;
  @attr('string') declare couponCode: string;

  @attr('number') declare quantity: number;
  @attr('number') declare couponAmountOff: number;
  @attr('number') declare couponPercentOff: number;
  @attr('number') declare periodEnd: number;
  @attr('number') declare periodStart: number;

  @attr('date') declare cancelledAt: Date;
  /**
   * @type {Date}
   */
  @attr('date') declare nextBillingAt: Date;
  @attr('date') declare trialEndDate: Date;
  @attr('date') declare trialStartDate: Date;
  @attr('number') declare desksCount: number;

  @attr('boolean', { defaultValue: false }) declare requireFinanceTeamIntervention: boolean;
  @attr('boolean') declare isManual: boolean;
  @attr('boolean') declare onExpiredTrial: boolean;
  @attr('boolean') declare faked: boolean;

  @attr('array', { defaultValue: () => [] }) declare features: string[];

  // These next few attributes are defined on the Subscription model but were not previously defined
  // here. Without a `downgradePlan` relationship (like the Subscription model) has, make the downgrade
  // stuff false-y/null.
  downgradePlan = null;
  isDowngrading = false;

  freeBundle = false;

  /*
    COMPUTEDS
  */
  @computed('app', 'billingCompany.{isFulfilled,trialEnd}')
  get trialEndDateFromBillingCompany(): boolean {
    if (this.billingCompany.isFulfilled) {
      // if promise is resolved, we can treat it like it's a plain model
      const billingCompany = <BillingCompanyModel>(this.billingCompany as unknown);
      return billingCompany?.trialEnd?.[this.app] ?? false;
    }
    return false;
  }

  @computed('subscriptionModifications.[]', 'app')
  get subscriptionModification(): SubscriptionModificationModel | undefined {
    return this.subscriptionModifications.find((mod) => {
      return mod.subscriptionPlan?.app === this.app;
    });
  }

  @computed('app', 'level')
  get planDetails(): ReturnType<typeof getPlanDetails> {
    return getPlanDetails(this.app, this.level);
  }

  @alias('billingCompany.delinquent') declare delinquent: BillingCompanyModel['delinquent'];
  @alias('billingCompany.subscriptionModifications')
  declare subscriptionModifications: BillingCompanyModel['subscriptionModifications'];
  @alias('onTrial') declare isOnTrial: this['onTrial'];

  // Subscription Plan computeds - Do we need?
  @alias('subscriptionPlan.name') declare name: SubscriptionPlanModel['name'];
  @alias('subscriptionPlan.rank') declare rank: SubscriptionPlanModel['rank'];
  @alias('subscriptionPlan.price') declare price: SubscriptionPlanModel['price'];
  @alias('subscriptionPlan.details.unitType') declare unitType: string; // TODO: Define a type for `subscriptionPlan.details` and use here
  @alias('subscriptionPlan.pricePerPeriod') declare pricePerPeriod: SubscriptionPlanModel['pricePerPeriod'];

  // PLAN_LEVEL computeds - Do we need?
  @computed('subscriptionModification.subscriptionPlan.rank', 'rank')
  get hasDowngradeModification(): boolean {
    if (!this.subscriptionModification?.subscriptionPlan) return false;
    return this.rank > this.subscriptionModification.subscriptionPlan.rank;
  }

  @computed('level')
  get isBasicPlan(): boolean {
    return this.level === PLAN_LEVEL.BASIC;
  }

  @computed('level')
  get isStandardPlan(): boolean {
    return this.level === PLAN_LEVEL.STANDARD;
  }

  @computed('level')
  get isPremiumPlan(): boolean {
    return this.level === PLAN_LEVEL.PREMIUM;
  }

  get isPremiumPlusPlan(): boolean {
    return this.level == PLAN_LEVEL.PREMIUM_PLUS;
  }

  @computed('level')
  get isEnterprisePlan(): boolean {
    return this.level === PLAN_LEVEL.ENTERPRISE;
  }

  // TODO: check this
  @alias('id') declare normalizedPlanId: this['id'];
  @alias('level') declare normalizedPlanName: this['level'];
  @alias('isPaidSubscription') declare isSubscribed: this['isPaidSubscription'];

  get appName(): string {
    if (this.app === App.WORKPLACE) {
      return 'workplace';
    }

    return this.app;
  }

  @computed('isBasicPlan', 'hasTrialDaysLeft', 'subscribed')
  get isBasicUser(): boolean {
    return this.isBasicPlan || (!this.hasTrialDaysLeft && !this.subscribed);
  }

  @computed('isBasicPlan', 'subscribed')
  get isPaidSubscription(): boolean {
    return !this.isBasicPlan && this.subscribed;
  }

  @not('isPaidSubscription') declare isNotPaidSubscription: boolean;

  @computed('onExpiredTrial', 'app')
  get isExpiredDeliveriesTrial(): boolean {
    return this.onExpiredTrial && this.app === App.DELIVERIES;
  }

  get isUsable(): boolean {
    return this.isSubscribed || this.onTrial;
  }

  get isUsableWithBasic(): boolean {
    return this.isSubscribed || this.onTrial;
  }

  get isEmptyPlan(): boolean {
    return false;
  }

  // PLAN_PERIOD computeds - Do we need?
  @computed('period')
  get isMonthly(): boolean {
    return this.period === PERIOD.MONTHLY;
  }

  @computed('period')
  get isYearly(): boolean {
    return this.period === PERIOD.YEARLY;
  }

  // SUBSCRIPTION_STATUS computeds - Do we need?
  @computed('status')
  get nonRenewing(): boolean {
    return this.status === SUBSCRIPTION_STATUS.NON_RENEWING;
  }

  @computed('status')
  get onTrial(): boolean {
    return this.status === SUBSCRIPTION_STATUS.IN_TRIAL;
  }

  @computed('status')
  get active(): boolean {
    return this.status === SUBSCRIPTION_STATUS.ACTIVE;
  }

  @computed('status')
  get cancelled(): boolean {
    return this.status === SUBSCRIPTION_STATUS.CANCELLED;
  }

  @computed('status')
  get paused(): boolean {
    return this.status === SUBSCRIPTION_STATUS.PAUSED;
  }

  @computed('active', 'nonRenewing')
  get subscribed(): boolean {
    return this.active || this.nonRenewing;
  }

  @gt('trialDaysLeft', 0) declare hasTrialDaysLeft: boolean;

  @computed('trialEndDate', 'onTrial')
  get trialDaysLeft(): number {
    const { onTrial, trialEndDate } = this;
    if (onTrial) {
      const currentDate = new Date();
      const trialEndAt = trialEndDate || currentDate;
      return Math.max(Math.ceil(moment(trialEndAt).diff(currentDate, 'days', true)), 0);
    }
    return 0;
  }

  @computed('trialEndDate', 'trialStartDate')
  get trialLength(): number {
    const { trialEndDate, trialStartDate } = this;
    if (trialEndDate && trialStartDate) {
      return Math.max(Math.ceil(moment(trialEndDate).diff(trialStartDate, 'days', true)), 0);
    }
    return 0;
  }

  @computed('subscriptionPlan.pricePerPeriod', 'quantity')
  get totalPricePerPeriod(): number {
    const priceEach = this.subscriptionPlan?.pricePerPeriod ?? 0;
    const quantity = this.quantity || 0;
    return quantity * priceEach;
  }

  @computed('periodStart')
  get periodStartAt(): moment.Moment {
    return moment(this.periodStart);
  }

  @computed('periodEnd')
  get periodEndAt(): moment.Moment {
    return moment(this.periodEnd);
  }

  @computed('app')
  get iconPath(): string {
    return `/assets/images/app-icons/${this.app}.svg`;
  }

  get maximumDesksForCurrentPlan(): number {
    if (this.app !== App.DESKS) {
      return 0;
    }

    // use backend-driven calculation, else if blank, fallback to assuming 25 * quantity
    // backend calculation takes custom addons into account. can be blank
    // if account has been given custom pricing that cannot be parsed
    const desksCount = this.desksCount || this.quantity * DESK_COUNT.PER_LICENSE;
    return this.isBasicPlan ? DESK_COUNT.PER_LICENSE : desksCount;
  }

  get canAccessGroupInviteRecords(): boolean {
    // TODO For [VIS-6197], when removing split:
    // Replace canAccessGroupInvitesFeature (above and in subscription)
    // with canAccessGroupInviteRecords
    if (!this.featureFlags.isEnabled('visitors-group-invite-updates-m1')) return false;

    return this.canAccessGroupInvitesFeature;
  }

  /*
    CAN Feature Permissions
  */

  // ENVOY ?
  @containsComputed('features', 'boss') declare canAccessBoss: boolean;

  // GENERAL ?
  @containsComputed('features', 'directory_sync') declare canAccessDirectorySync: boolean;

  // VISITORS ?
  @containsComputed('features', 'nda_bcc') declare canAccessNdaBcc: boolean;
  @containsComputed('features', 'nda_enabled') declare includesNda: boolean;
  @containsComputed('features', 'presign_nda') declare hasPresignNda: boolean;
  @containsComputed('features', 'video_in_legal_document') declare hasVideoInLegalDocument: boolean;
  @containsComputed('features', 'global_analytics') declare canAccessGlobalAnalytics: boolean;
  @containsComputed('features', 'conditional_sign_in_fields') declare canAccessConditionalSignInFields: boolean;
  @containsComputed('features', 'conditional_alerts') declare canAccessConditionalAlerts: boolean;
  @containsComputed('features', 'analytics') declare canAccessAnalytics: boolean;
  @containsComputed('features', 'assistants') declare canAccessAssistants: boolean;
  @containsComputed('features', 'blacklist') declare canAccessBlocklist: boolean;
  @containsComputed('features', 'custom_badge') declare canAccessCustomBadge: boolean;
  @containsComputed('features', 'custom_notifications') declare canAccessCustomNotifications: boolean;
  @containsComputed('features', 'locations_grouping') declare canAccessLocationsGrouping: boolean;
  @containsComputed('features', 'legal_name_validation') declare canAccessFullLegalName: boolean;
  @containsComputed('features', 'group_sign_in') declare canAccessGroupSignIn: boolean;
  @containsComputed('features', 'id_scanning') declare canAccessIdScanning: boolean;
  @containsComputed('features', 'multiple_hosts') declare canAccessMultipleHosts: boolean;
  @containsComputed('features', 'multiple_printers') declare canAccessMultiplePrinters: boolean;
  @containsComputed('features', 'multiple_visitor_types') declare canAccessMultipleVisitorTypes: boolean;
  @containsComputed('features', 'pre_registration_required') declare canAccessPreRegistrationRequired: boolean;
  @containsComputed('features', 'slideshows') declare canAccessSlideshows: boolean;
  @containsComputed('features', 'visitor_photos') declare canAccessVisitorPhotos: boolean;
  @containsComputed('features', 'visual_compliance') declare canAccessVisualCompliance: boolean;
  @containsComputed('features', 'visitor_badge_printing') declare canEnableBadgePrinting: boolean;
  @containsComputed('features', 'invite_approvals') declare canRequireInviteApproval: boolean;
  @containsComputed('features', 'sign_in_from_invite') declare canSignInFromInvite: boolean;
  @containsComputed('features', 'sign_in_visitors') declare canSignInVisitors: boolean;
  @containsComputed('features', 'id_check') declare canAccessIDCheck: boolean;
  @containsComputed('features', 'visitor_survey') declare canAccessVisitorSurvey: boolean;
  @containsComputed('features', 'welcome_guide') declare canAccessWelcomeGuide: boolean;
  @containsComputed('features', 'static_qr_codes') declare canAccessStaticQrCodes: boolean;
  @containsComputed('features', 'group_invites') declare canAccessGroupInvitesFeature: boolean;
  @containsComputed('features', 'facial_recognition') declare canAccessFacialRecognition: boolean;
  @containsComputed('features', 'host_search_options') declare canAccessHostSearchOptions: boolean;

  // DELIVERIES ?
  // None?

  // ROOMS ?
  @containsComputed('features', 'check_in_notification') declare canAccessCheckInNotification: boolean;
  @containsComputed('features', 'privacy_and_booking_controls') declare canAccessPrivacyAndBookingControls: boolean;
  @containsComputed('features', 'rooms_analytics') declare canAccessRoomsAnalytics: boolean;

  // Integrations ?
  @containsComputed('features', 'box') declare canAccessBox: boolean;
  @containsComputed('features', 'microsoft_365') declare canAccessMicrosoft365: boolean;
  @containsComputed('features', 'salesforce') declare canAccessSalesforce: boolean;
  @containsComputed('features', 'saml') declare canAccessSaml: boolean;
  @containsComputed('features', 'slack') declare canAccessSlack: boolean;
  @containsComputed('features', 'sms_notification') declare canAccessSmsNotification: boolean;

  @containsComputed('features', 'global_sign_in_flows') declare canAccessGlobalSignInFlows: boolean;
  @containsComputed('features', 'scheduled_reports') declare canAccessScheduledReports: boolean;
  @containsComputed('features', 'capacity_limits') declare canAccessCapacityLimits: boolean;
  @containsComputed('features', 'whitegloved_from_email_name') declare hasWhiteglovedFromEmailName: boolean;
  @containsComputed('features', 'occupancy_analytics') declare canAccessOccupancyAnalytics: boolean;
  @containsComputed('features', 'protect_legacy_features') declare canAccessProtectLegacyFeatures: boolean;
  @containsComputed('features', 'delegated_booking') declare canAccessDelegatedBooking: boolean;
  @containsComputed('features', 'attendance_analytics') declare canAccessAttendanceAnalytics: boolean;

  @containsComputed('features', 'emergency_notifications') declare canAccessEmergencyNotifications: boolean;
  @containsComputed('features', 'virtual_front_desk') declare canAccessVirtualFrontDesk: boolean;
  @containsComputed('features', 'custom_admin_roles') declare canManageCustomAdminRoles: boolean;
  @containsComputed('features', 'desk_reservation_delegated_management') declare canUseDbeam: boolean;
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import type StateService from 'garaje/services/state';

export default class LocationOverviewVirtualFrontDeskRoute extends Route {
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare skinnyLocations: SkinnyLocationsService;

  redirect(): void {
    if (!this.state.features?.canAccessVirtualFrontDesk) {
      void this.router.transitionTo('location-overview.virtual-front-desk.landing-page');
    }

    const activeVfdLocations = this.skinnyLocations.vfdActive;
    if (activeVfdLocations.length) {
      void this.router.transitionTo('location-overview.virtual-front-desk.call-log');
    } else {
      void this.router.transitionTo('location-overview.virtual-front-desk.locations');
    }
  }
}

import Model, { attr } from '@ember-data/model';

export default abstract class AbstractBadge extends Model {
  @attr('string') declare sampleLogoPath: string;
  @attr('string') declare samplePhotoPath: string;
  @attr('string') declare css: string;

  @attr('string') declare extraFieldOne: string;
  @attr('string') declare extraFieldTwo: string;
  @attr('string') declare extraFieldThree: string;

  @attr('string') declare footer: string;
  @attr('string', { defaultValue: 'none' }) declare header: string;
  @attr('string') declare notes: string;

  @attr('boolean') declare enabled: boolean;
  @attr('boolean') declare extraFieldOneLabel: boolean;
  @attr('boolean') declare extraFieldTwoLabel: boolean;
  @attr('boolean') declare extraFieldThreeLabel: boolean;
  @attr('boolean') declare showLabels: boolean;
  @attr('boolean') declare showPhoto: boolean;

  // read-only fields
  /**
   * @readonly
   */
  @attr('string') declare defaultCss: string;
  /**
   * @readonly
   */
  @attr('string') declare defaultHtml: string;
  /**
   * @readonly
   */
  @attr('string', { defaultValue: '' }) declare html: string;
  /**
   * @readonly
   */
  @attr('string') declare image: string;
  /**
   * @readonly
   */
  @attr('string') declare uiHookOne: string;
  /**
   * @readonly
   */
  @attr('string') declare uiHookTwo: string;
}

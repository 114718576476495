import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader';
import config from 'garaje/config/environment';

export function initialize(application) {
  const meticulousConfig = config['meticulous-recorder'];
  if (meticulousConfig && meticulousConfig.enabled && meticulousConfig.projectId) {
    application.deferReadiness();
    tryLoadAndStartRecorder({ projectId: meticulousConfig.projectId }).then(() => application.advanceReadiness());
  }
}

export default {
  name: 'start-meticulous-recorder',
  initialize,
};

/* eslint-disable ember/no-computed-properties-in-native-classes */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import EmberObject, { action, get, set, computed } from '@ember/object';
import { A } from '@ember/array';
import { task, restartableTask, dropTask, all } from 'ember-concurrency';
import Changeset from 'ember-changeset';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import addOrUpdateUserData from 'garaje/utils/user-data';
import { alias, readOnly, and, sort, map, filter } from '@ember/object/computed';
import lookupValidator from 'ember-changeset-validations';
import buildFlowFieldValidations from 'garaje/validations/flow-field';
import { isEmpty } from '@ember/utils';
import { NON_ASSIGNABLE_FLOWS } from 'garaje/utils/enums';
import moment from 'moment';

// Without this import the in-repo addon fails
// Doesn't even have to be for this component, but
// must be imported at least once
/* eslint-disable */
import rome from 'rome'; // eslint-disable-line

import { buildWaiter } from '@ember/test-waiters';
import config from 'garaje/config/environment';

const testWaiter = buildWaiter('new-visitor-form-component-waiter');
const isTesting = config.environment === 'test';

/**
 * @param {Class<Entry>}            model
 * @param {Array<Object>}           printers
 * @param {Object}                  vrSubscription
 * @param {Object}                  currentLocation
 * @param {Object}                  modelChangeset
 * @param {String}                  signedInAt
 * @param {Function}                modelDidSave
 * @param {Function}                dateChanged
 * @param {Function}                modelDidPartialSave
 * @param {Boolean}                 locationIsConnectedToProperty
 */
export default class NewVisitorForm extends Component {
  // ActiveStorage service: https://github.com/algonauti/ember-active-storage
  @service activeStorageExtension;
  @service logger;
  @service flashMessages;
  @service metrics;
  @service store;

  modelPath = 'modelChangeset';
  @tracked shouldPrintBadge = false;
  @tracked printerSelected = null;
  @tracked didOmitRequiredPrinter;
  @tracked printers;
  @tracked date;
  @tracked atCapacityForSignedInAt;
  @tracked hasAttachments = false;

  constructor() {
    super(...arguments);
    this.printers = this.args.printers ?? [];
    this.parseDateFromQP();
    this.checkCapacityTask.perform();
  }

  @map('sortedSignInFields', function (field) {
    return get(this.userDataChangeset, `field-${field.id}`) || this.buildFieldChangeset(field);
  })
  fieldChangesets;

  @sort('signInFields', (a, b) => a.position - b.position) sortedSignInFields;
  @and('args.currentLocation.printer.enabled', 'flow.badge.enabled') canPrintBadges;
  @alias('args.currentLocation.hostNotificationsEnabled') hostNotificationsEnabled;
  @readOnly('flow.signInFieldPage.signInFields') signInFields;

  @filter('args.currentLocation.flows.[]', function ({ employeeCentric, type }) {
    return !NON_ASSIGNABLE_FLOWS.includes(type) && !employeeCentric;
  })
  flowOptions;

  @readOnly('flow.activeUserDocumentTemplateConfigurations') activeUserDocumentTemplateConfigurations;

  @computed('fieldChangesets.@each.value')
  get renderableFieldChangesets() {
    return this.fieldChangesets.filter((fieldChangeset) => {
      if (get(fieldChangeset, 'isTopLevel')) {
        return true;
      }

      const action = get(fieldChangeset, 'actionableSignInFieldActions.firstObject');

      // Do not attempt to automatically async fetch related signInField
      // If signInField record isn't already fulfilled, it will probably not load
      const isApplicableAction = action && action.belongsTo('signInField').value() && get(action, 'signInField.id');

      if (!isApplicableAction) {
        return false;
      }

      const changesetParent = this.fieldChangesets.findBy('id', get(action, 'signInField.id'));

      return changesetParent && get(changesetParent, 'value') === get(action, 'dropdownOption.value');
    });
  }

  @computed('signInFields.@each.identifier')
  get hostSignInField() {
    return this.signInFields?.findBy('identifier', 'host');
  }

  @computed('args.modelChangeset.userData.@each.field')
  get hasHostNotificationField() {
    return !!get(this.args.modelChangeset, 'userData').findBy('field', 'Host');
  }

  get canSetPropertyNotes() {
    return this.args.locationIsConnectedToProperty;
  }

  @action
  buildFieldChangeset(field) {
    const validations = buildFlowFieldValidations(field);
    const validator = lookupValidator(validations);
    return new Changeset(field, validator, validations);
  }

  parseDateFromQP() {
    this.date = get(this.args.modelChangeset, 'signedInAt');
  }

  @action
  setDate(date) {
    set(this.args.modelChangeset, 'signedInAt', date);
    this.args.dateChanged(date);
    this.checkCapacityTask.perform();
  }

  isVisitorDocumentPersistable(visitorDocument) {
    const activeIdentifiers = this.activeUserDocumentTemplateConfigurations.mapBy('userDocumentTemplate.identifier');
    const isAssociatedToActiveTemplate = activeIdentifiers.includes(visitorDocument.identifier);
    const hasAttachmentsWithPendingUploads = visitorDocument.userDocumentAttachmentsPendingUpload.length > 0;

    return isAssociatedToActiveTemplate && hasAttachmentsWithPendingUploads;
  }

  @restartableTask
  *checkCapacityTask() {
    const locationId = get(this.args.currentLocation, 'id');
    const signedInAt = get(this.args.modelChangeset, 'signedInAt');

    if (get(this.args.currentLocation, 'capacityLimitEnabled')) {
      const locationsCapacity = yield this.store
        .query('locations-capacity', {
          filter: {
            location: locationId,
            capacity_date: moment(signedInAt).format('YYYY-MM-DDTHH:mm'),
          },
        })
        .then((models) => models.firstObject);

      if (locationsCapacity) {
        const { onSiteCount, expectedCount, capacityLimit } = locationsCapacity;
        const capacityUsed = onSiteCount + expectedCount;
        const capacityRatio = capacityLimit >= 0 ? capacityUsed / capacityLimit : 0;
        return (this.atCapacityForSignedInAt = capacityRatio >= 1);
      }
    }
    this.atCapacityForSignedInAt = false;
  }

  @dropTask
  *saveTask(options = {}) {
    set(this.args.modelChangeset, 'currentLocationId', get(this.args.currentLocation, 'id'));

    this.userDataChangeset.changes.forEach(({ value: fieldChangeset }) => {
      addOrUpdateUserData(
        this.args.modelChangeset,
        fieldChangeset.name,
        fieldChangeset.value
      );
    });

    let emailField = get(this.args.modelChangeset, 'userData').findBy('field', 'Your Email Address');

    if (emailField) {
      set(this.args.modelChangeset, 'email', get(emailField, 'value'));
    }

    addOrUpdateUserData(this.args.modelChangeset, 'Your Full Name', this.args.modelChangeset.fullName);

    this.args.modelChangeset.flowName = this.flow.name;

    // if a printer wasn't selected, then we set the printBadge flag on the entry and let the backend print the badge
    if (!this.printerSelected) {
      set(this.args.modelChangeset, 'printBadge', this.shouldPrintBadge);
    }

    const token = isTesting ? testWaiter.beginAsync() : false;

    try {
      const entry = yield this.args.modelChangeset.save();
      const visitorDocuments = entry?.visitorDocuments ?? A();
      let printMessage = this.shouldPrintBadge ? 'Printing badge' : '';

      if (this.printerSelected) {
        // if a printer was selected, then here we manually print the badge on that printer
        let data = [{ type: 'entries', id: get(entry, 'id') }];
        this.printerSelected.reprintBadge({ data });
        printMessage = `Badge printing on ${get(this.printerSelected, 'name')}`;
      }

      try {
        yield all(
          visitorDocuments.map((visitorDocument) => this.saveVisitorDocumentTask.perform(entry, visitorDocument))
        );
      } catch (error) {
        this.flashMessages.showFlash('error', 'Visitor saved! But one or more document uploads failed');

        // Handle entry successfully persisted but associated visitor document upload/save failed
        return this.args.modelDidPartialSave?.(entry, error);
      }

      this.args.modelDidSave(options);
      this.flashMessages.showAndHideFlash('success', 'New visitor entry created!', printMessage);
    } catch (e) {
      // In order for this to work, we modified how we are creating tokens
      // https://github.com/envoy/garaje/pull/2678
      // So, all users which have old tokens will still be effected by this
      // When we have no more old tokens, we should be able to remove this code
      let msg = 'Assertion Failed: Your entry record was saved';
      // Throwing error for entry creation. Not always actually an error
      this.logger.error(e);
      if (e.message.indexOf(msg) === 0) {
        this.flashMessages.showAndHideFlash('success', 'New visitor entry created!');
        this.args.modelDidSave(options);
      }
    } finally {
      if (token) testWaiter.endAsync(token);
    }
  }

  @dropTask
  *save(redirectToIndex, e) {
    e.preventDefault();

    const entryChangeset = get(this, 'args.modelChangeset');
    yield entryChangeset.validate();

    // Only validate RENDERED / VISIBLE field changesets
    for (const index of this.renderableFieldChangesets.keys()) {
      const changeset = this.renderableFieldChangesets[index];
      yield changeset.validate();
    }

    let isMissingPrinter = this.printerSelectionRequired && !this.printerSelected;
    this.didOmitRequiredPrinter = isMissingPrinter;

    if (get(entryChangeset, 'isInvalid') || isMissingPrinter) {
      const specificError = get(entryChangeset, 'errors.firstObject.validation.firstObject');
      this.flashMessages.showFlash('error', 'Please fill in the required fields', specificError);
      return;
    }

    for (const index of this.renderableFieldChangesets.keys()) {
      const changeset = this.renderableFieldChangesets[index];
      if (get(changeset, 'isInvalid')) {
        const specificError = get(changeset, 'errors.firstObject.validation.firstObject');
        this.flashMessages.showFlash('error', 'Please fill in the required fields', specificError);
        return;
      }
    }

    if (!this.isValidVisitorDocuments) {
      this.flashMessages.showFlash('error', 'Please enter all required information');
      return;
    }

    this.saveTask.perform({ redirectToIndex });
  }

  @(employeesSearcherTask({
    filter: { deleted: false, excludeHidden: true },
    prefix: true,
  }).restartable())
  searchEmployeesTask;

  @action
  doSearch(term) {
    return this.searchEmployeesTask.perform(term);
  }

  setHost(employee) {
    // employee will be null when select is cleared
    let employeeName = employee && get(employee, 'name');
    let attrs = { inviterName: employeeName, host: employeeName, employee };

    this.args.modelChangeset.setProperties(attrs);
  }

  /* MVT related
   * Keeping all the code related with MVT below
   */
  // we only care if the changeset changes since the POV field can't be deleted
  @computed('args.modelChangeset')
  get purposeOfVisit() {
    return this.args.modelChangeset.userData.findBy('field', 'Purpose of visit');
  }

  @computed('flowOptions.[]', 'args.modelChangeset.flow')
  get flow() {
    const flowId = this.args.modelChangeset.get('flow.id');
    return this.flowOptions.findBy('id', flowId);
  }

  @computed('args.modelChangeset', 'flow')
  get userDataChangeset() {
    let initialState = EmberObject.create({});
    let flow = this.flow
    let flowName = this.flow?.name;
    let signInFields = get(flow, 'signInFieldPage.signInFields');

    // bootstrap the initial state for the changeset so it uses whatever is in the invite.userData
    // this is required to show pre-existing data
    get(this.args.modelChangeset, 'userData').forEach((option, index) => {
      let fieldName = get(option, 'field');
      let value = get(option, 'value');

      // ignore purpose of visit here
      if (value !== flowName) {
        let field = signInFields.findBy('name', fieldName);

        if (!field) {
          field = {
            id: `${index}-${get(this.args.currentLocation, 'id')}`,
            name: fieldName,
            isLoaded: true,
          };
        }

        field = this.buildFieldChangeset(field);
        set(field, 'value', value); // set the initial value

        set(initialState, this.pseudoIdForField(field), field);
      }
    });

    return new Changeset(initialState);
  }

  @action
  checkForAttachments() {
    this.hasAttachments = this.visitorDocuments?.isAny('hasAttachedFile');
  }

  get visitorDocuments() {
    const {
      activeUserDocumentTemplateConfigurations,
      args: { model },
    } = this;

    return activeUserDocumentTemplateConfigurations?.sortBy('userDocumentTemplate.position').map((config) => {
      const { userDocumentTemplate } = config;

      return (
        model.visitorDocumentForTemplate(userDocumentTemplate) ||
        this.store.createRecord('visitor-document', { userDocumentTemplate })
      );
    });
  }

  get isValidVisitorDocuments() {
    const documentsToCheck = this.visitorDocuments?.filterBy('hasAttachedFile');
    if (isEmpty(documentsToCheck)) return true;

    return documentsToCheck.isEvery('isValidDocument');
  }

  pseudoIdForField(field) {
    // The key provided to set must be a string.
    return `field-${get(field, 'id')}`;
  }

  @action
  updateUserDataField(changeset) {
    set(this.userDataChangeset, this.pseudoIdForField(changeset), changeset);
  }

  @action
  setHostAndUserData(changeset, employee) {
    this.setHost(employee);

    if (!employee) {
      set(this.args.modelChangeset, 'sendHostNotification', false);
    }

    // employee will be null when select is cleared
    let employeeName = employee && get(employee, 'name');

    set(changeset, 'value', employeeName);

    this.updateUserDataField(changeset);
  }

  get printerOptions() {
    let options = this.printers;
    options.forEach((option) => set(option, 'disabled', get(option, 'status') !== 'connected'));
    return options;
  }

  get printerSelectionRequired() {
    return this.shouldPrintBadge && this.printers.length > 1;
  }

  @action
  selectPrinter(printer) {
    this.printerSelected = printer;
    this.didOmitRequiredPrinter = false;
  }

  @action
  attachFileToDocument(visitorDocument, userDocumentTemplateAttachment, update) {
    visitorDocument.entries.addObject(this.args.model);

    let userDocumentAttachment = visitorDocument.getAttachment(userDocumentTemplateAttachment.id);

    if (!userDocumentAttachment) {
      userDocumentAttachment = this.store.createRecord('user-document-attachment');
      userDocumentAttachment.userDocumentTemplateAttachment = userDocumentTemplateAttachment;
      userDocumentAttachment.visitorDocument = visitorDocument;
    }

    if (update instanceof File) {
      userDocumentAttachment.file = update;
    }

    if (typeof update === 'string') {
      userDocumentAttachment.fileUrl = update;
    }
  }

  @action
  resetVisitorDocument(visitorDocument) {
    const { model } = this.args;
    const { userDocumentTemplate } = visitorDocument;

    if (visitorDocument.isNew) {
      model.visitorDocuments.removeObject(visitorDocument);
      visitorDocument.unloadRecord();
      model.visitorDocuments.addObject(this.store.createRecord('visitor-document', { userDocumentTemplate }));
    } else {
      this.confirmRemovalOfDocument = visitorDocument;
    }
  }

  @action
  updateFlow(flow) {
    set(this.args.modelChangeset, 'flow', flow);
    set(this.purposeOfVisit, 'value', flow.name);
  }

  @task
  *saveVisitorDocumentTask(entry, visitorDocument) {
    if (!this.isVisitorDocumentPersistable(visitorDocument)) return visitorDocument;

    // Associate the entry's location to the visitor document (fallback to current location)
    const location = this.store.peekRecord('location', get(entry, 'location.id')) || this.state.currentLocation;

    visitorDocument.locations = A([location]);

    yield this.uploadVisitorDocumentAttachmentsTask.perform(entry, visitorDocument);
    yield visitorDocument.save();

    // Cleanup attachments
    visitorDocument.userDocumentAttachments.filterBy('isNew').invoke('unloadRecord');

    return visitorDocument;
  }

  @task
  *uploadVisitorDocumentAttachmentsTask(entry, visitorDocument) {
    const { activeStorageExtension } = this;
    const directUploadURL = '/a/visitors/api/direct-uploads';
    const userDocumentTemplateId = get(visitorDocument, 'userDocumentTemplate.id');

    if (!(entry?.id && userDocumentTemplateId)) return;

    const prefix = `user-documents/${userDocumentTemplateId}/entries/${entry.id}`;
    const endpoint = `${directUploadURL}?prefix=${prefix}`;

    return yield all(
      visitorDocument.userDocumentAttachmentsPendingUpload.map((userDocumentAttachment) =>
        this.uploadDocumentAttachmentTask.perform(userDocumentAttachment, endpoint, activeStorageExtension)
      )
    );
  }

  @task uploadDocumentAttachmentTask = {
    progress: 0,

    *perform(userDocumentAttachment, endpoint, activeStorageExtension) {
      const { file } = userDocumentAttachment;

      if (!(file instanceof File)) {
        throw new Error('Upload halted: no file specified');
      }

      if (!endpoint) {
        throw new Error('Upload halted: no direct upload endpoint specified');
      }

      if (typeof activeStorageExtension.upload !== 'function') {
        throw new Error('Upload halted: invalid Active Storage service specified');
      }

      const { signedId } = yield activeStorageExtension.upload(file, endpoint, {
        onProgress: (progress) => {
          this.progress = progress;
          if (!get(userDocumentAttachment, 'isDestroyed') && !get(userDocumentAttachment, 'isDestroying')) {
            set(userDocumentAttachment, 'uploadProgress', progress);
          }
        },
      });

      userDocumentAttachment.file = signedId;

      return signedId;
    },
  };
}

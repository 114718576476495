interface SerializedEmployeeFilter {
  id?: string;
  label: string;
  locationId: number;
  name: string;
  operation: string;
  value: string;
}

export default class EmployeeFilter {
  declare id: string;
  declare label: string;
  declare locationId: number;
  declare name: string;
  declare operation: string;
  declare value: string;

  constructor(attrs: Record<string, unknown>) {
    this.id = (attrs['id'] as string) ?? null;
    this.label = (attrs['label'] as string) ?? null;
    this.locationId = (attrs['locationId'] as number) ?? null;
    this.name = (attrs['name'] as string) ?? null;
    this.operation = (attrs['label'] as string) ?? '=';
    this.value = (attrs['value'] as string) ?? null;
  }

  serialize(): unknown {
    const result: SerializedEmployeeFilter = {
      label: this.label,
      locationId: this.locationId,
      name: this.name,
      operation: this.operation,
      value: this.value,
    };
    if (this.id) {
      result['id'] = this.id;
    }
    return result;
  }
}

import gql from 'graphql-tag';

export default gql`
  query MapFeatures(
    $areaMapID: ID!
    $floorID: ID!
    $featureTypes: [MapFeatureType!]!
    $spaceDashboardInput: SpaceDashboardInput!
  ) {
    areaMap(id: $areaMapID) {
      id
      floors(ids: [$floorID]) {
        id
        features(featureTypes: $featureTypes, includeDisabled: false) {
          __typename
          id
          ... on DeskMapFeature {
            desk {
              id
              utilization(input: $spaceDashboardInput) {
                percentUtilized
              }
            }
          }
          ... on RoomMapFeature {
            room {
              id
              utilization(input: $spaceDashboardInput) {
                percentUtilized
              }
            }
          }
        }
      }
    }
  }
`;

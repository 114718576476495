import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StateService from 'garaje/services/state';

export default class VirtualFrontDeskRoute extends Route {
  @service declare router: RouterService;
  @service declare state: StateService;

  redirect(): void {
    if (!this.state.features?.canAccessVirtualFrontDesk) {
      void this.router.transitionTo('virtual-front-desk.landing-page');
    } else {
      void this.router.transitionTo('virtual-front-desk.devices');
    }
  }
}

// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import Service, { service } from '@ember/service';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type VisitorsOnboardingService from 'garaje/services/visitors-onboarding';

const TOP_LEVEL_VISITORS_ROUTES = [
  'visitors.analytics',
  'devices',
  'entries',
  'entry',
  'invites',
  'security',
  'settings',
  'visitors',
];

const isActive = function (routeName: string) {
  return computed('router.currentRouteName', function (this: IsOpenService) {
    return this.router.isActive(routeName);
  });
};

export default class IsOpenService extends Service {
  @service declare router: RouterService;
  @service declare visitorsOnboarding: VisitorsOnboardingService;
  @service declare featureFlags: FeatureFlagsService;

  @isActive('billing') isBillingOpen!: boolean;
  @isActive('dashboard') isDashboardOpen!: boolean;
  @isActive('deliveries.settings') isDeliveriesSettingsOpen!: boolean;
  @isActive('visitors.devices') isDevicesOpen!: boolean;
  @isActive('virtual-front-desk.devices') isVfdDevicesOpen!: boolean;
  @isActive('virtual-front-desk.settings') isVfdSettingsOpen!: boolean;
  @isActive('schedule') isEmployeeScheduleOpen!: boolean;

  @computed('featureFlags.topLevelSecurity', 'router.currentRouteName')
  get isSettingsOpen(): boolean {
    if (this.featureFlags.isEnabled('topLevelSecurity')) {
      return this.router.isActive('visitors.settings');
    } else {
      return this.router.isActive('visitors.settings') || this.router.isActive('security');
    }
  }

  @computed('router.currentRouteName')
  get isProtectOpen(): boolean {
    return this.router.isActive('protect') && !this.router.isActive('protect.landing-page');
  }

  @computed('router.currentRouteName')
  get isMapsOpen(): boolean {
    return this.router.isActive('spaces.maps');
  }

  @computed('router.currentRouteName')
  get isWorkplaceOpen(): boolean {
    return this.router.isActive('workplace') && !this.router.isActive('workplace.start-trial');
  }

  @computed('router.currentRouteName')
  get isRoombaOpen(): boolean {
    return this.router.isActive('roomba') && !this.router.isActive('roomba.landing-page');
  }

  @computed('router.currentRouteName')
  get isAnalyticsOpen(): boolean {
    return this.router.isActive('analytics');
  }

  @computed('router.currentRouteName')
  get isAppStoreOpen(): boolean {
    return (
      this.router.isActive('integrations') || this.router.isActive('apps') || this.router.isActive('dev-dashboard')
    );
  }

  @computed('router.currentRouteName')
  get isAnalyticsAttendanceOverviewOpen(): boolean {
    return this.router.isActive('analytics.attendance.overview');
  }

  @computed('router.currentRouteName')
  get isAnalyticsAttendanceReportOpen(): boolean {
    return this.router.isActive('analytics.attendance.report');
  }

  @computed('router.currentRouteName')
  get isDeliveriesOpen(): boolean {
    return (
      this.router.isActive('deliveries') &&
      !this.router.isActive('deliveries.get-started') &&
      !this.router.isActive('deliveries.landing-page')
    );
  }

  @computed('router.currentRouteName')
  get isVirtualFrontDeskOpen(): boolean {
    return this.router.isActive('virtual-front-desk') && !this.router.isActive('virtual-front-desk.landing-page');
  }

  @computed('router.currentRouteName')
  get isDesksOpen(): boolean {
    return this.router.isActive('desks') && !this.router.isActive('desks.landing-page');
  }

  @computed('router.currentRouteName')
  get isCommunicationsOpen(): boolean {
    return this.router.isActive('communications');
  }

  @computed('router.currentRouteName', 'visitorsOnboarding.{isEnabled,isStarted}')
  get isVizRegOpen(): boolean {
    const { visitorsOnboarding } = this;
    if (visitorsOnboarding.isEnabled && !visitorsOnboarding.isStarted) {
      return false;
    }
    const hasActiveRoute = TOP_LEVEL_VISITORS_ROUTES.some((routeName) => this.router.isActive(routeName));
    return hasActiveRoute && !this.router.isActive('visitors.start-trial');
  }

  @computed('router.currentRouteName')
  get isVizOpen(): boolean {
    return TOP_LEVEL_VISITORS_ROUTES.some((routeName) => this.router.isActive(routeName));
  }

  @computed('router.currentRouteName')
  get isLocationConnectOpen(): boolean {
    return (
      this.router.isActive('location-settings.index.connect') ||
      this.router.isActive('manage.location-settings.index.connect')
    );
  }

  @isActive('property.devices') isPropertyDevicesOpen!: boolean;
  @isActive('property.settings') isPropertySettingsOpen!: boolean;
  @isActive('property.visitors') isPropertyVisitorsOpen!: boolean;
  @isActive('connect.properties') isConnectPropertiesOpen!: boolean;
  @isActive('connect.analytics') isConnectAnalyticsOpen!: boolean;

  @isActive('manage') isManageOpen!: boolean;
}

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import DeliveryAreaValidations from 'garaje/validations/delivery-area';
import updateAndSaveTask from 'garaje/utils/decorators/update-and-save-task';
import { alias } from 'macro-decorators';
import { dropTask } from 'ember-concurrency';
import { defer } from 'rsvp';

@updateAndSaveTask
class DeliveriesSettingsDeliveryAreasDeliveryAreaIndexController extends Controller {
  @service flashMessages;

  @alias('model.deliveryArea') deliveryArea;

  get changeset() {
    const validator = lookupValidator(DeliveryAreaValidations);
    const deliveryArea = this.deliveryArea;
    return new Changeset(deliveryArea, validator, DeliveryAreaValidations);
  }

  @action
  didSave(_model) {
    this.flashMessages.showAndHideFlash('success', 'Saved!');
  }

  @action
  toggleEnabled() {
    const deliveryArea = this.deliveryArea;
    deliveryArea.toggleProperty('enabled');
    this.updateAndSaveTask.perform(deliveryArea);
  }

  get modalElement() {
    return document.getElementById('modal');
  }

  @dropTask
  showConfirmationModalTask = {
    *perform() {
      const deferred = defer();

      this.abort = () => deferred.reject();
      this.continue = () => deferred.resolve();

      return yield deferred.promise;
    },
  };
}

export default DeliveriesSettingsDeliveryAreasDeliveryAreaIndexController;

import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class RecordSelectSelectAllComponent extends Component {
  get indeterminate() {
    return this.args.selectedModels.length > 0 && this.args.selectedModels.length < this.args.models.length;
  }

  get checked() {
    return this.args.selectedModels.length > 0 && this.args.selectedModels.length === this.args.models.length;
  }

  @action
  handleChange(evt) {
    if (evt.target.checked) {
      this.args.onSelectAll();
    } else {
      this.args.onDeselectAll();
    }
  }
}

import Route from '@ember/routing/route';
import dirtyCheck from 'garaje/utils/decorators/dirty-check';

@dirtyCheck
class DeliveriesSettingsDeliveryAreasDeliveryAreaIndexRoute extends Route {
  // start: dirty-check overrides
  _dirtyObject = 'changeset';
  _dirtyAttr = 'isDirty';
  // end: dirty-check overrides
}

export default DeliveriesSettingsDeliveryAreasDeliveryAreaIndexRoute;

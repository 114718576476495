import Component from '@glimmer/component';
import ObjectProxy from '@ember/object/proxy';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

class PrinterOption extends ObjectProxy {
  get name() {
    const { name } = this.content;
    if (this.disabled) {
      return `${name} (Printer offline)`;
    }
    return name;
  }
  get disabled() {
    return this.content.status !== 'connected';
  }
}

export default class PrinterSelectorComponent extends Component {
  @tracked selectedPrinter;

  constructor() {
    super(...arguments);
    const { printerOptions } = this;
    this.selectedPrinter = printerOptions.findBy('id', this.args.selectedPrinter?.id);
  }

  get printerOptions() {
    const { printers } = this.args;
    return printers.sortBy('name').map((printer) =>
      PrinterOption.create({
        content: printer,
      }),
    );
  }

  @action
  selectPrinter(printer) {
    this.selectedPrinter = printer;
    this.args.onChange(printer?.content);
  }
}

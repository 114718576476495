import { tracked } from '@glimmer/tracking';
import { Day } from 'garaje/utils/enums';
import moment from 'moment-timezone';

const dateFormat = 'YYYYMMDDHHmmss';

interface RecurringRuleOBJ {
  FREQ: string;
  BYDAY: string;
  BYMONTH: string;
  BYMONTHDAY: string;
  UNTIL: string;
}

type RecurringRuleWeekday = 'SU' | 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA';

export function byDayValue(day: Day): RecurringRuleWeekday {
  switch (day) {
    case Day.Sunday:
      return 'SU';
    case Day.Monday:
      return 'MO';
    case Day.Tuesday:
      return 'TU';
    case Day.Wednesday:
      return 'WE';
    case Day.Thursday:
      return 'TH';
    case Day.Friday:
      return 'FR';
    case Day.Saturday:
      return 'SA';
  }
}

export function dayFromRule(recurringRule: RecurringRule): Day {
  if (!recurringRule.byDay) {
    throw new Error(`Tried to retrieve the day value from a RecurringRule that does not have one`);
  }
  switch (recurringRule.byDay) {
    case 'SU':
      return Day.Sunday;
    case 'MO':
      return Day.Monday;
    case 'TU':
      return Day.Tuesday;
    case 'WE':
      return Day.Wednesday;
    case 'TH':
      return Day.Thursday;
    case 'FR':
      return Day.Friday;
    case 'SA':
      return Day.Saturday;
  }
}

export default class RecurringRule {
  @tracked frequency: string | null = null;
  @tracked byDay: RecurringRuleWeekday | null = null;
  @tracked byMonth: string | null = null;
  @tracked byMonthDay: string | null = null;
  @tracked until: moment.Moment | null = null;

  constructor(args = {}) {
    Object.assign(this, args);
  }

  formatUntil(date: moment.Moment): string {
    const formattedDate = moment.utc(moment(date).endOf('date').subtract(59, 'seconds')).format(dateFormat);
    return formattedDate + 'Z';
  }

  isBlank(): boolean {
    return this.toString() === '';
  }

  toString(): string {
    let string = '';

    if (this.frequency) {
      string = string + `FREQ=${this.frequency};`;
    }
    if (this.byDay) {
      string = string + `BYDAY=${this.byDay};`;
    }
    if (this.byMonth) {
      string = string + `BYMONTH=${this.byMonth};`;
    }
    if (this.byMonthDay) {
      string = string + `BYMONTHDAY=${this.byMonthDay};`;
    }
    if (this.until) {
      string = string + `UNTIL=${this.formatUntil(this.until)};`;
    }

    return string;
  }

  static parse(rruleStr: string): RecurringRule {
    const rruleObj = (rruleStr || '').split(';').reduce<Partial<RecurringRuleOBJ>>((acc, elem) => {
      const arr = <Array<keyof RecurringRuleOBJ>>elem.split('=');
      acc[arr[0]!] = arr[1];
      return acc;
    }, {});

    return new RecurringRule({
      frequency: rruleObj['FREQ'],
      byDay: <RecurringRuleWeekday>rruleObj['BYDAY'],
      byMonth: rruleObj['BYMONTH'],
      byMonthDay: rruleObj['BYMONTHDAY'],
      until: rruleObj['UNTIL'] ? moment(rruleObj['UNTIL'], dateFormat + 'Z') : null,
    });
  }
}

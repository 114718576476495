import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import { Changeset } from 'ember-changeset';
import { type BufferedChangeset } from 'ember-changeset/types';
import { optOutDefaultMessage } from 'garaje/models/abstract/abstract-gdpr-configuration';
import type ZoneModel from 'garaje/models/zone';
import type {
  PropertyFormChangesets,
  PropertySettingsChangesets,
} from 'garaje/pods/components/properties/property-form/component';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';
import { TrackedObject } from 'tracked-built-ins';

export interface PropertyEditRouteModel {
  changesets: PropertySettingsChangesets;
  propertyName: string;
  property: ZoneModel;
}

function isChangesetDirty(changeset?: BufferedChangeset) {
  // if no changes have been made, continue normally
  if (!changeset?.isDirty || changeset['isDestroyed']) return false;

  // for whatever reason, ember-changeset sometimes thinks it's dirty because of changes to
  // `_oldWillDestroy` and `willDestroy`. If those are the only changes, don't prompt the user.
  //
  // https://github.com/poteto/ember-changeset/issues/589
  // changeset seems to get polluted from ember inspector
  const changedAttributes = changeset.changes.map((c) => <string>c['key']);
  if (
    changedAttributes.length === 2 &&
    changedAttributes[0] === '_oldWillDestroy' &&
    changedAttributes[1] === 'willDestroy'
  ) {
    return false;
  }

  return true;
}

export default class PropertyEditRoute extends Route {
  @service declare router: RouterService;
  @service declare transitionConfirm: TransitionConfirmService;
  @service declare store: Store;

  async model(): Promise<PropertyEditRouteModel> {
    const property = <ZoneModel>this.modelFor('property');

    const configs = (
      await this.store.query('property-gdpr-configuration', {
        filter: {
          property: property.id,
        },
      })
    ).toArray();

    const gdprConfig =
      configs[0] ??
      this.store.createRecord('property-gdpr-configuration', {
        optOutVisitorProtocolEnabled: true,
        optOutVisitorProtocol: optOutDefaultMessage,
        property,
      });

    return new TrackedObject({
      changesets: {
        coverPhoto: Changeset(property),
        kioskLanguageSettings: Changeset(property),
        property: Changeset(property),
        accentColor: Changeset(property),
        logo: Changeset(property),
        gdpr: gdprConfig ? Changeset(gdprConfig) : undefined,
      },
      property,
      propertyName: property.name,
    });
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    // don't do anything when entering this route
    if (transition.to.name === this.routeName) return;

    const { changesets } = <PropertyEditRouteModel>this.modelFor(this.routeName);
    const allChangesets = Object.values(changesets);

    // if no changesets are dirty, continue normally
    if (allChangesets.every((changeset) => !isChangesetDirty(changeset))) {
      this.#cleanup(allChangesets);
      return;
    }

    // otherwise, prompt user for confirmation before leaving the page
    void this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue: () => this.#cleanup(allChangesets),
    });
  }

  #cleanup(changesets: PropertyFormChangesets[]): void {
    changesets.forEach((changeset) => {
      if (!changeset.isDestroyed && !changeset.isDestroying) changeset?.rollback();
      if (!changeset._content.isDestroyed && !changeset._content.isDestroying) changeset?._content.rollbackAttributes();
    });
  }
}

import { FormDropdown } from '@envoy/react-rich-components-core';
import { useEffect, useState } from 'react';
import { UseFormTrigger } from 'react-hook-form';

import { useCustomEmployeeOptions } from '../../hooks/use-custom-employee-options';
import AddCircleIcon from '../../icons/add-circle.svg';
import type { GenericService, GQLTemplate, LabelValue, Template } from '../../types';
import { MessageForm } from '../send-message/send-message';
import './employee-group-select.css';

type EmployeeGroupSelectProps = {
  options: LabelValue[];
  showCustomEmployeeSelect?: boolean;
  hasCustomValue?: boolean;
  canSelectScimGroups?: boolean;
  canSelectIndividualEmployees?: boolean;
  recipientGroupSelected?: boolean;
  formMethodTrigger?: UseFormTrigger<MessageForm>;
  isDisabled?: boolean;
  isTemplate?: boolean;
  service: Pick<GenericService, 'getEmployeeGroupOptions' | 'getEmployeeOptions'>;
};

const EmployeeGroupSelect = ({
  options,
  showCustomEmployeeSelect,
  recipientGroupSelected,
  formMethodTrigger,
  hasCustomValue,
  canSelectScimGroups,
  canSelectIndividualEmployees,
  isDisabled,
  isTemplate,
  service,
}: EmployeeGroupSelectProps) => {
  const [showCustomSelect, setShowCustomSelect] = useState(false);

  const { showHelpCenterArticle, loadCustomEmployeeOptions } = useCustomEmployeeOptions(
    service,
    canSelectScimGroups,
    canSelectIndividualEmployees,
  );

  const getEmployeeSelectLabel = () => {
    if (!isTemplate && canSelectScimGroups) {
      return 'Employee groups or employees';
    }

    if (!isTemplate) {
      return 'Custom employees';
    }

    if (canSelectScimGroups) {
      return 'Default employee groups or employees';
    }

    return 'Default custom employees';
  };

  useEffect(() => {
    if (formMethodTrigger) {
      void formMethodTrigger('customSelections');
    }
  }, [showCustomSelect]);

  return [
    <div className="employee-group-select-group">
      <div>
        <FormDropdown<Template | GQLTemplate>
          name={isTemplate ? 'defaultEmployeeAudiences' : 'employeeGroup'}
          label={isTemplate ? 'Default employees' : 'Employees'}
          isDisabled={isDisabled}
          isMulti
          options={options}
          placeholder="Select who should receive this message"
          isClearable={true}
          required={isTemplate || recipientGroupSelected ? undefined : 'Please select at least one recipient'}
          testId="employee-group-multi-select"
        />
        {showCustomEmployeeSelect && !showCustomSelect && !hasCustomValue && (
          <div className="add-employee-groups-button-container">
            <button
              type="button"
              data-testid="add-employee-groups-button"
              className="add-employee-groups-button"
              onClick={() => setShowCustomSelect(true)}
            >
              <AddCircleIcon />
              <span>Add groups or employees</span>
            </button>
          </div>
        )}
      </div>
      {showCustomEmployeeSelect && (showCustomSelect || hasCustomValue) && (
        <FormDropdown<Template | GQLTemplate>
          name="customSelections"
          label={getEmployeeSelectLabel()}
          inputMode="object"
          helpText={
            canSelectScimGroups && showHelpCenterArticle ? (
              <>
                Send messages to groups using employee attributes or custom groups.{' '}
                <a
                  href="https://envoy.help/en/articles/9917833-scim-groups-for-emergency-notifications"
                  target="_blank"
                >
                  Learn more
                </a>
              </>
            ) : undefined
          }
          options={[]}
          required={isTemplate || recipientGroupSelected ? undefined : 'Please select at least one recipient'}
          placeholder="Select who should receive this message"
          loadOptions={loadCustomEmployeeOptions}
          isMulti
          isAsync
          valueIsGrouped
          testId="custom-employee-multi-select"
        />
      )}
    </div>,
  ];
};

export { EmployeeGroupSelect };

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { localCopy } from 'tracked-toolbox';
import { isEmpty } from '@ember/utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';
import config from 'garaje/config/environment';
import flashPromise from 'garaje/utils/flash-promise';
import $ from 'jquery';

const IS_TESTING = config.environment === 'test';

/**
 * Generates an HTMLInputElement containing a valid src from a file upload
 *
 * @param {File} file the file to generate a preview for
 * @returns {Promise<HTMLImageElement>} the image element
 */
function loadPreview(file) {
  return new Promise(function (resolve, reject) {
    if (IS_TESTING) {
      return resolve();
    }

    const reader = new FileReader();

    reader.onload = function () {
      const image = new Image();

      image.onload = function () {
        resolve(image);
      };

      image.onerror = function () {
        reject(image.errors);
      };

      image.src = reader.result;
    };

    reader.onerror = function () {
      reject(reader.errors);
    };

    reader.readAsDataURL(file);
  });
}

/**
 * @param {string}                         accept
 * @param {Function}                       action
 * @param {Function}                       allUploaded
 * @param {boolean}                        autoUpload
 * @param {Function}                       beginUpload
 * @param {string}                         color
 * @param {object}                         extra
 * @param {Array}                          files
 * @param {boolean}                        flashPromiseOff
 * @param {string}                         invalidBody
 * @param {string}                         invalidTitle
 * @param {number}                         max
 * @param {boolean}                        multiple
 * @param {string}                         namespace
 * @param {Function<object[]>, object>}    [onPendingUploadChange]
 * @param {string}                         param
 * @param {string}                         requestType
 * @param {object}                         target
 * @param {string}                         url
 * @param {number}                         validSize
 * @param {string}                         validType
 */
export default class FileUpload extends Component {
  @service uploaderFactory;

  @localCopy('args.autoUpload', true) autoUpload;

  @localCopy('args.max', 1) max;
  @localCopy('args.param', 'file') param;
  @localCopy('args.requestType', 'POST') requestType;
  @localCopy('args.multiple', false) multiple;
  @localCopy('args.validType') validType;
  @localCopy('args.validSize') validSize;

  /**
   * @type {File[]}
   */
  @tracked _files;
  @tracked element;
  @tracked isValid = true;

  pendingUpload = null;

  @action
  onInsert(element) {
    this.element = element;
  }

  @action
  onChange({ target: input }) {
    if (!isEmpty(input.files)) {
      this.filesDidChange(input.files);
    }
  }

  @action
  onUpdate() {
    const oldFiles = this._files;
    const newFiles = this.args.files;
    if (newFiles && newFiles !== oldFiles) {
      next(this, function () {
        this.filesDidChange(newFiles);
      });
    }
  }

  async filesDidChange(files) {
    // HALT if any selected file is invalid (e.g. too big)
    for (let i = 0; i < files.length; i++) {
      if (!this.validate(files[i])) return;
    }

    // convert to array in case files is a FileList
    files = Array.from(files);

    if (!this.autoUpload) {
      // if we are not auto uploading and we are uploading multiple files, we need keep
      // building on the pending upload instead of resetting the upload state
      this._files = this.multiple ? [...(this._files ?? []), ...files] : files;

      // only generate pending file objects for new files
      const newPendingFiles = await this.generatePendingFiles(files);
      this.pendingUpload = await this.generatePendingUpload([...newPendingFiles]);

      // emit new pending files and pending upload object
      this.args.onPendingUploadChange?.(newPendingFiles, this.pendingUpload);
    } else {
      this._files = files;
      this.upload(files);
    }

    return;
  }

  /**
   * @param {File[]} files - array of files to generate pending file objects for
   */
  async generatePendingFiles(files) {
    const promises = files.map(async (file) => {
      const preview = await loadPreview(file);
      return {
        preview,
        file,
        clear: () => {
          this._files.splice(this._files.indexOf(file), 1);
          // reset component state if we have cleared all files
          if (this._files.length === 0) this.clear();
        },
      };
    });

    return Promise.all(promises);
  }

  async generatePendingUpload(pendingFiles) {
    return {
      // use the internal list of files that is kept up to
      // date as the pendingUpload is updated with new or removed files
      upload: () => this.upload(this._files),
      pendingFiles: [...(this.pendingUpload?.pendingFiles ?? []), ...pendingFiles],
      clear: () => this.clear(),
    };
  }

  /**
   * Clears the file input and any pending upload
   */
  clear() {
    this.element.value = null;
    this.pendingUpload = null;
  }

  upload(files) {
    const { url: uploadUrl, namespace, extra } = this.args;
    const { param, requestType: method } = this;

    const uploader = this.uploaderFactory.createUploader({
      url: uploadUrl,
      paramNamespace: namespace,
      paramName: param,
      method,
      headers: { Accept: 'application/vnd.api+json' },
    });

    const promises = [];
    const didUpload = (response) => {
      const previewPromise = loadPreview(files[0]);
      this.args.action?.(response, previewPromise);
    };

    uploader.on('didUpload', didUpload);

    if (!isEmpty(files)) {
      const maxFiles = Math.min(this.max, files.length);

      for (let i = 0; i < maxFiles; i++) {
        this.args.beginUpload?.();
        promises.push(uploader.upload(files[i], extra));
      }
    }

    const allPromise = Promise.all(promises).finally(() => {
      this.args.allUploaded?.();
      if (this.element) {
        $(this.element).prop('value', '');
      }
      uploader.off('didUpload', didUpload);
    });

    if (!this.args.flashPromiseOff) {
      flashPromise(allPromise, this.args.target);
    }
  }

  get validTypeReg() {
    if (this.validType instanceof RegExp) {
      return this.validType;
    }
    return new RegExp(this.validType);
  }

  validate(file) {
    const typeValid = this.validType ? this.validTypeReg.test(file.type) : true;
    const sizeValid = this.validSize ? file.size <= this.validSize : true;

    this.isValid = typeValid && sizeValid;

    return this.isValid;
  }
}

import { momentOrMomentTimezone } from '../index.js';
import BaseHelper from './-base.js';
import '@embroider/macros';
import '@ember/runloop';
import '@ember/component/helper';
import '@ember/object';
import '@ember/service';

var utc = BaseHelper.extend({
  compute([utcTime, format]) {
    this._super(...arguments);

    return this.moment.utc(momentOrMomentTimezone.utc(utcTime, format));
  }

});

export { utc as default };

import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type { DetailedChangeset } from 'ember-changeset/types';
import type { Task } from 'ember-concurrency';
import type SubscriptionModel from 'garaje/models/subscription';
import type StateService from 'garaje/services/state';

import type { SupportedAgreements } from '../component';
import type { SaveOptions } from '../edit-text/component';

interface SendSignedComponentSignature {
  Args: {
    changeset: DetailedChangeset<SupportedAgreements>;
    disabled: boolean;
    editingEmail: boolean;
    model: SupportedAgreements;
    propagable: boolean;
    trackLegalDocument: () => Promise<void>;
    vrSubscription: SubscriptionModel;
    updateAndSaveTask: Task<void, [SaveOptions<keyof SupportedAgreements>]>;
    updateEditingEmail: (value: boolean) => void;
  };
}

export default class SendSignedComponent extends Component<SendSignedComponentSignature> {
  @service declare state: StateService;

  get hasAccessToNdaBccFeature(): boolean {
    return Boolean(this.state.features?.canAccessNdaBcc);
  }

  @action
  applyChangeset(changeset: DetailedChangeset<SupportedAgreements>): void {
    changeset.execute();
    changeset.rollback();
  }

  @action
  rollbackChangeset(changeset: DetailedChangeset<SupportedAgreements>): void {
    changeset.rollback();
  }
}

import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type DeviceModel from 'garaje/models/device';
import type LocationModel from 'garaje/models/location';
import type LocationSubscriptionModel from 'garaje/models/location-subscription';
import type PrinterModel from 'garaje/models/printer';
import type SubscriptionModel from 'garaje/models/subscription';
import VisitorsDevicesIpadsRoute from 'garaje/pods/visitors/devices/ipads/route';
import type StateService from 'garaje/services/state';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

export interface VfdDevicesIpadsRouteModel {
  ipads: RecordArray<DeviceModel>;
  location: LocationModel;
  printers: RecordArray<PrinterModel>;
  subscription: LocationSubscriptionModel | SubscriptionModel;
}

export default class VirtualFrontDeskDevicesIpadsRoute extends VisitorsDevicesIpadsRoute {
  @service declare state: StateService;
  @service declare store: StoreService;

  model(): Promise<VfdDevicesIpadsRouteModel> {
    const subscription = this.state.vrSubscription!;
    const location = this.state.currentLocation;
    const locationId = location.id;
    const ipads = this.store.query('device', { filter: { location: locationId }, sort: 'name', include: 'printer' });
    const printers = this.store.query('printer', { filter: { location: locationId }, include: 'devices,location' });

    return hash({
      ipads,
      location,
      printers,
      subscription,
    });
  }
}

import Controller from '@ember/controller';
import { service } from '@ember/service';
import type Link from 'ember-link/link';
import type LinkManagerService from 'ember-link/services/link-manager';

import type { SettingsEmailTemplatesRouteModel } from './route';

interface Tab {
  label: string;
  link: Link;
}

export default class SettingsEmailTemplatesController extends Controller {
  declare model: SettingsEmailTemplatesRouteModel;

  @service declare linkManager: LinkManagerService;

  get tabs(): Tab[] {
    return this.model.envoyDefaultTemplates.map(({ id, title }) => {
      return {
        label: `${title} email`,
        link: this.linkManager.createUILink({
          route: 'visitors.settings.email-templates.show',
          models: [id],
          query: { action: null },
        }),
      };
    });
  }
}

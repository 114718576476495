import Component from '@glimmer/component';

/**
 * @param {Task}                activateTask
 * @param {Boolean}             deliveriesLimitExceeded
 * @param {Class<DeliveryArea>} deliveryArea
 * @param {Class<Location>}     location
 * @param {Boolean}             mustPurchaseToActivate
 */
// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class DeliveryAreaListItem extends Component {}

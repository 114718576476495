import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

export default class GlobalAnalyticsDesksRoute extends Route {
  @service skinnyLocations;

  beforeModel() {
    // needed for global-analytics ability
    return this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
  }
}

import V2Adapter from './v2';

export default class extends V2Adapter {
  pathForType() {
    return 'signed-agreements';
  }

  buildURL(_modelName, _id, _snapshot, _requestType, query) {
    const url = super.buildURL(...arguments);

    return url.replace('signed-agreements', `${query.agreeablePath}/${query.agreeableId}/signed-agreements`);
  }
}

import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class DeliveriesSettingsDeliveryAreasDeliveryAreaNotificationsRoute extends Route {
  @service transitionConfirm;

  model() {
    const { currentLocation, deliveryArea } = this.modelFor('deliveries.settings.delivery-areas.delivery-area');
    const notificationSchedule = get(deliveryArea, 'notificationSchedule');

    return hash({
      location: currentLocation,
      deliveryArea,
      notificationSchedule,
    });
  }

  @routeEvent
  routeWillChange(transition) {
    const {
      model: { notificationSchedule },
    } = this.controller;
    // no need to display confirmation modal when nothing is changed
    if (!notificationSchedule.hasDirtyAttributes) {
      return;
    }
    // display confirmation modal if settings are dirty
    this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        if (notificationSchedule.hasDirtyAttributes) {
          notificationSchedule.rollbackAttributes();
        }
      },
    });
  }
}

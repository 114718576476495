// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { didCancel, task, timeout, dropTask, restartableTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { pluralize } from 'ember-inflector';

import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import zft from 'garaje/utils/zero-for-tests';

const DOWNLOAD_DOCUMENTS_MAX_TIMEOUT = zft(60000);
const DOWNLOAD_DOCUMENTS_TIMEOUT = zft(500);

/**
 * @param {Object}            agreeable
 * @param {Object}            ndaPlatformJobs
 * @param {Object}            legalDocumentDescriptions
 */
export default class LegalDocumentActivity extends Component {
  @service flashMessages;
  @service store;
  @service windowLocation;

  @dropTask
  *downloadDocuments(agreeable) {
    const query = {
      agreeableId: agreeable.id,
      agreeablePath: pluralize(agreeable.constructor.modelName),
    };
    const signedAgreementsJob = yield this.store.queryRecord('signed-agreements-job', query);

    try {
      if (signedAgreementsJob.isNotDone) {
        this.setMaxTimeoutForSignedAgreementsJobPollTask.perform();

        yield this.pollForSignedAgreementsJobUntilDone.perform(query).catch((error) => {
          if (didCancel(error)) {
            throw "We're sorry, something went wrong.";
          } else {
            throw error;
          }
        });
      }

      this.windowLocation.assign(signedAgreementsJob.url);
      this.flashMessages.showAndHideFlash('success', 'Documents downloaded!');
    } catch (error) {
      const errorText = parseErrorForDisplay(error);
      this.flashMessages.showAndHideFlash('error', errorText);
    } finally {
      this.store.unloadAll('signed-agreements-job');
    }
  }

  @task
  *pollForSignedAgreementsJobUntilDone(query) {
    while (true) {
      const signedAgreementsJob = yield this.store.queryRecord('signed-agreements-job', query);

      if (signedAgreementsJob.isDone) {
        this.setMaxTimeoutForSignedAgreementsJobPollTask.last.cancel();
        break;
      }

      yield timeout(DOWNLOAD_DOCUMENTS_TIMEOUT);
    }
  }

  @restartableTask
  *setMaxTimeoutForSignedAgreementsJobPollTask() {
    yield timeout(DOWNLOAD_DOCUMENTS_MAX_TIMEOUT);

    this.pollForSignedAgreementsJobUntilDone.last.cancel();
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, set, setProperties } from '@ember/object';
import { all, task, dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

const integration_name = 'Visual Compliance';
const message_code_type = 'integration';

/**
 * @param {Boolean}                                         isOpen
 * @param {Function}                                        toggle
 * @param {Class<Location>}                                 location
 * @param {Class<Subscription>}                             subscription
 * @param {Class<VisualComplianceConfiguration>}            visualComplianceConfiguration
 * @param {Function}                                        onCreateConfiguration
 * @param {Class<Flow>}                                     flow
 */
export default class VisualCompilancePanel extends Component {
  @service flashMessages;
  @service metrics;
  @service store;

  @tracked configuration;

  constructor() {
    super(...arguments);
    this.configuration = this.args.visualComplianceConfiguration;
  }

  @dropTask
  *enableTask() {
    this.metrics.startJob();
    this.metrics.trackJobEvent('Security - Integration Enable Button Clicked', {
      integration_name,
      button_text: 'Enable',
    });
    try {
      if (!this.configuration) {
        const vcType = this.args.flow.isGlobal
          ? 'global-visual-compliance-configuration'
          : 'visual-compliance-configuration';
        const config = this.store.createRecord(vcType, {
          flow: this.args.flow,
          fieldsToCheck: ['name'],
        });
        yield config.save();
        this.configuration = config;
        this.args.onCreateConfiguration(config);
      }
      set(this.args.flow, 'visualComplianceEnabled', true);
      this.metrics.trackJobEvent('Security - Integration Enable Requested', { integration_name });
      yield this.args.flow.save();
      this.metrics.trackJobEvent('Security - Integration Enabled', { integration_name });
      this.args.toggle(true);
      this.flashMessages.showAndHideFlash('success', 'Enabled!');
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'success',
        integration_name,
        message_title: 'Enabled!',
        message_code: 'integration_enable_success',
        message_code_type,
      });
    } catch (e) {
      this.configuration.rollbackAttributes();
      this.args.flow.rollbackAttributes();
      this.args.toggle(false);
      const message_title = 'There was an issue enabling Visual Compliance';
      const message_code = parseErrorForDisplay(e);
      this.flashMessages.showFlash('error', message_title, message_code);
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'error',
        integration_name,
        message_title,
        message_code,
        message_code_type,
      });
    }
  }

  @dropTask
  *disableTask() {
    this.metrics.trackJobEvent('Security - Integration Disable Button Clicked', {
      integration_name,
      button_text: 'Disable',
    });
    this.args.toggle(false);
    set(this.args.flow, 'visualComplianceEnabled', false);
    set(this.configuration, 'fieldsToCheck', ['name']);
    try {
      this.metrics.trackJobEvent('Security - Integration Disable Requested', { integration_name });
      yield all([this.args.flow.save(), this.configuration.save()]);
      this.metrics.trackJobEvent('Security - Integration Disabled', { integration_name });
      this.flashMessages.showAndHideFlash('success', 'Disabled!');
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'success',
        integration_name,
        message_title: 'Disabled!',
        message_code: 'integration_disable_success',
        message_code_type,
      });
    } catch (e) {
      this.configuration.rollbackAttributes();
      this.args.flow.rollbackAttributes();
      this.args.toggle(true);
      const message_title = 'There was an issue disabling Visual Compliance';
      const message_code = parseErrorForDisplay(e);
      this.flashMessages.showFlash('error', message_title, message_code);
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'error',
        integration_name,
        message_title,
        message_code,
        message_code_type,
      });
    }
  }

  @dropTask
  *saveTask() {
    this.metrics.trackJobEvent('Security - Integration Settings Update Button Clicked', {
      integration_name,
      button_text: 'Save',
    });
    try {
      yield this.syncVisualComplianceFieldsTask.perform(this.configuration.fieldsToCheck);
      this.metrics.trackJobEvent('Security - Integration Settings Update Requested', { integration_name });
      yield this.configuration.save();
      this.metrics.trackJobEvent('Security - Integration Settings Updated', { integration_name });
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'success',
        integration_name,
        message_title: 'Saved!',
        message_code: 'integration_update_success',
        message_code_type,
      });
    } catch (e) {
      this.configuration.rollbackAttributes();
      const message_title = e.title || 'Error';
      const message_code = parseErrorForDisplay(e);
      this.flashMessages.showFlash('error', message_title, message_code);
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'error',
        integration_name,
        message_title,
        message_code,
        message_code_type,
      });
    }
  }

  @task
  *syncVisualComplianceFieldsTask(identifiers) {
    const signInFieldPage = yield get(this.args.flow, 'signInFieldPage');
    const signInFields = yield get(signInFieldPage, 'signInFields');
    const fields = [];

    try {
      [
        { name: 'City', identifier: 'locality' },
        { name: 'Company', identifier: 'company' },
      ].forEach(({ name, identifier }, i) => {
        if (identifiers.includes(identifier)) {
          const fieldWithIdentifier = signInFields.findBy('identifier', identifier);
          const fieldWithName = signInFields.findBy('name', name);
          if (fieldWithIdentifier) {
            set(fieldWithIdentifier, 'required', true);
            fields.push(fieldWithIdentifier);
          } else if (fieldWithName) {
            if (fieldWithName.kind !== 'text') {
              const error = new Error(
                `Your sign-in flow has a field called "${name}”. Remove the existing field, then try again.`,
              );
              error.title = 'Field already exists';
              throw error;
            } else {
              setProperties(fieldWithName, { required: true, identifier });
              fields.push(fieldWithName);
            }
          } else {
            fields.push(
              signInFields.createRecord({
                name,
                kind: 'text',
                required: true,
                identifier,
                position: signInFields.length + 1 + i,
                storeResponse: true,
              }),
            );
          }
        }
      });
      yield all(fields.map((field) => field.save()));
    } catch (e) {
      fields.map((field) => field.rollbackAttributes());
      throw e;
    }
  }

  @action
  onCloseVisualCompliance() {
    this.args.toggle(false);
    if (this.configuration.hasDirtyAttributes) {
      this.configuration.rollbackAttributes();
    }
    this.metrics.trackJobEvent('Security - Integration Section Close Button Clicked', {
      integration_name,
      button_text: 'X',
    });
  }

  @action
  onClickEdit() {
    this.metrics.startJob();
    this.metrics.trackJobEvent('Security - Go to Modify Integration Settings Button Clicked', {
      integration_name,
      button_text: 'Edit',
    });
    if (this.configuration) {
      this.args.toggle();
    } else {
      const message_title = 'Error';
      const message_code =
        'There was a problem loading the Visual Compliance settings for this sign-in flow. Please try refreshing your browser. If this problem persists please contact customer support for further assistance.';
      this.flashMessages.showFlash('error', message_title, message_code);
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'error',
        integration_name,
        message_title,
        message_code,
        message_code_type,
      });
    }
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';

const DEFAULT_IDENTIFIER = 'covid-19-vaccine-card';

export default class ProfileDocumentsRoute extends Route {
  @service declare router: RouterService;

  redirect(): void {
    void this.router.replaceWith('profile.document', DEFAULT_IDENTIFIER);
  }
}

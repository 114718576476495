/* eslint-disable ember/no-component-lifecycle-hooks */
/* eslint-disable ember/classic-decorator-hooks */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { dropTask, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { set, setProperties } from '@ember/object';
import { toUp, toDown } from 'ember-animated/transitions/move-over';
import { isBlank } from '@ember/utils';
import _sample from 'lodash/sample';

import config from 'garaje/config/environment';
import urlBuilder from 'garaje/utils/url-builder';
const DATA_POLLING_TIMEOUTS = [3500, 4500, 6500, 7500, 8500];

export default class DashboardProductBoxRoomsComponent extends Component {
  @service ajax;

  init() {
    super.init(...arguments);
    if (this.subscription) {
      this.initTask.perform();
      set(this, 'pollingInstance', this.pollForChangesTask.perform());
    }
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    if (this.pollingInstance) {
      this.pollingInstance.cancel();
    }
  }

  /**
   * @return {EmberAnimated Transition}
   */
  rules({ oldItems, newItems }) {
    return oldItems[0] < newItems[0] ? toDown : toUp;
  }

  /**
   * initTask
   * Task used for the initial loading state
   */
  @dropTask
  *initTask() {
    yield this.fetchBoxDataTask.perform();
  }

  /**
   * pollForChangesTask
   */
  @dropTask
  *pollForChangesTask() {
    while (config.environment !== 'test') {
      yield timeout(_sample(DATA_POLLING_TIMEOUTS));
      yield this.fetchBoxDataTask.perform();
    }
  }

  /**
   * fetchBoxDataTask
   */
  @dropTask
  *fetchBoxDataTask() {
    const url = urlBuilder.v2.roomsLandingPage(this.location.id);
    try {
      const {
        data: {
          'auto-released': autoReleased,
          'auto-released-change': autoReleasedChange,
          booked: booked,
          'booked-change': bookedChange,
        },
      } = yield this.ajax.request(url);
      setProperties(this, {
        autoReleased,
        autoReleasedChange,
        booked,
        bookedChange,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ error });
      if (isBlank(this.autoReleased)) {
        setProperties(this, { autoReleased: 0, autoReleasedChange: 0, booked: 0, bookedChange: 0 });
      }
    }
  }
}

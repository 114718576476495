import { get } from '@ember/object';
import { type AsyncBelongsTo } from '@ember-data/model';
import Component from '@glimmer/component';
import type LocationModel from 'garaje/models/location';
import type Printer from 'garaje/models/printer';
import PropertyPrinter from 'garaje/models/property-printer';
import type ZoneModel from 'garaje/models/zone';
import { equal, reads } from 'macro-decorators';

interface PrinterInfoComponentArgs {
  printer: Printer | PropertyPrinter;
  goToPrinterPage(): void;
}

/**
 * @param {Function}                  goToIPadPage
 * @param {Function}                  goToPrinterPage
 * @param {(Printer|PropertyPrinter)} printer
 */
export default class PrinterInfoComponent extends Component<PrinterInfoComponentArgs> {
  @reads('args.printer.connectionType') connectionType!: 'ip' | 'bluetooth' | 'integration' | 'printnode';
  @equal('connectionType', 'integration') isIntegration!: boolean;
  @equal('connectionType', 'printnode') isPrintNode!: boolean;

  get locationName(): string {
    const { printer } = this.args;
    const key: string = printer instanceof PropertyPrinter ? 'property.name' : 'location.name';
    const name: unknown = get(printer, key);

    return typeof name === 'string' ? name : '-';
  }

  get propertyOrLocation(): AsyncBelongsTo<LocationModel | ZoneModel> {
    const { printer } = this.args;

    if (printer instanceof PropertyPrinter) {
      return printer.property;
    }

    return printer.location;
  }

  get iconSrcKey(): string {
    const {
      isPrintNode,
      args: { printer },
    } = this;

    return isPrintNode ? 'printnode' : printer.model;
  }

  get shouldShowMode(): boolean {
    const { isIntegration, isPrintNode } = this;

    return !(isIntegration || isPrintNode);
  }

  get shouldShowIpAddress(): boolean {
    const { isIntegration, isPrintNode } = this;

    return !(isIntegration || isPrintNode);
  }

  get shouldShowConnectionName(): boolean {
    const {
      isPrintNode,
      args: { printer },
    } = this;

    return isPrintNode && Boolean(printer.printerConnection);
  }
}

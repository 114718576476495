import Controller from '@ember/controller';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';

class ManageRolesController extends Controller {
  @service declare abilities: AbilitiesService;

  @tracked showCreateDialog = false;
  @tracked showEnterpriseDialog = false;
  @tracked showRequestSentDialog = false;

  handleNewRoleClick = (): void => {
    if (this.abilities.can('create custom-roles')) {
      this.showCreateDialog = true;
    } else {
      this.showEnterpriseDialog = true;
    }
  };

  ENTERPRISE_DIALOG_HEADER = 'Create custom admin roles';
  ENTERPRISE_DIALOG_DESCRIPTION = `
    Control viewing and editing permissions based on your organization's specific needs.
    To unlock custom admin roles, please contact us to upgrade to our
    Enterprise plan. You'll also gain access to useful features like admin sync.
  `;

  @action
  setRequestSentDialog(showDialog: boolean): void {
    this.showRequestSentDialog = showDialog;
  }

  @action
  displayRequestSentDialog(): void {
    this.showEnterpriseDialog = false;
    this.setRequestSentDialog(true);
    later(() => {
      this.setRequestSentDialog(false);
    }, 2500);
  }

  reset(): void {
    this.showCreateDialog = false;
    this.showEnterpriseDialog = false;
    this.showRequestSentDialog = false;
  }
}

export default ManageRolesController;

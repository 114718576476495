import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

/**
 * @param {object}              userDocuments             array of models
 * @param {object}              userDocumentTemplate      model
 */
export default class DocumentListPanelComponent extends Component {
  @tracked isOpen = true;
}

import gql from 'graphql-tag';

export default gql`
  fragment DeskWithAmenitiesFragment on Desk {
    amenities {
      id
      name
    }
    id
    name
    floor {
      id
      name
      building
      floorPlanUrl
    }
    assignedTo
    neighborhood
    deskAvailability
    x
    y
  }
`;

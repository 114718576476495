import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import moment from 'moment-timezone';

class Meeting extends Model {
  @belongsTo('location') location;
  @belongsTo('employee') employee;
  @hasMany('attendees') attendees;

  @attr('date') expectedArrivalTime;
  @attr('string') name;
  @attr('string') flowName;

  get expectedArrivalTimeDisplay() {
    return moment(this.expectedArrivalTime).format('dddd, MMM D, YYYY [at] h:mm a');
  }
}

export default Meeting;

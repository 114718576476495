import Controller from '@ember/controller';
import urlBuilder from 'garaje/utils/url-builder';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { action } from '@ember/object';
import checkType from 'garaje/utils/check-file-type';
import { pluralize } from 'ember-inflector';
import { alias } from 'macro-decorators';
import csvFromArrayOfObjects from 'garaje/utils/object-array-to-csv';

const SampleCSVWithAmenities =
  'Floor Name,Neighborhood,Desk,Enabled,Assigned To,Amenities\n"Required. Can be letters and/or numbers","Optional. Can be the same across different floors","Required. Unique ID of letters and/or numbers","Required. Yes or No. When No, employees won\'t automatically be booked this desk nor can they select it","Optional. Email of person with assigned desk, otherwise leave blank", "Optional. Amenities to apply on the desk."';

export default class DesksImportController extends Controller {
  @service ajax;
  @service('desksCsvImporter') importer;
  @service featureConfig;
  @service featureFlags;
  @service flashMessages;
  @service metrics;
  @service state;
  @service store;

  sampleCSV = SampleCSVWithAmenities;

  @tracked file = null;
  @tracked records = [];

  @alias('state.desksSubscription') desksSubscription;

  get maximumDesksForCurrentPlan() {
    return this.featureConfig.getLimit('desks.unlimitedDesks');
  }

  get hasReachedDesksLimit() {
    if (!this.featureConfig.isEnabled('desks')) {
      return false;
    }

    return this.enabledDesksQuantity >= this.maximumDesksForCurrentPlan;
  }

  get enabledDesksQuantity() {
    return this.records.filter((record) => {
      const enabledStatus = record.enabled.toLowerCase();
      return enabledStatus === 'yes' || enabledStatus === 'true';
    }).length;
  }

  @dropTask
  *uploadFileTask() {
    const { id } = this.state.currentLocation;
    const uploadUrl = urlBuilder.rms.uploadDesksCsv(id);
    const formData = new FormData();
    formData.append('file', this.file);

    try {
      yield this.ajax.request(uploadUrl, {
        accept: 'application/vnd.api+json',
        contentType: false,
        data: formData,
        processData: false,
        type: 'POST',
      });
      yield this.store.unloadAll('desk');
      yield this.state.setDeskLocationsTask.perform();
      this.flashMessages.showAndHideFlash('success', 'File uploaded!');
      this.metrics.trackEvent('Desks csv imported', { count: this.records.length });
      this.send('refreshModel');
      this.transitionToRoute('desks'); // eslint-disable-line ember/no-deprecated-router-transition-methods
    } catch (err) {
      this.metrics.trackEvent('Error importing desks csv', { error: err });
      // NOTE: For now, we only show show the first error with the row number
      err.responseJSON = { error: err.payload?.[0]?.detail };
      this.flashMessages.showAndHideFlash('error', 'There was an error with your csv', parseErrorForDisplay(err));
    }
  }

  /**
   * @param {File}  file Required. The file to upload
   * @returns {void}
   */
  @dropTask
  *parseDesksCsvTask(file) {
    this.file = file;

    const checkResult = checkType(file, ['csv']);
    if (checkResult.isValid) {
      const result = yield this.importer.importRecordsFromCsvFile.perform(file);
      this.records = result.filter(Boolean);
      const wrongRows = result.length - this.records.length;
      if (wrongRows > 0 && this.records.length > 0) {
        const message = `${pluralize(wrongRows, 'row')} ${wrongRows > 1 ? 'were' : 'was'} not loaded`;
        this.flashMessages.showFlash(
          'error',
          message,
          'Please check that all rows in your file are properly formatted.',
        );
      }
    } else {
      const text = `Only files with extensions .csv are allowed. You uploaded a file with extension ${checkResult.extension}.`;
      this.flashMessages.showFlash('error', text);
    }
    if (this.hasReachedDesksLimit) {
      this.updateRecords();
    }
  }

  @action
  updateRecords() {
    let enabledCount = 0;
    const newRecords = this.records.map((record) => {
      const enabledStatus = record.enabled.toLowerCase();
      if (enabledStatus === 'yes' || enabledStatus === 'true') {
        enabledCount++;
      }

      if (enabledCount > this.maximumDesksForCurrentPlan) {
        record.enabled = 'No';
      }

      return record;
    });
    const newFile = csvFromArrayOfObjects(newRecords, { floorName: 'Floor Name' });
    this.file = newFile;
  }

  @action
  clearAll() {
    this.file = null;
    this.records = [];
  }
}

import Route from '@ember/routing/route';
import { service } from '@ember/service';

class AnalyticsVisitorsIndexRoute extends Route {
  @service abilities;
  @service featureFlags;
  @service flashMessages;
  @service state;
  @service visitorsOnboarding;
  @service router;

  titleToken = 'Analytics';

  beforeModel() {
    const { vrSubscription } = this.state;
    if (this.abilities.cannot('visit-visitors analytics')) {
      if (vrSubscription.canAccessAnalytics) {
        return this.router.transitionTo('unauthorized');
      } else {
        this.flashMessages.showFlash('error', 'Analytics is not available on this plan');
        return this.router.transitionTo('dashboard');
      }
    }
  }

  async model() {
    await this.visitorsOnboarding.loadSteps();
  }

  redirect(_model, transition) {
    this.visitorsOnboarding.gateRoute(this, transition);
  }
}

export default AnalyticsVisitorsIndexRoute;

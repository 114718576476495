/* eslint-disable */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { dropTask, waitForProperty } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { startingSubscriptionPlanId } from 'garaje/utils/starting-subscription-plan-id';
import { APP, PLAN_LEVEL, IMPRESSION_NAMES } from 'garaje/utils/enums';
import { or } from 'macro-decorators';
import { action } from '@ember/object';

export default class VisitorsStartTrialController extends Controller {
  @service currentAdmin;
  @service featureFlags;
  @service flashMessages;
  @service metrics;
  @service state;
  @service locationBilling;
  @service store;
  @service impressions;

  @tracked transitionDone;
  @tracked showBuyNowModal = false;
  @tracked showIntakeForm = false;
  @tracked hasSeenIntakeForm = false;
  @tracked signinPreviewModalClosed = false;
  @tracked shouldShowIpadDemo = false;

  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isBillingAdmin') canStartTrial;

  get showSigninPreviewModal() {
    if (this.featureFlags.isEnabled('growth_ipad_demo')) {
      return !this.showIntakeForm && this.hasSeenIntakeForm && !this.signinPreviewModalClosed;
    }
    return false;
  }

  get showIpadDemo() {
    if (this.featureFlags.isEnabled('growth_ipad_demo')) {
      return (
        !this.showIntakeForm &&
        this.hasSeenIntakeForm &&
        this.signinPreviewModalClosed &&
        this.shouldShowIpadDemo
      );
    }
    return false;
  }

  @action
  async openIntakeForm() {
    this.showIntakeForm = true;
    this.hasSeenIntakeForm = true;
    try {
      await this.startTrialTask.perform();
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }

  @dropTask
  *completeiPadDemo() {
    this.signinPreviewModalClosed = true;
    this.shouldShowIpadDemo = false;
    yield this.transitionToARouteTask.perform();
  }

  @dropTask
  *startiPadDemo() {
    this.signinPreviewModalClosed = true;
    this.shouldShowIpadDemo = true;
  }

  @dropTask
  *closeIntakeForm() {
    this.showIntakeForm = false;
    yield this.impressions.postImpression.perform(IMPRESSION_NAMES.SIGNUP_SURVEY_SUBMITTED);
    if (!this.featureFlags.isEnabled('growth_ipad_demo')) {
      yield this.transitionToARouteTask.perform();
    }
  }

  @dropTask
  *transitionToARouteTask() {
    this.store.query('homepage-group', {
      include:
        'homepage-item-group-mappings.homepage-item.setup-guide-items,homepage-item-group-mappings.homepage-item.marketing-items,homepage-item-group-mappings.homepage-item.helpdesk-items.helpdesk-questions,homepage-item-group-mappings.homepage-item.help-resources-items',
    });

    const transitionObj = this.transitionToRoute('visitors.entries');
    transitionObj.then(() => {
      if (!this.isDestroyed && !this.isDestroying) {
        this.transitionDone = true;
      }
    });
    yield waitForProperty(this, 'transitionDone');
  }

  /**
   * startTrialTask
   * Creates VR Subscription and reloads the global `state` service
   * @param {Event} evt* event being passed in
   */
  @dropTask
  *startTrialTask(evt) {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }
    const { currentCompany } = this.state;

    try {
      if (this.featureFlags.isEnabled('locationBilling')) {
        // Create the VR Subscription Batch
        const subscriptionBatch = this.store.createRecord('subscription-batch', {
          company: currentCompany,
          products: [
            {
              'subscription-plan-id': startingSubscriptionPlanId({ app: APP.VISITORS, state: this.state }),
              trial: true,
            },
          ],
          status: 'pending',
        });
        yield subscriptionBatch.save();
        yield this.locationBilling.pollForSubscriptionBatchTask.perform(subscriptionBatch);
      } else {
        // Create VR Subscription
        const subscription = this.store.createRecord('subscription', {
          app: APP.VISITORS,
          plan: PLAN_LEVEL.PREMIUM,
          company: currentCompany,
        });
        yield subscription.save();
        // Reload state service with the new subscription added
        yield this.state.initSubscriptionStateTask.perform();
      }
      this.flashMessages.showAndHideFlash('success', 'Trial started!');

      this.metrics.trackEvent('Product Landing Page Start Trial Button Clicked', { product: APP.VISITORS });
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }

  get canBuyNow() {
    return this.featureFlags.isEnabled('visitors-buy-now');
  }

  @action
  openBuyNowModal() {
    this.showBuyNowModal = true;
    this.trackBuyNowCTAClicked();
  }

  @action
  trackBuyNowCTAViewed() {
    this.impressions.postImpression.perform(IMPRESSION_NAMES.VISTORS_BUY_NOW.CTA_VIEWED);
  }

  @action
  trackBuyNowCTAClicked() {
    this.impressions.postImpression.perform(IMPRESSION_NAMES.VISTORS_BUY_NOW.CTA_CLICKED);
  }
}

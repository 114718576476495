const RouterEvent = {
  ROUTE_DID_CHANGE: 'routeDidChange',
};

/**
 * @returns {*}
 */
export default function (Class) {
  const DecoratedClass = class extends Class {
    constructor() {
      super(...arguments);
      this.on(RouterEvent.ROUTE_DID_CHANGE, () => {
        this.send('collectTitleTokens', []);
      });
    }
  };

  Object.defineProperty(DecoratedClass, 'name', { value: Class.name });

  return DecoratedClass;
}

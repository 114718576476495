import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { Changeset } from 'ember-changeset';
import { type DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import type BulkConnectInviteModel from 'garaje/models/bulk-connect-invite';
import type ZoneModel from 'garaje/models/zone';
import type ConnectInvitesService from 'garaje/services/connect-invites';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';
import buildInviteValidations from 'garaje/validations/connect-invite';

import { type InvitesModel } from '../route';

export interface InvitesNewModel {
  bulkConnectInviteChangeset?: DetailedChangeset<BulkConnectInviteModel>;
  bulkConnectInvite?: BulkConnectInviteModel;
  currentProperty: ZoneModel;
  loadTenantsTask: InvitesModel['loadTenantsTask'];
  loadPrintersTask: InvitesModel['loadPrintersTask'];
}

export default class PropertyVisitorsInvitesNewRoute extends Route {
  @service declare connectInvites: ConnectInvitesService;
  @service declare store: Store;
  @service declare transitionConfirm: TransitionConfirmService;

  async beforeModel(): Promise<void> {
    const currentProperty = <ZoneModel>this.modelFor('property');
    if (
      !this.connectInvites.loadUiHooks.lastSuccessful ||
      (<{ args: [string] }>(<unknown>this.connectInvites.loadUiHooks.lastSuccessful)).args[0] !== currentProperty.id
    ) {
      // only load list of available hooks once per property.
      await this.connectInvites.loadUiHooks.perform(currentProperty.id);
    }
  }

  model(): InvitesNewModel {
    // we need to load in a prior state of the model to re-utilize parts if it due to an
    // issue where the router service transition is refreshing the route / model when a transition is
    // aborted or interrupted. We clear the parts of the model when the route is exited without
    // saving changes to ignore the reutilization logic here.
    const model = <InvitesNewModel | undefined>this.modelFor(this.routeName);

    const validations = buildInviteValidations({ isBulk: true });
    const validator = lookupValidator(validations);

    const bulkConnectInvite = model?.bulkConnectInvite ?? this.store.createRecord('bulk-connect-invite');

    const bulkConnectInviteChangeset =
      model?.bulkConnectInviteChangeset ?? Changeset(bulkConnectInvite, validator, validations);

    return {
      bulkConnectInviteChangeset,
      bulkConnectInvite,
      currentProperty: <ZoneModel>this.modelFor('property'),
      loadTenantsTask: (<InvitesModel>this.modelFor('property.visitors.invites')).loadTenantsTask,
      loadPrintersTask: (<InvitesModel>this.modelFor('property.visitors.invites')).loadPrintersTask,
    };
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    const model = <InvitesNewModel>this.modelFor(this.routeName);
    const { bulkConnectInviteChangeset } = model;

    if (transition.to.name === this.routeName) {
      return;
    }

    if (!bulkConnectInviteChangeset?.isDirty) {
      this.rollback();
      return;
    }

    void this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue: () => this.rollback(),
    });
  }

  rollback(): void {
    const model = <InvitesNewModel>this.modelFor(this.routeName);

    if (!model.bulkConnectInviteChangeset || !model.bulkConnectInvite) return;

    model.bulkConnectInvite.rollbackAttributes();
    model.bulkConnectInviteChangeset.rollback();

    model.bulkConnectInviteChangeset = undefined;
    model.bulkConnectInvite = undefined;
  }
}

import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import { belongsTo, hasMany } from '@ember-data/model';
import AbstractSignInFieldPageModel from 'garaje/models/abstract/abstract-sign-in-field-page';
import type FlowModel from 'garaje/models/flow';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('v3-coalesce')
class SignInFieldPageModel extends AbstractSignInFieldPageModel<SignInFieldModel> {
  @belongsTo('flow') declare flow: AsyncBelongsTo<FlowModel>;
  @hasMany('sign-in-field') declare signInFields: AsyncHasMany<SignInFieldModel>;
}
export default SignInFieldPageModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'sign-in-field-page': SignInFieldPageModel;
  }
}

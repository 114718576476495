import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { hash } from 'rsvp';

export default class LocationOverviewEmployeesDirectoryRoute extends Route {
  @service currentLocation;
  @service employeeDirectory;
  @service logger;
  @service router;
  @service state;
  @service abilities;
  @service store;

  async model() {
    let userDocumentTemplateConfigurations, pendingUserDocumentLinks;

    if (this.abilities.can('review user-document-links')) {
      userDocumentTemplateConfigurations = this.store.query('user-document-template-configuration', {
        include: 'user-document-template',
        filter: {
          'location-id': this.state.currentLocation.id,
          active: true,
        },
      });

      pendingUserDocumentLinks = this.store.query('user-document-link', {
        include: 'user-document.user-document-template',
        filter: {
          'company-id': this.state.currentCompany.id,
          'approval-status': 'review',
        },
      });
    }

    const groups = await this.store.findAll('group');

    return hash({
      userDocumentTemplateConfigurations,
      pendingUserDocumentLinks,
      isConnectedToProperty: this.state.currentLocation.isConnectedToProperty(),
      groups,
    });
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.getEmployeeGatingModal.perform();
    this.currentLocation.loadIntegrations.perform();
    controller.resetEmployeesTask.perform();
  }

  deactivate() {
    super.deactivate(...arguments);
    this.controller.resetEmployeesTask.cancelAll();
    this.employeeDirectory.employees = [];
    this.employeeDirectory.employeesCount = 0;
    this.controller.page = 1;
  }

  @action
  resetEmployees() {
    this.controller.resetEmployeesTask.perform();
  }

  @action
  error(error) {
    if (!isEmpty(error.errors) && error.errors[0]?.status === '404') {
      this.router.transitionTo('employees');
    }
    this.logger.error(error);

    return true;
  }

  @action
  resetQueryParams() {
    this.controller.offset = 0;
    this.controller.limit = 50;
    this.controller.name = '';
    this.controller.searchTerm = '';
    this.controller.documentStatusFilter = '';
    this.controller.manuallyAddedFilter = '';
    this.controller.selectedLocationIds = '';
  }
}

import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * @param {Model<Device>}            ipad
 * @param {Boolean}                  isSelected
 * @param {Function}                 unselectIPad
 * @param {Function}                 selectIPad
 */
export default class GlobalDevicesIpadsIpadTile extends Component {
  @action
  onIPadCheckboxClick(event) {
    const ipad = this.args.ipad;

    if (event.target.checked) {
      // add ipad when user select the ipad
      this.args.selectIPad(ipad);
    } else {
      // remove ipad from selected ipad
      this.args.unselectIPad(ipad);
    }
  }
}

/* eslint-disable ember/no-computed-properties-in-native-classes */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action, computed, get, set } from '@ember/object';
import { or, readOnly, uniqBy } from '@ember/object/computed';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import urlBuilder from 'garaje/utils/url-builder';
import moment from 'moment-timezone';

const SIGNED_OUT_FILTERS = [
  { name: 'All visitors' },
  { name: 'Currently signed in', val: 'not-signed-out' },
  { name: 'Signed out', val: 'signed-out' },
];

const EXPORT_FORMATS = [
  {
    name: 'CSV',
    val: 'csv',
    desc: 'Download a CSV file. Data exports as a single sheet; location name will appear in a column.',
  },
  {
    name: 'XLSX',
    val: 'xlsx',
    desc: 'Download an Excel file. Each location’s data will appear on a separate sheet.',
  },
];
export default class VisitorLogTable extends Component {
  @service state;
  @service messageBus;
  @service asyncExportManager;
  @service featureFlags;

  tagName = '';

  showExportModal = false;

  companyId = null;
  @or('sortBy', 'sortDirection', 'locationIds', 'signedOut') isFiltered;
  @readOnly('query') isSearched;
  @uniqBy('availableLocations', 'id') uniqLocations;

  @computed('isFiltered', 'isSearched')
  get noResultMessage() {
    if (this.isFiltered && !this.isSearched) {
      return 'Try changing your filters.';
    } else if (this.isFiltered && this.isSearched) {
      return 'Try adjusting your search or changing your filters.';
    } else if (!this.isFiltered && this.isSearched) {
      return 'Try adjusting your search.';
    }

    return '';
  }

  @computed('uniqLocations')
  get locationOptions() {
    return [this.defaultLocationFilter, ...this.uniqLocations];
  }

  @computed('companyId', 'locationIds')
  get exportIframeUrl() {
    let modalLabel;
    let locationIds = this.locationIds;
    if (locationIds === '') {
      locationIds = this.availableLocations.mapBy('id').join(',');
    }
    const companyId = get(this, 'companyId');
    const defaultEndDate = moment().format('YYYY-MM-DD');
    const defaultStartDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
    const locationCount = locationIds.split(',').length;
    if (this.availableLocations.length === locationCount) {
      modalLabel = 'All locations';
    } else if (locationCount > 1) {
      modalLabel = locationCount + ` locations`;
    } else {
      modalLabel = this.availableLocations.findBy('id', locationIds).name;
    }
    return urlBuilder.embeddedEntryExportModalUrl(
      companyId,
      locationIds,
      null,
      defaultStartDate,
      defaultEndDate,
      modalLabel,
    );
  }

  // eslint-disable-next-line ember/classic-decorator-hooks
  init() {
    super.init(...arguments);
    this.defaultLocationFilter = {
      name: 'All locations',
    };
    this.exportFormats = EXPORT_FORMATS;
    this.selectedExportFormat = EXPORT_FORMATS[0];
    this.signedOutFilters = SIGNED_OUT_FILTERS;
    this.companyId = this.state.currentCompany.id;
    get(this, 'messageBus').on('embedded-app-message', this, this.handleMessage);
  }

  @action
  toggleModal() {
    const modalStatus = get(this, 'showExportModal');
    set(this, 'showExportModal', !modalStatus);
  }

  handleMessage(message) {
    if (message.event === 'showEntryExportModal') {
      this.toggleModal();
    } else if (message.event === 'closeExportModal') {
      this.toggleModal();
    }
  }

  @action
  exportEntries(withFilters) {
    set(this, 'showExportModal', false);

    let startDate = get(this, 'params.filter.startDate') ?? '';
    let endDate = get(this, 'params.filter.endDate') ?? '';

    if (startDate) {
      // Must transform to date object, or Ember model will ignore value
      startDate = moment(startDate).toDate();
    }

    if (endDate) {
      // Must transform to date object, or Ember model will ignore value
      endDate = moment(endDate).toDate();
    }

    let params = {
      sort: '',
      query: '',
      startDate,
      endDate,
      filter: '',
      exportFormat: get(this, 'selectedExportFormat.val') ?? 'csv',
      action_origin: 'location_overview',
    };

    if (!withFilters) {
      return this.asyncExportManager.exportEntriesTask.perform(params);
    }

    params = Object.assign({}, params, {
      sort: get(this, 'params.sort') ?? '',
      query: get(this, 'params.filter.query') ?? '',
      filter: get(this, 'params.filter.status') ?? '',
      exportFormat: get(this, 'selectedExportFormat.val') ?? 'csv',
      locationIds: (get(this, 'params.filter.location') ?? '').split(',').filter((id) => id),
    });

    return this.asyncExportManager.exportEntriesTask.perform(params);
  }

  @action
  clearAllFilters() {
    get(this, 'setQuery')();
    get(this, 'setSignedOut')();
    set(this, 'locationIds', '');
  }

  @action
  resetPageNumber() {
    get(this, 'setPageNumber')(1);
  }

  @action
  selectLocationsFilter(options) {
    let ids = this.locationIds.split(',').filter((el) => isPresent(el));
    options.forEach((option) => {
      ids = ids.includes(option.id) ? ids.filter((id) => id !== option.id) : [...ids, option.id];
    });
    set(this, 'locationIds', ids.join(','));
  }

  @action
  selectSignedOutFilter(signedOutFilter) {
    get(this, 'setSignedOut')(get(signedOutFilter, 'val'));
  }

  @action
  setExportFormat(value) {
    set(this, 'selectedExportFormat', value);
  }
}

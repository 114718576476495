import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';

/**
 * @param {Task}        deleteReservationTask
 * @param {Task}        showDeleteConfirmationTask
 */

export default class DeleteConfirmationModal extends Component {
  @dropTask
  *deleteReservationTask() {
    const { deleteReservationTask, showDeleteConfirmationTask } = this.args;
    yield deleteReservationTask.perform();
    showDeleteConfirmationTask.last.continue();
  }
}

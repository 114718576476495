import { belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import type FlowModel from 'garaje/models/flow';
import adapter from 'garaje/utils/decorators/adapter';
import OnboardingChangesTracker from 'garaje/utils/decorators/onboarding-changes-tracker';

import AbstractBadge from './abstract/abstract-badge';

const OVERWRITABLE_SETTINGS = {
  enabled: 'badge printing',
  css: 'badge CSS',
  notes: 'badge display',
};

@OnboardingChangesTracker({ taskName: 'loadFlowsTask', attributes: ['enabled'] })
@adapter('badge')
class BadgeModel extends AbstractBadge {
  // this is a link to a custom badge style. Set in Boss by customer support.

  @belongsTo('flow') declare flow: AsyncBelongsTo<FlowModel>;
}

BadgeModel.reopenClass({ OVERWRITABLE_SETTINGS });

export default BadgeModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    badge: BadgeModel;
  }
}

import Component from '@glimmer/component';
import { get } from '@ember/object';
import { convertDisplayRoleName } from 'garaje/helpers/display-role-name';

/**
 * Component to display admin role • locations
 * @param {String}          roleName
 * @param {Array<Object}    items
 */
export default class RoleLocations extends Component {
  get isCompanyRole() {
    const { items } = this.args;
    if (items?.length > 0) {
      return items[0].type === 'companies';
    }
    return false;
  }

  get hasItems() {
    return this.args.items?.length > 0;
  }

  // can be locations or zones
  get itemNames() {
    return this.args.items.map((item) => get(item, 'name'));
  }

  get roleNameText() {
    return convertDisplayRoleName([this.args.roleName]);
  }
}

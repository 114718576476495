import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class LocationOverviewAnalyticsProtectUpsellRoute extends Route {
  @service abilities;
  @service router;

  title = 'Protect · Global Analytics · Envoy';

  buildRouteInfoMetadata() {
    return { hideBanners: true };
  }

  redirect() {
    if (this.abilities.can('visit protect in global-analytics')) {
      this.router.transitionTo('location-overview.analytics.protect.index');
    } else if (this.abilities.cannot('visit analytics upsell in global-analytics')) {
      this.router.transitionTo('unauthorized');
    }
  }
}

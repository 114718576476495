import ApplicationSerializer from './application';

export default class EmployeeRegistrationConfigurationSerializer extends ApplicationSerializer {
  attrs = {
    location: {
      serialize: false,
    },
  };

  modelNameFromPayloadKey(key) {
    if (key === 'locations') {
      return 'skinny-location';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }

  payloadKeyFromModelName(key) {
    if (key === 'skinny-location') {
      return 'locations';
    } else {
      return super.payloadKeyFromModelName(...arguments);
    }
  }
}

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';

export default class ContactBDRModal extends Component {
  @service chiliPiper;
  @service state;
  @service flashMessages;
  @service impressions;

  companySizes = [
    '1 - 49 employees',
    '50 - 250 employees',
    '251 - 1500 employees',
    '1501 - 5000 employees',
    '5001+ employees',
  ];
  companyRegions = ['North America', 'South/Central America', 'Europe', 'Asia', 'Africa', 'Middle East', 'Australia'];

  bannedEmails = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com', 'icloud.com'];

  @tracked currentPage = 1;
  @tracked formData = {
    FirstName: '',
    LastName: '',
    Company: '',
    Email: '',
    Phone: '',
    Employee_Range__c: this.companySizes[0],
    Inbound_Form_Region__c: this.companyRegions[0],
    Contact_Form_Message__c: 'Growth Team Dashboard Lead',
  };

  constructor() {
    super(...arguments);
    this.setupInfo();
    this.logModalOpened.perform();
  }

  submitForm = task({ drop: true }, async () => {
    if (
      !this.formData.Email.includes('@') ||
      !this.formData.Email.includes('.com') ||
      this.bannedEmails.some((email) => this.formData.Email.endsWith(email))
    ) {
      this.flashMessages.showFlash('error', 'Please enter a valid company email.');
    } else {
      const newWindow = window.open('', '_blank');
      newWindow.document.write('Loading Meeting Details...');
      try {
        const response = await this.chiliPiper.getMeetingUrl.perform(this.formData);
        if (response.url) {
          newWindow.location = response.url;
          if (this.args.trackFormSubmitted) {
            this.args.trackFormSubmitted();
          }
          if (this.args.openConfirmationModal) {
            this.args.openConfirmationModal();
          } else {
            this.args.close();
          }
          await this.impressions.postImpression.perform(IMPRESSION_NAMES.CHILI_PIPER_MEETING_URL_OPENED);
        } else {
          newWindow.close();
          this.flashMessages.showFlash('error', 'Form data is incorrect. Please check your responses.');
        }
      } catch (e) {
        this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
      }
    }
  });

  logModalOpened = task({ drop: true }, async () => {
    try {
      await this.impressions.postImpression.perform(IMPRESSION_NAMES.CHILI_PIPER_MODAL_OPENED);
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
    }
  });

  @action
  setupInfo() {
    this.formData.Email = this.state.currentUser.email;
    this.formData.Phone = this.state.currentUser.phoneNumber;
    this.formData.Company = this.state.currentCompany.name;
    this.formData.FirstName = this.state.currentUser.firstName;
    this.formData.LastName = this.state.currentUser.lastName;
  }
}

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { type BadgeOption, type VariableMap } from 'garaje/pods/components/badges/badge-display-config/component';

import { type BadgesRouteModel } from './route';

type BackToOption = 'printers';

export default class PropertySettingsBadgesController extends Controller {
  queryParams = ['backTo'];

  get brandingOptions(): BadgeOption<string>[] {
    const { currentProperty } = <BadgesRouteModel>this.model;
    const brandingOptions: BadgeOption<string>[] = [
      {
        label: 'Tenant name',
        value: 'location_name',
      },
      {
        label: 'Tenant logo',
        value: 'location_logo',
      },
      {
        label: 'Property name',
        value: 'property_name',
      },
    ];

    if (currentProperty.logo) {
      brandingOptions.push({
        label: 'Property logo',
        value: 'property_logo',
      });
    }

    return brandingOptions;
  }

  extraFieldsOptions: BadgeOption<string>[] = [
    {
      label: 'Tenant name',
      value: 'location_name',
    },
    {
      label: 'Floor name',
      value: 'floor_name',
    },
    {
      label: 'Suite name',
      value: 'suite_name',
    },
    {
      label: 'Host',
      value: 'host_name',
    },
    {
      label: 'Group name',
      value: 'group_name',
    },
    {
      label: 'Property walk-in',
      value: 'property_walk_in',
    },
  ];

  get badgeVariables(): VariableMap {
    const { currentProperty } = <BadgesRouteModel>this.model;

    const badgeVariables: VariableMap = {
      groupName: '[Group name]',
      locationName: '[Tenant name]',
      hostName: '[Host name]',
      propertyName: currentProperty.name,
      locationLogoUrl: '/assets/images/connect/tenant-logo-placeholder.svg',
      propertyWalkIn: '[Property walk-in]',
      floorName: '[Floor name]',
      suiteName: '[Suite name]',
    };

    if (currentProperty.logo) badgeVariables['propertyLogoUrl'] = currentProperty.logo;

    return badgeVariables;
  }

  @tracked backTo?: BackToOption;

  get backToLink(): string | undefined {
    return this.backTo
      ? {
          printers: 'devices.printers',
        }[this.backTo]
      : undefined;
  }

  @action
  _redirectToEnvoy(path: string): void {
    this.send('redirectToEnvoy', path);
  }
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { DetailedChangeset } from 'ember-changeset/types';
import type { Task } from 'ember-concurrency';
import { type Select } from 'ember-power-select/components/power-select';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type ConnectFloorModel from 'garaje/models/connect-floor';
import type ZoneModel from 'garaje/models/zone';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';

interface PropertiesSuiteFormComponentArgs {
  /**
   * Array of floors that can selected from.
   * Only populated when "connect-floors" feature flag is on.
   */
  floors?: PaginatedRecordArray<ConnectFloorModel>;
  /**
   * A changeset for the suite that's being created/edited
   */
  suiteChangeset: DetailedChangeset<ZoneModel>;
  /**
   * Optional label for submit button; if not present, defaults to "Create" when @suiteChangeset.isNew is true and "Save" otherwise
   */
  saveButtonLabel?: string;
  /**
   * Task to be performed to save suite
   */
  saveTask: Task<[], [void]>;
}

export default class PropertiesSuiteFormComponent extends Component<PropertiesSuiteFormComponentArgs> {
  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;
  @service declare store: StoreService;

  @tracked enteredFloorName: string = '';

  // this is intentionally not `@tracked` so that it represents only the initial state of the `@suiteChangeset`.
  // Since we potentially use this to determine the label for the "Save" button we don't want
  // it to change from "Create" to "Save" while the form is still displayed after clicking the button.
  suiteIsNew = <boolean>(<unknown>this.args.suiteChangeset.isNew);

  get floorOptions(): { index: number; floor: ConnectFloorModel }[] {
    return this.args.floors?.map((floor, index) => ({ index, floor })) ?? [];
  }

  // Label text to use for the "Save" button. If a `@saveButtonLabel` argument is given, use that.
  // Otherwise, default to "Create" if the given `@suiteChangeset` is new, or "Save" if it is already saved.
  get saveButtonLabel(): string {
    if (this.args.saveButtonLabel) return this.args.saveButtonLabel;
    if (this.args.saveTask.isRunning) return 'Saving...';
    if (this.suiteIsNew) return 'Create';
    return 'Save';
  }

  get hasAllRequiredFields(): boolean {
    const suite = this.args.suiteChangeset;
    return isPresent(suite.name) && isPresent(suite.areaSqFt);
  }

  get isSaveButtonDisabled(): boolean {
    const suiteChangeset = this.args.suiteChangeset;
    return (
      this.args.saveTask.isRunning || !this.hasAllRequiredFields || !suiteChangeset.isDirty || !suiteChangeset.isValid
    );
  }

  /**
   * Determine whether the option to add a new floor should be included in the Floor dropdown.
   * It is shown if the user has typed something, and the typed input is not an exact match to the name of an existing floor.
   */
  get showAddFloorOption(): boolean {
    return (
      isPresent(this.enteredFloorName) &&
      !this.args.floors?.find((floor) => floor.name === this.enteredFloorName.trim())
    );
  }

  /**
   * Action to handle the `did-insert` modifier to focus the input
   */
  @action
  focusInput(element: HTMLElement): void {
    const inputElement = element.querySelector<HTMLInputElement>('[data-selector="form-suite-input"]');
    inputElement?.focus();
  }

  /**
   * Create a new floor with the given name, to be saved when the suite is saved.
   */
  @action
  createFloor(name: string, select: Select): void {
    const floor = this.store.createRecord('connect-floor', {
      name,
      company: this.state.currentCompany,
      property: this.args.suiteChangeset.parent?.content,
    });

    this.selectFloor({ floor });
    select.actions.close();
  }

  @action
  selectFloor({ floor }: { floor: ConnectFloorModel }): void {
    const suite = this.args.suiteChangeset;
    const oldFloor = <ConnectFloorModel>suite.floor;
    if (oldFloor && <boolean>(<unknown>oldFloor.isNew)) {
      oldFloor.unloadRecord();
    }

    suite.floor = floor;
  }

  @action
  updateFloorName(name: string): void {
    this.enteredFloorName = name;
  }
}

import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';

export default class EmailPreviewComponent extends Component {
  get htmlDocument() {
    const { htmlDocument } = this.args;
    if (isPresent(htmlDocument)) {
      const parser = new DOMParser();
      const serializer = new XMLSerializer();
      const doc = parser.parseFromString(htmlDocument, 'text/html');
      const headElm = doc.querySelector('head');
      const baseElm = doc.createElement('base');
      baseElm.setAttribute('target', '_blank');
      headElm.appendChild(baseElm);
      return serializer.serializeToString(doc);
    }
    return '';
  }
}

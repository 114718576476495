import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { capitalize } from '@ember/string';
import Component from '@glimmer/component';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';

interface AutoCheckInIntegrationLinkArgs {
  pluginPath: string;
}

/**
 * @param {Number}  pluginPath
 */
// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class AutoCheckInIntegrationLink extends Component<AutoCheckInIntegrationLinkArgs> {
  @service declare router: RouterService;
  @service declare workplaceMetrics: WorkplaceMetricsService;

  @action
  trackAccessControlSetupClicked(): void {
    this.workplaceMetrics.trackEvent('WORKPLACE_EMPLOYEE_REGISTRATION_ACCESS_CONTROL_SETUP_CLICKED', {
      pluginPath: this.args.pluginPath,
    });
  }

  get pluginAppStoreRoute(): string {
    return `/apps/${this.args.pluginPath}`;
  }

  get pluginTitle(): string {
    return capitalize(this.args.pluginPath.replaceAll('-', ' '));
  }
}

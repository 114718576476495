import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { sort } from '@ember/object/computed';
import Service, { service } from '@ember/service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { enqueueTask, all, dropTask } from 'ember-concurrency';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type LocationModel from 'garaje/models/location';
import type LocalStorageService from 'garaje/services/local-storage';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { LOCATION_ADMIN } from 'garaje/utils/roles';
import { gt, filterBy } from 'macro-decorators';

import type CurrentAdminService from './current-admin';

const LOCATIONS_TO_CACHE_AS_RECENT = 5;
const LOCATIONS_PAGE_SIZE = 20;

export default class LocationsService extends Service {
  @tracked model: LocationModel[] = [];

  @service declare store: Store;
  @service declare localStorage: LocalStorageService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;
  @service declare metrics: MetricsService;

  sortProperties = ['sortablePrimary', 'nameWithCompanyName'];
  @sort('model', 'sortProperties') content!: NativeArray<LocationModel>;

  loadAllTask = dropTask(async (forceReload = false) => {
    if (this.loadAllTask.lastSuccessful && !forceReload) {
      return this.model;
    }

    const fetchLocation = <PaginatedRecordArray<LocationModel>>(
      await this.store.query('location', { offset: 0, limit: LOCATIONS_PAGE_SIZE })
    );
    const total = fetchLocation.meta?.total || 0;
    const pageLoaderTasks = [];

    for (let i = LOCATIONS_PAGE_SIZE; i < total; i = i + LOCATIONS_PAGE_SIZE) {
      pageLoaderTasks.push(this.loadPageTask.perform(i));
    }

    await all(pageLoaderTasks);

    this.model = this.store.peekAll('location').toArray();

    return;
  });

  loadPageTask = enqueueTask(
    {
      maxConcurrency: 5,
    },
    async (offset: number = 0, limit: number = LOCATIONS_PAGE_SIZE) => {
      const query = { offset, limit };
      const locations = await this.store.query('location', query);

      return locations.toArray();
    },
  );

  /**
   * Returns all locations where the user has a location role or where
   * the location belongs to a company where the user is Global Admin or
   * Billing.
   */
  @computed('allPersisted.[]', 'currentAdmin.{user.id,companyRoles.[],locationRoles.[]}')
  get readableByCurrentAdmin(): LocationModel[] {
    if (!this.currentAdmin.user?.id) {
      return [];
    }
    const companiesWhereUserHasCompanyRole = this.currentAdmin.companyRoles
      .filter(Boolean)
      .map((companyRole) => companyRole.belongsTo('company').id());
    const locationsWhereUserHasLocationRole = this.currentAdmin.locationRoles
      .filter(Boolean)
      .map((locationRole) => locationRole.belongsTo('location').id());

    return this.allPersisted.filter((location) => {
      return (
        companiesWhereUserHasCompanyRole.includes(location.belongsTo('company').id()) ||
        locationsWhereUserHasLocationRole.includes(location.id)
      );
    });
  }

  /**
   * Returns all locations where the user has a location admin role,
   */
  @computed('allPersisted.[]', 'currentAdmin.{user.id,locationRoles.[]}')
  get readableByLocationAdmin(): LocationModel[] {
    if (!this.currentAdmin.user?.id) {
      return [];
    }
    const locationIds = this.currentAdmin.locationRoles.reduce<string[]>((ids, locationRole) => {
      if (locationRole.roleName === LOCATION_ADMIN) {
        ids.push(locationRole.belongsTo('location').id());
      }
      return ids;
    }, []);
    const locations = this.allPersisted.filter((location) => locationIds.includes(location.id));
    return locations;
  }

  @computed('readableByCurrentAdmin.[]', 'state.currentCompany.id')
  get currentCompanyLocations(): LocationModel[] {
    const { currentCompany } = this.state;

    if (!currentCompany) {
      return [];
    }

    return this.readableByCurrentAdmin.filter(function (location) {
      return location.belongsTo('company').id() === currentCompany.id;
    });
  }

  @filterBy('currentCompanyLocations', 'isNew', false) persisted!: LocationModel[];
  @filterBy('persisted', 'disabled', false) active!: LocationModel[];
  @filterBy('content', 'isNew', false) allPersisted!: LocationModel[];
  @filterBy('allPersisted', 'disabled', false) allActive!: LocationModel[];

  @gt('model.length', 1) hasMultipleLocations!: boolean;

  /*
   * Reads localStorage and returns an object where
   * We store recent locations by `{ companyId: [array, of, location, ids] }`
   */
  #readRecentLocations(): Record<string, string[]> {
    const recentLocationsIdsString = this.localStorage.getItem('recentLocations') ?? '{}';
    return <Record<string, string[]>>JSON.parse(recentLocationsIdsString);
  }

  /**
   * Writes localStorage updating a given company with new locations
   * Where locations are `{ companyId: [array, of, location, ids] }`
   */
  #writeRecentLocations(locations: string[], companyId: string) {
    const recentLocationsIds = this.#readRecentLocations();
    recentLocationsIds[companyId] = locations;
    this.localStorage.setItem('recentLocations', JSON.stringify(recentLocationsIds));
  }

  /**
   * Marks location as recent for a given location and company
   */
  markAsRecentlyVisited(locationId: string, companyId: string): void {
    let recentLocationsIds = this.recentLocationsIds;
    recentLocationsIds = [locationId, ...recentLocationsIds];
    recentLocationsIds = A(recentLocationsIds).uniq();
    recentLocationsIds = recentLocationsIds.slice(0, LOCATIONS_TO_CACHE_AS_RECENT);
    this.#writeRecentLocations(recentLocationsIds, companyId);
  }

  /**
   * Array of recently visit location ids for the current company
   */
  get recentLocationsIds(): string[] {
    const { currentCompany } = this.state;
    const recentLocations = this.#readRecentLocations();
    return recentLocations[`${currentCompany?.id}`] || [];
  }

  /**
   * Array of recently visited locations for the current company
   */
  @computed('recentLocationsIds.[]', 'readableByCurrentAdmin')
  get recentLocations(): LocationModel[] {
    const allLocations = this.readableByCurrentAdmin;
    const recentLocationIds = this.recentLocationsIds;
    const idToLocation = function (id: string) {
      return allLocations.find((location) => location.id === id);
    };

    return recentLocationIds.map((id) => idToLocation(id)).filter((location) => location !== undefined);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    locations: LocationsService;
  }
}

import type NativeArray from '@ember/array/-private/native-array';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import type CopyFlowModel from 'garaje/models/copy-flow';
import type FlowModel from 'garaje/models/flow';
import type GlobalFlowModel from 'garaje/models/global-flow';
import type ChameleonService from 'garaje/services/chameleon';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type SetupGuideStepperService from 'garaje/services/setup-guide-stepper';
import type StateService from 'garaje/services/state';
import { APP } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { gte } from 'macro-decorators';

import type { VisitorTypesNewRouteModel } from './route';

interface FlowGroup {
  groupName: string;
  options: NativeArray<GlobalFlowModel> | CopyFlowModel[];
}

export default class VisitorTypesNewController extends Controller {
  declare model: VisitorTypesNewRouteModel;

  @service declare chameleon: ChameleonService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;
  @service('setup-guide-stepper') declare setupGuideStepperService: SetupGuideStepperService;
  @service declare state: StateService;
  @service declare store: StoreService;

  copyFlowsSortProperties = ['companyName', 'locationName', 'name'];
  copyGlobalFlowsSortProperties = ['name'];

  @tracked copyFlowType: string | null = null;

  // allFlows is a Class, not a plain JS array. @notEmpty does not work as expected
  @gte('model.allFlows.length', 1) hasAnyVisitorFlows!: boolean;

  get sortedFlows(): NativeArray<CopyFlowModel> {
    return this.model.allFlows.sortBy(...this.copyFlowsSortProperties);
  }

  get sortedGlobalFlows(): NativeArray<GlobalFlowModel> {
    return this.model.globalFlows.sortBy(...this.copyGlobalFlowsSortProperties);
  }

  get groupedCopyFlows(): FlowGroup[] {
    return [
      {
        groupName: 'Global sign-in flows',
        options: this.sortedGlobalFlows,
      },
      {
        groupName: 'Location sign-in flows',
        // @ts-ignore
        options: this.sortedFlows.filter((flow) => flow.constructor.modelName !== 'copy-global-flow'),
      },
    ];
  }

  get isAllowedMoreVisitorTypes(): boolean {
    const isAllowedMultipleVisitorTypes = !!this.state.features?.canAccessMultipleVisitorTypes;
    const { hasAnyVisitorFlows } = this;

    return isAllowedMultipleVisitorTypes || !hasAnyVisitorFlows;
  }

  locationAndFlowNameMatcher(copyFlow: CopyFlowModel, term: string): number {
    const companyName = copyFlow.companyName;
    const locationName = copyFlow.locationName;
    const flowName = copyFlow.name;
    const string = `${companyName} ${locationName}: ${flowName}`.toLowerCase();

    return string.indexOf(term.toLowerCase());
  }

  get selectedCopyFlow(): CopyFlowModel | GlobalFlowModel | undefined {
    const blueprintId = this.model.changeset.blueprint;
    if (this.copyFlowType === 'global') {
      return this.model.globalFlows.find((flow) => flow.id.toString() === blueprintId?.toString());
    }

    return this.model.allFlows.find((flow) => flow.id.toString() === blueprintId?.toString());
  }

  saveTask = dropTask(async () => {
    const changeset = this.model.changeset;
    try {
      await changeset.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.chameleon.trackDashboardPageEvent('New Visitor Type Saved');

      if (this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper')) {
        void this.setupGuideStepperService.loadSetupStepsTask.perform(APP.VISITORS);
      }

      void this.transitionToRoute('visitors.settings.visitor-types.flow', changeset.id); // eslint-disable-line ember/no-deprecated-router-transition-methods
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
      changeset.rollback();
    }
  });

  @action
  updateBlueprint(flow: FlowModel): void {
    const changeset = this.model.changeset;

    if (flow) {
      this.copyFlowType = flow.isGlobal ? 'global' : 'local';
      changeset.blueprint = Number(flow.id);
    } else {
      changeset.blueprint = null;
    }
  }

  @action
  logUpsellViewed(): void {
    this.metrics.trackEvent('CTA Viewed', this.ctaEventProperties);
  }

  @action
  logUpsellClicked(): void {
    this.metrics.trackEvent('CTA Clicked', this.ctaEventProperties);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get ctaEventProperties() {
    return {
      cta_id: 'multiple_visitor_types_upsell',
      cta_type: 'page',
      cta_clickable_type: 'button',
      cta_clickable_text: 'Upgrade to Premium',
      cta_title: this.upsellHeader,
      cta_body: this.upsellBody,
      cta_intent: 'upgrade',
    };
  }

  upsellHeader = 'Add visitor types';
  upsellBody =
    'Customize the sign-in experience based on a guest’s purpose of visit. Present different sign-in fields or a different NDA; configure photo taking, badge printing and more.';
}

export default {
  badges: 'visitors.settings.visitor-types.flow.badge',
  deliveriesTutorial: 'deliveries.log.tutorial',
  deliveriesAdmin: 'manage.admin-users',
  deliveryNotifications: 'deliveries.settings.notifications',
  deliveryNotificationSchedule: 'deliveries.settings.delivery-areas.delivery-area.notifications',
  deliveryPickUp: 'deliveries.settings.delivery-areas.delivery-area.pick-up',
  devices: 'visitors.devices.ipads.new',
  directoryIntegrations: 'visitors.settings.integrations',
  employeesImport: 'employees.directory',
  visitorsEmployeesImport: 'employees.directory',
  entries: 'visitors.entries.index',
  fields: 'visitors.settings.visitor-types.flow.sign-in-fields',
  hostNotifications: 'visitors.settings.notifications',
  nda: 'visitors.settings.visitor-types.flow.legal-documents',
  newPrinter: 'visitors.devices.printers.new',
  nicknames: 'deliveries.settings.nicknames',
  notificationIntegrations: 'integrations',
  photo: 'visitors.settings.visitor-types.flow.visitor-photos',
  sampleDeliveryLabel: 'deliveries.log.tutorial',
  settings: 'location-settings',
  roomsSettings: 'roomba.settings',
  roomsCalendars: 'roomba.settings.calendars',
  roomsList: 'roomba.list',
  newInvite: 'visitors.invites.new',
};

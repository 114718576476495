import ApplicationSerializer from './application';

export default class EmployeeGroupRoleSerializer extends ApplicationSerializer {
  serialize(snapshot) {
    const json = super.serialize(...arguments);

    if (snapshot.id) {
      delete json.data.relationships['employee-group'];
    }

    return json;
  }
}

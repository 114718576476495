import Route from '@ember/routing/route';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { findAllDeliveryAreasForLocation } from 'garaje/utils/delivery-area';

export default class DeliveriesLogRoute extends Route {
  @service abilities;
  @service state;
  @service router;
  @service store;

  beforeModel() {
    if (this.abilities.cannot('visit delivery-log')) {
      return this.router.transitionTo('deliveries.index');
    }

    const currentLocation = get(this, 'state.currentLocation');

    return findAllDeliveryAreasForLocation(this.store, currentLocation).then((deliveryAreas) => {
      if (get(deliveryAreas, 'length') === 0) {
        return this.router.transitionTo('deliveries.get-started');
      }
    });
  }

  setupController(_controller, _model) {
    super.setupController(...arguments);
    // TODO: Double check usage of this prop in service
    set(this.state, 'showDeliveriesOnboarding', true);
  }
}

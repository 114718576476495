// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { equal, or, alias } from '@ember/object/computed';
import type { AsyncHasMany } from '@ember-data/model';
import Model, { attr, hasMany } from '@ember-data/model';
import type EntryModel from 'garaje/models/entry';
import { categoryName } from 'garaje/utils/integration-categories';

import type InviteModel from './invite';

interface PlatformJobAttachment {
  type: string;
  label: string;
  value: string;
}

type PlatformJobStatus = 'queued' | 'in_progress' | 'failed' | 'done' | 'ignored' | 'expired';

class PlatformJobModel extends Model {
  @hasMany('entry') declare entry: AsyncHasMany<EntryModel>;
  @hasMany('invite') declare invite: AsyncHasMany<InviteModel>;

  @attr('string') declare pluginName: string; // meraki, dropbox,
  @attr('string') declare pluginCategory: string; // wi-fi, crm
  @attr() declare attachments: PlatformJobAttachment[];

  @attr('string') declare name: string;

  @attr('string') declare status: PlatformJobStatus;
  @attr('string') declare statusMessage: string; // detailed success - need to be audited
  @attr('string') declare failureReason: string; // detailed error - need to be audited

  @attr('date') declare startedAt: Date;
  @attr('date') declare endedAt: Date;

  @attr('string') declare identifier: string;

  // computed properties
  @equal('status', 'queued') queued!: boolean;
  @equal('status', 'in_progress') inProgress!: boolean;
  @equal('status', 'ignored') ignored!: boolean;
  @equal('status', 'done') done!: boolean;
  @equal('status', 'failed') failed!: boolean;
  @equal('status', 'expired') expired!: boolean;

  @or('done', 'failed', 'expired', 'ignored') hasTerminalStatus!: boolean;
  @or('failed', 'expired', 'ignored') didNotSend!: boolean;

  @alias('pluginName') title!: string;

  get sourceId(): string {
    return `platform-job/${this.id}`;
  }

  get pluginCategoryName(): string {
    return categoryName(this.pluginCategory);
  }
}

export default PlatformJobModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'platform-job': PlatformJobModel;
  }
}

import RoomFragment from 'garaje/graphql/fragments/roomba/RoomFragment';
import gql from 'graphql-tag';

export default gql`
  mutation DeleteRoomBackgroundImage($roomId: ID!) {
    deleteRoomBackgroundImage(roomId: $roomId) {
      ...RoomFragment
    }
  }
  ${RoomFragment}
`;

import Controller from '@ember/controller';
import { service } from '@ember/service';
import urlBuilder from 'garaje/utils/url-builder';

export default class AnalyticsProtectIndexController extends Controller {
  @service state;
  /**
   * @return {String}
   */
  get iframeUrl() {
    const {
      currentCompany: { id: companyId },
      currentLocation: { id: locationId },
    } = this.state;
    return urlBuilder.embeddedProtectLocationAnalyticsUrl(companyId, locationId);
  }
}

import Route from '@ember/routing/route';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import validateConditional from 'garaje/validators/conditional';
import { get, setProperties } from '@ember/object';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import { validatePresence, validateFormat } from 'ember-changeset-validations/validators';
import { routeEvent } from 'garaje/utils/decorators/route';

function buildValidations() {
  return {
    videoUrl: validateConditional({
      if(key, newValue, oldValue, { kind }, model) {
        return (kind || get(model, 'kind')) === 'video';
      },
      then: validateFormat({
        type: 'url',
        allowBlank: false,
      }),
      else() {
        // Mark this field as valid, We don't need to validate since we
        // don't use the location value
        return true;
      },
    }),
    message: validateConditional({
      if(key, newValue, oldValue, { kind }, model) {
        return (kind || get(model, 'kind')) === 'message';
      },
      then: validatePresence(true),
      else() {
        // Mark this field as valid, We don't need to validate since we
        // don't use the location value
        return true;
      },
    }),
  };
}

export default class VisitorsSettingsVisitorTypesFlowFinalScreenRoute extends Route {
  @service transitionConfirm;

  model() {
    const { currentLocation, flow } = this.modelFor('visitors.settings.visitor-types.flow');
    const summaryPage = get(flow, 'summaryPage');
    const changeset = summaryPage.then((p) => this._buildChangeset(p));

    return hash({
      currentLocation,
      changeset,
      summaryPage,
      flow,
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      setProperties(controller, {
        messageType: '',
        isEditingCustomFinalMessage: false,
      });
    }
  }

  _buildChangeset(summaryPage) {
    const validations = buildValidations(this);
    const validator = lookupValidator(validations);
    return new Changeset(summaryPage, validator, validations);
  }

  @routeEvent
  routeWillChange(transition) {
    const { changeset } = this.controller.model;
    const confirmTask = this.transitionConfirm.displayConfirmTask;

    if (get(changeset, 'isDirty')) {
      confirmTask.perform(transition, {
        continue() {
          changeset.rollback();
        },
      });
    }
  }
}

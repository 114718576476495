import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { localCopy } from 'tracked-toolbox';

/**
 * @param {String}                              forType
 * @param {Boolean}                             wantsToUpdateSettings
 * @param {Model<GlobalSettingBatch>}           globalSettingBatch
 * @param {Task}                                overwriteTask
 * @param {Array}                               selectedChildren
 * @param {Function}                            updateSelectedChildren
 * @param {Function}                            close
 */
export default class PropagableSettingsChangedModal extends Component {
  @localCopy('args.forType', 'locations') forType;

  @tracked currentStep = 'searcher';

  get searcherComponentName() {
    return `propagable-settings-changed/modal/${this.forType}-searcher`;
  }

  @action
  nextToConfirm() {
    this.currentStep = 'confirm';
  }

  @action
  backToSearcher() {
    this.currentStep = 'searcher';
  }
}

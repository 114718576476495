export interface JsonError {
  status: number;
  statusText: string;
  message?: string;
  payload?: unknown;
  response: Response;
}

export interface ParsedResponse {
  status: number;
  ok: boolean;
  json?: unknown;
  text?: string;
}

/**
 * Determine if a string is JSON or not
 * @param str The string to check for JSON formatting
 */
export function isJsonString(str: string): boolean {
  try {
    const json = <unknown>JSON.parse(str);
    return typeof json === 'object';
  } catch (_e) {
    return false;
  }
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  response A response from a network request
 * @return The parsed JSON, status from the response
 */
export async function parseJSON(response: Response): Promise<ParsedResponse | JsonError> {
  // eslint-disable-next-line ember/use-ember-get-and-set
  const responseType = response.headers.get('content-type') || 'Empty Content-Type';
  let error: JsonError = {
    status: response.status,
    statusText: response.statusText,
    response: response,
  };

  if (!response.ok && responseType.includes('json')) {
    const errorBody = await response.text();
    error.message = errorBody;
  }

  if (responseType.includes('json')) {
    try {
      const json: unknown = await response.json();
      if (response.ok) {
        return {
          status: response.status,
          ok: response.ok,
          json,
        };
      } else {
        error = Object.assign({}, json, error);
        return error;
      }
    } catch (err) {
      if (error.message && isJsonString(error.message)) {
        error.payload = JSON.parse(error.message);
      } else {
        error.payload = error.message || (<Error>err).toString();
      }

      error.message = error.message || (<Error>err).toString();
      return error;
    }
  } else {
    try {
      const text = await response.text();

      if (!response.ok) {
        error.payload = text || 'Internal Server Error';
        return error;
      }

      const parsedResponse: ParsedResponse = {
        status: response.status,
        ok: response.ok,
      };
      if (isJsonString(text)) {
        parsedResponse.json = JSON.parse(text);
      } else {
        parsedResponse.text = text;
      }

      return parsedResponse;
    } catch (err) {
      if (error.message && isJsonString(error.message)) {
        error.payload = JSON.parse(error.message);
      } else {
        error.payload = error.message || (<Error>err).toString();
      }

      error.message = error.message || (<Error>err).toString();

      return error;
    }
  }
}

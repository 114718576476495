import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import type FeatureConfigService from 'garaje/services/feature-config';
import type StateService from 'garaje/services/state';
import { RoleType, RoleId } from 'garaje/utils/custom-roles';
import type { Role } from 'garaje/utils/custom-roles';
import { Permission } from 'garaje/utils/ui-permissions';

class CustomRoleAbility extends Ability {
  @service declare authz: AuthzService;
  @service declare state: StateService;
  @service declare featureConfig: FeatureConfigService;

  @tracked role: Role | undefined = undefined;

  get canView(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.USER_MANAGEMENT_CUSTOM_ROLE_READ);
  }

  get canViewOnGlobal(): boolean {
    return this.authz.hasPermissionAtCurrentCompany(Permission.USER_MANAGEMENT_CUSTOM_ROLE_READ);
  }

  get canCreateRole(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.USER_MANAGEMENT_CUSTOM_ROLE_CREATE);
  }

  get canCreate(): boolean {
    return (
      this.hasManageCustomRolesFeature &&
      this.authz.hasPermissionAtCurrentLocation(Permission.USER_MANAGEMENT_CUSTOM_ROLE_CREATE)
    );
  }

  get canCreateOnGlobal(): boolean {
    return (
      this.hasManageCustomRolesFeature &&
      this.authz.hasPermissionAtCurrentCompany(Permission.USER_MANAGEMENT_CUSTOM_ROLE_CREATE)
    );
  }

  get canEdit(): boolean {
    if (!this.role || this.role.type === RoleType.DEFAULT || this.role.id === RoleId.ANALYTICS_VIEWER) {
      return false;
    }

    return (
      this.hasManageCustomRolesFeature &&
      this.authz.hasPermissionAtCurrentLocation(Permission.USER_MANAGEMENT_CUSTOM_ROLE_UPDATE)
    );
  }

  get canEditOnGlobal(): boolean {
    if (!this.role || this.role.type === RoleType.DEFAULT || this.role.id === RoleId.ANALYTICS_VIEWER) {
      return false;
    }

    return (
      this.hasManageCustomRolesFeature &&
      this.authz.hasPermissionAtCurrentCompany(Permission.USER_MANAGEMENT_CUSTOM_ROLE_UPDATE)
    );
  }

  get canDelete(): boolean {
    if (!this.role || this.role.type === RoleType.DEFAULT || this.role.id === RoleId.ANALYTICS_VIEWER) {
      return false;
    }

    return (
      this.hasManageCustomRolesFeature &&
      this.authz.hasPermissionAtCurrentLocation(Permission.USER_MANAGEMENT_CUSTOM_ROLE_DELETE)
    );
  }

  get canDeleteOnGlobal(): boolean {
    if (!this.role || this.role.type === RoleType.DEFAULT || this.role.id === RoleId.ANALYTICS_VIEWER) {
      return false;
    }

    return (
      this.hasManageCustomRolesFeature &&
      this.authz.hasPermissionAtCurrentCompany(Permission.USER_MANAGEMENT_CUSTOM_ROLE_DELETE)
    );
  }

  get canOpenEdit(): boolean {
    return (
      this.hasManageCustomRolesFeature &&
      this.authz.hasPermissionAtCurrentLocation(Permission.USER_MANAGEMENT_CUSTOM_ROLE_UPDATE)
    );
  }

  get canOpenEditOnGlobal(): boolean {
    return (
      this.hasManageCustomRolesFeature &&
      this.authz.hasPermissionAtCurrentCompany(Permission.USER_MANAGEMENT_CUSTOM_ROLE_UPDATE)
    );
  }

  get hasManageCustomRolesFeature(): boolean {
    return this.featureConfig.isEnabled('manageCustomRoles');
  }
}

export default CustomRoleAbility;

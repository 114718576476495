export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  Long: number;
  URL: any;
  _Any: any;
  _FieldSet: any;
};

export type BeaconIdInput = {
  major: Scalars['Int'];
  minor: Scalars['Int'];
};

export type BeaconInput = {
  id: BeaconIdInput;
  proximity: BeaconProximity;
  rssi: Scalars['Int'];
};

export enum BeaconProximity {
  Far = 'far',
  Immediate = 'immediate',
  Near = 'near',
  Unknown = 'unknown'
}

export type BillingRoomsSubscription = {
  __typename?: 'BillingRoomsSubscription';
  cancelled: Scalars['Boolean'];
  features: Array<Scalars['String']>;
  quantity: Scalars['Int'];
};

export enum BluetoothStatusType {
  Allowed = 'ALLOWED',
  Denied = 'DENIED',
  Unknown = 'UNKNOWN'
}

export type Booking = {
  __typename?: 'Booking';
  endTime: Scalars['DateTime'];
  room: Room;
  startTime: Scalars['DateTime'];
};

export type Building = {
  __typename?: 'Building';
  address: Maybe<Scalars['String']>;
  companyId: Scalars['ID'];
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  latitude: Maybe<Scalars['String']>;
  longitude: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  postalCode: Maybe<Scalars['String']>;
  region: Maybe<Scalars['String']>;
};

export type BuildingFilterOption = {
  __typename?: 'BuildingFilterOption';
  floors: Array<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  attendeeEmails: Maybe<Array<Scalars['String']>>;
  checkIn: Maybe<CheckIn>;
  description: Maybe<Scalars['String']>;
  endDatetime: Scalars['DateTime'];
  id: Scalars['Long'];
  organizerEmail: Maybe<Scalars['String']>;
  originalEndTimezone: Scalars['String'];
  originalStartTimezone: Scalars['String'];
  room: Maybe<Room>;
  roomId: Scalars['String'];
  startDatetime: Scalars['DateTime'];
  summary: Maybe<Scalars['String']>;
};

export type CalendarEventInput = {
  attendeeEmails: InputMaybe<Array<Scalars['String']>>;
  description: InputMaybe<Scalars['String']>;
  endDatetime: Scalars['DateTime'];
  organizer: InputMaybe<Scalars['String']>;
  roomId: Scalars['ID'];
  startDatetime: Scalars['DateTime'];
  summary: InputMaybe<Scalars['String']>;
  timezone: Scalars['String'];
};

export type CalendarEventsFilterInput = {
  endDatetime: Scalars['DateTime'];
  roomId: Scalars['ID'];
  startDatetime: Scalars['DateTime'];
};

export type CalendarPermissionsStatus = {
  __typename?: 'CalendarPermissionsStatus';
  hasReadPermissions: Scalars['Boolean'];
  hasWritePermissions: Scalars['Boolean'];
  permissionsLastCheckedAt: Scalars['DateTime'];
};

export enum ChargingStatusType {
  Charging = 'CHARGING',
  Full = 'FULL',
  Unknown = 'UNKNOWN',
  Unplugged = 'UNPLUGGED'
}

export type CheckIn = {
  __typename?: 'CheckIn';
  calendarEventId: Scalars['Long'];
  checkInAt: Maybe<Scalars['DateTime']>;
  checkInWindowMinutes: Scalars['Int'];
  processedAt: Maybe<Scalars['DateTime']>;
  startDatetime: Scalars['DateTime'];
  startTimezone: Scalars['String'];
};

export type CheckInInput = {
  calendarEventId: Scalars['Long'];
  roomId: InputMaybe<Scalars['ID']>;
};

export type CheckOutInput = {
  calendarEventId: Scalars['Long'];
  roomId: InputMaybe<Scalars['ID']>;
};

export type Company = {
  __typename?: 'Company';
  billingRoomsSubscription: Maybe<BillingRoomsSubscription>;
  cancelUnattendedRecurringMeeting: Scalars['Boolean'];
  /** Company's connections (oauth pairings) using data loader */
  connections: Array<Connection>;
  devicesTimeFormat: TimeFormat;
  id: Scalars['ID'];
  /** Company's locations using data loader */
  locations: Array<Location>;
  name: Maybe<Scalars['String']>;
  onsiteRoomRecaptureEnabled: Scalars['Boolean'];
  pairingCode: Scalars['String'];
  roomCheckInEnabled: Scalars['Boolean'];
  roomResizerEnabled: Scalars['Boolean'];
  /** Time window (in seconds) after meetings start, when users can check in */
  roomsCheckInWindow: Scalars['Int'];
  /** Time window (in seconds) before meetings start, when users can check in */
  roomsPreCheckInWindow: Scalars['Int'];
  settingsAccessCode: Scalars['String'];
  /** For rooms */
  spaceSaverConfig: Maybe<SpaceSaverConfig>;
};

export type CompanyConfigInput = {
  cancelUnattendedRecurringMeeting: InputMaybe<Scalars['Boolean']>;
  devicesTimeFormat: InputMaybe<TimeFormat>;
  onsiteRoomRecaptureEnabled: InputMaybe<Scalars['Boolean']>;
  roomCheckInEnabled: InputMaybe<Scalars['Boolean']>;
  roomResizerEnabled: InputMaybe<Scalars['Boolean']>;
  settingsAccessCode: InputMaybe<Scalars['String']>;
};

export type CompanyInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  pairingCode: Scalars['String'];
};

export type Connection = {
  __typename?: 'Connection';
  email: Scalars['String'];
  id: Scalars['ID'];
  provider: OauthProvider;
  roomsCount: Maybe<Scalars['Int']>;
};

export type DeleteEventInput = {
  calendarEventId: Scalars['Long'];
};

export type Device = {
  __typename?: 'Device';
  appVersion: Maybe<Scalars['String']>;
  batteryLevel: Scalars['Int'];
  bluetoothStatus: BluetoothStatusType;
  chargingStatus: ChargingStatusType;
  clientId: Maybe<Scalars['String']>;
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  jackStatus: JackStatusType;
  lastOnlineAt: Maybe<Scalars['DateTime']>;
  ledAudioType: LightedCaseType;
  lightbarDetected: Scalars['Boolean'];
  model: Maybe<Scalars['String']>;
  name: Scalars['String'];
  offlineAt: Maybe<Scalars['DateTime']>;
  osVersion: Maybe<Scalars['String']>;
  /** Device's room using data loader */
  room: Maybe<Room>;
  roomId: Maybe<Scalars['ID']>;
  volume: Scalars['Int'];
};

export type DevicePairingCode = {
  __typename?: 'DevicePairingCode';
  deviceCode: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  interval: Scalars['Long'];
  redirectUri: Scalars['String'];
  userCode: Scalars['String'];
};

export type DevicePairingStatus = {
  __typename?: 'DevicePairingStatus';
  device: Maybe<Device>;
  deviceId: Maybe<Scalars['ID']>;
  expiresAt: Scalars['DateTime'];
  id: Scalars['Long'];
  interval: Scalars['Long'];
};

export type DeviceToken = {
  __typename?: 'DeviceToken';
  accessToken: Scalars['String'];
  expiresIn: Scalars['Long'];
  refreshToken: Maybe<Scalars['String']>;
  scope: Scalars['String'];
  tokenType: Scalars['String'];
};

export type DevicesFilterInput = {
  ids: InputMaybe<Array<Scalars['ID']>>;
  roomId: InputMaybe<Scalars['ID']>;
};

export enum ErrorDetail {
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DeadlineExceeded = 'DEADLINE_EXCEEDED',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  EnhanceYourCalm = 'ENHANCE_YOUR_CALM',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FieldNotFound = 'FIELD_NOT_FOUND',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  InvalidArgument = 'INVALID_ARGUMENT',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  InvalidCursor = 'INVALID_CURSOR',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MissingResource = 'MISSING_RESOURCE',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  ServiceError = 'SERVICE_ERROR',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TcpFailure = 'TCP_FAILURE',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  ThrottledConcurrency = 'THROTTLED_CONCURRENCY',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  ThrottledCpu = 'THROTTLED_CPU',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  Unimplemented = 'UNIMPLEMENTED',
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Unknown = 'UNKNOWN'
}

export type ErrorList = {
  __typename?: 'ErrorList';
  errors: Array<Scalars['String']>;
};

export enum ErrorType {
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BadRequest = 'BAD_REQUEST',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FailedPrecondition = 'FAILED_PRECONDITION',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Internal = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NotFound = 'NOT_FOUND',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PermissionDenied = 'PERMISSION_DENIED',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  Unauthenticated = 'UNAUTHENTICATED',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  Unavailable = 'UNAVAILABLE',
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  Unknown = 'UNKNOWN'
}

export type ExtendEventInput = {
  calendarEventId: Scalars['Long'];
  minutesToAdd: Scalars['Long'];
};

/** Only one of the operations will apply. If none is set, it'll be ignored */
export type IdFilter = {
  _eq: InputMaybe<Scalars['ID']>;
  _in: InputMaybe<Array<Scalars['ID']>>;
  /** True if it should return all records with the specified field null */
  _is_null: InputMaybe<Scalars['Boolean']>;
  _neq: InputMaybe<Scalars['ID']>;
  _nin: InputMaybe<Array<Scalars['ID']>>;
};

export enum JackStatusType {
  Plugged = 'PLUGGED',
  Unknown = 'UNKNOWN',
  Unplugged = 'UNPLUGGED'
}

export enum LightedCaseType {
  Generic = 'GENERIC',
  None = 'NONE',
  VisualTarget = 'VISUAL_TARGET'
}

export type Location = {
  __typename?: 'Location';
  /** Location's company using data loader */
  company: Maybe<Company>;
  companyId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  /**
   * A coalesced version of onsiteRoomRecaptureEnabledOverride from the location object and onsiteRoomRecaptureEnabled from the company object.
   * Indicates whether the onsite room recapture feature is turned on for this location.
   */
  onsiteRoomRecaptureEnabled: Scalars['Boolean'];
  /**
   * A flag indicating whether this location's onsite room recapture setting differs from the company-wide setting
   * If this is null, defer to the setting on the company
   */
  onsiteRoomRecaptureEnabledOverride: Maybe<Scalars['Boolean']>;
  /** A flag indicating whether rooms at this location have check-in enabled */
  roomCheckInEnabled: Scalars['Boolean'];
  /**
   * A coalesced version of roomResizerEnabledOverride from the location object and roomResizerEnabled from the company object.
   * Indicates whether the room resizer feature is turned on for this location.
   */
  roomResizerEnabled: Scalars['Boolean'];
  /**
   * A flag indicating whether this location's room resizer setting differs from the company-wide setting
   * If this is null, defer to the setting on the company
   */
  roomResizerEnabledOverride: Maybe<Scalars['Boolean']>;
  /** Location's rooms using data loader */
  rooms: Array<Room>;
  /** Time window (in seconds) after meetings start, when users can check in */
  roomsCheckInWindow: Scalars['Int'];
  roomsCheckInWindowIsOverride: Scalars['Boolean'];
  /** Additional rooms notification emails (these may be email addresses unknown to other apps in Envoy) */
  roomsNotificationEmails: Maybe<Array<Scalars['String']>>;
  /** Time window (in seconds) before meetings start, when users can check in */
  roomsPreCheckInWindow: Scalars['Int'];
  roomsPreCheckInWindowIsOverride: Scalars['Boolean'];
  /** Whether or not rooms device notifications should be sent to global admins at this location */
  roomsShouldEmailGlobalAdmins: Scalars['Boolean'];
  /** Whether or not rooms device notifications should be sent to location admins at this location */
  roomsShouldEmailLocationAdmins: Scalars['Boolean'];
};

export type LocationInput = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MarkEventMissedInput = {
  calendarEventId: Scalars['Long'];
  missedAt: Scalars['DateTime'];
  roomId: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  approveDeviceUserCode: DevicePairingStatus;
  /** Book a room now til a time that's suggested by backend */
  bookNow: Booking;
  checkCalendarPermissions: Room;
  checkInCalendarEvent: CalendarEvent;
  checkOutCalendarEvent: CalendarEvent;
  createCalendarEvent: CalendarEvent;
  createCompany: Company;
  createLocation: Location;
  deleteCalendarEvent: CalendarEvent;
  deleteRoomBackgroundImage: Room;
  extendCalendarEvent: CalendarEvent;
  getDevicePairingCode: DevicePairingCode;
  getDeviceToken: DeviceToken;
  linkDeviceWithRoom: Maybe<Device>;
  linkRoomsWithLocation: Array<Room>;
  markEventMissed: CalendarEvent;
  pairDevice: DeviceToken;
  pingWebviewLivenessCheck: Scalars['String'];
  /** Sets specific meeting organizer emails for which check-in are not required (company-wide) */
  putSpaceSaverCheckInExcludedOrganizers: SpaceSaverConfig;
  /**
   * Set specific rooms to have the setting specifically set to off.
   * This is a convenience mutation to support current interface
   * @see `updateRoomConfig`, `updateRoomCheckInWindow`, `updateLocationRoomsCheckInWindow` for more advanced configurations
   */
  putSpaceSaverCheckInExcludedRooms: SpaceSaverConfig;
  putSpaceSaverRoomResizerExcludedOrganizers: SpaceSaverConfig;
  putSpaceSaverRoomResizerExcludedRooms: SpaceSaverConfig;
  resyncRoomCalendar: Room;
  sendSlackCheckIn: Scalars['Boolean'];
  syncRoomCalendar: Room;
  synchronizeCompanyRoomList: ErrorList;
  unlinkConnection: Company;
  unpairDevice: Scalars['String'];
  updateCompanyConfig: Company;
  updateLightedCaseType: Maybe<Device>;
  updateLocationRoomsCheckInWindow: Location;
  updateRoomCheckInWindow: Room;
  updateRoomConfig: Room;
  updateRoomsCompanyOnsiteRoomRecaptureEnabled: Company;
  updateRoomsCompanyRoomResizerEnabled: Company;
  updateRoomsLocationNotificationEmails: Location;
  updateRoomsLocationOnsiteRoomRecaptureEnabledOverride: Location;
  updateRoomsLocationRoomCheckInEnabledOverride: Location;
  updateRoomsLocationRoomResizerEnabledOverride: Location;
  /**
   * Using separate mutations for each field rather than a generic updateLocationConfig because in the supergraph
   * a mutation named `updateLocationConfig` is ambiguous and these are all specific to Rooms
   * This completely leaks implementation details breaking the Supergraph abstraction, but this is necessary since
   * location based notification settings do not appear to be centralized
   */
  updateRoomsLocationShouldEmailGlobalAdmins: Location;
  updateRoomsLocationShouldEmailLocationAdmins: Location;
};


export type MutationApproveDeviceUserCodeArgs = {
  roomId: Scalars['String'];
  userCode: Scalars['String'];
};


export type MutationBookNowArgs = {
  roomId: Scalars['ID'];
  sendInviteTo: Array<Scalars['String']>;
};


export type MutationCheckCalendarPermissionsArgs = {
  roomId: Scalars['String'];
};


export type MutationCheckInCalendarEventArgs = {
  input: CheckInInput;
};


export type MutationCheckOutCalendarEventArgs = {
  input: CheckOutInput;
};


export type MutationCreateCalendarEventArgs = {
  input: CalendarEventInput;
};


export type MutationCreateCompanyArgs = {
  input: CompanyInput;
};


export type MutationCreateLocationArgs = {
  input: LocationInput;
};


export type MutationDeleteCalendarEventArgs = {
  input: DeleteEventInput;
};


export type MutationDeleteRoomBackgroundImageArgs = {
  roomId: Scalars['ID'];
};


export type MutationExtendCalendarEventArgs = {
  input: ExtendEventInput;
};


export type MutationGetDeviceTokenArgs = {
  deviceCode: Scalars['String'];
};


export type MutationLinkDeviceWithRoomArgs = {
  deviceId: Scalars['ID'];
  roomId: Scalars['ID'];
};


export type MutationLinkRoomsWithLocationArgs = {
  calendarEmails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  calendarIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  locationId: InputMaybe<Scalars['ID']>;
  roomIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationMarkEventMissedArgs = {
  input: MarkEventMissedInput;
};


export type MutationPairDeviceArgs = {
  pairingCode: Scalars['String'];
};


export type MutationPingWebviewLivenessCheckArgs = {
  clientDeviceId: InputMaybe<Scalars['String']>;
};


export type MutationPutSpaceSaverCheckInExcludedOrganizersArgs = {
  companyId: Scalars['ID'];
  organizerEmails: Array<Scalars['String']>;
};


export type MutationPutSpaceSaverCheckInExcludedRoomsArgs = {
  companyId: Scalars['ID'];
  roomIds: Array<Scalars['ID']>;
};


export type MutationPutSpaceSaverRoomResizerExcludedOrganizersArgs = {
  companyId: Scalars['ID'];
  organizerEmails: Array<Scalars['String']>;
};


export type MutationPutSpaceSaverRoomResizerExcludedRoomsArgs = {
  companyId: Scalars['ID'];
  roomIds: Array<Scalars['ID']>;
};


export type MutationResyncRoomCalendarArgs = {
  id: Scalars['ID'];
};


export type MutationSendSlackCheckInArgs = {
  input: SendSlackCheckInInput;
};


export type MutationSyncRoomCalendarArgs = {
  id: Scalars['ID'];
};


export type MutationUnlinkConnectionArgs = {
  connectionId: Scalars['ID'];
  shouldDeleteAssociatedRooms?: Scalars['Boolean'];
};


export type MutationUnpairDeviceArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateCompanyConfigArgs = {
  input: CompanyConfigInput;
};


export type MutationUpdateLightedCaseTypeArgs = {
  caseType: LightedCaseType;
  clientId: InputMaybe<Scalars['String']>;
  deviceId: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateLocationRoomsCheckInWindowArgs = {
  locationId: Scalars['ID'];
  roomsCheckInWindow: Scalars['Int'];
  roomsPreCheckInWindow: Scalars['Int'];
};


export type MutationUpdateRoomCheckInWindowArgs = {
  input: RoomCheckInWindowInput;
};


export type MutationUpdateRoomConfigArgs = {
  input: RoomConfigInput;
};


export type MutationUpdateRoomsCompanyOnsiteRoomRecaptureEnabledArgs = {
  companyId: Scalars['ID'];
  onsiteRoomRecaptureEnabled: Scalars['Boolean'];
};


export type MutationUpdateRoomsCompanyRoomResizerEnabledArgs = {
  companyId: Scalars['ID'];
  roomResizerEnabled: Scalars['Boolean'];
};


export type MutationUpdateRoomsLocationNotificationEmailsArgs = {
  locationId: Scalars['ID'];
  notificationEmails: InputMaybe<Array<Scalars['String']>>;
};


export type MutationUpdateRoomsLocationOnsiteRoomRecaptureEnabledOverrideArgs = {
  locationId: Scalars['ID'];
  onsiteRoomRecaptureEnabledOverride: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateRoomsLocationRoomCheckInEnabledOverrideArgs = {
  locationId: Scalars['ID'];
  roomCheckInEnabledOverride: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateRoomsLocationRoomResizerEnabledOverrideArgs = {
  locationId: Scalars['ID'];
  roomResizerEnabledOverride: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateRoomsLocationShouldEmailGlobalAdminsArgs = {
  locationId: Scalars['ID'];
  shouldEmailGlobalAdmins: Scalars['Boolean'];
};


export type MutationUpdateRoomsLocationShouldEmailLocationAdminsArgs = {
  locationId: Scalars['ID'];
  shouldEmailLocationAdmins: Scalars['Boolean'];
};

export enum OauthProvider {
  Google = 'GOOGLE',
  MicrosoftService = 'MICROSOFT_SERVICE',
  MicrosoftUser = 'MICROSOFT_USER'
}

export type PaginatedRoomsResponse = {
  __typename?: 'PaginatedRoomsResponse';
  items: Array<Room>;
  totalCount: Scalars['Int'];
};

export type PaginationInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  _entities: Array<Maybe<_Entity>>;
  _service: _Service;
  /** Available rooms */
  availableRooms: Array<Room>;
  building: Building;
  /** Full list of building names and floor names available for the company to be used as filters */
  buildingFilterOptions: Array<BuildingFilterOption>;
  calendarEvents: Array<CalendarEvent>;
  company: Company;
  devices: Array<Device>;
  getDevicePairingCodeByUserCode: Maybe<DevicePairingCode>;
  getDevicePairingStatus: DevicePairingStatus;
  introspectDeviceToken: Maybe<Device>;
  location: Location;
  paginatedRooms: PaginatedRoomsResponse;
  room: Room;
  rooms: Array<Room>;
  spaceSaverConfig: Maybe<SpaceSaverConfig>;
};


export type Query_EntitiesArgs = {
  representations: Array<Scalars['_Any']>;
};


export type QueryAvailableRoomsArgs = {
  location: InputMaybe<Scalars['ID']>;
  nearbyBeacons: InputMaybe<Array<BeaconInput>>;
};


export type QueryBuildingArgs = {
  id: Scalars['ID'];
};


export type QueryCalendarEventsArgs = {
  filter: CalendarEventsFilterInput;
};


export type QueryDevicesArgs = {
  filter: DevicesFilterInput;
};


export type QueryGetDevicePairingCodeByUserCodeArgs = {
  userCode: Scalars['String'];
};


export type QueryGetDevicePairingStatusArgs = {
  id: Scalars['Long'];
};


export type QueryIntrospectDeviceTokenArgs = {
  accessToken: InputMaybe<Scalars['String']>;
};


export type QueryLocationArgs = {
  id: Scalars['ID'];
};


export type QueryPaginatedRoomsArgs = {
  filter: RoomsPageFilterInput;
  pagination: InputMaybe<PaginationInput>;
  sort: RoomsSortOrder;
};


export type QueryRoomArgs = {
  id: Scalars['ID'];
};


export type QueryRoomsArgs = {
  filter: RoomsFilterInput;
};

export type Room = {
  __typename?: 'Room';
  /**
   * Things like TV, Zoom, Whiteboard
   * Field is migrating from Ruby subgraph
   */
  amenities: Array<Scalars['String']>;
  /**
   * Field is migrating from Ruby subgraph
   * @deprecated Use imageUrl instead
   */
  backgroundImageUrl: Maybe<Scalars['URL']>;
  building: Maybe<Building>;
  buildingId: Maybe<Scalars['ID']>;
  calendarEvents: Array<CalendarEvent>;
  calendarId: Scalars['String'];
  calendarPermissionsStatus: Maybe<CalendarPermissionsStatus>;
  /** Field is migrating from Ruby subgraph */
  capacity: Maybe<Scalars['Int']>;
  /** The coalesced setting of checkInEnabledOverride and company.checkInEnabled */
  checkInEnabled: Scalars['Boolean'];
  /** Setting override for company.checkInEnabled */
  checkInEnabledOverride: Maybe<Scalars['Boolean']>;
  /** Time window (in seconds) after meeting start when users can check in */
  checkInWindow: Scalars['Int'];
  /** True if this setting overrides the location setting */
  checkInWindowIsOverride: Scalars['Boolean'];
  claimable: Scalars['Boolean'];
  company: Maybe<Company>;
  companyId: Scalars['ID'];
  description: Maybe<Scalars['String']>;
  /** Room's devices */
  devices: Array<Device>;
  displayName: Maybe<Scalars['String']>;
  floor: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated Use imageUrl instead */
  image: Maybe<Scalars['String']>;
  /** Full url to access the public image (used as background on tablets) */
  imageUrl: Maybe<Scalars['String']>;
  /** Room's location using data loader */
  location: Maybe<Location>;
  locationId: Maybe<Scalars['ID']>;
  maskMeetingNames: Scalars['Boolean'];
  /**
   * Room display name
   * Field is migrating from Ruby subgraph
   */
  name: Scalars['String'];
  /** Time window (in seconds) before meeting when users can check in */
  preCheckInWindow: Scalars['Int'];
  /** True if this setting overrides the location setting */
  preCheckInWindowIsOverride: Scalars['Boolean'];
  /**
   * Distance to the available rooms
   * Field is migrating from Ruby subgraph
   */
  proximity: Maybe<BeaconProximity>;
  /** Room's status */
  status: Maybe<RoomStatus>;
  syncStatus: RoomSyncStatus;
  /**
   * Client will localize the duration to minutes/hours
   * Field is migrating from Ruby subgraph
   */
  timeAvailableInMinutes: Maybe<Scalars['Int']>;
  /**
   * The length of the booking is determined by rooms backend based on rooms availability and user preference
   * Field is migrating from Ruby subgraph
   */
  timeToBookInMinutes: Maybe<Scalars['Int']>;
};


export type RoomCalendarEventsArgs = {
  input: RoomCalendarEventsFilterInput;
};

export enum RoomAvailability {
  Available = 'AVAILABLE',
  Occupied = 'OCCUPIED',
  /** @deprecated Historically used for check-in status, now just use the checkin field instead. */
  Pending = 'PENDING'
}

export type RoomCalendarEventsFilterInput = {
  endDatetime: Scalars['DateTime'];
  /** Ordered by startDateTime. */
  limit: Scalars['Int'];
  startDatetime: Scalars['DateTime'];
};

export type RoomCheckInWindowInput = {
  /** Null to use default location setting */
  checkInWindowOverride: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** Null to use default location setting */
  preCheckInWindowOverride: InputMaybe<Scalars['Int']>;
};

export type RoomConfigInput = {
  calendarEmail: InputMaybe<Scalars['String']>;
  calendarId: InputMaybe<Scalars['ID']>;
  checkInEnabledOverride: InputMaybe<Scalars['Boolean']>;
  claimable: InputMaybe<Scalars['Boolean']>;
  displayName: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['ID']>;
  maskMeetingNames: InputMaybe<Scalars['Boolean']>;
};

export type RoomResizerExcludedRoom = {
  __typename?: 'RoomResizerExcludedRoom';
  room: Maybe<Room>;
  roomId: Scalars['ID'];
};

export type RoomStatus = {
  __typename?: 'RoomStatus';
  currentCalendarEventId: Maybe<Scalars['Long']>;
  status: RoomAvailability;
  time: Scalars['DateTime'];
};

export enum RoomSyncStatus {
  SyncCompleted = 'SYNC_COMPLETED',
  SyncNotStarted = 'SYNC_NOT_STARTED',
  SyncStarted = 'SYNC_STARTED'
}

export type RoomsFilterInput = {
  ids: InputMaybe<Array<Scalars['ID']>>;
  locationId: InputMaybe<Scalars['ID']>;
};

export enum RoomsOrderBy {
  BuildingName = 'BUILDING_NAME',
  DisplayName = 'DISPLAY_NAME',
  FloorName = 'FLOOR_NAME',
  LocationName = 'LOCATION_NAME'
}

export type RoomsPageFilterInput = {
  buildingId: InputMaybe<IdFilter>;
  /** We need to be able to filter by building name instead of id, because on Microsoft each room has a building associated to it */
  buildingName: InputMaybe<IdFilter>;
  /** Results will include rooms that have a name containing this text (case-insensitive) */
  displayName: InputMaybe<StringFilter>;
  /** Results will include rooms that have a floor matching the provided text */
  floorName: InputMaybe<IdFilter>;
  locationId: InputMaybe<IdFilter>;
};

export type RoomsSortOrder = {
  by: RoomsOrderBy;
  direction: SortDirection;
};

export type SendSlackCheckInInput = {
  calendarEventId: Scalars['Long'];
  roomId: Scalars['ID'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SpaceSaverConfig = {
  __typename?: 'SpaceSaverConfig';
  /** List of organizer emails for which the meetings don't require checking in */
  checkInExcludedOrganizers: Array<Scalars['String']>;
  /**
   * These are rooms that have the check-in option overwritten to off.
   * For advanced configs @see `checkInEnabledOverride`
   */
  checkInExcludedRooms: Array<Room>;
  companyId: Scalars['ID'];
  roomResizerExcludedOrganizers: Array<Scalars['String']>;
  roomResizerExcludedRooms: Array<RoomResizerExcludedRoom>;
};

/** Only one of the operations will apply. If none is set, it'll be ignored */
export type StringFilter = {
  _eq: InputMaybe<Scalars['String']>;
  /** Filter content containing the provided string (case insensitive) */
  _ilike: InputMaybe<Scalars['String']>;
  _in: InputMaybe<Array<Scalars['String']>>;
  /** True if it should return all records with the specified field null */
  _is_null: InputMaybe<Scalars['Boolean']>;
  _neq: InputMaybe<Scalars['String']>;
  /** Filter content that doesn't contain the provided string (case insensitive) */
  _nilike: InputMaybe<Scalars['String']>;
  _nin: InputMaybe<Array<Scalars['String']>>;
};

export enum TimeFormat {
  Military = 'MILITARY',
  Standard = 'STANDARD'
}

export type _Entity = Building | Company | Device | Location | Room;

export type _Service = {
  __typename?: '_Service';
  sdl: Scalars['String'];
};

export type CalendarPermissionsStatusFragmentFragment = { __typename?: 'CalendarPermissionsStatus', hasReadPermissions: boolean, hasWritePermissions: boolean, permissionsLastCheckedAt: string };

export type CompanyFragmentFragment = { __typename?: 'Company', id: string, name: string | null, pairingCode: string, cancelUnattendedRecurringMeeting: boolean, roomCheckInEnabled: boolean, settingsAccessCode: string, roomResizerEnabled: boolean, onsiteRoomRecaptureEnabled: boolean, devicesTimeFormat: TimeFormat };

export type ConnectionFragmentFragment = { __typename?: 'Connection', email: string, id: string, provider: OauthProvider, roomsCount: number | null };

export type DeviceFragmentFragment = { __typename?: 'Device', appVersion: string | null, batteryLevel: number, bluetoothStatus: BluetoothStatusType, chargingStatus: ChargingStatusType, clientId: string | null, id: string, jackStatus: JackStatusType, ledAudioType: LightedCaseType, lightbarDetected: boolean, model: string | null, name: string, offlineAt: string | null, osVersion: string | null, volume: number, lastOnlineAt: string | null };

export type LocationFragmentFragment = { __typename?: 'Location', name: string, id: string, roomsShouldEmailGlobalAdmins: boolean, roomsShouldEmailLocationAdmins: boolean, roomsNotificationEmails: Array<string> | null };

export type RoomFragmentFragment = { __typename?: 'Room', id: string, name: string, claimable: boolean, maskMeetingNames: boolean, displayName: string | null, image: string | null, imageUrl: string | null, floor: string | null, checkInEnabledOverride: boolean | null, checkInEnabled: boolean, building: { __typename?: 'Building', name: string | null } | null };

export type RoomStatusFragmentFragment = { __typename?: 'Room', status: { __typename?: 'RoomStatus', currentCalendarEventId: number | null, status: RoomAvailability, time: string } | null };

export type ApproveDeviceUserCodeMutationVariables = Exact<{
  userCode: Scalars['String'];
  roomId: Scalars['String'];
}>;


export type ApproveDeviceUserCodeMutation = { __typename?: 'Mutation', approveDeviceUserCode: { __typename?: 'DevicePairingStatus', id: number, interval: number, expiresAt: string, deviceId: string | null, device: { __typename?: 'Device', appVersion: string | null, batteryLevel: number, bluetoothStatus: BluetoothStatusType, chargingStatus: ChargingStatusType, clientId: string | null, id: string, jackStatus: JackStatusType, ledAudioType: LightedCaseType, lightbarDetected: boolean, model: string | null, name: string, offlineAt: string | null, osVersion: string | null, volume: number, lastOnlineAt: string | null } | null } };

export type CheckCalendarPermissionsMutationVariables = Exact<{
  roomId: Scalars['String'];
}>;


export type CheckCalendarPermissionsMutation = { __typename?: 'Mutation', checkCalendarPermissions: { __typename?: 'Room', id: string, name: string, claimable: boolean, maskMeetingNames: boolean, displayName: string | null, image: string | null, imageUrl: string | null, floor: string | null, checkInEnabledOverride: boolean | null, checkInEnabled: boolean, calendarPermissionsStatus: { __typename?: 'CalendarPermissionsStatus', hasReadPermissions: boolean, hasWritePermissions: boolean, permissionsLastCheckedAt: string } | null, building: { __typename?: 'Building', name: string | null } | null } };

export type CreateCompanyMutationVariables = Exact<{
  input: CompanyInput;
}>;


export type CreateCompanyMutation = { __typename?: 'Mutation', createCompany: { __typename?: 'Company', id: string, name: string | null, pairingCode: string, cancelUnattendedRecurringMeeting: boolean, roomCheckInEnabled: boolean, settingsAccessCode: string, roomResizerEnabled: boolean, onsiteRoomRecaptureEnabled: boolean, devicesTimeFormat: TimeFormat } };

export type CreateLocationMutationVariables = Exact<{
  input: LocationInput;
}>;


export type CreateLocationMutation = { __typename?: 'Mutation', createLocation: { __typename?: 'Location', name: string, id: string, roomsShouldEmailGlobalAdmins: boolean, roomsShouldEmailLocationAdmins: boolean, roomsNotificationEmails: Array<string> | null } };

export type DeleteRoomBackgroundImageMutationVariables = Exact<{
  roomId: Scalars['ID'];
}>;


export type DeleteRoomBackgroundImageMutation = { __typename?: 'Mutation', deleteRoomBackgroundImage: { __typename?: 'Room', id: string, name: string, claimable: boolean, maskMeetingNames: boolean, displayName: string | null, image: string | null, imageUrl: string | null, floor: string | null, checkInEnabledOverride: boolean | null, checkInEnabled: boolean, building: { __typename?: 'Building', name: string | null } | null } };

export type LinkRoomsWithLocationMutationVariables = Exact<{
  roomIds: Array<Scalars['ID']> | Scalars['ID'];
  locationId: InputMaybe<Scalars['ID']>;
}>;


export type LinkRoomsWithLocationMutation = { __typename?: 'Mutation', linkRoomsWithLocation: Array<{ __typename?: 'Room', id: string, name: string, claimable: boolean, maskMeetingNames: boolean, displayName: string | null, image: string | null, imageUrl: string | null, floor: string | null, checkInEnabledOverride: boolean | null, checkInEnabled: boolean, building: { __typename?: 'Building', name: string | null } | null }> };

export type PutSpaceSaverCheckInExcludedRoomsMutationVariables = Exact<{
  companyId: Scalars['ID'];
  roomIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type PutSpaceSaverCheckInExcludedRoomsMutation = { __typename?: 'Mutation', putSpaceSaverCheckInExcludedRooms: { __typename?: 'SpaceSaverConfig', checkInExcludedRooms: Array<{ __typename?: 'Room', id: string, name: string, claimable: boolean, maskMeetingNames: boolean, displayName: string | null, image: string | null, imageUrl: string | null, floor: string | null, checkInEnabledOverride: boolean | null, checkInEnabled: boolean, building: { __typename?: 'Building', name: string | null } | null }> } };

export type PutSpaceSaverRoomResizerExcludedOrganizersMutationVariables = Exact<{
  companyId: Scalars['ID'];
  organizerEmails: Array<Scalars['String']> | Scalars['String'];
}>;


export type PutSpaceSaverRoomResizerExcludedOrganizersMutation = { __typename?: 'Mutation', putSpaceSaverRoomResizerExcludedOrganizers: { __typename?: 'SpaceSaverConfig', roomResizerExcludedOrganizers: Array<string> } };

export type PutSpaceSaverRoomResizerExcludedRoomsMutationVariables = Exact<{
  companyId: Scalars['ID'];
  roomIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type PutSpaceSaverRoomResizerExcludedRoomsMutation = { __typename?: 'Mutation', putSpaceSaverRoomResizerExcludedRooms: { __typename?: 'SpaceSaverConfig', roomResizerExcludedRooms: Array<{ __typename?: 'RoomResizerExcludedRoom', roomId: string, room: { __typename?: 'Room', id: string, name: string, claimable: boolean, maskMeetingNames: boolean, displayName: string | null, image: string | null, imageUrl: string | null, floor: string | null, checkInEnabledOverride: boolean | null, checkInEnabled: boolean, building: { __typename?: 'Building', name: string | null } | null } | null }> } };

export type SynchronizeCompanyRoomListMutationVariables = Exact<{ [key: string]: never; }>;


export type SynchronizeCompanyRoomListMutation = { __typename?: 'Mutation', synchronizeCompanyRoomList: { __typename?: 'ErrorList', errors: Array<string> } };

export type UnlinkConnectionMutationVariables = Exact<{
  connectionId: Scalars['ID'];
  shouldDeleteAssociatedRooms: Scalars['Boolean'];
}>;


export type UnlinkConnectionMutation = { __typename?: 'Mutation', unlinkConnection: { __typename?: 'Company', id: string, name: string | null, pairingCode: string, cancelUnattendedRecurringMeeting: boolean, roomCheckInEnabled: boolean, settingsAccessCode: string, roomResizerEnabled: boolean, onsiteRoomRecaptureEnabled: boolean, devicesTimeFormat: TimeFormat, connections: Array<{ __typename?: 'Connection', email: string, id: string, provider: OauthProvider, roomsCount: number | null }> } };

export type UnpairDeviceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnpairDeviceMutation = { __typename?: 'Mutation', unpairDevice: string };

export type UpdateCompanyConfigMutationVariables = Exact<{
  input: CompanyConfigInput;
}>;


export type UpdateCompanyConfigMutation = { __typename?: 'Mutation', updateCompanyConfig: { __typename?: 'Company', id: string, name: string | null, pairingCode: string, cancelUnattendedRecurringMeeting: boolean, roomCheckInEnabled: boolean, settingsAccessCode: string, roomResizerEnabled: boolean, onsiteRoomRecaptureEnabled: boolean, devicesTimeFormat: TimeFormat } };

export type UpdateLightedCaseTypeMutationVariables = Exact<{
  deviceId: Scalars['ID'];
  caseType: LightedCaseType;
}>;


export type UpdateLightedCaseTypeMutation = { __typename?: 'Mutation', updateLightedCaseType: { __typename?: 'Device', appVersion: string | null, batteryLevel: number, bluetoothStatus: BluetoothStatusType, chargingStatus: ChargingStatusType, clientId: string | null, id: string, jackStatus: JackStatusType, ledAudioType: LightedCaseType, lightbarDetected: boolean, model: string | null, name: string, offlineAt: string | null, osVersion: string | null, volume: number, lastOnlineAt: string | null } | null };

export type UpdateLocationRoomsCheckInWindowMutationVariables = Exact<{
  locationId: Scalars['ID'];
  roomsCheckInWindow: Scalars['Int'];
  roomsPreCheckInWindow: Scalars['Int'];
}>;


export type UpdateLocationRoomsCheckInWindowMutation = { __typename?: 'Mutation', updateLocationRoomsCheckInWindow: { __typename?: 'Location', name: string, id: string, roomsShouldEmailGlobalAdmins: boolean, roomsShouldEmailLocationAdmins: boolean, roomsNotificationEmails: Array<string> | null } };

export type UpdateRoomConfigMutationVariables = Exact<{
  input: RoomConfigInput;
}>;


export type UpdateRoomConfigMutation = { __typename?: 'Mutation', updateRoomConfig: { __typename?: 'Room', id: string, name: string, claimable: boolean, maskMeetingNames: boolean, displayName: string | null, image: string | null, imageUrl: string | null, floor: string | null, checkInEnabledOverride: boolean | null, checkInEnabled: boolean, building: { __typename?: 'Building', name: string | null } | null } };

export type UpdateRoomsCompanyOnsiteRoomRecaptureEnabledMutationVariables = Exact<{
  companyId: Scalars['ID'];
  onsiteRoomRecaptureEnabled: Scalars['Boolean'];
}>;


export type UpdateRoomsCompanyOnsiteRoomRecaptureEnabledMutation = { __typename?: 'Mutation', updateRoomsCompanyOnsiteRoomRecaptureEnabled: { __typename?: 'Company', id: string, name: string | null, pairingCode: string, cancelUnattendedRecurringMeeting: boolean, roomCheckInEnabled: boolean, settingsAccessCode: string, roomResizerEnabled: boolean, onsiteRoomRecaptureEnabled: boolean, devicesTimeFormat: TimeFormat } };

export type UpdateRoomsCompanyRoomResizerEnabledMutationVariables = Exact<{
  companyId: Scalars['ID'];
  roomResizerEnabled: Scalars['Boolean'];
}>;


export type UpdateRoomsCompanyRoomResizerEnabledMutation = { __typename?: 'Mutation', updateRoomsCompanyRoomResizerEnabled: { __typename?: 'Company', id: string, name: string | null, pairingCode: string, cancelUnattendedRecurringMeeting: boolean, roomCheckInEnabled: boolean, settingsAccessCode: string, roomResizerEnabled: boolean, onsiteRoomRecaptureEnabled: boolean, devicesTimeFormat: TimeFormat } };

export type UpdateRoomsLocationRoomCheckInEnabledOverrideMutationVariables = Exact<{
  locationId: Scalars['ID'];
  roomCheckInEnabledOverride: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateRoomsLocationRoomCheckInEnabledOverrideMutation = { __typename?: 'Mutation', updateRoomsLocationRoomCheckInEnabledOverride: { __typename?: 'Location', name: string, id: string, roomsShouldEmailGlobalAdmins: boolean, roomsShouldEmailLocationAdmins: boolean, roomsNotificationEmails: Array<string> | null } };

export type UpdateRoomsLocationNotificationEmailsMutationVariables = Exact<{
  locationId: Scalars['ID'];
  notificationEmails: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type UpdateRoomsLocationNotificationEmailsMutation = { __typename?: 'Mutation', updateRoomsLocationNotificationEmails: { __typename?: 'Location', name: string, id: string, roomsShouldEmailGlobalAdmins: boolean, roomsShouldEmailLocationAdmins: boolean, roomsNotificationEmails: Array<string> | null } };

export type UpdateRoomsLocationOnsiteRoomRecaptureEnabledOverrideMutationVariables = Exact<{
  locationId: Scalars['ID'];
  onsiteRoomRecaptureEnabledOverride: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateRoomsLocationOnsiteRoomRecaptureEnabledOverrideMutation = { __typename?: 'Mutation', updateRoomsLocationOnsiteRoomRecaptureEnabledOverride: { __typename?: 'Location', name: string, id: string, roomsShouldEmailGlobalAdmins: boolean, roomsShouldEmailLocationAdmins: boolean, roomsNotificationEmails: Array<string> | null } };

export type UpdateRoomsLocationRoomResizerEnabledOverrideMutationVariables = Exact<{
  locationId: Scalars['ID'];
  roomResizerEnabledOverride: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateRoomsLocationRoomResizerEnabledOverrideMutation = { __typename?: 'Mutation', updateRoomsLocationRoomResizerEnabledOverride: { __typename?: 'Location', name: string, id: string, roomsShouldEmailGlobalAdmins: boolean, roomsShouldEmailLocationAdmins: boolean, roomsNotificationEmails: Array<string> | null } };

export type UpdateRoomsLocationShouldEmailGlobalAdminsMutationVariables = Exact<{
  locationId: Scalars['ID'];
  shouldEmailGlobalAdmins: Scalars['Boolean'];
}>;


export type UpdateRoomsLocationShouldEmailGlobalAdminsMutation = { __typename?: 'Mutation', updateRoomsLocationShouldEmailGlobalAdmins: { __typename?: 'Location', name: string, id: string, roomsShouldEmailGlobalAdmins: boolean, roomsShouldEmailLocationAdmins: boolean, roomsNotificationEmails: Array<string> | null } };

export type UpdateRoomsLocationShouldEmailLocationAdminsMutationVariables = Exact<{
  locationId: Scalars['ID'];
  shouldEmailLocationAdmins: Scalars['Boolean'];
}>;


export type UpdateRoomsLocationShouldEmailLocationAdminsMutation = { __typename?: 'Mutation', updateRoomsLocationShouldEmailLocationAdmins: { __typename?: 'Location', name: string, id: string, roomsShouldEmailGlobalAdmins: boolean, roomsShouldEmailLocationAdmins: boolean, roomsNotificationEmails: Array<string> | null } };

export type GetCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyQuery = { __typename?: 'Query', company: { __typename?: 'Company', id: string, name: string | null, pairingCode: string, cancelUnattendedRecurringMeeting: boolean, roomCheckInEnabled: boolean, settingsAccessCode: string, roomResizerEnabled: boolean, onsiteRoomRecaptureEnabled: boolean, devicesTimeFormat: TimeFormat, connections: Array<{ __typename?: 'Connection', email: string, id: string, provider: OauthProvider, roomsCount: number | null }>, locations: Array<{ __typename?: 'Location', name: string, id: string, roomsShouldEmailGlobalAdmins: boolean, roomsShouldEmailLocationAdmins: boolean, roomsNotificationEmails: Array<string> | null }> } };

export type GetDevicePairingStatusQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetDevicePairingStatusQuery = { __typename?: 'Query', getDevicePairingStatus: { __typename?: 'DevicePairingStatus', id: number, interval: number, expiresAt: string, deviceId: string | null, device: { __typename?: 'Device', appVersion: string | null, batteryLevel: number, bluetoothStatus: BluetoothStatusType, chargingStatus: ChargingStatusType, clientId: string | null, id: string, jackStatus: JackStatusType, ledAudioType: LightedCaseType, lightbarDetected: boolean, model: string | null, name: string, offlineAt: string | null, osVersion: string | null, volume: number, lastOnlineAt: string | null } | null } };

export type GetRoomQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetRoomQuery = { __typename?: 'Query', room: { __typename?: 'Room', id: string, name: string, claimable: boolean, maskMeetingNames: boolean, displayName: string | null, image: string | null, imageUrl: string | null, floor: string | null, checkInEnabledOverride: boolean | null, checkInEnabled: boolean, calendarPermissionsStatus: { __typename?: 'CalendarPermissionsStatus', hasReadPermissions: boolean, hasWritePermissions: boolean, permissionsLastCheckedAt: string } | null, devices: Array<{ __typename?: 'Device', appVersion: string | null, batteryLevel: number, bluetoothStatus: BluetoothStatusType, chargingStatus: ChargingStatusType, clientId: string | null, id: string, jackStatus: JackStatusType, ledAudioType: LightedCaseType, lightbarDetected: boolean, model: string | null, name: string, offlineAt: string | null, osVersion: string | null, volume: number, lastOnlineAt: string | null }>, building: { __typename?: 'Building', name: string | null } | null, status: { __typename?: 'RoomStatus', currentCalendarEventId: number | null, status: RoomAvailability, time: string } | null } };

export type GetRoomsQueryVariables = Exact<{
  filter: RoomsFilterInput;
}>;


export type GetRoomsQuery = { __typename?: 'Query', rooms: Array<{ __typename?: 'Room', id: string, name: string, claimable: boolean, maskMeetingNames: boolean, displayName: string | null, image: string | null, imageUrl: string | null, floor: string | null, checkInEnabledOverride: boolean | null, checkInEnabled: boolean, calendarPermissionsStatus: { __typename?: 'CalendarPermissionsStatus', hasReadPermissions: boolean, hasWritePermissions: boolean, permissionsLastCheckedAt: string } | null, devices: Array<{ __typename?: 'Device', appVersion: string | null, batteryLevel: number, bluetoothStatus: BluetoothStatusType, chargingStatus: ChargingStatusType, clientId: string | null, id: string, jackStatus: JackStatusType, ledAudioType: LightedCaseType, lightbarDetected: boolean, model: string | null, name: string, offlineAt: string | null, osVersion: string | null, volume: number, lastOnlineAt: string | null }>, location: { __typename?: 'Location', name: string, id: string, roomsShouldEmailGlobalAdmins: boolean, roomsShouldEmailLocationAdmins: boolean, roomsNotificationEmails: Array<string> | null } | null, building: { __typename?: 'Building', name: string | null } | null }> };

export type GetRoomsLocationWithConfigsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetRoomsLocationWithConfigsQuery = { __typename?: 'Query', location: { __typename?: 'Location', roomResizerEnabled: boolean, onsiteRoomRecaptureEnabled: boolean, roomsPreCheckInWindow: number, roomsCheckInWindow: number, roomCheckInEnabled: boolean, name: string, id: string, roomsShouldEmailGlobalAdmins: boolean, roomsShouldEmailLocationAdmins: boolean, roomsNotificationEmails: Array<string> | null } };

export type GetRoomsPaginatedQueryVariables = Exact<{
  filter: RoomsPageFilterInput;
  sort: RoomsSortOrder;
  pagination: InputMaybe<PaginationInput>;
}>;


export type GetRoomsPaginatedQuery = { __typename?: 'Query', paginatedRooms: { __typename?: 'PaginatedRoomsResponse', totalCount: number, items: Array<{ __typename?: 'Room', id: string, name: string, claimable: boolean, maskMeetingNames: boolean, displayName: string | null, image: string | null, imageUrl: string | null, floor: string | null, checkInEnabledOverride: boolean | null, checkInEnabled: boolean, calendarPermissionsStatus: { __typename?: 'CalendarPermissionsStatus', hasReadPermissions: boolean, hasWritePermissions: boolean, permissionsLastCheckedAt: string } | null, devices: Array<{ __typename?: 'Device', appVersion: string | null, batteryLevel: number, bluetoothStatus: BluetoothStatusType, chargingStatus: ChargingStatusType, clientId: string | null, id: string, jackStatus: JackStatusType, ledAudioType: LightedCaseType, lightbarDetected: boolean, model: string | null, name: string, offlineAt: string | null, osVersion: string | null, volume: number, lastOnlineAt: string | null }>, location: { __typename?: 'Location', name: string, id: string, roomsShouldEmailGlobalAdmins: boolean, roomsShouldEmailLocationAdmins: boolean, roomsNotificationEmails: Array<string> | null } | null, building: { __typename?: 'Building', name: string | null } | null, status: { __typename?: 'RoomStatus', currentCalendarEventId: number | null, status: RoomAvailability, time: string } | null }> } };

export type GetSpaceSaverConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSpaceSaverConfigQuery = { __typename?: 'Query', spaceSaverConfig: { __typename?: 'SpaceSaverConfig', roomResizerExcludedOrganizers: Array<string>, checkInExcludedRooms: Array<{ __typename?: 'Room', id: string, name: string, locationId: string | null }>, roomResizerExcludedRooms: Array<{ __typename?: 'RoomResizerExcludedRoom', room: { __typename?: 'Room', id: string, name: string, locationId: string | null } | null }> } | null };

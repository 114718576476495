import { attr } from '@ember-data/model';
import AbstractVisitorContactModel from 'garaje/models/abstract/abstract-visitor-contact';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('multi-tenancy')
class VisitorContactModel extends AbstractVisitorContactModel {
  @attr('string') declare photoUrl: string;
}

export default VisitorContactModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'visitor-contact': VisitorContactModel;
  }
}

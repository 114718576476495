import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type StateService from 'garaje/services/state';

export default class VirtualFrontDeskLandingPageRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare state: StateService;

  // We add async here because it fixes some ember.js race condition
  // that was previously causing the sidebar nav item to not light up

  // eslint-disable-next-line @typescript-eslint/require-await
  async beforeModel(): Promise<void> {
    if (this.state.features?.canAccessVirtualFrontDesk) {
      void this.router.transitionTo('virtual-front-desk.devices');
    }
  }
}

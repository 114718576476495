import type NativeArray from '@ember/array/-private/native-array';
import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type LocationModel from 'garaje/models/location';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import type LocationsService from 'garaje/services/locations';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

export default class LocationOverviewRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service('locations') declare locationsService: LocationsService;
  @service('skinny-locations') declare skinnyLocationsService: SkinnyLocationsService;

  model(): Promise<void | NativeArray<SkinnyLocationModel> | undefined | LocationModel[]> {
    if (this.abilities.can('view locations-grouping')) {
      return this.skinnyLocationsService.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
    }
    return this.locationsService.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
  }
}

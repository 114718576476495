import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class LocationOverviewLocationsRoute extends Route {
  @service abilities;
  @service router;
  @service store;
  @service globalOverviewAccess;

  beforeModel() {
    if (!this.globalOverviewAccess.canVisitGlobalLocation) {
      this.router.transitionTo('unauthorized');
    }
  }

  async model() {
    if (this.abilities.can('manage locations-grouping')) {
      const groups = await this.store.findAll('group');
      return { groups };
    }
    return this.store.findAll('averageMonthlyVisitorCount');
  }
}

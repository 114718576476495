import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import type SkinnyLocationModel from 'garaje/models/skinny-location';

interface CreateEmployeeLocationsSignature {
  Args: {
    selectedEmployeeLocations: Array<AvailableLocation>;
    remainingEmployeeLocations: Array<AvailableLocation>;
    addEmployeeLocation: (selectedLoc: AvailableLocation) => void;
    deleteEmployeeLocation: (locToDelete: AvailableLocation) => void;
    defaultLocationId: string | null | undefined;
    isGlobalOverview: boolean;
    currentLocationId: string;
    error: boolean;
  };
}

interface AvailableLocation {
  location: SkinnyLocationModel;
  disabled: boolean;
  nameWithCompanyName: string;
}

export default class CreateEmployeeLocations extends Component<CreateEmployeeLocationsSignature> {
  @service declare store: StoreService;

  get defaultLocationIdString(): string {
    return this.args.defaultLocationId?.toString() || '';
  }
}

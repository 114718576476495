import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class DeliveriesSampleLabelComponent extends Component {
  @service currentAdmin;

  senderName = 'Envoy Deliveries';
  trackingNumber = '1234 5678 9000 0000';

  deliveryAddress = {
    addressLineOne: '410 Townsend St',
    addressLineTwo: '',
    city: 'San Francisco',
    state: 'CA',
    zip: '94107',
  };

  returnAddress = {
    addressLineOne: '42 Wallaby Way',
    addressLineTwo: '',
    city: 'Sydney',
    state: 'NSW',
    zip: '2774',
  };
}

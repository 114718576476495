// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { get } from '@ember/object';

// eslint-disable-next-line ember/no-classic-classes
export default Component.extend({
  tagName: 'input',
  // Properties
  disabled: false,
  hasError: false,
  type: 'text',
  value: null,
  autofocus: false,
  isReadonly: false,
  placeholder: null,
  'data-test-ui-input': true,

  // Actions
  update: null,
  focusIn: null,
  focusOut: null,

  classNameBindings: Object.freeze(['hasError:error', 'isReadonly:readonly']),

  attributeBindings: Object.freeze([
    'disabled',
    'type',
    'value',
    'autofocus',
    'isReadonly:readonly',
    'id',
    'placeholder',
    'data-test-ui-input',
    'hasError:data-test-error-description',
  ]),
  init() {
    this._super(...arguments);

    if (get(this, 'min')) {
      this.attributeBindings = this.attributeBindings.concat('min');
    }
    if (get(this, 'max')) {
      this.attributeBindings = this.attributeBindings.concat('max');
    }
    if (get(this, 'dir')) {
      this.attributeBindings = this.attributeBindings.concat('dir');
    }
  },
  input({ target: { value } }) {
    get(this, 'update')(value);
  },
});

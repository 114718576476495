import type NativeArray from '@ember/array/-private/native-array';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type PropertyPrinterModel from 'garaje/models/property-printer';
import type TenantModel from 'garaje/models/tenant';
import type ConnectInvitesService from 'garaje/services/connect-invites';
import type LocalStorageService from 'garaje/services/local-storage';
import { TenantConnectionStatus } from 'garaje/utils/enums';
import { reads } from 'macro-decorators';

import { CURRENT_PRINTER_KEY } from '../controller';

export default class PropertyVisitorsInvitesNewController extends Controller {
  @service declare connectInvites: ConnectInvitesService;
  @service declare router: RouterService;
  @service declare localStorage: LocalStorageService;

  @reads('model.loadTenantsTask.value') tenants?: NativeArray<TenantModel>;
  @reads('model.loadPrintersTask.value') printers?: NativeArray<PropertyPrinterModel>;

  get connectedTenants(): TenantModel[] | undefined {
    return this.tenants?.filter((tenant) => tenant.status === TenantConnectionStatus.CONNECTED);
  }

  get selectedPrinter(): PropertyPrinterModel | null | undefined {
    const selectedPrinterId = this.localStorage.getItem(CURRENT_PRINTER_KEY);
    return selectedPrinterId ? this.printers?.findBy('id', selectedPrinterId) : null;
  }

  @action
  onClose(): void {
    void this.router.transitionTo('property.visitors.invites');
  }

  @action
  onSave(): void {
    void this.connectInvites.loadDataInForeground.perform();
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

/**
 * @param {Model}                     visitorSurveyConfiguration
 * @param {Function}                  onPreview
 */
export default class VisitorEmailsVisitorSurveyVsPreviewing extends Component {
  @tracked currentView = 'email';
}

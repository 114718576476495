import { isBlank } from '@ember/utils';
import Model, { type AsyncBelongsTo, type AsyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';
import type Device from 'garaje/models/device';

type DeviceModel = Device & { isIpadSeenRecently: boolean };

export enum PrinterStatus {
  OPERATIONAL = 'operational',
  OFFLINE = 'printerOffline',
  NOT_IN_USE = 'notInUse',
  CHECK_IPADS = 'checkIpads',
}

// Given a `status` value for a printer, convert that into a PrinterStatus value.
// This is because `status` and `printerStatus` don't convey the same information.
type PrinterStatusMapping = {
  [key: string]: PrinterStatus;
};
const PRINTER_STATUS_MAP: PrinterStatusMapping = {
  connected: PrinterStatus.OPERATIONAL,
  'Not connected': PrinterStatus.OFFLINE,
};

// for `status` values that are not present in PRINTER_STATUS_MAP, use this as its `printerStatus`
const UNHANDLED_PRINTER_STATUS: PrinterStatus = PrinterStatus.OFFLINE;

// Printers of these types are always considered "operational" because there's no way to determine their true status.
const ALWAYS_OPERATIONAL_CONNECTION_TYPES = ['integration'];

// Printers of these types have their `printerStatus` determined by their connected iPads (or lack thereof)
// instead of using the `status` property on the model.
const IPAD_PRINTER_CONNECTION_TYPES = ['bluetooth', 'ip'];

export type PrinterConnectionType = 'ip' | 'bluetooth' | 'integration' | 'printnode';

export default abstract class AbstractPrinter extends Model {
  @hasMany('device', { async: false })
  declare devices: AsyncHasMany<DeviceModel>;

  // Reference to PrintNode "computer" record
  @belongsTo('printer-connection')
  declare printerConnection: AsyncBelongsTo<Model>;

  @attr('string')
  declare ip: string;

  @attr('boolean', { defaultValue: true })
  declare enabled: boolean;

  @attr('string')
  declare model: string;

  @attr('string')
  declare status: string;

  @attr('string', { defaultValue: 'ip' })
  declare connectionType: PrinterConnectionType;

  @attr('string')
  declare name: string;

  // Reference to PrintNode "printer" record
  @attr('string')
  declare externalResourceUid: string;

  get connectedOnlineDevices(): DeviceModel[] {
    const devices = this.devices;
    return isBlank(devices)
      ? []
      : devices.filter(
          (device) =>
            device.printerStatus && device.isIpadSeenRecently && device.printerStatus.toLowerCase() === 'connected',
        );
  }

  get connectedDevices(): DeviceModel[] {
    const devices = this.devices;
    return isBlank(devices)
      ? []
      : devices.filter((device) => device.printerStatus && device.printerStatus.toLowerCase() === 'connected');
  }

  /**
   * Determime the status of the printer. For certain printer types (see the `IPAD_PRINTER_CONNECTION_TYPES` array)
   * the status is determined from the printer's connected devices (or lack thereof) instead of using the printer's
   * `status` attribute.
   * Certain other printer types (see `ALWAYS_OPERATIONAL_CONNECTION_TYPES`) do not report an accurate status and do
   * not have connected devices; these printers are treated as always being 'operational'.
   *
   * Possible printer status
   * 1. operational     All iPads are paired with the printer
   * 2. notInUse        No iPads is paired with the printer
   * 3. printerOffline  All iPads reported printer is offline
   * 4. check iPads     Some iPads reported printer is connected
   */
  get printerStatus(): PrinterStatus {
    if (ALWAYS_OPERATIONAL_CONNECTION_TYPES.includes(this.connectionType)) {
      return PrinterStatus.OPERATIONAL;
    }

    if (IPAD_PRINTER_CONNECTION_TYPES.includes(this.connectionType)) {
      const devices = this.devices;
      const connectedOnlineDevices = this.connectedOnlineDevices;
      const connectedDevices = this.connectedDevices;

      if (isBlank(devices)) {
        return PrinterStatus.NOT_IN_USE;
      } else if (isBlank(connectedDevices)) {
        return PrinterStatus.OFFLINE;
      } else if (devices.length !== connectedOnlineDevices.length) {
        return PrinterStatus.CHECK_IPADS;
      } else {
        return PrinterStatus.OPERATIONAL;
      }
    }

    return PRINTER_STATUS_MAP[this.status] ?? UNHANDLED_PRINTER_STATUS;
  }

  get isOperational(): boolean {
    return this.printerStatus === PrinterStatus.OPERATIONAL;
  }
}

import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class LanguageSelectorComponent extends Component {
  get selectedLanguage() {
    return this.args.languages.findBy('value', this.args.value);
  }

  @action
  onChange(language) {
    this.args.onChange(language.value);
  }
}

import gql from 'graphql-tag';

export default gql`
  fragment RoomFragment on Room {
    id
    name
    claimable
    calendarId
    maskMeetingNames
    displayName
    image
    imageUrl
    floor
    building {
      name
    }
    checkInEnabledOverride
    checkInEnabled
  }
`;

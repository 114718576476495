// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { set, action, get } from '@ember/object';
/**
 * Contextual component used for creating and editing basic Location information
 * @param {Changeset<Location>}     changeset            Location's changeset
 * @param {GlobalSettingBatch}      globalSettingBatch   Global setting batch insteance used when changing the address
 * @param {Boolean}                 isDisabled           Control the `disabled` status of Location and Company name inputs
 * @param {Function}                addressDidChange     Function triggered when the address changes (used on Google map rendering)
 * @param {Array<Group>}            groups               List of available groups to associate with this location
 */

export default class LocationFieldsComponent extends Component {
  /**
   * Test selectors
   */
  attributeBindings = ['data-test-location-fields'];
  'data-test-location-fields' = true;
  /**
   * @type {Boolean}
   */
  isDisabled = false;
  /**
   * @type {Function}
   */
  addressDidChange() {}

  // eslint-disable-next-line ember/classic-decorator-hooks
  init() {
    super.init(...arguments);
    set(this, 'groupSelected', get(this.changeset, 'group'));
  }

  @action
  onGroupSelected(group) {
    get(this.changeset, 'groups').clear();
    if (group) {
      set(this, 'groupSelected', group);
      get(this.changeset, 'groups').pushObject(group);
    } else {
      set(this, 'groupSelected', null);
    }
  }
}

import Model, { belongsTo, attr } from '@ember-data/model';
import type UserModel from 'garaje/models/user';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('v2')
class EmailModel extends Model {
  @belongsTo('user') declare user: UserModel;

  @attr('date') declare createdAt: Date;
  @attr('date') declare confirmedAt: Date;
  @attr('string') declare email: string;

  get isConfirmed(): boolean {
    return !!this.confirmedAt;
  }
}

export default EmailModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    email: EmailModel;
  }
}

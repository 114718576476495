import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

type DynamicColorSvgArgs = {
  squareColor: string;
};

export default class DynamicColorSvg extends Component<DynamicColorSvgArgs> {
  @tracked internalColor = '#0000ff';

  get squareColor(): string {
    return this.args.squareColor;
  }
}

import { formatDuration } from 'date-fns';
import { MINUTES_IN_A_WEEK, MINUTES_IN_A_DAY } from 'garaje/utils/enums';

export const getReadableScheduleEligibilityOffset = (scheduleEligibilityOffset: number): string => {
  const unit = scheduleEligibilityOffset < MINUTES_IN_A_DAY * -6 ? 'weeks' : 'days';
  const weeks = -scheduleEligibilityOffset / MINUTES_IN_A_WEEK;
  const days = -scheduleEligibilityOffset / MINUTES_IN_A_DAY - 1;

  return formatDuration({ weeks, days }, { format: [unit], zero: true });
};

import Model, { belongsTo, attr } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';

import type AnnouncementModel from './announcement';
export default class AnnouncementChannelsModel extends Model {
  @belongsTo('announcement') declare announcement: AsyncBelongsTo<AnnouncementModel>;

  // attribute
  @attr('string') declare channel: 'sms' | 'email' | 'push';
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import RSVP from 'rsvp';
import { get, set, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';
import { guidFor } from '@ember/object/internals';
import $ from 'jquery';
import validateAndUpdateAddressTask from 'garaje/utils/decorators/validate-and-update-address-task';

/**
 * @param {Class<DeliveryArea>}           model
 * @param {Object}                        changeset
 * @param {Boolean}                       editMode
 * @param {Function}                      saveTask
 * @param {Function}                      didSave
 * @param {Array<DeliveryArea>}           deliveryAreas
 * @param {Function}                      cancel
 * @param {Class<Subscription>}           deliveriesSubscription
 */
@validateAndUpdateAddressTask
class DeliveryAreaForm extends Component {
  @service featureFlags;
  @service flashMessages;
  @service state;
  @service store;

  @tracked changeset;
  elementId = guidFor(this);

  constructor() {
    super(...arguments);
    this.changeset = this.args.changeset;
  }

  get hasInsufficientQuantity() {
    return this.unusedQuantity < 1;
  }

  get hasDeliveriesApp() {
    return !!this.state.features?.canAccessDeliveriesApplication;
  }

  get activeDeliveryAreas() {
    return this.featureFlags.isEnabled('locationBilling')
      ? this.args.deliveryAreas.filter(
          (deliveryArea) =>
            deliveryArea.belongsTo('location').id() === this.state.currentLocation.id &&
            !deliveryArea.isNew &&
            deliveryArea.enabled,
        )
      : this.args.deliveryAreas.filter(({ enabled, isNew }) => !isNew && enabled);
  }

  get unusedQuantity() {
    return (get(this.args.deliveriesSubscription, 'quantity') ?? 0) - this.activeDeliveryAreas.length;
  }

  @action
  save(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    this.args.changeset.validate().then(() => {
      if (this.args.changeset.isInvalid) {
        return RSVP.reject();
      }
      this.validateAndUpdateAddress
        .perform(true)
        .then(() => {
          const save = this.args.saveTask;
          save.perform(this.args.changeset).then(() => {
            if (this.args.didSave) {
              this.args.didSave(this.args.model);
            }
          });
        })
        .catch((error) => {
          this.flashMessages.showFlash('warning', `Error validating delivery area address: ${error}`);
        });
    });
  }

  @action
  handleAddressSelect(value) {
    set(this.args.changeset, 'address', value);
    this.validateAndUpdateAddress.perform(true);
    // We need to wait until the `suit` input appears
    next(this, function () {
      $(`#${this.elementId} .addressLine2`).focus().select();
    });
  }
}

export default DeliveryAreaForm;

import { INVITE_STATE } from 'garaje/utils/enums';

type options = 'Available' | 'Scheduled' | 'Signed in' | 'Approved';

export default function inviteStateLabel(inviteState: string): options | void {
  switch (inviteState) {
    case INVITE_STATE.UNSCHEDULED:
      return 'Available';
    case INVITE_STATE.SCHEDULED:
      return 'Scheduled';
    case INVITE_STATE.SIGNED_IN:
      return 'Signed in';
    case INVITE_STATE.APPROVED:
      return 'Approved';
  }
}

import Model, { attr, hasMany } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';

@adapter('billing-v1')
class InvoiceEstimate extends Model {
  @hasMany('discount', { async: false }) discounts;
  @hasMany('tax', { async: false }) taxes;
  @hasMany('estimate-line-item', { async: false }) lineItems;

  // NEXT INVOICE ESTIMATE for LBB
  @attr('number') total;
  // Not applicable to LBB
  @attr('number') creditsApplied;
  // CURRENT INVOICE ESTIMATE for LBB
  @attr('number') amountDue;
  @attr('string') period;
  @attr('number') discountAmount;

  @computed('taxes')
  get totalTaxAmount() {
    return this.taxes.reduce((acc, tax) => acc + tax.amount, 0);
  }
}

export default InvoiceEstimate;

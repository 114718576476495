import Component from '@glimmer/component';
import { equal } from 'macro-decorators';

interface RoombaStatusBadgeArgs {
  status: string;
  classes: string;
  // Used in rooms list to display the total number of devices with the same status
  count?: number;
}

export enum DEVICE_STATUS {
  ONLINE = 'online',
  OFFLINE = 'offline',
  NOT_PAIRED = 'not-paired',
  INSUFFICIENT_PERMISSIONS = 'insufficient-permissions',
  OPERATIONAL = 'operational',
  OUT_OF_CASE = 'out-of-case',
  LOW_VOLUME = 'volume_low',
}

export default class RoombaStatusBadge extends Component<RoombaStatusBadgeArgs> {
  @equal('args.status', DEVICE_STATUS.ONLINE) declare online: boolean;
  @equal('args.status', DEVICE_STATUS.OFFLINE) declare offline: boolean;
  @equal('args.status', DEVICE_STATUS.NOT_PAIRED) declare notPaired: boolean;
  @equal('args.status', DEVICE_STATUS.INSUFFICIENT_PERMISSIONS) declare insufficientPermissions: boolean;

  // Device statuses
  @equal('args.status', DEVICE_STATUS.OPERATIONAL) declare operational: boolean;
  @equal('args.status', DEVICE_STATUS.OUT_OF_CASE) declare outOfCase: boolean;
  @equal('args.status', DEVICE_STATUS.LOW_VOLUME) declare volumeLow: boolean;

  get count(): string {
    return this.args.count === undefined ? '' : `${this.args.count} `;
  }
}

import Model, { attr } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('acs-location')
class AcsLocationModel extends Model {
  // attributes
  @attr('string') declare timezone: string;
  @attr('boolean') declare acsFailureNotificationsEnabled: boolean;
  @attr('boolean') declare dataMissingNotificationsEnabled: boolean;
  @attr('number') declare lastModifiedByUserId: number;
}

export default AcsLocationModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'acs-location': AcsLocationModel;
  }
}

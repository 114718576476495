import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import normalizeCouponCode from 'garaje/utils/normalize-coupon-code';
import { action } from '@ember/object';

/**
 * @param {Function}  onSubmit
 */
export default class ApplyCouponForm extends Component {
  @tracked couponCode = '';

  get canSubmit() {
    return this.couponCode.length > 0 && this.submitTask.isIdle;
  }

  @action
  setCouponCode(couponCode) {
    this.couponCode = normalizeCouponCode(couponCode);
  }

  @dropTask
  *submitTask() {
    yield this.args.onSubmit(this.couponCode);
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type TenantModel from 'garaje/models/tenant';
import type StateService from 'garaje/services/state';
import { TenantConnectionStatus } from 'garaje/utils/enums';
import { hash } from 'rsvp';

interface LocationSettingsConnectPermissionsRouteModel {
  tenant: TenantModel;
}

export default class LocationSettingsConnectPermissionsRoute extends Route {
  @service declare store: Store;
  @service declare state: StateService;
  @service declare router: RouterService;

  connectRoute = 'location-settings.index.connect';
  connectPermissionsRoute = 'location-settings.connect-permissions';

  model(params: { tenant_id: string }): Promise<LocationSettingsConnectPermissionsRouteModel> {
    return hash({
      tenant: this.store.findRecord('tenant', params.tenant_id, {
        include: 'tenant-permissions-configuration',
        reload: true,
      }),
    });
  }

  async afterModel({ tenant }: LocationSettingsConnectPermissionsRouteModel): Promise<void> {
    if (this.state.currentLocation?.id !== tenant.locationId) {
      const connectedTenants = await this.store.query('tenant', {
        filter: {
          location: this.state.currentLocation?.id,
          status: TenantConnectionStatus.CONNECTED,
        },
      });

      if (connectedTenants.firstObject) {
        void this.router.replaceWith(this.connectPermissionsRoute, connectedTenants.firstObject.id);
      } else {
        void this.router.replaceWith(this.connectRoute);
      }
    }
  }
}

import Model, { attr } from '@ember-data/model';
import { apiAction } from '@mainmatter/ember-api-actions';
import type { RoleScopeType } from 'garaje/utils/custom-roles';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('v2')
class RoleAssignmentModel extends Model {
  @attr('number') declare userId: number;
  @attr('string') declare roleId: string;
  @attr('number') declare roleScopeId: number;
  @attr('string') declare roleScopeType: RoleScopeType;
  // name of the company, location, or zone
  @attr('string') declare roleScopeName: string;
  @attr('boolean') declare manuallyAdded: boolean;
  @attr('boolean') declare editable: boolean;
  @attr('date') declare confirmedAt: Date;

  async deliverConfirmationEmail(): Promise<unknown> {
    return apiAction(this, { method: 'POST', path: 'confirmation' });
  }
}

export default RoleAssignmentModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'role-assignment': RoleAssignmentModel;
  }
}

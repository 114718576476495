import Model, { attr } from '@ember-data/model';

export const optOutDefaultMessage = 'Please ask the front desk for assistance.';

abstract class AbstractGdprConfigurationModel extends Model {
  @attr('boolean', { defaultValue: false }) declare enabled: boolean;
  @attr('string', { defaultValue: '' }) declare policyDisclosure: string;
  @attr('boolean', { defaultValue: false }) declare policyDisclosureEnabled: boolean;
  @attr('string', { defaultValue: optOutDefaultMessage }) declare optOutVisitorProtocol: string;
  @attr('boolean', { defaultValue: false }) declare optOutVisitorProtocolEnabled: boolean;
}

export default AbstractGdprConfigurationModel;

import Component from '@glimmer/component';
import { restartableTask } from 'ember-concurrency';
import { A } from '@ember/array';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { notEmpty } from 'macro-decorators';

/**
 * @param {Array}                               selectedChildren
 * @param {Model<GlobalSettingBatch>}           globalSettingBatch
 * @param {Function}                            close
 * @param {Function}                            nextToConfirm
 * @param {Function}                            updateChildren
 */
export default class PropagableSettingsChangedModalLocationsSearcher extends Component {
  @service store;

  @tracked searchQuery = null;
  @tracked locations = A();

  @notEmpty('args.selectedChildren') atLeastOneSelected;

  constructor() {
    super(...arguments);
    this.fetchLocations.perform();
  }

  get filteredLocations() {
    if (!this.searchQuery) {
      return this.locations;
    }

    return this.locations.filter((location) => location.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
  }

  get areAllSelected() {
    return get(this.locations, 'length') === get(this.args.selectedChildren, 'length');
  }

  get selectedMessage() {
    const totalLocations = get(this.locations, 'length');
    const totalSelected = get(this.args.selectedChildren, 'length');

    if (totalSelected === 0) {
      return '';
    } else if (totalSelected === totalLocations) {
      return 'All locations selected';
    } else {
      return `${totalSelected} of ${totalLocations} locations selected`;
    }
  }

  @restartableTask
  *fetchLocations() {
    const locationParentId = get(this.args.globalSettingBatch, 'parent.id');
    const locations = yield this.store.findAll('location');

    // Remove current location from the list
    this.locations = locations.filter((location) => {
      return get(location, 'id') !== locationParentId;
    });
  }

  @action
  clearAll() {
    this.args.updateChildren(A());
  }

  @action
  toggleAllSelected() {
    if (this.areAllSelected) {
      this.args.updateChildren(A());
    } else {
      this.args.updateChildren(this.locations.toArray());
    }
  }

  @action
  toggleLocation(location) {
    const selectedChildren = this.args.selectedChildren;
    if (selectedChildren.includes(location)) {
      selectedChildren.removeObject(location);
    } else {
      selectedChildren.addObject(location);
    }
  }
}

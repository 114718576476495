import type { AsyncBelongsTo } from '@ember-data/model';
import { belongsTo } from '@ember-data/model';
import AbstractSignInFieldActionsContactModel from 'garaje/models/abstract/abstract-sign-in-field-actions-contact';
import type EmployeeSignInFieldActionRuleGroupModel from 'garaje/models/employee-sign-in-field-action-rule-group';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('sign-in-field-actions-contact')
class EmployeeSignInFieldActionsContact extends AbstractSignInFieldActionsContactModel {
  @belongsTo('employee-sign-in-field-action-rule-group')
  declare signInFieldActionRuleGroup: AsyncBelongsTo<EmployeeSignInFieldActionRuleGroupModel>;
}

export default EmployeeSignInFieldActionsContact;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'employee-sign-in-field-actions-contact': EmployeeSignInFieldActionsContact;
  }
}

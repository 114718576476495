import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import announcementChangeset from 'garaje/changesets/announcement';
import { later } from '@ember/runloop';

/**
 * @param {object.Announcement}   announcement              optional announcement. if passed, it is for editing
 * @param {Function}              handleClose               optional action to trigger close callback if inside a modal
 * @param {boolean}               readOnly                  if user can edit the fields
 */
export default class NewAnnouncementForm extends Component {
  @service flashMessages;
  @service store;
  @service router;
  @service state;
  @service workplaceMetrics;

  /**
   * @type {boolean}
   */
  @tracked didAttemptToSubmit = false;
  @tracked changeset;

  constructor() {
    super(...arguments);
    const { currentCompany: company, currentLocation: location } = this.state;

    later(() => {
      if (!this.args.announcement) {
        this.announcement = this.store.createRecord('announcement', {
          company,
          location,
        });
      } else {
        this.announcement = this.args.announcement;
        this.readOnly = true;
      }
      this.changeset = announcementChangeset(this.announcement);
    });
  }

  @action
  onDestroy() {
    if (this.announcement.isNew && !this.announcement.isDestroying) {
      this.announcement.unloadRecord();
    }
  }

  /**
   * @returns {void} Saves an announcement
   */
  @dropTask
  *saveAnnouncementTask() {
    event.preventDefault();
    const { changeset, flashMessages, router, args } = this;

    yield changeset.validate();

    if (changeset.isInvalid) {
      this.didAttemptToSubmit = true;
      return;
    }

    try {
      this.workplaceMetrics.trackEvent('POST_ANNOUNCEMENT_BUTTON_CLICKED');
      yield changeset.save();

      flashMessages.showAndHideFlash('success', 'Announcement sent');
      args.handleClose && args.handleClose();
      this.workplaceMetrics.trackEvent('POST_ANNOUNCEMENT_SUCCESSFUL');

      if (router.currentRouteName.includes('landing-page')) {
        router.transitionTo('workplace.announcements');
      }
    } catch (err) {
      this.workplaceMetrics.trackEvent('POST_ANNOUNCEMENT_FAILED');
      flashMessages.showAndHideFlash('error', `Error: ${err}`);
    }
  }
}

// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

// eslint-disable-next-line ember/no-classic-classes
export default Component.extend({
  locations: service(),

  normalizedFilters: computed('filters.[]', function () {
    return this.filters.map(function (filter) {
      filter['location-id'] = filter['location-id'].toString();
      return filter;
    });
  }),
});

import type { AsyncBelongsTo } from '@ember-data/model';
import { belongsTo } from '@ember-data/model';
import AbstractSignInFieldActionModel from 'garaje/models/abstract/abstract-sign-in-field-action';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import type SignInFieldActionRuleGroupModel from 'garaje/models/sign-in-field-action-rule-group';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('v3-coalesce')
class SignInFieldActionModel extends AbstractSignInFieldActionModel {
  /**
   * Parent
   */
  @belongsTo('sign-in-field') declare signInField: AsyncBelongsTo<SignInFieldModel>;
  @belongsTo('sign-in-field', { inverse: 'actionableSignInFieldActions' })
  declare actionableSignInField: AsyncBelongsTo<SignInFieldModel>;
  @belongsTo('sign-in-field-action-rule-group')
  declare signInFieldActionRuleGroup: AsyncBelongsTo<SignInFieldActionRuleGroupModel>;
}

export default SignInFieldActionModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'sign-in-field-action': SignInFieldActionModel;
  }
}

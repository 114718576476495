import ApplicationSerializer from './application';

export default class LocationExportJobSerializer extends ApplicationSerializer {
  modelNameFromPayloadKey(key) {
    key = super.modelNameFromPayloadKey(...arguments);

    if (key === 'entry-export-job') {
      return 'location-export-job';
    } else {
      return key;
    }
  }

  payloadKeyFromModelName(modelName) {
    modelName = super.payloadKeyFromModelName(...arguments);

    if (modelName === 'location-export-jobs') {
      return 'entry-export-jobs';
    } else {
      return modelName;
    }
  }

  attrs = {
    status: {
      serialize: false,
    },
    progress: {
      serialize: false,
    },
    url: {
      serialize: false,
    },
    totalCount: {
      serialize: false,
    },
    // Leftover from extending entry export model
    location: {
      serialize: false,
    },
  };
}

import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { classify } from '@ember/string'; // classify() converts a string into UpperCamelCase form
import type IsOpenService from 'garaje/services/is-open';

export default class extends Helper {
  @service declare isOpen: IsOpenService;

  compute([name]: [name: string]): boolean {
    const method = `is${classify(name)}Open` as keyof IsOpenService;
    return this.isOpen[method] as boolean;
  }
}

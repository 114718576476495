import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { get } from '@ember/object';
import { service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

export default class LocationOverviewEmployeesScimSettingsRoute extends Route {
  @service abilities;
  @service currentLocation;
  @service integrationsManager;
  @service router;
  @service state;
  @service store;
  @service locations;
  @service scimPrimaryLocation;

  titleToken = 'Sync settings';

  beforeModel() {
    if (this.abilities.cannot('visit sync-settings')) {
      this.router.transitionTo('unauthorized');
    }
  }

  async model() {
    const { currentLocation, currentCompany } = this.state;

    this.locations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
    await this.integrationsManager.loadIntegration(currentCompany.id);

    const employeeGroupRoles = this.store.findAll('employee-group-role', {
      include: 'locations,employee-group',
    });

    let scimPrimaryLocationConfig = undefined;
    let scimPrimaryLocationMappings = [];
    const scimIntegration = await currentCompany.scimIntegration;
    if (scimIntegration) {
      scimPrimaryLocationConfig = await scimIntegration.scimPrimaryLocationConfiguration;
    }

    if (scimPrimaryLocationConfig?.id) {
      await this.scimPrimaryLocation.fetchDistinctMappingValues(scimPrimaryLocationConfig.id);
      scimPrimaryLocationMappings = await this.store.query('scim-primary-location-mapping', {
        include: 'location',
        reload: true,
        page: { limit: 200 }, // max page size
      });
    }

    return hash({
      plugins: this.store.query('plugin', {
        filter: { location: currentLocation.id },
      }),
      pluginInstalls: this.store.query('plugin-install', {
        filter: { location: currentLocation.id },
        page: { limit: 100 },
      }),
      employeeGroupRoles,
      scimPrimaryLocationConfig,
      scimPrimaryLocationMappings,
    });
  }

  afterModel(model) {
    const syncPlugins = model.plugins.filterBy('category', 'sync');
    const syncPluginInstalls = model.pluginInstalls.filter((install) => {
      return syncPlugins.findBy('id', get(install, 'plugin.id')) !== undefined;
    });
    const hasPlatform =
      syncPluginInstalls.filter(function (install) {
        return install.status !== 'uninstalled';
      }).length > 0;

    if (!this.integrations.length && !hasPlatform) {
      this.router.transitionTo(
        `${
          this.router.currentRouteName?.includes('location-overview') ? 'location-overview.' : ''
        }employees.integrations`,
      );
    }
  }

  deactivate() {
    super.deactivate(...arguments);
    this.controller.integration = '';
  }

  confirmAction() {
    return window.confirm('You will lose any unsaved changes. Are you sure you want to continue?');
  }

  get integrations() {
    return this.integrationsManager.getIntegrations(this.currentLocation);
  }

  @routeEvent
  routeWillChange(transition) {
    const currentLocation = this.currentLocation;
    const hasDirtyIntegrations = this.integrationsManager.hasDirtyIntegrations(currentLocation);

    if (hasDirtyIntegrations) {
      if (this.confirmAction()) {
        this.integrationsManager.cleanIntegrations(currentLocation);

        return true;
      } else {
        transition.abort();

        return false;
      }
    } else {
      return true;
    }
  }
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type TenantModel from 'garaje/models/tenant';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

interface TokenFormArgs {
  /**
   * tenant model
   */
  tenant: TenantModel;
  /**
   * closure to call when close button is clicked
   */
  onClose: () => unknown;
}

export default class PropertySettingsTenantsTokenFormComponent extends Component<TokenFormArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare featureFlags: FeatureFlagsService;

  @tracked showPrompt = true;

  get title(): string {
    if (this.showPrompt) {
      return 'Generate token';
    } else {
      return 'Copy tenant connection token';
    }
  }

  @action
  copySuccess(): void {
    this.flashMessages.showAndHideFlash('success', 'Connection token copied.');
  }

  regenerateTokenTask = task(async () => {
    this.showPrompt = false;

    try {
      await this.args.tenant.regenerateToken();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}

import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import { localCopy } from 'tracked-toolbox';

interface SignInFieldsTextAreaComponentArgs {
  canDelete: boolean;
  deleteFieldAction: (_: unknown) => unknown;
  disabledRequired: boolean;
  field: object;
  handleItem: unknown;
  isConditional?: boolean;
  isDisabled?: boolean;
  isRTL: boolean;
}

export default class SignInFieldsTextAreaComponent extends Component<SignInFieldsTextAreaComponentArgs> {
  @localCopy('args.canDelete', true) declare canDelete: boolean;
  @localCopy('args.isDisabled', false) declare isDisabled: boolean;
  @localCopy('args.placeholder', 'Question') declare placeholder: string;

  inputId = 'input-' + guidFor(this);

  get isDraggable(): boolean {
    return !this.args.isConditional && !this.args.isDisabled;
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task, timeout } from 'ember-concurrency';

export default class ButtonExamples extends Component {
  // Use this for the Async button example
  @tracked isDisabled = false;

  // Use this for the Async button example
  @task
  *asyncTask() {
    yield timeout(1500);
  }

  // Use this for the Async button example
  @action
  toggleDisabled() {
    this.isDisabled = !this.isDisabled;
  }
}

import Controller from '@ember/controller';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { defer } from 'rsvp';

export default class DeliveriesSettingsDeliveryAreasDeliveryAreaDevicesIndexController extends Controller {
  @service flashMessages;

  @dropTask
  confirmDeleteLastDeviceTask = {
    *perform() {
      const deferred = defer();

      this.abort = () => deferred.resolve(false);
      this.continue = () => deferred.resolve(true);

      return yield deferred.promise;
    },
  };

  @dropTask
  *deleteDeviceTask(device) {
    const { deliveryArea, devices } = this.model;
    const isLastDeviceForDeliveryArea = get(devices, 'length') === 1;

    if (isLastDeviceForDeliveryArea && get(deliveryArea, 'requiresSecurePickUp')) {
      const confirmed = yield this.confirmDeleteLastDeviceTask.perform();

      if (!confirmed) {
        return;
      }
    }

    try {
      yield device.destroyRecord();

      this.flashMessages.showAndHideFlash('success', 'Device deleted');

      if (get(devices, 'length') < 1) {
        this.transitionToRoute('deliveries.settings.delivery-areas.delivery-area.devices.new'); // eslint-disable-line ember/no-deprecated-router-transition-methods
      }
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', 'Device could not be deleted. Please try again.');
    }
  }

  @action
  deleteDevice(device) {
    this.deleteDeviceTask.perform(device);
  }
}

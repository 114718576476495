import { Audiences } from '../types/audience';

export const MESSAGE_AUDIENCE_TYPES = {
  [Audiences.Self]: 'Just you',
  [Audiences.AllEmployeesCheckedIn]: 'Employees checked in',
  [Audiences.AllEmployeesWithReservation]: 'Employees scheduled but not checked in',
  [Audiences.AllEmployeesAtDefaultLocation]: 'Employees with primary location of %{location_name}',
  [Audiences.AllEmployeesAtLocation]: 'Employee directory of %{location_name}',
  [Audiences.AllVisitorsCheckedIn]: 'Visitors signed in',
  [Audiences.AllVisitorsWithReservation]: 'Visitors invited but not signed in',
  [Audiences.AllVisitorsSignedOut]: 'Visitors signed out',
};

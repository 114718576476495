import RmsAdapter from './rms';

export default class NeighborhoodAdapter extends RmsAdapter {
  urlForFindAll(_, { adapterOptions }) {
    const url = super.urlForFindAll(...arguments);
    return adapterOptions.fetchCaptains
      ? `${url}?filter[location-id]=${adapterOptions.locationId}&include=captains`
      : `${url}?filter[location-id]=${adapterOptions.locationId}`;
  }
}

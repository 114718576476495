import Component from '@glimmer/component';
import moment from 'moment-timezone';

function filterMe(events, firstDay, lastDay) {
  return (events || []).filter(function (e) {
    const isBetween = e.isBetween(firstDay, lastDay);
    return isBetween;
  });
}

/**
 * @param {Date}        currentMonth
 * @param {Date}        selectedStartDate
 * @param {Date}        selectedEndDate
 * @param {Array}       selectedDates
 * @param {Boolean}     respectSchedulingLimits
 * @param {Boolean}     hideLeadingDayCells
 * @param {Boolean}     hideTrailingDayCells
 * @param {Array}       events
 * @param {Function}    selectDate
 */
export default class CalendarGrid extends Component {
  get weeksArray() {
    const { currentMonth } = this.args;
    const firstDayOfMonth = moment(currentMonth).startOf('month');
    const firstDayOfWeek = moment(firstDayOfMonth).startOf('week');
    const lastDayOfWeek = moment(firstDayOfWeek).endOf('week');
    const lastDayOfMonth = moment(currentMonth).endOf('month');
    const weeks = [];

    let snapshot;

    while (firstDayOfWeek.isBefore(lastDayOfMonth)) {
      snapshot = firstDayOfWeek.clone();
      const snapshotMinusOne = snapshot.clone().subtract(1, 'days');
      const lastDayOfWeekSnapshotPlusOne = lastDayOfWeek.clone().add(1, 'days');

      const events = filterMe(this.args.events, snapshotMinusOne, lastDayOfWeekSnapshotPlusOne);

      weeks.push({ startDate: snapshot, events });

      firstDayOfWeek.add(1, 'week');
      lastDayOfWeek.add(1, 'week');
    }

    return weeks;
  }

  get headerDays() {
    const firstDayOfWeek = moment.localeData().firstDayOfWeek();
    const days = [];
    let day;

    for (let i = 0; i < 7; i++) {
      day = moment.weekdaysShort((firstDayOfWeek + i) % 7);
      days.push(day);
    }

    return days;
  }
}

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { localCopy } from 'tracked-toolbox';

const HOURS = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const MINUTES = [...Array(60).keys()];
const AM = 'am';
const PM = 'pm';

type TimeParts = {
  hour: number;
  minute: number;
  ampm: string;
};

function padMinutes(minutes: number): string {
  return minutes <= 9 ? `0${minutes}` : `${minutes}`;
}

function parseTime(time: string): TimeParts {
  return {
    hour: parseInt(time.split(':')[0]!, 10),
    minute: parseInt(time.split(':')[1]!.split(' ')[0]!, 10),
    ampm: time.split(' ')[1]!,
  };
}

function formatTime(hour: number, minute: number, ampm: string): string {
  return `${hour}:${padMinutes(minute)} ${ampm}`;
}

function isDivisibleBy(numerator: number, denominator: number): boolean {
  return numerator % denominator === 0;
}

function generateTimes(hours: number[], minutes: number[], ampm: string): string[] {
  return hours.reduce(
    (times: string[], hour: number) => times.concat(minutes.map((minute) => formatTime(hour, minute, ampm))),
    [],
  );
}

function convertTimeToInt(time: string): number {
  const { hour, minute, ampm } = parseTime(time);
  return (hour === 12 ? 0 : hour * 60) + minute + (ampm === PM ? 12 * 60 : 0);
}

function roundTimeDownBy(time: string, increment: number): string {
  const { hour, minute, ampm } = parseTime(time);
  const roundedMinute = Math.floor(minute / increment) * increment;
  return formatTime(hour, roundedMinute, ampm);
}

function roundTimeUpBy(time: string, increment: number): string {
  const objTime = parseTime(time);
  const { hour, minute } = objTime;
  let { ampm } = objTime;
  let roundedMinute = Math.ceil(minute / increment) * increment;
  let roundedHour = hour;
  // rounding minutes to h:00 of next hour
  if (roundedMinute === 60) {
    roundedHour += 1;
    roundedMinute = 0;
  }
  // rounding from 11am to 12pm
  if (hour === 11 && roundedHour === 12 && ampm === AM) {
    ampm = PM;
  }
  // rounding from 12pm to 1pm
  if (roundedHour === 13 && ampm === PM) {
    roundedHour = 1;
  }
  return formatTime(roundedHour, roundedMinute, ampm);
}

function filterTimes(time = '', times: string[] = []) {
  let result: string[] = [];
  if (time.match(/^([0-9]|1[0-2])/)) {
    const parts = time.split(':');
    const hour = parts[0]!.replace(/\D/g, '');
    const partialTime = hour + (parts[1] ? ':' + parts[1].slice(0, 1) : '');
    try {
      result = times.filter((t) => t.match(new RegExp(`^${partialTime}`)));
    } catch (e) {
      // Fail silenty on error with bad input for time
      if (e instanceof SyntaxError) {
        return [];
      } else {
        throw e;
      }
    }
  }
  return result;
}

function expandAbbreviatedTime(time = '') {
  let result = time;
  const matched = time.match(/^([0-9]|1[0-2]) ?(a|p)m?$/);
  if (matched !== null) {
    let hour, period;
    [time, hour, period] = matched;
    if (hour && period) {
      result = `${hour}:00 ${period}m`;
    }
  } else {
    result = time.match(/(a|p)$/) !== null ? `${time}m` : time;
    if (result.match(/am|pm/) !== null) {
      result = result.split(/am|pm/)[0]!.trim() + ' ' + result.slice(-2);
    }
  }
  return result;
}

interface TimePickerSignature {
  Args: {
    ariaLabelledBy?: string;
    ariaLabel?: string;
    increment?: number;
    inputClass?: string;
    isDisabled?: boolean;
    isReadonly?: boolean;
    max?: string;
    min?: string;
    onSelectTime: (time: string) => void;
    placeholder?: string;
    time: string | null;
  };
}

export default class TimePicker extends Component<TimePickerSignature> {
  @localCopy('args.increment', 15) increment!: number;
  @localCopy('args.max', '11:59 pm') readonly max!: string | null;
  @localCopy('args.max', '11:59 pm') maxTime!: string | null | undefined;
  @localCopy('args.min', '12:00 am') readonly min!: string | null;
  @localCopy('args.min', '12:00 am') minTime!: string | null | undefined;

  get roundedMin(): string | null {
    // for min time, we round up to make sure no time below the min is available
    if (this.minTime) return roundTimeUpBy(this.minTime, this.increment);
    return null;
  }

  get roundedMax(): string | null {
    // for max time, we round down to make sure no time above the max is available
    if (this.maxTime) return roundTimeDownBy(this.maxTime, this.increment);
    return null;
  }

  get roundedTime(): string | null {
    if (this.args.time) return roundTimeDownBy(this.args.time, this.increment);
    return null;
  }

  get times(): string[] {
    const incrementedMinutes = MINUTES.filter((minute) => isDivisibleBy(minute, this.increment));
    // Here we generate an array of all the times of the given increment
    let times = [...generateTimes(HOURS, incrementedMinutes, AM), ...generateTimes(HOURS, incrementedMinutes, PM)];
    //Here we find the indexes of the rounded min and max values so that the array can be sliced accordingly
    // if the roundedMin isn't present in the times array, just use the first time
    const minIndex = !this.roundedMin || times.indexOf(this.roundedMin) === -1 ? 0 : times.indexOf(this.roundedMin);
    // if the roundedMax isn't present in the times array, just use the last time
    const maxIndex =
      !this.roundedMax || times.indexOf(this.roundedMax) === -1 ? times.length - 1 : times.indexOf(this.roundedMax);
    // return the times of the given increment between the given min and max times
    times = times.slice(minIndex, maxIndex + 1);
    if (this.min !== this.minTime) {
      times = times.filter((t) => {
        const hour = this.minTime?.split(':')[0] ?? '';
        const result = t.match(new RegExp(hour));
        return result !== null && result.index === 0;
      });
    }
    return times;
  }

  @action
  didInput(value: string): string {
    let time = value.trim();
    const filteredTimes = filterTimes(time, this.times);
    this.minTime = filteredTimes.length > 0 ? filteredTimes[0] : this.min;
    this.maxTime = filteredTimes.length > 0 ? filteredTimes[filteredTimes.length - 1] : this.max;
    time = expandAbbreviatedTime(time);
    return time;
  }

  @action
  validateTime(time: string): boolean {
    const isValidTime = /^([0-9]|1[0-2]):[0-5][0-9] (am|pm)$/.test(time);
    if (!isValidTime) {
      return false;
    } else {
      const timeValue = convertTimeToInt(time);
      const isAfterMin = !this.min || timeValue >= convertTimeToInt(this.min);
      const isBeforeMax = !this.max || timeValue <= convertTimeToInt(this.max);
      return isAfterMin && isBeforeMax;
    }
  }
}

import { Changeset } from 'ember-changeset';
import { type DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import type TicketCategoryEmailModel from 'garaje/models/ticket-category-email';
import ticketCategoryEmailValidations from 'garaje/validations/ticket-category-email';

type TicketCategoryEmailChangeset = DetailedChangeset<TicketCategoryEmailModel>;

export default function (ticketCategoryEmail: TicketCategoryEmailModel): TicketCategoryEmailChangeset {
  const validator = lookupValidator(ticketCategoryEmailValidations);

  return <TicketCategoryEmailChangeset>Changeset(ticketCategoryEmail, validator, ticketCategoryEmailValidations);
}

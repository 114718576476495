import Service, { service } from '@ember/service';
import type Store from '@ember-data/store';
import type DeviceModel from 'garaje/models/device';
import type CurrentLocationService from 'garaje/services/current-location';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { type SingleResponse } from 'jsonapi/response';

export type DeviceResponse = SingleResponse<DeviceModel> & { event: string; state: string; provider: string };

export default class DevicesManagerService extends Service {
  @service declare store: Store;
  @service declare currentLocation: CurrentLocationService;
  @service declare flashMessages: FlashMessagesService;

  checkDevicesStatus(devices: DeviceModel[]): void {
    this.currentLocation.setDeviceStatus(devices);
  }

  handleEvent(payload: DeviceResponse): void {
    let device: DeviceModel | undefined;

    if (payload.event === 'badge_print_error') {
      return this.reportBadgePrintError();
    }

    if (payload.data?.type === 'devices') {
      const store = this.store;
      switch (payload.state) {
        case 'bearer_token_issued':
        case 'heartbeat':
          switch (payload.provider) {
            case 'ipad':
            case 'envoy visitor registration (ios)': // client name for visitor kiosk (JWT)
              device = <DeviceModel>store.push(store.normalize('device', payload.data));

              if (payload.state !== 'bearer_token_issued') {
                this.checkDevicesStatus([device]);
              }
              break;
          }
          break;
      }
    }
  }

  reportBadgePrintError(): void {
    this.flashMessages.showFlash('error', 'There was a problem printing your custom badge content');
  }
}

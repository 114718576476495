import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { Task } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';

interface MFAChallengeComponentSignature {
  Args: {
    changeEmail: (email: string) => void;
    email: string;
    redirectToEnvoy: (path: string) => void;
    sendMfaCode: Task<void, []>;
    verifyMfaCode: Task<void, [string, boolean, boolean?]>;
  };
}

const CODE_LENGTH = 6;

export default class MFAChallengeComponent extends Component<MFAChallengeComponentSignature> {
  @service declare store: StoreService;

  codeLength = CODE_LENGTH;
  formId = `${guidFor(this)}-form`;

  @tracked code = new TrackedArray<string>(new Array(CODE_LENGTH).fill(''));

  @action
  handleInput(event: InputEvent): void {
    const input = event.target as HTMLInputElement;
    const value = input?.value;
    const index = parseInt(input.dataset['index'] as string);

    // only 1 digitnumbers, ${CODE_LENGTH} digit numbers or blank (backspace) values are allowed
    const validValueRegex = new RegExp(`^$|^\\d$|^\\d{${CODE_LENGTH}}$`);
    if (!validValueRegex.test(value)) {
      input.value = this.code[index] ?? ''; // maintain value on invalid inout
      input.select();
      return;
    }

    if (value.length === 0) {
      // backspace input
      this.code[index] = value;
    } else if (value.length === 1) {
      // single digit input
      this.code[index] = value;

      // focus on the next input if not at the end or deleting this input's value
      const nextInput = input.nextElementSibling as HTMLInputElement | null;
      if (nextInput && value !== '') nextInput.focus();
    } else if (index === 0) {
      // copy pasting a multi-digit code in the first input
      Array.from(value).forEach((digit, idx) => {
        const input = document.querySelector(`#${this.formId} input[data-index="${idx}"]`) as HTMLInputElement;
        if (input) input.value = digit;
        this.code[idx] = digit;
      });

      // focus the last code input
      const lastInput = document.querySelector(`#${this.formId} input[data-index="${CODE_LENGTH - 1}"]`);
      if (lastInput) (lastInput as HTMLInputElement).focus();
    } else {
      // noop, pasting a multi digit code into an input other than the first
      input.value = this.code[index] ?? '';
      input.select();
    }

    // if it is a full code try verifying
    if (this.mfaCode.length === CODE_LENGTH) {
      this.verifyMfaCode(true);
    }
  }

  @action
  handleBackspace(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    const prevInput = input.previousElementSibling as HTMLInputElement | null;

    // focus the previous input if current input is empty
    if (input.value === '') prevInput?.focus();
  }

  @action
  selectInputContent(event: FocusEvent): void {
    const input = event.target as HTMLInputElement;

    requestAnimationFrame(() => {
      input.select();
    });
  }

  @action
  verifyMfaCode(quiet: boolean): void {
    const rememberDeviceElement = <HTMLInputElement>(
      document.querySelector(`#${this.formId} input[name="remember-device"]`)
    );
    const rememberDevice = rememberDeviceElement.checked;
    void this.args.verifyMfaCode.perform(this.mfaCode, rememberDevice, quiet);
  }

  get mfaCode(): string {
    return this.code.join('');
  }
}

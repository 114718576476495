import Ember from 'ember';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { dasherize } from '@ember/string';
import { htmlSafe } from '@ember/template';
import { get } from '@ember/object';
import { isPresent, isBlank } from '@ember/utils';
const { escapeExpression } = Ember.Handlebars.Utils;
import moment from 'moment-timezone';

// Models needed for translations
import AgreementPage from 'garaje/models/agreement-page';
import Badge from 'garaje/models/badge';
import BlacklistFilter from 'garaje/models/blacklist-filter';
import DeviceConfig from 'garaje/models/config';
import Flow from 'garaje/models/flow';
import Location from 'garaje/models/location';
import PhotoPage from 'garaje/models/photo-page';
import ScimIntegration from 'garaje/models/scim-integration';
import SummaryPage from 'garaje/models/summary-page';

const SIGN_IN_FIELD_OPTIONS = Object.freeze([
  'storeResponse',
  'allowVisitorRespondents',
  'allowEmployeeRespondents',
  'autoPopulatePreviousResponse',
]);

class ChangelogModel extends Model {
  @belongsTo('user') user;
  @belongsTo('location') parentLocation;
  @hasMany('location') locations;

  @attr('date') createdAt;
  @attr('array') items;
  @attr('array') flowNames;
  @attr('string') event;

  get day() {
    return moment(this.createdAt).format('MMMM DD, YYYY');
  }

  get hour() {
    return moment(this.createdAt).format('h:mm a');
  }

  get verb() {
    const items = this.items;
    const firstItem = items[0];
    const event = this.event;
    const type = firstItem.type;
    const customVerb =
      typeof this._customVerbLogicForType[type] === 'function'
        ? this._customVerbLogicForType[type](items, event)
        : null;

    if (customVerb) {
      return customVerb;
    }

    if (event === 'create') {
      if (type === 'devices') {
        return 'paired';
      } else if (type === 'entry_export_jobs') {
        return 'exported';
      } else if (type === 'bulk_invites') {
        return 'created';
      } else {
        return 'added';
      }
    }

    if (event === 'destroy') {
      return 'deleted';
    }

    if (items.length === 1) {
      const [oldValue, newValue] = firstItem.value;
      if (typeof oldValue === 'boolean') {
        return newValue ? 'enabled' : 'disabled';
      } else if (type === 'entries') {
        return 'edited';
      } else if (isBlank(oldValue)) {
        return 'set';
      } else if (isBlank(newValue)) {
        return 'disabled';
      } else if (type === 'scim_integrations') {
        if (this._isSyncAllUsers(newValue)) {
          return 'removed';
        } else if (this._isSyncAllUsers(oldValue)) {
          return 'added';
        } else if (firstItem.field === 'accessTokenId') {
          return 'regenerated';
        }
      }
    }

    return 'changed';
  }

  get translatedChange() {
    const attributeTuples = [
      ['agreements', AgreementPage],
      ['badges', Badge],
      ['blacklist_filters', BlacklistFilter],
      ['device_configs', DeviceConfig],
      ['flows', Flow],
      ['locations', Location],
      ['pages', PhotoPage],
      ['summary_attributes', SummaryPage],
      ['scim_integrations', ScimIntegration],
    ];
    const items = this.items;
    const event = this.event;
    if (items[0]?.type === 'sign_in_fields') {
      // Special case for sign in fields
      const boldText = SIGN_IN_FIELD_OPTIONS.includes(items[0].field) ? 'sign-in field options' : 'sign-in fields';
      return htmlSafe(
        `<span class="bold-attr">${boldText}</span> for "${escapeExpression(this.flowNames[0])}" visitor type`,
      );
    } else if (event === 'create') {
      // Handle creation wording
      return this._creationWording();
    } else if (event === 'destroy') {
      // Handle destroy wording
      return this._destroyWording(attributeTuples);
    } else if (items.length > 1) {
      // Multiple attributes changed
      return this._multipleAttributesChanged(attributeTuples, items);
    } else {
      // Single attribute changed
      return this._singleAttributeChanged(attributeTuples, items);
    }
  }

  get _customVerbLogicForType() {
    return {
      sign_in_fields: function (items, event) {
        const firstItem = items[0];

        if (event !== 'update') {
          return null;
        }

        return SIGN_IN_FIELD_OPTIONS.includes(firstItem?.field) ? 'updated' : null;
      },
    };
  }

  _singleAttributeChanged(attributeTuples, items) {
    // We don't want `from` `to` wording for these attributes
    const noFromAndTo = ['css', 'body', 'imageFileUrls', 'filterRules', 'accessTokenId'];
    const colorTypes = ['color', 'welcomeBackgroundColor'];
    let changeWording = '';
    const item = items[0];
    const tuple = attributeTuples.find((tuple) => {
      const [attr, _model] = tuple;
      return attr === item.type;
    });
    const isColor = colorTypes.includes(item.field);
    changeWording = this._generateWording(item, tuple && tuple[1]);
    // Don't show "`from` to `to`" text" if:
    // * values are boolean
    // * old value was `null` or empty
    // * the field changed is a css field
    // * the field changed belongs to an entry
    // * the field is a filter rule - we'll use the big box format similar to NDA
    // * the field is an access token
    if (
      isPresent(item.value) &&
      isPresent(item.value[0]) &&
      isPresent(item.value[1]) &&
      typeof item.value[1] !== 'boolean' &&
      !noFromAndTo.includes(item.field) &&
      item.type !== 'entries'
    ) {
      const [oldValue, newValue] = this._cleanedValues(item);
      if (isColor) {
        changeWording += this._colorWording(item.value);
      } else {
        changeWording += ` from <i>${escapeExpression(oldValue)}</i> to <i>${escapeExpression(newValue)}</i>`;
      }
    }
    return htmlSafe(changeWording);
  }

  _cleanedValues(item) {
    const urlsType = ['welcomeImage', 'logo', 'photo'];
    if (urlsType.includes(item.field)) {
      // This extracts a file name from an url
      return item.value.map((value) => value.replace(/.+?(?=\/)\//g, ''));
    }
    return item.value;
  }

  _multipleAttributesChanged(attributeTuples, items) {
    const changeWording = [];
    attributeTuples.forEach((tuple) => {
      const [attr, model] = tuple;
      items.forEach((item) => {
        if (attr === item.type) {
          const word =
            (model.OVERWRITABLE_SETTINGS && model.OVERWRITABLE_SETTINGS[item.field]) ||
            dasherize(item.field).replace(/-/g, ' ');
          changeWording.push(word);
        }
      });
    });
    if (items[0].type === 'blacklist_filters') {
      return `a blocked person's ${changeWording.join(', ')}`;
    } else {
      return changeWording.join(', ');
    }
  }

  _colorWording(values) {
    let [oldValue, newValue] = values;
    oldValue = escapeExpression(oldValue);
    newValue = escapeExpression(newValue);
    const oldColor = `<i>${oldValue}</i><div style='background-color:${oldValue}' class='color-box'>&nbsp;</div>`;
    const newColor = `<i>${newValue}</i><div style='background-color:${newValue}' class='color-box'>&nbsp;</div>`;
    return ` from ${oldColor} to ${newColor}`;
  }

  _isSyncAllUsers(value) {
    return value.type === 'locations' && isBlank(value.data);
  }

  _creationWording() {
    const item = this.items[0];
    let [oldValue, newValue] = item.value;
    oldValue = escapeExpression(oldValue);
    newValue = escapeExpression(newValue);
    switch (item.type) {
      case 'printers':
        return htmlSafe(`<span class="bold-attr">${newValue}</span> printer`);
      case 'entry_export_jobs':
        if (isBlank(oldValue)) {
          // exported all data
          return htmlSafe(`all-time visitor data`);
        } else if (oldValue === newValue) {
          // exported data for a specigic date
          return htmlSafe(`visitor data for <i>${moment(oldValue).format('MMMM DD, YYYY')}</i>`);
        } else {
          // exported data for a date range
          const formattedOld = moment(oldValue).format('MMMM DD, YYYY');
          const formattedNew = moment(newValue).format('MMMM DD, YYYY');
          return htmlSafe(`visitor data for <i>${formattedOld}</i> - <i>${formattedNew}</i>`);
        }
      case 'devices':
        return htmlSafe(`<span class="bold-attr">${newValue.replace(' iPad', '')}</span> iPad`);
      case 'locations':
        return htmlSafe(`a new location: <span class="bold-attr">${newValue}</span>`);
      case 'flows':
        return htmlSafe(`"${newValue}" visitor type`);
      case 'entries':
        return htmlSafe(`<span class="bold-attr">${newValue}</span>'s visitor entry`);
      case 'bulk_invites':
        return htmlSafe(`<span class="bold-attr">${newValue}</span> invites`);
      case 'blacklist_filters':
        return htmlSafe('a blocked person');
      case 'scim_integrations':
        return htmlSafe(`<span class="bold-attr">${newValue}</span> SCIM integration`);
    }
  }

  _destroyWording(attributeTuples) {
    const item = this.items[0];
    switch (item.type) {
      case 'printers':
        return htmlSafe(`<span class="bold-attr">${escapeExpression(item.value[0])}</span> printer`);
      case 'devices':
        return htmlSafe(`<span class="bold-attr">${escapeExpression(item.value[0]).replace(' iPad', '')}</span> iPad`);
      case 'flows':
        return htmlSafe(`"${escapeExpression(item['flow-name'])}" visitor type`);
      case 'entries':
        return htmlSafe(`<span class="bold-attr">${escapeExpression(item.value[0])}</span>'s visitor entry`);
      case 'blacklist_filters':
        return htmlSafe('a blocked person');
      case 'scim_integrations':
        return htmlSafe(`<span class="bold-attr">${escapeExpression(item.value[0])}</span> SCIM integration`);
      default:
        return this._singleAttributeChanged(attributeTuples, this.items);
    }
  }

  _generateWording(item, model = {}) {
    // Get the human readable string for a given field if defined on the model.
    const translated =
      (model.OVERWRITABLE_SETTINGS && model.OVERWRITABLE_SETTINGS[item.field]) ||
      dasherize(item.field).replace(/-/g, ' ');

    const defaultFlowsMessage = `<span class="bold-attr">${translated}</span>`;

    switch (item.type) {
      case 'flows':
      case 'pages':
      case 'agreements':
        // Special case for flows (pages and agreements are also related with flows)
        if (item.field === 'enabled' && item.type === 'flows') {
          return `"${escapeExpression(item['flow-name'])}" visitor type on the iPad`;
        }
        if (item.field === 'name') {
          return 'visitor type <span class="bold-attr">name</span>';
        }
        return `<span class="bold-attr">${translated}</span> for "${escapeExpression(item['flow-name'])}" visitor type`;

      case 'locations':
        if (item.field === 'disabled') {
          return `<span class="bold-attr">${escapeExpression(get(this, 'locations.firstObject.name'))}</span> location`;
        }
        return defaultFlowsMessage;

      case 'badges':
        return `<span class="bold-attr">${translated}</span> for "${escapeExpression(this.flowNames[0])}" visitor type`;

      case 'devices':
        return `device <span class="bold-attr">${translated}</span>`;

      case 'entries':
        return `<span class="bold-attr">${escapeExpression(item.value[1])}</span>'s visitor entry`;

      case 'welcome_screen_slides':
        return 'the <span class="bold-attr">welcome screen slideshow</span>';

      case 'blacklist_filters':
        return `a blocked person's ${translated}`;

      case 'scim_integrations':
        return `<span class="bold-attr">SCIM ${translated}</span>`;

      default:
        return defaultFlowsMessage;
    }
  }
}

export default ChangelogModel;

import Controller from '@ember/controller';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StateService from 'garaje/services/state';
import { alias, equal, or } from 'macro-decorators';

export default class LocationOverviewSignInFlowsShowController extends Controller {
  @service declare state: StateService;
  @service declare router: RouterService;

  @alias('state.vrSubscription') vrSubscription!: StateService['vfdSubscription'];
  @equal('router.currentRouteName', 'location-overview.sign-in-flows.show.legal-documents.new')
  onNewLegalDocumentRoute!: boolean;
  @equal('router.currentRouteName', 'location-overview.sign-in-flows.show.legal-documents.edit')
  onEditLegalDocumentRoute!: boolean;
  @or('onEditLegalDocumentRoute', 'onNewLegalDocumentRoute') onNewOrEditLegalDocumentRoute!: boolean;
}

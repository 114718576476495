import Service, { service } from '@ember/service';
import { dasherize, underscore } from '@ember/string';
import type { Attributes } from '@splitsoftware/splitio/types/splitio';
import type LocationModel from 'garaje/models/location';
import type MetricsService from 'garaje/services/metrics';
import type SplitioService from 'garaje/services/splitio';
import type { TrafficType } from 'garaje/services/splitio';
import type StatsigService from 'garaje/services/statsig';
import { Promise as EmberPromise, all } from 'rsvp';

export default class FeatureFlagsService extends Service {
  @service declare metrics: MetricsService;
  @service declare splitio: SplitioService;
  @service declare statsig: StatsigService;

  isBooted = false;
  isStatsigBooted = false;
  isTimedOut = false;
  trafficType: TrafficType = 'company';

  features: Set<string> = new Set();

  constructor(properties: Record<string, unknown>) {
    super(properties);
    const splitio = this.splitio;
    splitio.on(`${this.trafficType}_update`, this, 'notifyFeatures');
    splitio.on(`${this.trafficType}_timeout`, this, 'toggleIsTimedOut');

    const statsig = this.statsig;
    statsig.on('update', this, 'notifyStatsigGates');
    statsig.on('error', this, 'toggleIsTimedOut');
  }

  notifyFeatures(): void {
    this.isBooted = true;
    this._notifyPropertyChanges();
  }

  notifyStatsigGates(): void {
    this.isStatsigBooted = true;
    this._notifyPropertyChanges();
  }

  _notifyPropertyChanges(): void {
    this.features.forEach((feature) => {
      // eslint-disable-next-line ember/classic-decorator-no-classic-methods
      this.notifyPropertyChange(feature);
    });
  }

  toggleIsTimedOut(): void {
    this.isTimedOut = true;
  }

  isEnabled(key: string, attributes?: Attributes): boolean {
    const name = dasherize(key);
    const gateName = underscore(key);
    return this.splitio.isEnabled(this.trafficType, name, attributes) || this.statsig.isEnabled(gateName, true);
  }

  // Used to check eligibility of user to see feature gate experience, does not log statsig exposure for pulse metrics
  isEnabledStatsigExposure(key: string, checkWithExposure = false): boolean {
    const gateName = underscore(key);
    return this.statsig.isEnabled(gateName, checkWithExposure);
  }

  unknownProperty(key: string): boolean {
    const features = this.features;
    const name = dasherize(key);
    // We add the key to features so later on we know to notify property change
    // if split updates
    features.add(key);
    return this.isEnabled(name);
  }

  setKey(key: string): void {
    this.splitio.setKey(key, this.trafficType);
  }

  setProperty(value: LocationModel): void {
    this.splitio.setKey(value.id, this.trafficType);
    this.statsig.setProperty(this.trafficType, value);
  }

  // @ts-ignore
  setProperties(props: Record<string, unknown>): void {
    Object.keys(props).forEach((prop) => {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.splitio.setKey(props[prop].id, prop);
    });
    this.statsig.setPropertiesBatch(props);
  }

  ready(): Promise<unknown> {
    try {
      if (this.isBooted && this.isStatsigBooted) {
        return EmberPromise.resolve();
      } else if (this.isTimedOut) {
        return EmberPromise.reject();
      } else {
        const splitPromise = new EmberPromise((resolve) => {
          this.splitio.on(`${this.trafficType}_update`, this, () => resolve());
          this.splitio.on(`${this.trafficType}_timeout`, this, () => {
            this.metrics.logMonitorError({ event: 'SPLIT_SDK_TIMEOUT' });
            resolve();
          });
        });

        const statsigPromise = new EmberPromise((resolve, reject) => {
          this.statsig.on('update', this, () => resolve());
          this.statsig.on('wait', this, () => resolve());
          this.statsig.on('error', this, reject);
        });

        return all([splitPromise, statsigPromise]);
      }
    } catch (error) {
      /* eslint-disable no-console */
      console.error({ error });
      /* eslint-enable no-console */
      return EmberPromise.reject();
    }
  }
}

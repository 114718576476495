import Model, { attr } from '@ember-data/model';

export const DELIVERIES_PAUSED_ALERT_TITLE = 'Your package scanning has been paused';
export const DELIVERIES_PAUSED_ALERT_MESSAGE =
  'You’ve hit the 100 package limit and need to upgrade to Deliveries Premium to continue scanning.';
export const DELIVERIES_PAUSED_ALERT_MESSAGE_FOR_NON_ADMIN =
  'Global or Billing Admins need to upgrade to Deliveries Premium to continue scanning packages.';

export default class DeliveryLimitModel extends Model {
  @attr('boolean', { defaultValue: false }) declare enforceLimit: boolean;
  @attr('boolean', { defaultValue: false }) declare limitExceeded: boolean;
  @attr('number', { defaultValue: 0 }) declare monthlyLimit: number;
  @attr('number', { defaultValue: 0 }) declare monthlyLimitUsage: number;
  @attr('date') declare resumeAt: Date;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'delivery-limit': DeliveryLimitModel;
  }
}

import gql from 'graphql-tag';

export default gql`
  fragment EmployeeFragment on Employee {
    id
    name
    email
  }
`;

// department
// attendance {
//   averageDaysPerWeek
// }

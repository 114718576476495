import Component from '@glimmer/component';
import { action, get, set } from '@ember/object';
import { filter } from 'macro-decorators';
import { SIGN_IN_FIELD_KINDS } from 'garaje/utils/enums';
import { task } from 'ember-concurrency';
import { localCopy } from 'tracked-toolbox';

const configurableFieldsFilterFn = (field) => {
  return get(field, 'identifier') !== 'host' && get(field, 'kind') === SIGN_IN_FIELD_KINDS.SINGLE_SELECTION;
};

/**
 * @param {Array<SignInField>} signInFields         Required. Sign in fields
 * @param {Flow}               flow                 Required. Used to set storeResponseConfig
 * @param {Task}               saveFieldsTask       Required. Pass through of task to save fields
 * @param {Boolean}            isDisabled           Optional. Indicate inputs are entirely disabled
 * @param {Boolean}            isPristine           Required. Indicate unsaved changes
 * @param {Function}           onChange             Optional. Action to take when data modified
 */
export default class SignInFieldsDataStorageComponent extends Component {
  @localCopy('args.signInFields') signInFields;

  optionsName = 'data-storage';

  @filter('signInFields', configurableFieldsFilterFn)
  configurableDropdownFields;

  onChange(field, attr) {
    if (typeof this.args.onChange === 'function') {
      this.args.onChange(field, attr);
    }
  }

  @action
  storeField(field) {
    if (!get(field, 'storeResponse')) {
      this.onChange(field, 'storeResponse');
    }

    set(field, 'storeResponse', true);
  }

  @action
  discardField(field) {
    if (get(field, 'storeResponse')) {
      this.onChange(field, 'storeResponse');
    }

    set(field, 'storeResponse', false);
  }

  @task
  *saveDataStorageOptionsTask() {
    this.args.flow.storeResponseConfig = 'managed';

    yield this.args.flow.save();
    yield this.args.saveFieldsTask.perform();
  }
}

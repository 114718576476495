import { service } from '@ember/service';
import { isBlank, isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import type StateService from 'garaje/services/state';
import { LOCATION_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_VISIT_ROLES = [LOCATION_ADMIN];

export default class MyLocationAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare skinnyLocations: SkinnyLocationsService;
  @service declare state: StateService;

  get canVisit(): boolean {
    const { vrSubscription } = this.state;
    // Company doesn't have VR
    if (isBlank(vrSubscription)) {
      return false;
    }

    const hasCorrectRoles = isPresent(_intersection(CAN_VISIT_ROLES, this.currentAdmin.allLocationRoleNames));
    const isOnCorrectPlan = !vrSubscription?.isBasicUser;
    const hasMultipleLocationsHasLocationAdmin = this.skinnyLocations.readableByLocationAdmin.length > 1;
    return hasCorrectRoles && isOnCorrectPlan && hasMultipleLocationsHasLocationAdmin;
  }
}

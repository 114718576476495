import Route from '@ember/routing/route';
import { hash } from 'rsvp';

export default class DeliveriesLogDeliverySignOutPhotoRoute extends Route {
  model() {
    const { delivery } = this.modelFor('deliveries.log.delivery');

    return hash({
      delivery,
    });
  }
}

import type Owner from '@ember/application';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type EmergencyNotificationConfigurationModel from 'garaje/models/emergency-notification-configuration';
import type ImpressionsService from 'garaje/services/impressions';
import type StateService from 'garaje/services/state';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';

interface PhoneNumberCollectionArgs {
  emergencyNotificationConfiguration: EmergencyNotificationConfigurationModel | null;
  userHasPhoneNumber: boolean;
}

export default class PhoneNumberCollection extends Component<PhoneNumberCollectionArgs> {
  @service declare router: RouterService;
  @service declare workplaceMetrics: WorkplaceMetricsService;
  @service declare impressions: ImpressionsService;
  @service declare state: StateService;

  @tracked dismissed: boolean = false;

  constructor(owner: Owner, args: PhoneNumberCollectionArgs) {
    super(owner, args);
    void this.isPreviouslyDismissed.perform();
  }

  get isVisible(): boolean {
    return (
      !!this.args.emergencyNotificationConfiguration?.passivePhoneNumberCollectionEnabledAt &&
      !this.state.currentCompany?.directorySyncProvider &&
      !!this.state.features?.canAccessEmergencyNotifications &&
      !this.args.userHasPhoneNumber
    );
  }

  @action
  async trackRedirect(): Promise<void> {
    this.workplaceMetrics.trackEvent('EMERGENCY_NOTIFICATIONS_PHONE_NUMBER_BANNER_CTA_CLICKED');
    await this.impressions.postImpression.perform(IMPRESSION_NAMES.EMERGENCY_NOTIFICATIONS.PHONE_NUMBER_BANNER_CLICKED);
  }

  @action
  async dismiss(): Promise<void> {
    this.dismissed = true;
    await this.impressions.postImpression.perform(
      IMPRESSION_NAMES.EMERGENCY_NOTIFICATIONS.PHONE_NUMBER_BANNER_DISMISSED,
    );
  }

  isPreviouslyDismissed = task({ drop: true }, async () => {
    const closedImpressions = await this.impressions.getImpressions.perform(
      IMPRESSION_NAMES.EMERGENCY_NOTIFICATIONS.PHONE_NUMBER_BANNER_DISMISSED,
    );
    this.dismissed = isPresent(closedImpressions);
  });
}

import { A } from '@ember/array';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type AccessEventModel from 'garaje/models/access-event';
import type LocationModel from 'garaje/models/location';
import type { AccessEventsDashboardField } from 'garaje/pods/workplace/access-events/index/controller';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';

/**
 * @param {Class<AccessEvent>}          accessEvent
 * @param {Array}                       fieldOptions
 * @param {Boolean}                     fullDate
 * @param {object}                      location
 */

interface AccessEventsTableDashboardRowComponentArgs {
  location: LocationModel;
  fullDate: boolean;
  fieldOptions: AccessEventsDashboardField[];
  accessEvent: AccessEventModel;
}

export default class DashboardRow extends Component<AccessEventsTableDashboardRowComponentArgs> {
  @service declare state: StateService;
  @service declare featureFlags: FeatureFlagsService;

  get fieldCount(): number {
    return A(this.args.fieldOptions ?? []).filterBy('show').length;
  }
}

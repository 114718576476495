import JSONAPISerializer from '@ember-data/serializer/json-api';

import { isPresent } from '@ember/utils';
import formatRelationshipsLinks from 'garaje/utils/relationships-formatter';
import embeddedRelationship from 'garaje/utils/embedded-relationship';
import { getStatusCode } from 'garaje/utils/parse-error';

export default class extends JSONAPISerializer {
  normalizeResponse(store, typeClass, payload) {
    embeddedRelationship(payload, typeClass);
    return super.normalizeResponse(...arguments);
  }

  normalize(typeClass, hash) {
    let { relationships } = hash;
    if (isPresent(relationships)) {
      relationships = formatRelationshipsLinks(relationships);
    }

    return super.normalize(...arguments);
  }

  extractErrors(store, typeClass, payload) {
    const code = Number(getStatusCode(payload));

    payload.errors?.forEach((error) => {
      // handle missing error source as ember data internals now expects them
      if (!error.source) error.source = { pointer: '/data' };
      error.meta = { status: error.status };
    });

    // add missing status code now stripped from ErrorClass by ember data internals
    if (!Number.isNaN(code)) payload.code = code;

    return super.extractErrors(...arguments);
  }
}

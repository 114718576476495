import type NativeArray from '@ember/array/-private/native-array';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type UserDocumentModel from 'garaje/models/user-document';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

import type { ProfileDocumentRouteModel } from '../route';

export default class ProfileDocumentIndexController extends Controller {
  declare model: ProfileDocumentRouteModel;
  @service declare flashMessages: FlashMessagesService;

  @action
  async deleteUserDocument(userDocument: UserDocumentModel): Promise<void> {
    try {
      await userDocument.destroyRecord();
      this.flashMessages.showAndHideFlash('success', 'Document deleted!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  get userDocumentLink(): UserDocumentModel['userDocumentLink'] {
    // TODO - filter document links by current company once
    // userDocumentLinkable polymorphic relationship exists
    return this.userDocument.userDocumentLink;
  }

  get approvalStatus(): string | undefined {
    return this.userDocument.isExpired ? 'expired' : this.userDocumentLink?.approvalStatus;
  }

  get userDocument(): UserDocumentModel {
    return this.userDocumentHistory.firstObject!;
  }

  get userDocumentHistory(): NativeArray<UserDocumentModel> {
    return this.model.userDocuments.sortBy('sortableDate').reverseObjects();
  }
}

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import { alias, collect } from 'macro-decorators';
import { buildChangeset, FakeChangeset } from 'garaje/utils/legal-document';

export default class extends Component {
  @service state;
  @service metrics;
  @service router;
  @service currentAdmin;
  @service flashMessages;

  @alias('state.vrSubscription') vrSubscription;
  @collect('bccChangeset', 'ndaChangeset') changesets;

  get bccChangeset() {
    return buildChangeset(this.args.agreement);
  }

  get ndaChangeset() {
    return buildChangeset(this.args.agreement);
  }

  get isSaved() {
    return !this.args.agreement.isNew && !this.args.agreement.hasDirtyAttributes;
  }

  get shouldRenderDelete() {
    if (!this.args.flow.canEdit) {
      return false;
    }

    return this.args.agreements.length > 1;
  }

  get changeset() {
    const changesets = get(this, 'changesets') ?? [];
    return new FakeChangeset(changesets);
  }
}

import { setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import { keepLatestTask, didCancel } from 'ember-concurrency';
import type ConnectLocationConfigurationModel from 'garaje/models/connect-location-configuration';
import type EmployeeModel from 'garaje/models/employee';
import type FallbackContactModel from 'garaje/models/fallback-contact';
import type LocationModel from 'garaje/models/location';
import type PluginModel from 'garaje/models/plugin';
import type PluginInstallModel from 'garaje/models/plugin-install';
import type TenantModel from 'garaje/models/tenant';
import type GlobalSettingBatchService from 'garaje/services/global-setting-batch';
import type StateService from 'garaje/services/state';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

import type VisitorsSettingsNotificationsController from './controller';

export interface SettingsNotificationsRouteModel {
  connectedTenants: RecordArray<TenantModel>;
  connectLocationConfiguration?: ConnectLocationConfigurationModel;
  currentLocation: LocationModel;
  config: unknown;
  employees: RecordArray<EmployeeModel>;
  vrSubscription: StateService['vrSubscription'];
  fallbackContacts: RecordArray<FallbackContactModel> | null;
  plugins: RecordArray<PluginModel>;
  pluginInstalls: RecordArray<PluginInstallModel>;
}

class SettingsNotificationsRoute extends Route {
  declare controller: VisitorsSettingsNotificationsController;

  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service('global-setting-batch') declare globalSettingBatchService: GlobalSettingBatchService;
  @service declare transitionConfirm: TransitionConfirmService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  titleToken = 'Notification Settings';

  beforeModel(): void {
    if (this.abilities.cannot('visit advance-settings')) {
      this.router.transitionTo('billing');
    }
  }

  async model(): Promise<SettingsNotificationsRouteModel> {
    const { currentLocation, vrSubscription } = this.state;
    const config = currentLocation.config;
    const fallbackContacts = this.fetchFallbackContactsTask.perform();

    if (this.abilities.can('propagate global-setting-batch')) {
      const globalSettingBatchService = this.globalSettingBatchService;
      globalSettingBatchService.createGlobalSettingBatch({ parent: currentLocation });
    }

    const employees = this.store.query('employee', {
      page: { offset: 0, limit: 1 },
      filter: {
        deleted: false,
        locations: currentLocation.id,
      },
    });

    const connectedTenants = await currentLocation.getPropertyConnections();

    let connectLocationConfiguration;

    if (connectedTenants.length) {
      // will create the value in BE if it doesn't exist
      connectLocationConfiguration = (
        await this.store.query('connect-location-configuration', {
          filter: {
            location: currentLocation.id,
          },
        })
      ).firstObject;
    }

    return hash({
      connectedTenants,
      connectLocationConfiguration,
      currentLocation,
      config,
      employees,
      vrSubscription,
      fallbackContacts: fallbackContacts.value,
      plugins: this.store.query('plugin', {
        filter: { location: currentLocation.id },
      }),
      pluginInstalls: this.store.query('plugin-install', {
        filter: { location: currentLocation.id },
      }),
    });
  }

  setupController(
    controller: VisitorsSettingsNotificationsController,
    model: SettingsNotificationsRouteModel,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    setProperties(controller, {
      model: model.currentLocation,
      employees: model.employees,
      fallbackContacts: model.fallbackContacts,
      vrSubscription: model.vrSubscription,
      fallbackContactsTask: this.fetchFallbackContactsTask,
      reloadFallbackContacts: this.reloadFallbackContacts.bind(this),
      installedPlugins: model.pluginInstalls.filterBy('status', 'active'),
      connectedTenants: model.connectedTenants,
      connectLocationConfiguration: model.connectLocationConfiguration,
    });

    if (!controller.hasNotificationEnabled) {
      controller.showBlankState = true;
    }
  }

  deactivate(transition: Transition): void {
    super.deactivate(transition);
    // eslint-disable-next-line ember/no-controller-access-in-routes
    setProperties(this.controller, { showBlankState: false, feature: '' });
  }

  fetchFallbackContactsTask = keepLatestTask({ cancelOn: 'deactivate' }, async () => {
    return await this.store.query('fallback-contact', {
      filter: {
        location: this.state.currentLocation.id,
      },
    });
  });

  reloadFallbackContacts(): Promise<unknown> {
    return this.fetchFallbackContactsTask.perform().catch((e) => {
      if (!didCancel(e)) {
        throw e;
      }
    });
  }

  @routeEvent
  routeWillChange(transition: Transition): void | boolean {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const controller = this.controller;
    if (!controller.hasDirtyChanges) {
      return true;
    } else {
      void this.transitionConfirm.displayConfirmTask.perform(transition, {
        continue() {
          controller.send('rollbackDirtyChanges');
        },
      });
    }
  }
}

export default SettingsNotificationsRoute;

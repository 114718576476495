import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, set } from '@ember/object';
import { alias, reads, or } from 'macro-decorators';
import { service } from '@ember/service';
import moment from 'moment-timezone';
import urlBuilder from 'garaje/utils/url-builder';
import zft from 'garaje/utils/zero-for-tests';
import { dropTask } from 'ember-concurrency';
import { defer } from 'rsvp';

/**
 * @param {Component<ListItemCheckbox>} checkbox
 * @param {Function}                    checkID
 * @param {String}                      context - The context in which the component is being used ("location" or "property"). Passed to EntryApprovalAbility#canReview.
 * @param {Function}                    deleteEntry
 * @param {Class<Entry>}                entry
 * @param {Array}                       eventReports
 * @param {Array}                       fieldOptions
 * @param {Boolean}                     fullDate
 * @param {Boolean}                     hasPhoto
 * @param {Boolean}                     isSelected
 * @param {object}                      location
 * @param {Array}                       printers
 * @param {Function}                    reprintBadge
 * @param {Moment}                      selectedDate
 * @param {Boolean}                     showSignedOut
 * @param {Function}                    signOutTask
 * @param {Class<Subscription>}         vrSubscription
 * @param {String}                      linkToRoute
 * @param {String}                      userType
 */
export default class EntryFeedItem extends Component {
  @service abilities;
  @service ajax;
  @service currentAdmin;
  @service featureFlags;
  @service metrics;
  @service router;
  @service state;
  @service store;
  @service windowLocation;
  @service workplaceMetrics;

  @tracked displayPrinterSelectorModal = false;

  @tracked itemFullNameWasTruncated = false;
  @tracked itemHostWasTruncated = false;

  tooltipDelay = zft(400);

  @alias('state.currentCompany') currentCompany;
  @alias('args.vrSubscription.canEnableBadgePrinting') canReprintBadge;
  @reads('currentAdmin.canDeleteVisitor') canDeleteVisitor;
  @or('canReferToEnvoy', 'canReprintBadge', 'canSignOutVisitor', 'canDeleteVisitor', 'canManageReservation')
  menuHasActions;

  get canManageReservation() {
    if (this.isForVisitor) {
      return false;
    }

    return this.abilities.can('edit reservation') || this.abilities.can('delete reservation');
  }

  // colspan for the denied message is equal the the number of shown fieldOptions minus 1 for the name field, which is already in the row and is not included in the colspan
  get deniedColspan() {
    return (this.args.fieldOptions ?? []).filter((field) => field.show).length - 1;
  }

  get canSignOutVisitor() {
    const entry = this.args.entry;

    // cannot sign out entry if it was not signed in
    if (!entry.signInTime) return false;

    // cannot sign out entry if it's already signed out
    if (entry.signedOutAt) return false;

    // cannot sign out entry is approval was denied
    if (entry.approvalWasDenied) return false;

    // otherwise, entry can be signed out if user has 'edit' ability for it
    return this.abilities.can('edit entry', entry);
  }

  get cannotReviewEntry() {
    return this.abilities.cannot('review entry-approval', {
      context: this.args.context ?? 'location',
      report: this.args.entry.approvalStatus.failedReport,
    });
  }

  get visitorEmail() {
    return this.getUserDataField(this.args.entry, 'Your Email Address');
  }

  get visitorFullName() {
    return this.getUserDataField(this.args.entry, 'Your Full Name');
  }

  get canReferToEnvoy() {
    return !!this.visitorEmail;
  }

  get referralCode() {
    return `c${get(this.currentCompany, 'id')}u${get(this.currentAdmin, 'id')}`;
  }

  get referralUrl() {
    return urlBuilder.signupReferralUrl(this.referralCode);
  }

  get isWorkplaceRoute() {
    return this.router.isActive('workplace');
  }

  get referralMailtoLink() {
    const subject = `Interested in ${get(this, 'currentCompany.name')}'s visitor sign-in system?`;
    const bcc = 'referrals@envoy.com';
    const body = `

      Hi ${get(this, 'visitorFullName')},

      When you signed in on the iPad today, you experienced Envoy — our digital sign in system.

      If you're interested in trying Envoy for yourself, just visit this link to sign up: ${get(this, 'referralUrl')}

      Once you sign up, email referrals@envoy.com to connect with the Envoy team and claim your special referral discount.

      Best,
      ${get(this, 'currentAdmin.fullName')}
      ${get(this, 'currentCompany.name')}

    `
      .trim()
      .replace(/\n +/g, '\n')
      .replace(/\n/g, '%0D%0A');
    return `mailto:${get(this, 'visitorEmail')}?subject=${subject}&bcc=${bcc}&body=${body}`;
  }

  get showInDate() {
    const date = this.args.selectedDate ?? moment();
    const signedInAt = moment(this.args.entry.signedInAt);
    return !date.isSame(signedInAt, 'day');
  }

  get showOutDate() {
    const date = this.args.selectedDate || moment();
    const signedOutAt = moment(this.args.entry.signedOutAt);
    return !date.isSame(signedOutAt, 'day');
  }

  get isForVisitor() {
    return this.args.userType === 'visitor';
  }

  get showEditOption() {
    const featureEnabled = this.abilities.can('see features for dbeam');
    return featureEnabled && !this.isForVisitor && !this.entry.signedInAt && this.abilities.can('edit reservation');
  }

  get showLegacyReservationOptions() {
    const featureFlagEnabled = this.abilities.can('see features for dbeam');
    return !featureFlagEnabled || this.isForVisitor;
  }

  get showDeleteReservationOption() {
    return this.canDeleteReservation && !this.entry.signedInAt;
  }

  get showEndReservationOption() {
    return this.canDeleteReservation && this.entry.signedInAt && !this.entry.signedOutAt;
  }

  get canDeleteReservation() {
    const featureFlagEnabled = this.abilities.can('see features for dbeam');
    return featureFlagEnabled && !this.isForVisitor && this.abilities.can('delete reservation');
  }

  get entry() {
    return this.args.entry;
  }

  get entryReservation() {
    return this.args.entry.reservations.firstObject;
  }

  getUserDataField(entry, fieldName) {
    const userData = get(entry, 'userData') ?? [];
    const userDataField = userData.findBy('field', fieldName);
    return userDataField && get(userDataField, 'value');
  }

  @action
  trackOpenActionMenu(entry_id) {
    this.metrics.trackEvent('Entry Overflow Opened', { entry_id });
  }

  trackReprintBadgeRequested(entry) {
    const entry_id = parseInt(entry.id, 10);
    const properties = { action_origin: 'visitor_log', entry_id };
    this.metrics.trackEvent('Reprint Entry Badge Requested', properties);
  }

  @action
  trackClickEntryDetail(entryId) {
    this.metrics.trackEvent('Entry Details Clicked', { entry_id: entryId });
  }

  @action
  referToEnvoy() {
    this.windowLocation.assign(this.referralMailtoLink);
  }

  @action
  reprintBadge(entry, printer) {
    this.args.reprintBadge(entry, printer);
    this.trackReprintBadgeRequested(entry);
  }

  @dropTask
  reservationModalTask = {
    *perform() {
      this.context.workplaceMetrics.trackEvent('EMPLOYEE_LOG_EDIT_RESERVATION_LINK_CLICKED', {
        entryId: this.context.entry?.id,
        reservationId: this.context.entryReservation?.id,
      });

      const deferred = defer();
      this.abort = () => {
        this.context.workplaceMetrics.trackEvent('EMPLOYEE_LOG_EDIT_RESERVATION_FLOW_ABORTED', {
          entryId: this.context.entry?.id,
          reservationId: this.context.entryReservation?.id,
        });

        deferred.resolve(false);
      };
      this.continue = () => {
        this.context.args.loadEntries.perform();

        this.context.workplaceMetrics.trackEvent('EMPLOYEE_LOG_EDIT_RESERVATION_FLOW_COMPLETED', {
          entryId: this.context.entry?.id,
          reservationId: this.context.entryReservation?.id,
        });

        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  @dropTask
  showDeleteConfirmationTask = {
    *perform() {
      this.context.workplaceMetrics.trackEvent('EMPLOYEE_LOG_DELETE_RESERVATION_LINK_CLICKED', {
        entryId: this.context.entry?.id,
        reservationId: this.context.entryReservation?.id,
      });

      const deferred = defer();
      this.abort = () => {
        this.context.workplaceMetrics.trackEvent('EMPLOYEE_LOG_DELETE_RESERVATION_FLOW_ABORTED', {
          entryId: this.context.entry?.id,
          reservationId: this.context.entryReservation?.id,
        });

        deferred.resolve(false);
      };
      this.continue = () => {
        this.context.args.loadEntries.perform();

        this.context.workplaceMetrics.trackEvent('EMPLOYEE_LOG_DELETE_RESERVATION_FLOW_COMPLETED', {
          entryId: this.context.entry?.id,
          reservationId: this.context.entryReservation?.id,
        });

        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  @dropTask
  *deleteReservationTask() {
    const reservation = this.entryReservation;

    try {
      if (!this.entry.signedInAt) {
        yield this.entry.destroyRecord();
      } else {
        set(this.entry, 'signOutTime', new Date());
        yield this.entry.save();
      }

      if (!reservation) {
        return;
      }

      const url = urlBuilder.rms.releaseDesk();
      yield this.ajax.request(url, {
        type: 'POST',
        contentType: 'application/vnd.api+json',
        data: {
          data: {
            attributes: {
              'reservation-id': reservation.id,
            },
          },
        },
      });

      this.store.unloadRecord(reservation);
      this.metrics.trackEvent('DelegatedBooking_Used', {
        action: 'delete',
        reservationId: reservation.id,
        partialDay: reservation.isPartialDay,
      });
    } catch (e) {
      this.workplaceMetrics.logMonitorError({
        event: 'RESERVATION_MODAL_FAILED_TO_DELETE_RESERVATION',
        debugExtras: {
          isDbeam: true,
          action: 'delete',
          entryId: this.entry?.id,
          reservationId: reservation?.id,
        },
        error: e,
      });

      throw e;
    }
  }
}

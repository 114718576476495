import JSONAPISerializer from 'ember-data/serializers/json-api';

export default class InvoiceSerializer extends JSONAPISerializer {
  modelNameFromPayloadKey(payloadKey) {
    if (payloadKey === 'payments') {
      return 'invoice';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }

  payloadKeyFromModelName(modelName) {
    if (modelName === 'invoice') {
      return 'payments';
    } else {
      return super.payloadKeyFromModelName(...arguments);
    }
  }
}

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class SubscriptionsIndexRoute extends Route {
  @service('location-billing') billingService;
  @service router;

  title = 'Billing · Subscriptions';

  beforeModel() {
    if (this.billingService.blockSelfServe) {
      this.router.transitionTo('location-billing');
    }
  }
}

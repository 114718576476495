import { SpaceDashboardInput } from 'garaje/graphql/generated/assigned-and-unassigned-employees-types';
import { Exact, Scalars } from 'garaje/graphql/generated/feature-config-types';

export enum FeaturesWithUtilization {
  DESK = 'DESK',
  ROOM = 'ROOM',
}

export type ResourceUtilizationInput = Exact<{
  areaMapID: string;
  floorID: string;
  featureTypes: [FeaturesWithUtilization.DESK, FeaturesWithUtilization.ROOM];
  spaceDashboardInput: SpaceDashboardInput;
}>;

export type FloorFeatureUtilization = {
  __typename: 'MapFloor';
  id: string;
  features: FeatureUtilization;
};

export type FeatureUtilization = Array<DeskUtilization | RoomUtilization>;

export type Utilization = {
  id: string;
  utilization: {
    percentUtilized: number;
  };
};

export type RoomUtilization = {
  __typename: 'RoomMapFeature';
  id: string;
  room: Utilization;
};

export type DeskUtilization = {
  __typename: 'DeskMapFeature';
  id: string;
  desk: Utilization;
};

export type ResourceUtilization = Array<{
  type: FeaturesWithUtilization;
  id: string;
  utilization: number;
}>;

export type GetResourceUtilizationQuery = {
  __typename?: 'Query';
  areaMap: {
    __typename: 'AreaMap';
    id: Scalars['ID'];
    floors: Array<FloorFeatureUtilization>;
  };
};

// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action, computed, set, get } from '@ember/object';

/**
 * @param {Function}              onUpdate
 * @param {Array<Flow>}           visitorTypes
 * @param {Function}              loadVisitorTypes
 */
export default class VisitorTypes extends Component {
  tagName = '';

  // Need to explicitly notify that visitorTypes changed when idScanPage is changed
  // Automatic notification through each async relation does not update
  @computed('visitorTypes.@each.idScanPage')
  get enabledValues() {
    const flows = this.visitorTypes && this.visitorTypes.length ? this.visitorTypes : [];
    return flows.map((vt) => get(vt, 'idScanPage.enabled')).uniq();
  }

  @computed('enabledValues')
  get allSelected() {
    return this.enabledValues.length === 1 && this.enabledValues[0] === true;
  }

  @computed('enabledValues')
  get noneSelected() {
    return this.enabledValues.length === 1 && this.enabledValues[0] === false;
  }

  @action
  deselectAll() {
    this.visitorTypes.forEach((vt) => set(vt, 'idScanPage.enabled', false));
    this.triggerUpdated();
  }

  @action
  selectAll() {
    this.visitorTypes.forEach((vt) => set(vt, 'idScanPage.enabled', true));
    this.triggerUpdated();
  }

  @action
  toggleEnabled(visitorType) {
    set(visitorType, 'idScanPage.enabled', !get(visitorType, 'idScanPage.enabled'));
    this.triggerUpdated();
  }

  triggerUpdated() {
    // eslint-disable-next-line ember/classic-decorator-no-classic-methods
    this.notifyPropertyChange('visitorTypes');
    this.onUpdate({ hasChanges: true });
  }
}

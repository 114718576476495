import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import { capitalize } from '@ember/string';
import Component from '@glimmer/component';
import type EntryModel from 'garaje/models/entry';
import type EventReportModel from 'garaje/models/event-report';

const POSSIBLE_ENTRY_STATUSES = [
  { key: 'emailStatus', eventReportName: 'email_notification', title: 'Email' },
  { key: 'pushStatus', eventReportName: 'push_notification', title: 'Push' },
  { key: 'slackStatus', eventReportName: 'slack_notification', title: 'Slack DM' },
  { key: 'smsStatusNormalized', eventReportName: 'sms_notification', title: 'SMS' },
] as const;

interface ProviderNotificationsArgs {
  entry: EntryModel;
  eventReports?: EventReportModel[];
}

export interface ProviderNotification {
  title: string;
  statusMessage: string;
  done: boolean;
  queued: boolean;
  inProgress: boolean;
  failed: boolean;
  expired: boolean;
  ignored: boolean;
}

export default class ProviderNotificationsComponent extends Component<ProviderNotificationsArgs> {
  @computed('args.entry.{emailStatus,pushStatus,slackStatus,smsStatusNormalized}', 'args.eventReports.@each.status')
  get notifications(): NativeArray<ProviderNotification> {
    const eventReports = this.args.eventReports || [];
    const eventReportNames: string[] = eventReports.map((eventReport) => eventReport.name);

    // filter out duplicate and ignored entry notification statuses, and normalize their format
    const statuses = POSSIBLE_ENTRY_STATUSES.reduce<ProviderNotification[]>((validStatuses, possibleStatus) => {
      const noEventReport = !eventReportNames.includes(possibleStatus.eventReportName);
      const title = possibleStatus.title;
      const status = this.args.entry[possibleStatus.key];
      const shouldBeShown = !(status === 'not_sent' || status === 'disabled');
      if (noEventReport && status && shouldBeShown) {
        validStatuses.push({
          title,
          statusMessage: capitalize(status),
          done: status === 'sent' || status === 'delivered',
          queued: status === 'queued',
          inProgress: status === 'sending',
          failed: status === 'failed' || status === 'undelivered',
          expired: false,
          ignored: false,
        });
      }
      return validStatuses;
    }, []);

    // merge entry statuses with event reports
    return A([...statuses, ...eventReports]).sortBy('title');
  }
}

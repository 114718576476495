/* eslint-disable ember/no-computed-properties-in-native-classes */
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type AbilitiesService from 'ember-can/services/abilities';
import { isProductDisabled } from 'garaje/helpers/product-disabled';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST } from 'garaje/utils/roles';
import { Permission } from 'garaje/utils/ui-permissions';
import _intersection from 'lodash/intersection';

// Checks if a list of roles contains any of the permitted roles
function includesAny(collection: Array<string>, ...values: Array<string>): boolean {
  return isPresent(_intersection(collection, values));
}

export default class DeskAbility extends Ability {
  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare featureConfig: FeatureConfigService;
  @service declare state: StateService;
  @service declare abilities: AbilitiesService;

  @reads('state.features') features!: StateService['features'];
  @reads('currentAdmin.roleNames') roles!: CurrentAdminService['roleNames'];

  get canManage(): boolean {
    return this.featureConfig.isEnabled('desks') && includesAny(this.roles, GLOBAL_ADMIN);
  }

  get canUsePartialDayBooking(): boolean {
    return (
      Boolean(this.features?.canUsePartialDayBooking) &&
      this.featureFlags.isEnabled('partial-day-booking-web') &&
      this.featureConfig.isEnabled('deskReservations.partialDayBooking') &&
      Boolean(this.state.currentLocationEnabledDesks)
    );
  }

  // Admin can see the desk column in the entry log
  get canSeeEntryLog(): boolean {
    const permissions = [Permission.MAPS_FLOOR_READ, Permission.DESKS_DESK_READ, Permission.DESKS_AMENITY_READ];

    if (this.featureFlags.isEnabled('desks-authz-spaces3')) {
      return this.authz.hasAllPermissionsAtCurrentLocation(permissions);
    }

    return includesAny(this.roles, LOCATION_ADMIN, GLOBAL_ADMIN, RECEPTIONIST);
  }

  // Admin can see the desk column in the invite log
  get canSeeInviteLog(): boolean {
    const permissions = [Permission.MAPS_FLOOR_READ, Permission.DESKS_DESK_READ, Permission.DESKS_AMENITY_READ];

    if (this.featureFlags.isEnabled('desks-authz-spaces3')) {
      return this.featureConfig.isEnabled('desks') && this.authz.hasAllPermissionsAtCurrentLocation(permissions);
    }

    return this.featureConfig.isEnabled('desks') && includesAny(this.roles, LOCATION_ADMIN, GLOBAL_ADMIN, RECEPTIONIST);
  }

  // RE: VIS-2423 (temporary fix - P0) https://envoycom.atlassian.net/browse/VIS-2423
  // TODO: delete when removing desks-authz-spaces3 feature flag, no longer needed with proper permissions
  get shouldPreventNonGlobalAdminAccess(): boolean {
    const nonGlobalAdmin = !includesAny(this.roles, GLOBAL_ADMIN);

    return nonGlobalAdmin && !this.featureConfig.isEnabled('desks');
  }

  // TODO: delete when removing desks-authz-spaces3 feature flag, no longer needed with proper permissions
  get shouldAllowLocationAdminToRequestTrial(): boolean {
    const locationAdmin = includesAny(this.roles, LOCATION_ADMIN);

    return locationAdmin && !this.featureConfig.isEnabled('desks');
  }

  // NAVIGATION

  get canVisit(): boolean {
    // cannot visit if user has no workplace sub or legacy desks sub
    if (isProductDisabled('desks') || !this.features?.canAccessDesksApplication) {
      return false;
    }

    if (this.featureFlags.isEnabled('desks-authz-spaces3')) {
      return (
        this.canVisitAllDesks ||
        this.canVisitSettings ||
        this.canVisitReservations ||
        this.canVisitLanding ||
        this.canVisitGetStarted
      );
    }

    if (this.shouldAllowLocationAdminToRequestTrial) {
      return true;
    }

    if (this.shouldPreventNonGlobalAdminAccess) {
      return false;
    }

    return this.canManage || this.canSeeEntryLog || this.canVisitLanding || this.canVisitGetStarted;
  }

  get canVisitAllDesks(): boolean {
    const permissions = [
      Permission.MAPS_FLOOR_READ,
      Permission.DESKS_DESK_READ,
      Permission.DESKS_AMENITY_CREATE,
      Permission.DESKS_AMENITY_UPDATE,
      Permission.DESKS_AMENITY_DELETE,
    ];

    return this.featureFlags.isEnabled('desks-authz-spaces3')
      ? this.featureConfig.isEnabled('desks') && this.authz.hasAllPermissionsAtCurrentLocation(permissions)
      : true;
  }

  get canVisitReservations(): boolean {
    const permissions = [Permission.DESKS_RESERVATION_READ, Permission.MAPS_FLOOR_READ, Permission.DESKS_DESK_READ];

    return this.featureFlags.isEnabled('desks-authz-spaces3')
      ? this.featureConfig.isEnabled('desks') &&
          this.authz.hasAllPermissionsAtCurrentLocation(permissions) &&
          this.featureConfig.isEnabled('deskReservations')
      : this.featureConfig.isEnabled('deskReservations');
  }

  get canVisitSettings(): boolean {
    const permissions = [
      Permission.DESKS_DESK_SETTING_CREATE,
      Permission.DESKS_DESK_SETTING_UPDATE,
      Permission.DESKS_DESK_SETTING_DELETE,
    ];

    return this.featureFlags.isEnabled('desks-authz-spaces3')
      ? this.featureConfig.isEnabled('desks') && this.authz.hasAllPermissionsAtCurrentLocation(permissions)
      : true;
  }

  get canVisitLanding(): boolean {
    const permissions = [
      Permission.DESKS_DESK_CREATE,
      Permission.DESKS_AMENITY_CREATE,
      Permission.DESKS_NEIGHBORHOOD_CREATE,
      Permission.MAPS_FLOOR_CREATE,
    ];

    if (this.featureFlags.isEnabled('desks-authz-spaces3')) {
      return (
        this.authz.hasAllPermissionsAtCurrentLocation(permissions) &&
        (this.canRequestDesksTrial || this.abilities.can('start-workplace-trial billing'))
      );
    }

    return includesAny(this.roles, GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST);
  }

  get canVisitGetStarted(): boolean {
    const permissions = [
      Permission.DESKS_DESK_CREATE,
      Permission.DESKS_AMENITY_CREATE,
      Permission.DESKS_NEIGHBORHOOD_CREATE,
      Permission.MAPS_FLOOR_CREATE,
    ];

    if (this.featureFlags.isEnabled('desks-authz-spaces3')) {
      return this.featureConfig.isEnabled('desks') && this.authz.hasAllPermissionsAtCurrentLocation(permissions);
    }

    return this.featureConfig.isEnabled('desks') && includesAny(this.roles, GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST);
  }

  // TRIALS

  get canRequestDesksTrial(): boolean {
    if (this.featureFlags.isEnabled('desks-authz-spaces3')) {
      return this.authz.hasPermissionAtCurrentLocation(Permission.DESKS_TRIAL_REQUEST_CREATE);
    }

    return includesAny(this.roles, LOCATION_ADMIN);
  }

  // LIVE MAP

  get canShowBulkActions(): boolean {
    const permissions = [
      Permission.MAPS_FLOOR_READ,
      Permission.MAPS_AREA_MAP_READ,
      Permission.MAPS_FEATURE_UPDATE,
      Permission.MAPS_FEATURE_DELETE,
      Permission.DESKS_DESK_UPDATE,
      Permission.DESKS_DESK_DELETE,
    ];

    return this.featureFlags.isEnabled('map-authz-spaces2')
      ? this.authz.hasAllPermissionsAtCurrentLocation(permissions)
      : true;
  }

  get canManageDesk(): boolean {
    const permissions = [
      Permission.MAPS_LIVE_CREATE,
      Permission.MAPS_LIVE_UPDATE,
      Permission.MAPS_LIVE_DELETE,
      Permission.MAPS_FEATURE_CREATE,
      Permission.MAPS_FEATURE_UPDATE,
      Permission.MAPS_FEATURE_DELETE,
      Permission.DESKS_DESK_CREATE,
      Permission.DESKS_DESK_UPDATE,
      Permission.DESKS_DESK_DELETE,
      Permission.DESKS_AMENITY_READ,
      Permission.DESKS_NEIGHBORHOOD_READ,
    ];

    return this.featureFlags.isEnabled('map-authz-spaces2')
      ? this.authz.hasAllPermissionsAtCurrentLocation(permissions)
      : true;
  }

  get canManagePointOfInterest(): boolean {
    const permissions = [
      Permission.MAPS_LIVE_UPDATE,
      Permission.MAPS_FEATURE_CREATE,
      Permission.MAPS_FEATURE_UPDATE,
      Permission.MAPS_FEATURE_DELETE,
    ];

    return this.featureFlags.isEnabled('map-authz-spaces2')
      ? this.authz.hasAllPermissionsAtCurrentLocation(permissions)
      : true;
  }

  // ALL DESKS

  get canViewFloors(): boolean {
    return this.featureFlags.isEnabled('desks-authz-spaces3')
      ? this.authz.hasPermissionAtCurrentLocation(Permission.MAPS_FLOOR_READ)
      : true;
  }

  get canViewFloorDetails(): boolean {
    const permissions = [
      Permission.MAPS_FLOOR_READ,
      Permission.DESKS_NEIGHBORHOOD_READ,
      Permission.DESKS_DESK_READ,
      Permission.DESKS_AMENITY_READ,
    ];

    return this.featureFlags.isEnabled('desks-authz-spaces3')
      ? this.authz.hasAllPermissionsAtCurrentLocation(permissions)
      : true;
  }

  get canManageFloor(): boolean {
    return this.canCreateFloor && this.canEditFloor && this.canDeleteFloor;
  }

  get canCreateFloor(): boolean {
    return this.featureFlags.isEnabled('desks-authz-spaces3')
      ? this.authz.hasPermissionAtCurrentLocation(Permission.MAPS_FLOOR_CREATE)
      : true;
  }

  get canEditFloor(): boolean {
    return this.featureFlags.isEnabled('desks-authz-spaces3')
      ? this.authz.hasPermissionAtCurrentLocation(Permission.MAPS_FLOOR_UPDATE)
      : true;
  }

  get canDeleteFloor(): boolean {
    return this.featureFlags.isEnabled('desks-authz-spaces3')
      ? this.authz.hasPermissionAtCurrentLocation(Permission.MAPS_FLOOR_DELETE)
      : true;
  }

  get canImportDesks(): boolean {
    const permissions = [
      Permission.MAPS_FLOOR_CREATE,
      Permission.MAPS_FLOOR_UPDATE,
      Permission.DESKS_DESK_IMPORT,
      Permission.DESKS_AMENITY_CREATE,
      Permission.DESKS_AMENITY_UPDATE,
      Permission.DESKS_NEIGHBORHOOD_CREATE,
      Permission.DESKS_NEIGHBORHOOD_UPDATE,
    ];

    return this.featureFlags.isEnabled('desks-authz-spaces3')
      ? this.authz.hasAllPermissionsAtCurrentLocation(permissions)
      : true;
  }

  get canExportDesks(): boolean {
    const permissions = [
      Permission.MAPS_FLOOR_READ,
      Permission.DESKS_DESK_EXPORT,
      Permission.DESKS_AMENITY_READ,
      Permission.DESKS_NEIGHBORHOOD_READ,
    ];

    return this.featureFlags.isEnabled('desks-authz-spaces3')
      ? this.authz.hasAllPermissionsAtCurrentLocation(permissions)
      : true;
  }

  // NEIGHBORHOODS

  get canCreateNeighborhood(): boolean {
    return this.featureFlags.isEnabled('map-authz-spaces2')
      ? this.authz.hasPermissionAtCurrentLocation(Permission.DESKS_NEIGHBORHOOD_CREATE)
      : true;
  }

  get canEditNeighborhood(): boolean {
    return this.featureFlags.isEnabled('map-authz-spaces2')
      ? this.authz.hasPermissionAtCurrentLocation(Permission.DESKS_NEIGHBORHOOD_UPDATE)
      : true;
  }

  get canDeleteNeighborhood(): boolean {
    return this.featureFlags.isEnabled('map-authz-spaces2')
      ? this.authz.hasPermissionAtCurrentLocation(Permission.DESKS_NEIGHBORHOOD_DELETE)
      : true;
  }
}

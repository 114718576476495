import Model, { attr, belongsTo, hasMany, type AsyncBelongsTo } from '@ember-data/model';
import type BillingCompanyModel from 'garaje/models/billing-company';
import type CompanyModel from 'garaje/models/company';
import type LocationSubscriptionModel from 'garaje/models/location-subscription';
import type SubscriptionPlanModel from 'garaje/models/subscription-plan';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('billing-v2')
class SubscriptionModificationModel extends Model {
  @belongsTo('company', { async: true, inverse: null }) declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('billing-company', { async: true, inverse: null })
  declare billingCompany: AsyncBelongsTo<BillingCompanyModel>;
  @hasMany('location-subscription', { async: false }) declare locationSubscriptions: LocationSubscriptionModel[];
  @belongsTo('subscription-plan', { async: false }) declare subscriptionPlan: SubscriptionPlanModel | null;

  @attr('number') declare periodStart: number;
}

export default SubscriptionModificationModel;

/* eslint-disable ember/no-get */
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed, get } from '@ember/object';
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import type { SyncHasMany } from '@ember-data/model';
import { APP } from 'garaje/utils/enums';
import { or } from 'macro-decorators';

import type CompanyModel from './company';
import type SubscriptionModificationModel from './subscription-modification';
import type SubscriptionPlanModel from './subscription-plan';

export default class BillingCompanyModel extends Model {
  @belongsTo('company', { async: false }) declare company: CompanyModel;
  @attr('boolean') declare delinquent: boolean;
  @attr('boolean') declare resellerPartner: boolean;
  @attr('boolean') declare hasChildren: boolean;
  @attr('boolean') declare notAllowedSelfServe: boolean;
  @attr('boolean') declare isAchTransfer: boolean;
  @attr('number') declare creditBalance: number; // cents
  @attr('trial-end') declare trialEnd: Record<string, boolean>;
  @hasMany('subscription-plan', { async: false }) declare subscriptionPlans: SyncHasMany<SubscriptionPlanModel>;
  @hasMany('subscription-modification', { async: false })
  declare subscriptionModifications: SyncHasMany<SubscriptionModificationModel>;

  @or('notAllowedSelfServe', 'isAchTransfer')
  declare blockSelfServe: boolean;

  @computed('trialEnd.visitors')
  get visitorsTrialEndDate(): boolean {
    return get(this.trialEnd, 'visitors');
  }
  @computed('trialEnd.deliveries')
  get deliveriesTrialEndDate(): boolean {
    return get(this.trialEnd, 'deliveries');
  }

  @computed('trialEnd.rooms')
  get roomsTrialEndDate(): boolean {
    return get(this.trialEnd, 'rooms');
  }

  @computed('subscriptionPlans.@each.id')
  get visitorsSubscriptionPlan(): SubscriptionPlanModel | undefined {
    return this.subscriptionPlans.find((plan) => {
      return plan.app === APP.VISITORS;
    });
  }

  @computed('subscriptionPlans.@each.id')
  get deliveriesSubscriptionPlan(): SubscriptionPlanModel | undefined {
    return this.subscriptionPlans.find((plan) => {
      return plan.app === APP.DELIVERIES;
    });
  }

  @computed('subscriptionPlans.@each.id')
  get roomsSubscriptionPlan(): SubscriptionPlanModel | undefined {
    return this.subscriptionPlans.find((plan) => {
      return plan.app === APP.ROOMS;
    });
  }

  @computed('subscriptionPlans.@each.app')
  get desksSubscriptionPlan(): SubscriptionPlanModel | undefined {
    return this.subscriptionPlans.find((plan) => {
      return plan.app === APP.DESKS;
    });
  }

  @computed('subscriptionPlans.@each.app')
  get workplaceSubscriptionPlan(): SubscriptionPlanModel | undefined {
    return this.subscriptionPlans.find((plan) => {
      return plan.app === APP.WORKPLACE;
    });
  }

  @computed('subscriptionModifications.@each.id')
  get visitorsSubscriptionModification(): SubscriptionModificationModel | undefined {
    return this.subscriptionModifications.find((mod) => {
      return get(mod, 'subscriptionPlan.app') === APP.VISITORS;
    });
  }

  @computed('subscriptionModifications.@each.id')
  get deliveriesSubscriptionModification(): SubscriptionModificationModel | undefined {
    return this.subscriptionModifications.find((mod) => {
      return get(mod, 'subscriptionPlan.app') === APP.DELIVERIES;
    });
  }

  @computed('subscriptionModifications.@each.id')
  get roomsSubscriptionModification(): SubscriptionModificationModel | undefined {
    return this.subscriptionModifications.find((mod) => {
      return get(mod, 'subscriptionPlan.app') === APP.ROOMS;
    });
  }

  @computed('visitorsSubscriptionModification.subscriptionPlan.details.rank', 'visitorsSubscriptionPlan.rank')
  get visitorsDowngrading(): boolean {
    let downgrading = false;
    if (this.visitorsSubscriptionPlan && this.visitorsSubscriptionModification) {
      downgrading =
        this.visitorsSubscriptionPlan.rank >
        <number>get(this, 'visitorsSubscriptionModification.subscriptionPlan.details.rank');
    }
    return downgrading;
  }

  @computed('deliveriesSubscriptionModification.subscriptionPlan.details.rank', 'deliveriesSubscriptionPlan.rank')
  get deliveriesDowngrading(): boolean {
    let downgrading = false;
    if (this.deliveriesSubscriptionPlan && this.deliveriesSubscriptionModification) {
      downgrading =
        this.deliveriesSubscriptionPlan.rank >
        <number>get(this, 'deliveriesSubscriptionModification.subscriptionPlan.details.rank');
    }
    return downgrading;
  }

  @computed('roomsSubscriptionModification.subscriptionPlan.details.rank', 'roomsSubscriptionPlan.rank')
  get roomsDowngrading(): boolean {
    let downgrading = false;
    if (this.roomsSubscriptionPlan && this.roomsSubscriptionModification) {
      downgrading =
        this.roomsSubscriptionPlan.rank >
        <number>get(this, 'roomsSubscriptionModification.subscriptionPlan.details.rank');
    }
    return downgrading;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'billing-company': BillingCompanyModel;
  }
}

import Component from '@glimmer/component';
import { get } from '@ember/object';
import { isArray } from '@ember/array';
import { inject as service } from '@ember/service';
/**
 * @param {Array<Number>}           adhocPlugins
 * @param {Boolean}                 isEnterprisePlan
 * @param {Object}                  plugin
 * @param {Array<Object>}           pluginInstalls
 * @param {Function}                onUpgrade
 */
export default class PlatformPluginSettingsPanel extends Component {
  @service featureFlags;
  get hasAdhocOverride() {
    return isArray(this.args.adhocPlugins) && this.args.adhocPlugins.includes(this.args.plugin.id);
  }

  get appStore() {
    return this.featureFlags.isEnabled('appStore');
  }

  get integration() {
    return this.isInstalled ? `${this.appStore ? '' : 'install-'}${this.pluginInstall.id}` : null;
  }

  get integrationsRoute() {
    if (this.appStore) return 'apps.dynamic';
    return this.isInstalled ? 'integrations.enabled' : 'integrations';
  }

  get isInstalled() {
    return this.pluginInstall && ['active', 'pending'].includes(this.pluginInstall.status);
  }

  get linkText() {
    return this.isInstalled ? 'Configure settings →' : 'Install →';
  }

  get slug() {
    if (!this.appStore) return '';
    return this.isInstalled ? 'installed' : this.args.plugin?.name?.toLowerCase().replace(/[^a-z0-9]/g, '-');
  }

  get hash() {
    return this.appStore
      ? { install_id: this.integration }
      : { scrollTo: this.scrollTo, integration: this.integration };
  }

  get pluginInstall() {
    return this.args.pluginInstalls.find((pluginInstall) => {
      return get(pluginInstall, 'plugin.id') === this.args.plugin.id && pluginInstall.status !== 'uninstalled';
    });
  }

  get scrollTo() {
    return this.isInstalled ? `pluginInstall-${this.pluginInstall.id}` : this.args.plugin.key;
  }
}

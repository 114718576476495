import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { set } from '@ember/object';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { APP, PLAN_LEVEL } from 'garaje/utils/enums';
import { alias } from 'macro-decorators';
import { action } from '@ember/object';

export default class DesksLandingPageController extends Controller {
  @service featureConfig;
  @service featureFlags;
  @service flashMessages;
  @service localStorage;
  @service metrics;
  @service productActivation;
  @service state;
  @service store;

  @tracked transitionDone;

  @alias('state.currentLocation.isProtectSetup') isProtectSetup;
  @alias('state.visitorsSubscription') visitorsSubscription;
  @alias('state.desksSubscription') desksSubscription;
  @alias('desksSubscription.onTrial') onTrial;

  @tracked showIntakeForm = false;

  get surveyApp() {
    return APP.WORKPLACE;
  }

  @action
  async openIntakeForm() {
    if (this.featureFlags.isEnabled('workplace-garaje-launch')) {
      this.showIntakeForm = true;
    }

    try {
      await this.startTrialTask.perform();
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }

    if (!this.featureFlags.isEnabled('workplace-garaje-launch')) {
      await this.closeIntakeForm();
    }
  }

  @action
  async closeIntakeForm() {
    const { currentCompany } = this.state;
    this.showIntakeForm = false;

    this.flashMessages.showAndHideFlash('success', 'Trial started!');
    this.send('refreshModel');

    this.metrics.trackEvent('Product Landing Page Start Trial Button Clicked', { product: 'desks' });
    this.localStorage.setItem('did_start_desks', currentCompany.id);
    this.transitionToRoute('desks.get-started'); // eslint-disable-line ember/no-deprecated-router-transition-methods
  }

  /**
   * Activates Desks at location if licenses available or on trial
   */
  @dropTask
  *activateDesksTask() {
    const { currentCompany, currentLocation } = this.state;
    const { model } = this;

    let deskLocation = model.currentLocationEnabledDesks;
    if (!deskLocation) {
      deskLocation = this.store.createRecord('desk-location', {
        active: true,
        timeZone: currentLocation.timezone,
        company: currentCompany,
        location: currentLocation,
      });
    } else {
      deskLocation = this.store.peekRecord('desk-location', deskLocation.id);
      set(deskLocation, 'active', true);
    }

    yield deskLocation.save();

    // Reload state service with the new subscription added
    yield this.state.setDeskLocationsTask.perform();

    this.transitionToRoute('desks.get-started'); // eslint-disable-line ember/no-deprecated-router-transition-methods
  }

  /**
   * Creates Desks Subsription (and maybe Visitors) and reloads the global `state` service
   *
   * @param {Event} evt the DOM event
   */
  @dropTask
  *startTrialTask(evt) {
    if (typeof evt?.preventDefault === 'function') {
      evt.preventDefault();
    }
    const { visitorsSubscription } = this;
    const { currentCompany, currentLocation } = this.state;
    try {
      if (this.featureFlags.isEnabled('workplace-garaje-launch')) {
        yield this.productActivation.startWorkplaceTrialTask.perform();
      } else {
        // Create VR Subscription
        if (!visitorsSubscription) {
          this.visitorsSubscription = this.store.createRecord('subscription', {
            app: APP.VISITORS,
            plan: PLAN_LEVEL.PREMIUM,
            company: currentCompany,
          });
          yield this.visitorsSubscription.save();
        }
        const desksSubscription = this.store.createRecord('subscription', {
          app: APP.DESKS,
          plan: PLAN_LEVEL.STANDARD,
          company: currentCompany,
        });
        yield desksSubscription.save();

        const newDeskLocation = this.store.createRecord('desk-location', {
          active: true,
          timeZone: currentLocation.timezone,
          company: currentCompany,
          location: currentLocation,
        });
        yield newDeskLocation.save();
      }

      yield this.featureConfig.fetchFeatureConfigTask.perform(currentLocation.id);
      // Reload state service with the new subscription added
      yield this.state.initSubscriptionStateTask.perform();
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.metrics.trackEvent('Error starting desk trial', { product: 'desks', location: currentLocation });
      this.flashMessages.showAndHideFlash('error', errorText);
      this.localStorage.removeItem('did_start_desks');
    }
  }
}

import type RouterService from '@ember/routing/router-service';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type MetricsService from 'garaje/services/metrics';
import LogRocket from 'logrocket';

const METRIC_LOAD_EVENT_TYPE = 'PAGE_LOAD_METRIC';
const METRIC_USABLE_EVENT_TYPE = 'PAGE_USABLE_METRIC';

export default class PerformanceLoadTime extends Service {
  private routeNameMap: Map<string, string>;
  private ignoreRouteMap: Map<string, boolean>;

  constructor(...args: unknown[]) {
    // @ts-ignore
    super(...args);
    this.routeNameMap = new Map<string, string>([
      ['dashboard.index', 'HOMEPAGE'],
      ['visitors.entries.index', 'VISITORS_LOG'],
      ['schedule', 'EMPLOYEE_SCHEDULE'],
    ]);

    // Routes that should not trigger setting initialRoute
    // This is useful for loading routes that happen before the load time is fired
    this.ignoreRouteMap = new Map<string, boolean>([['visitors.entries.loading', true]]);
  }

  @service declare metrics: MetricsService;
  @service declare router: RouterService;
  @service declare featureFlags: FeatureFlagsService;
  @tracked initialRoute: string | null = null;

  getRouteName(): string | null {
    const currentRouteName = this.router.currentRoute?.name as string | undefined;

    if (currentRouteName) {
      // eslint-disable-next-line ember/use-ember-get-and-set
      const routeName = this.routeNameMap.get(currentRouteName);
      return routeName ?? null;
    }

    return null;
  }

  isRouteSupported(): boolean {
    // Prevent incorrect measurements if the route is included in the map but the page metric
    // Is not logged before the user transitions away from the page

    if (!this.featureFlags.isEnabled('performance_service')) {
      return false;
    }

    if (
      this.initialRoute === null ||
      this.initialRoute === this.router?.currentRoute?.name ||
      this.ignoreRouteMap.has(this.router?.currentRoute?.name)
    ) {
      if (this.ignoreRouteMap.has(this.router?.currentRoute?.name)) {
        return true;
      } else {
        this.initialRoute = this.router?.currentRoute?.name;
        return this.routeNameMap.has(this.router?.currentRoute?.name);
      }
    }
    return false;
  }

  // Use this function to log multiple events that lead up to a page being usable
  logPageLoadMetric(eventName: string): void {
    if (!this.featureFlags.isEnabled('performance_service')) {
      return;
    }

    if (!window.appMeasurementTaken && window.appStartTime && this.isRouteSupported()) {
      const timeDifference = performance.now() - window?.appStartTime;
      const roundedMsTime = Math.floor(timeDifference);
      const roundedSecTime = parseFloat((timeDifference / 1000).toFixed(2));

      this.metrics.logMonitorEvent(`${METRIC_LOAD_EVENT_TYPE}_${eventName}_${this.getRouteName()}`, {
        loadTimeMs: roundedMsTime,
        loadTimeSec: roundedSecTime,
        uriPathEntryPoint: window?.location?.href,
      });

      LogRocket.track(`${METRIC_LOAD_EVENT_TYPE}_${eventName}_${this.getRouteName()}`, {
        loadTimeMs: roundedMsTime,
        loadTimeSec: roundedSecTime,
        uriPathEntryPoint: window?.location?.href,
      });
    }
  }

  // Use this function to log when the page is fully loaded to prevent additional measurements being taken
  // This function should always be called last to end the performance time logging
  logPageUsableMetric(): void {
    if (!this.featureFlags.isEnabled('performance_service')) {
      return;
    }

    if (
      !window.appMeasurementTaken &&
      window.appStartTime &&
      this.isRouteSupported() &&
      !this.ignoreRouteMap.has(this.router?.currentRoute?.name)
    ) {
      const timeDifference = performance.now() - window?.appStartTime;
      const roundedMsTime = Math.floor(timeDifference);
      const roundedSecTime = parseFloat((timeDifference / 1000).toFixed(2));

      this.metrics.logMonitorEvent(`${METRIC_USABLE_EVENT_TYPE}_${this.getRouteName()}`, {
        loadTimeMs: roundedMsTime,
        loadTimeSec: roundedSecTime,
        uriPathEntryPoint: window?.location?.href,
      });

      LogRocket.track(`${METRIC_USABLE_EVENT_TYPE}_${this.getRouteName()}`, {
        loadTimeMs: roundedMsTime,
        loadTimeSec: roundedSecTime,
        uriPathEntryPoint: window?.location?.href,
      });

      window.appMeasurementTaken = true;
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'performance-load-time': PerformanceLoadTime;
  }
}

import ApplicationSerializer from './application';

export default class extends ApplicationSerializer {
  payloadKeyFromModelName() {
    return 'signed-agreements';
  }

  modelNameFromPayloadKey() {
    return 'signed-agreements-job';
  }
}

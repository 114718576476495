import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import { task, all } from 'ember-concurrency';

function withManagerFunctionality(Class) {
  return class WithManagerFunctionality extends Class {
    @service store;

    clearQuantities() {
      const billingCart = this.billingCart;
      this._setEditingQuantities(billingCart, false);
      this.billingCart = billingCart;
    }

    setDirty(isDirty) {
      const billingCart = this.billingCart;
      this._setDirty(billingCart, isDirty);
      this.billingCart = billingCart;
    }

    setPeriod(selectedPeriod) {
      const billingCart = this.billingCart;
      const hasPlans =
        get(billingCart, 'deliveries.subscriptionPlanId') ||
        get(billingCart, 'visitors.subscriptionPlanId') ||
        get(billingCart, 'rooms.subscriptionPlanId');
      this._setPeriod(billingCart, selectedPeriod);
      if (hasPlans) {
        this._changeSubscriptionPlansByPeriod(billingCart, selectedPeriod);
        this._setDirty(billingCart, true);
      }
      this.billingCart = billingCart;
    }

    setSubscriptionPlan(subscriptionPlan) {
      const billingCart = this.billingCart;
      this._setSubscriptionPlan(billingCart, subscriptionPlan);
      this._setEditingQuantities(billingCart, true);
      this._setDirty(billingCart, true);
      this.billingCart = billingCart;
    }

    endSubscriptionPlanTrial(app) {
      const billingCart = this.billingCart;
      this._endSubscriptionPlanTrial(billingCart, app);
      this._setDirty(billingCart, true);
      this.billingCart = billingCart;
    }

    setLocationQuantity(app, locationId, locationName, quantity) {
      const billingCart = this.billingCart;
      this._setLocationQuantity(billingCart, app, locationId, locationName, quantity);
      this._setDirty(billingCart, true);
      this.billingCart = billingCart;
    }

    getCartSubscriptionPlanId(app) {
      return get(this, `billingCart.${app}.subscriptionPlanId`);
    }

    getCartSubscriptionPlanOnTrial(app) {
      return get(this, `billingCart.${app}.onTrial`);
    }

    toggleSectionOpened(sectionOpened) {
      const billingCart = this.billingCart;
      const section = billingCart.sectionOpened === sectionOpened ? null : sectionOpened;
      if (section) {
        const sectionIsOnTrial = this.getCartSubscriptionPlanOnTrial(section);
        const sectionHasPlanSelected = this.getCartSubscriptionPlanId(section);
        const editingQuantitiesIsOpen = sectionHasPlanSelected && !sectionIsOnTrial;
        this._setEditingQuantities(billingCart, editingQuantitiesIsOpen);
      }
      this._setSectionOpened(billingCart, section);
      this.billingCart = billingCart;
    }

    toggleSummaryOpened(summaryOpened) {
      const billingCart = this.billingCart;
      this._setEditingQuantities(billingCart, false);
      this._setSectionOpened(billingCart, null);
      this.summaryOpened = summaryOpened;
      this.billingCart = billingCart;
    }

    _changeSubscriptionPlansByPeriod(cart, period) {
      const { cartVisitorsPlan, cartDeliveriesPlan, cartRoomsPlan } = this;
      const { visitorsPlans, deliveriesPlans, roomsPlans } = this; // TODO: should be availableXxxPlans as mentioned above
      let newRoomsPlan, newVisitorsPlan, newDeliveriesPlan;
      if (cartVisitorsPlan) {
        const visitorsLevel = get(cartVisitorsPlan, 'level');
        newVisitorsPlan = visitorsPlans.find((plan) => {
          return plan.level === visitorsLevel && plan.period === period;
        });
        this._setSubscriptionPlan(cart, newVisitorsPlan);
      }
      if (cartDeliveriesPlan) {
        const deliveriesLevel = get(cartDeliveriesPlan, 'level');
        newDeliveriesPlan = deliveriesPlans.find((plan) => {
          return plan.level === deliveriesLevel && plan.period === period;
        });
        this._setSubscriptionPlan(cart, newDeliveriesPlan);
      }
      if (cartRoomsPlan) {
        const roomsLevel = get(cartRoomsPlan, 'level');
        newRoomsPlan = roomsPlans.find((plan) => {
          return plan.level === roomsLevel && plan.period === period;
        });
        this._setSubscriptionPlan(cart, newRoomsPlan);
      }
    }

    @task
    *createCurrentLocationSubscriptionEstimates() {
      const currentLocationSubscriptionEstimates = yield this.currentLocationSubscriptionEstimates;
      const { createLocationSubscriptionEstimate } = this;
      const tasks = [];
      if (currentLocationSubscriptionEstimates) {
        currentLocationSubscriptionEstimates.forEach((csle) => {
          if (csle && csle.products && csle.products.length) {
            tasks.push(createLocationSubscriptionEstimate.perform(csle));
          }
        });

        this.initialLocationSubscriptionEstimates = yield all(tasks);
      }
    }

    @task
    *createLocationSubscriptionEstimates() {
      const cartLocationSubscriptionEstimates = yield this.cartLocationSubscriptionEstimates;
      const { createLocationSubscriptionEstimate } = this;
      const tasks = [];
      if (cartLocationSubscriptionEstimates) {
        cartLocationSubscriptionEstimates.forEach((csle) => {
          if (csle && csle.products && csle.products.length) {
            tasks.push(createLocationSubscriptionEstimate.perform(csle));
          }
        });

        this.locationSubscriptionEstimates = yield all(tasks);
      }
    }

    @task
    *createLocationSubscriptionEstimate(cartLocationSubscriptionEstimate) {
      const locationSubscriptionEstimate = this.store.createRecord(
        'location-subscription-estimate',
        cartLocationSubscriptionEstimate,
      );
      yield locationSubscriptionEstimate.save();

      return locationSubscriptionEstimate;
    }

    @task
    *createSubscriptionBatchTask() {
      const company = this.company;
      const products = get(this, `cartSubscriptionBatch`).toArray();
      const subBatch = this.store.createRecord('subscription-batch', { company, products });
      yield subBatch.save();

      return subBatch;
    }

    @task
    *changeBillingPeriodTask() {
      this.togglePeriod.perform();
      this.toggleSummaryOpened(true);
      yield this.createLocationSubscriptionEstimates.perform();
    }

    @task
    *togglePeriod() {
      const period = yield this.selectedPeriod;
      if (period === 'monthly') {
        this.setPeriod('yearly');
      } else {
        this.setPeriod('monthly');
      }
    }
  };
}

export default withManagerFunctionality;

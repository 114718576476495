import { FlowsTestTrackingBase } from '../test-tracking-base';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class FlowsTestTrackingEmployee extends FlowsTestTrackingBase {
  @service abilities;

  get cannotToggle() {
    return this.abilities.cannot('toggle proof of covid negative test for workplace');
  }

  @action
  configureHideIfVaccinated(checked) {
    const { templateConfigurationChangeset } = this;

    if (!templateConfigurationChangeset) return;

    set(templateConfigurationChangeset, 'hideIfVaccinated', checked);

    this.saveTask.perform();
  }

  get isInputDisabled() {
    return super.isInputDisabled || this.cannotToggle;
  }

  get rollbackProperties() {
    return [...super.rollbackProperties, 'hideIfVaccinated'];
  }
}

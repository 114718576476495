import { helper } from '@ember/component/helper';

export function preventDefault([handler]) {
  return function (event) {
    event.preventDefault();

    if (handler && typeof handler === 'function') {
      handler(event);
    }
  };
}

export default helper(preventDefault);

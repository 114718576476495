import Controller from '@ember/controller';
import { service } from '@ember/service';
import urlBuilder from 'garaje/utils/url-builder';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class LocationOverviewOccupancySettingsController extends Controller {
  @service localStorage;
  @tracked loginRequired = false;
  @service state;
  @service flashMessages;
  @service messageBus;
  @tracked modalIframeUrl;
  @tracked showOccupancyCsvUploadDeleteModal = false;
  @tracked showOccupancyCsvUploadResultsModal = false;
  @tracked csvUploadData;

  constructor() {
    super(...arguments);
    this.messageBus.on('embedded-app-message', this, this.handleMessage);
  }

  /**
   * @returns {string} returns iframeUrl
   */
  get iframeUrl() {
    const companyId = this.state.currentCompany.id;
    return urlBuilder.embeddedSettingsGlobalAnalyticsUrl(companyId, this.loginRequired);
  }

  handleShowToaster(message) {
    const type = message.type;
    const msg = message.msg;
    this.flashMessages.showAndHideFlash(type, msg);
  }

  handleShowOccupancyCsvUploadDeleteModal(message, companyId) {
    const data = message?.query?.data;
    this.csvUploadData = data;
    this.modalIframeUrl = urlBuilder.embeddedOccupancyCsvUploadDeleteModal(companyId, this.loginRequired);
    this.showOccupancyCsvUploadDeleteModal = true;
  }

  handleCloseOccupancyCsvUploadDeleteModal() {
    this.showOccupancyCsvUploadDeleteModal = false;
  }

  handleShowOccupancyCsvUploadResultsModal(message, companyId) {
    const data = message?.query?.data;
    this.csvUploadData = data;
    this.modalIframeUrl = urlBuilder.embeddedOccupancyCsvUploadResultsModal(companyId, this.loginRequired);
    this.showOccupancyCsvUploadResultsModal = true;
  }

  handleCloseOccupancyCsvUploadResultsModal() {
    this.showOccupancyCsvUploadResultsModal = false;
  }

  @action
  handleMessage(message) {
    const {
      currentCompany: { id: companyId },
    } = this.state;

    switch (message.event) {
      case 'showToaster':
        this.handleShowToaster(message);
        break;
      case 'showOccupancyCsvUploadDeleteModal':
        this.handleShowOccupancyCsvUploadDeleteModal(message, companyId);
        break;
      case 'closeOccupancyCsvUploadDeleteModal':
        this.handleCloseOccupancyCsvUploadDeleteModal();
        break;
      case 'showOccupancyCsvUploadViewResults':
        this.handleShowOccupancyCsvUploadResultsModal(message, companyId);
        break;
      case 'closeOccupancyCsvUploadResultsModal':
        this.handleCloseOccupancyCsvUploadResultsModal();
        break;
      default:
        break;
    }
  }

  @action
  trackLoggedIn() {
    /**
     * Issue: When user logs out from the Garaje app there is no way Next.js app knows it also needs to logout.
     * Due to this the cookies of the logout user may persist.
     * Solution: After login, force login the user on his first visit to this app. This refreshes all the cookies
     * To know if the user is visiting for the first time we will maintain a global state in localstorage called 'wa_logged_in'
     * On Login -> wa_logged_in does not exists in localstorage -> its first visit
     * On first visit -> SignIn, set wa_logged_in = true in localstorage
     * On Logout -> Remove wa_logged_in
     */

    const waLoggedIn = this.localStorage.getItem('wa_logged_in');

    if (!waLoggedIn) {
      this.loginRequired = true;
      this.localStorage.setItem('wa_logged_in', true);
    } else {
      this.loginRequired = false;
    }
  }
}

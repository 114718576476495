import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { equal } from 'macro-decorators';
import moment from 'moment-timezone';

/**
 * This compoment allows selecting pre-defined ranges or defining or own
 * @param {String}                  labelText
 * @param {String}                  labelFor
 * @param {Object}                  currentDate               First option representing a single day
 * @param {Function}                setDateRange
 * @param {String}                  initialDateRangeFilter    Initially selected option label
 * @param {Array<String>}           dateRangeFilterOptions    All dropdown options
 */
export default class CustomDateRangeSelectComponent extends Component {
  @tracked startDate = null;
  @tracked endDate = null;
  @tracked dateRangeFilter;

  @equal('dateRangeFilter', 'Custom date range') customDateRangeSelected;

  constructor() {
    super(...arguments);
    this.dateRangeFilter = this.args.initialDateRangeFilter;
    this.setFromCurrent();
  }

  setFromCurrent() {
    const currentDate = this.args.currentDate.clone();
    const startDate = currentDate.startOf('day').format();
    const endDate = currentDate.endOf('day').format();
    this.startDate = startDate;
    this.endDate = endDate;
    this.args.setDateRange(startDate, endDate);
  }

  @action
  selectDateRange(option) {
    this.dateRangeFilter = option;

    if (option === 'All time') {
      this.args.setDateRange('', '');
      return;
    }

    if (this.customDateRangeSelected) {
      const startDate = moment().subtract(30, 'days').startOf('day').format();
      const endDate = moment().endOf('day').format();
      this.startDate = startDate;
      this.endDate = endDate;

      this.args.setDateRange(startDate, endDate);
      return;
    }

    if (option.startsWith('Last')) {
      let startDate = moment();
      switch (option) {
        case 'Last 7 days':
          startDate = moment().subtract(7, 'days');
          break;
        case 'Last 30 days':
          startDate = moment().subtract(30, 'days');
          break;
        case 'Last 60 days':
          startDate = startDate.subtract(60, 'days');
          break;
      }

      startDate = startDate.startOf('day').format();
      const endDate = moment().endOf('day').format();

      this.args.setDateRange(startDate, endDate);
      return;
    }

    this.setFromCurrent();
  }

  @action
  didSelectDateRange(startDate, endDate) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.args.setDateRange(startDate, endDate);
  }

  @action
  setStartDate(date) {
    this.startDate = date;
  }

  @action
  setEndDate(date) {
    this.endDate = date;
  }
}

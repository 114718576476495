import ApplicationSerializer from './application';
import { get } from '@ember/object';

export default class ScimPrimaryLocationMappingSerializer extends ApplicationSerializer {
  serialize(snapshot) {
    const json = super.serialize(...arguments);

    // check if it's an update operation
    if (!get(snapshot.record, 'isNew')) {
      delete json.data.attributes['scim-attribute-value'];
      if (json.data.relationships && json.data.relationships['scim-primary-location-configuration']) {
        delete json.data.relationships['scim-primary-location-configuration'];
      }
    }

    return json;
  }
}

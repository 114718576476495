import { belongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

import type EmployeeGroupRoleModel from './employee-group';
import EmployeeGroupModel from './employee-group';

@adapter('v3-coalesce')
class ScimCustomEmployeeGroupModel extends EmployeeGroupModel {
  @belongsTo('employee-group-role') declare employeeGroupRole: EmployeeGroupRoleModel;
}

export default ScimCustomEmployeeGroupModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'scim-custom-employee-group': ScimCustomEmployeeGroupModel;
  }
}

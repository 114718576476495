import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import type { GetRoomsQuery } from 'garaje/graphql/generated/roomba-types';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import moment from 'moment-timezone';

interface RoombaCalendarPermissionsRowArgs {
  room: GetRoomsQuery['rooms'][number];
}

export default class RoombaCalendarPermissionsRow extends Component<RoombaCalendarPermissionsRowArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare roombaGraphql: RoombaGraphqlService;

  get lastCheckedAt(): string | undefined {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const timestamp = this.args.room.calendarPermissionsStatus?.permissionsLastCheckedAt;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return timestamp ? moment(timestamp).format('h:mm a z on MMMM D, YYYY') : undefined;
  }

  recheckTask = task(this, { drop: true }, async () => {
    try {
      await this.roombaGraphql.checkCalendarPermissions({ roomId: this.args.room.id });
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
    }
  });
}

import ApplicationSerializer from './application';

export default class UserDocumentSerializer extends ApplicationSerializer {
  attrs = {
    createdAt: {
      serialize: false,
    },
    updatedAt: {
      serialize: false,
    },
  };

  serializeIntoHash(hash, _type, snapshot, options) {
    const payload = this.serialize(snapshot, options);

    if (!payload.data.id) {
      payload.data.attributes.attachments =
        snapshot.hasMany('userDocumentAttachments')?.map((attachment) => {
          return {
            file: attachment.attr('file'),
            'user-document-template-attachment': attachment.belongsTo('userDocumentTemplateAttachment').id,
          };
        }) ?? [];

      Object.assign(hash, payload);

      return hash;
    }

    return super.serializeIntoHash(...arguments);
  }
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { restartableTask, task, timeout } from 'ember-concurrency';
import config from 'garaje/config/environment';
import type { PrinterConnectionType } from 'garaje/models/abstract/printer';
import type PrinterModel from 'garaje/models/printer';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import type { PairablePrinter } from 'garaje/utils/pairable-printers';
import pairablePrinters from 'garaje/utils/pairable-printers';
import zft from 'garaje/utils/zero-for-tests';
import brotherIpAddressValidator from 'garaje/validators/brother-ip-address';

const PRINTER_VALIDATIONS = { ip: brotherIpAddressValidator() };

interface DevicesNewPrinterFormComponentArgs {
  /**
   * function called after new Bluetooth printer is paired successfully
   */
  printerPaired?: () => void;
  /**
   * function called after printer is saved successfully
   */
  printerSaved?: () => void;
  /**
   * new (unsaved) printer model
   */
  printer: PrinterModel;
  printers: PrinterModel[];
  /**
   * function called to query for a connected Bluetooth printer
   */
  queryForBluetoothPrinter: () => Promise<PrinterModel | null>;
}

export default class DevicesNewPrinterFormComponent extends Component<DevicesNewPrinterFormComponentArgs> {
  @service declare flashMessages: FlashMessagesService;

  pairablePrinters = pairablePrinters;

  @tracked selectedConnectionType: { value: PrinterConnectionType } | null = null;
  @tracked selectedPrinter: PairablePrinter | null = null;
  @tracked pairingSuccessful = false;
  @tracked printerChangeset = Changeset(this.args.printer, lookupValidator(PRINTER_VALIDATIONS), PRINTER_VALIDATIONS);

  get isSaveButtonDisabled(): boolean {
    return this.saveNewPrinterTask.isRunning;
  }

  saveNewPrinterTask = task(async () => {
    const printerChangeset = this.printerChangeset;
    await printerChangeset.validate();

    if (printerChangeset.isInvalid) {
      return;
    }

    try {
      await printerChangeset.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.args.printerSaved?.();
    } catch (error) {
      const errorText = parseErrorForDisplay(error);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  listenForBluetoothPairing = restartableTask(async () => {
    do {
      const printer = await this.args.queryForBluetoothPrinter();

      if (printer) {
        this.pairingSuccessful = true;
        await timeout(zft(3000));
        this.flashMessages.showAndHideFlash('success', 'Saved!');
        this.args.printerPaired?.();
        return;
      }

      await timeout(zft(3000));
    } while (config.environment !== 'test');
  });

  @action
  changeSelectedPrinter(printer: PairablePrinter): void {
    const connectionType = this.selectedConnectionType;
    this.selectedPrinter = printer;
    this.printerChangeset.model = printer.printerModel;

    // reset connection type if it is not support by selected printer
    if (!printer.bluetoothEnabled && connectionType && connectionType.value === 'bluetooth') {
      this.selectedConnectionType = null;
    }
  }

  @action
  changeSelectedConnectionType(type: { value: PrinterConnectionType }): void {
    this.selectedConnectionType = type;
    this.printerChangeset.connectionType = type.value;
    this.pairingSuccessful = false;

    if (type.value === 'bluetooth') {
      void this.listenForBluetoothPairing.perform();
    } else {
      void this.listenForBluetoothPairing.cancelAll();
    }
  }
}

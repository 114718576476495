/* eslint-disable ember/classic-decorator-hooks */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { dropTask, timeout } from 'ember-concurrency';
import { set } from '@ember/object';

import config from 'garaje/config/environment';

/**
 * @param {Funtion}              onResize
 * @param {Location}             location
 * @param {Subscription}         vrSubscription
 * @param {Subscription}         deliveriesSubscription*
 * @param {Subscription}         roomsSubscription*
 */

export default class DashboardProductBoxesContainerComponent extends Component {
  tagName = '';

  init() {
    super.init(...arguments);
    window.addEventListener('resize', this.onResize);
    set(this, 'checkerInstance', this.checkSizeTask.perform());
  }

  /**
   * checkSizeTask
   */
  @dropTask
  *checkSizeTask() {
    while (config.environment !== 'test') {
      this.onResize();
      yield timeout(900);
    }
  }

  // eslint-disable-next-line ember/no-component-lifecycle-hooks
  willDestroyElement() {
    super.willDestroyElement(...arguments);
    window.removeEventListener('resize', this._resizeBoxes);
    if (this.checkerInstance) {
      this.checkerInstance.cancel();
    }
  }
}

import gql from 'graphql-tag';

export default gql`
  fragment RoomStatusFragment on Room {
    status {
      currentCalendarEventId
      status
      time
    }
  }
`;

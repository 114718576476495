import Component from '@glimmer/component';
import { DISCARDED_RESPONSE_STRINGS } from 'garaje/utils/enums';
import { get } from '@ember/object';

/**
 * @param {Object}              changeset
 * @param {Function}            update
 * @param {Boolean}             disabled
 * @param {Boolean}             isReadonly
 * @param {String}              label
 */
export default class EntriesInputFieldComponent extends Component {
  get displayValue() {
    if (get(this.args.changeset, 'value') === DISCARDED_RESPONSE_STRINGS.PLACEHOLDER) {
      return DISCARDED_RESPONSE_STRINGS.DISPLAY_TEXT;
    }

    return get(this.args.changeset, 'value');
  }

  get isDisabled() {
    return (
      this.args.disabled ||
      this.args.isReadonly ||
      get(this.args.changeset, 'value') === DISCARDED_RESPONSE_STRINGS.PLACEHOLDER
    );
  }

  get isRequired() {
    return get(this.args.changeset, 'required') && !get(this.args.changeset, 'allowVisitorRespondents');
  }
}

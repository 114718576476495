import type { AsyncBelongsTo, SyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import type VfdCallLogTranscriptionModel from 'garaje/models/vfd-call-log-transcription';
import type VfdContactMethodModel from 'garaje/models/vfd-contact-method';

/**
 *  type VfdCallStatus
 *
 *    in-progress
 *      Visitor and VR are still in conversation or if the call is in the process
 *      of "ringing"/connecting
 *
 *    completed
 *      If call was ended by VR or Visitors once connected
 *
 *    missed
 *      If call was not answered by VR before 1 the time limit or if it was
 *      disconnected by Visitor before the VR could answer
 *
 *    disconnected - HOLD OFF (FUTURE USE)
 *      If call didn't connect due to connectivity issues, was disconnected due to
 *      connectivity issues, or the max time limit was reached
 */
type VfdCallStatus = 'in-progress' | 'completed' | 'missed' | 'disconnected';

export default class VfdCallLogModel extends Model {
  @belongsTo('skinny-location', { async: true, inverse: null }) declare location: AsyncBelongsTo<SkinnyLocationModel>;
  @belongsTo('vfd-call-log-transcription', { async: false })
  declare transcription: VfdCallLogTranscriptionModel;
  @hasMany('vfd-contact-methods', { async: false }) declare contactMethods: SyncHasMany<VfdContactMethodModel>;

  @attr('string') declare callStatus: VfdCallStatus;
  @attr('string') declare deviceName: string;
  @attr('number') declare durationSeconds: number;
  @attr('date') declare startedAt: Date;
  @attr('number') declare timeToAnswerSeconds: number;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface VfdCallLogModel {
    'vfd-call-log': VfdCallLogModel;
  }
}

// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { service } from '@ember/service';
import { SIGN_IN_FIELD_KINDS } from 'garaje/utils/enums';

export default class EmployeeRegistrationForm extends Component {
  @service state;

  tagName = '';
  baseOptions = [
    { name: 'Dropdown', kind: SIGN_IN_FIELD_KINDS.SINGLE_SELECTION },
    { name: 'Short answer', kind: SIGN_IN_FIELD_KINDS.TEXT },
  ];
}

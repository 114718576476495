import type UserModel from 'garaje/models/user';
import type { SinglePayload } from 'jsonapi/response';

import ApplicationSerializer from './application';

export default class UserSerializer extends ApplicationSerializer {
  attrs = {
    userPhoto: {
      serialize: false,
    },
    userPhotoUrl: {
      serialize: false,
    },
    createdAt: {
      serialize: false,
    },
    intercomIdentityHash: {
      serialize: false,
    },
  };

  serialize(
    ...parameters: Parameters<ApplicationSerializer['serialize']>
  ): ReturnType<ApplicationSerializer['serialize']> {
    const [snapshot] = parameters;
    const json = <SinglePayload<UserModel>>super.serialize(...parameters);

    const changes = snapshot.changedAttributes();
    // Updating user photo only supported on V3 user endpoint
    // Solo serialize only userPhoto for V3 PATCH request if changed and if is the only change
    if (snapshot.id && changes['userPhoto'] && Object.keys(changes).length === 1) {
      json.data.attributes = { 'user-photo': <string>snapshot.attributes()['userPhoto'] };
    }

    return json;
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    user: UserSerializer;
  }
}

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { format, toDate } from 'date-fns-tz';
import {
  ANNOUNCEMENT_RECIPIENT_FILTERS,
  ANNOUNCEMENT_RECIPIENT_OPTIONS,
  ANNOUNCEMENT_STATUS_OPTIONS,
  EMERGENCY_NOTIFICATION_STATUS_OPTIONS,
} from 'garaje/utils/enums';

import type { CommunicationsMessageModel } from './route-base';

type SelectOptionType = {
  label: string;
  value: string;
};

export default class CommunicationsMessageControllerBase extends Controller {
  declare model: CommunicationsMessageModel;

  @tracked recipientOptions = ANNOUNCEMENT_RECIPIENT_OPTIONS;
  @tracked selectedRecipient = 'all';
  @tracked selectedStatus = 'all';
  @tracked sortBy = 'name';
  @tracked sortDirection: string = 'desc';
  @tracked page = 1;
  @tracked limit = 100;

  get markAsSafeRequired(): boolean {
    return !!this.model?.announcement?.emergencyNotification?.content?.markAsSafeRequired;
  }

  get statusOptions(): typeof EMERGENCY_NOTIFICATION_STATUS_OPTIONS {
    if (this.model?.announcement?.emergencyNotification?.content?.markAsSafeRequired) {
      return EMERGENCY_NOTIFICATION_STATUS_OPTIONS;
    }
    return ANNOUNCEMENT_STATUS_OPTIONS;
  }

  get sentDate(): string {
    const createdAt = this.model.announcement.createdAt;
    const date = toDate(createdAt);

    return format(date, "MMM dd, yyyy 'at' h:mm a zzz");
  }

  get filters(): { [key: string]: string } {
    const filters: { [key: string]: string } = {};

    if (this.selectedRecipient !== 'all') {
      filters[ANNOUNCEMENT_RECIPIENT_FILTERS.recipient_type] = this.selectedRecipient;
    }

    switch (this.selectedStatus) {
      case 'email-not-sent':
        filters[ANNOUNCEMENT_RECIPIENT_FILTERS.email_sent] = 'false';
        break;
      case 'push-not-sent':
        filters[ANNOUNCEMENT_RECIPIENT_FILTERS.push_sent] = 'false';
        break;
      case 'sms-not-sent':
        filters[ANNOUNCEMENT_RECIPIENT_FILTERS.sms_sent] = 'false';
        break;
      case 'sent':
      case 'failed':
        filters[ANNOUNCEMENT_RECIPIENT_FILTERS.notification_status_equal_to] = this.selectedStatus;
        break;
      case 'not-marked-as-safe':
      case 'marked-as-safe':
        filters[ANNOUNCEMENT_RECIPIENT_FILTERS.marked_as_safe] =
          this.selectedStatus === 'marked-as-safe' ? 'true' : 'false';
    }

    return filters;
  }

  get recipientFilter(): SelectOptionType {
    return this.recipientOptions.find((o) => o.value === this.selectedRecipient)!;
  }

  get statusFilter(): SelectOptionType {
    return this.statusOptions.find((o) => o.value === this.selectedStatus)!;
  }

  @action
  setSort(field: string, direction: string): void {
    this.sortBy = field;
    this.sortDirection = direction;
  }

  @action
  updateRecipientFilter(option: SelectOptionType): void {
    this.selectedRecipient = option.value;
  }

  @action
  updateStatusFilter(option: SelectOptionType): void {
    this.selectedStatus = option.value;
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import urlBuilder from 'garaje/utils/url-builder';
import droppableUpload from 'garaje/utils/decorators/droppable-upload';

/**
 * @param {Function}            setLogo
 * @param {Model<Location>}     location
 * @param {Function}            expandWelcomeImage
 * @param {Function}            expandSlideshow
 * @param {Task}                deleteLogo
 * @param {Reference}           parent
 */

@droppableUpload
class CustomizeCompanyLogo extends Component {
  @tracked isLogoUploading = false;

  get logoUploadUrl() {
    return urlBuilder.v2.updateLocationPhotoUrl(this.args.location.id);
  }
}

export default CustomizeCompanyLogo;

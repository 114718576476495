import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const ALLOWED_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN];

export default class VisualComplianceAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;

  get canReview(): boolean {
    const { currentLocation } = this.state;
    const config = currentLocation?.visualComplianceConfiguration.content;
    const contactIds = (config && config.contactIds) || [];
    const { roleNames } = this.currentAdmin;

    const hasSubscription = !!this.state.features?.canAccessVisualCompliance;
    const hasRole = isPresent(_intersection(ALLOWED_ROLES, roleNames));
    const isContact = contactIds.map(String).includes(this.currentAdmin.id);

    return hasSubscription && (hasRole || isContact);
  }
}

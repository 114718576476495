// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action, get, set } from '@ember/object';
import { next } from '@ember/runloop';

/**
 * @param {Model<Location>}           location
 * @param {Object}                    integration
 * @param {Array}                     filterOptions
 * @param {Boolean}                   canEdit
 * @param {Function}                  addFilter
 * @param {Function}                  removeFilter
 * @param {Array}                     directoryGroups
 */
export default class LocationFilter extends Component {
  get filters() {
    return get(this, 'integration.locationFilters').filterBy('locationId', parseInt(get(this, 'location.id')));
  }

  get availableFilters() {
    const filters = this.filters.mapBy('name');
    return this.filterOptions.reject((filter) => filters.indexOf(filter.name) >= 0);
  }

  filterOperations = [
    { name: 'equals', value: '=' },
    { name: 'contains', value: '=~' },
  ];

  @action
  setFilterGroup(filter, { group_id }) {
    set(filter, 'value', group_id);
  }

  @action
  _addFilter(filterName) {
    const filter = get(this, 'availableFilters').findBy('name', filterName);

    next(() => {
      get(this, 'addFilter')(filter, parseInt(get(this, 'location.id')));
    });
  }
}

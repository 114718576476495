// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias, filterBy, mapBy, sum } from '@ember/object/computed';

/**
 * Component for showing purchase summary details
 * @param {Boolean}                        isLoading      Loading state
 * @param {Class<SubscriptionEstimate>}    estimate       A subscription estimate model
 */

export default class SubscriptionEstimateCost extends Component {
  @alias('estimate.discounts') discounts;
  @alias('estimate.taxes') taxes;
  @filterBy('estimate.lineItems', 'entityType', 'plan') plans;
  @filterBy('estimate.lineItems', 'entityType', 'addon') addons;
  @mapBy('plans', 'amount') plansAmounts;
  @mapBy('discounts', 'amount') discountsAmounts;
  @mapBy('taxes', 'amount') taxesAmounts;
  @sum('plansAmounts') subtotal;
  @sum('discountsAmounts') discountsTotal;
  @sum('taxesAmounts') taxesTotal;
}

import gql from 'graphql-tag';

import EmployeeFragment from '../fragments/EmployeeFragment';

export default gql`
  query Employees($query: String!, $locationIDs: [ID!]!, $sort: String!, $page: Int!, $limit: Int!, $groupId: [ID!]) {
    employees(
      query: $query
      locations: $locationIDs
      sort: $sort
      page: $page
      limit: $limit
      workplaceGroups: $groupId
    ) {
      ...EmployeeFragment
    }
  }
  ${EmployeeFragment}
`;

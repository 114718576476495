import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export const RECURRING_OPTIONS = Object.freeze({
  THIS: 'this',
  THIS_AND_FOLLOWING: 'thisAndFollowing',
  ALL: 'all',
});

export default class InvitesSelectRecurringInvitesComponent extends Component {
  @tracked selectedOption = this.args.scope || RECURRING_OPTIONS.THIS;

  get options() {
    return this.args.options || RECURRING_OPTIONS;
  }

  @action
  onChangeOption(evt) {
    const option = evt.target.value;

    this.selectedOption = option;
    this.args.onChange(option);
  }
}

import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type AnnouncementTemplateModel from 'garaje/models/announcement-template';
import type CompanyModel from 'garaje/models/company';

export default class AnnouncementTemplateCategoryModel extends Model {
  // Relationships
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @hasMany('announcement-template') declare announcementTemplates: AsyncHasMany<AnnouncementTemplateModel>;

  // Attributes
  @attr('string') declare name: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'announcement-template-category': AnnouncementTemplateCategoryModel;
  }
}

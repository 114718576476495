import RmsAdapter from './rms';

export default class AnnouncementAdapter extends RmsAdapter {
  urlForFindAll(_, { adapterOptions }) {
    const url = super.urlForFindAll(...arguments);
    return `${url}?filter[location]=${adapterOptions.locationId}`;
  }

  // ┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓
  // ┃  TODO: Remove the following hack when the RMS service is sunset   ┃
  // ┃  DO NOT REPEAT THIS ELSEWHERE :: VERY BAD                         ┃
  // ┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛

  // We require this to override the use of RMSAdapter for new communications/message-log
  // Delete the entire adapter after sunsetting workplace/announcements
  urlForQuery(query, modelName) {
    const originalNamespace = this.namespace;
    this.namespace = 'a/visitors/api/v3';

    const url = super.urlForQuery(query, modelName);

    // required for when we switch between new and old announcements
    // good to restore the original namespace
    this.namespace = originalNamespace;

    return url;
  }

  urlForFindRecord(query, modelName, snapshot) {
    const adapterOptions = snapshot?.adapterOptions || {};

    if (!adapterOptions.v3) {
      return;
    }

    const originalNamespace = this.namespace;
    this.namespace = 'a/visitors/api/v3';

    let url = super.urlForFindRecord(query, modelName);

    // required for when we switch between new and old announcements
    // good to restore the original namespace
    this.namespace = originalNamespace;

    const queryParams = [];

    if (adapterOptions.fields) {
      for (const field in adapterOptions.fields) {
        queryParams.push(`fields[${field}]=${adapterOptions.fields[field]}`);
      }
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    return url;
  }
}

import { service } from '@ember/service';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import type StoreService from '@ember-data/store';
import type MailerBlockModel from 'garaje/models/mailer-block';
import type MailerTemplateModel from 'garaje/models/mailer-template';

export default class MailerSectionModel extends Model {
  @service declare store: StoreService;

  // Relationships
  @hasMany('mailer-block') declare mailerBlocks: AsyncHasMany<MailerBlockModel>;
  @belongsTo('mailer-template') declare mailerTemplate: AsyncBelongsTo<MailerTemplateModel>;

  // Attributes
  @attr('immutable', { defaultValue: () => ({}) }) declare settings: Record<string, unknown>;
  @attr('string') declare title: string;
  @attr('boolean') declare sortable: boolean;
  @attr('boolean') declare editable: boolean;
  @attr('number') declare position: number;
  @attr('string') declare identifier: string;

  createHTMLBlock(): MailerBlockModel {
    return this.store.createRecord('mailer-block', {
      mailerSection: this,
      active: true,
      deactivatable: true,
      editable: true,
      identifier: 'custom_content',
      position: 9999,
      requiredAttributes: [],
      sortable: true,
      title: 'Custom content',
      settings: {
        attributes: [
          {
            custom_content: {
              type: 'html',
              title: null,
              value: '',
              input_validation: 'html',
              locale_file_fallback_key: null,
            },
          },
        ],
      },
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'mailer-section': MailerSectionModel;
  }
}

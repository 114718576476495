import { htmlSafe } from '@ember/template';
import { type SafeString } from '@ember/template/-private/handlebars';
import Model, { attr } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

interface Manifest {
  icon: string;
  icon_small: string;
  jobs_handled: object;
  installable_on?: string[];
}

@adapter('v2')
class PluginModel extends Model {
  @attr('string') declare category: string;
  @attr('string') declare description: string;
  @attr('boolean', { defaultValue: true }) declare isBeta: boolean;
  @attr('string') declare key: string;
  @attr('object') declare manifest: Manifest;
  @attr('string') declare minPlan: string;
  @attr('string') declare name: string;
  @attr('string') declare status: string;

  get isHostNotificationPlugin(): boolean {
    return this.category === 'host-notifications';
  }

  get isGlobalPlugin(): boolean {
    return this.category === 'global';
  }

  get isHostNotificationOrGlobalPlugin(): boolean {
    return this.isHostNotificationPlugin || this.isGlobalPlugin;
  }

  get icon(): SafeString {
    return htmlSafe(this.manifest?.icon ?? '');
  }

  get iconSmall(): SafeString {
    return htmlSafe(this.manifest?.icon_small ?? this.icon);
  }

  get jobsHandled(): Array<string> {
    return Object.keys(this.manifest?.jobs_handled ?? {});
  }
}

export default PluginModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    plugin: PluginModel;
  }
}

// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';

/**
 * Warning banner
 * @param {String}  title Required
 * @param {String}  subtitle Required
 * @param {String}  ctaText Optional - Primary button text (if passed, href also needs to be passed)
 * @param {String}  ctaHref Optional - Primary href (if passed, text also needs to be passed)
 * @param {String}  secondaryCtaText Optional - Secondary button text (if passed, secondary ref also needs to be passed)
 * @param {String}  secondaryCtaHref Optional - Secondary href (if passed, secondary text also needs to be passed)
 */
export default class WarningBanner extends Component {}

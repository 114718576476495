import DeviceFragment from 'garaje/graphql/fragments/roomba/DeviceFragment';
import gql from 'graphql-tag';

export default gql`
  query GetDevicePairingStatus($id: Long!) {
    getDevicePairingStatus(id: $id) {
      id
      interval
      expiresAt
      deviceId
      device {
        ...DeviceFragment
      }
    }
  }
  ${DeviceFragment}
`;

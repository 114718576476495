import Model, { attr } from '@ember-data/model';
import cardTypes from 'garaje/utils/card-types';
import adapter from 'garaje/utils/decorators/adapter';
import { equal, notEmpty, or } from 'macro-decorators';

@adapter('billing-v1')
class PaymentSourceModel extends Model {
  @attr('string') declare type: string;
  @attr('string') declare billingZip: string;
  @attr('string') declare companyId: string;

  // CARD DETAILS
  @attr('string') declare brand: string; // Needs ENUM
  @attr('string') declare name: string;
  @attr('string') declare last4: string;
  @attr('string') declare expiryMonth: string;
  @attr('string') declare expiryYear: string;
  @attr('string') declare stripeTempToken: string;
  @attr('boolean') declare isPrimary: boolean;
  @attr('string') declare status: string;
  @attr('string') declare paymentSourceRole: string;

  // ENABLED
  @equal('type', 'virtual_bank_account') declare isACH: boolean;
  @equal('type', 'card') declare isCard: boolean;
  @equal('type', 'direct_debit') declare isDirectDebit: boolean;
  @notEmpty('last4') declare isLast4Present: boolean;
  @or('isLast4Present', 'isACH') declare exists: boolean;

  get cardType(): string | undefined {
    return this.brand ? (cardTypes[this.brand as keyof typeof cardTypes] as string | undefined) : '';
  }

  get shownStatus(): string {
    return this.status && this.status !== 'valid' ? this.status.replace(/_/g, ' ') : '';
  }

  get canMakePrimary(): boolean {
    return this.status === 'valid' || this.status === 'expiring';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'payment-source': PaymentSourceModel;
  }
}

export default PaymentSourceModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'payment-source': PaymentSourceModel;
  }
}

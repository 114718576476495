import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import ManageViewRoleRoute from 'garaje/pods/manage/view-role/route';

class LocationOverviewManageViewRoleRoute extends ManageViewRoleRoute {
  controllerName = 'manage/view-role';
  templateName = 'manage/view-role';

  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  beforeModel(): void {
    if (this.abilities.cannot('view-on-global custom-roles')) {
      void this.router.transitionTo('unauthorized');
    }
  }
}

export default LocationOverviewManageViewRoleRoute;

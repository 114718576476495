import Component from '@glimmer/component';

export default class UserDocumentStatusComponent extends Component {
  get iconSrc() {
    switch (this.args.status) {
      case 'approved':
        return '/assets/images/checkmark-circle-green.svg';
      case 'denied':
        return '/assets/images/error-icon.svg';
      case 'review':
        return '/assets/images/clock-2.svg';
      case 'expired':
        return '/assets/images/blue-exclamation.svg';
      default:
        return '/assets/images/orange-exclamation.svg';
    }
  }
}

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class DowngradeBasicOrCancel extends Component {
  @service featureFlags;
  @service state;

  constructor() {
    super(...arguments);
  }

  get canShowLoseFreeWorkplaceModal() {
    return this.featureFlags.isEnabled('growth_workplace_free_bundle') && this.state.workplaceSubscription?.freeBundle;
  }

  @action
  handleDowngradeOrCancelClicked(requestType) {
    if (this.featureFlags.isEnabled('show-prosperstack')) {
      this.args.setProsperStackRequestType(requestType);
    }
    this.args.setShowLoseFreeWorkplaceModal(true);
  }
}

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class WorkplaceAnnouncementsLandingPageRoute extends Route {
  @service state;
  @service store;
  @service router;

  model() {
    const { announcements } = this.modelFor('workplace.announcements');

    return { announcements };
  }

  afterModel({ announcements }) {
    if (announcements.length) {
      this.router.transitionTo('workplace.announcements.list');
    }
  }
}

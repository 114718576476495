import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

/**
 * @param {Object}              location
 * @param {String}              integration
 * @param {Function}            openAction
 * @param {Function}            closeAction
 * @param {Function}            integrationDisconnected
 */
export default class SalesforceConfigBox extends Component {
  @service currentAdmin;
  @service integrationsManager;
  @service flashMessages;

  get company() {
    return get(this.args.location, 'company.content');
  }

  get connected() {
    return get(this.args.location, 'company.salesforceIntegration.content');
  }

  get salesforceUserId() {
    return get(this.args.location, 'company.salesforceIntegration.content.salesforceUserId');
  }

  get isOpen() {
    return this.args.integration === 'salesforce';
  }

  confirmAction() {
    return window.confirm('You will lose any unsaved changes. Are you sure you want to continue?');
  }

  @action
  close() {
    this.args.closeAction();
  }

  @action
  open() {
    this.args.openAction('salesforce');
  }

  confirmDisconnect() {
    return window.confirm('Are you sure you want to disconnect this integration?');
  }

  @dropTask
  *disconnectTask() {
    if (!this.confirmDisconnect()) {
      return;
    }
    try {
      yield this.integrationsManager.disconnectSalesforce(this.company);
      this.args.integrationDisconnected();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));
    }
  }
}

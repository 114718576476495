import Service from '@ember/service';
import Evented from '@ember/object/evented';

/**
 * events:
 *
 * flow-refresh
 * global-flow-refresh
 */

export default Service.extend(Evented);

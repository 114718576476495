import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

const FEATURES_TO_SHOW = [
  { name: 'multiple_visitor_types', description: 'Visitor types', icon: 'visitors' },
  { name: 'nda_enabled', description: 'Legal docs', icon: 'document-fill' },
  { name: 'unlimited_employees', description: 'Unlimited employees', icon: 'team' },
  { name: 'visitor_photos', description: 'Sign in photos', icon: 'camera-2' },
  { name: 'custom_notifications', description: 'Customized host notification', icon: 'sparkles-fill' },
  { name: 'analytics', description: 'Analytics', icon: 'analytics-bars-fill', suppressUsage: true },
  { name: 'customized_branding', description: 'Customized branding', icon: 'paintbrush' },
  { name: 'sms_notification', description: 'Notifications via SMS', icon: 'sms-notification' },
  { name: 'visitor_badge_printing', description: 'Badge printing usage', icon: 'badge-printer' },
  { name: 'assistants', description: 'Notify assistants', icon: 'instant-notification' },
  { name: 'capacity_limits', description: 'Capacity limits', icon: 'gauge' },
];

// maximum number of features to display, with the exception that if more features
// than this number are in use, show all of those.
const NUM_FEATURES_TO_DISPLAY = 8;

export default class VisitorsTrialEndModalBillingAdminStep2 extends Component {
  @service featureUsage;

  constructor() {
    super(...arguments);

    // The parent <Visitors::TrialEndModal> component should start this task to preload the data,
    // but in case it didn't, try again when this component is instantiated.
    if (!this.featureUsage.calculate.lastSuccessful) {
      // task has never successfully run to completion; try to `perform()` it again.
      // if it was still running, the `@dropTask` definition will prevent this from starting it
      // over, and if it failed, this will give it a retry.
      this.featureUsage.calculate.perform();
    }
  }

  get features() {
    // push to separate arrays that get joined later because Array.prototype.sort is not guaranteed to be stable
    // and so might reorder the features if we used it.
    const usedFeatures = [];
    const unusedFeatures = [];

    FEATURES_TO_SHOW.forEach((feature) => {
      feature.inUse = feature.suppressUsage ? false : this.featureUsage.isUsed(feature.name);
      if (feature.inUse) {
        usedFeatures.push(feature);
      } else {
        unusedFeatures.push(feature);
      }
    });

    const howManyUnusedFeaturesToDisplay = NUM_FEATURES_TO_DISPLAY - usedFeatures.length;
    const unusedFeaturesToDisplay = unusedFeatures.slice(0, howManyUnusedFeaturesToDisplay);
    return [...usedFeatures, ...unusedFeaturesToDisplay];
  }

  get usedFeaturesCount() {
    return this.features.filter((feature) => feature.inUse).length;
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type AbilitiesService from 'ember-can/services/abilities';
import type FlowModel from 'garaje/models/flow';
import type TenantModel from 'garaje/models/tenant';
import type StateService from 'garaje/services/state';
import type VisitorsOnboardingService from 'garaje/services/visitors-onboarding';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

export interface VisitorsInvitesRouteModel {
  flows: FlowModel[];
  connectedTenants: RecordArray<TenantModel>;
}

class VisitorsInvitesRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare visitorsOnboarding: VisitorsOnboardingService;
  @service declare router: RouterService;

  titleToken = 'Invites';

  beforeModel(): Promise<unknown> | void {
    const { currentLocation, vrSubscription } = this.state;
    if (!currentLocation?.preRegistrationEnabled) {
      // @ts-ignore
      return this.router.transitionTo('visitors.entries.index');
    }

    if (!(isPresent(vrSubscription) && this.abilities.can('visit dashboard'))) {
      if (this.abilities.can('visit dashboard')) {
        void this.router.transitionTo('visitors.start-trial');
      } else if (this.abilities.can('visit billing')) {
        void this.router.transitionTo('billing');
      } else if (this.abilities.can('visit security-desk')) {
        void this.router.transitionTo('security-desk');
      } else if (this.abilities.can('visit security')) {
        void this.router.transitionTo('security');
      } else {
        void this.router.transitionTo('unauthorized');
      }
    }
  }

  async model(): Promise<VisitorsInvitesRouteModel> {
    const { currentLocation } = this.state;
    const flows = this.state.loadFlows({ reload: false, locationId: currentLocation?.id });
    await this.visitorsOnboarding.loadSteps();

    const connectedTenants = currentLocation.getPropertyConnections();

    return hash({ flows, connectedTenants });
  }

  redirect(_model: VisitorsInvitesRouteModel, transition: Transition): void {
    this.visitorsOnboarding.gateRoute(this, transition);
  }
}

export default VisitorsInvitesRoute;

import Controller from '@ember/controller';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { task } from 'ember-concurrency';
import { reads } from 'macro-decorators';

export default class TouchlessWalkinsRoute extends Controller {
  @service flashMessages;
  @service('setupGuideStepper') setupGuideStepperService;

  @reads('model.subscription.canAccessStaticQrCodes') canAccessStaticQrCodes;

  toggletouchlessWalkins = task({ drop: true }, async (value) => {
    const { config } = this.model;

    config.guestQrCodeEnabled = value;

    try {
      await config.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      config.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}

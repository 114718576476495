import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type FeatureFlagsService from 'garaje/services/feature-flags';

export default class Helpdesk extends Component<{
  logEvent: (event_name: string, event_value: string) => void;
}> {
  @service declare featureFlags: FeatureFlagsService;
  @tracked searchTerm: string = '';

  get shouldShowSearchBar(): boolean {
    return window.chmln === undefined || !this.featureFlags.isEnabled('growth_chameleon_identify');
  }

  get shouldShowChameleonHelpBar(): boolean {
    return window.chmln !== undefined && this.featureFlags.isEnabled('growth_chameleon_identify');
  }

  get correctCommandSymbolForOS(): string {
    const userAgent = navigator?.userAgent;
    if (userAgent.indexOf('Win') != -1) {
      return 'WIN';
    } else if (userAgent.indexOf('Mac') != -1) {
      return '⌘';
    } else {
      return 'CMD';
    }
  }

  @action
  onSubmit(event: SubmitEvent): void {
    event.preventDefault();

    if (isEmpty(this.searchTerm)) {
      return;
    }

    if (this.args.logEvent) {
      this.args.logEvent('homepage_help_desk_question_searched', this.searchTerm);
    }

    window.open(`https://envoy.help/en/?q=${this.searchTerm}`, '_blank');
  }
}

import ImporterService from './importer';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';

export default class BlocklistFilterImporterService extends ImporterService {
  @service state;

  @task
  *handleImport(data) {
    const props = yield this._parseProps(data);
    if (
      isPresent(props.reason) &&
      (isPresent(props.fullName) ||
        isPresent(props.aliases) ||
        isPresent(props.emails) ||
        isPresent(props.phoneNumbers) ||
        isPresent(props.otherKeywords))
    ) {
      return props;
    }
    return null;
  }

  _parseProps(data) {
    const location = this.state.currentLocation;
    const { id, physicalDescription, fullName, reasonForBlocking, externalId, imageUrl } = data;

    const aliases = this.convertDelimitedStringToArray(data.aliases);
    const emails = this.convertDelimitedStringToArray(data.emailAddresses);
    const otherKeywords = this.convertDelimitedStringToArray(data.keywords);
    const phoneNumbers = this.convertDelimitedStringToArray(data.phoneNumbers);

    return {
      id,
      fullName,
      aliases,
      emails,
      phoneNumbers,
      description: physicalDescription,
      location,
      otherKeywords,
      reason: reasonForBlocking,
      imageUrl,
      externalId,
    };
  }
}

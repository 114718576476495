import { action } from '@ember/object';
import Component from '@glimmer/component';
import { runTask } from 'ember-lifeline';
import { cached } from 'tracked-toolbox';
import { v4 as uuid } from 'uuid';

interface SlideToggleComponentSignature {
  Args: {
    id?: string;
    isChecked: boolean;
    isDisabled?: boolean;
    onClick: (checked: boolean) => unknown;
  };
}

export default class SlideToggleComponent extends Component<SlideToggleComponentSignature> {
  @cached
  get id(): string {
    return this.args.id ?? uuid();
  }

  @action
  handleKeyUp(event: KeyboardEvent): void {
    if (event.key === ' ' || event.key === 'Spacebar') {
      event.preventDefault();
      event.stopImmediatePropagation();
      const input = <HTMLInputElement>document.getElementById(this.id);
      const toggled = !input.checked;
      this.args.onClick(toggled);
    }
  }

  @action
  toggle(event: Event): void {
    event.preventDefault();
    runTask(this, () => this.args.onClick(!this.args.isChecked));
  }
}

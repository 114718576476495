import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type LocalStorageService from 'garaje/services/local-storage';
import type StateService from 'garaje/services/state';
import urlBuilder from 'garaje/utils/url-builder';

export default class ConnectAnalyticsOccupancyController extends Controller {
  @service declare state: StateService;
  @service declare localStorage: LocalStorageService;
  @service declare featureFlags: FeatureFlagsService;

  @tracked loginRequired = false;

  get iframeUrl(): string {
    const companyId = this.state.currentCompany.id;

    return this.featureFlags.isEnabled('connect-occupancy-analytics-live')
      ? urlBuilder.embeddedConnectOccupancyAnalyticsUrl(companyId, this.loginRequired)
      : urlBuilder.embeddedConnectOccupancyAnalyticsDemoUrl(companyId, this.loginRequired);
  }

  @action
  trackLoggedIn(): void {
    /**
     * Issue: When user logs out from the Garaje app there is no way Next.js app knows it also needs to logout.
     * Due to this the cookies of the logout user may persist.
     * Solution: After login, force login the user on his first visit to this app. This refreshes all the cookies
     * To know if the user is visiting for the first time we will maintain a global state in localstorage called 'wa_logged_in'
     * On Login -> wa_logged_in does not exists in localstorage -> its first visit
     * On first visit -> SignIn, set wa_logged_in = true in localstorage
     * On Logout -> Remove wa_logged_in
     */

    const waLoggedIn = this.localStorage.getItem('wa_logged_in');

    if (!waLoggedIn) {
      this.loginRequired = true;
      this.localStorage.setItem('wa_logged_in', true);
    } else {
      this.loginRequired = false;
    }
  }
}

import Model, { belongsTo, attr } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('integration')
class IftttIntegration extends Model {
  @belongsTo('company', { async: true }) company;
  @attr('string', { defaultValue: '' }) granteeEmails;
}

export default IftttIntegration;

import Model, { attr } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('billing-v1')
class TaxModel extends Model {
  @attr('string') declare name: string;
  @attr('number') declare amount: number; // Cents
  @attr('string') declare description: string;
}

export default TaxModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    tax: TaxModel;
  }
}

import Component from '@glimmer/component';
import { format, subMinutes, fromUnixTime } from 'date-fns';
import { action } from '@ember/object';

export default class ApprovalScreenPartialDayComponent extends Component {
  @action
  formattedPartialDate(res) {
    const { minutesBetweenTimezones } = this.args;
    const startTime = format(subMinutes(fromUnixTime(res.startTime), minutesBetweenTimezones), 'h:mmaaa');
    const endTime = format(subMinutes(fromUnixTime(res.endTime), minutesBetweenTimezones), 'h:mmaaa');
    return `from ${startTime} to ${endTime}`;
  }
}

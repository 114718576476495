import ApplicationSerializer from './application';

export default class GlobalVisualComplianceConfigurationSerializer extends ApplicationSerializer {
  serialize() {
    const json = super.serialize(...arguments);
    if (json && json.data.attributes.password) {
      delete json.data.attributes.password;
    }
    return json;
  }

  modelNameFromPayloadKey(key) {
    if (key === 'flows') {
      return 'global-flow';
    } else if (key === 'visual-compliance-configurations') {
      return 'global-visual-compliance-configuration';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }

  payloadKeyFromModelName(modelName) {
    if (modelName === 'global-flow') {
      return 'flows';
    } else if (modelName === 'global-visual-compliance-configuration') {
      return 'visual-compliance-configurations';
    } else {
      return super.payloadKeyFromModelName(...arguments);
    }
  }
}

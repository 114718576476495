import { service } from '@ember/service';
import { isBlank, isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';
import { Permission } from 'garaje/utils/ui-permissions';
import _intersection from 'lodash/intersection';
import { reads } from 'macro-decorators';

const CAN_TRIAL_ROLES = [GLOBAL_ADMIN];

const CAN_VIEW_ONBOARDING_STEPS_ROLES = Object.freeze([GLOBAL_ADMIN, LOCATION_ADMIN]);
const CAN_VISIT_AUTHZ_PERMISSIONS = [
  Permission.VISITORS_ENTRY_READ,
  Permission.VISITORS_ENTRY_READ_MINE,
  Permission.VISITORS_INVITE_READ,
  Permission.VISITORS_INVITE_READ_MINE,
  Permission.ANALYTICS_LOCAL_VISITORS_DASH_READ,
  Permission.VISITORS_BLOCKLIST_READ,
  Permission.VISITORS_WATCHLIST_READ,
  Permission.VISITORS_FULL_LEGAL_NAME_SETTING_READ,
];

export default class VisitorAbility extends Ability {
  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;

  @reads('state.currentLocation') currentLocation!: StateService['currentLocation'];
  @reads('state.currentCompany') currentCompany!: StateService['currentCompany'];
  @reads('state.vrSubscription') vrSubscription!: StateService['vrSubscription'];

  get canTrial(): boolean {
    const { roleNames } = this.currentAdmin;
    const { vrSubscription } = this;
    return isPresent(_intersection(CAN_TRIAL_ROLES, roleNames)) && isBlank(vrSubscription);
  }

  get canViewOnboardingSteps(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_VIEW_ONBOARDING_STEPS_ROLES, roleNames));
  }

  get canVisit(): boolean {
    return this.authz.hasAnyPermissionAtCurrentLocation(CAN_VISIT_AUTHZ_PERMISSIONS);
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import brotherIpAddressValidator from 'garaje/validators/brother-ip-address';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';

const PRINTER_VALIDATIONS = {
  ip: brotherIpAddressValidator(),
};

// Printers with one of these as its `connectionType` can be edited.
// Other printers are configured via an external source, not garaje.
const EDITABLE_PRINTER_CONNECTION_TYPES = ['bluetooth', 'ip'];

/**
 * @param {Function}            cancelChanges                 Action to exit edit mode
 * @param {Boolean}             editMode                      Set to true if it's in edit mode
 * @param {Function}            [goToIPadPage]                Function called to transition to ipad route (if not provided, transitions to visitors.devices.ipads route)
 * @param {Object}              printer                       Printer to display
 * @param {Function}            savePrinterTask               Task to save printer
 * @param {Function}            deletePrinterTask             Task to delete printer
 * @param {Function}            setEditMode                   Action to set edit mode
 */
export default class PrinterDetails extends Component {
  @service abilities;

  printerValidations = PRINTER_VALIDATIONS;
  @tracked displayDeleteConfirm = false;

  get showDeleteOption() {
    return this.abilities.can('delete printer', this.args.printer);
  }

  get showEditOption() {
    const printer = this.args.printer;
    return (
      this.abilities.can('update printer', printer) &&
      EDITABLE_PRINTER_CONNECTION_TYPES.includes(printer.connectionType)
    );
  }

  get showOptionsMenu() {
    return this.showDeleteOption || this.showEditOption;
  }

  get printerChangeset() {
    const printer = this.args.printer;
    const validator = lookupValidator(this.printerValidations);

    return new Changeset(printer, validator, this.printerValidations);
  }
}

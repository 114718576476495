import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { isEmpty, isBlank } from '@ember/utils';
import { inject as service } from '@ember/service';
import _intersection from 'lodash/intersection';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';
import { formatScimProvider } from 'garaje/utils/format-scim-provider';

const SORT_BY_FUNC = (field, direction) => {
  const isAsc = direction === 'asc';

  let sortFn = () => {};

  if (field === 'Name') {
    sortFn = (itemA, itemB) => {
      const itemAName = get(itemA, 'fullName');
      const itemBName = get(itemB, 'fullName');

      // empty name should be sorted last
      if (isEmpty(itemAName) && isEmpty(itemBName)) {
        return 0;
      }

      if (isEmpty(itemAName)) {
        // Item B should come first
        return isAsc ? 1 : -1;
      }

      if (isEmpty(itemBName)) {
        // Item A should come first
        return isAsc ? -1 : 1;
      }

      return isAsc
        ? itemAName.localeCompare(itemBName, 'en', { sensitivity: 'base' })
        : itemBName.localeCompare(itemAName, 'en', { sensitivity: 'base' });
    };
  } else if (field === 'Last seen') {
    // acscending order of date is where oldest date comes first
    // recent the date, larger the value. (i.e recent_date > old_date)
    // ascending order sort function be { return date_A - date_b }
    sortFn = (itemA, itemB) => {
      const itemALastSeen = get(itemA, 'lastSeen');
      const itemBLastSeen = get(itemB, 'lastSeen');

      // We are assuming that if `lastSeenAt` field is empty, that
      // item happened in the infinite past
      if (isEmpty(itemALastSeen) && isEmpty(itemBLastSeen)) {
        return 0;
      }

      if (isEmpty(itemALastSeen)) {
        // Item A should come first
        return isAsc ? -1 : 1;
      }

      if (isEmpty(itemBLastSeen)) {
        // Item B should come first
        return isAsc ? 1 : -1;
      }

      if (itemALastSeen === itemBLastSeen) {
        return 0;
      }

      return isAsc ? itemALastSeen - itemBLastSeen : itemBLastSeen - itemALastSeen;
    };
  }

  return sortFn;
};

/**
 * @param {Array<Location>}             locations
 * @param {Array<String>}               locationsFilter
 * @param {String}                      nameFilter
 * @param {Boolean}                     refreshingModel
 * @param {Array}                       userRolesDigest
 * @param {Array}                       rolesFilter
 * @param {Function}                    updateNameFilter
 * @param {Function}                    updateLocationsFilter
 * @param {Function}                    updateRolesFilter
 * @param {String}                      adminBulkRoute
 * @param {Array}                       employeeGroupRoles
 */
export default class AdminRolesLayout extends Component {
  @service currentAdmin;
  @service currentLocation;
  @service abilities;

  @tracked sortBy = 'Name';

  sortByFunc = SORT_BY_FUNC;

  get viewOnly() {
    return isBlank(_intersection([GLOBAL_ADMIN, LOCATION_ADMIN], this.currentAdmin.roleNames));
  }

  get sortField() {
    return this.sortBy.replace(/^[-|+]/g, '');
  }

  get sortDirection() {
    return this.sortBy.startsWith('-') ? 'desc' : 'asc';
  }

  get sortedUsersWithRoles() {
    const userRolesDigest = this.args.userRolesDigest || [];
    const sortFunc = this.sortByFunc(this.sortField, this.sortDirection);

    return userRolesDigest.slice().sort(sortFunc);
  }

  get selectedLocations() {
    return this.args.locations.filter((location) => {
      return this.args.locationsFilter.includes(get(location, 'id'));
    });
  }

  get canViewFeatureDiscoveryBanner() {
    const scimIntegration = get(this.currentLocation, 'company.scimIntegration')?.content;
    // scim integration set up, but not syncing admins
    return (
      scimIntegration &&
      this.args.employeeGroupRoles?.length < 1 &&
      this.abilities.can('scim-sync-admins sync-settings')
    );
  }

  get featureDiscoveryDescription() {
    const scimIntegration = get(this.currentLocation, 'company.scimIntegration')?.content;
    const scimProvider =
      scimIntegration && scimIntegration.provider ? formatScimProvider(scimIntegration.provider) : 'SCIM';
    return `Automatically sync admin roles by setting up ${scimProvider} sync.`;
  }

  @action
  updateSortByWithDirection(field, direction) {
    const dir = direction === 'asc' ? '' : '-';
    this.sortBy = `${dir}${field}`;
  }
}

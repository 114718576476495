import EntryFragment from 'garaje/graphql/fragments/employee-schedule/EntryFragment';
import GenericResponseFragment from 'garaje/graphql/fragments/employee-schedule/GenericResponseFragment';
import gql from 'graphql-tag';

export default gql`
  mutation SignOutEntryReservation($id: ID!, $signedOutAt: DateTime!, $signedOutBy: String, $reservationId: ID!) {
    signOutEntry(id: $id, signedOutAt: $signedOutAt, signedOutBy: $signedOutBy) {
      ...EntryFragment
    }
    releaseDeskReservation(reservationId: $reservationId) {
      ...GenericResponseFragment
    }
  }
  ${EntryFragment}
  ${GenericResponseFragment}
`;

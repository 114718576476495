import ApplicationSerializer from './application';
import config from 'garaje/config/environment';
import { isPresent } from '@ember/utils';

const { host, envoyHost } = config;

export default class LocationSerializer extends ApplicationSerializer {
  get attrs() {
    return {
      blocklistContacts: 'blacklist-contacts',
      blocklistEnabled: 'blacklist-enabled',
      employeeScreeningEnabled: {
        serialize: false,
      },
      userDocumentRequirements: {
        serialize: false,
      },
    };
  }

  normalizeQueryRecordResponse() {
    const response = this.normalizeQueryResponse(...arguments);
    response.data = response.data[0] || null;
    return response;
  }

  normalize(_typeClass, hash) {
    const attributes = hash.attributes;

    if (isPresent(attributes['pre-registration-link'])) {
      attributes['pre-registration-link'] = attributes['pre-registration-link'].replace(host, envoyHost);
    }
    if (isPresent(attributes['security-desk-link'])) {
      attributes['security-desk-link'] = attributes['security-desk-link'].replace(host, envoyHost);
    }
    return super.normalize(...arguments);
  }
}

import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import dirtyCheck from 'garaje/utils/decorators/dirty-check';
import { routeEvent } from 'garaje/utils/decorators/route';

@dirtyCheck
class DeliveriesSettingsDeliveryAreasNewRoute extends Route {
  @service abilities;
  @service state;
  @service store;
  @service router;

  // start: dirty-check overrides
  _dirtyObject = 'changeset';
  _dirtyAttr = 'isDirty';
  // end: dirty-check overrides

  titleToken = 'Add Delivery Area';

  beforeModel() {
    if (this.abilities.cannot('create delivery-areas')) {
      this.router.transitionTo('unauthorized');
    }
  }

  model() {
    const { currentLocation, deliveriesSubscription } = this.state;
    const deliveryArea = this.store.createRecord('delivery-area', {
      address: get(currentLocation, 'address'),
      location: currentLocation,
    });

    // Include all the existing delivery areas for current location
    // and the delivery area created above.
    //
    // This is needed in order to include the new delivery area in the deliveryAreas list passed to
    // `delivery-area-form` component to show the Order Confirmation
    const { deliveryAreas } = this.modelFor('deliveries');

    return hash({ deliveriesSubscription, deliveryArea, deliveryAreas });
  }

  @routeEvent
  routeWillChange() {
    const model = this.controller.model.deliveryArea;
    if (model && get(model, 'isNew')) {
      model.unloadRecord();
    }
  }
}

export default DeliveriesSettingsDeliveryAreasNewRoute;

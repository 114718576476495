import Service, { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import type AbilitiesService from 'ember-can/services/abilities';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import includesAny from 'garaje/utils/includes-any';
import { GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST } from 'garaje/utils/roles';
import { reads } from 'macro-decorators';

type WorkplaceRouteConfig =
  | { type: 'off' }
  | { type: 'start-trial' }
  | { type: 'legacy-protect'; canVisitSettings: boolean; canVisitAnnouncements: boolean; canVisitEmployeeLog: boolean }
  | {
      type: 'subscribed';
      canVisitSetupGuide: boolean;
      canVisitSettings: boolean;
      canVisitAnnouncements: boolean;
      canVisitEmployeeLog: boolean;
    };

export default class WorkplaceRouteConfigService extends Service {
  @service declare abilities: AbilitiesService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;
  @service declare featureConfig: FeatureConfigService;

  @reads('state.workplaceSubscription') subscription!: StateService['workplaceSubscription'];
  @reads('state.vrSubscription') vrSubscription!: StateService['vrSubscription'];
  @reads('currentAdmin.roleNames') roles!: CurrentAdminService['roleNames'];

  computeWorkplaceRouteConfig(): WorkplaceRouteConfig {
    const { subscription, vrSubscription, roles } = this;
    const isAdmin = includesAny(roles, GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST);
    const canVisitEmployeeLog =
      isAdmin ||
      (this.abilities.can('use dbeam in workplace') &&
        this.featureConfig.isEnabled('adminConfig.canViewAllReservations'));

    if (!this.featureFlags.isEnabled('workplace-garaje-launch') || (!isAdmin && !canVisitEmployeeLog)) {
      return { type: 'off' };
    }

    const canAccessProtectLegacyFeatures = !!vrSubscription?.canAccessProtectLegacyFeatures;
    if (isBlank(subscription) && !canAccessProtectLegacyFeatures) {
      return { type: 'start-trial' };
    }

    if (isBlank(subscription) && canAccessProtectLegacyFeatures) {
      return {
        type: 'legacy-protect',
        canVisitAnnouncements: isAdmin,
        canVisitSettings: isAdmin,
        canVisitEmployeeLog: canVisitEmployeeLog,
      };
    }

    if (!isAdmin && canVisitEmployeeLog) {
      return {
        type: 'subscribed',
        canVisitEmployeeLog,
        canVisitAnnouncements: false,
        canVisitSettings: false,
        canVisitSetupGuide: false,
      };
    }

    return {
      type: 'subscribed',
      canVisitSetupGuide: true,
      canVisitSettings: true,
      canVisitAnnouncements: true,
      canVisitEmployeeLog: true,
    };
  }
}

import Component from '@glimmer/component';
import { get, action } from '@ember/object';
import { filter } from 'macro-decorators';
import { task } from 'ember-concurrency';
import { localCopy } from 'tracked-toolbox';

const HIDDEN_FIELD_IDENTIFIERS = Object.freeze(['host', 'name']);

const configurableFieldsFilterFn = (field) => {
  return !HIDDEN_FIELD_IDENTIFIERS.includes(get(field, 'identifier'));
};

/**
 * @param {Array<SignInField>} signInFields         Required. Sign in fields
 * @param {Flow}               flow                 Required. Used to set storeResponseConfig
 * @param {Task}               saveFieldsTask       Required. Pass through of task to save fields
 * @param {Boolean}            isDisabled           Optional. Indicate inputs are entirely disabled
 * @param {Boolean}            isPristine           Required. Indicate unsaved changes
 * @param {Function}           onChange             Optional. Action to take when data modified
 */
export default class SignInFieldsRespondentsComponent extends Component {
  @localCopy('args.signInFields') signInFields;

  optionsName = 'respondents';

  @filter('signInFields', configurableFieldsFilterFn)
  configurableFields;

  @action
  onChange(field, attr) {
    if (typeof this.args.onChange === 'function') {
      this.args.onChange(field, attr);
    }
  }

  @task
  *saveRespondentsOptionsTask() {
    yield this.args.saveFieldsTask.perform();
  }
}

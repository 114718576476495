import { action } from '@ember/object';
import Component from '@glimmer/component';

interface ListItemCheckboxArgs<T> {
  isDisabled: boolean;
  item: T;
  selectedItems: T[];
  userToggleChecked: (item: T, event: PointerEvent, checked: boolean) => unknown;
}

export default class ListItemCheckbox<T> extends Component<ListItemCheckboxArgs<T>> {
  get isSelected(): boolean {
    return this.args.selectedItems.includes(this.args.item);
  }

  @action
  didClick(event: PointerEvent): void {
    const checkbox = event.target as HTMLInputElement;
    this.args.userToggleChecked(this.args.item, event, checkbox.checked);
  }
}

import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class GlobalAnalyticsDesksIndexRoute extends Route {
  title = 'Desks · Global Analytics · Envoy';

  @service globalOverviewAccess;
  @service router;

  redirect() {
    if (!this.globalOverviewAccess.canVisitConsolidatedDesksAnalytics) {
      this.router.transitionTo('unauthorized');
    }
  }
}

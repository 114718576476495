import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type AuthzService from 'garaje/services/authz';

import type ManageCreateRoleController from './controller';

interface ModelParams {
  role_id: string;
}

class ManageCreateRoleRoute extends Route {
  @service declare authz: AuthzService;
  @service declare router: RouterService;
  @service declare abilities: AbilitiesService;

  titleToken = 'Create role';

  beforeModel(): void {
    if (this.abilities.cannot('create custom-roles')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  async model({ role_id }: ModelParams): Promise<unknown> {
    const matrix = await this.authz.fetchMatrix(role_id);
    const role = await this.authz.fetchRole(role_id);

    return { role, matrix };
  }

  setupController(controller: ManageCreateRoleController, model: ModelParams, transition: Transition): void {
    super.setupController(controller, model, transition);
    controller.initProperties();
  }

  resetController(controller: ManageCreateRoleController): void {
    controller.reset();
  }
}

export default ManageCreateRoleRoute;

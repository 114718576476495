import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import type StateService from 'garaje/services/state';
import { Permission } from 'garaje/utils/ui-permissions';

export default class GlobalVisitorLogAbility extends Ability {
  @service declare state: StateService;
  @service declare authz: AuthzService;

  get canVisit(): boolean {
    const { vrSubscription } = this.state;
    // Company doesn't have VR
    if (isBlank(vrSubscription)) {
      return false;
    }

    return !vrSubscription?.isBasicUser && this.authz.hasPermissionAtCurrentCompany(Permission.VISITORS_ENTRY_READ);
  }
}

import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type PrinterModel from 'garaje/models/printer';
import PropertyPrinterModel from 'garaje/models/property-printer';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import { GLOBAL_ADMIN, ZONE_ADMIN } from 'garaje/utils/roles';
import { Permission } from 'garaje/utils/ui-permissions';
import _intersection from 'lodash/intersection';

const CONNECT_DELETE_ROLES = [GLOBAL_ADMIN, ZONE_ADMIN];
const CONNECT_UPDATE_ROLES = [GLOBAL_ADMIN, ZONE_ADMIN];

export default class PrinterAbility extends Ability {
  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;

  declare model?: PrinterModel | PropertyPrinterModel;

  get canCreate(): boolean {
    if (this.featureFlags.isEnabled('expanded-printer-support-v1')) {
      const permissions = [Permission.VISITORS_PRINTER_CREATE, Permission.VISITORS_PRINTER_PASSCODE_CREATE];
      return this.authz.hasAllPermissionsAtCurrentLocation(permissions);
    }
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_PRINTER_CREATE);
  }

  get canDelete(): boolean {
    const { roleNames } = this.currentAdmin;

    const isPropertyPrinter = this.model && this.model instanceof PropertyPrinterModel;
    if (isPropertyPrinter) {
      // if the printer belongs to a property, use a Connect-specific check for permissions.
      return isPresent(_intersection(CONNECT_DELETE_ROLES, roleNames));
    }

    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_PRINTER_DELETE);
  }

  get canUpdate(): boolean {
    const { roleNames } = this.currentAdmin;

    const isPropertyPrinter = this.model && this.model instanceof PropertyPrinterModel;
    if (isPropertyPrinter) {
      // if the printer belongs to a property, use a Connect-specific check for permissions.
      return isPresent(_intersection(CONNECT_UPDATE_ROLES, roleNames));
    }

    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_PRINTER_UPDATE);
  }

  get canVisit(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_PRINTER_READ);
  }

  get canViewNavItem(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_PRINTER_NAV);
  }
}

import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';

/**
 * @param {Class<Device>}           ipad
 * @param {Function}                deleteAction
 */
export default class IpadDetails extends Component {
  @service ipadIcon;

  progressImgPath = '/assets/images/orange-progress-small.svg';

  // @heroiceric: wat there are at least 3 of these same functions
  get ipadIconURI() {
    const deviceType = get(this.args.ipad, 'deviceType');
    const isSleeping = get(this.args.ipad, 'isSleeping');

    // We are not worried about status yet on delivery devices
    const isStatusBad = false;

    return this.ipadIcon.getIconURI(deviceType, isSleeping, isStatusBad);
  }

  @action
  selectAction({ target }) {
    const { value } = target;

    if (value === 'delete') {
      this.args.deleteAction();
    }

    target.selectedIndex = 0;
  }
}

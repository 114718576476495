import Service, { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import config from 'garaje/config/environment';
import type AjaxService from 'garaje/services/ajax';
import type LocalStorageService from 'garaje/services/local-storage';
import type SessionService from 'garaje/services/session';
import type StateService from 'garaje/services/state';
// eslint-disable-next-line lodash/import-scope
import { type DebouncedFunc } from 'lodash';
import _throttle from 'lodash/throttle';

export default class InactivityService extends Service {
  @service declare ajax: AjaxService;
  @service declare localStorage: LocalStorageService;
  @service declare session: SessionService;
  @service declare state: StateService;
  @service declare store: StoreService;

  @tracked companyConfigurationId: string = '';

  timeoutDuration: number | null = null; // milliseconds
  throttledFunction: DebouncedFunc<() => void> | null = null;
  intervalId: NodeJS.Timeout | null = null;

  get canStartTimer(): boolean {
    return this.session.isAuthenticated;
  }

  async initialize(): Promise<void> {
    if (config.environment === 'development') return;
    if (!this.canStartTimer || !this.state.currentCompany) return;

    this.companyConfigurationId = await this.state.fetchCompanyConfigId();

    if (!this.companyConfigurationId) return;
    // fetch the company-configuration record and add it to the store
    const companyConfiguration = await this.store.findRecord('company-configuration', this.companyConfigurationId);

    if (!companyConfiguration) return;
    this.timeoutDuration = companyConfiguration.sessionInactivityTimeout;

    // only add event listeners if inactivity timeout is enabled (non null)
    if (this.timeoutDuration) {
      const throttled = _throttle(this.handleActivity.bind(this), 250);
      this.throttledFunction = throttled;

      // add event listeners to track user activity
      window.addEventListener('mousemove', throttled);
      window.addEventListener('click', throttled);
      window.addEventListener('scrollend', throttled);
      window.addEventListener('keydown', throttled);

      this.startTimer();
    }
  }

  cleanUp(): void {
    this.clearTimer();
    this.timeoutDuration = null;

    // remove event listeners
    if (this.throttledFunction) {
      window.removeEventListener('mousemove', this.throttledFunction);
      window.removeEventListener('click', this.throttledFunction);
      window.removeEventListener('scrollend', this.throttledFunction);
      window.removeEventListener('keydown', this.throttledFunction);
    }
  }

  // start the inactivity timer
  startTimer(): void {
    this.clearTimer();
    // check if logged in
    if (this.canStartTimer && this.timeoutDuration) {
      // use timestamps in case computer went to sleep
      const timestamp = Date.now() + this.timeoutDuration;
      this.intervalId = setInterval(() => {
        if (timestamp <= Date.now()) {
          void this.logout();
        }
      }, 5000);
    }
  }

  clearTimer(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  // handle user activity (eg mousemove or keydown)
  handleActivity(): void {
    this.startTimer();
  }

  async logout(): Promise<void> {
    this.localStorage.setItem('sessionTimeout', 'true');
    await this.session.invalidate();
  }
}

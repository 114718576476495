import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import popupFlow from 'garaje/utils/popup-flow';

/**
 * @param {Object}                              field
 * @param {Object}                              output
 * @param {Object}                              install
 * @param {Object}                              plugin
 * @param {Service<CurrentLocation>}            currentLocation
 * @param {Function}                            updateValue
 */
export default class Popup extends Component {
  @dropTask
  *openPopup() {
    const { url, width, height } = this.args.field;
    yield popupFlow(url, width ?? 750, height ?? 500);
  }
}

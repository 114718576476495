import Service, { service } from '@ember/service';
import type MetricsService from 'garaje/services/metrics';
import { APP } from 'garaje/utils/enums';

/**
 * A facade for `metrics` so that we can log to both segment and datadog
 */
export default class RoombaMetricsService extends Service {
  @service declare metrics: MetricsService;

  trackEvent(event: string, properties: Record<string, unknown> = {}, options: Record<string, unknown> = {}): void {
    const propertiesWithSharedContext = {
      product: APP.ROOMS,
      ...this.metrics.getStateEventProperties(),
      ...properties,
    };
    this.metrics.trackEvent(event, propertiesWithSharedContext, options);
    this.metrics.logMonitorEvent(event, propertiesWithSharedContext);
  }
}

import type Model from '@ember-data/model';
import ExtendedInfinityModel, { type PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';

/**
 *  Check is a record array is an ember infinity page model
 *
 * @param page page to check
 * @returns if the page is paginated
 */
export function isPaginated<T extends Model>(page: PaginatedRecordArray<T> | T[]): page is PaginatedRecordArray<T> {
  return page instanceof ExtendedInfinityModel;
}

import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StateService from 'garaje/services/state';

export default class CommunicationsMessageLogNewRoute extends Route {
  @service declare state: StateService;

  async model(): Promise<void> {
    // Preload emergency notification configuration
    await this.state.currentLocation.getEmergencyNotificationConfiguration();
  }
}

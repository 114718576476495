/**
 * TODO
 * We are pulling plans from both the envoy-web products database as well as Chargebee.
 * The plan ids in envoy-web do not always match with the Chargebee plan_levels. We should
 * migrate to pulling all plans from Chargebee and deprecate the envoy-web plans.
 */

const PLAN_ID_TO_RANK = Object.freeze({
  basic: 0, // visitors and deliveries basic
  'rooms-basic': 0, // rooms basic,
  'desks-basic': 0, // desks basic,
  'deliveries-standard': 10, // deliveries premium plan id in envoy-web
  standard: 10, // deliveries premium plan_level in Chargebee
  'rooms-standard': 10, // rooms standard
  'standard-1': 10, // visitors standard
  'desks-standard': 10, // desks standard
  'empxp-standard': 10, // workplace standard
  premium: 20, // visitors premium
  'empxp-premium': 20, // workplace premium
  'premium-plus': 25, // generic premium plus
  'empxp-premium-plus': 25, // workplace premium plus
  enterprise: 30, // visitors enterprise
});

function planRank(planId) {
  return PLAN_ID_TO_RANK[planId];
}

export default planRank;

import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * @param {Model}                   ipad
 * @param {Boolean}                 isSelected
 * @param {Function}                unselectIPad
 * @param {Function}                selectIPad
 */
export default class GlobalDevicesIpadsIpadRow extends Component {
  @action
  onIpadCheckboxClick(event) {
    if (event.target.checked) {
      // add ipad when user select the ipad
      this.args.selectIPad(this.args.ipad);
    } else {
      // remove ipad from selected ipad
      this.args.unselectIPad(this.args.ipad);
    }
  }
}

import { helper } from '@ember/component/helper';
import moment from 'moment-timezone';

export function formatTimezoneAbbreviation([timezone, ..._rest]) {
  const abbr = moment.tz(timezone).zoneAbbr();
  if (/^[A-Za-z]+$/.test(abbr)) {
    return abbr;
  }
  const timeDif = moment.tz(timezone).format('Z');
  return `UTC ${timeDif}`;
}

export default helper(formatTimezoneAbbreviation);

import Route from '@ember/routing/route';

import type { ProfileDocumentRouteModel } from '../../route';

export default class ProfileDocumentUploadIndexRoute extends Route {
  redirect(): void {
    const { userDocumentTemplate } = <ProfileDocumentRouteModel>this.modelFor('profile.document');

    const sortedUserDocumentTemplateAttachments =
      userDocumentTemplate!.userDocumentTemplateAttachments.sortBy('position');

    // eslint-disable-next-line ember/no-deprecated-router-transition-methods
    void this.replaceWith(
      'profile.document.upload.attachment',
      userDocumentTemplate!.identifier,
      sortedUserDocumentTemplateAttachments.firstObject!.identifier,
    );
  }
}

import { dropTask, timeout } from 'ember-concurrency';
import zft from 'garaje/utils/zero-for-tests';

const POLLING_DURATION = 30000;
const POLLING_TIMEOUT = 500;

function withSubscriptionBatchPolling(Class) {
  return class WithSubscriptionBatchPolling extends Class {
    @dropTask
    *pollForSubscriptionBatchTask(subscriptionBatch) {
      this.setPollingDuration.perform();

      yield subscriptionBatch.reload();

      while (subscriptionBatch.isPending) {
        yield timeout(zft(POLLING_TIMEOUT));
        yield subscriptionBatch.reload();
      }

      yield this.setPollingDuration.cancelAll();

      if (!subscriptionBatch.isSuccessful) {
        throw new Error('Something went wrong. Please try again.');
      }

      yield this.state.initializeState.perform();
      yield this.reset();
    }

    @dropTask
    *setPollingDuration() {
      yield timeout(POLLING_DURATION);
      yield this.pollForSubscriptionBatchTask.cancelAll();
    }
  };
}

export default withSubscriptionBatchPolling;

import Component from '@glimmer/component';

const STATUS_COLORS = {
  inactive: 'carbon',
  connected: 'cilantro',
  disconnected: 'pistachio',
  pending: 'papaya',
  invite_expired: 'papaya',
  invited: 'gem',
  not_invited: 'papaya',
};

const STATUS_ICONS = {
  inactive: 'ui-info',
  connected: 'ui-checkmark',
  disconnected: 'ui-exclamation',
  pending: 'ui-caution',
  invite_expired: 'ui-caution',
  invited: 'ui-email',
  not_invited: 'ui-caution',
};

/**
 * @param {string} status - the status of the tenant
 */
export default class TenantStatusComponent extends Component {
  get statusKey() {
    return (this.args.status || '').toLowerCase();
  }

  get color() {
    return STATUS_COLORS[this.statusKey];
  }

  get icon() {
    return STATUS_ICONS[this.statusKey];
  }

  get text() {
    return this.args.status?.split('_').join(' ');
  }
}

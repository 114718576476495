import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class LocationOverviewProtectAnalyticsReportsRoute extends Route {
  title = 'Protect · Global Analytics · Reports · Envoy';

  @service abilities;
  @service router;
  @service state;

  get hasScheduledReports() {
    return this.state.vrSubscription.canAccessScheduledReports;
  }

  beforeModel() {
    if (this.abilities.cannot('visit protect in global-analytics')) {
      if (this.abilities.can('visit analytics upsell in global-analytic')) {
        this.router.transitionTo('location-overview.analytics.protect.upsell');
      } else {
        this.router.transitionTo('unauthorized');
      }
    }
    if (!this.hasScheduledReports) {
      if (this.abilities.can('visit analytics upsell in global-analytic')) {
        this.router.transitionTo('location-overview.analytics.protect.upsell');
      } else {
        this.router.transitionTo('location-overview.analytics.protect');
      }
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.showModal = false;
  }
}

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface AccentColorArgs {
  color: string;
  setColor?: (color?: string) => unknown;
}

export default class AccentColor extends Component<AccentColorArgs> {
  @tracked showColorPicker = false;

  get style(): string {
    // based on http://www.nbdtech.com/Blog/archive/2008/04/27/Calculating-the-Perceived-Brightness-of-a-Color.aspx
    const rgb = this.hexToRgb(this.args.color);
    if (!rgb) return 'color:black';

    const backgroundStyle = `background-color: ${
      this.args.color[0] === '#' ? this.args.color : '#' + this.args.color
    };`;

    const { r, g, b } = rgb;
    const brightness = Math.sqrt(0.241 * Math.pow(r, 2) + 0.691 * Math.pow(g, 2) + 0.068 * Math.pow(b, 2));
    if (brightness >= 130) {
      return 'color:black;' + backgroundStyle;
    } else {
      return 'color:white;' + backgroundStyle;
    }
  }

  hexToRgb(hex: string): { r: number; g: number; b: number } | null {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result && result[1] && result[2] && result[3]) {
      return {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      };
    }
    return null;
  }

  get isLightBackground(): boolean {
    // Get the luminosity of the background color based on this formula:
    //   .299R^2 + .587G^2 + .114*B^2
    // The iPad switches text color from white to slate if luminosity is >= 0.38, so we do the same here.
    // `color` is passed to this component as a hex string => "#ababab"

    const hexColor = this.args.color.substring(1);
    const redPart = hexColor.substring(0, 2);
    const greenPart = hexColor.substring(2, 4);
    const bluePart = hexColor.substring(4, 6);

    const red = parseInt(redPart, 16) / 255.0;
    const green = parseInt(greenPart, 16) / 255.0;
    const blue = parseInt(bluePart, 16) / 255.0;

    const luminosity = 0.299 * (red * red) + 0.587 * (green * green) + 0.114 * (blue * blue);

    return luminosity >= 0.38;
  }

  @action
  setColor(): void {
    this.args.setColor?.();
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import urlBuilder from 'garaje/utils/url-builder';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

/**
 * @param {Model<AreaMap>}          areaMap
 * @param {Task}                    floorModalTask
 * @param {Boolean}                 isCreatingMapFloor
 * @param {Model<MapFloor>}         mapFloor
 * @param {string}                  transitionRoute
 */
export default class EditMapFloorModalComponent extends Component {
  @service store;
  @service flashMessages;
  @service ajax;
  @service router;
  @service metrics;

  @tracked floorName;
  @tracked floorNameError = [];
  @tracked fileError = [];
  @tracked file = null;

  uploadRasterImageTask = task({ drop: true }, async (floor) => {
    const uploadUrl = urlBuilder.maps.rasterImageUrl(floor.id);

    const formData = new FormData();
    formData.append('file', this.file);

    try {
      await this.ajax.request(uploadUrl, {
        accept: 'application/vnd.api+json',
        contentType: false,
        data: formData,
        processData: false,
        type: 'PATCH',
      });

      this.metrics.trackEvent('Map - Upload floor plan');

      this.flashMessages.showAndHideFlash('success', 'File uploaded!');
    } catch (err) {
      this.flashMessages.showAndHideFlash('error', 'Error uploading file', parseErrorForDisplay(err));
    }
  });

  createFloorTask = task(async () => {
    const { areaMap, transitionRoute } = this.args;
    const mapFloor = this.store.createRecord('map-floor', {
      areaMap,
      name: this.floorName,
      ordinality: (get(areaMap, 'mapFloors.length') || 0) + 1,
    });

    try {
      await mapFloor.save();
      await this.uploadRasterImageTask.perform(mapFloor);
      await mapFloor.reload();

      const drafts = await areaMap.drafts;
      this.metrics.trackEvent('Creating Floor', { isDraft: drafts.length > 0 });
      this.router.transitionTo(transitionRoute, mapFloor.id);
      this.resetValues();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error creating map floor', parseErrorForDisplay(e));
    }
  });

  editFloorTask = task(async () => {
    const { mapFloor } = this.args;
    set(mapFloor, 'name', this.floorName);
    if (mapFloor.hasDirtyAttributes) {
      try {
        await mapFloor.save();
        this.flashMessages.showAndHideFlash('success', 'Floor updated successfully');
      } catch (e) {
        this.flashMessages.showAndHideFlash('Error saving floor', parseErrorForDisplay(e));
      }
    }

    if (this.file) {
      await this.uploadRasterImageTask.perform(mapFloor);
      await mapFloor.reload();
    }
  });

  @action
  setFloorName() {
    this.floorName = this.args.isCreatingMapFloor ? null : this.args.mapFloor.name;
  }

  @action
  onFloorNameChange(name) {
    const { areaMap, mapFloor, isCreatingMapFloor } = this.args;
    this.floorName = name;

    const hasDuplicateName = areaMap.mapFloors?.any((findMapFloor) => {
      if (findMapFloor.name === name) {
        if (isCreatingMapFloor) return true;

        return findMapFloor.id !== mapFloor.id;
      }
    });

    if (name === '') {
      this.floorNameError = ['Please enter a floor name'];
    } else if (hasDuplicateName) {
      this.floorNameError = ['Floor name already exists'];
    } else {
      this.floorNameError = [];
    }
  }

  @action
  onFileUpload(ev) {
    const file = ev.target.files[0] || ev.files[0];

    if (file) {
      this.file = file;
      this.fileError = [];
    }
  }

  onSubmitTask = task({ drop: true }, async (event) => {
    event.preventDefault();

    const { floorModalTask, isCreatingMapFloor } = this.args;

    if (!this.floorName) this.floorNameError = ['Please enter a floor name'];
    if (!this.file && isCreatingMapFloor) this.fileError = ['Please upload a floor map'];
    if (!(this.floorNameError.length || this.fileError.length)) {
      if (isCreatingMapFloor) {
        await this.createFloorTask.perform();
      } else {
        await this.editFloorTask.perform();
      }

      floorModalTask.last.continue();
    }
  });

  @action
  onCancel() {
    const { floorModalTask } = this.args;
    floorModalTask.last.abort();
    this.resetValues();
  }

  resetValues() {
    this.floorName = null;
    this.floorNameError = [];
    this.fileError = [];
    this.file = null;
  }
}

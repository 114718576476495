// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { notEmpty } from '@ember/object/computed';
import { get } from '@ember/object';

// eslint-disable-next-line ember/no-classic-classes
export default Component.extend({
  tagName: 'label',
  for: null,
  required: false,
  isReadonly: false,
  text: null,

  'data-test-ui-label': true,
  attributeBindings: Object.freeze(['for', 'data-test-ui-label']),
  hasErrors: notEmpty('errors'),

  classNameBindings: Object.freeze([
    'hasErrors:error-description',
    'required:requiredField',
    'isReadonly:cursor-default',
  ]),

  init() {
    this._super(...arguments);

    const errors = get(this, 'errors');

    if (!errors) {
      this.errors = [];
    }
  },
});

/* eslint-disable ember/no-computed-properties-in-native-classes */
/* eslint-disable no-setter-return */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action, set, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import ScimMapping from 'garaje/models/scim-mapping';
import ScimField from 'garaje/models/scim-field';
import { not } from '@ember/object/computed';

/**
 * @param {Object}              model
 * @param {Function}            saveTask
 * @param {Boolean}             showSave
 */
export default class ScimConfigBoxFieldMappings extends Component {
  @service flashMessages;
  @service store;
  @service locations;

  @not('isMapAll') withLocationMappings;

  @computed('model.locationMapping')
  get isMapAll() {
    // Get the initial value from the model, use whatever is set
    // after that as the value.
    return isEmpty(this.model.locationMapping);
  }

  set isMapAll(value) {
    return value;
  }

  get filterOperations() {
    return [
      { name: 'Equals', value: '=' },
      { name: 'Contains', value: '=~' },
    ];
  }

  get scimFields() {
    return [
      new ScimField({
        label: 'Locale',
        name: 'locale',
        operators: ['=', '=~'],
      }),
      new ScimField({
        label: 'City',
        name: 'city',
        operators: ['=', '=~'],
      }),
    ];
  }

  @action
  addMapping(location, scimField) {
    const { model } = this;
    const locationMapping = [
      ...(model.locationMapping || []),
      Object.freeze(
        new ScimMapping({
          label: scimField.label,
          name: scimField.name,
          value: '',
          'location-id': location.id,
        }),
      ),
    ];
    set(model, 'locationMapping', Object.freeze(locationMapping));
  }

  @action
  setMappings(type) {
    const { model } = this;

    if (!model) return;

    switch (type) {
      case 'all':
        set(model, 'locationMapping', null);
        this.isMapAll = true;
        break;
      case 'locations':
        set(model, 'locationMapping', []);
        this.isMapAll = false;
        break;
    }
  }

  updateMapping(mapping, attrs = {}) {
    const { model } = this;
    const locationMapping = [...(model.locationMapping || [])];
    const updatedMapping = Object.freeze(
      new ScimMapping({
        ...mapping,
        ...attrs,
      }),
    );
    locationMapping.splice(locationMapping.indexOf(mapping), 1, updatedMapping);
    set(model, 'locationMapping', Object.freeze(locationMapping));
  }

  @action
  handleOperationChange(mapping, operation) {
    this.updateMapping(mapping, {
      operation,
    });
  }

  @action
  handleValueChange(mapping, evt) {
    const { value } = evt.target;
    this.updateMapping(mapping, {
      value,
    });
  }

  @action
  save() {
    return this.saveTask.perform();
  }

  @action
  removeMapping(mapping) {
    const { model } = this;
    const locationMapping = [...(model.locationMapping || [])];
    locationMapping.splice(locationMapping.indexOf(mapping), 1);
    set(model, 'locationMapping', Object.freeze(locationMapping));
  }
}

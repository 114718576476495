import { isArray, A } from '@ember/array';
import Transform from '@ember-data/serializer/transform';

export default class ContactsTransform extends Transform {
  deserialize(value) {
    if (isArray(value)) {
      const _val = value.map((v) => {
        return { userId: v['user-id'], fullName: v['full-name'] };
      });
      return A(_val);
    } else {
      return A();
    }
  }
}

// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { dropTask, waitForProperty } from 'ember-concurrency';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class DashboardAccountSetUpComponent extends Component {
  @service router;
  @service flashMessages;
  @service('setupGuideSteps') setupGuideStepsService;

  // eslint-disable-next-line ember/classic-decorator-hooks
  init() {
    super.init(...arguments);
    this.setupGuideStepsService.loadStepsTask.perform();
  }

  processinStep = null;

  /**
   * reviewStepAndTransitionTask
   * @param {LocationSetupGuideStep} locationSetupGuideStep
   * @param {Event} evt
   */
  @dropTask
  *reviewStepAndTransitionTask(locationSetupGuideStep, evt) {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }
    set(this, 'processinStep', locationSetupGuideStep);
    try {
      set(locationSetupGuideStep, 'reviewed', true);
      yield locationSetupGuideStep.save();
      // Keep the loading spinner until we destroy the component or we finish the transition
      this.router.transitionTo(locationSetupGuideStep.routeName).then(() => {
        if (!this.isDestroyed && !this.isDestroying) {
          set(this, 'transitionDone', true);
        }
      });
      yield waitForProperty(this, 'transitionDone');
    } catch (error) {
      /* eslint-disable no-console */
      console.log({ error });
      /* eslint-enable no-console */
      this.flashMessages.showFlash('error', "We're sorry, something went wrong.");
    }
  }
}

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type DetailedChangeset } from 'ember-changeset/types';
import { dropTask, type Task } from 'ember-concurrency';
import type ZoneModel from 'garaje/models/zone';
import type { FileUpload } from 'garaje/pods/components/direct-uploader/component';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

interface PropertyFormLogoConfigArgs {
  changeset: DetailedChangeset<ZoneModel>;
  onPhotoLoading: (isLoading: boolean) => unknown;
  property: ZoneModel;
  saveTask: Task<void, [DetailedChangeset<ZoneModel>]>;
}

export default class PropertyFormLogoConfig extends Component<PropertyFormLogoConfigArgs> {
  @tracked isOpen = false;
  @tracked pendingUpload: FileUpload | null = null;
  @tracked filePreview: string | null = null;

  isEmpty = !this.args.changeset.logo;

  get imgSrc(): string | null {
    if (this.args.changeset.logo === null) return null;
    return this.filePreview ?? this.args.changeset.logo;
  }

  @action
  onPendingUpload(pendingUpload?: FileUpload): void {
    this.pendingUpload = pendingUpload ? pendingUpload : null;
  }

  @action
  cancel(): void {
    this.isOpen = false;
    this.args.changeset.rollback();
  }

  @action
  reset(): void {
    this.pendingUpload?.reset();

    if (this.isEmpty) {
      this.args.changeset.rollbackProperty('logo');
    } else {
      this.args.changeset.logo = null;
    }

    this.pendingUpload = null;
  }

  @action
  previewUpdated(preview: string): void {
    this.args.changeset.logo = preview;
    this.filePreview = preview;
  }

  saveTask = dropTask(async () => {
    this.args.onPhotoLoading(true);

    if (this.pendingUpload) {
      this.args.changeset.logo = <string>await this.pendingUpload.upload().catch(throwUnlessTaskDidCancel);
    }

    await this.args.saveTask.perform(this.args.changeset).catch(throwUnlessTaskDidCancel);

    this.isOpen = false;
    this.pendingUpload = null;
    this.filePreview = null;
    this.isEmpty = !this.args.changeset.logo;
    this.args.onPhotoLoading(false);
  });
}

import Store from '@ember-data/store';
import { get } from '@ember/object';
import Formatter from 'garaje/utils/json-api-data-formatter';
import ArrayProxy from '@ember/array/proxy';
import { A } from '@ember/array';

export default class extends Store {
  /**
   * Support an alternative mechanism to map a modelName to an adapter via an optional
   * @adapter decorator on each model.
   *
   * If model has been decorated, it takes precedence over the default adapter lookup
   * mechanism even if there is an adapter named after the model.
   *
   * The primary driver for this is to limit the sprawl of per-type adapters. Ember Data's
   * only mechanism of a single application adapter is not suitable for models that span
   * across many different backends.
   *
   * @param {String} modelName identical to store.query() param
   * @returns {Adapter}
   */
  adapterFor(modelName) {
    const modelClass = this.modelFor(modelName);
    const adapterName = modelClass.adapterName;
    return super.adapterFor(adapterName || modelName);
  }

  /**
   * Alternative method for mapping a serializer to a model.
   *
   * @param {String} modelName
   * @returns {Serializer}
   */
  serializerFor(modelName) {
    if (modelName === 'application' || modelName === '-default') {
      return super.serializerFor(modelName);
    }
    const modelClass = this.modelFor(modelName);
    const serializerName = modelClass.serializerName;
    return super.serializerFor(serializerName || modelName);
  }

  /**
   * Query a json api endpoint transiently without storing reponse data in Ember's store.
   *
   * - Identical signature to store.query()
   * - Leverages the model's existing adapter to handle the GET request
   * - Normalize the json api response doc before returning it directly (So associations make sense)
   *
   * @param {String} modelName: identical to store.query() param
   * @param {Object} query identical to store.query() param
   * @returns {ArrayProxy} Quacks like a AdapterPopulatedRecordArray
   */
  async queryTransient(modelName, query) {
    const adapter = this.adapterFor(modelName);
    const url = adapter.buildURL(modelName, null, null, 'query', query);
    const data = await adapter.ajax(url, 'GET', {
      data: { ...query },
    });
    const formatter = new Formatter();
    const formattedData = formatter.deserialize(data);
    return ArrayProxy.create({ meta: formattedData.meta, content: A(formattedData.data) });
  }

  push(payload) {
    /*
     * Fixes Double Invite Bug
     *
     * REF: https://app.clubhouse.io/envoy/story/2802/inviting-a-visitor-from-the-dashboard-displays-two-duplicate-invite-views
     * REF: https://app.clubhouse.io/envoy/story/1783/inviting-a-visitor-from-the-dashboard-displays-two-duplicate-invite-views
     *
     * Expample—
     *
     * While `invite a` is inflight...
     *
     * Pubnub return that `invite a` was successfully saved
     * before the server does. Since the inflight `invite a` has no ID
     * ED assumes this is a new record, which causes a near-duplicate invite to be created in the store
     */
    if (payload && payload.data && !payload.included && payload.data.type === 'invite') {
      if (this._isDuplicateInvite(payload)) {
        return;
      }
    }

    if (payload && payload.data && !payload.included && payload.data.type === 'entry') {
      if (this._isDuplicateEntry(payload)) {
        return;
      }
    }

    return super.push(...arguments);
  }

  _isDuplicateInvite(payload) {
    // Fix bugsnag error: `Cannot read property 'isSaving' of null`
    const invitesInFlight = this.peekAll('invite').filter((i) => i && get(i, 'isSaving'));
    const pubNubInvite = payload.data;

    return invitesInFlight.some((invite) => {
      return (
        pubNubInvite.attributes.fullName === get(invite, 'fullName') &&
        pubNubInvite.attributes.expectedArrivalTime.getTime() === get(invite, 'expectedArrivalTime').getTime() &&
        pubNubInvite.relationships.location.data.id === invite.belongsTo('location').id()
      );
    });
  }

  _isDuplicateEntry(payload) {
    // Fix bugsnag error: `Cannot read property 'isSaving' of null`
    const entriesInFlight = this.peekAll('entry').filter((e) => e && get(e, 'isSaving'));
    const pubNubEntry = payload.data;

    return entriesInFlight.some((entry) => {
      return (
        pubNubEntry.attributes.fullName === get(entry, 'fullName') &&
        pubNubEntry.attributes.signedInAt.getTime() === get(entry, 'signedInAt').getTime() &&
        pubNubEntry.relationships.location.data.id === entry.belongsTo('location').id()
      );
    });
  }
}

import Component from '@glimmer/component';
import move from 'ember-animated/motions/move';
import { fadeOut, fadeIn } from 'ember-animated/motions/opacity';

/**
 * @param {Array}               roles
 * @param {User}                user
 * @param {Task}                confirmRoleTask
 * @param {Task}                deleteRoleTask
 * @param {Task}                saveRoleTask
 */
export default class AdminRolesRolesList extends Component {
  get sortedRoles() {
    return this.args.roles.sortBy('isGlobalAdmin', 'isNew', 'createdAt').reverse();
  }

  /* eslint-disable require-yield */
  *transition({ keptSprites, insertedSprites, removedSprites }) {
    keptSprites.forEach(move);
    insertedSprites.forEach(fadeIn);
    removedSprites.forEach(fadeOut);
  }
  /* eslint-enable require-yield */
}

import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type PropertyPrinterModel from 'garaje/models/property-printer';

import type { DevicesPrintersNewRouteModel } from './route';

export default class PropertyDevicesPrintersNewController extends Controller {
  declare model: DevicesPrintersNewRouteModel;

  @service declare router: RouterService;
  @service declare store: Store;

  @action
  queryForBluetoothPrinter(): Promise<PropertyPrinterModel> {
    return this.store.queryRecord('property-printer', {
      filter: {
        connectionType: 'bluetooth',
        property: this.model.property.id,
      },
    });
  }

  @action
  redirectToPrinterList(): void {
    void this.router.refresh('property.devices');
    void this.router.transitionTo('property.devices.printers.index');
  }
}

/* eslint-disable ember/no-computed-properties-in-native-classes */
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import { isPresent, isBlank } from '@ember/utils';
import { Ability } from 'ember-can';
import type LocationSubscriptionModel from 'garaje/models/location-subscription';
import type SubscriptionModel from 'garaje/models/subscription';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

/**
 * Checks if a list of roles contains any of the permitted roles
 *
 * @param collection array to search
 * @param values value to search for in the array
 *
 * @returns Whether or not the collection contains any of the values
 */
function includesAny(collection: string[], ...values: string[]): boolean {
  return isPresent(_intersection(collection, values));
}

export default class ProtectAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;

  @reads('state.vrSubscription') subscription!: StateService['vrSubscription'];
  @reads('currentAdmin.roleNames') roles!: CurrentAdminService['roleNames'];

  /**
   * @returns whether admin can enable/disable the "Capture proof of vaccination" feature
   */
  get canToggleProofOfVaccination(): boolean {
    const { subscription, roles } = this;
    return isPresent(subscription) && includesAny(roles, GLOBAL_ADMIN);
  }

  /**
   * @returns whether admin can enable/disable the "Capture SARS-CoV-2 test results" feature
   */
  get canToggleProofOfCovidNegativeTest(): boolean {
    const { subscription, roles } = this;
    return isPresent(subscription) && includesAny(roles, GLOBAL_ADMIN);
  }

  /**
   * @returns whether admin can view SARS-CoV-2 Document Approval Requirements
   */
  get canViewCovidDocumentRequirements(): boolean {
    const subscription: Partial<LocationSubscriptionModel | SubscriptionModel> = this.subscription ?? {};

    const { isPremiumPlan, isEnterprisePlan } = subscription;

    return !!(isPremiumPlan || isEnterprisePlan);
  }

  /**
   * @returns Admin can visit Protect Employee Registration with a single or multiple flows
   */
  get canVisitScreening(): boolean {
    const { subscription, roles } = this;
    if (!isPresent(subscription)) {
      return false;
    } else {
      return includesAny(roles, GLOBAL_ADMIN, LOCATION_ADMIN);
    }
  }

  /**
   * @returns whether admin can visit Protect Announcements
   */
  get canVisitAnnouncements(): boolean {
    const { subscription, roles } = this;
    return isPresent(subscription) && includesAny(roles, LOCATION_ADMIN, GLOBAL_ADMIN);
  }

  /**
   * Any Location or Global Admin will be able to visit the Capacity Limits page
   * as long as they have a Visitors subscription. If they don't have access to
   * the feature they'll see an upsell instead of being able to use it.
   * @returns whether admin can visit Protect Capacity Management
   */
  get canVisitCapacity(): boolean {
    const { subscription, roles } = this;

    return isPresent(subscription) && includesAny(roles, LOCATION_ADMIN, GLOBAL_ADMIN);
  }

  /**
   * @returns whether admin can visit Protect Settings
   */
  get canVisitSettings(): boolean {
    return this.canVisitCapacity || this.canVisitScreening;
  }

  /**
   * @returns whether user can visit Protect Landing Page
   */
  get canVisitLanding(): boolean {
    const { subscription, roles } = this;
    return isBlank(subscription) && includesAny(roles, GLOBAL_ADMIN, LOCATION_ADMIN);
  }

  /**
   * @returns whether user can visit any protect routes before Garaje Launch
   */
  get canVisitPreWorkplaceLaunch(): boolean {
    return this.canVisitAnnouncements || this.canVisitCapacity || this.canVisitLanding || this.canVisitScreening;
  }

  get canVisitWithWorkplaceLaunchSplit(): boolean {
    return !this.featureFlags.isEnabled('workplace-garaje-launch');
  }

  /**
   * @returns whether user can visit any protect routes
   */
  get canVisit(): boolean {
    return this.canVisitPreWorkplaceLaunch && this.canVisitWithWorkplaceLaunchSplit;
  }
}

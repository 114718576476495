// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { computed, get, set } from '@ember/object';
import { run } from '@ember/runloop';
import $ from 'jquery';
import config from 'garaje/config/environment';

// eslint-disable-next-line ember/no-classic-classes
export default Component.extend({
  tagName: 'img',
  size: 100,
  isNotFound: false,
  defaultImage: '/assets/images/features/selfie.svg',
  altImage: null,

  classNameBindings: Object.freeze(['isNotFound:placeholder']),

  attributeBindings: Object.freeze(['src']),
  src: alias('fallbackImage'),

  fallbackImage: computed('altImage', 'defaultImage', function () {
    return get(this, 'altImage') || get(this, 'defaultImage');
  }),

  // We don't rely on gravatar to return us a default
  // image. We set a default image that will 404
  // and use this handler to replace the broken images.
  // The reason is so that we can tell if we are using
  // the default image, vs gravatar returning to us
  // a cached version of our default image.
  // eslint-disable-next-line ember/no-component-lifecycle-hooks
  didInsertElement() {
    this._super(...arguments);
    if (config.environment === 'test') {
      return;
    }

    $(this.element).on('error', () => {
      run(this, function () {
        if (!this.isDestroyed) {
          set(this, 'isNotFound', true);
        }
      });
    });
  },

  willDestroy() {
    this._super(...arguments);
    $(this.element).off('error');
  },
});

import Route from '@ember/routing/route';
import { isPresent } from '@ember/utils';
import { get, set } from '@ember/object';
import { service } from '@ember/service';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

export default class AdminRolesBulkRoute extends Route {
  @service skinnyLocations;
  @service abilities;
  @service store;
  @service router;

  /**
   * This route can be access with an existing user
   */
  queryParams = {
    user_id: {
      refreshModel: true,
    },
  };

  async beforeModel() {
    if (this.abilities.cannot('manage admin-roles')) {
      this.router.transitionTo('unauthorized');
    }

    await this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
  }

  /**
   * @param {object} user the user
   * @param {number} user.user_id the user's id
   * @returns {{employee: any, user: any, groups: any[], zones: any[]}} - An object containing the employee, user, groups, and zones.
   */
  async model({ user_id }) {
    let groups = [];
    let zones = [];

    if (this.abilities.can('use property admin-role')) {
      zones = this.modelFor('current-zone').zones;
    }

    if (this.abilities.can('view locations-grouping')) {
      // Groups have been already loaded by `skinnyLocations` service.
      groups = this.store.peekAll('group');
    }

    if (isPresent(user_id)) {
      const user = await this.store.findRecord('user', user_id, { reload: true }); // eslint-disable-line camelcase

      let employee = this.store.peekAll('employee').findBy('email', user.email);

      if (!employee) {
        const params = { filter: { email: user.email } };
        const response = await this.store.query('employee', params);
        employee = get(response, 'firstObject');
      }
      return { employee, user, groups, zones };
    }

    return { groups, zones };
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    const { employee, user } = model;
    if (isPresent(employee) && isPresent(user)) {
      set(controller, 'selectedEmployee', employee);
      set(controller, 'employeeUser', user);
      controller.fetchRolesTask.perform();
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.reset();
    }
  }
}

import Evented from '@ember/object/evented';
import { run } from '@ember/runloop';
import Service from '@ember/service';
import { SplitFactory } from '@splitsoftware/splitio';
import type { IBrowserClient, Treatment } from '@splitsoftware/splitio/types/splitio';
import config from 'garaje/config/environment';

export type TrafficType = 'company' | 'user' | 'location';

export default class SplitioService extends Service.extend(Evented) {
  declare _company_sdk: IBrowserClient;
  declare _user_sdk: IBrowserClient;
  declare _location_sdk: IBrowserClient;

  declare _company_previousKey: string;
  declare _user_previousKey: string;
  declare _location_previousKey: string;

  setKey(key: string, trafficType: TrafficType): void {
    const prefixedPreviousKey = `_${trafficType}_previousKey` as const;
    const prefixedSdk = `_${trafficType}_sdk` as const;

    const previousKey = this[prefixedPreviousKey];

    if (previousKey !== key) {
      this[prefixedPreviousKey] = key;
      this.cleanupListeners(trafficType);
      this[prefixedSdk] = this.createSdk(key, trafficType)!;
    }
  }

  createSdk(key: string, trafficType: TrafficType): IBrowserClient | null {
    if (!key) {
      return null;
    }

    const factory = SplitFactory({
      core: {
        authorizationKey: config.splitioApiKey,
        key,
        trafficType,
      },
      storage: {
        type: 'LOCALSTORAGE',
      },
      // SDK startup configuration defaults:
      // - readyTimeout: 10 seconds
      // - requestTimeoutBeforeReady: 5 seconds
      // - retriesOnFailureBeforeReady: 1 retry

      // these features are only picked up for 'offline' split.io clients
      // which is the default in development and test
      features: trafficType === 'user' ? config.userFeatures : config.features,
    });

    const prefixedUpdate = `${trafficType}_update`;
    const prefixedTimeout = `${trafficType}_timeout`;

    const sdk = factory.client();
    // @ts-ignore
    sdk.on(sdk.Event.SDK_READY, () => run(this, 'trigger', prefixedUpdate));
    // @ts-ignore
    sdk.on(sdk.Event.SDK_UPDATE, () => run(this, 'trigger', prefixedUpdate));
    // @ts-ignore
    sdk.on(sdk.Event.SDK_READY_TIMED_OUT, () => run(this, 'trigger', prefixedTimeout));

    return sdk;
  }

  getTreatment(trafficType: TrafficType, ...rest: Parameters<IBrowserClient['getTreatment']>): Treatment {
    const sdk = this.getSdk(trafficType);
    if (sdk) {
      return sdk.getTreatment(...rest);
    } else {
      return 'control';
    }
  }

  isEnabled(trafficType: TrafficType, ...rest: Parameters<IBrowserClient['getTreatment']>): boolean {
    return this.getTreatment(trafficType, ...rest) === 'on';
  }

  cleanupListeners(trafficType: TrafficType): void {
    const sdk = this.getSdk(trafficType);
    if (sdk) {
      void sdk.destroy();
    }
  }

  willDestroy(): void {
    super.willDestroy();
    this.cleanupListeners('company');
    this.cleanupListeners('user');
    this.cleanupListeners('location');
  }

  getSdk(trafficType: TrafficType): IBrowserClient {
    return this[`_${trafficType}_sdk`];
  }

  trigger(...args: Parameters<Evented['trigger']>): void {
    const [name] = args;
    super.trigger(...args);
    if (!name.startsWith('company_') && !name.startsWith('user_') && !name.startsWith('location_')) {
      this.trigger(`company_${name}`, ...args);
      this.trigger(`user_${name}`, ...args);
      this.trigger(`location_${name}`, ...args);
    }
  }
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import fade from 'ember-animated/transitions/fade';
import { task } from 'ember-concurrency';
import type HomepageItemGroupMapping from 'garaje/models/homepage-item-group-mapping';
import type SetupGuideItemModel from 'garaje/models/setup-guide-item';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import zft from 'garaje/utils/zero-for-tests';
import { alias, filterBy } from 'macro-decorators';

interface SetupGuideArgs {
  mapping: HomepageItemGroupMapping;
  logEvent: (event_name: string, event_value?: string, metadata?: object) => void;
  reload: () => void;
}

export default class SetupGuide extends Component<SetupGuideArgs> {
  @service declare state: StateService;
  @service declare flashMessages: FlashMessagesService;

  @alias('state.vrSubscription') declare vrSubscription: StateService['vrSubscription'];
  @alias('state.workplaceSubscription') declare workplaceSubscription: StateService['workplaceSubscription'];

  @filterBy('args.mapping.homepageItem.setupGuideItems', 'showOnHomepage', true)
  declare setupGuideItems: SetupGuideItemModel[];
  @filterBy('setupGuideItems', 'app', 'visitors') declare visitorsSetupGuideItems: SetupGuideItemModel[];
  @filterBy('setupGuideItems', 'app', 'workplace') declare workplaceSetupGuideItems: SetupGuideItemModel[];

  @tracked activeTab: string = 'visitors';

  transition = fade;
  duration = zft(300);

  constructor(owner: unknown, args: SetupGuideArgs) {
    super(owner, args);
    if (isEmpty(this.visitorsSetupGuideItems)) {
      this.activeTab = 'workplace';
    }
  }

  get showTabs(): boolean {
    return !isEmpty(this.visitorsSetupGuideItems) && !isEmpty(this.workplaceSetupGuideItems);
  }

  @action
  logViewed(): void {
    this.args.logEvent('homepage_setup_guide_viewed');
  }

  hideGuidesTask = task({ drop: true }, async () => {
    const homepageItem = this.args.mapping.homepageItem;
    homepageItem.userClosed = true;

    try {
      await homepageItem.save();
      this.args.reload();
    } catch (e) {
      homepageItem.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class CardImageComponent extends Component {
  @tracked imageUrl = null;
  @tracked imageAlt = '';
  @tracked imageClasses = '';

  constructor() {
    super(...arguments);
    this.getImage();
  }

  imageUrlMap = {
    visa: '/assets/images/billing/visa.svg',
    mastercard: '/assets/images/billing/mastercard.svg',
    amex: '/assets/images/billing/amex.svg',
    discover: '/assets/images/billing/discover.svg',
    dinersclub: '/assets/images/billing/dinersclub.svg',
    jcb: '/assets/images/billing/jcb.svg',
  };

  getImage() {
    if (['visa', 'mastercard', 'amex', 'discover', 'dinersclub', 'jcb'].includes(this.args.cardType)) {
      this.imageUrl = this.imageUrlMap[this.args.cardType];
      this.imageAlt = this.args.cardType;
      this.imageClasses = `${this.args.cardType}-icon`;
    } else {
      this.imageUrl = '/assets/images/billing/default-card.svg';
      this.imageAlt = 'default card';
    }
  }
}

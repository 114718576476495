// IE 11 & Edge do not support iFrame srcDoc, write the source HTML
export default function (className, html) {
  if (!('srcdoc' in document.createElement('iframe'))) {
    const [iframe] = document.getElementsByClassName(className);
    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write(html);
    doc.close();
  }
}

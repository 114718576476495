/* eslint-disable ember/no-get */
/* eslint-disable ember/no-computed-properties-in-native-classes */
import type NativeArray from '@ember/array/-private/native-array';
import { get, computed } from '@ember/object';
import { filterBy } from '@ember/object/computed';
import Model, { attr } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';
import ReportFactory from 'garaje/utils/report-factory';

export interface Report {
  blacklistFilterId?: string;
  blacklistFilterSource?: string;
  blacklistFilterSourceId?: string;
  result: string;
  reviewable: boolean;
  source: string;
  status: string;
  messages: {
    failure: {
      text: string;
    };
  };
}

@adapter('v1')
class ApprovalStatusModel extends Model {
  @attr('string') declare status: string;
  @attr('string') declare reviewerName: string;
  @attr('string') declare reviewedAt: string;

  // @ts-ignore - using custom options. Refactor?
  @attr('immutable-array', {
    serializeItem: (item: ApprovalStatusModel) => item.serialize(),
    deserializeItem: (item: ApprovalStatusModel) => ReportFactory.createReport(item),
  })
  declare report: NativeArray<Report>;

  @filterBy('report', 'result', 'fail') failedReport!: Report[];
  @filterBy('report', 'source', 'blacklist') blocklistReport!: Report[];
  @filterBy('report', 'source', 'pre-registration-required') preRegistrationRequiredReport!: Report[];
  @filterBy('report', 'source', 'id-scanning') idScanningReport!: Report[];
  @filterBy('report', 'source', 'visual-compliance') visualComplianceReport!: Report[];
  @filterBy('report', 'source', 'property-invite-approval') propertyInviteApproval!: Report[];
  @filterBy('report', 'source', 'requires-approval') inviteApproval!: Report[];

  @computed('failedReport.@each.source') get didFailBlocklistCheck(): boolean {
    const reports = this.failedReport;
    return (reports || []).some((report) => report.source === 'blacklist');
  }

  @computed('failedReport.@each.source') get didFailPreRegistrationRequiredCheck(): boolean {
    const reports = this.failedReport;
    return (reports || []).some((report) => report.source === 'pre-registration-required');
  }

  @computed('failedReport.@each.source') get didFailIdScanningCheck(): boolean {
    const reports = this.failedReport;
    return (reports || []).some((report) => report.source === 'id-scanning');
  }

  @computed('failedReport.@each.source') get didFailVisualComplianceCheck(): boolean {
    const reports = this.failedReport;
    return (reports || []).some((report) => report.source === 'visual-compliance');
  }

  @computed('failedReport.@each.source') get didFailTempScreenCheck(): boolean {
    const reports = this.failedReport;
    return (reports || []).some((report) => report.source === 'temperature-screening');
  }

  @computed('failedReport.@each.source') get didFailIdVerificationCheck(): boolean {
    const reports = this.failedReport;
    return (reports || []).some((report) => report.source === 'identity-verification');
  }

  @computed('failedReport.@each.source') get tempCheckFailureMessage(): string {
    const report = (this.failedReport || []).find((report) => report.source === 'temperature-screening');
    return report ? <string>get(report, 'messages.failure.text') : '';
  }

  @computed('failedReport.@each.source') get idVerificationFailureMessage(): string {
    const report = (this.failedReport || []).find((report) => report.source === 'identity-verification');
    return report ? <string>get(report, 'messages.failure.text') : '';
  }

  @computed('didFailIdScanningCheck', 'report.@each.{source,result}') get hasPendingIdScan(): boolean {
    const reports = this.report || [];
    const hasFailedState = this.didFailIdScanningCheck;
    const hasPassedState = reports.any((r) => r.source === 'id-scanning' && r.result === 'pass');
    const hasPendingState = reports.any((r) => r.source === 'id-scanning' && r.result === 'pending');
    return !hasFailedState && !hasPassedState && hasPendingState;
  }

  @computed('failedReport.@each.source') get isAtCapacity(): boolean {
    const reports = this.failedReport;
    return (reports || []).some((report) => report.source === 'capacity-screening');
  }

  @computed('failedReport.@each.source') get visitorDocumentsCheck(): Report | undefined {
    return (this.failedReport || []).find((report) => report.source === 'visitor-documents');
  }
}

export default ApprovalStatusModel;

import leaflet from 'leaflet';
import animate from 'garaje/utils/animate';

/**
 * @param {Function} timing   Accepts a timing function
 *
 * @returns {Function}        Returns a transformed variant
 */
const makeEaseOut = (timing) => (timeFraction) => {
  return 1 - timing(1 - timeFraction);
};

/**
 * @returns {Void} Initializes custom extensions. To be invoked after importing leaflet
 * 
 * Usage:
 *   
    import leaflet from 'leaflet';
    import setupMarkerExtensions from 'garaje/utils/leaflet-marker-extensions';

    setupMarkerExtensions();
 */
export default function () {
  leaflet.Marker.include({
    getTransformedPosition: function () {
      const icon = this.getElement();
      const matrix = new DOMMatrixReadOnly(icon.style.transform);
      const { e: transformX, f: transformY } = matrix;

      return { transformX, transformY };
    },

    /**
     * Shakes a marker from side to side
     */
    shake() {
      const icon = this.getElement();
      const { transformX, transformY } = this.getTransformedPosition();

      function elastic(x, timeFraction) {
        return Math.pow(2, 10 * (timeFraction - 1)) * Math.cos(((20 * Math.PI * x) / 3) * timeFraction);
      }

      animate({
        duration: 1000,
        timing: makeEaseOut(elastic.bind(6, 1.5)),
        draw: (progress) => {
          icon.style.transform = `translate(${transformX - (1 - progress) * 10}px, ${transformY}px)`;
        },
      });
    },

    /**
     * Bounces a marker up and down
     */
    bounce() {
      const icon = this.getElement();
      const { transformX, transformY } = this.getTransformedPosition();

      function bounce(timeFraction) {
        for (let a = 0, b = 1; 1; a += b, b /= 2) {
          if (timeFraction >= (7 - 4 * a) / 11) {
            return -Math.pow((11 - 6 * a - 11 * timeFraction) / 4, 2) + Math.pow(b, 2);
          }
        }
      }

      animate({
        duration: 1000,
        timing: makeEaseOut(bounce),
        draw: (progress) => {
          icon.style.transform = `translate(${transformX}px, ${transformY - (1 - progress) * 10}px)`;
        },
      });
    },
  });
}

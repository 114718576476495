import Component from '@glimmer/component';

/**
 * Multiple select row.
 * @param {Object}                               option
 * @param {Array<Location|SkinnyLocation>}       selectedLocations  Current selected option ids `1,2,3`.
 * @param {Function}                             onGroupSelected
 * @param {Function}                             onSelected         Function triggered when an option is selected
 */
export default class LocationGroupRow extends Component {
  get checkedState() {
    const groupLocations = this.args.option.data.locations.mapBy('id');
    const selectedLocations = this.args.selectedLocations.mapBy('id');

    if (groupLocations.every((l) => selectedLocations.includes(l))) {
      return 'checked';
    } else if (groupLocations.some((l) => selectedLocations.includes(l))) {
      return 'indeterminate';
    }

    return null;
  }

  get groupName() {
    return `${this.args.option?.name} (group)`;
  }
}

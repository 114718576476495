/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Dropdown, DropdownOptionType } from '@envoy/polarwind-react';
import { ComponentProps, useEffect, useState } from 'react';
import { FieldValues, Path, useController } from 'react-hook-form';

export type FormDropdownProps<T extends FieldValues> = {
  name: Path<T>;
  required?: string;
  inputMode?: 'string' | 'object';
} & Omit<ComponentProps<typeof Dropdown>, 'required'>;

export function FormDropdown<T extends FieldValues>({
  name,
  required,
  inputMode = 'string',
  options,
  ...rest
}: FormDropdownProps<T>) {
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules: { required } });
  const [value, setValue] = useState<ComponentProps<typeof Dropdown>['value']>();

  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  const handleChange = (changedValue?: DropdownOptionType | DropdownOptionType[]) => {
    if (!changedValue) {
      return field.onChange(null);
    }

    if (inputMode === 'object') {
      return field.onChange(changedValue);
    }

    if (Array.isArray(changedValue)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return field.onChange(changedValue.map((v) => v.value));
    }

    return field.onChange(changedValue.value);
  };

  const formatValue = (changedValue: string | object) => {
    if (typeof changedValue === 'object') {
      return changedValue;
    }

    return options.find((o) => (o as DropdownOptionType).value === changedValue);
  };

  useEffect(() => {
    if (Array.isArray(field.value)) {
      return setValue((field.value as DropdownOptionType[]).map((v: string | object) => formatValue(v)));
    }

    setValue(formatValue(field.value));
  }, [field.value, options]);

  return (
    <Dropdown
      {...rest}
      options={options}
      required={!!required}
      onChange={(newValue) => handleChange(newValue as DropdownOptionType[])}
      value={value}
      error={error?.message}
    />
  );
}

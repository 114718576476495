import Select from '../component';
import { action } from '@ember/object';

/**
 * @param {Object}                              field
 * @param {Object}                              output
 * @param {Object}                              install
 * @param {Object}                              plugin
 * @param {Service<CurrentLocation>}            currentLocation
 * @param {Function}                            updateValue
 */
export default class MultiSelect extends Select {
  get placeholder() {
    return this.loading ? 'Loading...' : 'Select one or more';
  }

  @action
  onChange(selected) {
    const selectedArray = Array.isArray(selected) ? selected : [];
    this.selected = selectedArray ?? [];
    this.args.updateValue(selectedArray.map(({ value }) => value));
  }

  @action
  onLoaded(options) {
    const initialValue = Array.isArray(this.args.value) ? this.args.value : [];
    const selected = options.filter(({ value }) => initialValue.includes(value));
    if (selected.length === initialValue.length) {
      this.loading = false;
      return this.onChange(selected);
    }
    this.setInitialSelection.perform();
  }
}

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

/**
 * @param {Boolean} [showUpgradeButton=true] - whether the Upgrade button should be shown (note that the Upgrade button will not be shown to users who are not self-service or who cannot visit the billing page, regardless of this value)
 * @param {Function} dismiss - action to trigger when banner is dismissed
 * @param {String} variation - color to trigger different color banner
 * @param {String} ctaId - value to use for "cta_id" when logging events
 * @param {String} header - header text (also included in logged events)
 * @param {String} body - body text (also included in logged events)
 * @param {String} ctaText - primary action button label (also included in logged events)
 * @param {Function} ctaAction - primary action to trigger when action button is clicked
 * @param {Boolean} actionDisabled - is primary action disabled
 * @param {String|Component} [learnMoreModal] - component (or component name) to be rendered if user clicks the "Learn more" button. Should be a modal.
 * @param {String} [learnMoreURL] - URL that is the target for the "Learn more" button (if @learnMoreModal was not passed)
 */
export default class GrowthUpsellBanner extends Component {
  @service state;
  @service abilities;

  @tracked dismissed = false;
  @tracked showLearnMoreModal = false;

  get showUpgradeButton() {
    if (this.args.showUpgradeButton === false) return false;
    return this.abilities.can('visit billing') && !this.state.billingCompany.blockSelfServe;
  }

  get colorVariant() {
    if (this.args.variation === 'purple') return 'purple';
    if (this.args.variation === 'blue') return 'blue';
    return 'white';
  }

  get backgroundClass() {
    if (this.colorVariant === 'purple') return 'bg-purple-600';
    if (this.colorVariant === 'blue') return 'bg-blue-100';
    return 'bg-white';
  }

  get bodyTextClass() {
    if (this.colorVariant === 'purple') return 'text-white';
    if (this.colorVariant === 'blue') return 'text-black';
    return '';
  }

  get buttonClass() {
    if (this.colorVariant === 'purple') return 'btn--white';
    if (this.colorVariant === 'blue') return 'btn--white';
    return '';
  }

  @action
  dismiss(logDismissalAction) {
    this.dismissed = true;
    if (this.args.dismiss) this.args.dismiss();
    logDismissalAction();
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { type GetRoomQuery } from 'garaje/graphql/generated/roomba-types';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import type { ModelFrom } from 'garaje/utils/type-utils';

import type RoombaRoomController from './controller';

interface RoombaRoomRouteParams {
  room_id: string;
}

export default class RoombaRoomRoute extends Route<{ room?: GetRoomQuery['room'] }, RoombaRoomRouteParams> {
  @service declare roombaGraphql: RoombaGraphqlService;
  @service declare router: RouterService;

  async model(params: RoombaRoomRouteParams): Promise<{ room?: GetRoomQuery['room'] }> {
    const { room_id } = params;

    let room;
    try {
      room = await this.roombaGraphql.getRoom(room_id);
    } catch (e) {
      // todo: this works with mirage. once we connect to the real api we may have a well structured error object
      // for now, if there is an error, it'll be handled in the afterModel

      // eslint-disable-next-line no-console
      console.error('error fetching room: ', e);
    }

    return {
      room,
    };
  }

  afterModel({ room }: { room?: GetRoomQuery['room'] }, err: object): void {
    if (!room) {
      void this.router.transitionTo('/not-found', err);
    }
  }

  setupController(controller: RoombaRoomController, model: ModelFrom<this>, transition: Transition): void {
    super.setupController(controller, model, transition);
    if (!model.room) {
      return;
    }
    controller.room = model.room;
  }
}

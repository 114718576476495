import gql from 'graphql-tag';

export default gql`
  query GetAssignedAndUnassignedEmployees(
    $employeeInput: GetAssignedAndUnassignedEmployeesInput!
    $spaceInput: SpaceDashboardInput!
  ) {
    getAssignedAndUnassignedEmployees(input: $employeeInput) {
      assignedEmployees {
        id
        name
        email
        department
        assignedDesks {
          id
          name
          mapFeature {
            floor {
              id
              name
            }
          }
        }
        attendancePerformance(input: $spaceInput) {
          averageWeeklyAttendance
          onsiteMission {
            department
            mission
            period
          }
          insights {
            meetsAttendancePolicy
          }
        }
      }
      unassignedEmployees {
        id
        name
        email
        department
        attendancePerformance(input: $spaceInput) {
          averageWeeklyAttendance
          onsiteMission {
            department
            mission
            period
          }
          insights {
            meetsAttendancePolicy
          }
        }
      }
      meta {
        employeesPopulatedByScim
      }
    }
  }
`;

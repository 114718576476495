import Route from '@ember/routing/route';
import { hash } from 'rsvp';

export default class VisitorsSettingsVisitorTypesFlowLegalDocumentsIndexRoute extends Route {
  model() {
    const { agreements, agreementPage, flow, vrSubscription } = this.modelFor(
      'visitors.settings.visitor-types.flow.legal-documents',
    );
    const { ipads } = this.modelFor('visitors.settings');
    const { tracking } = this.modelFor('visitors.settings.visitor-types.flow.legal-documents');

    /** Sometimes when we transition from the legal-documents.edit route
     * after deleting a record via the transition-confirm dialog, the transition occurs
     * before the agreement deletion has persisted for some reason. We filter out
     * any agreements here that may have been marked for deletion but not yet persisted
     * at the time that we enter this route.
     */
    return hash({
      agreements: agreements.filterBy('isDeleted', false).sortBy('position'),
      agreementPage,
      flow,
      ipads,
      tracking,
      vrSubscription,
    });
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.setupTrackingConfigurations.perform();
  }
}

import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type AbilitiesService from 'ember-can/services/abilities';
import { isProductDisabled } from 'garaje/helpers/product-disabled';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, ZONE_ADMIN } from 'garaje/utils/roles';
import { Permission } from 'garaje/utils/ui-permissions';
import _intersection from 'lodash/intersection';

const CAN_VISIT_CONNECT_ROLES = [GLOBAL_ADMIN, ZONE_ADMIN];
const CAN_VISIT_PROTECT_ROLES = [GLOBAL_ADMIN, ZONE_ADMIN, LOCATION_ADMIN, RECEPTIONIST];

export default class AnalyticAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;
  @service declare featureConfig: FeatureConfigService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare authz: AuthzService;
  @service declare abilities: AbilitiesService;

  get canVisitAnalytics(): boolean {
    return (
      this.canVisitVisitors ||
      this.canVisitProtect ||
      this.canVisitOccupancy ||
      this.canVisitAttendance ||
      this.canVisitDeliveries ||
      (this.canVisitDesks && this.featureConfig.isEnabled('desks.analytics')) ||
      this.canVisitRooms ||
      this.abilities.can('visit occupancy-location-report')
    );
  }

  get canVisitVisitors(): boolean {
    const canAccessAnalytics = !!this.state.vrSubscription?.canAccessAnalytics;

    return (
      canAccessAnalytics && this.authz.hasPermissionAtCurrentLocation(Permission.ANALYTICS_LOCAL_VISITORS_DASH_READ)
    );
  }

  get canVisitProtect(): boolean {
    const { roleNames } = this.currentAdmin;
    const canAccessAnalytics =
      !!this.state.features?.canAccessProtectLegacyFeatures && !this.state.features?.canAccessOccupancyAnalytics;

    const unfilteredZoneRoles = this.state.currentUser?.zoneRoles.mapBy('roleName');
    const hasValidRole =
      isPresent(_intersection(CAN_VISIT_PROTECT_ROLES, roleNames)) ||
      isPresent(_intersection(CAN_VISIT_PROTECT_ROLES, unfilteredZoneRoles));

    return canAccessAnalytics && hasValidRole;
  }

  get canVisitConnect(): boolean {
    if (isProductDisabled('connect')) {
      return false;
    }

    const { roleNames } = this.currentAdmin;

    const unfilteredZoneRoles = this.state.currentUser?.zoneRoles.mapBy('roleName');
    const hasValidRole =
      isPresent(_intersection(CAN_VISIT_CONNECT_ROLES, roleNames)) ||
      isPresent(_intersection(CAN_VISIT_CONNECT_ROLES, unfilteredZoneRoles));

    return hasValidRole;
  }

  get canVisitOccupancy(): boolean {
    if (isProductDisabled('occupancy')) {
      return false;
    }

    const canAccessOccupancyAnalytics =
      !!this.state.features?.canAccessOccupancyAnalytics || this.featureFlags.isEnabled('occupancy-analytics');

    return (
      canAccessOccupancyAnalytics &&
      this.authz.hasPermissionAtCurrentLocation(Permission.ANALYTICS_LOCAL_OCCUPANCY_DASH_READ)
    );
  }

  get canVisitDeliveries(): boolean {
    if (isProductDisabled('deliveries')) {
      return false;
    }

    const canAccessAnalytics = !!this.state.features?.canAccessDeliveriesAnalytics;

    return (
      canAccessAnalytics && this.authz.hasPermissionAtCurrentLocation(Permission.ANALYTICS_LOCAL_DELIVERIES_DASH_READ)
    );
  }

  get canVisitDesks(): boolean {
    if (isProductDisabled('desks')) {
      return false;
    }

    const canAccessAnalytics = !!this.state.features?.canAccessDesksAnalytics;

    return canAccessAnalytics && this.authz.hasPermissionAtCurrentLocation(Permission.ANALYTICS_LOCAL_DESKS_DASH_READ);
  }

  get canVisitRooms(): boolean {
    // cannot visit if user has no workplace sub or legacy rooms sub
    if (isProductDisabled('rooms')) {
      return false;
    }

    return (
      !!this.state.features?.canAccessRoomsAnalytics &&
      this.authz.hasPermissionAtCurrentLocation(Permission.ANALYTICS_LOCAL_ROOMS_DASH_READ)
    );
  }

  get canVisitAttendance(): boolean {
    if (isProductDisabled('attendance')) {
      return false;
    }

    const canAccessAnalytics = !!this.state.features?.canAccessAttendanceAnalytics;

    return canAccessAnalytics && this.authz.hasPermissionAtCurrentLocation(Permission.ANALYTICS_OFFICE_ATTENDANCE_READ);
  }
}

import ApplicationSerializer from './application';
import { service } from '@ember/service';

export default class DeskSerializer extends ApplicationSerializer {
  @service store;
  @service state;
  @service featureFlags;

  attrs = {
    assignmentDetails: 'assignments',
  };

  normalize(typeClass, hash) {
    if (this.featureFlags.isEnabled('bulk-map-operations')) {
      const { relationships } = hash;
      let assignments = [];
      if (relationships.assignments?.data) {
        assignments = relationships.assignments.data;
      }
      const normalized = super.normalize(...arguments);
      // this block is needed because the key gets changed from assignments to assignment-details
      if (assignments) {
        normalized.data.relationships.assignments = {
          data: assignments.map((assignment) => {
            return {
              id: assignment.id,
              type: 'assignment',
            };
          }),
        };
      }
      return normalized;
    } else {
      return super.normalize(...arguments);
    }
  }

  serialize(snapshot) {
    const json = super.serialize(...arguments);

    if (snapshot.record.floorId) {
      json.data.relationships = {
        ...json.data.relationships,
        floor: {
          data: {
            id: snapshot.record.floorId,
            type: 'floors',
          },
        },
      };
    }

    const neighborhoodId = json?.data?.relationships?.neighborhood?.data?.id;

    if (neighborhoodId) {
      json.data.attributes['neighborhood-id'] = neighborhoodId;
    } else {
      json.data.attributes['neighborhood-id'] = null;
    }

    // if attributes.assignments is null, the API won't remove assignments
    // if attributes.assignments is an empty list, the API will remove assignments
    if (snapshot._attributes.assignmentDetails == null) {
      json.data.attributes.assignments = null;
    }
    return json;
  }

  normalizeFindHasManyResponse(store, model, payload) {
    delete payload.errors;
    return super.normalizeFindHasManyResponse(...arguments);
  }

  normalizeQueryResponse(store, model, payload) {
    delete payload.errors;
    return super.normalizeFindHasManyResponse(...arguments);
  }
}

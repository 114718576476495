import { action } from '@ember/object';
import Component from '@glimmer/component';
import type { DetailedChangeset } from 'ember-changeset/types';
import type { Task } from 'ember-concurrency';

import type { SupportedAgreements } from '../component';
import type { SaveOptions } from '../edit-text/component';

interface SkipSigningComponentSignature {
  Args: {
    changeset: DetailedChangeset<SupportedAgreements>;
    disabled: boolean;
    model: SupportedAgreements;
    propagable: boolean;
    editingEmail: boolean;
    trackLegalDocument: () => Promise<void>;
    updateAndSaveTask: Task<void, [SaveOptions<keyof SupportedAgreements>]>;
    updateEditingEmail: (value: boolean) => void;
  };
}

export default class SkipSigningComponent extends Component<SkipSigningComponentSignature> {
  @action
  toggleOptional(value: boolean): void {
    void this.args.updateAndSaveTask
      .perform({ model: this.args.model, field: 'optional', value, propagable: this.args.propagable })
      .then(() => this.args.trackLegalDocument());
  }
}

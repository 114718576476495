import Model, { attr } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('billing-v1')
class HostedPageModel extends Model {
  @attr('string') declare url: string;
  @attr('string') declare companyId: string;
}

export default HostedPageModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'hosted-page': HostedPageModel;
  }
}

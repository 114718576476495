/* eslint-disable no-console */
import Service, { inject as service } from '@ember/service';
import withCartFunctionality from './location-billing/cart';
import withStateFunctionality from './location-billing/state';
import withManagerFunctionality from './location-billing/manager';
import withDowngradeFunctionality from './location-billing/downgrade';
import withSubscriptionBatchPolling from './location-billing/subscription-batch-polling';
import { tracked } from '@glimmer/tracking';
import { allSettled } from 'rsvp';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { filterBy, notEmpty, or } from '@ember/object/computed';

@withStateFunctionality
@withCartFunctionality
@withManagerFunctionality
@withDowngradeFunctionality
@withSubscriptionBatchPolling
class LocationBillingService extends Service {
  @service store;
  @service state;
  @service session;
  @service flashMessages;
  @service('locations') locationService;
  @service('billing') paymentSourceService;

  @tracked allPlans = [];
  @tracked subscribed = false;
  @tracked hasPaidSubscription = false;
  @tracked billingCompany = null;
  @tracked company = null;
  @tracked locationsUnsorted = [];
  @tracked paymentSource = null;
  @tracked allLocationSubscriptions = [];
  @tracked initialLocationSubscriptionEstimates = [];

  @filterBy('allLocationSubscriptions', 'isEnterprisePlan', true) enterpriseLocationSubscriptions;
  @notEmpty('enterpriseLocationSubscriptions') isEnterpriseAtAnyLocation;
  @or('isEnterpriseAtAnyLocation', 'billingCompany.blockSelfServe') blockSelfServe;

  async initService() {
    const companyId = this.session.companyId;
    const company = this.store.peekRecord('company', companyId);

    await allSettled([
      this.store.findRecord('billing-company', companyId),
      this.locationService.loadAllTask.perform(),
      this.store.findAll('subscription-plan'),
      this.store.query('location-subscription', { filter: { company_id: company.id } }),
      this.paymentSourceService.loadPaymentSources.perform(),
    ]);

    this.allPlans = this.store.peekAll('subscription-plan');
    this.paymentSource = this.paymentSourceService.paymentSource;
    this.locationsUnsorted = this.locationService.persisted;
    this.billingCompany = this.store.peekRecord('billing-company', companyId);
    this.company = company;

    const locationSubscriptions = this.store.peekAll('location-subscription');

    if (locationSubscriptions.length) {
      this.allLocationSubscriptions.pushObjects(locationSubscriptions.toArray());
      this.hasPaidSubscription = !!locationSubscriptions.find((ls) => ls.isPaidSubscription);
      this.subscribed = this.hasPaidSubscription;
    }

    await allSettled([
      this.state.activeLocationsCount.perform(),
      this.state.locationsWithMultipleVisitorTypesCount.perform(),
      this.state.activeRoomsCount.perform(),
      this.state.activeRoomsPerLocation.perform(),
      this.state.activeDeliveryAreasCount.perform(),
      this.state.activeDeliveryAreasPerLocation.perform(),
    ]);
    // Comment out createCurrentLocationSubscriptionEstimates since it is not necessary for read-only billing page.
    // We may need it in the future.
    //    await this.createCurrentLocationSubscriptionEstimates.perform();
  }

  async reset() {
    await this.initService();
    this.billingCart = this._defaultBillingCart;
  }
}

export default LocationBillingService;

import { service } from '@ember/service';
import Component from '@glimmer/component';
import type StateService from 'garaje/services/state';
import type UserFeatureFlagsService from 'garaje/services/user-feature-flags';

interface AnalyticsMenuArgs {
  trackClickEvent: (trackingString: string) => void;
}

export default class AnalyticsMenuComponent extends Component<AnalyticsMenuArgs> {
  @service declare state: StateService;
  @service declare userFeatureFlags: UserFeatureFlagsService;
}

import { action } from '@ember/object';
import { schedule } from '@ember/runloop';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import type { SafeString } from '@ember/template/-private/handlebars';
import { buildWaiter } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { TaskInstance } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import config from 'garaje/config/environment';
import type ActiveStorageService from 'garaje/services/active-storage-extension';
import { TrackedObject } from 'tracked-built-ins';
import { localCopy } from 'tracked-toolbox';

export interface FileUpload {
  file?: File;
  upload: () => TaskInstance<string | undefined>;
  progress?: number;
  readerResult?: FileReader['result'];
  reset: () => void;
  isValid?: boolean;
  uploaded: boolean;
}

export interface ValidationResult {
  sizeValid: boolean;
  typeValid: boolean;
}

interface DirectUploaderComponentSignature {
  Args: {
    accept?: string;
    autoUpload?: boolean;
    currentUpload?: Partial<FileUpload>;
    directUploadURL?: string;
    disabled?: boolean;
    enqueue?: boolean;
    invalidBody?: string;
    labelRetry?: string;
    invalidTitle?: string;
    label?: string;
    subLabel?: string;
    onError?: (error: unknown) => void;
    onFileSelected?: (file: File | null) => void;
    onFilePreview?: (file: FileReader['result']) => void;
    onPendingUpload?: (upload?: FileUpload) => void;
    onPreviewClick?: () => void;
    onReset?: () => void;
    onUpload?: (task: TaskInstance<string | undefined>) => void;
    onUploadComplete?: (signedId: string) => void;
    onValidate?: (result: ValidationResult) => boolean;
    prefix?: string;
    showResetButton?: boolean;
    showErrorState?: boolean;
    uploadProgress?: number;
    /**
     * The maximum size of the file in bytes.
     */
    validSize?: number;
    validType?: string | RegExp;
  };
}

const isTesting = config.environment === 'test';
const testWaiter = buildWaiter('direct-uploader-component-waiter');

export default class DirectUploaderComponent extends Component<DirectUploaderComponentSignature> {
  @service declare activeStorageExtension: ActiveStorageService;

  @localCopy('args.directUploadURL', '/a/visitors/api/direct-uploads') directUploadURL!: string;
  @localCopy('args.prefix', '') prefix!: string;
  @localCopy('args.currentUpload') currentUpload?: FileUpload;
  @localCopy('args.enqueue', true) enqueue!: boolean;
  @localCopy('args.showResetButton', true) showResetButton!: boolean;
  @localCopy('args.showErrorState', true) showErrorState!: boolean;

  fileInputElement?: HTMLInputElement;

  /**
   * Used to perform operations on the file input element when it disabled
   */
  @tracked forceEnable = false;

  get isImage(): boolean {
    return Boolean(this.args.accept?.includes('image'));
  }

  /**
   * disables the file input element
   */
  get disabled(): boolean {
    return (this.args.disabled || this.uploadTask.isRunning) && !this.forceEnable;
  }

  get shouldShowResetButton(): boolean {
    return Boolean(this.showResetButton && this.currentUpload && this.currentUpload.isValid);
  }

  get label(): string {
    if (this.args.label) return this.args.label;
    return `Drag and drop a file${this.formattedSize ? ' (max size of ' + this.formattedSize + ')' : ''}`;
  }

  get subLabel(): string {
    return this.args.subLabel ?? 'or select a file from your computer';
  }

  get progress(): number {
    return this.args.uploadProgress ?? this.currentUpload?.progress ?? 0;
  }

  get progressStyle(): SafeString {
    return htmlSafe(`width: ${this.progress}%; transition: width 200ms;`);
  }

  // "Reverse" progress bar gets narrower as upload proceeds
  get progressStyleImage(): SafeString {
    const remaining = 100 - this.progress;
    return htmlSafe(`width: ${remaining}%; transition: width 200ms;`);
  }

  get isInvalid(): boolean {
    return this.currentUpload?.isValid === false;
  }

  get retryText(): string {
    return this.args.labelRetry ?? this.args.label ?? 'Use another file';
  }

  get formattedSize(): string | undefined {
    if (!this.args.validSize) return;
    return `${this.args.validSize / 1000000} mb`;
  }

  get validTypeReg(): RegExp | undefined {
    if (!this.args.validType) return;
    if (this.args.validType instanceof RegExp) {
      return this.args.validType;
    }
    return new RegExp(this.args.validType);
  }

  @action
  fileSelected(files: FileList): void {
    this.forceEnable = false;

    if (!files?.length) return;

    const { autoUpload, onFileSelected, onFilePreview } = this.args;

    const file = files.item(0)!;
    const reader = new FileReader();

    const currentUpload: FileUpload = new TrackedObject({
      file,
      upload: () => this.upload(file, currentUpload),
      reset: () => this.reset(),
      isValid: this.validate(file),
      uploaded: false,
    });

    this.currentUpload = currentUpload;

    // do not proceed if file is invalid but keep context to inform user
    if (!currentUpload.isValid) return;

    const token = isTesting ? testWaiter.beginAsync() : false;

    reader.onload = () => {
      const { result } = reader;

      currentUpload.readerResult = result;

      onFilePreview?.(result);

      if (token) testWaiter.endAsync(token);
    };
    reader.readAsDataURL(file);

    onFileSelected?.(file);
    // reset input value now that file has been consumed to allow the same file to be selected if the upload is ever cleared
    if (this.fileInputElement) this.fileInputElement.value = '';

    if (autoUpload) {
      void this.upload(file, currentUpload);
    } else {
      // upload is deferred and handler by consuming context
      this.args.onPendingUpload?.(currentUpload);
    }
  }

  upload(file: File, uploadContext: FileUpload): TaskInstance<string | undefined> {
    const { directUploadURL, prefix } = this;

    // ember-active-storage addon does not support extra params in the POST payload.
    // But API currently supports sending the prefix as a query param.
    const endpoint = prefix ? `${directUploadURL}?prefix=${prefix}` : directUploadURL;
    const task = this.uploadTask.perform(file, endpoint, uploadContext);

    this.args.onUpload?.(task);
    return task;
  }

  uploadTask = task({ enqueue: this.enqueue }, async (file: File, endpoint: string, uploadContext: FileUpload) => {
    if (!file) {
      throw new Error('Upload halted: no file specified');
    }

    if (!endpoint) {
      throw new Error('Upload halted: no direct upload endpoint specified');
    }

    if (typeof this.activeStorageExtension.upload !== 'function') {
      throw new Error('Upload halted: invalid Active Storage service specified');
    }

    try {
      const { signedId } = await this.activeStorageExtension.upload(file, endpoint, {
        onProgress: (progress) => {
          uploadContext.progress = progress;
        },
      });

      uploadContext.uploaded = true;
      this.args.onUploadComplete?.(signedId);

      return signedId;
    } catch (error) {
      this.args.onError?.(error);
      return;
    }
  });

  @action
  reset(): void {
    this.currentUpload = undefined;

    this.args.onFileSelected?.(null);
    this.args.onFilePreview?.(null);
    this.args.onPendingUpload?.(undefined);
    this.args.onReset?.();
  }

  @action
  forceUpload(): void {
    this.forceEnable = true;
    schedule('afterRender', () => this.fileInputElement?.click());
  }

  @action
  onPreviewClick(): void {
    this.args.onPreviewClick?.();
  }

  validate(file: File): boolean {
    if (!file) return false;

    const typeValid = this.validTypeReg ? this.validTypeReg.test(file.type) : true;
    const sizeValid = this.args.validSize ? file.size <= this.args.validSize : true;

    this.args.onValidate?.({ typeValid, sizeValid });

    return typeValid && sizeValid;
  }
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { keepLatestTask } from 'ember-concurrency';
import { type PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type Invite from 'garaje/models/invite';
import { TrackedArray } from 'tracked-built-ins';

interface VisitorsApprovalsTableDataArgs {
  filters: { [key: string]: string };
  sortBy: string;
  sortDirection: 'asc' | 'desc';
}

const PAGE_SIZE = 20;

// Map exposed sort param names to the ones the API uses. This allows us to check that a user-specified
// param is valid before passing it to the API (which could return with an HTTP 400 error and break the page).
const SORT_FIELDS: { [key: string]: string } = {
  dueAt: 'expected_arrival_time',
  name: 'full_name',
  type: 'flows.name',
};
const DEFAULT_SORT = 'expected_arrival_time';

export default class VisitorsApprovalsTableData extends Component<VisitorsApprovalsTableDataArgs> {
  @service declare store: Store;

  @tracked invites: TrackedArray<Invite> = new TrackedArray();

  #currentOffset = 0;
  #totalInvites = 0;

  constructor(owner: unknown, args: VisitorsApprovalsTableDataArgs) {
    super(owner, args);

    void this.loadInvites.perform(false);
  }

  get sort(): string {
    const sortField = SORT_FIELDS[this.args.sortBy] || DEFAULT_SORT;
    return this.args.sortDirection === 'desc' ? `-${sortField}` : sortField;
  }

  loadInvites = keepLatestTask(async (reset: boolean) => {
    if (reset) {
      this.invites = new TrackedArray();
      this.#currentOffset = 0;
      this.#totalInvites = 0;
    }
    const filters = {
      'approval-status': 'review',
      ...this.args.filters,
    };
    const invites = <PaginatedRecordArray<Invite>>await this.store.query('invite', {
      filter: filters,
      page: {
        limit: PAGE_SIZE,
        offset: this.#currentOffset,
      },
      sort: this.sort,
    });
    const invitesArray = invites.toArray();
    this.#currentOffset += invitesArray.length;
    this.#totalInvites = invites.meta.total;

    this.invites.push(...invitesArray);
  });

  get hasMorePages(): boolean {
    return this.invites.length < this.#totalInvites;
  }

  @action
  reloadData(): void {
    void this.loadInvites.perform(true);
  }

  @action
  removeInvites(invitesToRemove: Invite[]): void {
    this.invites = new TrackedArray(this.invites.filter((invite) => !invitesToRemove.includes(invite)));
    this.#totalInvites -= invitesToRemove.length;
  }

  @action
  loadMore(): void {
    if (this.hasMorePages) {
      void this.loadInvites.perform(false);
    }
  }
}

import Model, { attr } from '@ember-data/model';

class CopyFlowModel extends Model {
  @attr('string') declare companyName: string;
  @attr('string') declare locationName: string;
  @attr('string') declare name: string;
}

export default CopyFlowModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'copy-flow': CopyFlowModel;
  }
}

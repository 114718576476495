import Model, { type AsyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';

import type ConnectInviteModel from './connect-invite';
import type TenantModel from './tenant';

/**
 * Only used for the bulk CREATE of invites.
 */
export default class BulkConnectInviteModel extends Model {
  @attr('string') declare groupName: string | null;
  @attr('string') declare propertyNotes: string;
  @attr('array', { defaultValue: () => [] }) declare fullNames: string[];
  @attr('date', { defaultValue: () => new Date() }) declare checkedInAt: Date;
  @attr('date', { defaultValue: () => new Date() }) declare expectedArrivalTime: Date;

  @belongsTo('tenant', { async: false })
  declare tenant: TenantModel;

  @hasMany('connect-invite')
  declare connectInvites: AsyncHasMany<ConnectInviteModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'bulk-connect-invite': BulkConnectInviteModel;
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type PluginModel from 'garaje/models/plugin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';

export type LocationOverviewVirtualFrontDeskModel = { plugins: PluginModel[] };

export default class LocationOverviewVirtualFrontDeskRoute extends Route {
  title = 'Virtual Front Desk';

  @service declare abilities: AbilitiesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: Store;

  beforeModel(): void {
    if (this.abilities.cannot('visit global pages for virtual-front-desk')) {
      void this.router.transitionTo('/unauthorized');
    } else if (!this.state.features?.canAccessVirtualFrontDesk) {
      void this.router.transitionTo('location-overview.virtual-front-desk.landing-page');
    }
  }

  async model(): Promise<LocationOverviewVirtualFrontDeskModel> {
    const plugins = await this.store.query('plugin', {
      filter: {
        notification_channels: true,
      },
      page: {
        limit: 100,
      },
    });

    return { plugins: plugins.toArray() };
  }
}

/* eslint-disable ember/classic-decorator-hooks */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action, computed, get } from '@ember/object';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { isBlank, isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

/**
 * Wrapper for product boxes
 *
 * @param {string} productName "Rooms"" | "Visitors" | "Deliveries"
 * @param {string} productRoute
 * @param {boolean} loading Loading property to show or hide the skeleton
 * @param {object} subscription Subscription object
 *
 */
export default class DashboardProductBoxBaseComponent extends Component {
  @service('setupGuideSteps') setupGuideStepsService;
  @service metrics;
  @service router;
  @service visitorsOnboarding;
  @service featureFlags;
  @service growthFeatureSetup;

  @tracked cardStepsData = null;
  visitorsStepsData = [
    {
      feature_name: 'visitor_invited',
      text: 'Invite a visitor.',
      route: 'visitors.invites.new',
    },
    {
      feature_name: 'employee_created',
      text: 'Add more employees to host.',
      route: 'employees.directory.add-employee.new',
    },
    { feature_name: 'ipad_paired', text: 'Sync an Ipad.', route: 'visitors.devices.ipads' },
  ];

  init() {
    super.init(...arguments);
    if (isBlank(this.subscription)) {
      return;
    }
    if (this.productName !== 'Visitors') {
      this.setupGuideStepsService.loadStepsTask.perform();
    } else {
      this.visitorsOnboarding.loadSteps();
      if (this.featureFlags.isEnabled('dynamicDashboardCard')) {
        this.getCardData.perform();
      }
    }
  }

  /* eslint-disable require-yield */
  *transition({ removedSprites, insertedSprites }) {
    insertedSprites.forEach(fadeIn);
    removedSprites.forEach(fadeOut);
  }
  /* eslint-enable require-yield */

  /**
   * @returns {boolean} indicates whether to show the skeleton
   */
  @computed('subscription', 'setupGuideStepsService.loadingData', 'loading')
  get showSkeleton() {
    return this.loading || (isPresent(this.subscription) && this.setupGuideStepsService.loadingData);
  }

  /**
   * @returns {Array<object>} Array of LocationSetupGuideSteps
   */
  @computed('setupGuideStepsService.locationsSetupGuideSteps.[]', 'visitorsOnboarding.dashboardSteps.[]', 'productName')
  get setupSteps() {
    switch (this.productName) {
      case 'Deliveries':
        return this.setupGuideStepsService.deliveriesSteps;
      case 'Visitors':
        return this.visitorsOnboarding.dashboardSteps;
      case 'Rooms':
        return this.setupGuideStepsService.roomsSteps;
    }
    return [];
  }

  @computed('productName', 'setupSteps.@each.done', 'cardStepsData.@each.isComplete')
  get updatedCardStepsData() {
    if (
      this.setupSteps.length > 0 &&
      this.cardStepsData &&
      this.featureFlags.isEnabled('dynamicDashboardCard') &&
      this.productName == 'Visitors'
    ) {
      if (!this.cardStepsData[1].isComplete && this.setupSteps[2].done) {
        this.cardStepsData[1].isComplete = true;
      }
      if (!this.cardStepsData[2].isComplete && this.setupSteps[0].done) {
        this.cardStepsData[2].isComplete = true;
      }
    }
    return this.cardStepsData;
  }

  @computed('productName', 'updatedCardStepsData.@each.isComplete', 'setupSteps.[]')
  get updatedStepsRemaining() {
    if (
      this.setupSteps.length > 0 &&
      this.updatedCardStepsData &&
      this.featureFlags.isEnabled('dynamicDashboardCard') &&
      this.productName == 'Visitors'
    ) {
      return this.updatedCardStepsData.some((el) => el.isComplete === false);
    }
    return false;
  }

  /**
   * @returns {boolean} indicates whether a user has an active trial subscription
   */
  @computed('subscription.{onTrial,hasTrialDaysLeft,isOnTrial}')
  get onTrialSubscription() {
    return (
      get(this, 'subscription.hasTrialDaysLeft') &&
      (get(this, 'subscription.onTrial') || get(this, 'subscription.isOnTrial'))
    );
  }

  /**
   * @returns {number} number of steps user has completed or skipped
   */
  @computed('setupSteps.@each.isCompletedOrSkipped')
  get totalMissingSteps() {
    if (isBlank(this.setupSteps)) {
      return 0;
    }
    return this.setupSteps.reduce((accumulator, { isCompletedOrSkipped }) => {
      return isCompletedOrSkipped ? accumulator : accumulator + 1;
    }, 0);
  }

  getCardData = task({ drop: true }, async () => {
    try {
      this.cardStepsData = await this.getUsageDataTask.perform(this[`${this.productName.toLowerCase()}StepsData`]);
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
    }
  });

  getUsageDataTask = task({ drop: true }, async (array) => {
    const cardData = [];
    for (const data of array) {
      try {
        const result = await this.growthFeatureSetup.getSetupTask.perform(data.feature_name, data.action);
        const hasData = isPresent(result);
        data.isComplete = hasData;
        cardData.push(data);
      } catch (e) {
        this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
      }
    }
    return cardData;
  });

  /**
   * Handles tracking
   *
   * @param {MouseEvent} _evt Click event
   */
  @action
  trackGoToProduct(_evt) {
    this.metrics.trackEvent('Go to Product Button Clicked', {
      product: this.productName,
      target_page_url: this.router.urlFor(this.productRoute),
      button_text: '->',
    });
  }
}

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { FileUpload } from 'garaje/pods/components/direct-uploader/component';

interface PropertyFormPhotoUploaderArgs {
  onPendingUpload?: (pendingUpload: FileUpload) => unknown;
  onFilePreview?: (file: FileReader['result']) => void;
  reset?: () => unknown;
  imgSrc?: string;
}

export default class PropertyFormPhotoUploader extends Component<PropertyFormPhotoUploaderArgs> {
  @tracked hasFile?: boolean;

  validPhotoTypes = /image\/(gif|jpe?g|png)/;

  get directUploadUrl(): string {
    return `/a/multi-tenancy/api/direct-uploads`;
  }

  photoUploaderElement!: HTMLElement;

  @action
  onPendingUpload(pendingUpload: FileUpload): void {
    this.hasFile = true;
    this.args.onPendingUpload?.(pendingUpload);
  }

  @action
  reset(): void {
    this.hasFile = false;
    this.args.reset?.();
  }

  @action
  selectFile(): void {
    this.photoUploaderElement.querySelector('input')?.click();
  }
}

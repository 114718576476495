import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { capitalize } from '@ember/string';
import type ChameleonService from 'garaje/services/chameleon';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';

export default class WorkplacePlanPriceInfoComponent extends Controller {
  @service declare state: StateService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare workplaceMetrics: WorkplaceMetricsService;
  @service declare chameleon: ChameleonService;

  get shouldShowBillingAndTrialInfo(): boolean {
    return (
      !this.state.workplaceSubscription?.freeBundle &&
      !(this.state.workplaceSubscription?.subscribed && this.state.workplaceSubscription?.isPremiumPlan)
    );
  }

  get workplacePlanTierString(): string {
    const planString = this.state.workplaceSubscription?.normalizedPlanName ?? '';
    return planString.replace(' ', '-').split('-').map(capitalize).join(' ');
  }

  @action
  logAutoCheckInButtonClicked(): void {
    this.workplaceMetrics.trackEvent('WORKPLACE_TRIAL_SETUP_GUIDE_LEARN_ABOUT_AUTO_CHECK_IN_BUTTON_CLICKED');
  }

  @action
  logWorkplaceMapButtonClicked(): void {
    this.workplaceMetrics.trackEvent('WORKPLACE_TRIAL_SETUP_GUIDE_LEARN_ABOUT_WORKPLACE_MAPS_BUTTON_CLICKED');
  }

  @action
  logAnalyticsButtonClicked(): void {
    this.workplaceMetrics.trackEvent('WORKPLACE_TRIAL_SETUP_GUIDE_LEARN_ABOUT_ANALYTICS_BUTTON_CLICKED');
  }
}

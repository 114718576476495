import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type { TaskInstance } from 'ember-concurrency';
import type AsyncExportManagerService from 'garaje/services/async-export-manager';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import { alias, or } from 'macro-decorators';

import type { VisitorsSettingsLocationRouteModel } from './route';

export default class SettingsLocationController extends Controller {
  declare model: VisitorsSettingsLocationRouteModel;

  @service declare asyncExportManager: AsyncExportManagerService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;

  @alias('model.vrSubscription') currentSubscription!: VisitorsSettingsLocationRouteModel['vrSubscription']; //

  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isLocationAdmin') isAdmin!: boolean;

  get showSetupGuide(): boolean {
    return this.isAdmin && this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper');
  }

  @action
  exportEntries(): TaskInstance<void> {
    const params = { location: this.model.currentLocation, sort: '-signed-in-at' };

    return this.asyncExportManager.exportEntriesTask.perform(params);
  }
}

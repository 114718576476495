import { classify } from '@ember/string';
import { isPresent } from '@ember/utils';
import Blacklist from 'garaje/models/blacklist';
import CapacityScreening from 'garaje/models/capacity-screening';
import ConditionalRule from 'garaje/models/conditional-rule';
import EmployeeScreening from 'garaje/models/employee-screening';
import IdScanning from 'garaje/models/id-scanning';
import PreRegistrationRequired from 'garaje/models/pre-registration-required';
import Report from 'garaje/models/report';
import VisualCompliance from 'garaje/models/visual-compliance';
import VisitorDocuments from 'garaje/models/visitor-document-screening';

const REPORT_TYPES = {
  Blacklist,
  CapacityScreening,
  ConditionalRule,
  EmployeeScreening,
  IdScanning,
  PreRegistrationRequired,
  Report,
  VisualCompliance,
  VisitorDocuments,
};

/**
 * Factory method for creating report objects
 *
 * @param {object} report serialized report object
 * @returns {object} report
 */
function createReport(report) {
  report ||= {};
  const { source } = report;
  if (isPresent(source)) {
    return new (REPORT_TYPES[classify(source)] || Report)(report);
  }
  return new Report(report);
}

export default { createReport };

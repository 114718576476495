import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type DeviceModel from 'garaje/models/device';
import type ZoneModel from 'garaje/models/zone';

import type { PropertyDevicesRouteModel } from '../../route';

export interface PropertyDevicesIpadsNewRouteModel {
  devices: PropertyDevicesRouteModel['ipads'];
  newDevice: DeviceModel;
  property: ZoneModel;
}

export default class PropertyDevicesIpadsNewRoute extends Route {
  @service declare store: Store;

  model(): PropertyDevicesIpadsNewRouteModel {
    const property = <ZoneModel>this.modelFor('property');
    return {
      devices: (<PropertyDevicesRouteModel>this.modelFor('property.devices')).ipads,
      newDevice: this.store.createRecord('device', {
        zone: property,
      }),
      property,
    };
  }
}

import Controller from '@ember/controller';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import type DeviceModel from 'garaje/models/device';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

import type { PropertyDevicesRouteModel } from '../../route';

export default class PropertyDevicesIpadsIndexController extends Controller {
  declare model: PropertyDevicesRouteModel;

  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;

  deleteDeviceTask = dropTask(async (device: DeviceModel) => {
    try {
      await device.destroyRecord();
      this.flashMessages.showAndHideFlash('success', 'iPad removed successfully');
      void this.router.refresh('property.devices.ipads');
      if (this.model.ipads.length === 0) {
        // if we removed the last iPad, redirect to the "new iPad" page
        void this.router.transitionTo('property.devices.ipads.new');
      }
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { dropTask } from 'ember-concurrency';
import type PluginModel from 'garaje/models/plugin';
import type PluginInstallModel from 'garaje/models/plugin-install';
import type UserModel from 'garaje/models/user';
import type VfdConfigurationModel from 'garaje/models/vfd-configuration';
import type VfdContactMethodModel from 'garaje/models/vfd-contact-method';
import type { VfdContactMethodSlackMetadata, VfdContactMethodTeamsMetadata } from 'garaje/models/vfd-contact-method';
import { VfdContactMethodKind } from 'garaje/models/vfd-contact-method';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import { MSTEAMS_V2_PLUGIN_KEY, SLACK_V2_PLUGIN_KEY } from 'garaje/utils/enums';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import type { RecordArray } from 'garaje/utils/type-utils';

interface VirtualFrontDeskLocationsTableRowComponentArgs {
  configuration: VfdConfigurationModel;
  pluginInstalls: RecordArray<PluginInstallModel>;
  plugins: PluginModel[];
  remainingInSubscription: number;
  hasQuantityGating: boolean;
  noNeedVisitors: boolean;
}

export default class VirtualFrontDeskLocationsTableRowComponent extends Component<VirtualFrontDeskLocationsTableRowComponentArgs> {
  @service declare abilities: AbilitiesService;
  @service declare flashMessages: FlashMessagesService;
  @service declare skinnyLocations: SkinnyLocationsService;
  @service declare store: Store;

  @tracked isEditing = false;
  @tracked _selectedNotificationType: VfdContactMethodKind | null = null;
  @tracked _selectedSlackChannel: VfdContactMethodSlackMetadata | null = null;
  @tracked _selectedTeamsChannel: VfdContactMethodTeamsMetadata | null = null;
  @tracked _selectedUser: UserModel | null = null;

  get availableNotificationTypes(): string[] {
    const notificationTypes = [VfdContactMethodKind.EnvoyUser];
    if (this.hasSlackIntegration) {
      notificationTypes.push(VfdContactMethodKind.Slack);
    }
    if (this.hasTeamsIntegration) {
      notificationTypes.push(VfdContactMethodKind.Teams);
    }
    return notificationTypes;
  }

  get canEdit(): boolean {
    return this.abilities.can('update global settings for virtual-front-desk');
  }

  get hasSlackIntegration(): boolean {
    return !!this.slackPluginInstall;
  }

  get hasTeamsIntegration(): boolean {
    return !!this.teamsPluginInstall;
  }

  get isConfigured(): boolean {
    return this.args.configuration.hasMany('contactMethods').ids().length > 0;
  }

  get isVisitorsDisabled(): boolean {
    // eslint-disable-next-line ember/use-ember-get-and-set
    return !!this.args.configuration.location?.get('visitorsDisabled');
  }

  get isDirty(): boolean {
    if (this.isConfigured) {
      // if previously-saved, dirty if notification type changed, or notitication contact changed for the same type
      if (this.selectedNotificationType !== this.savedNotificationType) return true;
      switch (this.selectedNotificationType) {
        case VfdContactMethodKind.EnvoyUser:
          return !!this.selectedUser && this.selectedUser !== this.savedUser;
        case VfdContactMethodKind.Slack:
          return !!this.selectedSlackChannel && this.selectedSlackChannel !== this.savedSlackChannel;
        case VfdContactMethodKind.Teams:
          return !!this.selectedTeamsChannel && this.selectedTeamsChannel !== this.savedTeamsChannel;
        default:
          return false;
      }
    } else {
      // if not previously saved, dirty if a notification contact is set
      switch (this.selectedNotificationType) {
        case VfdContactMethodKind.EnvoyUser:
          return !!this._selectedUser;
        case VfdContactMethodKind.Slack:
          return !!this._selectedSlackChannel;
        case VfdContactMethodKind.Teams:
          return !!this._selectedTeamsChannel;
        default:
          return false;
      }
    }
  }

  get isToggleDisabled(): boolean {
    // on/off toggle is disabled if:
    // * the current user doesn't have permission to update VFD settings, or
    // * the location does not have a contact method configured, or
    // * try to enable VFD on a visitors disabled location, or
    // * try to enable VFD but hit the quantity gating, or
    // * a save operation is in progress
    return (
      !this.canEdit ||
      !this.isConfigured ||
      (!this.args.configuration.enabled &&
        ((!this.args.noNeedVisitors && this.isVisitorsDisabled) ||
          (this.args.hasQuantityGating && !this.args.remainingInSubscription))) ||
      this.saveContactMethod.isRunning ||
      this.toggleEnabled.isRunning
    );
  }

  get isValid(): boolean {
    switch (this.selectedNotificationType) {
      case VfdContactMethodKind.EnvoyUser:
        return !!this.selectedUser;
      case VfdContactMethodKind.Slack:
        return !!this.selectedSlackChannel;
      case VfdContactMethodKind.Teams:
        return !!this.selectedTeamsChannel;
      default:
        return false;
    }
  }

  get saveButtonDisabled(): boolean {
    if (!this.canEdit) return true; // this state should never happen but just in case...

    // disable "Save" button while a save operation is in progress
    if (this.saveContactMethod.isRunning || this.toggleEnabled.isRunning) return true;

    // disable "Save" button if not everything is selected, or no changes were made
    return !this.isValid || !this.isDirty;
  }

  get savedNotificationType(): VfdContactMethodKind | null {
    const contactMethods = this.args.configuration.contactMethods.toArray();
    if (contactMethods.length === 0) return null;
    const contactMethod = contactMethods[0]!;
    return contactMethod.kind;
  }

  get savedSlackChannel(): VfdContactMethodSlackMetadata | null {
    const contactMethods = this.args.configuration.contactMethods.toArray();
    if (contactMethods.length === 0) return null;
    const contactMethod = contactMethods[0]!;
    if (contactMethod.kind !== VfdContactMethodKind.Slack) return null;
    return <VfdContactMethodSlackMetadata>contactMethod.metadata;
  }

  get savedTeamsChannel(): VfdContactMethodTeamsMetadata | null {
    const contactMethods = this.args.configuration.contactMethods.toArray();
    if (contactMethods.length === 0) return null;
    const contactMethod = contactMethods[0]!;
    if (contactMethod.kind !== VfdContactMethodKind.Teams) return null;
    return <VfdContactMethodTeamsMetadata>contactMethod.metadata;
  }

  get savedUser(): UserModel | null {
    const contactMethods = this.args.configuration.contactMethods.toArray();
    if (contactMethods.length === 0) return null;
    const contactMethod = contactMethods[0]!;
    if (contactMethod.kind !== VfdContactMethodKind.EnvoyUser) return null;
    return contactMethod.user;
  }

  get selectedNotificationType(): VfdContactMethodKind {
    // If user has changed the selection, always use their choice.
    if (this._selectedNotificationType) return this._selectedNotificationType;
    // If there's already a notification method set up, use that.
    if (this.isConfigured) {
      const notificationMethod = this.args.configuration.contactMethods.toArray()[0]!;
      return notificationMethod.kind;
    }
    // Otherwise, pick a default. If they have the Slack integration installed,
    // assume that they prefer that over notifying an individual.
    if (this.hasSlackIntegration) return VfdContactMethodKind.Slack;
    // If they don't have Slack, but they do have Teams, default to that.
    if (this.hasTeamsIntegration) return VfdContactMethodKind.Teams;
    // Otherwise, default to notifying an individual.
    return VfdContactMethodKind.EnvoyUser;
  }

  get selectedSlackChannel(): VfdContactMethodSlackMetadata | null {
    // If a Slack channel has been searched for and selected, but not saved, use that;
    // otherwise, use previously-saved channel (if present)
    return this._selectedSlackChannel ?? this.savedSlackChannel;
  }

  get selectedTeamsChannel(): VfdContactMethodTeamsMetadata | null {
    // If a Teams channel has been searched for and selected, but not saved, use that;
    // otherwise, use previously-saved channel (if present)
    return this._selectedTeamsChannel ?? this.savedTeamsChannel;
  }

  get selectedUser(): UserModel | null {
    // If a user has been searched for and selected, but not saved, use that;
    // otherwise, use previously-saved user (if present)
    return this._selectedUser ?? this.savedUser;
  }

  get showCancelButton(): boolean {
    if (!this.isConfigured) {
      // if this location is unconfigured, never show "Cancel" button
      return false;
    }
    // otherwise, show "Cancel" button when in 'editing' state
    return this.isEditing;
  }

  get showDropdowns(): boolean {
    if (!this.canEdit) return false;
    return this.isEditing || !this.isConfigured;
  }

  get showEditIcon(): boolean {
    if (!this.canEdit) return false;
    if (this.isEditing) return false;
    if (!this.isConfigured) return false;
    return true;
  }

  get showSaveButton(): boolean {
    if (!this.isConfigured) {
      // if this location is unconfigured, show "Save" button once a user is selected
      return this.isValid;
    }
    // otherwise, show "Save" button when in 'editing' state
    return this.isEditing;
  }

  get tooltipOnToggle(): string {
    // show a tooltip on a disabled toggle if it's disabled because there's no notification contact saved
    if (this.isToggleDisabled) {
      if (!this.args.noNeedVisitors && this.isVisitorsDisabled) {
        return 'To turn on Virtual Front Desk for this location, please visit the location’s settings and activate Visitors.';
      } else if (this.args.hasQuantityGating && !this.args.remainingInSubscription) {
        return 'You have no licenses remaining. Disable Virtual Front Desk from a different location to enable it at this location, or purchase more licenses.';
      } else if (!this.isConfigured) {
        return 'Finish setting up <strong>Who to notify</strong> to enable Virtual front desk at this location.';
      }
    }

    return '';
  }

  get slackPluginInstall(): PluginInstallModel | undefined {
    const slackPlugin = this.args.plugins.find((plugin) => plugin.key === SLACK_V2_PLUGIN_KEY);
    if (!slackPlugin) return;
    return this.args.pluginInstalls.find(
      (pluginInstall) => pluginInstall.belongsTo('plugin').id() === slackPlugin.id && pluginInstall.status === 'active',
    );
  }

  get teamsPluginInstall(): PluginInstallModel | undefined {
    const teamsPlugin = this.args.plugins.find((plugin) => plugin.key === MSTEAMS_V2_PLUGIN_KEY);
    if (!teamsPlugin) return;
    return this.args.pluginInstalls.find(
      (pluginInstall) => pluginInstall.belongsTo('plugin').id() === teamsPlugin.id && pluginInstall.status === 'active',
    );
  }

  saveContactMethod = dropTask(async () => {
    let contactMethod: VfdContactMethodModel;
    let oldUser: UserModel | null = null;
    if (this.isConfigured) {
      // update existing vfd-contact-method
      contactMethod = this.args.configuration.contactMethods.firstObject!;
      // if saved contact method was EnvoyUser, store the previous user in case we roll back.
      // Calling .rollbackAttributes() only rolls back _attributes_, not relationships.
      if (contactMethod.kind === VfdContactMethodKind.EnvoyUser) {
        oldUser = contactMethod.user;
      }

      // now set new data based on current selection for notification type
      contactMethod.kind = this.selectedNotificationType;
      if (this.selectedNotificationType === VfdContactMethodKind.EnvoyUser) {
        contactMethod.user = this.selectedUser;
        contactMethod.metadata = {};
      } else if (this.selectedNotificationType === VfdContactMethodKind.Slack) {
        contactMethod.user = null;
        contactMethod.metadata = this.selectedSlackChannel!;
      } else if (this.selectedNotificationType === VfdContactMethodKind.Teams) {
        contactMethod.user = null;
        contactMethod.metadata = this.selectedTeamsChannel!;
      }
    } else {
      // create new vfd-contact-method
      contactMethod = this.store.createRecord('vfd-contact-method', {
        configuration: this.args.configuration,
        kind: this.selectedNotificationType,
      });

      if (this.selectedNotificationType === VfdContactMethodKind.EnvoyUser) {
        contactMethod.user = this._selectedUser;
      } else if (this.selectedNotificationType === VfdContactMethodKind.Slack) {
        contactMethod.metadata = this.selectedSlackChannel!;
      } else if (this.selectedNotificationType === VfdContactMethodKind.Teams) {
        contactMethod.metadata = this.selectedTeamsChannel!;
      }
    }

    try {
      await contactMethod.save();
      this._selectedSlackChannel = null;
      this._selectedTeamsChannel = null;
      this._selectedUser = null;
      this.isEditing = false;
    } catch (e) {
      contactMethod.rollbackAttributes();
      if (oldUser) contactMethod.user = oldUser;
      console.error(e); // eslint-disable-line no-console
      this.flashMessages.showAndHideFlash('error', 'Something went wrong', 'Please try again.');
    }
  });

  toggleEnabled = dropTask(async () => {
    const configuration = this.args.configuration;
    configuration.enabled = !configuration.enabled;
    try {
      await configuration.save();
      await this.skinnyLocations.loadAllTask.perform(true).catch(throwUnlessTaskDidCancel);
      if (configuration.enabled) {
        this.flashMessages.showAndHideFlash('success', 'Enabled!', 'Virtual Front Desk successfully enabled');
      } else {
        this.flashMessages.showAndHideFlash('success', 'Disabled!', 'Virtual Front Desk successfully disabled');
      }
    } catch {
      configuration.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', 'Something went wrong', 'Please try again');
    }
  });

  @action
  cancelEdit(): void {
    this._selectedUser = null;
    this.isEditing = false;
  }

  @action
  selectNotificationType(type: VfdContactMethodKind): void {
    this._selectedNotificationType = type;

    // clear previous selections for contact
    this._selectedSlackChannel = null;
    this._selectedTeamsChannel = null;
    this._selectedUser = null;
  }

  @action
  selectSlackChannel(channel: VfdContactMethodSlackMetadata): void {
    this._selectedSlackChannel = channel;
  }

  @action
  selectTeamsChannel(channel: VfdContactMethodTeamsMetadata): void {
    this._selectedTeamsChannel = channel;
  }

  @action
  selectUser(user: UserModel): void {
    this._selectedUser = user;
  }

  @action
  showEdit(): void {
    this.isEditing = true;
  }
}

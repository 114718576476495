import { modifier } from 'ember-modifier';

/**
 *
 * WarnOnUnload shows a browser confirmation warning when refreshing the page
 * if the first argument is truthy
 *
 * @param {Boolean} isDirty   When dirty, the browser shows a confirmation warning on unload
 */
export default modifier((_, [isDirty]) => {
  const handler = (ev) => {
    if (isDirty) {
      ev.preventDefault();
      ev.returnValue = '';
    }
  };

  window.addEventListener('beforeunload', handler);

  return () => {
    window.removeEventListener('beforeunload', handler);
  };
});

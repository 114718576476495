import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { adminRolesRoute } from 'garaje/utils/admin-routing';
import { getRoleIcon, RoleId, RoleType } from 'garaje/utils/custom-roles';
import type { Role } from 'garaje/utils/custom-roles';

interface RoleArgs {
  role: Role;
}

export default class RoleListItemComponent extends Component<RoleArgs> {
  @service declare router: RouterService;

  get iconPath(): string {
    const { role } = this.args;
    return role ? getRoleIcon(role.id) : '';
  }

  get routeToView(): string {
    return adminRolesRoute(this.router.currentRouteName, 'manage.view-role');
  }

  get routeToCreate(): string {
    return adminRolesRoute(this.router.currentRouteName, 'manage.create-role');
  }

  get routeToAdminsPage(): string {
    return adminRolesRoute(this.router.currentRouteName, 'manage.admin-users');
  }

  get showCustomBadge(): boolean {
    const { role } = this.args;

    return role.type === RoleType.CUSTOM && role.id != RoleId.ANALYTICS_VIEWER;
  }
}

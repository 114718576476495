import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import { Permission } from 'garaje/utils/ui-permissions';

export default class SecurityDeskAbility extends Ability {
  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;

  get canVisit(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_SECURITY_DESK_READ);
  }
}

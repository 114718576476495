import { service } from '@ember/service';
import Component from '@glimmer/component';
import type AbilitiesService from 'ember-can/services/abilities';
import { dropTask } from 'ember-concurrency';
import { type Task } from 'ember-concurrency';
import type EntryModel from 'garaje/models/entry';

interface SignedOutColumnArgs {
  signOutTask: Task<unknown, [EntryModel, string]>;
  entry: EntryModel;
  fullDate: boolean;
  userType: string;
}

export default class SignedOutColumn extends Component<SignedOutColumnArgs> {
  @service declare abilities: AbilitiesService;

  get canSignOut(): boolean {
    const entry = this.args.entry;

    // if entry is not signed in, it can't be signed out
    if (!entry.signInTime) return false;

    // if entry is already signed out, it can't be signed out again
    if (entry.signOutTime) return false;

    return this.abilities.can('edit entry', entry);
  }

  // We need to wrap this task so the loading state doesn't propagate into other rows
  internalSignOutTask = dropTask(async () => {
    await this.args.signOutTask.perform(this.args.entry, 'visitor_log');
  });
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';

import type { PropertyDevicesPrintersConnectionsRouteModel } from '../route';

export default class PropertyDevicesPrintersConnectionsIndexRoute extends Route {
  @service declare router: RouterService;

  queryParams = {
    lastPrinterConnectionId: { refreshModel: true },
  };

  beforeModel(transition: Transition): void {
    const printerConnections = (<PropertyDevicesPrintersConnectionsRouteModel>(
      this.modelFor('property.devices.printers.connections')
    )).printerConnections.toArray();

    if (printerConnections.length === 0) {
      void this.router.replaceWith('property.devices.printers.connections.new');
    } else {
      const lastPrinterConnectionId = transition.to.queryParams['lastPrinterConnectionId'];
      let printerConnectionId = printerConnections[0]!.id;
      if (lastPrinterConnectionId && printerConnections.find((pc) => pc.id === lastPrinterConnectionId)) {
        printerConnectionId = lastPrinterConnectionId;
      }
      void this.router.replaceWith('property.devices.printers.connections.show', printerConnectionId);
    }
  }
}

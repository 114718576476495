import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';

class BillingPlansRoute extends Route {
  @service declare router: RouterService;

  beforeModel(): void {
    this.redirectToNewPlansPage();
  }

  redirectToNewPlansPage(): void {
    void this.router.transitionTo('billing.product-plans', 'visitors');
  }
}

export default BillingPlansRoute;

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, set } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';

import config from 'garaje/config/environment';
import updateAndSaveTask from 'garaje/utils/decorators/update-and-save-task';
import WatchlistValidations from 'garaje/validations/watchlist';

/**
 * @param {boolean}             canAccessWatchList
 * @param {object}               location
 * @param {Function}            onUpgradeWatchlist
 */
@updateAndSaveTask
class WatchList extends Component {
  @service abilities;
  @service flashMessages;
  @service metrics;

  @tracked isEditingWatchlist = false;
  @tracked watchlist;

  defaultCountry = config.defaultCountry;

  get canCreateWatchlist() {
    return this.abilities.can('create watchlist in security');
  }

  get canReadWatchlist() {
    return this.abilities.can('read watchlist in security');
  }

  get canUpdateWatchlist() {
    return this.abilities.can('update watchlist in security');
  }

  get isWatchlistEditableAndFulfilled() {
    return Boolean(this.args.location.watchlist.list);
  }

  get isInputDisabled() {
    return !this.canUpdateWatchlist;
  }

  @action
  setupChangeset() {
    const immutableWatchlist = this.args.location.watchlist;
    const watchlist = { ...immutableWatchlist };
    const validator = lookupValidator(WatchlistValidations);

    this.watchlist = new Changeset(watchlist, validator, WatchlistValidations);
  }

  @task
  *saveWatchlist() {
    const changeset = this.watchlist;

    try {
      this.metrics.trackEvent('Security - Watch List Settings Saved', { button_text: 'Save' });
      yield changeset.validate();

      if (!get(changeset, 'isValid')) {
        throw 'Please correct the form errors.';
      }

      // We can't proxy .save to the object since it doesn't support it.
      changeset.execute();
      changeset.rollback();

      // save location model
      const location = this.args.location;
      set(location, 'watchlist', Object.freeze(get(changeset, 'data')));
      yield this.updateAndSaveTask.perform(location);

      this.isEditingWatchlist = false;
    } catch (e) {
      const message_title = 'Please correct the form errors.';
      this.flashMessages.showAndHideFlash('error', message_title);
      this.metrics.trackEvent('Viewed Flash Message', {
        type: 'error',
        message_title,
        message_codes: 'error_watch_list_update',
        message_code_type: 'watch_list',
      });
    }
  }

  @action
  editWatchlist() {
    this.isEditingWatchlist = true;
    this.metrics.trackEvent('Security - Watchlist Expanded', { button_text: 'Edit' });
  }

  @action
  enableWatchlist() {
    this.isEditingWatchlist = true;
    this.metrics.trackEvent('Security - Watchlist Enable Requested');
    this.metrics.trackEvent('Security - Watchlist Expanded', { button_text: 'Enable' });
  }

  @action
  rollbackWatchlist() {
    this.isEditingWatchlist = false;
    this.args.location.rollbackAttributes();
  }
}

export default WatchList;

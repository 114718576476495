import { A } from '@ember/array';
import { action, setProperties } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type DetailedChangeset } from 'ember-changeset/types';
import { dropTask } from 'ember-concurrency';
import config from 'garaje/config/environment';
import type DeviceConfigStaticPageAttributeModel from 'garaje/models/device-config-static-page-attribute';
import type DeviceConfigStaticPageAttributeTranslationModel from 'garaje/models/device-config-static-page-attribute-translation';
import type FlowModel from 'garaje/models/flow';
import type GlobalSettingBatchModel from 'garaje/models/global-setting-batch';
import type GlobalSummaryPageModel from 'garaje/models/global-summary-page';
import type SummaryPageModel from 'garaje/models/summary-page';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import fixVideoUrl from 'garaje/utils/fix-video-url';
import LOCALE_OPTIONS from 'garaje/utils/locale-options';
import manualPropagableHandler from 'garaje/utils/manual-propagable-handler';
import type { ModelAttrs } from 'garaje/utils/type-utils';
import urlBuilder from 'garaje/utils/url-builder';
import { type SingleResponse } from 'jsonapi/response';
import { equal } from 'macro-decorators';
import type ServerError from 'utils/server-error';

export type PageModel = DeviceConfigStaticPageAttributeModel | SummaryPageModel | GlobalSummaryPageModel;
type JoinedPagesModels = DeviceConfigStaticPageAttributeModel & SummaryPageModel & GlobalSummaryPageModel;
type AnySummaryPageModel = SummaryPageModel | GlobalSummaryPageModel;
type PageAttributeProps = Partial<Pick<JoinedPagesModels, 'image' | 'message' | 'videoUrl'>>;

interface PageEditorComponentArgs {
  changeset: DetailedChangeset<PageModel>;
  defaultLocale: string;
  flow?: FlowModel;
  globalSettingBatch?: GlobalSettingBatchModel;
  /**
   * text shown in SettingsPanel description
   */
  headerDescription: string;
  /**
   * text shown in SettingsPanel title
   */
  headerTitle: string;
  isDisabled: boolean;
  isEditingCustomFinalMessage?: boolean;
  /**
   * whether to show "Email me" button. Note that the "lobbyXp" and "lobbyXpTfvEmail" feature flags are required, in addition to this value being `true`, for the button to be displayed.
   */
  showEmailMeButton: boolean;
  translationLanguagesAvailable: boolean;
  translationEnabledLocales: string[];
  useDirectUploader?: boolean;
  defaultText?: string;
}

interface Locale {
  label: string;
  value: string;
}

const TEXT = { label: 'Text', value: 'message' };
const IMAGE = { label: 'Image', value: 'image' };
const VIDEO = { label: 'Video', value: 'video' };

const OPTIONS = [TEXT, IMAGE, VIDEO];

const EXAMPLE_MARKDOWN = `**This is an example of a summary message**

/**/
/*1*/

Paragraph [Link](https://envoy.com) **this is bold** *this is italic*

> Read, every day, something no one else is reading. Think, every day, something no one else is thinking. Do, every day, something no one else would be silly enough to do. It is bad for the mind to be always part of unanimity.

* one
* two
* three

- uno
- dos
- tres

![image](https://envoy.com/apple-touch-icon.png)`;

export default class PageEditorComponent extends Component<PageEditorComponentArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare store: Store;

  @tracked localeOptions = LOCALE_OPTIONS;
  options = OPTIONS;
  @tracked isEditingCustomFinalMessage = false;
  @tracked selectedLocale;
  @tracked imageUrl?: string | null;

  @equal('args.changeset.kind', 'message') isText!: boolean;
  @equal('args.changeset.kind', 'video') isVideo!: boolean;
  @equal('args.changeset.kind', 'image') isImage!: boolean;
  @equal('args.changeset.kind', 'none') isNone!: boolean;

  constructor(owner: unknown, args: PageEditorComponentArgs) {
    super(owner, args);
    this.selectedLocale = this.args.defaultLocale;
    this.isEditingCustomFinalMessage = Boolean(this.args.isEditingCustomFinalMessage);

    this.#setImageUrl();
  }

  get dirtyTranslations(): DeviceConfigStaticPageAttributeTranslationModel[] {
    if (!('deviceConfigStaticPageAttributeTranslations' in this.model)) return [];

    const attributeChangeset = <DetailedChangeset<DeviceConfigStaticPageAttributeModel>>this.args.changeset;

    return attributeChangeset.deviceConfigStaticPageAttributeTranslations.filter(
      (translation) => translation.hasDirtyAttributes,
    );
  }

  get isDirty(): boolean {
    return this.args.changeset.isDirty || this.dirtyTranslations.length > 0;
  }

  get model(): PageModel {
    return this.args.changeset._content;
  }

  get currentTranslationForLocale(): DeviceConfigStaticPageAttributeTranslationModel | undefined {
    if (!('deviceConfigStaticPageAttributeTranslations' in this.model)) return;

    const changeset = <DetailedChangeset<DeviceConfigStaticPageAttributeModel>>this.args.changeset;

    return changeset.deviceConfigStaticPageAttributeTranslations.find(
      (translation) =>
        translation.fromLanguage === this.args.defaultLocale && translation.toLanguage === this.selectedLocale,
    );
  }

  get showEmailMeButton(): boolean {
    return (
      this.args.showEmailMeButton &&
      this.featureFlags.isEnabled('lobbyXp') &&
      this.featureFlags.isEnabled('lobbyXpTfvEmail')
    );
  }

  get showSave(): boolean {
    // Image upload is done through the image-box component, which
    // is a file select, a "save" button doesn't make sense in this
    // scenario.

    return !!(!this.isImage || this.args.changeset.image);
  }

  get uploadImageUrl(): string {
    return this.args.useDirectUploader
      ? `${config.apiHost}/a/multi-tenancy/api/direct-uploads`
      : urlBuilder.v3.summaryPageImageUpload(this.model.id);
  }

  get availableLocales(): Locale[] {
    const { defaultLocale, translationEnabledLocales, flow } = this.args;
    let locales = translationEnabledLocales;

    if (flow?.isGlobal) {
      locales = A([...translationEnabledLocales, defaultLocale].flat()).uniq();
    }

    return this.localeOptions.filter((option) => locales.includes(option.value));
  }

  get messageValue(): string | null | undefined {
    if (this.args.defaultLocale === this.selectedLocale) {
      return this.args.changeset.message;
    }

    if (!('deviceConfigStaticPageAttributeTranslations' in this.model)) {
      const changeset = <DetailedChangeset<AnySummaryPageModel>>this.args.changeset;

      const customTranslations = changeset.customTranslations;
      if (
        isBlank(customTranslations['message']) ||
        typeof customTranslations['message'][this.selectedLocale] !== 'string'
      ) {
        return '';
      }
      return changeset.customTranslations['message'][this.selectedLocale];
    } else {
      const { currentTranslationForLocale } = this;
      if (!currentTranslationForLocale) {
        return '';
      }
      return currentTranslationForLocale.value;
    }
  }

  @action
  setKind(kind: PageModel['kind']): void {
    if (kind === 'message' && isBlank(this.args.changeset.message)) {
      this.args.changeset.message = this.args.defaultText ?? EXAMPLE_MARKDOWN;
    }
    this.args.changeset.kind = kind;
  }

  disableCustomFinalMessage = dropTask(async () => {
    const changeset = this.args.changeset;

    changeset.kind = 'none';
    changeset.image = null;
    changeset.message = null;
    changeset.videoUrl = null;

    await this.saveTask.perform(changeset);

    this.isEditingCustomFinalMessage = false;
  });

  saveTask = dropTask(async (changeset: PageEditorComponentArgs['changeset']) => {
    try {
      await changeset.save();

      // save translations if available
      await Promise.all(A(this.dirtyTranslations).invoke('save'));
      this.#setImageUrl();

      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e: unknown) {
      let message = 'Server error. Please try again.';

      if ((<ServerError>e).isAdapterError) {
        message = (<ServerError>e).errors.mapBy('detail').join(', ');
      }

      this.flashMessages.showFlash('error', message);
    }
  });

  cleanupProps(kind: string): PageAttributeProps {
    const props: PageAttributeProps = { image: null, message: null, videoUrl: null };

    switch (kind) {
      case VIDEO.value:
        delete props.videoUrl;
        this.fixVideoUrl();
        break;
      case TEXT.value:
        delete props.message;
        break;
      case IMAGE.value:
        delete props.image;
        break;
    }

    return props;
  }

  @action
  async save(): Promise<unknown> {
    const changeset = this.args.changeset;
    const props = this.cleanupProps(changeset.kind);
    setProperties(changeset, props);

    await changeset.validate();

    if (!changeset.isValid) {
      let message = 'Please include a valid URL';
      if (this.isText) {
        message = 'Please write a message';
      }

      this.flashMessages.showAndHideFlash('error', message);
      return;
    }

    if (this.isDirty) {
      const model = await this.saveTask.perform(changeset);
      next(this, () => {
        this.isEditingCustomFinalMessage = false;
      });
      return model;
    }

    return;
  }

  fixVideoUrl(): void {
    const url = this.args.changeset.videoUrl;
    if (url) {
      const fixed = fixVideoUrl(url);

      if (url !== fixed) {
        this.args.changeset.videoUrl = fixed;
      }
    }
  }

  @action
  setImage(data: string | Record<string, unknown>): void {
    if (this.args.useDirectUploader) {
      this.args.changeset.image = <string>data;
      void this.save();
    } else {
      /*
          TODO: This code is badly organized, we should move this
          onto the model, and have the model handle image upload.
          We'll need to break up the file uploader first to do this,
          it does too many things right now
        */
      const summaryPage = <AnySummaryPageModel>this.model;
      const modelName = this.args.flow?.isGlobal ? 'global-summary-page' : 'summary-page';
      this.store.pushPayload(modelName, data);
      const modelProps = { image: summaryPage.image, message: summaryPage.message, videoUrl: summaryPage.videoUrl };

      const changeset = this.args.changeset;
      setProperties(changeset, modelProps);
      changeset.execute();
      changeset.rollback();
      manualPropagableHandler(this.args.globalSettingBatch, 'flow.summaryPage', 'image');

      this.#setImageUrl();
    }
  }

  @action
  deleteImage(): Promise<unknown> {
    if (this.args.useDirectUploader) {
      this.args.changeset.image = null;
      return this.save();
    } else {
      const summaryPage = <AnySummaryPageModel>this.model;
      return summaryPage.deleteSummaryPhoto().then((data) => {
        const modelName = this.args.flow?.isGlobal ? 'global-summary-page' : 'summary-page';
        const normalized = <SingleResponse<AnySummaryPageModel>>this.store.normalize(modelName, data.data);
        // if image is not present then it is not included in the
        // payload. We need to nullify the key.
        //
        normalized.data.attributes.image = null;
        this.store.push(normalized);
        manualPropagableHandler(this.args.globalSettingBatch, 'flow.summaryPage', 'image');
      });
    }
  }

  toggleSummaryProperty = dropTask(async (prop: ModelAttrs<PageModel>, value: string) => {
    const changeset = this.args.changeset;
    changeset[prop] = value;

    await this.saveTask.perform(changeset);
  });

  @action
  rollback(): void {
    this.args.changeset.rollback();

    if (!('deviceConfigStaticPageAttributeTranslations' in this.model)) return;

    // roll back any edited translations. Deleted any translations that were created and not saved
    const changeset = <DetailedChangeset<DeviceConfigStaticPageAttributeModel>>this.args.changeset;
    changeset.deviceConfigStaticPageAttributeTranslations?.invoke('rollbackAttributes');
  }

  @action
  messageContentChanged(value: string): void {
    if (this.args.defaultLocale === this.selectedLocale) {
      this.args.changeset.message = value;
      return;
    }

    if (!('deviceConfigStaticPageAttributeTranslations' in this.model)) {
      const changeset = <DetailedChangeset<AnySummaryPageModel>>this.args.changeset;
      let customTranslations = Object.assign({}, changeset.customTranslations);
      if (isBlank(customTranslations['message'])) {
        customTranslations = { message: {} };
      }
      customTranslations['message'][this.selectedLocale] = value;
      changeset.customTranslations = customTranslations;
    } else {
      let { currentTranslationForLocale } = this;
      if (!currentTranslationForLocale) {
        currentTranslationForLocale = this.store.createRecord('device-config-static-page-attribute-translation', {
          fromLanguage: this.args.defaultLocale,
          toLanguage: this.selectedLocale,
          translationStatus: 'manually_translated',
          deviceConfigStaticPageAttribute: this.model,
          value,
        });
      }

      currentTranslationForLocale.value = value;
    }
  }

  #setImageUrl() {
    this.imageUrl = this.model.image;
  }
}

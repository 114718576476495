import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type PersonalizedChecklistsService from 'garaje/services/personalized-checklists';
import type StatsigService from 'garaje/services/statsig';
import type VisitorsOnboardingService from 'garaje/services/visitors-onboarding';
import { APP } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

export default class WelcomeChecklistComponent extends Component {
  @service declare flashMessages: FlashMessagesService;
  @service declare personalizedChecklists: PersonalizedChecklistsService;
  @service declare statsig: StatsigService;
  @service declare visitorsOnboarding: VisitorsOnboardingService;

  INTEGRATIONS_TAB_NAME = 'setupIntegration';

  get employeeStepComplete(): boolean {
    if (this.visitorsOnboarding.totalAdmins > 1 && this.visitorsOnboarding.totalEmployees > 1) {
      return true;
    }
    return false;
  }

  get brandingStepComplete(): boolean {
    if (this.visitorsOnboarding.hasLogoOrAccentColor && this.visitorsOnboarding.hasDesign) {
      return true;
    }
    return false;
  }

  get flowsStepComplete(): boolean {
    if (
      this.visitorsOnboarding.hasSignInFields &&
      (this.visitorsOnboarding.hasEnabledBadges ||
        this.visitorsOnboarding.hasEnabledPhotos ||
        this.visitorsOnboarding.totalLegalDocuments > 0)
    ) {
      return true;
    }
    return false;
  }

  get ipadStepComplete(): boolean {
    if (this.visitorsOnboarding.hasIpad) {
      return true;
    }
    return false;
  }

  get integrationsStepComplete(): boolean {
    return false;
  }

  get currentStep(): string {
    if (!this.employeeStepComplete) {
      return 'employeeStep';
    } else if (this.employeeStepComplete && !this.brandingStepComplete) {
      return 'brandingStep';
    } else if (this.employeeStepComplete && this.brandingStepComplete && !this.flowsStepComplete) {
      return 'flowsStep';
    } else if (
      this.employeeStepComplete &&
      this.brandingStepComplete &&
      this.flowsStepComplete &&
      !this.ipadStepComplete
    ) {
      return 'ipadStep';
    } else if (
      this.employeeStepComplete &&
      this.brandingStepComplete &&
      this.flowsStepComplete &&
      this.ipadStepComplete &&
      !this.integrationsStepComplete
    ) {
      return 'integrationsStep';
    }
    return 'none';
  }

  get allStepsComplete(): boolean {
    if (this.employeeStepComplete && this.brandingStepComplete && this.flowsStepComplete && this.ipadStepComplete) {
      return true;
    }
    return false;
  }

  get checklistButtonText(): string {
    if (this.allStepsComplete) {
      return 'Finish';
    } else if (
      this.employeeStepComplete ||
      this.brandingStepComplete ||
      this.flowsStepComplete ||
      this.ipadStepComplete
    ) {
      return 'Continue';
    }
    return 'Start';
  }

  get checklistButtonRoute(): string {
    if (this.allStepsComplete) {
      return 'visitors.entries';
    } else if (this.currentStep == 'employeeStep') {
      return 'employees.directory';
    } else if (this.currentStep == 'brandingStep') {
      return 'visitors.settings.location';
    } else if (this.currentStep == 'flowsStep') {
      return 'visitors.settings.visitor-types';
    } else if (this.currentStep == 'ipadStep') {
      return 'visitors.devices.ipads';
    } else if (this.currentStep == 'integrationsStep') {
      return 'integrations.index';
    }
    return 'visitors.entries';
  }

  @action
  onInsert(): void {
    void this.visitorsOnboarding.loadLocationsTask.perform();
    void this.visitorsOnboarding.loadEmployeesTask.perform();
    void this.visitorsOnboarding.loadUserRolesTask.perform();
    void this.visitorsOnboarding.loadFlowsTask.perform(false);

    void this.visitorsOnboarding.loadIpadsTask.perform();

    this.personalizedChecklists.checklistMetadata = { app: APP.VISITORS };
    void this.personalizedChecklists.loadChecklistTask.perform();

    this.logStatsigEvent('welcome-page-viewed');
  }

  @action
  logStatsigEvent(event_name: string): void {
    this.statsig.logEvent(event_name);
  }

  skipSetupTask = task(this, async () => {
    try {
      this.statsig.logEvent('setup-skipped');
      this.visitorsOnboarding.toggleSkipSetupBanner();
      await this.visitorsOnboarding.toggleVisitorsProductVideosEnabledTask.perform();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  get hasIntegrations(): boolean {
    return this.personalizedChecklists.customerTabs.includes(this.INTEGRATIONS_TAB_NAME);
  }
}

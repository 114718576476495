import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import { Permission } from 'garaje/utils/ui-permissions';

const CAN_VISIT_AUTHZ_PERMISSIONS = [
  Permission.VISITORS_ENTRY_READ,
  Permission.VISITORS_ENTRY_READ_MINE,
  Permission.VISITORS_INVITE_READ,
  Permission.VISITORS_INVITE_READ_MINE,
];

export default class DashboardAbility extends Ability {
  @service declare authz: AuthzService;

  get canVisit(): boolean {
    return this.authz.hasAnyPermissionAtCurrentLocation(CAN_VISIT_AUTHZ_PERMISSIONS);
  }
}

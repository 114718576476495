import Route from '@ember/routing/route';
import type GlobalFlowModel from 'garaje/models/global-flow';
import type GlobalSummaryPageModel from 'garaje/models/global-summary-page';
import { hash } from 'rsvp';

import type { LocationOverviewSignInFlowsShowRouteModel } from '../route';

interface LocationOverviewSignInFlowsShowFinalScreenRouteModel {
  flow: GlobalFlowModel;
  summaryPage: GlobalSummaryPageModel;
}

export default class LocationOverviewSignInFlowsShowFinalScreenRoute extends Route {
  async model(): Promise<LocationOverviewSignInFlowsShowFinalScreenRouteModel> {
    const { globalFlow } = <LocationOverviewSignInFlowsShowRouteModel>(
      this.modelFor('location-overview.sign-in-flows.show')
    );
    const summaryPage = await globalFlow.summaryPage;

    return hash({
      flow: globalFlow,
      summaryPage,
    });
  }
}

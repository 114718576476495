import Report from 'garaje/models/report';
import _defaults from 'lodash/defaults';

export default class CapacityScreening extends Report {
  declare reason: string | null;

  constructor(attrs: Partial<CapacityScreening> = {}) {
    super(attrs);
    const props = { ...attrs };
    _defaults(props, {
      reason: null,
    });
    Object.assign(this, props);
  }
}

// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed, action } from '@ember/object';

export default class NumberSpinnerComponent extends Component {
  tagName = '';

  @computed('number')
  get value() {
    return this.number;
  }

  @action
  async increment() {
    if (this.number < 100) {
      this.number = this.number + 1;
    }
  }

  @action
  async decrement() {
    if (this.number) {
      this.number = this.number - 1;
    }
  }
}

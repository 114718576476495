import { service } from '@ember/service';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type { ModelRegistry } from 'ember-data/model';
import type FeatureFlagsService from 'garaje/services/feature-flags';

import ApplicationSerializer from './application';

export default class GlobalAgreementSerializer extends ApplicationSerializer {
  @service declare featureFlags: FeatureFlagsService;

  attrs = {
    documentUrl: {
      serialize: false,
    },
  };

  modelNameFromPayloadKey(key: string): string {
    if (key === 'agreement-pages') {
      return 'global-agreement-page';
    } else if (key === 'agreements') {
      return 'global-agreement';
    } else {
      return super.modelNameFromPayloadKey(key);
    }
  }

  payloadKeyFromModelName(modelName: keyof ModelRegistry): string {
    if (modelName === 'global-agreement-page') {
      return 'agreement-pages';
    } else if (modelName === 'global-agreement') {
      return 'agreements';
    } else {
      return super.payloadKeyFromModelName(modelName);
    }
  }
}

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class VisitorsSettingsVisitorTypesFlowVisitorEmailsRoute extends Route {
  @service abilities;
  @service transitionConfirm;
  @service router;
  @service store;

  beforeModel() {
    if (this.abilities.cannot('visit flow visitor-emails')) {
      this.router.transitionTo('visitors.settings');
    }
  }

  model() {
    const { currentLocation, flow } = this.modelFor('visitors.settings.visitor-types.flow');
    const mailerTemplates = this.store.findAll('mailer-template');
    return hash({
      mailerTemplates,
      currentLocation,
      flow,
    });
  }

  @routeEvent
  routeWillChange(transition) {
    const model = this.modelFor(this.routeName);
    const { flow } = model;
    // no need to display confirmation modal when nothing is changed
    if (!flow.hasDirtyAttributes) {
      return;
    }
    // display confirmation modal if settings are dirty
    this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        if (flow.hasDirtyAttributes) {
          flow.rollbackAttributes();
        }
      },
    });
  }
}

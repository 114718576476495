import Model, { attr } from '@ember-data/model';

class SetupGuideStepModel extends Model {
  @attr('string') declare page: string;
  @attr('string') declare prerequisiteText: string;
  @attr('boolean') declare skippable: boolean;
  @attr('number') declare stepNumber: number;
  @attr('number') declare deliveriesSetupBarOrder: number;
  @attr('string') declare subtitle: string;
  @attr('string') declare title: string;
}

export default SetupGuideStepModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'setup-guide-step': SetupGuideStepModel;
  }
}

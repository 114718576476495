import gql from 'graphql-tag';

export default gql`
  query AnnouncementAudienceAggregateSize($audiences: [AnnouncementAudienceInput!]!, $locationId: ID!) {
    announcementAudienceAggregateSize(audiences: $audiences, locationId: $locationId) {
      aggregateSize
      locationId
    }
  }
`;

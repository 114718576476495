import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { RECURRING_OPTIONS } from 'garaje/pods/components/invites/select-recurring-invites/component';

export default class EditRecurringInvitesModal extends Component {
  @service featureFlags;

  @tracked sendNotificationCallback;
  @tracked scope = this.args.scope || RECURRING_OPTIONS.THIS;

  @action
  onSelectScope(scope) {
    this.scope = scope;
  }

  @dropTask
  *onSubmitTask(e) {
    e.preventDefault();
    if (this.args.shouldSendNotification) {
      this.sendNotificationCallback = () => this.args.onSubmit(this.scope);
    } else {
      yield this.args.onSubmit(this.scope);
      this.args.close();
    }
  }
}

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { LocationType } from 'garaje/models/employee';

interface LocationTypeInputArgs {
  disabled?: boolean;
  value: LocationType | null;
  update: (newValue: LocationType | null) => void;
}

export const LOCATION_TYPE_MAPPING = {
  [LocationType.REMOTE]: 'Remote',
  [LocationType.ONSITE]: 'In person',
};

export default class LocationTypeInput extends Component<LocationTypeInputArgs> {
  get value(): string {
    return this.args.value === null ? '' : this.args.value;
  }

  get locationTypeOptions(): Array<LocationType | ''> {
    return ['', LocationType.REMOTE, LocationType.ONSITE];
  }

  @action
  update(val: LocationType | ''): void {
    const newValue = val === '' ? null : val;
    this.args.update(newValue);
  }

  displayLocationTypeOption(option: LocationType | ''): string {
    return option === '' ? '' : LOCATION_TYPE_MAPPING[option];
  }
}

import Model, { attr, belongsTo } from '@ember-data/model';
import type VfdCallLogModel from 'garaje/models/vfd-call-log';

type VfdCallTranscriptStatus = 'unavailable' | 'available' | 'expired';

export interface VfdCallLogTranscriptionUrl {
  zip: string;
}

export default class VfdCallLogTranscriptionModel extends Model {
  @belongsTo('vfd-call-log', { async: false }) declare vfdCallLog: VfdCallLogModel;

  @attr('string') declare status: VfdCallTranscriptStatus;
  @attr() declare url: VfdCallLogTranscriptionUrl | null;
  @attr('date') declare expiresAt: Date | null;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface VfdCallLogTranscriptionModel {
    'vfd-call-log-transcription': VfdCallLogTranscriptionModel;
  }
}

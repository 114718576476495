import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type InvoiceEstimateModel from 'garaje/models/invoice-estimate';
import type TaxModel from 'garaje/models/tax';

interface TaxesBreakDownTooltip {
  invoiceEstimate: InvoiceEstimateModel | null;
  divideIntoMonthly: boolean | null;
}

export default class TaxesBreakdownTooltip extends Component<TaxesBreakDownTooltip> {
  @tracked taxesArray: { description: string; amount: number }[] = [];
  @tracked targetIdSuffix = 'm';

  constructor(...args: unknown[]) {
    // @ts-ignore
    super(...args);
    void this.loadTaxInfo();
  }

  @action loadTaxInfo(): void {
    if (this.args.invoiceEstimate?.taxes) {
      let runningTotal = 0;
      (this.args.invoiceEstimate.taxes as TaxModel[]).forEach((taxItem: TaxModel) => {
        const existingTax = this.taxesArray.find((tax) => tax.description === taxItem.description);
        let taxItemAmount = taxItem.amount;
        if (this.args.divideIntoMonthly) {
          this.targetIdSuffix = 'y';
          taxItemAmount = taxItemAmount / 12;
        }
        runningTotal += taxItemAmount;
        if (existingTax) {
          existingTax.amount += taxItemAmount;
        } else {
          this.taxesArray.push({ description: taxItem.description, amount: taxItemAmount });
        }
      });
      if (this.taxesArray.length) {
        this.taxesArray.push({ description: 'Total', amount: runningTotal });
      }
    }
  }
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import { dropTask } from 'ember-concurrency';
import RecurringRule, { byDayValue, dayFromRule } from 'garaje/models/recurring-rule';
import type VfdScheduleModel from 'garaje/models/vfd-schedule';
import { type VfdScheduleRule } from 'garaje/models/vfd-schedule';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { Day } from 'garaje/utils/enums';
import SimpleTime from 'garaje/utils/simple-time';
import TimeRange from 'garaje/utils/time-range';
import { TrackedArray, TrackedObject } from 'tracked-built-ins';
import { cached } from 'tracked-toolbox';

interface VirtualFrontDeskSettingsConfigurationSignature {
  readOnly: boolean;
  schedule: VfdScheduleModel;
}

// Days of the week, in the order they should appear
const DAYS = [Day.Monday, Day.Tuesday, Day.Wednesday, Day.Thursday, Day.Friday, Day.Saturday, Day.Sunday];

// "default" schedule means every day is enabled and has one interval with these start/end times:
const DEFAULT_START_TIME = SimpleTime.StartOfDayMidnight;
const DEFAULT_END_TIME = SimpleTime.EndOfDayMidnight;

function ruleMatchesDay(rule: VfdScheduleRule, day: Day): boolean {
  const recurringRule = RecurringRule.parse(rule.recurringRule);
  return recurringRule.frequency === 'WEEKLY' && recurringRule.byDay === byDayValue(day);
}

export default class VirtualFrontDeskSettingsConfiguration extends Component<VirtualFrontDeskSettingsConfigurationSignature> {
  @service declare flashMessages: FlashMessagesService;

  @tracked hasChanges = false;
  @tracked isOpen = false;
  @tracked showUnsavedChangesWarning = false;

  days = DAYS;

  @cached
  get hasMissingIntervals(): boolean {
    // Look at each day and check that at least one valid interval exists.
    // Invalid intervals are allowed to co-exist with valid ones but will be discarded when saving.
    return this.scheduleRules.some((scheduleForDay) => {
      return !scheduleForDay.intervals.some((interval) => interval.isValid);
    });
  }

  @cached
  get hasOverlappingIntervals(): boolean {
    return this.scheduleRules.some((scheduleForDay) => {
      // Look at each interval and see if it overlaps with any other interval.
      // Because overlapping is commutative, we only need to look at intervals
      // further ahead in the array, because intervals earlier in the array will
      // have already been checked against the current interval.
      for (let i = 0; i < scheduleForDay.intervals.length; i++) {
        const interval = scheduleForDay.intervals[i]!;
        for (let j = i + 1; j < scheduleForDay.intervals.length; j++) {
          const otherInterval = scheduleForDay.intervals[j]!;
          // don't compare intervals that are missing start or end times
          if (!interval.isValid || !otherInterval.isValid) return false;
          if (interval.overlaps(otherInterval)) return true;
        }
      }
      return false;
    });
  }

  @cached
  get isSaveButtonDisabled(): boolean {
    return !this.hasChanges || this.hasOverlappingIntervals || this.hasMissingIntervals || this.save.isRunning;
  }

  @cached
  get schedule(): DetailedChangeset<VfdScheduleModel> {
    return Changeset(this.args.schedule);
  }

  @cached
  get scheduleRules(): VfdScheduleRule[] {
    // Return a deep clone of the saved rules on @schedule. This allows us to mutate the rules here
    // without affecting the 'live' object until we're ready.
    return this.schedule.rules.map((rule) => {
      return new TrackedObject({
        enabled: rule.enabled,
        recurringRule: rule.recurringRule,
        intervals: new TrackedArray(rule.intervals.map((interval) => interval.clone())),
      });
    });
  }

  @cached
  get showResetButton(): boolean {
    if (this.args.readOnly) return false;
    // show reset button if the current schedule does not correspond to the "default" schedule
    return !this.scheduleRules.every(
      (rule) =>
        rule.enabled &&
        rule.intervals.length === 1 &&
        rule.intervals[0]!.isValid &&
        rule.intervals[0]!.from!.isSame(DEFAULT_START_TIME) &&
        rule.intervals[0]!.to!.isSame(DEFAULT_END_TIME),
    );
  }

  @action
  addIntervalToDay(day: Day): void {
    const scheduleForDay = this.scheduleForDay(day);
    // New interval should start where the latest interval ends.
    let latestEndTime: SimpleTime | null = null;
    for (const interval of scheduleForDay.intervals) {
      if (interval.to) {
        if (!latestEndTime || interval.to.isAfter(latestEndTime)) {
          latestEndTime = interval.to;
        }
      }
    }

    // If possible, the new interval should start at the same time the latest other interval ends.
    // When this is not possible (e.g., when an interval ends at midnight), don't set any default start/time
    // values for the new interval.
    if (!latestEndTime || latestEndTime.isBefore(SimpleTime.EndOfDayMidnight)) {
      const newStartTime = SimpleTime.clone(latestEndTime ?? SimpleTime.StartOfDayMidnight);
      const newEndTime = SimpleTime.addHours(newStartTime, 1);
      scheduleForDay.intervals.push(new TimeRange(newStartTime, newEndTime));
    } else {
      scheduleForDay.intervals.push(new TimeRange(null, null));
    }

    this.hasChanges = true;
  }

  @action
  closeWithConfirmation(): void {
    if (!this.hasChanges) {
      this.isOpen = false;
    } else {
      this.showUnsavedChangesWarning = true;
    }
  }

  @action
  closeAndReset(): void {
    this.schedule.rollback();
    this.showUnsavedChangesWarning = false;
    this.isOpen = false;
  }

  @action
  copySchedule(fromDay: Day, toDays: Set<Day>): void {
    const scheduleToCopy = this.scheduleForDay(fromDay);

    this.schedule.rules = this.scheduleRules.map((rule) => {
      const day = dayFromRule(RecurringRule.parse(rule.recurringRule));
      if (toDays.has(day)) {
        // copying to this day
        return new TrackedObject({
          enabled: scheduleToCopy.enabled,
          recurringRule: rule.recurringRule,
          intervals: new TrackedArray(scheduleToCopy.intervals.map((interval) => interval.clone())),
        });
      } else {
        // not copying to this day; leave the schedule unchanged
        return rule;
      }
    });
    this.hasChanges = true;
  }

  @action
  deleteIntervalFromDay(day: Day, intervalIndex: number): void {
    const scheduleForDay = this.scheduleForDay(day);
    scheduleForDay.intervals.splice(intervalIndex, 1);
    this.hasChanges = true;
  }

  @action
  open(): void {
    this.isOpen = true;
  }

  @action
  resetToDefault(): void {
    this.schedule.rules = this.schedule.rules.map((rule) => {
      return new TrackedObject({
        enabled: true,
        recurringRule: rule.recurringRule,
        intervals: new TrackedArray([new TimeRange(DEFAULT_START_TIME, DEFAULT_END_TIME)]),
      });
    });
    this.hasChanges = true;
  }

  @action
  scheduleForDay(day: Day): VfdScheduleRule {
    return this.scheduleRules.find((rule) => ruleMatchesDay(rule, day))!;
  }

  @action
  setIntervalEndTime(day: Day, intervalIndex: number, time: SimpleTime): void {
    const scheduleForDay = this.scheduleForDay(day);
    const interval = scheduleForDay.intervals[intervalIndex]!;
    interval.to = time;
    // if the new end time is earlier than the current start time, use it as the new start time and make the new end time one hour later (or midnight, whichever is closer)
    if (interval.from && time.isBefore(interval.from)) {
      const newEndTime = SimpleTime.addHours(time, 1);
      interval.from = time;
      interval.to = newEndTime;
    }
    this.hasChanges = true;
  }

  @action
  setIntervalStartTime(day: Day, intervalIndex: number, time: SimpleTime): void {
    const scheduleForDay = this.scheduleForDay(day);
    const interval = scheduleForDay.intervals[intervalIndex]!;
    interval.from = time;
    // if the new start time is later than the current end time, adjust the end time to one hour after new start time (or midnight, whichever is closer)
    if (interval.to && time.isAfter(interval.to)) {
      interval.to = SimpleTime.addHours(time, 1);
    }
    this.hasChanges = true;
  }

  @action
  toggleDaySchedule(day: Day, enabled: boolean): void {
    const scheduleForDay = this.scheduleForDay(day);
    scheduleForDay.enabled = enabled;
    this.hasChanges = true;
  }

  save = dropTask(async () => {
    try {
      this.schedule.rules = this.scheduleRules;
      await this.schedule.save();
      this.hasChanges = false;
      this.isOpen = false;
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      this.flashMessages.showAndHideFlash('error', 'Something went wrong. Please try again.');
    }
  });
}

// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { and } from '@ember/object/computed';
import { computed, get, set } from '@ember/object';
import { htmlSafe } from '@ember/template';

import Ember from 'ember';

// eslint-disable-next-line ember/no-classic-classes
export default Component.extend(Ember._ProxyMixin, {
  isInvalid: and('validated', 'isEmptyAndRequired'),

  fieldId: computed('index', function () {
    return htmlSafe(`_mappedFieldSelectId_${get(this, 'index')}`);
  }),

  host: computed('fieldData.value', {
    get() {
      const name = get(this, 'fieldData.value');
      return name ? { name } : undefined;
    },
    set(k, v) {
      //
      // Host is included in both userData and the host field.  Here
      // we make sure both fields are updated when the host is
      // changed.
      //
      if (this.setHost) {
        this.setHost(v);
      }

      const value = v && get(v, 'name');
      set(this, 'fieldData.value', value);

      return v;
    },
  }),

  // @heroiceric: wat?
  validated: computed(function () {
    return true;
  }),
});

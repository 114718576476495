import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type EmployeeModel from 'garaje/models/employee';

export default class AbstractSignInFieldActionsContact extends Model {
  // Relationships
  @belongsTo('employee', { inverse: null }) declare employee: AsyncBelongsTo<EmployeeModel>;

  // Attributes
  @attr('string') declare actionType: string;
}

import CalendarPermissionsStatusFragment from 'garaje/graphql/fragments/roomba/CalendarPermissionsStatusFragment';
import DeviceFragment from 'garaje/graphql/fragments/roomba/DeviceFragment';
import RoomFragment from 'garaje/graphql/fragments/roomba/RoomFragment';
import RoomStatusFragment from 'garaje/graphql/fragments/roomba/RoomStatusFragment';
import gql from 'graphql-tag';

export default gql`
  query GetRoom($id: ID!) {
    room(id: $id) {
      ...RoomFragment
      ...RoomStatusFragment
      calendarPermissionsStatus {
        ...CalendarPermissionsStatusFragment
      }
      devices {
        ...DeviceFragment
      }
    }
  }
  ${RoomFragment}
  ${RoomStatusFragment}
  ${DeviceFragment}
  ${CalendarPermissionsStatusFragment}
`;

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { alias } from 'macro-decorators';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import cardTypes from 'garaje/utils/card-types';

/**
 * @param {Function}    finalizePaymentMethod
 * @param {Boolean}     isEditingCC
 */
export default class PaymentSourceSectionComponent extends Component {
  @service('location-billing') billingService;
  @service impressions;
  @alias('billingService.paymentSource') paymentSource;

  addPaymentMethodCTAText = 'Add a payment method';

  @action
  addCreditCardClicked() {
    this.args.isEditingCC = true;
    this.logCreditCardButtonClicked.perform();
  }

  logCreditCardButtonClicked = task({ drop: true }, async () => {
    try {
      await this.impressions.postImpression.perform(IMPRESSION_NAMES.ADD_CREDIT_CARD_CLICKED);
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', e);
    }
  });

  get cardType() {
    return this.paymentSource.brand ? cardTypes[this.paymentSource.brand] : '';
  }
}

import ApplicationAdapter from './application';

export default class extends ApplicationAdapter {
  /**
   * Why do we include `url-params` here directly instead of using `store.query('', params)`?
   * When the `store.query` promise resolves, it returns an `AdapterPopulatedRecordArray`, this type
   * of array doesn't update when adding or deleting records from the `Store`.`store.findAll()` does.
   * But `store.findAll()` only supports `{include}` options params. So in order to use `sparse fieldsets` we need
   * to manually set those there.
   * https://github.com/emberjs/data/issues/6529
   */
  urlForFindAll() {
    const url = super.urlForFindAll(...arguments);
    const fields = 'fields[locations]=id';
    const includes = 'include=locations';
    return `${url}?${fields}&${includes}`;
  }
}

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from 'macro-decorators';
import { tracked } from '@glimmer/tracking';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

export default class VisitorsEditor extends Component {
  @service('location-billing') billingService;
  @service router;

  @tracked purchasing = false;

  @alias('billingService.selectedPeriod') selectedPeriod;
  @alias('billingService.currentPeriodDisplay') currentPeriodDisplay;
  @alias('billingService.subscribed') subscribed;
  @alias('billingService.creditBalance') creditBalance; // Now BC>CB
  @alias('billingService.locationSubscriptionEstimates') locationSubscriptionEstimates;
  @alias('billingService.createLocationSubscriptionEstimates.isRunning') loadingLocationSubscriptionEstimates;
  @alias('billingService.invoiceEstimatesAmountDue') invoiceEstimatesAmountDue;
  @alias('billingService.invoiceEstimatesTotal') invoiceEstimatesTotal;
  @alias('billingService.paymentSource') paymentSource; // TODO
  @alias('billingService.nextBillingAt') renewalDate;
  @alias('billingService.renewalPrice') renewalPrice;
  @alias('billingService.createSubscriptionBatchTask.isRunning') createTaskIsRunning;
  @alias('billingService.pollForSubscriptionBatchTask.isRunning') pollForSubscriptionBatchTaskIsRunning;

  get amountDueAfterCreditBalance() {
    const { invoiceEstimatesAmountDue, creditBalance } = this;
    if (!creditBalance) {
      return invoiceEstimatesAmountDue;
    } else {
      const amountDueAfterCreditBalance = invoiceEstimatesAmountDue - creditBalance;
      if (amountDueAfterCreditBalance > 0) {
        return amountDueAfterCreditBalance;
      } else {
        return 0;
      }
    }
  }

  get creditsApplied() {
    const { invoiceEstimatesAmountDue, creditBalance } = this;
    if (!creditBalance) {
      return 0;
    } else {
      const amountDueAfterCreditBalance = invoiceEstimatesAmountDue - creditBalance;
      if (amountDueAfterCreditBalance < 0) {
        return invoiceEstimatesAmountDue;
      } else {
        return amountDueAfterCreditBalance;
      }
    }
  }

  @action
  togglePeriod() {
    if (this.billingService.selectedPeriod === 'monthly') {
      this.billingService.setPeriod('yearly');
    } else {
      this.billingService.setPeriod('monthly');
    }
    this.billingService.createLocationSubscriptionEstimates.perform();
  }

  @action
  closeModal() {
    this.billingService.toggleSummaryOpened(false);

    if (this.purchasing) {
      this.router.transitionTo('location-billing');
    }
  }

  @action
  async pay() {
    this.purchasing = true;
    const subscriptionBatch = await this.billingService.createSubscriptionBatchTask
      .perform()
      .catch(throwUnlessTaskDidCancel);
    this.billingService.pollForSubscriptionBatchTask.perform(subscriptionBatch);
  }
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type CurrentAdminService from 'garaje/services/current-admin';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';

interface LocationSwitcherLocationComponentArgs {
  key?: string;
  name: string;
  active: boolean;
  onclick: () => void;
  recentlyViewed: boolean;
  disabledToEmployeesAt: Date;
}

export default class LocationSwitcherLocationComponent extends Component<LocationSwitcherLocationComponentArgs> {
  @service declare metrics: MetricsService;
  @service declare state: StateService;
  @service declare currentAdmin: CurrentAdminService;

  get key(): string {
    return this.args.key ?? '';
  }

  get hasWorkplaceSubscription(): boolean {
    return !!(this.state.workplaceSubscription || this.state.vrSubscription?.canAccessProtectLegacyFeatures);
  }

  get disabledToEmployees(): boolean {
    return !!this.args.disabledToEmployeesAt;
  }

  @action
  selectLocation(): void {
    const { currentUser, vrSubscription, currentCompany } = this.state;
    const trackingEventProperties: Record<string, string | undefined | string[]> = {
      company_id: currentCompany?.id,
      location_id: this.key,
      role: this.currentAdmin.roleNames,
      user_id: currentUser?.id,
    };

    if (vrSubscription) {
      trackingEventProperties['plan_id'] = vrSubscription.plan;
    }

    if (this.args.recentlyViewed) {
      this.metrics.trackEvent('Dashboard Location Changed Recent', trackingEventProperties);
    } else {
      this.metrics.trackEvent('Dashboard Location Changed All', trackingEventProperties);
    }

    this.args.onclick();
  }
}

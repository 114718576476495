export default function stringHash(str: string): string {
  let hash = 0;

  if (str.length == 0) {
    return `${hash}`;
  }

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }

  return `${hash}`;
}

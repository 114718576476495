import Controller from '@ember/controller';
import { service } from '@ember/service';
import type CurrentAdminService from 'garaje/services/current-admin';
import type EmptyStatePageService from 'garaje/services/empty-state-page';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type VisitorsOnboardingService from 'garaje/services/visitors-onboarding';

export default class LocationOverviewEmployeesController extends Controller {
  @service declare currentAdmin: CurrentAdminService;
  @service declare emptyStatePage: EmptyStatePageService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare visitorsOnboarding: VisitorsOnboardingService;

  get showSetupGuide(): boolean {
    return (
      (this.currentAdmin.isGlobalAdmin || this.currentAdmin.isLocationAdmin) &&
      !this.emptyStatePage.shouldShowEmployeeDirectoryEmptyPage &&
      this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper')
    );
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { underscore } from '@ember/string';
import { isEmpty } from '@ember/utils';
import eventsLogger from 'garaje/utils/decorators/events-logger';
import { next } from '@ember/runloop';
import { service } from '@ember/service';

/**
 * @param {Model<MapFeature>}           feature
 * @param {Function}                    removeFeature
 * @param {Function}                    back
 * @param {Array}                       floorMapFeatures
 * @param {Boolean}                     isDraftMap
 */
@eventsLogger
class PointOfInterestForm extends Component {
  @service featureFlags;
  @service abilities;

  @tracked POINameError = [];
  @tracked POINotesError = [];
  @tracked inputElement;

  get hasPoiError() {
    return !!(this.POINameError.length || this.POINotesError.length);
  }

  get cannotManagePOI() {
    return this.abilities.cannot('manage-point-of-interest desks');
  }

  @action
  inputInserted(element) {
    this.element = element;
    this.focusInput();
  }

  @action
  focusInput() {
    next(() => {
      this.element.focus();
    });
  }

  setPoiHasError() {
    const { feature } = this.args;

    if (this.hasPoiError) {
      set(feature, 'hasError', true);
    } else {
      set(feature, 'hasError', false);
    }
  }

  @action
  validateNameField() {
    const { feature } = this.args;
    const changedAttrs = feature.changedAttributes();

    if (isEmpty(feature.name) && (Object.keys(changedAttrs).includes('name') || feature.hasError || !feature.isValid)) {
      this.POINameError = ['Please enter a name'];
    } else if (feature.name?.length > 40) {
      this.POINameError = [''];
    } else {
      this.POINameError = [];
    }
    this.setPoiHasError();
  }

  validateNotesField(feature) {
    if (feature.notes?.length > 150) {
      this.POINotesError = [''];
    } else {
      this.POINotesError = [];
    }
    this.setPoiHasError();
  }

  @action
  toggleEnabled(state) {
    const { feature, isDraftMap } = this.args;
    set(feature, 'enabled', state);

    if (state) {
      this.logEnabled({
        resource_type: underscore(feature.type),
        isDraft: isDraftMap,
      });
    } else {
      this.logDisabled({
        resource_type: underscore(feature.type),
        isDraft: isDraftMap,
      });
    }
  }

  @action
  onPOINotesChange(note) {
    const { feature } = this.args;
    set(feature, 'notes', note.target.value);
    this.validateNotesField(feature);
  }

  @action
  onPOINameChange(name) {
    const { feature, updateResourceOverview } = this.args;
    set(feature, 'name', name);

    if (updateResourceOverview) {
      updateResourceOverview('update', feature);
    }
    this.validateNameField();
  }
}

export default PointOfInterestForm;

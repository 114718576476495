// These are the ids of the plans in envoy-web
export default function (planName, app) {
  if (planName === 'standard' && app === 'visitors') {
    return 'standard-1';
  } else if (planName === 'premium' && app === 'deliveries') {
    return 'deliveries-standard';
  } else if (planName === 'standard' && app === 'rooms') {
    return 'rooms-standard';
  } else if (planName === 'standard' && app === 'desks') {
    return 'desks-standard';
  } else if (app === 'empxp') {
    return `empxp-${planName}`;
  }

  return planName;
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { scheduleOnce } from '@ember/runloop';
import iframeWriteDoc from 'garaje/utils/iframe-write-doc';

/**
 * @param {Model}                 visitorSurveyConfiguration
 * @param {Function}              onPreview
 */
export default class VisitorEmailsVisitorSurveyVsPreviewingEmailView extends Component {
  @service flashMessages;

  @tracked previewHTML;
  @tracked subject;

  constructor() {
    super(...arguments);
    this.loadEmailPreviewHTMLTask.perform();
  }

  @task
  *loadEmailPreviewHTMLTask() {
    try {
      const { body, subject } = yield this.args.visitorSurveyConfiguration.getEmailviewPreview();
      this.previewHTML = body;
      this.subject = subject;
      scheduleOnce('afterRender', this, iframeWriteDoc, 'email-iframe', this.previewHTML);
      scheduleOnce('afterRender', this, this.args.onPreview, 'email');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', e);
      throw e;
    }
  }
}

import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type BadgeModel from 'garaje/models/badge';
import type ConfigModel from 'garaje/models/config';
import type DeviceModel from 'garaje/models/device';
import type FlowModel from 'garaje/models/flow';
import type LocationModel from 'garaje/models/location';
import type PhotoPageModel from 'garaje/models/photo-page';
import type PrinterModel from 'garaje/models/printer';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import type SignInFieldPageModel from 'garaje/models/sign-in-field-page';
import type UiHookModel from 'garaje/models/ui-hook';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

import type { VisitorsSettingsRouteModel } from '../../../route';
import type { VisitorTypesFlowRouteModel } from '../route';

import type VisitorsSettingsVisitorTypesFlowBadgeController from './controller';

export interface VisitorsSettingsVisitorTypesFlowBadgeRouteModel {
  badge: BadgeModel;
  config: ConfigModel;
  currentLocation: LocationModel;
  enableBadgeCustomContent: boolean;
  flow: FlowModel;
  ipads: RecordArray<DeviceModel>;
  photoPage: PhotoPageModel;
  printer: RecordArray<PrinterModel>;
  signInFieldPage: SignInFieldPageModel;
  signInFields: ArrayProxy<SignInFieldModel>;
  uiHooks: RecordArray<UiHookModel> | UiHookModel[];
  vrSubscription: StateService['vrSubscription'];
}

export default class VisitorsSettingsVisitorTypesFlowBadgeRoute extends Route {
  declare controller: VisitorsSettingsVisitorTypesFlowBadgeController;

  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;
  @service declare store: StoreService;
  @service declare transitionConfirm: TransitionConfirmService;

  model(): Promise<VisitorsSettingsVisitorTypesFlowBadgeRouteModel> {
    const { flow } = <VisitorTypesFlowRouteModel>this.modelFor('visitors.settings.visitor-types.flow');
    const { ipads } = <VisitorsSettingsRouteModel>this.modelFor('visitors.settings');
    const { currentLocation, vrSubscription } = this.state;
    const config = currentLocation.config;
    const signInFieldPage = flow.signInFieldPage;
    const signInFields = signInFieldPage.then((p) => p.signInFields);
    const badge = flow.badge;
    const printer = this.store.query('printer', { filter: { location: currentLocation.id } });
    const photoPage = flow.photoPage;
    const enableBadgeCustomContent = this.featureFlags.isEnabled('badge-custom-content');
    const uiHooks = enableBadgeCustomContent
      ? this.store.query('ui-hook', {
          location: currentLocation.id,
          triggerNames: ['badge_text_field', 'badge_barcode', 'badge_qr_code'],
        })
      : [];

    return hash({
      badge,
      config,
      currentLocation,
      enableBadgeCustomContent,
      flow,
      ipads,
      photoPage,
      printer,
      signInFieldPage,
      signInFields,
      uiHooks,
      vrSubscription,
    });
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const { badge } = this.controller.model;
    const confirmTask = this.transitionConfirm.displayConfirmTask;

    if (<boolean>(<unknown>badge.hasDirtyAttributes)) {
      void confirmTask.perform(transition, {
        continue() {
          badge.rollbackAttributes();
        },
      });
    }
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set, get } from '@ember/object';

/**
 * @param {object}              model             either an invite or an entry
 * @param {Array}               printers          available printers
 * @param {Function}            reprintBadge      method to send the entry and printer
 * @param {Function}            displayModal      set to true to display modal
 */
export default class ReprintBadgeModal extends Component {
  @tracked selectedPrinter;

  get printerOptions() {
    const options = this.args.printers;
    options.forEach((option) => {
      set(option, 'disabled', get(option, 'status') !== 'connected');
    });
    return options;
  }

  @action
  closeModal() {
    this.selectedPrinter = null;
    this.args.displayModal(false);
  }

  @action
  selectPrinter(printer) {
    this.selectedPrinter = printer;
  }

  @action
  reprintBadge() {
    const { reprintBadge, model } = this.args;
    reprintBadge(model, this.selectedPrinter);
    this.closeModal();
  }
}

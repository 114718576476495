import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type StatsigService from 'garaje/services/statsig';

export default class PageTemplate extends Component<{
  headerTitle: string;
  headerInfo: string;
  currentStep: string;
  totalStepCount: string;
  demoName: string;
  isUserLoggedIn: boolean;
  isDemoInstance: boolean;
}> {
  @service declare statsig: StatsigService;

  @action sendPageViewEvent(): void {
    if (this.args.isUserLoggedIn && !this.args.isDemoInstance) {
      this.statsig.logEvent(`kiosk-demo-${this.args.demoName}-page-viewed`, (this.args.currentStep + 1).toString());
    }
  }
}

import Model, { attr, hasMany, type SyncHasMany } from '@ember-data/model';
import type ConnectVisitorContactModel from 'garaje/models/connect-visitor-contact';
import type TenantRoleModel from 'garaje/models/tenant-role';

export default class ConnectEmployeeModel extends Model {
  @attr('string') declare name: string;
  @attr('string') declare profilePictureUrl: string;

  @hasMany('tenant-role') declare tenantRoles: SyncHasMany<TenantRoleModel>;
  @hasMany('connect-visitor-contact') declare connectVisitorContacts: SyncHasMany<ConnectVisitorContactModel>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'connect-employee': ConnectEmployeeModel;
  }
}

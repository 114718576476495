import Route from '@ember/routing/route';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';

export default class DeliveriesLandingPageRoute extends Route {
  @service state;
  @service router;

  titleToken = 'Deliveries';

  beforeModel() {
    const { workplaceSubscription } = this.state;
    if (!!this.state.features?.canAccessDeliveriesApplication || isPresent(workplaceSubscription)) {
      return this.router.transitionTo('deliveries.index');
    }
  }
}

import { isBlank } from '@ember/utils';

const containsProtocol = new RegExp(/^https?:\/\//);

export default function getHostname(url) {
  if (isBlank(url) || typeof url !== 'string') {
    return null;
  }

  url = url.trim();

  if (!containsProtocol.test(url)) {
    url = `http://${url}`;
  }

  const elem = document.createElement('a');
  elem.href = url;

  const { hostname } = elem;
  const { port } = elem;

  // port 0 comes from our friend phantomjs >:(
  return ['', '0', '80', '443'].includes(port) ? hostname : `${hostname}:${port}`;
}

import ApplicationSerializer from 'garaje/serializers/application';

export default class VfdConfigurationSerializer extends ApplicationSerializer {
  attrs = {
    locationName: {
      serialize: false,
    },
    kioskCount: {
      serialize: false,
    },
  };
}

// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'vfd-configuration': VfdConfigurationSerializer;
  }
}

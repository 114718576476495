import Component from '@glimmer/component';
import { action } from '@ember/object';
import { run } from '@ember/runloop';
import config from 'garaje/config/environment';

const testing = config.environment === 'test';

/**
 * @param String language the language mode
 * @param String value the content to load in the editor
 * @param function onChange action to call when value changes
 * @param Boolean readOnly
 */
export default class extends Component {
  editor;

  @action
  initEditor(element) {
    // eslint-disable-next-line no-undef
    this.editor = new Copenhagen.Editor({ language: this.args.language, rows: 27 });
    this.editor.open(element, false);
    this.editor.setValue(this.args.value);
    this.editor.setReadOnly(this.args.readOnly ?? false);
    this.editor.removeHotkey('ctrl+f'); // disable find and replace dialog
    this.editor.removeHotkey('ctrl+s'); // disable save with hotkey

    if (typeof this.args.onChange === 'function') {
      this.editor.on('change', (_editor, value) => {
        run(() => this.args.onChange(value));
      });
    }

    if (testing) {
      // for test hackery
      element.CodeEditor = {
        setValue: (value) => {
          this.updateValue(element, [value]);
          this.args.onChange?.(value);
        },
        getValue: () => this.editor.getValue(),
      };
    }

    return () => this.editor.close();
  }

  @action
  updateValue(element, [value]) {
    this.editor.setValue(value);
  }
}

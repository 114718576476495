import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';

export default class MapsLiveIndexRoute extends Route {
  @service declare router: RouterService;

  beforeModel(transition: Transition): void {
    const { floor_id } = transition.to.params;
    if (floor_id) {
      void this.router.transitionTo('spaces.maps.live', floor_id);
    } else {
      void this.router.transitionTo('spaces.maps.live');
    }
  }
}

/**
 * Used to explicitly map an ED Model to a serializer without relying on creating a
 * serializer with the same name.
 *
 * @param {String} serializerName
 * @returns Serializer
 */
export default function (serializerName) {
  return function (target) {
    target.serializerName = serializerName;
    return target;
  };
}

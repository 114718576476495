import InviteFragment from 'garaje/graphql/fragments/employee-schedule/InviteFragment';
import ReservationFragment from 'garaje/graphql/fragments/employee-schedule/ReservationFragment';
import SelfCertifyFragment from 'garaje/graphql/fragments/employee-schedule/SelfCertifyFragment';
import gql from 'graphql-tag';

export default gql`
  fragment RegistrationDateFragment on RegistrationDate {
    available
    date
    hasRequiredDocumentApproval
    maxOccupancy
    peopleRegistered
    requiredDocumentApprovalExpiresAt
    requiredDocumentMessage
    reservation {
      ...ReservationFragment
    }
    screeningCard {
      ... on Invite {
        ...InviteFragment
      }
      ... on SelfCertify {
        ...SelfCertifyFragment
      }
    }
  }
  ${ReservationFragment}
  ${InviteFragment}
  ${SelfCertifyFragment}
`;

/* eslint-disable @typescript-eslint/no-unsafe-call */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type StatsigService from 'garaje/services/statsig';

export default class RecommendedResourcesComponent extends Component {
  @service declare statsig: StatsigService;

  @action
  logStatsigEvent(event_name: string): void {
    this.statsig.logEvent(event_name);
  }
}

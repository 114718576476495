import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { Task } from 'ember-concurrency';
import type CompanyBillingAddressModel from 'garaje/models/company-billing-address';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';

interface Address {
  line1: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

interface BillingAddressArgs {
  addJustifyBetween: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchEstimateTask: Task<void, any>;
  confirmAddressValidity?: () => void;
}

export default class BillingAddress extends Component<BillingAddressArgs> {
  @service declare store: Store;
  @service declare state: StateService;
  @service declare flashMessages: FlashMessagesService;
  @service declare statsig: StatsigService;

  @tracked address: CompanyBillingAddressModel | null = null;
  @tracked addressString: string = '';

  @tracked selectedAddress: Address = { line1: '', city: '', state: '', zip: '', country: '' };
  @tracked revertedAddress: Address | null = null;
  @tracked isEditing: boolean = false;
  @tracked isSaving: boolean = false;
  @tracked hasSelectedDropdown: boolean = false;

  @tracked errorMessage: string = '';

  constructor(...args: unknown[]) {
    //@ts-ignore
    super(...args);
    void this.loadBillingAddress();
  }

  @action
  async loadBillingAddress(): Promise<void> {
    try {
      const companyId = this.state.currentCompany.id;
      this.address = await this.store.findRecord('company-billing-address', companyId);
      this.updateAddressString();

      if (this.args.confirmAddressValidity) {
        if (this.address && this.address.zip) {
          this.args.confirmAddressValidity();
        } else {
          this.errorMessage = 'Address should include zip code';
        }
      }
    } catch (_e) {
      // eslint-disable-next-line no-console
      console.error(_e);
    }
  }

  @action updateAddressString(): void {
    if (this.address) {
      this.addressString =
        `${this.address.line1 ? this.address.line1 + ', ' : ''}` +
        `${this.address.city ? this.address.city + ', ' : ''}` +
        `${this.address.state ? this.address.state + ' ' : ''}` +
        `${this.address.zip ? this.address.zip + ', ' : ''}` +
        `${this.address.country ? this.address.country : ''}`;
    }
  }

  @action toggleIsEditing(): void {
    if (this.isEditing) {
      this.statsig.logEvent('billing-address-cancel-edit-clicked');
      if (this.revertedAddress) {
        Object.assign(this.address ?? {}, this.revertedAddress);
        this.updateAddressString();
      } else {
        this.address = null;
      }
    } else {
      this.statsig.logEvent('billing-address-add-or-edit-clicked');
      if (this.address) {
        this.revertedAddress = {
          line1: this.address.line1 ?? '',
          city: this.address.city ?? '',
          state: this.address.state ?? '',
          zip: this.address.zip ?? '',
          country: this.address.country ?? '',
        };
      }
      this.hasSelectedDropdown = false;
    }
    this.isEditing = !this.isEditing;
  }

  @action handleAddressSelect(value: string): void {
    this.statsig.logEvent('billing-address-dropdown-item-selected');
    this.addressString = value;
  }

  @action getPlaceInfo(value: Address): void {
    if (value.zip) {
      this.selectedAddress = value;
      this.errorMessage = '';
      this.hasSelectedDropdown = true;
    } else {
      this.errorMessage = 'Address should include zip code';
    }
  }

  @action async handleSaveClicked(): Promise<void> {
    this.isSaving = true;
    try {
      this.statsig.logEvent('billing-address-save-clicked');
      if (this.address) {
        Object.assign(this.address, this.selectedAddress);
        await this.address.save();
        this.flashMessages.showAndHideFlash('success', 'Billing address updated');
        if (this.args.fetchEstimateTask) {
          void this.args.fetchEstimateTask.perform();
        }
        this.isEditing = false;
        this.isSaving = false;
        if (this.args.confirmAddressValidity) {
          this.args.confirmAddressValidity();
        }
      }
    } catch (_e) {
      // eslint-disable-next-line no-console
      console.error(_e);
      this.flashMessages.showAndHideFlash('error', 'Invalid or incomplete address');
      this.isSaving = false;
    }
  }
}

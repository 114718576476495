import type ArrayProxy from '@ember/array/proxy';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type PrinterConnectionModel from 'garaje/models/printer-connection';

interface DevicesPrinterConnectionSelectArgs {
  context?: string;
  options?: ArrayProxy<PrinterConnectionModel>;
  onCreate(): void;
  onChange(printerConnection: PrinterConnectionModel): void;
  onDelete(printerConnection: PrinterConnectionModel): void;
}

export default class DevicesPrinterConnectionSelect extends Component<DevicesPrinterConnectionSelectArgs> {
  @tracked deletePrinterConnection: PrinterConnectionModel | null = null;

  stopPropagation(e: Event): void {
    e.stopPropagation();
  }

  @action
  confirmPrinterConnectionDelete(printerConnection: PrinterConnectionModel): void {
    this.deletePrinterConnection = printerConnection;
  }

  @action
  confirmPrinterConnectionDeletion(): void {
    const { deletePrinterConnection } = this;

    if (deletePrinterConnection) {
      this.args.onDelete?.(deletePrinterConnection);
    }

    this.deletePrinterConnection = null;
  }

  @action
  cancelPrinterConnectionDeletion(): void {
    this.deletePrinterConnection = null;
  }
}

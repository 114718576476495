import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { scheduleOnce } from '@ember/runloop';
import iframeWriteDoc from 'garaje/utils/iframe-write-doc';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

/**
 * @param {Model}               visitorGuide
 * @param {Function}            onPreview
 */
export default class VisitorEmailsVisitorGuideVgPreviewing extends Component {
  @service flashMessages;

  @tracked previewHTML;

  constructor() {
    super(...arguments);
    this.loadPreviewHTMLTask.perform();
  }

  @task
  *loadPreviewHTMLTask() {
    try {
      const visitorGuide = yield this.args.visitorGuide;
      const { body } = yield visitorGuide.getHTMLPreview();
      this.previewHTML = body;
      scheduleOnce('afterRender', this, iframeWriteDoc, 'iphone-iframe', this.previewHTML);
      scheduleOnce('afterRender', this, this.args.onPreview);
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', e);
    }
  }
}

import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type PluginInstallModel from 'garaje/models/plugin-install';
import type CurrentAdminService from 'garaje/services/current-admin';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

export default class PluginInstallAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;

  declare model: PluginInstallModel;

  /**
   * pluginInstalls have either a company relationship or a location relationship. They don't have both.
   * If they have a company relationship, then it's a company-wide integration that only global admins
   * should be able to configure. If it has a location relationship, then both global admins
   * and location admins should be able to configure.
   */
  get canConfigure(): boolean {
    const company = this.model.belongsTo('company').value();
    const location = this.model.belongsTo('location').value();
    const { roleNames: roles } = this.currentAdmin;

    if (company) {
      return roles.includes(GLOBAL_ADMIN);
    } else if (location) {
      return isPresent(_intersection([GLOBAL_ADMIN, LOCATION_ADMIN], roles));
    }

    return false;
  }
}

import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type DeviceModel from 'garaje/models/device';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import { App } from 'garaje/utils/enums';
import { GLOBAL_ADMIN, ZONE_ADMIN } from 'garaje/utils/roles';
import { Permission } from 'garaje/utils/ui-permissions';
import _intersection from 'lodash/intersection';

// These roles can delete devices in Connect
const CONNECT_DELETE_ROLES = [GLOBAL_ADMIN, ZONE_ADMIN];

// These roles can update devices in Connect
const CONNECT_UPDATE_ROLES = [GLOBAL_ADMIN, ZONE_ADMIN];

export default class DeviceAbility extends Ability {
  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;

  /**
   * `context` is used to tell where this ability is being used, and therefore what method should be used
   * to check permissions. For the moment, this ability is used by both Visitors and Connect; each has different
   * rules for whether a user can perform an action. (For Visitors it requires a certain Authz role at the
   * current location; for Connect, it's based on legacy role names, but even with Authz, the check will need
   * to be made at the current _zone_ and not current _location_, so it will still be different.)
   */
  declare context?: App;

  declare model?: DeviceModel;

  get canVisit(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_DEVICE_READ);
  }

  get canCreate(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_DEVICE_CREATE);
  }

  get canDelete(): boolean {
    if (this.context === App.CONNECT) {
      const { roleNames } = this.currentAdmin;
      return isPresent(_intersection(CONNECT_DELETE_ROLES, roleNames));
    }

    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_DEVICE_DELETE);
  }

  get canUpdate(): boolean {
    if (this.context === App.CONNECT) {
      const { roleNames } = this.currentAdmin;
      return isPresent(_intersection(CONNECT_UPDATE_ROLES, roleNames));
    }

    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_DEVICE_UPDATE);
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';

import type { PrinterConnectionsRouteModel } from '../route';

export default class VisitorsDevicesPrintersConnectionsIndex extends Route {
  @service declare router: RouterService;

  model(): PrinterConnectionsRouteModel {
    return this.modelFor('visitors.devices.printers.connections') as PrinterConnectionsRouteModel;
  }

  afterModel(model: PrinterConnectionsRouteModel): void {
    const printerConnection = model.printerConnections.firstObject;

    if (printerConnection) {
      void this.router.transitionTo('visitors.devices.printers.connections.show', printerConnection.id);
    } else {
      void this.router.transitionTo('visitors.devices.printers.connections.new');
    }
  }
}

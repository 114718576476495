import type ArrayProxy from '@ember/array/proxy';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type EmployeeModel from 'garaje/models/employee';
import type EmployeeLocationModel from 'garaje/models/employee-location';
import type UserDocumentModel from 'garaje/models/user-document';
import type UserDocumentTemplateModel from 'garaje/models/user-document-template';
import type UserDocumentTemplateConfigurationModel from 'garaje/models/user-document-template-configuration';
import LocationOverviewEmployeesDirectoryEmployeeRoute from 'garaje/pods/location-overview/employees/directory/employee/route';

interface EmployeesDirectoryEmployeeRouteModel {
  employee: EmployeeModel;
  employeeLocations: ArrayProxy<EmployeeLocationModel>;
  userDocuments: Array<UserDocumentModel> | ArrayProxy<UserDocumentModel>;
  userDocumentTemplates: Array<UserDocumentTemplateModel>;
  userDocumentTemplateConfigurations: Array<UserDocumentTemplateConfigurationModel>;
}

export default class EmployeesDirectoryEmployeeRoute extends LocationOverviewEmployeesDirectoryEmployeeRoute {
  @service declare abilities: AbilitiesService;
  @service declare store: StoreService;

  controllerName = 'location-overview/employees/directory/employee';
  templateName = 'location-overview/employees/directory/employee';

  async model({ employee_id }: { employee_id: string }): Promise<EmployeesDirectoryEmployeeRouteModel> {
    const employee = await this.store.findRecord('employee', employee_id);
    const employeeLocations = await employee.employeeLocations;
    const user = await employee.user;

    const { userDocumentTemplates, userDocumentTemplateConfigurations } = <
      {
        userDocumentTemplates: Array<UserDocumentTemplateModel>;
        userDocumentTemplateConfigurations: Array<UserDocumentTemplateConfigurationModel>;
      }
    >this.modelFor('employees.directory');

    const templateIds: Array<string> =
      userDocumentTemplateConfigurations?.map((config: UserDocumentTemplateConfigurationModel) =>
        config.belongsTo('userDocumentTemplate').id(),
      ) ?? [];

    const model: EmployeesDirectoryEmployeeRouteModel = {
      employee,
      employeeLocations,
      userDocuments: [],
      userDocumentTemplates,
      userDocumentTemplateConfigurations,
    };

    if (this.abilities.can('review user-document-links') && isPresent(user) && templateIds.length) {
      model.userDocuments = await this.store.query('user-document', {
        include: 'user-document-template,user-document-links,user-document-location-contexts',
        filter: {
          user: user.id,
          'user-document-template': templateIds,
        },
      });
    }

    return model;
  }
}

import ApplicationSerializer from './application';
import _isEqual from 'lodash/isEqual';

export default class mapFeatureSerializer extends ApplicationSerializer {
  normalize(_mapFeature, hash) {
    const { attributes } = hash;
    const { type: geometryType, coordinates } = attributes.geometry;
    const { length, 0: start, [length - 1]: end } = coordinates;

    if (geometryType === 'Polygon' && _isEqual(start, end)) {
      attributes.geometry.coordinates = coordinates.slice(0, -1);
    }

    return super.normalize(...arguments);
  }

  serialize() {
    const payload = super.serialize(...arguments);
    const { type: geometryType, coordinates } = payload.data.attributes.geometry;
    const { length, 0: start, [length - 1]: end } = coordinates;

    if (geometryType === 'Polygon' && !_isEqual(start, end)) {
      payload.data.attributes.geometry.coordinates = [...coordinates, start];
    }

    return payload;
  }
}

export const POLYGON_BASE_OPTIONS = {
  draggable: true,
  weight: 4,
  fillOpacity: 0.6,
  opacity: 1,
};

export const POLYGON_COLORS = {
  error: '#FA4338',
  edit: {
    border: '#4E4EDA', // gem-60
    fill: '#E0E8FF', // gem-10
  },
  placed: {
    border: '#4EC176', // cilantro-30
    fill: '#C7F5D2', // cilantro-10
  },
  disabled: {
    border: '#C9CBD4', // carbon-20
    fill: '#EBEBF0', // carbon-10
  },
  enabled: {
    border: '#4EC176', // cilantro-30
    fill: '#C7F5D2', // cilantro-10
  },
  unavailable: {
    border: '#FA4338', // red-40
    fill: '#FA433840', // red-40 (transparent)
  },
};

export const POLYGON_DRAWING_OPTIONS = {
  allowIntersection: false,
  drawError: {
    color: POLYGON_COLORS.error,
  },
  shapeOptions: {
    color: POLYGON_COLORS.edit.border,
    fill: false,
    ...POLYGON_BASE_OPTIONS,
  },
};

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type AreaMapModel from 'garaje/models/area-map';
import type StateService from 'garaje/services/state';

export interface MapsDraftsAreaMapModel {
  areaMap: AreaMapModel;
}

export default class MapsDraftsAreaMapRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: StoreService;

  beforeModel(): void {
    if (this.abilities.cannot('visit drafts for maps')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  async model({ area_map_id }: { area_map_id: number }): Promise<MapsDraftsAreaMapModel> {
    const areaMap = await this.store.findRecord('area-map', area_map_id, {
      reload: true,
      filter: {
        location: this.state.currentLocation?.id,
        'include-draft-area-maps': true,
      },
      include: 'drafts,map-floors',
    });
    return { areaMap };
  }
}

import Controller from '@ember/controller';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import type CompanyModel from 'garaje/models/company';
import type LocationModel from 'garaje/models/location';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import { alias } from 'macro-decorators';

export default class ProfileNotificationsController extends Controller {
  @service declare flashMessages: FlashMessagesService;
  @service declare state: StateService;

  get company(): CompanyModel {
    return this.state.currentCompany;
  }

  @alias('state.vrSubscription') declare vrSubscription: StateService['vrSubscription'];

  saveTask = dropTask(
    async <T extends keyof LocationModel>(
      model: LocationModel,
      property: T,
      value: LocationModel[T],
    ): Promise<void> => {
      model[property] = value;

      if (property === 'pushEnabled') {
        model.invitePushEnabled = <boolean>value;
      }

      try {
        await model.save();

        this.flashMessages.showAndHideFlash('success', 'Saved!');
      } catch (_e) {
        model.rollbackAttributes();
      }
    },
  );
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';

export default class VirtualFrontDeskSettingsRoute extends Route {
  @service declare router: RouterService;

  redirect(): void {
    void this.router.replaceWith('virtual-front-desk.settings.call-operations');
  }
}

import LocationFragment from 'garaje/graphql/fragments/roomba/LocationFragment';
import gql from 'graphql-tag';

export default gql`
  mutation UpdateRoomsLocationShouldEmailGlobalAdmins($locationId: ID!, $shouldEmailGlobalAdmins: Boolean!) {
    updateRoomsLocationShouldEmailGlobalAdmins(
      locationId: $locationId
      shouldEmailGlobalAdmins: $shouldEmailGlobalAdmins
    ) {
      ...LocationFragment
    }
  }
  ${LocationFragment}
`;

import $ from 'jquery';
import { cancel, later, run } from '@ember/runloop';
import { Promise as EmberPromise } from 'rsvp';

export default function childWindow(url, width, height) {
  return new EmberPromise(function (resolve, reject) {
    const screenX = typeof window.screenX !== 'undefined' ? window.screenX : window.screenLeft;
    const screenY = typeof window.screenY !== 'undefined' ? window.screenY : window.screenTop;
    const outerWidth = typeof window.outerWidth !== 'undefined' ? window.outerWidth : document.body.clientWidth;
    const outerHeight =
      typeof window.outerHeight !== 'undefined' ? window.outerHeight : document.body.clientHeight - 22;
    const left = parseInt(screenX + (outerWidth - width) / 2, 10);
    const top = parseInt(screenY + (outerHeight - height) / 2.5, 10);
    const features = `width=${width},height=${height},left=${left},top=${top}`;

    const newWindow = window.open(url, 'Envoy Integration', features);

    if (window.focus) {
      newWindow.focus();
    }

    // Check whether the user closes the OAuth window
    const windowClosedHandler = function () {
      if (newWindow.closed) {
        cancel(checkWindowClosedTimer);
        reject();
      } else {
        checkWindowClosedTimer = later(windowClosedHandler, 500);
      }
    };

    let checkWindowClosedTimer = later(windowClosedHandler, 500);

    $(window).on('message', function (event) {
      cancel(checkWindowClosedTimer);
      const data = JSON.parse(event.originalEvent.data);
      if (data.status === 'oauth.connected') {
        run(function () {
          resolve(data);
        });
      } else {
        run(function () {
          reject(data);
        });
      }
    });
  });
}

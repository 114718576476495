import { typeOf } from '@ember/utils';
import type Model from '@ember-data/model';
import type Store from '@ember-data/store';
import type PrinterPasscodeModel from 'garaje/models/printer-passcode';
import { type SingleResponse } from 'jsonapi/response';

import ApplicationSerializer from './application';

export default class PrinterPasscode extends ApplicationSerializer {
  attrs = {
    code: {
      serialize: false,
    },
    factoryRecord: {
      serialize: false,
    },
    consumedAt: {
      serialize: false,
    },
    expiresAt: {
      serialize: false,
    },
    printerConnection: {
      serialize: false,
    },
    createdAt: {
      serialize: false,
    },
    user: {
      serialize: false,
    },
  };

  normalizeSingleResponse(
    store: Store,
    primaryModelClass: Model,
    payload: SingleResponse<PrinterPasscodeModel>,
    id: string | number,
    requestType: string,
  ): ReturnType<ApplicationSerializer['normalizeSingleResponse']> {
    /**
     * GET /a/visitors/api/v3/printer-passcodes/:id returns attributes like:
     *
     * "attributes": {
     *   "code": null,
     *   "expires-at": "2023-02-01T03:24:18.712Z",
     *   "consumed-at": null
     * }
     *
     * That is: the `code` attribute is obfuscated in the DB and always returns as `null`
     * when fetched. This normalization logic retains the current one-time passcode string
     * on the record in the store to prevent it from being lost and removed from the UI.
     */

    if (requestType === 'findRecord' && id) {
      const code: string = store.peekRecord('printer-passcode', id)?.code ?? '';

      if (code && typeOf(payload?.data?.attributes) === 'object') {
        payload.data.attributes = { ...payload.data.attributes, code };
      }
    }

    return super.normalizeSingleResponse(store, primaryModelClass, payload, id, requestType);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'printer-passcode': PrinterPasscode;
  }
}

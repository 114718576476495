import { action } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type PrinterModel from 'garaje/models/printer';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

const SORT_COL_TO_FIELD_NAME = {
  Device: 'model',
  Name: 'name',
  Mode: 'connection_type',
  'Printer IP address': 'ip',
  Location: 'device_config.location.name',
};

const ORDER_DESC_VALS = ['Z-A'];
const DEFAULT_PAGE_SIZE = 30;

export interface LocationOverviewGlobalDevicesPrintersRouteModel {
  printers: RecordArray<PrinterModel>;
  params: {
    sort?: string;
    filter?: {
      location?: string;
      connectionType?: string;
      status?: string;
      model?: string;
    };
    page?: {
      offset: number;
      limit: number;
    };
    include?: string;
  };
}

interface LocationOverviewGlobalDevicesPrintersRouteParams {
  sortBy: keyof typeof SORT_COL_TO_FIELD_NAME;
  sortDirection: string;
  pageNumber: number;
  locationId?: string;
  printerMode?: string;
  printerStatus: string;
  printerModel: string;
}

export default class LocationOverviewGlobalDevicesPrintersRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  queryParams = {
    sortBy: {
      refreshModel: true,
    },
    sortDirection: {
      refreshModel: true,
    },
    pageNumber: {
      refreshModel: true,
    },
    locationId: {
      refreshModel: true,
    },
    printerMode: {
      refreshModel: true,
    },
    printerStatus: {
      refreshModel: true,
    },
    printerModel: {
      refreshModel: true,
    },
  };

  beforeModel(): void {
    if (this.abilities.cannot('view-nav-item global-printers')) {
      this.router.transitionTo('unauthorized');
    }
  }

  model({
    sortBy,
    sortDirection,
    pageNumber,
    locationId,
    printerMode,
    printerStatus,
    printerModel,
  }: LocationOverviewGlobalDevicesPrintersRouteParams): Promise<LocationOverviewGlobalDevicesPrintersRouteModel> {
    const params: LocationOverviewGlobalDevicesPrintersRouteModel['params'] = {};
    const pageSize = DEFAULT_PAGE_SIZE;
    const orderDescending = ORDER_DESC_VALS.includes(sortDirection);
    const sortOrder = orderDescending ? '-' : '';
    const sortProp = SORT_COL_TO_FIELD_NAME[sortBy];
    const offset = pageSize * pageNumber - pageSize;

    if (sortBy) {
      params.sort = `${sortOrder}${sortProp}`;
    }

    params.filter = {};

    if (locationId) {
      params.filter.location = locationId;
    }

    if (printerMode && printerMode !== '') {
      params.filter.connectionType = printerMode;
    }
    if (printerStatus && printerStatus !== '') {
      params.filter.status = printerStatus;
    }
    if (printerModel && printerModel !== '') {
      params.filter.model = printerModel;
    }

    params.page = {
      offset,
      limit: pageSize,
    };

    params.include = 'devices,location';

    const printers = this.store.query('printer', params);

    return hash({
      printers,
      params,
    });
  }

  @action
  refreshModel(): void {
    this.refresh();
  }
}

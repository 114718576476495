import gql from 'graphql-tag';

export default gql`
  query GetSpaceSaverConfig {
    spaceSaverConfig {
      checkInExcludedRooms {
        id
        name
        locationId
      }
      roomResizerExcludedRooms {
        room {
          id
          name
          locationId
        }
      }
      roomResizerExcludedOrganizers
    }
  }
`;

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { alias } from 'macro-decorators';
import { DEFAULT_FLOW_NAME } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

export default class WorkplaceSettingsEmployeesRegistrationController extends Controller {
  @service abilities;
  @service flow;
  @service state;
  @service currentAdmin;
  @service router;
  @service workplaceMetrics;
  @service store;
  @service flashMessages;

  @tracked copied = '';
  @tracked name = DEFAULT_FLOW_NAME.EMPLOYEE_SCREENING;

  @alias('flow.openedModal') openedModal;
  @alias('flow.selectedLocation') selectedLocation;
  @alias('flow.selectedFlow') selectedFlow;
  @alias('flow.locations') selectedLocations;
  @alias('flow.activeEmployeeScreeningFlow') activeEmployeeScreeningFlow;
  @alias('flow.globalEmployeeScreeningFlows') globalEmployeeScreeningFlows;
  @alias('currentAdmin.isGlobalAdmin') isGlobalAdmin;
  @alias('currentAdmin.isLocationAdmin') isLocationAdmin;

  @action
  closeModal() {
    this.openedModal = '';
  }

  @action
  async create() {
    this.openedModal = '';
    // eslint-disable-next-line no-console
    await this.newEmployeeScreeningFlow.perform().catch((e) => console.log(`new Employee Screening error: ${e}`));
  }

  @action
  confirmSelection() {
    this.flow.assignFlowToLocation.perform();
  }

  @action
  confirmDelete() {
    this.flow.confirmDelete();
    this.transitionToRoute('workplace.settings.employees.registration'); // eslint-disable-line ember/no-deprecated-router-transition-methods
  }

  @action
  autoGenerateEmployeeFlowName() {
    const { name, globalEmployeeScreeningFlows } = this;

    const doesFlowNameConflict =
      name.length && globalEmployeeScreeningFlows.length
        ? globalEmployeeScreeningFlows.find((flow) => flow.name === name)
        : name.length
          ? false
          : true;

    if (doesFlowNameConflict) {
      const finalCharacterOfName = this.name.slice(-1);
      if (parseInt(finalCharacterOfName)) {
        this.name = `${this.name.slice(0, -1)}${parseInt(finalCharacterOfName) + 1}`;
      } else {
        this.name = `${this.name} 2`;
      }
      this.autoGenerateEmployeeFlowName();
    }
  }

  @dropTask
  *newEmployeeScreeningFlow() {
    this.autoGenerateEmployeeFlowName();
    const { name } = this;
    this.workplaceMetrics.trackEvent('WORKPLACE_SETTINGS_NEW_REGISTRATION_FLOW_SAVE_BTN_CLICKED', {
      name,
    });
    let employeeScreeningFlow;
    try {
      employeeScreeningFlow = this.store.createRecord('employee-screening-flow', {
        company: this.state.currentCompany,
        name: this.name,
      });
      yield employeeScreeningFlow.save();
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showFlash('error', errorText);
    }
    this.workplaceMetrics.trackEvent('WORKPLACE_SETTINGS_NEW_REGISTRATION_FLOW_SAVE_SUCCESSFUL', {
      name,
    });
    this.openedModal = '';
    const transitionRouteString = this.abilities.can('visit settings for workplace')
      ? 'workplace.settings.employees.registration.edit'
      : 'protect.settings.employees.registration.edit';
    this.router.transitionTo(transitionRouteString, employeeScreeningFlow.id);
  }
}

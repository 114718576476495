import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const LOCATION_SPECIFIC_INSTALLS = ['installWebhook'];

export default class IntegrationAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;

  declare model: string;

  /**
   * Almost all of our existing integrations are company wide, which only global admins should be able to install.
   * Only webhooks are location-specific, which both global admins and location admins should be able to install.
   */
  get canInstall(): boolean {
    const { roleNames: roles } = this.currentAdmin;

    if (LOCATION_SPECIFIC_INSTALLS.includes(this.model)) {
      return isPresent(_intersection([GLOBAL_ADMIN, LOCATION_ADMIN], roles));
    } else {
      return roles.includes(GLOBAL_ADMIN);
    }
  }
}

import type RouterService from '@ember/routing/router-service';
import Service, { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import config from 'garaje/config/environment';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { ACS_PLUGIN_IDS } from 'garaje/utils/enums';
import { getActiveEmployeeCategory, getActiveLocationsCategory } from 'garaje/utils/log-rocket-helpers';

import type CurrentAdminService from './current-admin';
import type StatsigService from './statsig';

export interface ChameleonUserData {
  companyLocationId?: number;
  created?: string;
  employeeId?: number;
  userEnvironment?: string;
  companyCsmContactEmail?: string;
  hasActiveAcsPluginInstalls: boolean;
  hasRoleLastDashboardLocationReceptionist?: boolean;
  hasRoleLastDashboardLocationSecurity?: boolean;
  hasRoleLastDashboardLocationLocationAdmin?: boolean;
  hasRoleLastDashboardLocationEmployee?: boolean;
  hasRoleLastDashboardCompanyBilling?: boolean;
  hasRoleLastDashboardCompanyGlobalAdmin?: boolean;
  hasRoleAnyLocationSecurity?: boolean;
  hasRoleAnyLocationLocationAdmin?: boolean;
  hasRoleAnyLocationReceptionist?: boolean;
  hasRoleAnyLocationEmployee?: boolean;
}

export interface ChameleonCompanyData {
  uid: number;
  created?: string;
  employeesLastSyncAt?: string;
  employeesSyncState?: string;
  employeeCount?: number;
  activeVisitorsLocationCount?: number;
  activeEmployeesCategory?: string;
  activeVisitorsLocationsCategory?: string;
  lastEmployeeDirectorySyncAt?: string;
  staticQrEnabled?: boolean;
  blockListEnabled?: boolean;
  hostNotificationsEnabled?: boolean;
  hostApprovalEnabled?: boolean;
  capacityLimitEnabled?: boolean;
  touchlessSigninEnabled?: boolean;
  visitorsSubscription?: string;
  visitorsSubscriptionIsOnTrial?: boolean;
  visitorsSubscriptionIsOnExpiredTrial?: boolean;
  visitorsSubscriptionTrialStartDate?: string;
  visitorsSubscriptionTrialEndDate?: string;
  visitorsSubscriptionisDowngrading?: boolean;
  visitorsSubscriptionDowngradePlanLevel?: string;
  visitorsSubscriptionIsCancelled?: boolean;
  visitorsSubscriptionCancelledAt?: string;
  workplaceSubscription?: string;
  workplaceSubscriptionIsOnTrial?: boolean;
  workplaceSubscriptionIsOnExpiredTrial?: boolean;
  workplaceSubscriptionTrialStartDate?: string;
  workplaceSubscriptionTrialEndDate?: string;
  workplaceSubscriptionTrialisDowngrading?: boolean;
  workplaceSubscriptionTrialDowngradePlanLevel?: string;
  workplaceSubscriptionIsCancelled?: boolean;
  workplaceSubscriptionCancelledAt?: string;
  roomsSubscription?: string;
  roomsSubscriptionIsOnTrial?: boolean;
  roomsSubscriptionIsOnExpiredTrial?: boolean;
  roomsSubscriptionTrialStartDate?: string;
  roomsSubscriptionTrialEndDate?: string;
  roomsSubscriptionisDowngrading?: boolean;
  roomsSubscriptionDowngradePlanLevel?: string;
  roomsSubscriptionIsCancelled?: boolean;
  roomsSubscriptionCancelledAt?: string;
  deliveriesSubscription?: string;
  deliveriesSubscriptionIsOnTrial?: boolean;
  deliveriesSubscriptionIsOnExpiredTrial?: boolean;
  deliveriesSubscriptionTrialStartDate?: string;
  deliveriesSubscriptionTrialEndDate?: string;
  deliveriesSubscriptionisDowngrading?: boolean;
  deliveriesSubscriptionDowngradePlanLevel?: string;
  deliveriesSubscriptionIsCancelled?: boolean;
  deliveriesSubscriptionCancelledAt?: string;
  desksSubscription?: string;
  desksSubscriptionIsOnTrial?: boolean;
  desksSubscriptionIsOnExpiredTrial?: boolean;
  desksSubscriptionTrialStartDate?: string;
  desksSubscriptionTrialEndDate?: string;
  desksSubscriptionisDowngrading?: boolean;
  desksSubscriptionDowngradePlanLevel?: string;
  desksSubscriptionIsCancelled?: boolean;
  desksSubscriptionCancelledAt?: string;
  desksSubscriptionDeskCount?: number;
  vfdSubscription?: string;
  vfdSubscriptionIsOnTrial?: boolean;
  vfdSubscriptionIsOnExpiredTrial?: boolean;
  vfdSubscriptionTrialStartDate?: string;
  vfdSubscriptionTrialEndDate?: string;
  vfdSubscriptionIsCancelled?: boolean;
  vfdSubscriptionCancelledAt?: string;
}

export interface ChameleonIdentificationData extends ChameleonUserData {
  company?: ChameleonCompanyData;
}

export default class ChameleonService extends Service {
  @service declare store: StoreService;
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare statsig: StatsigService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;

  constructor(...args: unknown[]) {
    // @ts-ignore
    super(...args);

    // Helpbar / event logging and in-app routing
    if (this.featureFlags.isEnabled('growth_chameleon_identify') && window.chmln && config.environment !== 'test') {
      window.chmln?.on('app:navigate', (opts: { to: string }) => {
        const navigateURL = new URL(opts.to);

        if (this.router.recognize(navigateURL.pathname)) {
          this.statsig.logEvent('chameleon-helpbar-navigate-success', opts.to, { routerPath: navigateURL.pathname });
          this.router
            .transitionTo(navigateURL.pathname)
            .catch((err) => this.statsig.logEvent('chameleon-helpbar-navigate-error', 'error', { err }));
        } else {
          this.statsig.logEvent('chameleon-helpbar-navigate-unknown-route', opts.to, {
            routerPath: navigateURL.pathname,
          });
        }
      });

      // Event logging for all tours, banners, tooltips
      window.chmln?.on('chmln:event', (eventName: string, ctx: object) => {
        this.statsig.logEvent('chameleon-event', eventName, { ...ctx });
      });

      // Helpbar logging for searched content
      window.chmln?.on('helpbar:search:content', (opts: { query: string }) => {
        this.statsig.logEvent('chameleon-helpbar-search-query', opts.query);
      });

      window.chmln?.on('helpbar:opened', (_opts: string, ctx: { source: string }) => {
        this.statsig.logEvent('chameleon-helpbar-opened', ctx.source);
      });

      window.chmln?.on('helpbar:search:items', (opts: { query: string; items: [{ title: string; url: string }] }) => {
        const flattenedItems = opts.items.map((item) => {
          return { title: item.title, url: item.url };
        });
        this.statsig.logEvent('chameleon-helpbar-search-returned', opts.query, { returnedItems: flattenedItems });
      });

      window.chmln?.on('helpbar:item:action', (opts: { query: string; item: { title: string; url: string } }) => {
        this.statsig.logEvent('chameleon-helpbar-search-item-clicked', opts.query, {
          itemClicked: { title: opts.item?.title, url: opts.item?.url },
        });
      });
    }
  }

  isChameleonScriptLoaded(): boolean {
    if (window.chmln?.isInDOM) {
      return window.chmln?.isInDOM();
    }
    return false;
  }

  // Used to track page view events from the dashboard
  trackDashboardPageEvent(pageName: string): void {
    const { isGlobalAdmin, isLocationAdmin } = this.currentAdmin;
    if (this.featureFlags.isEnabled('growth_chameleon_identify') && (isGlobalAdmin || isLocationAdmin)) {
      window.chmln?.track(`[dashboard-page] ${pageName}`);
    }
  }

  // Used to track action events in the dashboard (click, completion, etc...)
  trackDashboardActionEvent(actionName: string): void {
    const { isGlobalAdmin, isLocationAdmin } = this.currentAdmin;
    if (this.featureFlags.isEnabled('growth_chameleon_identify') && (isGlobalAdmin || isLocationAdmin)) {
      window.chmln?.track(`[dashboard-action] ${actionName}`);
    }
  }

  // Show chameleon Walkthrough tour
  showTour(tourId: string): void {
    const { isGlobalAdmin, isLocationAdmin } = this.currentAdmin;
    if (this.featureFlags.isEnabled('growth_chameleon_identify') && (isGlobalAdmin || isLocationAdmin)) {
      window.chmln?.show(tourId);
    }
  }

  // Used to identify the current user upon entering the app with all relevent info
  identifyChameleonUser(): void {
    if (this.featureFlags.isEnabled('growth_chameleon_identify')) {
      const { isGlobalAdmin, isLocationAdmin } = this.currentAdmin;
      if (isGlobalAdmin || isLocationAdmin) {
        const { currentUser, currentLocation, currentCompany } = this.state;
        const currentEmployeeId = this.currentAdmin.employee?.id;
        const userId = currentUser?.id;

        const employeeCount = this.state._companyMeta ? this.state._companyMeta['employees-count'] : null;
        const activeVisitorsLocationCount = this.state._companyMeta
          ? this.state._companyMeta['active-locations-count']
          : null;

        const activeEmployeesCategory = getActiveEmployeeCategory(employeeCount);
        const activeVisitorsLocationsCategory = getActiveLocationsCategory(activeVisitorsLocationCount);

        const activeAcsPluginInstalls = this.state.pluginInstalls?.find(
          (pluginInstall) =>
            ACS_PLUGIN_IDS.includes(pluginInstall.belongsTo('plugin').id()) && pluginInstall.status === 'active',
        );
        const companyCsmContact = this.state.companyInfo?.contacts.find((contact) => {
          return contact.title === 'Customer Success Manager';
        });

        const hasActiveAcsPluginInstalls = !!activeAcsPluginInstalls;
        const companyCsmContactEmail = companyCsmContact?.email || null;

        if (window.chmln && config.environment !== 'test') {
          try {
            window.chmln.identify(userId, {
              companyLocationId: currentLocation?.id,
              created: currentUser?.createdAt,
              employeeId: currentEmployeeId,
              userEnvironment: config.environment,
              companyCsmContactEmail,
              hasActiveAcsPluginInstalls,
              hasRoleLastDashboardLocationReceptionist: this.currentAdmin.isReceptionist,
              hasRoleLastDashboardLocationSecurity: this.currentAdmin.isSecurity,
              hasRoleLastDashboardLocationLocationAdmin: this.currentAdmin.isLocationAdmin,
              hasRoleLastDashboardLocationEmployee: this.currentAdmin.isEmployee,
              hasRoleLastDashboardCompanyBilling: this.currentAdmin.isBillingAdmin,
              hasRoleLastDashboardCompanyGlobalAdmin: this.currentAdmin.isGlobalAdmin,
              hasRoleAnyLocationSecurity: this.currentAdmin.allLocationRoleNames.includes('Security'),
              hasRoleAnyLocationLocationAdmin: this.currentAdmin.allLocationRoleNames.includes('Location Admin'),
              hasRoleAnyLocationReceptionist: this.currentAdmin.allLocationRoleNames.includes('Receptionist'),
              hasRoleAnyLocationEmployee: this.currentAdmin.allLocationRoleNames.includes('Employee'),
              company: {
                uid: currentCompany?.id,
                createdAt: this.state.currentCompany?.createdAt,
                employeesLastSyncAt: this.state.currentCompany?.employeesLastSyncAt,
                employeesSyncState: this.state.currentCompany?.employeesSyncState,
                activeEmployeesCategory,
                activeVisitorsLocationsCategory,
                employeeCount,
                activeVisitorsLocationCount,
                lastEmployeeDirectorySyncAt: this.state.currentCompany?.syncedSince,
                staticQrEnabled: this.state.currentLocation?.staticQrEnabled || false,
                blockListEnabled: this.state.currentLocation?.blocklistEnabled || false,
                hostNotificationsEnabled: currentLocation?.hostNotificationsEnabled || false,
                hostApprovalEnabled: currentLocation?.hostApprovalEnabled || false,
                capacityLimitEnabled: currentLocation?.capacityLimitEnabled || false,
                touchlessSigninEnabled: currentLocation?.touchlessSigninEnabled || false,
                visitorsSubscription: this.state.vrSubscription?.plan,
                visitorsSubscriptionIsOnTrial: this.state.vrSubscription?.onTrial,
                visitorsSubscriptionIsOnExpiredTrial: this.state.vrSubscription?.onExpiredTrial,
                visitorsSubscriptionTrialStartDate: this.state.vrSubscription?.trialStartDate,
                visitorsSubscriptionTrialEndDate: this.state.vrSubscription?.trialEndDate,
                visitorsSubscriptionisDowngrading: this.state.vrSubscription?.isDowngrading,
                visitorsSubscriptionDowngradePlanLevel: this.state.vrSubscription?.downgradePlan?.planLevel,
                visitorsSubscriptionIsCancelled: this.state.vrSubscription?.cancelled,
                visitorsSubscriptionCancelledAt: this.state.vrSubscription?.cancelledAt,
                workplaceSubscription: this.state.workplaceSubscription?.plan,
                workplaceSubscriptionIsOnTrial: this.state.workplaceSubscription?.onTrial,
                workplaceSubscriptionIsOnExpiredTrial: this.state.workplaceSubscription?.onExpiredTrial,
                workplaceSubscriptionTrialStartDate: this.state.workplaceSubscription?.trialStartDate,
                workplaceSubscriptionTrialEndDate: this.state.workplaceSubscription?.trialEndDate,
                workplaceSubscriptionTrialisDowngrading: this.state.workplaceSubscription?.isDowngrading,
                workplaceSubscriptionTrialDowngradePlanLevel:
                  this.state.workplaceSubscription?.downgradePlan?.planLevel,
                workplaceSubscriptionIsCancelled: this.state.workplaceSubscription?.cancelled,
                workplaceSubscriptionCancelledAt: this.state.workplaceSubscription?.cancelledAt,
                deliveriesSubscription: this.state.deliveriesSubscription?.plan,
                deliveriesSubscriptionIsOnTrial: this.state.deliveriesSubscription?.onTrial,
                deliveriesSubscriptionIsOnExpiredTrial: this.state.deliveriesSubscription?.onExpiredTrial,
                deliveriesSubscriptionTrialStartDate: this.state.deliveriesSubscription?.trialStartDate,
                deliveriesSubscriptionTrialEndDate: this.state.deliveriesSubscription?.trialEndDate,
                deliveriesSubscriptionisDowngrading: this.state.deliveriesSubscription?.isDowngrading,
                deliveriesSubscriptionDowngradePlanLevel: this.state.deliveriesSubscription?.downgradePlan?.planLevel,
                deliveriesSubscriptionIsCancelled: this.state.deliveriesSubscription?.cancelled,
                deliveriesSubscriptionCancelledAt: this.state.deliveriesSubscription?.cancelledAt,
                desksSubscription: this.state.desksSubscription?.plan,
                desksSubscriptionIsOnTrial: this.state.desksSubscription?.onTrial,
                desksSubscriptionIsOnExpiredTrial: this.state.desksSubscription?.onExpiredTrial,
                desksSubscriptionTrialStartDate: this.state.desksSubscription?.trialStartDate,
                desksSubscriptionTrialEndDate: this.state.desksSubscription?.trialEndDate,
                desksSubscriptionisDowngrading: this.state.desksSubscription?.isDowngrading,
                desksSubscriptionDowngradePlanLevel: this.state.desksSubscription?.downgradePlan?.planLevel,
                desksSubscriptionIsCancelled: this.state.desksSubscription?.cancelled,
                desksSubscriptionCancelledAt: this.state.desksSubscription?.cancelledAt,
                desksSubscriptionDeskCount: this.state.desksSubscription?.desksCount,
                roomsSubscription: this.state.roomsSubscription?.plan,
                roomsSubscriptionIsOnTrial: this.state.roomsSubscription?.onTrial,
                roomsSubscriptionIsOnExpiredTrial: this.state.roomsSubscription?.onExpiredTrial,
                roomsSubscriptionTrialStartDate: this.state.roomsSubscription?.trialStartDate,
                roomsSubscriptionTrialEndDate: this.state.roomsSubscription?.trialEndDate,
                roomsSubscriptionisDowngrading: this.state.roomsSubscription?.isDowngrading,
                roomsSubscriptionDowngradePlanLevel: this.state.roomsSubscription?.downgradePlan?.planLevel,
                roomsSubscriptionIsCancelled: this.state.roomsSubscription?.cancelled,
                roomsSubscriptionCancelledAt: this.state.roomsSubscription?.cancelledAt,
                vfdSubscription: this.state.vfdSubscription?.plan,
                vfdSubscriptionIsOnTrial: this.state.vfdSubscription?.onTrial,
                vfdSubscriptionIsOnExpiredTrial: this.state.vfdSubscription?.onExpiredTrial,
                vfdSubscriptionTrialStartDate: this.state.vfdSubscription?.trialStartDate,
                vfdSubscriptionTrialEndDate: this.state.vfdSubscription?.trialEndDate,
                vfdSubscriptionIsCancelled: this.state.vfdSubscription?.cancelled,
                vfdSubscriptionCancelledAt: this.state.vfdSubscription?.cancelledAt,
              },
            });
          } catch (e: unknown) {
            let errorMessage: string;
            if (e instanceof Error) {
              errorMessage = e.message;
            } else {
              errorMessage = 'An unknown error occurred';
            }
            this.statsig.logEvent('chameleon-identify-error', 'cannot identify user', {
              error: errorMessage,
            });
          }
        }
      }
    }
  }
}

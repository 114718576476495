export const DESK_ICON = Object.freeze({
  iconClassname: 'desk-icon',
  iconSize: [30, 30],
});

export const SELECTED_DESK_ICON = Object.freeze({
  iconClassname: 'selected-desk-icon',
  iconSize: [30, 30],
});

export const CURRENT_DESK_ICON = Object.freeze({
  iconClassname: 'employee-desk',
  iconSize: [46, 46],
});

export const ERROR_DESK_ICON = Object.freeze({
  iconClassname: 'error-desk-icon',
  iconSize: [35, 35],
});

export const RESERVED_DESK_ICON = Object.freeze({
  iconClassname: 'reserved-desk-icon',
  iconSize: [30, 30],
});

export const DISABLED_DESK_ICON = Object.freeze({
  iconClassname: 'disabled-desk-icon',
  iconSize: [30, 30],
});

export const SELECTED_DISABLED_DESK_ICON = Object.freeze({
  iconClassname: 'selected-disabled-desk-icon',
  iconSize: [30, 30],
});

export const ACCESSIBLE_ICON = Object.freeze({
  iconSize: [30, 30],
  labelColor: 'carbon-70',
});

export const ELEVATORS_ICON = Object.freeze({
  iconSize: [30, 30],
  labelColor: 'carbon-70',
});

export const RESTROOM_ICON = Object.freeze({
  iconSize: [30, 30],
  labelColor: 'carbon-70',
});

export const STAIRS_ICON = Object.freeze({
  iconSize: [30, 30],
  labelColor: 'carbon-70',
});

export const AED_ICON = Object.freeze({
  iconSize: [30, 30],
  labelColor: 'papaya-50',
});

export const EMERGENCY_EXIT_ICON = Object.freeze({
  iconSize: [30, 30],
  labelColor: 'papaya-50',
});

export const FIRE_EXTINGUISHER_ICON = Object.freeze({
  iconSize: [30, 30],
  labelColor: 'papaya-50',
});

export const FIRST_AID_ICON = Object.freeze({
  iconSize: [30, 30],
  labelColor: 'papaya-50',
});

export const PRINTER_ICON = Object.freeze({
  iconSize: [30, 30],
  labelColor: 'gem-60',
});

export const BREAK_ROOM_ICON = Object.freeze({
  iconSize: [30, 30],
  labelColor: 'gem-60',
});

export const CAFE_ICON = Object.freeze({
  iconSize: [30, 30],
  labelColor: 'gem-60',
});

export const KITCHEN_ICON = Object.freeze({
  iconSize: [30, 30],
  labelColor: 'gem-60',
});

export const MOTHERS_ROOM_ICON = Object.freeze({
  iconSize: [30, 30],
  labelColor: 'gem-60',
});

export const CUSTOM_ICON = Object.freeze({
  iconSize: [30, 30],
  labelColor: 'carbon-70',
});

export const VISITOR_AREA_ICON = Object.freeze({
  iconSize: [30, 30],
  labelColor: 'gem-70',
});

export const DELIVERY_AREA_ICON = Object.freeze({
  iconSize: [30, 30],
  labelColor: 'gem-70',
});

export const ROOM_ICON = Object.freeze({
  iconSize: [30, 30],
  labelColor: 'cilantro-40',
});

export const FEATURE_ICON_MAP = {
  accessible: ACCESSIBLE_ICON,
  elevator: ELEVATORS_ICON,
  'emergency-exit': EMERGENCY_EXIT_ICON,
  'fire-extinguisher': FIRE_EXTINGUISHER_ICON,
  stairs: STAIRS_ICON,
  aed: AED_ICON,
  'first-aid-kit': FIRST_AID_ICON,
  printer: PRINTER_ICON,
  restroom: RESTROOM_ICON,
  'break-room': BREAK_ROOM_ICON,
  cafe: CAFE_ICON,
  kitchen: KITCHEN_ICON,
  'mothers-room': MOTHERS_ROOM_ICON,
  custom: CUSTOM_ICON,
  'visitor-area': VISITOR_AREA_ICON,
  'delivery-area': DELIVERY_AREA_ICON,
  room: ROOM_ICON,
};

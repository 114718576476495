import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

/**
 * @param {Class<Subscription>}           subscription
 * @param {Function}                      boss
 * @param {Function}                      editProfile
 * @param {Function}                      logout
 */
export default class UserMenu extends Component {
  @service currentLocation;
  @service currentAdmin;

  @action
  onInsert() {
    this.currentLocation.setupHeadway();
  }
}

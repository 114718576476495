interface Geometry {
  type: string;
  coordinates: [number] | [[number]];
}

interface RelationshipsData {
  type: string;
  id: string;
}

interface Relationships {
  'map-floor'?: {
    data: RelationshipsData;
  };
  'area-map'?: {
    data: RelationshipsData;
  };
}

interface Attributes {
  name: string;
  notes: string;
  enabled: boolean;
  geometry: Geometry;
  type: string;
  ['external-id']: string;
  ['parent-feature-id']?: string;
}

/**
 * A JSON API-compliant representation of a map-feature.
 */
interface Feature {
  /**
   * A unique identifier for the feature.
   */
  id: string;

  /**
   * The type of the feature, always set to 'map-feature'.
   */
  type: 'map-feature';

  /**
   * Describes various attributes of the feature, such as name, notes, etc.
   */
  attributes: Attributes;

  /**
   * Optional. Defines the relationships that this feature has with other entities like floors and area maps.
   */
  relationships?: Relationships;
}

function addRelationships(feature: Feature, payload: { data: Feature }, areaMapId?: string) {
  if (feature.relationships && feature.relationships['map-floor']) {
    payload.data.relationships = {
      'map-floor': {
        data: {
          type: 'map-floor',
          id: feature.relationships['map-floor']?.data.id,
        },
      },
    };
  }
  if (areaMapId) {
    if (payload.data.relationships) {
      payload.data.relationships['area-map'] = {
        data: {
          type: 'area-map',
          id: areaMapId,
        },
      };
    } else {
      payload.data.relationships = {
        'area-map': {
          data: {
            type: 'area-map',
            id: areaMapId,
          },
        },
      };
    }
  }
}

/**
 * Function that takes a JSON API-compliant response from the server and converts it to an object that can be inserted
 * into the ember store
 * @param feature - represents a JSON API compliant representation of a map-feature
 * @param areaMapId of the areaMap in which the feature is being stored
 * @returns an object that can be pushed into the ember data store via the pushPayload function
 */
function createFeatureStorePayload(feature: Feature, areaMapId?: string): { data: Feature } {
  const payload: { data: Feature } = {
    data: {
      id: feature.id,
      type: 'map-feature',
      attributes: feature.attributes,
    },
  };

  addRelationships(feature, payload, areaMapId);

  return payload;
}

export default createFeatureStorePayload;

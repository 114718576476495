import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type AjaxService from 'garaje/services/ajax';
import type { AssignmentCountsPerRole } from 'garaje/utils/custom-roles';
import urlBuilder from 'garaje/utils/url-builder';

export default class RoleAssignmentsService extends Service {
  @service declare ajax: AjaxService;

  @tracked assignmentCountsPerRole: AssignmentCountsPerRole = {};

  async fetchAssignmentCountsPerRole(): Promise<void> {
    const url = urlBuilder.v2.assignmentCountsPerRole();
    this.assignmentCountsPerRole = await this.ajax.request(url, { type: 'GET' });
  }
}

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
export default class LocationOverviewAnalyticsDashboardRoute extends Route {
  @service abilities;
  @service router;

  beforeModel() {
    if (this.abilities.can('visit-occupancy global-analytic')) {
      this.router.transitionTo('location-overview.occupancy.analytics');
    } else if (this.abilities.can('visit attendance in global-analytics')) {
      this.router.transitionTo('location-overview.analytics.attendance.overview');
    } else if (this.abilities.can('visit-visitors global-analytic')) {
      this.router.transitionTo('location-overview.analytics.visitors');
    } else if (this.abilities.can('visit-protect global-analytics')) {
      this.router.transitionTo('location-overview.analytics.protect.index');
    } else if (this.abilities.can('visit-deliveries global-analytics')) {
      this.router.transitionTo('location-overview.analytics.deliveries');
    } else if (this.abilities.can('visit-desks global-analytics')) {
      this.router.transitionTo('location-overview.analytics.desks');
    } else if (this.abilities.can('visit-rooms global-analytics')) {
      this.router.transitionTo('location-overview.analytics.rooms');
    } else if (this.state?.vrSubscription?.canAccessScheduledReports) {
      this.router.transitionTo('location-overview.analytics.reports');
    } else if (this.abilities.can('visit occupancy-global-settings')) {
      this.router.transitionTo('location-overview.occupancy.settings');
    } else {
      this.router.transitionTo('unauthorized');
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.showModal = false;
  }
}

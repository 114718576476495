import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import type GlobalFlowModel from 'garaje/models/global-flow';

import type { LocationOverviewSignInFlowsShowRouteModel } from '../route';

export interface LocationOverviewSignInFlowsShowIndexRouteModel extends LocationOverviewSignInFlowsShowRouteModel {
  changeset: DetailedChangeset<GlobalFlowModel>;
}

export default class LocationOverviewSignInFlowsShowIndexRoute extends Route {
  @service declare store: StoreService;

  model(): LocationOverviewSignInFlowsShowIndexRouteModel {
    const model = <LocationOverviewSignInFlowsShowRouteModel>this.modelFor('location-overview.sign-in-flows.show');

    return {
      ...model,
      changeset: Changeset(model.globalFlow),
    };
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { isBlank } from '@ember/utils';

/**
 * @param {Boolean}           isRTLLocale
 * @param {Object}            section
 * @param {String}            selectedLocale
 */
export default class VisitorEmailsVisitorGuideManageTranslationsModalSectionRow extends Component {
  @tracked titleLocaleValue;
  @tracked bodyLocaleValue;

  constructor() {
    super(...arguments);
    this.titleLocaleValue = this.lookupValue('title');
    this.bodyLocaleValue = this.lookupValue('body');
  }

  lookupValue(key) {
    if (!this.args.selectedLocale) {
      return '';
    }
    const { value } = this.args.selectedLocale;
    const { customTranslations } = this.args.section;
    if (
      isBlank(customTranslations) ||
      isBlank(customTranslations[key]) ||
      typeof customTranslations[key][value] !== 'string'
    ) {
      return '';
    } else {
      return customTranslations[key][value];
    }
  }

  @action
  updateLocaleValues() {
    this.titleLocaleValue = this.lookupValue('title');
    this.bodyLocaleValue = this.lookupValue('body');
  }

  @action
  changeBodyValue({ target: { value } }) {
    let { customTranslations } = this.args.section;
    if (isBlank(customTranslations)) {
      customTranslations = { body: {}, title: {} };
    }
    const body = customTranslations['body'];
    body[this.args.selectedLocale.value] = value.trim();
    set(this.args.section, 'customTranslations', { ...customTranslations, body });
  }
}

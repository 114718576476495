import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type ConnectFloorModel from 'garaje/models/connect-floor';
import type ZoneModel from 'garaje/models/zone';
import type StateService from 'garaje/services/state';
import type { RecordArray } from 'garaje/utils/type-utils';
import { TrackedObject } from 'tracked-built-ins';

import { type PropertySettingsFloorsRouteModel } from '../route';

export type PropertySettingsFloorsNewRouteModel = {
  floor: ConnectFloorModel;
  floors: RecordArray<ConnectFloorModel>;
  property: ZoneModel;
};

export default class PropertySettingsFloorsNewRoute extends Route {
  @service declare state: StateService;
  @service declare store: StoreService;

  model(): PropertySettingsFloorsNewRouteModel {
    const property = <ZoneModel>this.modelFor('property');
    const parentModel = <PropertySettingsFloorsRouteModel>this.modelFor('property.settings.floors');
    const floors = parentModel.floors;

    const floor = this.store.createRecord('connect-floor', {
      company: this.state.currentCompany,
      property,
    });

    // return a TrackedObject instead of a POJO because the controller modifies `model.floor`
    // and if this isn't tracked, that assignment won't trigger dependent tracked properties to update
    return new TrackedObject({
      floor,
      floors,
      property,
    });
  }
}

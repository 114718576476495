import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import { Changeset } from 'ember-changeset';
import { type BufferedChangeset } from 'ember-changeset/types';
import type PropertyBadgeModel from 'garaje/models/property-badge';
import type UiHookModel from 'garaje/models/ui-hook';
import type ZoneModel from 'garaje/models/zone';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

export interface BadgesRouteModel {
  currentProperty: ZoneModel;
  badge?: PropertyBadgeModel;
  badgeChangeset?: BufferedChangeset;
  uiHooks?: UiHookModel[];
}

const BADGE_CONTENT_TRIGGERS = ['badge_text_field', 'badge_barcode', 'badge_qr_code'];

export default class PropertySettingsBadgesRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare transitionConfirm: TransitionConfirmService;

  beforeModel(): void {
    if (this.abilities.cannot('manage property badges')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  async model(): Promise<BadgesRouteModel> {
    const currentProperty = <ZoneModel>this.modelFor('property');
    await currentProperty.loadBadgeTask.perform().catch(throwUnlessTaskDidCancel);

    let badgeChangeset: BufferedChangeset | undefined;
    if (currentProperty.badge) badgeChangeset = Changeset(currentProperty.badge);

    const model: BadgesRouteModel = { currentProperty, badge: currentProperty.badge, badgeChangeset };
    const uiHooks = await this.store.query('ui-hook', {
      triggerNames: BADGE_CONTENT_TRIGGERS,
      zoneIds: [currentProperty.id],
    });
    model.uiHooks = uiHooks.toArray();

    return model;
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    const { badge, badgeChangeset } = <BadgesRouteModel>this.modelFor(this.routeName);
    const noChanges = !badge?.hasDirtyAttributes && !badgeChangeset?.isDirty;

    if (transition.to.name === this.routeName || noChanges) return;

    void this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        badge?.rollbackAttributes();
        badgeChangeset?.rollback();
      },
    });
  }
}

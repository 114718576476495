import type RouterService from '@ember/routing/router-service';
import Service, { service } from '@ember/service';
import type Store from '@ember-data/store';
import { dropTask } from 'ember-concurrency';
import type SubscriptionModel from 'garaje/models/subscription';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type LocationBillingService from 'garaje/services/location-billing';
import type MetricsService from 'garaje/services/metrics';
import type ProductActivationService from 'garaje/services/product-activation';
import type StateService from 'garaje/services/state';
import { APP, PLAN_LEVEL } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { startingSubscriptionPlanId } from 'garaje/utils/starting-subscription-plan-id';

export default class SubscriptionsService extends Service {
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessagesService;
  @service declare store: Store;
  @service declare state: StateService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare metrics: MetricsService;
  @service declare locationBilling: LocationBillingService;
  @service declare productActivation: ProductActivationService;

  /**
   * initiates up a rooms subscription
   */
  createRoomsSubscriptionTask = dropTask(async () => {
    try {
      const { currentCompany } = this.state;
      if (this.featureFlags.isEnabled('workplace-garaje-launch')) {
        await this.productActivation.startWorkplaceTrialTask.perform();
      } else {
        if (this.featureFlags.isEnabled('locationBilling')) {
          const subscriptionBatch = this.store.createRecord('subscription-batch', {
            company: currentCompany,
            products: [
              {
                'subscription-plan-id': startingSubscriptionPlanId({ app: APP.ROOMS, state: this.state }),
                trial: true,
              },
            ],
            status: 'pending',
          });
          await subscriptionBatch.save();
          await this.locationBilling.pollForSubscriptionBatchTask.perform(subscriptionBatch);
        } else {
          const subscription = this.store.createRecord('subscription', {
            app: APP.ROOMS,
            plan: PLAN_LEVEL.STANDARD,
            company: currentCompany,
          });

          await subscription.save();
        }
      }
      await this.state.initSubscriptionStateTask.perform();

      this.metrics.trackEvent('Product Landing Page Start Trial Button Clicked', { product: APP.ROOMS });

      this.flashMessages.showAndHideFlash('success', 'Trial started!');
      void this.router.transitionTo('roomba.settings');
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  downgradeToBasicTask = dropTask(async (subscription: SubscriptionModel) => {
    const { currentCompany } = this.state;
    const basicPlan = await this.store.findRecord('plan', 'basic');

    subscription.downgradePlan = basicPlan;
    currentCompany.planIntent = 'basic';

    try {
      await subscription.save();
      await currentCompany.save();
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
      subscription.rollbackAttributes();
    }
  });
}

import Component from '@glimmer/component';

/**
 * @param {Boolean}               blockSelfServe
 * @param {String}                svg
 * @param {String}                app
 * @param {String}                item
 * @param {String}                link
 * @param {Boolean}               subscribedAtOtherLocations
 * @param {Class<Subscription>}   subscription
 */
// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class SubscriptionsOverviewComponent extends Component {}

import gql from 'graphql-tag';

export default gql`
  fragment EmployeeFragment on Employee {
    id
    email
    name
    phoneNumber
    profilePictureUrl
  }
`;

import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import { Ability } from 'ember-can';
import type StateService from 'garaje/services/state';

export default class ConditionalAlertAbility extends Ability {
  @service declare state: StateService;

  isProtect = false;

  get canAccess(): boolean {
    const { vrSubscription } = this.state;

    if (this.isProtect) {
      return true;
    }

    if (isBlank(vrSubscription)) {
      return false;
    }

    return !!vrSubscription?.canAccessConditionalAlerts;
  }
}

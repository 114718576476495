import { hasMany } from '@ember-data/model';

/**
 * @returns {*}
 */
export default function (Class) {
  const DecoratedClass = class extends Class {
    @hasMany('agreeable-nda', { async: false, inverse: 'agreeable' }) agreeableNdas;
  };

  // reassign the original class name as the name of the decorated class
  Object.defineProperty(DecoratedClass, 'name', { value: Class.name });

  return DecoratedClass;
}

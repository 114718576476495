import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

/**
 * @param {String}      install
 * @param {Task}        handleInstallTask
 * @param {Boolean}     installed
 * @param {Boolean}     isUpgradeRequired
 * @param {String}      minPlanRequired
 * @param {Boolean}     showInstall
 * @param {Boolean}     vrOnly
 */
export default class InstallBox extends Component {
  @tracked minPlanRequired;
  @tracked showInstall;

  constructor() {
    super(...arguments);
    this.minPlanRequired = this.args.minPlanRequired ?? 'premium';
    this.showInstall = this.args.showInstall ?? true;
  }
}

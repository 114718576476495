import Model, { attr, hasMany, type AsyncHasMany } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';
import { sortBy } from 'macro-decorators';

import type HomepageItemGroupMapping from './homepage-item-group-mapping';

@adapter('growth')
class HomepageGroup extends Model {
  @attr('string')
  declare identifier: string;

  @attr('string')
  declare title: string;

  @attr('number')
  declare rank: number;

  @attr('number')
  declare width: number;

  @attr('number')
  declare height: number;

  @attr('boolean')
  declare enabled: boolean;

  @attr('boolean')
  declare completed: boolean;

  @hasMany('homepage-item-group-mapping')
  declare homepageItemGroupMappings: AsyncHasMany<HomepageItemGroupMapping>;

  @sortBy('homepageItemGroupMappings', 'rank') declare sortedMappings: HomepageItemGroupMapping[];
}

export default HomepageGroup;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'homepage-group': HomepageGroup;
  }
}

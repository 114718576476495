import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type LocalStorageService from 'garaje/services/local-storage';
import type SigmaUrlService from 'garaje/services/sigma-url';
import type StateService from 'garaje/services/state';
import type UserFeatureFlagsService from 'garaje/services/user-feature-flags';
import urlBuilder from 'garaje/utils/url-builder';

export default class AttendanceController extends Controller {
  @service declare localStorage: LocalStorageService;
  @service declare state: StateService;
  @service declare flashMessages: FlashMessagesService;
  @service declare sigmaUrl: SigmaUrlService;
  @service declare userFeatureFlags: UserFeatureFlagsService;

  @tracked loginRequired = false;

  get iframeUrl(): Promise<string | undefined> {
    const companyId = this.state?.currentCompany?.id;

    if (!companyId) {
      return Promise.resolve(undefined);
    }

    return this.getSigmaIframeUrl(companyId);
  }

  get legacyIframeUrl(): string {
    const companyId = this.state?.currentCompany?.id;
    const view = 'overview';

    return urlBuilder.embeddedAttendanceGlobalAnalyticsUrl(this.loginRequired, companyId, view);
  }

  async getSigmaIframeUrl(companyId: string): Promise<string | undefined> {
    try {
      return this.sigmaUrl.getGlobalSigmaUrl(companyId, 'attendance');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error loading dashboard');
      // eslint-disable-next-line no-console
      console.error(e);
      return;
    }
  }

  @action
  trackLoggedIn(): void {
    /**
     * Issue: When user logs out from the Garaje app there is no way Next.js app knows it also needs to logout.
     * Due to this the cookies of the logout user may persist.
     * Solution: After login, force login the user on his first visit to this app. This refreshes all the cookies
     * To know if the user is visiting for the first time we will maintain a global state in localstorage called 'wa_logged_in'
     * On Login -> wa_logged_in does not exists in localstorage -> its first visit
     * On first visit -> SignIn, set wa_logged_in = true in localstorage
     * On Logout -> Remove wa_logged_in
     */

    const waLoggedIn = this.localStorage.getItem('wa_logged_in');

    if (!waLoggedIn) {
      this.loginRequired = true;
      this.localStorage.setItem('wa_logged_in', true);
    } else {
      this.loginRequired = false;
    }
  }
}

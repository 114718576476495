import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, set } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { gt, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { all, task } from 'ember-concurrency';

import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';

/**
 * @param {Array<FallbackContact>}            fallbackContacts
 * @param {Function}                          onUpdateFallbackContacts
 * @param {Function}                          reloadFallbackContacts
 */
export default class FallbackNotifications extends Component {
  @service currentLocation;
  @service flashMessages;
  @service impressions;
  @service store;

  @tracked isOpen = false;
  @tracked showUnsavedEditsWarning = false;
  @tracked fallbackEmployees;
  @tracked _fallbackEmployees;
  @tracked hasChangedFallbackContacts = false;

  @gt('_fallbackEmployees.length', 0) hasSavedFallbackEmployees;
  @readOnly('currentLocation.fallbackNotificationsEnabled') isEnabled;

  constructor() {
    super(...arguments);
    this._mapContactsToEmployees();
  }

  @(employeesSearcherTask({
    filter: {
      deleted: false,
      excludeHidden: false,
    },
    prefix: true,
  }).restartable())
  searchEmployeesTask;

  _mapContactsToEmployees() {
    // Even though we pass in fallbackContacts, every other operation
    // we need to do is simpler in terms of the employee
    const fallbackEmployees = this.args.fallbackContacts.map((fallbackContact) => get(fallbackContact, 'employee'));
    this.fallbackEmployees = fallbackEmployees;

    // '_fallbackEmployees' allows us to compare which fallback contacts are new (which
    // need to be saved) vs old (which need to be deleted) upon save
    this._fallbackEmployees = fallbackEmployees;
  }

  @task
  *searchEmployeesWithoutDupsTask(term) {
    const employees = yield this.searchEmployeesTask.perform(term);
    const currentEmployeeIds = this.fallbackEmployees.mapBy('id');

    return employees.filter((employee) => {
      return !currentEmployeeIds.includes(employee.id);
    });
  }

  @task
  *enableFallbackNotifications() {
    const currentLocation = this.currentLocation;
    // We only want to enable this feature if we
    // have contacts
    if (this.hasSavedFallbackEmployees) {
      set(currentLocation, 'fallbackNotificationsEnabled', true);
      yield currentLocation.save();
      yield this.impressions.postImpression.perform(IMPRESSION_NAMES.VR_NOTIFICATIONS_FALLBACK_NOTIFICATION['ENABLED']);
    }
  }

  @task
  *disableFallbackNotifications() {
    const currentLocation = this.currentLocation;
    set(currentLocation, 'fallbackNotificationsEnabled', false);
    yield currentLocation.save();
    yield this.impressions.postImpression.perform(IMPRESSION_NAMES.VR_NOTIFICATIONS_FALLBACK_NOTIFICATION['DISABLED']);
  }

  _saveEmployeeAsFallbackContact(employee) {
    const store = this.store;
    const location = get(this, 'currentLocation.location');
    const fallbackContactRecord = store.createRecord('fallbackContact', {
      employee,
      location,
    });
    return fallbackContactRecord.save();
  }

  _deleteFallbackContactForEmployee(employee) {
    const fallbackContact = this.store.peekAll('fallbackContact').findBy('employee.id', get(employee, 'id'));
    return fallbackContact.destroyRecord();
  }

  _findNewFallbackContacts(oldContacts, newContacts) {
    return [...newContacts].filter((contact) => {
      return !oldContacts.includes(contact);
    });
  }

  _findRemovedFallbackContacts(oldContacts, newContacts) {
    return [...oldContacts].filter((contact) => {
      return !newContacts.includes(contact);
    });
  }

  @task
  *syncFallbackContactsTask() {
    const newFallbackContacts = [...this.fallbackEmployees];
    const oldFallbackContacts = [...this._fallbackEmployees];

    const newContactsToSave = this._findNewFallbackContacts(oldFallbackContacts, newFallbackContacts);
    const oldContactsToDelete = this._findRemovedFallbackContacts(oldFallbackContacts, newFallbackContacts);

    try {
      yield all(newContactsToSave.map(this._saveEmployeeAsFallbackContact.bind(this)));
      yield all(oldContactsToDelete.map(this._deleteFallbackContactForEmployee.bind(this)));

      this.hasChangedFallbackContacts = false;
      this._fallbackEmployees = this.fallbackEmployees;

      const contact = newContactsToSave.length > 1 ? 'contacts' : 'contact';
      this.flashMessages.showAndHideFlash('success', `Saved fallback ${contact}!`);

      if (!this.isEnabled) {
        yield this.enableFallbackNotifications.perform();
      }
      this.args.onUpdateFallbackContacts({ hasChanges: false });
    } catch (error) {
      this.args.onUpdateFallbackContacts({ hasChanges: true });
      const errorText = parseErrorForDisplay(error);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
    yield this.args.reloadFallbackContacts();
  }

  @action
  async onContinue() {
    if (this.hasChangedFallbackContacts) {
      await this.args.reloadFallbackContacts();
    }
    this.showUnsavedEditsWarning = false;
    this.isOpen = false;
  }

  @action
  async cancel() {
    if (this.hasChangedFallbackContacts) {
      this.showUnsavedEditsWarning = true;
    } else {
      await this.onContinue();
    }
  }

  @action
  async enable() {
    await this.enableFallbackNotifications.perform();
    this.isOpen = true;
  }

  @action
  edit() {
    this.isOpen = true;
  }

  @action
  async disable() {
    await this.disableFallbackNotifications.perform();
    this.isOpen = false;
  }

  @action
  setFallbackContacts(newFallbackContacts) {
    this.fallbackEmployees = newFallbackContacts;
    this.hasChangedFallbackContacts = true;
    this.args.onUpdateFallbackContacts({ hasChanges: true });
  }
}

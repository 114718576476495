import ApplicationSerializer from './application';

export default class SkinnyLocationSerializer extends ApplicationSerializer {
  attrs = {
    employeeRegistrationConfiguration: { serialize: false },
    enabledLocales: { serialize: false },
    flows: { serialize: false },
    globalFlows: { serialize: false },
    locale: { serialize: false },
    vfdConfiguration: { serialize: false },
  };

  modelNameFromPayloadKey(key) {
    if (key === 'locations') {
      return 'skinny-location';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }

  payloadKeyFromModelName(modelName) {
    if (modelName === 'skinny-location') {
      return 'locations';
    } else {
      return super.payloadKeyFromModelName(...arguments);
    }
  }
}

import Component from '@glimmer/component';
import { action, get, set } from '@ember/object';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { reads } from 'macro-decorators';

/**
 * Settings panels for indicating if and when push notifications should be sent to remind
 * employees at a Location to sign-in or sign-out.
 *
 * @param {Location}       location      (required) Location to manage notification settings for
 * @param {Boolean}        isDisabled    If true, disables all child settings inputs
 */
export default class SettingsPushNotificationsComponent extends Component {
  @service featureFlags;
  @service flashMessages;
  @service store;

  get isInputDisabled() {
    if (this.args.isDisabled) return true;
    if (this.loadConfigurationForLocationTask.isRunning) return true;
    if (this.loadInstalledPluginsTask.isRunning) return true;
    if (this.updateConfigurationTask.isRunning) return true;
    if (!this.employeeRegistrationConfiguration) return true;

    return false;
  }

  get isBadgeSignInSupported() {
    if (!this.featureFlags.isEnabled('protect-badge-sign-in-notifications')) return false;

    return this.installedPlugins.any((installedPlugin) => {
      const isEventDrivenProtect = get(installedPlugin, 'config.eventDrivenProtect');

      return installedPlugin.status === 'active' && isEventDrivenProtect;
    });
  }

  @reads('loadConfigurationForLocationTask.lastSuccessful.value') employeeRegistrationConfiguration;
  @reads('loadInstalledPluginsTask.lastSuccessful.value', []) installedPlugins;

  @action
  onInsert() {
    this.loadConfigurationForLocationTask.perform();

    if (this.featureFlags.isEnabled('protect-badge-sign-in-notifications')) {
      this.loadInstalledPluginsTask.perform();
    }
  }

  loadConfigurationForLocationTask = task({ drop: true }, async () => {
    const locationId = this.args.location?.id;

    if (!locationId) return;

    const filter = { 'location-id': locationId };
    const employeeRegistrationConfigurations = await this.store.query('employee-registration-configuration', {
      filter,
    });

    return get(employeeRegistrationConfigurations, 'firstObject');
  });

  loadInstalledPluginsTask = task({ drop: true }, async () => {
    const locationId = this.args.location?.id;

    if (!locationId) return;

    const filter = { location: locationId };

    return await this.store.query('plugin-install', { filter });
  });

  updateConfigurationTask = task({ enqueue: true }, async (attribute, value) => {
    const { flashMessages, employeeRegistrationConfiguration } = this;

    set(employeeRegistrationConfiguration, attribute, value);

    try {
      await employeeRegistrationConfiguration.save();

      flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (_) {
      flashMessages.showFlash('error', 'Error', 'Something went wrong while saving those changes.');
    }
  });
}

import semver from 'semver';

export function isOsUpgradeAvailable(osVersion, maximumOsVersion) {
  osVersion = (osVersion || '').replace(/^(\d+)\.(\d+)$/, '$1.$2.0');
  const currentOsVersion = (maximumOsVersion || '').replace(/^(\d+)\.(\d+)$/, '$1.$2.0');

  if (semver.valid(osVersion) && semver.valid(currentOsVersion)) {
    return semver.lt(osVersion, currentOsVersion);
  }

  return osVersion !== currentOsVersion;
}

export function isAppUpgradeAvailable(appVersion, currentAppVersion) {
  return semver.valid(appVersion) && semver.valid(currentAppVersion) && semver.lt(appVersion, currentAppVersion);
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Ember from 'ember';
import type AbilitiesService from 'ember-can/services/abilities';
import { task } from 'ember-concurrency';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type ImpressionsService from 'garaje/services/impressions';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';

type ResourceOptions = {
  desk: DeskResource;
  [key: string]: unknown;
};

type DeskResource = {
  title: 'Desks';
  type: 'desk';
  geometryType: 'Point';
  icon: 'desk';
  disabled: boolean;
  tooltip: string | null;
  onClick: (desk: DeskResource) => void;
};

interface AnnouncementModalArgs {
  setPlacingResource: (resource: DeskResource) => void;
  placeResource: (desk: DeskResource) => void;
  resourceOptions: ResourceOptions;
  openResourcePanel: () => void;
}

export default class AnnouncementModalComponent extends Component<AnnouncementModalArgs> {
  @service declare impressions: ImpressionsService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare abilities: AbilitiesService;
  @tracked deskAutoGenerationAnnouncementModalDismissed = true;

  get announcementTextBeforeLink(): string {
    const OsCommand = navigator.userAgent.indexOf('Mac') !== -1 ? 'command ⌘' : 'control ⌃';
    return `Quickly configure your workplace map in Envoy! Simply hold ${OsCommand}, click and drag over the area where you want to place desks and Envoy will automatically detect the desks in your map and create them for you.
Need more support? Check out our `;
  }

  get announcementLinkText(): string {
    return 'help center article';
  }

  get announcementTextAfterLink(): string {
    return ' on placing desks';
  }

  @action
  async dismissAutoGenerationModal(): Promise<void> {
    this.deskAutoGenerationAnnouncementModalDismissed = true;
    await this.impressions.postImpression.perform(
      IMPRESSION_NAMES.MAP.DESK_AUTO_GENERATION_ANNOUNCEMENT_BANNER_DISMISSED,
    );
  }

  @action
  async dismissAutoGenerationModalAndNavigateToDeskGeneration(): Promise<void> {
    const { setPlacingResource, placeResource, resourceOptions, openResourcePanel } = this.args;
    const { desk } = resourceOptions;
    const deskResource: DeskResource = {
      title: 'Desks',
      type: 'desk',
      geometryType: 'Point',
      icon: 'desk',
      disabled: desk.disabled,
      tooltip: desk.tooltip,
      onClick: (resource: DeskResource) => {
        if (!resource.disabled) {
          placeResource(resource);
        }
      },
    };
    this.deskAutoGenerationAnnouncementModalDismissed = true;
    await this.impressions.postImpression.perform(
      IMPRESSION_NAMES.MAP.DESK_AUTO_GENERATION_ANNOUNCEMENT_BANNER_DISMISSED,
    );
    // set the placingResource to a 'Desk' so that the user can immediately try out the auto desk generation flow
    setPlacingResource(deskResource);
    openResourcePanel();
  }

  isAutoGenerationAnnouncementModalDismissedTask = task({ drop: true }, async () => {
    const closedImpressions = await this.impressions.getImpressions.perform(
      IMPRESSION_NAMES.MAP.DESK_AUTO_GENERATION_ANNOUNCEMENT_BANNER_DISMISSED,
    );
    this.deskAutoGenerationAnnouncementModalDismissed = isPresent(closedImpressions);
  });

  // we don't want to render this in most tests, so this getter allows us to prevents rendering in most cases
  get shouldRender(): boolean {
    if (this.abilities.cannot('edit maps')) {
      return false;
    }

    return !Ember.testing || this.featureFlags.isEnabled('should-render-announcement-modal');
  }
}

import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import {
  BILLING,
  EMPLOYEE,
  GLOBAL_ADMIN,
  LOCATION_ADMIN,
  RECEPTIONIST,
  DELIVERIES_ADMIN,
  SECURITY,
} from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_BULK_CHANGE_DELIVERY_AREA = [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, DELIVERIES_ADMIN];

const CAN_BULK_DELETE_DELIVERIES = [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, DELIVERIES_ADMIN];

const CAN_BULK_RESEND_NOTIFICATIONS = [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, DELIVERIES_ADMIN];

const CAN_EXPORT_DELIVERIES_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN];

const CAN_FILTER_BY_RECIPIENT_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, DELIVERIES_ADMIN];

const CAN_VIEW_ALL_DELIVERIES_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, DELIVERIES_ADMIN];

const CAN_VISIT_ROLES = [BILLING, EMPLOYEE, GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, DELIVERIES_ADMIN, SECURITY];

export default class DeliveryLogAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;

  get canBulkChangeDeliveryArea(): boolean {
    const { roleNames } = this.currentAdmin;
    const canChangeRoles = CAN_BULK_CHANGE_DELIVERY_AREA;

    return isPresent(_intersection(canChangeRoles, roleNames));
  }

  get canBulkDeleteDeliveries(): boolean {
    const { roleNames } = this.currentAdmin;
    const canDeleteRoles = CAN_BULK_DELETE_DELIVERIES;

    return isPresent(_intersection(canDeleteRoles, roleNames));
  }

  get canBulkResendNotifications(): boolean {
    const { roleNames } = this.currentAdmin;
    const canResendRoles = CAN_BULK_RESEND_NOTIFICATIONS;

    return isPresent(_intersection(canResendRoles, roleNames));
  }

  get canExportDeliveries(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_EXPORT_DELIVERIES_ROLES, roleNames));
  }

  get canFilterByRecipient(): boolean {
    const { roleNames } = this.currentAdmin;
    const canFilterRoles = CAN_FILTER_BY_RECIPIENT_ROLES;

    return isPresent(_intersection(canFilterRoles, roleNames));
  }

  get canViewAllDeliveries(): boolean {
    const { roleNames } = this.currentAdmin;
    const canViewAllRoles = CAN_VIEW_ALL_DELIVERIES_ROLES;

    return isPresent(_intersection(canViewAllRoles, roleNames));
  }

  get canViewUnidentifiedDeliveries(): boolean {
    return this.canViewAllDeliveries;
  }

  get canVisit(): boolean {
    const { roleNames } = this.currentAdmin;
    const canVisitRoles = CAN_VISIT_ROLES;

    return isPresent(_intersection(canVisitRoles, roleNames));
  }
}

import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type AbilitiesService from 'ember-can/services/abilities';
import type FlowModel from 'garaje/models/flow';
import type GlobalFlowModel from 'garaje/models/global-flow';
import type AuthzService from 'garaje/services/authz';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import { Permission } from 'garaje/utils/ui-permissions';

export default class VisitorTypeAbility extends Ability {
  @service declare abilities: AbilitiesService;
  @service declare authz: AuthzService;
  @service declare featureFlags: FeatureFlagsService;

  declare model?: GlobalFlowModel | FlowModel;

  get canView(): boolean {
    if (!this.featureFlags.isEnabled('custom-roles-garaje-authz-vis3-flows')) {
      return this.abilities.can('visit advance-settings');
    }

    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_FLOW_INSPECT);
  }

  get canEdit(): boolean {
    if (this.model?.isGlobal) return this.abilities.can('edit global-flow', this.model);

    if (!this.featureFlags.isEnabled('custom-roles-garaje-authz-vis3-flows')) {
      return this.abilities.can('visit advance-settings');
    }

    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_FLOW_UPDATE);
  }
}

// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { computed } from '@ember/object';

// eslint-disable-next-line ember/no-classic-classes
export default Component.extend({
  groupParents: computed('hasGroupParent', 'model.@each.hasAdditionalGuests', function () {
    if (!this.hasGroupParent) {
      return [];
    }

    return this.model.filter((entry) => entry.hasAdditionalGuests);
  }),

  init() {
    this._super(...arguments);

    if (!this.hasGroupParent) {
      this.signOutTask.perform(this.model);
    }
  },
});

import { isArray, A } from '@ember/array';
import Transform from '@ember-data/serializer/transform';
import _identity from 'lodash/identity';

export default class ArrayTransform extends Transform {
  deserialize(value, options = {}) {
    if (isArray(value)) {
      const deserializeItem = options.deserializeItem || _identity;
      value = (value ?? []).map((item) => deserializeItem(item));

      return A(value);
    } else {
      return A();
    }
  }

  serialize(value, options = {}) {
    if (isArray(value)) {
      const serializeItem = options.serializeItem || _identity;
      value = (value ?? []).map((item) => serializeItem(item));

      return A(value);
    } else {
      return A();
    }
  }
}

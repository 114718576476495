import { c as computeFn } from '../helper-compute-36ed98e8.js';
import BaseHelper from './-base.js';
import '@ember/utils';
import '@ember/runloop';
import '@ember/component/helper';
import '@ember/object';
import '@ember/service';

var momentDiff = BaseHelper.extend({
  compute: computeFn(function (params, {
    precision,
    float,
    locale,
    timeZone
  }) {
    this._super(...arguments);

    if (!params || params && params.length !== 2) {
      throw new TypeError('ember-moment: Invalid Number of arguments, must be 2');
    }

    const moment = this.moment;
    const [dateA, dateB] = params;
    return this.morphMoment(moment.moment(dateB), {
      locale,
      timeZone
    }).diff(dateA, precision, float);
  })
});

export { momentDiff as default };

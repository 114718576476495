import { action } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { and, reads, oneWay, or, notEmpty } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { type Task } from 'ember-concurrency';
import type CompanyModel from 'garaje/models/company';
import type LocationModel from 'garaje/models/location';
import type LocationSubscriptionModel from 'garaje/models/location-subscription';
import type SubscriptionModel from 'garaje/models/subscription';
import { type PluginPreference } from 'garaje/pods/components/notification-preferences-loader/component';
import type CurrentLocationService from 'garaje/services/current-location';

interface NotificationSettingsComponentSignature<T> {
  Args: {
    company: CompanyModel;
    isHubPreferencesEnabled: boolean;
    isReadOnly: boolean;
    model: T;
    pluginPreferences: PluginPreference[];
    saveTask: Task<unknown, [object, string, unknown]>;
    /**
     * Show settings items as SettingsPanel
     */
    showAsSP?: boolean;
    subscription: LocationSubscriptionModel | SubscriptionModel;
  };
}

export default class NotificationSettingsComponent<T> extends Component<NotificationSettingsComponentSignature<T>> {
  @service declare currentLocation: CurrentLocationService;

  @reads('currentLocation.location.slackNotificationEnabled')
  declare hasSlack: LocationModel['slackNotificationEnabled'];
  @oneWay('args.company.callEnabled') declare hasCalls: CompanyModel['callEnabled'];
  @or('hasSlack', 'hasNotificationPreferences') declare hasSlackOrNoticationPreferences: boolean;
  @notEmpty('args.pluginPreferences') declare pluginPreferencesPresent: boolean;
  @and('pluginPreferencesPresent', 'args.isHubPreferencesEnabled') declare hasNotificationPreferences: boolean;
  @or('hasSlack', 'hasCalls', 'hasNotificationPreferences') declare hasOthers: boolean;

  /*
   * This action is overloaded to not only set properties on `@model` but also to update  notification preferences
   * (from `@pluginPreferences`) when `@isHubPreferencesEnabled` is true, which is why `model` is declared as an
   * `object` type rather than using `T` (or using `this.args.model` directly).
   */
  @action
  changeValue(model: object, property: string, value: unknown): boolean {
    void this.args.saveTask.perform(model, property, value);

    return true;
  }
}

// https://gist.github.com/amk221/1bea7a783f76413e2e47
import Ember from 'ember';
import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';
const { escapeExpression } = Ember.Handlebars.Utils;

/*
 * This helper escapes a value, useful for building styles
 *
 * @example
 *   <div style={{escape (concat 'color:' ugcColour)}}></div>
 *
 */
export default helper(function (args = []) {
  return htmlSafe(escapeExpression(args[0]));
});

import LocationFragment from 'garaje/graphql/fragments/roomba/LocationFragment';
import gql from 'graphql-tag';

export default gql`
  mutation CreateLocation($input: LocationInput!) {
    createLocation(input: $input) {
      ...LocationFragment
    }
  }
  ${LocationFragment}
`;

import Component from '@glimmer/component';
import { reject } from 'rsvp';
import { reads } from 'macro-decorators';
import { action, get, set } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { validateFormat } from 'ember-changeset-validations/validators';
import { inject as service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

const Validations = {
  url: validateFormat({
    type: 'url',
    message: 'must be a valid URL',
    allowBlank: false,
  }),
};

/**
 * @param {Object}              webhook
 * @param {String}              integration
 * @param {Function}            openAction
 * @param {Function}            closeAction
 * @param {Function}            integrationDisconnected
 */
export default class WebhookConfigBox extends Component {
  @service flashMessages;

  @reads('changeset.enabled') enabled;

  @tracked changeset;

  // Check if the record exist, we don't care about the state or if is new.
  get connected() {
    return !isEmpty(this.args.webhook);
  }

  get isOpen() {
    return this.args.integration === 'webhook';
  }

  @action
  setupChangeset() {
    if (!this.connected) return null;

    const validator = lookupValidator(Validations);
    this.changeset = new Changeset(this.args.webhook, validator, Validations);
  }

  confirmAction() {
    return window.confirm('You will lose any unsaved changes. Are you sure you want to continue?');
  }

  destroyRecord() {
    return this.args.webhook.destroyRecord();
  }

  @action
  close() {
    // changes only apply to the changeset if they are valid, you can
    // have "changes" in the UI but the changeset might return
    // "isDirty" as false since the change didn't go through.
    if ((get(this.changeset, 'isDirty') || get(this.changeset, 'isInvalid')) && !get(this.changeset, 'isNew')) {
      if (this.confirmAction()) {
        this.changeset.rollback();
      } else {
        return;
      }
    }

    if (get(this.changeset, 'isNew')) {
      if (this.confirmAction()) {
        this.destroyRecord().then(() => {
          this.args.integrationDisconnected();
        });
      } else {
        return;
      }
    }

    this.args.closeAction();
  }

  @action
  open() {
    this.args.openAction('webhook');
  }

  confirmDisconnect() {
    return window.confirm('Are you sure you want to disconnect this integration?');
  }

  @dropTask
  *disconnectTask() {
    if (!this.confirmDisconnect()) {
      return;
    }

    try {
      yield this.destroyRecord();
      this.close();
      this.args.integrationDisconnected();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));
    }
  }

  @dropTask
  *saveTask() {
    try {
      const changeset = this.changeset;
      yield changeset.validate();
      if (!get(this.changeset, 'isValid')) {
        return reject(false);
      }
      yield changeset.save();
      this.close();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));
    }
  }

  @dropTask
  *disableTask() {
    try {
      set(this.changeset, 'enabled', false);
      yield this.changeset.save();
      this.close();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (error) {
      set(this.changeset, 'enabled', true);
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));
    }
  }

  @dropTask
  *enableTask() {
    try {
      set(this.changeset, 'enabled', true);
      yield this.changeset.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (error) {
      set(this.changeset, 'enabled', false);
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));
    }
  }
}

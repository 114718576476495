import Transform from '@ember-data/serializer/transform';
import { isArray, A } from '@ember/array';
import { typeOf } from '@ember/utils';
import UserDatum from 'garaje/models/user-datum';

export default class UserDataTransform extends Transform {
  deserialize(val) {
    if (isArray(val)) {
      return A(val.map(({ field, value }) => new UserDatum({ field, value })));
    } else if (typeOf(val) === 'object') {
      // `previous-entries` returns user-data in an object (ie. { Host: abc, field: answer, ...})
      return A(Object.entries(val).map(([field, value]) => new UserDatum({ field, value })));
    } else {
      // eslint-disable-next-line no-console
      console.warn(`unsupported deserialize user-data type ${typeof val}: ${val}`);
    }
  }

  serialize(val) {
    if (isArray(val)) {
      return val.map((userDatum) => userDatum.toJSON());
    } else {
      // eslint-disable-next-line no-console
      console.warn(`unsupported serialize user-data type ${typeof val}: ${val}`);
    }
  }
}

import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import ManageCreateRoleRoute from 'garaje/pods/manage/create-role/route';
import { roleIds } from 'garaje/utils/custom-roles';

class ManageEditRoleRoute extends ManageCreateRoleRoute {
  controllerName = 'manage/create-role';
  templateName = 'manage/create-role';

  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  titleToken = 'Edit role';

  beforeModel(): void {
    const legacyRoleEdit =
      this.router.currentURL.split('/').filter((urlSplit) => roleIds.includes(urlSplit)).length > 0;

    if (this.abilities.cannot('open-edit custom-roles') || legacyRoleEdit) {
      void this.router.transitionTo('unauthorized');
    }
  }
}

export default ManageEditRoleRoute;

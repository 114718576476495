import Model from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

import type CompanyRoleModel from './company-role';
import type LocationRoleModel from './location-role';
import type ZoneRoleModel from './zone-role';
/*
  This used to be the parent class of Company Roles and Location Roles,
  but they have been refactored into independent classes. However,
  we still use user role in queries to load company + location role together
  so we can't remove it.
*/
@adapter('v2')
class UserRole extends Model {}

export default UserRole;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-role': ZoneRoleModel | LocationRoleModel | CompanyRoleModel;
  }
}

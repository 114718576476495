// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type ModelRegistry from 'ember-data/types/registries/model';
import ApplicationSerializer from 'garaje/serializers/application';

export default class VfdCallLogSerializer extends ApplicationSerializer {
  modelNameFromPayloadKey(key: string): string {
    if (key === 'locations') {
      return 'skinny-location';
    } else {
      return super.modelNameFromPayloadKey(key);
    }
  }

  payloadKeyFromModelName<K extends keyof ModelRegistry>(key: K): string {
    if (key === 'skinny-location') {
      return 'locations';
    } else {
      return super.payloadKeyFromModelName(key);
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'vfd-call-log': VfdCallLogSerializer;
  }
}

import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { equal, not, or } from 'macro-decorators';

export default class SettingsWelcomeScreenDeliveriesController extends Controller {
  @equal('model.employees.length', 0) doesNotHaveEmployees;
  @not('model.currentLocation.hasNotificationEnabled') doesNotHaveNotification;
  @or('doesNotHaveNotification', 'doesNotHaveEmployees') hasUnmetPreconditions;

  get isDirty() {
    const deliveryPersonHasChanged =
      this.model.currentDeliveryPerson &&
      this.model.currentDeliveryPerson.id !== this.model.config.belongsTo('deliveryPerson').id();

    return this.model.config.hasDirtyAttributes || deliveryPersonHasChanged;
  }

  @action
  rollback() {
    set(this.model.config, 'deliveryPerson', this.model.currentDeliveryPerson);
    this.model.config.rollbackAttributes();
  }

  @action
  didSave() {
    set(this.model, 'currentDeliveryPerson', this.model.config.belongsTo('deliveryPerson').value());
  }
}

import type { Relationship } from 'jsonapi/types';

interface Relationships {
  floor: {
    data: Relationship;
  };
  neighborhood?: {
    data: Relationship;
  };
  location: {
    data: Relationship;
  };
  company: {
    data: Relationship;
  };
  amenities?: {
    data: Relationship[];
  };
  assignments?: {
    data: Relationship[];
  };
  draft?: {
    data: Relationship;
  };
}

interface Attributes {
  'assigned-to': string;
  enabled: boolean;
  name: string;
  neighborhood?: string | null;
  'neighborhood-id'?: string | null;
  'x-pos': number;
  'y-pos': number;
  availability: string;
  accessible: boolean;
  'parent-desk-id': number;
}

/**
 * Function that takes a JSON API-compliant response from the server and converts it to an object that can be inserted
 * into the ember store
 * @param desk - represents a JSON API compliant representation of a desk
 * @param neighborhood that the desk is associated with, if there is one
 * @returns a desk object that can be pushed into the ember data store via the pushPayload function
 */
function createDeskStorePayload(desk: Desk): { data: Desk } {
  // if the backend returns an empty string for the neighborhood name, since desk.neighborhood is deprecated
  // we want to simply insert the record with an undefined neighborhood name
  if (desk.attributes.neighborhood === '') {
    desk.attributes.neighborhood = undefined;
  }
  // TODO: add the included key from the response into the store
  return {
    data: {
      id: desk.id,
      type: 'desk',
      attributes: desk.attributes,
      relationships: desk.relationships,
    },
  };
}

/**
 * A JSON API-compliant representation of a desk
 */
interface Desk {
  /**
   * A unique identifier for the desk.
   */
  id: string;

  /**
   * The type of the desk, always set to 'desk'.
   */
  type: 'desk';

  /**
   * Describes various attributes of the desk
   */
  attributes: Attributes;

  /**
   * Describes relationships array of the desk
   */
  relationships: Relationships;
}

export default createDeskStorePayload;

import Model, { attr, belongsTo } from '@ember-data/model';
import type EmployeeModel from 'garaje/models/employee';
import type UserModel from 'garaje/models/user';
import adapter from 'garaje/utils/decorators/adapter';

import type ZoneModel from './zone';

@adapter('v2')
class ZoneRoleModel extends Model {
  @belongsTo('zone') declare zone: ZoneModel;
  @belongsTo('employee') declare employee: EmployeeModel;
  @belongsTo('user') declare user: UserModel;

  @attr('date') declare confirmedAt: Date;
  @attr('date') declare createdAt: Date;
  @attr('date') declare lastLogin: Date;
  @attr('date') declare lastSeenAt: Date;
  @attr('string') declare roleName: string;
  @attr('boolean') declare manuallyAdded: boolean;
}

export default ZoneRoleModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'zone-role': ZoneRoleModel;
  }
}

import { makeArray } from '@ember/array';

// https://ember-twiddle.com/623b7a29a0cd899b54a66c3dda56f53a?numColumns=2&openFiles=validators.conditional.js%2Ccontrollers.application.js

export default function conditional(options) {
  const thenValidators = makeArray(options.then);
  const elseValidators = makeArray(options.else);
  const max = Math.max(thenValidators.length, elseValidators.length);

  // A single validator can't return an array of validations,
  // so we return an array of validators instead :/
  const validators = [];
  for (let i = 0; i < max; i++) {
    validators.push(makeValidator(options.if, thenValidators[i], elseValidators[i]));
  }
  return validators;
}

function makeValidator(condition, thenValidator, elseValidator) {
  return (key, newValue, oldValue, changes, content) => {
    const conditionPassed = condition(key, newValue, oldValue, changes, content);
    const validator = conditionPassed ? thenValidator : elseValidator;
    return !validator || validator(key, newValue, oldValue, changes, content);
  };
}

import Model, { attr, hasMany, belongsTo, type AsyncHasMany } from '@ember-data/model';
import type DeskModel from 'garaje/models/desk';
import type LocationModel from 'garaje/models/location';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('rms')
class AmenityModel extends Model {
  // Attributes
  @attr('string') declare name: string;
  @attr('number', { defaultValue: 0 }) declare deskCount: number;
  @attr('number') declare position: number;

  // Relationships
  @hasMany('desk') declare desks: AsyncHasMany<DeskModel>;
  @belongsTo('location') declare location: LocationModel;
}

export default AmenityModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    amenity: AmenityModel;
  }
}

// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';

/**
 * Modal to selected a group to assign to multiple locations
 * @param {Array<Group>}             groups                List of available groups to be assigned
 * @param {Task}                     assignGroupTask
 */

export default class LocationsTableBulkModalAssignmentComponent extends Component {
  /**
   * @type {Group}
   */
  selectedGroup = null;
}

import { action } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type LocationModel from 'garaje/models/location';
import type LocationSubscriptionModel from 'garaje/models/location-subscription';
import type SubscriptionModel from 'garaje/models/subscription';
import type StateService from 'garaje/services/state';
import { hash } from 'rsvp';

export interface VisitorsSettingsLocationRouteModel {
  currentLocation: LocationModel;
  vrSubscription: LocationSubscriptionModel | SubscriptionModel | null;
}

class VisitorsSettingsLocationRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  titleToken = 'Account Settings';

  beforeModel(): void {
    if (this.abilities.cannot('visit advance-settings')) {
      if (this.abilities.can('visit security')) {
        void this.router.transitionTo('security');
      } else if (this.abilities.can('visit billing')) {
        void this.router.transitionTo('billing');
      } else {
        void this.router.transitionTo('unauthorized');
      }
    }
  }

  async model(): Promise<VisitorsSettingsLocationRouteModel> {
    const currentLocationId = (<LocationModel>this.modelFor('current-location')).id;
    const currentLocation = await this.store.findRecord('location', currentLocationId, { reload: true });
    const { vrSubscription } = this.state;

    return hash({
      currentLocation,
      vrSubscription,
    });
  }

  @action
  backToLocation(): void {
    void this.router.transitionTo('visitors.settings.location');
  }
}

export default VisitorsSettingsLocationRoute;

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default class SettingsVisitorEntranceRoute extends Route {
  @service store;

  async model({ visitor_entrance_id }) {
    const visitorEntrance = await this.store.findRecord('visitor-entrance', visitor_entrance_id);

    const ipads = this.store.query('device', {
      filter: { visitor_entrance: visitor_entrance_id },
      sort: 'name',
      include: 'printer',
    });
    const printers = this.store.query('printer', {
      filter: { visitor_entrance: visitor_entrance_id },
      include: 'devices,location',
    });

    return hash({ visitorEntrance, ipads, printers });
  }
}

import DeliveriesAdapter from './deliveries';
import _chunk from 'lodash/chunk';

const MAX_PAGE_SIZE = 50;

export default class RecipientAliasAdapter extends DeliveriesAdapter {
  coalesceFindRequests = true;
  // Group requests into chunks of 50
  groupRecordsForFindMany() {
    const [snapshots] = super.groupRecordsForFindMany(...arguments);
    return _chunk(snapshots, MAX_PAGE_SIZE);
  }
}

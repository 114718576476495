import { action } from '@ember/object';
import Component from '@glimmer/component';
import { type TaskInstance, type Task, task } from 'ember-concurrency';

/**
 * @param {Task}                        confirmTicketDeletionModalTask   Required - Task that controls the modal
 */

interface ConfirmCategoryDeleteModalArgs {
  confirmTicketDeletionModalTask: Task<void, []>;
}

interface MyTaskInstance extends TaskInstance<void> {
  continue: () => Promise<void>;
  abort: () => void;
}

export default class ConfirmCategoryDeleteModalComponent extends Component<ConfirmCategoryDeleteModalArgs> {
  onSubmitTask = task({ drop: true }, async (e: Event) => {
    e.preventDefault();
    const { confirmTicketDeletionModalTask } = this.args;
    const confirmTask = confirmTicketDeletionModalTask.last as MyTaskInstance;
    await confirmTask?.continue();
  });

  @action
  onCancel(): void {
    const { confirmTicketDeletionModalTask } = this.args;
    const confirmTask = confirmTicketDeletionModalTask.last as MyTaskInstance;
    confirmTask?.abort();
  }
}

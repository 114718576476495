import Component from '@glimmer/component';
import moment from 'moment-timezone';

/**
 * @param {Date}                    currentMonth
 * @param {Date}                    startDate
 * @param {Array}                   selectedDates
 * @param {Date}                    selectedStartDate
 * @param {Date}                    selectedEndDate
 * @param {Boolean}                 respectSchedulingLimits
 * @param {Boolean}                 hideTrailingDayCells
 * @param {Boolean}                 hideLeadingDayCells
 * @param {Array<Object>}           events
 * @param {Function}                selectDate
 */
export default class WeekRow extends Component {
  get daysArray() {
    const startDate = moment(this.args.startDate);

    const days = [];
    let snapshot;

    const events = this.args.events.map((event) => event.format('YYYYMMDD'));

    for (let i = 0; i < 7; i++) {
      snapshot = startDate.clone();

      const hasEvents = events.includes(snapshot.format('YYYYMMDD'));

      days.push({ date: snapshot, events: hasEvents });

      startDate.add(1, 'day');
    }

    return days;
  }
}

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { isAppUpdateRequired } from 'garaje/utils/check-app-version';
import zft from 'garaje/utils/zero-for-tests';

import { type LocationOverviewVirtualFrontDeskLocationsIndexModel } from './route';

const MINIMUM_IPAD_VERSION_NEEDED_FOR_VFD = '4.27.0';
const DEBOUNCE_MS = 250;

export default class LocationOverviewVirtualFrontDeskLocationsIndexController extends Controller {
  declare model: LocationOverviewVirtualFrontDeskLocationsIndexModel;

  queryParams = ['sortBy', 'sortDirection', 'locationStatus', 'query'];

  @tracked sortBy?: string;
  @tracked sortDirection?: 'asc' | 'desc';
  @tracked locationStatus = 'all';
  @tracked query = '';

  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;

  minimumIpadVersionNeededForVfd = MINIMUM_IPAD_VERSION_NEEDED_FOR_VFD;

  @isAppUpdateRequired('model.loadIpadsTaskInstance.value', MINIMUM_IPAD_VERSION_NEEDED_FOR_VFD)
  isAppUpdateRequiredForVfd!: boolean;

  get enabledVfdCount(): number {
    return this.model.configurations.filter((config) => config.enabled).length;
  }

  // TODO: quantity needs to be included in features API
  get vfdSubscriptionQty(): number {
    if (!this.state.features?.canAccessVirtualFrontDesk) return 0;

    return this.state.vfdSubscription?.quantity || 0;
  }

  get remainingInSubscription(): number {
    return Math.max(this.vfdSubscriptionQty - this.enabledVfdCount, 0);
  }

  // for beta customers have VFD on visitors subscription, we won't do any quantity gating.
  // it needs to cleanup after VFD GA.
  get hasQuantityGating(): boolean {
    return !!this.state.vfdSubscription && !this.onTrial;
  }

  get trialDaysLeft(): number | undefined {
    return this.state.vfdSubscription?.trialDaysLeft;
  }

  get onTrial(): boolean | undefined {
    return this.state.vfdSubscription?.onTrial;
  }

  // turn it ON for vfd M2
  get noNeedVisitors(): boolean {
    return this.featureFlags.isEnabled('growth_vfd_without_visitors');
  }

  searchTask = restartableTask(async (term: string) => {
    await timeout(zft(DEBOUNCE_MS));
    this.query = term;
  });

  @action
  handleSort(sortBy: string, sortDirection: 'asc' | 'desc'): void {
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
  }
}

/* eslint-disable ember/no-computed-properties-in-native-classes */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import SyncSettingsObject from 'garaje/utils/decorators/sync-settings-object';

/**
 * @param {Model<Company>}            company
 * @param {Array}                     locations
 * @param {Array}                     filterOptions
 * @param {Array}                     directoryGroups
 * @param {Function}                  showFlash
 * @param {Function}                  hideFlash
 * @param {Function}                  handleFailedQuery
 * @param {Function}                  addFilter
 * @param {Function}                  removeFilter
 */
@SyncSettingsObject
class OneLogin extends Component {
  @alias('company.oneLogin') integrationData;

  filterComponents = [
    'employees/sync-settings/all-filter',
    'employees/sync-settings/company-filter',
    'employees/sync-settings/locations-filter',
  ];
}

export default OneLogin;

import Model, { attr, belongsTo } from '@ember-data/model';

class ScheduledEmployee extends Model {
  @belongsTo('employee', { inverse: null }) employee;
  @belongsTo('user', { inverse: null }) user;

  @attr('string') email;
  @attr('string') name;
  @attr('date') date;
  @attr('boolean') scheduled;
  @attr('string') profilePictureUrl;
}

export default ScheduledEmployee;

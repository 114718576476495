import type { AsyncBelongsTo } from '@ember-data/model';
import { belongsTo } from '@ember-data/model';
import AbstractAgreementModel from 'garaje/models/abstract/abstract-agreement';
import type EmployeeAgreementPageModel from 'garaje/models/employee-agreement-page';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('agreement')
class EmployeeAgreementModel extends AbstractAgreementModel {
  @belongsTo('employee-agreement-page') declare agreementPage: AsyncBelongsTo<EmployeeAgreementPageModel>;
}

export default EmployeeAgreementModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'employee-agreement': EmployeeAgreementModel;
  }
}

import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import { Ability } from 'ember-can';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import type CurrentAdminService from 'garaje/services/current-admin';
import type GlobalOverviewAccessService from 'garaje/services/global-overview-access';
import type StateService from 'garaje/services/state';

export default class GlobalVisitorAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare skinnyLocations: SkinnyLocationModel;
  @service declare state: StateService;
  @service declare globalOverviewAccess: GlobalOverviewAccessService;

  get canVisit(): boolean {
    const { vrSubscription } = this.state;
    if (isBlank(vrSubscription) || !!vrSubscription?.isBasicUser) {
      return false;
    }

    return this.globalOverviewAccess.hasAnyGlobalVisitorAbility;
  }
}

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

/**
 * @param {Object}  metaData
 */
export default class UpgradeRequiredComponent extends Component {
  @service metrics;

  title = 'Wow, you’re really growing!';

  get upgradeLinkText() {
    if (this.employeeLimitReached) {
      return 'Premium plan';
    }
    return 'Standard plan';
  }

  get employeeLimitReached() {
    return this.args.metaData?.employeeLimitReached;
  }

  get employeeLimit() {
    return this.args.metaData?.employeeLimit;
  }

  get isHidden() {
    return this.args.metaData?.hidden;
  }

  get locationLimitReached() {
    return this.args.metaData?.locationLimitReached;
  }

  get eventProperties() {
    let cta_body = '';
    if (this.locationLimitReached) {
      cta_body = 'more than one location';
    } else if (this.employeeLimitReached) {
      cta_body = `more than ${this.employeeLimit || '50'} employees`;
    }
    return {
      cta_body,
      cta_clickable_type: 'link',
      cta_clickable_text: this.upgradeLinkText,
      cta_id: 'upgrade_required_banner',
      cta_title: this.title,
      cta_type: 'banner',
      cta_intent: 'upgrade',
    };
  }

  @action
  logClickedUpgradeLink() {
    this.metrics.trackEvent('CTA Clicked', this.eventProperties);
  }

  @action
  logShowed() {
    this.metrics.trackEvent('CTA Viewed', this.eventProperties);
  }
}

import { helper } from '@ember/component/helper';

export function removeYour(params /*, hash*/) {
  let [value] = params;
  if (value.match(/^your /i) !== null) {
    const words = value.split(' ');
    words.shift();
    value = words.join(' ');
  }
  return value;
}

export default helper(removeYour);

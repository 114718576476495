import Model, { attr, belongsTo } from '@ember-data/model';
import type CompanyModel from 'garaje/models/company';
import adapter from 'garaje/utils/decorators/adapter';
import { equal } from 'macro-decorators';

const PENDING_STATUS = 'pending';
const SUCCESS_STATUS = 'success';

@adapter('billing-v2')
class SubscriptionBatchModel extends Model {
  // Create Model with top 2 fields - status provided on future gets
  @belongsTo('company', { async: false }) declare company: CompanyModel;
  @attr('array') declare products: string[];
  // Not sure on return of above in response - may need to investigate adapter

  @attr('string') declare status: 'pending' | 'success' | 'failure';

  @equal('status', PENDING_STATUS) isPending!: boolean;
  @equal('status', SUCCESS_STATUS) isSuccessful!: boolean;
}

export default SubscriptionBatchModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'subscription-batch': SubscriptionBatchModel;
  }
}

interface SanitizeMarkupOptions {
  permittedTags: string[];
}

/**
 *
 * Sanitizes a markup string to be safely rendered
 *
 * @param value string to sanitize
 * @param options options for sanitization
 * @returns sanitized value
 */
export function sanitizeMarkup(value: string, options: SanitizeMarkupOptions): string {
  const { permittedTags } = options;

  const parser = new DOMParser();
  const doc = parser.parseFromString(value, 'text/html');

  doc.body.querySelectorAll('*').forEach((node) => {
    if (!permittedTags.includes(node.tagName.toLowerCase())) {
      node.remove();
    }
  });

  return doc.body.innerHTML;
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';
import zft from 'garaje/utils/zero-for-tests';
import ENV from 'garaje/config/environment';

const { animationSpeed } = ENV;

const DURATION = zft(animationSpeed);

export default class AnimatedCloseComponent extends Component {
  @tracked hideComponent = false;

  get duration() {
    return this.args.duration || DURATION;
  }

  /* eslint-disable require-yield */
  *transition({ insertedSprites, removedSprites }) {
    insertedSprites.forEach((sprite) => {
      fadeIn(sprite, { duration: DURATION / 2 });
    });
    removedSprites.forEach((sprite) => {
      fadeOut(sprite, { duration: DURATION / 2 });
    });
  }
  /* eslint-enable require-yield */

  @action
  closeAction() {
    // TODO: persist choice to backend via API call
    this.hideComponent = true;

    if (typeof this.args.closeAction === 'function') {
      this.args.closeAction();
    }
  }
}

import { service } from '@ember/service';
import Component from '@glimmer/component';
import type StateService from 'garaje/services/state';

interface DesksMenuArgs {
  trackClickEvent: (trackingString: string) => void;
}

export default class DesksMenuComponent extends Component<DesksMenuArgs> {
  @service declare state: StateService;
}

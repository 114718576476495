import type Transition from '@ember/routing/transition';
import Service from '@ember/service';

/**
 * This service exists to provide shared state for storing a transition that should be
 * retried after a successful login. It is not generally necessary to use this service,
 * unless you need this specific functionality.
 */
export default class LoginService extends Service {
  transition: Transition | null = null;
}

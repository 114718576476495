import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { all, dropTask } from 'ember-concurrency';
import { defer } from 'rsvp';
import { inject as service } from '@ember/service';

/**
 * @param {Function}    bulkActions         generate list of action to perform
 * @param {Function}    afterChange         items to perform bulk action
 * @param {Array}       selectedItems       method to invoke after deleted
 * @param {String}      confirmTitle        confirmation modal title
 * @param {String}      confirmMessage      confirmation modal content
 * @param {String}      successMessage      success flash message
 * @param {String}      failedMessage       failed flash message
 * @param {String}      actionButtonLabel   confirm action label
 */
export default class BulkActionConfirm extends Component {
  @service flashMessages;

  @tracked selectedItems;

  constructor() {
    super(...arguments);
    this.selectedItems = this.args.selectedItems;
  }

  @dropTask
  confirmBulkActionTask = {
    *perform() {
      const deferred = defer();

      this.abort = () => deferred.resolve(false);
      this.continue = () => deferred.resolve(true);

      return yield deferred.promise;
    },
  };

  @dropTask
  *confirmBulkActions() {
    const selectedItems = this.selectedItems;
    const flashMessages = this.flashMessages;
    const confirmed = yield this.confirmBulkActionTask.perform();

    if (!confirmed) {
      return;
    }

    const actions = this.args.bulkActions(selectedItems);

    try {
      yield all(actions);
      flashMessages.showAndHideFlash('success', this.args.successMessage);
      this.selectedItems = [];
      this.args.afterChange();
    } catch (error) {
      flashMessages.showAndHideFlash('error', this.args.failedMessage);
    }
  }
}

import Component from '@glimmer/component';

export default class VisitorsPresentCapacityComponent extends Component {
  get onSiteCount() {
    return this.args.onSiteCount || 0;
  }

  get expectedCount() {
    return this.args.expectedCount || 0;
  }

  get capacityLimit() {
    return this.args.capacityLimit || 0;
  }

  get capacityUsed() {
    return this.onSiteCount + this.expectedCount;
  }

  get capacityRemaining() {
    return Math.max(this.capacityLimit - this.capacityUsed, 0);
  }
}

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';

import droppableUpload from 'garaje/utils/decorators/droppable-upload';
import urlBuilder from 'garaje/utils/url-builder';

/**
 * @param {Function}                                       setWelcomeImage
 * @param {Changeset<Config | PropertyDeviceConfig>}       changeset
 * @param {Function}                                       deleteWelcomeImage
 * @param {Reference}                                      parent
 */

@droppableUpload
class CustomizeWelcomeImageLogo extends Component {
  @service messageBus;
  @service flashMessages;

  @tracked isEditingWelcomeImage = false;
  @tracked isWelcomeImageUploading = false;

  @tracked pendingUpload = null;

  validFileTypes = /image\/(gif|jpe?g|png)/;

  get isDisabled() {
    return this.isSaving || this.args.changeset.isPristine;
  }

  get isSaving() {
    return this.saveTask.isRunning || this.isWelcomeImageUploading;
  }

  get welcomeImageUploadUrl() {
    return urlBuilder.v2.config.attachmentsUrl(this.args.changeset.id, 'welcome-image');
  }

  @action
  handleDidInsertElement() {
    this.messageBus.on('expandWelcomeImage', this, this.enableWelcomeImage);
  }

  @action
  handleWillDestroy() {
    this.messageBus.off('expandWelcomeImage', this, this.enableWelcomeImage);
  }

  @action
  enableWelcomeImage() {
    this.isEditingWelcomeImage = true;
  }

  @action
  setPendingUpload([pendingFile], pendingUpload) {
    this.pendingUpload = pendingUpload;
    // if preview fails for some reason, use file name to mark model as dirty
    this.args.changeset.welcomeImage = pendingFile.preview?.src ?? pendingFile.file?.name;
  }

  @action
  delete() {
    this.args.changeset.welcomeImage = null;

    if (this.pendingUpload) {
      this.pendingUpload.clear();
      this.pendingUpload = null;
    }
  }

  @action
  allUploaded() {
    this.isWelcomeImageUploading = false;
    this.isEditingWelcomeImage = false;
    // changes get serialized in callback so we need to rollback
    this.args.changeset.rollback();

    this.reset();
  }

  @action
  cancel() {
    this.isEditingWelcomeImage = false;
    this.args.changeset.rollback();
    this.reset();
  }

  @dropTask
  *saveTask() {
    if (this.args.changeset.welcomeImage) {
      this.pendingUpload.upload();
    } else {
      yield this.args.deleteWelcomeImage();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      // changes get serialized in callback so we need to rollback
      this.args.changeset.rollback();
      this.isEditingWelcomeImage = false;
      this.reset();
    }
  }

  @action
  reset() {
    this.pendingUpload = null;
  }
}

export default CustomizeWelcomeImageLogo;

import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { service } from '@ember/service';

export default class MapsLiveIndexRoute extends Route {
  @service store;

  async beforeModel() {
    const { areaMap, currentUserReservationMapFloor } = this.modelFor('spaces.maps');
    const firstMapFloor = await get(areaMap, 'mapFloors').sortBy('ordinality').firstObject;
    if (currentUserReservationMapFloor?.rasterImageUrl) {
      this.transitionTo('spaces.maps.live.show', currentUserReservationMapFloor.id); // eslint-disable-line ember/no-deprecated-router-transition-methods
    } else if (firstMapFloor) {
      this.transitionTo('spaces.maps.live.show', firstMapFloor.id); // eslint-disable-line ember/no-deprecated-router-transition-methods
    }
  }

  model() {
    const { areaMap } = this.modelFor('spaces.maps');
    return { areaMap };
  }
}

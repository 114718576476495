import { service } from '@ember/service';
import Component from '@glimmer/component';
import type StateService from 'garaje/services/state';

interface ManageMenuArgs {
  trackClickEvent: (trackingString: string) => void;
}

export default class ManageMenuComponent extends Component<ManageMenuArgs> {
  @service declare state: StateService;

  get manageLocationSettingsRegex(): RegExp {
    return /^manage\.location-settings/;
  }
}

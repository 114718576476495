import Service, { service } from '@ember/service';
import { task } from 'ember-concurrency';
import type AjaxService from 'garaje/services/ajax';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

const URL = 'https://api.chilipiper.com/marketing/envoy';

//This is a service that handles the the POST request to the Chili Piper API. This service is used to schedule meetings with BDRs via the Contact a BDR Growth Project.

export default class ChiliPiperService extends Service {
  @service declare state: StateService;
  @service declare metrics: MetricsService;
  @service declare ajax: AjaxService;

  getMeetingUrl = task({ drop: true }, async (formData: Record<string, unknown>) => {
    const settings = {
      URL,
      type: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: {
        form: formData,
        options: {
          router: 'inbound_contact_router',
        },
      },
    };

    try {
      return await this.ajax.request<string>(URL, settings);
    } catch (e) {
      const error_message = parseErrorForDisplay(e);
      this.metrics.trackEvent('Chili Piper Meeting Scheduling Failed', {
        type: 'error',
        message_title: error_message,
        company: formData['company'],
        location_id: this.state.currentLocation.id,
        company_id: this.state.currentCompany.id,
      });
      return;
    }
  });
}

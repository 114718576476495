import adapter from 'garaje/utils/decorators/adapter';

import EmployeeGroupModel from './employee-group';

@adapter('v3-coalesce')
class DepartmentEmployeeGroupModel extends EmployeeGroupModel {}

export default DepartmentEmployeeGroupModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'department-employee-group': DepartmentEmployeeGroupModel;
  }
}

import Route from '@ember/routing/route';
import { next } from '@ember/runloop';
import { hash } from 'rsvp';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import urlBuilder from 'garaje/utils/url-builder';
import { routeEvent } from 'garaje/utils/decorators/route';

class EmployeesSyncSettingsRoute extends Route {
  @service abilities;
  @service ajax;
  @service state;
  @service currentLocation;
  @service googleSyncManager;
  @service integrationsManager;
  @service router;
  @service store;

  titleToken = 'Sync settings';

  beforeModel() {
    if (this.abilities.cannot('visit sync-settings')) {
      this.router.transitionTo('unauthorized');
    }
  }

  model() {
    const { currentCompany } = this.state;
    const integrationsHash = {
      integrations: this.integrationsManager.loadIntegration(currentCompany.id),
    };

    return hash(integrationsHash);
  }

  afterModel(model) {
    const directorySyncProvider = get(this.currentLocation, 'company.directorySyncProvider');
    if (!directorySyncProvider) {
      this.router.transitionTo('employees.directory');
    } else {
      let url;

      if (get(this.currentLocation, 'company.oneLogin.content')) {
        url = urlBuilder.v2.integrations.oneLoginCustomAttributesUrl(get(this.currentLocation, 'company.oneLogin.id'));

        model.filterOptions = this.ajax.request(url).then(
          function (response) {
            return response.data;
          },
          function () {
            return [];
          },
        );
      } else if (get(this.currentLocation, 'company.oktaIntegration.content')) {
        url = urlBuilder.v2.integrations.oktaCustomAttributesUrl(
          get(this.currentLocation, 'company.oktaIntegration.id'),
        );

        model.filterOptions = this.ajax.request(url).then(function (response) {
          return response.data;
        });

        const groupsUrl = urlBuilder.v2.integrations.oktaGroupsUrl(
          get(this.currentLocation, 'company.directorySyncProvider.id'),
        );

        model.directoryGroups = this.ajax.request(groupsUrl).then(
          function (response) {
            return response.data;
          },
          function () {
            return [];
          },
        );
      }
    }

    return hash(model);
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.filterOptions = [];
    controller.directoryGroups = [];

    if (model.filterOptions) {
      model.filterOptions.then((result) => {
        controller.filterOptions = result;
      });
    }

    if (model.directoryGroups) {
      model.directoryGroups.then((result) => {
        controller.directoryGroups = result;
      });
    }
  }

  @routeEvent
  routeWillChange(transition) {
    let employeesFilter;

    if (
      get(this.currentLocation, 'company.oneLogin.content') ||
      get(this.currentLocation, 'company.oktaIntegration.content')
    ) {
      employeesFilter = get(this.currentLocation, 'company.directorySyncProvider');
    } else {
      const employeesFilters = this.store.peekAll('employees-filter');
      employeesFilter = get(employeesFilters, 'firstObject');
    }

    let rollback;
    let keepBubbling = true;

    if (get(employeesFilter, 'hasDirtyAttributes')) {
      rollback = window.confirm('You will lose any unsaved changes. Are you sure you want to continue?');

      if (rollback) {
        next(function () {
          employeesFilter.rollbackAttributes();
        });
      } else {
        transition.abort();

        keepBubbling = false;
      }
    }

    return keepBubbling;
  }
}

export default EmployeesSyncSettingsRoute;

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class HostSearchSettingsPanel extends Component {
  @tracked currentPageIdx = 0;
  @tracked isOpen = false;

  @action
  goToNextPage(): void {
    if (this.currentPageIdx === 2) {
      this.isOpen = false;
      return;
    }

    this.currentPageIdx += 1;
  }

  @action
  goToPreviousPage(): void {
    this.currentPageIdx -= 1;
  }

  @action
  reset(): void {
    this.currentPageIdx = 0;
  }

  get displayedPageIndex(): number {
    return this.currentPageIdx + 1;
  }
}

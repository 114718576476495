import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { dropTask, waitForProperty } from 'ember-concurrency';
import { or } from 'macro-decorators';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { action } from '@ember/object';
import { APP } from 'garaje/utils/enums';

export default class DeliveriesLandingPageController extends Controller {
  @service currentAdmin;
  @service featureFlags;
  @service flashMessages;
  @service metrics;
  @service productActivation;
  @service state;

  @tracked transitionDone;

  /**
   * @return {Boolean}
   */
  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isBillingAdmin') canStartTrial;

  @tracked showIntakeForm = false;

  get surveyApp() {
    return APP.WORKPLACE;
  }

  @action
  async openIntakeForm() {
    if (this.featureFlags.isEnabled('workplace-garaje-launch')) {
      this.showIntakeForm = true;
    }

    try {
      await this.startTrialTask.perform();
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }

    if (!this.featureFlags.isEnabled('workplace-garaje-launch')) {
      await this.closeIntakeForm();
    }
  }

  @action
  async closeIntakeForm() {
    this.showIntakeForm = false;

    this.flashMessages.showAndHideFlash('success', 'Deliveries enabled!');

    const transitionObj = this.transitionToRoute('deliveries.log.tutorial'); // eslint-disable-line ember/no-deprecated-router-transition-methods

    this.metrics.trackEvent('Product Landing Page Start Trial Button Clicked', { product: 'deliveries' });

    transitionObj.then(() => {
      if (!this.isDestroyed && !this.isDestroying) {
        this.transitionDone = true;
      }
    });
    await waitForProperty(this, 'transitionDone');
  }

  /**
   * startTrialTask
   * Creates Deliveries Subsription and reloads the global `state` service
   * @param {Event} evt*
   */
  @dropTask
  *startTrialTask(evt) {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }
    try {
      if (this.featureFlags.isEnabled('workplace-garaje-launch')) {
        try {
          yield this.productActivation.startWorkplaceTrialTask.perform();
          yield this.state.initSubscriptionStateTask.perform();
        } catch (e) {
          this.flashMessages.showFlash('error', "We're sorry, something went wrong.");
        }
      } else {
        yield this.productActivation.enableDeliveriesTask.perform();
      }
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }
}

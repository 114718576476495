import Component from '@glimmer/component';
import { action } from '@ember/object';
import { next } from '@ember/runloop';

/**
 * @param {Object}              changeset
 * @param {Function}            addressDidChange
 * @param {Function}            updateAddressLineTwo
 * @param {Function}            updateAddress
 */
export default class GranularAddressLookupFields extends Component {
  @action
  handleSelect(value) {
    this.args.updateAddress(value);
    this.args.addressDidChange();

    next(this, function () {
      const addressLine2 = document.querySelector('.addressLine2');
      if (addressLine2) {
        addressLine2.focus();
      }
    });
  }
}

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { later } from '@ember/runloop';
import { pluralize } from 'ember-inflector';
import { tracked } from '@glimmer/tracking';
import { formatScimProvider } from 'garaje/utils/format-scim-provider';

/**
 * @param {String}                     integration
 * @param {Function}                   openAction
 * @param {Function}                   closeAction
 * @param {Object}                     model
 * @param {Array<Object>}              locations
 * @param {Array<Object>}              employeeGroupRoles
 * @param {Boolean}                    isEnterprisePlan
 */
class ScimAdminsBox extends Component {
  @service currentAdmin;
  @service state;

  @tracked showDeleteDialog = false;
  @tracked showEnterpriseDialog = false;
  @tracked showRequestSentDialog = false;
  @tracked employeeGroupRoleToDelete = '';

  get connected() {
    return !isEmpty(this.args.model);
  }

  get isOpen() {
    return this.args.integration === 'scim-admins';
  }

  get formattedProvider() {
    return formatScimProvider(this.args.model.provider);
  }

  get hasCorrectPlan() {
    return this.state.features?.canAccessScimAdminSync;
  }

  get learnMoreLink() {
    switch (this.args.model.provider) {
      case 'onelogin':
        return 'https://envoy.help/en/articles/3453393-onelogin-application#h_9ff111675a';
      case 'azure':
        return 'https://envoy.help/en/articles/3453335-microsoft-entra-id-integration#h_ce739a590a';
      default:
        // okta
        return 'https://envoy.help/en/articles/3453325-okta#h_4b60a66c59';
    }
  }

  get pillText() {
    const employeeGroupRoles = this.args.employeeGroupRoles;
    const count = employeeGroupRoles?.length || 0;
    if (count > 0) {
      return `${count} admin ${pluralize(count, 'group', { withoutCount: true })}`;
    } else {
      return '';
    }
  }

  @action
  open() {
    if (this.hasCorrectPlan) {
      this.args.openAction('scim-admins');
    } else {
      this.showEnterpriseDialog = true;
    }
  }

  @action
  close() {
    this.args.closeAction();
  }

  @action
  setDeleteDialog(showDialog, employeeGroupRole) {
    this.showDeleteDialog = showDialog;
    this.employeeGroupRoleToDelete = employeeGroupRole;
  }

  @action
  setRequestSentDialog(showDialog) {
    this.showRequestSentDialog = showDialog;
  }

  @action
  displayRequestSentDialog() {
    this.setRequestSentDialog(true);
    later(this, 'setRequestSentDialog', false, 2500);
  }
}

export default ScimAdminsBox;

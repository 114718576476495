import Route from '@ember/routing/route';
import { set } from '@ember/object';
import { service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class WorkplaceSettingsEmployeesRegistrationRoute extends Route {
  title = 'Workplace · Employee registration · Envoy';

  @service flow;
  @service store;
  @service transitionConfirm;

  async beforeModel(transition) {
    const flowId = transition.to.params.flow_id;
    const employeeScreeningFlow = await this.flow.getFlowWithIncludes(flowId);

    return employeeScreeningFlow;
  }

  async model(params) {
    const model = {
      flow: null,
      signInFieldPage: null,
      signInFields: null,
      agreementPage: null,
      summaryPage: null,
      signInFieldActionRuleGroups: null,
      groups: [],
      ipads: [],
      locations: [],
    };

    model.flow = this.store.peekRecord('employee-screening-flow', params.flow_id);
    model.agreementPage = await model.flow.agreementPage;
    model.summaryPage = await model.flow.summaryPage;
    model.signInFieldPage = await model.flow.signInFieldPage;
    model.signInFields = model.signInFieldPage ? await model.signInFieldPage.signInFields : [];
    model.signInFieldActionRuleGroups = await model.flow.signInFieldActionRuleGroups;
    model.groups = this.store.peekAll('group');
    model.locations = await model.flow.locations;

    this.flow.selectedEmployeeScreeningFlow = model.flow;

    return model;
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    set(controller, 'selectedLocations', model.locations.toArray());
  }

  @routeEvent
  routeWillChange(transition) {
    const { isDirty, rollbackAll } = this.controller;

    if (isDirty()) {
      this.transitionConfirm.displayConfirmTask.perform(transition, {
        continue() {
          rollbackAll();
        },
      });
    }
    return true;
  }
}

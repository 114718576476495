import { service } from '@ember/service';
import Component from '@glimmer/component';
import type AbilitiesService from 'ember-can/services/abilities';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type IsOpenService from 'garaje/services/is-open';

export default class SideBarPropertyMenuComponent extends Component {
  @service declare abilities: AbilitiesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare isOpen: IsOpenService;

  get showDevicesMenuItem(): boolean {
    return this.abilities.can('manage devices for properties');
  }

  get showAppsMenuItem(): boolean {
    return this.abilities.can('manage apps for properties');
  }
}

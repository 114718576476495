import Component from '@glimmer/component';
import type ApprovalStatus from 'garaje/models/approval-status';
import { alias } from 'macro-decorators';

interface ApprovalResolutionComponentArgs {
  ApprovalStatus: ApprovalStatus;
}

export default class ApprovalResolutionComponent extends Component<ApprovalResolutionComponentArgs> {
  @alias('args.approvalStatus.status') status!: ApprovalStatus['status'];
  @alias('args.approvalStatus.reviewerName') reviewerName!: ApprovalStatus['reviewerName'];
  @alias('args.approvalStatus.reviewedAt') reviewedAt!: ApprovalStatus['reviewedAt'];
}

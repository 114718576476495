import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_EDIT_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN];

const CAN_VISIT_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST];

export default class SyncSettingAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;

  get canEdit(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_EDIT_ROLES, roleNames));
  }

  get canVisit(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_VISIT_ROLES, roleNames));
  }

  get canScimSyncAdmins(): boolean {
    const { roleNames } = this.currentAdmin;
    const hasCorrectPlan = !!this.state.features?.canAccessScimAdminSync;
    return hasCorrectPlan && roleNames.includes(GLOBAL_ADMIN);
  }
}

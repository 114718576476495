import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type AnnouncementModel from 'garaje/models/announcement';

export default class EmergencyNotificationModel extends Model {
  // Relationships
  @belongsTo('announcement') declare announcement: AsyncBelongsTo<AnnouncementModel>;

  // Attributes
  @attr('boolean') declare markAsSafeRequired: boolean;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'emergency-notification': EmergencyNotificationModel;
  }
}

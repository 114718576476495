import { service } from '@ember/service';
import Component from '@glimmer/component';
import { restartableTask } from 'ember-concurrency';
import type PluginInstallModel from 'garaje/models/plugin-install';
import type { VfdContactMethodSlackMetadata } from 'garaje/models/vfd-contact-method';
import type SessionService from 'garaje/services/session';
import urlBuilder from 'garaje/utils/url-builder';

type VirtualFrontDeskLocationsTableRowSlackChannelDropdownComponentArgs = {
  disabled?: boolean;
  selectedChannel?: VfdContactMethodSlackMetadata;
  selectChannel: (channel: VfdContactMethodSlackMetadata) => void;
  slackPluginInstall: PluginInstallModel;
};

type SlackChannelResponse = Array<{ id: string; name: string }>;

export default class VirtualFrontDeskLocationsTableRowSlackChannelDropdownComponent extends Component<VirtualFrontDeskLocationsTableRowSlackChannelDropdownComponentArgs> {
  @service declare session: SessionService;

  searchChannelsTask = restartableTask(async (query: string) => {
    const pluginInstall = this.args.slackPluginInstall;
    let url = urlBuilder.slack.v2.searchChannelsUrl(pluginInstall.id);
    if (query) {
      url = `${url}?search=${encodeURIComponent(query)}`;
    }
    try {
      const response = await fetch(url, {
        credentials: 'include',
      });

      if (response.ok) {
        const data = <SlackChannelResponse>await response.json();
        return data.map((item) => ({
          'channel-id': item.id,
          'channel-name': item.name,
        }));
      } else {
        console.error('fetching list of Slack channels failed: [%s] %s', response.status, response.statusText); // eslint-disable-line no-console
      }
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
    return [];
  });
}

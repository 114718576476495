import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type StatsigService from 'garaje/services/statsig';

export default class VisitorsSingleAdmin extends Component<{
  closePage: () => void;
  adminBulkRoute: string;
}> {
  @service declare router: RouterService;
  @service declare statsig: StatsigService;

  @action
  pageViewEvent(): void {
    this.statsig.logEvent('add-admin-empty-page-viewed');
  }

  @action
  addAdmin(): void {
    this.statsig.logEvent('add-admin-empty-page-add-clicked');
    this.args.closePage();
    this.router.transitionTo(this.args.adminBulkRoute).catch(() => null);
  }

  @action
  dismissPage(): void {
    this.statsig.logEvent('add-admin-empty-page-dismiss-clicked');
    this.args.closePage();
  }
}

import type NativeArray from '@ember/array/-private/native-array';
import { action, set } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { DetailedChangeset } from 'ember-changeset/types';
import { dropTask, hash } from 'ember-concurrency';
import type AgreementModel from 'garaje/models/agreement';
import type FlowModel from 'garaje/models/flow';
import type GlobalAgreementModel from 'garaje/models/global-agreement';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import type SubscriptionModel from 'garaje/models/subscription';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { equal, or } from 'macro-decorators';
import type ServerError from 'utils/server-error';

import type { PendingUploadsPerLanguage, SaveOptions } from './edit-text/component';

export type SupportedAgreements = AgreementModel | GlobalAgreementModel;

interface LegalDocumentComponentSignature {
  Args: {
    translationLanguagesAvailable: boolean;
    translationEnabledLocales: string[];
    defaultLocale: string;
    changeset: DetailedChangeset<SupportedAgreements>;
    flowName: string;
    flow: FlowModel;
    isAppUpdateRequired: boolean;
    model: SupportedAgreements;
    signInFields: NativeArray<SignInFieldModel>;
    tracking: Record<string, string>;
    vrSubscription: SubscriptionModel;
    disabled: boolean;
    isSaved: boolean;
  };
}

export default class LegalDocumentComponent extends Component<LegalDocumentComponentSignature> {
  @service declare router: RouterService;
  @service declare metrics: MetricsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare featureFlags: FeatureFlagsService;

  @tracked editingEmail = false;

  @equal('router.currentRouteName', 'visitors.settings.visitor-types.flow.legal-documents.edit')
  onEditLegalDocumentRoute!: boolean;
  @equal('router.currentRouteName', 'visitors.settings.visitor-types.flow.legal-documents.new')
  onNewLegalDocumentRoute!: boolean;
  @or('onEditLegalDocumentRoute', 'onNewLegalDocumentRoute') onNewOrEditLegalDocumentRoute!: boolean;

  @action
  trackLegalDocument(agreement: SupportedAgreements): void {
    const { jobId, eventName } = this.args.tracking || {};
    if (eventName) {
      let buttonText = 'save document';
      if (eventName.match(/Previewed/) !== null) {
        buttonText = 'preview';
      }
      const duration = agreement.duration;
      this.metrics.trackEvent(eventName, {
        allow_decline_signing: agreement.optional,
        button_text: buttonText,
        document_name: agreement.name,
        document_character_count: agreement.body.length,
        email_signed_document: agreement.bccEmail,
        job_id: jobId || null,
        legal_doc_id: agreement.id,
        resign_for_every_visit: duration === null,
        resign_required_after: duration && duration > 0 ? duration : null,
        video_url: agreement.videoUrl,
      });
      if (eventName.match('Add Legal Document') !== null) {
        this.args.tracking['eventName'] = 'Legal Docs - Legal Document Edited';
      }
    }
  }

  updateAndSaveTask = dropTask(
    async <T extends keyof SupportedAgreements>({
      model,
      field = null,
      value = null,
      propagable = true,
      pendingUploadsPerLanguage,
    }: SaveOptions<T>) => {
      if (pendingUploadsPerLanguage) await this.#handlePendingUploads(pendingUploadsPerLanguage, model);

      if (field) set(model, field, value!);

      try {
        // @ts-ignore
        await model.save({ propagable });
        this.flashMessages.showAndHideFlash('success', 'Saved!');
      } catch (e) {
        const serverError = <ServerError>e;
        let message = 'Server error. Please try again.';

        if (serverError.isAdapterError) {
          message = serverError.errors.mapBy('detail').join(', ');
        }

        this.flashMessages.showFlash('error', message);

        /*
          Make task finish in an err state, this is useful if you are
          using the task as a promise in code like the following:

          task.perform().then((succ) => { }, (err) => { });

          For more info read https://ember-concurrency.com/docs/error-vs-cancelation/
        */
        await Promise.reject(<Error>e);
      }
    },
  );

  async #handlePendingUploads(
    pendingUploadsPerLanguage: PendingUploadsPerLanguage,
    model: SupportedAgreements,
  ): Promise<void> {
    const hasPendingUploads = Object.values(pendingUploadsPerLanguage).some((pendingUpload) => pendingUpload);
    if (!hasPendingUploads) return;

    try {
      const pendingUploadsPromises: Record<string, Promise<string | undefined>> = {};

      Object.entries(pendingUploadsPerLanguage).forEach(([key, pendingUpload]) => {
        // do not re upload if the file has already been uploaded in a previous save attempt
        if (!pendingUpload || pendingUpload.uploaded) return;
        pendingUploadsPromises[key] = pendingUpload.upload();
      });

      const resolvedUploads = await hash(pendingUploadsPromises);

      Object.entries(resolvedUploads).forEach(([key, signedId]) => {
        if (!signedId) return;

        if (key === 'default') {
          // assign signed id for default locale
          model.document = {
            ...model.document,
            id: signedId,
          };
        } else {
          // assign signed id for custom translations
          const customTranslations = { ...model.customTranslations };

          if (!customTranslations.document) customTranslations.document = {};
          if (!customTranslations.document[key]) customTranslations.document[key] = {};
          customTranslations.document[key].id = signedId;

          model.customTranslations = customTranslations;
        }
      });
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      await Promise.reject(<Error>e);
    }
  }
}

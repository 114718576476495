import { App } from 'garaje/utils/enums';

export const DOWNGRADE_RESTRICTIONS = Object.freeze([
  {
    id: 'visitors-basic-multiple-locations',
    app: App.VISITORS,
    level: 'basic',
    item: 'location',
    maximum: 1,
    title: 'Multiple location are not allowed on the Basic plan',
    subtitle: 'Please deactivate all locations but one to downgrade. ',
    linkRoute: 'location-billing.subscriptions',
    linkText: 'Manage active locations →',
  },
  {
    id: 'visitors-standard-multiple-visitor-types',
    app: App.VISITORS,
    level: 'standard',
    item: 'visitor-type',
    maximum: 1,
    title: 'Multiple visitor types are not allowed on the Standard plan',
    subtitle: 'Please remove all but one visitor type to downgrade. ',
    linkRoute: 'location-billing.index',
    linkText: 'Manage visitor types →',
  },
  {
    id: 'visitors-basic-multiple-visitor-types',
    app: App.VISITORS,
    level: 'basic',
    item: 'visitor-type',
    maximum: 1,
    title: 'Multiple visitor types are not allowed on the Basic plan',
    subtitle: 'Please remove all but one visitor type to downgrade. ',
    linkRoute: 'visitors.settings.visitor-types',
    linkText: 'Manage visitor types →',
  },
  {
    id: 'rooms-basic-maximum-rooms',
    app: App.ROOMS,
    level: 'basic',
    item: 'room',
    maximum: 10,
    title: 'Rooms Basic cannot have more than 10 rooms',
    subtitle: 'Please unpair extra rooms to downgrade. ',
    linkRoute: 'roomba.list',
    linkText: 'Manage active rooms →',
  },
  {
    id: 'desks-basic-maximum-desks',
    app: App.DESKS,
    level: 'basic',
    item: 'desk',
    maximum: 25,
    title: 'Desks Basic cannot have more than 25 desks',
    subtitle: 'Please deactivate extra desks to downgrade. ',
    linkRoute: 'desks.index',
    linkText: 'Manage active desks →',
  },
]);

type Feature = {
  app: App;
  description: string;
  downgrade: boolean;
  id: string;
  level: string;
  name: string;
  rank: number;
  svg: string | null;
  upgrade: boolean;
};

type PlanDetails = {
  addLocationsPrompt?: Record<string, string>;
  app: App;
  customMessage: string;
  customRoute: string;
  description: string;
  downgradeFeatures: string[];
  features: string[];
  id: string;
  legacyId: string;
  maximum?: number | null;
  name: string;
  plusFeaturesPlan: string;
  quantityMessage: string;
  rank: number;
  sequence: number;
  unitType: string;
  upgradeFeatures: string[];
};

type ProductPlansDetails = {
  id: string;
  types: readonly PlanDetails[];
};

export const FEATURES: readonly Feature[] = Object.freeze([
  {
    id: 'visitors-standard-unlimited-visitors',
    app: App.VISITORS,
    level: 'standard',
    rank: 10,
    upgrade: true,
    downgrade: false,
    svg: '',
    name: 'Unlimited visitors',
    description: '',
  },
  {
    id: 'visitors-standard-badge-printing',
    app: App.VISITORS,
    level: 'standard',
    rank: 10,
    upgrade: true,
    downgrade: true,
    svg: '/assets/images/features/printer.svg',
    name: 'Badge printing',
    description: 'Identify authorized visitors with printed badges',
  },
  {
    id: 'visitors-standard-legal-documents',
    app: App.VISITORS,
    level: 'standard',
    rank: 10,
    upgrade: true,
    downgrade: true,
    svg: '/assets/images/features/nda-signing.svg',
    name: 'Legal documents',
    description: 'Visitors sign your NDA directly on the iPad',
  },
  {
    id: 'visitors-standard-host-notifications-integrations',
    app: App.VISITORS,
    level: 'basic',
    rank: 10,
    upgrade: true,
    downgrade: true,
    svg: '/assets/images/features/monitor-with-message.svg',
    name: 'Host notifications integrations',
    description: 'Alert employees via Slack and Microsoft Teams when visitors arrive',
  },
  {
    id: 'visitors-standard-unlimited-employees',
    app: App.VISITORS,
    level: 'standard',
    rank: 10,
    upgrade: true,
    downgrade: true,
    svg: '/assets/images/features/multiple-people.svg',
    name: 'Unlimited employees w/ Envoy Protect',
    description: 'Everyone in your company can host visitors',
  },
  {
    id: 'visitors-premium-visitor-photos',
    app: App.VISITORS,
    level: 'premium',
    rank: 20,
    upgrade: true,
    downgrade: true,
    svg: '/assets/images/features/visitor-photo.svg',
    name: 'Visitor photos',
    description: 'Capture photos for enhanced security',
  },
  {
    id: 'visitors-premium-analytics-dashboard',
    app: App.VISITORS,
    level: 'premium',
    rank: 20,
    upgrade: true,
    downgrade: false,
    svg: null,
    name: 'Analytics dashboard',
    description: '',
  },
  {
    id: 'visitors-premium-invite-only-entry',
    app: App.VISITORS,
    level: 'premium',
    rank: 20,
    upgrade: true,
    downgrade: false,
    svg: null,
    name: 'Invite-only entry',
    description: '',
  },
  {
    id: 'visitors-premium-multiple-sign-in-flows',
    app: App.VISITORS,
    level: 'premium',
    rank: 20,
    upgrade: true,
    downgrade: true,
    svg: '/assets/images/features/visitor-role.svg',
    name: 'Multiple sign-in flows',
    description: 'Create unique sign-in flows for different types of visitors',
  },
  {
    id: 'visitors-premium-capacity-management',
    app: App.VISITORS,
    level: 'premium',
    rank: 20,
    upgrade: true,
    downgrade: false,
    svg: '',
    name: 'Capacity management w/ Envoy Protect',
    description: '',
  },
  {
    id: 'visitors-premium-directory-integrations',
    app: App.VISITORS,
    level: 'premium',
    rank: 20,
    upgrade: true,
    downgrade: true,
    svg: '/assets/images/features/directory.svg',
    name: 'Directory integrations w/ Envoy Protect',
    description: 'Automatically sync Envoy with your employee directory',
  },
  {
    id: 'visitors-premium-sms-notifications',
    app: App.VISITORS,
    level: 'premium',
    rank: 20,
    upgrade: true,
    downgrade: true,
    svg: '/assets/images/features/phone-notification.svg',
    name: 'Alerts w/ Envoy Protect',
    description: 'Send employees a text message when their guests arrive',
  },
  {
    id: 'visitors-enterprise-block-list',
    app: App.VISITORS,
    level: 'enterprise',
    rank: 30,
    upgrade: true,
    downgrade: false,
    svg: null,
    name: 'Block list',
    description: '',
  },
  {
    id: 'visitors-enterprise-id-scanning',
    app: App.VISITORS,
    level: 'enterprise',
    rank: 30,
    upgrade: true,
    downgrade: false,
    svg: null,
    name: 'ID scanning',
    description: '',
  },
  {
    id: 'visitors-enterprise-wi-fi-integrations',
    app: App.VISITORS,
    level: 'enterprise',
    rank: 30,
    upgrade: true,
    downgrade: false,
    svg: null,
    name: 'Wi-Fi Integrations',
    description: '',
  },
  {
    id: 'visitors-enterprise-access-control-integrations',
    app: App.VISITORS,
    level: 'enterprise',
    rank: 30,
    upgrade: true,
    downgrade: false,
    svg: null,
    name: 'Access control integrations w/ Envoy Protect',
    description: '',
  },
  {
    id: 'visitors-enterprise-visitor-screening-integrations',
    app: App.VISITORS,
    level: 'enterprise',
    rank: 30,
    upgrade: true,
    downgrade: false,
    svg: null,
    name: 'Visitor screening integrations w/ Envoy Protect',
    description: '',
  },
  {
    id: 'deliveries-basic-easy-recording-process',
    app: App.DELIVERIES,
    level: 'basic',
    rank: 0,
    upgrade: true,
    downgrade: false,
    svg: null,
    name: 'Easy recording process',
    description: '',
  },
  {
    id: 'deliveries-basic-recipient-notifications',
    app: App.DELIVERIES,
    level: 'basic',
    rank: 0,
    upgrade: true,
    downgrade: false,
    svg: null,
    name: 'Recipient notifications',
    description: '',
  },
  {
    id: 'deliveries-basic-automatic-reminders',
    app: App.DELIVERIES,
    level: 'basic',
    rank: 0,
    upgrade: true,
    downgrade: false,
    svg: null,
    name: 'Automatic reminders',
    description: '',
  },
  {
    id: 'deliveries-basic-secure-pickup',
    app: App.DELIVERIES,
    level: 'basic',
    rank: 0,
    upgrade: true,
    downgrade: false,
    svg: null,
    name: 'Secure pickup',
    description: '',
  },
  {
    id: 'deliveries-standard-unlimited-deliveries',
    app: App.DELIVERIES,
    level: 'standard',
    rank: 10,
    upgrade: true,
    downgrade: true,
    svg: '/assets/images/features/package.svg',
    name: 'Unlimited Deliveries',
    description: "You'll no longer be able to scan unlimited packages",
  },
  {
    id: 'rooms-basic-schedule-display',
    app: App.ROOMS,
    level: 'basic',
    rank: 0,
    upgrade: true,
    downgrade: false,
    svg: null,
    name: 'Schedule display',
    description: '',
  },
  {
    id: 'rooms-basic-walk-up-booking',
    app: App.ROOMS,
    level: 'basic',
    rank: 0,
    upgrade: true,
    downgrade: false,
    svg: null,
    name: 'Walk-up booking',
    description: '',
  },
  {
    id: 'rooms-basic-check-in-and-end-meetings',
    app: App.ROOMS,
    level: 'basic',
    rank: 0,
    upgrade: true,
    downgrade: false,
    svg: null,
    name: 'Check-in and end meetings',
    description: '',
  },
  {
    id: 'rooms-basic-color-coded-availability-statuses',
    app: App.ROOMS,
    level: 'basic',
    rank: 0,
    upgrade: true,
    downgrade: false,
    svg: null,
    name: 'Color-coded availability statuses',
    description: '',
  },
  {
    id: 'rooms-basic-room-kiosk-stability-alerts',
    app: App.ROOMS,
    level: 'basic',
    rank: 0,
    upgrade: true,
    downgrade: false,
    svg: null,
    name: 'Room kiosk stability alerts',
    description: '',
  },
  {
    id: 'rooms-basic-proximity-based-mobile-booking',
    app: App.ROOMS,
    level: 'basic',
    rank: 0,
    upgrade: true,
    downgrade: false,
    svg: null,
    name: 'Proximity-based mobile booking',
    description: '',
  },
  {
    id: 'rooms-standard-space-utilization-analytics',
    app: App.ROOMS,
    level: 'standard',
    rank: 10,
    upgrade: true,
    downgrade: true,
    svg: '/assets/images/features/presentation.svg',
    name: 'Space utilization analytics',
    description: 'Make smarter decisions about your space with analytics on rooms usage',
  },
  {
    id: 'rooms-standard-privacy-and-booking-controls',
    app: App.ROOMS,
    level: 'standard',
    rank: 10,
    upgrade: true,
    downgrade: true,
    svg: '/assets/images/features/shield.svg',
    name: 'Privacy and booking controls',
    description: 'Determine room permissions to control booking access',
  },
  {
    id: 'rooms-standard-slack-and-ms-teams-integrations',
    app: App.ROOMS,
    level: 'basic',
    rank: 10,
    upgrade: true,
    downgrade: true,
    svg: '/assets/images/features/integration.svg',
    name: 'Slack and MS Teams integrations',
    description: 'Receive room check-in notifications from Envoy on Slack or Microsoft Team',
  },
]);

export const VISITORS_BASIC: PlanDetails = Object.freeze({
  id: 'basic', // CHARGEBEE
  legacyId: 'basic',
  rank: 0,
  sequence: 3,
  app: App.VISITORS,
  unitType: 'location',
  name: 'Basic',
  maximum: 1,
  description: 'For a single workplace with basic front desk needs',
  customRoute: '',
  customMessage: 'Up to 1 location per company',
  quantityMessage: 'Select the location where you would like to use Visitors',
  plusFeaturesPlan: '',
  features: [],
  upgradeFeatures: [],
  downgradeFeatures: [],
});

export const VISITORS_STANDARD: PlanDetails = Object.freeze({
  id: 'standard-1',
  legacyId: 'standard-1',
  rank: 10,
  sequence: 0,
  app: App.VISITORS,
  unitType: 'location',
  name: 'Standard',
  description: 'For small teams who want to safely welcome people to their office.',
  customRoute: '',
  customMessage: '',
  quantityMessage: 'Select the locations where you would like to purchase Visitors',
  addLocationsPrompt: {
    message: 'Need to add more locations?',
    display: 'Create a new location →',
    route: 'new-location',
  },
  plusFeaturesPlan: '',
  features: ['Unlimited visitors', 'Email notifications', 'Badge printing', 'Legal documents'],
  upgradeFeatures: [],
  downgradeFeatures: [],
});

export const VISITORS_PREMIUM: PlanDetails = Object.freeze({
  id: 'premium',
  legacyId: 'premium',
  rank: 20,
  sequence: 1,
  app: App.VISITORS,
  unitType: 'location',
  name: 'Premium',
  maximum: null,
  description: 'For companies who need to oversee safety and capacity across multiple locations.',
  customRoute: '',
  customMessage: '',
  quantityMessage: 'Select the locations where you would like to purchase Visitors',
  addLocationsPrompt: {
    message: 'Need to add more locations?',
    display: 'Create a new location →',
    route: 'new-location',
  },
  plusFeaturesPlan: 'Standard',
  features: [
    'Multiple sign-in flows',
    'Directory integrations',
    'Visitor photos',
    'Invite-only entry',
    'Analytics dashboard',
    'Customized branding',
    'SMS notifications',
  ],
  upgradeFeatures: [],
  downgradeFeatures: [],
});

export const VISITORS_ENTERPRISE: PlanDetails = Object.freeze({
  id: 'enterprise',
  legacyId: 'enterprise',
  rank: 30,
  sequence: 2,
  app: App.VISITORS,
  unitType: 'location',
  name: 'Enterprise',
  maximum: null,
  description: 'For workplaces with complex safety, security and compliance needs.',
  customRoute: '',
  customMessage: 'Ready to discuss your enterprise needs?',
  quantityMessage: 'Select the locations where you would like to purchase Visitors',
  addLocationsPrompt: {
    message: 'Need to add more locations?',
    display: 'Create a new location →',
    route: 'new-location',
  },
  plusFeaturesPlan: 'Premium',
  features: [
    'Block list',
    'ID scanning',
    'Wi-Fi Integrations',
    'Access control integrations',
    'Visitor screening integrations',
  ],
  upgradeFeatures: [],
  downgradeFeatures: [],
});

export const VISITORS_PLAN_TYPES = Object.freeze([
  VISITORS_STANDARD,
  VISITORS_PREMIUM,
  VISITORS_ENTERPRISE,
  VISITORS_BASIC,
]);

export const VISITORS: ProductPlansDetails = Object.freeze({
  id: 'visitors',
  types: VISITORS_PLAN_TYPES,
});

export const DELIVERIES_BASIC: PlanDetails = Object.freeze({
  id: 'deliveries-basic', // CHARGEBEE
  legacyId: 'deliveries-basic',
  rank: 0,
  sequence: 0,
  app: App.DELIVERIES,
  unitType: 'delivery area',
  name: 'Basic',
  maximum: null,
  description: 'Manage up to 100 incoming deliveries per month with:',
  customRoute: '',
  customMessage: 'Up to 100 deliveries per company, per month',
  quantityMessage: 'Select the number of delivery areas that you have in each location.',
  plusFeaturesPlan: '',
  features: ['Easy recording process', 'Recipient notifications', 'Automatic reminders', 'Secure pickup'],
  upgradeFeatures: [],
  downgradeFeatures: [],
});

export const DELIVERIES_PREMIUM: PlanDetails = Object.freeze({
  id: 'standard',
  legacyId: 'deliveries-standard',
  rank: 10,
  sequence: 1,
  app: App.DELIVERIES,
  unitType: 'delivery area',
  name: 'Premium',
  maximum: null,
  description: 'Avoid package pileup with:',
  customRoute: '',
  customMessage: '',
  quantityMessage: 'Select the number of delivery areas that you have in each location.',
  plusFeaturesPlan: 'Basic',
  features: ['Unlimited Deliveries'],
  upgradeFeatures: [],
  downgradeFeatures: [],
});

export const DELIVERIES_PLAN_TYPES = Object.freeze([DELIVERIES_BASIC, DELIVERIES_PREMIUM]);

export const DELIVERIES: ProductPlansDetails = Object.freeze({
  id: 'deliveries',
  types: DELIVERIES_PLAN_TYPES,
});

export const ROOMS_BASIC: PlanDetails = Object.freeze({
  id: 'rooms-basic', // CHARGEBEE
  legacyId: 'rooms-basic',
  rank: 0,
  sequence: 0,
  app: App.ROOMS,
  unitType: 'room',
  name: 'Basic',
  maximum: 10,
  description: 'Make conference room booking easier for your team with:',
  customRoute: '',
  customMessage: 'Up to 10 rooms across all locations',
  quantityMessage: 'Enter how many of the 10 rooms you want at each location.',
  plusFeaturesPlan: '',
  features: [
    'Schedule display',
    'Walk-up booking',
    'Check-in and end meetings',
    'Color-coded availability statuses',
    'Room kiosk stability alerts',
    'Proximity-based mobile booking',
  ],
  upgradeFeatures: [],
  downgradeFeatures: [],
});

export const ROOMS_STANDARD: PlanDetails = Object.freeze({
  id: 'rooms-standard',
  legacyId: 'rooms-standard',
  rank: 10,
  sequence: 1,
  app: App.ROOMS,
  unitType: 'room',
  name: 'Standard',
  maximum: null,
  description: 'Get insights on room usage to make the most of your space:',
  customRoute: '',
  customMessage: '',
  quantityMessage: 'Select the number of rooms you have in each location. ',
  plusFeaturesPlan: 'Basic',
  features: ['Space utilization analytics', 'Privacy and booking controls', 'Slack and MS Teams integrations'],
  upgradeFeatures: [],
  downgradeFeatures: [],
});

export const ROOMS_PLAN_TYPES = Object.freeze([ROOMS_BASIC, ROOMS_STANDARD]);

export const ROOMS: ProductPlansDetails = Object.freeze({
  id: 'rooms',
  types: ROOMS_PLAN_TYPES,
});

export const DESKS_BASIC: PlanDetails = Object.freeze({
  id: 'desks-basic', // CHARGEBEE
  legacyId: 'desks-basic',
  rank: 0,
  sequence: 0,
  app: App.DESKS,
  unitType: 'desk license',
  name: 'Basic',
  maximum: 25,
  description: 'Desks is available as an add-on to any Visitors + Protect plan and includes:',
  customRoute: '',
  customMessage: 'Up to 25 desks',
  quantityMessage: '', // No need to select, Up to 25 desks each location
  plusFeaturesPlan: '',
  features: [
    'Up to 25 active desks',
    'Unlimited reservations',
    'On-demand desk reservations',
    'Desk hoteling',
    'Assigned desks',
    'Team neighborhoods',
    'Interactive floor maps',
    'Mobile app and web portal',
    'Find coworkers on the map',
  ],
  upgradeFeatures: [],
  downgradeFeatures: [],
});

export const DESKS_STANDARD: PlanDetails = Object.freeze({
  id: 'desks-standard',
  legacyId: 'desks-standard',
  rank: 10,
  sequence: 1,
  app: App.DESKS,
  unitType: 'desk license',
  name: 'Standard',
  maximum: null,
  description: 'Desks is available as an add-on to any Visitors + Protect plan and includes:',
  customRoute: '',
  customMessage: '',
  quantityMessage: 'Select the number of desk licenses you have in each location. ',
  plusFeaturesPlan: 'Basic',
  features: [
    'Unlimited desk reservations (pay per 25 desks)',
    'Desk utilization analytics',
    'Desk check-in and release',
  ],
  upgradeFeatures: [],
  downgradeFeatures: [],
});

export const DESKS_PLAN_TYPES = Object.freeze([DESKS_BASIC, DESKS_STANDARD]);

export const DESKS: ProductPlansDetails = Object.freeze({
  id: 'desks',
  types: DESKS_PLAN_TYPES,
});

export const WORKPLACE_STANDARD: PlanDetails = Object.freeze({
  id: 'empxp-standard',
  legacyId: 'empxp-standard',
  rank: 10,
  sequence: 0,
  app: App.WORKPLACE,
  unitType: 'employee license',
  name: 'Standard',
  maximum: null,
  description: 'For companies who want to foster onsite collaboration and streamline the employee experience.',
  customRoute: '',
  customMessage: '',
  quantityMessage: 'Select the number of employee licenses you have in each location. ',
  plusFeaturesPlan: '',
  features: [
    'Workplace maps',
    'Workplace scheduling',
    'Package and delivery tracking',
    'Ticketing integrations',
    'Notifications & announcements',
    'Mobile, Slack, Teams, GCal, Outlook integrations',
    'Location-based mobile sign-in',
  ],
  upgradeFeatures: [],
  downgradeFeatures: [],
});

export const WORKPLACE_PREMIUM: PlanDetails = Object.freeze({
  id: 'empxp-premium',
  legacyId: 'empxp-premium',
  rank: 20,
  sequence: 1,
  app: App.WORKPLACE,
  unitType: 'employee license',
  name: 'Premium',
  maximum: null,
  description: 'For companies who want to optimize the workplace experience with accurate data and space booking.',
  customRoute: '',
  customMessage: '',
  quantityMessage: 'Select the number of employee licenses you have in each location. ',
  plusFeaturesPlan: 'Standard',
  features: [
    'Health and safety controls',
    'Auto-sign in via access control integrations',
    'Conference room booking',
    'Desk booking',
    'Occupancy and space usage analytics',
    'Directory integrations',
    'SSO, Directory Sync',
  ],
  upgradeFeatures: [],
  downgradeFeatures: [],
});

export const WORKPLACE_PREMIUM_PLUS: PlanDetails = Object.freeze({
  id: 'empxp-premium-plus',
  legacyId: 'empxp-premium-plus',
  rank: 25,
  sequence: 2,
  app: App.WORKPLACE,
  unitType: 'employee license',
  name: 'Premium Plus',
  maximum: null,
  description: 'For companies who want to leverage emergency notifications and admin management features.',
  customRoute: '',
  customMessage: '',
  quantityMessage: 'Select the number of employee licenses you have in each location. ',
  plusFeaturesPlan: 'Premium',
  features: [
    'Send multi-channel emergency notifications to employees',
    'Manage user identities and access rights automatically',
  ],
  upgradeFeatures: [],
  downgradeFeatures: [],
});

export const WORKPLACE_PLAN_TYPES = Object.freeze([WORKPLACE_STANDARD, WORKPLACE_PREMIUM, WORKPLACE_PREMIUM_PLUS]);

export const WORKPLACE: ProductPlansDetails = Object.freeze({
  id: 'empxp',
  types: WORKPLACE_PLAN_TYPES,
});

export const WORKPLACE_SEAT_MINIMUM = 25;

export const PLAN_TYPES = Object.freeze([VISITORS, DELIVERIES, ROOMS, DESKS, WORKPLACE]);

export const plansByType = (app: App): ProductPlansDetails | undefined => {
  switch (app) {
    case App.VISITORS:
      return VISITORS;
    case App.DELIVERIES:
      return DELIVERIES;
    case App.ROOMS:
      return ROOMS;
    case App.DESKS:
      return DESKS;
    case App.WORKPLACE:
      return WORKPLACE;
  }
  return;
};

export const getPlanDetails = (app: App, key: string): PlanDetails | undefined => {
  if (app === App.VISITORS) {
    switch (key) {
      case 'basic':
        return VISITORS_BASIC;
      case 'standard':
        return VISITORS_STANDARD;
      case 'premium':
        return VISITORS_PREMIUM;
      case 'enterprise':
        return VISITORS_ENTERPRISE;
    }
  } else if (app === App.DELIVERIES) {
    switch (key) {
      case 'basic':
        return DELIVERIES_BASIC;
      case 'standard':
        return DELIVERIES_PREMIUM;
    }
  } else if (app === App.ROOMS) {
    switch (key) {
      case 'basic':
        return ROOMS_BASIC;
      case 'standard':
        return ROOMS_STANDARD;
    }
  } else if (app === App.DESKS) {
    switch (key) {
      case 'basic':
        return DESKS_BASIC;
      case 'standard':
        return DESKS_STANDARD;
    }
  } else if (app === App.WORKPLACE) {
    switch (key) {
      case 'standard':
        return WORKPLACE_STANDARD;
      case 'premium':
        return WORKPLACE_PREMIUM;
      case 'premium-plus':
        return WORKPLACE_PREMIUM_PLUS;
    }
  }
  return;
};

export default {
  plansByType,
  getPlanDetails,
  FEATURES,
  DOWNGRADE_RESTRICTIONS,
};

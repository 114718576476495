import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import { validate } from 'ember-validators';

/**
 * Extends validateConfirmation to include onlyIf.
 */

export default function validateConfirmation(options = {}) {
  return (key, newValue, oldValue, changes) => {
    if (options.onlyIf && !options.onlyIf(changes)) {
      return true;
    }

    const result = validate('confirmation', newValue, options, changes, key);
    return result === true ? true : buildMessage(key, result);
  };
}

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { Dropdown } from 'ember-basic-dropdown/components/basic-dropdown';
import type { VfdScheduleRule } from 'garaje/models/vfd-schedule';
import type { Day } from 'garaje/utils/enums';
import SimpleTime from 'garaje/utils/simple-time';
import type TimeRange from 'garaje/utils/time-range';
import { TrackedSet } from 'tracked-built-ins';
import { cached } from 'tracked-toolbox';

interface VirtualFrontDeskSettingsHoursOfOperationConfigurationDaySignature {
  Args: {
    addInterval: () => void;
    copySchedule: (days: Set<Day>) => void;
    day: Day;
    /**
     * Array of Days, in the order they should appear (used for the "copy schedule" dropdown).
     */
    days: Day[];
    deleteInterval: (intervalIndex: number) => void;
    readOnly: boolean;
    schedule: VfdScheduleRule;
    setIntervalEndTime: (intervalIndex: number, time: SimpleTime) => void;
    setIntervalStartTime: (interval: number, time: SimpleTime) => void;
    toggleDaySchedule: (value: boolean) => void;
  };
}

// maximum number of intervals that can be created for a day
const MAX_INTERVALS = 20;

export default class VirtualFrontDeskSettingsHoursOfOperationConfigurationDay extends Component<VirtualFrontDeskSettingsHoursOfOperationConfigurationDaySignature> {
  // Despite being documented, ember-basic-dropdown doesn't actually have a `@registerAPI` action (only ember-power-select does).
  // ember-basic-dropdown _does_, however, yield the `actions` portion of the API, so using our own `did-insert`/`did-update` we can
  // get similar behavior (since all we care about is `actions.close()`, this works for us).
  @tracked copyScheduleDropdownActions: Dropdown['actions'] | null = null;

  selectedDaysToCopy = new TrackedSet<Day>();

  get canDeleteIntervals(): boolean {
    if (this.args.readOnly) return false;
    // intervals can be deleted if the day is enabled and has more than one interval
    return this.isScheduleEnabled && this.args.schedule.intervals.length > 1;
  }

  get cannotAddIntervals(): boolean {
    if (this.args.readOnly) return true;
    // intervals cannot be added if the day is at its maximum
    return this.args.schedule.intervals.length >= MAX_INTERVALS;
  }

  get hasSelectedDaysToCopy(): boolean {
    return this.selectedDaysToCopy.size > 0;
  }

  @cached
  get isScheduleDisabled(): boolean {
    return !this.isScheduleEnabled;
  }

  @cached
  get isScheduleEnabled(): boolean {
    return this.args.schedule.enabled;
  }

  @cached
  get showCopyHoursButton(): boolean {
    // show "copy hours" button only when schedule is enabled for the day, and @readOnly is not true
    return this.isScheduleEnabled && !this.args.readOnly;
  }

  @action
  clearSelectedDaysToCopy(): void {
    this.selectedDaysToCopy.clear();
  }

  @action
  copySchedule(): void {
    if (this.args.readOnly) return;
    if (this.hasSelectedDaysToCopy) {
      this.args.copySchedule(new Set(this.selectedDaysToCopy));
      this.selectedDaysToCopy.clear();
      if (this.copyScheduleDropdownActions) {
        this.copyScheduleDropdownActions.close();
        this.copyScheduleDropdownActions = null;
      }
    }
  }

  @action
  copyScheduleToAll(): void {
    if (this.args.readOnly) return;
    const days = this.args.days.filter((day) => day !== this.args.day);
    this.args.copySchedule(new Set(days));
    this.selectedDaysToCopy.clear();
    if (this.copyScheduleDropdownActions) {
      this.copyScheduleDropdownActions.close();
      this.copyScheduleDropdownActions = null;
    }
  }

  @action
  registerCopyScheduleDropdownActions(_element: Element, [dropdownActions]: [Dropdown['actions']]): void {
    this.copyScheduleDropdownActions = dropdownActions;
  }

  @action
  setIntervalEndTime(intervalIndex: number, time: string): void {
    this.args.setIntervalEndTime(intervalIndex, SimpleTime.parse12Hour(time, { midnightIsEndOfDay: true }));
  }

  @action
  setIntervalStartTime(intervalIndex: number, time: string): void {
    this.args.setIntervalStartTime(intervalIndex, SimpleTime.parse12Hour(time, { midnightIsEndOfDay: false }));
  }

  @action
  toggleActive(): void {
    this.args.toggleDaySchedule(!this.isScheduleEnabled);
  }

  @action
  toggleDayForCopy(day: Day): void {
    if (this.selectedDaysToCopy.has(day)) {
      this.selectedDaysToCopy.delete(day);
    } else {
      this.selectedDaysToCopy.add(day);
    }
  }

  @action
  endTimeErrorMessage(interval: TimeRange): string | null {
    if (!this.endTimeHasError(interval)) return null;

    if (!interval.isValid) return null;

    return 'Working hours cannot overlap';
  }

  /**
   * Determine whether to show "error" state for end time.
   * This is the case when the interval is invalid (e.g., start time is later than end time),
   * or there is any other interval which starts inside of this one.
   */
  @action
  endTimeHasError(interval: TimeRange): boolean {
    // check that start/end times are valid
    if (!interval.isValid) return true;

    // check for any other range that starts inside of this one
    for (const otherInterval of this.args.schedule.intervals.filter((interval) => interval.isValid)) {
      // skip self
      if (interval === otherInterval) continue;
      // skip any intervals missing a start or end time
      if (!otherInterval.isValid) continue;
      if (otherInterval.startsAtOrAfter(interval.from!) && otherInterval.startsBefore(interval.to!)) return true;
    }

    return false;
  }

  @action
  isDaySelectedForCopy(day: Day): boolean {
    return this.selectedDaysToCopy.has(day);
  }

  @action
  isLastInterval(index: number): boolean {
    return index === this.args.schedule.intervals.length - 1;
  }

  @action
  startTimeErrorMessage(interval: TimeRange): string | null {
    if (!this.startTimeHasError(interval)) return null;

    if (!interval.isValid) return null;

    return 'Working hours cannot overlap';
  }

  /**
   * Determine whether to show "error" state for end time.
   * This is the case when the interval is invalid (e.g., start time is later than end time),
   * or the start time of this interval falls inside any other interval.
   */
  @action
  startTimeHasError(interval: TimeRange): boolean {
    // check that start/end times are valid
    if (!interval.isValid) return true;

    // check if this interval starts inside of any other interval
    for (const otherInterval of this.args.schedule.intervals.filter((interval) => interval.isValid)) {
      // skip self
      if (interval === otherInterval) continue;
      // skip any intervals missing a start or end time
      if (!otherInterval.isValid) continue;
      if (interval.startsAtOrAfter(otherInterval.from!) && interval.startsBefore(otherInterval.to!)) return true;
    }

    return false;
  }

  // Times are saved as 24-hour times, but the time picker uses 12-hour times.
  // Convert the saved value to the appropriate value for the time picker so the currently-selected time is properly selected in the UI.
  timeForPicker(time: SimpleTime | null): string | null {
    if (!time) return null;

    if (time.isSame(SimpleTime.EndOfDayMidnight)) return '12:00 am';

    let displayHour = time.hour;
    const amPm = displayHour < 12 ? 'am' : 'pm';
    if (displayHour > 12) {
      displayHour -= 12;
    } else if (displayHour === 0) {
      displayHour = 12;
    }
    return `${displayHour}:${time.minute.toString().padStart(2, '0')} ${amPm}`;
  }
}

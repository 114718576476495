import { action } from '@ember/object';
import Component from '@glimmer/component';

interface ReSignDaysInputComponentSignature {
  Args: {
    inputId: string;
    days: string;
    disabled: boolean;
    errorMessage: string;
    validate: (days: string) => void;
    updateDays: (days: string) => void;
  };
}

export default class ReSignDaysInputComponent extends Component<ReSignDaysInputComponentSignature> {
  @action
  onKeyPress(event: KeyboardEvent): boolean {
    const days = (<HTMLInputElement>event.target).value;
    return event.key.match(/^[0-9]$/) !== null && days.length < 4;
  }

  @action
  onInput(event: InputEvent): void {
    const days = (<HTMLInputElement>event.target).value;
    this.args.validate(days);
    // for paste, truncate
    this.args.updateDays(days.length > 4 ? days.slice(0, 4) : days);
  }

  @action
  onChange(event: InputEvent): void {
    const days = (<HTMLInputElement>event.target).value;
    this.args.validate(days);
    this.args.updateDays(days);
  }
}

import Controller from '@ember/controller';
import { action } from '@ember/object';
import type { TaskInstance } from 'ember-concurrency';
import type GlobalBadgeModel from 'garaje/models/global-badge';
import updateAndSaveTask from 'garaje/utils/decorators/update-and-save-task';

import type { LocationOverviewSignInFlowsShowBadgeRouteModel } from './route';

class LocationOverviewSignInFlowsShowBadgeController extends updateAndSaveTask(Controller) {
  declare model: LocationOverviewSignInFlowsShowBadgeRouteModel;

  @action
  save(badge: GlobalBadgeModel, e: Event): TaskInstance<void> {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    return this.updateAndSaveTask.perform(badge);
  }

  rollback(): void {
    (<GlobalBadgeModel>(<unknown>this.model.globalFlow.badge)).rollbackAttributes();
  }

  @action
  _redirectToEnvoy(path: string): void {
    this.send('redirectToEnvoy', path);
  }
}

export default LocationOverviewSignInFlowsShowBadgeController;

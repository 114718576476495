import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type PrinterConnection from 'garaje/models/printer-connection';
import type ZoneModel from 'garaje/models/zone';
import type { RecordArray } from 'garaje/utils/type-utils';

import type { PropertyDevicesRouteModel } from '../../route';

export interface PropertyDevicesPrintersConnectionsRouteModel {
  printerConnections: RecordArray<PrinterConnection>;
  printers: PropertyDevicesRouteModel['printers'];
  property: ZoneModel;
}

export default class PropertyDevicesPrintersConnectionsRoute extends Route {
  @service declare router: RouterService;
  @service declare store: Store;

  async model(): Promise<PropertyDevicesPrintersConnectionsRouteModel> {
    const property = <ZoneModel>this.modelFor('property');
    const printerConnections = await this.store.query('printer-connection', {
      filter: {
        'zone-id': property.id,
      },
    });

    return {
      printerConnections,
      printers: (<PropertyDevicesRouteModel>this.modelFor('property.devices')).printers,
      property,
    };
  }
}

import gql from 'graphql-tag';

export default gql`
  fragment RoomMapFeatureFragment on RoomMapFeature {
    room {
      id
      calendarId
      displayName
      capacity
      amenities
      imageUrl
      claimable
      status {
        status
        time
      }
      maskMeetingNames
      timeToBookInMinutes
      checkInEnabled
    }
  }
`;

// utilization {
//   percentUtilized
// }

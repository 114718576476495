import _some from 'lodash/some';
import { getProperties, setProperties } from '@ember/object';
import NotificationPeriod from 'garaje/models/notification-period';

const NOTIFICATION_PERIOD_PROPERTIES = ['active', 'day', 'endAt', 'startAt'];

export const DEFAULT_NOTIFICATION_PERIODS = Object.freeze([
  Object.freeze(new NotificationPeriod({ day: 1, active: true, startAt: '08:00:00', endAt: '18:00:00' })),
  Object.freeze(new NotificationPeriod({ day: 2, active: true, startAt: '08:00:00', endAt: '18:00:00' })),
  Object.freeze(new NotificationPeriod({ day: 3, active: true, startAt: '08:00:00', endAt: '18:00:00' })),
  Object.freeze(new NotificationPeriod({ day: 4, active: true, startAt: '08:00:00', endAt: '18:00:00' })),
  Object.freeze(new NotificationPeriod({ day: 5, active: true, startAt: '08:00:00', endAt: '18:00:00' })),
  Object.freeze(new NotificationPeriod({ day: 6, active: false, startAt: '08:00:00', endAt: '18:00:00' })),
  Object.freeze(new NotificationPeriod({ day: 7, active: false, startAt: '08:00:00', endAt: '18:00:00' })),
]);

/**
 * Resets NotificationSchedule model to default values
 *
 * @param {object} notificationSchedule NotificationSchedule
 */
export function resetToDefault(notificationSchedule) {
  setProperties(notificationSchedule, {
    active: true,
    notificationPeriods: DEFAULT_NOTIFICATION_PERIODS,
  });
}

/**
 * Tests whether a given notificationPeriod is one of the defaults
 *
 * @param {object} notificationPeriod NotificationPeriod
 * @returns {boolean} true when notification period is one of the defaults
 */
export function isDefaultNotificationPeriod(notificationPeriod) {
  const attrs = getProperties(notificationPeriod, NOTIFICATION_PERIOD_PROPERTIES);
  return _some(DEFAULT_NOTIFICATION_PERIODS, attrs);
}

import ApplicationSerializer from './application';

export default class TicketConfigurationSerializer extends ApplicationSerializer {
  attrs = {
    ticketingIntegrationInstalled: {
      serialize: false,
    },
    ticketingIntegrationName: {
      serialize: false,
    },
  };
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type LocationModel from 'garaje/models/location';
import type PrinterModel from 'garaje/models/printer';
import type StateService from 'garaje/services/state';
import type { RecordArray } from 'garaje/utils/type-utils';

export interface VisitorsDevicesPrintersNewRouteModel {
  hasBluetoothPrinter: boolean;
  location: LocationModel;
  printer: PrinterModel | null;
  printers: RecordArray<PrinterModel>;
}

export default class VisitorsDevicesPrintersNewRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: StoreService;

  beforeModel(): void {
    if (this.abilities.cannot('create printers')) {
      void this.router.transitionTo('visitors.devices.printers.index');
    }
  }

  async model(): Promise<VisitorsDevicesPrintersNewRouteModel> {
    const location = this.state.currentLocation;
    const printers = await this.store.query('printer', { filter: { location: location.id } });
    const hasBluetoothPrinter = printers.any((printer) => printer.connectionType === 'bluetooth');
    const printer = hasBluetoothPrinter
      ? null
      : this.store.createRecord('printer', {
          location,
          model: null,
          ip: null,
          name: null,
          enable: true,
        });
    return { hasBluetoothPrinter, location, printer, printers };
  }

  redirect({ hasBluetoothPrinter }: VisitorsDevicesPrintersNewRouteModel): void {
    if (hasBluetoothPrinter) {
      // we do not allow user to add multiple printer if they already have bluetooth printer
      void this.router.transitionTo('visitors.devices.printers.index');
    }
  }
}

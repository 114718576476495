// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';

export default class DashboardProductBoxSetupStepsComponent extends Component {
  tagName = '';

  /**
   * @return {Integer}
   */
  @computed('setupSteps.@each.isCompletedOrSkipped')
  get currentStep() {
    for (let i = 0; this.setupSteps.length; i++) {
      if (!this.setupSteps[i].isCompletedOrSkipped) return i;
    }
    return this.setupSteps.length;
  }
}

import type { AsyncHasMany } from '@ember-data/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import AbstractAgreementPageModel from 'garaje/models/abstract/abstract-agreement-page';
import type EmployeeAgreementModel from 'garaje/models/employee-agreement';
import type EmployeeScreeningFlowModel from 'garaje/models/employee-screening-flow';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('agreement-page')
class EmployeeAgreementPageModel extends AbstractAgreementPageModel {
  @hasMany('employee-agreement') declare agreements: AsyncHasMany<EmployeeAgreementModel>;
  @belongsTo('employee-screening-flow') declare flow: AsyncHasMany<EmployeeScreeningFlowModel>;

  @attr('string', { defaultValue: '' }) declare body: string;
}

export default EmployeeAgreementPageModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'employee-agreement-page': EmployeeAgreementPageModel;
  }
}

import Controller from '@ember/controller';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import type PhotoPageModel from 'garaje/models/photo-page';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import updateAndSaveTask from 'garaje/utils/decorators/update-and-save-task';

import type { VisitorsSettingsVisitorTypesFlowVisitorPhotosRouteModel } from './route';

class VisitorsSettingsVisitorTypesFlowVisitorPhotosController extends updateAndSaveTask(Controller) {
  declare model: VisitorsSettingsVisitorTypesFlowVisitorPhotosRouteModel;

  @service declare featureFlags: FeatureFlagsService;
  @service declare currentAdmin: CurrentAdminService;

  updateFeature = dropTask(async (photoPage: PhotoPageModel, value) => {
    await this.updateAndSaveTask.perform(photoPage, 'enabled', value);

    // if user turns off photos, badge showPhoto is set to false.
    if (!value) {
      const { badge } = this.model;

      if (badge.showPhoto) {
        badge.showPhoto = false;
        await badge.save();
      }
    }
  });
}

export default VisitorsSettingsVisitorTypesFlowVisitorPhotosController;

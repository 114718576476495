import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AttendanceReportRoute extends Route {
  @service abilities;
  @service state;
  @service router;

  title = 'Attendance by Employee · Analytics · Envoy';

  beforeModel() {
    if (this.abilities.cannot('visit-attendance analytic')) {
      this.router.transitionTo('unauthorized');
    }
  }
}

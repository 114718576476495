import { isArray } from '@ember/array';
import Service, { service } from '@ember/service';
import Ember from 'ember';
import { UnauthorizedError } from 'garaje/services/ajax/errors';
import DS from 'ember-data';
import config from 'garaje/config/environment';

const { AdapterError } = DS;
const { environment } = config;

const shouldIgnoreError = (error) => {
  return (
    error.jqXHR ||
    (error.statusText && error.statusCode) ||
    error.message === 'Unauthorized' ||
    error.message === 'Bad Request'
  );
};

export default class ErrorTriageService extends Service {
  @service logger;
  @service session;
  environment = environment;

  constructor() {
    super(...arguments);
    Ember.onerror = (error) => this.onerror(error);
  }

  willDestroy() {
    Ember.onerror = undefined;
    super.willDestroy(...arguments);
  }

  onerror(error) {
    if (!error) {
      return;
    }

    const logger = this.logger;

    /* eslint-disable no-console */
    if (console.group) {
      console.group('Ember.onerror:');
    }

    console.info(error);

    if (console.groupEnd) {
      console.groupEnd();
    }
    /* eslint-enable no-console */

    // noop, we let jqxhr errors like 401 Unauthorized pass through
    // so that ember-simple-auth can reauth
    if (shouldIgnoreError(error)) {
      return;
    }

    if (error instanceof UnauthorizedError) {
      this.session.invalidate();
      return;
    }

    if (error instanceof AdapterError) {
      this._handleEmberDataAdapterError(error);
      return;
    }

    logger.error(error);

    if (this.environment === 'test' || this.environment === 'development') {
      throw error;
    }
  }

  // TODO: @heroiceric
  // Why are we doing this?
  _handleEmberDataAdapterError(error) {
    const logger = this.logger;
    let details;

    const ignoredErrorCodes = [400, 404];
    const hasValidErrors = error.errors.some((err) => {
      return !ignoredErrorCodes.includes(err.status);
    });

    if (isArray(error.errors)) {
      details = error.errors
        .map((err) => err.detail || err.title || err)
        .uniq()
        .join(', ');
    }

    const message = `${error} - ${details}`;

    if (hasValidErrors) {
      logger.error(message, error);
    }
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';

export default class CommunicationsMessageLogRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  beforeModel(): void | Promise<unknown> {
    if (this.abilities.cannot('manage-announcements communications')) {
      void this.router.transitionTo('unauthorized');
    }
  }
}

import ApplicationSerializer from './application';

export default class EntryExportJob extends ApplicationSerializer {
  attrs = {
    status: {
      serialize: false,
    },
    progress: {
      serialize: false,
    },
    url: {
      serialize: false,
    },
    totalCount: {
      serialize: false,
    },
  };
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { formatInTimeZone } from 'date-fns-tz';
import type VfdCallLogModel from 'garaje/models/vfd-call-log';
import type WindowLocationService from 'garaje/services/window-location';
import { reads } from 'macro-decorators';

const DEFAULT_TIMEZONE = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'America/Los_Angeles';

export interface VfdCallLogRowComponentSignature {
  Args: {
    model?: VfdCallLogModel;
    timezone?: string;
  };

  Blocks: {
    default: [];
  };

  Element: HTMLElement;
}

export default class VfdCallLogRowComponent extends Component<VfdCallLogRowComponentSignature> {
  @service declare windowLocation: WindowLocationService;

  @reads('args.timezone', DEFAULT_TIMEZONE) timezone!: string;
  @reads('args.model.location.timezone', '') locationTimezone!: string;

  get startedAtInTimezone(): { day?: string; time?: string } {
    const {
      args: { model },
      timezone,
    } = this;

    if (!model?.startedAt) return {};

    return {
      day: formatInTimeZone(model.startedAt, timezone, 'MMM d, yyyy'),
      time: formatInTimeZone(model.startedAt, timezone, 'h:mm aaa'),
    };
  }

  get startedAtInLocation(): { day?: string; time?: string } {
    const {
      args: { model },
      locationTimezone,
    } = this;

    if (!(model?.startedAt && locationTimezone)) return {};

    return {
      day: formatInTimeZone(model.startedAt, locationTimezone, 'MMM d, yyyy'),
      time: formatInTimeZone(model.startedAt, locationTimezone, 'h:mm aaa'),
    };
  }

  get startedAtTooltip(): string {
    const { startedAtInLocation } = this;

    if (!startedAtInLocation?.day) return '';

    return `Location time: ${startedAtInLocation.day}, at ${startedAtInLocation.time}`;
  }

  get duration(): string {
    const {
      args: { model: { durationSeconds = 0 } = {} },
    } = this;

    if (!durationSeconds) return '';

    const strings: string[] = [];
    const min = Math.floor(durationSeconds / 60);
    const sec = durationSeconds % 60;

    if (min > 0) strings.push(`${min}m`);

    strings.push(`${sec}s`);

    return strings.join(' ');
  }

  get callStatusToRender(): { text: string; className: string } {
    const {
      args: { model: { callStatus = 'none' } = {} },
    } = this;
    const none = { text: '', className: '' };

    return (
      {
        completed: { text: 'Ended', className: 'bg-cilantro-10 text-cilantro-60' },
        'in-progress': { text: 'In Progress', className: 'bg-gem-10 text-gem-60' },
        missed: { text: 'Missed', className: 'bg-pistachio-10 text-pistachio-60' },
        disconnected: { text: 'Disconnected', className: 'bg-carbon-10 text-carbon-60' },
        none,
      }[callStatus] || none
    );
  }

  @action
  downloadFromUrl(url: string): void {
    this.windowLocation.assign(url);
  }
}

import Service, { service } from '@ember/service';
import fetch from 'fetch';
import urlBuilder from 'garaje/utils/url-builder';

export default class ExternalAuthenticationService extends Service {
  @service cookies;

  invalidateEnvoyAuth() {
    const url = urlBuilder.envoyLogoutUrl();
    const csrfToken = this.cookies.read('csrf_token');
    const headers = { 'X-CSRF-Token': csrfToken };
    const options = {
      headers,
      method: 'DELETE',
      credentials: 'include',
    };

    return fetch(url, options);
  }
}

import Route from '@ember/routing/route';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';

export default class WorkplaceStartTrialPageRoute extends Route {
  @service state;
  @service router;

  titleToken = 'Workspace';

  redirect() {
    const { workplaceSubscription } = this.state;
    if (isPresent(workplaceSubscription)) {
      this.router.transitionTo('workplace.settings.employees.registration');
    }
  }
}

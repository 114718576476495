import { TEST_IDENTIFIER, VACCINE_IDENTIFIER } from 'garaje/models/user-document-template';
import { BaseLoginModalDefinition } from 'garaje/utils/base-login-modal-definition';

const showCTAModal = (userDocumentTemplateConfiguration, routeName, isModalDismissed, latestDocument) => {
  const isConfigActive = userDocumentTemplateConfiguration?.active ?? false;
  const isNotUploadRoute = !routeName?.includes('profile.document.upload');
  const isNotDismissed = !isModalDismissed;
  const isFirstTime = !latestDocument;

  return isConfigActive && isNotUploadRoute && isNotDismissed && isFirstTime;
};

class BaseDocumentModalDefinition extends BaseLoginModalDefinition {
  userDocumentTemplateConfiguration = null;
  latestDocument = null;

  get configuration() {
    const { userDocumentTemplateConfiguration, latestDocument } = this;
    return { userDocumentTemplateConfiguration, latestDocument };
  }

  /**
   *
   * @param {object} options
   *  ```
   * {
   *   routeName: String
   *   isModalDismissed: boolean
   *   usersDocuments: UserDocument[]
   *   userDocumentTemplateConfigurations: UserDocumentTemplateConfiguration[]
   * }
   * ```
   */
  constructor(options) {
    super(options);
    const identifier = this.constructor.identifier;

    this.userDocumentTemplateConfiguration = options.userDocumentTemplateConfigurations?.findBy(
      'identifier',
      identifier,
    );
    this.latestDocument = options.usersDocuments
      .filterBy('userDocumentTemplate.identifier', identifier)
      .sortBy('sortableDate').lastObject;
  }
}

export class VaccineCTAModalDefinition extends BaseDocumentModalDefinition {
  static priority = 1;
  static identifier = VACCINE_IDENTIFIER;

  get active() {
    return showCTAModal(
      this.userDocumentTemplateConfiguration,
      this.options.routeName,
      this.options.isModalDismissed,
      this.latestDocument,
    );
  }
}

export class TestCTAModalDefinition extends BaseDocumentModalDefinition {
  static priority = 2;
  static identifier = TEST_IDENTIFIER;

  get active() {
    const { userDocumentTemplateConfiguration } = this;
    const latestVaccineDocument = this.options.usersDocuments
      .filterBy('userDocumentTemplate.identifier', VACCINE_IDENTIFIER)
      .sortBy('sortableDate').lastObject;

    const isNotHiddenViaConfigAndApproval =
      !userDocumentTemplateConfiguration?.hideIfVaccinated ||
      (userDocumentTemplateConfiguration?.hideIfVaccinated && !latestVaccineDocument?.isApproved);

    return (
      showCTAModal(
        userDocumentTemplateConfiguration,
        this.options.routeName,
        this.options.isModalDismissed,
        this.latestDocument,
      ) && isNotHiddenViaConfigAndApproval
    );
  }
}

export class VaccineAlertModalDefinition extends BaseDocumentModalDefinition {
  component = 'vaccine-alert-modal';
  static identifier = VACCINE_IDENTIFIER;
  static priority = 1;

  get active() {
    const { routeName, isModalDismissed } = this.options;

    const isConfigActive = this.userDocumentTemplateConfiguration?.active ?? false;
    const isNotUploadRoute = !routeName?.includes('profile.document.upload');
    const isNotDismissed = !isModalDismissed;

    const isDocumentDenied = this.latestDocument?.isDenied;

    return isConfigActive && isNotUploadRoute && isNotDismissed && isDocumentDenied;
  }
}

export class ExpiringAlertModalDefinition extends BaseDocumentModalDefinition {
  static identifier = TEST_IDENTIFIER;
  static priority = 2;

  component = 'expiring-alert-modal';

  get active() {
    const { userDocumentTemplateConfiguration } = this;
    const { routeName, isModalDismissed } = this.options;
    const latestVaccineDocument = this.options.usersDocuments
      .filterBy('userDocumentTemplate.identifier', VACCINE_IDENTIFIER)
      .sortBy('sortableDate').lastObject;

    const isNotHiddenViaConfigAndApproval = !(
      userDocumentTemplateConfiguration?.hideIfVaccinated && latestVaccineDocument?.isApproved
    );

    const isConfigActive = this.userDocumentTemplateConfiguration?.active ?? false;
    const isNotUploadRoute = !routeName?.includes('profile.document.upload');
    const isNotDismissed = !isModalDismissed;
    const isDocumentApproved = this.latestDocument?.isApproved;
    const isDocumentExpiring = this.latestDocument?.isExpiringSoon;

    return (
      isNotHiddenViaConfigAndApproval &&
      isConfigActive &&
      isNotUploadRoute &&
      isNotDismissed &&
      isDocumentApproved &&
      isDocumentExpiring
    );
  }
}

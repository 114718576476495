import Route from '@ember/routing/route';
import { findAllDeliveryAreasForLocation } from 'garaje/utils/delivery-area';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';

export default class DeliveriesSettingsDeliveryAreasIndexRoute extends Route {
  @service state;
  @service store;

  model() {
    const { currentLocation, deliveriesSubscription } = this.state;
    const { deliveryLimit } = this.modelFor('deliveries');
    const deliveryAreas = findAllDeliveryAreasForLocation(this.store, currentLocation);
    const companyDeliveryAreas = this.modelFor('deliveries').deliveryAreas;
    const hasDeliveriesApp = !!this.state.features?.canAccessDeliveriesApplication;

    return hash({
      companyDeliveryAreas,
      currentLocation,
      deliveriesSubscription,
      deliveryAreas,
      deliveryLimit,
      hasDeliveriesApp,
    });
  }
}

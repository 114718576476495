import type { Logger } from '@datadog/browser-logs';
import { datadogLogs } from '@datadog/browser-logs';
import { assert } from '@ember/debug';
import Service, { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import config from 'garaje/config/environment';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type LocalStorageService from 'garaje/services/local-storage';
import type OnlineService from 'garaje/services/online';
import type StateService from 'garaje/services/state';
import { logLCPToLogRocket } from 'garaje/utils/log-rocket-helpers';
import pickBy from 'lodash/pickBy';
import LogRocket from 'logrocket';
import { tracked } from 'tracked-built-ins';
import { v4 as uuid } from 'uuid';

const PII_FIELDS = ['email', 'phone', 'name'];

export default class MetricsService extends Service {
  @service declare localStorage: LocalStorageService;
  @service declare state: StateService;
  @service declare online: OnlineService;
  @service declare featureFlags: FeatureFlagsService;

  get notABoss(): boolean {
    return this.localStorage.getItem('is_boss') === 'false' || !this.localStorage.getItem('is_boss');
  }

  @tracked jobId: string | null = null; // used by trackJobEvent method, set by startJob method
  datadog: Logger | null = null;

  constructor(properties: Record<string, unknown>) {
    super(properties);

    datadogLogs.init({
      service: 'garaje',
      clientToken: config.dataDogClientToken,
      forwardErrorsToLogs: false,
    });
    this.datadog = datadogLogs.logger;
  }

  /**
   * @param userId A unique identifier for the current user.
   * @param traits Data about the current user to be sent to segment.
   * @param options Additional options passed to Segment - e.g. the Intercom user identity hash.
   *
   * @return window.analytics
   *
   * https://segment.com/docs/sources/website/analytics.js/#identify
   */
  identify(userId: string, traits: AnalyticsTraits = {}, options = {}): void {
    const compactedTraits = pickBy(traits, isPresent);

    if (window && window.analytics && this.notABoss) {
      void this.online.runWhenOnline(() => {
        window.analytics.identify(userId, compactedTraits, options);
        if (config.logRocketAppId) {
          const filteredTraits = pickBy(traits, (_value, key) => !PII_FIELDS.includes(key));
          filteredTraits['name'] = traits['name'] = userId;
          LogRocket.identify(userId, filteredTraits);
          logLCPToLogRocket();
        }
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getStateEventProperties() {
    if (!this.state) {
      return null;
    }
    return {
      user: this.state.currentUser
        ? {
            id: this.state.currentUser.id,
          }
        : null,
      company: this.state.currentCompany
        ? {
            id: this.state.currentCompany.id,
            name: this.state.currentCompany.name,
          }
        : null,
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  pickStateContextForLogging() {
    if (!this.state) {
      return null;
    }
    return {
      user_id: this.state.currentUser?.id,
      company: this.state.currentCompany
        ? {
            id: this.state.currentCompany.id,
            name: this.state.currentCompany.name,
          }
        : null,
      location: this.state.currentLocation
        ? {
            id: this.state.currentLocation.id,
          }
        : null,
    };
  }

  /**
   *
   * @param event The name of the event you’re tracking.
   * @param properties A dictionary of properties for the event. If the event was 'delivery picked up', it might have properties like pickedUpBy.
   * @param options A dictionary of options for segment.
   *
   * @return window.analytics
   *
   * https://segment.com/docs/sources/website/analytics.js/#track
   */
  trackEvent(event: string, properties: AnalyticsTraits = {}, options: EventOptions = {}): void {
    const compactedProperties = pickBy(properties, isPresent);
    compactedProperties['project'] = 'dashboard';
    if (window && window.analytics && this.notABoss) {
      options = this.#setContext(options);
      void this.online.runWhenOnline(() => {
        window.analytics.track(`[dashboard] ${event}`, compactedProperties, options);
        if (config.logRocketAppId) LogRocket.track(`[dashboard] ${event}`, compactedProperties);
      });
    }
  }

  /**
   * Same API as trackEvent, but adds the current job_id to the properties
   */
  trackJobEvent(event: string, properties: Record<string, string>, options = {}): void {
    if (this.jobId === null) {
      assert(
        `metrics.trackJobEvent was called without first calling metrics.startJob. Please call metrics.startJob to generate a new job_id for subsequent job events.`,
      );
    }
    properties['job_id'] = this.jobId;
    properties['project'] = 'dashboard';
    this.trackEvent(event, properties, options);
  }

  /**
   * Called to start a new job chain.
   * Subsequent calls to trackJobEvent will use the uuid generated by this method.
   */
  startJob(): void {
    this.jobId = uuid();
  }

  /**
   *
   * @param name A unique page name.
   * @param properties A dictionary of properties of the page. Note: url, title, referrer and path are collected automatically!
   * @param options A dictionary of options for segment.
   *
   * @return window.analytics
   *
   * https://segment.com/docs/sources/website/analytics.js/#page
   */
  trackPage(name: string, properties: Record<string, unknown> = {}, options: EventOptions = {}): void {
    if (window && window.analytics && this.notABoss && isPresent(name)) {
      options = this.#setContext(options);
      properties['project'] = 'dashboard';
      void this.online.runWhenOnline(() => {
        window.analytics.page(`[dashboard] ${name}`, properties, options);
      });
    }
  }

  logMonitorError({
    event,
    debugExtras,
    error,
  }: {
    event: string;
    debugExtras: Record<string, unknown>;
    error: Error;
  }): void {
    try {
      const stackTrace = (error instanceof Error ? error : new Error()).stack;
      this.datadog?.error(event, {
        ...this.pickStateContextForLogging(),
        error,
        debugExtras,
        stack_trace: stackTrace,
      });
    } catch (e) {
      // eslint-disable-next-line
      console.error('error in logMonitorError', e, error);
    }
  }

  logMonitorEvent(event: string, properties = {}): void {
    try {
      this.datadog?.log(event, {
        ...this.pickStateContextForLogging(),
        extras: {
          ...properties,
        },
      });
    } catch (e) {
      // eslint-disable-next-line
      console.error('error in logMonitorEvent', e);
    }
  }

  #setContext(options: EventOptions): EventOptions {
    // See https://community.segment.com/t/x18vag for our universal traits (from identify call)
    options.context = options.context || {};
    if (typeof window.analytics.user === 'function') {
      options.context.traits = window.analytics.user().traits();
    } else {
      options.context.traits = {};
    }

    // @ts-expect-error - not compatible with logrocket types
    options.context.traits['user_id'] = this.state.currentUser?.id || null;
    return options;
  }
}

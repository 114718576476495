import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';

export default class EmployeesDirectoryDocumentReviewIndexRoute extends Route {
  @service router;
  @service store;
  @service state;

  queryParams = {
    type: { refreshModel: true },
  };

  model(params) {
    const companyId = this.state.currentCompany.id;
    const locationId = this.state.currentLocation.id;

    const filter = {
      'company-id': companyId,
      'approval-status': 'review',
      'user-document-location-contexts-location': locationId,
    };

    if (isPresent(params.type)) {
      Object.assign(filter, { 'user-document-template-identifier': params.type });
    }

    return this.store.query('user-document-link', {
      include:
        'user-document.user,user-document.user-document-attachments,user-document.user-document-template.user-document-template-attachments,user-document.user-document-location-contexts',
      filter,
    });
  }

  redirect(model) {
    if (isEmpty(model)) {
      this.router.transitionTo('employees');
    }
  }

  resetController(controller, isExiting, transition) {
    if (isExiting && transition.targetName !== 'error') {
      controller.currentUserDocumentLinkIndex = 0;
      controller.type = null;
    }
  }
}

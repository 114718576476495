import { service } from '@ember/service';
import type Model from '@ember-data/model';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type ConnectLocationConfigurationModel from 'garaje/models/connect-location-configuration';
import type ZoneModel from 'garaje/models/zone';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import type { KeysMatching, ModelData } from 'garaje/utils/type-utils';

type SettingsModels = ZoneModel | ConnectLocationConfigurationModel;
type BooleanKeys<T extends Model> = KeysMatching<ModelData<T>, boolean>;

interface RebadgingSettingsComponentArgs<T extends SettingsModels> {
  model: T;
  toggleKey: BooleanKeys<T>;
  title: string;
  description: string;
  showDisableConfirmation?: boolean;
  confirmationMessage?: string;
  cannotToggleLink?: string;
  cannotToggleLinkText?: string;
}

export default class RebadgingSettingsComponent<T extends SettingsModels> extends Component<
  RebadgingSettingsComponentArgs<T>
> {
  @tracked showConfirmationModal = false;

  get isChecked(): T[BooleanKeys<T>] {
    return this.args.model[this.args.toggleKey];
  }

  @service declare flashMessages: FlashMessagesService;

  toggleTask = task({ drop: true }, async (confirmed = false) => {
    if (confirmed) this.showConfirmationModal = false;

    const newToggleValue = <T[BooleanKeys<T>]>!this.isChecked;

    if (this.args.showDisableConfirmation && !confirmed && !newToggleValue) {
      this.showConfirmationModal = true;
      return;
    }

    this.args.model[this.args.toggleKey] = newToggleValue;

    try {
      await this.args.model.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      this.args.model.rollbackAttributes();
    }
  });
}

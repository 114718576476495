import Component from '@glimmer/component';
import EntryModel from 'garaje/models/entry';
import type InviteModel from 'garaje/models/invite';

interface ReservationStartTimeColumnArgs {
  content: EntryModel | InviteModel;
  fullDate: boolean;
}

export default class ReservationStartTimeColumn extends Component<ReservationStartTimeColumnArgs> {
  get reservationStartTime(): Date {
    const { content } = this.args;
    if (content instanceof EntryModel) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return content.signedInAt;
    }
    return content.expectedArrivalTime;
  }
}

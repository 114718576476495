/**
 * From: https://javascript.info/js-animation
 *
 * @param {Number}    duration  the total animation time in ms
 * @param {Function}  timing    the function to calculate animation progress
 * @param {Function}  draw      the function to draw the animation
 */
export default function animate({ timing, draw, duration }) {
  const start = performance.now();

  requestAnimationFrame(function animate(time) {
    // timeFraction goes from 0 to 1
    let timeFraction = (time - start) / duration;
    if (timeFraction > 1) {
      timeFraction = 1;
    }

    // calculate the current animation state
    const progress = timing(timeFraction);

    draw(progress);

    if (timeFraction < 1) {
      requestAnimationFrame(animate);
    }
  });
}

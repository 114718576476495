import { action } from '@ember/object';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { restartableTask, timeout } from 'ember-concurrency';
import type { Desk } from 'garaje/graphql/generated/assigned-and-unassigned-employees-types';
import type {
  DeskMapFeature,
  EmployeeItem,
  EmployeesOverviewSidePanel,
  FeaturesOverview,
  MapFeature,
  ResourceItem,
  RoomMapFeature,
  ScheduledAssignment,
  ScheduledEmployeesPanel,
} from 'garaje/graphql/generated/map-features-types';
import type AreaMapModel from 'garaje/models/area-map';
import type DeskModel from 'garaje/models/desk';
import type Employee from 'garaje/models/employee';
import type MapFeatureModel from 'garaje/models/map-feature';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type MetricsService from 'garaje/services/metrics';
import type ResourceOverviewService from 'garaje/services/resource-overview';
import {
  filterAndSortGroupedEmployees,
  getFlattenedEmployees,
  meetsAttendancePolicy,
  missesAttendancePolicy,
} from 'garaje/utils/resource-overview';
import zft from 'garaje/utils/zero-for-tests';

interface ResourceOverviewArgs {
  areaMap: AreaMapModel;
  gqlMapFeatures: FeaturesOverview;
  gqlEmployees: EmployeesOverviewSidePanel;
  gqlAssignments: ScheduledEmployeesPanel;
  selectedView: string;
  selectedNodeId: string;
  setSelectedView: (selectedView: string) => void;
  setPlacingResource: (resource: MapFeatureModel | null) => void;
  onSelectedFeatureChange: (feature: MapFeatureModel) => void;
  onSelectedEmployeeChange: (emoloyee: Employee | null) => void;
  onSelectedAssignmentChange: (assignment: ScheduledAssignment | null) => void;
  updateEmployeeOverview: (employee: Employee, assignedDesks: Array<Desk>) => Promise<void>;
  insightsCount: number | null;
  unassignedInsightsCount: number | null;
  assignedInsightsCount: number | null;
  setInsightsCount: () => void;
  floorId: number;
  shouldShowEmployeeForm: boolean;
  setEmployeeFormVisibility: (shouldShowEmployeeForm: boolean) => void;
  selectedEmployeeEmail: string | null;
  expandedMap: Array<string>;
  setExpandedMap: (path: string) => void;
  setSelectedEmployeeEmail: (email: string | null) => void;
  isLoading: boolean;
}
export default class ResourceOverview extends Component<ResourceOverviewArgs> {
  @service declare store: StoreService;
  @service declare resourceOverview: ResourceOverviewService;
  @service declare metrics: MetricsService;
  @service declare featureConfig: FeatureConfigService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare abilities: AbilitiesService;

  @tracked employees: Array<Employee> = [];
  @tracked mapFeatures: Array<DeskMapFeature | RoomMapFeature | MapFeature> = [];
  @tracked selectedEmployee: EmployeeItem | null = null;
  @tracked searchTerm: string = '';
  @tracked isInsightsToggled = false;
  @tracked seatingTabSelected = 'current';

  @action
  setSeatingTab(tab: string): void {
    this.seatingTabSelected = tab;
  }

  get canAccessResourceOverview(): boolean {
    return (
      this.featureConfig.isEnabled('maps.drafts') ||
      (this.featureConfig.isEnabled('maps.edit.resourcesOverview') && this.abilities.can('access-overview maps'))
    );
  }

  get gqlMapFeatures(): Partial<FeaturesOverview> {
    if (this.abilities.can('edit maps')) {
      return this.args.gqlMapFeatures;
    }

    return {
      desks: this.args.gqlMapFeatures.desks,
    };
  }

  get canAccessEmployeeSeating(): boolean {
    if (this.abilities.cannot('access-overview maps')) {
      return false;
    }

    return (
      this.featureConfig.isEnabled('maps.drafts') ||
      (this.featureConfig.isEnabled('maps.edit.employeeSeating') && this.abilities.can('access-overview maps'))
    );
  }

  get canAccessInsights(): boolean {
    return (
      (this.featureConfig.isEnabled('maps.drafts') ||
        (this.featureConfig.isEnabled('maps.edit.attendanceAnalytics') &&
          this.abilities.can('access-overview maps'))) &&
      this.isDataOfIndividualsSettingOn
    );
  }

  get employeesPopulatedByScim(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return !!this.resourceOverview.employeesData.meta?.employeesPopulatedByScim;
  }

  get isEmployeesGroupedByDepartment(): boolean {
    return this.flattenedEmployees.some((employee) => employee.department);
  }

  get assignmentsToShow(): ScheduledEmployeesPanel {
    const { gqlAssignments } = this.args;
    if (!this.searchTerm) {
      return gqlAssignments;
    }
    const filteredAssignments = gqlAssignments.assignments.filter((it) => {
      return it.name.toLowerCase().includes(this.searchTerm.toLowerCase());
    });
    return {
      assignments: filteredAssignments,
    };
  }

  // (ar) all this function does is apply insight or search term filters to determine which employees to show
  // this logic needs to be pushed up into the controller, and then passed down to this component as a tracked property
  // this way, the component will re-render properly when the filter changes
  get employeesToShow(): EmployeesOverviewSidePanel {
    const { gqlEmployees } = this.args;

    const unassignedEmployees = filterAndSortGroupedEmployees(
      gqlEmployees.unassigned,
      meetsAttendancePolicy,
      this.searchTerm,
      this.isInsightsToggled,
    );
    const assignedEmployees = filterAndSortGroupedEmployees(
      gqlEmployees.assigned,
      missesAttendancePolicy,
      this.searchTerm,
      this.isInsightsToggled,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-return
    return {
      unassigned: unassignedEmployees,
      assigned: assignedEmployees,
    } as EmployeesOverviewSidePanel;
  }

  get flattenedEmployees(): Array<EmployeeItem> {
    const { gqlEmployees } = this.args;
    return [...getFlattenedEmployees(gqlEmployees.assigned), ...getFlattenedEmployees(gqlEmployees.unassigned)];
  }

  get isDataOfIndividualsSettingOn(): boolean {
    return !this.flattenedEmployees.some(
      (it) => it.attendancePerformance === null || it.attendancePerformance === undefined,
    );
  }

  get deskFeaturesOnFloor(): Array<Desk> {
    // Might need to change this to use the object from the controller so updates are reflected
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return this.resourceOverview.gqlMapFeaturesApiResponse.reduce(
      (acc: Array<Desk>, feature: DeskMapFeature | RoomMapFeature | MapFeature) => {
        if (feature.__typename === 'DeskMapFeature') {
          acc.push(feature.desk);
        }
        return acc;
      },
      [],
    );
  }

  get selectAllKey(): string {
    if (navigator.userAgent.indexOf('Mac') !== -1) {
      return '⌘ Cmd';
    } else {
      return 'Ctlr';
    }
  }

  expandEmployeesInDepartment(): void {
    const { expandedMap, setExpandedMap } = this.args;

    const { assigned: assignedEmployees, unassigned: unassignedEmployees } = this.employeesToShow;
    if (this.isEmployeesGroupedByDepartment) {
      const assignedSections = Object.keys(assignedEmployees).reduce((acc: string[], department: string) => {
        const expandedMapPath = 'seating/assigned/' + department;
        if (!expandedMap.includes(expandedMapPath)) {
          acc.push(expandedMapPath);
        }
        return acc;
      }, []);

      const unassignedSections = Object.keys(unassignedEmployees).reduce((acc: string[], department: string) => {
        const expandedMapPath = 'seating/unassigned/' + department;
        if (!expandedMap.includes(expandedMapPath)) {
          acc.push(expandedMapPath);
        }
        return acc;
      }, []);
      const sectionsToExpand = [...assignedSections, ...unassignedSections];
      sectionsToExpand.forEach((section) => {
        setExpandedMap(section);
      });
    }
  }

  collapseAllDepartments(): void {
    const { expandedMap, setExpandedMap } = this.args;

    const sectionsToCollapse = expandedMap.filter((section) => {
      return section.includes('seating/unassigned/') || section.includes('seating/assigned/');
    });
    sectionsToCollapse.forEach((section) => {
      setExpandedMap(section);
    });
  }

  @restartableTask
  *employeeSearchTask(term: string): Generator<unknown, void, void> {
    const DEBOUNCE_MS = 250;
    yield timeout(zft(DEBOUNCE_MS));
    this.searchTerm = term;

    if (term) {
      this.expandEmployeesInDepartment();
    } else {
      this.collapseAllDepartments();
    }
  }

  @action
  onInsightsToggleClick(): void {
    this.isInsightsToggled = !this.isInsightsToggled;
    this.metrics.trackEvent('Toggled insights', { isInsightsToggled: this.isInsightsToggled });
  }

  @action
  onFeatureClick(data: ResourceItem): void {
    const { selectedView, onSelectedFeatureChange, setEmployeeFormVisibility } = this.args;
    const mapFeature = this.store.peekRecord('map-feature', data.featureId);
    if (mapFeature) {
      onSelectedFeatureChange(mapFeature);
    }
    this.selectedEmployee = null;
    setEmployeeFormVisibility(false);
    this.metrics.trackEvent('Selected feature in overview panel', {
      panel: selectedView,
      featureId: data.featureId,
    });
  }

  selectDeskAssignedToEmployee(employee: Employee): void {
    const { onSelectedFeatureChange, floorId } = this.args;
    // if the selected employee has an assigned desk, find the corresponding map feature and select it
    const assignedDesksForEmployeeWithSmallestId: DeskModel | null = this.store
      .peekAll('desk')
      .filter((desk) => {
        // @ts-ignore
        return desk.assignedTo === employee.email && desk.belongsTo('floor').id() === floorId;
      })
      .reduce(
        (deskWithSmallestId, currentDesk) => {
          if (!deskWithSmallestId) {
            return currentDesk;
          }
          return currentDesk.id > deskWithSmallestId.id ? currentDesk : deskWithSmallestId;
        },
        null as unknown as DeskModel,
      );
    let feature: MapFeatureModel | null = null;

    if (assignedDesksForEmployeeWithSmallestId) {
      feature =
        this.store.peekAll('map-feature').find((mapFeature) => {
          return mapFeature.type === 'desk' && mapFeature.externalId === assignedDesksForEmployeeWithSmallestId.id;
        }) ?? null;
    }
    if (feature) {
      onSelectedFeatureChange(feature);
      this.args.setEmployeeFormVisibility(true);
    }
  }

  @action
  updateSelectedEmployee<K extends keyof EmployeeItem>(employee: EmployeeItem, key: K, value: EmployeeItem[K]): void {
    this.selectedEmployee = {
      ...employee,
      [key]: value,
    };
  }

  @action
  async onEmployeeClick(employeeData: EmployeeItem): Promise<void> {
    const { selectedView, onSelectedEmployeeChange } = this.args;
    this.selectedEmployee = employeeData;
    const employee = await this.store.findRecord('employee', employeeData.id);
    // ensure that the desks for the employee's assignments are loaded into the store
    await this.resourceOverview.loadAssignmentDesks(
      employeeData.assignments.map((it) => {
        return it.deskId;
      }),
    );
    if (employee) {
      onSelectedEmployeeChange(employee);
    }
    this.selectDeskAssignedToEmployee(employee);
    this.metrics.trackEvent('Selected employee in overview panel', { panel: selectedView, employeeId: employee?.id });
  }

  @action
  async onAssignmentClick(assignment: ScheduledAssignment): Promise<void> {
    const { onSelectedAssignmentChange, setSelectedEmployeeEmail, setEmployeeFormVisibility, gqlAssignments } =
      this.args;
    onSelectedAssignmentChange(assignment);
    const gqlEmployee = this.flattenedEmployees.find((it) => it.id === parseInt(assignment.employeeId));
    const assignmentsForEmployee = gqlAssignments.assignments.filter((it) => {
      return it.employeeId === assignment.employeeId;
    });
    await this.resourceOverview.loadAssignmentDesks([assignment.deskId]);
    if (gqlEmployee) {
      this.selectedEmployee = {
        id: gqlEmployee.id,
        name: gqlEmployee.name,
        display: gqlEmployee.display,
        email: gqlEmployee.email,
        department: gqlEmployee.department,
        assignedDesks: gqlEmployee.assignedDesks,
        attendancePerformance: gqlEmployee.attendancePerformance,
        assignments: assignmentsForEmployee,
        iconClass: 'clock-icon-borderless',
        shouldShowIcon: assignmentsForEmployee.length > 0,
        meta: gqlEmployee.meta,
      };
      setSelectedEmployeeEmail(gqlEmployee.email);
      setEmployeeFormVisibility(true);
    }
  }

  @action
  onMenuClick(option: string): void {
    this.args.setSelectedView(option);
    if (option !== 'resources') {
      this.args.setPlacingResource(null);
    }
    this.metrics.trackEvent('Selected view in panel menu', { panel: option });
  }

  @action
  closeEmployeeForm(): void {
    const { setEmployeeFormVisibility } = this.args;
    this.selectedEmployee = null;
    setEmployeeFormVisibility(false);
    this.args.onSelectedEmployeeChange(null);
  }

  @action
  onCloseClick(): void {
    const { setSelectedView } = this.args;
    setSelectedView('');
  }
}

import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { service } from '@ember/service';
import { hash, restartableTask } from 'ember-concurrency';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import dirtyCheck from 'garaje/utils/decorators/dirty-check';

@dirtyCheck
class WorkplaceScheduledRoute extends Route {
  @service flow;
  @service skinnyLocations;
  @service state;
  @service store;

  // start: dirty-check overrides
  _dirtyObject = 'changeset';
  _dirtyAttr = 'isDirty';
  // end: dirty-check overrides

  model(params) {
    return this.modelTask.perform(params);
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.setupChangeset();
  }

  @restartableTask({
    cancelOn: 'deactivate',
  })
  *modelTask({ invite_id }) {
    const { currentLocation: location, workplaceSubscription } = this.state;
    const blocklistContacts = yield get(location, 'blocklistContacts');

    // Preload custom fields for flows. Since we use the fields to
    // build the UI, we want to make sure the data is loaded before
    // rendering something.
    const { flows } = this.modelFor('workplace.entries');

    // TODO: @heroiceric
    // This ensures we only reload config if it was already loaded. If we try
    // to reload when the config has not been previously loaded, we get an
    // error.
    //
    // I think this problem should go away when we're able to upgrade to newer
    // versions of ember data.
    if (location.belongsTo('config').value()) {
      yield location.belongsTo('config').reload();
    }

    const inviteInclude = [];

    const { config, invite } = yield hash({
      config: get(location, 'config'),
      invite: this.store
        .findRecord('invite', invite_id, { reload: true, include: inviteInclude.join() })
        .then((invite) => location.matchupInviteOrEntryFieldsWithLocationConfig(invite)),
    });

    const inviteLocation = yield get(invite, 'location');
    if (inviteLocation) {
      this.maybeSwitchCurrentLocation(location, inviteLocation);
    }

    this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
    yield this.flow.initService();

    let agreements = [];
    let signInFields = [];
    if (invite.flow) {
      const agreementPage = yield get(invite.flow, 'agreementPage');
      if (agreementPage && agreementPage.enabled) {
        agreements = yield get(agreementPage, 'agreements');
      }

      const signInFieldPage = yield get(invite.flow, 'signInFieldPage');
      if (signInFieldPage) {
        signInFields = yield get(signInFieldPage, 'signInFields');
      }

      if (get(invite, 'flow.isProtect')) {
        yield this.flow.getFlowWithIncludes(get(invite, 'flow.id'));
      }
    }

    return hash({
      agreements,
      blocklistContacts,
      config,
      flows,
      invite,
      location,
      signInFields,
      workplaceSubscription,
    });
  }

  maybeSwitchCurrentLocation(currentLocation, inviteLocation) {
    if (get(currentLocation, 'id') !== get(inviteLocation, 'id')) {
      const protectedController = this.controllerFor('protected');
      protectedController.send('switchLocation', get(inviteLocation, 'id'));
    }
  }
}

export default WorkplaceScheduledRoute;

// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action } from '@ember/object';
import { Promise } from 'rsvp';

export default class InlineInputEditorComponent extends Component {
  tagName = '';

  @action
  onChange(e) {
    // blur on success
    Promise.resolve(this.onTextChanged(e.target.value)).then(() => e.target.blur());
  }
}

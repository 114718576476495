import Component from '@glimmer/component';
import type { DetailedChangeset } from 'ember-changeset/types';
import type ZoneModel from 'garaje/models/zone';
import LOCALE_OPTIONS from 'garaje/utils/locale-options';

interface PropertyKioskLanguageSettingsLocaleComponentArgs {
  /**
   * action triggered when "Delete" button is clicked. Receives `@locale` as an argument.
   */
  delete: (locale: string) => unknown;
  /**
   * two-character locale code
   */
  locale: string;
  property: ZoneModel | DetailedChangeset<ZoneModel>;
  /**
   * action triggered when "Set as default" button is clicked. Receives `@locale` as an argument.
   */
  setDefault: (locale: string) => unknown;
}

export default class PropertyKioskLanguageSettingsLocaleComponent extends Component<PropertyKioskLanguageSettingsLocaleComponentArgs> {
  get isDefault(): boolean {
    return this.args.locale === this.args.property.locale;
  }

  get locale(): (typeof LOCALE_OPTIONS)[number] | undefined {
    const locale = this.args.locale;
    return LOCALE_OPTIONS.find(({ value }) => value === locale);
  }

  get localeName(): string | undefined {
    return this.locale?.label;
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type ConnectPropertyConfigurationModel from 'garaje/models/connect-property-configuration';
import type ZoneModel from 'garaje/models/zone';

export type PropertySecuritySettingsModel = {
  property: ZoneModel;
  connectPropertyConfiguration?: ConnectPropertyConfigurationModel;
};

export default class PropertySecuritySettingsRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare store: Store;

  beforeModel(): void {
    if (this.abilities.cannot('manage security settings for property')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  async model(): Promise<PropertySecuritySettingsModel> {
    const property = <ZoneModel>this.modelFor('property');

    const configurations = await this.store.query('connect-property-configuration', {
      filter: {
        property: property.id,
      },
    });

    const connectPropertyConfiguration = configurations.firstObject;

    return {
      property,
      connectPropertyConfiguration,
    };
  }
}

import { c as computeFn } from '../helper-compute-36ed98e8.js';
import BaseHelper from './-base.js';
import '@ember/utils';
import '@ember/runloop';
import '@ember/component/helper';
import '@ember/object';
import '@ember/service';

var momentFromNow = BaseHelper.extend({
  compute: computeFn(function (params, {
    hideAffix,
    locale,
    timeZone
  }) {
    this._super(...arguments);

    const moment = this.moment;
    return this.morphMoment(moment.moment(...params), {
      locale,
      timeZone
    }).fromNow(hideAffix);
  })
});

export { momentFromNow as default };

import { attr, belongsTo, hasMany } from '@ember-data/model';
import type { AsyncHasMany } from '@ember-data/model';
import AbstractAgreementPageModel from 'garaje/models/abstract/abstract-agreement-page';
import type FlowModel from 'garaje/models/flow';
import PropagableChangesTracker from 'garaje/utils/decorators/propagable-changes-tracker';
import NDA_TEXT from 'garaje/utils/nda-text';

import type AgreementModel from './agreement';

@PropagableChangesTracker
class AgreementPageModel extends AbstractAgreementPageModel {
  @hasMany('agreement') declare agreements: AsyncHasMany<AgreementModel>;
  @belongsTo('flow') declare flow: FlowModel;

  @attr('string', { defaultValue: NDA_TEXT }) declare body: string;
}

AgreementPageModel.reopenClass({
  OVERWRITABLE_PARENT: 'flow',
  OVERWRITABLE_SETTINGS: Object.freeze({
    enabled: 'NDA signing',
    body: 'NDA text',
    optional: 'allow visitors to decline signing',
    duration: 're-sign NDA after total days',
    sendToBcc: 'email signed NDAs',
    bccEmail: 'email signed NDA recipient',
  }),
});

export default AgreementPageModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'agreement-page': AgreementPageModel;
  }
}

import { type ValidatorMap } from 'ember-changeset-validations';
import { validatePresence } from 'ember-changeset-validations/validators';

interface BuildInviteValidationsOptions {
  isBulk?: boolean;
}

/**
 *  Generates validation rules for a connect invite pr bulk connect invite
 *
 * @param options object literal for configuring validation rules for the changeset
 * @param options.isBulk object literal for configuring validation rules for the changeset
 *
 * @returns validations for a connect invite or bulk connect invite
 */
export default function buildInviteValidations(options: BuildInviteValidationsOptions = {}): ValidatorMap {
  const defaults = {
    isBulk: false,
  };

  options = Object.assign({}, defaults, options);

  const validations: ValidatorMap = {
    expectedArrivalTime: validatePresence({
      presence: true,
      message: 'This is a required field',
    }),
    checkedInAt: validatePresence({
      presence: true,
      message: 'This is a required field',
    }),
    tenant: validatePresence({
      presence: true,
      message: 'This is a required field',
    }),
  };

  if (options.isBulk) {
    validations['fullNames'] = validatePresence({
      presence: true,
      message: 'This is a required field',
    });
  } else {
    validations['fullName'] = validatePresence({
      presence: true,
      message: 'This is a required field',
      ignoreBlank: true,
    });
  }

  return validations;
}

/* eslint-disable ember/no-computed-properties-in-native-classes */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { computed } from '@ember/object';
import { sort } from '@ember/object/computed';

export default class RecordSelectHeadComponent extends Component {
  tagName = 'tbody';

  @sort('models', 'sortDefinition') sortedModels;

  @computed('sortDirection', 'sortField')
  get sortDefinition() {
    return [`${this.sortField}:${this.sortDirection}`];
  }
}

import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Modifier from 'ember-modifier';
import type ResizeObserverService from 'ember-resize-observer-service/services/resize-observer';

interface AutoTableHeadersModifierSignature {
  Element: HTMLTableElement;
  Args?: {
    Positional?: Array<unknown>;
    Named?: {
      [argName: string]: unknown;
    };
  };
}

/**
 * A modifier that automatically sets the width of the table headers to equal the table body column widths.
 * Useful when you have table with a scrollable body that utilized a block element for the table header and body.
 */
export default class AutoTableHeadersModifier extends Modifier<AutoTableHeadersModifierSignature> {
  @service declare resizeObserver: ResizeObserverService;

  modify(table: HTMLTableElement): void {
    const onResize = () => this.handleResize(table);

    this.resizeObserver.observe(table, onResize);

    registerDestructor(this, (table: HTMLTableElement) => {
      this.resizeObserver.unobserve(table, onResize);
    });
  }

  @action
  handleResize(table: HTMLTableElement): void {
    const $bodyCells = table.querySelectorAll<HTMLTableCellElement>('tbody tr:first-child td');
    const $headerCells = table.querySelectorAll<HTMLTableCellElement>('thead tr:first-child th');

    Array.from($bodyCells).map((cell, index) => {
      if ($headerCells[index]) {
        $headerCells[index].style.width = cell.clientWidth + 'px';
        $headerCells[index].style.minWidth = cell.clientWidth + 'px';
        $headerCells[index].style.boxSizing = 'border-box';
      }
    });
  }
}

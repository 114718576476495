import Model, { belongsTo, attr } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import type LocationModel from 'garaje/models/location';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('v2')
class SyncBatchModel extends Model {
  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;
  @attr('string') declare status: string;
  @attr() declare info: unknown;
  @attr('date') declare startedAt: Date;
  @attr('date') declare endedAt: Date;
  @attr('string') declare providerName: string;
  @attr('string') declare message: string;
}

export default SyncBatchModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'sync-batch': SyncBatchModel;
  }
}

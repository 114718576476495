import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type AbilitiesService from 'ember-can/services/abilities';

export default class ReadOnly extends Component {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  get showEmployeeDetails(): boolean {
    if (this.router.currentRouteName?.includes('location-overview')) {
      return this.abilities.can('see directory details for employees');
    } else {
      return this.abilities.can('see directory details at location for employees');
    }
  }
}

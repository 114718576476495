import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type { GetCompanyQuery } from 'garaje/graphql/generated/roomba-types';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import type RoomsPollingService from 'garaje/services/rooms-polling';

export default class RoombaListRoute extends Route {
  @service declare roombaGraphql: RoombaGraphqlService;
  @service declare roomsPolling: RoomsPollingService;

  async model(): Promise<{ company: GetCompanyQuery['company'] }> {
    await this.roomsPolling.fetchRooms.perform();
    return {
      company: await this.roombaGraphql.getCompany(),
    };
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { formatMinutesAsTime, getMinutesInDayFromTime, TIMES } from 'garaje/utils/format-minutes-as-time';

/**
 * @param {Model<Location>}           location
 * @param {Task}                      saveTask
 * @param {Function}                  updateAutoSignOutAtMinutesSinceMidnight
 */
export default class AutoSignOutTime extends Component {
  @service workplaceMetrics;

  defaultTime = '12:00 am';
  @tracked isOpen = false;
  @tracked showUnsavedEditsWarning = false;
  times = TIMES;

  get isEnabled() {
    const { location: currentLocation } = this.args;
    const { autoSignOutAtMinutesSinceMidnight } = currentLocation.changedAttributes();
    if (autoSignOutAtMinutesSinceMidnight && autoSignOutAtMinutesSinceMidnight.length) {
      const [original, _changed] = autoSignOutAtMinutesSinceMidnight;
      return original !== null;
    } else {
      return currentLocation.autoSignOutAtMinutesSinceMidnight !== null;
    }
  }

  get time() {
    const { location: currentLocation } = this.args;
    const minutesInDay = currentLocation.autoSignOutAtMinutesSinceMidnight;
    return formatMinutesAsTime(minutesInDay || 0);
  }

  @action
  onContinue() {
    const { location: currentLocation } = this.args;
    if (currentLocation.hasDirtyAttributes) {
      currentLocation.rollbackAttributes();
    }
    this.showUnsavedEditsWarning = false;
    this.isOpen = false;
  }

  @action
  cancel() {
    const { location: currentLocation } = this.args;
    const { autoSignOutAtMinutesSinceMidnight } = currentLocation.changedAttributes();
    if (!autoSignOutAtMinutesSinceMidnight || !autoSignOutAtMinutesSinceMidnight.length) {
      this.onContinue();
    }
    const [original, _changed] = autoSignOutAtMinutesSinceMidnight;
    if (original === null) {
      // Not enabled yet
      this.onContinue();
    } else {
      this.showUnsavedEditsWarning = true;
    }
  }

  @action
  edit() {
    this.workplaceMetrics.trackEvent('LOCATION_SETTINGS_AUTO_SIGN_OUT_EDIT_BUTTON_CLICKED');
    this.isOpen = true;
  }

  @action
  enable() {
    this.setTime(this.defaultTime);
    this.isOpen = true;
  }

  @action
  async disable() {
    this.args.updateAutoSignOutAtMinutesSinceMidnight(null);
    await this.args.saveTask.perform();
    this.isOpen = false;
  }

  @action
  async save() {
    this.workplaceMetrics.trackEvent('LOCATION_SETTINGS_AUTO_SIGN_OUT_SETTINGS_SAVE_BUTTON_CLICKED');
    await this.args.saveTask.perform();
    this.isOpen = false;
  }

  @action
  setTime(time) {
    const minutesInDay = getMinutesInDayFromTime(time);
    this.args.updateAutoSignOutAtMinutesSinceMidnight(minutesInDay);
  }
}

import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import type AbilitiesService from 'ember-can/services/abilities';
import type FlowModel from 'garaje/models/flow';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type GlobalSettingBatchService from 'garaje/services/global-setting-batch';
import { alias, equal, or } from 'macro-decorators';

import type { VisitorTypesFlowRouteModel } from './route';

export default class VisitorTypesFlowController extends Controller {
  declare model: VisitorTypesFlowRouteModel;

  @service declare abilities: AbilitiesService;
  @service declare featureFlags: FeatureFlagsService;
  @service('global-setting-batch') declare globalSettingBatchService: GlobalSettingBatchService;
  @service declare router: RouterService;
  @service declare currentAdmin: CurrentAdminService;

  @alias('globalSettingBatchService.currentGlobalSettingBatch')
  globalSettingBatch!: GlobalSettingBatchService['currentGlobalSettingBatch'];
  @equal('router.currentRouteName', 'visitors.settings.visitor-types.flow.legal-documents.edit')
  onEditLegalDocumentRoute!: boolean;
  @equal('router.currentRouteName', 'visitors.settings.visitor-types.flow.legal-documents.new')
  onNewLegalDocumentRoute!: boolean;
  @or('onEditLegalDocumentRoute', 'onNewLegalDocumentRoute') onNewOrEditLegalDocumentRoute!: boolean;
  @equal('localFlows.length', 1) singleLocationFlow!: boolean;
  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isLocationAdmin') isAdmin!: boolean;

  get localFlows(): FlowModel[] {
    const { allFlowsForCurrentLocation: flows } = this.model;
    return flows.filter(
      ({ type, employeeCentric }) => (isBlank(type) || type.toLowerCase() === 'flows') && !employeeCentric,
    );
  }

  get canEditGlobalFlow(): boolean {
    return this.featureFlags.isEnabled('custom-roles-garaje-authz-vis3-flows')
      ? this.abilities.can('edit global-flows')
      : this.currentAdmin.isGlobalAdmin;
  }

  get showSetupGuide(): boolean {
    return this.isAdmin && this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper');
  }

  @action
  transitionToFlow(flow: FlowModel): void {
    this.transitionToRoute(this.router.currentRouteName, flow.id); // eslint-disable-line ember/no-deprecated-router-transition-methods
  }
}

import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const ALLOWED_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN];

export default class TemperatureScreeningAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;

  get canApprove(): boolean {
    return this.canReview;
  }

  get canDeny(): boolean {
    return this.canReview;
  }

  get canConfigure(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(ALLOWED_ROLES, roleNames));
  }

  get canReview(): boolean {
    const { roleNames } = this.currentAdmin;
    const blocklistContacts = this.state.currentLocation?.blocklistContacts.slice() || [];
    const isSecurityContact = !!blocklistContacts.find((contact) => contact.id === this.currentAdmin.id);

    return isPresent(_intersection(ALLOWED_ROLES, roleNames)) || isSecurityContact;
  }
}

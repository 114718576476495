import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { addDays, addYears, endOfDay, fromUnixTime, getUnixTime, isAfter, startOfDay } from 'date-fns';
import { getReadableScheduleEligibilityOffset } from 'garaje/utils/getReadableScheduleEligibilityOffset';
import { MINUTES_IN_A_DAY } from 'garaje/utils/enums';

/**
 * @param {Date}        date
 * @param {Date}        currentMonth
 * @param {Array}       selectedDates
 * @param {Date}        selectedStartDate
 * @param {Date}        selectedEndDate
 * @param {Boolean}     respectSchedulingLimits
 * @param {Boolean}     hideTrailingDayCells
 * @param {Boolean}     hideLeadingDayCells
 * @param {Array}       hasEvents
 * @param {Function}    selectDate
 * @param {Object}      daysToCustomize
 * @param {Object}      dateLimits
 */
export default class DayCell extends Component {
  @service state;

  get shouldHightlightCell() {
    const { date, currentMonth, hideTrailingDayCells, hideLeadingDayCells } = this.args;
    const beginningOfNextMonth = moment(currentMonth).startOf('month').add(1, 'month');
    const beginningOfPrevMonth = moment(currentMonth).startOf('month').subtract(1, 'month');
    if (date.clone().startOf('month').isSame(beginningOfNextMonth) && hideTrailingDayCells) {
      return false;
    }
    if (date.clone().startOf('month').isSame(beginningOfPrevMonth) && hideLeadingDayCells) {
      return false;
    }
    return true;
  }

  get classes() {
    const { date, currentMonth, selectedStartDate, selectedEndDate, hideTrailingDayCells, hideLeadingDayCells } =
      this.args;

    const dayFormatted = moment(date).format('YYYYMMDD');
    const today = moment().format('YYYYMMDD');
    const displayedMonth = moment().format('MM');
    const beginningOfMonth = moment(currentMonth).startOf('month');
    const endOfMonth = moment(beginningOfMonth).endOf('month');
    const currentMonthFormatted = endOfMonth.format('MM');

    const classes = [];
    if (dayFormatted === today && displayedMonth === currentMonthFormatted) {
      classes.push('today');
    }

    if (date.weekday() === 0 || date.weekday() === 6) {
      classes.push('weekend');
    }

    if (selectedStartDate && date.isSame(selectedStartDate.startOf('day')) && this.shouldHightlightCell) {
      classes.push('range-boundary');
      classes.push('startAt');
    }

    if (selectedEndDate && date.isSame(selectedEndDate.startOf('day')) && this.shouldHightlightCell) {
      classes.push('range-boundary');
      classes.push('endAt');
    }

    if (date.isBefore(beginningOfMonth)) {
      classes.push(hideLeadingDayCells ? 'pointer-events-none' : 'past');
    }

    if (date.isAfter(endOfMonth)) {
      classes.push(hideTrailingDayCells ? 'pointer-events-none' : 'future');
    }

    if (
      selectedStartDate &&
      selectedEndDate &&
      date.isBetween(selectedStartDate, selectedEndDate) &&
      this.shouldHightlightCell
    ) {
      classes.push('range');
    }

    if (!this.isWithinLimits || !this.dayIsActive || this.dayIsOutOfSchedule) {
      classes.push('line-through', 'leading-6', 'out-of-limits', 'disabled');
    }

    if (!this.isWithinLimits || !this.dayIsActive) {
      classes.push('pointer-events-none');
    }

    const uniqClasses = [...new Set(classes)];
    return uniqClasses.join(' ');
  }

  get dayClasses() {
    const {
      daysToCustomize,
      selectedDates,
      selectedStartDate,
      selectedEndDate,
      date,
      currentMonth,
      hideLeadingDayCells,
      hideTrailingDayCells,
    } = this.args;
    const classes = [];
    const isInDateRange = moment(date).isBetween(selectedStartDate, selectedEndDate, undefined, '[]');
    const beginningOfMonth = moment(currentMonth).startOf('month');
    const endOfMonth = moment(beginningOfMonth).endOf('month');
    if (daysToCustomize && this.isWithinLimits && this.dayToCustomize) {
      const classArr = this.dayToCustomize.class.split(' ');
      classes.push(...classArr);
    } else if (!isInDateRange && this.isWithinLimits && !this.dayIsOutOfSchedule) {
      classes.push('hoverable');
    }
    const dayFormatted = moment(date).format('YYYYMMDD');
    if (selectedDates) {
      const dateIsSelected = selectedDates.find((selected) => dayFormatted === moment(selected).format('YYYYMMDD'));
      if (!selectedStartDate && !selectedEndDate && dateIsSelected) {
        classes.push('bg-carbon-10', 'text-carbon-70', 'rounded-full', 'py-1', 'selected-date');
      }
    } else {
      if (date.isBefore(beginningOfMonth)) {
        classes.push(hideLeadingDayCells ? 'hidden' : 'text-carbon-10');
      }

      if (date.isAfter(endOfMonth)) {
        classes.push(hideTrailingDayCells ? 'hidden' : 'text-carbon-10');
      }
    }

    const uniqClasses = [...new Set(classes)];
    return uniqClasses.join(' ');
  }

  get dayIsOutOfSchedule() {
    const { date, respectSchedulingLimits } = this.args;

    if (!respectSchedulingLimits) {
      return false;
    }

    const locationDate = this.state.getOfficeLocationTime(startOfDay(new Date()));
    const endOfEligibilityDate = this.getEndOfEligibilityDate(locationDate);

    if (!endOfEligibilityDate) {
      return false;
    }

    return isAfter(fromUnixTime(date.unix()), fromUnixTime(endOfEligibilityDate));
  }

  get tooltip() {
    if (this.dayToCustomize?.tooltip) {
      return this.dayToCustomize.tooltip;
    }

    if (this.dayIsOutOfSchedule) {
      const offsetText = getReadableScheduleEligibilityOffset(this.state.currentLocation.scheduleEligibilityOffset);
      return `Your company only allows you to schedule up to ${offsetText} in advance`;
    }

    return undefined;
  }

  get dayIsActive() {
    const { activeDays, date } = this.args;

    if (!activeDays) {
      return true;
    }

    const dayOfWeek = moment(date).format('dddd');

    return activeDays.some((day) => day.dayOfWeek === dayOfWeek);
  }

  get dayToCustomize() {
    const { date, daysToCustomize } = this.args;

    if (!daysToCustomize) {
      return null;
    }

    const dayToCustomize = daysToCustomize.find((day) => {
      return moment(date).isSame(moment.unix(day.date), 'day');
    });

    return dayToCustomize;
  }

  get isWithinLimits() {
    const { date, dateLimits } = this.args;

    if (dateLimits) {
      return moment(date).isBetween(
        moment.unix(dateLimits.startDate).startOf('day'),
        moment.unix(dateLimits.endDate).endOf('day'),
        'day',
        '[]',
      );
    } else {
      return true;
    }
  }

  getEndOfEligibilityDate(locationDate) {
    const endOfScheduleEligibility = this.state.currentLocation.scheduleEligibilityOffset;

    if (!endOfScheduleEligibility) {
      return getUnixTime(addYears(locationDate, 2));
    }

    const daysToAdd = endOfScheduleEligibility / -MINUTES_IN_A_DAY - 1;

    return getUnixTime(endOfDay(addDays(locationDate, daysToAdd)));
  }

  @action
  selectDate() {
    const { selectDate, date } = this.args;

    if (!this.dayToCustomize?.disabled && !this.dayIsOutOfSchedule) {
      selectDate(date);
    }
  }
}

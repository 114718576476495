import { helper } from '@ember/component/helper';
import config, { type Product } from 'garaje/config/environment';

/**
 * This ProductDisabled helper determines if an entire product is disabled or not,
 * globally. This is (currently) primarily used for the EU data center where we want to
 * hide large areas of the product because they have not been deployed to EU.
 *
 * We maintain a list of products to disable in the config/environments/<env>.js file. If
 * a product is not listed in the config, it is assumed to be enabled.
 *
 * Example usage:
 * {{#if (product-enabled "desks")}}
 *   <div>Desks is enabled</div>
 * {{/if}}
 *
 * @param {String[]} productName   Required. An unnamed string, passed as the first argument.
 * @returns {Boolean}              Whether the split flag is enabled or disabled
 */
export function isProductDisabled(productName: Product): boolean {
  return _isProductDisabled([productName]);
}

// The only difference between this internal function and the exported one above is the
// method signature. The array positional parameter is a requirement of a helper function,
// but looks odd to use if you use it directly in a regular js/ts file.
function _isProductDisabled([productName]: [Product]) {
  const disabledProducts: string[] = config.disabledProducts || [];
  return disabledProducts.includes(productName);
}

export default helper(_isProductDisabled);

import RegistrationDatePartialDayFragment from 'garaje/graphql/fragments/employee-schedule/RegistrationDatePartialDayFragment';
import gql from 'graphql-tag';

export default gql`
  query EmployeeRegistrationPartialDay(
    $locationId: ID!
    $startDate: DateTime!
    $endDate: DateTime!
    $includeEmployeeInvite: Boolean!
  ) {
    employeeRegistrationPartialDay(locationId: $locationId, startDate: $startDate, endDate: $endDate) {
      registrationDates {
        ...RegistrationDatePartialDayFragment
      }
    }
  }
  ${RegistrationDatePartialDayFragment}
`;

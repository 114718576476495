import $ from 'jquery';
import { run, later, cancel } from '@ember/runloop';
import { Promise as EmberPromise } from 'rsvp';

import config from 'garaje/config/environment';

export function _popupFlow(window, $, intervalTimeInMs, providerURL, width, height, successStatus = 'success') {
  if (config.environment === 'test') {
    intervalTimeInMs = 0;
  }

  return new EmberPromise(function (resolve, reject) {
    const screenX = typeof window.screenX !== 'undefined' ? window.screenX : window.screenLeft;
    const screenY = typeof window.screenY !== 'undefined' ? window.screenY : window.screenTop;
    const outerWidth = typeof window.outerWidth !== 'undefined' ? window.outerWidth : document.body.clientWidth;
    const outerHeight =
      typeof window.outerHeight !== 'undefined' ? window.outerHeight : document.body.clientHeight - 22;
    const left = parseInt(screenX + (outerWidth - width) / 2, 10);
    const top = parseInt(screenY + (outerHeight - height) / 2.5, 10);
    const features = `width=${width},height=${height},left=${left},top=${top}`;
    let isResolved = false;
    const newWindow = window.open(providerURL, 'Login', features);
    window._popupFlowWindow = newWindow;

    if (!newWindow) {
      run(function () {
        reject('could not open popup flow');
      });
    }

    if (window.focus) {
      newWindow.focus();
    }

    // Check whether the user closes the window
    const windowClosedHandler = function () {
      if (newWindow && newWindow.closed) {
        window.console.log('window closed');
        // wait to see if there's a posted message from the same time the window was closed
        later(function () {
          if (!isResolved) {
            cancel(checkWindowClosedTimer);
            reject();
          }
        }, intervalTimeInMs);
      } else {
        checkWindowClosedTimer = later(windowClosedHandler, intervalTimeInMs);
      }
    };
    let checkWindowClosedTimer = later(windowClosedHandler, intervalTimeInMs);
    const waitForNextMessageEvent = function () {
      $(window).one('message onmessage', function (event) {
        cancel(checkWindowClosedTimer);
        let data;
        try {
          data = JSON.parse(event.originalEvent.data);

          // We check that there's a status field to ensure it's a message of Envoy.
          if (!data.status) {
            throw new Error('NoStatusField');
          }
        } catch (e) {
          window.console.log('probably posted by flow subject', event);
          waitForNextMessageEvent();
          return;
        }
        isResolved = true;
        if (data.status === successStatus) {
          run(function () {
            resolve(data);
          });
        } else {
          run(function () {
            reject(data);
          });
        }
      });
    };
    waitForNextMessageEvent();
  });
}

export default _popupFlow.bind(null, window, $, 500);

import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { dropTask } from 'ember-concurrency';

/**
 * @param {Delivery}                delivery
 * @param {Task}                    onMarkAsPickedUpTask
 * @param {Boolean}                 isSelected
 * @param {Function}                toggleDelivery
 */
export default class DeliveryListItem extends Component {
  dateFormat = 'MMM d, yyyy - h:mm aaa';

  get isUnidentified() {
    const name = get(this.args.delivery, 'recipientName');
    return isEmpty(name);
  }

  @dropTask
  *markAsPickedUp() {
    yield this.args.onMarkAsPickedUpTask.perform(this.args.delivery);
  }

  @action
  toggleDelivery({ target: { checked } }) {
    this.args.toggleDelivery(checked);
  }
}

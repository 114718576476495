import { type AsyncBelongsTo, belongsTo } from '@ember-data/model';
import AbstractGdprConfigurationModel from 'garaje/models/abstract/abstract-gdpr-configuration';
import type LocationModel from 'garaje/models/location';

class GdprConfigurationModel extends AbstractGdprConfigurationModel {
  @belongsTo('location') declare location: AsyncBelongsTo<LocationModel>;
}

export default GdprConfigurationModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'gdpr-configuration': GdprConfigurationModel;
  }
}

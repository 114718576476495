import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { dropTask, task, timeout } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import zft from 'garaje/utils/zero-for-tests';
import { APP } from 'garaje/utils/enums';

const POLLING_INTERVAL = 1000; // check @device.isConnected every this often (in milliseconds) when pairing

/**
 * @param {string}    cancelRoute          - name of the route to transition to if "Cancel" button is clicked
 * @param {Device}    device               - new Device model
 * @param {Function}  onConnected          - action triggered once the new device completes the pairing process
 * @param {Printer[]} printers             - list of printers that can be selected to connect to the new device
 * @param {Function}  reinitializeDevice   - an action triggered to create a new Device model; this is used if the initial one we were given _saved_ successfully but failed to pair. Since the backend logic for pairing only works on create, not update, we need to start over with a new model. However, since the model may have different relationships depending on how it's used (location vs. zone, for example), we can't just create a new one here. This function should return a Device model.
 * @param {boolean}   [saveButtonDisabled] - optional flag to disable Save button. (This is in addition to the Save button becoming disabled while the save operation is running.)
 * @param {boolean}   showPrinters         - whether the Printer selection portion of the form should be displayed. (Note that the Printer section will not displayed if `@printers` is empty, regardless of the value of this argument.)
 *
 * This component yields a `<:buttons>` named block to allow callers to insert additional content in the same part
 * of the UI as the "save" button". This block receives one argument:
 * @param {boolean} saveRunning - whether or note the save task is currently running (this can be used to disable other buttons while saving)
 */
export default class DevicesNewIpadFormComponent extends Component {
  @service flashMessages;
  @service router;
  @service metrics;
  @service featureFlags;
  @service('setupGuideStepper') setupGuideStepperService;

  @tracked device = this.args.device; // eslint-disable-line ember/no-tracked-properties-from-args

  get userToken() {
    return this.device.userToken;
  }
  set userToken(value) {
    const userToken = (value || '').toUpperCase();
    this.device.userToken = userToken;
  }

  get isGrantExpired() {
    const expiredAt = this.device?.grantExpiresAt;
    return new Date() > expiredAt;
  }

  get printerOptions() {
    return this.args.printers.map((printer) => ({
      disabled: printer.connectionType === 'bluetooth',
      printer,
    }));
  }

  get selectedPrinterOption() {
    const selectedPrinterId = get(this.device.printer || {}, 'id'); // /me shakes fist at ember-data
    return this.printerOptions.find((option) => option.printer.id === selectedPrinterId);
  }

  get showPrinters() {
    return this.args.showPrinters && this.args.printers.length > 0;
  }

  @dropTask
  *saveTask() {
    if (this.args.saveButtonDisabled) return;
    try {
      yield this.device.save();
      this.metrics.trackEvent(
        'Device - New iPad Paired',
        { marketo_prop: 'yes' }, // this prop is useless but Marketo requires at least 1 prop on CustomActivity
        {
          // context is here to satisfy downstream adWords requirements
          context: {
            app: { namespace: 'com.envoy.dashboard', version: 1 },
            device: {
              type: window?.navigator?.vendor,
              advertisingId: 'web-browser',
            },
            os: { version: 1 },
          },
        },
      );

      yield this.waitUntilConnectedOrExpired.perform();
      if (this.device.isConnected) {
        this.args.onConnected?.();

        if (this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper')) {
          this.setupGuideStepperService.loadSetupStepsTask.perform(APP.VISITORS);
        }
      }
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }

  @task
  *waitUntilConnectedOrExpired() {
    while (!(this.device.isConnected || this.isGrantExpired)) {
      yield timeout(zft(POLLING_INTERVAL));
    }
  }

  @action
  cancel() {
    // reset the state of this component on cancel because we might end up back on the same route,
    // in which case this component is not torn down and recreated but instead reused.
    // This can haopen when cancelling during the pairing of the first iPad for a location/property;
    // @cancelRoute might be the listing page, which redirects to the "new" page when there are no
    // paired iPads.
    this.saveTask.cancelAll();
    this.newToken();
    this.router.transitionTo(this.args.cancelRoute);
  }

  @action
  newToken() {
    this.device = this.args.reinitializeDevice();
  }

  @action
  printerSelected({ printer }) {
    this.device.printer = printer;
  }
}

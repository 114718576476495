import CalendarPermissionsStatusFragment from 'garaje/graphql/fragments/roomba/CalendarPermissionsStatusFragment';
import DeviceFragment from 'garaje/graphql/fragments/roomba/DeviceFragment';
import LocationFragment from 'garaje/graphql/fragments/roomba/LocationFragment';
import RoomFragment from 'garaje/graphql/fragments/roomba/RoomFragment';
import gql from 'graphql-tag';

export default gql`
  query GetRooms($filter: RoomsFilterInput!) {
    rooms(filter: $filter) {
      ...RoomFragment
      calendarPermissionsStatus {
        ...CalendarPermissionsStatusFragment
      }
      devices {
        ...DeviceFragment
      }
      location {
        ...LocationFragment
      }
    }
  }
  ${RoomFragment}
  ${DeviceFragment}
  ${LocationFragment}
  ${CalendarPermissionsStatusFragment}
`;

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { Changeset } from 'ember-changeset';
import { type DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import { dropTask } from 'ember-concurrency';
import type ConnectFloorModel from 'garaje/models/connect-floor';
import type ZoneModel from 'garaje/models/zone';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import buildZoneValidations from 'garaje/validations/zone';
import { defer } from 'rsvp';
import { cached } from 'tracked-toolbox';

import type { SuitesRouteModel } from './route';

export default class PropertySettingsSuitesController extends Controller {
  declare model: SuitesRouteModel;

  @service declare abilities: AbilitiesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare state: StateService;
  @service declare store: Store;

  queryParams = ['sortBy', 'sortOrder', 'backTo'];

  @tracked suite: ZoneModel | null = null;
  @tracked addingNewSuite = false;
  @tracked sortBy = 'Suite name';
  @tracked sortOrder = 'A-Z';
  @tracked backTo = null;

  @tracked total = 0;

  get isShowingSuiteModal(): boolean {
    return !!this.suite;
  }

  get backToLink(): string | undefined {
    return this.backTo
      ? {
          tenants: 'property.settings.tenants',
          properties: 'connect.properties',
        }[this.backTo]
      : undefined;
  }

  @cached
  get suiteChangeset(): DetailedChangeset<ZoneModel> | null {
    if (!this.suite) return null;

    const validations = buildZoneValidations({
      // It cannot be defaulted to true since area is _not_ required for non-suite zones (such as properties).
      requireArea: true,
    });

    const validator = lookupValidator(validations);

    return Changeset(this.suite, validator, validations);
  }

  @action
  closeSuiteModal(): void {
    if (!this.suite) return;
    if (<boolean>(<unknown>this.suite.isNew)) {
      this.suite.unloadRecord();
    } else {
      this.suite.rollbackAttributes();
    }
    this.suite = null;
  }

  @action
  openAddSuiteModal(): void {
    this.addingNewSuite = true;
    this.suite = this.store.createRecord('zone', {
      company: this.state.currentCompany,
      parent: this.model.property,
    });
  }

  @action
  openEditSuiteModal(suite: ZoneModel): void {
    this.addingNewSuite = false;
    this.suite = suite;
  }

  saveSuite = dropTask(async () => {
    if (!this.suiteChangeset) return;

    const isNew = <boolean>(<unknown>this.suiteChangeset.isNew);

    try {
      const successMessage = isNew ? 'Successfully created!' : 'Saved!';
      const floor = <ConnectFloorModel>this.suiteChangeset.floor;
      if (floor && <boolean>(<unknown>floor.isNew)) {
        await floor.save();
      }
      await this.suiteChangeset.save();

      let details: string | undefined;

      if (
        this.abilities.can('visit connect analytics') &&
        this.featureFlags.isEnabled('connect-occupancy-analytics-live')
      ) {
        details = 'Note: it may take up to 1 hour for these changes to reflect in the Connect Overview Analytics.';
      }
      this.flashMessages.showAndHideFlash('success', successMessage, {
        details,
      });

      if (isNew) {
        void this.model.suites.unshiftObject(this.suite!);
        this.total += 1;
      }

      this.suite = null;
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      const errorMessage = isNew ? 'Unable to create suite.' : 'Unable to save.';
      this.flashMessages.showAndHideFlash('error', errorMessage);
    }
  });

  @action
  async saveNewSuiteAndAddAnother(): Promise<void> {
    await this.saveSuite.perform();
    this.suite = this.store.createRecord('zone', {
      company: this.state.currentCompany,
      parent: this.model.property,
    });
  }

  @dropTask
  confirmDeleteSuiteTask: {
    perform(suite: ZoneModel): Generator<Promise<unknown>, unknown, unknown>;
  } = {
    *perform(
      this: {
        abort?: () => void;
        continue?: () => void;
        suite?: ZoneModel;
      },
      suite: ZoneModel,
    ): Generator<Promise<unknown>, void, void> {
      const deferred = defer();

      this.abort = () => deferred.resolve(false);
      this.continue = () => deferred.resolve(true);
      this.suite = suite;

      return yield deferred.promise;
    },
  };

  deleteSuiteTask = dropTask(async (suite: ZoneModel) => {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    const confirm = await this.confirmDeleteSuiteTask.perform(suite);
    if (!confirm) return;

    const suiteName = suite.name;
    try {
      await suite.destroyRecord();
      this.model.suites.removeObject(suite);
      this.total -= 1;
      this.flashMessages.showAndHideFlash('success', `"${suiteName}" has been deleted.`);
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      this.flashMessages.showAndHideFlash('error', 'Something went wrong, please try again.');
    }
  });
}

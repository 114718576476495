import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type ConfigModel from 'garaje/models/config';
import type LocationModel from 'garaje/models/location';
import type GlobalSettingBatchService from 'garaje/services/global-setting-batch';
import type StateService from 'garaje/services/state';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';
import { hash } from 'rsvp';

import type VisitorsSettingsSignOutController from './controller';

export interface VisitorsSettingsSignOutRouteModel {
  config: ConfigModel;
  currentLocation: LocationModel;
  vrSubscription: StateService['vrSubscription'];
}

export default class VisitorsSettingsSignOutRoute extends Route {
  declare controller: VisitorsSettingsSignOutController;

  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service('global-setting-batch') declare globalSettingBatchService: GlobalSettingBatchService;
  @service declare transitionConfirm: TransitionConfirmService;

  titleToken = 'Self sign-out';

  model(): Promise<VisitorsSettingsSignOutRouteModel> {
    const { currentLocation, vrSubscription } = this.state;
    const config = currentLocation.config;

    if (this.abilities.can('propagate global-setting-batch')) {
      const globalSettingBatchService = this.globalSettingBatchService;
      globalSettingBatchService.createGlobalSettingBatch({ parent: currentLocation });
    }

    return hash({ config, currentLocation, vrSubscription });
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const controller = this.controller;
    const { model } = controller;
    const { currentLocation } = model;
    // no need to display confirmation modal when nothing is changed
    if (!currentLocation.hasDirtyAttributes) {
      return;
    }
    // display confirmation modal if settings are dirty
    void this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        if (<boolean>(<unknown>currentLocation.hasDirtyAttributes)) {
          currentLocation.rollbackAttributes();
        }
      },
    });
  }
}

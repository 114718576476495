import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class ProtectEmployeesIndexRoute extends Route {
  @service router;
  @service abilities;

  async beforeModel() {
    if (this.abilities.can('visit workplace')) {
      return this.router.transitionTo('workplace.settings.employees');
    }
    this.router.transitionTo('protect.settings.employees.registration');
  }
}

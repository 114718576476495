import Model, { attr, belongsTo } from '@ember-data/model';
import type LocationModel from 'garaje/models/location';
import type ScimPrimaryLocationConfigurationModel from 'garaje/models/scim-primary-location-configuration';

class ScimPrimaryLocationMappingModel extends Model {
  @attr('string') declare scimAttributeValue: string;

  @belongsTo('scim-primary-location-configuration')
  declare scimPrimaryLocationConfiguration: ScimPrimaryLocationConfigurationModel;
  @belongsTo('location', { async: false }) declare location: LocationModel | null;
}

export default ScimPrimaryLocationMappingModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'scim-primary-location-mapping': ScimPrimaryLocationMappingModel;
  }
}

import type { AsyncHasMany } from '@ember-data/model';
import Model, { attr, hasMany } from '@ember-data/model';
import type UserDocumentModel from 'garaje/models/user-document';
import type UserDocumentTemplateAttachmentModel from 'garaje/models/user-document-template-attachment';
import type UserDocumentTemplateConfigurationModel from 'garaje/models/user-document-template-configuration';

export const FEATURE_FLAG_MAP: Partial<Record<Identifier, string>> = {};
export const VACCINE_IDENTIFIER = 'covid-19-vaccine-card';
export const TEST_IDENTIFIER = 'covid-19-test-result';

export type Identifier = typeof VACCINE_IDENTIFIER | typeof TEST_IDENTIFIER;

const DOCUMENT_POSITIONS = Object.freeze({
  'covid-19-vaccine-card': 1,
  'covid-19-test-result': 2,
});

export interface TemplateInputField {
  type: string;
  required: boolean;
  default_value: string;
  identifier: string;
}

export default class UserDocumentTemplateModel extends Model {
  static validIdentifiers = [VACCINE_IDENTIFIER, TEST_IDENTIFIER];

  @hasMany('user-document-template-attachment')
  declare userDocumentTemplateAttachments: AsyncHasMany<UserDocumentTemplateAttachmentModel>;
  @hasMany('user-document-template-configuration')
  declare userDocumentTemplateConfigurations: AsyncHasMany<UserDocumentTemplateConfigurationModel>;
  @hasMany('user-document') declare userDocuments: AsyncHasMany<UserDocumentModel>;

  @attr('boolean') declare active: boolean;
  @attr('string') declare identifier: Identifier;
  @attr('string') declare title: string;
  @attr('string') declare description: string;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('immutable-array', { defaultValue: () => [] }) declare inputFields: TemplateInputField[];
  @attr('string') declare illustrationImageUrl: string;

  get featureFlag(): string | undefined {
    return FEATURE_FLAG_MAP[this.identifier];
  }

  get position(): number {
    return DOCUMENT_POSITIONS[this.identifier] ?? parseInt(this.id, 10);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-document-template': UserDocumentTemplateModel;
  }
}

import Component from '@glimmer/component';
import { isBlank } from '@ember/utils';
import { NAME_FOR_FIELD_TYPE } from '../component';

/**
 * @param {Function}                             onToggle  Function triggered when an item is selected
 * @param {object}                               option
 * @param {Array<Location|SkinnyLocation|Zone>}  selected  Current selected items
 * @param {boolean}                              subLevel
 * @param {string}                               type
 */

export default class AdminRolesMultiItemsListItemRowComponent extends Component {
  get name() {
    return this.args.option.item[NAME_FOR_FIELD_TYPE[this.args.type]];
  }

  /**
   * @returns {boolean}
   */
  get isChecked() {
    const { selected, option } = this.args;

    if (isBlank(selected)) {
      return false;
    }

    return Boolean(selected.find((item) => (item && option?.item ? item.id === option.item.id : false)));
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

/**
 * Multiple select nested in radio style `BasicDropdown` for recipeint filters of deliveries.
 * @param {Boolean}           loading                         Data loading in process
 * @param {Array<Employee>}   recipients                      List of employees to filter deliveries by
 * @param {Task}              searchEmployeesTask             Function to lookup a deliveries recipient
 * @param {Function}          setRecipientFilter              Action to set the selected recipient
 * @param {Object}            selectedRecipientTypeFilter     The filter type e.g. All, My deliveries, Custom
 * @param {Array<Object>}     recipientTypeFilterOptions      List of filter types, may omit My deliveries for non-employee admins
 * @param {Function}          setRecipientTypeFilter          Action to set the selected recipient type
 * @param {String}            currentEmployeeId               ID value of current admin user if user is employee
 */

const ALL_RECIPIENTS = 'All recipients';
const ALL = 'all';
const MY_DELIVERIES = 'My deliveries';
const CUSTOM_SEARCH = 'Search by recipient';
const CUSTOM = 'custom';

export default class DeliveriesRecipientFilterComponent extends Component {
  @tracked selectedOption = null;
  @tracked employees = [];
  @tracked _previousOption = null;
  customOption = { label: CUSTOM_SEARCH, value: CUSTOM };

  @action
  _onUpdate() {
    if (this.selectedOption?.value === CUSTOM) {
      if (this.args.recipients && this.args.recipients.length > 0) {
        this.employees = this.args.recipients.toArray();
      } else if (this.args.recipients && this.args.recipients.length === 0) {
        this.employees = [];
      }
      return;
    } else {
      this.selectedOption = this.args.selectedRecipientTypeFilter;
    }
    if (this.args.recipients && this.args.recipients.length > 0) {
      this.employees = this.args.recipients.toArray();
      if (this.args.currentEmployeeId && this.currentEmployee) {
        if (this.args.selectedRecipientTypeFilter.label !== MY_DELIVERIES) {
          this.selectedOption = this.args.recipientTypeFilterOptions.findBy('label', MY_DELIVERIES);
        }
      } else if (this.selectedOption?.value !== 'custom') {
        this.selectedOption = this.customOption;
      }
    } else if (this.args.recipients && this.args.recipients.length === 0) {
      this.employees = [];
    }
  }

  /**
   * @return {Boolean}
   */
  get isCustomSelected() {
    if (this.selectedOption) {
      return this.selectedOption?.value === CUSTOM;
    } else if (this.employees && this.employees.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * The text to show in the dropdown header when collapsed/expanded
   *
   * @return {String}
   */
  get selectedText() {
    let result = ALL_RECIPIENTS;
    const count = this.employees ? this.employees.length : 0;
    if (count > 1) {
      result = `${count} recipients`;
    } else if (count === 1) {
      if (this.args.currentEmployeeId && this.args.currentEmployeeId === this.selectedOption?.value) {
        result = MY_DELIVERIES;
      } else {
        result = this.employees.firstObject.name;
      }
    } else {
      if (this.args.currentEmployeeId && this.args.currentEmployeeId === this.selectedOption?.value) {
        result = MY_DELIVERIES;
      }
    }
    return result;
  }

  /**
   * Cache the current employee record, to use when switching radio options back and forth
   */
  get currentEmployee() {
    const employees = this.employees || [];
    return employees.findBy('id', this.args.currentEmployeeId);
  }

  /**
   * Handles the radio option selected action
   *
   * @param {Object{name:String,value:String}} option
   */
  @action
  onRadioSelected(dropdown, option) {
    if (this.selectedOption?.value !== option.value) {
      this._previousOption = this.selectedOption;
    }
    if ((this.selectedOption?.label === MY_DELIVERIES && option.value === CUSTOM) || option.value === ALL) {
      this.employees.clear();
    } else if (option.label === MY_DELIVERIES && this.employees.length === 0 && this.currentEmployee) {
      this.employees.pushObject(this.currentEmployee);
    }
    this.selectedOption = option;
    if (option.value !== CUSTOM) {
      this.args.setRecipientTypeFilter(option);
      dropdown.actions.close();
    }
    return false;
  }

  /**
   * On addition/removal of recipient as filters
   *
   * @param {Array<Employee>} recipients
   */
  @action
  onRecipientsChange(recipients) {
    this.args.setRecipientFilter(recipients);
    if (recipients.length === 0) {
      this.employees.clear();
    }
  }

  /**
   * Handles the close event of the basic dropdown component
   */
  @action
  onClose() {
    if (this.isCustomSelected && this.employees.length === 0) {
      this.selectedOption = this._previousOption ?? this.args.recipientTypeFilterOptions.firstObject;
      const employees = this.employees || [];
      if (this.selectedOption?.value === CUSTOM && employees.length === 0) {
        this.selectedOption = this.args.recipientTypeFilterOptions.firstObject;
      }
      if (this.selectedOption?.label === MY_DELIVERIES && this.currentEmployee) {
        this.employees = [this.currentEmployee];
      }
    }
  }
}

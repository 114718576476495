import { isBlank } from '@ember/utils';
import ApplicationSerializer from './application';

export default class SubscriptionSerializer extends ApplicationSerializer {
  attrs = {
    flowPayload: {
      serialize: false,
    },
    requireFinanceTeamIntervention: {
      serialize: false,
    },
    timesTrialExtended: {
      serialize: false,
    },
    desksCount: {
      serialize: false,
    },
  };

  normalize() {
    const normalized = super.normalize(...arguments);
    const { attributes } = normalized.data;

    if (!isBlank(attributes.couponPercentOff) || !isBlank(attributes.couponAmountOff)) {
      attributes.coupon = {
        percentOff: attributes.couponPercentOff,
        amountOff: attributes.couponAmountOff,
      };
    }

    return normalized;
  }
}

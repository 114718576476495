import { action } from '@ember/object';
import Component from '@glimmer/component';
import type { EmployeeItem, ResourceItem } from 'garaje/graphql/generated/map-features-types';

interface ExpandableSectionArgs {
  key: string;
  itemDetails: Array<EmployeeItem> | Array<ResourceItem>;
  topLevel: boolean;
  expandedMap: Array<string>;
  onLastNodeClick: (data: ResourceItem) => void;
  setExpandedMap: (key: string) => void;
  path: string;
}

export default class ExpandableSection extends Component<ExpandableSectionArgs> {
  get expandSection(): boolean {
    const { expandedMap } = this.args;
    return expandedMap.some((item) => {
      return item.includes(this.path);
    });
  }

  get sectionCount(): number {
    const { itemDetails } = this.args;

    if (Array.isArray(itemDetails)) {
      return itemDetails.length;
    }

    if (typeof itemDetails === 'object') {
      let count = 0;
      Object.values(itemDetails as object).forEach((item) => {
        if (Array.isArray(item)) {
          count += item.length;
        }
      });
      return count;
    }
    return 0;
  }

  @action
  onExpandedSectionClick(): void {
    const { setExpandedMap } = this.args;
    setExpandedMap(this.path);
  }

  get path(): string {
    const { path, key } = this.args;
    return path + '/' + key;
  }
}

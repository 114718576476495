import Controller from '@ember/controller';
import { action } from '@ember/object';
import type { DetailedChangeset } from 'ember-changeset/types';
import type GlobalIdScanPageModel from 'garaje/models/global-id-scan-page';
import updateAndSaveTask from 'garaje/utils/decorators/update-and-save-task';

@updateAndSaveTask
class LocationOverviewSignInFlowsShowSecurityController extends Controller {
  idScanChangeset!: DetailedChangeset<GlobalIdScanPageModel>;

  @action
  handleIdScanPageChanges(changeset: DetailedChangeset<GlobalIdScanPageModel>): void {
    this.idScanChangeset = changeset;
  }
}

export default LocationOverviewSignInFlowsShowSecurityController;

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type GlobalOverviewAccessService from 'garaje/services/global-overview-access';

export default class GlobalVisitorComponent extends Component {
  @service declare globalOverviewAccess: GlobalOverviewAccessService;

  get visitorsRoute(): string | null {
    return this.globalOverviewAccess.firstAccessibleRouteForGlobalVisitor();
  }
}

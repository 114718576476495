import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';

export default class TouchlessSignInComponent extends Component {
  @service impressions;
  @service metrics;

  @action
  async toggleSetting() {
    const { location, saveTask } = this.args;
    set(location, 'touchlessSigninEnabled', !location.touchlessSigninEnabled);
    await saveTask.perform(location);
    await this._trackSettingToggled(location.touchlessSigninEnabled);
  }

  async _trackSettingToggled(value) {
    const name = 'Pre-registration - Touchless Sign In Setting Toggled';
    this.metrics.trackEvent(name, { toggle_state_after_click: value });
    await this.impressions.postImpression.perform(
      IMPRESSION_NAMES.VR_INVITES_TOUCHLESS_SIGN_IN[location.touchlessSigninEnabled ? 'ENABLED' : 'DISABLED'],
    );
  }
}

import { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { apiAction } from '@mainmatter/ember-api-actions';
import AbstractSummaryPageModel from 'garaje/models/abstract/abstract-summary-page';
import type FlowModel from 'garaje/models/flow';
import adapter from 'garaje/utils/decorators/adapter';
import { type SingleResponse } from 'jsonapi/response';

@adapter('summary-page')
class GlobalSummaryPageModel extends AbstractSummaryPageModel {
  @belongsTo('global-flow') declare flow: AsyncBelongsTo<FlowModel>;
  @attr('string') declare message?: string | null;
  @attr('string') declare image?: string | null;

  async deleteSummaryPhoto(): Promise<{ data: Record<string, unknown> }> {
    const response = <SingleResponse<GlobalSummaryPageModel>>(
      await apiAction(this, { method: 'DELETE', path: 'attachments/image' })
    );
    if (response) {
      response.data.type = 'global-summary-pages';
    }

    return response;
  }
}

export default GlobalSummaryPageModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'global-summary-page': GlobalSummaryPageModel;
  }
}

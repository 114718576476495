import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { localCopy } from 'tracked-toolbox';

/**
 * @param {Object}              changeset
 * @param {Function}            update
 * @param {Object}              locality
 * @param {Function}            updateLocality
 * @param {Boolean}             disabled
 */
export default class LocalityField extends Component {
  @localCopy('args.disabled', false) disabled;

  @action
  didChangeName(name) {
    set(this.args.changeset, 'value', name);
    this.args.update(this.args.changeset);
  }

  @action
  didChangePlaceId(placeId) {
    this.args.updateLocality({ placeId });
  }
}

import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { htmlSafe } from '@ember/template';
import type { SafeString } from '@ember/template/-private/handlebars';
import Component from '@glimmer/component';

interface Font {
  value: string;
  label: string;
}

interface FontOption extends Font {
  style: SafeString;
}

export const DEFAULT_FONTS: Font[] = [
  {
    label: 'Arial',
    value: 'arial, helvetica, sans-serif',
  },
  {
    label: 'Cera Pro',
    value: "'Cera-Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  },
  {
    label: 'Courier New',
    value: "'courier new', courier, monospace",
  },
  {
    label: 'Georgia',
    value: 'georgia, serif',
  },
  {
    label: 'Helvetica',
    value: 'helvetica, arial, sans-serif',
  },
  {
    label: 'Lucida Sans',
    value: "'lucida sans', 'lucida grande', sans-serif",
  },
  {
    label: 'Tahoma',
    value: 'tahoma, geneva, sans-serif',
  },
  {
    label: 'Times New Roman',
    value: "'times new roman', times, serif",
  },
  {
    label: 'Trebuchet MS',
    value: "'trebuchet ms', helvetica, sans-serif",
  },
];

interface FontSelectComponentSignature {
  Args: {
    additionalFonts?: Font[];
    value: string;
    defaultFont?: string;
    onChange: (value: string) => void;
  };
}

export default class FontSelectComponent extends Component<FontSelectComponentSignature> {
  elementId = guidFor(this);

  get selected(): FontOption | undefined {
    return this.options.find((font) => font.value === this.args.value);
  }

  get options(): FontOption[] {
    const { additionalFonts } = this.args;
    return [...DEFAULT_FONTS, ...(additionalFonts ?? [])].map((font) => {
      return { ...font, style: htmlSafe(`font-family: ${font.value};`) };
    });
  }

  @action
  handleChange(option: FontOption): void {
    this.args.onChange?.(option.value);
  }
}

import Component from '@glimmer/component';
import { type Task } from 'ember-concurrency';
import config from 'garaje/config/environment';
import type EmployeeModel from 'garaje/models/employee';

interface EmployeeContactBoxSignature {
  Args: {
    changeset: EmployeeModel;
    employee: EmployeeModel;
    isEditModeEnabled: boolean;
    checkIfEmployeeExists?: Task<void, [string]>;
    employeeExists?: boolean;
    canAddToAnotherLocation?: boolean;
    addToLocation?: () => void;
    disabled?: boolean;
  };
}

export default class EmployeeContactBox extends Component<EmployeeContactBoxSignature> {
  get defaultCountry(): string {
    return config.environment === 'test' ? 'us' : 'auto';
  }
}

import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import type CopyFlowModel from 'garaje/models/copy-flow';
import type FlowModel from 'garaje/models/flow';
import type GlobalFlowModel from 'garaje/models/global-flow';
import type LocationModel from 'garaje/models/location';
import type StateService from 'garaje/services/state';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';
import type { RecordArray } from 'garaje/utils/type-utils';
import FlowValidations from 'garaje/validations/flow';
import { hash } from 'rsvp';

import type VisitorTypesNewController from './controller';

export interface VisitorTypesNewRouteModel {
  allFlows: RecordArray<CopyFlowModel>;
  changeset: DetailedChangeset<FlowModel>;
  currentLocation: LocationModel;
  newFlow: FlowModel;
  vrSubscription: StateService['vrSubscription'];
  globalFlows: ArrayProxy<GlobalFlowModel>;
}

export default class VisitorTypesNewRoute extends Route {
  declare controller: VisitorTypesNewController;

  @service declare state: StateService;
  @service declare store: StoreService;
  @service declare transitionConfirm: TransitionConfirmService;

  async model(): Promise<VisitorTypesNewRouteModel> {
    const { currentLocation, vrSubscription } = this.state;
    const allFlows = await this.store.query('copy-flow', {});
    const newFlow = this.store.createRecord('flow', { location: currentLocation });
    const changeset = this._buildChangeset(newFlow);
    const globalFlows = this.store.findAll('global-flow');

    // All flows is a collection of "Copy Flow" records which do not have a type. This filter serves no purpose!
    // allFlows = allFlows.filter(({ employeeCentric, type }) => !NON_ASSIGNABLE_FLOWS.includes(type) && !employeeCentric);

    return hash({
      allFlows,
      changeset,
      currentLocation,
      newFlow,
      vrSubscription,
      globalFlows,
    });
  }

  _buildChangeset(flow: FlowModel): DetailedChangeset<FlowModel> {
    const validator = lookupValidator(FlowValidations);
    return Changeset(flow, validator, FlowValidations);
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const { changeset, newFlow } = this.controller.model;

    if (changeset.isDirty) {
      void this.transitionConfirm.displayConfirmTask.perform(transition, {
        continue() {
          changeset.rollback();
        },
      });
    } else {
      if (<boolean>(<unknown>newFlow.isNew)) {
        void newFlow.destroyRecord();
      }
    }
  }
}

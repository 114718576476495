import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import config from 'garaje/config/environment';

/**
 * @param {Object}                              field
 * @param {Object}                              output
 * @param {Object}                              install
 * @param {Object}                              plugin
 * @param {Object}                              value
 * @param {Service<CurrentLocation>}            currentLocation
 * @param {Function}                            updateValue
 */
export default class Select extends Component {
  @service ajax;
  @service flashMessages;

  @tracked loading = true;
  @tracked selected = null;
  @tracked error;

  cursor = null;

  get placeholder() {
    return this.loading ? 'Loading...' : 'Select one';
  }

  get optionsUrl() {
    let lookupUrl = this.args.field.options || this.args.field.endpoint;
    if (!lookupUrl || Array.isArray(lookupUrl)) {
      return '';
    }
    if (lookupUrl.charAt(0) === '/') {
      lookupUrl = lookupUrl.substr(1);
    }
    return `${config.apiHost}/platform/${get(this.args.plugin, 'key')}/${lookupUrl}`;
  }

  @dropTask
  *fetchOptions(page = 1, search = '') {
    if (page === 1) {
      this.cursor = null;
    }
    if (Array.isArray(this.args.field.options)) {
      const searchNormalized = search.toLowerCase().trim();
      return this.args.field.options.filter(({ label }) => label.toLowerCase().includes(searchNormalized));
    }
    try {
      const result = yield this.ajax.request(this.optionsUrl, {
        data: {
          location_id: get(this.args, 'currentLocation.id'),
          page,
          search,
          cursor: this.cursor || undefined,
        },
      });
      if (Array.isArray(result)) {
        return result;
      }
      if (Array.isArray(result.data) && result.cursor) {
        this.cursor = result.cursor;
        return result.data;
      }
      this.error = 'Incorrect response format.';
      this.flashMessages.showFlash('error', this.error);
    } catch (e) {
      let msg;
      try {
        msg = e.payload[0].detail.split('\n')[0];
      } catch (e) {
        // continue regardless of error
      }
      this.error = msg ?? 'Error loading options.';
      this.flashMessages.showFlash('error', `Error loading select options. ${msg}`);
    }
  }

  @dropTask
  *fetchMissingSelection() {
    if (!this.value || !this.args.field.selected) {
      return;
    }
    if (Array.isArray(this.args.field.options)) {
      return this.args.field.options.find(({ value }) => value == this.value);
    }
    try {
      const selectedUrl = `${config.apiHost}/platform/${get(this.args.plugin, 'key')}/${this.args.field.selected}`;
      return yield this.ajax.request(selectedUrl, {
        data: {
          location_id: get(this.args, 'currentLocation.id'),
          value: this.value,
        },
      });
    } catch (e) {
      let msg;
      try {
        msg = e.payload[0].detail.split('\n')[0];
      } catch (e) {
        // continue regardless of error
      }
      this.error = msg ?? 'Error loading select value.';
      this.flashMessages.showFlash('error', `Error loading select value. ${msg}`);
    }
  }

  @dropTask
  *setInitialSelection() {
    const selected = yield this.fetchMissingSelection.perform();
    this.loading = false;
    this.onChange(selected);
  }

  @action
  onChange(selected) {
    this.selected = selected;
    this.args.updateValue(selected ? selected.value : null);
  }

  @action
  onLoaded(options) {
    const selected = options.find(({ value }) => value == this.args.value);
    if (selected) {
      this.loading = false;
      return this.onChange(selected);
    }
    this.setInitialSelection.perform();
  }

  optionsAreEqual(a, b) {
    return (a && a.value) == (b && b.value);
  }
}

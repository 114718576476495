import ReservationFragment from 'garaje/graphql/fragments/employee-schedule/ReservationFragment';
import gql from 'graphql-tag';

export default gql`
  mutation CheckInReservation($reservationID: ID!) {
    checkInReservation(reservationId: $reservationID) {
      ...ReservationFragment
    }
  }
  ${ReservationFragment}
`;

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default class EmployeesDirectoryDocumentReviewRoute extends Route {
  @service abilities;
  @service router;
  @service state;
  @service store;

  beforeModel() {
    return this.loadActiveDocumentTemplateConfigurations();
  }

  redirect() {
    if (!this.abilities.can('review user-document-links')) {
      this.router.transitionTo('unauthorized');
    } else if (isEmpty(this.activeUserDocumentTemplateConfigurations)) {
      this.router.transitionTo('employees');
    }
  }

  async loadActiveDocumentTemplateConfigurations() {
    this.activeUserDocumentTemplateConfigurations = await this.store.query('user-document-template-configuration', {
      filter: {
        active: true,
        'location-id': this.state.currentLocation.id,
      },
    });
  }
}

import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type FlashMessagesService from 'garaje/services/flash-messages';

export default class PropertyVisitorsBlocklistImportController extends Controller {
  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;

  @action
  uploadCompleted(): void {
    this.flashMessages.showAndHideFlash('success', 'Block list imported!');
    void this.router.transitionTo('property.visitors.blocklist.index');
  }
}

import { datadogRum } from '@datadog/browser-rum';
import Service, { service } from '@ember/service';
import config from 'garaje/config/environment';
import type SessionService from 'garaje/services/session';

export default class DataDogService extends Service {
  @service declare session: SessionService;

  measure(): void {
    if (['test', 'development'].includes(config.environment) || !config.dataDogRumCompaniesToTrack) {
      return;
    }

    const companyId = this.session['companyId'] as string | null;
    const IDS_TO_TRACK = config.dataDogRumCompaniesToTrack.split(',');

    if (!companyId || !IDS_TO_TRACK.includes(companyId)) {
      return;
    }

    datadogRum.init({
      applicationId: config.dataDogApplicationId,
      clientToken: config.dataDogClientToken,
      site: 'datadoghq.com',
      service: 'garaje',
      env: config.environment,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.setGlobalContextProperty('company', {
      id: companyId,
    });
  }
}

import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';

class NewLocationRoute extends Route {
  @service abilities;
  @service state;
  @service locations;
  @service router;
  @service store;

  title = 'Add New Location · Envoy';

  beforeModel() {
    return this.beforeModelTask.perform();
  }

  model() {
    let newLocation = this._newLocation();
    const { currentCompany, deliveriesSubscription, vrSubscription, workplaceSubscription } = this.state;
    let groups = [];

    if (this.abilities.can('view locations-grouping')) {
      groups = this.store.findAll('group');
    }

    if (!newLocation) {
      newLocation = this.store.createRecord('location', {
        company: currentCompany,
        employeeScheduleEnabled: true,
      });
    }

    return hash({
      deliveriesSubscription,
      groups,
      newLocation,
      vrSubscription,
      workplaceSubscription,
    });
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.setupChangeset();
  }

  resetController(controller) {
    super.resetController(...arguments);
    controller.fromPropertyConnection = false;
  }

  // find an existing new location from a previous attempt
  _newLocation() {
    if (get(this, 'locations.content').findBy) {
      return get(this, 'locations.content').findBy('isNew');
    }
  }

  @dropTask
  *beforeModelTask() {
    yield this.locations.loadAllTask.perform();

    if (this.abilities.cannot('create locations')) {
      this.router.transitionTo('unauthorized');
    }
  }
}

export default NewLocationRoute;

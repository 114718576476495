import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';

export default class PreRegistrationRoute extends Route {
  @service declare router: RouterService;

  // @ts-ignore
  beforeModel(): Transition<unknown> {
    // Route has been renamed to /visitors/settings/invites
    // keeping this redirect to support KB articles
    return this.router.replaceWith('visitors.settings.invites');
  }
}

import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { Permission } from 'garaje/utils/ui-permissions';

export default class GlobalCommunicationAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare authz: AuthzService;

  // TODO: Incorporate FC instead here
  get canVisit(): boolean {
    return (
      this.canSeeFeatures &&
      this.canReadGlobalCommunications &&
      this.featureFlags.isEnabled('emergency-notifications-templates-garaje')
    );
  }

  get canSeeFeatures(): boolean {
    if (!this.currentAdmin.isGlobalAdmin) {
      return false;
    }

    return !!this.state.features?.canAccessEmergencyNotifications;
  }

  get canEditFeatures(): boolean {
    return this.canSeeFeatures;
  }

  get canReadGlobalCommunications(): boolean {
    const permissions = [
      Permission.COMMUNICATIONS_ANNOUNCEMENT_READ,
      Permission.COMMUNICATIONS_ANNOUNCEMENT_TEMPLATE_READ,
      Permission.COMMUNICATIONS_SETTING_READ,
    ];

    return this.authz.hasAnyPermissionAtCurrentCompany(permissions);
  }

  get canManageAnnouncements(): boolean {
    const permissions = [
      Permission.COMMUNICATIONS_ANNOUNCEMENT_CREATE,
      Permission.COMMUNICATIONS_ANNOUNCEMENT_READ,
      Permission.COMMUNICATIONS_ANNOUNCEMENT_UPDATE,
      Permission.COMMUNICATIONS_ANNOUNCEMENT_DELETE,
    ];

    return this.authz.hasAllPermissionsAtCurrentCompany(permissions);
  }

  get canManageAnnouncementTemplates(): boolean {
    const permissions = [
      Permission.COMMUNICATIONS_ANNOUNCEMENT_TEMPLATE_CREATE,
      Permission.COMMUNICATIONS_ANNOUNCEMENT_TEMPLATE_READ,
      Permission.COMMUNICATIONS_ANNOUNCEMENT_TEMPLATE_UPDATE,
      Permission.COMMUNICATIONS_ANNOUNCEMENT_TEMPLATE_DELETE,
    ];

    return this.authz.hasAllPermissionsAtCurrentCompany(permissions);
  }

  get canManageSettings(): boolean {
    const permissions = [
      Permission.COMMUNICATIONS_SETTING_CREATE,
      Permission.COMMUNICATIONS_SETTING_READ,
      Permission.COMMUNICATIONS_SETTING_UPDATE,
      Permission.COMMUNICATIONS_SETTING_DELETE,
    ];

    return this.authz.hasAllPermissionsAtCurrentCompany(permissions);
  }
}

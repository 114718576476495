import Model, { attr, belongsTo } from '@ember-data/model';
import type TenantModel from 'garaje/models/tenant';

export default abstract class AbstractVisitorContactModel extends Model {
  @attr('string') declare name: string;
  @attr('string') declare email: string;
  @attr('string') declare phoneNumber: string;

  @belongsTo('tenant', { async: false }) declare tenant: TenantModel;
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import type Model from '@ember-data/model';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type AjaxService from 'garaje/services/ajax';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MessageBusService from 'garaje/services/message-bus';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';

interface ConnectConfigConnectPropertyComponentArgs {
  /**
   * closure to close modal
   */
  onClose?: () => unknown;
  /**
   * closure when property is successfully connected
   */
  onConnectionSuccess?: () => unknown;
  /**
   * user's current location
   */
  currentLocation: Model;
}

export default class ConnectConfigConnectPropertyComponent extends Component<ConnectConfigConnectPropertyComponentArgs> {
  @service declare ajax: AjaxService;
  @service declare flashMessages: FlashMessagesService;
  @service declare messageBus: MessageBusService;

  @tracked token?: string;
  @tracked authUrl: string | null = null;

  get disabled(): boolean {
    return isEmpty(this.token?.trim());
  }

  constructor(owner: unknown, args: ConnectConfigConnectPropertyComponentArgs) {
    super(owner, args);
    this.messageBus.on('embedded-app-message', ({ status }) => {
      if (status === 'oauth.connected') {
        this.args.onConnectionSuccess?.();
        this.onClose();
      } else if (status === 'oauth.rejected') {
        this.authUrl = null;
      }
    });
  }

  connectTask = task(async (token: string) => {
    if (this.disabled) return;
    try {
      const response = await this.ajax.post<{ url: string }>(urlBuilder.multiTenancy.claimToken(token), {
        data: {
          location: this.args.currentLocation.id,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      this.authUrl = response.url;
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  @action
  onClose(): void {
    this.args.onClose?.();
  }
}

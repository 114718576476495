import { Changeset } from 'ember-changeset';
import { type DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import { validateFormat } from 'ember-changeset-validations/validators';
import type ConnectionRequestInviteModel from 'garaje/models/connection-request-invite';

const Validations = {
  email: validateFormat({
    type: 'email',
    allowBlank: false,
    message: 'Please use a valid email address',
  }),
};

type ConnectionRequestInviteChangeset = DetailedChangeset<ConnectionRequestInviteModel>;

export default function (requestInvite: ConnectionRequestInviteModel): ConnectionRequestInviteChangeset {
  return <ConnectionRequestInviteChangeset>Changeset(requestInvite, lookupValidator(Validations), Validations);
}

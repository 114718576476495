import type NativeArray from '@ember/array/-private/native-array';
import { service } from '@ember/service';
import type { AsyncHasMany } from '@ember-data/model';
import { attr, hasMany } from '@ember-data/model';
import AbstractDocumentModel from 'garaje/models/abstract/abstract-document';
import type EntryModel from 'garaje/models/entry';
import type InviteModel from 'garaje/models/invite';
import type LocationModel from 'garaje/models/location';
import type RecurringInviteModel from 'garaje/models/recurring-invite';
import type UserDocumentAttachmentModel from 'garaje/models/user-document-attachment';
import type FeatureFlagsService from 'garaje/services/feature-flags';

export default class VisitorDocumentModel extends AbstractDocumentModel {
  @service declare featureFlags: FeatureFlagsService;

  // Relationships
  @hasMany('entry') declare entries: AsyncHasMany<EntryModel>;
  @hasMany('invite') declare invites: AsyncHasMany<InviteModel>;
  @hasMany('location') declare locations: AsyncHasMany<LocationModel> | NativeArray<LocationModel>;
  @hasMany('recurring-invite') declare recurringInvites: AsyncHasMany<RecurringInviteModel>;

  // Attributes
  @attr('boolean') declare skipped: boolean;
  @attr('immutable', { defaultValue: () => [] }) declare externalAttachments: unknown[];

  get userDocumentAttachmentsPendingUpload(): UserDocumentAttachmentModel[] {
    return this.userDocumentAttachments.filter(
      (userDocumentAttachment) =>
        <boolean>(<unknown>userDocumentAttachment.isValid) && userDocumentAttachment.file instanceof File,
    );
  }

  get hasAttachedFile(): boolean {
    return super.hasAttachedFile || this.externalAttachments.length > 0;
  }

  get isCapableOfAttachments(): boolean {
    const hasExternalAttachments = this.externalAttachments.length > 0;

    return (
      super.isCapableOfAttachments ||
      (this.featureFlags.isEnabled('visitors-id-scan-images') && (hasExternalAttachments || this.hasInputFieldData))
    );
  }

  /**
   * Delete the many-to-many association between this document record and invites
   *
   * @return HTTP DELETE request, expect 204
   */
  async removeFromInvites(invites: InviteModel[] = []): Promise<void> {
    const adapter = this.store.adapterFor('visitor-document');
    const url = adapter.buildURL('visitor-document', this.id) + '/relationships/invites';

    const data = {
      data: invites.map(({ id }) => {
        return { type: 'invites', id };
      }),
    };

    await adapter.ajax(url, 'DELETE', { data });
  }

  /**
   * Delete the many-to-many association between this document record and entries
   *
   * @return HTTP DELETE request, expect 204
   */
  async removeFromEntries(entries: EntryModel[] = []): Promise<void> {
    const adapter = this.store.adapterFor('visitor-document');
    const url = adapter.buildURL('visitor-document', this.id) + '/relationships/entries';
    const data = {
      data: entries.map(({ id }) => {
        return { type: 'entries', id };
      }),
    };

    await adapter.ajax(url, 'DELETE', { data });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'visitor-document': VisitorDocumentModel;
  }
}

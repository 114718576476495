import { isEmpty } from '@ember/utils';
import Changeset from 'ember-changeset';
import { regularExpressions } from 'ember-validators/format';

const buildChangeset = (model) => {
  return new Changeset(model, _validate);
};
class FakeChangeset {
  constructor(changesets) {
    this.changesets = changesets ?? [];
  }

  get isDirty() {
    const changesets = this.changesets;
    return changesets.isAny('isDirty') || changesets.isAny('isInvalid');
  }

  get isPristine() {
    const changesets = this.changesets;
    return changesets.isEvery('isPristine');
  }

  rollback() {
    this.changesets.invoke('rollback');
  }
}

const _validate = ({ key, newValue }) => {
  let isValid = true;

  if (key === 'bccEmail') {
    if (isEmpty(newValue) || newValue.indexOf('@') === -1) {
      isValid = false;
    }
  } else if (key === 'duration' && parseInt(newValue) < 0) {
    isValid = false;
  } else if (key === 'videoUrl' && !isEmpty(newValue)) {
    isValid = newValue.match(regularExpressions.url) !== null && newValue.match(/^https?:\/\//) !== null;
  }

  return isValid;
};

export { buildChangeset, FakeChangeset };

import moment from 'moment-timezone';
const DATE_FORMAT = 'YYYY-MM-DD';

/**
 * Generates parameters used for querying locations-capacity models
 *
 * @param  {string} locationId Location ID
 * @param  {Date|string} date1 Query start date
 * @param  {Date|string} date2 Query end date
 * @returns {Array<string|object>} Array of params
 */
export function getParamsForQueryLocationsCapacity(locationId, date1, date2) {
  const startDate = moment(date1 || undefined).format(DATE_FORMAT);
  const endDate = moment(date2 || date1 || undefined).format(DATE_FORMAT);
  const params = {
    filter: {
      location: locationId,
    },
  };

  if (startDate === endDate) {
    params.filter['capacity-date'] = startDate;
  } else {
    params.filter['start-date'] = startDate;
    params.filter['end-date'] = endDate;
  }

  return ['locations-capacity', params];
}

/**
 * Fetches a single locations-capacity model by date
 *
 * @param  {object} store StoreService
 * @param  {any} location LocationModel
 * @param  {Date|string} date Query date
 * @returns {Promise.<object>} Promise for LocationsCapacityModel
 */
export function fetchCapacity(store, location, date) {
  return store
    .query(...getParamsForQueryLocationsCapacity(location.id, date))
    .then((capacities) => capacities.firstObject);
}

/**
 * Fetches locations-capacity models by date range
 *
 * @param  {object} store StoreService
 * @param  {object} location LocationModel
 * @param  {Date|string} startDate  Query start date
 * @param  {Date|string} endDate Query end date
 * @returns {Promise.<object[]>} Promise for array of LocationsCapacityModel
 */
export function fetchCapacities(store, location, startDate, endDate) {
  return store.query(...getParamsForQueryLocationsCapacity(location.id, startDate, endDate));
}

/**
 * Generates an ember-changeset validator function for validating the capacity limit for a given day
 *
 * @param  {object} store StoreService
 * @param  {object} location LocationModel
 * @returns {(key: string, value: unknown) => Promise<boolean>} ember-changeset validator function
 */
export function createCapacityValidator(store, location) {
  if (!location.capacityLimitEnabled) {
    return () => true;
  }
  return function validateCapacity(key, newValue, oldValue, changes, _content) {
    // Do not run validation on a value that did not change
    if (!changes[key]) return true;

    return fetchCapacity(store, location, newValue).then((locationsCapacity) => {
      if (locationsCapacity) {
        return locationsCapacity.hasCapacity || 'Location at capacity. Please select another date.';
      }
      return true;
    });
  };
}

import Component from '@glimmer/component';
import { A } from '@ember/array';

export default class UserDocumentStepsStepComponent extends Component {
  get isActive() {
    const { currentRouteName, step } = this.args;

    return step.route === currentRouteName;
  }

  get isDone() {
    const { currentStep, step } = this.args;

    return step.position < currentStep;
  }

  get currentSubstep() {
    if (!this.isActive) return -1;

    const currentPosition = this.args.currentPosition ?? -1;
    const substeps = this.args.step?.substeps ?? A();
    const substep = substeps.findBy('position', currentPosition);

    return substep?.position ?? -1;
  }

  get sortedSubsteps() {
    const substeps = this.args.step?.substeps ?? A();

    return substeps.sortBy('position');
  }
}

import ApplicationSerializer from './application';

export default class FloorSerializer extends ApplicationSerializer {
  attrs = {
    floorPlanUrl: {
      serialize: false,
    },
    totalAssignableDesks: {
      serialize: false,
    },
  };

  normalize(floorModel, hash) {
    const normalized = super.normalize(...arguments);
    normalized.data.attributes.totalAssignableDesks = hash.meta?.active_desks || 0;

    return normalized;
  }
}

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import {
  VaccineAlertModalDefinition,
  VaccineCTAModalDefinition,
  TestCTAModalDefinition,
  ExpiringAlertModalDefinition,
} from 'garaje/utils/document-modal-definitions';
import { TEST_IDENTIFIER, VACCINE_IDENTIFIER } from 'garaje/models/user-document-template';
import { ConnectionRequestConfigModalDefinition } from 'garaje/utils/connect-modal-definitions';
import { assert } from '@ember/debug';

export default class LoginModalComponent extends Component {
  @service('user-document') userDocumentService;
  @service router;
  @service localStorage;
  @service featureFlags;
  @service state;
  @service skinnyLocations;
  @service session;

  get tenantConnectionRequestsLoaded() {
    const connectionRequestsLoaded =
      !this.state.loadTenantConnectionRequests.isRunning &&
      this.state.tenantConnectionRequests &&
      !this.skinnyLocations.loadAllTask.isRunning;
    const tenantConnectionsLoaded = !this.state.loadTenantConnections.isRunning && this.state.tenantConnections;

    return connectionRequestsLoaded && tenantConnectionsLoaded;
  }

  get userDocumentsLoaded() {
    return (
      (!this.userDocumentService.initialize.isRunning && this.userDocumentService.configurations) ||
      // documents do not get loaded when viewing a property
      !this.state.currentLocation
    );
  }

  get renderModal() {
    // we need to wait for all async requests to finish before we can determine what modal has the highest priority
    return this.userDocumentsLoaded && this.tenantConnectionRequestsLoaded && this.modalDefinition;
  }

  get latestVaccineDocuments() {
    return this.userDocumentService.getLatestDocumentsByIdentifier(VACCINE_IDENTIFIER).toArray();
  }

  get latestTestDocuments() {
    return this.userDocumentService.getLatestDocumentsByIdentifier(TEST_IDENTIFIER).toArray();
  }

  get modalDefinition() {
    if (!this.session.isAuthenticated) return null;

    const modalClasses = [
      VaccineCTAModalDefinition,
      VaccineAlertModalDefinition,
      TestCTAModalDefinition,
      ExpiringAlertModalDefinition,
      ConnectionRequestConfigModalDefinition,
    ].sortBy('priority');

    const documentOptions = {
      usersDocuments: [...this.latestVaccineDocuments, ...this.latestTestDocuments],
      userDocumentTemplateConfigurations: this.userDocumentService.activeUserDocumentTemplateConfigurations,
    };

    const connectOptions = {
      tenantConnectionRequest: this.state.tenantConnectionRequests?.firstObject,
    };

    const classOptions = {
      routeName: this.router.currentRouteName,
      ...documentOptions,
      ...connectOptions,
    };

    for (let i = 0; i < modalClasses.length; i++) {
      const ModalClass = modalClasses[i];

      const isModalDismissed = this.isModalDismissed(ModalClass.identifier);
      const featureEnabled = ModalClass.feature ? this.featureFlags.isEnabled(ModalClass.feature) : true;

      const modal = new ModalClass({
        isModalDismissed,
        featureEnabled,
        ...classOptions,
      });

      if (modal.active) {
        return modal;
      }
    }

    return null;
  }

  /**
   * Updates local storage to dismiss modals
   *
   * @param {string | string []} identifiers - identifier or array of identifiers to dismiss
   */
  @action
  modalDismissed(identifiers) {
    identifiers = Array.isArray(identifiers) ? identifiers : [identifiers];
    identifiers.forEach((identifier) => {
      assert('LoginModalComponent.modalDismissed(): identifier must be a string', typeof identifier === 'string');
      this.localStorage.setItem(`dismissed-${identifier}`, Date.now());
    });
  }

  /**
   * Checks if a modal has been dismissed
   *
   * @param {string} identifier - the modal identifier
   * @returns {boolean} whether the modal has been dismissed
   */
  isModalDismissed(identifier) {
    return !!this.localStorage.getItem(`dismissed-${identifier}`);
  }
}

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';

/**
 * @param {object} templateConfiguration - user-document-template-configuration instance linked to location or flow
 * @param {boolean} isDisabled - disables inputs
 */
export class FlowsVaccineTrackingBase extends Component {
  @service flashMessages;

  @tracked confirmationModalToShow;

  get templateConfiguration() {
    return this.args.templateConfiguration;
  }

  get isInputDisabled() {
    return this.saveTask.isRunning || this.args.isDisabled;
  }

  @action
  toggleStatus() {
    if (this.templateConfiguration.active) {
      this.confirmationModalToShow = 'disable';
    } else {
      this.confirmationModalToShow = 'enable';
    }
  }

  @action
  cancel() {
    if (this.templateConfiguration.hasDirtyAttributes) {
      this.templateConfigurationtemplateConfiguration.rollbackAttributes();
    }

    this.confirmationModalToShow = null;
  }

  @action
  performToggle() {
    switch (this.confirmationModalToShow) {
      case 'enable':
        this.saveTask.perform(true);
        break;
      case 'disable':
        this.saveTask.perform(false);
        break;
    }
  }

  @dropTask
  *saveTask(state) {
    const templateConfiguration = this.templateConfiguration;

    templateConfiguration.active = state;
    try {
      yield templateConfiguration.save();
      this.args.flow?.syncActiveUserDocumentTemplateConfigurations?.();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch {
      templateConfiguration.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', 'Error saving changes.');
    } finally {
      this.confirmationModalToShow = null;
    }
  }
}

import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import { GLOBAL_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_REVIEW_ROLES = [GLOBAL_ADMIN];

export default class UserDocumentLinkAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;

  get canReview(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_REVIEW_ROLES, roleNames));
  }
}

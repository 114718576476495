import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { action, set } from '@ember/object';
import { underscore } from '@ember/string';
import { isPresent, isEmpty } from '@ember/utils';
import eventsLogger from 'garaje/utils/decorators/events-logger';
import { scheduleOnce } from '@ember/runloop';
import { cached } from 'tracked-toolbox';

/**
 * @param {Model<MapFeature>}           feature
 * @param {Function}                    removeFeature
 * @param {Function}                    back
 * @param {Object}                      availableResources
 * @param {boolean}                     isDraftMap
 */
@eventsLogger
class RoomForm extends Component {
  @service store;

  @tracked validations = {};

  get selectedRoom() {
    const { feature } = this.args;
    return feature.externalId && this.store.peekRecord('room', feature.externalId);
  }

  @cached
  get selectedRoomInsight() {
    const { additionalDetails } = this.args;
    const insight = additionalDetails.resourceInsights.find((insight) => {
      return insight.id === this.selectedRoom.id && insight.type === 'ROOM';
    });
    const insightDisplay = insight?.utilization ?? 0;
    return `${insightDisplay}% utilization` || null;
  }

  get availableRooms() {
    const { availableResources } = this.args;

    return availableResources.rooms;
  }

  @action
  onInsert() {
    scheduleOnce('afterRender', this, 'setDefaultValues');
  }

  @action
  onUpdate() {
    scheduleOnce('afterRender', this, 'setDefaultValues');
  }

  setDefaultValues() {
    const { feature } = this.args;

    if (feature.isNew && isPresent(this.availableRooms) && isEmpty(feature.externalId)) {
      this.validate();
    }
  }

  @action
  toggleEnabled(state) {
    const { feature, isDraftMap, updateResourceOverview } = this.args;
    set(feature, 'enabled', state);
    if (updateResourceOverview) {
      updateResourceOverview('update', feature);
    }
    if (state) {
      this.logEnabled({
        resource_type: underscore(feature.type),
        isDraft: isDraftMap,
      });
    } else {
      this.logDisabled({
        resource_type: underscore(feature.type),
        isDraft: isDraftMap,
      });
    }
  }

  @action
  onRoomSelect(room) {
    const { feature, updateResourceOverview } = this.args;
    set(feature, 'name', room.displayName);
    set(feature, 'externalId', room.id);

    if (updateResourceOverview) {
      updateResourceOverview('update', feature);
    }

    this.validate();
  }

  @action
  validate() {
    const { feature } = this.args;

    if (!feature.externalId) {
      set(feature, 'hasError', true);
      set(this.validations, 'room', 'Please select a room');
    } else {
      set(this.validations, 'room', null);
      set(feature, 'hasError', false);
    }
  }
}

export default RoomForm;

import gql from 'graphql-tag';

export default gql`
  query GetAssignments($assignmentsInput: AssignmentFilterInput!) {
    assignments(input: $assignmentsInput) {
      id
      deskId
      startTime
      employee {
        id
      }
      scheduledBy {
        id
      }
    }
  }
`;

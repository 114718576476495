import Component from '@glimmer/component';

/**
 * @param {Array<Device>}             ipads
 * @param {String}                    sortDirection
 * @param {Function}                  setSortDirection
 * @param {Function}                  setSortBy
 * @param {String}                    sortBy
 * @param {Array<Device>}             selectedIPads
 * @param {Function}                  unselectIPad
 * @param {Function}                  clearSelectedIPads
 * @param {Function}                  selectIPad
 * @param {Function}                  selectAllIPads
 */
export default class GlobalDevicesIpadsIpadTable extends Component {
  get isSelectAllIndeterminate() {
    const selectedCount = this.args.selectedIPads.length;
    return selectedCount > 0 && !this.isSelectAll;
  }

  get isSelectAll() {
    const selectedCount = this.args.selectedIPads.length;
    const iPadCount = this.args.ipads.length;
    return iPadCount === selectedCount;
  }
}

import Component from '@glimmer/component';

const ICON_MAPPING = {
  not_applicable: '/assets/images/gray-dash-small.svg',
  not_signed: '/assets/images/cross-small-red.svg',
  pending: '/assets/images/gray-dash-small.svg',
  signed: '/assets/images/checkmark-small-gray.svg',
  skipped: '/assets/images/gray-dash-small.svg',
};

const LEGAL_DOCUMENTS_STATUS_MAPPING = {
  not_applicable: 'N/A',
  not_signed: 'Not signed',
  pending: 'Pending',
  signed: 'Signed',
  skipped: 'Skipped',
};

/**
 * @param {String}            status
 */
export default class LegalDocumentsStatus extends Component {
  get displayStatus() {
    return LEGAL_DOCUMENTS_STATUS_MAPPING[this.args.status];
  }

  get icon() {
    return ICON_MAPPING[this.args.status];
  }
}

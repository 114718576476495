import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { Task } from 'ember-concurrency';
import type LocationModel from 'garaje/models/location';
import { formatMinutesAsTime, getMinutesInDayFromTime, TIMES } from 'garaje/utils/format-minutes-as-time';

interface AutoSignOutTimeComponentSignature {
  Args: {
    location: LocationModel;
    saveTask: Task<void, []>;
    updateAutoSignOutAtMinutesSinceMidnight: (mins: number | null) => void;
  };
}

export default class AutoSignOutTimeComponent extends Component<AutoSignOutTimeComponentSignature> {
  defaultTime = '12:00 am';
  @tracked isOpen = false;
  @tracked showUnsavedEditsWarning = false;
  times = TIMES;

  get isEnabled(): boolean {
    const autoSignOutAtMinutesSinceMidnight = <[string, string]>(
      this.args.location.changedAttributes()['autoSignOutAtMinutesSinceMidnight']
    );
    if (autoSignOutAtMinutesSinceMidnight && autoSignOutAtMinutesSinceMidnight.length) {
      const [original] = autoSignOutAtMinutesSinceMidnight;
      return original !== null;
    } else {
      return this.args.location.autoSignOutAtMinutesSinceMidnight !== null;
    }
  }

  get time(): string {
    const minutesInDay = this.args.location.autoSignOutAtMinutesSinceMidnight;
    return formatMinutesAsTime(minutesInDay || 0);
  }

  @action
  onContinue(): void {
    if (<boolean>(<unknown>this.args.location.hasDirtyAttributes)) {
      this.args.location.rollbackAttributes();
    }
    this.showUnsavedEditsWarning = false;
    this.isOpen = false;
  }

  @action
  cancel(): void {
    const autoSignOutAtMinutesSinceMidnight = <[string, string]>(
      this.args.location.changedAttributes()['autoSignOutAtMinutesSinceMidnight']
    );
    if (!autoSignOutAtMinutesSinceMidnight || !autoSignOutAtMinutesSinceMidnight.length) {
      this.onContinue();
    }
    const [original] = autoSignOutAtMinutesSinceMidnight;
    if (original === null) {
      // Not enabled yet
      this.onContinue();
    } else {
      this.showUnsavedEditsWarning = true;
    }
  }

  @action
  edit(): void {
    this.isOpen = true;
  }

  @action
  enable(): void {
    this.setTime(this.defaultTime);
    this.isOpen = true;
  }

  @action
  async disable(): Promise<void> {
    this.args.updateAutoSignOutAtMinutesSinceMidnight(null);
    await this.args.saveTask.perform();
    this.isOpen = false;
  }

  @action
  async save(): Promise<void> {
    await this.args.saveTask.perform();
    this.isOpen = false;
  }

  @action
  setTime(time: string): void {
    const minutesInDay = getMinutesInDayFromTime(time);
    this.args.updateAutoSignOutAtMinutesSinceMidnight(minutesInDay);
  }
}

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { addDays } from 'date-fns';
import { dropTask } from 'ember-concurrency';
import _isEmpty from 'lodash/isEmpty';
import _omitBy from 'lodash/omitBy';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';

import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

const DISMISSAL_LOCAL_STORAGE_KEY_BASE = 'growth-trial-end-experience-dismissed';

// we need to be able to differentiate between dismissing the modal before
// trial expiration, and dismissing it after. These are strings and not integers
// because that's how they get retrieved from localStorage.
const DISMISSED_BEFORE_EXPIRATION = '1';
const DISMISSED_AFTER_EXPIRATION = '2';

// the number of days a trial should be extended
const TRIAL_EXTENSION_DAYS = 14;

const NUM_STEPS = 3;

export default class VisitorsTrialEndModal extends Component {
  @service abilities;
  @service flashMessages;
  @service featureUsage;
  @service localStorage;
  @service metrics;
  @service state;
  @service store;
  @service statsig;
  @service impressions;
  @service router;

  @tracked closed = false;
  @tracked step = 1;
  @tracked trialConvertedToBasic = false;
  @tracked isFromTrialEndPage = this.args.pageSource === 'trial-end-page';

  constructor() {
    super(...arguments);

    if (this.isFromTrialEndPage) {
      this.step = 2;
    }
    this._loadVisitorsPlans();

    // kick off the `calculate` task on the FeatureUsage service.
    // Step 2 of the "billing admin" flow relies on data that it fetches; to try and cut down on
    // the time spent in a loading spinner on that step, we start the loading now.
    this.featureUsage.calculate.perform();
  }

  async _loadVisitorsPlans() {
    await this.store.findAll('plan');
    this.visitorsBasicPlan = await this.store.peekRecord('plan', 'basic');
  }

  get canDismiss() {
    // users who can't visit billing can dismiss this, since they can't take any action.
    if (this.abilities.cannot('visit billing')) return true;
    // users who trigger the modal from the trial-end page can always dismiss it
    if (this.isFromTrialEndPage) return true;
    // users who _can_ visit billing can dismiss the modal if it appears before the trial expiration;
    // once the trial end date has passed they have to choose a new plan option & can't dismiss it.
    return !this.subscription.onExpiredTrial;
  }

  get canExtendTrial() {
    // subscription cannot be extended more than once
    // and if trial is longer than regular length 14-day, do not allow to extend.
    return this.subscription.timesTrialExtended === 0 && this.subscription.trialLength <= 14;
  }

  // TODO: refactor this to import & return the actual components instead of strings.
  // This requires a version of Ember > 3.21 (but starts working with something <= 3.27).
  get contentComponent() {
    if (this.abilities.cannot('visit billing')) {
      return 'visitors/trial-end-modal/non-billing-admin';
    }

    if (this.trialConvertedToBasic) {
      return 'visitors/trial-end-modal/billing-admin/trial-converted-to-basic';
    }

    if (
      this.step === 2 &&
      !_isEmpty(_omitBy(this.statsig.getExperiment('visitor_trial_end_coupon_experiment').value, _isEmpty))
    ) {
      return 'visitors/trial-end-modal/billing-admin/coupon-experiment';
    }

    return `visitors/trial-end-modal/billing-admin/step-${this.step}`;
  }

  get ctaEventProperties() {
    return {
      cta_id: this.ctaId || 'growth-trial-end-interstitial',
      cta_type: 'modal',
      growth_team_project: true,
    };
  }

  get ctaId() {
    if (this.abilities.cannot('visit billing')) {
      return 'growth-trial-end-interstitial_non-billing-admin';
    }

    if (this.trialConvertedToBasic) {
      return 'growth-trial-end-interstitial_trial-converted-to-basic';
    }

    return `growth-trial-end-interstitial_step-${this.step}`;
  }

  get localStorageKey() {
    return `${DISMISSAL_LOCAL_STORAGE_KEY_BASE}-${this.state.currentCompany.id}`;
  }

  get modalSizeClass() {
    // the modal should be smaller when displaying the "converted to basic" pane
    // than when displaying other content.
    if (this.trialConvertedToBasic) {
      return '!max-w-xs';
    }
    return '!max-w-xl';
  }

  get shouldDisplay() {
    // don't display modal if the user is not authorized
    if (this.abilities.cannot('view trial-end-modal')) return false;
    // don't display modal if user has closed it
    if (this.closed) return false;
    // always show modal if source is trial-end page
    if (this.isFromTrialEndPage) return true;

    // only check for previous dismissals if the user would still be able to dismiss the modal
    if (this.canDismiss) {
      // don't display modal is user has previously dismissed it
      const dismissalType = this.localStorage.getItem(this.localStorageKey);

      // show the modal if user never dismissed it
      if (!dismissalType) return true;

      // show the modal if the user dismissed it before trial expiration, and the expiration has now passed
      if (dismissalType === DISMISSED_BEFORE_EXPIRATION && this.subscription.onExpiredTrial) return true;

      // don't show modal if user dismissed it
      if (
        (dismissalType === DISMISSED_BEFORE_EXPIRATION && !this.subscription.onExpiredTrial) ||
        (dismissalType === DISMISSED_AFTER_EXPIRATION && this.subscription.onExpiredTrial)
      ) {
        return false;
      }
    }

    // otherwise, display the modal
    return true;
  }

  get subscription() {
    return this.state.vrSubscription;
  }

  @action
  moveToNextStep() {
    this.step = Math.min(this.step + 1, NUM_STEPS);
  }

  @action
  moveToPreviousStep() {
    if (this.isFromTrialEndPage) {
      this.close();
    } else {
      this.step = Math.max(this.step - 1, 1);
    }
  }

  @action
  dismiss() {
    const dismissalType = this.subscription.onExpiredTrial ? DISMISSED_AFTER_EXPIRATION : DISMISSED_BEFORE_EXPIRATION;
    this.localStorage.setItem(this.localStorageKey, dismissalType);

    this.metrics.trackEvent('CTA Dismissed', this.ctaEventProperties);

    // only log billing admin dismiss the modal on the first step
    if (this.abilities.can('visit billing') && this.step === 1) {
      this.logToGrowthService.perform('closed');
    }

    this.close();
  }

  @action
  close() {
    if (this.isFromTrialEndPage) {
      this.args.closeModal();
    } else {
      this.closed = true;
    }
  }

  @action
  closeAndRedirect() {
    this.router.transitionTo('visitors.entries.index');
    this.closed = true;
  }

  @dropTask
  *downgradeToBasicTask() {
    const company = this.state.currentCompany;

    this.subscription.downgradePlan = this.visitorsBasicPlan;
    company.planIntent = 'basic';

    try {
      yield this.subscription.save();
      yield company.save();

      yield this.subscription.reload();

      this.trialConvertedToBasic = true;
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }

  @dropTask
  *extendTrialTask() {
    // for reasons I haven't been able to figure out, if I include contents of the `extendTrial` method here (using
    // `yield` instead of `await`, obviously), nothing after the save is run (nor is the catch block executed).
    // This is a workaround.
    yield this.extendTrial();
  }

  async extendTrial() {
    if (!this.canExtendTrial) return;

    // if the trial has expired, the extension is 14 days from the current date.
    // otherwise, the extension is 14 days from the current end date.
    let newTrialEndDate;
    if (this.subscription.onExpiredTrial) {
      newTrialEndDate = addDays(new Date(), TRIAL_EXTENSION_DAYS);
    } else {
      newTrialEndDate = addDays(this.subscription.trialEndDate, TRIAL_EXTENSION_DAYS);
    }

    this.subscription.trialEndDate = newTrialEndDate;
    try {
      await this.subscription.save();
      this.args.trialExtended();
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }

  @action
  logCTAClicked(event) {
    const buttonText = event.target.textContent.trim();
    this.metrics.trackEvent('CTA Clicked', {
      ...this.ctaEventProperties,
      cta_clickable_type: 'button',
      cta_clickable_text: buttonText,
    });

    return event;
  }

  @action
  logCTAViewed() {
    this.metrics.trackEvent('CTA Viewed', this.ctaEventProperties);
  }

  @dropTask
  *logToGrowthService(action) {
    const event_name = IMPRESSION_NAMES.VISITORS_TRIAL_END_MODAL[action.toUpperCase()];
    this.statsig.logEvent(event_name);
    yield this.impressions.postImpression.perform(event_name);
  }
}

import { A } from '@ember/array';
import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import type LocationModel from 'garaje/models/location';
import type PrinterModel from 'garaje/models/printer';
import type ProtectedController from 'garaje/pods/protected/controller';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import { alias, reads, or, uniqueBy } from 'macro-decorators';

import type LocationOverviewGlobalDevicesController from '../controller';

import type { LocationOverviewGlobalDevicesPrintersRouteModel } from './route';

export default class LocationOverviewGlobalDevicesPrintersController extends Controller {
  declare model: LocationOverviewGlobalDevicesPrintersRouteModel;

  @service declare abilities: AbilitiesService;
  @service declare flashMessages: FlashMessagesService;
  @service declare skinnyLocations: SkinnyLocationsService;
  @service declare store: StoreService;

  @controller('protected') protectedController!: ProtectedController;
  @controller('location-overview.global-devices') globalDevices!: LocationOverviewGlobalDevicesController;

  queryParams = [
    'sortBy',
    'sortDirection',
    'pageNumber',
    'pageSize',
    'locationId',
    'printerMode',
    'printerModel',
    'printerStatus',
  ];

  @tracked selectedPrinters: PrinterModel[] = [];
  @tracked printerMode = '';
  @tracked locationId = '';
  @tracked printerStatus = '';
  @tracked printerModel = '';
  @tracked pageNumber = 1;
  defaultRecordCount = 0;
  defaultLocationFilter = { name: 'All locations', id: '' };

  @alias('globalDevices.showList') showList!: LocationOverviewGlobalDevicesController['showList'];
  @reads('model.printers.meta.total') metaRecordCount!: number;
  @reads('model.printers.meta.models') printerModels!: string[];
  @or('metaRecordCount', 'defaultRecordCount') recordCount!: number;
  @uniqueBy('skinnyLocations.readableByCurrentAdmin', 'id') uniqLocations!: LocationModel[];
  @or('printerMode', 'locationId', 'printerStatus', 'printerModel') isFiltered!: boolean;

  get allowSelect(): boolean {
    return this.abilities.can('delete global-printers');
  }

  get printers(): PrinterModel[] {
    // this computed property is created because multi-select does not like
    // model.printers - which is not an array
    return this.model.printers.toArray();
  }

  get isSelectAllIndeterminate(): boolean {
    const selectedCount = this.selectedPrinters.length;
    const isSelectAll = this.isSelectAll;
    return selectedCount > 0 && !isSelectAll;
  }

  get isSelectAll(): boolean {
    const selectedCount = this.selectedPrinters.length;
    const printerCount = this.printers.length;
    return printerCount === selectedCount;
  }

  @action
  clearSelectedPrinters(): void {
    this.selectedPrinters = [];
  }

  @action
  selectAllPrinter(): void {
    if (this.isSelectAll) {
      this.selectedPrinters = [];
    } else {
      this.selectedPrinters = [...this.printers];
    }
  }

  @action
  clearAllFilters(): void {
    this.printerMode = '';
    this.locationId = '';
    this.printerStatus = '';
    this.printerModel = '';
  }

  @action
  unselectPrinter(printer: PrinterModel): void {
    A(this.selectedPrinters).removeObject(printer);
  }

  @action
  selectPrinter(printer: PrinterModel): void {
    A(this.selectedPrinters).addObject(printer);
  }

  @action
  setAndClearPage(setter: (val: string) => void, val: string): void {
    setter(val);
    this.pageNumber = 1;
  }

  @action
  goToPrinterPage(locationId: string): void {
    this.protectedController.send('switchLocation', locationId, 'visitors.devices.printers');
  }

  bulkActions(selectedPrinters: PrinterModel[]): Promise<PrinterModel>[] {
    return selectedPrinters.map((printer) => printer.destroyRecord());
  }

  @action
  refreshList(): void {
    this.send('refreshModel');
  }

  @action
  goToIPadPage(locationId: string): void {
    // we need to load location before transition so that pingDevices method is available
    this.store.findRecord('location', locationId).then((loadedLocation) => {
      this.protectedController.send('switchLocation', loadedLocation.id, 'visitors.devices.ipads');
    });
  }
}

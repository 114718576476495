import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type PluginModel from 'garaje/models/plugin';
import type CurrentAdminService from 'garaje/services/current-admin';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

export default class PluginAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;

  declare model: PluginModel;

  /**
   * plugins are installable on either a company or a location. This information is stored in the installable_on
   * key in the plugin manifest. If the plugin is installable on a company, it is company-wide and only global
   * admins should be able to install. If the plugin is installable on a location, it is location specific and
   * either location admins or global admins should be able to install
   */
  get canInstall(): boolean {
    const { roleNames: roles } = this.currentAdmin;

    if (this.isInstallableOnCompany) {
      return roles.includes(GLOBAL_ADMIN);
    } else if (this.isInstallableOnLocation) {
      return isPresent(_intersection([GLOBAL_ADMIN, LOCATION_ADMIN], roles));
    }

    return false;
  }

  get isInstallableOnCompany(): boolean {
    const { manifest } = this.model;

    return !!manifest?.installable_on?.includes('company');
  }

  get isInstallableOnLocation(): boolean {
    const { manifest } = this.model;

    return !!manifest?.installable_on?.includes('location');
  }
}

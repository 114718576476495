import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { alias, mapBy, sum, filterBy } from 'macro-decorators';
import { inject as service } from '@ember/service';
import { dropTask, lastValue } from 'ember-concurrency';
import fade from 'ember-animated/transitions/fade';
import { guidFor } from '@ember/object/internals';
import { isPresent } from '@ember/utils';
import { action } from '@ember/object';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import zft from 'garaje/utils/zero-for-tests';
import groupBy from 'garaje/utils/decorators/group-by';

/**
 * @param {String}                        planName
 * @param {Class<Subscription>}           subscription
 * @param {Array<String>}                 units
 */

export default class SubscriptionDetails extends Component {
  @service flashMessages;
  @service store;

  @tracked isAddonsExpanded = false;
  @tracked isSummaryExpanded = false;

  transition = fade;
  duration = zft(200);
  elementId = guidFor(this);

  @lastValue('fetchEstimateTask') estimate;

  @alias('estimate.taxes') taxes;
  @alias('estimate.total') total;
  @filterBy('estimate.lineItems', 'entityType', 'plan') plans;
  @groupBy('plans', 'unitAmount') plansByPrice;
  @mapBy('addons', 'amount') addonsAmounts;
  @mapBy('taxes', 'amount') taxesAmounts;
  @sum('addonsAmounts') addonsTotal;
  @sum('taxesAmounts') taxesTotal;

  get hasAddons() {
    return isPresent(this.addons);
  }

  get hasTaxes() {
    return isPresent(this.taxes);
  }

  get addons() {
    return this.estimate?.lineItems?.filterBy('entityType', 'addon') ?? [];
  }

  get allPlans() {
    if (!this.estimate?.lineItems) {
      return null;
    }

    return this.plansByPrice.map((e) => {
      e.quantity = e.items.reduce((acc, i) => acc + i.quantity, 0);
      e.amount = e.items.reduce((acc, i) => acc + i.amount, 0);
      e.discounts = e.items.mapBy('discounts.firstObject').compact();
      return e;
    });
  }

  @action
  onInsert() {
    this.fetchEstimateTask.perform();
  }

  @dropTask
  *fetchEstimateTask() {
    const { subscription } = this.args;

    try {
      if (subscription.isBasicPlan) {
        return null;
      }

      const estimate = yield this.store
        .createRecord('subscription-estimate', {
          app: subscription.app,
          quantity: subscription.quantity,
          plan: subscription.plan,
          period: subscription.period,
          subscription: subscription,
        })
        .save();
      return estimate;
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
    }
  }
}

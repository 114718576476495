import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { cached } from 'tracked-toolbox';

const MODAL_NAV_OPTIONS = Object.freeze([
  Object.freeze({ component: 'data-storage', title: 'Data storage' }),
  Object.freeze({ component: 'returning-visitors', title: 'Returning visitors' }),
  Object.freeze({ component: 'respondents', title: 'Respondents' }),
]);

/**
 * @param {Array<SignInField>} signInFields         Required. Sign in fields
 * @param {Flow}               flow                 Required. Used to set storeResponseConfig
 * @param {Config}             config               Required. Used to show/hide some panel content
 * @param {Task}               saveFieldsTask       Required. Pass through of task to save fields
 * @param {Function}           onClose              Required. Function to handle modal close
 * @param {string}             showFieldOptions     Optional. Indicate which "tab" to pre-select
 * @param {Boolean}            isDisabled           Optional. Indicate inputs are entirely disabled
 * @param {Function}           onSectionChange      Optional. Function to handle tab selection
 */
export default class SignInFieldsFieldOptionsModalComponent extends Component {
  @service featureFlags;

  @tracked selectedSection = MODAL_NAV_OPTIONS[0];
  @tracked isUnsavedFieldsModalForFieldOptionsShowing = false;

  constructor() {
    super(...arguments);

    const { showFieldOptions } = this.args;

    this.selectedSection = this.findSectionByName(showFieldOptions) ?? MODAL_NAV_OPTIONS[0];
  }

  @cached
  get sections() {
    const sections = [...MODAL_NAV_OPTIONS];
    const idScanning = Object.freeze({
      component: 'id-scanning',
      title: 'ID scanning',
    });

    // Feature flag to enable ID scanning UI
    if (this.featureFlags.isEnabled('id-scanning-sign-in-fields-mapping')) {
      sections.push(idScanning);
    }

    return Object.freeze(sections);
  }

  get isPristine() {
    return !this.args.canSave;
  }

  findSectionByName(name) {
    return this.sections.find((sec) => {
      return name === sec.component;
    });
  }

  notifySectionChange(name) {
    if (typeof this.args.onSectionChange === 'function') {
      this.args.onSectionChange(name);
    }
  }

  @action
  handleSectionSelect(section) {
    this.selectedSection = section;
    this.notifySectionChange(section.component);
  }

  @action
  handleClose() {
    if (this.isPristine) {
      return this.confirmClose();
    }

    this.isUnsavedFieldsModalForFieldOptionsShowing = true;
  }

  @action
  confirmClose() {
    this.isUnsavedFieldsModalForFieldOptionsShowing = false;
    this.rollbackResponseOptions();
    this.args.flow.rollbackAttributes();
    this.args.onClose();
  }

  rollbackResponseOptions() {
    this.args.signInFields.forEach((field) => field.rollbackAttributes());
  }

  @task
  *saveFieldOptionsTask() {
    yield this.args.saveFieldsTask.perform();
  }
}

import { validateFormat, validatePresence } from 'ember-changeset-validations/validators';

export default {
  email: [
    validatePresence({
      presence: true,
      message: 'is a required field',
    }),
    validateFormat({
      type: 'email',
      message: 'must be a valid email',
    }),
  ],
};

import RoomFragment from 'garaje/graphql/fragments/roomba/RoomFragment';
import gql from 'graphql-tag';

export default gql`
  mutation PutSpaceSaverRoomResizerExcludedRooms($companyId: ID!, $roomIds: [ID!]!) {
    putSpaceSaverRoomResizerExcludedRooms(companyId: $companyId, roomIds: $roomIds) {
      roomResizerExcludedRooms {
        roomId
        room {
          ...RoomFragment
        }
      }
    }
  }
  ${RoomFragment}
`;

import { FlowsVaccineTrackingBase } from '../vaccine-tracking-base';
import { action } from '@ember/object';

export default class FlowsVaccineTrackingVisitor extends FlowsVaccineTrackingBase {
  @action
  configureOptional(checked) {
    const { templateConfiguration } = this;
    templateConfiguration.optional = checked;
    this.saveTask.perform(templateConfiguration.active);
  }
}

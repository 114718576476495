import ApplicationSerializer from './application';

export default class EmployeeLocationSerializer extends ApplicationSerializer {
  attrs = {
    hasRequiredDocumentApproval: {
      serialize: false,
    },
    requiredDocumentApprovalExpiresAt: {
      serialize: false,
    },
    requiredDocumentMessage: {
      serialize: false,
    },
  };

  modelNameFromPayloadKey(key) {
    if (key === 'locations') {
      return 'skinny-location';
    } else {
      return super.modelNameFromPayloadKey(key);
    }
  }

  payloadKeyFromModelName(key) {
    if (key === 'skinny-location') {
      return 'locations';
    } else {
      return super.payloadKeyFromModelName(key);
    }
  }
}

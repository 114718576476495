// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

/**
 * Action bar component houses new field, translations, rules, etc.
 * @param {Class<CurrentLocation>}    currentLocation                Required. Current location
 * @param {Task}                      saveFieldsTask                 Required. Save Fields Task
 * @param {Array<SignInField>}        signInFields                   Required. Sign in fields
 * @param {Function}                  recalculateDraggableContainer  Required. Recalculates draggable container
 * @param {Array<Object>}             fieldOptions                   Required. Array of field options
 * @param {Boolean}                   canSave                        Required. If form is valid to save
 * @param {Function}                  showRulesModal                 Required. Click handler for showing rules modal
 * @param {Function}                  showFieldOptionsModal          Required. Click handler for showing field options modal
 * @param {Function}                  showResponseOptionsModal       Required. Click handler for showing response options modal
 * @param {Integer}                   ruleCount                      Optional. Count of rules in place for this flow
 * @param {Boolean}                   isProtect                      Required. Type of flow. Helps determine whether user has access to rules
 * @param {Integer}                   savingImpactsRules             Optional. If any rules could be affected while saving
 * @param {Boolean}                   embedded                       Is the form embedded in another view?
 * @param {Flow}                      flow                           A flow or globalFlow
 * @param {Function}                  getDefaultStoreResponse        Required. Default storeResponse value for new fields
 */
export default class ActionBar extends Component {
  tagName = '';

  @service featureFlags;

  /**
   * @type {Boolean}
   */
  @tracked showModalTranslations = false;

  @action
  buildField({ field, kind, value, identifier }) {
    if (!field) {
      this.signInFields.createRecord({
        name: value || '',
        kind,
        required: false,
        identifier,
        position: this.signInFields.length + 1, // random for now, we'll fix it once we had sortability
        storeResponse: this.getDefaultStoreResponse(kind, identifier),
      });

      if (kind !== 'email' && kind !== 'phone') {
        this.scrollDown();
      }
    } else {
      field.rollbackAttributes();
    }

    this.recalculateDraggableContainer();
  }

  scrollDown() {
    window.scrollTo({
      top: document.body.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  }
}

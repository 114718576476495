/* eslint-disable ember/no-component-lifecycle-hooks */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action, set } from '@ember/object';
import { all, task, dropTask } from 'ember-concurrency';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { and } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { v1 as uuid } from 'uuid';

import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

/**
 * Modal form for entering payment method details
 * @param {Function}             onClose       Required - triggered when closing modal
 * @param {Function}             onSuccess     Optional - triggered when payment method successfully save. Otherwise fall back to onClose
 * @param {Class<PaymentSource>} paymentSource Required - A payment source model
 * @param {String}               viaLabel      Required - Text on the CTA used to open this modal
 * @param {String}               viaObject     Required - Type of CTA used to open this modal (button, hover, text_link)
 */

export default class PaymentMethodModal extends Component {
  @service flashMessages;
  @service metrics;
  @service store;

  isPaymentMethodComplete = false;
  createStripeToken = () => {};
  title = 'Enter your payment method';

  @and('isPaymentMethodComplete', 'saveCC.isIdle')
  canSaveCreditCard;

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);
    set(this, 'trackingId', uuid());
    this.trackModalEnteredTask.perform();
  }

  @dropTask
  *openChargeBeeLink() {
    if (this.hostedPageLink) {
      this.openChargeBeeModal();
      return;
    }

    try {
      const hostedPage = this.store.createRecord('hosted-page', {
        companyId: this.companyId,
      });
      yield hostedPage.save();
      if (hostedPage.url) {
        set(this, 'hostedPageLink', hostedPage.url);
        this.openChargeBeeModal();
      } else {
        this.flashMessages.showFlash('error', 'Something went wrong. Please try again later.');
      }
    } catch (e) {
      this.flashMessages.showFlash('error', 'Something went wrong. Please try again later.');
    }
  }

  @dropTask
  *saveCC() {
    if (this.isPaymentMethodComplete) {
      try {
        const stripeResponse = yield this.createStripeToken();
        if (stripeResponse.error) {
          throw new Error(stripeResponse.error.message);
        }
        return yield this.updatePaymentSourceTask.perform(stripeResponse.token);
      } catch (e) {
        this.flashMessages.showFlash('error', 'Stripe error', parseErrorForDisplay(e));
      }
    }
  }

  @dropTask
  *updatePaymentSourceTask(stripeToken) {
    if (this.paymentSource.isACH) {
      const newPaymentSource = this.store.createRecord('payment-source', { companyId: this.companyId });
      set(this, 'paymentSource', newPaymentSource);
    }
    set(this.paymentSource, 'stripeTempToken', stripeToken.id);

    if (this.needReload) {
      this.needReload();
    }

    try {
      const hasPaymentSource = this.paymentSource.exists;
      yield this.paymentSource.save();

      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.metrics.trackEvent('Account modified', {
        product: 'account',
        account_change_type: 'payment_method',
        account_change_object: 'credit_card',
        account_change_action: hasPaymentSource ? 'updated' : 'added',
      });
      const subscriptions = this.store.peekAll('subscription');
      yield all(subscriptions.map((subscription) => subscription.reload()));

      if (this.onSuccess) {
        return this.onSuccess();
      }

      this.onClose();
      set(this, 'isPaymentMethodComplete', false);
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @action
  handlePaymentMethodChange({ complete, createStripeToken }) {
    set(this, 'isPaymentMethodComplete', complete);
    set(this, 'createStripeToken', createStripeToken);
  }

  @task
  *trackModalEnteredTask() {
    yield this.metrics.trackPage('Modal entered', {
      action_id: this.trackingId, // Auto-generated ID to link multiple properties firing to a single action
      modal_title: this.title, // title of the modal
      modal_type: 'payment_method', // modal type
      modal_purpose: 'add_payment_method', // purpose of modal
      via_object: this.viaObject, // object interacted with that triggered the modal opening
      via_action: 'click', // type of interaction
      via_label: this.viaLabel, // Label of the button or element being interacted with
      num_warnings_shown: 0, // Number of warning boxes displayed in the Modal. Can be 0.
      warning_text: null, // Text list (JSON is ok) of the message displayed in the warning boxes. Useful to track in case we want to optimize these in the future.
    });
  }

  @dropTask
  *trackModalExited(object, label) {
    yield this.metrics.trackPage('Modal exited', {
      action_id: this.trackingId, // Auto-generated ID to link multiple properties firing to a single action
      modal_title: this.title, // title of the modal
      modal_type: 'payment_method', // modal type
      modal_purpose: 'add_payment_method', // purpose of modal
      via_object: object, // object interacted with that triggered the modal exiting
      via_action: 'click', // type of interaction
      via_label: label, // Label of the button or element being interacted with
    });
  }
}

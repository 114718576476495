import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AnalyticsDesksRoute extends Route {
  @service router;
  @service featureConfig;
  @service abilities;

  title = 'Desks · Analytics · Envoy';

  beforeModel() {
    if (this.abilities.cannot('visit-desks analytic')) {
      this.router.transitionTo('unauthorized');
    } else if (!this.featureConfig.isEnabled('desks.analytics')) {
      this.router.transitionTo('desks');
    }
  }
}

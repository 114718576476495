import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
import { set } from '@ember/object';
import { v4 as uuid } from 'uuid';

/**
 * @param {Function}                  updateConfigValue
 * @param {Function}                  deletePair
 * @param {Object}                    field
 * @param {Object}                    row
 * @param {Function}                  install
 * @param {Object}                    plugin
 * @param {Object}                    output
 * @param {Class<Location>}           currentLocation
 * @param {Function}                  updateValue
 */
export default class Row extends Component {
  get sourceValue() {
    return this.args.row.source;
  }

  set sourceValue(value) {
    set(this.args.row, 'source', value);
    this.checkForUpdate();
  }

  get matchingValue() {
    return this.args.row.matching;
  }

  set matchingValue(value) {
    set(this.args.row, 'matching', value);
    this.checkForUpdate();
  }

  get componentForType() {
    const { type, multi, open } = this.args.field.schema;
    const prefix = 'plugin-config-field';
    switch (type) {
      case 'select':
        if (multi) {
          return `${prefix}/${type}/multi`;
        }
        break;
      case 'link':
        return `${prefix}/${type}/${open}`;
      case 'resource':
        if (multi) {
          return `${prefix}/${type}/multi`;
        }
        break;
    }
    return `${prefix}/${type}`;
  }

  checkForUpdate() {
    if (this.args.row.source && isPresent(this.args.row.matching)) {
      set(this.args.row, 'id', uuid());
      this.args.updateConfigValue();
    }
  }
}

import Component from '@glimmer/component';
import { action } from '@ember/object';
import moment from 'moment-timezone';

const copyTime = ({ from, to }) => {
  const momentFrom = moment(from);
  const momentTo = moment(to);

  if (from) {
    /* eslint-disable ember/use-ember-get-and-set */
    momentTo.set('hour', momentFrom.get('hour'));
    momentTo.set('minute', momentFrom.get('minute'));
    momentTo.set('second', momentFrom.get('second'));
    momentTo.set('millisecond', momentFrom.get('millisecond'));
    /* eslint-enable ember/use-ember-get-and-set */
  }

  return momentTo.toDate();
};

/**
 * @param {Date}        minDate
 * @param {Date}        maxDate
 * @param {Date}        minTime
 * @param {Date}        maxTime
 * @param {Date}        value
 * @param {Function}    update
 * @param {String}      timezone
 * @param {Boolean}     disabled
 * @param {Boolean}     isReadonly
 */
export default class DatetimePicker extends Component {
  @action
  updateDate(oldDateTime, newDate) {
    let newDateTime;

    if (oldDateTime === null && newDate === null) {
      newDateTime = null;
    } else {
      newDateTime = copyTime({ to: newDate, from: oldDateTime });
    }

    this.args.update(newDateTime);
  }

  @action
  updateTime(oldDateTime, newTime) {
    let newDateTime;

    if (oldDateTime === null && newTime === null) {
      newDateTime = null;
    } else if (oldDateTime) {
      newDateTime = copyTime({ to: oldDateTime, from: newTime });
    } else {
      newDateTime = newTime;
    }

    this.args.update(newDateTime);
  }
}

// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type { CookiesService } from 'ember-cookies/services/cookies';
import SessionService from 'ember-simple-auth/services/session';
import config from 'garaje/config/environment';
import type CookieForLoginService from 'garaje/services/cookie-for-login';
import type CurrentLocationService from 'garaje/services/current-location';
import type InactivityService from 'garaje/services/inactivity';
import type LocalStorageService from 'garaje/services/local-storage';
import type LoginService from 'garaje/services/login';
import type LoginModalService from 'garaje/services/login-modal';
import type MetricsService from 'garaje/services/metrics';
import type WindowLocationService from 'garaje/services/window-location';
import redirectToBoss from 'garaje/utils/redirect-to-boss';

export default class extends SessionService {
  @service declare cookieForLogin: CookieForLoginService;
  @service declare cookies: CookiesService;
  @service declare currentLocation: CurrentLocationService;
  @service declare localStorage: LocalStorageService;
  @service declare login: LoginService;
  @service declare loginModal: LoginModalService;
  @service declare metrics: MetricsService;
  @service declare windowLocation: WindowLocationService;
  @service declare inactivity: InactivityService;

  @tracked _sessionInvalidationFromDeboss = false;

  get isBoss(): boolean {
    return this.localStorage.getItem('is_boss') === 'true';
  }

  /**
    The value of the `company_id` claim from the cookies
  */
  @computed('isAuthenticated')
  get companyId(): string | null | undefined {
    const companyId = this.cookies.read('company_id');
    this.metrics.trackEvent('Read company ID', { source: 'cookie' });
    return companyId;
  }

  async handleAuthentication(routeAfterAuthentication: string): Promise<void> {
    this.cookieForLogin.write();
    if (this.login.transition) {
      try {
        await this.login.transition.retry();
        this.login.transition = null;
      } catch (e) {
        if (!(e instanceof Error) || e.message !== 'TransitionAborted') throw e;
      }
    }

    // start inactivity timer
    await this.inactivity.initialize();

    super.handleAuthentication(routeAfterAuthentication);
  }

  handleInvalidation(/* routeAfterInvalidation: string */): void {
    if (!this.isBoss) {
      // ember-metrics segment adapter doesn't support reset
      if (window.analytics) {
        window.analytics.reset();

        if (window.analytics.Integrations && window.analytics.Integrations.Intercom) {
          window.Intercom('shutdown');
        }
      }
    }

    this.cookieForLogin.clear();
    this.loginModal.reset();

    // clear inactivity timer
    this.inactivity.cleanUp();

    const { cookieDomain: domain } = config;
    this.cookies.clear('intercomIdentityHash', { domain });
    this.localStorage.setItem('is_boss', false);

    const urlAfterInvalidation = this.localStorage.getItem('urlAfterInvalidation');
    this.localStorage.removeItem('urlAfterInvalidation');

    if (urlAfterInvalidation) {
      this.windowLocation.replace(urlAfterInvalidation);
    } else if (this._sessionInvalidationFromDeboss) {
      this._sessionInvalidationFromDeboss = false;
      const location = this.currentLocation.location;
      redirectToBoss(`/locations/${location.id}`);
    } else {
      // The default would redirect to the root, and app would transition to login since user is no longer authenticated
      // And, calling super is a noop when testing we can just use window.location.replace to reboot the app.
      // https://github.com/simplabs/ember-simple-auth/blob/a06cc92d588c1dfc72ddf430cd295ada7e5d132e/addon/mixins/application-route-mixin.js#L133
      // this._super(...arguments);
      this.windowLocation.replace('/');
    }
  }
}

import Component from '@glimmer/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { empty } from '@ember/object/computed';

/**
 * @param {Array}           notifications
 */
export default class HostNotificationColumn extends Component {
  @empty('args.notifications') noneAttempted;

  get everyDidNotSend() {
    return this.args.notifications.every(
      (notification) => notification.failed || notification.expired || notification.ignored,
    );
  }

  get someSent() {
    return this.args.notifications.some((notification) => notification.done);
  }
}

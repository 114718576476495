import Controller from '@ember/controller';
import DS from 'ember-data';
import { service } from '@ember/service';
import { action } from '@ember/object';

const { AdapterError, ServerError, TimeoutError } = DS;

export default class ErrorController extends Controller {
  @service metrics;
  @service router;
  @service session;
  @service sessionStorage;
  @service windowLocation;

  get isServerError() {
    const error = this.model;
    return error instanceof AdapterError || error instanceof ServerError || error instanceof TimeoutError;
  }

  get isLoggedIn() {
    return this.session.isAuthenticated;
  }

  @action
  goToDashboard() {
    this.windowLocation.replace('/');
  }

  @action
  logOutUser() {
    this.session.invalidate();
  }

  @action
  retry() {
    this.sessionStorage.setItem('recovery_retry_attempt', 'user');
    let count = this.sessionStorage.getItem('error_state_retry_count') || '0';
    count = parseInt(count, 10);
    count++;
    this.sessionStorage.setItem('error_state_retry_count', count);
    this.windowLocation.reload();
  }

  @action
  trackStatusClick() {
    const current_url = this.router.currentURL;
    this.metrics.trackEvent('Status Link Clicked', { current_url });
  }
}

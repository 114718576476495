import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import { Changeset } from 'ember-changeset';
import { type DetailedChangeset } from 'ember-changeset/types';
import type ZoneModel from 'garaje/models/zone';
import type StateService from 'garaje/services/state';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';

interface ConnectPropertiesNewRouteModel {
  changesets: {
    coverPhoto: DetailedChangeset<ZoneModel>;
    property: DetailedChangeset<ZoneModel>;
  };
  property: ZoneModel;
}

export default class ConnectPropertiesNewRoute extends Route {
  @service declare state: StateService;
  @service declare store: Store;
  @service declare transitionConfirm: TransitionConfirmService;
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  beforeModel(): void {
    if (this.abilities.cannot('create properties')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  model(): ConnectPropertiesNewRouteModel {
    const property = this.store.createRecord('zone');
    property.company = this.state.currentCompany;
    const propertyChangeset = Changeset(property);

    return {
      changesets: {
        coverPhoto: propertyChangeset, // use same changeset so all changes are applied to the same one
        property: propertyChangeset,
      },
      property,
    };
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    // don't do anything when entering this route
    if (transition.to.name === this.routeName) return;
    const { changesets, property } = <ConnectPropertiesNewRouteModel>this.modelFor(this.routeName);

    // if we're leaving the page after the new property was saved, let transition happen normally
    if (!property.isNew) return;

    const allChangesets = Object.values(changesets);

    // otherwise, check if the user made any changes. `hasDirtyAttributes` is always `true` for a new record
    if (allChangesets.every((changeset) => !changeset.isDirty)) {
      property.unloadRecord();
      return;
    }

    // user changed something; prompt for confirmation
    void this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        // remove the record we created in the model hook from the store
        allChangesets.forEach((changeset) => changeset.rollback());
        property.unloadRecord();
      },
    });
  }
}

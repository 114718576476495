import type Array from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import type MutableArray from '@ember/array/mutable';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { TableField } from 'garaje/pods/roomba/settings/calendars/controller';

export const MODELS_PER_PAGE = 25;

type RecordSelectTableComponentArgs<T> = {
  fields: Array<TableField>;
  models: Array<T>;
  selectedModels: MutableArray<T>;
  recordCount: number;
  setPageNumber: (pageNumber: number) => void;
  pageNumber: number;
  handleSort: (sortField: string, sortDirection: 'asc' | 'desc') => void;
  isSortingEnabled: boolean;
};

export default class RecordSelectTableComponent<T> extends Component<RecordSelectTableComponentArgs<T>> {
  @tracked sortDirection = 'asc';
  @tracked sortField: string;
  @tracked pageNumber = 1;
  @tracked pageSize = MODELS_PER_PAGE;

  constructor(owner: RecordSelectTableComponent<T>, args: RecordSelectTableComponentArgs<T>) {
    super(owner, args);
    this.sortField = this.args.fields.firstObject?.name || '';
  }

  get sortedModels(): T[] {
    const { models, isSortingEnabled } = this.args;
    // if sorting is explicitly disabled, return the models as-is
    if (isSortingEnabled !== undefined && isSortingEnabled !== null && !isSortingEnabled) {
      return models as NativeArray<T>;
    }
    const modelsSorted = models.sortBy(this.sortField);
    if (this.sortDirection === 'desc') {
      return modelsSorted.reverse();
    }
    return modelsSorted;
  }

  get paginatedModels(): T[] {
    const { pageNumber } = this.args;
    return this.sortedModels.slice(pageNumber * this.pageSize - this.pageSize, pageNumber * this.pageSize);
  }

  get recordCount(): number {
    return this.args.recordCount ?? this.args.models.length;
  }

  @action
  setPageNumber(pageNumber: number): void {
    const { setPageNumber } = this.args;
    if (setPageNumber) {
      setPageNumber(pageNumber);
    }
  }

  @action
  handleSelectAll(): void {
    this.args.selectedModels.addObjects(this.args.models);
  }

  @action
  handleDeselectAll(): void {
    this.args.selectedModels.clear();
  }

  @action
  handleSelect(model: T): void {
    this.args.selectedModels.addObject(model);
  }

  @action
  handleDeselect(model: T): void {
    this.args.selectedModels.removeObject(model);
  }

  @action
  handleSort(sortField: string, sortDirection: 'asc' | 'desc'): void {
    this.sortField = sortField;
    this.sortDirection = sortDirection;

    if (this.args.handleSort) {
      this.args.handleSort(sortField, sortDirection);
    }

    this.setPageNumber(1);
  }
}

import Component from '@glimmer/component';
import { type TemplateViewData } from 'garaje/pods/components/announcement-template/announcement-template-list/component';

interface AnnouncementFeedItemArgs {
  template: TemplateViewData;
}

export default class AnnouncementFeedItem extends Component<AnnouncementFeedItemArgs> {
  get popoverLocations(): string[] {
    return this.args.template.remainingLocations.slice(0, 15);
  }

  get remainingPopoverLocationsCount(): number {
    return this.args.template.remainingLocations.length - 15;
  }
}

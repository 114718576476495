import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type LocalStorageService from 'garaje/services/local-storage';
import type MessageBusService from 'garaje/services/message-bus';
import type SigmaUrlService from 'garaje/services/sigma-url';
import type StateService from 'garaje/services/state';
import type UserFeatureFlagsService from 'garaje/services/user-feature-flags';
import urlBuilder from 'garaje/utils/url-builder';

type Message = {
  query?: {
    location_id?: string;
    company_id?: string;
    flow_id?: string;
    start?: string;
    end?: string;
  };
  label?: string;
  event: 'showEntryExportModal' | 'showInviteExportModal' | 'closeExportModal';
};

export default class AnalyticsVisitorsController extends Controller {
  @service declare messageBus: MessageBusService;
  @service declare state: StateService;
  @service declare localStorage: LocalStorageService;
  @service declare flashMessages: FlashMessagesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare userFeatureFlags: UserFeatureFlagsService;
  @service declare sigmaUrl: SigmaUrlService;

  @tracked showModal = false;
  @tracked exportIframeUrl = '';

  constructor(properties: Record<string, unknown>) {
    super(properties);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.messageBus.on('embedded-app-message', this, this.handleMessage);
  }

  get iframeUrl(): Promise<string | undefined> {
    const companyId = this.state?.currentCompany?.id;
    const locationId = this.state?.currentLocation?.id;

    if (!companyId || !locationId) {
      return Promise.resolve(undefined);
    }

    return this.getSigmaIframeUrl(companyId, locationId);
  }

  get legacyIframeUrl(): string {
    const companyId = this.state?.currentCompany?.id;
    const locationId = this.state?.currentLocation?.id;

    return urlBuilder.embeddedVisitorsLocationAnalyticsUrl(companyId, locationId);
  }

  async getSigmaIframeUrl(companyId: string, locationId: string): Promise<string | undefined> {
    try {
      return this.sigmaUrl.getLocationSigmaUrl(companyId, locationId, 'visitors');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error loading dashboard');
      // eslint-disable-next-line no-console
      console.error(e);
      return;
    }
  }

  @action
  toggleShowModal(): void {
    this.showModal = !this.showModal;
  }

  @action
  handleMessage(message: Message): void {
    const locationId = message.query?.location_id || '';
    const companyId = message.query?.company_id || '';
    const flowId = message.query?.flow_id || '';
    const start = message.query?.start || '';
    const end = message.query?.end || '';
    const label = message.label || '';

    if (message.event === 'showEntryExportModal') {
      this.exportIframeUrl = urlBuilder.embeddedEntryExportModalUrl(companyId, locationId, flowId, start, end, label);
      this.toggleShowModal();
    } else if (message.event === 'showInviteExportModal') {
      this.exportIframeUrl = urlBuilder.embeddedInviteExportModalUrl(companyId, locationId, flowId, start, end, label);
      this.toggleShowModal();
    } else if (message.event === 'closeExportModal') {
      this.toggleShowModal();
    }
  }
}

import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type DeviceModel from 'garaje/models/device';
import type LocationModel from 'garaje/models/location';
import SettingsWelcomeScreenMultipleLanguagesRoute from 'garaje/pods/visitors/settings/welcome-screen/multiple-languages/route';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

interface VirtualFrontDeskSettingsWelcomeScreenMultipleLanguagesRouteModel {
  location: LocationModel;
  ipads: RecordArray<DeviceModel>;
}

export default class VirtualFrontDeskSettingsWelcomeScreenMultipleLanguagesRoute extends SettingsWelcomeScreenMultipleLanguagesRoute {
  @service declare store: Store;

  controllerName = 'visitors/settings/welcome-screen/multiple-languages';
  templateName = 'visitors/settings/welcome-screen/multiple-languages';

  titleToken = 'Multiple Languages';

  model(): Promise<VirtualFrontDeskSettingsWelcomeScreenMultipleLanguagesRouteModel> {
    const location = <LocationModel>this.modelFor('current-location');
    const ipads = this.store.query('device', { filter: { location: location.id } });
    return hash({ location, ipads: ipads });
  }
}

import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { adminRoute } from 'garaje/utils/admin-routing';
import { formatScimProvider } from 'garaje/utils/format-scim-provider';

/**
 * Table row component showing admin information of a particular user
 * @param {String}            fullName
 * @param {String}            lastSeen  Date
 * @param {String}            userId
 * @param {String}            avatar
 * @param {String}            userPhotoUrl
 * @param {Object}            rolesWithLocations { roleName<String>: items<Array<Object> }
 * @param {Boolean}           hasSyncedRole
 * @param {Boolean}           hasManuallyAddedRole
 */
export default class TableRow extends Component {
  @service metrics;
  @service router;
  @service currentAdmin;
  @service state;

  tooltipId = `${this.args.userId}-admins-synced-icon`;

  get isLoggedInUser() {
    if (this.currentAdmin && this.args.userId) {
      return get(this.currentAdmin, 'id') === this.args.userId;
    }

    return false;
  }

  get formattedProvider() {
    const { currentCompany } = this.state;
    const scimIntegration = get(currentCompany, 'scimIntegration');
    if (scimIntegration) {
      const provider = get(scimIntegration, 'provider');
      if (provider) {
        return formatScimProvider(provider);
      }
    }

    return 'your SCIM provider';
  }

  @action
  trackAndTransitionToEdit(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    this.metrics.trackEvent('Admin Roles - Edit Admin Role Button Clicked', {
      selected_admin_name: this.args.fullName,
      selected_admin_user_id: this.args.userId,
    });

    const path = adminRoute(this.router.currentRouteName);

    this.router.transitionTo(`${path}.edit`, this.args.userId);
  }

  @action
  trackAndTransitionToDelete(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    this.metrics.trackEvent('Admin Roles - Delete Admin Roles Button Clicked', {
      selected_admin_name: this.args.fullName,
      selected_admin_user_id: this.args.userId,
    });
    const path = adminRoute(this.router.currentRouteName);

    this.router.transitionTo(`${path}.single-delete`, this.args.userId);
  }
}

import config from 'garaje/config/environment';
import { MockUiHooks } from 'garaje/utils/mock-ui-hooks';

export function initialize() {
  if (config.mirageEnabled && !window._GarajeUiHookResponses) {
    window._GarajeUiHookResponses = new MockUiHooks();
  }
}

export default {
  // this initializer needs to run before ember-cli-mirage so that the MockUiHooks data store gets initialized
  // _before_ tests (or scenarios) start trying to add stuff to it.
  before: config.mirageEnabled ? 'ember-cli-mirage' : '',
  name: 'mock-ui-hooks',
  initialize,
};

export function initialize(appInstance) {
  const errorTriage = appInstance.lookup('service:error-triage'); // force the service to init, which will bind Ember.onerror
  if (!errorTriage) {
    throw new Error('The error-triage service failed to instantiate.');
  }
}

export default {
  name: 'error-reporting',
  initialize,
};

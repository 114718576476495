import type ArrayProxy from '@ember/array/proxy';
import { setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { formatInTimeZone } from 'date-fns-tz';
import { didCancel } from 'ember-concurrency';
import type LocationModel from 'garaje/models/location';
import type LocationSubscriptionModel from 'garaje/models/location-subscription';
import type LocationsCapacityModel from 'garaje/models/locations-capacity';
import type PrinterModel from 'garaje/models/printer';
import type SubscriptionModel from 'garaje/models/subscription';
import type UserModel from 'garaje/models/user';
import type StateService from 'garaje/services/state';
import { DATE_FNS_YYYY_MM_DD } from 'garaje/utils/date-fns-tz-utilities';
import { fetchCapacity } from 'garaje/utils/locations-capacity';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

import type VisitorsEntriesController from '../controller';
import type { VisitorsEntriesRouteModel, VisitorsEntriesRouteParams } from '../route';

export interface VisitorsEntriesIndexRouteModel {
  blocklistContacts: ArrayProxy<UserModel>;
  currentLocation: LocationModel;
  entries: VisitorsEntriesRouteModel;
  idScanContacts: ArrayProxy<UserModel>;
  locationsCapacity: LocationsCapacityModel | null;
  printers: RecordArray<PrinterModel> | undefined;
  vrSubscription: LocationSubscriptionModel | SubscriptionModel | null;
}

class VisitorsEntriesIndexRoute extends Route {
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  titleToken = '';

  model(): Promise<VisitorsEntriesIndexRouteModel> {
    const { vrSubscription } = this.state;
    const currentLocation = this.state.currentLocation;
    const { capacityLimitEnabled, timezone } = currentLocation;
    const blocklistContacts = currentLocation.blocklistContacts;
    const idScanContacts = currentLocation.idScanContacts;
    const entries = <VisitorsEntriesRouteModel>this.modelFor('visitors.entries');
    const { printers } = entries;
    const today = formatInTimeZone(new Date(), timezone, DATE_FNS_YYYY_MM_DD);

    let { date, startDate, endDate } = <VisitorsEntriesRouteParams>this.paramsFor('visitors.entries');

    startDate = startDate ? formatInTimeZone(new Date(startDate), timezone, DATE_FNS_YYYY_MM_DD) : today;
    endDate = endDate ? formatInTimeZone(new Date(endDate), timezone, DATE_FNS_YYYY_MM_DD) : today;
    date = date || (startDate === endDate ? startDate : today);

    let locationsCapacity: Promise<LocationsCapacityModel> | null = null;

    if (capacityLimitEnabled) {
      locationsCapacity = <Promise<LocationsCapacityModel>>(<unknown>fetchCapacity(this.store, currentLocation, date));
    }

    return hash({
      blocklistContacts,
      currentLocation,
      entries,
      idScanContacts,
      locationsCapacity,
      printers,
      vrSubscription,
    });
  }

  afterModel(model: VisitorsEntriesIndexRouteModel): void | Promise<unknown> {
    const entries = model.entries;
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const controller = <VisitorsEntriesController>this.controllerFor('visitors.entries');

    const props = {
      refresh: '',
      page: 0,
      limit: 25,
      totalLoadedEntries: 0,
      date: entries.date,
      query: entries.query,
      filter: entries.filter,
      sort: entries.sort,
      selectedFlow: entries.selectedFlow ?? '',
    };

    setProperties(controller, props);

    void controller.loadBosses.perform();
    return controller.loadEntries.perform().catch((e) => {
      if (!didCancel(e)) {
        throw e;
      }
      return this.router.transitionTo('visitors.entries');
    });
  }
}

export default VisitorsEntriesIndexRoute;

import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class RecordSelectRowComponent extends Component {
  get isSelected() {
    const { selectedModels, model } = this.args;

    return selectedModels.includes(model);
  }

  @action
  handleChange(evt) {
    const { onSelect, onDeselect, model } = this.args;

    if (evt.target.checked) {
      onSelect(model);
    } else {
      onDeselect(model);
    }
  }
}

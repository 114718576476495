// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { readOnly, equal } from '@ember/object/computed';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';

// eslint-disable-next-line ember/no-classic-classes
export default Component.extend({
  locations: service(),
  tagName: '',
  item: readOnly('changelog.items.firstObject'),
  multipleImages: equal('item.field', 'imageFileUrls'),
  isAFilterRule: equal('item.field', 'filterRules'),

  isAnImage: computed('item.field', function () {
    const imageTypes = ['logo', 'welcomeImage', 'imageFileUrls'];
    return imageTypes.includes(get(this, 'item.field'));
  }),
});

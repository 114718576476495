import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import ManageAdminUsersRoute from 'garaje/pods/manage/admin-users/route';
import type GlobalOverviewAccessService from 'garaje/services/global-overview-access';

class LocationOverviewAdminsRoute extends ManageAdminUsersRoute {
  @service declare router: RouterService;
  @service declare globalOverviewAccess: GlobalOverviewAccessService;

  controllerName = 'manage/admin-users';
  templateName = 'manage/admin-users';

  beforeModel(): void {
    if (!this.globalOverviewAccess.canVisitGlobalAdmins) {
      void this.router.transitionTo('unauthorized');
    }
  }
}

export default LocationOverviewAdminsRoute;

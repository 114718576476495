import { belongsTo, hasMany } from '@ember-data/model';
import type { SyncHasMany, AsyncBelongsTo } from '@ember-data/model';
import { apiAction } from '@mainmatter/ember-api-actions';
import AbstractConfig from 'garaje/models/abstract/abstract-config';
import type FlowModel from 'garaje/models/flow';
import type WelcomeScreenSlideModel from 'garaje/models/welcome-screen-slide';
import type ZoneModel from 'garaje/models/zone';
import { type SingleResponse } from 'jsonapi/response';

export default class PropertyDeviceConfigModel extends AbstractConfig {
  @belongsTo('zone')
  declare property: AsyncBelongsTo<ZoneModel>;

  @hasMany('flow', { async: false })
  declare flows: SyncHasMany<FlowModel>;

  @hasMany('welcome-screen-slide', { async: false })
  declare welcomeScreenSlides: SyncHasMany<WelcomeScreenSlideModel>;

  async deleteWelcomeImage(): Promise<SingleResponse<PropertyDeviceConfigModel>> {
    return <SingleResponse<PropertyDeviceConfigModel>>(
      await apiAction(this, { method: 'DELETE', path: 'attachments/welcome-image' })
    );
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'property-device-config': PropertyDeviceConfigModel;
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { dropTask, timeout } from 'ember-concurrency';
import { htmlSafe } from '@ember/template';

const SORT_KEYS = {
  'Location name': 'name',
  'Location created': 'createdAt',
  'Last sign-in': 'lastEntryAt',
  'Average monthly visitors': 'averageMonthlyVisitorCount',
};

/**
 * @param {Array<Object>}           locations
 * @param {Array<Object>}           averageMonthlyVisitorCount
 */
export default class LocationsTable extends Component {
  @tracked sortBy = 'Location name';
  @tracked sortDirection = 'A-Z';
  @tracked fixHeader = false;
  @tracked selectedLocations = [];
  @tracked resizeListener;
  @tracked scrollListener;
  @tracked element;

  constructor() {
    super(...arguments);
    this.selectedLocations = [];

    this.resizeListener = () => {
      this.resizeColsTask.perform();
    };

    this.scrollListener = () => {
      this.adjustHeaderStylesTask.perform();
    };

    window.addEventListener('resize', this.resizeListener);
    window.addEventListener('scroll', this.scrollListener);
  }

  get locationsWithAverageCount() {
    const locations = this.args.locations;
    const averageMonthlyVisitorCounts = this.args.averageMonthlyVisitorCount;

    return locations.map((location) => {
      const countObject =
        averageMonthlyVisitorCounts.find((a) => {
          return a.belongsTo('location').id() === location.id;
        }) || {};

      return {
        ...location.toJSON(),
        averageMonthlyVisitorCount: countObject.count || 0,
      };
    });
  }

  get headerStyles() {
    let style = ``;

    if (this.fixHeader) {
      style = `
          position: fixed;
          top: 0;
          box-shadow: 0 6px 12px 0 #e9e9ea;
        `;
    }

    return htmlSafe(style);
  }

  get isSortAsc() {
    const sortAscKeys = ['A-Z', 'Least to most recent', 'Oldest to newest', 'Fewest to most'];
    return sortAscKeys.includes(this.sortDirection);
  }

  get sortedLocations() {
    const sortBy = SORT_KEYS[this.sortBy];
    let locations = this.locationsWithAverageCount.sortBy(sortBy);

    if (!this.isSortAsc) {
      locations = locations.reverse();
    }

    return locations;
  }

  @action
  onInsert(element) {
    this.element = element;
    this.resizeColsTask.perform();
  }

  willDestroy() {
    super.willDestroy(...arguments);
    window.removeEventListener('resize', this.resizeListener);
    window.removeEventListener('scroll', this.scrollListener);
  }

  /*
   * resizeColsTask
   *
   * In order to acheive the floating cols as the talble scrolls, we
   * have a dispaly table ([data-display-table]) and hide actual
   * `thead`. This task syncs with column width between the display
   * table and actual table to simulate the native content stretch
   * that occurs in an HTML table.
   */
  @dropTask
  *resizeColsTask() {
    yield timeout(100);
    const tableCols = this.element.querySelectorAll('[data-display-table] tr:first-child td');
    const tableColWidths = [...tableCols].map((col) => col.clientWidth);
    const tableThs = this.element.querySelectorAll('[data-header-table] th');

    tableThs.forEach((col, index) => {
      col.style.width = `${tableColWidths[index]}px`;
    });
  }

  @dropTask
  *adjustHeaderStylesTask() {
    yield timeout(10);

    if (document.querySelector('html').scrollTop > document.querySelector('.contentContainer').offsetTop) {
      this.fixHeader = true;
    } else {
      this.fixHeader = false;
    }
  }

  @action
  toggleSelectLocation(location) {
    const locations = this.selectedLocations;
    const locationId = get(location, 'id');

    if (locations.findBy('id', locationId)) {
      const indexOfElementToRemove = locations.findIndex((location) => get(location, 'id') === locationId);
      this.selectedLocations = [
        ...locations.slice(0, indexOfElementToRemove),
        ...locations.slice(indexOfElementToRemove + 1),
      ];
    } else {
      this.selectedLocations = [...locations, location];
    }
  }

  @action
  toggleAllLocations() {
    const selectedLocations = this.selectedLocations;
    const locations = this.args.locations;

    if (get(selectedLocations, 'length') === get(locations, 'length')) {
      this.selectedLocations = [];
    } else {
      this.selectedLocations = [...locations];
    }
  }
}

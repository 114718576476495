import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';

export default class ThinkificService extends Service {
  @service state;
  @service ajax;

  getThinkificUrlTask = task({ drop: true }, async () => {
    const URL = urlBuilder.growth.getThinkificSsoUrl();
    const settings = {
      URL,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        data: {
          attributes: {
            'first-name': this.state.currentUser.firstName,
            'last-name': this.state.currentUser.lastName,
            email: this.state.currentUser.email,
            company: this.state.currentCompany.name,
            timezone: this.state.currentLocation.timezone,
            locale: this.state.currentLocation.locale,
          },
        },
      }),
    };

    try {
      const { jwt } = await this.ajax.request(URL, settings);

      return `https://envoy.thinkific.com/api/sso/v2/sso/jwt?jwt=${jwt}`;
    } catch (e) {
      const error_message = parseErrorForDisplay(e);
      this.metrics.trackEvent('POST Thinkific Sign In failed', {
        type: 'error',
        message_title: error_message,
        location_id: this.state.currentLocation?.id,
        company_id: this.state.currentCompany?.id,
      });
    }
  });
}

import { action } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import clamp from 'clamp';
import Ember from 'ember';
import { localCopy } from 'tracked-toolbox';

const { escapeExpression } = (<typeof Handlebars>(<unknown>Ember.Handlebars)).Utils;

interface LineClampComponentSignature {
  Args: {
    /**
     * the maximum # of lines the text should be displayed on
     */
    lines: number;
    /**
     * the line-height of the lines of text
     */
    lineHeight: string;
    /**
     * the content being "clamped"
     */
    text: string;
    /**
     * string to append to truncated text (default: '…')
     */
    truncationChar: string;
    /**
     * @param isTruncated a callback function executed after truncation
     */
    handleTruncate?: (isTruncated: boolean) => void;
    useNative: boolean;
  };
}

export default class LineClampComponent extends Component<LineClampComponentSignature> {
  @tracked clampedText?: string;
  @localCopy('args.truncationChar', '…') truncationChar!: LineClampComponentSignature['Args']['truncationChar'];
  @localCopy('args.useNative', false) useNative!: LineClampComponentSignature['Args']['useNative'];

  get style(): string {
    return `line-height: ${this.args.lineHeight};`;
  }

  constructor(properties: unknown, args: LineClampComponentSignature['Args']) {
    super(properties, args);
  }

  @action
  clamp(element: HTMLElement): void {
    const { args, useNative, truncationChar } = this;
    const { text, lines } = args;

    // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
    scheduleOnce('afterRender', this, () => {
      element.innerHTML = escapeExpression(text);

      const { clamped: clampedText } = clamp(element, {
        clamp: lines,
        truncationChar,
        useNativeClamp: !!useNative,
      });

      // Check for both clamped value and indication of native line-clamp
      if (clampedText || element.scrollHeight > element.clientHeight) {
        this.clampedText = clampedText;
        this.args.handleTruncate?.(true);
      } else {
        this.args.handleTruncate?.(false);
      }
    });
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';

export default class SettingsRedirectRoute extends Route {
  @service declare router: RouterService;

  beforeModel(transition: Transition): Promise<unknown> {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return this.router.transitionTo(`/visitors${transition.intent.url}`);
  }
}

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

export default class EmployeeRegistrationRoute extends Route {
  @service flow;
  @service abilities;
  @service skinnyLocations;
  @service featureFlags;
  @service router;

  async beforeModel() {
    if (this.abilities.can('visit workplace')) {
      return this.router.transitionTo('workplace.settings.employees.registration');
    }
    if (this.abilities.cannot('visit screening for protect')) {
      this.router.transitionTo('protect');
    }
    await this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
  }

  async model() {
    await this.flow.initService();
  }
}

import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Model from '@ember-data/model';
import type Store from '@ember-data/store';
import { task } from 'ember-concurrency';
import type CompanyModel from 'garaje/models/company';
import type LocationModel from 'garaje/models/location';
import type PrinterPasscodeModel from 'garaje/models/printer-passcode';
import type UserModel from 'garaje/models/user';
import type StateService from 'garaje/services/state';

export default class VisitorsDevicesPrintersConnectionsNewController extends Controller {
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: Store;

  @action
  handlePasscodeConsumed(printerConnection: Model): void {
    void this.router.transitionTo('visitors.devices.printers.connections.show', printerConnection.id);
  }

  fetchOneTimePasscodeTask = task({ drop: true }, async (global = false) => {
    const company: CompanyModel | null = this.state.currentCompany;
    const location: LocationModel | null = this.state.currentLocation;
    const user: UserModel | null = this.state.currentUser;

    if (!user) return;
    if (!global && !location) return;
    if (global && !company) return;

    const association: { company?: CompanyModel | null; location?: LocationModel | null } = {};
    if (global) {
      association.company = company;
    } else {
      association.location = location;
    }

    const printerPasscode: PrinterPasscodeModel = this.store.createRecord('printer-passcode', {
      user,
      ...association,
    });

    await printerPasscode.save();

    return printerPasscode;
  });
}

import Controller from '@ember/controller';
import { dropTask } from 'ember-concurrency';
import { defer } from 'rsvp';

export default class MapsEditIndexController extends Controller {
  @dropTask
  showFloorModalTask = {
    *perform() {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = async () => {
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };
}

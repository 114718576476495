import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class extends Component {
  @tracked isMultiLocationSelectModalOpen = false;
  @tracked currentGlobalFlowObj = undefined;

  @action
  onClickEditLocations(globalFlowObj) {
    this.isMultiLocationSelectModalOpen = !this.isMultiLocationSelectModalOpen;
    this.currentGlobalFlowObj = globalFlowObj;
  }

  @action
  closeModal() {
    this.isMultiLocationSelectModalOpen = false;
    this.currentGlobalFlowObj = undefined;
  }
}

import _defaults from 'lodash/defaults';
import Report from 'garaje/models/report';

export default class IdScanning extends Report {
  constructor(attrs = {}) {
    super(...arguments);
    const props = { ...attrs };
    _defaults(props, {
      reason: null, // string
    });
    Object.assign(this, props);
  }
}

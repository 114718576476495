import Component from '@glimmer/component';
import { reads, notEmpty } from 'macro-decorators';

/**
 * @param {Entry}           entry
 */
export default class IdScanningColumn extends Component {
  @reads('args.entry.approvalStatus.status') status;
  @reads('args.entry.approvalStatus.reviewerName') reviewerName;
  @reads('args.entry.approvalStatus.reviewedAt') reviewedAt;
  @reads('args.entry.approvalStatus.didFailIdScanningCheck') didFailIdScanningCheck;
  @notEmpty('args.entry.approvalStatus.idScanningReport') hasReports;
}

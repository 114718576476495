import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, ZONE_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_VISIT_ROLES = [GLOBAL_ADMIN, ZONE_ADMIN, LOCATION_ADMIN, RECEPTIONIST];

export default class AnalyticAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;

  get canVisit(): boolean {
    const { roleNames } = this.currentAdmin;
    const canAccessAnalytics =
      !!this.state.features?.canAccessProtectLegacyFeatures && !this.state.features?.canAccessOccupancyAnalytics;

    const unfilteredZoneRoles = this.state.currentUser?.zoneRoles.mapBy('roleName');
    const hasValidRole =
      isPresent(_intersection(CAN_VISIT_ROLES, roleNames)) ||
      isPresent(_intersection(CAN_VISIT_ROLES, unfilteredZoneRoles));

    return canAccessAnalytics && hasValidRole;
  }

  get canVisitGlobal(): boolean {
    const { roleNames } = this.currentAdmin;
    const canAccessAnalytics =
      !!this.state.features?.canAccessProtectLegacyFeatures && !this.state.features?.canAccessOccupancyAnalytics;

    const unfilteredZoneRoles = this.state.currentUser?.zoneRoles.mapBy('roleName');
    const hasValidRole =
      isPresent(_intersection([GLOBAL_ADMIN], roleNames)) ||
      isPresent(_intersection(CAN_VISIT_ROLES, unfilteredZoneRoles));

    return canAccessAnalytics && hasValidRole;
  }
}

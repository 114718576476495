import EmberObject, { computed, get, set } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import stringHash from 'garaje/utils/string-hash';

function withCartFunctionality(Class) {
  return class WithCartFunctionality extends Class {
    @service sessionStorage;
    @service state;

    @tracked _billingCart = null;

    @computed('_billingCart', '_cartId')
    get billingCart() {
      let cart = this._billingCart;
      if (!cart) {
        const sessionCart = this.sessionStorage.getItem('billingCart');
        if (sessionCart) {
          try {
            cart = EmberObject.create(JSON.parse(sessionCart));
          } catch (error) {
            /* eslint-disable no-console */
            console.error({ error });
            /* eslint-enable no-console */
          }
        }
      }
      if (!cart) {
        cart = this._defaultBillingCart;
      }
      if (this._shouldExpireBillingCart(cart._cartId)) {
        cart = this._defaultBillingCart;
      }
      this.billingCart = cart;
      return this._billingCart;
    }

    set billingCart(cart) {
      this.sessionStorage.setItem('billingCart', JSON.stringify(cart));
      this._billingCart = cart;
      // eslint-disable-next-line no-setter-return
      return this._billingCart;
    }

    @computed(
      'allPlans',
      'allLocations',
      'visitorsSubscriptionPlan.id',
      'roomsSubscriptionPlan.id',
      'deliveriesSubscriptionPlan.id',
    )
    get _cartId() {
      const { allPlans, allLocations } = this;
      const string = [
        allPlans.map((p) => p.id),
        allLocations.map((p) => p.id),
        get(this, 'visitorsSubscriptionPlan.id'),
        get(this, 'roomsSubscriptionPlan.id'),
        get(this, 'deliveriesSubscriptionPlan.id'),
      ]
        .flat()
        .join();
      return stringHash(string);
    }

    get _defaultBillingCart() {
      const cart = EmberObject.create({
        _cartId: this._cartId,
        selectedPeriod: null,
        sectionOpened: null,
        editingQuantities: true,
        isDirty: false,
        deliveries: EmberObject.create({ onTrial: false, subscriptionPlanId: null, locationQuantities: [] }),
        rooms: EmberObject.create({ onTrial: false, subscriptionPlanId: null, locationQuantities: [] }),
        visitors: EmberObject.create({ onTrial: false, subscriptionPlanId: null, locationQuantities: [] }),
      });

      const {
        period,
        visitorsTrialDaysLeft,
        roomsTrialDaysLeft,
        deliveryTrialDaysLeft,
        visitorsSubscriptionPlan,
        deliveriesSubscriptionPlan,
        roomsSubscriptionPlan,
      } = this;

      this._setSubscriptionPlan(cart, visitorsSubscriptionPlan, !!visitorsTrialDaysLeft);
      this._setSubscriptionPlan(cart, deliveriesSubscriptionPlan, !!deliveryTrialDaysLeft);
      this._setSubscriptionPlan(cart, roomsSubscriptionPlan, !!roomsTrialDaysLeft);
      this._setPeriod(cart, period);

      this._syncCartLocationQuantities(cart);
      return cart;
    }

    _setEditingQuantities(billingCart, editingQuantities) {
      set(billingCart, 'editingQuantities', editingQuantities);
    }

    _setDirty(billingCart, isDirty) {
      set(billingCart, 'isDirty', isDirty);
    }

    _setLocationQuantity(billingCart, app, id, name, quantityString, setInitialQuantity, activeQuantity) {
      const quantity = parseInt(quantityString, 10);
      const subs = billingCart[app].locationQuantities;
      const sub = subs.find((s) => s.id === id);
      if (sub) {
        set(sub, 'quantity', quantity);
      } else {
        if (setInitialQuantity) {
          subs.pushObject({ id, name, quantity, activeQuantity, initialQuantity: quantity });
        } else {
          subs.pushObject({ id, name, quantity, activeQuantity });
        }
      }
    }

    _setPeriod(billingCart, selectedPeriod) {
      set(billingCart, 'selectedPeriod', selectedPeriod);
    }

    _setSectionOpened(billingCart, sectionOpened) {
      set(billingCart, 'sectionOpened', sectionOpened);
    }

    _setSubscriptionPlan(billingCart, subscriptionPlan, onTrial) {
      if (subscriptionPlan) {
        const { app, id, pricePerPeriod, name } = subscriptionPlan;

        set(billingCart, `${app}.subscriptionPlanId`, id);
        set(billingCart, `${app}.subscriptionPlanPricePerPeriod`, pricePerPeriod);
        set(billingCart, `${app}.subscriptionPlanName`, name);
        set(billingCart, `${app}.onTrial`, onTrial);
      }
    }

    _endSubscriptionPlanTrial(billingCart, app) {
      const planExists = get(billingCart, `${app}.subscriptionPlanId`);
      if (planExists) {
        set(billingCart, `${app}.onTrial`, false);
      }
    }

    @computed('state.{activeRoomsCount.last.value,activeDeliveryAreasCount.last.value,}', 'app')
    get activeQuantity() {
      let quantity = 0;
      switch (this.app) {
        case 'visitors':
          quantity = get(this, 'state.activeLocationsCount.last.value');
          break;
        case 'deliveries':
          quantity = get(this, 'state.activeDeliveryAreasCount.last.value');
          break;
        case 'rooms':
          quantity = get(this, 'state.activeRoomsCount.last.value');
          break;
      }
      return quantity;
    }

    _syncCartLocationQuantities(billingCart) {
      const locationActiveQuantitiesD = get(this, 'state.activeDeliveryAreasPerLocation') | null;
      const locationActiveQuantitiesR = get(this, 'state.activeRoomsPerLocation') | null;
      this.allLocations.forEach((location) => {
        let quantityV = 0;
        let quantityD = 0;
        let quantityR = 0;
        const activeQuantityV = 1;
        const activeQuantityD =
          locationActiveQuantitiesD && locationActiveQuantitiesD[location.id]
            ? locationActiveQuantitiesD[location.id]
            : 0;
        const activeQuantityR =
          locationActiveQuantitiesR && locationActiveQuantitiesR[location.id]
            ? locationActiveQuantitiesR[location.id]
            : 0;
        if (location.visitorsSubscription && !get(location, 'visitorsSubscription.cancelled')) {
          quantityV = location.visitorsSubscription.quantity;
        }
        if (location.deliveriesSubscription && !get(location, 'deliveriesSubscription.cancelled')) {
          quantityD = location.deliveriesSubscription.quantity;
        }
        if (location.roomsSubscription && !get(location, 'roomsSubscription.cancelled')) {
          quantityR = location.roomsSubscription.quantity;
        }
        this._setLocationQuantity(
          billingCart,
          'visitors',
          location.id,
          location.nameWithCompanyName,
          quantityV,
          true,
          activeQuantityV,
        );
        this._setLocationQuantity(
          billingCart,
          'deliveries',
          location.id,
          location.nameWithCompanyName,
          quantityD,
          true,
          activeQuantityD,
        );
        this._setLocationQuantity(
          billingCart,
          'rooms',
          location.id,
          location.nameWithCompanyName,
          quantityR,
          true,
          activeQuantityR,
        );
      });
      return billingCart;
    }

    _shouldExpireBillingCart(cartId) {
      return this._cartId !== cartId;
    }
  };
}

export default withCartFunctionality;

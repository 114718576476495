import Controller from '@ember/controller';
import { action, set, setProperties } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import type StoreService from '@ember-data/store';
import { dropTask } from 'ember-concurrency';
import type DeviceModel from 'garaje/models/device';
import type LocationsSetupGuideStepModel from 'garaje/models/locations-setup-guide-step';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import type VisitorsOnboardingService from 'garaje/services/visitors-onboarding';

export default class DevicesIpadsNewController extends Controller {
  @service declare currentAdmin: CurrentAdminService;
  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: StoreService;
  @service declare visitorsOnboarding: VisitorsOnboardingService;

  hasPairedIpads: boolean | null = null;
  isOnboardingEnabled: boolean | null = null;
  currentStep?: LocationsSetupGuideStepModel | null = null;
  currentRouteStep?: LocationsSetupGuideStepModel | null = null;
  nextStep?: LocationsSetupGuideStepModel | null = null;

  get isVirtualFrontDesk(): boolean {
    const currentRoute = this.router.currentRouteName;
    return currentRoute.includes('virtual-front-desk');
  }

  get backLink(): string {
    return this.isVirtualFrontDesk ? 'virtual-front-desk.devices.ipads.index' : 'visitors.devices.ipads.index';
  }

  get showSkipStepButton(): boolean {
    const { isOnboardingEnabled, isCurrentStep } = this;
    return Boolean(isOnboardingEnabled && isCurrentStep);
  }

  get destinationRoute(): string {
    const { isOnboardingEnabled, isCurrentStep, nextStep } = this;
    if (isOnboardingEnabled && isCurrentStep && nextStep) {
      return nextStep.routeName;
    }
    if (this.isVirtualFrontDesk) {
      return 'virtual-front-desk.devices';
    }
    return 'visitors.devices';
  }

  get isCurrentStep(): boolean {
    const { currentRouteStep, currentStep } = this;
    return Boolean(currentRouteStep && currentRouteStep === currentStep);
  }

  @action
  reinitializeDevice(): DeviceModel {
    const { currentLocation: location } = this.state;
    return this.store.createRecord('device', { location });
  }

  @action
  async reloadDevicesAndRedirect(): Promise<void> {
    void this.router.refresh('visitors.devices.ipads');
    if (this.visitorsOnboarding.showVideoWalkthrough) {
      void this.visitorsOnboarding.loadIpadsTask.perform();
    }
    if (this.isVirtualFrontDesk) {
      const vfdConfiguration = await this.state.currentLocation.getVfdConfiguration();
      if (!vfdConfiguration?.enabled) {
        const details = this.currentAdmin.isGlobalAdmin
          ? htmlSafe(
              `<a href="/location-overview/virtual-front-desk/locations">Enable Virtual Front Desk for this location in Global Overview</a>`,
            )
          : 'Please contact a Global Admin to enable Virtual Front Desk for this location.';
        this.flashMessages.showFlash('success', 'iPad added successfully!', { details: details });
      } else {
        this.flashMessages.showAndHideFlash('success', 'iPad added successfully');
      }
    } else {
      this.flashMessages.showAndHideFlash('success', 'iPad added successfully');
    }
    void this.router.transitionTo(this.destinationRoute);
  }

  skipStepTask = dropTask(async () => {
    const { isCurrentStep, currentStep, nextStep } = this;
    if (isCurrentStep && currentStep) {
      // @ts-ignore started is not part of current model?
      setProperties(currentStep, { started: true, skipped: true });
      // @ts-ignore started is not part of current model?
      if (nextStep) void set(nextStep, 'started', true);
      await currentStep.save();
      await this.router.transitionTo(this.destinationRoute);
    }
  });
}

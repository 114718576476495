import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type { SyncHasMany, AsyncBelongsTo } from '@ember-data/model';
import type LocationModel from 'garaje/models/location';
import type TicketCategoryModel from 'garaje/models/ticket-category';
import type TicketCategoryEmailModel from 'garaje/models/ticket-category-email';

class TicketConfigurationModel extends Model {
  @attr('boolean') declare enabled: boolean;
  @attr('boolean') declare ticketingIntegrationInstalled: boolean;
  @attr('string') declare ticketingIntegrationName: string;
  @attr('string') declare serviceRequestUrl: string;

  @belongsTo('location') declare location: AsyncBelongsTo<LocationModel>;
  @hasMany('ticket-category', { async: false }) declare ticketCategories: SyncHasMany<TicketCategoryModel>;
  @hasMany('ticket-category-email', { async: false })
  declare ticketCategoryEmails: SyncHasMany<TicketCategoryEmailModel>;
}

export default TicketConfigurationModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'ticket-configuration': TicketConfigurationModel;
  }
}

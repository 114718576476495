import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import type { Task } from 'ember-concurrency';
import type ConfigModel from 'garaje/models/config';
import type FlowModel from 'garaje/models/flow';
import type IdScanPageModel from 'garaje/models/id-scan-page';
import type LocationModel from 'garaje/models/location';
import type UserModel from 'garaje/models/user';
import type VisualComplianceConfigurationModel from 'garaje/models/visual-compliance-configuration';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { alias } from 'macro-decorators';

interface FlowsSectionsSecurityComponentArgs {
  config: ConfigModel;
  currentLocation: LocationModel;
  flow: FlowModel;
  idScanningEnabled: boolean;
  idScanPage: IdScanPageModel;
  inviteApprovalContacts: UserModel[];
  isDisabled: boolean;
  onChange: () => unknown;
  onCreateConfiguration: (val: VisualComplianceConfigurationModel) => unknown;
  showIdScanning: boolean;
  showVisualCompliance: boolean;
  updateAndSaveTask: Task<unknown, [FlowModel, string]>;
  visualComplianceConfiguration: VisualComplianceConfigurationModel;
}

interface Link {
  name: string;
  path: string;
}

export default class FlowsSectionsSecurityComponent extends Component<FlowsSectionsSecurityComponentArgs> {
  @service declare abilities: AbilitiesService;
  @service declare metrics: MetricsService;
  @service declare state: StateService;

  @alias('state.currentLocation') currentLocation!: StateService['currentLocation'];
  @alias('state.vrSubscription') vrSubscription!: StateService['currentLocation'];

  @tracked isVisualComplianceOpen = false;

  get learnMoreLinks(): Link[] {
    const idCheckLink = this.abilities.can('manage id-check');
    const links = [];
    if (idCheckLink) {
      links.push({ name: 'ID check', path: 'https://help.envoy.com/id-requirements/' });
    }

    if (this.args.showIdScanning) {
      links.push({ name: 'ID scanning', path: 'https://help.envoy.com/id-scanning/' });
    }

    if (this.args.showVisualCompliance) {
      links.push({
        name: 'the Visual Compliance integration',
        path: 'https://help.envoy.com/visual-compliance-integration/',
      });
    }

    return links;
  }

  @action
  onUpgradeIdScanning(plan: string): void {
    this.metrics.trackEvent('Visitor Type - ID Scanning Upgrade Clicked', { plan });
  }

  @action
  toggleVisualCompliancePanel(): void {
    this.isVisualComplianceOpen = !this.isVisualComplianceOpen;
  }
}

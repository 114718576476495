interface SharedOptions {
  routeName: string;
  isModalDismissed: boolean;
  featureEnabled?: boolean;
}

export abstract class BaseLoginModalDefinition<T> {
  static identifier?: string;
  static feature?: string;

  protected options: T & SharedOptions;

  component?: string;

  get configuration(): Partial<T> & SharedOptions {
    return this.options ?? {};
  }

  constructor(options: T & SharedOptions) {
    this.options = options;

    const identifier = (<typeof BaseLoginModalDefinition>this.constructor).identifier;
    this.component = this.component ?? identifier;
  }
}

export abstract class OpenableModal {
  active!: boolean;
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';

import type { PrinterConnectionsRouteModel } from '../route';

interface ShowPrinterConnectionParams {
  printer_connection_id: string;
}

export default class VisitorsDevicesPrintersConnectionsShow extends Route {
  @service declare router: RouterService;
  @service declare store: Store;

  async model(params: ShowPrinterConnectionParams): Promise<PrinterConnectionsRouteModel> {
    const model = this.modelFor('visitors.devices.printers.connections') as PrinterConnectionsRouteModel;
    const { query } = model;

    model.printerConnections = await this.store.query('printer-connection', query);

    const printerConnection = model.printerConnections.findBy('id', params.printer_connection_id);

    if (!printerConnection?.id) {
      void this.router.transitionTo('visitors.devices.printers.connections.index');
    }

    return { ...model, printerConnection };
  }
}

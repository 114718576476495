// https://github.com/helpers/handlebars-helpers/blob/master/lib/string.js#L596
// the titilize function provided by hbs does not remove the - in the string
import { helper } from '@ember/component/helper';

export function titleizeString(params /*, hash*/) {
  var [value] = params;

  var title = value.replace(/[- _]+/g, ' ');
  var words = title.split(' ');
  var len = words.length;
  var res = [];
  var i = 0;
  while (len--) {
    var word = words[i++];
    res.push(word.charAt(0).toUpperCase() + word.slice(1));
  }
  return res.join(' ');
}

export default helper(titleizeString);

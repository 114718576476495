import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import VisitorsDevicesRoute from 'garaje/pods/visitors/devices/route';

export default class VirtualFrontDeskDevicesRoute extends VisitorsDevicesRoute {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  controllerName = 'visitors/devices';
  templateName = 'visitors/devices';

  beforeModel(): void {
    if (this.abilities.cannot('view location settings for virtual-front-desk')) {
      void this.router.transitionTo('virtual-front-desk');
      return;
    }
  }
}

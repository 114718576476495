/* eslint-disable ember/no-computed-properties-in-native-classes */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { action, computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';

/**
 * @param {Model<Company>}            company
 * @param {Array}                     locations
 * @param {Array}                     filterOptions
 * @param {Array}                     directoryGroups
 * @param {Function}                  showFlash
 * @param {Function}                  hideFlash
 * @param {Function}                  handleFailedQuery
 * @param {Function}                  addFilter
 * @param {Function}                  removeFilter
 */
export default class GoogleApps extends Component {
  @service googleSyncManager;

  @alias('company.googleApp') integrationData;
  @alias('integrationData.enabled') enabled;
  @alias('integrationData.domain') domain;
  @alias('company.employeesLastSyncAt') lastSyncAt;
  @alias('company.syncedSince') fromNow;
  @alias('integrationData.connectedBy') connectedBy;
  @alias('integrationData.errorMessage') syncErrorMessage;

  filter = {};

  @computed('company.employeesSyncState')
  get isInvalid() {
    const state = get(this, 'company.employeesSyncState');
    return ['errored', 'unauthorized'].includes(state);
  }

  @computed('integrationData.connected')
  get connected() {
    if (Object.keys(get(this, 'integrationData')).length !== 1) {
      return get(this, 'integrationData.connected');
    } else {
      return true;
    }
  }

  // eslint-disable-next-line ember/classic-decorator-hooks
  init() {
    super.init(...arguments);
    this._setDisplayFilter();
  }

  _setDisplayFilter() {
    set(this, 'displayFilter', get(this, 'filter.type'));

    const integration = get(this, 'integrationData');

    set(this, 'filter', get(this, 'googleSyncManager').loadFilter(integration));
  }

  @action
  setFilter(filter, options, callback) {
    const promise = get(this, 'googleSyncManager').setFilter(get(this, 'company'), filter, options);

    if (callback) {
      callback(promise);
    }
  }

  @action
  sync() {
    return get(this, 'googleSyncManager').sync();
  }
}

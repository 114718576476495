import Model, { belongsTo, attr } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('integration')
class SalesforceIntegration extends Model {
  @belongsTo('company', { async: true }) company;
  @attr('string') salesforceUserId;
  @attr('number') salesforceAccessGrantId;
}

export default SalesforceIntegration;

import ApplicationSerializer from './application';

export default class GlobalSignInFieldActionSerializer extends ApplicationSerializer {
  attrs = {
    operation: { serialize: false },
  };
  modelNameFromPayloadKey(key) {
    if (key === 'sign-in-field-action-rule-groups') {
      return 'global-sign-in-field-action-rule-group';
    } else if (key === 'sign-in-fields') {
      return 'global-sign-in-field';
    } else if (key === 'sign-in-field-actions-contacts') {
      return 'global-sign-in-field-actions-contact';
    } else if (key === 'sign-in-field-actions') {
      return 'global-sign-in-field-action';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }

  payloadKeyFromModelName(modelName) {
    if (modelName === 'global-sign-in-field-action-rule-group') {
      return 'sign-in-field-action-rule-groups';
    } else if (modelName === 'global-sign-in-field') {
      return 'sign-in-fields';
    } else if (modelName === 'global-sign-in-field-action') {
      return 'sign-in-field-actions';
    } else {
      return super.payloadKeyFromModelName(...arguments);
    }
  }
}

import { c as computeFn } from '../helper-compute-36ed98e8.js';
import BaseHelper from './-base.js';
import '@ember/utils';
import '@ember/runloop';
import '@ember/component/helper';
import '@ember/object';
import '@ember/service';

var momentTo = BaseHelper.extend({
  compute: computeFn(function ([datetime, ...params], {
    hideAffix,
    locale,
    timeZone
  }) {
    this._super(...arguments);

    const moment = this.moment;
    return this.morphMoment(moment.moment(datetime), {
      locale,
      timeZone
    }).to(...params, hideAffix);
  })
});

export { momentTo as default };

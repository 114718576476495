import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type FeatureFlagsService from 'garaje/services/feature-flags';

export default class PropertyAppsRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare router: RouterService;

  beforeModel(): void {
    if (!this.abilities.can('manage apps for properties')) {
      void this.router.transitionTo('unauthorized');
    } else {
      void this.router.replaceWith('apps');
    }
  }
}

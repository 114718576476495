import V2Adapter from './v2';

export default class extends V2Adapter {
  // Customized to allow for `store.queryRecord('user', { id: 'me' })` to be
  // used when fetching the current user.
  //
  // Using `store.findRecord('me')` is not good because the resource returned
  // from the server has a different `id`, resulting in multiple records being
  // pushed into the store and us having to manually remove one of them.
  urlForQueryRecord(query, modelName) {
    const url = super.urlForQueryRecord(...arguments);

    if (modelName === 'user' && query.id === 'me') {
      delete query.id;
      return `${url}/me`;
    } else {
      return url;
    }
  }

  urlForUpdateRecord(id, modelName, snapshot) {
    const { namespace } = this;

    // Updating user photo only supported on V3 user endpoint
    if (snapshot.changedAttributes().userPhoto) {
      this.namespace = 'a/visitors/api/v3';
    }

    const url = super.urlForUpdateRecord(...arguments);

    this.namespace = namespace;

    return url;
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

const CSS_MARGIN = 20; // match this exactly to what we use in the .leaflet-popup.left / .leaflet-popup.right css

/**
 * DeskMarker is a HOC that wraps a <LayerMarker />
 *
 * @param {Class<Desk>} desk                  A desk
 * @param {Object} icon                       An ember-leaflet {{div-icon}}
 * @param {Boolean} isAnyDeskAlreadySelected  True if any marker on the map has the popup open
 * @param {Boolean} canHover                  If we should listen to or ignore hover effects
 */
export default class DeskMarker extends Component {
  /**
   * @type {Boolean} A popup should default to the right, unless it would be off the map
   */
  @tracked popupToRight = true;

  /**
   * @type {Boolean} Whether the popup should remain open with a delete button
   */
  @tracked isPermanent = false;

  /**
   * @type {Boolean} When the marker is being repositioned
   */
  @tracked isDragging = false;

  hoverPopupVisible = false;

  get tooltipClassname() {
    return `tip-for-${this.args.desk.id}`;
  }

  get iconClassname() {
    return this.args.desk.id && `icon-for-${this.args.desk.id}`;
  }

  get canUpdate() {
    return !this.isDragging && !this.isPermanent && !this.args.isAnyDeskAlreadySelected;
  }

  /**
   * @param {Element} el  A leaflet popup
   */
  @action
  handlePopupAdded(el) {
    const popup = el.popup._contentNode.getBoundingClientRect();
    const leafletContainer = document.querySelector('.leaflet-container').getBoundingClientRect();
    const icon = document
      .querySelector(`.${this.iconClassname ?? this.args.desk.iconClassname}`)
      .getBoundingClientRect();

    // NOTE: `this.iconClassname` is set only for desks with `id` value, so we don't have `icon-for-null` class added for new desks
    // because `icon` would have values only of the first added desk, and not get updated.
    // Desks contain `iconClassname` property that reflects selected desk (for new desks it's `selected-disabled-desk-icon`)

    if (icon.left + icon.width + popup.width + CSS_MARGIN > leafletContainer.left + leafletContainer.width) {
      this.popupToRight = false;

      // NOTE: There seems to be an issue with ember leaflet, where you can set the `className` on the initial
      // popup render but when we determine direction and reset it, the changes are not applied to the element
      // This does not re-render in the template: `className={{if dm.popupToRight "right" "left"}}`
      // We can get around this by modifying the element directly.
      el.popup._container.classList.remove('right');
      el.popup._container.classList.add('left');
    } else {
      el.popup._container.classList.add('right');
      el.popup._container.classList.remove('left');
    }

    if (popup.top < leafletContainer.top) {
      el.popup._container.classList.add('bottom');
      el.popup._container.classList.remove('top');
    }

    if (popup.bottom > leafletContainer.bottom) {
      el.popup._container.classList.remove('bottom');
      el.popup._container.classList.add('top');
    }
  }

  @action
  handleMouseOver(ev) {
    if (this.canUpdate && this.args.canHover) {
      ev.target.openPopup();
      this.hoverPopupVisible = true;
    }
  }

  /**
   * The drag / hover functionality is different for admin desks editing compared to
   * employee-scheduling. Forcing the popup closed when we do not have drag capabilities
   * causes unnecessary flickering. If this event is supressed, popups are permanently shown
   * until we have a new event that modifies the state
   */
  @action
  handleMouseOut(ev) {
    if (this.canUpdate && this.args.canHover && ev.target.options?.draggable) {
      ev.target.closePopup();
      this.hoverPopupVisible = false;
    }
  }

  /**
   * We can simply use the default leaflet popup settings when we don't have unique hover effects
   */
  @action
  handleClick(ev) {
    if (this.args.canHover) {
      this.isPermanent = true;
      //Prevents leaflet js bug
      if (this.hoverPopupVisible) {
        ev.target.openPopup();
      }
      ev.target.addEventListener('popupclose', () => {
        this.isPermanent = false;
      });
    }
  }

  @action
  handleDragStart(ev) {
    this.isDragging = true;
    ev.target.openPopup();
  }

  @action
  handleDragEnd() {
    this.isDragging = false;
    this.isPermanent = false;
  }

  @action
  setIsPermanent(bool) {
    this.isPermanent = bool;
  }
}

import ApplicationSerializer from './application';

export default class EmployeeSerializer extends ApplicationSerializer {
  attrs = {
    createdAt: {
      serialize: false,
    },
    user: {
      serialize: false,
    },
    profilePictureUrl: {
      serialize: false,
    },
    hideFromEmployeeSchedule: {
      serialize: false,
    },
  };
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { getAnnouncementQueryParams } from 'garaje/helpers/communications/message-log/list/announcement-queries';
import type { AnnoucementsListModel } from 'garaje/helpers/communications/message-log/list/route-base';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type AnnouncementModel from 'garaje/models/announcement';
import type GroupModel from 'garaje/models/group';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import CommunicationsMessageLogListControllerBase from 'garaje/pods/communications/message-log/list/controller';
import type CurrentLocationService from 'garaje/services/current-location';

const GLOBAL_MESSAGE_LOG_HEADERS = ['Sent at', 'Message', 'Location', 'Recipients'] as const;
export default class CommunicationsMessageLogListController extends CommunicationsMessageLogListControllerBase {
  declare model: AnnoucementsListModel;
  @service declare currentLocation: CurrentLocationService;
  @tracked globalMessageLogHeaders = GLOBAL_MESSAGE_LOG_HEADERS;
  @tracked totalCount: number = 0;

  @action
  navigateToSendMessagePage(): void {
    void this.router.transitionTo('location-overview.communications.message-log.new');
  }

  get availableLocations(): SkinnyLocationModel[] {
    return this.store.peekAll('skinny-location').toArray();
  }

  get groups(): GroupModel[] {
    return this.store.peekAll('group').toArray();
  }

  get selectedLocationIdsString(): string {
    return this.selectedLocationIds.join(',');
  }

  refreshData = task({ drop: true }, async () => {
    const queryParams = getAnnouncementQueryParams(this.selectedLocationIds, this.startDate, this.endDate);
    const announcements = (await this.store.query(
      'announcement',
      queryParams,
    )) as PaginatedRecordArray<AnnouncementModel>;
    this.totalCount = announcements?.meta?.total || 0;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.messageLogEntries = await Promise.all(
      announcements.map((announcement) => this.createMessageLogEntry(announcement)),
    );
  });

  @action
  async updateLocationsFilter(options: SkinnyLocationModel[]): Promise<void> {
    // already selected ids
    let ids = this.selectedLocationIds;

    // add an id of a clicked location if it's not already selected
    options.forEach(function (option) {
      const optionId = option.id;
      ids = ids.includes(optionId)
        ? ids.filter(function (id) {
            return id !== optionId;
          })
        : ids.concat(optionId);
    });

    this.selectedLocationIds = ids;

    await this.refreshData.perform();
  }
}

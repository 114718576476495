import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { service } from '@ember/service';

/**
 * @param {Boolean}             canChangeStatus
 * @param {Function<Task>}      changeDeliveryAreaTask
 * @param {Function<Task>}      deleteTask
 * @param {Number}              deliveryAreaCount
 * @param {Function<Task>}      markAsPickedUpTask
 * @param {Function<Task>}      resendNotificationsTask
 * @param {Array<Delivery>}     selectedDeliveries
 */
export default class DeliveryListBulkActions extends Component {
  @service abilities;

  get moreActions() {
    const actions = [];
    const canBulkChangeDeliveryArea = this.abilities.can('bulk change delivery area in delivery-log');
    const hasMultipleDeliveryAreas = this.args.deliveryAreaCount > 1;

    if (canBulkChangeDeliveryArea && this.allPending && hasMultipleDeliveryAreas) {
      actions.push({
        label: 'Move',
        task: this.args.changeDeliveryAreaTask,
      });
    }
    if (this.abilities.can('bulk delete deliveries in delivery-log')) {
      actions.push({
        label: 'Delete',
        task: this.args.deleteTask,
      });
    }
    return actions;
  }

  get allPending() {
    return this.args.selectedDeliveries.every((delivery) => !get(delivery, 'acknowledgedAt'));
  }

  @action
  performTask(option) {
    option.task.perform(this.args.selectedDeliveries);
  }
}

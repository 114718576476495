import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import urlBuilder from 'garaje/utils/url-builder';

/**
 * TODO
 * These should probably live on the Chargebee plan. When we migrate to fully fetching plans from Chargebee instead
 * of envoy-web, we should remove these.
 */
const MINIMUM_ROOMS_NEEDED_FOR_DISCOUNT = 11;
const ROOMS_BASIC_LIMIT = 10;

export default class BillingRoute extends Route {
  @service abilities;
  @service ajax;
  @service billing;
  @service featureConfig;
  @service featureFlags;
  @service locations;
  @service router;
  @service state;
  @service store;

  title = 'Billing · Envoy';

  beforeModel() {
    if (
      this.abilities.cannot('visit billing') ||
      (this.state.billingCompany.resellerPartner && !this.featureFlags.isEnabled('growth_billing_page_access_override'))
    ) {
      this.router.transitionTo('unauthorized');
    }

    if (this.featureFlags.isEnabled('locationBilling')) {
      this.router.replaceWith('location-billing');
    }
    return this.locations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
  }

  async model() {
    let deliveryLimit;

    if (this.featureConfig.isEnabled('deliveries')) {
      deliveryLimit = this.store.queryRecord('delivery-limit', {});
    }

    const { currentCompany, roomsSubscription } = this.state;
    let activeRoomsQuantity = 0;
    let hasSufficientRoomsForDiscount = false;

    if (roomsSubscription && this.featureConfig.isEnabled('rooms')) {
      ({ activeRoomsQuantity, hasSufficientRoomsForDiscount } = await this.fetchActiveRoomsCount
        .perform(currentCompany.id)
        .catch(throwUnlessTaskDidCancel));
    }

    const paymentSources = await this.billing.loadPaymentSources.perform();
    const paymentSource = paymentSources.firstObject;
    return {
      activeRoomsQuantity,
      deliveryLimit,
      exceedsRoomsBasicLimit: activeRoomsQuantity > ROOMS_BASIC_LIMIT,
      hasSufficientRoomsForDiscount,
      paymentSource,
      paymentSources,
    };
  }

  @dropTask({
    cancelOn: 'deactivate',
  })
  *fetchActiveRoomsCount(companyId) {
    const url = urlBuilder.v2.roomsActiveCountUrl(companyId);
    const { num_active_rooms, num_active_rooms_per_location } = yield this.ajax.request(url);
    const greatestNumberOfRoomsAtALocation =
      Object.values(num_active_rooms_per_location).sort((a, b) => a - b).lastObject || 0;
    const hasSufficientRoomsForDiscount = greatestNumberOfRoomsAtALocation >= MINIMUM_ROOMS_NEEDED_FOR_DISCOUNT;

    return {
      activeRoomsQuantity: num_active_rooms || 0,
      hasSufficientRoomsForDiscount,
    };
  }

  @action
  refreshModel() {
    this.refresh();
  }
}

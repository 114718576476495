import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import { isPresent } from '@ember/utils';
import { action } from '@ember/object';

const squashEvent = function squashEvent(event) {
  event.preventDefault();
  event.stopPropagation();
};

/**
 *
 * @param {*} Class
 * @returns {*}
 */
function droppableUpload(Class) {
  return class droppableUpload extends Class {
    @tracked isBeingDragged = false;
    @tracked files;

    _disableDragAndDrop = false;

    @action
    handleDragEnter(e) {
      if (this._disableDragAndDrop) return;
      return squashEvent(e);
    }

    @action
    handleDrop(e) {
      if (this._disableDragAndDrop) return;
      squashEvent(e);
      const dt = e.dataTransfer;
      if (isPresent(dt.types) && this._isBeingDragged(dt.types)) {
        this.isBeingDragged = false;
        this.files = dt.files;
      }
    }

    @action
    handleDragOver(e) {
      if (this._disableDragAndDrop) return;
      squashEvent(e);
      const dt = e.dataTransfer;
      if (isPresent(dt.types) && this._isBeingDragged(dt.types)) {
        this.isBeingDragged = true;
      } else {
        dt.dropEffect = 'none';
      }
    }

    @action
    handleDragLeave(e) {
      if (this._disableDragAndDrop) return;
      squashEvent(e);
      this.isBeingDragged = false;
    }

    _isBeingDragged(types) {
      if (types.indexOf) {
        return types.indexOf('Files') !== -1;
      } else if (types.contains) {
        // DOMStringList IE
        return types.contains('Files');
      } else {
        return A(types).includes('application/x-moz-file');
      }
    }
  };
}

export default droppableUpload;

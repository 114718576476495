import { set } from '@ember/object';
import { typeOf } from '@ember/utils';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type DS from 'ember-data';
import InfinityModel from 'ember-infinity/lib/infinity-model';
import { objectAssign } from 'ember-infinity/utils';

export type PaginatedRecordArray<T> = DS.RecordArray<T> & { meta: { total: number; offset?: number; limit?: number } };

/**
 * This custom infinity model works with v3's offset pagination.
 */
export default class ExtendedInfinityModel extends InfinityModel {
  buildParams(...args: Parameters<InfinityModel['buildParams']>): Record<string, unknown> {
    const [increment] = args;
    const pageParams = super.buildParams(...args);
    const { pageParam } = this;

    if (typeOf(pageParam) === 'string' && pageParam.indexOf('offset') > -1) {
      pageParams[pageParam] = (this.currentPage + increment) * this.perPage;
    }

    return objectAssign(pageParams, this.extraParams);
  }

  afterInfinityModel<T>(arrayProxy: PaginatedRecordArray<T>): void {
    // calculate offset and limit based off in class properties if not returned from API
    const offset = arrayProxy.meta.offset ?? (this.currentPage + 1) * this.perPage;
    const limit = arrayProxy.meta.limit ?? this.perPage;

    set(this, 'canLoadMore', offset + limit < arrayProxy.meta.total);
  }
}

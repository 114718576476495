import Component from '@glimmer/component';
import { action } from '@ember/object';
import { bool, map } from 'macro-decorators';

/**
 * @param {Object}      locationQuantities
 * @param {Class}       initialSubscriptionPlan
 * @param {Class}       selectedPlan
 * @param {Function}    handleChange
 * @param {Function}    clearQuantities
 * @param {Function}    openSection
 * @param {String}      app
 * @param {String}      item
 * @param {Boolean}     multiple
 * @param {Boolean}     hasPaidSubscription
 */
export default class BillingQuantityEditorComponent extends Component {
  @bool('args.selectedPlan.isPaidSubscription') enforceMinimumQuantity;
  @map('args.locationQuantities', ({ quantity, id }) => {
    quantity, id;
  })
  allInputs;

  get maxTotalQuantity() {
    return this.args.selectedPlan.details.maximum ?? 999;
  }

  get totalRemaining() {
    const total = this.args.locationQuantities.map((lq) => lq.quantity);
    if (!total || !total.length) {
      return 0;
    }
    const used = total.reduce(function (a, b) {
      return parseInt(a, 10) + parseInt(b, 10);
    });
    const totalAvailable = this.maxTotalQuantity;
    return totalAvailable - used;
  }

  @action
  handleInputChangeEvent(locationId, locationName, minimum, e) {
    const { maxTotalQuantity, enforceMinimumQuantity } = this;
    let value = parseInt(e.target.value, 10);
    if (minimum && enforceMinimumQuantity && value < minimum) {
      value = minimum;
    }
    if (!maxTotalQuantity) {
      this.args.handleChange(locationId, locationName, value);
    } else {
      const allInputsButThis = this.args.locationQuantities.filter((lq) => lq.id != e.target.id);
      const total = allInputsButThis.map((lq) => lq.quantity);
      let used = 0;
      if (total && total.length) {
        used = total.reduce(function (a, b) {
          return parseInt(a, 10) + parseInt(b, 10);
        });
        const availableToThisInput = maxTotalQuantity - used;
        if (!availableToThisInput || availableToThisInput < 0) {
          e.target.value = 0;
        } else if (value > availableToThisInput) {
          e.target.value = availableToThisInput;
        }
        this.args.handleChange(locationId, locationName, e.target.value);
      } else {
        this.args.handleChange(locationId, locationName, value);
      }
    }
  }

  @action
  handleToggleChangeEvent(locationId, locationName, incomingQuantity) {
    let quantity = incomingQuantity;
    if (quantity > this.totalRemaining) {
      quantity = 0;
    }
    this.args.handleChange(locationId, locationName, quantity);
  }

  // TODO: Ensure we cant type a number larger than maxTotalQuantity or lower than minLocationQuantity
  @action
  validateNumberCharacter(e) {
    if (e.keyCode < 48 || e.keyCode > 57) {
      e.preventDefault();
      return;
    }
  }
}

import { action } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type EntryModel from 'garaje/models/entry';
import type GroupModel from 'garaje/models/group';
import type CurrentLocationService from 'garaje/services/current-location';
import type GlobalOverviewAccessService from 'garaje/services/global-overview-access';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import type { PromiseArray, RecordArray } from 'garaje/utils/type-utils';

import type LocationOverviewVisitorLogController from './controller';

const SORT_COL_TO_FIELD_NAME = {
  Host: 'host',
  'Signed in': 'finalized_at',
  Name: 'full_name',
  Location: 'locations.name',
};

const ORDER_DESC_VALS = ['Z-A', 'Most to least recent'];
const PAGE_SIZE = 30;

interface LocationOverviewVisitorLogRouteModel {
  entries: PromiseArray<EntryModel, RecordArray<EntryModel>>;
  params: {
    sort?: string;
    filter?: {
      query?: string;
      startDate?: string;
      endDate?: string;
      location?: string;
      status?: string;
    };
    page?: {
      offset: number;
      size: number;
      limit: number;
    };
  };
  groups: RecordArray<GroupModel> | GroupModel[];
}

interface LocationOverviewVisitorLogRouteParams {
  pageNumber: number;
  pageSize: number;
  sortBy: keyof typeof SORT_COL_TO_FIELD_NAME;
  sortDirection: string;
  query: string;
  locationIds: string;
  signedOut: string;
  startDate: string;
  endDate: string;
}

export default class LocationOverviewVisitorLogRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare currentLocation: CurrentLocationService;
  @service declare skinnyLocations: SkinnyLocationsService;
  @service declare router: RouterService;
  @service declare store: StoreService;
  @service declare globalOverviewAccess: GlobalOverviewAccessService;

  queryParams = {
    pageNumber: {
      refreshModel: true,
    },
    pageSize: {
      refreshModel: true,
    },
    sortBy: {
      refreshModel: true,
    },
    sortDirection: {
      refreshModel: true,
    },
    query: {
      refreshModel: true,
    },
    locationIds: {
      refreshModel: true,
    },
    signedOut: {
      refreshModel: true,
    },
    startDate: {
      refreshModel: true,
    },
    endDate: {
      refreshModel: true,
    },
  };

  beforeModel(): Promise<unknown> {
    if (!this.globalOverviewAccess.canVisitVisitorLog) {
      this.router.transitionTo('unauthorized');
    }
    return this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
  }

  model({
    endDate,
    locationIds,
    pageNumber,
    query,
    signedOut,
    sortBy,
    sortDirection,
    startDate,
  }: LocationOverviewVisitorLogRouteParams): LocationOverviewVisitorLogRouteModel {
    const params: LocationOverviewVisitorLogRouteModel['params'] = {};
    const offset = PAGE_SIZE * pageNumber - PAGE_SIZE;
    const store = this.store;
    const orderDescending = ORDER_DESC_VALS.includes(sortDirection);
    const sortOrder = orderDescending ? '-' : '';
    const sortProp = SORT_COL_TO_FIELD_NAME[sortBy];

    if (sortBy) {
      params.sort = `${sortOrder}${sortProp}`;
    }

    params.filter = {};

    if (query) {
      params.filter.query = query;
    }

    if (startDate) {
      params.filter.startDate = startDate;
    }

    if (endDate) {
      params.filter.endDate = endDate;
    }

    params.page = { offset, size: PAGE_SIZE, limit: PAGE_SIZE };

    if (locationIds) {
      params.filter.location = locationIds;
    }

    if (signedOut) {
      params.filter.status = signedOut;
    }
    let groups: RecordArray<GroupModel> | GroupModel[] = [];
    if (this.abilities.can('view locations-grouping')) {
      // Groups have been already loaded by `skinnyLocations` service.
      groups = this.store.peekAll('group');
    }

    // performance tweak: we're specifically not returning an rsvp hash here so we can
    // respond quickly with a loading state in the template.
    return { entries: store.query('entry', params), params, groups };
  }

  @action
  loading(transition: Transition): void {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const controller = <LocationOverviewVisitorLogController>this.controllerFor('location-overview/visitor-log');
    controller.loadingData = true;
    void transition.promise.finally(function () {
      controller.loadingData = false;
    });
  }
}

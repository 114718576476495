type CustomTranslations = { [key: string]: { [key: string]: string } };

interface SerializedDropdownOption {
  id?: string;
  'custom-translations': CustomTranslations;
  position: number;
  value: string;
}

export default class DropdownOption {
  declare id: string | null;
  declare value: string;
  declare position: number;

  declare customTranslations: { [key: string]: { [key: string]: string } };

  constructor(attrs: Record<string, unknown>) {
    this.id = (attrs['id'] as number | undefined)?.toString() || null;
    this.position = (attrs['position'] as number) ?? null;
    this.value = (attrs['value'] as string) ?? '';
    this.customTranslations = (attrs['custom-translations'] as CustomTranslations) ?? {
      value: {},
    };
  }

  serialize(): SerializedDropdownOption {
    const serialized: SerializedDropdownOption = {
      'custom-translations': this.customTranslations,
      position: this.position,
      value: this.value,
    };
    if (this.id) {
      serialized['id'] = this.id;
    }
    return serialized;
  }
}

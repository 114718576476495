import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
export default class SettingsPanelExamples extends Component {
  @tracked inputValue = '';
  @tracked isSaved = true;
  @tracked isOpen = false;
  @tracked isEnabled = false;
  @tracked isEditing = false;
  @tracked isPreviewing = false;
  @tracked basicSettingsToggle = false;
  @tracked expandableSettingsPanelExpanded = false;
  @tracked expandableSettingsPanelEnabled = false;
  @tracked isToggleEnabled = false; // non expandable example

  _toEditing() {
    this.isEditing = true;
    this.isPreviewing = false;
  }

  _toPreviewing() {
    this.isPreviewing = true;
    this.isEditing = false;
  }

  @action
  enable() {
    this.isEnabled = true;
    this.isOpen = true;
    this._toEditing();
  }

  @action
  edit() {
    this.isOpen = true;
    this._toEditing();
  }

  @action
  disable() {
    this.inputValue = '';
    this.isSaved = true;
    this.isEnabled = false;
    this.isOpen = false;
    this._toEditing();
  }

  @action
  cancel() {
    this.isOpen = false;
    this._toEditing();
  }

  @action
  save() {
    this.isOpen = false;
    this.isEnabled = true;
    this.isSaved = true;
    this._toEditing();
  }

  @action
  showEditing() {
    this._toEditing();
  }

  @action
  showPreview() {
    this._toPreviewing();
  }

  @action
  updateToggle(value) {
    this.isToggleEnabled = value;
  }
}

/* eslint-disable ember/no-component-lifecycle-hooks */
import LabeledInput from 'garaje/pods/components/labeled-input/component';
import { get, set } from '@ember/object';
import { once, run } from '@ember/runloop';
import $ from 'jquery';
import layout from 'garaje/pods/components/labeled-input/template';
import intlTelInput from 'intl-tel-input';

export default LabeledInput.extend({
  layout,

  type: 'tel',
  phoneNumber: null,
  usingIntlTelInput: false,
  intlInstance: null,

  didReceiveAttrs() {
    this._super(...arguments);

    const phoneNumber = get(this, 'phoneNumber');

    if (phoneNumber !== get(this, 'userSetPhoneNumber')) {
      this._destroyIntlTelInput();
      set(this, 'value', phoneNumber);
      set(this, 'userSetPhoneNumber', phoneNumber);
      once(this, this._initIntlTelInput);
    }
  },

  didInsertElement() {
    this._super(...arguments);
    // This used to be here, for an unknown purpose,
    // so keeping here in case we ran into issues
    // see https://github.com/envoy/garaje/pull/965
    // Ember.run.next(this, function() {
    // if (get(this,'isDestroyed')) {
    //  return;
    // }
    const parent = $(`#${this.elementId} .intl-tel-input`);

    $(`#${this.elementId} input`)
      .on('focus', function () {
        parent.addClass('is-focused');
      })
      .on('blur', function () {
        parent.removeClass('is-focused');
      })
      .on('change keyup countrychange', () => {
        once(this, this.updateInput);
      });
    this._initIntlTelInput();
  },

  _initIntlTelInput() {
    const phoneNumber = get(this, 'phoneNumber');
    if ($(`#${this.elementId} input`) && (!phoneNumber || phoneNumber.indexOf('+') !== -1)) {
      const input = document.querySelector(`#${this.elementId} input`);
      const intlInstance = get(this, 'intlInstance');
      if (intlInstance) {
        this._destroyIntlTelInput();
      }

      const useNationalMode = phoneNumber && phoneNumber.startsWith('+1');

      const iti = intlTelInput(input, {
        initialCountry: 'us',
        nationalMode: useNationalMode,
        preferredCountries: ['us'],
        ipinfoToken: '36ebffded95f73',
        autoPlaceholder: false,
        containerClass: `intl-tel-input ${this.noMargin && 'no-margin'}`,
        countrySearch: false,
      });

      set(this, 'usingIntlTelInput', true);
      set(this, 'intlInstance', iti);
    }
  },

  _destroyIntlTelInput() {
    set(this, 'usingIntlTelInput', false);
    if ($(`#${this.elementId} input`)) {
      $(`#${this.elementId} input`).off('focus, blur');
      const intlInstance = get(this, 'intlInstance');
      if (intlInstance) {
        intlInstance.destroy();
        set(this, 'intlInstance', null);
      }
    }
  },

  updateInput() {
    let value;
    const input = document.querySelector(`#${this.elementId} input`);
    const inputValue = input.value;
    if (get(this, 'usingIntlTelInput')) {
      const intlInstance = get(this, 'intlInstance');
      value = intlInstance.getNumber();
    }
    if (!value || value === '') {
      value = inputValue;
    }
    // We store the user inputted phone number so we can
    // compare against the data coming from upstream, and
    // tell whether the user inputted the data or it was set
    // from parent
    run(() => {
      set(this, 'userSetPhoneNumber', value);
      set(this, 'phoneNumber', value);
    });
    return true;
  },
  willDestroy() {
    this._super(...arguments);
    this._destroyIntlTelInput();
  },
});

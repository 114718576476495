import Component from '@glimmer/component';
import { A } from '@ember/array';
import { action, get } from '@ember/object';
import { alias } from 'macro-decorators';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { entryApprovalMessage } from 'garaje/helpers/entry-approval-message';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { fetchCapacity } from 'garaje/utils/locations-capacity';

/**
 * @param {Array}   blocklistContacts
 * @param {String}  context - The context in which the component is being used ("location" or "property").
 * @param {Class}   entry
 * @param {Array}   idScanContacts
 */
export default class EntryFeedItemFlagged extends Component {
  @service abilities;
  @service currentLocation;
  @service flashMessages;
  @service metrics;
  @service store;

  @alias('args.entry.approvalStatus.hasPendingIdScan') hasPendingIdScan;

  get canReview() {
    return this.abilities.can('review entry-approval', {
      context: this.context,
      report: this.args.entry.approvalStatus.failedReport,
    });
  }

  get canReviewOrReadBlocklistMatch() {
    return this.abilities.can('review or read blocklist match in security', {
      context: this.context,
      didFailBlocklistCheck: this.args.entry.approvalStatus?.didFailBlocklistCheck,
      report: this.args.entry.approvalStatus.failedReport,
    });
  }

  get colspan() {
    let cols = 1;
    const dashboardFields = get(this.currentLocation, 'dashboardFields');

    if (dashboardFields) {
      cols += dashboardFields.length;
    }

    return cols;
  }

  get contactedNames() {
    let contacts = [];
    if (this.args.entry.approvalStatus.didFailBlocklistCheck && this.args.blocklistContacts) {
      contacts = [...contacts, ...this.args.blocklistContacts.map((contact) => contact.fullName)];
    }
    if (
      this.args.entry.approvalStatus.didFailIdScanningCheck &&
      this.args.idScanContacts.map((contact) => contact.fullName)
    ) {
      contacts = [...contacts, ...this.args.idScanContacts.map((contact) => contact.fullName)];
    }
    return A(contacts).uniq();
  }

  get context() {
    return this.args.context ?? 'location';
  }

  get sourcesForApprovalReviewMetrics() {
    return this.args.entry.approvalStatus.failedReport.reduce(
      (sources, report) => ({ ...sources, [report.source]: true }),
      {},
    );
  }

  @task
  *approveEntryTask(entry) {
    try {
      yield entry.approveEntry();
      yield get(entry, 'flow.badge'); // load async relationship for entryApprovalMessage helper dependency
      this.flashMessages.showAndHideFlash('success', 'Access approved', entryApprovalMessage(entry));
      this.metrics.trackEvent('Dashboard Entry - Reviewed', {
        action: 'approve',
        entry_id: entry.id,
        source: 'Visitor Log',
        ...this.sourcesForApprovalReviewMetrics,
      });
      yield entry.reload();
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', 'Error approving entry', parseErrorForDisplay(error));
    } finally {
      const currentLocation = this.currentLocation.content;
      if (currentLocation.capacityLimitEnabled) {
        yield fetchCapacity(this.store, currentLocation, entry.signedInAt);
      }
    }
  }

  @task
  *denyEntryTask(entry) {
    try {
      yield entry.denyEntry();
      this.flashMessages.showAndHideFlash('warning', 'Entry denied');
      yield entry.reload();

      this.metrics.trackEvent('Dashboard Entry - Reviewed', {
        action: 'deny',
        entry_id: entry.id,
        source: 'Visitor Log',
        ...this.sourcesForApprovalReviewMetrics,
      });
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', 'Error denying entry', parseErrorForDisplay(error));
    }
  }

  @action
  trackClickEntryDetail(entry_id) {
    this.metrics.trackEvent('Entry Details Clicked', { entry_id });
  }
}

import { underscore } from '@ember/string';
import { A } from '@ember/array';
import { isEmpty } from '@ember/utils';

/**
 * Builds a uniform resource name based on a hash of string values
 *
 * @param {object} hash hash of string values
 * @param {string} namespace namespace
 * @returns {string} Uniform resource name
 */
export function buildUrn(hash = {}, namespace = '') {
  const identifier = A(Object.entries(hash))
    .map(([key, value]) => [underscore(key).toUpperCase(), value])
    .sortBy('0')
    .map(([key, value]) => `${key}:${value}`)
    .join('::');

  return isEmpty(namespace) ? identifier : `${namespace}|${identifier}`;
}

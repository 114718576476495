import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type { Room } from 'garaje/graphql/generated/roomba-types';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import type RoombaMetricsService from 'garaje/services/roomba-metrics';
import { APP } from 'garaje/utils/enums';

interface RoomDisplayNameInputParams {
  room: Room;
  shouldAutofocusInput: boolean;
  onDisplayNameChanged: () => Promise<void>;
}

export default class RoomDisplayNameInput extends Component<RoomDisplayNameInputParams> {
  @service declare roombaGraphql: RoombaGraphqlService;
  @service declare roombaMetrics: RoombaMetricsService;

  @tracked inputValue = '';
  @tracked showErrorOnEdit = false;
  @tracked showSuccessOnEdit = false;

  @action
  focusInput(element: HTMLInputElement): void {
    if (!this.args.shouldAutofocusInput || !element) {
      return;
    }
    element?.focus();
  }

  @action
  async onSubmitDisplayName(event: { target: HTMLInputElement }): Promise<void> {
    const value = event.target && 'value' in event.target ? event.target['value'] : null;
    const isKeyUp = 'type' in event && event.type == 'keyup';
    const isButtonClick = 'type' in event && event.type == 'click';

    if (isKeyUp && value) {
      this.inputValue = value;
    }

    if ('code' in event && event.code != 'Enter' && !isButtonClick) {
      return;
    }

    await this.editRoomDisplayNameTask.perform(this.inputValue);
  }

  get isDisplayNameChanged(): boolean {
    return this.args.room.name != this.args.room.displayName;
  }

  editRoomDisplayNameTask = task(this, { restartable: true }, async (displayName: string) => {
    this.showErrorOnEdit = false;
    this.showSuccessOnEdit = false;

    try {
      await this.roombaGraphql.updateRoomConfig({
        id: this.args.room.id,
        displayName,
      });

      this.showSuccessOnEdit = true;
    } catch (_error) {
      this.showErrorOnEdit = true;
    }

    this.roombaMetrics.trackEvent('room_display_name_changed', {
      roomDisplayName: displayName,
      room_id: this.args.room.id,
      product: APP.ROOMS,
      company_id: this.args.room.companyId,
    });

    await this.args.onDisplayNameChanged();
  });
}

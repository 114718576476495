import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { all, task } from 'ember-concurrency';

/**
 * @param {Array<User>}           idScanContacts
 * @param {Function}              onUpdateContacts
 * @param {Function}              saveTask
 * @param {Function}              searchUsers
 */
export default class EmailAlerts extends Component {
  @tracked hasChanges = false;
  @tracked newIdScanContacts = [];

  @action
  async onInsert() {
    const currentContacts = await this.args.idScanContacts;
    this.newIdScanContacts = currentContacts.toArray() ?? [];
  }

  @task
  *searchUsersTask(term) {
    const re = new RegExp(`.*${term}.*`, 'i');
    const userRoles = yield this.args.searchUsers(term);
    let users = yield all(userRoles.map((role) => get(role, 'user')));
    const currentContactsIds = this.newIdScanContacts.map((contact) => get(contact, 'id'));

    // deduplicate since roles could point to same user
    users = users
      .uniqBy('id')
      // remove users that are already selected
      .reject((user) => currentContactsIds.indexOf(user.id) > -1)
      .filter((user) => user.fullName.match(re));

    return users;
  }

  @action
  addIdScanContacts(users) {
    this.hasChanges = true;
    this.newIdScanContacts = users;
    this.args.onUpdateContacts(users);
  }

  async saveContacts() {
    this.hasChanges = false;
    await this.args.saveTask.perform();
  }
}

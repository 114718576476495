import ApplicationSerializer from './application';
import { typeOf, isPresent } from '@ember/utils';
import { dasherize, camelize } from '@ember/string';

export default class extends ApplicationSerializer {
  /**
   * The response from envoy-web does not include attributes
   * and ember-data will empty the model attributes
   * This is a workaround for that, calling `createRecord` with
   * `restoreAttributes` true in `adapterOptions` will place current attributes
   * in _attributes obj on the response, and we restore it here
   */
  normalize(typeClass, hash) {
    hash.attributes = hash.attributes ?? {};

    if (isPresent(hash._attributes)) {
      Object.keys(hash._attributes).forEach((attr) => {
        hash.attributes[dasherize(attr)] = this._camelizeUpdateAttributes(hash._attributes[attr]);
      });

      delete hash._attributes;
    }

    return super.normalize(...arguments);
  }

  // `Global setting batch has multiple hash attributes (`location`, `config`, `flow`),
  // so we need to manually build the JSONAPI payload
  serializeIntoHash(hash, type, snapshot, options) {
    const payload = this.serialize(snapshot, options);
    Object.keys(payload.data.attributes).forEach((attr) => {
      payload.data.attributes[attr] = this._dasherizeUpdateAttributes(payload.data.attributes[attr]);
    });

    // Change relationships to match envoy-web json resources
    const parent = payload.data.relationships.parent;
    if (parent.data.type === 'flows') {
      payload.data.relationships['parent-flow'] = parent;
      delete payload.data.relationships.parent;
      payload.data.relationships['child-flows'] = payload.data.relationships.children;
      delete payload.data.relationships.children;
    }
    Object.assign(hash, payload);
  }

  // We need to manually dasherize `updatedAttributes` because
  // `location`, `config`, `flow` attrs on `globalSettingBatch` are hashes
  _dasherizeUpdateAttributes(obj) {
    if (!obj || typeOf(obj) === 'array') {
      return obj;
    }
    const keys = Object.keys(obj);
    keys.forEach((key) => {
      const value = obj[key];
      const dasherizedKey = dasherize(key);
      obj[dasherizedKey] = value;
      delete obj[key];
      if (typeOf(obj[dasherizedKey]) === 'instance') {
        obj[dasherizedKey] = this._dasherizeUpdateAttributes(obj[dasherizedKey]);
      }
    });
    return obj;
  }

  _camelizeUpdateAttributes(obj) {
    if (!obj || typeOf(obj) === 'array') {
      return obj;
    }
    const keys = Object.keys(obj);
    keys.forEach((key) => {
      const value = obj[key];
      const camelizedKey = camelize(key);
      obj[camelizedKey] = value;
      delete obj[key];
      if (typeOf(obj[camelizedKey]) === 'instance') {
        obj[camelizedKey] = this._dasherizeUpdateAttributes(obj[camelizedKey]);
      }
    });
    return obj;
  }
}

import Model, { attr } from '@ember-data/model';

interface Contact {
  title: string;
  email: string;
}

class CompanyInfoModel extends Model {
  @attr('array', { defaultValue: () => [] }) declare contacts: Contact[];
}

export default CompanyInfoModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'company-info': CompanyInfoModel;
  }
}

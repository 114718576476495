import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';

export default class VirtualFrontDeskRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  title = 'Virtual Front Desk';

  beforeModel(): void {
    if (this.abilities.cannot('visit location pages for virtual-front-desk')) {
      void this.router.transitionTo('unauthorized');
      return;
    }
  }
}

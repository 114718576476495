import Model, { attr } from '@ember-data/model';

export default class IdScanFieldModel extends Model {
  @attr('string') displayName;

  get identifier() {
    return this.id;
  }

  save() {
    this.rollbackAttributes();

    return this;
  }
}

import ApplicationSerializer from './application';

export default class PaperDeviceSerializer extends ApplicationSerializer {
  attrs = {
    svgSrc: {
      serialize: false,
    },
    pngSrc: {
      serialize: false,
    },
    signInUrl: {
      serialize: false,
    },
    printerStatus: {
      serialize: false,
    },
    lastHeartbeatAt: {
      serialize: false,
    },
    createdAt: {
      serialize: false,
    },
    updatedAt: {
      serialize: false,
    },
  };
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'paper-device': PaperDeviceSerializer;
  }
}

import { momentOrMomentTimezone } from '../index.js';
import BaseHelper from './-base.js';
import '@embroider/macros';
import '@ember/runloop';
import '@ember/component/helper';
import '@ember/object';
import '@ember/service';

var now = BaseHelper.extend({
  compute() {
    this._super(...arguments);

    const momentService = this.moment;
    return momentService.moment(momentOrMomentTimezone.now());
  }

});

export { now as default };

import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_MANAGE_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN];
const CAN_REVIEW_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST];

export default class IdCheckAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;

  get canManage(): boolean {
    const { roleNames } = this.currentAdmin;
    const { vrSubscription } = this.state;
    return Boolean(vrSubscription?.canAccessIDCheck) && isPresent(_intersection(CAN_MANAGE_ROLES, roleNames));
  }

  get canReview(): boolean {
    const { roleNames } = this.currentAdmin;
    const { vrSubscription } = this.state;

    return Boolean(vrSubscription?.canAccessIDCheck) && isPresent(_intersection(CAN_REVIEW_ROLES, roleNames));
  }
}

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task, waitForProperty } from 'ember-concurrency';
import { action, set } from '@ember/object';

export default class TabChecklistComponent extends Component {
  @service router;
  @service flashMessages;
  @service statsig;

  currentStep = null;
  currentRoute = '';

  constructor() {
    super(...arguments);
    this.setCurrentRoute();
  }

  setCurrentRoute() {
    this.currentRoute = this.router.currentRoute['parent']['name'];
  }

  @action
  stepIconClass(checklistStep) {
    if (checklistStep.route === this.currentRoute) {
      set(this, 'currentStep', checklistStep);
      set(checklistStep, 'stepIconClass', 'steps-circle current');
    } else {
      set(checklistStep, 'stepIconClass', 'steps-circle');
    }
  }

  /**
   * reviewStepAndTransitionTask
   * @param {PersonalizedChecklist} checklistStep
   * @param {Event} evt
   */
  reviewStepAndTransitionTask = task({ drop: true }, async (checklistStep, evt) => {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }

    if (this.currentStep) set(this.currentStep, 'stepIconClass', 'steps-circle');
    set(this, 'currentStep', checklistStep);
    set(checklistStep, 'stepIconClass', 'steps-circle current');

    try {
      // Keep the loading spinner until we destroy the component or
      // until we finish the transition
      if ('flowId' in checklistStep) {
        this.router.transitionTo(checklistStep.route, checklistStep.flowId).then(() => {
          if (!this.isDestroyed && !this.isDestroying) {
            set(this, 'transitionDone', true);
          }
        });
        this.statsig.logEvent('customized-checklist', checklistStep.title, { flowId: checklistStep.flowId });
      } else {
        this.router.transitionTo(checklistStep.route).then(() => {
          if (!this.isDestroyed && !this.isDestroying) {
            set(this, 'transitionDone', true);
          }
        });
        this.statsig.logEvent('customized-checklist', checklistStep.title);
      }

      await waitForProperty(this, 'transitionDone');
    } catch (error) {
      this.flashMessages.showFlash('error', error);
    }
  });
}

import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class LocationOverviewOccupancyAnalyticsIndexRoute extends Route {
  title = 'Occupancy Global Analytics · Envoy';

  @service globalOverviewAccess;
  @service router;

  beforeModel() {
    if (!this.globalOverviewAccess.canVisitOccupancyAnalytics) {
      this.router.transitionTo('unauthorized');
    }
  }
}

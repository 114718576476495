import LocationFragment from 'garaje/graphql/fragments/roomba/LocationFragment';
import gql from 'graphql-tag';

export default gql`
  mutation UpdateRoomsLocationRoomResizerEnabledOverride($locationId: ID!, $roomResizerEnabledOverride: Boolean) {
    updateRoomsLocationRoomResizerEnabledOverride(
      locationId: $locationId
      roomResizerEnabledOverride: $roomResizerEnabledOverride
    ) {
      ...LocationFragment
    }
  }
  ${LocationFragment}
`;

import type ArrayProxy from '@ember/array/proxy';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type { Task } from 'ember-concurrency';
import type PrinterConnectionModel from 'garaje/models/printer-connection';
import type PrinterConnectionOptionModel from 'garaje/models/printer-connection-option';
import { reads } from 'macro-decorators';
import { localCopy } from 'tracked-toolbox';

type FetchPrinterConnectionOptionsTaskType = Task<ArrayProxy<PrinterConnectionOptionModel>, []>;

interface DevicesPrinterOptionsListArgs {
  ctaText?: string;
  printerConnection?: PrinterConnectionModel | null;
  fetchPrinterConnectionOptionsTask?: FetchPrinterConnectionOptionsTaskType;
  onSubmit(connection: PrinterConnectionModel, selectedOptions: Array<PrinterConnectionOptionModel>): Promise<void>;
}

export default class DevicesPrinterOptionsList extends Component<DevicesPrinterOptionsListArgs> {
  @tracked selectedOptions: Array<PrinterConnectionOptionModel> = [];

  @localCopy('args.ctaText', 'Add to this location') ctaText!: string;
  @localCopy('args.printerConnection', null) printerConnection!: PrinterConnectionModel | null;
  @localCopy('args.fetchPrinterConnectionOptionsTask', null)
  fetchPrinterConnectionOptionsTask!: FetchPrinterConnectionOptionsTaskType | null;

  @reads('fetchPrinterConnectionOptionsTask.isRunning') isLoading!: boolean;
  @reads('fetchPrinterConnectionOptionsTask.lastSuccessful.value', [])
  printerConnectionOptions!: ArrayProxy<PrinterConnectionOptionModel>;

  get listedOptions(): Array<PrinterConnectionOptionModel> {
    const { printerConnectionOptions, printerConnection } = this;

    if (!printerConnection) return printerConnectionOptions.toArray();

    return printerConnectionOptions.filterBy('parentId', printerConnection.externalResourceUid);
  }

  @action
  async fetchPrinterConnectionOptions(): Promise<void> {
    await this.fetchPrinterConnectionOptionsTask?.perform?.();
  }

  @action
  selectionChanged(newSelection: Array<PrinterConnectionOptionModel>): void {
    this.selectedOptions = newSelection;
  }

  @action
  selectAllOptions(checked: boolean): void {
    const selection: Array<PrinterConnectionOptionModel> = checked ? this.printerConnectionOptions.toArray() : [];

    this.selectionChanged(selection);
  }

  submitTask = task({ drop: true }, async () => {
    const { printerConnection, selectedOptions } = this;

    if (!printerConnection?.id) return;
    if (!selectedOptions.length) return;

    await this.args.onSubmit?.(printerConnection, selectedOptions);
  });
}

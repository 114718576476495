import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import ObjectProxy from '@ember/object/proxy';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { dropTask } from 'ember-concurrency';

export default class LocationOverviewEmployeesScimSettingsController extends Controller {
  @service currentLocation;
  @service flashMessages;
  @service('locations') locationsService;
  @service router;

  queryParams = ['integration', 'from'];

  @tracked integration = '';
  @tracked from = `${this.isGlobalOverview ? 'location-overview.' : ''}employees.integrations`;

  get activeInstalls() {
    const syncPlugins = this.model.plugins?.filterBy('category', 'sync');
    const pluginInstalls = this.model.pluginInstalls;

    // find active installs that belong to any plugin in the sync category
    const syncPluginInstalls = pluginInstalls.filter((install) => {
      return syncPlugins.findBy('id', get(install, 'plugin.id')) !== undefined;
    });
    return syncPluginInstalls.filter(function (install) {
      return install.status !== 'uninstalled';
    });
  }

  get locations() {
    return this.locationsService.currentCompanyLocations.map((location) =>
      ObjectProxy.create({ content: location, disabled: false }),
    );
  }

  get isGlobalOverview() {
    return this.router.currentRouteName?.includes('location-overview');
  }

  @action
  integrationDisconnected() {
    this.router.transitionTo(`${this.isGlobalOverview ? 'location-overview.' : ''}employees.integrations`);
  }

  @action
  setIntegration(integration) {
    this.integration = integration;
  }

  @action
  clearIntegration() {
    this.integration = '';
  }

  saveDirectoryProvider = dropTask(async (model) => {
    try {
      await model.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.router.transitionTo(`${this.isGlobalOverview ? 'location-overview.' : ''}employees.sync-settings`);
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}

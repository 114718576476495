import { validateFormat } from 'ember-changeset-validations/validators';

export default {
  notifyEmail: validateFormat({
    type: 'email',
    allowBlank: true,
    message: 'Please use a valid email address',
  }),
  // TODO: Add phone validation
  // notifyPhoneNumber
};

import Helper from '@ember/component/helper';
import { service } from '@ember/service';

/**
 * This IsRouteActive helper checks if the provided list of route names includes an active route
 *
 * @param {Array}  routeNames   Required. Array of route names
 */
export default class IsRouteActive extends Helper {
  @service router;

  compute([routeNames]) {
    if (!Array.isArray(routeNames)) {
      routeNames = [routeNames];
    }

    return (
      this.router.currentRoute?.localName !== 'loading' &&
      routeNames.any((routeName) => this.router.isActive(routeName))
    );
  }
}

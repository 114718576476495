import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * @param {function} onScroll Fires each time the options list is scrolled
 */
export default class ScrollAwareOptions extends Component {
  _handleScroll = (e) => {
    const listElement = e.target;
    const scrolledPercent = listElement.scrollTop / (listElement.scrollHeight - listElement.offsetHeight);
    this.args.onScroll(scrolledPercent);
  };

  optionsElement(element) {
    return element.parentElement.getElementsByClassName('ember-power-select-options')[0];
  }

  @action
  registerListener(element) {
    this.optionsElement(element).addEventListener('scroll', this._handleScroll, { passive: true });
  }

  @action
  unregisterListener(element) {
    this.optionsElement(element).removeEventListener('scroll', this._handleScroll);
  }
}

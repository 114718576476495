import RmsAdapter from './rms';

export default class extends RmsAdapter {
  namespace = 'a/rms/api/v2';

  urlForDeleteRecord(id, modelName, snapshot) {
    if (modelName === 'room-device') {
      // Only delete requests go to Python API (for now)
      const url = super.urlForDeleteRecord(id, 'device', snapshot);
      return url.replace('rms/api/v2', 'rooms-v2/api/v1');
    } else {
      return super.urlForDeleteRecord(id, modelName, snapshot);
    }
  }
}

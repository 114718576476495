import Model, { attr } from '@ember-data/model';
import { apiAction } from '@mainmatter/ember-api-actions';
import { type SingleResponse } from 'jsonapi/response';

class AbstractConfig extends Model {
  @attr('string') declare welcomeImage: string | null;
  @attr('string', { defaultValue: '#FFFFFF' }) declare welcomeBackgroundColor: string;
  @attr('boolean') declare welcomeBackgroundColorEnabled: boolean;

  async deleteWelcomeImage(): Promise<SingleResponse<AbstractConfig>> {
    return <SingleResponse<AbstractConfig>>(
      await apiAction(this, { method: 'DELETE', path: 'attachments/welcome-image' })
    );
  }

  async refreshConfig(): Promise<unknown> {
    return await apiAction(this, { method: 'POST', path: 'refresh' });
  }
}

export default AbstractConfig;

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import { task } from 'ember-concurrency';
import moment from 'moment-timezone';

export default class PlansWorkplaceComponent extends Component {
  @service abilities;
  @service impressions;
  @service metrics;
  @service statsig;

  get isBillingAdmin() {
    return this.abilities.can('visit billing');
  }

  get ctaEventProperties() {
    return {
      cta_id: this.ctaId || 'growth-workplace-trial-end-interstitial',
      cta_type: 'card',
      growth_team_project: true,
    };
  }

  get ctaId() {
    if (!this.isBillingAdmin) {
      return 'growth-workplace-trial-end-interstitial_non-billing-admin';
    }

    return `growth-workplace-trial-end-interstitial`;
  }

  get eventMetadata() {
    const trialExpiredDuration = this.args.subscription.trialEndDate
      ? moment.duration(moment().diff(this.args.subscription.trialEndDate)).days()
      : null;
    const onExpiredTrial = this.args.subscription.onExpiredTrial;
    return {
      onExpiredTrial,
      trialExpiredDuration: onExpiredTrial ? trialExpiredDuration : null,
    };
  }

  @action
  logCTAClicked(event) {
    const buttonText = event.target.textContent.trim();
    this.metrics.trackEvent('CTA Clicked', {
      ...this.ctaEventProperties,
      cta_clickable_type: 'button',
      cta_clickable_text: buttonText,
    });

    this.statsig.logEvent('workplace-plans-page-button-clicked', buttonText, this.eventMetadata);

    return event;
  }

  logToGrowthServiceTask = task({ drop: true }, async (action) => {
    if (this.args.subscription.onExpiredTrial) {
      await this.impressions.postImpression.perform(IMPRESSION_NAMES.WORKPLACE_TRIAL_END_MODAL[action.toUpperCase()]);
    }
  });
}

import Component from '@glimmer/component';
import { get } from '@ember/object';

/**
 * Multiple select row.
 * @param {Array<Location|SkinnyLocation>}       selectedLocations  Current selected option ids `1,2,3`.
 * @param {Location|SkinnyLocation}              location
 * @param {Function}                             onSelected         Function triggered when an option is selected
 */

export default class LocationRow extends Component {
  /**
   * Needs to check against id as @location and @selectedLocations can be of diffferent objects
   * i.e. one is a Location and the other is SkinnyLocation
   */
  get isChecked() {
    return this.args.selectedLocations.mapBy('id').includes(get(this.args.location, 'id'));
  }
}

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { TrackedSet } from 'tracked-built-ins';
import { cached } from 'tracked-toolbox';

interface MultiCheckboxArgs<T> {
  items: T[];
  onDestroy?: () => void;
  selectedDidChange: (selected: T[]) => unknown;
  selectedItems: T[];
}

export default class MultiCheckbox<T> extends Component<MultiCheckboxArgs<T>> {
  lastClickedIndex = -1;
  lastClickedItem: T | null = null;

  @cached
  get selectedItems(): Set<T> {
    return new TrackedSet(this.args.selectedItems);
  }

  // this should be called when list of items change
  // for example, when list of items is being removed by the parent component
  @action
  itemsDidChange(): void {
    const lastClickedItemCache = this.lastClickedItem;
    const items = this.args.items;
    const lastClickedItem = this.lastClickedIndex !== -1 ? items[this.lastClickedIndex] : null;

    // If the position of the last click changed we should clear the last click cache
    if (this.lastClickedIndex !== -1 && lastClickedItemCache && lastClickedItemCache !== lastClickedItem) {
      this.lastClickedIndex = -1;
      this.lastClickedItem = null;
    }

    const selectedItems = this.args.selectedItems;
    selectedItems.forEach((item) => {
      this.selectedItems.delete(item);
    });
  }

  toggleSingle(item: T): void {
    if (!this.selectedItems.has(item)) {
      this.selectedItems.add(item);
    } else {
      this.selectedItems.delete(item);
    }
  }

  @action
  toggleChecked(item: T, event: PointerEvent): void {
    // this component support toggle single item or multiple item
    const lastClickedIndex = this.lastClickedIndex;
    const lastClickedItem = this.lastClickedItem;
    const selectedItems = this.args.selectedItems;
    const items = this.args.items;

    // toggle single item
    if (!event.shiftKey || lastClickedIndex === null) {
      this.toggleSingle(item);
    } else {
      // toggle multiple item when user has an item is being clicked earlier
      // and user press shift key.
      const targetIndex = items.indexOf(item);

      // calculate the range of targeted items
      const targetIndexStart = Math.min(targetIndex, lastClickedIndex);
      // we only have to +1 the targetIndex because the lastClickedIndex is already in the correct state
      const targetIndexEnd = Math.max(targetIndex + 1, lastClickedIndex);
      const targetItems = items.slice(targetIndexStart, targetIndexEnd);

      if (!selectedItems.includes(item) && lastClickedItem && selectedItems.includes(lastClickedItem)) {
        // add targetItems into list of selected items
        for (const item of targetItems) {
          this.selectedItems.add(item);
        }
      } else if (selectedItems.includes(item) && lastClickedItem && !selectedItems.includes(lastClickedItem)) {
        // remove targetItems from list of selected items
        for (const item of targetItems) {
          this.selectedItems.delete(item);
        }
      } else {
        // otherwise, treat this as toggle single item
        this.toggleSingle(item);
      }
    }

    // update last click cache
    this.lastClickedIndex = items.indexOf(item);
    this.lastClickedItem = item;
    this.args.selectedDidChange([...this.selectedItems]);
  }

  willDestroy(): void {
    super.willDestroy();
    if (this.args.onDestroy) {
      this.args.onDestroy();
    }
  }
}

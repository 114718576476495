import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type GlobalOverviewAccessService from 'garaje/services/global-overview-access';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_VISIT_ROLES = [GLOBAL_ADMIN];

export default class GlobalOverviewAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;
  @service declare globalOverviewAccess: GlobalOverviewAccessService;

  get canVisit(): boolean {
    const isOnCorrectPlan = !!this.state.features?.canAccessGlobalOverview;
    if (!isOnCorrectPlan) {
      return false;
    }

    const hasCorrectRoles = isPresent(_intersection(CAN_VISIT_ROLES, this.currentAdmin.roleNames));

    if (hasCorrectRoles) {
      return true;
    }

    return this.globalOverviewAccess.hasAnyGlobalOverviewAbility;
  }
}

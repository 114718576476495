import { LinkComponent } from '@ember/legacy-built-in-components';
import { computed } from '@ember/object';

// eslint-disable-next-line ember/no-classic-classes
export default LinkComponent.extend({
  activeClass: '', // remove the default .active class since we use aria-current as the styling hook
  attributeBindings: Object.freeze(['current:aria-current', 'aria-expanded']),

  // _active is a private boolean computed property of LinkComponent
  current: computed('_active', function () {
    return this._active ? 'page' : false;
  }),
});

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type DetailedChangeset } from 'ember-changeset/types';
import { dropTask } from 'ember-concurrency';
import type ConfigModel from 'garaje/models/config';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

interface WelcomeBgColorArgs {
  changeset: DetailedChangeset<ConfigModel>;
}

export default class WelcomeBgColor extends Component<WelcomeBgColorArgs> {
  @service declare flashMessages: FlashMessagesService;

  WHITE = '#ffffff';

  @tracked isOpen = false;
  @tracked showColorPicker = false;
  @tracked welcomeBackgroundColor = this.args.changeset.welcomeBackgroundColorEnabled
    ? this.args.changeset.welcomeBackgroundColor
    : `${this.WHITE}`;

  isResettingColor = false;
  _enabled = this.args.changeset.welcomeBackgroundColorEnabled;

  get style(): string {
    const { welcomeBackgroundColor } = this.args.changeset;
    // based on http://www.nbdtech.com/Blog/archive/2008/04/27/Calculating-the-Perceived-Brightness-of-a-Color.aspx
    const rgb = this.hexToRgb(welcomeBackgroundColor);
    if (!rgb) return 'color:black';

    const backgroundStyle = `background-color: ${
      welcomeBackgroundColor[0] === '#' ? welcomeBackgroundColor : '#' + welcomeBackgroundColor
    };`;
    const { r, g, b } = rgb;
    const brightness = Math.sqrt(0.241 * Math.pow(r, 2) + 0.691 * Math.pow(g, 2) + 0.068 * Math.pow(b, 2));
    if (brightness >= 130) {
      return 'color:black;' + backgroundStyle;
    } else {
      return 'color:white;' + backgroundStyle;
    }
  }

  hexToRgb(hex: string): { r: number; g: number; b: number } | null {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result && result[1] && result[2] && result[3]) {
      return {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      };
    }
    return null;
  }

  @action
  setColor(color: string): void {
    this.args.changeset.welcomeBackgroundColor = `${color}`;
    if (!this.isResettingColor) {
      this.args.changeset.welcomeBackgroundColorEnabled = true;
    } else {
      this.isResettingColor = false;
    }
  }

  @action
  reset(): void {
    this.args.changeset.rollback();
    this.args.changeset.welcomeBackgroundColor = this.WHITE;
    this.isResettingColor = true;
    this.args.changeset.welcomeBackgroundColorEnabled = false;
  }

  @action
  cancel(): void {
    this.isOpen = false;
    this.args.changeset.rollback();
  }

  saveTask = dropTask(async () => {
    try {
      await this.args.changeset.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.isOpen = false;
      this._enabled = this.args.changeset.welcomeBackgroundColorEnabled;
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}

/* eslint-disable ember/no-computed-properties-in-native-classes */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { restartableTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { alias, uniqBy } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { timeout } from 'ember-concurrency';

const FILTER_FLOW_TYPES = [
  { name: 'All flows', value: 'ALL' },
  { name: 'Global flows', value: 'GLOBAL' },
  { name: 'Local flows', value: 'LOCAL' },
];

export default class extends Component {
  @service skinnyLocations;

  @alias('infinityModel.content') allFlows;
  @alias('infinityModel.meta') meta;
  @alias('skinnyLocations.readableByCurrentAdmin') availableLocations;
  @uniqBy('availableLocations', 'id') uniqLocations;

  @tracked selectedLocationIds = '';
  @tracked selectedFilterFlowType = FILTER_FLOW_TYPES[0];
  @tracked searchValue = '';
  @tracked infinityModel = false;

  queryModelName = 'flow';

  get queryParams() {
    const params = {
      include: 'location,global-flow.locations',
      fields: {
        flows: 'name,type,enabled,location,global-flow,position',
        locations: 'name,disabled',
        'global-flows': 'name,enabled,locations',
      },
      sort: 'location.name,position',
      filter: {},
      perPage: 20,
      startingPage: 0,
      perPageParam: 'page[limit]',
      pageParam: 'page[offset]',
      countParam: 'meta.total',
      storeFindMethod: 'queryTransient',
    };

    if (this.selectedLocationIds) {
      params.filter['location-id'] = this.selectedLocationIds;
    }

    if (this.selectedFilterFlowType.value !== 'ALL') {
      if (this.selectedFilterFlowType.value === 'LOCAL') {
        params.filter.type = 'local';
      } else {
        params.filter.type = 'Flows::GlobalChild';
      }
    }

    if (this.searchValue) {
      params.filter.name = this.searchValue;
    }

    return params;
  }

  get filterFlowTypes() {
    return FILTER_FLOW_TYPES;
  }

  @action
  selectLocationsFilter(options) {
    let ids = this.selectedLocationIds.split(',').filter((el) => isPresent(el));

    options.forEach((option) => {
      ids = ids.includes(option.id) ? ids.filter((id) => id !== option.id) : [...ids, option.id];
    });

    this.selectedLocationIds = ids.join(',');
  }

  @action
  changeSelectedFlowFilter(option) {
    this.selectedFilterFlowType = option;
  }

  @action
  setInfinityModel(el, [infinityModel]) {
    this.infinityModel = infinityModel;
  }

  @action
  clearFilters() {
    this.selectedLocationIds = '';
    this.selectedFilterFlowType = FILTER_FLOW_TYPES[0];
    this.searchValue = '';
  }

  @restartableTask
  *filterByNameTask({ target }) {
    yield timeout(500);
    this.searchValue = target.value;
  }
}

import { DeliveryMethod } from '../types/delivery-method';

export const MESSAGE_DELIVERY_METHODS: { label: string; value: DeliveryMethod }[] = [
  {
    label: 'SMS',
    value: 'sms',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Push Notification',
    value: 'push',
  },
];

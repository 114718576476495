import { set } from '@ember/object';
import { service } from '@ember/service';
import type Model from '@ember-data/model';
import { dropTask } from 'ember-concurrency';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { reject } from 'rsvp';
import type { Constructor } from 'type-fest';
import type ServerError from 'utils/server-error';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
function updateAndSaveTask<TBase extends Constructor<any>>(Base: TBase) {
  class UpdateAndSaveTask extends Base {
    @service declare flashMessages: FlashMessagesService;

    updateAndSaveTask = dropTask(async (model: Model, field = null, value = null, propagable: boolean = true) => {
      if (field) {
        set(model, field, value);
      }

      try {
        // @ts-ignore
        await model.save({ propagable });
        this.flashMessages.showAndHideFlash('success', 'Saved!');
      } catch (e) {
        let message = 'Server error. Please try again.';

        if ((<ServerError>e).isAdapterError) {
          message = (<ServerError>e).errors.mapBy('detail').join(', ');
        }

        this.flashMessages.showFlash('error', message);

        /*
         Make task finish in an err state, this is useful if you are
         using the task as a promise in code like the following:
  
           task.perform().then((succ) => { }, (err) => { });
  
         For more info read https://ember-concurrency.com/docs/error-vs-cancelation/
         */
        await reject();
      }
    });
  }

  return UpdateAndSaveTask;
}

export default updateAndSaveTask;

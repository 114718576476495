import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type ZoneModel from 'garaje/models/zone';
import type ContextSwitcherService from 'garaje/services/context-switcher';
import type CurrentZoneService from 'garaje/services/current-zone';

export default class ConnectPropertiesNewController extends Controller {
  @service declare contextSwitcher: ContextSwitcherService;
  @service declare currentZone: CurrentZoneService;

  @action
  saved(property: ZoneModel): void {
    this.currentZone.zonesAreStale = true;

    void this.contextSwitcher.transitionToPropertyRoute('property.settings.suites', property, {
      backTo: 'properties',
    });
  }
}

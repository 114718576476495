import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type DetailedChangeset } from 'ember-changeset/types';
import { dropTask, type Task } from 'ember-concurrency';
import type ZoneModel from 'garaje/models/zone';
import type { FileUpload } from 'garaje/pods/components/direct-uploader/component';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

interface PropertyFormCoverPhotoConfigArgs {
  changeset: DetailedChangeset<ZoneModel>;
  property: ZoneModel;
  onPhotoLoading: (isLoading: boolean) => unknown;
  onPendingUpload?: (pendingUpload: FileUpload | null) => unknown;
  saveTask: Task<void, [DetailedChangeset<ZoneModel>]>;
}

export default class PropertyFormCoverPhotoConfig extends Component<PropertyFormCoverPhotoConfigArgs> {
  @tracked isOpen = <boolean>(<unknown>this.args.property.isNew);
  @tracked isPropertyNew = this.args.property.isNew; // eslint-disable-line ember/no-tracked-properties-from-args
  @tracked pendingUpload: FileUpload | null = null;

  isEmpty = !this.args.changeset.coverPhotoUrl;

  get showEditButton(): boolean {
    return !this.isPropertyNew && !this.isOpen;
  }

  get imgSrc(): string | null {
    if (this.args.changeset.coverPhoto === null) return null;
    return this.args.changeset.coverPhotoUrl;
  }

  @action
  onPendingUpload(pendingUpload: FileUpload): void {
    this.pendingUpload = pendingUpload;
    this.args.onPendingUpload?.(pendingUpload);
  }

  @action
  onCancel(): void {
    this.isOpen = false;
    this.args.changeset.rollback();
  }

  saveTask = dropTask(async () => {
    this.args.onPhotoLoading(true);

    if (this.pendingUpload) {
      this.args.changeset.coverPhoto = <string>await this.pendingUpload.upload().catch(throwUnlessTaskDidCancel);
    }
    await this.args.saveTask.perform(this.args.changeset).catch(throwUnlessTaskDidCancel);

    this.args.onPhotoLoading(false);
    this.pendingUpload = null;
    this.isOpen = false;

    this.isEmpty = !this.args.changeset.coverPhotoUrl;
  });

  @action
  reset(): void {
    this.pendingUpload?.reset();

    if (this.isEmpty) {
      this.args.changeset.rollbackProperty('coverPhoto');
    } else {
      this.args.changeset.coverPhoto = null;
    }

    this.pendingUpload = null;
    this.args.onPendingUpload?.(null);
  }
}

/**
 *
 * @param {string} haystack
 * @param {string} needle
 * @returns {function}
 */
export default function (haystack, needle) {
  return function (_target, _key, _descriptor) {
    return {
      get() {
        return Boolean(this[haystack]?.includes(needle));
      },
    };
  };
}

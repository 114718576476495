import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import LocationOverviewAddEmployeeRoute from 'garaje/pods/location-overview/employees/directory/add-employee/route';

export default class AddEmployeeRoute extends LocationOverviewAddEmployeeRoute {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  beforeModel(): void {
    if (this.abilities.cannot('manage employees')) {
      void this.router.transitionTo('unauthorized');
    }
  }
}

import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';

export default class DeliveriesSettingsNotificationsRoute extends Route {
  @service abilities;
  @service state;
  @service router;
  @service store;

  titleToken = 'Deliveries Notification Settings';

  beforeModel() {
    if (this.abilities.cannot('edit notification config for deliveries')) {
      this.router.transitionTo('unauthorized');
    }
  }

  model() {
    const { currentLocation, deliveriesSubscription } = this.state;
    const plugins = this.store.query('plugin', { filter: { location: currentLocation.id } });
    const pluginInstalls = this.store.query('plugin-install', {
      filter: { location: currentLocation.id },
      page: { limit: 100 },
    });

    const notificationConfig = this.store
      .query('notification-config', { filter: { location: currentLocation.id } })
      .then((notificationConfigs) => get(notificationConfigs, 'firstObject'));

    return hash({
      deliveriesSubscription,
      currentLocation,
      notificationConfig,
      plugins,
      pluginInstalls,
    });
  }
}

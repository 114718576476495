import Model, { attr } from '@ember-data/model';
import PropagableChangesTracker from 'garaje/utils/decorators/propagable-changes-tracker';
import type { Constructor } from 'type-fest';

/*
  This is copied over from the agreement-page model and will need to be updated when we update the global settings
  batch endpoint to support multiple agreements. Moving this here for now to begin to use the new agreements data
  model without breaking existing global settings batch behavior.
  @thomascchen

  This is the list of attributes with its humanize version that, when changed,
  can be propagable to other locations
*/
const OVERWRITABLE_SETTINGS = {
  enabled: 'NDA signing',
  body: 'NDA text',
  optional: 'allow visitors to decline signing',
  duration: 're-sign NDA after total days',
  sendToBcc: 'email signed NDAs',
  bccEmail: 'email signed NDA recipient',
};

export interface CustomTranslations {
  body: Record<string, string>;
}

export interface AgreementDocument {
  id?: string | null;
  'document-url'?: string;
  filename?: string;
  'updated-at'?: string;
}

export interface ExtendedCustomTranslations extends CustomTranslations {
  footer?: Record<string, string>;
  document?: Record<string, AgreementDocument>;
}

type HasExtendedFunctionality<TBase extends Constructor<Model>> = TBase &
  Constructor<
    Model & {
      footer: string;
      document: AgreementDocument;
      customTranslations: ExtendedCustomTranslations;
      deleteDocument(documentId: string): Promise<unknown>;
    }
  >;

/**
 * Adds support for extended functionality to an agreement based model
 *
 * @param Base the base class
 * @returns a new model class with extended functionality via the `footer`, `documentId` and `documentUrl` fields
 */
export function withExtendedFields<TBase extends Constructor<Agreement>>(Base: TBase): HasExtendedFunctionality<TBase> {
  class withExtendedFields extends Base {
    @attr('string') declare footer: string;
    @attr('immutable', {
      defaultValue: function () {
        return {};
      },
    })
    declare document: AgreementDocument;
    declare customTranslations: ExtendedCustomTranslations;

    async deleteDocument(documentUrl: string): Promise<unknown> {
      if (this.document['document-url'] === documentUrl) {
        // document to delete is the main document
        this.document = {
          ...this.document,
          id: null,
        };
      }

      Object.entries(this.customTranslations.document ?? {}).forEach(([key, document]) => {
        // the document is in the translations
        if (document['document-url'] === documentUrl) {
          this.customTranslations.document![key] = {
            ...document,
            id: null,
          };
          document.id = null;
        }
      });

      return await this.save();
    }
  }

  return withExtendedFields;
}

@PropagableChangesTracker
class Agreement extends Model {
  @attr('string') declare bccEmail: string;
  @attr('string', { defaultValue: '' }) declare body: string;
  @attr('immutable', {
    defaultValue: function () {
      return { body: {} };
    },
  })
  declare customTranslations: CustomTranslations;

  /**
   * Agreement#duration:
   *
   * - 0 means they never have to resign
   * - null means they have to resign every time
   * - Any other number is the number of days before visitors have to resign
   */
  @attr('number', { defaultValue: 0 }) declare duration: number | null;
  @attr('boolean', { defaultValue: false }) declare enabled: boolean;
  @attr('string') declare name: string;
  @attr('boolean', { defaultValue: false }) declare optional: boolean;
  @attr('number', { defaultValue: 0 }) declare position: number;
  @attr('boolean', { defaultValue: false }) declare requireResign: boolean;
  @attr('boolean', { defaultValue: false }) declare requiredOnsite: boolean;
  @attr('boolean', { defaultValue: false }) declare sendToBcc: boolean;
  @attr('string', { defaultValue: null }) declare videoUrl: string | null;
}

/*
  This is copied over from the agreement-page model and will need to be updated when we update the global settings
  batch endpoint to support multiple agreements. Moving this here for now to begin to use the new agreements data
  model without breaking existing global settings batch behavior.
  @thomascchen

  This is the list of attributes with its humanize version that, when changed,
  can be propagable to other locations
*/
Agreement.reopenClass({
  OVERWRITABLE_PARENT: 'flow',
  OVERWRITABLE_SETTINGS,
});

export default Agreement;

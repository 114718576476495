import { helper } from '@ember/component/helper';

const currency = 'USD';
const style = 'currency';

export function formatCentsAsDollars([cents]) {
  const dollars = cents / 100;
  return new Intl.NumberFormat('en-US', { style, currency }).format(dollars);
}

export default helper(formatCentsAsDollars);

import { dasherize } from '@ember/string';

/**
 * Looks for configured embedded relationships in a response payload and normalizes them
 *
 * @param {Record<string, unknown>} payload payload to normalize with embedded relationships
 * @param {Class} typeClass Model class
 */
export default function embeddedRelationship(payload, typeClass) {
  if (!payload.data) return;
  const included = payload.included || [];

  (Array.isArray(payload.data) ? payload.data : [payload.data]).forEach((record) => {
    const { attributes, relationships, type: parentType, id } = record;
    typeClass.relationships.forEach(([relationship]) => {
      const { options, key, type } = relationship;

      if (options.embedded === true) {
        const dasherizedKey = dasherize(key);
        const dasherizedType = dasherize(type);

        if (attributes?.[dasherizedKey]) {
          const data = {
            // if there is no id, produce a key based off the parent model
            id: attributes[dasherizedKey].id ?? `${parentType}-${id}-${key}`,
            type: dasherizedType,
            attributes: attributes[dasherizedKey],
          };

          relationships[dasherizedKey] = { data };
          included.push(data);

          // remove the embedded relationship from the attributes
          delete attributes[dasherizedKey];
        }
      }
    });
  });

  if (included.length) payload['included'] = included;
}

import Component from '@glimmer/component';
import type SetupGuideItemModel from 'garaje/models/setup-guide-item';
import type Subscription from 'garaje/models/subscription';
import type { RecordArray } from 'garaje/utils/type-utils';
import { filterBy } from 'macro-decorators';

export default class SetupGuideList extends Component<{
  title: string;
  app: string;
  items: RecordArray<SetupGuideItemModel>;
  subscription: Subscription;
  logEvent: (event_name: string, event_value: string, metadata?: object) => void;
}> {
  @filterBy('args.items', 'completed', true) declare completedItems: SetupGuideItemModel[];
  @filterBy('args.items', 'completed', false) declare incompleteItems: SetupGuideItemModel[];
}

import type VfdCompanyConfiguration from 'garaje/models/vfd-company-configuration';
import ApplicationSerializer from 'garaje/serializers/application';
import type { SinglePayload } from 'jsonapi/response';

export default class VfdCompanyConfigurationSerializer extends ApplicationSerializer {
  serialize(...args: Parameters<ApplicationSerializer['serialize']>): SinglePayload<VfdCompanyConfiguration> {
    const payload = <SinglePayload<VfdCompanyConfiguration>>super.serialize(...args);

    delete payload.data.relationships['company'];

    return payload;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'vfd-company-configuration': VfdCompanyConfigurationSerializer;
  }
}

import Component from '@glimmer/component';
import { get, set } from '@ember/object';

/**
 * @param {Boolean}                   canEdit
 * @param {Array}                     filters
 * @param {Model<Location}            location
 * @param {Function}                  save
 */
export default class FilterInput extends Component {
  get locationInfo() {
    return this.args.filters.findBy('locationId', parseInt(get(this.args.location, 'id')));
  }

  get query() {
    if (!this.locationInfo) {
      return '';
    }

    return get(this.locationInfo, 'query') ?? '';
  }

  set query(val) {
    set(this.locationInfo, 'query', val);
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';

class InviteDeletedRoute extends Route {
  @service declare router: RouterService;

  titleToken = 'Invite Deleted';
}

export default InviteDeletedRoute;

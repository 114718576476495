import Component from '@glimmer/component';
import { get } from '@ember/object';

/**
 * @param {Object<iPad|Dashboard|Passport|System>}          device
 * @param {String|Null}                                     via
 * @param {String|Null}                                     by
 * @param {String}                                          type      values [in | out] used in tests
 */
export default class SignInOutDetails extends Component {
  get viaText() {
    const via = this.args.via;
    const deviceName = get(this.args, 'device.name');
    if (!via) {
      return '';
    }
    // We only want to force the first character to lowercase,
    // to retain correct casing specifically for iPad
    let viaLowerCase = via[0].toLowerCase() + via.slice(1);

    if (via === 'iPad' && deviceName) {
      viaLowerCase += `: ${deviceName}`;
    }
    return viaLowerCase;
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type GlobalOverviewAccessService from 'garaje/services/global-overview-access';

export default class LocationOverviewSignInFlowsRoute extends Route {
  @service declare router: RouterService;
  @service declare globalOverviewAccess: GlobalOverviewAccessService;

  beforeModel(): void {
    if (!this.globalOverviewAccess.canVisitFlows) {
      void this.router.transitionTo('unauthorized');
    }
  }
}

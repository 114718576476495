import ApplicationSerializer from './application';

export default class GlobalSignInFieldActionRuleGroupSerializer extends ApplicationSerializer {
  // This is used for the `hasMany` relationship between flows and skinny-locations
  // envoy-web doesn't know anything about the skinny-locations model.
  modelNameFromPayloadKey(key) {
    if (key === 'flows') {
      return 'global-flow';
    } else if (key === 'sign-in-field-action-rule-groups') {
      return 'global-sign-in-field-action-rule-group';
    } else if (key === 'sign-in-field-actions') {
      return 'global-sign-in-field-action';
    } else if (key === 'sign-in-field-actions-contacts') {
      return 'global-sign-in-field-actions-contact';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }

  payloadKeyFromModelName(modelName) {
    if (modelName === 'global-flow') {
      return 'flows';
    } else if (modelName === 'global-sign-in-field-action-rule-group') {
      return 'sign-in-field-action-rule-groups';
    } else if (modelName === 'global-sign-in-field-actions-contact') {
      return 'sign-in-field-actions-contacts';
    } else if (modelName === 'global-sign-in-field-action') {
      return 'sign-in-field-actions';
    } else {
      return super.payloadKeyFromModelName(...arguments);
    }
  }
}

import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';

/**
 * @param {Function}            close              action to close the modal
 * @param {Function}            onSubmit       task performed when confirming
 * @param {Entry}               entry
 * @param {VisitorDocument}     visitorDocument
 */
export default class RemoveVisitorDocumentConfirmation extends Component {
  @dropTask
  *onSubmitTask(e) {
    const { visitorDocument, entry } = this.args;

    e.preventDefault();

    yield this.args.onSubmit(visitorDocument, entry);

    this.args.close();
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { guidFor } from '@ember/object/internals';

/**
 * @param {Model<Integration}           googleApp
 * @param {Object}                      filter
 * @param {Model<Company>}              company
 * @param {Boolean}                     canEdit
 * @param {Function}                    showFlash
 * @param {Function}                    hideFlash
 * @param {Function}                    handleFailedQuery
 */
export default class GoogleAppsAllFilter extends Component {
  @service googleSyncManager;
  @tracked search;

  elementId = guidFor(this);

  get isActive() {
    return this.args.filter.type === 'unfiltered';
  }

  get neverSynced() {
    return get(this.args.company, 'employeesSyncState') === 'never';
  }

  get isDirty() {
    return this.args.filter.hasDirtyAttributes || this.neverSynced;
  }

  @action
  _resetResults() {
    if (!this.isActive) {
      this.search = null;
    }
  }

  @action
  setFilter() {
    return this.googleSyncManager.setFilter(this.args.company, 'unfiltered');
  }

  @dropTask
  *saveTask() {
    return yield this.setFilter().then(() => {
      this.search = null;
      this.args.showFlash('success', 'Saved!');
    });
  }

  @dropTask
  *testTask() {
    this.args.hideFlash();

    const promise = yield this.googleSyncManager.companyFilter(this.args.googleApp, '').then(
      (data) => {
        this.search = { results: data };
      },
      (response) => {
        this.args.handleFailedQuery(response);
      },
    );

    return promise;
  }

  @action
  activate() {
    if (this.args.canEdit && !this.isActive) {
      set(this.args.filter, 'type', 'unfiltered');
    }
  }

  @action
  cancel() {
    this.args.filter.rollbackAttributes();
  }
}

import ApplicationSerializer from './application';
import { isEmpty } from '@ember/utils';

export default class SignInFieldSerializer extends ApplicationSerializer {
  get attrs() {
    const attrs = {
      localized: {
        serialize: false,
      },
      actionableSignInFields: {
        serialize: false,
      },
      signInFieldActions: {
        serialize: false,
      },
    };

    return attrs;
  }

  serializeIntoHash(hash, type, snapshot, options) {
    const payload = this.serialize(snapshot, options);
    const {
      data: { attributes },
    } = payload;

    if (!isEmpty(attributes.options)) {
      attributes.options.forEach((opt) => {
        opt.app = 'garaje'; // `app` param signfies to envoy-web to always create id's for dropdown options.
        if (opt.customTranslations) {
          opt['custom-translations'] = opt.customTranslations;
        }
        delete opt.customTranslations;
      });
    }
    Object.assign(hash, payload);
  }
}

import Route from '@ember/routing/route';
import { isArray } from '@ember/array';
import { Promise } from 'rsvp';
import { getOwner } from '@ember/application';

const lookupFastBoot = (context) => {
  return getOwner(context).lookup('service:fastboot');
};

const routeProps = {
  titleToken: null,
  title: null,
};

routeProps['actions'] = {
  collectTitleTokens(tokens) {
    const { title, currentModel } = this;
    let titleToken = this.titleToken;

    if (typeof titleToken === 'function') {
      titleToken = titleToken.call(this, currentModel);
    }

    if (isArray(titleToken)) {
      tokens.unshift.apply(tokens, titleToken);
    } else if (titleToken) {
      tokens.unshift(titleToken);
    }

    if (title) {
      const completion = Promise.resolve()
        .then(() => {
          if (typeof title === 'function') {
            return Promise.all(tokens).then((resolvedTokens) => {
              return title.call(this, resolvedTokens);
            });
          } else {
            return title;
          }
        })
        .then((finalTitle) => {
          document.title = finalTitle;
        });

      const fastboot = lookupFastBoot(this);
      if (fastboot && fastboot.isFastBoot) {
        fastboot.deferRendering(completion);
      }
    } else {
      return true;
    }
  },
};

export function initialize() {
  Route.reopen(routeProps);
}

export default {
  initialize,
};

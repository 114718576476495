import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { alias } from 'macro-decorators';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { DEFAULT_FLOW_NAME } from 'garaje/utils/enums';

/**
 * @param {Function}            cancel
 */
export default class CreateEmployeeRegistrationFlowModal extends Component {
  @service abilities;
  @service state;
  @service store;
  @service flow;
  @service router;
  @service workplaceMetrics;

  @tracked name = '';

  @alias('flow.openedModal') openedModal;
  @alias('flow.globalEmployeeScreeningFlows') globalEmployeeScreeningFlows;
  @alias('state.currentCompany') company;

  constructor() {
    super(...arguments);
    if (this.globalEmployeeScreeningFlows.length === 0) {
      this.name = DEFAULT_FLOW_NAME.EMPLOYEE_SCREENING;
    }
  }

  get disableSubmit() {
    const { name, globalEmployeeScreeningFlows } = this;
    const disabled =
      name.length && globalEmployeeScreeningFlows.length
        ? globalEmployeeScreeningFlows.find((flow) => flow.name === name)
        : name.length
          ? false
          : true;
    return !!disabled;
  }

  @dropTask
  *newEmployeeScreeningFlow() {
    const { company, name } = this;
    this.workplaceMetrics.trackEvent('WORKPLACE_SETTINGS_NEW_REGISTRATION_FLOW_SAVE_BTN_CLICKED', {
      name,
    });
    const employeeScreeningFlow = this.store.createRecord('employee-screening-flow');
    employeeScreeningFlow.name = name;
    employeeScreeningFlow.company = company;
    yield employeeScreeningFlow.save();
    this.workplaceMetrics.trackEvent('WORKPLACE_SETTINGS_NEW_REGISTRATION_FLOW_SAVE_SUCCESSFUL', {
      name,
    });
    this.openedModal = '';
    const transitionRouteString = this.abilities.can('visit settings for workplace')
      ? 'workplace.settings.employees.registration.edit'
      : 'protect.settings.employees.registration.edit';
    this.router.transitionTo(transitionRouteString, employeeScreeningFlow.id);
  }
}

import { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { apiAction } from '@mainmatter/ember-api-actions';
import AbstractSummaryPageModel from 'garaje/models/abstract/abstract-summary-page';
import type FlowModel from 'garaje/models/flow';
import adapter from 'garaje/utils/decorators/adapter';
import PropagableChangesTracker from 'garaje/utils/decorators/propagable-changes-tracker';

@PropagableChangesTracker
@adapter('v3-coalesce')
class SummaryPageModel extends AbstractSummaryPageModel {
  @belongsTo('flow') declare flow: AsyncBelongsTo<FlowModel>;
  @attr('string') declare message?: string | null;
  @attr('string') declare image?: string | null;

  async deleteSummaryPhoto(): Promise<{ data: Record<string, unknown> }> {
    return <{ data: Record<string, unknown> }>await apiAction(this, { method: 'DELETE', path: 'attachments/image' });
  }
}

SummaryPageModel.reopenClass({
  OVERWRITABLE_PARENT: 'flow',
  OVERWRITABLE_SETTINGS: Object.freeze({
    allowEmail: 'Final screen, "Email me" this info option',
    message: 'Final screen, Custom message',
    videoUrl: 'Final screen, Video URL',
    image: 'Final screen, Image',
  }),
});

export default SummaryPageModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'summary-page': SummaryPageModel;
  }
}

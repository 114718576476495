import { action } from '@ember/object';
import Component from '@glimmer/component';

import { type ModelTypes } from '../component';

interface MultiZonesSelectorRowComponentArgs {
  disabledMessage: string | null;
  isDisabled: boolean;
  zone: ModelTypes;
  onSelected?: (item: ModelTypes) => unknown;
}

export default class MultiZonesSelectorRowComponent extends Component<MultiZonesSelectorRowComponentArgs> {
  @action
  select(item: ModelTypes): void {
    if (this.args.isDisabled) return;
    this.args.onSelected?.(item);
  }
}

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get, set } from '@ember/object';
import { alias } from 'macro-decorators';
import { dropTask } from 'ember-concurrency';

export default class extends Component {
  @service state;

  @alias('state.vrSubscription') vrSubscription;

  get hasAccessToVisitorPhotos() {
    return !!this.state.features?.canAccessVisitorPhotos;
  }

  @dropTask
  *updateFeature(photoPage, value) {
    yield this.args.updateAndSaveTask.perform(photoPage, 'enabled', value);

    // if user turns off photos, badge showPhoto is set to false.
    if (!value) {
      const badge = this.args.badge;

      if (get(badge, 'showPhoto')) {
        set(badge, 'showPhoto', false);
        yield badge.save();
      }
    }
  }
}

import setupGuidePagesMap from 'garaje/utils/setup-guide-pages-map';

/**
 * This Class implements the same methods and attributes as the `locations-setup-guide-step` model.
 * Room's API doesn't handle Setup Guide Steps the same way Envoy-web does. So this is a wrapper that will mimic that
 * behavior
 */
export default class SetupGuideRoomsStep {
  constructor(completed, title, page) {
    this.setupGuideStep = { title, page };
    this.completed = completed;
  }
  get isCompletedOrSkipped() {
    return this.completed;
  }
  get routeName() {
    return setupGuidePagesMap[this.setupGuideStep.page];
  }
  save() {}
}

import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

import type VisitorGuideModel from './visitor-guide';

@adapter('v3-coalesce')
class VisitorGuideSectionModel extends Model {
  @belongsTo('visitor-guide', { async: true }) declare visitorGuide: AsyncBelongsTo<VisitorGuideModel>;

  @attr('boolean') declare enabled: boolean;
  @attr('string') declare body: string;
  @attr('string') declare bodyLabel: string;
  @attr('string') declare bodyPlaceholder: string;
  @attr('string') declare iconType: string;
  @attr('number') declare position: number;
  @attr('string') declare title: string;
  @attr('immutable', {
    defaultValue: function () {
      return { body: {}, title: {} };
    },
  })
  declare customTranslations: { body: Record<string, string>; title: Record<string, string> };
}

export default VisitorGuideSectionModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'visitor-guide-section': VisitorGuideSectionModel;
  }
}

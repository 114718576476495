import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';

export default class DevDashboardAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;

  get canVisit(): boolean {
    return this.currentAdmin.isGlobalAdmin;
  }
}

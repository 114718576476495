import type { AsyncHasMany } from '@ember-data/model';
import Model, { attr, hasMany } from '@ember-data/model';
import type DeliveryModel from 'garaje/models/delivery';
import adapter from 'garaje/utils/decorators/adapter';
import serializer from 'garaje/utils/decorators/serializer';

@adapter('deliveries')
@serializer('deliveries')
class CarrierModel extends Model {
  @hasMany('delivery') declare deliveries: AsyncHasMany<DeliveryModel>;
  @attr('string') declare name: string;
}

export default CarrierModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    carrier: CarrierModel;
  }
}

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

/**
 *
 * @param {String} ctaID - value to use as "cta_id" value in metrics tracking
 * @param {String} imagePath - path to the image to be used in the splash
 * @param {Boolean} [showUpgradeButton=true] - if false, the "Upgrade to Premium" button is not shown and an <:upgrade-text> block is yielded in its place
 * @param {String} [upgradeButtonText="Upgrade to Premium →"] - text to show on the Upgrade button, if it's displayed
 * @param {String} title - Text to use as title for display and metrics tracking
 *
 * Yields two named blocks:
 * <:body> - to be used for the main body text of the upsell
 * <:upgrade-text> - when @showUpgradeButton is `false`, this block is yielded in place of the Upgrade button.
 *                   It can be used to show a message (e.g., "Contact your Global Admin", etc.) in place of the button.
 */
export default class UpsellSplash extends Component {
  @service metrics;

  get upgradeButtonText() {
    return this.args.upgradeButtonText || 'Upgrade to Premium →';
  }

  get eventProperties() {
    return {
      cta_clickable_type: this.showUpgradeButton ? 'button' : 'none',
      cta_clickable_text: this.showUpgradeButton ? this.upgradeButtonText : null,
      cta_id: this.args.ctaId,
      cta_title: this.args.title,
      cta_type: 'overlay',
      cta_intent: 'upgrade',
      growth_team_project: true,
    };
  }

  get showUpgradeButton() {
    return this.args.showUpgradeButton ?? true;
  }

  @action
  logUpsellViewed() {
    this.metrics.trackEvent('CTA Viewed', this.eventProperties);
  }

  @action
  logUpgradeButtonClicked() {
    this.metrics.trackEvent('CTA Clicked', this.eventProperties);
  }
}

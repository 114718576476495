import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { MatrixByProduct } from 'garaje/pods/manage/create-role/controller';
import { MatrixKind, displayNameKeyMapping } from 'garaje/utils/permission-sets';
import type { MatrixRow } from 'garaje/utils/permission-sets';

interface ViewProductPermissionsArgs {
  productMatrix: MatrixByProduct;
}

class ViewProductPermissionsComponent extends Component<ViewProductPermissionsArgs> {
  declare args: ViewProductPermissionsArgs;

  @tracked collapsed = false;

  get productRow(): string {
    const { productMatrix } = this.args;
    return productMatrix && productMatrix.product
      ? displayNameKeyMapping[productMatrix.product.displayNameKey] || ''
      : '';
  }

  @action
  handleCollapseAndExpand(): void {
    this.collapsed = !this.collapsed;
  }

  renderRow(row: MatrixRow): string {
    return row.kind === MatrixKind.PERMISSION_SET ? displayNameKeyMapping[row.displayNameKey] || '' : '';
  }
}

export default ViewProductPermissionsComponent;

/* eslint-disable ember/no-computed-properties-in-native-classes */
import { not, and, notEmpty } from '@ember/object/computed';
import Model, { attr, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import type LocationModel from 'garaje/models/location';
import type VisitorEntranceModel from 'garaje/models/visitor-entrance';
import deviceStatus from 'garaje/utils/decorators/device-status';

import type AbstractPrinter from './abstract/printer';
import type ZoneModel from './zone';

@deviceStatus
class DeviceModel extends Model {
  @belongsTo('location') declare location: LocationModel;
  @belongsTo('abstract/printer', { polymorphic: true }) declare printer: AbstractPrinter;
  @belongsTo('visitor-entrance') declare visitorEntrance: VisitorEntranceModel;
  @belongsTo('zone') declare zone: ZoneModel;

  @attr('string') declare userToken: string;
  @attr('boolean') declare isConnected: boolean;
  @attr('date') declare grantExpiresAt: Date;
  @attr('string') declare osVersion: string;
  @attr('string') declare maximumOsVersion: string;
  @attr('string') declare appVersion: string;
  @attr('string') declare currentIpadAppVersion: string;
  @attr('string') declare ipAddress: string;
  @attr('string') declare wifiNetwork: string;
  @attr('string') declare printerStatus: string;
  @attr('date') declare lastHeartbeatAt: Date;
  @attr('boolean') declare isSleeping: boolean;
  @attr('string') declare deviceType: string;
  @attr('string') declare name: string;

  @tracked newPingRequestWentOutAt: Date | null = null;
  @tracked oldPingRequestWentOutAt: Date | null = null;
  @not('isDeleted') declare isNotDeleted: boolean;
  @and('isConnected', 'isNotDeleted') declare isVisible: boolean;
  @notEmpty('deviceType') declare hasType: boolean;

  get isIpad(): boolean {
    if (this.deviceType) {
      const substring = this.deviceType.substring(0, 4);
      // x86_ is for the iOS simulator
      return substring === 'iPad' || substring === 'x86_';
    }

    return false;
  }

  setPingTime(): void {
    if (this.newPingRequestWentOutAt) {
      this.oldPingRequestWentOutAt = this.newPingRequestWentOutAt;
    }
    this.newPingRequestWentOutAt = new Date();
  }
}

export default DeviceModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    device: DeviceModel;
  }
}

import ApplicationSerializer from './application';

interface Meta {
  'desk-count': number;
  'desk-count-by-floor': Record<string, number>;
}

interface ResponseData {
  attributes: {
    deskCount?: number;
    deskCountByFloor?: Record<string, number>;
  };
}

interface Response {
  data: ResponseData[];
  meta?: {
    'neighborhood-meta': Meta[];
  };
}

export default class NeighborhoodSerializer extends ApplicationSerializer {
  attrs = {
    deskCount: {
      serialize: false,
    },
    deskCountByFloor: {
      serialize: false,
    },
  };

  normalizeResponse(): Response {
    // eslint-disable-next-line prefer-rest-params
    const response = super.normalizeResponse(...arguments) as Response;
    if (response.meta) {
      response.meta['neighborhood-meta'].forEach((meta: Meta, idx: number) => {
        const deskCount = meta['desk-count'];
        const deskCountByFloor = meta['desk-count-by-floor'];
        if (response.data[idx]?.attributes) {
          response.data[idx].attributes['deskCount'] = deskCount;
          response.data[idx].attributes['deskCountByFloor'] = deskCountByFloor;
        }
      });
      delete response.meta;
    }

    return response;
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    neighbohood: NeighborhoodSerializer;
  }
}

import Service, { service } from '@ember/service';
import type Store from '@ember-data/store';
import { isTesting, macroCondition } from '@embroider/macros';
import { restartableTask, timeout } from 'ember-concurrency';
import type ConnectInviteModel from 'garaje/models/connect-invite';
import type UiHookModel from 'garaje/models/ui-hook';
import type ZoneModel from 'garaje/models/zone';
import type AjaxService from 'garaje/services/ajax';
import type CurrentZoneService from 'garaje/services/current-zone';
import type { ChannelConnectEventData } from 'garaje/services/current-zone';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type { FlashMessage } from 'garaje/services/flash-messages';
import type MessageBusService from 'garaje/services/message-bus';
import normalizeResponse from 'garaje/utils/normalize-response';
import { parseEventPayloads } from 'garaje/utils/ui-hooks';
import urlBuilder from 'garaje/utils/url-builder';
import { TrackedMap } from 'tracked-built-ins';

const SORT_COL_TO_FIELD_NAME = {
  Name: 'full_name',
  'Expected arrival': 'expected-arrival-time',
  'Suite name': 'suite-name',
  'Tenant name': 'tenant-name',
  'Host name': 'host',
  Group: 'group',
};

const ORDER_DESC_VALS = ['Z-A', 'Most to least recent'];
const PAGE_SIZE = 30;

const REFRESH_DELAY = 5; // automatically reload the model data every this-many seconds

const CHECKIN_TRIGGER_NAME = 'property_invite_checked_in';

// This is a list of all triggers that we should load hooks for, regardless of how they might be used.
// For example, 'property_invite_log_table' is used to fetch headers & content for the invite log table,
// while 'property_invite_checked_in' is an event fired when an invite is checked in.
const HOOK_TRIGGERS = ['property_invite_log_table', CHECKIN_TRIGGER_NAME];

// This is a list of all triggers that add content to the table. These must _also_ be included in
// HOOK_TRIGGERS above.
const TABLE_CONTENT_HOOK_TRIGGERS = ['property_invite_log_table'];

// event name to emit on message bus when to communicate with <IframeModalContainer> component
const EVENT_NAME = 'iframe-modal-message';

interface HookItem {
  responseHTML: string;
  hookId: string;
  resourceId: string;
}

interface HooksResponse {
  data: HookItem[];
}

export interface LoadInvitesParams {
  dateFrom?: string;
  dateTo?: string;
  pageNumber: number;
  property: string;
  selectedDateRange?: string;
  search?: string;
  sortBy: keyof typeof SORT_COL_TO_FIELD_NAME;
  sortDirection: string;
  status?: string;
  suiteIds?: string[];
  tenantIds?: string[];
}

export interface ColumnHeader {
  text?: string;
  content?: string;
  uiHookId: string;
}

export default class ConnectInvitesService extends Service {
  @service declare ajax: AjaxService;
  @service declare store: Store;
  @service declare messageBus: MessageBusService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare currentZone: CurrentZoneService;

  #params?: LoadInvitesParams;
  #knownUiHooks = new TrackedMap<string, UiHookModel>();

  #connectInviteNotifications: FlashMessage[] = [];

  get columnHooks(): Map<string, UiHookModel> {
    const columnHooks = new Map<string, UiHookModel>();
    for (const [hookId, hook] of this.#knownUiHooks) {
      if (TABLE_CONTENT_HOOK_TRIGGERS.includes(hook.triggerName)) {
        columnHooks.set(hookId, hook); // eslint-disable-line ember/use-ember-get-and-set
      }
    }
    return columnHooks;
  }

  get extraColumnHeaders(): ColumnHeader[] {
    return Array.from(this.columnHooks.values()).map((uiHook) => ({
      text: uiHook.label,
      uiHookId: uiHook.id,
    }));
  }

  startPolling = restartableTask(async () => {
    if (this.featureFlags.isEnabled('connect-pubnub-invites-log')) {
      this.currentZone.subscribeToConnectInvites((payload: ChannelConnectEventData) => {
        switch (payload.event) {
          case 'new_connect_invite': {
            const connectInvite = <ConnectInviteModel>(
              this.store.push(normalizeResponse(this.store, 'connect-invite', payload.connect_invite))
            );

            // refresh data
            void this.loadDataInBackground.perform(this.#params);

            // show toast for new walk ins that need approval
            if (connectInvite.visitorCategory === 'property' && connectInvite.needsApprovalReview) {
              this.#addFlash(
                this.flashMessages.showFlash('error', `${connectInvite.fullName} just checked in as a walk-in`, {
                  details: `Access pending review by ${connectInvite.tenant.name}`,
                  icon: 'clock-2',
                  stack: true,
                }),
              );
            }

            break;
          }
          case 'updated_connect_invite':
          case 'deleted_connect_invite': {
            // refresh data
            void this.loadDataInBackground.perform(this.#params);
            break;
          }

          case 'reviewed_connect_invite': {
            // show approved / deny toasts
            if (payload.action === 'approve') {
              this.#addFlash(
                this.flashMessages.showFlash('success', `${payload.visitor_name} was approved`, {
                  details: `${payload.reviewer_name} from ${payload.tenant_name} approved access`,
                  stack: true,
                }),
              );
            }

            if (payload.action === 'deny') {
              this.#addFlash(
                this.flashMessages.showFlash('error', `${payload.visitor_name} was denied`, {
                  details: `${payload.reviewer_name} from ${payload.tenant_name} denied access`,
                  stack: true,
                }),
              );
            }
          }
        }
      });
    } else {
      if (macroCondition(isTesting())) return;

      await timeout(REFRESH_DELAY * 1000);
      void this.refreshData.perform();
    }
  });

  stopPolling(): void {
    if (this.featureFlags.isEnabled('connect-pubnub-invites-log')) {
      this.currentZone.unsubscribeToConnectInvites();
      // clear all stacked notifications when leaving the page
      this.#connectInviteNotifications.forEach((flash) => this.flashMessages.hideFlash(flash));
      this.#connectInviteNotifications = [];
    } else {
      void this.startPolling.cancelAll();
      void this.refreshData.cancelAll();
    }
  }

  hooksForTrigger(triggerName: string): UiHookModel[] {
    return Array.from(this.#knownUiHooks.values()).filter((uiHook) => uiHook.triggerName === triggerName);
  }

  refreshData = restartableTask(async () => {
    // if we're running in tests, don't continuously poll (or else tests will run forever)
    if (macroCondition(isTesting())) return;
    await this.loadDataInBackground.perform(this.#params);
    await timeout(REFRESH_DELAY * 1000);
    void this.refreshData.linked().perform();
  });

  loadDataInBackground = restartableTask(async (params?: LoadInvitesParams) => {
    await this.loadData.perform(params);
  });

  loadDataInForeground = restartableTask(async (params?: LoadInvitesParams) => {
    void this.loadDataInBackground.cancelAll();
    await this.loadData.perform(params);
  });

  loadData = restartableTask(async (params?: LoadInvitesParams) => {
    if (params) {
      this.#params = params;
    } else if (this.#params) {
      params = this.#params;
    } else {
      return;
    }

    const invites = await this.loadInvites.perform(params);

    let extraColumnData: Record<string, ColumnHeader[]> = {};
    let extraColumnHeaders: ColumnHeader[] = [];
    if (<number>invites.length > 0) {
      [extraColumnHeaders, extraColumnData] = await this.loadHooksData.perform(params.property, invites.toArray());
    }
    return {
      extraColumnData,
      extraColumnHeaders,
      invites,
    };
  });

  loadInvites = restartableTask(
    async ({
      dateFrom,
      dateTo,
      pageNumber,
      property,
      selectedDateRange,
      search,
      sortBy,
      sortDirection,
      status,
      suiteIds,
      tenantIds,
    }: LoadInvitesParams) => {
      const offset = PAGE_SIZE * pageNumber - PAGE_SIZE;
      const orderDescending = ORDER_DESC_VALS.includes(sortDirection);
      const sortOrder = orderDescending ? '-' : '';
      const sortProp = SORT_COL_TO_FIELD_NAME[sortBy] || '';

      return await this.store.query('connect-invite', {
        include: 'suites,tenant',
        sort: `${sortOrder}${sortProp}`,
        filter: {
          property,
          ...(status && { status }),
          ...(search && { fullName: search }),
          ...(dateFrom && selectedDateRange !== 'All Time' && { ['date-from']: dateFrom }),
          ...(dateTo && selectedDateRange !== 'All Time' && { ['date-to']: dateTo }),
          ...(tenantIds && { tenant: tenantIds }),
          ...(suiteIds && { suites: suiteIds }),
        },
        page: {
          offset,
          limit: PAGE_SIZE,
        },
      });
    },
  );

  loadUiHooks = restartableTask(async (propertyId: string) => {
    try {
      this.#knownUiHooks.clear();
      const uiHooks = await this.store.query('ui-hook', {
        triggerNames: HOOK_TRIGGERS,
        zoneIds: [propertyId],
      });
      for (const hook of uiHooks.toArray()) {
        this.#knownUiHooks.set(hook.id, hook); // eslint-disable-line ember/use-ember-get-and-set
      }
    } catch {
      /* noop - don't blow up the page if the list of hooks fails to load, just don't show the extra columns */
    }
  });

  loadHooksData = restartableTask(
    async (
      propertyId: string,
      invites: ConnectInviteModel[],
    ): Promise<[ColumnHeader[], Record<string, ColumnHeader[]>]> => {
      try {
        if (this.columnHooks.size === 0) return [[], {}];

        // deduplicate invite IDs
        const ids = new Set(invites.map((invite) => invite.id));

        const response: HooksResponse = await this.ajax.post(urlBuilder.hooks.v1.triggerZoneUiHooksUrl(), {
          data: {
            previewMode: false,
            resourceIds: [...ids],
            resourceType: 'INVITE',
            responseType: 'HTML',
            triggerNames: TABLE_CONTENT_HOOK_TRIGGERS,
            zoneIds: [propertyId],
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data: Record<string, ColumnHeader[]> = {};
        response.data.forEach((item) => {
          // skip any hooks that weren't loaded previously
          if (!this.columnHooks.has(item.hookId)) return;

          const inviteId = item.resourceId;
          const content = item.responseHTML;
          const columns = data[inviteId] || [];
          columns.push({
            content,
            uiHookId: item.hookId,
          });
          data[inviteId] = columns;
        });

        return [this.extraColumnHeaders, data];
      } catch (_e) {
        return [[], {}];
      }
    },
  );

  async triggerCheckinHooks(inviteIds: string[], property: ZoneModel): Promise<void> {
    // don't bother making a request if there are no registered handlers
    if (this.hooksForTrigger(CHECKIN_TRIGGER_NAME).length === 0) return;
    try {
      const response: unknown = await this.ajax.post(urlBuilder.hooks.v1.triggerZoneUiHooksUrl(), {
        data: {
          previewMode: false,
          resourceIds: inviteIds,
          resourceType: 'INVITE',
          responseType: 'JSON',
          triggerNames: [CHECKIN_TRIGGER_NAME],
          zoneIds: [property.id],
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      for (const payload of parseEventPayloads(response)) {
        this.messageBus.trigger(EVENT_NAME, payload);
      }
    } catch (_e) {
      /* noop - don't break the page if this fails */
    }
  }

  #addFlash(flash: ReturnType<FlashMessagesService['showFlash']>): void {
    if (flash && typeof flash === 'object') this.#connectInviteNotifications.push(flash);
  }
}

import { pluralize } from 'ember-inflector';

import MultiTenancyAdapter from './multi-tenancy';

export default class TenantConnectionRequestAdapter extends MultiTenancyAdapter {
  urlForQuery(query: { id?: string }, modelName: Parameters<MultiTenancyAdapter['urlForQuery']>[1]): string {
    const url = super.urlForQuery(query, modelName);
    if (modelName === 'tenant-connection-request' && query.id === 'my-connection-requests') {
      delete query.id;
      return url.replace(pluralize(modelName), 'my-connection-requests');
    } else {
      return url;
    }
  }
}

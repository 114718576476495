import { get } from '@ember/object';
import Service, { service } from '@ember/service';
import Ember from 'ember';
import { enqueueTask } from 'ember-concurrency';
import {
  type GetFeatureConfigQuery,
  type GetFeatureConfigQueryVariables,
  type ProductFeature,
  type QuantityLimitedProductFeature,
} from 'garaje/graphql/generated/feature-config-types';
import featureConfigQuery from 'garaje/graphql/queries/FeatureConfigQuery';
import type ApolloService from 'garaje/services/apollo-extension';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import { tracked } from 'tracked-built-ins';

export default class FeatureConfigService extends Service {
  @service declare apolloExtension: ApolloService;
  @service declare featureFlags: FeatureFlagsService;

  @tracked declare features: GetFeatureConfigQuery['featureConfig'];

  fetchFeatureConfigTask = enqueueTask(async (locationId: string): Promise<void> => {
    try {
      // test-federated-graphql is only used in tests to trigger the query
      if (!Ember.testing || this.featureFlags.isEnabled('test-federated-graphql')) {
        this.features = await this.apolloExtension.query<
          GetFeatureConfigQuery,
          GetFeatureConfigQueryVariables,
          'featureConfig'
        >(
          {
            query: featureConfigQuery,
            fetchPolicy: 'network-only',
            variables: {
              locationId: locationId,
            },
          },
          'featureConfig',
        );
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphq query. Error: ', JSON.stringify(e));
      throw e;
    }
  });

  isEnabled(config: string): boolean {
    const featureConfig: ProductFeature | null = this.features ? (get(this.features, config) as ProductFeature) : null;
    return featureConfig?.enabled ?? false;
  }

  getLimit(config: string): number | null {
    const featureConfig: QuantityLimitedProductFeature | null = get(
      this.features,
      config,
    ) as QuantityLimitedProductFeature | null;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return featureConfig?.limit ?? null;
  }
}

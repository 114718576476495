import Model, { attr } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('v3-coalesce')
class ScimGroup extends Model {
  @attr('string') declare displayName: string;
}

export default ScimGroup;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'scim-group': ScimGroup;
  }
}

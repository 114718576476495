import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import urlBuilder from 'garaje/utils/url-builder';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
import { restartableTask } from 'ember-concurrency';

/**
 * @param {Date}        date
 * @param {Function}    didSelectDate
 * @param {String}      eventType
 * @param {Number}      locationId
 */
export default class EnvoyCalendar extends Component {
  @service ajax;

  @tracked showingDate;
  @tracked shouldHideCalendars = true;

  get selectedDate() {
    if (this.args.date) {
      return moment(this.args.date, 'YYYYMMDD');
    }
    return null;
  }

  get currentMonth() {
    return moment(this.showingDate).startOf('month');
  }

  get calendarWrapperClass() {
    return this.shouldHideCalendars ? 'hide' : 'show';
  }

  // To prevent reloading when the user change the month we need to have
  // another date for showing purpose
  _resetShowingDate() {
    this.showingDate = this.args.date;
  }

  @restartableTask
  *fetchData(specificDate) {
    const formattedDate = moment(specificDate).format('YYYY-MM-DD');
    const url = urlBuilder.v2.entriesCalendarUrl(this.args.locationId, formattedDate);

    const calendarEventEntries = yield this.ajax.request(url).then((response) => response.data);

    return calendarEventEntries
      .filter((event) => event.attributes[this.args.eventType] > 0)
      .map((event) => moment(event.attributes.date));
  }

  @action
  goBackOneMonth() {
    this.showingDate = moment(this.showingDate).subtract(1, 'month');
    this.fetchData.perform(moment(this.showingDate).format('YYYY-MM-DD'));
  }

  @action
  goToNextMonth() {
    this.showingDate = moment(this.showingDate).add(1, 'month');
    this.fetchData.perform(moment(this.showingDate).format('YYYY-MM-DD'));
  }

  @action
  selectDate(selectedDate) {
    this.shouldHideCalendars = true;
    this.args.didSelectDate(moment(selectedDate));
    this._resetShowingDate();
  }

  @action
  beginStartDateSelection() {
    this.fetchData.perform(this.args.date);
    document.querySelector('.datepicker-open').focus();
    this.shouldHideCalendars = false;
    this._resetShowingDate();
  }

  // We need to still use `mouseDown` event to prevent the calendar from closing
  @action
  onMouseDown(e) {
    if (!this.shouldHideCalendars) {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
    }
  }

  @action
  onFocusOut() {
    if (!this.shouldHideCalendars) {
      later(this, function () {
        this.shouldHideCalendars = true;
        this._resetShowingDate();
      });
    }
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, get, set } from '@ember/object';
import { all, dropTask, task } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import RSVP from 'rsvp';

/**
 * @param {Class<Location>}               location
 * @param {Function}                      onUpdate
 * @param {Function}                      onUpgrade
 * @param {Class<Subscription>}           subscription
 * @param {Boolean}                       unchanged
 * @param {Function}                      searchUsers
 * @param {Boolean}                       isOpen
 */
export default class IdScanning extends Component {
  @service featureFlags;
  @service flashMessages;
  @service metrics;
  @service state;

  @tracked isOpen = false;
  @tracked hasDirtyIdScanContacts = false;
  @tracked showUnsavedEditsWarning = false;
  @tracked visitorTypes;

  constructor() {
    super(...arguments);
    this.visitorTypes = [];
    this.isOpen = this.args.isOpen ?? false;
    this.loadVisitorTypes.perform();
  }

  @action
  onEdit() {
    this.isOpen = true;
    this.args.location.pinIdScanContacts();
  }

  @action
  async onEnable() {
    const idScanPages = await this.allIdScanPages();
    idScanPages.forEach((isp) => set(isp, 'enabled', true));
    this.args.onUpdate({ hasChanges: true });
    this.isOpen = true;
  }

  @action
  async onDisable() {
    const idScanPages = await this.allIdScanPages();
    idScanPages.forEach((isp) => set(isp, 'enabled', false));
    this.disableTask.perform();
    this.isOpen = false;
  }

  @action
  onUpdateContacts(users) {
    get(this.args.location, 'idScanContacts').setObjects(users.toArray());
    this.hasDirtyIdScanContacts = true;
    this.args.onUpdate({ hasChanges: true });
  }

  @action
  async onCancel() {
    const config = await this.locationConfig();
    if (this.args.unchanged || !config.idScanningEnabled) {
      await this.onContinue();
    } else {
      this.showUnsavedEditsWarning = true;
    }
  }

  @action
  async onContinue() {
    const idScanPages = await this.allIdScanPages();
    idScanPages.filter((isp) => isp.hasDirtyAttributes).forEach((isp) => isp.rollbackAttributes());
    const config = await this.locationConfig();
    const shouldDisable = (await this._hasNoEnabledVisitors()) && config.idScanningEnabled;
    if (shouldDisable) {
      await this._toggleEnabledTask(false);
    }
    if (this.hasDirtyIdScanContacts) {
      this.args.location.rollbackIdScanContacts();
    }
    this.showUnsavedEditsWarning = false;
    this.isOpen = false;
    this.args.onUpdate({ hasChanges: false });
  }

  @dropTask
  *loadVisitorTypes() {
    let visitorTypes = [];

    try {
      visitorTypes = yield this.state.loadFlows({ reload: false, locationId: this.args.location?.id });
    } catch (_) {
      // If user state flow fetching fails, fallback to location association
      try {
        visitorTypes = yield get(this, 'args.location.flows');
      } catch (e) {
        visitorTypes = [];
        this.flashMessages.showAndHideFlash('error', e);
      }
    }

    try {
      visitorTypes = visitorTypes.filter((vt) => vt.enabled && !vt.isProtect);

      yield this.allIdScanPages(visitorTypes);

      this.visitorTypes = visitorTypes;

      return visitorTypes;
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', e);
    }
  }

  allIdScanPages(visitorTypes = this.visitorTypes) {
    return RSVP.all(visitorTypes.mapBy('idScanPage'));
  }

  async locationConfig() {
    return get(this.args.location, 'config');
  }

  @dropTask
  *saveTask(_users) {
    try {
      const config = yield this.locationConfig();
      const shouldDisable = yield this._hasNoEnabledVisitors();
      if (shouldDisable) {
        yield this._toggleEnabledTask(false);
      } else if (!config.idScanningEnabled) {
        yield this._toggleEnabledTask(true);
      }
      yield this.args.location.save();
      const idScanPages = yield this.allIdScanPages();
      const dirtyVisitorTypeIdScanPages = idScanPages.filterBy('hasDirtyAttributes');
      if (dirtyVisitorTypeIdScanPages.length) {
        yield all(dirtyVisitorTypeIdScanPages.map((idScanPage) => idScanPage.save()));
      }
      if (!shouldDisable) {
        this.flashMessages.showAndHideFlash('success', 'Saved!');
      }
      this.isOpen = false;
      this.args.onUpdate({ hasChanges: false });
    } catch (e) {
      this.isOpen = true;
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @task
  *enableTask() {
    yield this._toggleEnabledTask(true);
  }

  @task
  *disableTask() {
    const idScanPages = yield this.allIdScanPages();
    const dirtyVisitorTypeIdScanPages = idScanPages.filterBy('hasDirtyAttributes');
    if (dirtyVisitorTypeIdScanPages.length) {
      yield all(dirtyVisitorTypeIdScanPages.map((idScanPage) => idScanPage.save()));
    }
    yield this._toggleEnabledTask(false);
  }

  async _hasNoEnabledVisitors() {
    const idScanPages = await this.allIdScanPages();
    const enabledVisitorTypes = idScanPages.filterBy('enabled');
    return enabledVisitorTypes.length === 0;
  }

  async _toggleEnabledTask(enabled) {
    const config = await this.locationConfig();
    set(config, 'idScanningEnabled', enabled);
    if (enabled) {
      this.metrics.trackEvent('Security - ID Scanning Enable Requested');
    } else {
      this.metrics.trackEvent('Security - ID Scanning Disable Requested');
    }
    try {
      await config.save();
      const status = enabled ? 'enabled' : 'disabled';
      const message_title = `ID scanning ${status}`;
      this.flashMessages.showAndHideFlash('success', message_title);
      this.metrics.trackEvent('Viewed Flash Message', {
        type: 'success',
        message_title,
        message_codes: 'id_scan_updated',
        message_code_type: 'id_scan',
      });
    } catch (e) {
      const status = enabled ? 'enabling' : 'disabling';
      const message_title = `There was an issue ${status} ID scanning`;
      this.flashMessages.showFlash('error', message_title, parseErrorForDisplay(e));
      this.metrics.trackEvent('Viewed Flash Message', {
        type: 'error',
        message_title,
        message_codes: 'error_id_scan_update',
        message_code_type: 'id_scan',
      });
    }
  }
}

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { MatrixByProduct } from 'garaje/pods/manage/create-role/controller';
import { MatrixKind, displayDescriptionKeyMapping, displayNameKeyMapping } from 'garaje/utils/permission-sets';
import type { MatrixItem, MatrixRow } from 'garaje/utils/permission-sets';

interface CreateProductPermissionsArgs {
  productMatrix: MatrixByProduct;
  permissionSetIds: Array<string>;
  addPermission: (item: MatrixItem) => void;
  removePermission: (item: MatrixItem) => void;
  copiedFrom: string | null;
}

class CreateProductPermissionsComponent extends Component<CreateProductPermissionsArgs> {
  declare args: CreateProductPermissionsArgs;

  @tracked collapsed = false;

  get productRow(): string {
    const { productMatrix } = this.args;
    return productMatrix && productMatrix.product
      ? displayNameKeyMapping[productMatrix.product.displayNameKey] || ''
      : '';
  }

  @action
  handleCollapseAndExpand(): void {
    this.collapsed = !this.collapsed;
  }

  @action
  togglePermission(row: MatrixRow): void {
    const item = row.item;
    if (item) {
      const isPermissionSelected = this.isRowSelected(row);
      if (isPermissionSelected) {
        this.args.removePermission(item);
      } else {
        this.args.addPermission(item);
      }
    }
  }

  @action
  isRowSelected(row: MatrixRow): boolean {
    return this.args.permissionSetIds.includes(row.item?.id || '');
  }

  isRowCategory(row: MatrixRow): boolean {
    return row.kind === MatrixKind.CATEGORY;
  }

  renderRowName(row: MatrixRow): string {
    return displayNameKeyMapping[row.displayNameKey] || '';
  }

  renderRowDescription(row: MatrixRow): string {
    return displayDescriptionKeyMapping[row.displayDescriptionKey] || '';
  }
}

export default CreateProductPermissionsComponent;

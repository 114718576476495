import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { localCopy } from 'tracked-toolbox';
import { enqueueTask } from 'ember-concurrency';

/**
 * @param {String}            currentPhoto        URL for the user/employee's current photo
 * @param {Number}            outputSize          Size of square output image in px (passes through to input)
 * @param {String|RegExp}     validType           Allowed file types (passes through to input)
 * @param {String}            validationError     Custom validation failure message
 * @param {String}            prefix              S3 path prefix
 * @param {Function}          onClose             Action triggered when the modal dismissed
 * @param {Function}          onSave              Action triggered when Save button clicked
 * @param {Function}          onRemove            Action triggered when "Remove" button clicked
 * @param {Function}          onUpload            Action triggered with reference to task when upload initiated
 * @param {Function}          onError             Action triggered when upload error
 * @param {Function}          onUploadComplete    Action triggered when upload completed successfully
 */
export default class EmployeesProfilePhotoUploaderModalComponent extends Component {
  // ActiveStorage service: https://github.com/algonauti/ember-active-storage
  @service activeStorageExtension;

  @tracked fileToUpload;
  @tracked isInvalid = false;
  @tracked scale = 1;

  @localCopy('args.currentPhoto') currentPhoto;
  @localCopy('args.directUploadURL', '/a/visitors/api/direct-uploads') directUploadURL;
  @localCopy('args.prefix', '') prefix;
  @localCopy('args.validationError', 'Please select an image file') validationError;
  @localCopy('args.minimumScale', '1') minimumScale;
  @localCopy('args.maximumScale', '4') maximumScale;

  get inputsDisabled() {
    if (this.args.isLoading) return true;
    if (this.uploadTask.isRunning) return true;

    return false;
  }

  get saveDisabled() {
    if (this.inputsDisabled) return true;

    return isPresent(this.fileToUpload) ? false : true;
  }

  upload() {
    const { args, directUploadURL, prefix, activeStorageExtension, fileToUpload } = this;
    const { onUpload, onError } = args;
    const onUploadComplete = (value) => this.afterUploadSuccess(value);

    // ember-active-storage addon does not support extra params in the POST payload.
    // But API currently supports sending the prefix as a query param.
    const endpoint = prefix ? `${directUploadURL}?prefix=${prefix}` : directUploadURL;
    const task = this.uploadTask.perform(fileToUpload, endpoint, activeStorageExtension, { onUploadComplete, onError });

    onUpload?.(task);
  }

  afterUploadSuccess(value) {
    this.fileToUpload = null;
    this.args.onUploadComplete?.(value);
  }

  @action
  close() {
    this.fileToUpload = null;
    this.args.onClose?.();
  }

  @action
  save() {
    if (this.fileToUpload instanceof File) {
      this.upload();
    } else if (this.fileToUpload === false) {
      this.afterUploadSuccess(null);
    }

    this.args.onSave?.();
  }

  @action
  remove() {
    this.currentPhoto = false;
    this.fileToUpload = false;
    this.isInvalid = false;
    this.args.onRemove?.();
  }

  @action
  handleFile(file) {
    this.isInvalid = false;
    this.fileToUpload = file;
  }

  @action
  handleError(error) {
    if (error === this.validationError) {
      this.isInvalid = true;
    } else {
      this.args.onError?.(error);
    }
  }

  // Prevent default behavior if file dropped outside of input
  @action
  handleDrop(evt) {
    if ((evt.originalTarget ?? evt.srcElement).type === 'file') return true;

    evt.stopPropagation();
    evt.stopImmediatePropagation();
    evt.preventDefault();

    if (evt.dataTransfer) {
      evt.dataTransfer.effectAllowed = 'none';
      evt.dataTransfer.dropEffect = 'none';
    }

    return false;
  }

  @enqueueTask uploadTask = {
    progress: 0,

    *perform(file, endpoint, activeStorageExtension, callbacks = {}) {
      const { onUploadComplete, onError } = callbacks;

      if (!file) {
        throw new Error('Upload halted: no file specified');
      }

      if (!endpoint) {
        throw new Error('Upload halted: no direct upload endpoint specified');
      }

      if (typeof activeStorageExtension.upload !== 'function') {
        throw new Error('Upload halted: invalid Active Storage service specified');
      }

      try {
        const { signedId } = yield activeStorageExtension.upload(file, endpoint, {
          onProgress: (progress) => {
            this.progress = progress;
          },
          onXHROpened: (xhr) => {
            try {
              xhr.withCredentials = this.context.args.includeCredentials ?? false;
            } catch (_) {
              // Fail silently
            }
          },
        });

        onUploadComplete?.(signedId);

        return signedId;
      } catch (error) {
        const message =
          typeof error === 'string'
            ? `Photo upload failed with the error "${error.toLowerCase()}"`
            : 'Photo upload failed';
        onError?.(message);
      }
    },
  };
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type LocationModel from 'garaje/models/location';
import type StateService from 'garaje/services/state';

import type VisitorsSettingsPreRegistrationUpgradeController from './controller';

class PreRegistrationUpgradeRoute extends Route {
  @service declare router: RouterService;
  @service declare state: StateService;

  titleToken = 'Pre-Registration Upgrade';

  beforeModel(): void {
    const { currentLocation } = this.state;
    if (currentLocation.preRegistrationEnabled) {
      this.router.transitionTo('visitors.settings.invites');
    }
  }

  model(): LocationModel {
    const { currentLocation } = this.state;
    return currentLocation;
  }

  setupController(
    controller: VisitorsSettingsPreRegistrationUpgradeController,
    model: LocationModel,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);
    controller._buildChangeset(model);
  }
}

export default PreRegistrationUpgradeRoute;

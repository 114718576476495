import RoomFragment from 'garaje/graphql/fragments/roomba/RoomFragment';
import gql from 'graphql-tag';

export default gql`
  mutation PutSpaceSaverCheckInExcludedRooms($companyId: ID!, $roomIds: [ID!]!) {
    putSpaceSaverCheckInExcludedRooms(companyId: $companyId, roomIds: $roomIds) {
      checkInExcludedRooms {
        ...RoomFragment
      }
    }
  }
  ${RoomFragment}
`;

import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

import type { LocationOverviewSignInFlowsShowLegalDocumentsEditRouteModel } from './route';

export default class LocationOverviewSignInFlowsLegalDocumentsEditController extends Controller {
  declare model: LocationOverviewSignInFlowsShowLegalDocumentsEditRouteModel;

  @service declare router: RouterService;
  @service declare metrics: MetricsService;
  @service declare flashMessages: FlashMessagesService;

  trackDeleteClicked(id: string): void {
    this.metrics.trackEvent('Legal Docs - Delete Legal Document Button Clicked', {
      legal_doc_id: id,
      location_id: this.model.tracking.currentLocationId,
    });
  }

  @action
  handleDelete(): void {
    const id = this.model.agreement.id;
    try {
      this.model.agreement.destroyRecord();
      this.trackDeleteClicked(id);
      this.router.transitionTo('location-overview.sign-in-flows.show.legal-documents.index', this.model.flow.id);
    } catch (e) {
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
    }
  }
}

import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { A } from '@ember/array';

/**
 * @param {Object}               model
 * @param {Object}               userDataChangeset
 * @param {Array}                signInFields
 * @param {Function}             handleUpdate
 */
export default class EntriesNotAssignableFlowFields extends Component {
  get mappedSignInFields() {
    return A(this.args.signInFields).reduce((map, field) => {
      map[field.name] = field.value;
      return map;
    }, {});
  }

  get renderableFields() {
    const mappedSignInFields = this.mappedSignInFields;

    return this.args.userDataChangeset.filter(
      (fieldChangeset) =>
        get(fieldChangeset, 'isLoaded') &&
        (get(fieldChangeset, 'isCustom') || mappedSignInFields[get(fieldChangeset, 'name')]),
    );
  }

  @action
  onUpdate() {
    if (this.args.handleUpdate) {
      this.args.handleUpdate();
    }
  }
}

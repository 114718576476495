import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';

/**
 * This FeatureEnabled helper determines if a feature is on or off
 * Handy for template only components when you don't want to create a component.js when all you need is the featureFlags service :)
 *
 * Example usage:
 * <input readonly={{feature-enabled "your-feature"}} ... >
 *
 * @param {String}  featureName   Required. An unnamed string, passed as the first argument. All flags are camelized
 * @param {Boolean} enabled       Whether the split flag is enabled or disabled
 */
export default class FeatureEnabled extends Helper {
  @service featureFlags;

  compute([feature]) {
    return this.featureFlags.isEnabled(camelize(feature));
  }
}

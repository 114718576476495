import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { action, get, set, setProperties } from '@ember/object';
import { confirmTransferTask, findAllDeliveryAreasForCompany } from 'garaje/utils/delivery-area';
import { pluralize } from 'ember-inflector';
import { all, task, timeout, enqueueTask, dropTask, restartableTask, lastValue } from 'ember-concurrency';
import { service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import { defer } from 'rsvp';
import zft from 'garaje/utils/zero-for-tests';
import { v4 as uuid } from 'uuid';
import { reads, filterBy } from 'macro-decorators';

const ALL_DELIVERY_AREAS_FILTER_OPTION = { label: 'All delivery areas', value: 'all' };
const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_PAGE_SIZE = 30;
const DEFAULT_STATUS_FILTER = 'all';

export const STATUS_FILTER_OPTIONS = [
  { label: 'All deliveries', value: 'all' },
  { label: 'Pending', value: 'pending' },
  { label: 'Picked up', value: 'collected' },
];

export default class DeliveriesLogIndexController extends Controller {
  @service abilities;
  @service asyncExportManager;
  @service currentAdmin;
  @service deliveriesAjax;
  @service logger;
  @service flashMessages;
  @service featureFlags;
  @service metrics;
  @service state;
  @service store;

  queryParams = ['deliveryAreaFilter', 'pageNumber', 'pageSize', 'recipient', 'statusFilter', 'trackingNumberFilter'];

  @tracked deliveryAreaFilter = 'current location';
  @tracked pageNumber = DEFAULT_PAGE_NUMBER;
  @tracked pageSize = DEFAULT_PAGE_SIZE;
  @tracked recipient = '';
  @tracked statusFilter = DEFAULT_STATUS_FILTER;
  @tracked trackingNumberFilter = '';
  @tracked trackingSearchJobId = null;
  @tracked deliveryLimit;
  @tracked superiors = [];

  recipientTypeFilter = 'all';

  @tracked companyDeliveryAreaSort = ['locationName', 'name'];
  @tracked locationDeliveryAreaSort = ['name'];
  @tracked origins = [];
  @tracked selectedDeliveries = [];

  @lastValue('fetchCompanyDeliveryAreas') companyDeliveryAreas;
  @lastValue('fetchDeliveriesTask') deliveries;
  @filterBy('companyDeliveryAreas', 'enabled') activeCompanyDeliveryAreas;

  @reads('deliveries.meta.page-count') pageCount;
  @reads('deliveries.meta.record-count') recordCount;

  get sortedCompanyDeliveryAreas() {
    return this.activeCompanyDeliveryAreas.sortBy(...this.companyDeliveryAreaSort);
  }

  get sortedLocationDeliveryAreas() {
    return this.locationDeliveryAreas.sortBy(...this.locationDeliveryAreaSort);
  }

  @(employeesSearcherTask().restartable())
  searchEmployeesTask;

  @(confirmTransferTask().drop())
  confirmTransferTask;

  get recipients() {
    if (isEmpty(this.recipient)) {
      return [];
    } else {
      return this.fetchRecipients.lastSuccessful.value;
    }
  }

  get activeExportJob() {
    return get(this.asyncExportManager, 'activeJobType') === 'deliveries';
  }

  // filter the company-wide delivery areas by this location's id
  get locationDeliveryAreas() {
    const companyDeliveryAreas = this.companyDeliveryAreas ? this.companyDeliveryAreas.toArray() : [];

    return companyDeliveryAreas.filter((deliveryArea) => {
      return deliveryArea.belongsTo('location').id() == get(this.state, 'currentLocation.id');
    });
  }

  // Instead of doing a second fetch for this location's delivery areas
  get deliveryAreaFilterOptions() {
    const currentLocation = get(this.state, 'currentLocation');
    const sortedLocationDeliveryAreas = this.sortedLocationDeliveryAreas;

    const currentLocationFilterOption = {
      label: [get(currentLocation, 'name'), 'delivery areas'].join(' '),
      value: 'current location',
    };

    const deliveryAreaFilterOptions = sortedLocationDeliveryAreas.map((deliveryArea) => {
      const label = `${get(deliveryArea, 'locationName')}: ${get(deliveryArea, 'name')}`;
      return { label, value: get(deliveryArea, 'id') };
    });

    return [ALL_DELIVERY_AREAS_FILTER_OPTION, currentLocationFilterOption].concat(deliveryAreaFilterOptions);
  }

  get recipientTypeFilterOptions() {
    const employee = get(this.currentAdmin, 'employee');
    const superiors = this.superiors || [];
    let filters = [];

    if (employee) {
      filters.push({ label: 'My deliveries', value: get(employee, 'id') });
    }

    const superiorFilters = superiors.map((superior) => {
      const label = `${get(superior, 'name')}'s deliveries`;
      const value = get(superior, 'id');
      return { label, value };
    });

    filters = filters.concat(superiorFilters);

    if (this.abilities.can('view all deliveries in delivery-log') || isPresent(superiors)) {
      filters.unshift({ label: 'All recipients', value: 'all' });
    }

    return filters;
  }

  get selectedRecipientTypeFilter() {
    const recipient = this.recipient;
    const recipientTypeFilterOptions = this.recipientTypeFilterOptions;

    const selectedOption = recipientTypeFilterOptions.find((option) => option.value === recipient);

    // @heroiceric: This is needed in order to show the "All recipients" option
    // as selected when filtering by the recipient multiselect
    return selectedOption || recipientTypeFilterOptions[0];
  }

  get statusFilterOptions() {
    const filters = STATUS_FILTER_OPTIONS.slice();

    if (this.abilities.can('view unidentified deliveries in delivery-log')) {
      filters.push({ label: 'Unidentified', value: 'unidentified' });
    }

    return filters;
  }

  get selectedStatusFilter() {
    const statusFilter = this.statusFilter;
    const statusFilterOptions = this.statusFilterOptions;

    return statusFilterOptions.find((option) => {
      return option.value === statusFilter;
    });
  }

  @enqueueTask({
    maxConcurrency: 10,
  })
  *markDeliveryAsPickedUpTask(delivery) {
    if (!get(delivery, 'acknowledgedAt')) {
      set(delivery, 'acknowledgedAt', new Date());
    }

    try {
      yield delivery.save();
    } catch (_error) {
      delivery.rollbackAttributes();
    }
    this._trackMarkDeliveryAsPickedUp(delivery);
  }

  @task
  chooseNewDeliveryAreaTask = {
    *perform() {
      const deferred = defer();

      this.abort = () => deferred.resolve(false);
      this.continue = (deliveryArea) => deferred.resolve(deliveryArea);

      return yield deferred.promise;
    },
  };

  @dropTask
  confirmBulkDeleteTask = {
    *perform() {
      const deferred = defer();

      this.abort = () => deferred.resolve(false);
      this.continue = () => deferred.resolve(true);

      return yield deferred.promise;
    },
  };

  @dropTask
  *confirmBulkDeleteContinueTask() {
    yield this.confirmBulkDeleteTask.last.continue();
  }

  @restartableTask
  *mutateTrackingNumberFilter(value) {
    yield timeout(zft(500));
    yield this.fetchDeliveriesTask.cancelAll();
    this.trackingNumberFilter = value;
    if (!isEmpty(this.trackingNumberFilter)) {
      if (isEmpty(this.trackingSearchJobId)) {
        this.trackingSearchJobId = uuid();
      }
      this.metrics.trackEvent('Deliveries Log - Search Requested', {
        job_id: this.trackingSearchJobId,
        search_query_value: this.trackingNumberFilter,
        filter_delivery_status: this.statusFilter,
        filter_delivery_area: this.deliveryAreaFilter,
        prequery_number_deliveries: this.deliveries?.length,
      });
    }
  }

  formattedFilterParams() {
    const params = {};

    const deliveryArea = this.deliveryAreaFilter;
    const recipient = this.recipient;
    const status = this.statusFilter;
    const trackingNumberPrefix = this.trackingNumberFilter;

    if (status === 'unidentified') {
      params.status = 'new,processing,failed';
    } else if (status && status !== 'all') {
      params.status = status;
    }

    if (recipient && recipient !== 'all') {
      params['recipient-employee'] = recipient;
    }

    if (deliveryArea === 'current location') {
      params['delivery-area'] = this.locationDeliveryAreas.mapBy('id').join(',');
    } else if (deliveryArea === 'all') {
      params['delivery-area'] = this.companyDeliveryAreas.mapBy('id').join(',');
    } else {
      params['delivery-area'] = deliveryArea;
    }

    if (trackingNumberPrefix) {
      params['search'] = trackingNumberPrefix;
    }

    return params;
  }

  @restartableTask
  *fetchDataTask(params = {}) {
    yield all([
      this.fetchCompanyDeliveryAreas.perform(),
      this.fetchRecipients.perform(params),
      this.fetchSuperiors.perform(),
    ]);

    yield this.fetchDeliveriesTask.perform(params);
  }

  @restartableTask
  *fetchCompanyDeliveryAreas() {
    const companyDeliveryAreas = yield findAllDeliveryAreasForCompany(this.store);
    return companyDeliveryAreas;
  }

  @restartableTask
  *fetchDeliveriesTask({ pageNumber, pageSize }) {
    const deliveryAreas = this.companyDeliveryAreas;

    if (isEmpty(deliveryAreas)) {
      return [];
    }

    const filter = this.formattedFilterParams();

    const deliveries = yield this.store.query('delivery', {
      filter,
      include: ['carrier', 'delivery-area'].join(','),
      page: { number: pageNumber, size: pageSize },
      sort: '-created-at',
    });
    if (!isEmpty(this.trackingNumberFilter)) {
      let ids = [];
      const count = this.deliveries?.length;
      if (count) {
        ids = deliveries.mapBy('id');
      }
      this.metrics.trackEvent('Deliveries Log - Search Result Returned', {
        job_id: this.trackingSearchJobId,
        search_query_value: this.trackingNumberFilter,
        filter_delivery_status: this.statusFilter,
        filter_delivery_area: this.deliveryAreaFilter,
        prequery_number_deliveries: count,
        postquery_number_deliveries: ids.length,
        delivery_ids: ids,
      });
    }

    return deliveries;
  }

  @restartableTask
  *fetchRecipients({ recipient }) {
    let recipients = [];

    if (recipient && recipient !== 'all' && recipient !== 'unidentified') {
      recipients = yield this.store.query('employee', {
        filter: {
          id: recipient,
        },
      });
    }

    return recipients;
  }

  @restartableTask
  *fetchSuperiors() {
    try {
      const employee = get(this.currentAdmin, 'employee');

      if (!employee) {
        return;
      }

      const superiorIds = employee.hasMany('bosses') && employee.hasMany('bosses').ids();

      if (!superiorIds.length) {
        return;
      }

      const superiors = yield this.store.query('employee', {
        filter: { id: superiorIds.join(','), deleted: false },
      });

      this.superiors = superiors.filter((superior) => get(superior, 'id') !== get(employee, 'id')); // prevent self bossing.
    } catch (e) {
      this.logger.error(e);
      this.superiors = [];
    }

    return this.superiors;
  }

  @dropTask
  *bulkMarkDeliveriesAsPickedUp(deliveries) {
    const deliveriesAjax = this.deliveriesAjax;
    const deliveryCount = this.selectedDeliveries?.length;
    const successMessage =
      deliveryCount > 1 ? `${deliveryCount} deliveries marked as picked up!` : 'Delivery marked as picked up!';

    const task = deliveriesAjax.bulkUpdateAttribute(deliveries, 'acknowledged-at', new Date());

    try {
      yield task;
      this.flashMessages.showAndHideFlash('success', successMessage);
      this.clearAllSelectedDeliveries();
      this.pageNumber = 1;
      this.fetchDeliveriesTask.perform({});
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', 'Error marking as picked up');
    }
  }

  @dropTask
  *bulkResendNotifications(deliveries) {
    const deliveriesAjax = this.deliveriesAjax;
    const deliveryCount = this.selectedDeliveries?.length;
    const successMessage = `${pluralize(deliveryCount, 'Notification', { withoutCount: true })} scheduled!`;

    const task = deliveriesAjax.bulkRenotify(deliveries);

    try {
      yield task;
      this.flashMessages.showAndHideFlash('success', successMessage);
      this.clearAllSelectedDeliveries();
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', 'Error resending notifications');
    }
  }

  @task
  *bulkChangeDeliveryArea(deliveries) {
    const deliveriesAjax = this.deliveriesAjax;

    const origins = [];
    for (const selectedDelivery of this.selectedDeliveries) {
      const deliveryArea = yield get(selectedDelivery, 'deliveryArea');
      origins.push(deliveryArea);
    }
    this.origins = origins;

    const destination = yield this.chooseNewDeliveryAreaTask.perform();
    const deliveryCount = this.selectedDeliveries?.length;
    const successMessage = deliveryCount > 1 ? `${deliveryCount} deliveries moved!` : 'Delivery moved!';

    if (!destination) {
      return;
    }

    const task = deliveriesAjax.bulkUpdateRelationship(deliveries, 'delivery-area', get(destination, 'id'));

    try {
      yield task;
      this.flashMessages.showAndHideFlash('success', successMessage);
      this.clearAllSelectedDeliveries();
      // since there could be a delivery area filter applied, need to reset so pagination doesn't get messed up
      this.pageNumber = 1;
      this.fetchDeliveriesTask.perform({});
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', 'Error moving deliveries.');
    }
  }

  @dropTask
  *bulkDeleteDeliveries(deliveries) {
    const deliveriesAjax = this.deliveriesAjax;
    const confirmed = yield this.confirmBulkDeleteTask.perform();
    const deliveryCount = this.selectedDeliveries?.length;
    const successMessage = deliveryCount > 1 ? 'Deliveries deleted!' : 'Delivery deleted!';

    if (!confirmed) {
      return;
    }

    const task = deliveriesAjax.bulkDelete(deliveries);

    try {
      yield task;
      this.flashMessages.showAndHideFlash('success', successMessage);
      this.clearAllSelectedDeliveries();
      // need to reset and re-fetch so pagination doesn't get screwed up
      this.pageNumber = 1;
      this.fetchDeliveriesTask.perform({});
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', 'Error deleting deliveries');
    }
  }

  @restartableTask
  *setTrackingNumberFilter(number) {
    yield timeout(500);

    return setProperties(this, {
      trackingNumberFilter: number,
      pageNumber: 1,
    });
  }

  @action
  clearAllSelectedDeliveries() {
    this.selectedDeliveries = [];
  }

  @action
  selectAllDeliveries() {
    this.selectedDeliveries = this.deliveries;
  }

  @action
  toggleDelivery(delivery, doSelect) {
    const selectedDeliveries = this.selectedDeliveries;

    if (doSelect) {
      this.selectedDeliveries = [delivery, ...selectedDeliveries];
    } else {
      const newSelectedDeliveries = selectedDeliveries.filter((d) => d !== delivery);
      this.selectedDeliveries = newSelectedDeliveries;
    }
  }

  async _trackMarkDeliveryAsPickedUp(delivery) {
    const deliveryArea = get(delivery, 'deliveryArea');
    const location = get(deliveryArea, 'location');
    const company = get(location, 'company');
    const recipient = await get(delivery, 'recipientEmployee.promise');
    const props = {
      company_id: get(company, 'id'),
      location_id: get(location, 'id'),
      delivery_area_id: get(deliveryArea, 'id'),
      delivery_id: delivery.id,
      recipient_name: recipient ? get(recipient, 'name') : null,
      recipient_id: recipient ? get(recipient, 'id') : null,
    };
    this.metrics.trackEvent('Deliveries Log - Mark as Picked Up Clicked', props);
  }

  @action
  clearTrackingNumberFilter() {
    this.trackingNumberFilter = '';
  }

  @action
  exportDeliveries() {
    const params = this.formattedFilterParams();
    return this.asyncExportManager.exportDeliveriesTask.perform(params);
  }

  @action
  setDeliveryAreaFilter(filter) {
    this.deliveryAreaFilter = get(filter, 'value');
    this.pageNumber = 1;
  }

  @action
  setRecipientFilter(users) {
    let recipient = '';

    if (isPresent(users)) {
      recipient = users.map((user) => get(user, 'id')).join(',');
    }

    this.recipient = recipient;
    this.pageNumber = 1;

    const statusFilter = this.statusFilter;

    if (recipient !== '' && statusFilter === 'unidentified') {
      this.statusFilter = DEFAULT_STATUS_FILTER;
    }
  }

  @action
  setRecipientTypeFilter(filter) {
    this.recipient = filter.value;
    this.pageNumber = 1;

    const statusFilter = this.statusFilter;

    if (filter.value !== 'all' && statusFilter === 'unidentified') {
      this.statusFilter = DEFAULT_STATUS_FILTER;
    }
  }

  @action
  setStatusFilter(filter) {
    this.statusFilter = filter.value;
    this.pageNumber = 1;

    if (filter.value === 'unidentified') {
      this.recipient = '';
    }
  }

  @action
  trackingNumberFilterClicked(inputValue) {
    this.trackingSearchJobId = uuid();
    this.metrics.trackEvent('Deliveries Log - Search Query Clicked', {
      job_id: this.trackingSearchJobId,
      search_text: inputValue,
    });
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import { TrackedObject } from 'tracked-built-ins';

type ColumnOption = {
  name: string;
  show: boolean;
  value: string;
};

const COLUMN_OPTIONS: ColumnOption[] = [
  { name: 'Visitor name', show: true, value: 'name' },
  { name: 'Status', show: false, value: 'entryStatus' },
  { name: 'Visitor type', show: true, value: 'type' },
  { name: 'Approval type', show: true, value: 'approvalType' },
  { name: 'Returning visitor', show: false, value: 'returningVisitor' },
  { name: 'Private notes', show: false, value: 'privateNotes' },
];

export type VisitorsApprovalsIndexModel = {
  columnOptions: ColumnOption[];
};

export default class VisitorsApprovalsIndexRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  beforeModel(): void {
    // user must be an admin that can review invites
    if (this.abilities.cannot('review invites')) {
      void this.router.transitionTo('unauthorized');
      return;
    }
  }

  model(): VisitorsApprovalsIndexModel {
    const columnOptions = COLUMN_OPTIONS.map((option) => new TrackedObject(option));
    return {
      columnOptions,
    };
  }
}

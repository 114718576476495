export const CATEGORIES_LIST = Object.freeze([
  { id: 'host-notifications', label: 'Communication' },
  { id: 'single-sign-on', label: 'SSO', required: true },
  { id: 'printing', label: 'Productivity' },
  { id: 'nda', label: 'File Storage' },
  { id: 'wi-fi', label: 'Wi-Fi' },
  { id: 'security', label: 'Access control' },
  { id: 'health-safety', label: 'Health and wellness' },
  { id: 'crm', label: 'Sales and marketing' },
  { id: 'hr-recruiting', label: 'HR and recruiting' },
  { id: 'parking', label: 'Space management' },
  { id: 'rooms', label: 'Space management' },
  { id: 'customer-support', label: 'Customer Support' },
  { id: 'labs', label: 'Build your own', required: true },
]);

export const categoryName = (category) => {
  const found = CATEGORIES_LIST.find(({ id }) => id === category);
  if (!found) {
    return 'Integration';
  }
  return found.label;
};

export default {
  CATEGORIES_LIST,
  categoryName,
};

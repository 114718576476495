import moment from 'moment-timezone';

type Options = {
  header?: Record<string, unknown>;
  payload?: Record<string, unknown>;
  signature?: string;
};

export default (options: Options = {}): string => {
  let { header, payload, signature } = options;

  if (!header) {
    header = { alg: 'ES384', typ: 'JWT' };
  }

  if (!payload) {
    payload = {
      exp: moment().add(1, 'day').format('X'),
    };
  }

  if (!signature) {
    signature = 'irrelevant';
  }

  const encodedPayload = window.btoa(JSON.stringify(payload));
  const encodedHeader = window.btoa(JSON.stringify(header));

  return `${encodedHeader}.${encodedPayload}.${signature}`;
};

export function decodeJwt(jwt: string): Options {
  const [encodedHeader, encodedPayload, signature] = jwt.split('.');
  const header: Record<string, unknown> = encodedHeader
    ? <Record<string, unknown>>JSON.parse(window.atob(encodedHeader))
    : {};
  const payload: Record<string, unknown> = encodedPayload
    ? <Record<string, unknown>>JSON.parse(window.atob(encodedPayload))
    : {};

  return {
    header,
    payload,
    signature,
  };
}

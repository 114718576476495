import Component from '@glimmer/component';
import { formatInTimeZone } from 'date-fns-tz';

const DATE_FORMAT = 'MMM d, yyyy - h:mm a';
const TIME_FORMAT = 'h:mm a';

/**
 * @param {string} timezone
 * @param {Date} value
 * @param {boolean} showFullDate
 */
export default class ArrivedAtColumnComponent extends Component {
  get timezone() {
    return this.args.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  get value() {
    return this.args.value
      ? formatInTimeZone(this.args.value, this.timezone, this.args.showFullDate ? DATE_FORMAT : TIME_FORMAT)
      : null;
  }
}

import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type Store from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type AreaMapModel from 'garaje/models/area-map';
import type DeskModel from 'garaje/models/desk';
import type MapFeatureModel from 'garaje/models/map-feature';
import type MapFloorModel from 'garaje/models/map-floor';
import type WorkplaceDayModel from 'garaje/models/workplace-day';
import type ApolloService from 'garaje/services/apollo-extension';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';

import type MapsLiveShowController from './controller';

interface ModelParams {
  mapFeatures: ArrayProxy<MapFeatureModel>;
}

export interface ModelResponse {
  areaMap: AreaMapModel;
  mapFloor: MapFloorModel | null;
  mapFloors: ArrayProxy<MapFloorModel>;
  mapFeatures: ArrayProxy<MapFeatureModel>;
  desksInLocation: ArrayProxy<DeskModel>;
  workplaceDays: WorkplaceDayModel[];
}

interface AfterModelParams {
  mapFloors: ArrayProxy<MapFloorModel>;
  mapFloor: MapFloorModel;
}

export default class MapsLiveShowRoute extends Route {
  @service declare store: Store;
  @service declare state: StateService;
  @service declare metrics: MetricsService;
  @service declare router: RouterService;
  @service declare abilities: AbilitiesService;
  @service declare apollo: ApolloService;

  queryParams = {
    selectedTime: {
      refreshModel: false,
    },
    selectedResourceFilter: {
      refreshModel: false,
    },
    selectedEmployeeId: {
      refreshModel: false,
    },
  };

  async beforeModel(): Promise<void> {
    const mapFloors = await this.store.query('map-floor', { filter: { location: this.state.currentLocation?.id } });

    const hasMapFloorWithRasterImage = isPresent(
      mapFloors.filter((floor) => {
        return floor.rasterImageUrl;
      }),
    );

    if (!hasMapFloorWithRasterImage && this.abilities.cannot('visit-live-maps maps')) {
      return void this.router.transitionTo('unauthorized');
    }

    return void this.metrics.trackEvent('Accessed live map');
  }

  async model({ floor_id }: { floor_id: string }): Promise<ModelResponse> {
    const { areaMap } = this.modelFor('spaces.maps') as { areaMap: AreaMapModel };
    const { currentLocation } = this.state;
    const mapFloors: ArrayProxy<MapFloorModel> = await areaMap.mapFloors;
    const mapFloor = this.store.peekRecord('map-floor', floor_id);
    const mapFeatures = await this.store.query('map-feature', {
      filter: {
        floor: floor_id,
      },
    });

    const desksInLocation = this.store.query('desk', {
      filter: {
        'location-id': currentLocation?.id,
        booked: true,
        permanentlyAssigned: true,
      },
    });

    let workplaceDays: WorkplaceDayModel[] = [];

    if (this.state.currentLocationEnabledDesks?.id) {
      await this.store.findRecord('desk-location', this.state.currentLocationEnabledDesks.id, {
        reload: true,
        include: 'workplace-days',
      });

      workplaceDays = this.store.peekAll('workplaceDay').filter((day: WorkplaceDayModel) => {
        return day.belongsTo('deskLocation').id() === this.state.currentLocationEnabledDesks?.id;
      }) as WorkplaceDayModel[];
    }

    return {
      areaMap,
      mapFloor,
      mapFloors,
      mapFeatures,
      desksInLocation,
      workplaceDays,
    };
  }

  afterModel({ mapFloors, mapFloor }: AfterModelParams): void {
    const hasFloorInLocation = mapFloors.any((floor: MapFloorModel) => floor.id === mapFloor.id);

    if (!hasFloorInLocation) {
      void this.router.transitionTo('spaces.maps.live.show', mapFloors.firstObject?.id || '');
    }

    if (!mapFloor.rasterImageUrl) {
      const floorWithUrl = mapFloors.find((floor) => floor.rasterImageUrl);

      if (floorWithUrl) {
        return void this.router.transitionTo('spaces.maps.live.show', floorWithUrl.id);
      }

      return void this.router.transitionTo('spaces.maps');
    }
  }

  setupController(controller: MapsLiveShowController, model: ModelParams, transition: Transition): void {
    super.setupController(controller, model, transition);
    controller.mapFeatures = model.mapFeatures.toArray();
    void controller.loadResoucesDataTask.perform();
    controller.setSelectedDesk();
  }
}

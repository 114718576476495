/* eslint-disable ember/no-mixins */
import { service } from '@ember/service';
import Model, { type AsyncBelongsTo, type AsyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';
import type Store from '@ember-data/store';
import { lastValue, task } from 'ember-concurrency';
import ContactConfigurableMixin from 'garaje/mixins/contact-configurable';
import CsvUploadableMixin from 'garaje/mixins/csv-uploadable';
import { squareFeetToSquareInches, squareInchesToSquareFeet } from 'garaje/utils/area-conversions';
import adapter from 'garaje/utils/decorators/adapter';
import { TrackedObject, TrackedSet } from 'tracked-built-ins';

import type CompanyModel from './company';
import type ConnectFloorModel from './connect-floor';
import type DeviceModel from './device';
import type PropertyBadgeModel from './property-badge';
import type PropertyDeviceConfigModel from './property-device-config';
import type TenantModel from './tenant';

const BaseClass = <typeof Model>Model.extend(ContactConfigurableMixin, CsvUploadableMixin);

@adapter('multi-tenancy')
class ZoneModel extends BaseClass {
  @service declare store: Store;

  /**
   * The property device config for this zone. It is safe to assume that a property will
   * always have a config, if this is not defined, then the config hasn't been loaded from the task in this class.
   */
  @lastValue('loadConfigTask') config?: PropertyDeviceConfigModel;

  /**
   * The property badge for this zone. It is safe to assume that a property will
   * always have a badge, if this is not defined, then the badge hasn't been loaded from the task in this class.
   */
  @lastValue('loadBadgeTask') badge?: PropertyBadgeModel;

  @attr('string') declare accentColor: string;
  @attr('tracked-object', { defaultValue: () => new TrackedObject() }) declare address: {
    time_zone: string;
    address?: string;
  };

  @attr('string') declare coverPhotoUrl: string;
  @attr('string') declare coverPhoto: string | null;
  @attr('tracked-set', { defaultValue: () => new TrackedSet() }) declare enabledLocales: TrackedSet<string>;
  @attr('string') declare locale: string;
  @attr('string') declare logo: string | null;
  @attr('string') declare name: string;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('boolean') declare visitorPhotosEnabled: boolean;
  @attr('boolean') declare employeeRebadgingEnabled: boolean;
  @attr('boolean') declare employeeMobileCredentialingEnabled: boolean;
  @attr('number') declare area: number | null;

  /**
   * Will only return ID if included because of difference in API serializer
   */
  @belongsTo('company') declare company: CompanyModel | null;
  @belongsTo('connect-floor', { inverse: 'suites' }) declare floor:
    | AsyncBelongsTo<ConnectFloorModel>
    | ConnectFloorModel;
  @belongsTo('zone', { inverse: 'children' }) declare parent: AsyncBelongsTo<ZoneModel>;
  @hasMany('zone', { inverse: 'parent' }) declare children: AsyncHasMany<ZoneModel>;
  @hasMany('tenant', { inverse: 'suites' }) declare tenants: AsyncHasMany<TenantModel>;
  @hasMany('device') declare devices: AsyncHasMany<DeviceModel>;

  get childZoneCount(): number {
    //  https://github.com/typed-ember/ember-cli-typescript/issues/1416
    return (<ZoneModel>this).hasMany('children').ids().length;
  }

  get timezone(): string {
    return this.address.time_zone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  get areaSqFt(): number | null {
    if (!this.area) return null;
    return squareInchesToSquareFeet(this.area);
  }
  set areaSqFt(value: string) {
    const area = parseFloat(value);
    if (isNaN(area)) return;
    this.area = squareFeetToSquareInches(area);
  }

  loadConfigTask = task({ drop: true }, async () => {
    const configs = await this.store.query('property-device-config', {
      include: 'flows,flows.summary-page,welcome-screen-slides',
      filter: {
        property: this.id,
      },
    });
    return configs.firstObject;
  });

  loadBadgeTask = task({ drop: true }, async () => {
    const badges = await this.store.query('property-badge', {
      filter: {
        property: this.id,
      },
    });
    return badges.firstObject;
  });
}

export default ZoneModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    zone: ZoneModel;
  }
}

import { A } from '@ember/array';
import Component from '@glimmer/component';
import type SkinnyLocationModel from 'garaje/models/skinny-location';

const DEFAULT_PAGE_SIZE = 30;
const PRINTER_MODE_FILTER_OPTIONS = [
  { label: 'All modes', value: '' },
  { label: 'Bluetooth mode', value: 'bluetooth' },
  { label: 'Network mode', value: 'ip' },
];
const PRINTER_STATUS_FILTER_OPTIONS = [
  { label: 'All printer statuses', value: '' },
  { label: 'Operational', value: 'connected' },
  { label: 'Offline', value: 'Not connected' },
];

interface GlobalDevicesPrintersFiltersComponentSignature {
  Args: {
    locationId: string;
    changeLocationId: (locationId: string) => void;
    locations: SkinnyLocationModel[];
    changePrinterModel: (printerModel: string) => void;
    printerModel: string;
    printerModels: Array<string>;
    changePrinterMode: (printerMode: string) => void;
    printerMode: string;
    changePrinterStatus: (printerStatus: string) => void;
    printerStatus: string;
    pageNumber?: number;
    pageSize?: number;
    recordCount: number;
    setPageNumber: (pageNumber: number) => void;
    totalRecords?: number;
  };
}

export default class GlobalDevicesPrintersFiltersComponent extends Component<GlobalDevicesPrintersFiltersComponentSignature> {
  printerModeFilterOptions = PRINTER_MODE_FILTER_OPTIONS;
  printerStatusFilterOptions = PRINTER_STATUS_FILTER_OPTIONS;
  defaultLocationFilter = { name: 'All locations', id: '' };

  get pageNumber(): number {
    return this.args.pageNumber ?? 1;
  }
  get pageSize(): number {
    return this.args.pageSize ?? DEFAULT_PAGE_SIZE;
  }
  get totalRecords(): number {
    return this.args.totalRecords ?? 0;
  }

  /**
   * Filters
   */
  get locationOptions(): Partial<SkinnyLocationModel>[] {
    return [this.defaultLocationFilter, ...this.args.locations];
  }

  get printerModelFilterOptions(): { label: string; value: string }[] {
    const models = this.args.printerModels;
    const modelFilters = [{ label: 'All device types', value: '' }];

    if (models) {
      models.forEach((model) => {
        A(modelFilters).addObject({ label: model, value: model });
      });
    }

    return modelFilters;
  }
}

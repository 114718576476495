// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import { task, dropTask } from 'ember-concurrency';
import { v1 as uuid } from 'uuid';

export default class RoomsDowngradeWarningModalComponent extends Component {
  @service metrics;

  title = 'Deactivate your rooms to downgrade';

  // eslint-disable-next-line ember/no-component-lifecycle-hooks
  didReceiveAttrs() {
    super.didReceiveAttrs();
    set(this, 'trackingId', uuid());
    this.trackModalEnteredTask.perform();
  }

  @task
  *trackModalEnteredTask() {
    yield this.metrics.trackPage('Modal entered', {
      action_id: this.trackingId, // Auto-generated ID to link multiple properties firing to a single action
      modal_title: this.title, // title of the modal
      modal_type: 'subscription_change', // modal type
      modal_purpose: 'downgrade_warning', // purpose of modal
      via_object: this.viaObject, // object interacted with that triggered the modal opening
      via_action: 'click', // type of interaction
      via_label: this.viaLabel, // Label of the button or element being interacted with
      num_warnings_shown: 0, // Number of warning boxes displayed in the Modal. Can be 0.
      warning_text: null, // Text list (JSON is ok) of the message displayed in the warning boxes. Useful to track in case we want to optimize these in the future.
    });
  }

  @dropTask
  *trackModalExited(object, label) {
    yield this.metrics.trackPage('Modal exited', {
      action_id: this.trackingId, // Auto-generated ID to link multiple properties firing to a single action
      modal_title: this.title, // title of the modal
      modal_type: 'subscription_change', // modal type
      modal_purpose: 'downgrade_warning', // purpose of modal
      via_object: object, // object interacted with that triggered the modal exiting
      via_action: 'click', // type of interaction
      via_label: label, // Label of the button or element being interacted with
    });
  }
}

import type EmberArray from '@ember/array';
import { A } from '@ember/array';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type UserDatum from 'garaje/models/user-datum';
import { DISCARDED_RESPONSE_STRINGS } from 'garaje/utils/enums';
import { reads } from 'macro-decorators';

interface InvitesTableInviteListPlainCellArgs {
  content?: {
    userData?: UserDatum[];
    [key: string]: unknown;
  };
  delay?: number;
  lineHeight?: number;
  lines?: number;
  name?: string;
  popperContainer?: string;
  side?: string;
  text?: string;
  tooltipClass?: string;
}

export default class InvitesTableInviteListPlainCell extends Component<InvitesTableInviteListPlainCellArgs> {
  @tracked textWasTruncated: boolean = false;

  @reads('args.delay', 400) delay!: number;
  @reads('args.lineHeight', 1.25) lineHeight!: number;
  @reads('args.lines', 2) lines!: number;
  @reads('args.popperContainer', '.ui__page') popperContainer!: string;
  @reads('args.side', 'top') side!: string;
  @reads('args.tooltipClass', 'break-word max-w-sm z-50') tooltipClass!: string;

  contentElementId = guidFor(this);

  get data(): string {
    const { text, content, name } = this.args;

    if (text) return text;
    if (!(content && name)) return '';

    const userData: EmberArray<UserDatum> = A(content['userData'] ?? []);
    const field = userData.findBy('field', name);

    if (field) {
      const value = (field?.value ?? '') as string;

      return value === DISCARDED_RESPONSE_STRINGS.PLACEHOLDER ? `${DISCARDED_RESPONSE_STRINGS.DISPLAY_TEXT}` : value;
    }

    return '';
  }
}

import Model, { belongsTo, attr } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

import type CompanyModel from './company';

@adapter('integration')
class ZapierIntegrationModel extends Model {
  @belongsTo('company', { async: true }) declare company: AsyncBelongsTo<CompanyModel>;
  @attr('string') declare granteeEmails: string;
  @attr('number') declare entryCreatedCount: number;
  @attr('number') declare signOutCount: number;
  @attr('number') declare inviteCreatedCount: number;
}

export default ZapierIntegrationModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'zapier-integration': ZapierIntegrationModel;
  }
}

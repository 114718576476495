import type { AsyncBelongsTo } from '@ember-data/model';
import Component from '@glimmer/component';
import DeviceModel from 'garaje/models/device';

interface SignInOutDetailsComponentSignature {
  Args: {
    device?: DeviceModel | AsyncBelongsTo<DeviceModel>;
    via: string | null;
    by: string | null;
    type: string;
  };
  Element: HTMLElement;
}

export default class SignInOutDetailsComponent extends Component<SignInOutDetailsComponentSignature> {
  get viaText(): string {
    const via = this.args.via;
    const device = this.args.device;
    const deviceName = device instanceof DeviceModel ? device.name : device?.content?.name;
    if (!via) {
      return '';
    }
    // We only want to force the first character to lowercase,
    // to retain correct casing specifically for iPad
    let viaLowerCase = via[0]!.toLowerCase() + via.slice(1);

    if (via === 'iPad' && deviceName) {
      viaLowerCase += `: ${deviceName}`;
    }
    return viaLowerCase;
  }
}

import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';

import type CompanyModel from './company';

export default class VfdCompanyConfiguration extends Model {
  @belongsTo('company', { async: true }) declare company: AsyncBelongsTo<CompanyModel>;

  @attr('boolean') declare callTranscriptionEnabled: boolean;
  @attr('boolean') declare callRecordingEnabled: boolean;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface VfdCompanyConfiguration {
    'vfd-company-configuration': VfdCompanyConfiguration;
  }
}

import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type AbilitiesService from 'ember-can/services/abilities';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import type StateService from 'garaje/services/state';

interface LocationsAfterOptionsComponentArgs {
  onClose: () => unknown;
}

export default class LocationsAfterOptionsComponent extends Component<LocationsAfterOptionsComponentArgs> {
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare skinnyLocations: SkinnyLocationsService;
  @service declare abilities: AbilitiesService;

  get unusedQuantity(): number {
    return (this.state.visitorsSubscription?.quantity ?? 0) - (this.skinnyLocations.active.length ?? 0);
  }

  get showUpgradeLink(): boolean {
    if (!this.state.visitorsSubscription) return true;

    // always show link to create a location if the current user can create locations and if they are on a trial
    const canCreateLocationsOnTrial =
      this.abilities.can('create locations') &&
      this.state.visitorsSubscription.onTrial &&
      !this.state.visitorsSubscription.subscribed;

    return this.unusedQuantity < 1 && !canCreateLocationsOnTrial;
  }

  @action
  async navigate(): Promise<void> {
    if (this.showUpgradeLink) {
      await this.router.transitionTo('billing');
    } else {
      await this.router.transitionTo('new-location', { queryParams: { fromPropertyConnection: true } });
    }
    this.args.onClose();
  }
}

import Component from '@glimmer/component';
import { action } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default class ProtectCapacityLimitsUpsell extends Component {
  @service state;
  @service abilities;

  @reads('state.billingCompany.blockSelfServe') isNotSelfServeBilling;

  get showUpgradeButton() {
    return this.abilities.can('visit billing') && !this.isNotSelfServeBilling;
  }

  @action
  openChat() {
    window.Intercom('show');
  }
}

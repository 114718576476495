import Route from '@ember/routing/route';
export default class LocationsGroupsNewRoute extends Route {
  setupController(controller) {
    super.setupController(...arguments);
    controller.reset();
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.reset();
    }
  }
}

/* eslint-disable ember/no-component-lifecycle-hooks */
import { set, setProperties } from '@ember/object';
import { later, debounce } from '@ember/runloop';
import { htmlSafe } from '@ember/template';
import $ from 'jquery';
import LabeledInput from 'garaje/pods/components/labeled-input/component';
import layout from 'garaje/pods/components/labeled-input/template';
import zft from 'garaje/utils/zero-for-tests';

import mailcheck from 'mailcheck';

export default LabeledInput.extend({
  layout,

  label: 'Email',

  init() {
    this._super(...arguments);
    this._label = this.label;
  },

  focusOut() {
    later(
      this,
      function () {
        if (!this.isDestroyed) {
          set(this, 'label', this._label);
        }
      },
      zft(1000),
    );
  },

  input() {
    debounce(this, this.doMailCheck, 500);
    return this._super(...arguments);
  },

  doMailCheck() {
    // ember-concurrency
    if (this.isDestroyed) {
      return false;
    }

    // normalize email before we check
    const email = this.value.trim();
    set(this, 'value', email);

    const mailcheckOptions = {
      email,
      suggested: (suggestion) => {
        setProperties(this, {
          label: htmlSafe(`Did you mean <a>${suggestion.full}</a>?`),
          suggestedEmail: suggestion.full,
        });
      },
      empty: () => {
        setProperties(this, { label: this._label, suggestedEmail: null });
      },
    };

    if (this.domain) {
      mailcheckOptions.domains = [this.domain];
    }

    mailcheck.run(mailcheckOptions);
  },

  // eslint-disable-next-line ember/no-actions-hash
  actions: {
    labelClicked() {
      if (this.suggestedEmail) {
        set(this, 'value', this.suggestedEmail);
      }
      $(`#${this.elementId} input`).focus();
    },
  },
});

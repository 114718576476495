import type StateService from 'garaje/services/state';
import { START_SUBSCRIPTION_PLAN_ID } from 'garaje/utils/enums';

export const startingSubscriptionPlanId = ({
  app,
  state,
}: {
  app: string;
  state: StateService;
}): string | undefined => {
  const monthlyPlanId = START_SUBSCRIPTION_PLAN_ID[<keyof typeof START_SUBSCRIPTION_PLAN_ID>app.toUpperCase()];
  const period = state.billingCompany.subscriptionPlans.length
    ? state.billingCompany.subscriptionPlans.firstObject?.period
    : 'monthly';

  if (period === 'monthly') {
    return monthlyPlanId;
  } else {
    return state.allPlans!.findBy('id', monthlyPlanId)?.alternate.id;
  }
};

import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type DeviceModel from 'garaje/models/device';
import type LocationModel from 'garaje/models/location';
import type PrinterModel from 'garaje/models/printer';
import VisitorsDevicesIpadsNewRoute from 'garaje/pods/visitors/devices/ipads/new/route';
import type StateService from 'garaje/services/state';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

import type { VfdDevicesIpadsRouteModel } from '../route';

interface VirtualFrontDeskDevicesIpadsNewRouteModel {
  location: LocationModel;
  device: DeviceModel;
  printers: RecordArray<PrinterModel>;
}

export default class VirtualFrontDeskDevicesIpadsNewRoute extends VisitorsDevicesIpadsNewRoute {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare store: StoreService;
  @service declare state: StateService;

  controllerName = 'visitors/devices/ipads/new';
  templateName = 'visitors/devices/ipads/new';

  beforeModel(): void {
    if (this.abilities.cannot('create devices')) {
      void this.router.transitionTo('virtual-front-desk.devices');
    }
  }

  model(): Promise<VirtualFrontDeskDevicesIpadsNewRouteModel> {
    const { location, printers } = <VfdDevicesIpadsRouteModel>this.modelFor('virtual-front-desk.devices.ipads');
    const device = this.store.createRecord('device', { location });
    return hash({ device, location, printers });
  }
}

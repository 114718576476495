import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';

import { CsvUploadDestination, CsvUploadStatus } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';
import zft from 'garaje/utils/zero-for-tests';

const STATUS_POLL_INTERVAL = 750; // amount of time (in ms) to wait between polling calls

/**
 * @param {Property} property        - the property that the blocklist entries should be saved to
 * @param {Function} uploadCompleted - action triggered when upload process completes successfully. Receives no arguments.
 */
export default class PropertyBlocklistCsvImportComponent extends Component {
  @service('blocklistFilter') blocklistFilterService;
  @service flashMessages;
  @service store;
  @service uploaderFactory;

  @tracked importedCount = 0;

  @task
  *saveBlocklistFiltersTask(csvFile) {
    let csvUpload = this.store.createRecord('csv-upload');
    csvUpload.destination = CsvUploadDestination.BLOCKLIST_FILTERS;
    csvUpload.csvUploadable = this.args.property;

    const url = urlBuilder.v3.csvUpload();
    const uploader = this.uploaderFactory.createUploader({
      url,
    });
    try {
      const response = yield uploader.upload(csvFile, { data: JSON.stringify(csvUpload.serialize().data) });
      csvUpload.unloadRecord();
      this.store.pushPayload(response);
      csvUpload = this.store.peekRecord('csv-upload', response.data.id);
      yield this.waitForUploadToFinishTask.perform(csvUpload);
      // reload blocklist filters
      yield this.blocklistFilterService.queryByPropertyTask.perform(this.args.property.id);
      this.args.uploadCompleted && this.args.uploadCompleted();
    } catch (e) {
      csvUpload.unloadRecord();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      console.error(e); // eslint-disable-line no-console
      throw e; // re-throw the error so the base component can clear the upload
    }
  }

  @task
  *waitForUploadToFinishTask(csvUpload) {
    let done = false;
    do {
      yield timeout(zft(STATUS_POLL_INTERVAL));
      const {
        data: { attributes },
      } = yield csvUpload.uploadStatus();
      const status = attributes['upload-status'];
      switch (status) {
        case CsvUploadStatus.IN_PROGRESS:
          this.importedCount = attributes['imported-count'];
          break;
        case CsvUploadStatus.DONE:
          done = true;
          break;
        default:
          throw new Error(attributes.message);
      }
    } while (!done);
  }
}

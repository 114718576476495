const EVENT_KEY_PREFIX = 'data-payload-';
const EVENT_KEY_PREFIX_REGEX = new RegExp(`^${EVENT_KEY_PREFIX}`);

/**
 * Given a response from a POST call to /a/hooks/v1/zones/hooks/ui/trigger, parse any
 * event payloads.
 * Because integrations/the hooks service do not permit totally generic JSON to be returned
 * (they're built around the idea of an integration returning content to be used to build HTML)
 * we have to resort a workaround where the parts we care about are includes as part of the response.
 * In particular, we want the keys in the response JSON with the prefix indicated by the EVENT_KEY_PREFIX constant.
 * These keys (and their values) are extracted, the prefix removed, and the result transformed into a POJO
 * with only the relevant data.
 *
 * For example, a response like this:
 * ```
 * [
 *   {
 *     "id": "82a6cae1-9c21-4f52-8f3c-9fe1c989e6bd",
 *     "clientId": "c6c61ea6-fc81-11ec-8117-c3fe4dd69b5f",
 *     "clientName": "OnGuard (Release Candidate)",
 *     "clientIcon": null,
 *     "triggerName": "property_invite_log_table",
 *     "hookId": "6edd2fbe-4837-4e48-9afe-3bb18aa376c6",
 *     "hookLabel": "Building credentials",
 *     "type": "UI",
 *     "status": "DONE",
 *     "resourceType": "INVITE",
 *     "resourceId": "44397750",
 *     "responseJSON": {
 *         "link": "https://envoy-plugin-lenel.apps.envoy.com/app-extension/action/create?baz=1",
 *         "size": "slim",
 *         "type": "button",
 *         "label": "Assign",
 *         "style": "primary-outlined",
 *         "data-payload-event": "showModal",
 *         "data-payload-url": "https://envoy-plugin-lenel.apps.envoy.com/app-extension/action/create/?foo=bar"
 *     },
 *     "responseMessage": null,
 *     "attachments": []
 *   }
 * ]
 * ```
 * is converted to:
 * ```
 * [
 *   {
 *     "event": "showModal",
 *     "url": "https://envoy-plugin-lenel.apps.envoy.com/app-extension/action/create/?foo=bar"
 *   }
 * ]
 * ```
 */
export function parseEventPayloads(response) {
  if (!response.data) return [];

  const payloads = [];
  for (const item of response.data) {
    if (!item.responseJSON) continue;
    const data = item.responseJSON;

    const matchingKeys = Object.keys(data).filter((key) => key.startsWith(EVENT_KEY_PREFIX));
    if (matchingKeys.length === 0) continue;
    const payload = matchingKeys.reduce(function (payload, key) {
      const bareName = key.replace(EVENT_KEY_PREFIX_REGEX, '');
      payload[bareName] = data[key];
      return payload;
    }, {});
    payloads.push(payload);
  }

  return payloads;
}

import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import AbstractAgreementPageModel from 'garaje/models/abstract/abstract-agreement-page';
import type GlobalAgreementModel from 'garaje/models/global-agreement';
import type GlobalFlowModel from 'garaje/models/global-flow';
import adapter from 'garaje/utils/decorators/adapter';
import NDA_TEXT from 'garaje/utils/nda-text';

@adapter('agreement-page')
class GlobalAgreementPageModel extends AbstractAgreementPageModel {
  @hasMany('global-agreement') declare agreements: AsyncHasMany<GlobalAgreementModel>;
  @belongsTo('global-flow') declare flow: AsyncBelongsTo<GlobalFlowModel>;

  @attr('string', { defaultValue: NDA_TEXT }) declare body: string;
}

export default GlobalAgreementPageModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'global-agreement-page': GlobalAgreementPageModel;
  }
}

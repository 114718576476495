import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type StoreService from '@ember-data/store';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import type UserDocumentModel from 'garaje/models/user-document';
import type UserDocumentTemplateModel from 'garaje/models/user-document-template';
import type StateService from 'garaje/services/state';
import { routeEvent } from 'garaje/utils/decorators/route';

import type { ProfileDocumentRouteModel } from '../route';

import type UploadController from './controller';

export interface ProfileDocumentUploadRouteModel {
  userDocumentTemplate?: UserDocumentTemplateModel;
  userDocument?: DetailedChangeset<UserDocumentModel>;
}

export default class ProfileDocumentUploadRoute extends Route {
  declare controller: UploadController;

  @service declare state: StateService;
  @service declare store: StoreService;

  model(): ProfileDocumentUploadRouteModel {
    const { userDocumentTemplate, userDocuments } = <ProfileDocumentRouteModel>this.modelFor('profile.document');

    let userDocument = userDocuments.sortBy('sortableDate').lastObject;
    let userDocumentChangeset: DetailedChangeset<UserDocumentModel> | undefined;

    if (!userDocument || userDocument.isExpired || userDocument.isExpiringSoon || userDocument.isDenied) {
      userDocument = this.store.createRecord('user-document');
      userDocument.userDocumentTemplate = userDocumentTemplate!;
      userDocument.user = this.state.currentUser;

      userDocumentChangeset = Changeset(userDocument);
    } else {
      // will redirect
      userDocumentChangeset = undefined;
    }

    return {
      userDocumentTemplate,
      userDocument: userDocumentChangeset,
    };
  }

  afterModel(model: ProfileDocumentUploadRouteModel): void {
    if (!isPresent(model.userDocument)) {
      void this.transitionTo('profile.document', model.userDocumentTemplate!.identifier); // eslint-disable-line ember/no-deprecated-router-transition-methods
    }
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    const { userDocument } = <ProfileDocumentUploadRouteModel>this.modelFor(this.routeName);

    const isDirty =
      userDocument?.hasDirtyAttributes ||
      userDocument?.userDocumentAttachments.any((attachment) => <boolean>(<unknown>attachment.hasDirtyAttributes));

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    if (!transition.targetName.includes(this.routeName) && isDirty) {
      // eslint-disable-next-line ember/no-controller-access-in-routes
      this.controller.confirmNavigation(transition);
    }

    return;
  }
}

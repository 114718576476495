import Service, { service } from '@ember/service';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import urlBuilder from 'garaje/utils/url-builder';

import type FeatureFlagsService from './feature-flags';

export enum CohoWorkplaceEventNames {
  ViewAnalyticsDashboard = 'analytics_dashboard_viewed',
  SendScheduleReport = 'scheduled_report_sent',
  MapEdited = 'map_edited',
  DraftEdited = 'draft_edited',
  NeighborhoodCreated = 'neighborhood_created',
  NeighborhoodEdited = 'neighborhood_edited',
  ActivityLogChecked = 'activity_log_checked',
  LocationAssigned = 'location_assigned',
  GlobalAdminAssigned = 'global_admin_assigned',
  EMNOCreateTemplate = 'emergency_notification_template_created',
  EMNOEditTemplate = 'emergency_notification_template_edited',
  EMNOSendNotification = 'emergency_notification_sent',
  EmployeeCreated = 'employee_created',
  EmployeeEdited = 'employee_edited',
  EmployeeCSVUploaded = 'employee_csv_uploaded',
  CustomRoleCreated = 'custom_role_created',
  ExportEmployees = 'export_employees',
  SetCapacityLimit = 'set_capacity_limit',
  AddNewLocation = 'add_new_location',
  InactivityTimeoutEnabled = 'inactivity_timeout_enabled',
  InactivityTimeoutDisabled = 'inactivity_timeout_disabled',
  AllEmployeesPageViewed = 'all_employees_page_viewed',
}

export interface CohoEventRequestBody {
  user_id: string;
  event_name: string;
  product: string;
  timestamp: string;
  company_id: string;
  location_id: string;
}

export interface CohoRequiredParams {
  product: string;
}

const COHO_ENDPOINT = 'coho/proxy';

export default class CohoService extends Service {
  @service declare state: StateService;
  @service declare metrics: MetricsService;
  @service declare featureFlags: FeatureFlagsService;

  sendEvent(eventName: CohoWorkplaceEventNames, data: CohoRequiredParams): void {
    if (this.featureFlags.isEnabled('growth_coho_service')) {
      try {
        const { currentUser, currentLocation, currentCompany, currentAdmin } = this.state;
        let userID = currentUser?.id;
        if (!userID) {
          userID = currentAdmin?.id;
        }

        const requestData: CohoEventRequestBody = {
          user_id: userID,
          event_name: eventName,
          product: data.product,
          timestamp: new Date().toISOString(),
          company_id: currentCompany?.id,
          location_id: currentLocation?.id,
        };

        const endpoint = urlBuilder.growth.getGrowthServiceUrl() + COHO_ENDPOINT;

        fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ data: requestData }),
        })
          .then()
          .catch((e: Error) => {
            this.metrics.trackEvent('coho_api_error', { cohoEventName: eventName, error: e });
          });
      } catch (e) {
        this.metrics.trackEvent('coho_api_error', { cohoEventName: eventName, error: e });
      }
    }
  }
}

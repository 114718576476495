import ApplicationSerializer from './application';

export default class DeviceSerializer extends ApplicationSerializer {
  attrs = {
    grantExpiresAt: {
      serialize: false,
    },
    isSleeping: {
      serialize: false,
    },
    isConnected: {
      serialize: false,
    },
    maximumOsVersion: {
      serialize: false,
    },
    appVersion: {
      serialize: false,
    },
    osVersion: {
      serialize: false,
    },
    currentIpadAppVersion: {
      serialize: false,
    },
  };
}

import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import Service, { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type EmployeeModel from 'garaje/models/employee';
import type VisitorsOnboardingService from 'garaje/services/visitors-onboarding';
// import type { RecordArray } from 'garaje/utils/type-utils';

export default class EmployeeDirectoryService extends Service {
  @service declare store: StoreService;
  @service declare visitorsOnboarding: VisitorsOnboardingService;

  @tracked employees: NativeArray<EmployeeModel> = A();
  @tracked employeesCount: number = 0;
  @tracked totalEmployees: number | null = null;

  addEmployee(employee: EmployeeModel): void {
    this.employees.addObject(employee);
    this.employeesCount++;

    if (this.totalEmployees) {
      this.totalEmployees++;
    }

    if (this.visitorsOnboarding.showVideoWalkthrough) {
      void this.visitorsOnboarding.loadEmployeesTask.perform();
    }
  }
}

import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { all } from 'rsvp';

/**
 * @param {boolean} isConnectedToProperty - whether the location is connected to a multi-tenant property
 */
export default class SecurityComponentsConfigureBlocklistLocation extends Component {
  @service abilities;
  @service('blocklistFilter') blocklistFilterService;
  @service currentLocation;
  @service state;

  determineAdminPermissionText =
    'Adding admins to the above alert list will give them permission to approve and deny visitors.';

  get canCreateBlocklist() {
    return this.abilities.can('create blocklist in security');
  }

  get canUpdateBlocklist() {
    return this.abilities.can('update blocklist in security');
  }

  get canUploadBlocklist() {
    return this.abilities.can('upload blocklist in security');
  }

  @action
  loadBlocklistContacts() {
    return get(this.state, 'currentLocation.blocklistContacts');
  }

  @action
  loadBlocklistFilters() {
    return this.blocklistFilterService.queryByCompanyTask.perform(this.state.currentCompany.id);
  }

  @action
  saveBlocklistContacts(blocklistContacts) {
    get(this.state, 'currentLocation.blocklistContacts').clear();
    get(this.state, 'currentLocation.blocklistContacts').pushObjects(blocklistContacts);
    return this.state.currentLocation.save();
  }

  @action
  async searchUsers(term) {
    const roles = ['Global Admin', 'Location Admin', 'Receptionist'];
    const confirmedUsers = true;
    const userRoles = await this.currentLocation.searchUsers.perform(term, roles, confirmedUsers);
    return all(userRoles.map((role) => get(role, 'user')));
  }
}

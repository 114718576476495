import Controller from '@ember/controller';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

import type { LocationOverviewSignInFlowsShowIndexRouteModel } from './route';

export default class LocationOverviewSignInFlowsGeneralController extends Controller {
  declare model: LocationOverviewSignInFlowsShowIndexRouteModel;

  @service declare state: StateService;
  @service declare flashMessages: FlashMessagesService;

  deleteTask = dropTask(async () => {
    try {
      await this.model.globalFlow.destroyRecord();
      this.flashMessages.showAndHideFlash('success', 'Global flow deleted');
      this.transitionToRoute('location-overview.sign-in-flows.index'); // eslint-disable-line ember/no-deprecated-router-transition-methods
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  saveTask = dropTask(async () => {
    const { changeset } = this.model;

    await changeset.validate();

    if (changeset.isInvalid) {
      return;
    }

    try {
      await changeset.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}

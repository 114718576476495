import Component from '@glimmer/component';
import { service } from '@ember/service';
import { alias, gt, or } from 'macro-decorators';

export default class CustomizeSignInFlows extends Component {
  @service state;
  @service visitorsOnboarding;

  get canAccessMultipleVisitorTypes() {
    return !!this.state.features?.canAccessMultipleVisitorTypes;
  }

  @alias('visitorsOnboarding.hasEnabledBadges') hasEnabledBadges;
  @alias('visitorsOnboarding.hasEnabledPhotos') hasEnabledPhotos;
  @alias('visitorsOnboarding.hasSignInFields') hasSignInFields;
  @alias('visitorsOnboarding.s3Url') s3Url;

  @gt('visitorsOnboarding.totalFlows', 1) hasVisitorTypes;
  @gt('visitorsOnboarding.totalLegalDocuments', 0) hasLegalDocuments;

  @or('hasLegalDocuments', 'hasEnabledBadges', 'hasEnabledPhotos') hasVisitorFeatures;

  constructor() {
    super(...arguments);
    this.visitorsOnboarding.loadFlowsTask.perform(false);
  }
}

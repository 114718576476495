import roundDate from 'garaje/utils/round-date';
import moment from 'moment-timezone';

/*
 * This will read the query param from either entry or invite,
 * sanitize the input, and round it to the nearest 15 minute
 *
 * The sanitization that occurs:
 * * Valid moment date
 * * If it has how time, then it will set the current time to now
 * * If it's valid and has a time, then it will return as is
 */
function parseDateFromQP(dateString = ''): Date {
  const date = moment(dateString);
  const today = moment();
  let returnDate = today;
  const isValidDate = date.isValid();
  const isMissingTime = dateString.match(/^\d{4}-\d{2}-\d{2}$/g);

  if (!isValidDate || dateString === '') {
    returnDate = today;
  }

  if (isMissingTime && isValidDate) {
    returnDate = date.hours(today.hours()).minutes(today.minutes());
  }

  if (isValidDate && !isMissingTime) {
    returnDate = date;
  }

  const roundedDate = roundDate(returnDate, 'minutes', 15).startOf('minute').toDate();

  return roundedDate;
}

export { parseDateFromQP };
export default parseDateFromQP;

import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import ManageRolesRoute from 'garaje/pods/manage/roles/route';
import type GlobalOverviewAccessService from 'garaje/services/global-overview-access';

class LocationOverviewManageRolesRoute extends ManageRolesRoute {
  controllerName = 'manage/roles';
  templateName = 'manage/roles';

  @service declare router: RouterService;
  @service declare globalOverviewAccess: GlobalOverviewAccessService;

  beforeModel(): void {
    if (!this.globalOverviewAccess.canVisitGlobalCustomRoles) {
      void this.router.transitionTo('unauthorized');
    }
  }
}

export default LocationOverviewManageRolesRoute;

import config from 'garaje/config/environment';
import { buildUrl } from 'garaje/utils/url-builder';

const testing = config.environment === 'test';

export default function redirectToBoss(path: string): void {
  if (testing) {
    window.__garajeTests['redirectToBossPath'] = path;
  } else {
    window.location.href = buildUrl(path, config.envoyBossHost);
  }
}

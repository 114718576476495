import { capitalize } from '@ember/string';
import Model, { attr } from '@ember-data/model';

export default class PrinterConnectionOption extends Model {
  @attr('string')
  declare name: string;

  @attr('string')
  declare parentId: string;

  @attr('string')
  declare state: string;

  @attr('date')
  declare createdAt: Date;

  get humanizedState(): string {
    const state = capitalize(this.state);

    return state.replaceAll('_', ' ');
  }

  get status(): string {
    return (
      {
        error: 'bad',
        idle: 'good',
        offline: 'bad',
        online: 'good',
      }[this.state] || 'warn'
    );
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'printer-connection-option': PrinterConnectionOption;
  }
}

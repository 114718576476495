import Changeset from 'ember-changeset';
import { get, set, getProperties } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';
import ObjectProxy from '@ember/object/proxy';
import lookupValidator from 'ember-changeset-validations';
import userDatumValidations from 'garaje/validations/user-datum';

/**
 * UserDatum is a pseudo-model for client side manipulation of objects
 * Paired with the user-datum DS.Transform, the backend can send us id-less objects
 * A changeset is used to determine when the object dirty
 * Replacement for what was a Fragment
 *
 * @type {UserDatum}
 */
export default class UserDatum {
  constructor({ field, value }) {
    this.isUserDatum = true;
    this._field = field;
    this._value = value;

    this.changeset = new Changeset(ObjectProxy.create({ field, value }), lookupValidator, userDatumValidations);
  }

  @alias('changeset.isDirty') isDirty;

  get field() {
    return this._field;
  }

  get value() {
    return get(this.changeset, 'value');
  }

  set value(v) {
    set(this, 'changeset.value', v);
  }

  /**
    Returns just the field/value without changeset and instance methods

    @returns {Object} POJO of current field/value
  */
  toJSON() {
    return getProperties(this, 'field', 'value');
  }
}

import { helper } from '@ember/component/helper';
import { typeOf } from '@ember/utils';

/**
 * @returns {boolean} Returns true if the argument starts with a number
 */
export function isNum(arg) {
  const parsed = parseFloat(arg);
  return typeOf(parsed) === 'number' && !Number.isNaN(parsed);
}

export default helper(([arg]) => isNum(arg));

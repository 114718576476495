import EntryFragment from 'garaje/graphql/fragments/employee-schedule/EntryFragment';
import gql from 'graphql-tag';

export default gql`
  mutation SignInInvite($inviteID: ID!, $reservationID: ID) {
    signInInvite(inviteId: $inviteID, reservationId: $reservationID) {
      ...EntryFragment
    }
  }
  ${EntryFragment}
`;

import gql from 'graphql-tag';

import EmployeeFragment from './EmployeeFragment';

export default gql`
  fragment DeskFragment on Desk {
    id
    name
    mapFeature {
      floor {
        id
        name
      }
    }
    neighborhood
    neighborhoodId
    deskAvailability
    enabled
    x
    y
    assignedEmployee {
      ...EmployeeFragment
    }
    amenities {
      id
      name
    }
  }
  ${EmployeeFragment}
`;

import V2Adapter from './v2';

export default class extends V2Adapter {
  // Overwriting createRecord to reuse the same route
  // as the iPad for creating entires. This is stripping
  // out unpermitted properties and renaming fields.
  createRecord(store, type, snapshot) {
    const data = {};
    const serializer = store.serializerFor(type.modelName);
    const url = this.buildURL(type.modelName, null, snapshot, 'createRecord');

    serializer.serializeIntoHashForCreate(data, type, snapshot, { includeId: true });

    return this.ajax(url, 'POST', { data });
  }
}

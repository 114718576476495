import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import { Permission } from 'garaje/utils/ui-permissions';

export default class EmployeeDirectoryAbility extends Ability {
  @service declare authz: AuthzService;
  @service declare featureFlags: FeatureFlagsService;

  get canVisitGlobal(): boolean {
    if (!this.featureFlags.isEnabled('global-employee-directory')) return false;
    return this.authz.hasPermissionAtCurrentCompany(Permission.USER_MANAGEMENT_EMPLOYEE_READ);
  }
}

import type NativeArray from '@ember/array/-private/native-array';
import Component from '@glimmer/component';
import type PlatformJobModel from 'garaje/models/platform-job';

interface NotificationActivityComponentSignature {
  Args: {
    notifications: NativeArray<PlatformJobModel>;
    isLoading: boolean;
    isError: boolean;
    retry: () => void;
    noHost: boolean;
    signedInVia: string;
  };
  Element: HTMLElement;
}

export default class NotificationActivityComponent extends Component<NotificationActivityComponentSignature> {
  get hasMultipleRecipients(): boolean {
    return this.args.notifications.length > this.args.notifications.uniqBy('title').length;
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type CompanyConfigurationModel from 'garaje/models/company-configuration';
import type StateService from 'garaje/services/state';

class LocationOverviewManageAccountSettingsRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: StoreService;

  titleToken = 'Account Settings · Envoy';

  beforeModel(): void {
    if (this.abilities.cannot('visit-account-settings manage-settings')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  async model(): Promise<CompanyConfigurationModel> {
    const companyConfigurationId = await this.state.fetchCompanyConfigId();
    const companyConfiguration = await this.store.findRecord('company-configuration', companyConfigurationId);
    return companyConfiguration;
  }
}

export default LocationOverviewManageAccountSettingsRoute;

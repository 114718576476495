import Service from '@ember/service';
import type { TaskInstance } from 'ember-concurrency';
import { enqueueTask, timeout } from 'ember-concurrency';
import zft from 'garaje/utils/zero-for-tests';
import { equal } from 'macro-decorators';
import { tracked } from 'tracked-built-ins';

enum OnlineStatus {
  OFFLINE = 'offline',
  ONLINE = 'online',
}

export default class OnlineService extends Service {
  @tracked status = OnlineStatus.ONLINE;
  @equal('status', OnlineStatus.OFFLINE) isOffline!: boolean;
  @equal('status', OnlineStatus.ONLINE) isOnline!: boolean;

  declare _handleOfflineEvent;
  declare _handleOnlineEvent;

  constructor(properties: Record<string, unknown>) {
    super(properties);

    if (typeof window.navigator.onLine === 'boolean') {
      this.status = window.navigator.onLine ? OnlineStatus.ONLINE : OnlineStatus.OFFLINE;
    }
    this._handleOfflineEvent = this.handleOfflineEvent.bind(this);
    this._handleOnlineEvent = this.handleOnlineEvent.bind(this);
    window.addEventListener(OnlineStatus.OFFLINE, this._handleOfflineEvent);
    window.addEventListener(OnlineStatus.ONLINE, this._handleOnlineEvent);
  }

  willDestroy(): void {
    window.removeEventListener(OnlineStatus.OFFLINE, this._handleOfflineEvent);
    window.removeEventListener(OnlineStatus.ONLINE, this._handleOnlineEvent);
  }

  handleOfflineEvent(): void {
    this.status = OnlineStatus.OFFLINE;
  }

  handleOnlineEvent(): void {
    this.status = OnlineStatus.ONLINE;
  }

  runWhenOnline(fn: () => Promise<unknown> | void): TaskInstance<unknown> {
    return this._runWhenOnlineTask.perform(fn);
  }

  _runWhenOnlineTask = enqueueTask(async (fn: () => Promise<unknown> | void) => {
    while (this.isOffline) {
      await timeout(zft(2000));
    }
    if (typeof fn === 'function') {
      const result = await fn();
      return result;
    }

    return;
  });
}

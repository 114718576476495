import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type AbilitiesService from 'ember-can/services/abilities';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';

import type { CtaEventProperties } from '../../feature-or-upgrade-button/component';

/**
 * @param {String} ctaId - value to send as "cta_id" when logging events
 * @param {String} header - header text to display (also included in logged events)
 * @param {String} body - body text to display (also included in logged events)
 * @param {Function} closeModal - action to trigger when modal is closed
 */
export default class GrowthFeatureUpgradeModal extends Component<{
  ctaId: string;
  header: string;
  body: string;
  closeModal: () => void;
}> {
  @service declare abilities: AbilitiesService;
  @service declare metrics: MetricsService;
  @service declare state: StateService;

  get ctaEventProperties(): CtaEventProperties {
    return {
      cta_id: this.args.ctaId,
      cta_type: 'modal',
      cta_clickable_text: this.showUpgradeButton ? 'Upgrade now' : null,
      cta_clickable_type: this.showUpgradeButton ? 'button' : 'none',
      cta_title: this.args.header,
      cta_body: this.args.body,
      cta_intent: 'upgrade',
      growth_team_project: true,
    };
  }

  get showUpgradeButton(): boolean {
    return this.abilities.can('visit billing') && !this.state.billingCompany?.blockSelfServe;
  }

  @action
  logViewed(): void {
    this.metrics.trackEvent('CTA Viewed', this.ctaEventProperties);
  }

  @action
  logClicked(): void {
    this.metrics.trackEvent('CTA Clicked', this.ctaEventProperties);
  }

  @action
  logDismissalAndClose(): void {
    this.metrics.trackEvent('CTA Dismissed', this.ctaEventProperties);
    if (this.args.closeModal) this.args.closeModal();
  }
}

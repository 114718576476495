import Controller from '@ember/controller';
import { action, set, get } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

export default class DeliveriesSettingsDeliveryAreasDeliveryAreaDevicesNewController extends Controller {
  @service flashMessages;
  @service store;

  @task
  *saveTask() {
    const { newDevice } = this.model;
    const activationCode = this.activationCode.toUpperCase();

    set(newDevice, 'activationCode', activationCode);

    try {
      yield newDevice.save();
      this.flashMessages.showAndHideFlash('success', 'New device added');
      this.transitionToRoute('deliveries.settings.delivery-areas.delivery-area.devices'); // eslint-disable-line ember/no-deprecated-router-transition-methods
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showFlash('error', errorText);
    }
  }

  @action
  newToken() {
    const { newDevice: currentDevice } = this.model;
    const newDevice = this.store.createRecord('deliveryDevice', {
      deliveryArea: get(currentDevice, 'deliveryArea'),
    });
    set(this.model, 'newDevice', newDevice);
  }

  @action
  save(e) {
    e.preventDefault();
    return this.saveTask.perform();
  }
}

import Controller from '@ember/controller';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';

export default class IntegrationsController extends Controller {
  @service currentAdmin;
  @service currentLocation;
  @service integrationsManager;

  // 10-14-2015 - Adolfo Builes
  // TODO: meeh, we should do this better? probably have it only in the
  // integration manager service?
  get hasIntegrations() {
    const hasCore = this.integrationsManager.getIntegrations(this.currentLocation).length > 0;
    const hasPlatform =
      this.model.pluginInstalls.filter(function (install) {
        return get(install, 'status') !== 'uninstalled';
      }).length > 0;
    return hasCore || hasPlatform;
  }
}

import type NativeArray from '@ember/array/-private/native-array';
import type ArrayProxy from '@ember/array/proxy';
import type Route from '@ember/routing/route';
import type Model from '@ember-data/model';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type DS from 'ember-data';

/**
 * Represent a model's attributes (as keys) without underlying Model class properties.
 */
export type ModelAttrs<T extends Model> = keyof Omit<T, keyof Model>;
/**
 * Represent a model's attributes without underlying Model class properties.
 */
export type ModelData<T extends Model> = Omit<T, keyof Model>;
export type RecordArray<T> = DS.RecordArray<T>;
export type PromiseArray<T, ArrayType extends ArrayProxy<T>['content'] = NativeArray<T>> = DS.PromiseArray<
  T,
  ArrayType
>;

export type NonEmptyArray<T> = [T, ...T[]];
export function isNonEmptyArray<T>(arr: T[]): arr is NonEmptyArray<T> {
  return arr.length > 0;
}
export type KeysMatching<T, V> = { [K in keyof T]-?: T[K] extends V ? K : never }[keyof T];

/**
  Get the resolved type of an item.

  - If the item is a promise, the result will be the resolved value type
  - If the item is not a promise, the result will just be the type of the item
 */
export type Resolved<P> = P extends Promise<infer T> ? T : P;
/** Get the resolved model value from a route. */
export type ModelFrom<R extends Route> = Resolved<ReturnType<R['model']>>;

import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import type { SafeString } from '@ember/template/-private/handlebars';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface EmbeddedIframeComponentSignature {
  Args: {
    url: string;
    customizedStyle: string;
  };
}

export default class EmbeddedIframeComponent extends Component<EmbeddedIframeComponentSignature> {
  @tracked isLoading = true;

  @action
  onInsert(element: HTMLElement): void {
    const iframe = element.getElementsByTagName('iframe')[0];
    iframe!.onload = () => {
      this.isLoading = false;
    };
  }

  get iframeStyle(): SafeString {
    let style = 'border:none;height: calc(100vh - 10.5rem);';
    if (this.args.customizedStyle) {
      style += `${this.args.customizedStyle}`;
    }
    return htmlSafe(style);
  }
}

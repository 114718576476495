import type { CtaEventProperties } from 'garaje/pods/components/feature-or-upgrade-button/component';
import FeatureOrUpgradeButton from 'garaje/pods/components/feature-or-upgrade-button/component';

/**
 * @param {String}    fieldClass
 * @param {String}    fieldFor
 * @param {String}    fieldText
 */
export default class FeatureOrUpgradeField extends FeatureOrUpgradeButton {
  ctaEventProperties(intent: string, text: string): CtaEventProperties {
    return {
      cta_id: this.args.featureName ? `${this.args.featureName}_feature_or_upgrade_field` : 'feature_or_upgrade_field',
      cta_title: null,
      cta_body: null,
      cta_type: 'field',
      cta_clickable_type: 'link',
      cta_clickable_text: text,
      cta_intent: intent,
    };
  }
}

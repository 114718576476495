import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';
import { Permission } from 'garaje/utils/ui-permissions';
import _intersection from 'lodash/intersection';

const CAN_CONFIG_DATA_PRIVACY_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN];
const CAN_VISIT_AUTHZ_PERMISSIONS = [
  Permission.VISITORS_BLOCKLIST_READ,
  Permission.VISITORS_WATCHLIST_READ,
  Permission.VISITORS_FULL_LEGAL_NAME_SETTING_READ,
];

export default class SettingAbility extends Ability {
  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;

  isProtect = false;

  get canVisit(): boolean {
    // When the feature flag is enabled the FLOW_INSPECT permission is sufficent to view
    // Once launched we should remove the split and add this permission to CAN_VISIT_AUTHZ_PERMISSIONS
    if (
      this.featureFlags.isEnabled('custom-roles-garaje-authz-vis3-flows') &&
      this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_FLOW_INSPECT)
    ) {
      return true;
    }

    return this.authz.hasAnyPermissionAtCurrentLocation(CAN_VISIT_AUTHZ_PERMISSIONS);
  }

  get canConfigureDataPrivacy(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_CONFIG_DATA_PRIVACY_ROLES, roleNames));
  }

  get canManageResponseOptions(): boolean {
    const isPremiumOrEnterprisePlan =
      this.state.vrSubscription?.isPremiumPlan || this.state.vrSubscription?.isEnterprisePlan || false;
    const { roleNames } = this.currentAdmin;

    let canRoleManage = false;
    if (this.isProtect) {
      canRoleManage = roleNames.includes(GLOBAL_ADMIN);
    } else {
      canRoleManage = isPresent(_intersection([GLOBAL_ADMIN, LOCATION_ADMIN], roleNames));
    }

    return isPremiumOrEnterprisePlan && canRoleManage;
  }
}

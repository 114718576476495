import ApplicationSerializer from './application';

export default class GlobalAgreementPageSerializer extends ApplicationSerializer {
  attrs = {
    bccEmail: { serialize: false },
    sendToBcc: { serialize: false },
  };

  // This is used for the `hasMany` relationship between flows and skinny-locations
  // envoy-web doesn't know anything about the skinny-locations model.
  modelNameFromPayloadKey(key) {
    if (key === 'flows') {
      return 'global-flow';
    } else if (key === 'agreements') {
      return 'global-agreement';
    } else if (key === 'agreement-pages') {
      return 'global-agreement-page';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }

  payloadKeyFromModelName(modelName) {
    if (modelName === 'global-flow') {
      return 'flows';
    } else if (modelName === 'global-agreement') {
      return 'agreements';
    } else if (modelName === 'global-agreement-page') {
      return 'agreement-pages';
    } else {
      return super.payloadKeyFromModelName(...arguments);
    }
  }
}

import Controller from '@ember/controller';
import { service } from '@ember/service';
import { action } from '@ember/object';
import updateAndSaveTask from 'garaje/utils/decorators/update-and-save-task';

@updateAndSaveTask
class VisitorsSettingsVisitorTypesFlowSecurityController extends Controller {
  @service abilities;
  @service metrics;
  @service currentAdmin;
  @service state;

  idScanChangeset;
  isVisualComplianceOpen = false;

  get learnMoreLinks() {
    const idCheckLink = this.abilities.can('manage id-check');
    const links = [];
    if (idCheckLink) {
      links.push({ name: 'ID check', path: 'https://help.envoy.com/id-requirements/' });
    }
    links.push({ name: 'ID scanning', path: 'https://help.envoy.com/id-scanning/' });
    links.push({
      name: 'the Visual Compliance integration',
      path: 'https://help.envoy.com/visual-compliance-integration/',
    });
    return links;
  }

  @action
  onUpgradeIdScanning(plan) {
    this.metrics.trackEvent('Visitor Type - ID Scanning Upgrade Clicked', { plan });
  }

  @action
  handleIdScanPageChanges(changeset) {
    this.idScanChangeset = changeset;
  }
}

export default VisitorsSettingsVisitorTypesFlowSecurityController;

import Component from '@glimmer/component';
import { action, getProperties } from '@ember/object';
import _compact from 'lodash/compact';
import RecurringRule from 'garaje/models/recurring-rule';
import { cached } from 'tracked-toolbox';

/**
 * Form with dropdown and date-picker for managing recurring invites
 * @param {String}        expectedArrivalTime
 * @param {String}        minDate
 * @param {Function}      onChange
 * @param {String}        selected
 * @param {String}        timezone
 * @param {Boolean}       isReadonly
 */
export default class RecurringInvitesContainer extends Component {
  @cached
  get recurringRule() {
    if (this.args.selected) {
      return RecurringRule.parse(this.args.selected);
    } else {
      return new RecurringRule();
    }
  }

  get showRepeatEnd() {
    return !!this.recurringRule.frequency;
  }

  get recurringRuleWithoutUntil() {
    let props;
    if (this.args.disabledFromMultilocation) {
      props = undefined;
    } else {
      props = getProperties(this.recurringRule, 'frequency', 'byDay', 'byMonth', 'byMonthDay');
    }

    return new RecurringRule(props).toString();
  }

  @action
  onRruleSelect(option) {
    const recurringRule = RecurringRule.parse(option.value);

    // NOTE if user selects 'Does not repeat', we do not want to update the until
    if (!recurringRule.isBlank) {
      recurringRule.until = this.recurringRule.until;
    }

    this.args.onChange(recurringRule.toString());
  }

  @action
  onRruleUntilSelect(date) {
    if (date) {
      this.recurringRule.until = date;
    } else {
      this.recurringRule.until = null;
    }

    this.args.onChange(this.recurringRule.toString());
  }
}

import Component from '@glimmer/component';

/**
 * @param {Number}                    maxCharacters
 * @param {String}                    text
 */
export default class CharacterCounter extends Component {
  get numCharacters() {
    const { text } = this.args;
    return text?.length || 0;
  }

  get isOverMaxCharacters() {
    const { maxCharacters } = this.args;
    return this.numCharacters > maxCharacters;
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { alias } from 'macro-decorators';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

/**
 * @param {Object}              newAlias
 * @param {Function}            transitionBack
 */
export default class NewNickname extends Component {
  @service flashMessages;
  @service state;
  @service store;

  @tracked selectedEmployee;
  @tracked name;

  @alias('state.currentLocation.id') locationId;

  @(employeesSearcherTask().restartable())
  searchEmployeesTask;

  @task
  *saveTask() {
    const store = this.store;
    const selectedEmployee = this.selectedEmployee;
    const { locationId } = this;

    const newAlias = store.createRecord('recipient-alias', {
      name: this.name,
      locationId,
      recipientEmployee: selectedEmployee,
    });

    try {
      yield newAlias.save();
      this.args.transitionBack();
      this.flashMessages.showAndHideFlash('success', 'Nicknames updated');
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showFlash('error', errorText);
      newAlias.unloadRecord();
    }
  }
}

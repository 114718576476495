import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import LocationOverviewManageAuditLogRoute from 'garaje/pods/location-overview/manage/audit-log/route';

export default class ManageAuditLogRoute extends LocationOverviewManageAuditLogRoute {
  controllerName = 'location-overview/manage/audit-log';
  templateName = 'location-overview/manage/audit-log';

  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  beforeModel(): void {
    if (this.abilities.cannot('visit local audit log in manage-settings')) {
      void this.router.transitionTo('unauthorized');
    }
  }
}

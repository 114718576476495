import ApplicationSerializer from './application';

export default class NotificationConfigurationSerializer extends ApplicationSerializer {
  attrs = {
    emergencyNotificationsEnabledAt: {
      serialize: false,
    },
    createdAt: {
      serialize: false,
    },
    updatedAt: {
      serialize: false,
    },
    location: {
      serialize: false,
    },
  };
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'notification-configuration': NotificationConfigurationSerializer;
  }
}

import ObjectTransform from 'garaje/transforms/object';
import moment from 'moment-timezone';
import { isNone } from '@ember/utils';

export default class TrialEndTransform extends ObjectTransform {
  deserialize(serialized, ...args) {
    if (isNone(serialized)) {
      return super.deserialize(...arguments);
    }

    const obj = Object.getOwnPropertyNames(serialized).reduce((acc, key) => {
      const mmt = moment(serialized[key]);
      if (mmt.isValid()) {
        acc[key] = mmt.toDate();
      }
      return acc;
    }, {});

    return super.deserialize(obj, ...args);
  }
}

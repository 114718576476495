import Model, { attr } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

// <2015-09-02> Adolfo Builes This is only used by google apps
// filtering eventually we should remove this and keep only
// model/employee-filter.js which is a generalized version of filters.
//

@adapter('v2')
class EmployeesFilterModel extends Model {
  @attr('string')
  declare type: string;
  @attr('string')
  declare query: string;

  @attr()
  declare locations: unknown;
}

export default EmployeesFilterModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'employees-filter': EmployeesFilterModel;
  }
}

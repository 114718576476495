import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import type CurrentLocationService from 'garaje/services/current-location';

export default class CommunicationsMessageLogNewController extends Controller {
  @service declare currentLocation: CurrentLocationService;

  get locationId(): string {
    return this.currentLocation.location.id;
  }

  get locationName(): string {
    return this.currentLocation.location.name;
  }
}

import Ember from 'ember';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { debounce, cancel, later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import leaflet from 'leaflet';

/**
 * @param {Function}  updateDeactivationStatus  // calls a function that accepts desk as an argument
 * @param {Array}     desks                     // mapped out desks. each object should have a "selected" boolean, as well
 * @param {Object<Floor>}     selectedFloor            // Instance of floor
 */

export default class DeactivateDesksMap extends Component {
  crs = leaflet.CRS.Simple;

  @service state;
  @service store;
  @service metrics;
  @service flashMessages;
  @service ajax;

  @tracked mapWidth = 600;
  @tracked mapHeight = 400;
  @tracked isMapReady = true;

  @tracked cursorX;
  @tracked cursorY;
  @tracked polylineX = [];
  @tracked polylineY = [];

  @tracked currentDesk;
  @tracked selectedDesk;

  @tracked containerWidth = 640;
  @tracked containerHeight = 400;

  @tracked zoomDelta = 0.5;

  @tracked map;

  @tracked isInteraction = false;
  @tracked debouncedInteractionOff;
  @tracked isPopupOpen = false;

  originalZoomValue = 0.5;

  get bounds() {
    const { mapWidth, mapHeight } = this;
    return new leaflet.LatLngBounds(
      leaflet.CRS.Simple.unproject({ x: 0, y: 0 }, 0),
      leaflet.CRS.Simple.unproject({ x: mapWidth, y: -mapHeight }, 0),
    );
  }

  get maxZoom() {
    if (this.isLandscape) {
      return Math.sqrt(this.containerHeight / this.mapHeight);
    }
    return Math.sqrt(this.containerWidth / this.mapWidth);
  }

  get minZoom() {
    const delta = 1.35; //this delta prevents images from getting cut off
    if (this.isLandscape) {
      return -Math.sqrt(this.mapWidth / this.containerWidth) * delta;
    }

    return -Math.sqrt(this.mapHeight / this.containerHeight) * delta;
  }

  get isLandscape() {
    return this.mapWidth > this.mapHeight;
  }

  @action
  registerMap({ target: map }) {
    this.map = map;
    if (!Ember.testing) {
      map.fitBounds(this.bounds);
    }
  }

  @action
  handleClickTask() {
    this.selectedDesk = null;
  }

  @action
  onLoadImage() {
    // Without the timeout, when image is cached LeafletMap will render the markers before the image is fit to the bounds
    // When the image is fit the markers will be out of place. The timeout gives time for the image to be fit after loading
    later(this, () => {
      this.originalZoomValue = this.map.getZoom();
    });
  }

  @action
  toggleInteractionOn() {
    cancel(this.debouncedInteractionOff);
    this.isInteraction = true;
  }

  @action
  toggleInteractionOff() {
    this.resetCursor();
    this.debouncedInteractionOff = debounce(this, this.turnOffInteraction, 250);
  }

  @action
  turnOffInteraction() {
    this.isInteraction = false;
    this.resetCursor();
  }

  /**
   * @returns {Void} resetting the cursor temporarily resets the draggableDiv
   */
  @action
  resetCursor() {
    this.cursorX = null;
    this.cursorY = null;
  }

  @action
  togglePopupOpen() {
    this.resetCursor();
    this.isPopupOpen = true;
  }

  @action
  togglePopupClosed() {
    this.resetCursor();
    this.isPopupOpen = false;
  }

  @action
  handleMarkerClick(desk) {
    this.selectedDesk = desk;
  }

  @action
  handleAddMarkerTask() {
    this.resetCursor();
  }
}

import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';

export default class AdminDashboardAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;

  get canVisit(): boolean {
    const { isGlobalAdmin, isLocationAdmin, isReceptionist } = this.currentAdmin;
    return isGlobalAdmin || isLocationAdmin || isReceptionist;
  }
}

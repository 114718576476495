import Controller from '@ember/controller';
import { dropTask } from 'ember-concurrency';
import { defer } from 'rsvp';

export default class MapsDraftsAreaMapIndexController extends Controller {
  @dropTask
  showFloorModalTask: {
    perform(): Generator<Promise<unknown>, unknown, unknown>;
  } = {
    *perform(this: { context: MapsDraftsAreaMapIndexController; abort?: () => void; continue?: () => void }) {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = () => {
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };
}

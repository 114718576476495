import { modifier } from 'ember-modifier';

/**
 * TextareaResize resizes a textarea's height to its scrollHeight if the content is longer than can fit
 * If a max height is set, the browser gives that priority
 *
 *
 * @param {HTMLElement} el The dom element textarea to resize
 */
export default modifier((el) => {
  const { scrollHeight, clientHeight } = el;

  if (scrollHeight > clientHeight) {
    el.style.height = `${scrollHeight}px`;
  }
});

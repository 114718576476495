import Component from '@glimmer/component';
import { alias } from 'macro-decorators';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

/**
 * @param {Invoice}  invoice
 */
export default class InvoiceRow extends Component {
  @service ajax;
  @service windowLocation;

  @alias('args.invoice.date') date;
  @alias('args.invoice.isChargebeeInvoice') isChargebeeInvoice;
  @alias('args.invoice.pdf') pdf;
  @alias('args.invoice.refunded') refunded;

  get amount() {
    return this.args.invoice.amount / 100;
  }

  get amountRefunded() {
    return this.args.invoice.amountRefunded / 100;
  }

  get status() {
    const status = this.args.invoice.status;
    if (status === 'upcoming') {
      return { classes: 'paymentIcon icon-next', text: 'Next invoice' };
    } else if (status === 'failed') {
      return { classes: 'paymentIcon icon-alert', text: 'Failed' };
    } else if (status === 'paid') {
      if (this.refunded) {
        if (this.args.invoice.amountRefunded === this.args.invoice.amount) {
          return { classes: 'paymentIcon icon-refund', text: 'Refunded' };
        } else {
          return { classes: 'paymentIcon icon-refund icon-refund-partial', text: 'Partially refunded' };
        }
      }
      return { classes: 'paymentIcon icon-paid', text: 'Paid' };
    }
    return null;
  }

  @action
  async downloadPdf() {
    if (this.isChargebeeInvoice) {
      const response = await this.ajax.request(this.pdf);
      this.windowLocation.assign(response.url);
    } else {
      this.windowLocation.assign(this.pdf);
    }
  }
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type CurrentAdminService from 'garaje/services/current-admin';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';

interface LocationSwitcherZoneComponentArgs {
  key?: string;
  name: string;
  onclick: () => void;
}

export default class LocationSwitcherZoneComponent extends Component<LocationSwitcherZoneComponentArgs> {
  @service declare metrics: MetricsService;
  @service declare state: StateService;
  @service declare currentAdmin: CurrentAdminService;

  get key(): string {
    return this.args.key ?? '';
  }

  @action
  selectZone(): void {
    const { currentUser, vrSubscription, currentCompany } = this.state;
    const trackingEventProperties: Record<string, string | undefined | string[]> = {
      company_id: currentCompany?.id,
      zone_id: this.key,
      role: this.currentAdmin.roleNames,
      user_id: currentUser?.id,
    };

    if (vrSubscription) {
      trackingEventProperties['plan_id'] = vrSubscription.plan;
    }

    this.metrics.trackEvent('Dashboard Zone Changed All', trackingEventProperties);

    this.args.onclick();
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type FeatureFlagsService from 'garaje/services/feature-flags';

export default class CommunicationsRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare featureFlags: FeatureFlagsService;

  redirect(): void {
    const hasMessageLog = this.featureFlags.isEnabled('emergency-notifications-dashboard-parity');

    if (this.abilities.can('manage-announcements communications') && hasMessageLog) {
      return void this.router.transitionTo('communications.message-log');
    }

    if (this.abilities.can('manage-announcement-templates communications')) {
      return void this.router.transitionTo('communications.templates');
    }

    if (this.abilities.can('manage-settings communications')) {
      return void this.router.transitionTo('communications.settings');
    }

    return void this.router.transitionTo('unauthorized');
  }
}

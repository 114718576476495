import { not, or } from '@ember/object/computed';
import { isBlank } from '@ember/utils';
import { get, computed } from '@ember/object';

import {
  isOsUpgradeAvailable as isOsUpgradeAvailableFn,
  isAppUpgradeAvailable as isAppUpgradeAvailableFn,
} from 'garaje/utils/upgrade-available';

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const IPAD_LAST_SEEN_THRESHOLD = 11 * MINUTE;

/**
 * @returns {*}
 */
export default function (Class) {
  const DecoratedClass = class extends Class {
    @not('isIpadSeenRecently') isIpadNotSeenRecently;
    @or('isOsUpgradeAvailable', 'isAppUpgradeAvailable', 'isPrinterErrored') isStatusWarn;
    @or('isIpadNotSeenRecently', 'hasPrinterIssues') isStatusBad;

    @computed('isPrinterEnabled', 'model.printerStatus')
    get isPrinterDisconnected() {
      if (this.isPrinterEnabled) {
        const status = get(this, 'model.printerStatus');
        return isBlank(status) || status === 'not connected';
      } else {
        return false;
      }
    }

    @computed('isPrinterEnabled', 'model.printerStatus')
    get isPrinterErrored() {
      if (this.isPrinterEnabled) {
        return get(this, 'model.printerStatus') !== 'connected';
      } else {
        return false;
      }
    }

    @computed('isPrinterEnabled', 'model.printerStatus')
    get hasPrinterIssues() {
      const status = get(this, 'model.printerStatus');

      if (this.isPrinterEnabled && status) {
        return status.match(/paper/);
      } else {
        return false;
      }
    }

    @computed('appVersion', 'currentAppVersion', 'model.{appVersion,currentIpadAppVersion}')
    get isAppUpgradeAvailable() {
      const appVersion = get(this, 'model.appVersion') ?? this.appVersion;
      const currentAppVersion = this.currentAppVersion ?? this.currentIpadAppVersion;
      return isAppUpgradeAvailableFn(appVersion, currentAppVersion);
    }

    @computed('maximumOsVersion', 'model.{maximumOsVersion,osVersion}', 'osVersion')
    get isOsUpgradeAvailable() {
      const osVersion = get(this, 'model.osVersion') ?? this.osVersion;
      const maximumOsVersion = get(this, 'model.maximumOsVersion') ?? this.maximumOsVersion;
      return isOsUpgradeAvailableFn(osVersion, maximumOsVersion);
    }

    @computed('lastHeartbeatAt', 'model.lastHeartbeatAt')
    get isIpadSeenRecently() {
      const heartbeat = get(this, 'model.lastHeartbeatAt') ?? this.lastHeartbeatAt;
      const now = new Date();
      const elapsed = now - heartbeat;
      const threshold = IPAD_LAST_SEEN_THRESHOLD;

      return elapsed <= threshold;
    }

    @computed('isStatusBad', 'isStatusWarn')
    get deviceStatus() {
      if (this.isStatusBad) {
        return 'bad';
      } else if (this.isStatusWarn) {
        return 'warn';
      } else {
        return 'good';
      }
    }
  };

  // reassign the original class name as the name of the decorated class
  Object.defineProperty(DecoratedClass, 'name', { value: Class.name });

  return DecoratedClass;
}

export { IPAD_LAST_SEEN_THRESHOLD };

import Helper from '@ember/component/helper';
import { get } from '@ember/object';
//
// When a company is using scim, we use this helper to get the filters
// which are not yet being used in a location.
//
// For example, if scimFields are ['locale', 'city'], and we defined a
// filter for location like {value: 'foo', name: 'locale'}, then this
// function returns `city` since it is still not used for a particular
// location.
//
export default class ChoosableFiltersHelper extends Helper {
  compute([scimFields, selectedFields = [], locationId]) {
    const names = selectedFields.filterBy('location-id', locationId).mapBy('name');

    return scimFields.toArray().filter(function (field) {
      return !names.includes(get(field, 'name'));
    });
  }
}

import type NativeArray from '@ember/array/-private/native-array';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type DeviceModel from 'garaje/models/device';
import type PropertyPrinterModel from 'garaje/models/property-printer';
import type ZoneModel from 'garaje/models/zone';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

export interface PropertyDevicesRouteModel {
  ipads: RecordArray<DeviceModel>;
  printers: RecordArray<PropertyPrinterModel> | NativeArray<PropertyPrinterModel>;
}

export default class PropertyDevicesRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare router: RouterService;
  @service declare store: Store;

  beforeModel(): void {
    if (this.abilities.cannot('manage devices for property')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  async model(): Promise<PropertyDevicesRouteModel> {
    const property = <ZoneModel>this.modelFor('property');

    const model = {
      ipads: this.store.query('device', {
        filter: {
          zone: property.id,
        },
        include: 'printer',
      }),
      printers: this.store.query('property-printer', {
        filter: {
          property: property.id,
        },
        include: 'devices,property',
      }),
    };

    return hash(model);
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';

const DEFAULT_SORT_BY = 'Date received';
const DEFAULT_SORT_DIRECTION = 'Most to least recent';

const SORT_KEYS = {
  Recipient: 'recipientName',
  'Delivery area': 'deliveryArea.name',
  Carrier: 'carrier.name',
  'Date received': 'createdAt',
  'Date picked up': 'acknowledgedAt',
};

/**
 * @param {Array}               deliveries
 * @param {Function}            onMarkDeliveryAsPickedUpTask
 * @param {Array}               selectedDeliveries
 * @param {Function}            selectAllDeliveries
 * @param {Function}            clearAllSelectedDeliveries
 * @param {Function}            toggleDelivery
 */
export default class DeliveryList extends Component {
  @tracked sortBy = DEFAULT_SORT_BY;
  @tracked sortDirection = DEFAULT_SORT_DIRECTION;
  @tracked deliveries;

  constructor() {
    super(...arguments);
    this.deliveries = this.args.deliveries || A();
  }

  get isSortAsc() {
    const sortDirection = this.sortDirection;
    const sortAscKeys = ['A to Z', 'Least to most recent'];

    return sortAscKeys.includes(sortDirection);
  }

  get sortedDeliveries() {
    const sortField = SORT_KEYS[this.sortBy];
    const sortedDeliveries = this.args.deliveries?.sortBy(sortField);
    return this.isSortAsc ? sortedDeliveries : sortedDeliveries.reverse();
  }

  get allDeliveriesSelected() {
    const selectedCount = this.args.selectedDeliveries?.length;
    const totalCount = this.sortedDeliveries.length;
    return selectedCount === totalCount;
  }

  get isSelectAllIndeterminate() {
    const selectedCount = this.args.selectedDeliveries?.length;
    const totalCount = this.sortedDeliveries.length;
    return selectedCount > 0 && selectedCount !== totalCount;
  }
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { type Task } from 'ember-concurrency';
import type ZoneModel from 'garaje/models/zone';
import type ContextSwitcherService from 'garaje/services/context-switcher';

interface PropertyCardArgs {
  property: ZoneModel;
  deletePropertyTask: Task<void, unknown[]>;
}

export default class PropertyCard extends Component<PropertyCardArgs> {
  @service declare contextSwitcher: ContextSwitcherService;

  @action
  navigateToPropertySettingsPage(page: string, options: Record<string, unknown>): void {
    void this.contextSwitcher.transitionToPropertyRoute(`property${page}`, this.args.property, options);
  }
}

import Service from '@ember/service';
import { TrackedObject } from 'tracked-built-ins';

export default class SessionStorageService extends Service {
  _sessionStorageCache = new TrackedObject();

  getItem(key: string): string | null {
    // read _sessionStorageCache to trigger autotracking behavior, but don't do anything with its value
    void this._sessionStorageCache[key];
    return window.sessionStorage.getItem(key);
  }

  setItem(key: string, value: unknown): void {
    if (value == null) {
      this.removeItem(key);
    } else {
      this._sessionStorageCache[key] = value;
      // @ts-ignore-error - TS seems to have the wrong signature for window.sessionStorage.setItem
      window.sessionStorage.setItem(key, value);
    }
  }

  removeItem(key: string): void {
    delete this._sessionStorageCache[key];
    window.sessionStorage.removeItem(key);
  }
}

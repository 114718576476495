export function formatMinutesAsTime(minutesInDay) {
  const hours = getHourFromMinutesInDay(minutesInDay);
  const minutes = getMinutesOfHourFromMinutesInDay(minutesInDay);
  const meridiem = getAmOrPmFromMinutesInDay(minutesInDay);
  return `${hours}:${leftPadZero(minutes)} ${meridiem}`;
}

export function getMinutesInDayFromTime(time) {
  let result = time.match(/pm$/) !== null ? 12 * 60 : 0;
  const times = time.split(':');
  const hours = time.match(/^12/) ? '0' : times[0];
  result += parseInt(hours, 10) * 60;
  result += parseInt(times[1].split(' ')[0], 10);
  return result;
}

const HOURS = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const MINUTES = [0, 15, 30, 45];
const AM = 'am';
const PM = 'pm';

export const TIMES = [...generateTimes(HOURS, MINUTES, AM), ...generateTimes(HOURS, MINUTES, PM)];

function getHourFromMinutesInDay(minutes) {
  let hours = Math.floor(minutes / 60);
  hours = hours > 12 ? hours - 12 : hours;
  return hours === 0 ? 12 : hours;
}

function getMinutesOfHourFromMinutesInDay(minutes) {
  return minutes % 60;
}

function getAmOrPmFromMinutesInDay(minutes) {
  return minutes < 12 * 60 ? 'am' : 'pm';
}

function generateTimes(hours, minutes, ampm) {
  return hours.reduce((times, hour) => {
    return times.concat(minutes.map((minute) => formatTime(hour, minute, ampm)));
  }, []);
}

function formatTime(hour, minute, ampm) {
  return `${hour}:${leftPadZero(minute)} ${ampm}`;
}

function leftPadZero(number) {
  return number < 10 ? `0${number}` : `${number}`;
}

import type NativeArray from '@ember/array/-private/native-array';
import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type ZoneModel from 'garaje/models/zone';
import type ProtectedController from 'garaje/pods/protected/controller';
import { LOCATION_ROUTES, SHARED_ROUTES } from 'garaje/router';
import type ContextSwitcherService from 'garaje/services/context-switcher';
import type CurrentAdminService from 'garaje/services/current-admin';
import type CurrentZoneService from 'garaje/services/current-zone';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type LocalStorageService from 'garaje/services/local-storage';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import type WindowLocationService from 'garaje/services/window-location';
import { ConnectionRequestConfigModalDefinition } from 'garaje/utils/connect-modal-definitions';
import { routeEvent } from 'garaje/utils/decorators/route';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

import type CurrentZoneController from './controller';

export interface CurrentZoneRouteModel {
  currentZone?: ZoneModel;
  zones?: NativeArray<ZoneModel> | ArrayProxy<ZoneModel>;
}

export default class CurrentZoneRoute extends Route {
  @service declare contextSwitcher: ContextSwitcherService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare currentZone: CurrentZoneService;
  @service declare flashMessages: FlashMessagesService;
  @service declare localStorage: LocalStorageService;
  @service declare metrics: MetricsService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: Store;
  @service declare windowLocation: WindowLocationService;

  beforeModel(transition: Transition): void {
    if (transition.to.queryParams['forceConnectPopup'] === 'true') {
      const queryParams = { ...(transition.to?.queryParams ?? {}), forceConnectPopup: false };

      this.localStorage.removeItem(`dismissed-${ConnectionRequestConfigModalDefinition.identifier}`);
      void this.router.replaceWith(transition.to.name, { queryParams });
    }
  }

  model(): CurrentZoneRouteModel | null {
    return this.state.zoneState;
  }

  async afterModel({ currentZone }: CurrentZoneRouteModel, transition: Transition): Promise<void> {
    // run check for property/location context in case this is initial load
    this.#handleContextTransition(transition);

    const { currentUser, currentCompany } = this.state;

    if (currentZone && currentUser && currentCompany) {
      await this.currentZone.setup(currentZone);

      // load roles
      await this.currentAdmin.setRolesTask
        .perform({ currentUser, currentCompany, currentZone })
        .catch(throwUnlessTaskDidCancel);
    }
  }

  deactivate(): void {
    this.currentZone.teardown();
  }

  redirect({ currentZone }: CurrentZoneRouteModel, transition: Transition): void {
    const transitioningToProperty = transition.to?.name.startsWith('property');
    const url = new URL(this.router.currentURL, this.windowLocation.href);
    const path = url.pathname;
    const transitionFromBaseRoute = path === '/' || path === '/login';

    // redirect to property when applicable
    if (currentZone && transitionFromBaseRoute && !transitioningToProperty) {
      void this.router.replaceWith('property');
    }
  }

  setupController(controller: CurrentZoneController, model: CurrentZoneRouteModel, transition: Transition): void {
    super.setupController(controller, model, transition);

    // eslint-disable-next-line ember/no-controller-access-in-routes
    const protectedController = <ProtectedController>this.controllerFor('protected');

    protectedController.zones = model.zones;
    protectedController.currentZone = model.currentZone;
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    this.#handleContextTransition(transition);
  }

  /**
   * Handles transitioning to a property or location route when a "context" is not properly set
   */
  #handleContextTransition(transition: Transition): void {
    // no need to handle transitions if connect is not enabled
    if (!this.state.connectSubscription) return;

    const fromProperty = transition.from?.name.startsWith('property');
    const toProperty = transition.to?.name.startsWith('property');

    const baseFrom = transition.from?.name.split('.')[0];
    const baseTo = transition.to?.name.split('.')[0];

    const toLocation = !LOCATION_ROUTES.includes(baseFrom ?? '') && baseTo && LOCATION_ROUTES.includes(baseTo);

    const isSharedRouteTransition =
      (baseFrom && SHARED_ROUTES.includes(baseFrom)) || (baseTo && SHARED_ROUTES.includes(baseTo));

    const defaultToProperty = !fromProperty && toProperty && !this.contextSwitcher.zoneId && !isSharedRouteTransition;
    const defaultToLocation =
      (fromProperty || toLocation) && !toProperty && this.contextSwitcher.zoneId && !isSharedRouteTransition;

    if (defaultToProperty) {
      // navigating from location to property with no zone selected
      const model = <CurrentZoneRouteModel | undefined>this.modelFor(this.routeName);
      let zone: ZoneModel | undefined;
      if (model?.zones) zone = this.getFirstZone(model.zones);

      if (zone && transition.to) {
        void this.contextSwitcher.transitionToPropertyRoute(transition.to.name, zone, transition.to.queryParams);
      }
    } else if (defaultToLocation) {
      // default to location when navigating from property to location
      // or to a location based route from a cold refresh when a property is in the cache
      this.contextSwitcher.zoneId = null;
      void this.router.refresh('application');
    }
  }

  getFirstZone(zones: ArrayProxy<ZoneModel> | NativeArray<ZoneModel>): ZoneModel | undefined {
    // return first zone that belongs to the current company
    return zones.sortBy('name').find((zone) => zone.belongsTo('company').id() === this.state.currentCompany?.id);
  }
}

import { A } from '@ember/array';
import type EmberObject from '@ember/object';
import { set } from '@ember/object';
import UserDatum from 'garaje/models/user-datum';

/**
 * @param {MutableArray<Object>} userData array of { field, value }
 * @param {string} field
 * @param {string} value
 */

export interface Datum {
  field: string;
  value?: string;
}

type HasUserData = EmberObject & {
  userData: Datum[];
};

function addOrUpdateUserData(model: HasUserData, field: string, value?: string): string | void {
  const { userData } = model;
  const data = A(userData);
  const found = data.findBy('field', field);

  if (found) {
    if (value) {
      return set(found, 'value', value);
    }
    data.removeObject(found);
  } else if (value) {
    data.addObject(new UserDatum({ field, value }));
  }
  // need to reassign to key to detect changes
  // eslint-disable-next-line ember/use-ember-get-and-set
  model.set('userData', data);
}

export default addOrUpdateUserData;

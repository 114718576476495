import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * @param {String}              cssColor
 * @param {Function}            updateColor
 * @param {Function}            onHideAction
 * @param {Function}            onChangeAction
 */
export default class ColorPicker extends Component {
  colpickLayout = 'hex';
  colorScheme = 'light';
  flat = false;

  element;

  get value() {
    const { cssColor } = this.args;
    return cssColor ? cssColor.replace(/^#/, '') : '';
  }

  @action
  onInsert(element) {
    this.element = element;
  }

  @action
  onUpdate() {
    const { value } = this;
    this.args.setColor?.(`#${value}`);
  }

  @action
  onKeyUp() {
    this.element.click();
  }
}

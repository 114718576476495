import { Day } from 'garaje/utils/enums';

export default function dayName(day: Day): string {
  switch (day) {
    case Day.Sunday:
      return 'Sunday';
    case Day.Monday:
      return 'Monday';
    case Day.Tuesday:
      return 'Tuesday';
    case Day.Wednesday:
      return 'Wednesday';
    case Day.Thursday:
      return 'Thursday';
    case Day.Friday:
      return 'Friday';
    case Day.Saturday:
      return 'Saturday';
  }
}

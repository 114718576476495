import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * @param {Boolean}        slackIntegrationEnabled
 * @param {Boolean}        isInactive
 * @param {Boolean}        slackNotificationsEnabled
 * @param {Task}           toggleNotificationSetting
 */
export default class SlackSettingsPanelComponent extends Component {
  /**
    @return {undefined|Promise}
  */
  @action toggleSetting(name, value) {
    if (this.args.toggleNotificationSetting && typeof this.args.toggleNotificationSetting.perform === 'function') {
      return this.args.toggleNotificationSetting.perform(name, value);
    }
  }
}

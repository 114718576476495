import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { dropTask, all } from 'ember-concurrency';
import type FlowModel from 'garaje/models/flow';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { FLOW_TYPE, APP } from 'garaje/utils/enums';
import { filterBy, or } from 'macro-decorators';

import type { VisitorTypesIndexRouteModel } from './route';

export default class VisitorTypesIndexController extends Controller {
  declare model: VisitorTypesIndexRouteModel;

  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;

  @filterBy('model.allFlowsForCurrentLocation', 'enabled', true) enabledFlows!: FlowModel[];
  @filterBy('flows', 'type', FLOW_TYPE.GLOBAL_CHILD) globalChildFlows!: FlowModel[];

  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isLocationAdmin') isAdmin!: boolean;

  @tracked showIpadDemoModal = false;

  app = APP.VISITORS;

  get flows(): FlowModel[] {
    const { allFlowsForCurrentLocation: flows } = this.model;
    return flows.filter(
      ({ type }) => isBlank(type) || type.toLowerCase() === 'flows' || type === FLOW_TYPE.GLOBAL_CHILD,
    );
  }

  get walkupFlow(): FlowModel | undefined {
    const { allFlowsForCurrentLocation: flows } = this.model;
    return flows.find(({ type }) => type === FLOW_TYPE.PROPERTY_WALKUP);
  }

  get locationIsConnectedToProperty(): boolean {
    return <number>this.model.connectedTenants.length > 0;
  }

  get showSetupGuide(): boolean {
    return this.isAdmin && this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper');
  }

  get flowsWithNoPhoneNumbersAsArray(): FlowModel[] {
    const { flowsWithNoPhoneNumberRequired: flows } = this.model;
    if (!flows) {
      return [];
    }
    return flows.toArray();
  }

  get isUserEligibleForModal(): boolean {
    return this.featureFlags.isEnabled('growth_ipad_demo');
  }

  @action
  showModal(): void {
    if (this.featureFlags.isEnabled('growth_ipad_demo')) {
      this.showIpadDemoModal = true;
    }
  }

  @action
  closeModal(): void {
    if (this.featureFlags.isEnabled('growth_ipad_demo')) {
      this.showIpadDemoModal = false;
    }
  }

  updateFlowPositions = dropTask(async (flows: FlowModel[]) => {
    await all(
      flows.map((flow, index) => {
        flow.position = index + 1;
        return flow.save();
      }),
    );
  });
}

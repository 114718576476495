import ApplicationSerializer from './application';

export default class ConnectInviteSerializer extends ApplicationSerializer {
  attrs = {
    approvalStatus: {
      serialize: false,
    },
    email: {
      serialize: false,
    },
    hostName: {
      serialize: false,
    },
    inviterName: {
      serialize: false,
    },
    visitorCategory: {
      serialize: false,
    },
    groupName: {
      serialize: false,
    },
    photoUrl: {
      serialize: false,
    },
    suites: {
      serialize: false,
    },
  };
  modelNameFromPayloadKey(...args: Parameters<ApplicationSerializer['modelNameFromPayloadKey']>): string {
    const [key] = args;
    if (key === 'invites') {
      return 'connect-invite';
    } else {
      return super.modelNameFromPayloadKey(...args);
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'connect-invite': ConnectInviteSerializer;
  }
}

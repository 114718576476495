import { getTimezoneOffset } from 'date-fns-tz';

// return the timezone offset in minutes between the user and a location
function minutesBetweenTimezones(locationTimezone) {
  const locationOffset = (getTimezoneOffset(locationTimezone) || 0) / -60000; // offset in minutes
  const userOffset = new Date().getTimezoneOffset(); //offset in minutes
  return locationOffset - userOffset;
}

export default minutesBetweenTimezones;

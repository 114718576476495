import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import type ArrayProxy from '@ember/array/proxy';
import { get } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { lastValue, task } from 'ember-concurrency';
import FlowModel from 'garaje/models/flow';
import type GlobalFlowModel from 'garaje/models/global-flow';
import type UserDocumentModel from 'garaje/models/user-document';
import type UserDocumentTemplateModel from 'garaje/models/user-document-template';
import type { Identifier } from 'garaje/models/user-document-template';
import type UserDocumentTemplateConfiguration from 'garaje/models/user-document-template-configuration';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type LocalStorageService from 'garaje/services/local-storage';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';
import { localCopy } from 'tracked-toolbox';

import type FlashMessagesService from './flash-messages';

const checkIfConfigActive = (featureFlags: FeatureFlagsService) => {
  return (userDocumentTemplateConfiguration: UserDocumentTemplateConfiguration) => {
    if (!userDocumentTemplateConfiguration.active) return false;

    // eslint-disable-next-line ember/no-get
    const featureFlag = get(userDocumentTemplateConfiguration, 'userDocumentTemplate.featureFlag');

    return typeof featureFlag === 'string' ? featureFlags.isEnabled(featureFlag) : true;
  };
};

interface Configurations {
  userDocumentTemplateConfigurations:
    | RecordArray<UserDocumentTemplateConfiguration>
    | NativeArray<UserDocumentTemplateConfiguration>
    | null;
  latestDocumentsByIdentifier: Record<string, RecordArray<UserDocumentModel>>;
  userDocumentTemplates: ArrayProxy<UserDocumentTemplateModel> | null;
}

export default class UserDocumentService extends Service {
  @service declare state: StateService;
  @service declare store: StoreService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare localStorage: LocalStorageService;
  @service declare flashMessages: FlashMessagesService;

  @lastValue('initialize') configurations!: Configurations;

  @localCopy('configurations.latestDocumentsByIdentifier')
  latestDocumentsByIdentifier!: Configurations['latestDocumentsByIdentifier'];

  @localCopy('configurations.userDocumentTemplateConfigurations')
  userDocumentTemplateConfigurations!: Configurations['userDocumentTemplateConfigurations'];

  @localCopy('configurations.userDocumentTemplates') userDocumentTemplates!: Configurations['userDocumentTemplates'];

  initialize = task(async () => {
    let userDocumentTemplateConfigurations:
      | RecordArray<UserDocumentTemplateConfiguration>
      | NativeArray<UserDocumentTemplateConfiguration>
      | null = A();
    let latestDocumentsByIdentifier;

    if (this.state.currentLocation) {
      userDocumentTemplateConfigurations = await this.loadConfigurations.perform();
    }

    const userDocumentTemplates = this.loadTemplates.lastSuccessful?.value ?? (await this.loadTemplates.perform());

    if (this.state.currentUser) {
      latestDocumentsByIdentifier = await this.loadLatestDocumentsByIdentifier.perform(
        userDocumentTemplateConfigurations!.filter(checkIfConfigActive(this.featureFlags)),
      );
    }

    return {
      latestDocumentsByIdentifier,
      userDocumentTemplateConfigurations,
      userDocumentTemplates,
    };
  });

  loadConfigurations = task(async () => {
    let userDocumentTemplateConfigurations = null;

    try {
      userDocumentTemplateConfigurations = await this.store.query('user-document-template-configuration', {
        include: 'user-document-template',
        filter: {
          'location-id': this.state.currentLocation.id,
          active: true,
        },
      });
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }

    return userDocumentTemplateConfigurations;
  });

  loadTemplates = task(async () => {
    let templates: ArrayProxy<UserDocumentTemplateModel> | null = null;

    try {
      templates = await this.store.findAll('user-document-template', { include: 'user-document-template-attachments' });
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }

    return templates;
  });

  loadLatestDocumentsByIdentifier = task(
    async (userDocumentTemplateConfigurations: UserDocumentTemplateConfiguration[]) => {
      let loadLatestDocumentsByIdentifier;

      try {
        const promises: Record<string, Promise<RecordArray<UserDocumentModel>>> = {};
        // only load documents for active templates
        userDocumentTemplateConfigurations.map((config) => {
          // eslint-disable-next-line ember/no-get
          const identifier = <string>get(config, 'userDocumentTemplate.identifier');
          promises[identifier] = this.store.query('user-document', {
            include:
              'user-document-template,user-document-links,user-document-attachments,user-document-location-contexts,user-document-attachments.user-document-template-attachment',
            filter: {
              user: this.state.currentUser?.id,
              'user-document-template-identifier': identifier,
            },
            sort: '-created-at',
            page: { limit: 3 },
          });
        });

        loadLatestDocumentsByIdentifier = await hash(promises);
      } catch (e) {
        const errorText = parseErrorForDisplay(e);
        this.flashMessages.showAndHideFlash('error', errorText);
      }

      return loadLatestDocumentsByIdentifier;
    },
  );

  get activeUserDocumentTemplateConfigurations(): UserDocumentTemplateConfiguration[] | undefined {
    return this.userDocumentTemplateConfigurations?.filter(checkIfConfigActive(this.featureFlags));
  }

  isActive(identifier: string): boolean {
    return A(this.activeUserDocumentTemplateConfigurations).any(
      // eslint-disable-next-line ember/no-get
      (config) => get(config, 'userDocumentTemplate.identifier') === identifier,
    );
  }

  /**
   * Returns the last 3 created documents by template identifier
   *
   * @param identifier - the template identifier to search by
   * @returns - the last 3 created user documents
   */
  getLatestDocumentsByIdentifier(identifier: string): RecordArray<UserDocumentModel> | NativeArray<UserDocumentModel> {
    return (this.latestDocumentsByIdentifier ?? {})[identifier] ?? A<UserDocumentModel>();
  }

  async getUserDocumentTemplateConfigurationForFlow(
    flow: FlowModel | GlobalFlowModel,
    identifier: Identifier,
  ): Promise<UserDocumentTemplateConfiguration | undefined> {
    const userDocTemplate = this.userDocumentTemplates?.findBy('identifier', identifier);

    let config;

    if (!userDocTemplate) return;

    try {
      config = (
        await this.store.query('user-document-template-configuration', {
          filter: {
            'flow-id': flow.id,
            'user-document-template-id': userDocTemplate.id,
          },
        })
      )?.firstObject;
    } catch (e) {
      const errorText = parseErrorForDisplay(e);

      this.flashMessages.showAndHideFlash('error', errorText);
    }

    if (config && flow instanceof FlowModel) {
      flow.userDocumentTemplateConfigurations?.addObject(config);
      flow.syncActiveUserDocumentTemplateConfigurations?.();
    }

    return config;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'user-document': UserDocumentService;
  }
}

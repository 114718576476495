import Modifier from 'ember-modifier';
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';

function cleanup(instance) {
  const { element } = instance;
  element.removeEventListener('input', instance.scheduleResize);
  element.removeEventListener('change', instance.scheduleResize);
  element.removeEventListener('cut', instance.scheduleResize);
  element.removeEventListener('paste', instance.scheduleResize);
  element.removeEventListener('drop', instance.scheduleResize);
  element.removeEventListener('keydown', instance.scheduleResize);
}

export default class AutoresizeModifier extends Modifier {
  element = null;

  constructor() {
    super(...arguments);
    registerDestructor(this, cleanup);
  }

  resize() {
    const { element } = this;

    element.style.height = 'auto';
    element.style.height = element.scrollHeight + 2 + 'px';
  }

  @action
  scheduleResize() {
    scheduleOnce('afterRender', this, 'resize');
  }

  modify(element, _, { maxHeight }) {
    if (!this.element) {
      // element was just inserted
      this.element = element;

      element.style.maxHeight = maxHeight;
      element.style.overflowY = 'auto';
      element.style.minHeight = 'unset';
      element.style.resize = 'none';

      element.addEventListener('input', this.scheduleResize);
      element.addEventListener('change', this.scheduleResize);
      element.addEventListener('cut', this.scheduleResize);
      element.addEventListener('paste', this.scheduleResize);
      element.addEventListener('drop', this.scheduleResize);
      element.addEventListener('keydown', this.scheduleResize);
    }
    this.scheduleResize();
  }
}

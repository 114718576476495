import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { alias } from 'macro-decorators';

export default class SubscriptionsManagerComponent extends Component {
  @service('location-billing') billingService;

  @alias('billingService.isDirty') isDirty;
  @alias('billingService.sectionOpened') sectionOpened;
  @alias('billingService.summaryOpened') summaryOpened;
  @alias('billingService.summaryDisabled') summaryDisabled;

  get disabledPurchase() {
    const notDirty = !this.isDirty;
    const editing = !!this.sectionOpened;
    return notDirty || editing;
  }

  @action
  summarize() {
    this.billingService.createLocationSubscriptionEstimates.perform();
    this.billingService.toggleSummaryOpened(true);
  }

  @action
  cancel() {
    this.billingService.reset();
  }
}

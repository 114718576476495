import type ArrayProxy from '@ember/array/proxy';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency';
import type CompanyModel from 'garaje/models/company';
import type DeskLocationModel from 'garaje/models/desk-location';
import type FeatureModel from 'garaje/models/feature';
import type LocationModel from 'garaje/models/location';
import type SubscriptionPlanModel from 'garaje/models/subscription-plan';
import type AjaxService from 'garaje/services/ajax';
import type BannerManagerService from 'garaje/services/banner-manager';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type { AnySubscriptionModel, MaybeSubscriptionModel } from 'garaje/services/state';
import { APP } from 'garaje/utils/enums';
import { TrackedArray } from 'tracked-built-ins';
import type { Constructor } from 'type-fest';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
function withSubscriptionStateFunctionality<TBase extends Constructor<any>>(Base: TBase) {
  class withSubscriptionStateFunctionality extends Base {
    declare visitorsSubscription?: MaybeSubscriptionModel;
    declare desksSubscription?: MaybeSubscriptionModel;
    declare deliveriesSubscription?: MaybeSubscriptionModel;
    declare roomsSubscription?: MaybeSubscriptionModel;
    declare workplaceSubscription?: MaybeSubscriptionModel;
    declare connectSubscription?: MaybeSubscriptionModel;
    declare emnoSubscription?: MaybeSubscriptionModel;
    declare vfdSubscription?: MaybeSubscriptionModel;
    declare features?: FeatureModel | null;

    declare realDeliveriesSubscription?: AnySubscriptionModel;
    declare realRoomsSubscription?: AnySubscriptionModel;
    @tracked realDesksSubscription?: AnySubscriptionModel;

    @tracked hasAnySubscriptions = false;

    declare currentLocation?: LocationModel | null;
    declare currentCompany: CompanyModel | null;

    declare allPlans: ArrayProxy<SubscriptionPlanModel> | null;

    declare allDeskLocations: DeskLocationModel[];
    declare activeDeskLocations: DeskLocationModel[];

    declare _companyMeta: unknown;

    @service declare featureFlags: FeatureFlagsService;
    @service declare store: StoreService;
    @service declare ajax: AjaxService;
    @service declare bannerManager: BannerManagerService;
    @service declare featureConfig: FeatureConfigService;
    @service declare flashMessages: FlashMessagesService;

    initSubscriptionStateTask = restartableTask(
      async (
        incomingLocation?: LocationModel,
        preloadedSubscriptions?: AnySubscriptionModel[],
        preloadedFeatures?: FeatureModel | null,
      ) => {
        const { featureFlags, currentCompany, currentLocation } = this;

        if (!currentCompany) {
          return;
        }

        if (!currentLocation) {
          this.currentLocation = incomingLocation;
        }

        if (featureFlags.isEnabled('locationBilling') && this.currentLocation) {
          await this.currentLocation.hasMany('locationSubscriptions').reload();
          const locationSubscriptions = await this.currentLocation.locationSubscriptions;

          this.hasAnySubscriptions = locationSubscriptions.toArray().length > 0;
          this.visitorsSubscription = locationSubscriptions.find((sub) => sub.app === APP.VISITORS);
          this.deliveriesSubscription = locationSubscriptions.find((sub) => sub.app === APP.DELIVERIES);
          this.roomsSubscription = locationSubscriptions.find((sub) => sub.app === APP.ROOMS);
          this.desksSubscription = locationSubscriptions.find((sub) => sub.app === APP.DESKS);
          this.workplaceSubscription = locationSubscriptions.find((sub) => sub.app === APP.WORKPLACE);
          this.connectSubscription = locationSubscriptions.find((sub) => sub.app === APP.CONNECT);
          this.emnoSubscription = locationSubscriptions.find((sub) => sub.app === APP.EMNO);
          this.vfdSubscription = locationSubscriptions.find((sub) => sub.app === APP.VFD);
          this.allPlans = await this.store.findAll('subscription-plan');

          this.features = await this.store.queryRecord('feature', {
            filter: { company_id: currentCompany?.id, location_id: currentLocation?.id },
          });

          this.realDeliveriesSubscription = locationSubscriptions.find(
            (sub) => sub.app === APP.DELIVERIES && !sub.faked,
          );
          this.realRoomsSubscription = locationSubscriptions.find((sub) => sub.app === APP.ROOMS && !sub.faked);
          this.realDesksSubscription = locationSubscriptions.find((sub) => sub.app === APP.DESKS && !sub.faked);
        } else {
          let subscriptions = preloadedSubscriptions;

          if (!subscriptions) {
            const subscriptionsFilter = { filter: { company_id: currentCompany.id } };
            subscriptions = (await this.store.query('subscription', subscriptionsFilter)).toArray();
          }

          this.hasAnySubscriptions = subscriptions.length > 0;
          this.visitorsSubscription = subscriptions.find(
            (subscription) => subscription.app === APP.VISITORS && !subscription.faked,
          );
          this.deliveriesSubscription = subscriptions.find((subscription) => subscription.app === APP.DELIVERIES);
          this.roomsSubscription = subscriptions.find((subscription) => subscription.app === APP.ROOMS);
          this.desksSubscription = subscriptions.find((subscription) => subscription.app === APP.DESKS);
          this.workplaceSubscription = subscriptions.find((subscription) => subscription.app === APP.WORKPLACE);
          this.connectSubscription = subscriptions.find((subscription) => subscription.app === APP.CONNECT);
          this.emnoSubscription = subscriptions.find((subscription) => subscription.app === APP.EMNO);
          this.vfdSubscription = subscriptions.find((subscription) => subscription.app === APP.VFD);

          this.features = preloadedFeatures;

          // Load features if not passed in
          if (this.features === undefined) {
            const featuresFilter = { filter: { company_id: currentCompany?.id } };
            this.features = await this.store.queryRecord('feature', featuresFilter);
          }

          // real subscription to show on billing page
          this.realDeliveriesSubscription = subscriptions.find(
            (subscription) => subscription.app === APP.DELIVERIES && !subscription.faked,
          );
          this.realRoomsSubscription = subscriptions.find(
            (subscription) => subscription.app === APP.ROOMS && !subscription.faked,
          );
          this.realDesksSubscription = subscriptions.find(
            (subscription) => subscription.app === APP.DESKS && !subscription.faked,
          );
        }

        if (this.visitorsSubscription) {
          void this.checkRestrictionsTask.perform();
        }

        if (this.desksSubscription && this.featureConfig.isEnabled('desks')) {
          void this.setDeskLocationsTask.perform();
        }
      },
    );

    checkRestrictionsTask = restartableTask(async (refresh = true) => {
      const { currentCompany, visitorsSubscription } = this;

      if (!visitorsSubscription) {
        return;
      }

      const locationLimit = parseFloat(this.visitorsSubscription!.locationLimit);

      if (!visitorsSubscription.isSubscribed && locationLimit !== Infinity) {
        await this._getCompanyMeta.perform(currentCompany!, refresh);
      }
    });

    _getCompanyMeta = restartableTask(async (currentCompany: CompanyModel, refresh = false) => {
      if (this._companyMeta && !refresh) {
        return this._companyMeta;
      } else {
        const adapter = this.store.adapterFor('company');
        let meta = null;
        try {
          const url = `${adapter.buildURL('company', currentCompany.id)}?counts-meta=1`;
          const data = await this.ajax.request<{ meta: unknown }>(url);
          this._companyMeta = data.meta;
          meta = data.meta;
        } catch (_e) {
          // do nothing
        }
        return meta;
      }
    });

    setDeskLocationsTask = restartableTask(async () => {
      const { allDeskLocations, activeDeskLocations } = await this.getDeskLocations.perform();
      this.allDeskLocations = new TrackedArray(allDeskLocations);
      this.activeDeskLocations = new TrackedArray(activeDeskLocations);
    });

    getDeskLocations = restartableTask(async () => {
      try {
        const adapterOptions = { companyId: this.currentCompany?.id };
        const deskLocations = await this.store.findAll('desk-location', { reload: true, adapterOptions });

        let allDeskLocations: DeskLocationModel[] = [];
        let activeDeskLocations: DeskLocationModel[] = [];

        if (deskLocations?.length) {
          allDeskLocations = deskLocations.toArray();
          activeDeskLocations = allDeskLocations.filter((dl) => dl.active);
        }

        return { allDeskLocations, activeDeskLocations };
      } catch {
        this.flashMessages.showFlash(
          'error',
          'Some of our backend systems are currently down.  Desks-related features may not work properly.  This should be resolved soon.',
        );
        return { allDeskLocations: [], activeDeskLocations: [] };
      }
    });
  }

  return withSubscriptionStateFunctionality;
}

export default withSubscriptionStateFunctionality;

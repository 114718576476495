import { service } from '@ember/service';
import Component from '@glimmer/component';
import type IsOpenService from 'garaje/services/is-open';

interface RoomsMenuArgs {
  trackClickEvent: (trackingString: string) => void;
}

export default class RoomsMenuComponent extends Component<RoomsMenuArgs> {
  @service declare isOpen: IsOpenService;
}

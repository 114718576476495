import LocationFragment from 'garaje/graphql/fragments/roomba/LocationFragment';
import gql from 'graphql-tag';

export default gql`
  query GetRoomsLocationWithConfigs($id: ID!) {
    location(id: $id) {
      ...LocationFragment
      roomResizerEnabled
      onsiteRoomRecaptureEnabled
      roomsPreCheckInWindow
      roomsCheckInWindow
      roomCheckInEnabled
    }
  }
  ${LocationFragment}
`;

import RoomFragment from 'garaje/graphql/fragments/roomba/RoomFragment';
import gql from 'graphql-tag';

export default gql`
  mutation UpdateRoomConfig($input: RoomConfigInput!) {
    updateRoomConfig(input: $input) {
      ...RoomFragment
    }
  }
  ${RoomFragment}
`;

import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { get } from '@ember/object';
import { routeEvent } from 'garaje/utils/decorators/route';
import { resolve } from 'rsvp';
import Changeset from 'ember-changeset';

// NOTE: this file is extended by a vfd settings route
export default class SettingsWelcomeScreenRoute extends Route {
  titleToken = 'Design';

  @service state;
  @service transitionConfirm;

  async model() {
    const { currentLocation } = this.state;
    const config = await resolve(get(currentLocation, 'config'));

    return {
      config,
      changeset: new Changeset(config),
      slideshows: (await config.slideshows).slice(),
    };
  }

  @routeEvent
  routeWillChange(transition) {
    const { changeset, config } = this.controller.model;
    const dirtySlideshows = config.slideshows.filter((changeset) => changeset.hasDirtyAttributes);

    if (!changeset.isDirty && !dirtySlideshows.length) return;
    // display confirmation modal if settings are dirty
    this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        if (changeset.isDirty) changeset.rollback();
        if (dirtySlideshows.length) {
          for (const changeset of dirtySlideshows) {
            changeset.rollbackAttributes();
          }
        }
      },
    });
  }
}

import { helper } from '@ember/component/helper';

export function columnComponentName(field) {
  /* eslint-disable */
  let name = field[0];
  let componentName = field[1];
  let fieldOptions = field[2];
  let arrayContainsName = fieldOptions.findBy('name', name);
  /* eslint-enable */

  return name === 'blank-column' || !arrayContainsName ? 'blank-column' : componentName;
}

export default helper(columnComponentName);

import Model, { attr, belongsTo, hasMany, type AsyncHasMany, type AsyncBelongsTo } from '@ember-data/model';
import type AmenityModel from 'garaje/models/amenity';
import type AssignmentModel from 'garaje/models/assignment';
import type FloorModel from 'garaje/models/floor';
import type ReservationModel from 'garaje/models/reservation';
import adapter from 'garaje/utils/decorators/adapter';
import RelationshipsTracker from 'garaje/utils/decorators/relationships-tracker';
import { PENDING_UNASSIGNMENT } from 'garaje/utils/resource-map';

import type DraftModel from './draft';
import type NeighborhoodModel from './neighborhood';

export type AssignmentCreationDetails = {
  'employee-id': number | null;
  'start-time': string;
  'scheduled-by': number | null;
  'should-send-notification': boolean;
};

@RelationshipsTracker
@adapter('desk')
class DeskModel extends Model {
  // Attributes
  @attr('string') declare name: string;
  @attr('boolean') declare enabled: boolean;
  @attr('string') declare assignedTo: string;
  @attr('number') declare xPos: number;
  @attr('number') declare yPos: number;
  @attr('number') declare parentDeskId: number;
  @attr('array', { defaultValue: () => null }) declare assignmentDetails: AssignmentCreationDetails[] | null;

  // Relationships
  @belongsTo('floor') declare floor: FloorModel;
  @belongsTo('draft') declare draft: DraftModel;
  @belongsTo('neighborhood') declare neighborhood: AsyncBelongsTo<NeighborhoodModel>;
  @hasMany('reservation') declare reservations: AsyncHasMany<ReservationModel>;
  @hasMany('amenity') declare amenities: AsyncHasMany<AmenityModel>;
  @hasMany('assignment') declare assignments: AsyncHasMany<AssignmentModel>;

  assignmentEmployeeId(): string | undefined {
    // @ts-ignore
    const resolvedAssignmentIds = this.hasMany('assignments').ids();
    const firstAssignmentDetail = this.assignmentDetails?.[0] ? this.assignmentDetails[0] : undefined;
    const resolvedAssignments = this.store
      .peekAll('assignment')
      .toArray()
      .filter((assignment) => resolvedAssignmentIds.includes(assignment.id));
    if (firstAssignmentDetail) {
      const employeeId = firstAssignmentDetail['employee-id']?.toString();
      if (employeeId) {
        return employeeId;
      } else {
        return PENDING_UNASSIGNMENT;
      }
    } else if (resolvedAssignments.length) {
      const employeeId = resolvedAssignments[0]?.employee?.id;
      if (employeeId) {
        return employeeId;
      } else {
        return PENDING_UNASSIGNMENT;
      }
    } else {
      return undefined;
    }
  }

  assignmentStartDate(): string | undefined {
    // @ts-ignore
    const resolvedAssignmentIds = this.hasMany('assignments').ids();
    const firstAssignmentDetail = this.assignmentDetails?.[0] ? this.assignmentDetails[0] : undefined;
    const resolvedAssignments = this.store
      .peekAll('assignment')
      .toArray()
      .filter((assignment) => resolvedAssignmentIds.includes(assignment.id));
    return firstAssignmentDetail
      ? firstAssignmentDetail['start-time'].toString()
      : resolvedAssignments.length
        ? resolvedAssignments[0]?.startTime
        : undefined;
  }

  get neighborhoodName(): string | null {
    const key = 'neighborhood' as Exclude<keyof this, keyof Model>;
    const neighborhood = this?.belongsTo(key)?.value() as NeighborhoodModel;

    return neighborhood?.name;
  }

  get placed(): boolean {
    return !!this.xPos && !!this.yPos;
  }

  saveWithAssignments(): Promise<this> {
    return this.save({ adapterOptions: { include: 'assignments' } });
  }
}

export default DeskModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    desk: DeskModel;
  }
}

import { action } from '@ember/object';
import { service } from '@ember/service';

export default function (Class) {
  return class EventsLogger extends Class {
    @service metrics;

    @action
    logPlaced(properties) {
      return this.metrics.trackEvent('Map - Place resource', properties);
    }

    @action
    logEdited(properties) {
      return this.metrics.trackEvent('Map - Edit resource', properties);
    }

    @action
    logDisabled(properties) {
      return this.metrics.trackEvent('Map - Disable resource', properties);
    }

    @action
    logEnabled(properties) {
      return this.metrics.trackEvent('Map - Enable resource', properties);
    }

    @action
    logDeleted(properties) {
      return this.metrics.trackEvent('Map - Delete resource', properties);
    }
  };
}

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class BannerBase extends Component {
  @service metrics;

  @action
  logClicked() {
    this.metrics.trackEvent('CTA Clicked', this.args.ctaEventProperties);
  }

  @action
  logViewed() {
    this.metrics.trackEvent('CTA Viewed', this.args.ctaEventProperties);
  }

  @action
  logDismissed() {
    this.metrics.trackEvent('CTA Dismissed', this.args.ctaEventProperties);
  }
}

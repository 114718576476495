import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ProtectIndexRoute extends Route {
  @service abilities;
  @service featureFlags;
  @service router;
  @service workplaceMetrics;

  beforeModel() {
    this.workplaceMetrics.trackEvent('PROTECT_ROUTE_VISITED');
    const pathToEmployeeRegistrationSettings = 'protect.settings.employees.registration';
    if (this.abilities.can('visit workplace')) {
      return this.router.transitionTo('workplace');
    }
    if (this.abilities.can('visit protect')) {
      if (this.abilities.can('visit landing for protect')) {
        return this.router.replaceWith('protect.landing-page');
      }
      if (this.abilities.can('visit screening for protect')) {
        return this.router.replaceWith(pathToEmployeeRegistrationSettings);
      }
      if (this.abilities.can('visit capacity for protect')) {
        return this.router.replaceWith('protect.settings.capacity');
      }
      if (this.abilities.can('visit announcements for protect')) {
        return this.router.replaceWith('protect.announcements');
      }
    }
    return this.router.transitionTo('unauthorized');
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { PairablePrinter } from 'garaje/utils/pairable-printers';

interface PrinterModelSelectArgs {
  /**
   * list of printer to display in the select
   */
  pairablePrinters: PairablePrinter[];
  /**
   * link to transition to when other printers is selected
   */
  otherPrintersLink: string;
  /**
   * selected printer
   */
  selectedPrinter: PairablePrinter;
  /**
   * @param printer action for changing selected printer
   */
  changeSelectedPrinter: (printer: PairablePrinter) => void;
}

export default class PrinterModelSelect extends Component<PrinterModelSelectArgs> {
  @tracked currentSelectedPrinter;

  constructor(owner: unknown, args: PrinterModelSelectArgs) {
    super(owner, args);
    this.currentSelectedPrinter = this.args.selectedPrinter;
  }
}

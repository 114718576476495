import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';

export default class Discount extends Component {
  @tracked validTillStr = null;

  constructor() {
    super(...arguments);
    this.getValidTillStr();
  }

  getValidTillStr() {
    if (this.args.discount.validTill) {
      this.validTillStr = moment(this.args.discount.validTill).format('MMM DD, YYYY');
    }
  }
}

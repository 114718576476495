import gql from 'graphql-tag';

export default gql`
  fragment DeviceFragment on Device {
    appVersion
    batteryLevel
    bluetoothStatus
    chargingStatus
    clientId
    id
    jackStatus
    ledAudioType
    lightbarDetected
    model
    name
    offlineAt
    osVersion
    volume
    lastOnlineAt
  }
`;

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type LoginService from 'garaje/services/login';
import type SessionService from 'garaje/services/session';
import type WindowLocationService from 'garaje/services/window-location';

interface AuthRedirectRouteParams {
  url: string;
}

/**
 * This route exists to allow a user to either directly access a URL (given by the `url` query param)
 * if they are authenticated, or show them the login form and then redirect them to that URL once
 * they are authenticated. This is meant to be general-purpose and so the URL may not correspond to a
 * route in garaje.
 *
 * This cannot just be implemented directly on the `login` route because that route explicitly declares
 * that it should not be accessible to authenticated users using ember-simple-auth's `prohibitAuthentication`,
 * which redirects to a route (not a general URL) when accessed by an authenticated user.
 */
export default class AuthRedirectRoute extends Route {
  @service declare login: LoginService;
  @service declare router: RouterService;
  @service declare session: SessionService;
  @service declare windowLocation: WindowLocationService;

  queryParams = {
    url: { refreshModel: true },
  };

  model(params: AuthRedirectRouteParams, transition: Transition): void {
    if (this.session.isAuthenticated) {
      this.windowLocation.replace(params.url);
    } else {
      this.login.transition = transition;
      void this.router.transitionTo('login');
    }
  }
}

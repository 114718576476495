import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { validatePresence } from 'ember-changeset-validations/validators';

const RoleValidations = {
  roleName: validatePresence(true),
};

export default function (role) {
  return new Changeset(role, lookupValidator(RoleValidations), RoleValidations);
}

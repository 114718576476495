import gql from 'graphql-tag';

export default gql`
  fragment UnassignedEmployeeFragment on UnassignedEmployee {
    id
    name
    email
    department
    attendance {
      averageDaysPerWeek
    }
  }
`;

import BadgeSerializer from './badge';

export default class GlobalBadgeSerializer extends BadgeSerializer {
  modelNameFromPayloadKey(key) {
    if (key === 'flows') {
      return 'global-flow';
    } else if (key === 'badges') {
      return 'global-badge';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }

  payloadKeyFromModelName(modelName) {
    if (modelName === 'global-flow') {
      return 'flows';
    } else if (modelName === 'global-badge') {
      return 'badges';
    } else {
      return super.payloadKeyFromModelName(...arguments);
    }
  }
}

import { service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import type ScimIntegrationModel from 'garaje/models/scim-integration';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

interface ConfirmDisconnectDialogArgs {
  model: ScimIntegrationModel;
  closeDialog: () => void;
  closeBox: () => void;
  formattedProvider: string;
  integrationDisconnected: () => void;
}

export default class ConfirmDisconnectDialog extends Component<ConfirmDisconnectDialogArgs> {
  @service declare flashMessages: FlashMessagesService;

  disconnectTask = task(async () => {
    try {
      await this.args.model.destroyRecord();
      this.args.closeDialog();
      this.args.closeBox();
      this.args.integrationDisconnected();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}

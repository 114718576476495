import {
  GLOBAL_ADMIN,
  LOCATION_ADMIN,
  RECEPTIONIST,
  DELIVERIES_ADMIN,
  SECURITY,
  BILLING,
  EMPLOYEE,
} from 'garaje/utils/roles';

export const PERMISSIONS = Object.freeze([
  {
    name: 'Manage employee directory',
    type: 'account',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN],
    readOnly: [RECEPTIONIST],
  },
  {
    name: 'Manage admin roles',
    type: 'account',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN],
    readOnly: [RECEPTIONIST],
  },
  {
    name: 'View/edit billing details',
    type: 'account',
    allowed: [GLOBAL_ADMIN, BILLING],
    readOnly: [],
  },
  {
    name: 'Add locations',
    type: 'account',
    allowed: [GLOBAL_ADMIN],
    readOnly: [],
  },
  {
    name: 'Add delivery areas',
    type: 'account',
    allowed: [GLOBAL_ADMIN],
    readOnly: [],
  },
  {
    name: 'View their own visitors',
    type: 'visitors',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, DELIVERIES_ADMIN, EMPLOYEE],
    readOnly: [],
  },
  {
    name: 'View all visitor entries',
    type: 'visitors',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST],
    readOnly: [],
  },
  {
    name: 'Edit visitor entries',
    type: 'visitors',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST],
    readOnly: [],
  },
  {
    name: 'Delete visitor entries',
    type: 'visitors',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN],
    readOnly: [],
  },
  {
    name: 'Create invites',
    type: 'visitors',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, DELIVERIES_ADMIN, EMPLOYEE],
    readOnly: [],
  },
  {
    name: 'View invited visitor list',
    type: 'visitors',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, SECURITY],
    readOnly: [],
  },
  {
    name: 'Edit/delete all invites',
    type: 'visitors',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST],
    readOnly: [],
  },
  {
    name: 'Manage devices',
    type: 'visitors',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN],
    readOnly: [RECEPTIONIST],
  },
  {
    name: 'Edit settings',
    type: 'visitors',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN],
    readOnly: [],
  },
  {
    name: 'View their own deliveries',
    type: 'deliveries',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, DELIVERIES_ADMIN, EMPLOYEE],
    readOnly: [],
  },
  {
    name: 'View all deliveries',
    type: 'deliveries',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, DELIVERIES_ADMIN],
    readOnly: [],
  },
  {
    name: 'Edit delivery details',
    type: 'deliveries',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, DELIVERIES_ADMIN],
    readOnly: [],
  },
  {
    name: 'Delete deliveries',
    type: 'deliveries',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, DELIVERIES_ADMIN],
    readOnly: [],
  },
  {
    name: 'Record deliveries (mobile app)',
    type: 'deliveries',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, DELIVERIES_ADMIN],
    readOnly: [],
  },
  {
    name: 'Edit settings',
    type: 'deliveries',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN],
    readOnly: [],
  },
  {
    name: 'Manage desks & maps',
    type: 'desks',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST],
    readOnly: [],
  },
  {
    name: 'View all reservations',
    type: 'desks',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST],
    readOnly: [],
  },
  {
    name: 'Manage employee reservations',
    type: 'desks',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST],
    readOnly: [],
  },
  {
    name: 'Sync company calendar',
    type: 'rooms',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN],
    readOnly: [],
  },
  {
    name: 'Manage Rooms settings',
    type: 'rooms',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN],
    readOnly: [],
  },
  {
    name: 'Receive downtime alerts',
    type: 'rooms',
    allowed: [GLOBAL_ADMIN, LOCATION_ADMIN],
    readOnly: [],
  },
]);

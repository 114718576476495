import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

const revisionRegexp = /^garaje\:\w+\.html$|^index\.html:\w+$/;

class NotFoundRoute extends Route {
  @service router;

  titleToken = 'Not Found';

  beforeModel(transition) {
    if (transition.to.params['not-found'] && revisionRegexp.test(transition.to.params['not-found'].path)) {
      this.router.transitionTo('protected');
    }
  }
}

export default NotFoundRoute;

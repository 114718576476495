import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import type Store from '@ember-data/store';
import type { TaskInstance } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import type InfinityService from 'ember-infinity/services/infinity';
import ExtendedInfinityModel from 'garaje/infinity-models/v3-offset';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type DeviceModel from 'garaje/models/device';
import type PluginModel from 'garaje/models/plugin';
import type PluginInstallModel from 'garaje/models/plugin-install';
import type VfdConfigurationModel from 'garaje/models/vfd-configuration';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import { MSTEAMS_V2_PLUGIN_KEY, SLACK_V2_PLUGIN_KEY } from 'garaje/utils/enums';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

import type { LocationOverviewVirtualFrontDeskModel } from '../../route';

import type LocationOverviewVirtualFrontDeskLocationsIndexController from './controller';

export type LocationOverviewVirtualFrontDeskLocationsIndexModel = {
  configurations: PaginatedRecordArray<VfdConfigurationModel>;
  pluginInstalls: PluginInstallModel[];
  plugins: PluginModel[];
  loadIpadsTaskInstance: TaskInstance<RecordArray<DeviceModel>>;
};

const PAGE_SIZE = 50; // number of locations to request at a time using paginated API

interface LocationOverviewVirtualFrontDeskLocationsIndexRouteParams {
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  locationStatus?: string;
  query: string;
}

export default class LocationOverviewVirtualFrontDeskLocationsIndexRoute extends Route {
  @service declare infinity: InfinityService;
  @service declare store: Store;
  @service declare skinnyLocations: SkinnyLocationsService;

  queryParams = {
    sortBy: { refreshModel: true },
    sortDirection: { refreshModel: true },
    locationStatus: { refreshModel: true },
    query: { refreshModel: true },
  };

  async model(
    { sortBy, sortDirection, locationStatus, query }: LocationOverviewVirtualFrontDeskLocationsIndexRouteParams,
    transition: Transition,
  ): Promise<LocationOverviewVirtualFrontDeskLocationsIndexModel> {
    const { plugins } = <LocationOverviewVirtualFrontDeskModel>this.modelFor('location-overview.virtual-front-desk');
    let sort: string | undefined;

    if (sortDirection && sortBy) {
      const sortAttrs = [sortDirection === 'asc' ? sortBy : `-${sortBy}`];
      sort = sortAttrs.join(',');
    }

    const filter: Record<string, unknown> = {};

    if (locationStatus !== 'all') filter['enabled'] = locationStatus === 'enabled';
    if (!isBlank(query)) filter['query'] = query;

    const configurations = this.infinity.model(
      'vfd-configuration',
      {
        countParam: 'meta.total',
        include: 'contact-methods,contact-methods.user',
        pageParam: 'page[offset]',
        perPage: PAGE_SIZE,
        perPageParam: 'page[limit]',
        sort: sort ?? '-enabled,location.name',
        filter,
        startingPage: 0,
      },
      ExtendedInfinityModel,
    );

    const pluginInstalls = this.fetchAllActiveHostNotificationPluginInstalls(plugins);
    const didRefresh = transition.from?.name === this.routeName;

    // only load ipads if we haven't already loaded them and we're coming from a different route
    const loadIpadsTaskInstance =
      this.loadIpadsTask.lastSuccessful && didRefresh
        ? this.loadIpadsTask.lastSuccessful
        : this.loadIpadsTask.perform();

    return {
      ...(await hash({
        configurations,
        pluginInstalls,
        plugins,
      })),
      loadIpadsTaskInstance,
    };
  }

  // Fetch only Plugin Installs for the Slack and Teams Plugins
  fetchAllActiveHostNotificationPluginInstalls(plugins: PluginModel[]): Promise<PluginInstallModel[]> {
    const slackPlugin = plugins.find((plugin) => plugin.key === SLACK_V2_PLUGIN_KEY);
    const teamsPlugin = plugins.find((plugin) => plugin.key === MSTEAMS_V2_PLUGIN_KEY);
    const validPlugins = [slackPlugin?.id, teamsPlugin?.id].filter(Boolean);
    const page = { limit: 100, offset: 0 };
    const filter = { status: 'active', plugins: validPlugins };

    return validPlugins.length > 0
      ? this.store.query('plugin-install', { filter, page }).then((pluginInstalls) => pluginInstalls.slice())
      : Promise.resolve([]);
  }

  loadIpadsTask = task(async () => {
    return this.store.query('device', {
      page: {
        limit: 200,
      },
      filter: { location: this.skinnyLocations.readableByCurrentAdmin.map((location) => location.id) },
    });
  });

  resetController(
    controller: LocationOverviewVirtualFrontDeskLocationsIndexController,
    isExiting: boolean,
    transition: Transition,
  ): void {
    super.resetController(controller, isExiting, transition);
    if (isExiting) {
      controller.sortBy = undefined;
      controller.sortDirection = undefined;
      controller.locationStatus = 'all';
      controller.query = '';
    }
  }
}

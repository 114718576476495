import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { all } from 'rsvp';
import { GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST } from 'garaje/utils/roles';
import { Promise as EmberPromise } from 'rsvp';
import { fetchCapacities } from 'garaje/utils/locations-capacity';
import { dropTask } from 'ember-concurrency';
import moment from 'moment-timezone';

const USER_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST].join(',');

/**
 * params: currentLocation, changeset
 */
export default class SettingsCapacityComponent extends Component {
  @service flashMessages;
  @service store;

  @tracked exeededCapacities = [];
  @tracked showUpsell = false;

  resolveCapacityChange = () => {};
  limitChangeset;

  @action
  isDirty() {
    return this.limitChangeset?.isDirty;
  }

  @action
  rollback() {
    return this.limitChangeset?.rollback();
  }

  @action
  registerChangeset(changeset) {
    this.limitChangeset = changeset;
  }

  @action
  async save(changeset) {
    const { flashMessages } = this;
    try {
      await changeset.save();
      flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      throw e;
    }
  }

  @action
  async searchUsers(term) {
    const { currentLocation } = this.args;
    const userRoles = await this.store.query('user-role', {
      filter: {
        name: term,
        location: currentLocation.id,
        roles: USER_ROLES,
      },
    });
    const users = await all(userRoles.map((role) => role.user));
    return users.uniq();
  }

  @action
  async validateCapacityChange(capacityLimit) {
    const { store } = this;
    const { currentLocation } = this.args;
    const today = moment().startOf('day');
    const future = moment(today).add(1, 'month');
    const capacities = await fetchCapacities(store, currentLocation, today, future);
    const exeededCapacities = capacities.filter(({ capacityUsed }) => capacityUsed > capacityLimit);
    if (exeededCapacities.length === 0) {
      return true;
    }
    return await this.confirmCapacityModalTask.perform(exeededCapacities);
  }

  @dropTask
  *confirmCapacityModalTask(exeededCapacities = []) {
    this.exeededCapacities = exeededCapacities;
    return yield new EmberPromise((resolve) => {
      this.resolveCapacityChange = (value) => resolve(value);
    });
  }

  @action
  confirmCapacityChange() {
    this.resolveCapacityChange(true);
  }

  @action
  cancelCapacityChange() {
    this.resolveCapacityChange(false);
  }
}

import Controller from '@ember/controller';
import { service } from '@ember/service';
import { get, set } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';

export default class DeliveriesSettingsDeliveryAreasIndexController extends Controller {
  @service featureFlags;
  @service flashMessages;
  @service impressions;

  get activeDeliveryAreas() {
    const areas = this.featureFlags.isEnabled('locationBilling')
      ? this.model.deliveryAreas
      : this.model.companyDeliveryAreas;
    return areas.filter(({ enabled, isNew }) => !isNew && enabled);
  }

  get hasInsufficientQuantity() {
    return (get(this, 'model.deliveriesSubscription.quantity') ?? 0) <= this.activeDeliveryAreas.length;
  }

  @dropTask
  *trackAddDeliveryAreaClicked() {
    yield this.impressions.postImpression.perform(IMPRESSION_NAMES.DELIVERIES.ADD_DELIVERY_AREA_CLICKED);
  }

  @dropTask
  *activateDeliveryAreaTask(deliveryArea) {
    set(deliveryArea, 'enabled', true);
    try {
      yield deliveryArea.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.transitionToRoute('deliveries.settings.delivery-areas.delivery-area.index', deliveryArea.id); // eslint-disable-line ember/no-deprecated-router-transition-methods
    } catch (_e) {
      deliveryArea.rollbackAttributes();
      this.flashMessages.showFlash('error', 'Server error. Please try again.');
    }
  }
}

import { service } from '@ember/service';
import type ConnectLocationConfigurationModel from 'garaje/models/connect-location-configuration';
import ApplicationSerializer from 'garaje/serializers/application';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type { SinglePayload } from 'jsonapi/response';

export default class ConnectLocationConfigurationSerializer extends ApplicationSerializer {
  @service declare featureFlags: FeatureFlagsService;

  attrs = {
    canEnableEmployeePropertyMobileCredentialing: {
      serialize: false,
    },
    canEnableEmployeeRebadging: {
      serialize: false,
    },
    canEnableVisitorPropertyDigitalPass: {
      serialize: false,
    },
  };

  serialize(...args: Parameters<ApplicationSerializer['serialize']>): SinglePayload<ConnectLocationConfigurationModel> {
    const payload = <SinglePayload<ConnectLocationConfigurationModel>>super.serialize(...args);

    if (!this.featureFlags.isEnabled('connect-pre-reg-acs-qr-code')) {
      delete payload.data.attributes['visitor-property-digital-pass-enabled'];
    }

    return payload;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'connect-location-configuration': ConnectLocationConfigurationSerializer;
  }
}

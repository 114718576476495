import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { dropTask } from 'ember-concurrency';
import type FlashMessagesService from 'garaje/services/flash-messages';

import { type PropertySettingsFloorsEditRouteModel } from './route';

export default class PropertySettingsFloorsEditController extends Controller {
  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  declare model: PropertySettingsFloorsEditRouteModel;

  saveTask = dropTask(async () => {
    const floor = this.model.floorChangeset;
    try {
      await floor.save();
      this.flashMessages.showAndHideFlash('success', `Floor updated successfully.`);
      void this.router.transitionTo('property.settings.floors');
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      this.flashMessages.showAndHideFlash('error', 'Something went wrong, please try again.');
    }
  });

  @action
  cancel(): void {
    void this.router.transitionTo('property.settings.floors');
  }
}

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { BlocklistFilterSource } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

const APPROVAL_REQUIRED_COPY = {
  location: 'This invite requires approval by a property admin.',
  property: 'This invite requires approval by a tenant admin.',
};

/**
 * @param {Task}          approveInviteTask - ember-concurrency task to be performed when approving an invite. Receives the invite as its argument.
 * @param {Array}         blocklistContacts
 * @param {String}        context - The context in which the component is being used ("location" or "property").
 * @param {Array}         fields
 * @param {Class<Invite>} invite
 */
export default class InviteFeedItemFlagged extends Component {
  @service abilities;
  @service flashMessages;
  @service metrics;
  @service store;
  @service state;

  get canReview() {
    return this.abilities.can('review entry-approval', {
      context: this.context,
      report: this.report,
    });
  }

  get canReviewOrReadBlocklistMatch() {
    return this.abilities.can('review or read blocklist match in security', {
      context: this.context,
      didFailBlocklistCheck: this.args.invite.approvalStatus?.didFailBlocklistCheck,
      report: this.report,
    });
  }

  get context() {
    return this.args.context ?? 'location';
  }

  get report() {
    return this.args.invite.approvalStatus?.failedReport ?? [];
  }

  get hasPropertyReport() {
    return this.report.isAny('blacklistFilterSource', BlocklistFilterSource.PROPERTY);
  }

  get showDetails() {
    return this.context === 'location';
  }

  get additionalApprovalRequiredCopy() {
    // Default copy
    let copy = this.args.invite.preregistrationComplete
      ? 'This invite requires approval.'
      : 'This invite requires approval to send.';

    // If there is a failed property specific report then we need to specify whose approval is required.
    if (this.hasPropertyReport) {
      copy = APPROVAL_REQUIRED_COPY[this.context];
    }

    return copy;
  }

  get colspan() {
    // colspan is equal the the number of shown fields
    // only because we have to add 1 for the kebab menu cell,
    // but subtract 1 for the name field, which is already in the row and is not included in the colspan
    return (this.args.fields ?? []).filter((field) => field.show).length;
  }

  get contactedNames() {
    if (this.args.invite.approvalStatus?.didFailBlocklistCheck && this.args.blocklistContacts) {
      return this.args.blocklistContacts.map((contact) => contact.fullName);
    }
    return [];
  }

  get sourcesForApprovalReviewMetrics() {
    return this.report.reduce((sources, report) => ({ ...sources, [report.source]: true }), {});
  }

  @task
  *approveInviteTask(invite) {
    // This component-local version of the task exists so we can get instance-specific state.
    // The actual logic lives at a higher level so that, when invoked with `.unlinked()`, it all
    // executes; otherwise, once the approved invite was reloaded (either with an `invite.reload()`
    // call or by the pubnub subscription that gets notified of changes to invites and pushes them
    // into the store out-of-band), this component gets torn down and the task is aborted before,
    // e.g., the flash message is shown. By keeping that logic out of this component, we don't
    // have to worry about that.
    // The instance-local state means that we can disable this invite's Approve/Deny buttons when
    // approveInviteTask or denyInviteTask is running without having to track state ourselves at
    // the higher level, or ended up disabling the buttons for _all_ invites instead of this one.
    yield this.args.approveInviteTask.unlinked().perform(invite);
  }

  @task
  *denyInviteTask(invite) {
    try {
      yield invite.denyInvite();
      this.flashMessages.showAndHideFlash('warning', 'Entry denied');
      yield invite.reload();

      this.metrics.trackEvent('Dashboard Invite - Reviewed', {
        action: 'deny',
        invite_id: invite.id,
        source: 'Invite Log',
        ...this.sourcesForApprovalReviewMetrics,
      });
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', 'Error denying invite', parseErrorForDisplay(error));
    }
  }

  @action
  trackClickInviteDetail(invite_id) {
    this.metrics.trackEvent('Invite Details Clicked', { invite_id });
  }
}

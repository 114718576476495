import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

/**
 * This FeatureConfig helper determines if a feature configuration is on or off
 * Handy for template only components when you don't want to create a component.js when all you need is the featureConfig service :)
 *
 * Example usage:
 * <input readonly={{config-enabled "your-config"}} ... >
 *
 * @param {String}  config   Required. An unnamed string, passed as the first argument.
 */
export default class ConfigEnabled extends Helper {
  @service featureConfig;

  compute([config]) {
    return this.featureConfig.isEnabled(config);
  }
}

import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * @param {String}              name
 * @param {Function}            sortAction
 * @param {Boolean}             isActive
 * @param {String}              sortDirection
 * @param {String}              sortField
 */
export default class SortableHeaderColumn extends Component {
  nameWasTruncated;

  get isAsc() {
    const isActive = this.args.isActive;
    const sortDirection = this.args.sortDirection;
    return isActive && sortDirection === 'asc';
  }

  get isDesc() {
    const isActive = this.args.isActive;
    const sortDirection = this.args.sortDirection;
    return isActive && sortDirection === 'desc';
  }

  @action
  toggleSortAction() {
    const isActive = this.args.isActive;
    const sortDirection = this.args.sortDirection;
    let dir = 'asc';
    if (isActive && sortDirection === 'asc') {
      dir = 'desc';
    }
    this.args.sortAction(this.args.sortField, dir);
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { loadGoogleMaps } from 'garaje/utils/script-loader';

/**
 * @param {String}            latitude
 * @param {String}            longitude
 */
export default class GoogleMaps extends Component {
  @tracked map;
  @tracked marker;

  constructor() {
    super(...arguments);
    this._insertMap();
  }

  get latLng() {
    if (!window.google) {
      return null;
    }
    return new window.google.maps.LatLng(this.args.latitude, this.args.longitude);
  }

  @action
  handleLatLngChange() {
    const latLng = this.latLng;
    this.map.setCenter(latLng);
    this.marker.setPosition(latLng);
  }

  _insertMap() {
    loadGoogleMaps().then(() => {
      if (!window.google) {
        return;
      }

      const container = document.querySelector('.mapCanvas');
      const latLng = this.latLng;

      const options = {
        zoom: 15,
        center: latLng,
        disableDefaultUI: true,
        draggable: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
      };

      const map = new window.google.maps.Map(container, options);
      this.map = map;

      const marker = new window.google.maps.Marker({
        position: latLng,
        map,
      });
      this.marker = marker;
    });
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

/**
 * @param {Class<VisualComplianceConfiguration>}            configuration
 * @param {Class<Location>}                                 location
 * @param {Class<Flow>}                                     flow
 */
export default class VisualCompilanceConfigure extends Component {
  @service store;

  @tracked contactCount = 0;
  @tracked contactName = '';

  get isLocalityChecked() {
    return this.args.configuration?.fieldsToCheck.includes('locality');
  }

  get isCompanyChecked() {
    return this.args.configuration?.fieldsToCheck.includes('company');
  }

  constructor() {
    super(...arguments);
    this.loadContactsTask.perform();
  }

  @task
  *loadContactsTask() {
    if (isEmpty(this.args.location)) {
      return;
    }

    const locationConfig = yield get(this.args.location, 'visualComplianceConfiguration');
    if (isEmpty(locationConfig.contactIds)) return;
    const firstContact = yield this.store.findRecord('user', locationConfig.contactIds[0]);
    this.contactCount = locationConfig.contactIds.length;
    this.contactName = firstContact.fullName;
  }

  @action
  toggleFlowField({ checked, name }) {
    if (checked) {
      const added = [...(this.args.configuration?.fieldsToCheck ?? []), name];
      set(this.args.configuration, 'fieldsToCheck', added);
    } else {
      const removed = this.args.configuration?.fieldsToCheck.filter((field) => field !== name);
      set(this.args.configuration, 'fieldsToCheck', removed);
    }
  }
}

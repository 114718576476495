import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { set } from '@ember/object';

export default class extends Component {
  @service flashMessages;
  @service store;

  @task
  *toggleEnabled() {
    const newState = !this.args.flowObj.data.enabled;
    try {
      const flow = yield this.store.find('flow', this.args.flowObj.id);
      flow.enabled = newState;
      yield flow.save();
      set(this.args.flowObj, 'data.enabled', newState);
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      set(this.args.flowObj, 'data.enabled', !newState);
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }
}

import Component from '@glimmer/component';

/**
 * @param {object}              userDocuments             array of models
 * @param {object}              userDocumentTemplate      model
 */
export default class UserDocumentList extends Component {
  get sortedUserDocumentList() {
    return this.args?.userDocuments?.sortBy('sortableDate').reverse() || [];
  }
}

/* eslint-disable ember/no-computed-properties-in-native-classes */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { htmlSafe } from '@ember/template';
import { isArray } from '@ember/array';
import { isBlank } from '@ember/utils';
import { alias } from '@ember/object/computed';
import { action, computed, get, getProperties } from '@ember/object';

import { arrayToSentence } from 'garaje/helpers/array-to-sentence';
import falsyComputed from 'garaje/utils/falsy-computed';

export default class LabeledInput extends Component {
  type = 'text';
  size = 1;
  placeholder = '';

  validated = true;
  required = false;

  attributeBindings = Object.freeze(['disabled', 'data-test-error-description']);

  @alias('hasErrors') 'data-test-error-description';
  @falsyComputed('hasErrors') noErrors;

  @computed('validated', 'noErrors')
  get isValid() {
    if (get(this, 'validated')) {
      return get(this, 'noErrors');
    } else {
      return true;
    }
  }

  @computed('errorLabel', 'hasErrors', 'label')
  get labelForError() {
    const errorLabel = get(this, 'errorLabel');
    const label = get(this, 'label');
    const hasErrors = get(this, 'hasErrors');

    if (isBlank(errorLabel)) {
      if (typeof hasErrors === 'boolean') {
        return 'This field is invalid';
      } else if (isArray(hasErrors)) {
        return [label, arrayToSentence(hasErrors)].join(' ');
      } else if (typeof hasErrors === 'string') {
        return `${label} ${hasErrors}`;
      }
    }
    return htmlSafe(errorLabel);
  }

  @action
  labelClicked() {}

  @action
  _setAttrs(input) {
    const props = getProperties(
      this,
      'size',
      'data-stripe',
      'data-private',
      'maxlength',
      'autocomplete',
      'pattern',
      'tabindex',
      'spellcheck',
    );

    Object.keys(props).forEach(function (key) {
      if (typeof props[key] !== 'undefined' && props[key] !== null) {
        input.setAttribute(key, props[key]);
      }
    });
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { Changeset } from 'ember-changeset';
import { type DetailedChangeset } from 'ember-changeset/types';
import type ConfigModel from 'garaje/models/config';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';

export type SettingsWelcomeScreenHostSearchModel = DetailedChangeset<ConfigModel>;

export default class SettingsWelcomeScreenHostSearchRoute extends Route<SettingsWelcomeScreenHostSearchModel> {
  titleToken = 'Host Search';

  @service declare state: StateService;
  @service declare transitionConfirm: TransitionConfirmService;
  @service declare router: RouterService;
  @service declare featureFlags: FeatureFlagsService;

  beforeModel(): void {
    if (
      !this.featureFlags.isEnabled('kiosk-host-search-improvements') ||
      !this.state.vrSubscription?.canAccessHostSearchOptions
    ) {
      void this.router.replaceWith('visitors.settings.welcome-screen.design');
    }
  }

  async model(): Promise<SettingsWelcomeScreenHostSearchModel> {
    const config = await this.state.currentLocation.config;
    return Changeset(config);
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    const changeset = <SettingsWelcomeScreenHostSearchModel>this.modelFor(this.routeName);

    if (!changeset.isDirty) {
      return;
    }

    void this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        changeset.rollback();
      },
    });
  }
}

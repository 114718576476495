// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import Service, { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import {
  isAbortError,
  isBadRequestResponse,
  isConflictResponse,
  isForbiddenResponse,
  isGoneResponse,
  isInvalidResponse,
  isNotFoundResponse,
  isServerErrorResponse,
  isUnauthorizedResponse,
} from 'ember-fetch/errors';
import fetch from 'fetch';
import config from 'garaje/config/environment';
import type CookieAuthService from 'garaje/services/cookie-auth';
import type SessionService from 'garaje/services/session';
import param from 'jquery-param';

import {
  AbortError,
  BadRequestError,
  ConflictError,
  FetchError,
  ForbiddenError,
  GoneError,
  InvalidError,
  NotFoundError,
  ServerError,
  UnauthorizedError,
} from './ajax/errors';
import type { JsonError, ParsedResponse } from './ajax/json-helpers';
import { isJsonString, parseJSON } from './ajax/json-helpers';
import {
  endsWithSlash,
  haveSameHost,
  isFullURL,
  parseURL,
  removeLeadingSlash,
  removeTrailingSlash,
  startsWithSlash,
  stripSlashes,
} from './ajax/url-helpers';

interface BuiltOptions {
  url?: string;
  host?: string;
  type?: string;
  dataType?: string;
  contentType?: string | boolean;
  headers?: Record<string, string>;
  namespace?: string;
  method?: string;
  processData?: boolean;
  data?: string | object;
  signal?: AbortSignal;
  timeout?: number;
}

interface RequestOptions extends RequestInit {
  method: string;
  headers: Record<string, string>;
}

interface RawResponse {
  response: Response;
  requestOptions: RequestOptions;
  builtURL: string;
}

/**
 * migrated from https://github.com/expel-io/ember-ajax-fetch/blob/main/addon/mixins/fetch-request.js to not use EmberError or Ember polyfills
 */
export default class AjaxFetchService extends Service {
  @service declare cookieAuth: CookieAuthService;
  @service declare session: SessionService;

  @computed('session.{data.authenticated.access_token,isAuthenticated}')
  get headers(): Record<string, string> {
    const headers: Record<string, string> = { Accept: 'application/json; charset=utf-8' };

    if (this.session.isAuthenticated) {
      headers['Authorization'] = `Bearer ${<string>this.session.data.authenticated['access_token']}`;
    }

    return headers;
  }

  declare host: string;
  declare namespace: string;

  contentType = 'application/x-www-form-urlencoded; charset=UTF-8';
  trustedHosts = config.trustedHosts;

  async raw(url: string, options: BuiltOptions = {}): Promise<RawResponse> {
    const hash = this.options(url, options);
    const method = hash.method || hash.type || 'GET';
    let headers = hash.headers || {};
    headers = this.cookieAuth.decorate(method, { headers }).headers;

    const requestOptions: RequestOptions = {
      credentials: 'include',
      method,
      headers: {
        ...headers,
      },
    };

    const abortController = new AbortController();
    requestOptions.signal = abortController.signal;

    // If `contentType` is set to false, we want to not send anything and let the browser decide
    // We also want to ensure that no content-type was manually set on options.headers before overwriting it
    if (
      hash.contentType !== false &&
      typeof hash.contentType !== 'boolean' &&
      isEmpty(requestOptions.headers['Content-Type'])
    ) {
      requestOptions.headers['Content-Type'] = hash.contentType!;
    }

    let builtURL = hash.url!;
    if (hash.data) {
      let { data } = hash;

      if (options.processData === false) {
        // @ts-ignore
        requestOptions.body = data;
      } else {
        if (typeof data === 'string' && isJsonString(data)) {
          data = <Record<string, unknown>>JSON.parse(data);
        }

        if (requestOptions.method === 'GET') {
          const { search } = parseURL(builtURL);
          builtURL = `${builtURL}${isEmpty(search) ? '?' : '&'}${param(data)}`;
        } else {
          requestOptions.body = JSON.stringify(data);
        }
      }
    }

    // eslint-disable-next-line no-useless-catch
    try {
      if (options.signal) {
        options.signal.addEventListener('abort', () => abortController.abort());
      }
      let timeout;
      if (options.timeout) {
        timeout = setTimeout(() => abortController.abort(), options.timeout);
      }
      const response = await fetch(builtURL, requestOptions);
      if (timeout) {
        clearTimeout(timeout);
      }

      return { response, requestOptions, builtURL };
    } catch (error) {
      throw error;
    }
  }

  async request<T>(url: string, options: BuiltOptions = {}): Promise<T> {
    const { response, requestOptions, builtURL } = await this.raw(url, options);
    const parsedResponse = await parseJSON(response);

    return this.#handleResponse(parsedResponse, requestOptions, builtURL);
  }

  #shouldSendHeaders({ url, host }: { url?: string; host?: string }): boolean {
    url = url || '';
    host = host || this.host || '';

    const trustedHosts = this.trustedHosts || [];
    const { hostname } = parseURL(url);

    // Add headers on relative URLs
    if (!isFullURL(url)) {
      return true;
    } else if (trustedHosts.find((matcher) => this.#matchHosts(hostname, matcher))) {
      return true;
    }

    // Add headers on matching host
    return haveSameHost(url, host);
  }

  #generateDetailedMessage(status: number, payload: string, contentType: string, type: string, url: string): string {
    let shortenedPayload;
    const payloadContentType = contentType || 'Empty Content-Type';

    if (payloadContentType.toLowerCase() === 'text/html' && payload.length > 250) {
      shortenedPayload = '[Omitted Lengthy HTML]';
    } else {
      shortenedPayload = JSON.stringify(payload);
    }

    const requestDescription = `${type} ${url}`;
    const payloadDescription = `Payload (${payloadContentType})`;

    return [
      `Ember Ajax Fetch Request ${requestDescription} returned a ${status}`,
      payloadDescription,
      shortenedPayload,
    ].join('\n');
  }

  options(url: string, options: BuiltOptions = {}): BuiltOptions {
    options = Object.assign({}, options);
    options.url = this.#buildURL(url, options);
    options.type = options.type || 'GET';
    options.dataType = options.dataType || 'json';
    options.contentType = isEmpty(options.contentType) ? this.contentType : options.contentType;

    if (this.#shouldSendHeaders(options)) {
      options.headers = this.#getFullHeadersHash(options.headers);
    } else {
      options.headers = options.headers || {};
    }

    return options;
  }

  #buildURL(url: string, options: BuiltOptions = {}) {
    if (isFullURL(url)) {
      return url;
    }

    const urlParts = [];

    let host = options.host || this.host;
    if (host) {
      host = endsWithSlash(host) ? removeTrailingSlash(host) : host;
      urlParts.push(host);
    }

    let namespace = options.namespace || this.namespace;
    if (namespace) {
      // If host is given then we need to strip leading slash too( as it will be added through join)
      if (host) {
        namespace = stripSlashes(namespace);
      } else if (endsWithSlash(namespace)) {
        namespace = removeTrailingSlash(namespace);
      }

      const hasNamespaceRegex = new RegExp(`^(/)?${stripSlashes(namespace)}/`);
      if (!hasNamespaceRegex.test(url)) {
        urlParts.push(namespace);
      }
    }

    // *Only* remove a leading slash -- we need to maintain a trailing slash for
    // APIs that differentiate between it being and not being present
    if (startsWithSlash(url) && urlParts.length !== 0) {
      url = removeLeadingSlash(url);
    }
    urlParts.push(url);

    return urlParts.join('/');
  }

  #createCorrectError(response: Response, payload: string, requestOptions: RequestOptions, url: string) {
    let error;

    // @ts-ignore
    payload = this.#normalizeErrorResponse(response.status, payload);

    if (isUnauthorizedResponse(response)) {
      error = new UnauthorizedError(payload);
    } else if (isForbiddenResponse(response)) {
      error = new ForbiddenError(payload);
    } else if (isInvalidResponse(response)) {
      error = new InvalidError(payload);
    } else if (isBadRequestResponse(response)) {
      error = new BadRequestError(payload);
    } else if (isNotFoundResponse(response)) {
      error = new NotFoundError(payload);
    } else if (isGoneResponse(response)) {
      error = new GoneError(payload);
      // @ts-ignore
    } else if (isAbortError(response)) {
      error = new AbortError();
    } else if (isConflictResponse(response)) {
      error = new ConflictError(payload);
    } else if (isServerErrorResponse(response)) {
      error = new ServerError(payload, response.status);
    } else {
      const detailedMessage = this.#generateDetailedMessage(
        response.status,
        payload,
        requestOptions.headers['Content-Type']!,
        requestOptions.method,
        url,
      );

      error = new FetchError(payload, detailedMessage, response.status);
    }

    return error;
  }

  post<T>(url: string, options?: BuiltOptions): Promise<T> {
    return this.request(url, this.#addTypeToOptionsFor(options, 'POST'));
  }

  put<T>(url: string, options?: BuiltOptions): Promise<T> {
    return this.request(url, this.#addTypeToOptionsFor(options, 'PUT'));
  }

  patch<T>(url: string, options?: BuiltOptions): Promise<T> {
    return this.request(url, this.#addTypeToOptionsFor(options, 'PATCH'));
  }

  del<T>(url: string, options?: BuiltOptions): Promise<T> {
    return this.request(url, this.#addTypeToOptionsFor(options, 'DELETE'));
  }

  delete<T>(url: string, options?: BuiltOptions): Promise<T> {
    return this.del(url, options);
  }

  #addTypeToOptionsFor(options: BuiltOptions = {}, method: string) {
    options.type = method;
    return options;
  }

  #getFullHeadersHash(headers: Record<string, string> = {}): Record<string, string> {
    const classHeaders = this.headers;
    return Object.assign({}, classHeaders, headers);
  }

  #handleResponse<T>(response: JsonError | ParsedResponse, requestOptions: RequestOptions, url: string): T {
    if ('ok' in response) {
      return <T>response.json;
    } else {
      throw this.#createCorrectError(response.response, <string>response.payload, requestOptions, url);
    }
  }

  #matchHosts(host: string, matcher: string | RegExp): boolean {
    if (typeof host !== 'string') {
      return false;
    }

    if (matcher instanceof RegExp) {
      return matcher.test(host);
    } else if (typeof matcher === 'string') {
      return matcher === host;
    } else {
      // eslint-disable-next-line no-console
      console.warn('trustedHosts only handles strings or regexes. ', matcher, ' is neither.');
      return false;
    }
  }

  #normalizeErrorResponse(status: number, payload: string | Record<string, unknown>): object {
    if (payload && typeof payload === 'object') {
      // TODO-FIXME-API
      // @ts-ignore
      if (!payload['error'] && payload['meta'] && payload['meta'].message) {
        // @ts-ignore
        payload['errors'] = [payload['meta'].message];
      }

      return payload['errors'] || payload['error'] || payload;
    } else {
      return [
        {
          status: `${status}`,
          title: 'The backend responded with an error',
          detail: `${payload}`,
        },
      ];
    }
  }
}

import Component from '@glimmer/component';
import { not } from 'macro-decorators';
import { action } from '@ember/object';

/**
 * @param {Boolean}                     disabled
 * @param {Boolean}                     hideFromHostList
 * @param {Function}                    setHideFromHostList
 */
export default class ShowAsHost extends Component {
  @not('args.hideFromHostList') showOnHostList;

  @action
  setShowOnHostList(value) {
    this.args.setHideFromHostList(!value);
  }
}

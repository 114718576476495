import { EventEmitter } from './EventEmitter';
import { getFetch } from '../platform/getFetch/browser';
import { getEventSource } from '../platform/getEventSource/browser';
import { BrowserSignalListener } from '@splitsoftware/splitio-commons/esm/listeners/browser';
export var platform = {
    getFetch: getFetch,
    getEventSource: getEventSource,
    EventEmitter: EventEmitter
};
export var SignalListener = BrowserSignalListener;

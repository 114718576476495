import Controller from '@ember/controller';
import { action } from '@ember/object';
import type ActionHandler from '@ember/object/-private/action-handler';
import { service } from '@ember/service';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type { FlashMessage } from 'garaje/services/flash-messages';

export default class ApplicationController extends Controller {
  @service declare flashMessages: FlashMessagesService;

  queryParams = ['revision'];
  revision = null;

  declare target: { send: ActionHandler['send'] };

  /*
    Used as a closure action in the template. Other parts of the app use
    bubbling, so forward the action handling up to the route (target).
  */
  @action
  hideFlash(flash: FlashMessage): void {
    this.target.send('hideFlash', flash);
  }
}

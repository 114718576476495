import { isEmpty } from '@ember/utils';
import type { ValidatorMap } from 'ember-changeset-validations';
import { validatePresence, validateLength } from 'ember-changeset-validations/validators';

function checkNumber(value?: number) {
  if (isNaN(value!)) return 'Amount must be a number';
  if (value! < 0) return 'Amount cannot be negative';

  return true;
}

export default function buildTenantValidations(): ValidatorMap {
  const validations: ValidatorMap = {
    name: validatePresence({ presence: true, ignoreBlank: true, message: 'This is a required field' }),
    suites: validateLength({ min: 1, message: 'This is a required field' }),
    annualizedRent(_key: string, newValue: number | undefined) {
      if (isEmpty(newValue)) return true;
      return checkNumber(newValue);
    },
  };

  validations['expectedEmployeesCount'] = (_key: string, newValue: number | undefined) => {
    if (isEmpty(newValue)) return 'This is a required field';
    return checkNumber(newValue);
  };

  return validations;
}

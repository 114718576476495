import { isPresent } from '@ember/utils';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import type DeviceModel from 'garaje/models/device';
import type LocationModel from 'garaje/models/location';

class VisitorEntranceModel extends Model {
  @belongsTo('location') declare location: AsyncBelongsTo<LocationModel>;
  @hasMany('device') declare devices: AsyncHasMany<DeviceModel>;

  @attr('string') declare address: string;
  @attr('string') declare addressLineOne: string;
  @attr('string') declare addressLineTwo: string;

  @attr('boolean') declare active: boolean;
  @attr('string') declare name: string;
  @attr('boolean') declare useLocationAddress: boolean;

  get currentAddress(): string {
    if (this.useLocationAddress) {
      return this.address;
    }
    if (isPresent(this.addressLineTwo)) {
      return `${this.addressLineOne} ${this.addressLineTwo}`;
    }
    return this.addressLineOne;
  }
}

export default VisitorEntranceModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'visitor-entrance': VisitorEntranceModel;
  }
}

import { modifier } from 'ember-modifier';

export default modifier((element: HTMLElement, [action]: [(event: KeyboardEvent) => void]) => {
  const keydownHandler = (event: KeyboardEvent): void => {
    if (event.key !== 'Backspace') return;
    action(event);
  };

  element.addEventListener('keydown', keydownHandler);
  return () => {
    element.removeEventListener('keydown', keydownHandler);
  };
});

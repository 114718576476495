import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { HostSearchDisplayOptions, HostSearchType } from 'garaje/models/config';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

import type { SettingsWelcomeScreenHostSearchModel } from '../route';

interface HostSearchSettingsPanelArgs {
  changeset: SettingsWelcomeScreenHostSearchModel;
}

export default class HostSearchSettingsPanel extends Component<HostSearchSettingsPanelArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;
  @service declare state: StateService;

  @tracked isOpen = false;
  @tracked showUnsavedEditsWarning = false;

  get isHostNameSelected(): boolean {
    return (
      this.args.changeset.hostSearchTypes.includes(HostSearchType.Name) &&
      this.args.changeset.hostSearchTypes.length === 1
    );
  }

  get isHostEmailSelected(): boolean {
    return (
      this.args.changeset.hostSearchTypes.includes(HostSearchType.Email) &&
      this.args.changeset.hostSearchTypes.length === 1
    );
  }

  get isHostNameAndEmailSelected(): boolean {
    return (
      this.args.changeset.hostSearchTypes.includes(HostSearchType.Name) &&
      this.args.changeset.hostSearchTypes.includes(HostSearchType.Email)
    );
  }

  get shouldDisplayTitleForHostSearch(): boolean {
    return this.args.changeset.hostSearchDisplayOptions.includes(HostSearchDisplayOptions.Title);
  }

  @action
  openPanel(): void {
    this.isOpen = true;
  }

  @action
  closePanel(): void {
    if (this.args.changeset.isDirty) {
      this.showUnsavedEditsWarning = true;
      return;
    }

    this.isOpen = false;
  }

  @action
  continue(): void {
    if (this.args.changeset.isDirty) {
      this.args.changeset.rollback();
    }

    this.showUnsavedEditsWarning = false;
    this.isOpen = false;
  }

  @action
  setHostSearchType(value: HostSearchType[]): void {
    this.args.changeset.hostSearchTypes = value;
  }

  @action
  toggleHostSearchAutoSuggest(): void {
    this.args.changeset.hostSearchAutoSuggestEnabled = !this.args.changeset.hostSearchAutoSuggestEnabled;
  }

  @action
  toggleTitleInHostSearchDisplayOptions(): void {
    if (this.shouldDisplayTitleForHostSearch) {
      this.args.changeset.hostSearchDisplayOptions = this.args.changeset.hostSearchDisplayOptions.filter(
        (option) => option !== HostSearchDisplayOptions.Title,
      );
      return;
    }

    this.args.changeset.hostSearchDisplayOptions = [
      ...this.args.changeset.hostSearchDisplayOptions,
      HostSearchDisplayOptions.Title,
    ];
  }

  save = task({ drop: true }, async () => {
    try {
      await this.args.changeset.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.metrics.trackEvent('visitors.kiosk-host-search-improvements', {
        company_id: this.state.currentCompany.id,
        company_name: this.state.currentCompany.name,
        location_id: this.state.currentLocation.id,
        hostSearchTypes: this.args.changeset.hostSearchTypes,
        hostSearchAutoSuggestEnabled: this.args.changeset.hostSearchAutoSuggestEnabled,
        hostSearchDisplayOptions: this.args.changeset.hostSearchDisplayOptions,
      });
    } catch (error: unknown) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));
    }
  });
}

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { later } from '@ember/runloop';
import { format } from 'date-fns';

import zft from 'garaje/utils/zero-for-tests';

const KEY_EXCEEDED = 'deliveries_warning_100';
const KEY_REACHED_90 = 'deliveries_warning_90';
const KEY_REACHED_80 = 'deliveries_warning_80';

/**
 * @param {Boolean}           limitExceeded
 * @param {Number}            monthlyLimit
 * @param {Date}              resumeAt
 * @param {Number}            monthlyLimitUsage
 */
export default class LimitWarningComponent extends Component {
  @service flashMessages;
  @service localStorage;
  @service metrics;

  type = 'warning';

  currentMonthAndYear() {
    return format(new Date(), 'MM-yyyy');
  }

  get message() {
    if (this.args.limitExceeded) {
      return `You’ve hit your ${this.args.monthlyLimit} package limit`;
    } else {
      return `You’ve scanned ${this.args.monthlyLimitUsage} packages this month`;
    }
  }

  get details() {
    if (this.args.limitExceeded) {
      return `Your package scanning is now paused. Upgrade to Deliveries Premium to continue scanning.`;
    } else {
      return `To scan more than ${this.args.monthlyLimit} packages a month, you need to upgrade to Deliveries Premium.`;
    }
  }

  get _key() {
    if (this.args.limitExceeded) {
      return KEY_EXCEEDED;
    } else if (this.args.monthlyLimitUsage >= 90) {
      return KEY_REACHED_90;
    } else if (this.args.monthlyLimitUsage >= 80) {
      return KEY_REACHED_80;
    } else {
      return false;
    }
  }

  get hasResumeDate() {
    return isPresent(this.args.resumeAt);
  }

  constructor() {
    super(...arguments);
    this._onInsert();
  }

  @action
  _onInsert() {
    if (!this._key || this.wasPreviouslyNotified()) {
      return;
    }

    this.flashMessages.hideAll();

    later(() => {
      this.flashMessages.showFlash(this.type, this.message, this.details, true);
      this.setAsPreviouslyNotified();
      this.metrics.trackEvent('Viewed flash message', {
        product: 'deliveries',
        delivery_threshold: this.args.monthlyLimitUsage,
        type: this.type,
        message_title: this.message,
        message_code: this._key,
        message_code_type: 'deliveries_warning',
      });
    }, zft(600));
  }

  wasPreviouslyNotified() {
    return this._key && this.localStorage.getItem(this._key) === this.currentMonthAndYear();
  }

  setAsPreviouslyNotified() {
    if (this._key) {
      this.localStorage.setItem(this._key, this.currentMonthAndYear());
    }
  }
}

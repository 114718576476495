//this is a basic function that does not account for commas in the data
function csvFromArrayOfObjects(data, headerDisplayValues = {}) {
  const keys = Object.keys(data[0]);
  const displayHeaders = keys.map((key) => headerDisplayValues[key] || key);
  const result = [displayHeaders.join(',')];

  data.forEach((row) => {
    result.push(keys.map((k) => row[k]).join(','));
  });

  const resultString = result.join('\n');
  return new Blob([resultString], { type: 'text/csv;charset=utf-8;' });
}

export default csvFromArrayOfObjects;

/* eslint-disable no-console */
import { assert, debug } from '@ember/debug';
import Service from '@ember/service';
import bugsnag from 'garaje/utils/bugsnag';
import { match } from 'macro-decorators';

export default class LoggerService extends Service {
  bugsnag: typeof bugsnag;
  originalPageURL?: string;

  constructor(properties: Record<string, unknown>) {
    super(properties);
    this.bugsnag = bugsnag;
    this.originalPageURL = window.envoy?.originalPageURL;
  }

  @match('originalPageURL', /(:)([a-zA-Z0-9]{6})/g) isPreviewSHA!: boolean; // match production url against the dashboard.envoy.com:123ABC preview SHA format

  info(error: string): void {
    debug(error);
  }

  warn(error: string | Error): void {
    console.warn(error);

    this._bugsnagNotify(error, 'warning');
  }

  error(error: string | Error): void {
    console.error(error);

    this._bugsnagNotify(error, 'error');
  }

  assert(message: string, condition: string): void {
    assert(message, condition);
  }

  _bugsnagNotify(message: string | Error, level = 'error'): void {
    if (this.isPreviewSHA) return; // don't notify bugsnag when the app is deployed as a preview SHA

    assert(
      `Due to Bugsnag's notify API, you must pass either a string or an Error object when calling \`logger.${level}\`.`,
      typeof message === 'string' || message instanceof Error,
    );

    this.bugsnag.notify(message, {
      severity: level,
    });
  }
}

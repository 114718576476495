/**
 * Supports routing for global overview admins and location level admins
 *
 * @param {string}   currentRouteName             the current route given through the router
 *
 * @returns {string}  path to route back to
 */
export function adminRoute(currentRouteName) {
  return currentRouteName?.includes('location-overview.manage.admin-users')
    ? 'location-overview.manage.admin-users'
    : 'manage.admin-users';
}

/**
 * Supports routing for global overview roles and location level roles
 *
 * @param {string}   currentRouteName             the current route given through the router
 * @param {string}   pathToRouteTo                portion of the route to route back to
 *
 * @returns {string}  path to route back to
 */
export function adminRolesRoute(currentRouteName, pathToRouteTo) {
  let path = pathToRouteTo;
  if (currentRouteName?.includes('location-overview')) {
    path = `location-overview.${pathToRouteTo}`;
  }

  return path;
}

import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { belongsTo, attr } from '@ember-data/model';
import type CompanyModel from 'garaje/models/company';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('integration')
class SlackIntegrationModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @attr('string') declare team: string;
  @attr('string') declare teamUid: string;
  @attr('string') declare botAccessToken: string;
  @attr('string') declare botUserId: string;
  @attr('string') declare token: string;
}

export default SlackIntegrationModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'slack-integration': SlackIntegrationModel;
  }
}

import Component from '@glimmer/component';

interface WorkplacePlanPriceInfoBoxArgs {
  title: string;
  subtitle: string;
}

export default class WorkplacePlanPriceInfoComponent extends Component<WorkplacePlanPriceInfoBoxArgs> {
  constructor(owner: WorkplacePlanPriceInfoComponent, args: WorkplacePlanPriceInfoBoxArgs) {
    super(owner, args);
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';

import type { PropertyDevicesRouteModel } from '../../route';

export default class PropertyDevicesIpadsIndexRoute extends Route {
  @service declare router: RouterService;

  model(): PropertyDevicesRouteModel {
    return <PropertyDevicesRouteModel>this.modelFor('property.devices');
  }

  redirect(model: PropertyDevicesRouteModel): void {
    if (model.ipads.length === 0) {
      void this.router.transitionTo('property.devices.ipads.new');
    }
  }
}

import { service } from '@ember/service';
import { Ability } from 'ember-can';
import { isProductDisabled } from 'garaje/helpers/product-disabled';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';

export default class RoombaAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;

  get canVisit(): boolean {
    // cannot visit if user has no workplace sub or legacy rooms sub
    if (isProductDisabled('rooms') || !this.state.features?.canAccessRoomsApplication) {
      return false;
    }

    const { roleNames: roles } = this.currentAdmin;
    return [GLOBAL_ADMIN, LOCATION_ADMIN].some((role) => roles.includes(role));
  }
}

import ApplicationSerializer from './application';

export default class VisitorEntranceSerializer extends ApplicationSerializer {
  attrs = {
    addressLineOne: 'address-line-1',
    addressLineTwo: 'address-line-2',
    address: {
      serialize: false,
    },
  };
}

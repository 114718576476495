/* eslint-disable ember/no-get */
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type LocationModel from 'garaje/models/location';
import type ScimPrimaryLocationMappingModel from 'garaje/models/scim-primary-location-mapping';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

interface SelectedLocation {
  name: string;
  id: string;
  value: LocationModel | null;
}

interface PrimaryLocationMappingsRowArgs {
  locations: Array<LocationModel>;
  primaryLocationMapping: ScimPrimaryLocationMappingModel;
}

export default class PrimaryLocationMappingsRow extends Component<PrimaryLocationMappingsRowArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare store: StoreService;

  @tracked isEditing: boolean = Boolean(this.args.primaryLocationMapping.isNew);
  @tracked selectedLocation: SelectedLocation | undefined = undefined;
  noPrimaryLocationId: string = '-1';
  tooltipId: string = `scim-primary-location-unmapped-row-tooltip-${this.args.primaryLocationMapping.scimAttributeValue}`;

  constructor(owner: unknown, args: PrimaryLocationMappingsRowArgs) {
    super(owner, args);
    this.selectedLocation = this.initialSelectedLocation;
  }

  get initialSelectedLocation(): SelectedLocation | undefined {
    const mapping = this.args.primaryLocationMapping;
    const location = mapping.location;

    if (<boolean>(<unknown>mapping.isNew)) {
      return undefined;
    } else if (location === null) {
      return { name: 'No primary location', id: this.noPrimaryLocationId, value: null };
    }

    return {
      name: location.name,
      id: location.id,
      value: location,
    };
  }

  get locations(): Array<SelectedLocation> {
    const locations: Array<SelectedLocation> = [];
    if (this.args.locations) {
      locations.push({ name: 'No primary location', id: this.noPrimaryLocationId, value: null });
      this.args.locations.forEach((loc) => {
        locations.push({
          name: get(loc, 'name'),
          id: get(loc, 'id'),
          value: <LocationModel>get(loc, 'content'),
        });
      });
    }

    return locations;
  }

  saveMapping = task({ drop: true }, async () => {
    try {
      const { primaryLocationMapping } = this.args;
      if (this.selectedLocation && primaryLocationMapping) {
        primaryLocationMapping.location = this.selectedLocation.value;

        await primaryLocationMapping.save();

        this.isEditing = false;
        this.flashMessages.showAndHideFlash('success', 'Primary location mapping saved');
      }
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showFlash('error', errorText);
    }
  });

  @action
  handleCancel(): void {
    this.selectedLocation = this.initialSelectedLocation;
    this.isEditing = false;
  }
}

import JSONAPISerializer from 'ember-data/serializers/json-api';

export default class DeliveriesSerializer extends JSONAPISerializer {
  /**
   * Maps an incoming json payload type to the correct model. You can confirm this by
   * inspect the Ember dev tools Data tab to see which model got created.
   *
   * @param {String} key the type field
   * @returns {String} model name to map to
   */
  modelNameFromPayloadKey(key) {
    if (key === 'devices') {
      return 'delivery-device';
    }
    return super.modelNameFromPayloadKey(...arguments);
  }

  /**
   * Maps an outgoing model name to what the backend expects
   *
   * @param {String} key the model name
   * @returns {String} the value to serialize as the `type` field
   */
  payloadKeyFromModelName(key) {
    if (key === 'delivery-device') {
      return 'devices';
    }
    return super.payloadKeyFromModelName(...arguments);
  }
}

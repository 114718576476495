import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { startOfDay, endOfDay, subDays, subYears, startOfMonth, startOfYear } from 'date-fns';
import { modifyDateInTimeZone } from 'garaje/utils/date-fns-tz-utilities';

interface DateRangeWithCalendarArgs {
  showPresetSelector: boolean;
  selectedDateRangeWithDefault: string;
  startDateFormat: string;
  endDateFormat: string;
  didSelectDateRange: (startDate: Date, endDate: Date) => void;
  startDateWithDefault: Date;
  endDateWithDefault: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  timezone: string;
}

export default class DateRangeWithCalendarComponent extends Component<DateRangeWithCalendarArgs> {
  @tracked showCalendar = false;
  @tracked selectedDateRange = '';

  get selectedDateRangeWithDefault(): string {
    const { selectedDateRange } = this;

    if (selectedDateRange) return selectedDateRange;

    return 'Custom';
  }

  get dateRangeFilterOptions(): string[] {
    const { selectedDateRangeWithDefault } = this;
    const options = [
      'Today',
      'Yesterday',
      'Past 7 days',
      'Past 30 days',
      'Past 60 days',
      'Past 90 days',
      'Month-to-date',
      'Year-to-date',
      'Past year',
      'All time',
    ];
    if (!options.includes(selectedDateRangeWithDefault)) {
      options.push(selectedDateRangeWithDefault);
    }

    return options;
  }

  @action
  toggleCalendar(): void {
    this.showCalendar = !this.showCalendar;
  }

  @action
  onDateRangeSelect(option: string): void {
    const { didSelectDateRange, timezone } = this.args;
    let start = modifyDateInTimeZone(new Date(), timezone, startOfDay);
    let end = modifyDateInTimeZone(new Date(), timezone, endOfDay);

    switch (option) {
      case 'Yesterday':
        start = subDays(start, 1);
        end = subDays(end, 1);
        break;
      case 'Past 7 days':
        start = subDays(start, 7);
        break;
      case 'Past 30 days':
        start = subDays(start, 30);
        break;
      case 'Past 60 days':
        start = subDays(start, 60);
        break;
      case 'Past 90 days':
        start = subDays(start, 90);
        break;
      case 'Month-to-date':
        start = startOfMonth(start);
        break;
      case 'Year-to-date':
        start = startOfYear(start);
        break;
      case 'Past year':
        start = subYears(start, 1);
        break;
      case 'All time':
        start = startOfDay(new Date('2000-01-01'));
        break;
      case 'Custom':
        return;
    }

    this.selectedDateRange = option;
    this.args.setStartDate(start);
    this.args.setEndDate(end);
    didSelectDateRange(start, end);
  }

  @action
  didSelectDateRange(startDate: Date, endDate: Date): void {
    this.args.didSelectDateRange(startDate, endDate);
  }

  @action
  setStartDate(date: Date): void {
    this.args.setStartDate(date);
  }

  @action
  setEndDate(date: Date): void {
    this.args.setEndDate(date);
  }

  @action
  onShowCalendar(): void {
    this.showCalendar = true;
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { enqueueTask, restartableTask, timeout, dropTask } from 'ember-concurrency';
import checkType from 'garaje/utils/check-file-type';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { EMPLOYEE_COUNT } from 'garaje/utils/enums';
import _cloneDeep from 'lodash/cloneDeep';

import zft from 'garaje/utils/zero-for-tests';

export default class EmployeeDeletionModal extends Component {
  @service store;
  @service state;
  @service employeeUpload;
  @service flashMessages;
  @service featureFlags;

  @tracked deleteAllChecked = false;
  @tracked employees = [];
  @tracked originalImport = null;
  @tracked originalFile = null;
  @tracked rawEmployees = [];
  @tracked name = '';
  @tracked totalEmployees = null;
  @tracked filteredEmployeesCount = null;
  @tracked page = 1;
  @tracked limit = 20;
  @tracked anyEmployeeChecked = false;

  constructor() {
    super(...arguments);
    this.getEmployees.perform();
  }

  get employeesOverLimit() {
    return this.isOverEmployeeLimit ? Math.round(this.totalEmployees - EMPLOYEE_COUNT.EMPLOYEE_LIMIT) : null;
  }

  get isOverEmployeeLimit() {
    return this.totalEmployees > EMPLOYEE_COUNT.EMPLOYEE_LIMIT;
  }

  get hasMorePages() {
    return this.page * this.limit < this.totalEmployees;
  }

  get nameEmpty() {
    return this.name === '';
  }

  @action
  changeDeleteAll() {
    this.deleteAllChecked = !this.deleteAllChecked;
    this.employees.forEach((emp) => {
      set(emp, 'checked', this.deleteAllChecked);
    });
    if (this.originalImport) {
      this.originalImport.forEach((emp) => {
        set(emp, 'checked', this.deleteAllChecked);
      });
    }
    this.anyEmployeeChecked = this.deleteAllChecked;
  }

  @action
  deleteAll() {
    if (!this.originalImport) {
      const employeesToDelete = this.employees.filter((row) => row.checked).map((row) => row.employee);
      this.args.close(employeesToDelete);
    } else {
      this.args.close(this.originalImport, true);
    }
  }

  @action
  deleteSingleEmployee(employee, index) {
    if (!this.originalImport) {
      this.args.close([employee]);
    } else {
      set(this.originalImport[index], 'checked', true);
      this.args.close(this.originalImport, true);
    }
  }

  @action
  checkEmployee(index) {
    set(this.employees[index], 'checked', !this.employees[index].checked);
    if (this.originalImport) {
      set(this.originalImport[index], 'checked', !this.originalImport[index].checked);
    }
    this.anyEmployeeChecked = this.employees.some((emp) => emp.checked === true);
  }

  @action
  closeModal() {
    this.args.close(null);
  }

  @restartableTask
  *filterByName(name) {
    if (!this.originalImport) {
      yield timeout(zft(500));

      this.name = name;
      this.page = 1;

      yield this.getEmployees.perform();
    } else {
      const searchableArray = [...this.originalImport];
      if (name.length > 0) {
        const searchedArray = searchableArray.filter((emp) =>
          emp.employee.name.toLowerCase().includes(name.toLowerCase()),
        );
        this.employees = searchedArray;
      } else {
        this.employees = searchableArray;
      }
    }
  }

  @dropTask
  *loadNextPageTask() {
    if (this.hasMorePages) {
      try {
        this.page++;

        yield this.getEmployees.perform();
      } catch (e) {
        this.page--;
      }
    }
  }

  @enqueueTask
  *getEmployees() {
    if (!this.args.employeesToImport) {
      const employees = yield this.store.query('employee', this.buildQuery());
      // Convert both query results to arrays before combining them because Ember Data query results
      // are intended to be immutable. In this case, since we are essentially invalidating the original query
      // by loading more results, we don't gain anything from the "live array" nature of a RecordArray, so
      // a raw array is preferable.
      const employeesArray = employees.toArray();
      const existingModelArray = this.rawEmployees.toArray();

      if (existingModelArray && this.page > 1) {
        this.rawEmployees = existingModelArray.pushObjects(employeesArray).uniqBy('id');
      } else {
        this.rawEmployees = employeesArray;
      }

      if (!this.totalEmployees) {
        this.totalEmployees = employees.meta.total;
      }

      this.filteredEmployeesCount = employees.meta.total;

      this.employees = this.rawEmployees.map((employee) => ({ employee, checked: this.deleteAllChecked }));
    } else {
      this.employees = this.args.employeesToImport.map((employee) => ({ employee, checked: this.deleteAllChecked }));
      this.originalImport = _cloneDeep(this.employees);
      this.totalEmployees = this.employees.length;
      this.filteredEmployeesCount = this.employees.length;
    }
  }

  buildQuery() {
    const limit = this.limit;
    const offset = (this.page - 1) * limit;
    const params = {
      include: 'assistants',
      sort: 'name',
      page: { limit, offset },
      filter: { deleted: false, locations: this.args.locationId },
    };

    if (isPresent(this.name)) {
      params.filter['query'] = this.name;
    }

    return params;
  }

  @action
  async uploadCsvToServer() {
    try {
      await this.employeeUpload.uploadEmployeesCSV(this.originalFile);

      this.parsedObjects = null;

      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
    this.args.close();
  }

  @action
  toggleProcessing() {
    this.isProcessing = !this.isProcessing;
  }

  @action
  handleCsvResults(contents, originalFile) {
    this.originalFile = originalFile;
    const checkResult = checkType(originalFile, ['csv']);

    if (checkResult.isValid) {
      this.parsedObjects = contents.data?.map(([name, email, phone, assistant]) => ({
        name,
        email,
        phone,
        assistant,
      }));
      if (this.parsedObjects.length > EMPLOYEE_COUNT.EMPLOYEE_LIMIT) {
        this.employees = this.parsedObjects.map((employee) => ({ employee, checked: this.deleteAllChecked }));
        this.originalImport = _cloneDeep(this.employees);
        this.totalEmployees = this.employees.length;
        this.filteredEmployeesCount = this.employees.length;
      } else {
        this.uploadCsvToServer();
      }
    } else {
      const text = `Only files with extensions .csv are allowed. You uploaded a file with extension ${checkResult.extension}.`;
      this.flashMessages.showFlash('error', text);
    }
  }
}

import ApplicationSerializer from './application';

export default class EmployeeSummaryPageSerializer extends ApplicationSerializer {
  attrs = {
    defaultMessage: {
      serialize: false,
    },
  };

  modelNameFromPayloadKey(key) {
    if (key === 'flows') {
      return 'employee-screening-flow';
    } else if (key === 'summary-pages') {
      return 'employee-summary-page';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }

  payloadKeyFromModelName(modelName) {
    if (modelName === 'employee-screening-flow') {
      return 'flows';
    } else if (modelName === 'employee-summary-page') {
      return 'summary-pages';
    } else {
      return super.payloadKeyFromModelName(...arguments);
    }
  }
}

import { TextField } from '@envoy/polarwind-react';
import { FieldValues, Path, PathValue, useController } from 'react-hook-form';

export interface FormTextFieldProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  required?: string;
  disabled?: boolean;
  maxLength?: { value: number; message: string };
  placeholder?: string;
  multiline?: number;
  testId?: string;
  tooltip?: string;
}

export function FormTextField<T extends FieldValues>(props: FormTextFieldProps<T>) {
  const {
    field,
    fieldState: { error },
  } = useController({ name: props.name, rules: { required: props.required, maxLength: props.maxLength } });

  return (
    <TextField
      label={props.label}
      required={!!props.required}
      disabled={props.disabled}
      multiline={props.multiline}
      placeholder={props.placeholder}
      value={field.value || ''}
      onBlur={() => field.onBlur()}
      onChange={(value: PathValue<T, Path<T>>) => field.onChange(value)}
      error={error?.message}
      testId={props.testId}
      tooltip={props.tooltip}
    />
  );
}

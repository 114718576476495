import Transform from '@ember-data/serializer/transform';
import { isNone } from '@ember/utils';

export default class ImmutableTransform extends Transform {
  deserialize(serialized) {
    return isNone(serialized) ? null : Object.freeze(serialized);
  }

  serialize(deserialized) {
    return isNone(deserialized) ? null : deserialized;
  }
}

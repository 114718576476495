import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type AnnouncementTemplateCategoryModel from 'garaje/models/announcement-template-category';

export default class CommunicationsSettingsRoute extends Route {
  @service declare store: Store;
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  beforeModel(): void {
    if (this.abilities.cannot('manage-settings global-communications')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  model(): ArrayProxy<AnnouncementTemplateCategoryModel> {
    return this.store.findAll('announcement-template-category');
  }
}

import Model, { attr, belongsTo } from '@ember-data/model';
import type LocationModel from 'garaje/models/location';

class AverageMonthlyVisitorCountModel extends Model {
  @belongsTo('location') declare location: LocationModel;
  @attr('number') declare count: number;
  @attr() declare locationId: string;
}

export default AverageMonthlyVisitorCountModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'average-monthly-visitor-count': AverageMonthlyVisitorCountModel;
  }
}

/* eslint-disable ember/no-component-lifecycle-hooks */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { get, getProperties, set } from '@ember/object';
import config from 'garaje/config/environment';
import { buildWaiter } from '@ember/test-waiters';
import _isEmpty from 'lodash/isEmpty'; // Use lodash version as isBlank from utils doesn't check object emptiness
import Handsontable from 'handsontable';
import $ from 'jquery';

const testWaiter = buildWaiter('handson-table-waiter');

const testing = config.environment === 'test';

const DEFAULT_OPTIONS = {
  manualColumnResize: true,
  minCols: 1,
  minRows: testing ? 7 : 50,
  minSpareRows: 1,
  rowHeaders: true,
  stretchH: 'all',
};
/**
 * @param {Object}                    handsontable
 * @param {Array<String>}             colHeaders
 * @param {Array}                     columns
 * @param {Function}                  registerInstance
 * @param {Array}                     data
 * @param {Object}                    options
 * @param {String}                    minRows
 * @param {String}                    minSpareRows
 * @param {Object}                    hooks
 */
// eslint-disable-next-line ember/no-classic-classes
export default Component.extend({
  init() {
    this._super(...arguments);
    if (_isEmpty(this.hooks)) {
      this.hooks = {};
    }
  },

  didInsertElement() {
    this._super(...arguments);
    this.installHot();
  },

  didUpdateAttrs() {
    this._super();
    const hot = this.hotInstance;

    if (hot) {
      const settings = getProperties(this, 'columns', 'colHeaders', 'data');
      hot.updateSettings(settings);

      // didUpdateAttrs may redraw aggressively; this could remove validation classes
      this.revalidateData();
    }
  },

  willDestroyElement() {
    this._super(...arguments);
    const hot = this.hotInstance;
    if (hot) {
      hot.destroy();
    }
  },

  revalidateData() {
    const hot = this.hotInstance;

    if (!hot) return;

    const data = hot.getData();

    data.forEach((_, i) => {
      if (hot.isEmptyRow(i)) return;

      hot.validateRows([i], () => {});
    });
  },

  installHot() {
    let token;
    if (testing) {
      token = testWaiter.beginAsync();
    }

    const elementId = this.elementId;
    const container = $(`#${elementId} #${elementId}-hot`).get(0); // eslint-disable-line ember/use-ember-get-and-set
    if (container) {
      const options = get(this, 'options');
      const props = getProperties(this, 'colHeaders', 'columns', 'data');
      const hotInstance = new Handsontable(container, Object.assign({}, DEFAULT_OPTIONS, props, options));

      set(this, 'hotInstance', hotInstance);

      // need this for page-object
      if (testing) {
        window.hotInstance = hotInstance;
      }

      const hooks = this.hooks;
      Object.keys(hooks).forEach((key) => {
        Handsontable.hooks.add(
          key,
          (...args) => {
            const fn = hooks[key];
            if (typeof fn === 'function') {
              return fn(...[hotInstance, ...args]);
            }
          },
          hotInstance,
        );
      });

      const registerInstance = this.registerInstance;
      if (registerInstance) {
        registerInstance(hotInstance);
      }

      if (testing) {
        testWaiter.endAsync(token);
      }
    }
  },
});

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type PrinterConnection from 'garaje/models/printer-connection';
import type ZoneModel from 'garaje/models/zone';
import type { RecordArray } from 'garaje/utils/type-utils';

import type { PropertyDevicesPrintersConnectionsRouteModel } from '../route';

export interface PropertyDevicesPrintersConnectionsShowRouteModel {
  printerConnection: PrinterConnection;
  printerConnections: RecordArray<PrinterConnection>;
  printers: PropertyDevicesPrintersConnectionsRouteModel['printers'];
  property: ZoneModel;
}

export default class PropertyDevicesPrintersConnectionsShowRoute extends Route {
  @service declare router: RouterService;
  @service declare store: Store;

  model({ id }: { id: string }): PropertyDevicesPrintersConnectionsShowRouteModel | undefined {
    const { printerConnections, printers, property } = <PropertyDevicesPrintersConnectionsRouteModel>(
      this.modelFor('property.devices.printers.connections')
    );

    const printerConnection = this.store.peekRecord('printer-connection', id);
    if (!printerConnection) {
      void this.router.transitionTo('property.devices.printers.connections.new');
      return;
    }

    return {
      printerConnection,
      printerConnections,
      printers,
      property,
    };
  }
}

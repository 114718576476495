import { action } from '@ember/object';
import { isBlank, isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { timeout, task } from 'ember-concurrency';
import type GroupModel from 'garaje/models/group';
import zft from 'garaje/utils/zero-for-tests';
import type HTMLElementEvent from 'utils/html-element-event';
import type Location from 'utils/location-record';

export interface GroupOption {
  isParent: true;
  name: string;
  data: {
    id: string;
    name: string;
    totalLocations: number;
    locations: Location[];
  };
}

export interface LocationOption {
  isParent: false;
  name: string;
  data: {
    id: string;
    name: string;
    group: unknown;
  };
}

export type MultiLocationOption = GroupOption | LocationOption;

/**
 * Multiple select on top of `BasicDropdown` for locations and groups.
 */
export interface MultiLocationsSelectorComponentSignature {
  Args: {
    selectedLocationIds?: string;
    locations?: Location[];
    groups?: GroupModel[];
    loading?: boolean;
    onSelected?: (locations: Location[]) => void;
  };

  Element: HTMLElement;
}

export default class MultiLocationsSelectorComponent extends Component<MultiLocationsSelectorComponentSignature> {
  @tracked options: MultiLocationOption[] = [];
  @tracked searchQuery = '';

  get selectedText(): string {
    const { selectedLocations, locations } = this;
    const totalSelected = selectedLocations.length;

    if (isBlank(selectedLocations) || totalSelected === locations.length) {
      return 'All locations';
    }

    if (totalSelected === 1) {
      return selectedLocations[0]?.name || '1 location';
    }

    return `${totalSelected} locations`;
  }

  get groups(): GroupModel[] {
    return this.args.groups || [];
  }

  get locations(): Location[] {
    return this.args.locations || [];
  }

  get selectedLocationIds(): string {
    return this.args.selectedLocationIds || '';
  }

  get selectedLocations(): Location[] {
    const ids = (this.selectedLocationIds || '').split(',');

    return this.locations.filter((location) => ids.includes(location.id));
  }

  get isSelectAllDisabled(): boolean {
    return this.selectedLocations.length === this.locations.length;
  }

  get isDeselectAllDisabled(): boolean {
    return this.selectedLocations.length === 0;
  }

  get filteredOptions(): MultiLocationOption[] {
    if (isBlank(this.searchQuery)) {
      return this.options;
    }

    return this.options.reduce(
      (filteredOpts: MultiLocationOption[], option: MultiLocationOption): MultiLocationOption[] => {
        // If the top level option matches the search query, render the option
        // along with all its nested locations.
        if (this.#matchesSearchQuery(option.name)) {
          filteredOpts.push(option);

          return filteredOpts;
        }

        // If the option doesn't directly match the search and it's a group,
        // check its nested locations for a match.
        if (option.isParent) {
          const { id, name, totalLocations, locations } = option.data;
          const filteredLocations = locations.filter((loc: Location) => this.#matchesSearchQuery(loc.name));

          if (filteredLocations.length) {
            const data = { id, name, totalLocations, locations: this.#sortOptions(filteredLocations) };

            filteredOpts.push({
              ...option,
              data,
            });
          }
        }

        return filteredOpts;
      },
      [],
    );
  }

  @action
  handleSearch(event: HTMLElementEvent<HTMLInputElement>): void {
    void this.setSearchQueryTask.perform(event.target.value);
  }

  /**
   * Handles the `group` selected action. If the `group` is in an `indeterminate` state, we only send the already
   * selected locations as we want to clear the group. Otherwise we send all locations for that group.
   *
   * @param optionGroup
   */
  @action
  onGroupSelected(optionGroup: GroupOption): void {
    const { locations } = optionGroup.data;
    const { selectedLocations } = this;
    const isSelected = locations.some((l) => selectedLocations.includes(l));

    this.args.onSelected?.(this.#locationsToUpdateSelectionState([optionGroup], isSelected));
  }

  @action
  selectAll(): void {
    this.args.onSelected?.(this.#locationsToUpdateSelectionState(this.options));
  }

  @action
  deselectAll(): void {
    this.args.onSelected?.(this.#locationsToUpdateSelectionState(this.options, true));
  }

  computeOptionsTask = task({ restartable: true }, async (): Promise<void> => {
    await timeout(zft(500));

    const options: MultiLocationOption[] = [];

    // All the locations that do not belong to a group
    const locationsWithoutGroup = this.locations.filter((location) => !location.group || isBlank(this.groups));

    locationsWithoutGroup.forEach((location) => options.push({ isParent: false, name: location.name, data: location }));

    if (isPresent(this.groups)) {
      // All the available groups with their states
      this.groups.forEach((group) => {
        const { id, name, totalLocations, locations } = group;

        if (totalLocations === 0) return;

        const data = { id, name, totalLocations, locations: this.#sortOptions(locations.toArray()) };

        options.push({ isParent: true, name, data });
      });
    }

    this.options = this.#sortOptions(options);
  });

  setSearchQueryTask = task({ restartable: true }, async (value: string): Promise<void> => {
    await timeout(zft(200));
    this.searchQuery = value;
  });

  /**
   * Helper that matches a string against this.searchQuery. Returns true if
   * there's a string overlap, false if not.
   *
   * @param str the string to match against this.searchQuery
   * @return whether there is a match or not
   */
  #matchesSearchQuery(str: string): boolean {
    return str.toLowerCase().includes(this.searchQuery.toLowerCase());
  }

  #sortOptions<T extends MultiLocationOption[] | Location[]>(options: T): T {
    return options.sort(({ name: n1 }, { name: n2 }) => {
      const n1l = n1.toLowerCase();
      const n2l = n2.toLowerCase();

      if (n1l === n2l) return 0;

      return n1l < n2l ? -1 : 1;
    }) as typeof options;
  }

  /**
   * Among the options provided, find either:
   * - locations included in the current selection
   * - locations excluded from the current selection
   * This allows select/deselect all to indicate a list of
   * locations to toggle selection state for.
   */
  #locationsToUpdateSelectionState(options: MultiLocationOption[], currentlySelected: boolean = false): Location[] {
    const { selectedLocationIds, locations } = this;
    const ids = (selectedLocationIds || '').split(',');
    const filterFn = (data: { id: string }): boolean => {
      const isSelected = ids.includes(data.id);

      return currentlySelected ? isSelected : !isSelected;
    };

    return options.reduce((locs: Location[], opt: MultiLocationOption): Location[] => {
      // Check each location in a group
      if (opt.isParent) {
        return [...locs, ...opt.data.locations.filter(filterFn)];
      }

      if (filterFn(opt.data)) {
        const loc = locations.find((l) => l.id === opt.data.id);

        if (loc) locs.push(loc);
      }

      return locs;
    }, []);
  }
}

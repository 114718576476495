import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { format, sub, add, parse } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const API_DATE_FORMAT = 'yyyy-MM-dd';
const DISPLAY_DATE_FORMAT = 'MM/dd/yyyy';
const DEFAULT_FILTER = 'All Time';
const CUSTOM_FILTER = 'Custom';

type DateValue = string | Date | null | undefined;
type Range<T> = { start: T; end: T };

export interface DateRangeSelectComponentArgs {
  /**
   * close when date range is mutated
   */
  onDateRangeChange: (dateRangeFilter: string, start: string | null, end: string | null) => unknown;
  /**
   * the selected filter option
   */
  dateRangeFilter: string;
  /**
   * whether to return formatted date as ISO string or date
   */
  startDate?: DateValue;
  endDate?: DateValue;
}

export default class DateRangeSelectComponent extends Component<DateRangeSelectComponentArgs> {
  @tracked showCalendar = false;

  get isDefaultFilter(): boolean {
    return this.dateRangeFilter === DEFAULT_FILTER;
  }

  get startDate(): Date | undefined {
    const { startDate } = this.args;
    if (startDate && typeof startDate === 'string') return parse(startDate, API_DATE_FORMAT, new Date());
    if (startDate && startDate instanceof Date) return startDate;
    return;
  }

  get endDate(): Date | undefined {
    const { endDate } = this.args;
    if (endDate && typeof endDate === 'string') return parse(endDate, API_DATE_FORMAT, new Date());
    if (endDate && endDate instanceof Date) return endDate;
    return;
  }

  get dateRangeFormatted(): Range<string> {
    const range = { start: '', end: '' };

    if (this.args.dateRangeFilter === CUSTOM_FILTER) {
      range.start = format(this.startDate!, DISPLAY_DATE_FORMAT);
      range.end = format(this.endDate!, DISPLAY_DATE_FORMAT);

      return range;
    }

    const [startDate, endDate] = this.#getRangeForFilter(this.dateRangeFilter);
    range.start = format(startDate, DISPLAY_DATE_FORMAT);
    range.end = format(endDate, DISPLAY_DATE_FORMAT);

    return range;
  }

  get dateRange(): Range<Date | undefined> {
    const range: Range<Date | undefined> = { start: undefined, end: undefined };

    if (this.args.dateRangeFilter === CUSTOM_FILTER) {
      range.start = this.startDate;
      range.end = this.endDate;

      return range;
    }

    const [startDate, endDate] = this.#getRangeForFilter(this.dateRangeFilter);
    range.start = startDate;
    range.end = endDate;

    return range;
  }

  get isSingleDay(): boolean {
    return this.dateRangeFormatted.start === this.dateRangeFormatted.end;
  }

  get isToday(): boolean {
    return this.dateRangeFilter === 'Today';
  }

  dateRangeFilterOptions = [
    'Today',
    'Yesterday',
    'Last 7 days',
    'Last 30 days',
    'Last 90 days',
    'Past year',
    'Tomorrow',
    'Next 7 days',
    'Next 30 days',
    'Next 90 days',
    'All Time',
  ];

  get dateRangeFilter(): string {
    return this.args.dateRangeFilter ?? DEFAULT_FILTER;
  }

  @action
  selectDateRange(option: string): void {
    if (option === 'All Time') {
      this.args.onDateRangeChange(option, null, null);
      return;
    }

    this.args.onDateRangeChange(option, ...this.#getRangeForFilter(option, true));
  }

  @action
  didSelectDateRange(startDate: Date, endDate: Date): void {
    this.args.onDateRangeChange(CUSTOM_FILTER, format(startDate, API_DATE_FORMAT), format(endDate, API_DATE_FORMAT));
  }

  @action
  setStartDate(date: moment.Moment): void {
    // moment is problematic when formatting as the timezone interferes with the date
    const start = formatInTimeZone(date.toDate(), date.tz()!, API_DATE_FORMAT);
    // handle case where "All Time" is selected
    const end = this.endDate ? format(this.endDate, API_DATE_FORMAT) : start;

    this.args.onDateRangeChange(CUSTOM_FILTER, start, end);
  }

  @action
  setEndDate(date: moment.Moment): void {
    // moment is problematic when formatting as the timezone interferes with the date
    const end = formatInTimeZone(date.toDate(), date.tz()!, API_DATE_FORMAT);
    // handle case where "All Time" is selected
    const start = this.startDate ? format(this.startDate, API_DATE_FORMAT) : end;

    this.args.onDateRangeChange(CUSTOM_FILTER, start, end);
  }

  @action
  onShowCalendar(): void {
    this.showCalendar = true;
  }

  #getRangeForFilter(filter: string): [Date, Date];
  #getRangeForFilter(filter: string, formatForApi?: boolean): [string, string];
  #getRangeForFilter(filter: string, formatForApi?: boolean): [string | Date, string | Date] {
    let start = new Date();
    let end = new Date();

    switch (filter) {
      case 'Yesterday':
        start = sub(start, { days: 1 });
        end = sub(end, { days: 1 });
        break;
      case 'Last 7 days':
        start = sub(start, { days: 7 });
        break;
      case 'Last 30 days':
        start = sub(start, { days: 30 });
        break;
      case 'Last 90 days':
        start = sub(start, { days: 90 });
        break;
      case 'Past year':
        start = sub(start, { years: 1 });
        break;
      case 'Tomorrow':
        start = add(start, { days: 1 });
        end = add(end, { days: 1 });
        break;
      case 'Next 7 days':
        end = add(end, { days: 7 });
        break;
      case 'Next 30 days':
        end = add(end, { days: 30 });
        break;
      case 'Next 90 days':
        end = add(end, { days: 90 });
        break;
    }

    if (formatForApi === true) {
      return [format(start, API_DATE_FORMAT), format(end, API_DATE_FORMAT)];
    }

    return [start, end];
  }
}

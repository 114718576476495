import Controller from '@ember/controller';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { all, dropTask, keepLatestTask, timeout } from 'ember-concurrency';
import MailerTemplateModel from 'garaje/models/mailer-template';
import type { MailerTemplateUpdateArgs } from 'garaje/pods/components/mailer-template-editor/component';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import zft from 'garaje/utils/zero-for-tests';
import remove from 'lodash/remove';
import type { TrackedArray } from 'tracked-built-ins';

import type { SettingsEmailTemplatesShowRouteModel } from './route';

const ACTIONS = {
  CREATE: 'create',
  MANAGE: 'manage',
  EDIT: 'edit',
};

interface NavigationMenuOption {
  title: string;
  defaultId: string;
  customId: string;
}

export default class SettingsEmailTemplatesShowController extends Controller {
  declare model: SettingsEmailTemplatesShowRouteModel;

  queryParams = ['action'];

  guid = guidFor(this);
  transitionConfirmed = false;

  @tracked tempSelectId: string | null = null;
  @tracked loadingTemplateRoute = false;

  @tracked action = '';

  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;
  @service declare store: StoreService;
  @service declare transitionConfirm: TransitionConfirmService;

  mailerTemplates!: TrackedArray<MailerTemplateModel>;

  get currentChildTemplates(): MailerTemplateModel[] {
    return this.mailerTemplates.filter((template) =>
      [template.id, template.envoyDefaultId].includes(this.model.envoyDefaultTemplate.id),
    );
  }

  get navigationMenuOptions(): NavigationMenuOption[] {
    const { envoyDefaultTemplate } = this.model;

    return this.currentChildTemplates.map((template) => {
      const title = template.id === envoyDefaultTemplate.id ? 'Default' : template.title;
      return {
        title,
        defaultId: envoyDefaultTemplate.id,
        customId: template.id,
      };
    });
  }

  get selectedNavigationMenuOption(): NavigationMenuOption | null {
    const {
      navigationMenuOptions,
      model: { mailerTemplate, envoyDefaultTemplate },
    } = this;
    const match = this.tempSelectId || mailerTemplate?.id || envoyDefaultTemplate.id;

    return navigationMenuOptions.find((option) => match === option.customId) || navigationMenuOptions[0] || null;
  }

  get isCreateModalOpen(): boolean {
    return this.action === ACTIONS.CREATE;
  }

  set isCreateModalOpen(value: boolean) {
    this.action = value ? ACTIONS.CREATE : '';
  }

  get isManageModalOpen(): boolean {
    return this.action === ACTIONS.MANAGE;
  }

  set isManageModalOpen(value: boolean) {
    this.action = value ? ACTIONS.MANAGE : '';
  }

  get isEditing(): boolean {
    return this.action === ACTIONS.EDIT;
  }

  set isEditing(value: boolean) {
    this.action = value ? ACTIONS.EDIT : '';
  }

  get shouldUseCompanyName(): boolean {
    const {
      model: { envoyDefaultTemplate, isWhiteLabelFrom },
    } = this;
    const isAlwaysFromCompany = MailerTemplateModel.IDENTIFIERS_ALWAYS_FROM_COMPANY.includes(
      envoyDefaultTemplate?.identifier,
    );

    return isWhiteLabelFrom || isAlwaysFromCompany;
  }

  get shouldShowSidebar(): boolean {
    const {
      isEditing,
      model: { mailerTemplate },
    } = this;

    return Boolean(isEditing && mailerTemplate && !mailerTemplate.isDeleted);
  }

  get hasChanges(): boolean {
    const { mailerTemplate } = this.model;
    const mailerBlocks = mailerTemplate?.mailerBlocks;
    const isBlocksDirty = mailerBlocks && mailerBlocks.some((block) => block.isDirty);

    return Boolean(mailerTemplate?.hasDirtyAttributes || isBlocksDirty);
  }

  confirmTransition(transition: Transition): void {
    if (!this.transitionConfirmed && this.hasChanges) {
      void this.transitionConfirm.displayConfirmTask.perform(transition, {
        continue: () => (this.transitionConfirmed = true),
      });
    }
  }

  @action
  async saveNewCustomMailerTemplate(title: string): Promise<void> {
    const {
      flashMessages,
      store,
      model: { currentLocation, envoyDefaultTemplate },
    } = this;

    try {
      const customTemplate = store.createRecord('mailer-template', {
        ...envoyDefaultTemplate.childTemplateConfig,
        ...{
          title,
          locations: [currentLocation],
        },
      });

      await customTemplate.save();

      flashMessages.showAndHideFlash('success', `Template "${customTemplate.title}" created!`);

      // add new template to the model list
      this.mailerTemplates.push(customTemplate);

      await this.router.transitionTo(
        'visitors.settings.email-templates.show.custom',
        envoyDefaultTemplate.id,
        customTemplate.id,
        {
          queryParams: {
            action: '',
          },
        },
      );
    } catch (e) {
      flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));

      throw e;
    }
  }

  @action
  async saveRecipients(mailerTemplates: MailerTemplateModel[]): Promise<void> {
    const { flashMessages } = this;
    try {
      await all(
        mailerTemplates.filter((template) => !template.envoyDefault).map((mailerTemplate) => mailerTemplate.save()),
      );
      flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      throw e;
    }
  }

  @action
  async viewMailerTemplate({ defaultId, customId }: NavigationMenuOption): Promise<void> {
    this.tempSelectId = customId;
    if (customId === defaultId) {
      await this.router.transitionTo('visitors.settings.email-templates.show', defaultId, {
        queryParams: {
          action: '',
        },
      });
    } else {
      await this.router.transitionTo('visitors.settings.email-templates.show.custom', defaultId, customId, {
        queryParams: {
          action: '',
        },
      });
    }
  }

  saveMailerTemplateTask = dropTask(async ({ mailerTemplateChangeset, mailerBlocks }: MailerTemplateUpdateArgs) => {
    try {
      await mailerTemplateChangeset.save();

      if (mailerBlocks) {
        await all(mailerBlocks.filter((block) => block.isDirty).map((block) => block.save()));
      }

      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      throw e;
    }
  });

  @dropTask deleteConfirmationTask = {
    *perform(
      this: {
        context: SettingsEmailTemplatesShowController;
        continue: () => Promise<void>;
        abort: () => void;
      },
      mailerTemplate: MailerTemplateModel,
    ): Generator<Promise<unknown>, void, unknown> {
      const { context: controller } = this;
      yield new Promise<void>((resolve, reject) => {
        this.continue = async () => {
          await controller.deleteTemplate(mailerTemplate);
          resolve();
        };
        // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
        this.abort = () => reject();
      });
    },
  };

  @dropTask rollbackConfirmationTask = {
    *perform(this: {
      context: SettingsEmailTemplatesShowController;
      continue: () => void;
      abort: () => void;
    }): Generator<Promise<unknown>, void, unknown> {
      const { context: controller } = this;

      yield new Promise<void>((resolve, reject) => {
        this.continue = () => {
          controller.isEditing = false;
          resolve();
        };

        // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
        this.abort = () => reject();

        if (!controller.hasChanges) this.continue();
      });
    },
  };

  @action
  async deleteTemplate(mailerTemplate: MailerTemplateModel): Promise<void> {
    try {
      await mailerTemplate?.destroyRecord();
      remove(this.mailerTemplates, (template) => template.id === mailerTemplate?.id);
      this.flashMessages.showAndHideFlash('success', 'Template deleted!');
      void this.router.replaceWith('visitors.settings.email-templates.show', this.model.envoyDefaultTemplate.id, {
        queryParams: { action: '' },
      });
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      throw e;
    }
  }

  previewEmailTask = keepLatestTask(async () => {
    await timeout(zft(1000));

    const {
      flashMessages,
      model: { envoyDefaultTemplate, mailerTemplate },
    } = this;

    try {
      return await (mailerTemplate || envoyDefaultTemplate).getEmailPreview();
    } catch (e) {
      flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      throw e;
    }
  });

  sendTestEmailTask = dropTask(async () => {
    const {
      flashMessages,
      model: { envoyDefaultTemplate, mailerTemplate },
    } = this;

    try {
      await (mailerTemplate || envoyDefaultTemplate).sendTestEmail();
      flashMessages.showAndHideFlash('success', 'Test email sent!');
    } catch (e) {
      flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      throw e;
    }
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'visitors.settings.email-templates.show': SettingsEmailTemplatesShowController;
  }
}

import { Audiences } from '../types';

import { MESSAGE_AUDIENCE_TYPES } from './message-audience-types';

export const MESSAGE_EMPLOYEE_AUDIENCES = [
  {
    label: MESSAGE_AUDIENCE_TYPES[Audiences.Self],
    value: Audiences.Self,
  },
  {
    label: MESSAGE_AUDIENCE_TYPES[Audiences.AllEmployeesCheckedIn],
    value: Audiences.AllEmployeesCheckedIn,
  },
  {
    label: MESSAGE_AUDIENCE_TYPES[Audiences.AllEmployeesWithReservation],
    value: Audiences.AllEmployeesWithReservation,
  },
  {
    label: MESSAGE_AUDIENCE_TYPES[Audiences.AllEmployeesAtDefaultLocation],
    value: Audiences.AllEmployeesAtDefaultLocation,
  },
  {
    label: MESSAGE_AUDIENCE_TYPES[Audiences.AllEmployeesAtLocation],
    value: Audiences.AllEmployeesAtLocation,
  },
];

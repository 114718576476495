// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { sort } from '@ember/object/computed';
import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import { belongsTo, hasMany } from '@ember-data/model';
import AbstractSignInFieldPageModel from 'garaje/models/abstract/abstract-sign-in-field-page';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import adapter from 'garaje/utils/decorators/adapter';

import type GlobalFlowModel from './global-flow';
import type GlobalSignInFieldModel from './global-sign-in-field';

@adapter('sign-in-field-page')
class GlobalSignInFieldPageModel extends AbstractSignInFieldPageModel<GlobalSignInFieldModel> {
  @belongsTo('global-flow') declare flow: AsyncBelongsTo<GlobalFlowModel>;
  @hasMany('global-sign-in-field') declare signInFields: AsyncHasMany<GlobalSignInFieldModel>;

  sortPosition = ['position'];
  @sort('signInFields', 'sortPosition') topLevelFields!: GlobalSignInFieldModel[];
}

export default GlobalSignInFieldPageModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'global-sign-in-field-page': GlobalSignInFieldPageModel;
  }
}

import { isArray } from '@ember/array';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type AbilitiesService from 'ember-can/services/abilities';
import { dropTask } from 'ember-concurrency';
import type FlowModel from 'garaje/models/flow';
import type LocationModel from 'garaje/models/location';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type FlowService from 'garaje/services/flow';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { and, alias, not } from 'macro-decorators';
import type ServerError from 'utils/server-error';

interface FlowCardComponentSignature {
  Args: {
    /**
     * flow model to render
     */
    flow: FlowModel;
    /**
     * array of enabled flows models
     */
    enabledFlows: FlowModel[];
  };
  Element: HTMLDivElement;
}

export default class FlowCardComponent extends Component<FlowCardComponentSignature> {
  @service declare abilities: AbilitiesService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;
  @service('flow') declare employeeScreeningFlowService: FlowService;

  @alias('employeeScreeningFlowService.activeEmployeeScreeningFlow.name')
  employeeScreeningFlowName: LocationModel['employeeScreeningFlow'];

  @and('notGlobalAdmin', 'currentAdmin.isLocationAdmin') isOnlyLocationAdmin!: boolean;
  @not('currentAdmin.isGlobalAdmin') notGlobalAdmin!: boolean;

  get canEditLocalFlow(): boolean {
    // If the split is not enabled then users without edit access will not be on this page
    return this.featureFlags.isEnabled('custom-roles-garaje-authz-vis3-flows')
      ? this.abilities.can('edit visitor-types')
      : true;
  }

  get canEditGlobalFlow(): boolean {
    return this.featureFlags.isEnabled('custom-roles-garaje-authz-vis3-flows')
      ? this.abilities.can('edit global-flows')
      : !this.isOnlyLocationAdmin;
  }

  toggleEnabledTask = dropTask(async (flow: FlowModel) => {
    flow.enabled = !flow.enabled;
    try {
      // @ts-ignore
      await flow.save({ propagable: true });
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      const serverError = <ServerError>e;

      flow.enabled = !flow.enabled;
      let title = 'Server error. Please try again.';
      let body = '';

      if (serverError.isAdapterError) {
        title = parseErrorForDisplay(e);
      }

      if (isArray(serverError.errors) && serverError.errors.length === 1) {
        const error = serverError.errors[0];
        if (error?.source && error.source.pointer === '/data/attributes/name') {
          title = error.title;
          body = error.detail;
        }
      }

      this.flashMessages.showFlash('error', title, body);
    }
  });
}

import type ArrayProxy from '@ember/array/proxy';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { type SafeString } from '@ember/template/-private/handlebars';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { formatInTimeZone } from 'date-fns-tz';
import Ember from 'ember';
import { type DetailedChangeset, type BufferedChangeset } from 'ember-changeset/types';
import { type Task, task } from 'ember-concurrency';
import config from 'garaje/config/environment';
import type AbstractBadge from 'garaje/models/abstract/abstract-badge';
import type UiHookModel from 'garaje/models/ui-hook';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StatesService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import urlBuilder from 'garaje/utils/url-builder';
import type Handlebars from 'handlebars';
import { alias, equal, union, sortBy, filterBy } from 'macro-decorators';
import { localCopy } from 'tracked-toolbox';
import { isChangeset } from 'validated-changeset';

const { escapeExpression } = (<typeof Handlebars>(<unknown>Ember.Handlebars)).Utils;

export type VariableMap = Record<string, SafeString | string | boolean | undefined>;

export interface BadgeOption<T> {
  label: string;
  value: T;
}

interface BadgeDisplayConfigArgs {
  badge: AbstractBadge | DetailedChangeset<AbstractBadge>;
  badgeVariables?: VariableMap;
  brandingOptions?: BadgeOption<string>[];
  /**
   * CSS override for badge
   */
  css?: AbstractBadge['css'];
  extraFieldsOptions?: BadgeOption<string>[];
  isDisabled?: boolean;
  canShowPhoto?: boolean;
  logo?: string;
  onChangePhoto?: (value: boolean) => unknown;
  shouldShowNoNdaBadgeToggle?: boolean;
  showBadgeIntegrationMenus?: boolean;
  isAppUpdateRequired?: boolean;
  timezone: string;
  uiHooks?: ArrayProxy<UiHookModel>;
  saveTask?: Task<void, []>;
  wrapLogoUrl?: boolean;
  showLocationIntegrationReminder?: boolean;
}

export default class BadgeDisplayConfig extends Component<BadgeDisplayConfigArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StatesService;

  @localCopy('args.canShowPhoto', true) canShowPhoto!: boolean;
  @localCopy('args.isAppUpdateRequired', false) isAppUpdateRequired!: boolean;
  @localCopy('args.wrapLogoUrl', true) wrapLogoUrl!: boolean;

  sampleBarcode = '/assets/images/badge/sample-barcode.png';
  sampleQrCode = '/assets/images/badge/sample-qr-code.png';

  customUiHooksOptions = [{ label: 'None', urn: null }];

  @tracked isNoNdaBadgeVisible = false;
  @tracked isEditing = false;

  @alias('args.badge') badge!: BadgeDisplayConfigArgs['badge'];
  @alias('state.currentCompany') currentCompany!: StatesService['currentCompany'];

  @sortBy('args.uiHooks', 'label') sortedUiHooks!: UiHookModel[];

  @filterBy('sortedUiHooks', 'triggerName', 'badge_barcode') hooksBadgeBarcode!: UiHookModel[];
  @filterBy('sortedUiHooks', 'triggerName', 'badge_qr_code') hooksBadgeQrCode!: UiHookModel[];
  @filterBy('sortedUiHooks', 'triggerName', 'badge_text_field') hooksBadgeTextField!: UiHookModel[];

  @union('customUiHooksOptions', 'hooksBadgeBarcode') hooksBadgeBarcodeOptions!: UiHookModel[];
  @union('customUiHooksOptions', 'hooksBadgeQrCode') hooksBadgeQrCodeOptions!: UiHookModel[];

  @equal('badge.footer', 'time_of_entry') timeOfEntryEnabled!: boolean;

  get isDirty(): boolean {
    return isChangeset(this.badge) ? (<BufferedChangeset>this.badge).isDirty : !!this.badge.hasDirtyAttributes;
  }

  get photoOptions(): BadgeOption<boolean>[] {
    return [
      { label: 'Display', value: true },
      { label: 'Hide', value: false },
    ];
  }

  get brandingOptions(): BadgeOption<string>[] {
    let options = [{ label: 'None', value: 'none' }];

    const logo = this.logoUrl;

    if (!isBlank(logo)) {
      options.unshift({ label: 'Logo', value: 'logo' });
    }

    if (this.args.brandingOptions) {
      options = [...this.args.brandingOptions, ...options];
    }

    return options;
  }

  get extraFields(): BadgeOption<string>[] {
    let extraFields = [{ label: 'None', value: 'none' }];

    if (this.args.extraFieldsOptions) {
      extraFields = [...this.args.extraFieldsOptions, ...extraFields];
    }

    return extraFields;
  }

  get logoUrl(): string {
    if (this.args.logo) {
      return this.wrapLogoUrl ? urlBuilder.photoUrl(this.args.logo) : this.args.logo;
    }

    return '';
  }

  get uiHook1Markup(): string | SafeString {
    return this.uiHookSampleMarkup(this.badge.uiHookOne);
  }

  get uiHook2Markup(): string | SafeString {
    return this.uiHookSampleMarkup(this.badge.uiHookTwo);
  }

  get badgeVariables(): VariableMap {
    let css = this.args.css ?? this.badge.css ?? '';

    // ignore any css that may be passed in as an override if we for any reason alter the css in this component
    if (isChangeset(this.badge) && (<BufferedChangeset>this.badge).change['css']) {
      css = this.badge.css;
    }

    const badgeVariables: VariableMap = {
      name: 'Visitor Name',
      dateTime: formatInTimeZone(new Date(), this.args.timezone, 'MMM d, yyyy · HH:mm'),
      company: this.currentCompany?.name,
      email: this.currentAdmin.email,
      phone: this.currentAdmin.phoneNumber,
      photoUrl: urlBuilder.photoUrl(this.badge.samplePhotoPath),
      logoUrl: this.logoUrl,
      badgeHeaderContents: this.badge.header,
      badgeShowPhoto: !this.canShowPhoto ? false : this.args.badge.showPhoto,
      badgeExtraField1: this.badge.extraFieldOne,
      badgeExtraField2: this.badge.extraFieldTwo,
      badgeExtraField3: this.badge.extraFieldThree,
      badgeExtraField1Label: this.badge.extraFieldOneLabel,
      badgeExtraField2Label: this.badge.extraFieldTwoLabel,
      badgeExtraField3Label: this.badge.extraFieldThreeLabel,
      badgeFooter: this.badge.footer,
      badgeNotes: this.badge.notes,
      badgeCSS: css.replace(/(?:\r\n|\r|\n)+/g, ''),
      uiHook1Html: this.uiHook1Markup,
      uiHook2Html: this.uiHook2Markup,
      ...this.args.badgeVariables,
    };

    if (this.args.shouldShowNoNdaBadgeToggle) {
      badgeVariables['ndaRejected'] = this.isNoNdaBadgeVisible ? '1' : '0';
    }

    return badgeVariables;
  }

  get customBadgeStyle(): SafeString {
    const url = this.badge.image;
    let style = htmlSafe('');
    if (url) {
      style = htmlSafe(`background-image: url('${url}');`);
    }
    return style;
  }

  get previewHTML(): SafeString {
    const previewHTML = this.badge.html;
    const variables = this.badgeVariables;
    const variablesRegex = new RegExp(`var (${Object.keys(variables).join('|')}) = (.+);`, 'g');

    const badgeCSS = <string>variables['badgeCSS'];
    delete variables['badgeCSS'];

    const params = this.parameterize(variables);

    const result = previewHTML
      .replace(
        '<script src="jquery.min.js"></script>',
        '<script src="https://code.jquery.com/jquery-1.10.2.min.js"></script>',
      )
      .replace(/\/assets\/badge-fonts/, `${config.envoyHost}/assets/badge-fonts`)
      .replace(variablesRegex, function (...args: [string, string]) {
        const [, varName] = args;
        const value = <string>variables[varName];

        return `var ${varName} = "${escapeExpression(value)}";`;
      })
      .replace('location.search', `"${params}"`)
      .replace('var badgeCSS = ""', `var badgeCSS = "${badgeCSS}"`);

    return htmlSafe(result);
  }

  get showAppUpdateWarning(): boolean {
    return !!this.args.showBadgeIntegrationMenus && !!this.args.isAppUpdateRequired;
  }

  parameterize(params: VariableMap): string {
    delete params['badgeCSS'];

    const andParams = Object.keys(params)
      .map((key) => {
        const paramValue = <string>params[key] || '';

        if (['badgeExtraField1', 'badgeExtraField2', 'badgeExtraField3'].includes(key)) {
          return `${encodeURIComponent(paramValue)}=[${encodeURIComponent(paramValue.substr(6))}]`;
        }

        return `${encodeURIComponent(key)}=${encodeURIComponent(paramValue)}`;
      })
      .join('&');

    return `?${andParams}`;
  }

  uiHookSampleMarkup(urn: string): SafeString | string {
    if (!(urn && this.args.showBadgeIntegrationMenus)) return '';

    const { src, alt } = this.uiHookSampleImage(urn);

    if (!(src && alt)) return '';

    return htmlSafe(`<img src='${src}' alt='${alt}' />`);
  }

  uiHookSampleImage(urn: string): {
    src?: string;
    alt?: string;
  } {
    if (!urn) return {};

    const uiHook = this.args.uiHooks?.findBy('urn', urn);
    const triggerName = <string>uiHook?.triggerName;

    return (
      {
        badge_barcode: { src: this.sampleBarcode, alt: 'Sample Barcode' },
        badge_qr_code: { src: this.sampleQrCode, alt: 'Sample QR code' },
      }[triggerName] || {}
    );
  }

  @action
  applyStylesV2(): void {
    const css = this.badge.css || '';

    // Detect the v2 style string. If it is present, leave the current styles
    if (css.includes('[data-style-version-2]')) return;

    this.badge.css = this.badge.defaultCss || '';
  }

  @action
  changePhoto(option: BadgeOption<boolean>): void {
    const selection = option.value;
    this.badge.showPhoto = selection;
    this.args.onChangePhoto?.(selection);
  }

  @action
  handleChangeBrandingOption(option: BadgeOption<string>): void {
    this.badge.header = option.value;
  }

  @action
  cancel(): void {
    this.isEditing = false;
  }

  @action
  rollback(): void {
    if (isChangeset(this.badge)) {
      (<BufferedChangeset>this.badge).rollback();
    } else {
      this.badge.rollbackAttributes();
    }
  }

  saveTask = task({ drop: true }, async () => {
    try {
      await (this.args.saveTask?.perform().catch(throwUnlessTaskDidCancel) ?? this.badge.save());
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.isEditing = false;
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}

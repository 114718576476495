// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { readOnly, notEmpty } from '@ember/object/computed';

// eslint-disable-next-line ember/no-classic-classes
export default Component.extend({
  status: readOnly('content.approvalStatus.status'),
  hasReports: notEmpty('content.approvalStatus.preRegistrationRequiredReport'),
  hasInvite: notEmpty('content.invite.id'),

  classNames: Object.freeze(['table-cell', 'border-b', 'align-top', 'px-2', 'pt-6', 'text-carbon-50']),
});

/* eslint-disable ember/no-get */
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import type { SafeString } from '@ember/template/-private/handlebars';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { dropTask } from 'ember-concurrency';
import type InviteModel from 'garaje/models/invite';
import type PrinterModel from 'garaje/models/printer';
import type CurrentAdminService from 'garaje/services/current-admin';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { empty, alias } from 'macro-decorators';

interface BulkActionsInvitesMenuComponentArgs {
  canPrintBadges: boolean;
  preprintBadge: (invites: InviteModel[]) => Promise<void>;
  printers: PrinterModel[];
  isSelectAllIndeterminate: boolean;
  invites?: InviteModel[];
  clearAll: () => void;
  deleteInvites: (invites: InviteModel[]) => void;
  menuHeight: number;
}

export default class BulkActionsInvitesMenuComponent extends Component<BulkActionsInvitesMenuComponentArgs> {
  @service declare abilities: AbilitiesService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare metrics: MetricsService;
  @service declare state: StateService;

  @tracked displayPrinterSelectorModal = false;
  @tracked showSignInModal = false;

  @empty('deletableInvites') deleteInviteDisabled!: boolean;
  @alias('invitesAllowedAccess') preprintableInvites!: this['invitesAllowedAccess'];

  get canSignInFromInvite(): boolean {
    return this.abilities.can('create entries') && Boolean(this.state.vrSubscription?.canSignInFromInvite);
  }

  get invitesAllowedAccess(): InviteModel[] | undefined {
    return this.args.invites?.filter((invite) => !invite.needsApprovalReview && !invite.approvalWasDenied);
  }

  get invitesNotPendingReview(): InviteModel[] | undefined {
    return this.args.invites?.filter((invite) => !invite.needsApprovalReview);
  }

  get signInableInvites(): InviteModel[] | undefined {
    return this.invitesAllowedAccess?.filter((invite) => !get(invite, 'entry.signInTime'));
  }

  get deletableInvites(): InviteModel[] | undefined {
    if (this.abilities.cannot('delete invites')) return [];

    return this.invitesNotPendingReview?.filter((invite) => !get(invite, 'entry.signInTime'));
  }

  get headerStyle(): SafeString {
    return htmlSafe(`top: -18px;height:${this.args.menuHeight}`);
  }

  get signInDisabled(): boolean {
    if (this.abilities.cannot('edit invites')) return true;
    return this.signInableInvites?.length === 0;
  }

  preprintBadgeTask = dropTask(async (invites: InviteModel[]) => {
    await this.args.preprintBadge(invites);
    this.trackPreprintBadgeRequested(invites);
    this.args.clearAll();
  });

  @action
  trackPreprintBadgeRequested(invites: InviteModel[]): void {
    const properties = {
      invite_count: invites.length,
      invite_ids: invites.map((i) => parseInt(i.id, 10)),
    };
    this.metrics.trackEvent('Bulk Preprint Invite Badge Requested', properties);
  }
}

import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type SessionService from 'garaje/services/session';

import type LoginController from './controller';
import type { RedirectCode } from './controller';

export default class LoginRoute extends Route {
  @service declare session: SessionService;

  title = 'Log in to Envoy';

  beforeModel(): void {
    this.session.prohibitAuthentication('protected');
  }

  setupController(controller: LoginController, model: unknown, transition: Transition): void {
    super.setupController(controller, model, transition);

    const { rc: redirectCode } = controller;

    if (redirectCode) {
      controller.showRedirectCodeError(<RedirectCode>(<unknown>redirectCode));
    }
  }
}

import DeskWithAmenitiesFragment from 'garaje/graphql/fragments/employee-schedule/DeskWithAmenitiesFragment';
import EnvoyErrorsFragment from 'garaje/graphql/fragments/employee-schedule/EnvoyErrorsFragment';
import gql from 'graphql-tag';

export default gql`
  fragment DesksOnFloorFragment on DesksOnFloor {
    desks {
      ...DeskWithAmenitiesFragment
    }
    errors {
      ...EnvoyErrorsFragment
    }
  }
  ${DeskWithAmenitiesFragment}
  ${EnvoyErrorsFragment}
`;

import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type AnnouncementTemplateCategoryModel from 'garaje/models/announcement-template-category';
import type CompanyModel from 'garaje/models/company';
import type EmployeeModel from 'garaje/models/employee';
import type EmployeeGroupModel from 'garaje/models/employee-group';
import type LocationModel from 'garaje/models/location';
import type UserModel from 'garaje/models/user';

export default class AnnouncementTemplateModel extends Model {
  // Relationships
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('user') declare creator: AsyncBelongsTo<UserModel>;
  @belongsTo('announcement-template-category')
  declare announcementTemplateCategory: AsyncBelongsTo<AnnouncementTemplateCategoryModel>;
  @hasMany('location') declare locations: AsyncHasMany<LocationModel>;
  @hasMany('employee-group') declare employeeGroups: AsyncHasMany<EmployeeGroupModel>;
  @hasMany('employees') declare employees: AsyncHasMany<EmployeeModel>;

  // Attributes
  @attr('string') declare title: string;
  @attr('string') declare name: string;
  @attr('string') declare message: string;
  @attr('array') declare defaultChannels: string[];
  @attr('array') declare defaultEmployeeAudiences: string[];
  @attr('array') declare defaultVisitorAudiences: string[];
  @attr('array') declare defaultEmployeeGroups: string[];
  @attr('array') declare defaultEmployees: string[];
  @attr('array', { defaultValue: () => [] }) declare locationNames: string[];
  @attr('boolean') declare published: boolean;
  @attr('boolean') declare markAsSafe: boolean;
  @attr('date') declare deletedAt: Date;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'announcement-template': AnnouncementTemplateModel;
  }
}

import { service } from '@ember/service';
import ApplicationAdapter from 'garaje/adapters/application';
import config from 'garaje/config/environment';
import type StateService from 'garaje/services/state';

export default class AcsLocationAdapter extends ApplicationAdapter {
  @service declare state: StateService;

  host = config.badgeLogApiHost;
  namespace = '';

  urlForQueryRecord(): string {
    const { currentLocation } = this.state;
    return `/a/badge-log/v1/locations/${currentLocation.id}/notification-settings`;
  }
}

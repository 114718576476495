import type { AsyncBelongsTo, SyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type LocationModel from 'garaje/models/location';
import type VfdContactMethodModel from 'garaje/models/vfd-contact-method';
import type VfdScheduleModel from 'garaje/models/vfd-schedule';

class VfdConfiguration extends Model {
  @belongsTo('location', { async: true, inverse: null }) declare location: AsyncBelongsTo<LocationModel>;
  @belongsTo('vfd-schedule', { async: false }) declare schedule: VfdScheduleModel;
  @hasMany('vfd-contact-methods', { async: false }) declare contactMethods: SyncHasMany<VfdContactMethodModel>;

  @attr('boolean') declare enabled: boolean;
  @attr('string') declare locationName: string;
  @attr('number') declare kioskCount: number;
}

export default VfdConfiguration;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface VfdConfiguration {
    'vfd-configuration': VfdConfiguration;
  }
}

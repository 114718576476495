import Model, { attr } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';
import serializer from 'garaje/utils/decorators/serializer';

@adapter('deliveries')
@serializer('deliveries')
class DeliveryExport extends Model {
  // Accepted formats: csv and xlsx
  @attr('string', { defaultValue: 'csv' }) declare exportFormat: string;
  @attr('string') declare file: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @attr() declare filter: any;
  @attr('number', { defaultValue: 0 }) declare progress: number;
  @attr('string', { defaultValue: 'pending' }) declare status: string;

  get isDone(): boolean {
    return this.status === 'completed';
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'delivery-export': DeliveryExport;
  }
}

export default DeliveryExport;

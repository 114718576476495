import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { endOfDay, isFuture, startOfDay, startOfMonth, startOfYear, subDays, subYears } from 'date-fns';
import { formatInTimeZone, toZonedTime, fromZonedTime } from 'date-fns-tz';
import type { Dropdown } from 'ember-basic-dropdown/components/basic-dropdown';
import type {
  CalculatePosition,
  HorizontalPosition,
  VerticalPosition,
} from 'ember-basic-dropdown/utils/calculate-position';
import { modifier } from 'ember-modifier';
import { advanceSelectableOption } from 'ember-power-select/utils/group-utils';
import { modifyDateInTimeZone, parseYyyyMmDdInTimeZone, DATE_FNS_YYYY_MM_DD } from 'garaje/utils/date-fns-tz-utilities';
import moment from 'moment-timezone';
import { localCopy } from 'tracked-toolbox';

type DateRangeTuple = [startDate: Date, endDate: Date];

const DEFAULT_TIMEZONE = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'America/Los_Angeles';

const ARROW_DOWN = 'ArrowDown';
const ARROW_UP = 'ArrowUp';
const ENTER_KEY = 'Enter';
const ESC_KEY = 'Escape';
const SPACE_KEY = ' ';
const TAB_KEY = 'Tab';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface DateRanger extends Dropdown {
  // Custom Public API to yield
}

export interface DateRangerComponentSignature {
  Args: {
    calculatePosition?: CalculatePosition;
    destination?: string;
    disabled?: boolean;
    endDate?: Date;
    horizontalPosition?: HorizontalPosition;
    initiallyOpened?: boolean;
    preventScroll?: boolean;
    renderInPlace?: boolean;
    rootEventType?: 'click' | 'mousedown';
    startDate?: Date;
    tabindex?: number | string;
    timezone?: string;
    triggerRole?: string;
    verticalPosition?: VerticalPosition;
    onGoBackOneMonth?: (date: Date) => void;
    onGoToNextMonth?: (date: Date) => void;
    setStartDate?: (date: Date) => void;
    setEndDate?: (date: Date) => void;
    onDateSelected?: (startDate: Date, endDate: Date) => void;
    onDateRangeSelected?: (startDate: Date, endDate: Date) => void;

    // Note: onInit and registerAPI are interface stubs for future use
    onInit?: (api: DateRanger) => void;
    registerAPI?: (api: DateRanger) => void;
    onOpen?: (api: DateRanger, e: Event) => boolean | undefined | void;
    onClose?: (api: DateRanger, e: Event) => boolean | undefined | void;
  };

  Element: HTMLElement;
}

// Convert 11:59pm in one timezone to 11:59pm in another timezone
const sameTimeInTimeZone = function (date: Date, toTz: string, fromTz: string): Date {
  return toZonedTime(fromZonedTime(new Date(date.toJSON()), toTz), fromTz);
};

export default class DateRangerComponent extends Component<DateRangerComponentSignature> {
  storedAPI!: DateRanger;
  triggerElement!: HTMLElement;

  @tracked date: string | null = '';
  @tracked highlightedDateRange: string | null = '';

  @localCopy('args.endDate', null) endDate!: Date | null;
  @localCopy('args.startDate', null) startDate!: Date | null;
  @localCopy('args.timezone', DEFAULT_TIMEZONE) timezone!: string;

  get shortcuts(): string[] {
    return [
      'Today',
      'Yesterday',
      'Past 7 days',
      'Past 30 days',
      'Past 60 days',
      'Past 90 days',
      'Month-to-date',
      'Year-to-date',
      'Past year',
      'All time',
    ];
  }

  get dateRangeFilterOptions(): string[] {
    const { selectedDateRange, shortcuts } = this;
    const options = [...shortcuts];

    if (!options.includes(selectedDateRange)) {
      options.push(selectedDateRange);
    }

    return options;
  }

  get selectedDateRange(): string {
    const { shortcuts, startDateFormat, endDateFormat, timezone } = this;

    return (
      shortcuts.find((option) => {
        const [start, end] = this.#datesForRange(option);
        const optStartFormat = formatInTimeZone(start, timezone, 'MM/dd/yyyy');
        const optEndFormat = formatInTimeZone(end, timezone, 'MM/dd/yyyy');

        return optStartFormat === startDateFormat && optEndFormat === endDateFormat;
      }) || 'Custom'
    );
  }

  get highlightedIndex(): number {
    return this.dateRangeFilterOptions.indexOf(this.highlightedDateRange || '');
  }

  get todayAsString(): string {
    return formatInTimeZone(new Date(), this.timezone, DATE_FNS_YYYY_MM_DD);
  }

  get todayAsDate(): Date {
    return parseYyyyMmDdInTimeZone(this.todayAsString, new Date(), this.timezone);
  }

  get isToday(): boolean {
    const { dateWithDefault, startDateWithDefault, endDateWithDefault, todayAsString } = this;

    if (dateWithDefault !== todayAsString) return false;
    if (formatInTimeZone(startDateWithDefault, this.timezone, DATE_FNS_YYYY_MM_DD) !== todayAsString) return false;
    if (formatInTimeZone(endDateWithDefault, this.timezone, DATE_FNS_YYYY_MM_DD) !== todayAsString) return false;

    return true;
  }

  get isAfterToday(): boolean {
    const { startDateWithDefault, endDateWithDefault } = this;

    return isFuture(startDateWithDefault) && isFuture(endDateWithDefault);
  }

  get isSingleDay(): boolean {
    return this.startDateFormat === this.endDateFormat;
  }

  get dateWithDefault(): string {
    return this.date || formatInTimeZone(new Date(), this.timezone, DATE_FNS_YYYY_MM_DD);
  }

  get startDateWithDefault(): Date {
    if (this.startDate) return new Date(this.startDate);

    const { dateWithDefault } = this;
    const date = parseYyyyMmDdInTimeZone(dateWithDefault, new Date(), this.timezone);

    return modifyDateInTimeZone(date, this.timezone, startOfDay);
  }

  get endDateWithDefault(): Date {
    if (this.endDate) return new Date(this.endDate);

    const { dateWithDefault } = this;
    const date = parseYyyyMmDdInTimeZone(dateWithDefault, new Date(), this.timezone);

    return modifyDateInTimeZone(date, this.timezone, endOfDay);
  }

  // Calendar picker uses Moment.js which has its own timezone logic
  get startDateForCalendar(): Date | moment.Moment {
    const tz: string = `${moment().tz() || DEFAULT_TIMEZONE}`;

    return moment(sameTimeInTimeZone(this.startDateWithDefault, tz, this.timezone));
  }

  // Calendar picker uses Moment.js which has its own timezone logic
  get endDateForCalendar(): Date | moment.Moment {
    const tz: string = `${moment().tz() || DEFAULT_TIMEZONE}`;

    return moment(sameTimeInTimeZone(this.endDateWithDefault, tz, this.timezone));
  }

  get startDateFormat(): string {
    const { timezone, startDateWithDefault } = this;

    return formatInTimeZone(startDateWithDefault, timezone, 'MM/dd/yyyy');
  }

  get endDateFormat(): string {
    const { timezone, endDateWithDefault } = this;

    return formatInTimeZone(endDateWithDefault, timezone, 'MM/dd/yyyy');
  }

  // Use words for screen readers
  get startDateWords(): string {
    const { timezone, startDateWithDefault } = this;

    return formatInTimeZone(startDateWithDefault, timezone, 'MMMM do, yyyy');
  }

  // Use words for screen readers
  get endDateWords(): string {
    const { timezone, endDateWithDefault } = this;

    return formatInTimeZone(endDateWithDefault, timezone, 'MMMM do, yyyy');
  }

  @action
  handleOpen(_api: DateRanger, e: Event): boolean | void {
    const { args } = this;

    if (args.onOpen?.(this.storedAPI, e) === false) return false;

    if (e) {
      if (e instanceof KeyboardEvent && e.type === 'keydown' && [ARROW_UP, ARROW_DOWN].includes(e.key)) {
        e.preventDefault();
      }
    }

    this.#resetHighlighted();
  }

  @action
  handleClose(_api: DateRanger, e: Event): void {
    this.args.onClose?.(this.storedAPI, e);
  }

  @action
  handleTriggerKeydown(e: KeyboardEvent): void {
    this.#routeKeydown(this.storedAPI, e);
  }

  @action
  handleListboxFocus(): void {
    this.#focusOnTriggerElement();
  }

  @action
  didSelectDateRange(startDate: Date, endDate: Date): void {
    this.startDate = this.#dateInTimezone(startDate);
    this.endDate = this.#dateInTimezone(endDate);
    this.date = '';

    this.args.onDateRangeSelected?.(this.startDate, this.endDate);
  }

  @action
  didSelectDate(startDate: Date, endDate: Date): void {
    this.args.onDateSelected?.(this.#dateInTimezone(startDate), this.#dateInTimezone(endDate));

    this.#resetHighlighted();
    this.#focusOnTriggerElement();
  }

  @action
  onDateRangeSelect(option: string, close?: () => void): void {
    if (option === 'Custom') return;

    const [start, end] = this.#datesForRange(option);

    this.didSelectDateRange(start, end);

    close?.();
  }

  @action
  setStartDate(date: Date): void {
    this.startDate = this.#dateInTimezone(date);

    this.args.setStartDate?.(this.startDate);
  }

  @action
  setEndDate(date: Date): void {
    this.endDate = this.#dateInTimezone(date);

    this.args.setEndDate?.(this.endDate);
  }

  @action
  handleGoBackOneMonth(date: Date): void {
    this.args.onGoBackOneMonth?.(new Date(date));
  }

  @action
  handleGoToNextMonth(date: Date): void {
    this.args.onGoToNextMonth?.(new Date(date));
  }

  #dateInTimezone(date: Date): Date {
    const tz: string = date instanceof moment ? `${moment().tz() || DEFAULT_TIMEZONE}` : this.timezone;

    return sameTimeInTimeZone(date, this.timezone, tz);
  }

  #datesForRange(option: string): DateRangeTuple {
    const { todayAsDate, timezone } = this;

    let start: Date = modifyDateInTimeZone(todayAsDate, timezone, startOfDay);
    let end: Date = modifyDateInTimeZone(todayAsDate, timezone, endOfDay);

    switch (option) {
      case 'Yesterday':
        start = subDays(start, 1);
        end = subDays(end, 1);
        break;
      case 'Past 7 days':
        start = subDays(start, 7);
        break;
      case 'Past 30 days':
        start = subDays(start, 30);
        break;
      case 'Past 60 days':
        start = subDays(start, 60);
        break;
      case 'Past 90 days':
        start = subDays(start, 90);
        break;
      case 'Month-to-date':
        start = modifyDateInTimeZone(start, timezone, startOfMonth);
        break;
      case 'Year-to-date':
        start = modifyDateInTimeZone(start, timezone, startOfYear);
        break;
      case 'Past year':
        start = subYears(start, 1);
        break;
      case 'All time':
        start = modifyDateInTimeZone(new Date('2024-01-01T10:59:00Z'), timezone, startOfDay);
        break;
    }

    return [start, end];
  }

  #registerAPI(triggerElement: HTMLElement, [publicAPI]: [DateRanger]): void {
    this.triggerElement = triggerElement;
    this.storedAPI = publicAPI;
  }

  // Keyboard interaction managed by "trigger" element.
  // Restore focus to maintain keyboard event handling.
  #focusOnTriggerElement(): void {
    this.triggerElement?.focus();
  }

  #resetHighlighted(): void {
    this.#highlight(this.selectedDateRange);
  }

  #highlight(opt: string): void {
    this.highlightedDateRange = opt;
  }

  #routeKeydown(api: DateRanger, e: KeyboardEvent): boolean | void {
    const { key } = e;

    if ([ARROW_UP, ARROW_DOWN].includes(key)) {
      return this.#handleKeyUpDown(api, e);
    }

    if ([ENTER_KEY].includes(key)) {
      return this.#handleKeyEnter(api, e);
    }

    if ([ESC_KEY].includes(key)) {
      return this.#handleKeyEsc(api, e);
    }

    if ([SPACE_KEY].includes(key)) {
      return this.#handleKeySpace(api, e);
    }

    if ([TAB_KEY].includes(key)) {
      return this.#handleKeyTab(api, e);
    }
  }

  #handleKeySpace(api: DateRanger, e: KeyboardEvent): void {
    if (e.target !== null && ['TEXTAREA', 'INPUT'].includes((e.target as Element).nodeName)) {
      e.stopImmediatePropagation();
    } else if (api.isOpen && this.highlightedDateRange) {
      e.preventDefault(); // Prevents scrolling of the page.
      e.stopImmediatePropagation();
      this.onDateRangeSelect(this.highlightedDateRange);
      api.actions.close(e);
    }
  }

  #handleKeyUpDown(api: DateRanger, e: KeyboardEvent): void {
    if (api.isOpen) {
      e.preventDefault();
      e.stopPropagation();

      const { dateRangeFilterOptions, highlightedDateRange } = this;
      const step: 1 | -1 = e.key === ARROW_DOWN ? 1 : -1;
      const newHighlighted = <string | null>advanceSelectableOption(dateRangeFilterOptions, highlightedDateRange, step);

      this.highlightedDateRange = newHighlighted;
    } else {
      api.actions.open(e);
    }
  }

  #handleKeyEnter(api: DateRanger, e: KeyboardEvent): boolean | void {
    if (api.isOpen && this.highlightedDateRange) {
      this.onDateRangeSelect(this.highlightedDateRange);
      api.actions.close(e);
      e.stopImmediatePropagation();

      return false;
    }
  }

  #handleKeyTab(api: DateRanger, e: KeyboardEvent): void {
    api.actions.close(e);
  }

  #handleKeyEsc(api: DateRanger, e: KeyboardEvent): void {
    api.actions.close(e);
  }

  updateRegisterAPI = modifier((triggerElement: HTMLElement, [publicAPI]: [DateRanger]) => {
    this.#registerAPI(triggerElement, [publicAPI]);
  });
}

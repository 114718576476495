import ApplicationSerializer from './application';

export default class ScimIntegrationSerializer extends ApplicationSerializer {
  attrs = {
    accessToken: {
      serialize: false,
    },
    createdAt: {
      serialize: false,
    },
    updatedAt: {
      serialize: false,
    },
    lastSyncAt: {
      serialize: false,
    },
  };

  serialize(snapshot) {
    const json = super.serialize(...arguments);

    // check if it's an update operation
    if (!snapshot.record.isNew) {
      if (json.data.relationships && json.data.relationships['scim-primary-location-configuration']) {
        delete json.data.relationships['scim-primary-location-configuration'];
      }
    }

    return json;
  }
}

import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from 'macro-decorators';

export default class SubscriptionsIndexController extends Controller {
  @service('location-billing') billingService;

  @alias('billingService.selectedPeriod') selectedPeriod;
  @alias('billingService.summaryOpened') summaryOpened;
  @alias('billingService.downgradeWarningOpened') downgradeWarningOpened;
  @alias('billingService.subscribed') subscribed;
  @alias('billingService.currentPeriodDisplay') currentPeriodDisplay;
  @alias('billingService.oppositePeriodDisplay') oppositePeriodDisplay;
  @alias('billingService.changeBillingPeriodTask') changeBillingPeriodTask;
  @alias('billingService.togglePeriod') togglePeriod;
  @alias('billingService.renewalDate') renewalDate;
}

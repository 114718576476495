import Component from '@glimmer/component';

import type MailerBlockAttributeObjectProxy from '../../lib/mailer-block-attribute-object-proxy';

interface MailerTemplateEditorAttributeDynamicStringComponentSignature {
  Args: {
    attribute: MailerBlockAttributeObjectProxy;
    active: boolean;
    onChangeValue: (value: string) => void;
  };
}

export default class MailerTemplateEditorAttributeDynamicStringComponent extends Component<MailerTemplateEditorAttributeDynamicStringComponentSignature> {
  get value(): string {
    // eslint-disable-next-line ember/use-ember-get-and-set
    return this.args.attribute.get('value') || '';
  }

  get defaultValue(): string {
    return this.args.attribute.defaultValue || '';
  }

  get variables(): string[] {
    // eslint-disable-next-line ember/use-ember-get-and-set
    return (this.args.attribute.get('locale_interpolation_variables') || []).map((variable) => `%{${variable}}`);
  }
}

import Controller from '@ember/controller';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class LocationOverviewGlobalDevicesController extends Controller {
  queryParams = ['showList'];
  @tracked showList = true;

  @service declare router: RouterService;

  get showNav(): boolean {
    return !this.router.currentRouteName.includes('virtual-front-desk');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'location-overview.global-devices': LocationOverviewGlobalDevicesController;
  }
}

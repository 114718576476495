import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class LocationOverviewOccupancySettingsIndexRoute extends Route {
  title = 'Occupancy Global Analytics Settings · Envoy';

  @service globalOverviewAccess;
  @service router;

  redirect() {
    if (!this.globalOverviewAccess.canVisitOccupancyGlobalSettings) {
      this.router.transitionTo('unauthorized');
    } else {
      this.router.transitionTo('location-overview.occupancy.settings');
    }
  }
}

import Model, { attr } from '@ember-data/model';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { match } from '@ember/object/computed';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('billing-v1')
class Payment extends Model {
  @attr('date') date;
  @attr('number') amount;
  @attr('string') pdf;
  @attr('string') status;
  @attr('boolean') refunded;
  @attr('number') amountRefunded;
  @attr('string') companyName;
  @attr('object') invoiceItems;

  @match('pdf', /billing\/v[1,2]\/payment_pdfs\/.+/) isChargebeePayment;
}

export default Payment;

import ApplicationAdapter from './application';
import config from 'garaje/config/environment';

export default class UiHookAdapter extends ApplicationAdapter {
  host = config.hooksHost;
  namespace = 'a/hooks/v1';

  buildURL(modelName, id, snapshot, requestType, query) {
    const baseUrl = super.buildURL(...arguments);

    const zoneIds = query?.zoneIds ?? snapshot?.adapterOptions?.zoneIds;
    const location = query?.location ?? snapshot?.adapterOptions?.location;
    if (zoneIds) {
      return `${baseUrl}/zones/hooks/ui`;
    } else if (location) {
      return `${baseUrl}/locations/${location}/hooks/ui`;
    }

    // "Generic" UI Hooks endpoint (without location) added by DEVP-1318
    return `${baseUrl}/locations/hooks/ui`;
  }

  sortQueryParams(params) {
    const newParams = { ...super.sortQueryParams(params) };
    delete newParams.location;
    return newParams;
  }

  pathForType() {
    return '';
  }
}

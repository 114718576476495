// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { timeout, enqueueTask, dropTask, restartableTask } from 'ember-concurrency';
import { set } from '@ember/object';
import { isEqual } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

/**
 * Wrapper around power-select to enable infinite-scroll pagination.
 * @param {boolean}  paginatedEnabled       Toggle infinite-scroll pagination on or off
 * @param {number}   scrollThresholdPercent Amount to scroll before loading more data
 * @param {number}   searchThresholdMs      Amount of time to wait during an active search before fetching data
 * @param {function} fetchOptions           Return an array or promised array of options for the page/search params
 * @param {function} onLoaded               Fires after the initial options are loaded
 * @param {function} optionsAreEqual        How to determine if two options are equal
 */
export default class PaginatedSelect extends Component {
  /*
   * public attributes
   */
  paginateEnabled = false;
  scrollThresholdPercent = 0.9;
  searchThresholdMs = 250;
  fetchOptions = (_page, _term, _select) => {};
  onLoaded = (_options, _select) => {};
  optionsAreEqual = (a, b) => isEqual(a, b);

  /*
   * private attributes
   */
  @tracked _api = null;
  _optionsPage = 1;
  _hasMoreData = true;
  _defaultOptions = null;

  // eslint-disable-next-line ember/classic-decorator-hooks
  init() {
    super.init(...arguments);
    set(this, 'registerAPI', (publicAPI) => (this._api = publicAPI));
    set(this, 'search', (term) => this._searchOptions.perform(term));
    set(this, '_reportScroll', (scrolledPercent) => this._scrollOptions.perform(scrolledPercent));
    set(this, 'onInput', (term) => {
      /**
       * Resets the page to 1 on each search.
       */
      set(this, '_optionsPage', 1);
      set(this, '_hasMoreData', true);
      if (!term) {
        /*
         * PowerSelect does not fire the "search" action if the term is empty,
         * so we need to manually reset the options to its original data.
         */
        set(this, 'options', this._defaultOptions);
      }
      return true;
    });

    this._fetchDefaultOptions.perform();
  }

  /**
   * Get first page.
   */
  @dropTask
  *_fetchDefaultOptions() {
    const options = yield this._fetchOptions.perform();
    set(this, 'options', options);
    set(this, '_defaultOptions', options);
    this.onLoaded(options, this._api);
  }

  /**
   * Enqueue all requests to fetchOptions.
   */
  @enqueueTask
  *_fetchOptions(page = 1, term = '') {
    const options = yield this.fetchOptions(page, term, this._api);
    return (options || []).map((a) => {
      a.isEqual = (b) => this.optionsAreEqual(a, b); // used interally by power-select
      return a;
    });
  }

  /**
   * A debounced search.
   */
  @restartableTask
  *_searchOptions(term = '') {
    yield timeout(this.searchThresholdMs);
    const options = yield this._fetchOptions.perform(this._optionsPage, term);
    set(this, 'options', options);
  }

  @dropTask
  *_scrollOptions(scrolledPercent) {
    /**
     * Perform pagination only once when the scroll is over the threshold, and only when applicable.
     */
    if (!this.paginateEnabled || !this._hasMoreData || scrolledPercent < this.scrollThresholdPercent) {
      return;
    }
    set(this, '_optionsPage', this._optionsPage + 1);

    const options = yield this._fetchOptions.perform(this._optionsPage, this._api.searchText);
    set(this, '_hasMoreData', options.length > 0);
    set(this, 'options', this.options.concat(options));
  }
}

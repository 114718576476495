import gql from 'graphql-tag';

export default gql`
  query FeatureConfig($locationId: ID!) {
    featureConfig {
      adminConfig {
        canViewAllReservations(locationId: $locationId) {
          enabled
        }
        canManageAllReservations(locationId: $locationId) {
          enabled
        }
      }

      announcements(locationId: $locationId) {
        enabled
      }

      communications(locationId: $locationId) {
        enabled

        employeeGroups {
          enabled
        }
      }

      deliveries(locationId: $locationId) {
        enabled
      }

      desks(locationId: $locationId) {
        enabled

        unlimitedDesks(locationId: $locationId) {
          enabled
          limit
        }

        permanentAssignment(locationId: $locationId) {
          enabled
        }

        neighborhood(locationId: $locationId) {
          enabled
        }

        amenities(locationId: $locationId) {
          enabled
        }

        allowEnabling(locationId: $locationId) {
          enabled
        }

        accessDesksTab(locationId: $locationId) {
          enabled
        }

        analytics(locationId: $locationId) {
          enabled
        }

        csv(locationId: $locationId) {
          enabled
        }
      }

      deskReservations(locationId: $locationId) {
        enabled

        view(locationId: $locationId) {
          enabled
        }

        booking(locationId: $locationId) {
          enabled
        }

        partialDayBooking(locationId: $locationId) {
          enabled
        }

        delegatedBooking(locationId: $locationId) {
          enabled
        }

        bookingHours(locationId: $locationId) {
          enabled
        }

        editing(locationId: $locationId) {
          enabled
        }

        checkingIn(locationId: $locationId) {
          enabled
        }

        canceling(locationId: $locationId) {
          enabled
        }

        autoAssignment(locationId: $locationId) {
          enabled
        }
      }

      rooms(locationId: $locationId) {
        enabled

        accessRoomsTab(locationId: $locationId) {
          enabled
        }

        analytics(locationId: $locationId) {
          enabled
        }

        unlimitedRooms(locationId: $locationId) {
          enabled
          limit
        }
      }

      roomMeetings(locationId: $locationId) {
        enabled

        checkingIn(locationId: $locationId) {
          enabled
        }

        maskNames(locationId: $locationId) {
          enabled
        }

        notifications(locationId: $locationId) {
          enabled
        }

        onSiteRoomRecapture(locationId: $locationId) {
          enabled
        }

        recurringCleanup(locationId: $locationId) {
          enabled
        }

        resizer(locationId: $locationId) {
          enabled
        }

        serviceRequests(locationId: $locationId) {
          enabled
        }
      }

      maps(locationId: $locationId) {
        enabled

        drafts(locationId: $locationId) {
          enabled
        }

        edit(locationId: $locationId) {
          enabled

          attendanceAnalytics(locationId: $locationId) {
            enabled
          }

          employeeSeating(locationId: $locationId) {
            enabled
          }

          resourceAnalytics(locationId: $locationId) {
            enabled
          }

          resourceOverview(locationId: $locationId) {
            enabled
          }
        }

        view(locationId: $locationId) {
          enabled
        }
      }

      deskAutoAssignment(locationId: $locationId) {
        enabled
      }
      desksActive(locationId: $locationId) {
        enabled
      }
      deskCheckIn(locationId: $locationId) {
        enabled
      }
      desksOnMaps(locationId: $locationId) {
        enabled
      }
      employeeRegistration(locationId: $locationId) {
        enabled
      }
      employeePrivateData(locationId: $locationId) {
        enabled
      }
      employeeScheduling(locationId: $locationId) {
        enabled
      }
      employeeSignInStatus(locationId: $locationId) {
        enabled
      }
      manageCustomRoles(locationId: $locationId) {
        enabled
      }
      mapsPointOfInterest(locationId: $locationId) {
        enabled
      }
      roomsOnMaps(locationId: $locationId) {
        enabled
      }
      schedulingLimits(locationId: $locationId) {
        enabled
      }
    }
  }
`;

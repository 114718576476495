import Component from '@glimmer/component';
import { capitalize } from '@ember/string';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { all } from 'rsvp';

/**
 * Form for downgrading a subscription
 * @param {String}        currentPlanName The user-facing plan name of the current subscription
 * @param {String}        newPlanName     The user-facing plan name we are downgrading to
 * @param {Function}      onContinue      A function to run after the subscription has been downgraded
 * @param {String}        title           Title of component
 * @param {String}        trackingId      Unique id for analytics tracking
 * @param {String}        viaLabel        Text on the CTA used to open this modal
 * @param {String}        viaObject       Type of CTA used to open this modal (button or text_link)
 */

export default class BillingDowngradeVisitorFeatures extends Component {
  @service locations;
  @service metrics;

  constructor() {
    super(...arguments);
    this.trackModalEnteredTask.perform();
  }

  get mustRemoveLocations() {
    const isDowngradingToBasic = this.args.newPlanName === 'basic';
    const hasMultipleActiveLocations = this.locations.active.length > 1;
    return isDowngradingToBasic && hasMultipleActiveLocations;
  }

  get mustRemoveVisitorTypes() {
    const isDowngradingFromPremium = this.args.currentPlanName === 'premium';
    return all(this.locations.active.map((location) => location.hasNonProtectFlows)).then((res) => {
      return res.some(Boolean) && isDowngradingFromPremium;
    });
  }

  get mustRemoveVisitorTypesMessage() {
    return {
      header: `Multiple visitor types are not allowed on the ${capitalize(this.args.newPlanName)}`,
      message: 'Please remove all but one visitor type to downgrade.',
    };
  }

  get mustRemoveLocationsMessage() {
    return {
      header: 'Multiple locations are not allowed on the Basic plan.',
      message: 'Please remove all but one location to downgrade.',
    };
  }

  @task
  *trackModalEnteredTask() {
    const mustRemoveVisitorTypes = yield this.mustRemoveVisitorTypes;

    const { mustRemoveLocations, mustRemoveLocationsMessage, mustRemoveVisitorTypesMessage } = this;

    const numWarningsShown = [mustRemoveLocations, mustRemoveVisitorTypes].filter(Boolean).length;
    const warningText = {};

    if (mustRemoveLocations) {
      Object.assign(warningText, { mustRemoveLocationsMessage });
    }

    if (mustRemoveVisitorTypes) {
      Object.assign(warningText, { mustRemoveVisitorTypesMessage });
    }

    yield this.metrics.trackPage('Modal entered', {
      action_id: this.args.trackingId, // Auto-generated ID to link multiple properties firing to a single action
      modal_title: this.args.title, // title of the modal
      modal_type: 'subscription_change', // modal type
      modal_purpose: 'downgrade_visitors_warning', // purpose of modal
      via_object: this.args.viaObject, // object interacted with that triggered the modal opening
      via_action: 'click', // type of interaction
      via_label: this.args.viaLabel, // Label of the button or element being interacted with
      num_warnings_shown: numWarningsShown, // Number of warning boxes displayed in the Modal. Can be 0.
      warning_text: warningText, // Text list (JSON is ok) of the message displayed in the warning boxes. Useful to track in case we want to optimize these in the future.
      object_change_from: this.args.currentPlanName, // Current plan
      object_change_to: this.args.newPlanName, // New plan (intended)
    });
  }
}

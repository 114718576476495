import Controller from '@ember/controller';
import { service } from '@ember/service';
import { get, action } from '@ember/object';
import ObjectProxy from '@ember/object/proxy';
import { tracked } from '@glimmer/tracking';
import _intersection from 'lodash/intersection';
import { adminRoute } from 'garaje/utils/admin-routing';
import { or } from 'macro-decorators';
import { task } from 'ember-concurrency';
import { COMPANY_ROLES, LOCATION_ROLES } from 'garaje/utils/roles';

export default class EmployeesAdminRolesController extends Controller {
  @service router;
  @service skinnyLocations;
  @service currentAdmin;
  @service featureFlags;
  @service state;
  @service currentLocation;
  queryParams = ['nameFilter', 'locationsFilter', 'rolesFilter'];

  @tracked nameFilter = '';
  @tracked rolesFilter = [];
  @tracked locationsFilter = [];
  @tracked modelTask;
  @tracked accountAdminCount = 0;
  @tracked showAdminAddEmptyPage = true;
  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isLocationAdmin') isAdmin;

  constructor() {
    super(...arguments);
    this.featureFlags.ready().then((_) => {
      if (this.featureFlags.isEnabled('growth_add_admin_empty_state')) {
        this.getRoleCountTask.perform();
      }
    });
  }

  getRoleCountTask = task(async () => {
    const roleCount = await this.currentLocation.getUniqueUserRoleCount.perform(
      null,
      [...COMPANY_ROLES, ...LOCATION_ROLES],
      false,
    );
    this.accountAdminCount = roleCount;
  });

  @action
  closeAdminEmptyPage() {
    this.showAdminAddEmptyPage = false;
  }

  get locations() {
    return get(this.skinnyLocations, 'currentCompanyLocations')?.map((location) =>
      ObjectProxy.create({ content: location, disabled: false }),
    );
  }

  get adminBulkRoute() {
    const path = adminRoute(this.router.currentRouteName);
    return `${path}.bulk`;
  }

  get showSetupGuide() {
    return this.isAdmin && this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper');
  }

  get shouldShowAdminAddPage() {
    if (this.featureFlags.isEnabled('growth_add_admin_empty_state') && this.accountAdminCount === 1) {
      if (
        (this.state.vrSubscription?.isSubscribed || this.state.vrSubscription?.onTrial) &&
        this.showAdminAddEmptyPage &&
        this.state.vrSubscription?.plan !== 'enterprise'
      ) {
        return true;
      }
    }
    return false;
  }
}

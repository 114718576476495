import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class BlocklistImportController extends Controller {
  @service flashMessages;
  @service router;
  @service state;

  @action
  goBackToSecurity() {
    this.router.transitionTo('security');
  }

  @action
  uploadCompleted() {
    this.flashMessages.showAndHideFlash('success', 'Block list imported!');
    this.goBackToSecurity();
  }
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type LocalStorageService from 'garaje/services/local-storage';

interface FeatureDiscoveryArgs {
  title: string;
  description: string;
  localStorageKey: string;
  icon?: string;
  btnRoute?: string;
  btnQuery?: object;
  btnText?: string;
}

export default class FeatureDiscoveryComponent extends Component<FeatureDiscoveryArgs> {
  @service declare localStorage: LocalStorageService;

  get isDismissed(): boolean {
    return this.localStorage.getItem(this.args.localStorageKey) === 'true';
  }

  @action
  dismissBanner(): void {
    this.localStorage.setItem(this.args.localStorageKey, 'true');
  }
}

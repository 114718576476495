import Component from '@glimmer/component';
import type FloorModel from 'garaje/models/floor';
import type NeighborhoodModel from 'garaje/models/neighborhood';

interface NeighborhoodPopupArgs {
  neighborhood: NeighborhoodModel;
  floor: FloorModel;
}

export default class NeighborhoodPopup extends Component<NeighborhoodPopupArgs> {
  get neighborhoodDeskCountOnFloor(): number | undefined {
    const deskCount = this.args.neighborhood.deskCountByFloor.find((floor) => floor['floor-id'] == this.args.floor.id);
    return deskCount?.['desk-count'];
  }
}

import ApplicationSerializer from './application';

export default class EmployeeScreeningFlowSerializer extends ApplicationSerializer {
  attrs = {
    locations: { serialize: true },
    employeeCentric: { serialize: false },
  };
  // This is used for the `hasMany` relationship between flows and skinny-locations
  // envoy-web doesn't know anything about the skinny-locations model.
  modelNameFromPayloadKey(key) {
    if (key === 'summary-pages') {
      return 'employee-summary-page';
    } else if (key === 'locations') {
      return 'skinny-location';
    } else if (key === 'agreement-pages') {
      return 'employee-agreement-page';
    } else if (key === 'sign-in-field-pages') {
      return 'employee-sign-in-field-page';
    } else if (key === 'sign-in-field-action-rule-groups') {
      return 'employee-sign-in-field-action-rule-group';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }

  normalizeResponse(store, primaryModelClass, payload) {
    if (payload.included && payload.included.length) {
      payload.included.forEach((item) => {
        const prefixClasses = [
          'summary-pages',
          'sign-in-field-pages',
          'agreement-pages',
          'agreements',
          'sign-in-field-actions',
          'sign-in-field-action-rule-groups',
          'sign-in-field-actions-contacts',
          'sign-in-fields',
        ];
        if (prefixClasses.includes(item.type)) {
          item.type = `employee-${item.type}`;
        }
      });
    } else if (payload.data.relationships) {
      if (payload.data.relationships['agreement-page']) {
        payload.data.relationships['agreement-page'].type = `employee-agreement-pages`;
      }
      if (payload.data.relationships['summary-page']) {
        payload.data.relationships['summary-page'].type = `employee-summary-pages`;
      }
      if (payload.data.relationships['sign-in-field-page']) {
        payload.data.relationships['sign-in-field-page'].type = `employee-sign-in-field-pages`;
      }
      if (
        payload.data.relationships['sign-in-field-action-rule-groups'] &&
        payload.data.relationships['sign-in-field-action-rule-groups'].data.length
      ) {
        const data = payload.data.relationships['sign-in-field-action-rule-groups'].data;
        data.forEach(function (item) {
          item.type = `employee-sign-in-field-action-rule-groups`;
        });
      }
    }

    return super.normalizeResponse(...arguments);
  }

  payloadKeyFromModelName(key) {
    if (key === 'employee-summary-page') {
      return 'summary-pages';
    } else if (key === 'employee-agreement-page') {
      return 'agreement-pages';
    } else if (key === 'skinny-location') {
      return 'locations';
    } else if (key === 'employee-sign-in-field-page') {
      return 'sign-in-field-pages';
    } else if (key === 'employee-sign-in-field-action-rule-group') {
      return 'sign-in-field-action-rule-groups';
    } else {
      return super.payloadKeyFromModelName(...arguments);
    }
  }
}

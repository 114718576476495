import ApplicationSerializer from './application';

export default class DeskLocationSerializer extends ApplicationSerializer {
  attrs = {
    desksQuantity: {
      serialize: false,
    },
    assignableDesksQuantity: {
      serialize: false,
    },
  };

  serialize(snapshot) {
    const payload = super.serialize(...arguments);

    // we want the server to decide auto-assignment on initial creation
    if (!snapshot.id) {
      delete payload.data.attributes['auto-assign-desk'];
    }

    return payload;
  }
}

import DeliveriesSerializer from './deliveries';

export default class DeliveryAreaSerializer extends DeliveriesSerializer {
  attrs = {
    addressLineOne: {
      serialize: false,
    },
    addressLineTwo: {
      serialize: false,
    },
    city: {
      serialize: false,
    },
    country: {
      serialize: false,
    },
    createdAt: {
      serialize: false,
    },
    latitude: {
      serialize: false,
    },
    locationName: {
      serialize: false,
    },
    longitude: {
      serialize: false,
    },
    notificationSchedule: {
      serialize: false,
    },
    state: {
      serialize: false,
    },
    timeZone: {
      serialize: false,
    },
    zip: {
      serialize: false,
    },
  };

  serialize() {
    const payload = super.serialize(...arguments);

    // hack to only serialize location on create, not update
    if (payload.data.id) {
      delete payload.data.relationships.location;
    }

    if (payload.data.attributes['name'] === null) {
      delete payload.data.attributes.name;
    }

    return payload;
  }
}

import EmberObject from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { get, computed } from '@ember/object';

export const STEPS = [
  {
    id: 1,
    title: 'Get the mobile app',
    description: 'Download the Envoy Deliveries mobile app to get started.',
    order: 0,
    page: 'deliveriesTutorial',
    skippable: false,
    skipped: false,
    reviewed: false,
    completed: false,
  },
  {
    id: 2,
    title: 'Record your first delivery',
    description: 'Snap a photo with the mobile app, and see it appear on your delivery log.',
    order: 1,
    page: 'sampleDeliveryLabel',
    skippable: true,
    skipped: false,
    reviewed: false,
    completed: false,
  },
  {
    id: 3,
    title: 'Set up your employee directory',
    description: 'Upload a CSV or use a directory integration.',
    order: 2,
    page: 'employeesImport',
    skippable: true,
    skipped: false,
    reviewed: false,
    completed: false,
  },
  {
    id: 4,
    title: 'Set up delivery notifications',
    description: 'Configure how employees get delivery notifications.',
    order: 3,
    page: 'deliveryNotifications',
    skippable: true,
    skipped: false,
    reviewed: false,
    completed: false,
  },
  {
    id: 5,
    title: 'Configure notification schedule',
    description: 'Adjust when employees get notifications based on your workplace’s hours.',
    order: 4,
    page: 'deliveryNotificationSchedule',
    skippable: true,
    skipped: false,
    reviewed: false,
    completed: false,
  },
  {
    id: 6,
    title: 'Set up nicknames',
    description: 'Add common nicknames to help match deliveries more reliably.',
    order: 5,
    page: 'nicknames',
    skippable: true,
    skipped: false,
    reviewed: false,
    completed: false,
  },
  {
    id: 7,
    title: 'Assign admin',
    description: 'Assign a delivery admin to your delivery area.',
    order: 6,
    page: 'deliveriesAdmin',
    skippable: true,
    skipped: false,
    reviewed: false,
    completed: false,
  },
  {
    id: 8,
    title: 'Configure pick up security',
    description: 'Require signature and/or photo when someone picks up a delivery.',
    order: 7,
    page: 'deliveryPickUp',
    skippable: true,
    skipped: false,
    reviewed: false,
    completed: false,
  },
];

export class Step extends EmberObject {
  @computed('reviewed', 'skipped', 'completed')
  get done() {
    return get(this, 'reviewed') || get(this, 'skipped') || get(this, 'completed');
  }
}

export const createSteps = () => {
  return STEPS.map((step) => Step.create(step));
};

export const updateSteps = (steps, { reviewed, skipped, completed }, stopAtIndex) => {
  return steps.map((step, idx) => {
    if (idx < stopAtIndex) {
      step.reviewed = typeof reviewed === 'boolean' ? reviewed : step.reviewed;
      step.skipped = typeof skipped === 'boolean' ? skipped : step.skipped;
      step.completed = typeof completed === 'boolean' ? completed : step.completed;
    }
    step.firstViewedAt = new Date();
    return step;
  });
};

// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { filterBy } from '@ember/object/computed';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action, computed, set } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import LOCALE_OPTIONS, { isRTLLocale } from 'garaje/utils/locale-options';

/**
 * @param {Model<Location>}           location
 * @param {Array}                     sections
 * @param {Task}                      reloadTask
 * @param {Task}                      saveTask
 */
export default class VisitorEmailsVisitorGuideManageTranslationsModal extends Component {
  tagName = '';
  localeOptions = LOCALE_OPTIONS;
  selectedLocale = {};

  @filterBy('sections', 'enabled', true) filteredSections;

  @computed('location.{enabledLocales.[],locale}', 'localeOptions.@each.value')
  get availableLocales() {
    const enabledLocales = this.location.enabledLocales;
    return this.localeOptions.filter((option) => enabledLocales.includes(option.value));
  }

  @computed('location.locale', 'localeOptions.@each.{value,label}')
  get defaultLocale() {
    return this.localeOptions.find((locale) => locale.value === this.location.locale).label;
  }

  @computed('selectedLocale.value')
  get isRTLLocale() {
    return isRTLLocale(this.selectedLocale.value);
  }

  // eslint-disable-next-line ember/no-component-lifecycle-hooks
  didInsertElement() {
    super.didInsertElement(...arguments);
    const firstLocale = this.location.enabledLocales[0];
    const selectedLocale = this.localeOptions.find((locale) => locale.value === firstLocale);

    set(this, 'selectedLocale', selectedLocale);

    this.reloadTask.perform();
  }

  @dropTask
  *saveAndClose() {
    yield this.saveTask.perform();
    set(this, 'showModalTranslations', false);
  }

  @action
  changeLocale(locale) {
    set(this, 'selectedLocale', locale);
  }
}

/* eslint-disable key-spacing */

export default {
  Visa: 'visa',
  MasterCard: 'mastercard',
  'American Express': 'amex',
  'Diners Club': 'dinersclub',
  Discover: 'discover',
  UnionPay: 'unionpay',
  Jcb: 'jcb',
  'Visa Electron': 'visaelectron',
  Maestro: 'maestro',
  Forbrugsforeningen: 'forbrugsforeningen',
  Dankort: 'dankort',
  // Chargebee mapping:
  visa: 'visa',
  american_express: 'amex', // eslint-disable-line camelcase
  mastercard: 'mastercard',
  discover: 'discover',
  Mastercard: 'mastercard',
};

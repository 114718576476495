// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { get, set, setProperties, action } from '@ember/object';

export default class RecordSelectTableComponent extends Component {
  sortDirection = 'asc';

  tagName = 'table';

  classNames = ['table-fixed'];

  // eslint-disable-next-line ember/classic-decorator-hooks
  init() {
    super.init(...arguments);
    set(this, 'sortField', get(this, 'fields.0.name'));
  }

  @action
  handleSelectAll() {
    this.selectedModels.addObjects(this.models);
  }

  @action
  handleDeselectAll() {
    this.selectedModels.clear();
  }

  @action
  handleSelect(model) {
    this.selectedModels.addObject(model);
  }

  @action
  handleDeselect(model) {
    this.selectedModels.removeObject(model);
  }

  @action
  handleSort(sortField, sortDirection) {
    setProperties(this, { sortField, sortDirection });
  }
}

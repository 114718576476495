import gql from 'graphql-tag';

export default gql`
  fragment DeskFragment on Desk {
    id
    name
    floor {
      id
      name
      building
      floorPlanUrl
      floorNumber
    }
    neighborhood
    deskAvailability
    x
    y
  }
`;

import Component from '@glimmer/component';
import { isRTLLocale } from 'garaje/utils/locale-options';
import { action, set, get } from '@ember/object';
import { isBlank } from '@ember/utils';
import { localCopy } from 'tracked-toolbox';

/**
 * @param {String|required}                   selectedLocale As defined in app/utils/locale-options.js
 * @param {Array<Object>|required}            translatable An aray of objects adhereing to a translatable interface. Must have a customTranslations prop.
 * @param {boolean}                           nested used to determine indentation
 * @param {String}                            key what attr to look for in customTranslations ... its usually "name"
 */

export default class ManageTranslationsFieldRowComponent extends Component {
  @localCopy('args.translatable.customTranslations', {}) customTranslations;

  get key() {
    // args.key will be 'value' always if template is rendered recursively
    // this only happens when we render sign-in-field options for translation
    // everything else we translate is keyed on 'name' ... options though ... 🤷‍♂️
    return this.args.key || 'name';
  }

  get bestAvailableLabel() {
    const { translatable, key } = this.args;
    const preferredKeys = [key, 'localized', 'name'];

    if (!translatable) return '';

    for (let i = 0; i < preferredKeys.length; i++) {
      const label = preferredKeys[i] ? get(translatable, preferredKeys[i]) : null;

      if (label) return label;
    }

    return '';
  }

  get isDisabled() {
    if (this.args.forceDisable) {
      return true;
    }

    // If it quacks like a signinfield ...
    if ([true, false].includes(this.args.translatable.isCustom)) {
      return !this.args.translatable.isCustom;
    }

    return false;
  }

  get isRTLLocale() {
    return isRTLLocale(this.args.selectedLocale.value);
  }

  get localeValue() {
    const { key, args, customTranslations } = this;
    const { selectedLocale } = args;
    const locale = selectedLocale?.value ?? '';

    if (isBlank(locale)) {
      return '';
    }

    if (isBlank(customTranslations[key])) {
      customTranslations[key] = {};
    }

    if (isBlank(customTranslations[key][locale])) {
      customTranslations[key][locale] = '';
    }

    return customTranslations[key][locale];
  }

  changeCustomTranslationValue(value) {
    const { translatable, selectedLocale } = this.args;
    const customTranslations = translatable.customTranslations;

    set(customTranslations, `${this.key}.${selectedLocale.value}`, value);
    this.args.updateTranslatable(translatable, 'customTranslations', customTranslations);
  }

  @action
  changeValue(value) {
    this.changeCustomTranslationValue(value);
  }

  @action
  cleanValue(value = '') {
    this.changeCustomTranslationValue(`${value}`.trim());
  }
}

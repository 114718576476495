import { alias, filter } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { computed, get } from '@ember/object';
import { sort } from '@ember/object/computed';
import { APP } from 'garaje/utils/enums';
import moment from 'moment-timezone';

function withStateFunctionality(Class) {
  return class WithStateFunctionality extends Class {
    @service store;

    @tracked locationsUnsorted = [];
    @tracked allPeriods = ['monthly', 'weekly', 'yearly'];
    @tracked allPlans = [];
    @tracked locationSubscriptionEstimates = [];
    @tracked summaryOpened = false;
    @tracked paymentSource = null;

    sortAtoZ = ['nameWithCompanyName'];
    @sort('locationsUnsorted', 'sortAtoZ') allLocations;

    @alias('allLocations.firstObject') firstLocation;
    @alias('billingCart.visitors.onTrial') cartVisitorsTrial;
    @alias('billingCart.rooms.onTrial') cartRoomsTrial;
    @alias('billingCart.deliveries.onTrial') cartDeliveriesTrial;
    @alias('billingCart.deliveries.locationQuantities') cartDeliveriesLocationQuantities;
    @alias('billingCart.deliveries.subscriptionPlanId') cartDeliveriesSubscriptionPlanId;
    @alias('billingCart.editingQuantities') editingQuantities;
    @alias('billingCart.isDirty') isDirty;
    @alias('billingCart.rooms.locationQuantities') cartRoomsLocationQuantities;
    @alias('billingCart.rooms.subscriptionPlanId') cartRoomsSubscriptionPlanId;
    @alias('billingCart.sectionOpened') sectionOpened;
    @alias('billingCart.selectedPeriod') selectedPeriod;
    @alias('billingCart.visitors.locationQuantities') cartVisitorsLocationQuantities;
    @alias('billingCart.visitors.subscriptionPlanId') cartVisitorsSubscriptionPlanId;
    @alias('billingCompany.creditBalance') creditBalance;
    @alias('billingCompany.visitorsSubscriptionPlan') visitorsSubscriptionPlan;
    @alias('billingCompany.deliveriesSubscriptionPlan') deliveriesSubscriptionPlan;
    @alias('billingCompany.roomsSubscriptionPlan') roomsSubscriptionPlan;
    @alias('billingCompany.desksSubscriptionPlan') desksSubscriptionPlan;
    @alias('billingCompany.workplaceSubscriptionPlan') workplaceSubscriptionPlan;
    @alias('billingCompany.visitorsTrialEndDate') visitorsTrialEndDate;
    @alias('billingCompany.deliveriesTrialEndDate') deliveriesTrialEndDate;
    @alias('billingCompany.roomsTrialEndDate') roomsTrialEndDate;
    @alias('billingCompany.visitorsSubscriptionModification') visitorsSubscriptionModification;
    @alias('billingCompany.deliveriesSubscriptionModification') deliveriesSubscriptionModification;
    @alias('billingCompany.roomsSubscriptionModification') roomsSubscriptionModification;
    @alias('billingCompany.visitorsDowngrading') visitorsDowngrading;
    @alias('billingCompany.deliveriesDowngrading') deliveriesDowngrading;
    @alias('billingCompany.roomsDowngrading') roomsDowngrading;

    @computed('visitorsTrialEndDate')
    get visitorsTrialDaysLeft() {
      const { visitorsTrialEndDate } = this;
      if (visitorsTrialEndDate) {
        const currentDate = new Date();
        const trialEndAt = visitorsTrialEndDate || currentDate;
        return Math.max(Math.ceil(moment(trialEndAt).diff(currentDate, 'days', true)), 0);
      }
      return 0;
    }

    // DO NOT REMOVE
    deliveriesTrialDaysLeft = 0;

    @computed('roomsTrialEndDate')
    get roomsTrialDaysLeft() {
      const { roomsTrialEndDate } = this;
      if (roomsTrialEndDate) {
        const currentDate = new Date();
        const trialEndAt = roomsTrialEndDate || currentDate;
        return Math.max(Math.ceil(moment(trialEndAt).diff(currentDate, 'days', true)), 0);
      }
      return 0;
    }

    @computed('selectedPeriod')
    get currentPeriodDisplay() {
      if (this.selectedPeriod === 'yearly') {
        return 'yearly';
      } else {
        return 'monthly';
      }
    }

    @computed('selectedPeriod')
    get oppositePeriodDisplay() {
      if (this.selectedPeriod === 'monthly') {
        return 'yearly';
      } else {
        return 'monthly';
      }
    }

    @computed('allLocationSubscriptions.@each.nextBillingAt')
    get renewalDate() {
      const { allLocationSubscriptions } = this;
      if (allLocationSubscriptions && allLocationSubscriptions.length) {
        const sub = this.allLocationSubscriptions.find((sub) => !sub.onTrial && sub.nextBillingAt);
        return sub ? get(sub, 'nextBillingAt') : null;
      }
      return null;
    }

    @computed('locationSubscriptionEstimates.@each.nextBillingAt')
    get nextBillingAt() {
      const { locationSubscriptionEstimates } = this;
      return get(locationSubscriptionEstimates, 'firstObject.nextBillingAt');
    }

    @computed('locationSubscriptionEstimates.@each.invoiceEstimatesTotal')
    get invoiceEstimatesTotal() {
      const { locationSubscriptionEstimates } = this;
      const invoiceEstimatesTotals = locationSubscriptionEstimates.map((ls) => get(ls, 'invoiceEstimatesTotal'));
      if (!invoiceEstimatesTotals || !invoiceEstimatesTotals.length) {
        return 0;
      }
      return invoiceEstimatesTotals.reduce(function (a, b) {
        return a + b;
      });
    }

    @computed('locationSubscriptionEstimates.@each.invoiceEstimatesAmountDue')
    get invoiceEstimatesAmountDue() {
      const { locationSubscriptionEstimates } = this;
      const invoiceEstimatesAmountsDue = locationSubscriptionEstimates.map((ls) =>
        get(ls, 'invoiceEstimatesAmountDue'),
      );
      if (!invoiceEstimatesAmountsDue || !invoiceEstimatesAmountsDue.length) {
        return 0;
      }
      return invoiceEstimatesAmountsDue.reduce(function (a, b) {
        return a + b;
      });
    }

    _peekBillingCartPlan(app) {
      const subscriptionPlanId = this.billingCart[app].subscriptionPlanId;
      return subscriptionPlanId && this.store.peekRecord('subscription-plan', subscriptionPlanId);
    }

    @computed('cartVisitorsSubscriptionPlanId')
    get cartVisitorsSubscriptionPlan() {
      const { cartVisitorsSubscriptionPlanId } = this;
      if (!cartVisitorsSubscriptionPlanId) {
        return null;
      }
      return this.store.peekRecord('subscription-plan', cartVisitorsSubscriptionPlanId);
    }

    @computed('cartDeliveriesSubscriptionPlanId')
    get cartDeliveriesSubscriptionPlan() {
      const { cartDeliveriesSubscriptionPlanId } = this;
      if (!cartDeliveriesSubscriptionPlanId) {
        return null;
      }
      return this.store.peekRecord('subscription-plan', cartDeliveriesSubscriptionPlanId);
    }

    @computed('cartRoomsSubscriptionPlanId')
    get cartRoomsSubscriptionPlan() {
      const { cartRoomsSubscriptionPlanId } = this;
      if (!cartRoomsSubscriptionPlanId) {
        return null;
      }
      return this.store.peekRecord('subscription-plan', cartRoomsSubscriptionPlanId);
    }

    @computed('cartVisitorsLocationQuantities.@each.quantity')
    get cartVisitorsLocationQuantitiesTotal() {
      const { cartVisitorsLocationQuantities } = this;
      const quantityArray = cartVisitorsLocationQuantities.map((ls) => ls.quantity);
      if (!quantityArray || !quantityArray.length) {
        return 0;
      }
      return quantityArray.reduce(function (a, b) {
        return a + b;
      });
    }

    @computed('cartDeliveriesLocationQuantities.@each.quantity')
    get cartDeliveriesLocationQuantitiesTotal() {
      const { cartDeliveriesLocationQuantities } = this;
      const quantityArray = cartDeliveriesLocationQuantities.map((ls) => ls.quantity);
      if (!quantityArray || !quantityArray.length) {
        return 0;
      }
      return quantityArray.reduce(function (a, b) {
        return a + b;
      });
    }

    @computed('cartRoomsLocationQuantities.@each.quantity')
    get cartRoomsLocationQuantitiesTotal() {
      const { cartRoomsLocationQuantities } = this;
      const quantityArray = cartRoomsLocationQuantities.map((ls) => ls.quantity);
      if (!quantityArray || !quantityArray.length) {
        return 0;
      }
      return quantityArray.reduce(function (a, b) {
        return a + b;
      });
    }

    @computed('cartVisitorsLocationQuantitiesTotal', 'cartVisitorsSubscriptionPlan.pricePerPeriod')
    get cartVisitorsPriceTotal() {
      const { cartVisitorsLocationQuantitiesTotal } = this;
      const cost = get(this, 'cartVisitorsSubscriptionPlan.pricePerPeriod') || 0;
      return cartVisitorsLocationQuantitiesTotal * cost;
    }

    @computed('cartDeliveriesLocationQuantitiesTotal', 'cartDeliveriesSubscriptionPlan.pricePerPeriod')
    get cartDeliveriesPriceTotal() {
      const { cartDeliveriesLocationQuantitiesTotal } = this;
      const cost = get(this, 'cartDeliveriesSubscriptionPlan.pricePerPeriod') || 0;
      return cartDeliveriesLocationQuantitiesTotal * cost;
    }

    @computed('cartRoomsLocationQuantitiesTotal', 'cartRoomsSubscriptionPlan.pricePerPeriod')
    get cartRoomsPriceTotal() {
      const { cartRoomsLocationQuantitiesTotal } = this;
      const cost = get(this, 'cartRoomsSubscriptionPlan.pricePerPeriod') || 0;
      return cartRoomsLocationQuantitiesTotal * cost;
    }

    @computed('billingCompany.subscriptionPlans.@each.id')
    get period() {
      const { billingCompany } = this;
      return (
        get(billingCompany, 'visitorsSubscriptionPlan.period') ||
        get(billingCompany, 'deliveriesSubscriptionPlan.period') ||
        get(billingCompany, 'roomsSubscriptionPlan.period') ||
        'monthly'
      );
    }

    // TODO: 'selectedPeriod' isn't consumed, on purpose?
    @computed(
      'allLocations',
      'cartVisitorsLocationQuantities.@each.quantity',
      'cartDeliveriesLocationQuantities.@each.quantity',
      'cartRoomsLocationQuantities.@each.quantity',
      'cartVisitorsSubscriptionPlanId',
      'cartDeliveriesSubscriptionPlanId',
      'cartRoomsSubscriptionPlanId',
      'cartVisitorsTrial',
      'cartRoomsTrial',
      'cartDeliveriesTrial',
      'selectedPeriod',
    )
    get cartLocationSubscriptionEstimates() {
      const {
        allLocations,
        cartVisitorsTrial,
        cartRoomsTrial,
        cartDeliveriesTrial,
        cartVisitorsLocationQuantities,
        cartDeliveriesLocationQuantities,
        cartRoomsLocationQuantities,
        cartVisitorsSubscriptionPlanId,
        cartDeliveriesSubscriptionPlanId,
        cartRoomsSubscriptionPlanId,
      } = this;
      return allLocations.map((location) => {
        const visitorsQuantities = cartVisitorsLocationQuantities.find((item) => item.id === location.id);
        const deliveriesQuantities = cartDeliveriesLocationQuantities.find((item) => item.id === location.id);
        const roomsQuantities = cartRoomsLocationQuantities.find((item) => item.id === location.id);
        const products = [];
        const visitors = {};
        const deliveries = {};
        const rooms = {};
        if (visitorsQuantities && visitorsQuantities.quantity && cartVisitorsSubscriptionPlanId && !cartVisitorsTrial) {
          visitors.quantity = visitorsQuantities.quantity;
          visitors['subscription-plan-id'] = cartVisitorsSubscriptionPlanId;
          products.pushObject(visitors);
        }
        if (
          deliveriesQuantities &&
          deliveriesQuantities.quantity &&
          cartDeliveriesSubscriptionPlanId &&
          !cartDeliveriesTrial
        ) {
          deliveries.quantity = deliveriesQuantities.quantity;
          deliveries['subscription-plan-id'] = cartDeliveriesSubscriptionPlanId;
          products.pushObject(deliveries);
        }
        if (roomsQuantities && roomsQuantities.quantity && cartRoomsSubscriptionPlanId && !cartRoomsTrial) {
          rooms.quantity = roomsQuantities.quantity;
          rooms['subscription-plan-id'] = cartRoomsSubscriptionPlanId;
          products.pushObject(rooms);
        }
        return {
          location,
          products,
        };
      });
    }

    @computed('allLocationSubscriptions.length')
    get currentLocationSubscriptionEstimates() {
      const { allLocations } = this;
      return allLocations.map((location) => {
        const visitorsSubscriptionQuantity = get(location, 'visitorsSubscription.quantity');
        const deliveriesSubscriptionQuantity = get(location, 'deliveriesSubscription.quantity');
        const roomsSubscriptionQuantity = get(location, 'roomsSubscription.quantity');
        const visitorsSubscriptionPlanId = get(location, 'visitorsSubscription.subscriptionPlan.id');
        const deliveriesSubscriptionPlanId = get(location, 'deliveriesSubscription.subscriptionPlan.id');
        const roomsSubscriptionPlanId = get(location, 'roomsSubscription.subscriptionPlan.id');
        const visitorsSubscriptionNotIncluded =
          get(location, 'visitorsSubscription.cancelled') || get(location, 'visitorsSubscription.onTrial');
        const deliveriesSubscriptionNotIncluded =
          get(location, 'deliveriesSubscription.cancelled') || get(location, 'deliveriesSubscription.onTrial');
        const roomsSubscriptionNotIncluded =
          get(location, 'roomsSubscription.cancelled') || get(location, 'roomsSubscription.onTrial');
        const products = [];
        const visitors = {};
        const deliveries = {};
        const rooms = {};
        if (visitorsSubscriptionQuantity && visitorsSubscriptionPlanId && !visitorsSubscriptionNotIncluded) {
          visitors.quantity = visitorsSubscriptionQuantity;
          visitors['subscription-plan-id'] = visitorsSubscriptionPlanId;
          products.pushObject(visitors);
        }
        if (deliveriesSubscriptionQuantity && deliveriesSubscriptionPlanId && !deliveriesSubscriptionNotIncluded) {
          deliveries.quantity = deliveriesSubscriptionQuantity;
          deliveries['subscription-plan-id'] = deliveriesSubscriptionPlanId;
          products.pushObject(deliveries);
        }
        if (roomsSubscriptionQuantity && roomsSubscriptionPlanId && !roomsSubscriptionNotIncluded) {
          rooms.quantity = roomsSubscriptionQuantity;
          rooms['subscription-plan-id'] = roomsSubscriptionPlanId;
          products.pushObject(rooms);
        }
        return {
          location,
          products,
        };
      });
    }

    // TODO: Fix Format of this to match whats expected
    // TODO: 'selectedPeriod' isn't consumed, on purpose?
    @computed(
      'allLocations',
      'cartVisitorsLocationQuantities.@each.quantity',
      'cartDeliveriesLocationQuantities.@each.quantity',
      'cartRoomsLocationQuantities.@each.quantity',
      'cartVisitorsSubscriptionPlanId',
      'cartDeliveriesSubscriptionPlanId',
      'cartRoomsSubscriptionPlanId',
      'selectedPeriod',
    )
    get cartSubscriptionBatch() {
      const {
        allLocations,
        cartVisitorsLocationQuantities,
        cartDeliveriesLocationQuantities,
        cartRoomsLocationQuantities,
        cartVisitorsSubscriptionPlanId,
        cartDeliveriesSubscriptionPlanId,
        cartVisitorsTrial,
        cartRoomsTrial,
        cartDeliveriesTrial,
        cartRoomsSubscriptionPlanId,
      } = this;
      const products = [];
      const visitors = {
        'subscription-plan-id': cartVisitorsSubscriptionPlanId,
        trial: cartVisitorsTrial,
        locations: [],
      };
      const deliveries = {
        'subscription-plan-id': cartDeliveriesSubscriptionPlanId,
        trial: cartDeliveriesTrial,
        locations: [],
      };
      const rooms = {
        'subscription-plan-id': cartRoomsSubscriptionPlanId,
        trial: cartRoomsTrial,
        locations: [],
      };
      allLocations.forEach((location) => {
        const visitorsQuantities = cartVisitorsLocationQuantities.find((item) => item.id === location.id);
        if (visitorsQuantities && visitorsQuantities.quantity && cartVisitorsSubscriptionPlanId && !cartVisitorsTrial) {
          visitors.locations.push({
            id: location.id,
            quantity: visitorsQuantities.quantity,
          });
        }
        const deliveriesQuantities = cartDeliveriesLocationQuantities.find((item) => item.id === location.id);
        if (
          deliveriesQuantities &&
          deliveriesQuantities.quantity &&
          cartDeliveriesSubscriptionPlanId &&
          !cartDeliveriesTrial
        ) {
          deliveries.locations.push({
            id: location.id,
            quantity: deliveriesQuantities.quantity,
          });
        }
        const roomsQuantities = cartRoomsLocationQuantities.find((item) => item.id === location.id);
        if (roomsQuantities && roomsQuantities.quantity && cartRoomsSubscriptionPlanId && !cartRoomsTrial) {
          rooms.locations.push({
            id: location.id,
            quantity: roomsQuantities.quantity,
          });
        }
      });
      if (cartVisitorsSubscriptionPlanId) {
        products.push(visitors);
      }
      if (cartDeliveriesSubscriptionPlanId) {
        products.push(deliveries);
      }
      if (cartRoomsSubscriptionPlanId) {
        products.push(rooms);
      }
      return products;
    }

    @computed('billingCart.selectedPeriod')
    get unselectedPeriod() {
      return this.billingCart.selectedPeriod === 'monthly' ? 'yearly' : 'monthly';
    }

    @computed('allPeriods', 'unselectedPeriod')
    get allowedPeriods() {
      const { unselectedPeriod } = this;
      return this.allPeriods.filter((period) => period !== unselectedPeriod);
    }

    // ALL Subscription Plans TODO: we should rename to availabile plans
    @filter('allPlans', function (plan) {
      return plan.app === APP.VISITORS && plan.available;
    })
    visitorsPlans; // TODO: we should rename to availableVisitorsPlans

    @filter('allPlans', function (plan) {
      return plan.app === APP.DELIVERIES && plan.available;
    })
    deliveriesPlans; // TODO: we should rename to availableDeliveriesPlans

    @filter('allPlans', function (plan) {
      return plan.app === APP.ROOMS && plan.available;
    })
    roomsPlans; // TODO: we should rename to availableRoomsPlans

    // CART Subscription Plans
    @computed('billingCart.visitors.subscriptionPlanId')
    get cartVisitorsPlan() {
      return this._peekBillingCartPlan('visitors');
    }

    @computed('billingCart.deliveries.subscriptionPlanId')
    get cartDeliveriesPlan() {
      return this._peekBillingCartPlan('deliveries');
    }

    @computed('billingCart.rooms.subscriptionPlanId')
    get cartRoomsPlan() {
      return this._peekBillingCartPlan('rooms');
    }

    @computed('billingCompany.visitorsSubscriptionPlan')
    get initialVisitorsSubscriptionPlan() {
      return get(this, 'billingCompany.visitorsSubscriptionPlan');
    }

    @computed('billingCompany.deliveriesSubscriptionPlan')
    get initialDeliveriesSubscriptionPlan() {
      return get(this, 'billingCompany.deliveriesSubscriptionPlan');
    }

    @computed('billingCompany.roomsSubscriptionPlan')
    get initialRoomsSubscriptionPlan() {
      return get(this, 'billingCompany.roomsSubscriptionPlan');
    }
  };
}

export default withStateFunctionality;

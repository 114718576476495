// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { htmlSafe } from '@ember/template';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed, get } from '@ember/object';

const INTEGRATIONS = [
  { name: 'Google Hangout / XMPP', type: 'Notification' },
  { name: 'Microsoft Lync', type: 'Notification' },
  { name: 'A phone call', type: 'Notification' },
  { name: 'Eventbrite', type: 'Event' },
  { name: 'Meetup', type: 'Event' },
  { name: 'LDAP', type: 'Employee Directory' },
  { name: 'Workday', type: 'Employee Directory' },
  { name: 'Google Calendar', type: 'Pre-registration' },
  { name: 'Other', type: 'Other' },
];

export default class IntegrationRequest extends Component {
  tagName = '';
  selected = '';
  integrations = INTEGRATIONS;

  @computed('selected')
  get href() {
    let subject = 'Integration Request';
    let body = 'Tell us a bit about how you want to use Envoy';
    const other = 'What integration do you want next? Tell us a bit about how you want to use it with Envoy.';

    if (this.selected && this.selected !== 'Other') {
      subject = `${subject}: ${get(this.selected, 'name')}`;
      body = `${body} with ${get(this.selected, 'name')}.`;
    } else {
      body = `${other}.`;
    }

    return htmlSafe(`mailto:feedback@envoy.com?subject=${subject}&body=${body}`);
  }
}

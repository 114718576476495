import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';

const BLUETOOTH_CONNECTION_TYPE = [{ label: 'Bluetooth', value: 'bluetooth' }];

const CONNECTION_TYPES = [
  { label: 'Wi-Fi', value: 'ip' },
  { label: 'Ethernet', value: 'ip' },
];

/**
 * @param {Boolean}             bluetoothEnabled        set to true if the given printer support bluetooth
 * @param {Object}              connectionType          selected connection type
 * @param {Function}            changeConnectionType    action to change connection type
 */
export default class PrinterConnectionTypeSelect extends Component {
  @tracked selectedConnectionType;

  elementId = guidFor(this);

  constructor() {
    super(...arguments);
    this.selectedConnectionType = this.args.connectionType;
  }

  get connectionTypes() {
    // do not include BT connection options if bluetoothEnabled is true
    if (this.args.bluetoothEnabled) {
      return CONNECTION_TYPES.concat(BLUETOOTH_CONNECTION_TYPE);
    }

    return CONNECTION_TYPES;
  }
}

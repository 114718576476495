import { htmlSafe } from '@ember/template';
import type { SafeString } from '@ember/template/-private/handlebars';
import Component from '@glimmer/component';
import RecurringRule, { byDayValue } from 'garaje/models/recurring-rule';
import type VfdScheduleModel from 'garaje/models/vfd-schedule';
import { type VfdScheduleRule } from 'garaje/models/vfd-schedule';
import dayName from 'garaje/utils/day-name';
import { Day } from 'garaje/utils/enums';
import SimpleTime from 'garaje/utils/simple-time';
import type TimeRange from 'garaje/utils/time-range';
import { cached } from 'tracked-toolbox';

const MINUTES_IN_DAY = 24 * 60;

interface VirtualFrontDeskSettingsHoursOfOperationsGridDaySignature {
  Args: {
    alwaysOpen?: boolean; // when truthy and @day is not provided, show "24/7"
    day?: Day; // if no specific day is present, treat this as covering ALL days
    schedule: VfdScheduleModel;
  };
}

type IntervalAndStyle = {
  interval: TimeRange;
  style: SafeString;
};

export default class VirtualFrontDeskSettingsHoursOfOperationsGridDay extends Component<VirtualFrontDeskSettingsHoursOfOperationsGridDaySignature> {
  @cached
  get alwaysOpen(): boolean {
    const schedule = this.scheduleForDay;
    if (schedule.intervals.length !== 1) return false;
    return (
      schedule.intervals[0]!.from!.isSame(SimpleTime.StartOfDayMidnight) &&
      schedule.intervals[0]!.to!.isSame(SimpleTime.EndOfDayMidnight)
    );
  }

  @cached
  get dayName(): string {
    switch (this.args.day) {
      case Day.Sunday:
      case Day.Monday:
      case Day.Tuesday:
      case Day.Wednesday:
      case Day.Thursday:
      case Day.Friday:
      case Day.Saturday:
        return dayName(this.args.day);
      default:
        return 'Mon–Sun';
    }
  }

  @cached
  get isClosed(): boolean {
    return !this.scheduleForDay.enabled;
  }

  @cached
  get scheduleForDay(): VfdScheduleRule {
    // This is built on the assumption that the backend will always return a full set of days.
    return this.args.schedule.rules.find((scheduleRule) => {
      const parsedRule = RecurringRule.parse(scheduleRule.recurringRule);
      return parsedRule.byDay === this._dayAbbreviation;
    })!;
  }

  @cached
  get _dayAbbreviation(): string {
    switch (this.args.day) {
      case Day.Sunday:
      case Day.Monday:
      case Day.Tuesday:
      case Day.Wednesday:
      case Day.Thursday:
      case Day.Friday:
      case Day.Saturday:
        return byDayValue(this.args.day);
      default:
        return 'XX';
    }
  }

  get intervalsWithStyle(): IntervalAndStyle[] {
    const intervals: IntervalAndStyle[] = [];
    for (const interval of this.scheduleForDay.intervals.filter((interval) => interval.isValid)) {
      const start = interval.from!.asMinutes();
      const duration = interval.duration;

      // Figure out what percentage of the day is present _before_ the range (`offset`)
      // and what percentage of the day is covered by  the range (`width`). These are used
      // to provide relative positioning and sizing for the bar for the range.
      const offset = (start / MINUTES_IN_DAY) * 100;
      const width = (duration / MINUTES_IN_DAY) * 100;

      intervals.push({
        interval,
        style: htmlSafe(`left: ${offset}%; width: ${width}%`),
      });
    }
    return intervals;
  }

  formatTimeForTooltip(time: SimpleTime): string {
    if (time.isSame(SimpleTime.StartOfDayMidnight) || time.isSame(SimpleTime.EndOfDayMidnight)) return '12:00 am';
    const amPm = time.hour >= 12 ? 'pm' : 'am';
    const hour = time.hour > 12 ? time.hour - 12 : time.hour;
    return `${hour}:${time.minute.toString().padStart(2, '0')} ${amPm}`;
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

/**
 * @param {Class<TicketConfiguration>}  ticketConfiguration         Required - Ticket configuration model
 * @param {Array<TicketCategories>}     ticketCategories            Required - Exisitng categories
 * @param {Task}                        showCreateTicketModalTask   Required - Task that controls the modal
 */
export default class CreateCategoryModalComponent extends Component {
  @service store;
  @service metrics;
  @service flashMessages;

  @tracked newCategoryName = '';
  @tracked categoryNameError = [];

  @action
  inputInserted(element) {
    element.focus();
  }

  @dropTask
  *createCategoryTask(closeModal) {
    const { showCreateTicketModalTask, ticketConfiguration } = this.args;

    if (!this.newCategoryName) {
      this.categoryNameError = ['Please enter a category name'];
    } else if (this.isDuplicateName()) {
      this.categoryNameError = ['Category name already exists'];
    }

    if (!this.categoryNameError.length) {
      const ticketCategory = this.store.createRecord('ticket-category', {
        ticketConfiguration,
        name: this.newCategoryName,
      });
      try {
        yield ticketCategory.save();

        this.metrics.trackEvent('Ticketing category created', {
          locationId: get(ticketConfiguration, 'location.id'),
          categoryName: this.newCategoryName,
        });

        this.flashMessages.showAndHideFlash('success', 'Category created successfully!');
      } catch (e) {
        this.flashMessages.showAndHideFlash('error', 'Error', parseErrorForDisplay(e));
      }

      this.newCategoryName = '';
      this.categoryNameError = [];
      if (closeModal) {
        showCreateTicketModalTask.last.continue();
      }
    }
  }

  @dropTask
  *createCategoryAndCloseTask(e) {
    e.preventDefault();
    yield this.createCategoryTask.perform(true);
  }

  @action
  onCancel() {
    const { showCreateTicketModalTask } = this.args;
    this.newCategoryName = '';
    this.categoryNameError = [];
    showCreateTicketModalTask.last.abort();
  }

  @action
  onCategoryNameChange(name) {
    this.newCategoryName = name;
    if (this.isDuplicateName()) {
      this.categoryNameError = ['Category name already exists'];
    } else {
      this.categoryNameError = [];
    }
  }

  isDuplicateName() {
    const { ticketCategories } = this.args;
    return ticketCategories.any((category) => category.name.toLowerCase() === this.newCategoryName.toLowerCase());
  }
}

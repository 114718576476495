import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

class DevDashboardRoute extends Route {
  @service currentAdmin;
  @service router;

  titleToken = 'Dev Dashboard';

  beforeModel() {
    this.redirectForNonGlobalAdmins();
  }

  redirectForNonGlobalAdmins() {
    if (!this.currentAdmin.isGlobalAdmin) {
      this.router.transitionTo('apps');
    }
  }
}

export default DevDashboardRoute;

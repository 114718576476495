import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { Changeset } from 'ember-changeset';
import { type DetailedChangeset } from 'ember-changeset/types';
import type DeviceConfigStaticPageAttributeModel from 'garaje/models/device-config-static-page-attribute';
import PropertyAssistanceScreenModel from 'garaje/models/property-assistance-screen';
import type ZoneModel from 'garaje/models/zone';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type LoggerService from 'garaje/services/logger';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';

import { type PropertySettingsKioskRouteModel } from '../route';

export interface AssistanceScreenRouteModel {
  property: ZoneModel;
  assistancePageAttributeChangeset?: DetailedChangeset<DeviceConfigStaticPageAttributeModel>;
}

export default class PropertySettingsKioskAssistanceScreenRoute extends Route {
  @service declare flashMessages: FlashMessagesService;
  @service declare logger: LoggerService;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare transitionConfirm: TransitionConfirmService;

  model(): AssistanceScreenRouteModel | undefined {
    const property = <ZoneModel>this.modelFor('property');

    let assistancePageAttributeChangeset: DetailedChangeset<DeviceConfigStaticPageAttributeModel> | undefined;

    const { deviceConfigStaticPages } = <PropertySettingsKioskRouteModel>this.modelFor('property.settings.kiosk');

    deviceConfigStaticPages.forEach((page) => {
      if (page instanceof PropertyAssistanceScreenModel)
        assistancePageAttributeChangeset = Changeset(page.deviceConfigStaticPageAttributes.firstObject);
    });

    return {
      property,
      assistancePageAttributeChangeset,
    };
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    // don't do anything when entering this route
    if (transition.to.name === this.routeName) return;

    const { assistancePageAttributeChangeset } = <AssistanceScreenRouteModel>this.modelFor(this.routeName);

    const attributePageDirty =
      assistancePageAttributeChangeset?.isDirty ||
      assistancePageAttributeChangeset?.deviceConfigStaticPageAttributeTranslations.any(
        (page) => !!page.hasDirtyAttributes,
      );

    if (!attributePageDirty) return;

    // otherwise, prompt user for confirmation before leaving the page
    void this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        assistancePageAttributeChangeset?.rollback();
        assistancePageAttributeChangeset?.deviceConfigStaticPageAttributeTranslations.invoke('rollbackAttributes');
      },
    });
  }
}

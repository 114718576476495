import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { dropTask } from 'ember-concurrency';
import type PrinterConnection from 'garaje/models/printer-connection';
import type StateService from 'garaje/services/state';

export default class PropertyDevicesPrintersConnectionsNewController extends Controller {
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: Store;

  @action
  handlePasscodeConsumed(printerConnection: PrinterConnection): void {
    void this.router.transitionTo('property.devices.printers.connections.show', printerConnection.id);
  }

  fetchOneTimePasscodeTask = dropTask(async () => {
    const user = this.state.currentUser;
    const zone = this.model;

    if (!user || !zone) return;

    const printerPasscode = this.store.createRecord('printer-passcode', {
      user,
      zone,
    });

    await printerPasscode.save();

    return printerPasscode;
  });
}

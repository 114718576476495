import CompanyFragment from 'garaje/graphql/fragments/roomba/CompanyFragment';
import ConnectionFragment from 'garaje/graphql/fragments/roomba/ConnectionFragment';
import gql from 'graphql-tag';

export default gql`
  mutation UnlinkConnection($connectionId: ID!, $shouldDeleteAssociatedRooms: Boolean!) {
    unlinkConnection(connectionId: $connectionId, shouldDeleteAssociatedRooms: $shouldDeleteAssociatedRooms) {
      ...CompanyFragment
      connections {
        ...ConnectionFragment
      }
    }
  }
  ${CompanyFragment}
  ${ConnectionFragment}
`;

import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AnnouncementsIndexRoute extends Route {
  title = 'Protect · Announcements · Envoy';

  @service abilities;
  @service store;
  @service state;
  @service router;

  beforeModel() {
    if (this.abilities.can('visit workplace')) {
      return this.router.transitionTo('workplace.announcements');
    }
    if (this.abilities.cannot('visit announcements for protect')) {
      this.router.transitionTo('protect');
    }
  }

  async model() {
    const { currentLocation } = this.state;
    const adapterOptions = { locationId: currentLocation.id };

    const announcements = await this.store.findAll('announcement', { adapterOptions, reload: true });

    return {
      announcements,
    };
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class ContactBDRBanner extends Component {
  @tracked showContactModal = false;
  @tracked formSubmitted = false;

  @action
  trackFormSubmitted() {
    this.formSubmitted = true;
  }
}

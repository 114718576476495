import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type MetricsService from 'garaje/services/metrics';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import type WorkplaceRouteConfigService from 'garaje/services/workplace-route-config';

class WorkplaceIndexRoute extends Route {
  @service declare metrics: MetricsService;
  @service declare router: RouterService;
  @service declare workplaceMetrics: WorkplaceMetricsService;
  @service declare workplaceRouteConfig: WorkplaceRouteConfigService;

  title = 'Workplace · Envoy';

  beforeModel(): void {
    this.workplaceMetrics.trackEvent('WORKPLACE_ROUTE_VISITED');

    const routeConfig = this.workplaceRouteConfig.computeWorkplaceRouteConfig();
    switch (routeConfig.type) {
      case 'off':
        void this.router.transitionTo('dashboard');
        return;
      case 'start-trial':
        void this.router.transitionTo('workplace.start-trial');
        return;
      case 'legacy-protect':
        if (!routeConfig.canVisitSettings) {
          void this.router.transitionTo('workplace.entries.index');
          return;
        }
        void this.router.transitionTo('workplace.settings.employees.registration');
        return;
      case 'subscribed':
        if (!routeConfig.canVisitSetupGuide) {
          void this.router.transitionTo('workplace.entries.index');
          return;
        }
        void this.router.transitionTo('workplace.setup-guide');
        return;
    }
  }
}
export default WorkplaceIndexRoute;

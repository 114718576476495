import Component from '@glimmer/component';
import { service } from '@ember/service';
import { alias } from 'macro-decorators';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import moment from 'moment-timezone';

export default class WorkplaceTrialEndCard extends Component {
  @service abilities;
  @service metrics;
  @service state;
  @service impressions;
  @service router;
  @service statsig;

  @alias('state.workplaceSubscription') subscription;

  get isBillingAdmin() {
    return this.abilities.can('visit billing');
  }

  get ctaEventProperties() {
    return {
      cta_id: this.ctaId || 'growth-workplace-trial-end-interstitial',
      cta_type: 'card',
      growth_team_project: true,
    };
  }

  get ctaId() {
    if (!this.isBillingAdmin) {
      return 'growth-workplace-trial-end-interstitial_non-billing-admin';
    }

    return `growth-workplace-trial-end-interstitial`;
  }

  get eventMetadata() {
    const trialExpiredDuration = this.subscription.trialEndDate
      ? moment.duration(moment().diff(this.subscription.trialEndDate)).days()
      : null;
    const onExpiredTrial = this.subscription.onExpiredTrial;
    return {
      onExpiredTrial,
      trialExpiredDuration: onExpiredTrial ? trialExpiredDuration : null,
    };
  }

  @action
  logCTAClicked(event) {
    const buttonText = event.target.textContent.trim();
    this.metrics.trackEvent('CTA Clicked', {
      ...this.ctaEventProperties,
      cta_clickable_type: 'button',
      cta_clickable_text: buttonText,
    });

    this.statsig.logEvent('workplace-trial-end-page-button-clicked', buttonText, this.eventMetadata);

    return event;
  }

  @action
  logCTAViewed() {
    this.metrics.trackEvent('CTA Viewed', this.ctaEventProperties);
    this.statsig.logEvent('workplace-trial-end-page-viewed', null, this.eventMetadata);
  }

  logToGrowthServiceTask = task({ drop: true }, async (action) => {
    await this.impressions.postImpression.perform(IMPRESSION_NAMES.WORKPLACE_TRIAL_END_MODAL[action.toUpperCase()]);
  });

  @action
  redirectToChoosePlan() {
    this.router.transitionTo('billing.product-plans', 'workplace');
  }

  @action redirectToComparePlans() {
    window.open('https://envoy.com/pricing/#workplace-section', '_blank');
  }
}

import DeliveriesSerializer from './deliveries';

export default class DeliveryDeviceSerializer extends DeliveriesSerializer {
  attrs = {
    name: {
      serialize: false,
    },
    osVersion: {
      serialize: false,
    },
    maximumOsVersion: {
      serialize: false,
    },
    deviceType: {
      serialize: false,
    },
    appVersion: {
      serialize: false,
    },
    currentAppVersion: {
      serialize: false,
    },
  };
}

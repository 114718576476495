import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import { BILLING, GLOBAL_ADMIN } from 'garaje/utils/roles';
import { Permission } from 'garaje/utils/ui-permissions';
import _intersection from 'lodash/intersection';

const CAN_UPDATE_ROLES = [BILLING, GLOBAL_ADMIN];

export default class BillingAbility extends Ability {
  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;

  get canUpdate(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_UPDATE_ROLES, roleNames));
  }

  get canVisit(): boolean {
    return this.authz.hasPermissionAtCurrentCompany(Permission.ACCOUNT_MANAGEMENT_BILLING_SETTINGS_MANAGE);
  }

  get canStartWorkplaceTrial(): boolean {
    if (this.featureFlags.isEnabled('desks-authz-spaces3')) {
      return this.authz.hasPermissionAtCurrentCompany(Permission.ACCOUNT_MANAGEMENT_BILLING_WORKPLACE_TRIAL_CREATE);
    }

    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_UPDATE_ROLES, roleNames));
  }
}

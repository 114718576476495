"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalOverrideAdapter = void 0;
const LOCAL_OVERRIDE_REASON = 'LocalOverride';
function _makeEmptyStore() {
    return {
        gate: {},
        dynamicConfig: {},
        experiment: {},
        layer: {},
    };
}
class LocalOverrideAdapter {
    constructor() {
        this._overrides = _makeEmptyStore();
    }
    overrideGate(name, value) {
        this._overrides.gate[name] = value;
    }
    removeGateOverride(name) {
        delete this._overrides.gate[name];
    }
    getGateOverride(current, _user) {
        const overridden = this._overrides.gate[current.name];
        if (overridden == null) {
            return null;
        }
        return Object.assign(Object.assign({}, current), { value: overridden, details: Object.assign(Object.assign({}, current.details), { reason: LOCAL_OVERRIDE_REASON }) });
    }
    overrideDynamicConfig(name, value) {
        this._overrides.dynamicConfig[name] = value;
    }
    removeDynamicConfigOverride(name) {
        delete this._overrides.dynamicConfig[name];
    }
    getDynamicConfigOverride(current, _user) {
        return this._getConfigOverride(current, this._overrides.dynamicConfig);
    }
    overrideExperiment(name, value) {
        this._overrides.experiment[name] = value;
    }
    removeExperimentOverride(name) {
        delete this._overrides.experiment[name];
    }
    getExperimentOverride(current, _user) {
        return this._getConfigOverride(current, this._overrides.experiment);
    }
    overrideLayer(name, value) {
        this._overrides.layer[name] = value;
    }
    removeLayerOverride(name) {
        delete this._overrides.layer[name];
    }
    getAllOverrides() {
        return JSON.parse(JSON.stringify(this._overrides));
    }
    removeAllOverrides() {
        this._overrides = _makeEmptyStore();
    }
    getLayerOverride(current, _user) {
        const overridden = this._overrides.layer[current.name];
        if (overridden == null) {
            return null;
        }
        return Object.assign(Object.assign({}, current), { __value: overridden, details: Object.assign(Object.assign({}, current.details), { reason: LOCAL_OVERRIDE_REASON }) });
    }
    _getConfigOverride(current, lookup) {
        const overridden = lookup[current.name];
        if (overridden == null) {
            return null;
        }
        return Object.assign(Object.assign({}, current), { value: overridden, details: Object.assign(Object.assign({}, current.details), { reason: LOCAL_OVERRIDE_REASON }) });
    }
}
exports.LocalOverrideAdapter = LocalOverrideAdapter;

import CompanyFragment from 'garaje/graphql/fragments/roomba/CompanyFragment';
import ConnectionFragment from 'garaje/graphql/fragments/roomba/ConnectionFragment';
import LocationFragment from 'garaje/graphql/fragments/roomba/LocationFragment';
import gql from 'graphql-tag';

export default gql`
  query GetCompany {
    company {
      ...CompanyFragment
      connections {
        ...ConnectionFragment
      }
      locations {
        ...LocationFragment
      }
    }
  }
  ${CompanyFragment}
  ${ConnectionFragment}
  ${LocationFragment}
`;

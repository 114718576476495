import { helper } from '@ember/component/helper';
import { ZONE_SECURITY, ZONE_ADMIN } from 'garaje/utils/roles';

export function convertDisplayRoleName([roleName]) {
  let role = roleName;

  switch (role) {
    case 'Receptionist':
      role = 'Front Desk Admin';
      break;
    case 'Security':
      role = 'Security Admin';
      break;
    case 'Billing':
      role = 'Billing Admin';
      break;
    case ZONE_SECURITY:
      role = 'Property Security Admin';
      break;
    case ZONE_ADMIN:
      role = 'Property Admin';
      break;
  }
  return role;
}

export default helper(convertDisplayRoleName);

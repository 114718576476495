import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { keepLatestTask } from 'ember-concurrency';
import { type PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type AnnouncementRecipient from 'garaje/models/announcement-recipient';
import { TrackedArray } from 'tracked-built-ins';

type AnnouncementRecipientListDataArgs = {
  filters: { [key: string]: string };
  announcementId: string;
  sortBy: string;
  sortDirection: 'asc' | 'desc';
};

const PAGE_SIZE = 20;
const DEFAULT_SORT = 'name';

export default class AnnouncementRecipientListData extends Component<AnnouncementRecipientListDataArgs> {
  @service declare store: Store;

  @tracked recipients: TrackedArray<AnnouncementRecipient> = new TrackedArray();

  #currentOffset = 0;
  #totalRecipients = 0;

  constructor(owner: unknown, args: AnnouncementRecipientListDataArgs) {
    super(owner, args);

    void this.loadRecipients.perform(false);
  }

  get sort(): string {
    const sortField = this.args.sortBy || DEFAULT_SORT;
    return this.args.sortDirection === 'desc' ? `-${sortField}` : sortField;
  }

  loadRecipients = keepLatestTask(async (reset: boolean) => {
    if (reset) {
      this.recipients = new TrackedArray();
      this.#currentOffset = 0;
      this.#totalRecipients = 0;
    }

    const recipients = <PaginatedRecordArray<AnnouncementRecipient>>await this.store.query('announcement-recipient', {
      filter: {
        announcement: this.args.announcementId,
        ...this.args.filters,
      },
      page: {
        limit: PAGE_SIZE,
        offset: this.#currentOffset,
      },
      sort: this.sort,
      // TODO: filter sent status based on channel
      // include: 'announcement-recipient-channels',
    });

    const recipientsArray = recipients.toArray();

    this.#currentOffset += recipientsArray.length;
    this.#totalRecipients = recipients.meta.total;

    this.recipients.push(...recipientsArray);
  });

  get hasMorePages(): boolean {
    return this.recipients.length < this.#totalRecipients;
  }

  @action
  reloadData(): void {
    void this.loadRecipients.perform(true);
  }

  @action
  loadMore(): void {
    if (this.hasMorePages) {
      void this.loadRecipients.perform(false);
    }
  }
}

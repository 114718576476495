import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ChangelogsRoute extends Route {
  @service abilities;
  @service state;
  @service router;

  beforeModel() {
    const isEnterprisePlan = get(this, 'state.vrSubscription.isEnterprisePlan');

    if (!isEnterprisePlan || this.abilities.cannot('visit changelog')) {
      return this.router.transitionTo('/');
    }
  }
}

import RMSAdapter from 'garaje/adapters/rms';

/***
 This adapter essentially appends the includes query param to the PATCH /desks/:id request. This is needed because the
 regular JSONAPIAdapter does not support this query param for save requests (link). I considered moving this up into the
 RMSAdapater, but it's a quite unusual pattern so I wanted to keep it isolated unless it comes up as a requirement again,
 in which case I will likely get this of this adapter and move this buildUrl function into the RmsAdapter.
 ***/
export default class DeskAdapter extends RMSAdapter {
  buildURL(modelName, id, snapshot, requestType, query) {
    let url = super.buildURL(modelName, id, snapshot, requestType, query);

    const include = snapshot?.adapterOptions?.include;
    if (include) {
      const separator = url.includes('?') ? '&' : '?';
      url += `${separator}include=${encodeURIComponent(include)}`;
    }
    return url;
  }
}

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { localCopy } from 'tracked-toolbox';

/**
 * @param {Function} onConfirm
 * @param {Function} onCancel
 * @param {Function} onClose
 * @param {String}   title
 * @param {String}   ctaText
 * @param {String}   cancelText
 * @param {Boolean}  canDismiss
 * @param {Object}   ctaEventProperties
 */
export default class CtaColModal extends Component {
  @service metrics;

  @localCopy('args.canDismiss', true) canDismiss;

  @action
  logClicked(event) {
    if (this.args.ctaEventProperties) {
      const buttonText = event.target.textContent.trim();
      this.metrics.trackEvent('CTA Clicked', {
        ...this.args.ctaEventProperties,
        cta_type: 'modal',
        cta_clickable_type: 'button',
        cta_clickable_text: buttonText,
      });
    }
  }

  @action
  logViewed() {
    if (this.args.ctaEventProperties) {
      this.metrics.trackEvent('CTA Viewed', {
        ...this.args.ctaEventProperties,
        cta_type: 'modal',
      });
    }
  }

  @action
  logDismissed() {
    if (this.args.ctaEventProperties) {
      this.metrics.trackEvent('CTA Dismissed', {
        ...this.args.ctaEventProperties,
        cta_type: 'modal',
        cta_clickable_type: 'button',
      });
    }
  }
}

import Service from '@ember/service';

export default class WindowLocationService extends Service {
  assign(url: string): void {
    window.location.assign(url);
  }

  reload(): void {
    window.location.reload();
  }

  replace(url: string): void {
    window.location.replace(url);
  }

  get href(): string {
    return window.location.href;
  }
}

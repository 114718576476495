import Component from '@glimmer/component';
import { get, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

/**
 * @param {Array}            group
 * @param {Array}            selectedChildren
 */
export default class PropagableSettingsChangedModalFlowsSearcherGroup extends Component {
  @tracked showingAll = false;

  get allSelected() {
    const { selectedChildren, group } = this.args;
    const flows = get(group, 'items');
    return selectedChildren && flows.every((flow) => selectedChildren.includes(flow));
  }

  @action
  toggleFlow(flow) {
    const selectedChildren = this.args.selectedChildren;

    if (selectedChildren.includes(flow)) {
      selectedChildren.removeObject(flow);
    } else {
      selectedChildren.addObject(flow);
    }
  }

  @action
  toggleAllSelected() {
    const { selectedChildren, group } = this.args;
    const flows = get(group, 'items');

    if (this.allSelected) {
      selectedChildren.removeObjects(flows);
    } else {
      flows.forEach((flow) => selectedChildren.addObject(flow));
    }
  }
}

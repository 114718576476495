import Component from '@glimmer/component';

/**
 * @param {Array}           notifications
 */
export default class MultipleRecipient extends Component {
  get categoryRollup() {
    return this.args.notifications.reduce((categories, notification) => {
      if (!categories[notification.title]) {
        categories[notification.title] = { total: 0, sent: 0, sending: 0, failed: 0, skipped: 0 };
      }
      categories[notification.title].total++;

      if (notification.done) categories[notification.title].sent++;
      if (notification.inProgress || notification.queued) categories[notification.title].sending++;
      if (notification.failed || notification.expired) categories[notification.title].failed++;
      if (notification.ignored) categories[notification.title].skipped++;

      return categories;
    }, {});
  }
}

import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import type StateService from 'garaje/services/state';
import { Permission } from 'garaje/utils/ui-permissions';

export default class ExportAnalyticAbility extends Ability {
  @service declare state: StateService;
  @service declare authz: AuthzService;

  get canExportVisitorLog(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_ENTRY_EXPORT);
  }

  get canExportGlobalVisitorLog(): boolean {
    return this.authz.hasPermissionAtCurrentCompany(Permission.VISITORS_ENTRY_EXPORT);
  }

  get canExportInvites(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_INVITE_EXPORT);
  }
}

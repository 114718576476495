import _defaults from 'lodash/defaults';
import Report from 'garaje/models/report';

export default class VisitorDocumentScreening extends Report {
  constructor(attrs = {}) {
    super(...arguments);
    const props = { ...attrs };
    _defaults(props, {
      reviewable: true,
      reason: null,
    });
    Object.assign(this, props);
  }
}

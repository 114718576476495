import Model, { attr, belongsTo } from '@ember-data/model';
import type LocationModel from 'garaje/models/location';
import adapter from 'garaje/utils/decorators/adapter';

const DEFAULT_SIGN_IN_REMINDER_MINUTES = 540;
const DEFAULT_SIGN_OUT_REMINDER_MINUTES = 1020;

@adapter('v3')
class EmployeeRegistrationConfigurationModel extends Model {
  // Protect / Workplace Settings: sign-in/sign-out push notification reminders
  @attr('boolean', { defaultValue: true }) declare badgeSignInNotificationEnabled: boolean;
  @attr('boolean', { defaultValue: true }) declare signInReminderEnabled: boolean;
  @attr('boolean', { defaultValue: true }) declare signOutReminderEnabled: boolean;
  @attr('number', { defaultValue: DEFAULT_SIGN_IN_REMINDER_MINUTES })
  declare signInReminderAtMinutesSinceMidnight: number; // Default: 9am
  @attr('number', { defaultValue: DEFAULT_SIGN_OUT_REMINDER_MINUTES })
  declare signOutReminderAtMinutesSinceMidnight: number; // Default: 5pm
  @attr('boolean', { defaultValue: true }) declare eaDelegatedBookingEnabled: boolean;

  @belongsTo('skinny-location', { async: false }) declare location: LocationModel;
}

export default EmployeeRegistrationConfigurationModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'employee-registration-configuration': EmployeeRegistrationConfigurationModel;
  }
}

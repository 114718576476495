import { pluralize } from 'ember-inflector';

export function customPluralize(type: string): string {
  if (type === 'cafe') {
    return 'cafes';
  }
  // Do not pluralize custom types
  if (type === 'custom') {
    return 'custom';
  }
  return pluralize(type);
}

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import { isPresent } from '@ember/utils';

export default class WorkplaceBundleBanner extends Component {
  @service state;
  @service router;
  @service featureFlags;
  @service impressions;
  @service statsig;

  @tracked shouldShowBanner = false;
  @tracked actionLinks = [
    {
      title: 'View comprehensive attendance data',
      subtext: 'Passively log employee attendance data',
      imagePath: '/assets/images/workplace-bundle/attendance-data-icon.svg',
      type: 'link',
      contentUrl: 'https://envoy.help/en/articles/4121489-employee-log?utm_campaign=wpb&utm_source=dashboard',
      eventName: 'attendance_data',
    },
    {
      title: 'Send emergency notifications',
      subtext: 'Alert employees that are on-site',
      imagePath: '/assets/images/workplace-bundle/emergency-notifications-icon.svg',
      type: 'link',
      contentUrl:
        'https://envoy.help/en/articles/8391766-emergency-notifications?utm_campaign=wpb&utm_source=dashboard',
      eventName: 'emergency_notifications',
    },
    {
      title: 'Analyze occupancy trends',
      subtext: 'Drive decisions with reliable data',
      imagePath: '/assets/images/workplace-bundle/occupancy-trends-icon.svg',
      type: 'modal',
      contentUrl: 'https://envoy.navattic.com/kpgv0r7e',
      eventName: 'occupancy_trends',
    },
    {
      title: 'Manage deliveries',
      subtext: 'Automatically notify employees',
      imagePath: '/assets/images/workplace-bundle/manage-deliveries-icon.svg',
      type: 'link',
      contentUrl: 'https://envoy.help/en/collections/1993107-deliveries?utm_campaign=wpb&utm_source=dashboard',
      eventName: 'manage_deliveries',
    },
  ];

  constructor() {
    super(...arguments);
    this.checkShouldShowBanner().then(() => {
      if (this.shouldShowBanner) {
        this.statsig.logEvent('homepage_workplace_bundle_banner_viewed');
      }
    });
  }

  async checkShouldShowBanner() {
    if (
      this.args.subscription?.subscribed &&
      this.args.subscription?.freeBundle &&
      this.featureFlags.isEnabled('growth_workplace_free_bundle')
    ) {
      const closedImpressions = await this.impressions.getImpressions.perform(
        IMPRESSION_NAMES.WORKPLACE_FREE_BUNDLE_BANNER_DISMISSED,
      );
      const hasBeenDismissed = isPresent(closedImpressions);
      if (!hasBeenDismissed) {
        this.shouldShowBanner = true;
      }
    }
  }

  get calculatedValue() {
    // 7$ * 12 mo. * 100 cents
    return this.args.subscription?.quantity * 8400;
  }

  @action
  async handleClose() {
    await this.impressions.postImpression.perform(IMPRESSION_NAMES.WORKPLACE_FREE_BUNDLE_BANNER_DISMISSED);
    this.shouldShowBanner = false;
  }

  @action
  handleActionClicked(url, type, eventName) {
    this.statsig.logEvent('homepage_workplace_bundle_banner_link_clicked', eventName);
    if (type === 'link') {
      window.open(url, '_blank');
    } else if (type === 'modal') {
      this.args.openDemo(url);
    } else if (type === 'route') {
      this.router.transitionTo(url);
    }
  }
}

import DeviceFragment from 'garaje/graphql/fragments/roomba/DeviceFragment';
import gql from 'graphql-tag';

export default gql`
  mutation ApproveDeviceUserCode($userCode: String!, $roomId: String!) {
    approveDeviceUserCode(userCode: $userCode, roomId: $roomId) {
      id
      interval
      expiresAt
      deviceId
      device {
        ...DeviceFragment
      }
    }
  }
  ${DeviceFragment}
`;

import GrowthAdapter from 'garaje/adapters/growth';

export default class CompanyInfoAdapter extends GrowthAdapter {
  urlForQueryRecord(query, modelName) {
    if (Object.hasOwn(query, 'id') && Object.keys(query).length === 1) {
      // If the query is _only_ for `id`, return a URL like /company-infos/:id.
      // We can't just use .findRecord because the ID given is the company ID, and the backend responds with a UUID for the company-info
      // in the response, which ember-data does NOT like.
      const baseUrl = this.buildURL(modelName);
      const id = query.id;
      delete query.id;
      return `${baseUrl}/${encodeURIComponent(id)}`;
    } else {
      return super.urlForQueryRecord(query, modelName);
    }
  }
}

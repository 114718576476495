import DeskWithAmenitiesFragment from 'garaje/graphql/fragments/employee-schedule/DeskWithAmenitiesFragment';
import gql from 'graphql-tag';

export default gql`
  fragment ReservationWithAmenitiesFragment on Reservation {
    desk {
      ...DeskWithAmenitiesFragment
    }
    id
    startTime
    endTime
    isPartialDay
  }
  ${DeskWithAmenitiesFragment}
`;

import Route from '@ember/routing/route';
import type GlobalBadgeModel from 'garaje/models/global-badge';
import type GlobalFlowModel from 'garaje/models/global-flow';
import type GlobalPhotoPageModel from 'garaje/models/global-photo-page';
import { hash } from 'rsvp';

import type { LocationOverviewSignInFlowsShowRouteModel } from '../route';

interface LocationOverviewSignInFlowsShowVisitorPhotosRouteModel {
  flow: GlobalFlowModel;
  photoPage: GlobalPhotoPageModel;
  badge: GlobalBadgeModel;
}

export default class LocationOverviewSignInFlowsShowVisitorPhotosRoute extends Route {
  model(): Promise<LocationOverviewSignInFlowsShowVisitorPhotosRouteModel> {
    const { globalFlow } = <LocationOverviewSignInFlowsShowRouteModel>(
      this.modelFor('location-overview.sign-in-flows.show')
    );
    const photoPage = globalFlow.photoPage;
    const badge = globalFlow.badge;

    return hash({ flow: globalFlow, photoPage, badge });
  }
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type LocationModel from 'garaje/models/location';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import type { BinaryDownloadModalOption } from 'garaje/utils/binary-download-options';
import { OPERATING_SYSTEM_OPTIONS, PLATFORM_ARCHITECTURE_OPTIONS } from 'garaje/utils/binary-download-options';
import urlBuilder from 'garaje/utils/url-builder';

interface WorkplaceSettingsBinaryDownloadDownloadModalArgs {
  location: LocationModel;
}

/**
 * @param {Class<Location>} location Location
 * @param {Boolean} [disabled] If true, open button is disabled
 */
export default class WorkplaceSettingsBinaryDownloadDownloadModal extends Component<WorkplaceSettingsBinaryDownloadDownloadModalArgs> {
  @service declare workplaceMetrics: WorkplaceMetricsService;

  @tracked isSettingsPanelOpen = false;
  @tracked isOperatingSystemPresent = false;
  @tracked isPlatformArchitecturePresent = false;
  @tracked isDownloading = false;
  @tracked hashText = 'Please choose an OS and architecture option above';
  @tracked showLoadingSpinner = false;
  @tracked selectedOperatingSystem: BinaryDownloadModalOption | null = null;
  @tracked selectedPlatformArchitecture: BinaryDownloadModalOption | null = null;
  operatingSystems: BinaryDownloadModalOption[] = OPERATING_SYSTEM_OPTIONS;
  platformArchitectures: BinaryDownloadModalOption[] = PLATFORM_ARCHITECTURE_OPTIONS;

  get downloadUrl(): string {
    const os = this.selectedOperatingSystem?.value;
    const arch = this.selectedPlatformArchitecture?.value;
    if (!os || !arch) {
      return '';
    }
    return urlBuilder.v3.diplomatClients.download(os, arch);
  }

  /**
   * Sets the operatingSystem value in the location changeset
   *
   * @param {BinaryDownloadModalOption} operatingSystem
   */
  @action
  async setOperatingSystem(operatingSystem: BinaryDownloadModalOption): Promise<void> {
    this.selectedOperatingSystem = operatingSystem;
    this.isOperatingSystemPresent = true;
    await this.generateHash();
  }

  /**
   * Sets the platformArchitecture value in the location changeset
   *
   * @param {BinaryDownloadModalOption} platformArchitecture
   */
  @action
  async setPlatformArchitecture(platformArchitecture: BinaryDownloadModalOption): Promise<void> {
    this.selectedPlatformArchitecture = platformArchitecture;
    this.isPlatformArchitecturePresent = true;
    await this.generateHash();
  }

  /**
   * Shows settings panel
   */
  @action
  showSettings(): void {
    this.workplaceMetrics.trackEvent('LOCATION_SETTINGS_BINARY_DOWNLOAD_ENABLE_BUTTON_CLICKED');
    this.isSettingsPanelOpen = true;
  }

  /**
   * Hides settings panel
   */
  @action
  hideSettings(): void {
    this.isSettingsPanelOpen = false;
    this.showLoadingSpinner = false;
  }

  @action
  async generateHash(): Promise<void> {
    const selectedOs = this.selectedOperatingSystem?.value;
    const selectedArch = this.selectedPlatformArchitecture?.value;
    if (!selectedOs || !selectedArch) {
      return;
    }
    const url = urlBuilder.v3.diplomatClients.hash(selectedOs, selectedArch);
    this.hashText = 'Fetching hash...';
    this.showLoadingSpinner = true;
    try {
      const response = await fetch(url);
      const text = await response.text();
      this.hashText = text;
      this.workplaceMetrics.trackEvent('LOCATION_SETTINGS_HASH_GENERATED');
    } catch {
      this.hashText = 'Failed to fetch hash, please try again';
    }
    this.showLoadingSpinner = false;
  }

  @action
  disableDownloadButton(): void {
    this.isDownloading = true;

    setTimeout(() => {
      this.isDownloading = false;
    }, 2000);
  }
}

import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import { GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, ZONE_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_PRINT_BADGES = [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST];
const CAN_MANAGE_BADGES = [GLOBAL_ADMIN, LOCATION_ADMIN];
const CAN_MANAGE_PROPERTY_PRINTERS = [GLOBAL_ADMIN, ZONE_ADMIN];

export default class BadgeAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;

  get canPrint(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_PRINT_BADGES, roleNames));
  }

  get canManage(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_MANAGE_BADGES, roleNames));
  }

  get canManageProperty(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_MANAGE_PROPERTY_PRINTERS, roleNames));
  }
}

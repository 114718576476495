import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AnalyticsProtectUpsellRoute extends Route {
  @service abilities;
  @service featureFlags;
  @service router;

  title = 'Protect · Analytics · Envoy';

  buildRouteInfoMetadata() {
    return { hideBanners: true };
  }

  redirect() {
    if (this.abilities.can('visit protect analytic')) {
      return this.router.transitionTo('analytics.protect.index');
    }
  }
}

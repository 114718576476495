import type NativeArray from '@ember/array/-private/native-array';
import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import type GlobalAgreementModel from 'garaje/models/global-agreement';
import { hash } from 'rsvp';

import type { LocationOverviewSignInFlowsShowLegalDocumentsRouteModel } from '../route';

import type LocationOverviewSignInFlowsShowLegalDocumentsIndexController from './controller';

export interface LocationOverviewSignInFlowsShowLegalDocumentsIndexRouteModel {
  agreements: NativeArray<GlobalAgreementModel>;
  agreementPage: LocationOverviewSignInFlowsShowLegalDocumentsRouteModel['agreementPage'];
  flow: LocationOverviewSignInFlowsShowLegalDocumentsRouteModel['flow'];
  tracking: LocationOverviewSignInFlowsShowLegalDocumentsRouteModel['tracking'];
}

export default class LocationOverviewSignInFlowsShowLegalDocumentsIndexRoute extends Route {
  model(): Promise<LocationOverviewSignInFlowsShowLegalDocumentsIndexRouteModel> {
    const { agreements, agreementPage, flow, tracking } = <LocationOverviewSignInFlowsShowLegalDocumentsRouteModel>(
      this.modelFor('location-overview.sign-in-flows.show.legal-documents')
    );

    return hash({
      agreements: agreements!.rejectBy('isDeleted').sortBy('position'),
      agreementPage,
      flow,
      tracking,
    });
  }

  setupController(
    controller: LocationOverviewSignInFlowsShowLegalDocumentsIndexController,
    model: LocationOverviewSignInFlowsShowLegalDocumentsIndexRouteModel,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);
    void controller.setupTrackingConfigurations.perform();
  }
}

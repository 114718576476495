import CompanyFragment from 'garaje/graphql/fragments/roomba/CompanyFragment';
import gql from 'graphql-tag';

export default gql`
  mutation CreateCompany($input: CompanyInput!) {
    createCompany(input: $input) {
      ...CompanyFragment
    }
  }
  ${CompanyFragment}
`;

import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { or } from 'macro-decorators';

export default class SettingsVisitorEmailsController extends Controller {
  @service abilities;
  @service currentAdmin;
  @service featureFlags;

  @tracked visitorGuideHasChanges = false;
  @tracked surveyConfigHasChanges = false;

  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isLocationAdmin') isAdmin;

  sidebarTitle = 'Invite confirmation email';
  sidebarHeader = 'address,directions,calendar';
  sidebarBody = 'custom';

  get showSetupGuide() {
    return this.isAdmin && this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper');
  }

  @action
  onVisitorGuideEdit({ hasChanges }) {
    this.visitorGuideHasChanges = hasChanges;
  }

  @action
  onSurveyConfigEdit({ hasChanges }) {
    this.surveyConfigHasChanges = hasChanges;
  }

  @action
  rollbackVisitorGuide() {
    this.visitorGuideHasChanges = false;
    get(this.model.currentLocation, 'visitorGuide').then((visitorGuide) => {
      visitorGuide.rollbackSections();
    });
  }

  @action
  rollbackSurveyConfig() {
    this.surveyConfigHasChanges = false;
    get(this.model.currentLocation, 'visitorSurveyConfiguration').then((surveyConfig) => {
      surveyConfig.rollbackAttributes();
    });
  }

  get welcomeMailerTemplate() {
    return this.model.mailerTemplates.findBy('identifier', 'welcome');
  }

  get canManageWelcomeEmailTemplates() {
    return this.abilities.can('customize email for visitor-email') && isPresent(this.welcomeMailerTemplate);
  }
}

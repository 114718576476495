import { action, computed, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

/*
computed will be removed when we refactor
components to glimmer that are using this decorator
-- viktorfuto
*/
function syncSettingsObject(Class) {
  return class SyncSettingsObject extends Class {
    @service flashMessages;
    @service syncManager;

    @alias('integrationData.enabled') enabled;
    @alias('integrationData.domain') domain;
    @alias('company.employeesLastSyncAt') lastSyncAt;
    @alias('integrationData.errorMessage') syncErrorMessage;
    @alias('company.employeesFilter') filter;
    @alias('company.syncedSince') fromNow;

    @computed('company.employeesSyncState')
    get isInvalid() {
      const state = get(this, 'company.employeesSyncState');
      return ['errored', 'unauthorized'].includes(state);
    }

    @computed('integrationData.connected')
    get connected() {
      if (Object.keys(get(this, 'integrationData')).length !== 1) {
        return get(this, 'integrationData.connected');
      } else {
        return true;
      }
    }

    @action
    sync() {
      return get(this, 'syncManager').sync(get(this, 'company').content);
    }
  };
}

export default syncSettingsObject;

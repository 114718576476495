import ApplicationSerializer from './application';

export default class ReservationSerializer extends ApplicationSerializer {
  /**
   * The reservations endpoint returns an array. We just want the first object or null if not present
   */
  normalizeFindBelongsToResponse(store, model, payload) {
    payload.data = payload.data.firstObject || null;

    return super.normalizeFindBelongsToResponse(...arguments);
  }
}

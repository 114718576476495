import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { addDays } from 'date-fns';
import { alias, or } from 'macro-decorators';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';

const TRIAL_EXTENSION_DAYS = 14;

/**
 * @param {Object}   ctaEventProperties
 */
export default class PremiumTrialModal extends Component {
  @service metrics;
  @service impressions;
  @service state;
  @service store;
  @service flashMessages;
  @service bannerManager;
  @service currentAdmin;

  @alias('state.vrSubscription') subscription;

  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isBillingAdmin') isAdmin;

  get canStartTrial() {
    return this.isAdmin && this.subscription.timesTrialExtended === 0;
  }

  get ctaEventProperties() {
    return {
      cta_id: this.args.ctaId,
      cta_type: 'modal',
      cta_clickable_text: this.showUpgradeButton ? 'Demo Premium' : null,
      cta_clickable_type: this.showUpgradeButton ? 'button' : 'none',
      cta_title: this.args.header,
      cta_body: this.args.body,
      cta_intent: 'demo',
      growth_team_project: true,
    };
  }

  @action
  logClicked(event) {
    if (this.args.ctaEventProperties) {
      const buttonText = event.target.textContent.trim();
      this.metrics.trackEvent('CTA Clicked', {
        ...this.args.ctaEventProperties,
        cta_type: 'modal',
        cta_clickable_type: 'button',
        cta_clickable_text: buttonText,
      });
    }
  }

  @action
  logViewed() {
    if (this.args.ctaEventProperties) {
      this.metrics.trackEvent('CTA Viewed', {
        ...this.args.ctaEventProperties,
        cta_type: 'modal',
      });
    }
  }

  @action
  logDismissalAndClose() {
    this.metrics.trackEvent('CTA Dismissed', this.ctaEventProperties);
    if (this.args.closeModal) this.args.closeModal();
  }

  @dropTask
  *startPremiumTrialTask() {
    if (!this.canStartTrial) return;

    this.subscription.trialEndDate = addDays(new Date(), TRIAL_EXTENSION_DAYS);

    try {
      yield this.subscription.save();
      yield this.impressions.postImpression.perform(IMPRESSION_NAMES.VISITORS_BASIC_TO_PREMIUM_TRIAL);
      this.bannerManager.growthBanner = null;
      this.flashMessages.showAndHideFlash('success', 'Premium trial started!');
      this.args.closeModal();
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
      this.subscription.rollbackAttributes();
    }
  }
}

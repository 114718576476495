import Component from '@glimmer/component';
import { format, setISODay } from 'date-fns';
import { action } from '@ember/object';

const TIME_OPTIONS = [
  { label: '1:00 am', value: '01:00:00' },
  { label: '2:00 am', value: '02:00:00' },
  { label: '3:00 am', value: '03:00:00' },
  { label: '4:00 am', value: '04:00:00' },
  { label: '5:00 am', value: '05:00:00' },
  { label: '6:00 am', value: '06:00:00' },
  { label: '7:00 am', value: '07:00:00' },
  { label: '8:00 am', value: '08:00:00' },
  { label: '9:00 am', value: '09:00:00' },
  { label: '10:00 am', value: '10:00:00' },
  { label: '11:00 am', value: '11:00:00' },
  { label: '12:00 pm', value: '12:00:00' },
  { label: '1:00 pm', value: '13:00:00' },
  { label: '2:00 pm', value: '14:00:00' },
  { label: '3:00 pm', value: '15:00:00' },
  { label: '4:00 pm', value: '16:00:00' },
  { label: '5:00 pm', value: '17:00:00' },
  { label: '6:00 pm', value: '18:00:00' },
  { label: '7:00 pm', value: '19:00:00' },
  { label: '8:00 pm', value: '20:00:00' },
  { label: '9:00 pm', value: '21:00:00' },
  { label: '10:00 pm', value: '22:00:00' },
  { label: '11:00 pm', value: '23:00:00' },
];

const START_AT_OPTIONS = [{ label: '12:00 am', value: '00:00:00' }, ...TIME_OPTIONS];

const END_AT_OPTIONS = [...TIME_OPTIONS, { label: '12:00 am', value: '24:00:00' }];

/**
 * @param {Boolean}       disabled
 * @param {Object}        notificationPeriod
 * @param {Function}      updateNotificationPeriod
 */
export default class NotificationScheduleItem extends Component {
  get canEditTime() {
    const active = this.args.notificationPeriod.active;
    const disabled = this.args.disabled;

    return active && !disabled;
  }

  get endAtOptions() {
    const startAt = this.args.notificationPeriod.startAt;
    return END_AT_OPTIONS.filter(({ value }) => value > startAt);
  }

  get formattedDay() {
    const day = this.args.notificationPeriod.day;
    if (day) {
      return format(setISODay(new Date(), day), 'EEEE');
    }
    return '';
  }

  get startAtOptions() {
    const endAt = this.args.notificationPeriod.endAt;
    return START_AT_OPTIONS.filter(({ value }) => value < endAt);
  }

  @action
  setEndAt({ value }) {
    this.args.updateNotificationPeriod(this.args.notificationPeriod, { endAt: value });
  }

  @action
  setStartAt({ value }) {
    this.args.updateNotificationPeriod(this.args.notificationPeriod, { startAt: value });
  }

  @action
  toggleActive({ target: { checked } }) {
    this.args.updateNotificationPeriod(this.args.notificationPeriod, { active: checked });
  }
}

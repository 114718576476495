// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { equal } from '@ember/object/computed';
import Model, { attr } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';
import serializer from 'garaje/utils/decorators/serializer';

@adapter('rooms-v1')
@serializer('rooms')
class CompanyConfig extends Model {
  @attr('string') declare activationCode: string;
  @attr('boolean') declare automaticRoomReleaseEnabled: boolean;
  @attr('boolean') declare cancelRecurringMeeting: boolean;
  @attr('boolean') declare autoRoomReleaseEmailWarningEnabled: boolean;
  @attr('number') declare checkInWindow: number;
  @attr('string') declare connectedEmailAccount: string;
  @attr('boolean') declare hasConnectedCalendarAccount: boolean;
  @attr('boolean') declare roomCheckInEnabled: boolean;
  @attr('string') declare settingsAccessCode: string;
  @attr('string') declare provider: string;
  @attr('string') declare authErrorType: string;
  @attr('string') declare authErrorMsg: string;

  @equal('authErrorType', 'INSUFFICIENT_PERMISSIONS')
  declare hasInsufficientPermissions: boolean;
}

export default CompanyConfig;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'company-config': CompanyConfig;
  }
}

import { get } from '@ember/object';
import { service } from '@ember/service';
import Model, { attr, belongsTo } from '@ember-data/model';
import type StoreService from '@ember-data/store';
import type RmsAdapter from 'garaje/adapters/rms';
import type DeskModel from 'garaje/models/desk';
import type EntryModel from 'garaje/models/entry';
import type InviteModel from 'garaje/models/invite';
import type LocationModel from 'garaje/models/location';
import type UserModel from 'garaje/models/user';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('rms')
class ReservationModel extends Model {
  @service declare store: StoreService;

  // Attributes
  // API expects and returns unix time in seconds
  @attr('number') declare startTime: number;
  @attr('number') declare endTime: number;
  @attr('number') declare checkInTime: number;
  @attr('number') declare checkOutTime: number;
  @attr('number') declare canceledAt: number;
  @attr('string') declare userEmail: string;
  @attr('boolean') declare isPartialDay: boolean;

  // Relationships
  @belongsTo('desk') declare desk: DeskModel;
  @belongsTo('location') declare location: LocationModel;
  @belongsTo('entry') declare entry: EntryModel;
  @belongsTo('invite') declare invite: InviteModel;
  @belongsTo('user') declare user: UserModel;

  /**
   * Editing a reservation. Reservations are immutable in the backend
   * so the current reservation will be canceled and a new one will be returned
   */
  async editReservation(invite: InviteModel, desk: DeskModel, startTime: string, endTime: string): Promise<unknown> {
    const adapter = this.store.adapterFor('reservation') as RmsAdapter;
    const url = adapter.host + `/a/rms/desks/abstract/change-desk`;
    const data = {
      data: {
        attributes: {
          'reservation-id': this.id,
          // eslint-disable-next-line ember/no-get
          'current-desk-id': get(this.desk, 'id'),
          // eslint-disable-next-line ember/no-get
          'new-desk-id': get(desk, 'id'),
          // eslint-disable-next-line ember/no-get
          'invite-id': get(invite, 'id'),
          'start-time': startTime,
          'end-time': endTime,
        },
      },
    };
    return (await adapter.ajax(url, 'POST', { data })) as Promise<unknown>;
  }
}

export default ReservationModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    reservation: ReservationModel;
  }
}

import { service } from '@ember/service';
import { isPresent, isBlank } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, ZONE_ADMIN, ZONE_SECURITY } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_VISIT_CONNECT_ROLES = [GLOBAL_ADMIN, ZONE_ADMIN, ZONE_SECURITY];

export default class ConnectAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;

  get canVisit(): boolean {
    const { roleNames } = this.currentAdmin;
    const { connectSubscription } = this.state;

    // Company doesn't have Connect
    if (isBlank(connectSubscription)) {
      return false;
    }

    // Permission to access connect overview does not care about whether a user can access it from a specific property.
    const unfilteredZoneRoles = this.state.currentUser?.zoneRoles.mapBy('roleName');
    return (
      isPresent(_intersection(CAN_VISIT_CONNECT_ROLES, roleNames)) ||
      isPresent(_intersection(CAN_VISIT_CONNECT_ROLES, unfilteredZoneRoles))
    );
  }
}

import { isEmpty } from '@ember/utils';

export default function validateBrotherIpAddress() {
  return (key, newValue) => {
    let isValid = true;
    /*
      An invalid IP address would be all zeroes, an APIPA address
      (169.254.x.x) on an Infrastructure network, or an address
      within an incorrect range for your network.

      http://support.brother.com/g/b/faqend.aspx?c=us&lang=en&prod=hl2270dw_all&faqid=faq00002977_005
      */
    const selfAssignedIp = /^(169\.254|0\.0\.0\.0)/;
    if (isEmpty(newValue) || selfAssignedIp.test(newValue)) {
      isValid = false;
    }

    return isValid;
  };
}

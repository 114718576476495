/**
 * Add a new hook response.
 * This can be done in one of two ways:
 * 1) Pass a trigger name, resource ID, and response. (The response can either be a POJO, or a function that
 *    returns a POJO. This object should contain `html` and/or `json` keys that are includes in the response
 *    as `responseHTML` and `responseJSON`, respectively.)
 *    This method is useful for specifying the exact response for a particular ID.
 * 2) Pass a trigger name and a response (POJO or function). This sets a "default" handler for that trigger name;
 *    any time the trigger is used and a response has _not_ been set for the given ID, this function is called.
 *    (If a function is passed it should return a POJO with `html` and/or `json` keys that that become the
 *    `responseHTML` and `responseJSON` content of a /hooks/ui/trigger payload.)
 * In either case, if a function is given, it will be called with the requested `resourceId` as an argument.
 * Note that once you provide an ID-specific override, any "default" functions defined for the trigger are not
 * called for that ID. This means that if you have multiple default functions, you will need to provide the same
 * number of ID-specific responses to match.
 *
 * Examples:
 * Add a handler for _all_ invites:
 * ```
 * const uiHook = server.create('ui-hook', 'asPropertyInviteLog', {
 *   label: '√(Invite ID)',
 * });
 *
 * addUiHookResponse(uiHook, function (inviteId) {
 *   return {
 *     html: `<pre>${Math.sqrt(inviteId).toFixed(3)}</pre>`,
 *   };
 * });
 * ```
 *
 * Add a general handler, plus an override for a specific invite:
 * ```
 * const uiHook = server.create('ui-hook', 'asPropertyInviteLog', {
 *    label: 'Are they special?',
 *  });
 *
 * addUiHookResponse(uiHook, {
 *   html: 'No.',
 * });
 * addUiHookResponse(uiHook, 1000000, {
 *   html: '<marquee><blink>Congratulations, this is our 1 millionth invite!</blink></marquee>',
 * });
 * ```
 */
export function addUiHookResponse(uiHook, resourceIdOrResponse, maybeResponse) {
  if (!window._GarajeUiHookResponses) {
    // eslint-disable-next-line no-console
    console.warn(
      'You called addUiHookResponse but the response cache was not initialized; this probably means you are running without Mirage enabled',
    );
    return;
  }

  window._GarajeUiHookResponses.addUiHookResponse(uiHook, resourceIdOrResponse, maybeResponse);
}

const DEFAULT_RESPONSES = Symbol('default-responses');

export class MockUiHooks {
  #triggers = new Map();

  addUiHookResponse(uiHook, resourceIdOrResponse, maybeResponse) {
    /* eslint-disable ember/use-ember-get-and-set */
    const triggerName = uiHook.triggerName;
    let triggerResponses = this.#triggers.get(triggerName);
    if (!triggerResponses) {
      triggerResponses = new Map();
      this.#triggers.set(triggerName, triggerResponses);
    }

    let key, response;
    if (!maybeResponse) {
      key = DEFAULT_RESPONSES;
      response = resourceIdOrResponse;
    } else {
      key = resourceIdOrResponse.toString();
      response = maybeResponse;
    }
    let responses = triggerResponses.get(key);
    if (!responses) {
      responses = [];
      triggerResponses.set(key, responses);
    }
    responses.push({ uiHookId: uiHook.uiHookId, content: response });
  }

  getUiHookResponses(triggerName, resourceId) {
    const triggerResponses = this.#triggers.get(triggerName);
    if (!triggerResponses) {
      return [];
    }
    let responses = [];
    if (triggerResponses.has(resourceId.toString())) {
      responses = triggerResponses.get(resourceId.toString());
    } else if (triggerResponses.has(DEFAULT_RESPONSES)) {
      responses = triggerResponses.get(DEFAULT_RESPONSES);
    }

    return responses.map(({ content, uiHookId }) => {
      if (typeof content === 'function') {
        content = content(resourceId);
      }
      return { content, uiHookId };
    });
  }

  reset() {
    this.#triggers.clear();
  }
}

import { action } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import type EntryModel from 'garaje/models/entry';
import type LocationModel from 'garaje/models/location';
import type PrinterModel from 'garaje/models/printer';
import type TenantModel from 'garaje/models/tenant';
import type LoggerService from 'garaje/services/logger';
import type StateService from 'garaje/services/state';
import type VisitorsOnboardingService from 'garaje/services/visitors-onboarding';
import parseDateFromQP from 'garaje/utils/parse-date-from-qp';
import type { RecordArray } from 'garaje/utils/type-utils';
import buildInviteValidations from 'garaje/validations/new-entry';
import moment from 'moment-timezone';
import { hash } from 'rsvp';

import type VisitorsNewController from './controller';

interface VisitorsNewRouteParams {
  signedInAt: string;
}

export interface VisitorsNewRouteModel {
  connectedTenants: RecordArray<TenantModel>;
  currentLocation: LocationModel;
  entry: EntryModel;
  printers: PrinterModel[] | RecordArray<PrinterModel>;
  vrSubscription: StateService['vrSubscription'];
}

class VisitorsNewRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare logger: LoggerService;
  @service declare visitorsOnboarding: VisitorsOnboardingService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  titleToken = 'New visitor';

  queryParams = {
    signedInAt: {
      as: 'targetDate',
    },
  };

  beforeModel(): void {
    if (this.abilities.cannot('create entries')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  async model(params: VisitorsNewRouteParams): Promise<VisitorsNewRouteModel> {
    const dateFromQP = parseDateFromQP(params.signedInAt);
    const today = moment();
    const midnight = moment().add(1, 'day').startOf('day');

    // parseDateFromQP will round any time after 11:45pm to 12:00 am next day, so 12:00 am next day is considered today
    const dateFromQPisAfterToday = moment(dateFromQP).isAfter(midnight, 'minute');

    const date = dateFromQPisAfterToday ? today.toDate() : dateFromQP;
    let printers: PrinterModel[] | Promise<RecordArray<PrinterModel>> = [];
    const { vrSubscription, currentLocation } = this.state;
    // Printers endpoint returns 403 for unauthorized employees which end up on users
    // getting sad cloud upon sign in
    if (this.abilities.can('visit device') && vrSubscription?.canEnableBadgePrinting) {
      printers = this.store.query('printer', {
        filter: { location: this.state.currentLocation.id, enabled: true },
        sort: 'status',
      });
    }

    await this.visitorsOnboarding.loadSteps();
    await this.state.loadFlows({ locationId: currentLocation.id });

    return hash({
      connectedTenants: currentLocation.getPropertyConnections(),
      currentLocation,
      entry: currentLocation.createEntry(date),
      printers,
      vrSubscription,
    });
  }

  redirect(_model: VisitorsNewRouteModel, transition: Transition): void {
    this.visitorsOnboarding.gateRoute(this, transition);
  }

  setupController(controller: VisitorsNewController, model: VisitorsNewRouteModel, transition: Transition): void {
    super.setupController(controller, model, transition);

    // changeset needs controller context to pull in the hostSignInField
    controller.changeset = this.#createChangeset(model.entry, controller);

    const dateString = moment(model.entry.signedInAt).format('YYYY-MM-DDTHH:mm');
    // make the model, the single source of truth for the targetDate query-param
    if (controller.signedInAt !== dateString) {
      // Why a run latter? https://github.com/emberjs/ember.js/issues/5465
      later(() => (controller.signedInAt = dateString), 0);
    }
  }

  resetController(controller: VisitorsNewController): void {
    const entry = controller.model.entry;
    if (<boolean>(<unknown>entry.isNew)) {
      // This will reset the model and remove the model, even in the instance where
      // the record was created (204) and loads with a different ID, and this model
      // would still linger and be unable to unload
      // rollbackAttributes will call unloadRecord if model is isNew
      try {
        entry.rollbackAttributes();
      } catch (e) {
        // Record not unloaded from visitor sign in form
        this.logger.error(<Error>e);
      }
    }

    controller.signedInAt = undefined;
  }

  @action
  refreshRoute(): void {
    void this.refresh();
  }

  #createChangeset(entry: EntryModel, context: VisitorsNewController) {
    const validations = buildInviteValidations(context);
    const validator = lookupValidator(validations);
    return Changeset(entry, validator, validations);
  }
}

export default VisitorsNewRoute;

import V2Adapter from './v2';

export default class extends V2Adapter {
  // requests to location/:id/request_ping use api/v3 while
  // the rest of location uses api/v2
  buildURL(modelName, id, snapshot, requestType) {
    let url = super.buildURL(...arguments);
    if (['PING', 'v3'].includes(requestType)) {
      url = url.replace('api/v2', 'api/v3');
    }
    return url;
  }
}

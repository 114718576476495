import Component from '@glimmer/component';
import type ApprovalStatus from 'garaje/models/approval-status';
import type EntryModel from 'garaje/models/entry';

interface ApprovalHistoryComponentArgs {
  approvalStatus: ApprovalStatus;
  expiredAt: string;
  entry: EntryModel;
  fullName: string;
}

interface ExpiredApprovalStatus {
  status: string;
  reviewedAt: string;
}

export default class ApprovalHistoryComponent extends Component<ApprovalHistoryComponentArgs> {
  get expiredApprovalStatus(): ExpiredApprovalStatus | null {
    return this.args.expiredAt
      ? {
          status: 'expired',
          reviewedAt: this.args.expiredAt,
        }
      : null;
  }
}

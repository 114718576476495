import Controller from '@ember/controller';
import config from 'garaje/config/environment';
import { tracked } from '@glimmer/tracking';
import { inject as controller } from '@ember/controller';
import { action } from '@ember/object';

export default class AppStoreWithOnePathController extends Controller {
  queryParams = ['install_id'];

  @controller apps;
  @tracked install_id;

  get iframeUrl() {
    const appspath = this.model.path;
    return `${config.appStoreHost}${appspath}${this.install_id != undefined ? `?install_id=${this.install_id}` : ''}`;
  }

  get context() {
    return this.apps.context;
  }

  @action
  trackLoggedIn() {
    return this.apps.trackLoggedIn();
  }
}

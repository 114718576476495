/* eslint-disable ember/no-component-lifecycle-hooks */
/* eslint-disable ember/classic-decorator-hooks */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { dropTask, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { action, set, setProperties } from '@ember/object';
import { toUp, toDown } from 'ember-animated/transitions/move-over';
import { isBlank } from '@ember/utils';
import _sample from 'lodash/sample';

import { findAllDeliveryAreasForLocation } from 'garaje/utils/delivery-area';
import config from 'garaje/config/environment';
import urlBuilder from 'garaje/utils/url-builder';
const DATA_POLLING_TIMEOUTS = [3500, 4500, 6500, 7500, 8500];

export default class DashboardProductBoxDeliveriesComponent extends Component {
  @service ajax;
  @service store;
  @service metrics;
  @service state;

  /**
   * @type {Boolean}
   */
  hasDeliveryAreas = true;

  init() {
    super.init(...arguments);
    if (this.state.features?.canAccessDeliveriesApplication) {
      this.initTask.perform();
    }
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    if (this.pollingInstance) {
      this.pollingInstance.cancel();
    }
  }

  /**
   * @return {EmberAnimated Transition}
   */
  rules({ oldItems, newItems }) {
    return oldItems[0] < newItems[0] ? toDown : toUp;
  }

  /**
   * initTask
   * Task used for the initial loading state
   */
  @dropTask
  *initTask() {
    const deliveryAreas = yield findAllDeliveryAreasForLocation(this.store, this.location);
    if (deliveryAreas.length === 0) {
      set(this, 'hasDeliveryAreas', false);
      return;
    }
    yield this.fetchBoxDataTask.perform();
    set(this, 'pollingInstance', this.pollForChangesTask.perform()); // Do not yield
  }

  /**
   * pollForChangesTask
   */
  @dropTask
  *pollForChangesTask() {
    while (config.environment !== 'test') {
      yield timeout(_sample(DATA_POLLING_TIMEOUTS));
      yield this.fetchBoxDataTask.perform();
    }
  }

  /**
   * fetchBoxDataTask
   */
  @dropTask
  *fetchBoxDataTask() {
    const url = urlBuilder.v2.deliveriesLandingPage(this.location.id);
    try {
      const {
        data: {
          'currently-waiting-for-pickup': currentlyWaitingForPickup,
          'currently-waiting-for-pickup-change': currentlyWaitingForPickupChange,
          'scanned-this-week': scannedThisWeek,
          'scanned-this-week-change': scannedThisWeekChange,
        },
      } = yield this.ajax.request(url);
      setProperties(this, {
        currentlyWaitingForPickup,
        currentlyWaitingForPickupChange,
        scannedThisWeek,
        scannedThisWeekChange,
      });
    } catch (error) {
      /* eslint-disable no-console */
      console.log({ error });
      /* eslint-enable no-console */
      if (isBlank(this.currentlyWaitingForPickup)) {
        setProperties(this, {
          currentlyWaitingForPickup: 0,
          currentlyWaitingForPickupChange: 0,
          scannedThisWeek: 0,
          scannedThisWeekChange: 0,
        });
      }
    }
  }

  /**
   * Handles tracking
   * @param {String} eventName
   * @param {Event} evt
   */
  @action
  trackClicked(eventName, _evt) {
    this.metrics.trackEvent(eventName, { product: 'deliveries' });
  }
}

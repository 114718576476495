import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { LightedCaseType } from 'garaje/graphql/generated/roomba-types';
import type { Device } from 'garaje/graphql/generated/roomba-types';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import { getDeviceStatus, isDeviceOnline } from 'garaje/utils/device-status';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

import { DEVICE_STATUS } from '../status-badge/component';

interface DeviceDetailsComponentArgs {
  device: Device;
  index: number;
}

export default class DeviceDetailsComponent extends Component<DeviceDetailsComponentArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare roombaGraphql: RoombaGraphqlService;

  @tracked showUnpairDeviceConfirmationModal = false;

  get DEVICE_STATUS(): typeof DEVICE_STATUS {
    return DEVICE_STATUS;
  }

  caseTypes = [
    { label: 'None', value: LightedCaseType.None },
    { label: 'Generic', value: LightedCaseType.Generic },
    { label: 'Visual Target', value: LightedCaseType.VisualTarget },
  ];

  saveLedAudioTypeTask = task({ drop: true }, async (device: Device, selected: (typeof this.caseTypes)[number]) => {
    try {
      await this.roombaGraphql.updateLightedCaseType(device.id, selected.value);
      this.flashMessages.showAndHideFlash('success', 'Device updated!');
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
    }
  });

  @action
  showConfirmationModal(): void {
    this.showUnpairDeviceConfirmationModal = true;
  }

  @action
  closeConfirmationModal(): void {
    this.showUnpairDeviceConfirmationModal = false;
  }

  unpairDeviceTask = task({ drop: true }, async () => {
    try {
      await this.roombaGraphql.unpairDevice(this.args.device.id);
      this.flashMessages.showAndHideFlash('success', 'Device removed!');
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
    } finally {
      this.showUnpairDeviceConfirmationModal = false;
    }
  });

  get deviceStatus(): DEVICE_STATUS {
    return getDeviceStatus(this.args.device);
  }

  get isDeviceOnline(): boolean {
    return isDeviceOnline(this.args.device);
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { defer } from 'rsvp';
import { action, get } from '@ember/object';

/**
 * @param {object}              userDocument              model
 * @param {Function}            deleteUserDocument        method to delete user document
 */
export default class DocumentReviewPanelComponent extends Component {
  @tracked isOpen = false;

  @dropTask
  showDeleteConfirmationModalTask = {
    *perform() {
      const deferred = defer();
      this.abort = () => deferred.resolve(false);
      this.continue = async () => {
        this.context.args.deleteUserDocument();
        deferred.resolve(true);
      };

      return yield deferred.promise;
    },
  };

  @action
  getAttachment(templateAttachmentId) {
    return this.args.userDocument.getAttachment(templateAttachmentId);
  }

  get sortedUserDocumentTemplateAttachments() {
    return get(this.args.userDocument, 'userDocumentTemplate.userDocumentTemplateAttachments').sortBy('position');
  }

  get canDeleteDocument() {
    return this.args.userDocument.userDocumentLinks.isEvery('approvalStatus', 'review');
  }
}

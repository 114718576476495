import Component from '@glimmer/component';
import type PlatformJobModel from 'garaje/models/platform-job';

interface MultipleRecipientComponentSignature {
  Args: {
    notifications: PlatformJobModel[];
  };
  Element: HTMLElement;
}

interface Rollup {
  total: number;
  sent: number;
  sending: number;
  failed: number;
  skipped: number;
}

export default class MultipleRecipientComponent extends Component<MultipleRecipientComponentSignature> {
  get categoryRollup(): Record<string, Rollup> {
    return this.args.notifications.reduce<Record<string, Rollup>>((categories, notification) => {
      if (!categories[notification.title]) {
        categories[notification.title] = { total: 0, sent: 0, sending: 0, failed: 0, skipped: 0 };
      }
      categories[notification.title]!.total++;

      if (notification.done) categories[notification.title]!.sent++;
      if (notification.inProgress || notification.queued) categories[notification.title]!.sending++;
      if (notification.failed || notification.expired) categories[notification.title]!.failed++;
      if (notification.ignored) categories[notification.title]!.skipped++;

      return categories;
    }, {});
  }
}

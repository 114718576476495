import { Changeset } from 'ember-changeset';
import { type DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import type MailerTemplateModel from 'garaje/models/mailer-template';
import mailerTemplateValidations from 'garaje/validations/mailer-template';

export default function (mailerTemplate: MailerTemplateModel): DetailedChangeset<MailerTemplateModel> {
  const validator = lookupValidator(mailerTemplateValidations);

  return Changeset(mailerTemplate, validator, mailerTemplateValidations);
}

import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type GlobalFlowModel from 'garaje/models/global-flow';
import type GlobalIdScanPageModel from 'garaje/models/global-id-scan-page';
import type GlobalVisualComplianceConfiguration from 'garaje/models/global-visual-compliance-configuration';
import type UserModel from 'garaje/models/user';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';
import { hash } from 'rsvp';

import type { LocationOverviewSignInFlowsShowRouteModel } from '../route';

import type LocationOverviewSignInFlowsShowSecurityController from './controller';

interface LocationOverviewSignInFlowsShowSecurityRouteModel {
  flow: GlobalFlowModel;
  idScanPage: GlobalIdScanPageModel;
  inviteApprovalContacts: ArrayProxy<UserModel>;
  visualComplianceConfiguration: GlobalVisualComplianceConfiguration;
}

export default class LocationOverviewSignInFlowsShowSecurityRoute extends Route {
  declare controller: LocationOverviewSignInFlowsShowSecurityController;

  @service declare transitionConfirm: TransitionConfirmService;

  model(): Promise<LocationOverviewSignInFlowsShowSecurityRouteModel> {
    const { globalFlow } = <LocationOverviewSignInFlowsShowRouteModel>(
      this.modelFor('location-overview.sign-in-flows.show')
    );

    return hash({
      flow: globalFlow,
      idScanPage: globalFlow.idScanPage,
      inviteApprovalContacts: globalFlow.inviteApprovalContacts,
      visualComplianceConfiguration: globalFlow.visualComplianceConfiguration,
    });
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const { idScanChangeset } = this.controller;
    const confirmTask = this.transitionConfirm.displayConfirmTask;
    const isDirty = idScanChangeset?.isDirty;

    if (isDirty) {
      void confirmTask.perform(transition, {
        continue() {
          idScanChangeset?.rollback();
        },
      });
    }
  }
}

import DeliveriesAdapter from './deliveries';

export default class extends DeliveriesAdapter {
  /**
   * Customized to allow `deliveryLimit.reload()` (which is equivalent to `store.findRecord('delivery-limit', 1)`)
   * to make a request to the `delivery-limit` endpoint without an `id`. The `delivery-limit` endpoint is a
   * singleton resource (https://jsonapi-resources.com/v0.10/guide/resources.html#Singleton-Resources). The `id`
   * the server returns for the `deliveryLimit` resource will be the same as the current company `id`.
   */
  urlForFindRecord(_id, modelName, _snapshot) {
    return this.buildURL(modelName, null);
  }
}

export default function toOxfordComma(arr: string[]): string {
  // clone to avoid mutation
  const fragments = [...arr];

  if (!fragments.length) {
    return '';
  }

  if (fragments.length === 1) {
    return fragments[0]!;
  }

  if (fragments.length === 2) {
    return `${fragments[0]!} and ${fragments[1]!}`;
  }

  const last = fragments.pop()!;
  return `${fragments.join(', ')}, and ${last}`;
}

import DeviceFragment from 'garaje/graphql/fragments/roomba/DeviceFragment';
import gql from 'graphql-tag';

export default gql`
  mutation UpdateLightedCaseType($deviceId: ID!, $caseType: LightedCaseType!) {
    updateLightedCaseType(deviceId: $deviceId, caseType: $caseType) {
      ...DeviceFragment
    }
  }
  ${DeviceFragment}
`;

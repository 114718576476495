import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import type StateService from 'garaje/services/state';
import { Permission } from 'garaje/utils/ui-permissions';

export default class GlobalDeviceAbility extends Ability {
  @service declare authz: AuthzService;
  @service declare state: StateService;

  get canDelete(): boolean {
    if (!this._isOnCorrectPlan) return false;

    return this.authz.hasPermissionAtCurrentCompany(Permission.VISITORS_DEVICE_DELETE);
  }

  get canManage(): boolean {
    if (!this._isOnCorrectPlan) return false;

    const permissions = [
      Permission.VISITORS_DEVICE_CREATE,
      Permission.VISITORS_DEVICE_UPDATE,
      Permission.VISITORS_DEVICE_DELETE,
    ];

    return this.authz.hasAnyPermissionAtCurrentCompany(permissions);
  }

  get canRead(): boolean {
    if (!this._isOnCorrectPlan) return false;

    return this.authz.hasPermissionAtCurrentCompany(Permission.VISITORS_DEVICE_READ);
  }

  get canUpdate(): boolean {
    if (!this._isOnCorrectPlan) return false;

    return this.authz.hasPermissionAtCurrentCompany(Permission.VISITORS_DEVICE_UPDATE);
  }

  get canVisit(): boolean {
    return this.canRead;
  }

  get _isOnCorrectPlan(): boolean {
    return this.state.features?.canAccessGlobalOverview ?? false;
  }
}

import Route from '@ember/routing/route';
import type GlobalFlowModel from 'garaje/models/global-flow';
import { hash } from 'rsvp';

import type { LocationOverviewSignInFlowsShowRouteModel } from '../route';

interface LocationOverviewSignInFlowsShowPlusOneRouteModel {
  flow: GlobalFlowModel;
}

export default class LocationOverviewSignInFlowsShowPlusOneRoute extends Route {
  model(): Promise<LocationOverviewSignInFlowsShowPlusOneRouteModel> {
    const { globalFlow } = <LocationOverviewSignInFlowsShowRouteModel>(
      this.modelFor('location-overview.sign-in-flows.show')
    );

    return hash({ flow: globalFlow });
  }
}

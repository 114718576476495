import Component from '@glimmer/component';

import type { KioskDemoPage } from '../../provider/component';

export default class Photo extends Component<KioskDemoPage> {
  get shareLink(): string {
    if (this.args.firstFlowId) {
      return `/visitors/settings/visitor-types/${this.args.firstFlowId}/visitor-photos`;
    }
    return '/visitors/settings';
  }
}

import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';
import { or } from 'macro-decorators';

import type CurrentAdminService from './current-admin';

// Service to contain logic for showing the different empty page A/B tests for growth team
export default class EmptyStatePageService extends Service {
  @service declare state: StateService;
  @service declare statsig: StatsigService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare abilities: AbilitiesService;
  @service declare currentAdmin: CurrentAdminService;

  @tracked isEmployeeDirectoryEmptyPageOpen: boolean = true;

  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isLocationAdmin') isAdmin?: boolean;

  get employeeDirectoryEmptyStateEligibility(): boolean {
    if (
      this.isAdmin &&
      (this.state?.vrSubscription?.isSubscribed || this.state?.vrSubscription?.onTrial) &&
      this.abilities.can('manage employees') &&
      // @ts-ignore
      this.state?._companyMeta['employees-count'] === 1 &&
      !this.state?.currentCompany?.directorySyncProvider &&
      this.featureFlags.isEnabled('growth_employee_directory_empty_state')
    ) {
      return true;
    }
    return false;
  }

  get shouldShowEmployeeDirectoryEmptyPage(): boolean {
    return this.employeeDirectoryEmptyStateEligibility && this.isEmployeeDirectoryEmptyPageOpen;
  }

  closeEmployeeDirectoryEmptyPage(): void {
    this.isEmployeeDirectoryEmptyPageOpen = false;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'empty-state-page': EmptyStatePageService;
  }
}

import Component from '@glimmer/component';
import { A } from '@ember/array';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import { reads } from 'macro-decorators';

import Location from 'garaje/models/location';
import DeviceConfig from 'garaje/models/config';
import Flow from 'garaje/models/flow';
import AgreementPage from 'garaje/models/agreement-page';
import PhotoPage from 'garaje/models/photo-page';
import SummaryPage from 'garaje/models/summary-page';

/**
 * @param {Array}                               selectedChildren
 * @param {Model<GlobalSettingBatch>}           globalSettingBatch
 * @param {String}                              forType
 * @param {Function}                            close
 * @param {Function}                            backToSearcher
 * @param {Task}                                overwriteTask
 */
export default class PropagableSettingsChangedModalChangesConfirm extends Component {
  @service currentLocation;
  @service flashMessages;
  @service store;
  @service('global-setting-batch') globalSettingBatchService;

  @reads('args.globalSettingBatch.parent.content.name') parentName;
  @reads('args.globalSettingBatch.totalSettingsChanged') totalSettingsChanged;

  get settingsList() {
    const attributeTuples = [
      ['location', Location],
      ['config', DeviceConfig],
      ['flow', Flow],
      ['flow.agreementPage', AgreementPage],
      ['flow.photoPage', PhotoPage],
      ['flow.summaryPage', SummaryPage],
    ];
    return attributeTuples.reduce((list, tuple) => {
      const [attr, model] = tuple;
      const updatedAttributes = get(this.args.globalSettingBatch, `${attr}.updatedAttributes`) ?? A();
      return [...list, ...this._translateAttributes(updatedAttributes, model.OVERWRITABLE_SETTINGS)];
    }, []);
  }

  get signInFields() {
    const signInFieldIds = get(this.args.globalSettingBatch, 'signInFields') ?? A();
    return signInFieldIds.map((id) => this.store.peekRecord('sign-in-field', id));
  }

  get atName() {
    const parent = get(this.args.globalSettingBatch, 'parent.content');
    const locationName = get(this.currentLocation, 'name');
    if (get(parent, 'constructor.modelName') === 'flow') {
      return `${locationName} / ${get(parent, 'name')}`;
    } else {
      return locationName;
    }
  }

  _translateAttributes(attributes, toTranslate) {
    return attributes.map((attribute) => {
      return toTranslate[attribute];
    });
  }
}

import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';

/**
 * @param {Function}            close              action to close the modal
 * @param {Function}            onSubmit       task performed when confirming
 * @param {Invite}              invite
 * @param {VisitorDocument}     visitorDocument
 */
export default class RemoveVisitorDocumentConfirmation extends Component {
  @dropTask
  *onSubmitTask(e) {
    const { visitorDocument, invite } = this.args;

    e.preventDefault();

    yield this.args.onSubmit(visitorDocument, invite);

    this.args.close();
  }
}

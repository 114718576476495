import Component from '@glimmer/component';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { isEmpty } from '@ember/utils';

import { htmlSafe } from '@ember/template';
import { arrayToSentence } from 'garaje/helpers/array-to-sentence';

/**
 * @param {Object}              location
 * @param {String}              integration
 * @param {Function}            openAction
 * @param {Function}            closeAction
 * @param {Function}            integrationDisconnected
 */
export default class ZapierConfigBox extends Component {
  @service currentAdmin;
  @service integrationsManager;

  get company() {
    return get(this.args.location, 'company.content');
  }

  get zapierIntegration() {
    return get(this.company, 'zapierIntegration.content');
  }

  get connected() {
    return !isEmpty(this.zapierIntegration);
  }

  get isOpen() {
    return this.args.integration === 'zapier';
  }

  get connectedTo() {
    const emails = this.zapierIntegration.granteeEmails.split(',').map((email) => {
      return `<b>${email}</b>`;
    });

    return htmlSafe(arrayToSentence(emails));
  }

  confirmDisconnect() {
    return window.confirm('Are you sure you want to disconnect this integration?');
  }

  @dropTask
  *disconnectTask() {
    if (!this.confirmDisconnect()) {
      return;
    }

    const zapierIntegration = this.zapierIntegration;
    yield zapierIntegration.destroyRecord();
    this.args.closeAction();
    this.args.integrationDisconnected();
  }
}

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { isEmpty } from '@ember/utils';

/**
 * @param {String}                    accept
 * @param {Function}                  onId
 * @param {Function}                  onLoadFile
 * @param {Function}                  onLoadDataUrl
 * @param {Function}                  onError
 * @param {Function}                  onLoadBinary
 */
export default class FileInput extends Component {
  elementId = guidFor(this);

  @action
  onInsert({ id: elementId }) {
    // We might need access to this element's ID in the outer context to give it a label[for="this_id"]
    // Passing in an ID when invoking this component seems to break FileField
    this.args.onId?.(elementId);
  }

  @action
  onChange({ target: input }) {
    if (!isEmpty(input.files)) {
      this.filesDidChange(input.files);
    }
  }

  filesDidChange(files) {
    if (files && files[0]) {
      this.args.onLoadFile?.(files[0]);

      if (this.args.onLoadBinary) {
        const binaryReader = new FileReader();
        binaryReader.onloadend = () => {
          this.args.onLoadBinary(binaryReader.result);
        };
        binaryReader.onerror = () => {
          this.args.onError?.(binaryReader.error);
        };
        binaryReader.readAsBinaryString(files[0]);
      }

      if (this.args.onLoadDataUrl) {
        const dataUrlReader = new FileReader();
        dataUrlReader.onloadend = () => {
          this.args.onLoadDataUrl(dataUrlReader.result);
        };
        dataUrlReader.onerror = () => {
          this.args.onError?.(dataUrlReader.error);
        };
        dataUrlReader.readAsDataURL(files[0]);
      }
    }
  }
}

import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type SetupGuideItemModel from 'garaje/models/setup-guide-item';
import type ChameleonService from 'garaje/services/chameleon';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type ImpressionsService from 'garaje/services/impressions';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

export default class SetupGuideItem extends Component<{
  item: SetupGuideItemModel;
  stepNumber: number;
  logEvent: (event_name: string, event_value: string, metadata?: object) => void;
  autoOpen: boolean;
}> {
  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;
  @service declare impressions: ImpressionsService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare chameleon: ChameleonService;

  @tracked isOpen: boolean = this.args.autoOpen; // eslint-disable-line ember/no-tracked-properties-from-args
  @tracked showModal: boolean = false;
  @tracked showiPadDemoModal: boolean = false;

  get markButtonLabel(): string | undefined {
    if (this.args.item.completed) {
      return this.args.item.metadata.dashboard_completed_step_label;
    }

    return this.args.item.metadata.dashboard_incomplete_step_label || 'Mark as complete';
  }

  @action
  handleCTAClick(buttonType: string, event: Event): void {
    localStorage.setItem('setupGuideStep', String(this.args.stepNumber));
    const item = this.args.item;
    const tourId =
      buttonType === 'secondary' ? item.metadata.secondary_button_chmln_tour_id : item.metadata.chmln_tour_id;
    if (tourId && this.featureFlags.isEnabled('growth_show_chmln_tour')) {
      this.chameleon.showTour(tourId);
    }

    const buttonText = (event.target as HTMLElement).textContent?.trim() || '';
    this.args.logEvent('homepage_setup_guide_item_clicked', item.identifier, { app: item.app, buttonText });

    if (item.metadata.dashboard_is_step_modal) {
      if (this.featureFlags.isEnabled('growth_ipad_demo')) {
        this.showiPadDemoModal = true;
      } else {
        void this.impressions.postImpression.perform(IMPRESSION_NAMES.NAVATTIC_IPAD_DEMO_VIEWED);
        this.showModal = true;
      }
    }

    // iPad demo users are required to get to step 8 before this step is completed which the iPad modal will handle
    if (item.metadata.dashboard_is_step_modal && !item.completed && !this.featureFlags.isEnabled('growth_ipad_demo')) {
      void this.toggleCompletionTask.perform(true);
    }
  }

  @action
  closeModal(): void {
    if (this.featureFlags.isEnabled('growth_ipad_demo')) {
      this.showiPadDemoModal = false;
    } else {
      this.showModal = false;
    }
  }

  @action
  handleTextLinkClick(event: Event): void {
    const target = event.target as HTMLAnchorElement;
    if (target.tagName === 'A') {
      const item = this.args.item;
      this.args.logEvent('homepage_setup_guide_item_text_link_clicked', item.identifier, { app: item.app });

      if (!target.getAttribute('target')) {
        event.preventDefault();
        const href = target.getAttribute('href');
        void this.router.transitionTo(href!);
      }
    }
  }

  toggleCompletionTask = task({ drop: true }, async (isLogged = false) => {
    const setupGuideItem = this.args.item;
    const completed = !setupGuideItem.completed;
    const statsigEventLabel = setupGuideItem.completed ? 'incomplete' : 'complete';

    setupGuideItem.completed = completed;
    this.isOpen = !completed;

    if (!isLogged) {
      this.args.logEvent(`homepage_setup_guide_item_mark_as_${statsigEventLabel}_clicked`, setupGuideItem.identifier, {
        app: setupGuideItem.app,
      });
    }

    try {
      await setupGuideItem.save();
    } catch (e) {
      setupGuideItem.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}

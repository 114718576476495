import Select from '../component';
import { action } from '@ember/object';
import { dropTask, all } from 'ember-concurrency';

export default class MultiResource extends Select {
  get placeholder() {
    return this.loading ? 'Loading...' : 'Select one or more';
  }

  @dropTask
  *fetchMissingSelection() {
    if (!Array.isArray(this.args.value) || !this.args.value.length) {
      return;
    }
    try {
      const fetchSelectedOptions = this.args.value.map((id) => this.fetchOption.perform(id));
      return yield all(fetchSelectedOptions);
    } catch (e) {
      let msg;
      try {
        msg = e.payload[0].detail.split('\n')[0];
      } catch (e) {
        // continue regardless of error
      }
      this.error = msg ?? 'Error loading select value.';
      this.flashMessages.showFlash('error', `Error loading select value. ${msg}`);
    }
  }

  @action
  onChange(selected) {
    const selectedArray = Array.isArray(selected) ? selected : [];
    this.selected = selectedArray ?? [];
    this.args.updateValue(selectedArray.map(({ id }) => id));
  }

  @action
  onLoaded(options) {
    const initialValue = Array.isArray(this.args.value) ? this.args.value : [];
    const selected = options.filter(({ id }) => initialValue.includes(id));
    if (selected.length === initialValue.length) {
      this.loading = false;
      return this.onChange(selected);
    }
    this.fetchMissingSelection.perform().then((selected) => {
      this.loading = false;
      this.onChange(selected);
    });
  }
}

import { action } from '@ember/object';
import Component from '@glimmer/component';

interface MailerTemplateEditorAttributeStringComponentSignature {
  Args: {
    onChangeValue?: (value: string) => void;
  };
}

export default class MailerTemplateEditorAttributeStringComponent extends Component<MailerTemplateEditorAttributeStringComponentSignature> {
  @action
  onInput(e: InputEvent): void {
    this.args.onChangeValue?.((<HTMLInputElement>e.target).value);
  }
}

import { attr } from '@ember-data/model';
import Report from 'garaje/models/report';

export default class TemperatureScreeningModel extends Report {
  @attr('string') declare result: string;
  @attr('string') declare reason: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'temperature-screening': TemperatureScreeningModel;
  }
}

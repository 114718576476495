import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { all, dropTask, enqueueTask } from 'ember-concurrency';
import { fetchCapacity } from 'garaje/utils/locations-capacity';

/**
 * @param {Array<Entry>}            entries
 * @param {Function}                continue
 * @param {Function}                cancel
 */
export default class DeleteEntry extends Component {
  @service ajax;
  @service flashMessages;
  @service metrics;
  @service state;
  @service store;

  get hasGroupParent() {
    return this.args.entries.any((entry) => entry.hasAdditionalGuests);
  }

  @enqueueTask({
    maxConcurrency: 10,
  })
  *deleteEntriesTask(entry, deleteGroup) {
    if (entry?.signInTime) {
      const url = this.store.adapterFor('entry').urlForDeleteRecord(get(entry, 'id'), 'entry');

      // This API is not done in the backend yet
      // we are assuming it for now
      yield this.ajax.request(url, { type: 'DELETE', data: { 'delete-group': deleteGroup } });
    }
  }

  @dropTask
  *deleteTask(entries, deleteGroup) {
    try {
      yield all(entries.map((entry) => this.deleteEntriesTask.perform(entry, deleteGroup)));

      if (entries.length > 1) {
        const entry_count = entries.reduce((count, entry) => count + entry.additionalGuests, entries.length);
        this.metrics.trackEvent('Bulk Entries Deleted', { entry_count });
      }

      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.args.continue({ entries, deleteGroup });
      const { currentLocation } = this.state;
      if (currentLocation.capacityLimitEnabled) {
        yield fetchCapacity(this.store, currentLocation, entries[0].signedInAt);
      }
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @action
  closeModal() {
    this.args.cancel();
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export const DEFAULT = Object.freeze({
  VALUE:
    'Your answers to the following questions will be used to determine if you’re ready to work in the workplace. Your responses are private to you and will not be stored.\n\nYou can decline to answer these questions; however doing so will require that you continue working remotely.',
});

/**
 * @param {Object}               model
 * @param {String}               value
 * @param {Function}             updateState
 * @param {Boolean}              isDisabled
 */

export default class FlowsScreeningHeaderText extends Component {
  @service flashMessages;

  @tracked value = '';
  @tracked cachedValue = '';
  @tracked model = null;
  @tracked editable = true;
  @tracked defaultValue = DEFAULT.VALUE;
  @tracked cachedUseDefaultHeaderText = false;

  constructor() {
    super(...arguments);
    this.value = this.args.value;
    this.cachedValue = this.args.value;
    this.model = this.args.model;
    this.updateState = this.args.updateState;
  }

  get isDirty() {
    return this.model && this.model.id && this.value != this.cachedValue;
  }

  @dropTask
  *save() {
    this.value = this.cachedValue;
    this.useDefaultHeaderText = this.cachedUseDefaultHeaderText;
    this.model.headerText = this.value;
    this.model.useDefaultHeaderText = this.useDefaultHeaderText;
    yield this.model.save();
    this.updateState(this.isDirty);
    try {
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      let message = 'Server error. Please try again.';

      if (e.isAdapterError) {
        message = e.errors.mapBy('detail').join(', ');
      }

      this.flashMessages.showFlash('error', message);
    }
  }

  @action
  contentChanged(text) {
    this.cachedUseDefaultHeaderText = !(text !== this.defaultValue);
    this.cachedValue = text;
    this.updateState(this.isDirty);
  }

  @action
  resetDefault() {
    this.cachedUseDefaultHeaderText = true;
    this.cachedValue = this.defaultValue;
    this.updateState(this.isDirty);
  }
}

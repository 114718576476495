import type { AsyncBelongsTo } from '@ember-data/model';
import { belongsTo } from '@ember-data/model';
import AbstractSignInFieldActionsContactModel from 'garaje/models/abstract/abstract-sign-in-field-actions-contact';
import type SignInFieldActionRuleGroupModel from 'garaje/models/sign-in-field-action-rule-group';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('v3-coalesce')
class SignInFieldActionsContact extends AbstractSignInFieldActionsContactModel {
  @belongsTo('sign-in-field-action-rule-group')
  declare signInFieldActionRuleGroup: AsyncBelongsTo<SignInFieldActionRuleGroupModel>;
}

export default SignInFieldActionsContact;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'sign-in-field-actions-contact': SignInFieldActionsContact;
  }
}

import type ArrayProxy from '@ember/array/proxy';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { enqueueTask, restartableTask, timeout, all } from 'ember-concurrency';
import type ConfigModel from 'garaje/models/config';
import type SlideshowModel from 'garaje/models/slideshow';
import type WelcomeScreenSlideModel from 'garaje/models/welcome-screen-slide';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { type SingleResponse } from 'jsonapi/response';

import { type WelcomeScreenRouteModel } from './route';

export default class PropertySettingsKioskWelcomeScreenController extends Controller {
  declare model: WelcomeScreenRouteModel;

  @service declare store: Store;
  @service declare flashMessages: FlashMessagesService;

  @action
  setWelcomeImage(response: { data: Record<string, unknown> }): void {
    this.store.push(this.store.normalize('property-device-config', response.data));
  }

  @action
  async deleteWelcomeImage(): Promise<void> {
    const response = await this.model.config.deleteWelcomeImage();
    const normalized = <SingleResponse<ConfigModel>>this.store.normalize('config', response.data);
    // if welcomeImage is not present, then it is not included in the payload. We need to nullify the key.
    normalized.data.attributes.welcomeImage = null;
    this.store.push(normalized);
  }

  @action
  addSlide(image: string): void {
    const slide = this.store.createRecord('welcome-screen-slide');
    slide.image = image;

    this.model.config.welcomeScreenSlides.addObject(slide);
  }

  removeSlidesTask = enqueueTask({ maxConcurrency: 3 }, async (slides: ArrayProxy<WelcomeScreenSlideModel>) => {
    try {
      await all(slides.invoke('save'));
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      void this.throttledRefreshConfigTask.perform();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  throttledRefreshConfigTask = restartableTask(async () => {
    await timeout(2000);
    await this.refreshConfigTask.perform();
  });

  /**
   * TODO remove when built into envoy-web
   */
  refreshConfigTask = restartableTask(async () => {
    await this.model.config.refreshConfig();
  });

  @action
  async finalizeSlides(data: { slideshow: SlideshowModel }): Promise<void> {
    const store = this.store;
    const { welcomeScreenSlides } = this.model.config;

    // find and clean up a single placeholder for the single upload that was completed
    const placeholder = welcomeScreenSlides.findBy('isNew');
    placeholder?.rollbackAttributes();

    // load in the real one
    store.pushPayload('welcome-screen-slide', {
      data: {
        id: data.slideshow.id,
        attributes: {
          image: data.slideshow.url,
        },
        type: 'welcome-screen-slide',
      },
    });
    const serializedSlide = await store.findRecord('welcome-screen-slide', data.slideshow.id);

    welcomeScreenSlides.addObject(serializedSlide);
  }
}

import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type AuthzService from 'garaje/services/authz';
import { adminRolesRoute } from 'garaje/utils/admin-routing';
import { getRoleIcon, type Role, RoleType, RoleId } from 'garaje/utils/custom-roles';
import type { Matrix, MatrixRow } from 'garaje/utils/permission-sets';
import { MatrixKind } from 'garaje/utils/permission-sets';

import type { MatrixByProduct } from '../create-role/controller';

interface ViewRoleModel {
  role: Role;
  matrix: Matrix;
}

class ManageViewRoleController extends Controller {
  @service declare authz: AuthzService;
  @service declare router: RouterService;

  @tracked showDeleteRoleModal = false;

  declare model: ViewRoleModel;

  // break down the matrix into products > permission sets
  get matrixByProduct(): Array<MatrixByProduct> {
    const { matrix } = this.model;
    const matrixByProduct: Array<MatrixByProduct> = [];
    let currentProduct: MatrixByProduct;

    if (matrix && matrix.rows?.length > 0) {
      matrix.rows.forEach((row: MatrixRow, i: number) => {
        if (row.kind === MatrixKind.PRODUCT) {
          if (currentProduct && Object.keys(currentProduct).length > 0 && currentProduct.product) {
            // new product, add everything to currentProduct
            matrixByProduct.push(currentProduct);
          }
          // reset
          currentProduct = {
            product: row,
            rows: [],
          };
        } else if (row.kind === MatrixKind.PERMISSION_SET) {
          currentProduct.rows.push(row);
          if (i === matrix.rows.length - 1) {
            // last item in the matrix, push to matrixByProduct
            matrixByProduct.push(currentProduct);
          }
        }
      });
    }

    return matrixByProduct;
  }

  get iconPath(): string {
    const { role } = this.model;
    return role ? getRoleIcon(role.id) : '';
  }

  get routeBackTo(): string {
    return adminRolesRoute(this.router.currentRouteName, 'manage.roles');
  }

  get editRoute(): string {
    return adminRolesRoute(this.router.currentRouteName, 'manage.edit-role');
  }

  get adminUsersRoute(): string {
    return adminRolesRoute(this.router.currentRouteName, 'manage.admin-users');
  }

  get isCustomRole(): boolean {
    const { role } = this.model;
    return role.type === RoleType.CUSTOM && role.id !== RoleId.ANALYTICS_VIEWER;
  }

  @action
  afterDelete(): void {
    void this.router.transitionTo(this.routeBackTo);
  }

  @action
  closeDeleteRoleModal(): void {
    this.showDeleteRoleModal = false;
  }
}

export default ManageViewRoleController;

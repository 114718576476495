import { run } from '@ember/runloop';

import { Promise as EmberPromise } from 'rsvp';

// Parse gecode address_components
export function parseAddress(address_components, componentForm) {
  // reference to
  // https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
  if (typeof componentForm === 'undefined') {
    componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'short_name',
      postal_code: 'short_name',
      neighborhood: 'long_name',
      sublocality_level_1: 'long_name',
      postal_town: 'long_name',
    };
  }

  const parsedPlace = {};

  for (let i = 0; i < address_components.length; i++) {
    for (let j = 0; j < address_components[i].types.length; j++) {
      const addressType = address_components[i].types[j];
      if (componentForm[addressType]) {
        const val = address_components[i][componentForm[addressType]];
        parsedPlace[addressType] = val;
      }
    }
  }

  let line1;
  if (parsedPlace.street_number) {
    line1 = `${parsedPlace.street_number} ${parsedPlace.route}`;
  } else if (parsedPlace.route) {
    line1 = parsedPlace.route;
  } else {
    line1 = parsedPlace.neighborhood;
  }

  return {
    line1,
    city: parsedPlace.locality || parsedPlace.sublocality_level_1 || parsedPlace.postal_town,
    country_code: parsedPlace.country,
    state: parsedPlace.administrative_area_level_1,
    zip: parsedPlace.postal_code,
  };
}

export default function geocode(inputAddress) {
  return new EmberPromise(function (resolve, reject) {
    if (!window.google) {
      return;
    }
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: inputAddress }, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        const result = results[0];
        const { location } = result.geometry;

        run(null, resolve, {
          latitude: location.lat(),
          longitude: location.lng(),
          address: result.formatted_address,
          parsedAddress: parseAddress(result.address_components),
        });
      } else {
        run(null, reject, status);
      }
    });
  });
}

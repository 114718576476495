import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import { hash } from 'rsvp';

import type { VisitorsDevicesIpadsRouteModel } from '../route';

export default class VisitorsDevicesIpadsIndexRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  model(): Promise<VisitorsDevicesIpadsRouteModel> {
    const { location, printers, ipads, subscription } = <VisitorsDevicesIpadsRouteModel>(
      this.modelFor('visitors.devices.ipads')
    );
    return hash({
      ipads,
      location,
      printers,
      subscription,
    });
  }

  afterModel({ ipads }: VisitorsDevicesIpadsRouteModel): void {
    if (ipads.length === 0 && this.abilities.can('create devices')) {
      void this.router.transitionTo('visitors.devices.ipads.new');
    }
  }
}

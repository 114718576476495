import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import config from 'garaje/config/environment';
import type AjaxService from 'garaje/services/ajax';
import type MetricsService from 'garaje/services/metrics';
import type SessionService from 'garaje/services/session';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

import type CurrentAdminService from './current-admin';

const URL = config.growthApiHost ? config.growthApiHost + '/a/growth/api/v1/feature-setup' : null;

//This is a service that handles the GET and POST calls to the Growth Feature Setup API. The API tracks events that are used and analyzed by the growth team.

export default class GrowthFeatureSetupService extends Service {
  @service declare session: SessionService;
  @service declare ajax: AjaxService;
  @service declare state: StateService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare metrics: MetricsService;

  getSetupTask = task(async (type: string) => {
    if (!URL) {
      return [];
    }

    const settings = {
      URL,
      type: 'GET',
      contentType: 'application/json',
      data: {
        filter: {
          feature_name: type,
          company_id: this.state?.currentCompany?.id,
        },
      },
    };

    try {
      return await this.ajax.request<unknown[]>(URL, settings);
    } catch (e) {
      const error_message = parseErrorForDisplay(e);
      this.metrics.trackEvent('GET Feature Usages failed', {
        type: 'error',
        message_title: error_message,
        feature_name: type,
        location_id: this.state?.currentLocation?.id,
        company_id: this.state?.currentCompany?.id,
      });
      return [];
    }
  });
}

import { helper } from '@ember/component/helper';
import { makeArray } from '@ember/array';

export function arrayToSentence(arr) {
  arr = makeArray(arr).compact();

  if (arr.length === 2) {
    return arr.join(' and ');
  } else {
    const last = arr.pop();
    const rest = arr.length ? arr.join(', ') : null;
    return [rest, last].compact().join(', and ');
  }
}

export default helper(function ([arr]) {
  return arrayToSentence(arr);
});

/* eslint-disable ember/no-component-lifecycle-hooks */
import SortableGroup from 'ember-sortable/components/sortable-group';
import { get, set } from '@ember/object';
import { next, once } from '@ember/runloop';
import $ from 'jquery';

export default SortableGroup.extend({
  didUpdateAttrs() {
    this._super();
    const oldCollectionLength = get(this, '_oldCollectionLength');
    const newCollectionLength = get(this, 'collectionLength');

    if (oldCollectionLength && newCollectionLength) {
      if (oldCollectionLength !== newCollectionLength) {
        once(this, this._recalculateContainer);
      }
    }

    if (get(this, 'recalculateDragContainer')) {
      once(this, this._recalculateContainer);
    }

    set(this, '_oldCollectionLength', newCollectionLength);
  },

  _recalculateContainer() {
    if (get(this, 'recalculateContainer')) {
      get(this, 'recalculateContainer')();
    } else {
      const parent = $(get(this, 'parentClass'));
      parent.removeAttr('style');

      next(this, function () {
        const originalHeight = parent.height() + 80;
        parent.css('height', originalHeight);
        get(this, 'toggleRecalculateContainer')();
      });
    }
  },

  // eslint-disable-next-line ember/no-actions-hash
  actions: {
    toggleRecalculateContainer() {
      once(this, this._recalculateContainer);
    },
  },
});

import GenericResponseFragment from 'garaje/graphql/fragments/employee-schedule/GenericResponseFragment';
import gql from 'graphql-tag';

export default gql`
  mutation ReleaseDeskReservation($reservationId: ID!) {
    releaseDeskReservation(reservationId: $reservationId) {
      ...GenericResponseFragment
    }
  }
  ${GenericResponseFragment}
`;

import { action } from '@ember/object';
import { service } from '@ember/service';
import type Model from '@ember-data/model';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { Duration } from 'date-fns';
import { intervalToDuration, differenceInSeconds } from 'date-fns';
import { task, timeout } from 'ember-concurrency';
import type { Task } from 'ember-concurrency';
import config from 'garaje/config/environment';
import type PrinterPasscodeModel from 'garaje/models/printer-passcode';
import type FlashMessagesService from 'garaje/services/flash-messages';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import zft from 'garaje/utils/zero-for-tests';
import { or, reads } from 'macro-decorators';
import { all } from 'rsvp';
import { localCopy } from 'tracked-toolbox';

const IS_TESTING: boolean = config.environment === 'test';

type FetchOneTimePasscodeTask = Task<PrinterPasscodeModel, [boolean]>;

interface DevicesPrinterPasscodeArgs {
  connectionIsGlobal: boolean;
  fetchOneTimePasscodeTask: FetchOneTimePasscodeTask;
  onPasscodeConsumed(printerConnection: Model): void;
}

export default class DevicesPrinterPasscode extends Component<DevicesPrinterPasscodeArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare store: Store;

  @tracked passcodeCountdownString = '';
  @tracked currentDate: Date = new Date();
  @tracked consumedCode = '';

  @localCopy('args.countdownFrom', 60) countdownFrom!: number;

  @reads('args.fetchOneTimePasscodeTask.isRunning') isRunning!: boolean;
  @reads('args.fetchOneTimePasscodeTask.lastSuccessful.value') printerPasscode!: PrinterPasscodeModel;
  @reads('printerPasscode.expiresAt') expiresAt!: Date;
  @reads('printerPasscode.consumedAt', null) consumedAt!: Date | null;
  @reads('printerPasscode.factoryRecord', false) isFactoryRecord!: boolean;

  @or('isRunning', 'isPairing') isLoading!: boolean;

  get isPairing(): boolean {
    const { consumedCode, printerPasscode } = this;

    return consumedCode && consumedCode === printerPasscode?.code ? true : false;
  }

  get loadingMessage(): string {
    return this.isPairing ? 'Connecting to Envoy Print...' : 'Generating pairing code...';
  }

  get secondsUntilPasscodeExpires(): number {
    const { expiresAt, currentDate } = this;

    if (!(expiresAt instanceof Date)) return Infinity;

    return differenceInSeconds(expiresAt, currentDate);
  }

  get shouldShowCountdown(): boolean {
    const { secondsUntilPasscodeExpires: remaining, countdownFrom, consumedAt } = this;

    if (consumedAt instanceof Date) return false;

    return remaining > 0 && remaining <= countdownFrom;
  }

  get isExpired(): boolean {
    const { isLoading, secondsUntilPasscodeExpires: remaining } = this;

    if (isLoading) return false;

    return remaining <= 0;
  }

  get isValidCode(): boolean {
    const { expiresAt, isExpired } = this;

    if (!(expiresAt instanceof Date)) return false;
    if (isExpired) return false;

    return true;
  }

  get isClipboardSupport(): boolean {
    if (IS_TESTING) return true;

    return typeof navigator?.clipboard?.writeText === 'function';
  }

  computePasscodeTimeRemaining(): string {
    this.currentDate = new Date();

    if (!this.shouldShowCountdown) return (this.passcodeCountdownString = '');

    const timeRemaining: Duration = intervalToDuration({ start: this.currentDate, end: this.expiresAt });
    const durationParts: Array<string> = [];

    if (timeRemaining.hours) {
      durationParts.push(`${timeRemaining.hours}`);
    }

    durationParts.push(timeRemaining.minutes ? `${timeRemaining.minutes}`.padStart(2, '0') : '00');
    durationParts.push(timeRemaining.seconds ? `${timeRemaining.seconds}`.padStart(2, '0') : '00');

    return (this.passcodeCountdownString = durationParts.join(':'));
  }

  @action
  async fetchOneTimePasscode(): Promise<void> {
    await this.args.fetchOneTimePasscodeTask.perform(this.args.connectionIsGlobal);
    await all([
      this.countdownTask.perform().catch(throwUnlessTaskDidCancel),
      this.monitorPasscodeStatusTask.perform().catch(throwUnlessTaskDidCancel),
    ]);
  }

  @action
  async copyToClipboard(): Promise<void> {
    const { isValidCode, isClipboardSupport, isFactoryRecord } = this;

    if (!(isValidCode && isClipboardSupport)) return;

    try {
      // Don't actually try to copy to clipboard in a test...
      if (!IS_TESTING) {
        await navigator.clipboard.writeText(this.printerPasscode.code);
      }

      // ...but test for flash message
      this.flashMessages.showAndHideFlash('success', 'Passcode copied');
    } catch (_) {
      // quietly fail
    }

    // If record comes from Mirage.js, trigger simulation of passcode usage
    if (isFactoryRecord) {
      await this.simulatePasscodeConsumedTask.perform().catch(throwUnlessTaskDidCancel);
    }
  }

  countdownTask = task(async () => {
    // Countdown timer (while loop) prevents tests from running.
    // Break while loop if running tests.
    do {
      this.computePasscodeTimeRemaining();

      // Recalculate time remaining more rapidly if countdown visible
      await timeout(this.shouldShowCountdown ? zft(250) : zft(3000));
    } while (!(IS_TESTING || this.isExpired || this.consumedAt));
  });

  monitorPasscodeStatusTask = task(async () => {
    do {
      const {
        store,
        printerPasscode,
        isValidCode,
        args: { onPasscodeConsumed },
      } = this;

      if (!isValidCode) break;

      const otpCheck: PrinterPasscodeModel = await store.findRecord('printer-passcode', printerPasscode.id, {
        reload: true,
        include: 'printer-connection',
      });

      if (otpCheck.consumedAt instanceof Date) {
        this.consumedCode = printerPasscode.code;

        if (otpCheck.printerConnectionId) {
          const printerConnection = await otpCheck.printerConnection;

          onPasscodeConsumed?.(printerConnection);

          break;
        }
      }

      await timeout(zft(5000));
    } while (!(IS_TESTING || this.isExpired));
  });

  simulatePasscodeConsumedTask = task(async () => {
    if (!this.isFactoryRecord) return;

    await timeout(zft(3000));

    this.printerPasscode.createdAt = new Date(this.printerPasscode.createdAt);

    await this.printerPasscode.save();
  });

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  async willDestroy(...args: Parameters<Component['willDestroy']>): Promise<void> {
    super.willDestroy(...args);
    await all([
      this.args.fetchOneTimePasscodeTask.cancelAll(),
      this.countdownTask.cancelAll(),
      this.monitorPasscodeStatusTask.cancelAll(),
    ]);
  }
}

import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import type Store from '@ember-data/store';
import type InfinityService from 'ember-infinity/services/infinity';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import ExtendedInfinityModel from 'garaje/infinity-models/v3-offset';
import type ZoneModel from 'garaje/models/zone';

import type ConnectPropertiesIndexController from './controller';

export type ConnectPropertiesIndexRouteModel = PaginatedRecordArray<ZoneModel>;

interface ModelParams {
  query: string;
  sortKey: string;
  sortDirection: string;
}

export default class ConnectPropertiesIndexRoute extends Route {
  queryParams = {
    query: { refreshModel: true },
    sortKey: { refreshModel: true },
    sortDirection: { refreshModel: true },
  };

  @service declare store: Store;
  @service declare infinity: InfinityService;

  #query = '';

  titleToken = 'Properties';

  model({ sortKey, sortDirection, query }: ModelParams): Promise<ConnectPropertiesIndexRouteModel> {
    const filter: Record<string, unknown> = { parent: null };
    filter['name'] = query;

    this.#query = query;

    const orderDescending = sortDirection === 'desc';
    const sortOrder = orderDescending ? '-' : '';

    return this.infinity.model(
      'zone',
      {
        filter,
        include: 'children',
        sort: `${sortOrder}${sortKey.toLowerCase()}`,
        perPage: 20,
        perPageParam: 'page[limit]',
        pageParam: 'page[offset]',
        startingPage: 0,
        countParam: 'meta.total',
      },
      ExtendedInfinityModel,
    );
  }

  setupController(
    controller: ConnectPropertiesIndexController,
    model: ConnectPropertiesIndexRouteModel,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);
    // only assign on page load and not after subsequent model refreshes to avoid concurrency / debounce issues with input
    if (isEmpty(controller._query)) controller._query = this.#query;
    controller.total = controller.model.meta.total;
  }
}

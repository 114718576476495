import RoomFragment from 'garaje/graphql/fragments/roomba/RoomFragment';
import gql from 'graphql-tag';

export default gql`
  mutation LinkRoomsWithLocation($roomIds: [ID!]!, $locationId: ID) {
    linkRoomsWithLocation(roomIds: $roomIds, locationId: $locationId) {
      ...RoomFragment
    }
  }
  ${RoomFragment}
`;

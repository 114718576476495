import Model, { attr, belongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

import type InvoiceEstimateModel from './invoice-estimate';

@adapter('billing-v1')
class EstimateModel extends Model {
  @belongsTo('invoice-estimate', { async: false }) declare currentInvoiceEstimate: InvoiceEstimateModel;
  @belongsTo('invoice-estimate', { async: false }) declare nextInvoiceEstimate: InvoiceEstimateModel;
  @attr('date') declare nextBillingAt: Date;
}

export default EstimateModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    estimate: EstimateModel;
  }
}

import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const ALLOWED_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN];

export default class EmployeeScreeningAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;

  get canApprove(): boolean {
    return this.canApprove;
  }

  get canDeny(): boolean {
    return this.canDeny;
  }

  get canReview(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(ALLOWED_ROLES, roleNames));
  }
}

import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { reads } from 'macro-decorators';

interface InvitesTableInviteListNameCellArgs {
  delay?: number;
  lineHeight?: number;
  lines?: number;
  model?: {
    [key: string]: unknown;
    additionalGuests?: number;
    fullName?: string;
    id?: string;
  };
  popperContainer?: string;
  route?: string;
  side?: string;
  text?: string;
  tooltipClass?: string;
}

export default class InvitesTableInviteListNameCell extends Component<InvitesTableInviteListNameCellArgs> {
  @tracked textWasTruncated: boolean = false;

  @reads('args.delay', 400) delay!: number;
  @reads('args.lineHeight', 1) lineHeight!: number;
  @reads('args.lines', 1) lines!: number;
  @reads('args.popperContainer', '.ui__page') popperContainer!: string;
  @reads('args.route', 'visitors.invites.show') route!: string;
  @reads('args.side', 'top') side!: string;
  @reads('args.tooltipClass', 'text-sm font-bold break-word max-w-sm z-50') tooltipClass!: string;

  contentElementId = guidFor(this);

  get data(): string {
    const { text, model } = this.args;

    return text || model?.fullName || '';
  }
}

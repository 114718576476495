import Route from '@ember/routing/route';

export default class AppStoreWithOnePathRoute extends Route {
  model({ path }) {
    const segments = [path.includes('directory') ? null : 'apps', path];

    return {
      path: `/${segments.compact().join('/')}`,
    };
  }
}

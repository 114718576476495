import Controller from '@ember/controller';
import { service } from '@ember/service';
import urlBuilder from 'garaje/utils/url-builder';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class LocationOverviewProtectAnalyticsReportsController extends Controller {
  @service messageBus;
  @service state;
  @service localStorage;

  @tracked loginRequired = false;
  @tracked showModal = false;
  @tracked modalIframeUrl;
  @tracked modalData;

  constructor() {
    super(...arguments);
    this.messageBus.on('embedded-app-message', this.handleMessage);
  }

  /**
   * @return {String}
   */
  get iframeUrl() {
    const {
      currentCompany: { id: companyId },
    } = this.state;
    return urlBuilder.embeddedProtectGlobalReportsUrl(companyId, this.loginRequired);
  }

  @action
  handleMessage(message) {
    const data = message?.query?.data;
    const {
      currentCompany: { id: companyId },
    } = this.state;
    if (message.event === 'showProtectGlobalSRModal') {
      this.modalData = data;
      this.modalIframeUrl = urlBuilder.embeddedProtectGlobalReportsModalUrl(companyId, this.loginRequired);
      this.showModal = true;
    } else if (message.event === 'closeProtectGlobalSRModal') {
      this.showModal = false;
    } else if (message.event === 'showToaster') {
      const type = message.type;
      const msg = message.msg;
      this.flashMessages.showAndHideFlash(type, msg);
    }
  }

  @action
  trackLoggedIn() {
    /**
     * Issue: When user logs out from the Garaje app there is no way Next.js app knows it also needs to logout.
     * Due to this the cookies of the logout user may persist.
     * Solution: After login, force login the user on his first visit to this app. This refreshes all the cookies
     * To know if the user is visiting for the first time we will maintain a global state in localstorage called 'wa_logged_in'
     * On Login -> wa_logged_in does not exists in localstorage -> its first visit
     * On first visit -> SignIn, set wa_logged_in = true in localstorage
     * On Logout -> Remove wa_logged_in
     */

    const waLoggedIn = this.localStorage.getItem('wa_logged_in');

    if (!waLoggedIn) {
      this.loginRequired = true;
      this.localStorage.setItem('wa_logged_in', true);
    } else {
      this.loginRequired = false;
    }
  }
}

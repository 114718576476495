import Component from '@glimmer/component';
import moment from 'moment-timezone';
import RecurringRule from 'garaje/models/recurring-rule';
import { localCopy } from 'tracked-toolbox';

/**
 * @param {String}        expectedArrivalTime
 * @param {Function}      onChange
 * @param {String}        selected
 * @param {Boolean}       isReadonly
 */

export default class InvitesRecurringInvitesComponent extends Component {
  @localCopy('args.onChange', () => () => {}) onChange;

  get rruleOptions() {
    return this.updateRruleOptions(this.args.expectedArrivalTime);
  }

  get selected() {
    return this.args.selected || { display: 'Does not repeat', value: '' };
  }

  weekOfMonth(date) {
    return Math.ceil(moment(date).date() / 7);
  }

  isLastInstanceOfDayInMonth(date) {
    // If next week is next month, then this is the last instance of a day this month
    return this.weekOfMonth(moment(date).add(1, 'week').toDate()) === 1;
  }

  updateRruleOptions(date) {
    const rruleOptions = [{ display: 'Does not repeat', value: '' }];
    const dayOfWeek = date ? moment(date).format('dddd') : '';
    const monthDay = date ? moment(date).format('MMMM D') : '';
    const numToOrd = {
      1: 'first',
      2: 'second',
      3: 'third',
      4: 'fourth',
      5: 'fifth',
      '-1': 'the last',
    };
    const week = this.weekOfMonth(date);

    const weeklyStr = function () {
      if (date) {
        return `Weekly on ${dayOfWeek}`;
      }
      return 'Weekly';
    };

    const monthlyStr = function (idx = week) {
      if (date) {
        return `Monthly on ${numToOrd[`${idx}`]} ${dayOfWeek}`;
      }
      return 'Monthly';
    };

    const yearlyStr = function () {
      if (date) {
        return `Annually on ${monthDay}`;
      }
      return 'Annually';
    };

    const daily = {
      display: 'Daily',
      value: new RecurringRule({ frequency: 'DAILY' }).toString(),
    };

    const weekly = {
      display: weeklyStr(),
      value: new RecurringRule({
        frequency: 'WEEKLY',
        byDay: moment(date).format('dd').toUpperCase(),
      }).toString(),
    };

    const monthly = {
      display: monthlyStr(),
      value: new RecurringRule({
        frequency: 'MONTHLY',
        byDay: `${this.weekOfMonth(date)}${moment(date).format('dd').toUpperCase()}`,
      }).toString(),
    };

    const lastDay = {
      display: monthlyStr(-1),
      value: new RecurringRule({
        frequency: 'MONTHLY',
        byDay: `-1${moment(date).format('dd').toUpperCase()}`,
      }).toString(),
    };

    const yearly = {
      display: yearlyStr(),
      value: new RecurringRule({
        frequency: 'YEARLY',
        byMonth: moment(date).format('M'),
        byMonthDay: moment(date).format('D'),
      }).toString(),
    };

    const weekdays = {
      display: 'Every weekday (Monday to Friday)',
      value: new RecurringRule({ frequency: 'WEEKLY', byDay: 'MO,TU,WE,TH,FR' }).toString(),
    };

    const options = [
      daily,
      weekly,
      ...(week <= 4 ? [monthly] : []),
      ...(this.isLastInstanceOfDayInMonth(date) ? [lastDay] : []),
      yearly,
      weekdays,
    ];

    return [...rruleOptions, ...options];
  }
}

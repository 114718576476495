import Service from '@ember/service';
import { isEmpty } from '@ember/utils';
import { set } from '@ember/object';
import stream from 'stream';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';

import config from 'garaje/config/environment';
export default class StreamService extends Service {
  @service ajax;

  // eslint-disable-next-line ember/classic-decorator-hooks
  init() {
    super.init(...arguments);
    set(this, 'client', stream.connect(config.streamApiKey, null, config.streamAppId));
  }

  @dropTask
  *configureFeedsForLocationTask(locationId) {
    const { data: { locations } = { locations: [] } } = yield this.ajax.request(`${config.apiHost}/a/graphql`, {
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        variables: { id: locationId },
        query: `
          query LocationsQuery($id: [ID!]) {
            locations(id: $id) {
              feeds {
                id
                group
                token
              }
            }
          }
        `,
      }),
    });
    if (isEmpty(locations)) {
      return;
    }
    const { feeds } = locations[0];

    const { id: notificationsFeedId, token: notificationsFeedToken } = feeds[0];
    set(this, 'notificationsFeed', this.client.feed('notification', notificationsFeedId, notificationsFeedToken));

    const { id: timelineFeedId, token: timelineFeedToken } = feeds[1];
    set(this, 'timelineFeed', this.client.feed('timeline', timelineFeedId, timelineFeedToken));
  }
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type { DetailedChangeset } from 'ember-changeset/types';
import type PaperDeviceModel from 'garaje/models/paper-device';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { localCopy } from 'tracked-toolbox';

interface StaticQrEditDetailsArgs {
  /**
   * The "Paper Device" (changeset) to view and edit.
   */
  model: DetailedChangeset<PaperDeviceModel>;

  isSaving?: boolean;

  onClose?: () => void;
  onSave?: () => void;
  onDelete?: () => void;
  onRegenerate?: () => void;
}

export default class StaticQrEditDetails extends Component<StaticQrEditDetailsArgs> {
  @service declare flashMessages: FlashMessagesService;

  @localCopy('args.onSave', () => () => {}) onSave!: () => void;

  @action
  onClipboardSuccess(): void {
    this.flashMessages.showAndHideFlash('success', 'Sign-in link copied');
  }

  @action
  handleKey(event: KeyboardEvent): void {
    if (event.key === 'Escape') this.args.onClose?.();
  }

  // Set up keyboard event listening when rendered to the DOM
  @action
  didInsert(): void {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    window?.addEventListener('keyup', this.handleKey);
  }

  // Tear down keyboard event listening
  willDestroy(...args: Parameters<Component['willDestroy']>): void {
    super.willDestroy(...args);

    // eslint-disable-next-line @typescript-eslint/unbound-method
    window?.removeEventListener('keyup', this.handleKey);
  }
}

import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import type Location from 'utils/location-record';

import type { GroupOption } from '../component';

export interface MultiLocationsSelectorLocationGroupRowComponentSignature {
  Args: {
    option: GroupOption;
    selectedLocations?: Location[];
    onSelected?: (locations: Location[]) => void;
    onGroupSelected?: (groupOption: GroupOption) => void;
  };

  Element: HTMLElement;
}

export default class MultiLocationsSelectorLocationGroupRowComponent extends Component<MultiLocationsSelectorLocationGroupRowComponentSignature> {
  inputId = 'checkbox-' + guidFor(this);

  get option(): GroupOption {
    return this.args.option;
  }

  get selectedLocations(): Location[] {
    return this.args.selectedLocations ?? [];
  }

  get checkedState(): string {
    const groupLocations = this.option.data.locations.map((l) => l);
    const selectedLocations = this.selectedLocations;

    if (groupLocations.every((l) => selectedLocations.includes(l))) {
      return 'checked';
    }

    if (groupLocations.some((l) => selectedLocations.includes(l))) {
      return 'indeterminate';
    }

    return '';
  }

  @action
  onGroup(): void {
    this.args.onGroupSelected?.(this.option);
    this.#syncCheckboxState();
  }

  /**
   * Clicking an "indeterminate" checkbox typically causes that checkbox to
   * become checked. Instead, we uncheck the input and deselect all group members.
   * To override the default behavior of HTML checkboxes, #syncCheckboxState
   * applies the current checkedState to the input element.
   */
  #syncCheckboxState(): void {
    const { inputId, checkedState } = this;
    const checkbox = <HTMLInputElement>document.getElementById(inputId);

    switch (checkedState) {
      case 'checked':
        checkbox.checked = true;
        checkbox.indeterminate = false;
        break;
      case 'indeterminate':
        checkbox.checked = false;
        checkbox.indeterminate = true;
        break;
      default:
        checkbox.checked = false;
        checkbox.indeterminate = false;
        break;
    }
  }
}

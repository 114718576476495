import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type FeatureFlagsService from 'garaje/services/feature-flags';

// export type LocationOverviewVirtualFrontDeskCallLogModel = {};

export default class LocationOverviewVirtualFrontDeskCallLogRoute extends Route {
  @service declare featureFlags: FeatureFlagsService;
  @service declare router: RouterService;

  queryParams = {
    from: { replace: true },
    until: { replace: true },
    location: { replace: true },
    sortBy: { replace: true },
    sortDirection: { replace: true },
    page: { replace: true },
  };
}

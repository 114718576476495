// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import Model, { attr } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('v2')
class PlanModel extends Model {
  @attr('string') declare app: string;
  @attr('string') declare name: string;
  @attr('string') declare planLevel: string;
  @attr('number') declare monthlyPrice: number;
  @attr('number') declare yearlyPrice: number;

  get customPrice(): boolean {
    return this.id === 'enterprise';
  }

  get fullYearlyPrice(): number {
    return this.yearlyPrice * 12;
  }

  @computed('app', 'planLevel')
  get normalizedPlanLevel(): string {
    // Convert a chargebee plan level on a downgrade plan to a user-facing plan level
    // this model gets used for both billing subscription downgrade plans AND envoy-web plans
    if (this.planLevel === 'standard-1') {
      // VR standard
      return 'standard';
    } else if (this.planLevel === 'standard' && this.app === 'deliveries') {
      // Deliveries Standard
      return 'premium';
    } else {
      return this.planLevel;
    }
  }
}

export default PlanModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    plan: PlanModel;
  }
}

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';

/**
 * @param {Task}                        confirmTicketDeletionModalTask   Required - Task that controls the modal
 */
export default class ConfirmCategoryDeleteModalComponent extends Component {
  @dropTask
  *onSubmitTask(e) {
    e.preventDefault();
    const { confirmTicketDeletionModalTask } = this.args;

    yield confirmTicketDeletionModalTask.last.continue();
  }

  @action
  onCancel() {
    const { confirmTicketDeletionModalTask } = this.args;
    confirmTicketDeletionModalTask.last.abort();
  }
}

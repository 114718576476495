import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type VisitorsOnboardingService from 'garaje/services/visitors-onboarding';

import type DevicesController from './controller';

export default class VisitorsDevicesRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare visitorsOnboarding: VisitorsOnboardingService;
  @service declare router: RouterService;

  titleToken = 'Devices';

  beforeModel(): void {
    if (this.abilities.cannot('visit devices') && this.abilities.cannot('view-nav-item printers')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  async model(): Promise<void> {
    await this.visitorsOnboarding.loadSteps();
  }

  redirect(_model: unknown, transition: Transition): void {
    this.visitorsOnboarding.gateRoute(this, transition);
  }

  setupController(controller: DevicesController, model: unknown, transition: Transition): void {
    super.setupController(controller, model, transition);
    void this.visitorsOnboarding.loadImpressionsTask.perform();
  }
}

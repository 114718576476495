import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask, lastValue } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { action, get, set, setProperties } from '@ember/object';
import { alias } from 'macro-decorators';
import { findAllDeliveryAreasForLocation } from 'garaje/utils/delivery-area';
import { dependentKeyCompat } from '@ember/object/compat';

class Step {
  @tracked reviewed;
  @tracked skipped;
  @tracked completed;

  constructor(args) {
    Object.assign(this, args);
  }

  @dependentKeyCompat
  get done() {
    return this.reviewed || this.skipped || this.completed;
  }
}

export default class DeliveriesOnboardingStepsProviderComponent extends Component {
  @service metrics;
  @service router;
  @service state;
  @service store;

  @tracked _locationId;

  @alias('deliveryAreas.firstObject.id') deliveryAreaId;
  @lastValue('loadOnboardingStepsTask') prevSteps;
  @alias('state.currentLocation') currentLocation;

  get steps() {
    if (this._locationId && this._locationId === this.currentLocation.id) {
      return this.prevSteps;
    } else if (this.currentLocation.id && this._locationId) {
      return this.loadOnboardingStepsTask.perform();
    } else {
      return [];
    }
  }

  get deliveryAreas() {
    if (this._locationId && this._locationId === this.currentLocation.id) {
      return this.prevSteps;
    } else if (this.currentLocation.id && this._locationId) {
      return this.loadDeliveryAreasTask.perform();
    } else {
      return [];
    }
  }

  constructor() {
    super(...arguments);
    this.loadOnboardingStepsTask.perform();
    this.loadDeliveryAreasTask.perform();
  }

  @dropTask
  *loadOnboardingStepsTask() {
    const locationId = this.currentLocation.id;
    const locationSteps = yield this.store.query('locations-setup-guide-step', {
      filter: {
        location: locationId,
        product: 'deliveries',
      },
      include: 'setup-guide-step',
    });
    this._locationId = locationId;
    return this._setupSteps(this._createSteps(locationSteps));
  }

  @dropTask
  *loadDeliveryAreasTask() {
    const location = this.currentLocation;
    const areas = yield findAllDeliveryAreasForLocation(this.store, location);
    return areas.length > 0 ? areas.sortBy('createdAt') : [];
  }

  _createSteps(locationSteps) {
    return locationSteps.map((locationStep) => {
      const setupGuideStep = locationStep.setupGuideStep;
      return new Step({
        id: locationStep.id,
        title: get(setupGuideStep, 'title'),
        description: get(setupGuideStep, 'subtitle'),
        order: get(setupGuideStep, 'deliveriesSetupBarOrder'),
        page: get(setupGuideStep, 'page'),
        skippable: get(setupGuideStep, 'skippable'),
        started: false,
        skipped: locationStep.skipped,
        reviewed: locationStep.reviewed,
        completed: locationStep.completed,
        routeName: locationStep.routeName,
        firstViewedAt: locationStep.firstViewedAt,
      });
    });
  }

  _setupSteps(steps) {
    const _steps = steps.sortBy('order');
    const currentIdx = this._findCurrentIndex(_steps);
    return _steps.map((step, idx) => {
      // Let upcoming steps appear as needing review, even if completed already
      if (idx > currentIdx) {
        step.reviewed = false;
      }
      return step;
    });
  }

  _findCurrentIndex(steps) {
    const current = steps.findBy('done', false);
    return steps.indexOf(current);
  }

  @action
  async review(step) {
    const { routeName } = step;
    if (routeName) {
      let promise;
      if (routeName) {
        if (routeName.match(/\.delivery-areas\./) !== null) {
          promise = this.router.transitionTo(routeName, this.deliveryAreaId);
        } else {
          promise = this.router.transitionTo(routeName);
        }
        promise.then(() => {
          if (routeName.match(/deliveries/) === null) {
            step.reviewed = true;
            this.update(step);
          }
        });
      }
    }
  }

  @action
  async update(step) {
    const locationStep = this.store.peekRecord('locations-setup-guide-step', step.id);
    setProperties(locationStep, { skipped: step.skipped, reviewed: step.reviewed });
    await locationStep.save();
    const { reviewed, skipped } = locationStep;
    setProperties(step, { reviewed, skipped });
  }

  @action
  async dismiss() {
    set(this.state, 'showDeliveriesOnboarding', false);
    set(this.state.currentLocation, 'deliveriesOnboardingComplete', true);
    const eventName = 'Deliveries Onboarding - Completion Banner Closed';
    this.metrics.trackEvent(eventName);
    return await this.state.currentLocation.save();
  }

  @action
  trackSkip(banner_cta_title, current_completion_percentage) {
    const eventName = 'Deliveries Onboarding - Skip Button Clicked';
    this.metrics.trackEvent(eventName, { banner_cta_title, current_completion_percentage });
  }

  @action
  trackStart(banner_cta_title, current_completion_percentage) {
    const eventName = 'Deliveries Onboarding - Start Button Clicked';
    this.metrics.trackEvent(eventName, { banner_cta_title, current_completion_percentage });
  }
}

import Service, { service } from '@ember/service';
import UserDocumentTemplate from 'garaje/models/user-document-template';
import type LocalStorageService from 'garaje/services/local-storage';
import { ConnectionRequestConfigModalDefinition } from 'garaje/utils/connect-modal-definitions';

export default class LoginModalService extends Service {
  @service declare localStorage: LocalStorageService;

  /**
   * Resets all login modals configured in the login-modal component to a non dismissed state.
   */
  reset(): void {
    UserDocumentTemplate.validIdentifiers.forEach((identifier) => {
      this.localStorage.removeItem(`dismissed-${identifier}`);
    });

    this.localStorage.removeItem(`dismissed-${ConnectionRequestConfigModalDefinition.identifier}`);
  }
}

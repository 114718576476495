import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * @param {Number}              pageCount
 * @param {Number}              pageNumber
 * @param {Number}              pageSize
 * @param {Number}              recordCount
 * @param {Function}            setPageNumber
 */
export default class UiPagination extends Component {
  get hasPreviousPage() {
    return this.args.pageNumber > 1;
  }

  get end() {
    if (this.isLastPage) {
      return this.start + this.lastPageSize - 1;
    } else {
      return this.start + this.args.pageSize - 1;
    }
  }

  get isLastPage() {
    return this.args.pageCount === 0 || this.args.pageNumber === this.args.pageCount;
  }

  get hasNextPage() {
    return this.args.pageNumber < this.args.pageCount;
  }

  get lastPageSize() {
    const recordCount = this.args.recordCount;
    const pageSize = this.args.pageSize;
    const remainder = recordCount % pageSize;

    if (remainder === 0) {
      return pageSize;
    } else {
      return remainder;
    }
  }

  get nextPageNumber() {
    return this.args.pageNumber + 1;
  }

  get previousPageNumber() {
    const previousPageNumber = this.args.pageNumber - 1;

    if (previousPageNumber > 0) {
      return previousPageNumber;
    } else {
      return 1;
    }
  }

  get start() {
    const pageNumber = this.args.pageNumber;
    const pageSize = this.args.pageSize;

    return pageNumber * pageSize - pageSize + 1;
  }

  @action
  goToNextPage() {
    const hasNextPage = this.hasNextPage;
    const nextPageNumber = this.nextPageNumber;

    if (hasNextPage) {
      this.args.setPageNumber(nextPageNumber);
    }
  }

  @action
  goToPreviousPage() {
    const hasPreviousPage = this.hasPreviousPage;
    const previousPageNumber = this.previousPageNumber;

    if (hasPreviousPage) {
      this.args.setPageNumber(previousPageNumber);
    }
  }
}

export interface BinaryDownloadModalOption {
  value: string;
  name: string;
}

export const OPERATING_SYSTEM_OPTIONS: BinaryDownloadModalOption[] = [
  { value: 'linux', name: 'Linux' },
  { value: 'windows', name: 'Windows' },
];

export const PLATFORM_ARCHITECTURE_OPTIONS: BinaryDownloadModalOption[] = [
  { value: 'amd64', name: 'AMD64' },
  { value: 'arm', name: 'ARM' },
  { value: '386', name: '386' },
];

import Component from '@glimmer/component';

const IMAGES = {
  'analytics-marketing-1': '/assets/images/dashboard/analytics-marketing-1.svg',
  'deliveries-marketing-1': '/assets/images/dashboard/deliveries-marketing-1.svg',
  'desks-marketing-1': '/assets/images/dashboard/desks-marketing-1.svg',
  'helpdesk-1': '/assets/images/dashboard/helpdesk-1.svg',
  'maps-marketing-1': '/assets/images/dashboard/maps-marketing-1.svg',
  'rooms-marketing-1': '/assets/images/dashboard/rooms-marketing-1.svg',
  'visitors-marketing-1': '/assets/images/dashboard/visitors-marketing-1.svg',
  'workplace-marketing-1': '/assets/images/dashboard/workplace-marketing-1.svg',
};

export default class DashboardImage extends Component<{
  name: keyof typeof IMAGES;
}> {
  get path(): string {
    return IMAGES[this.args.name];
  }
}

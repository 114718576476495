import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';

interface SelectAllCheckboxArgs {
  selectedItemCount: number;
  totalItemCount: number;
}

export default class SelectAllCheckbox extends Component<SelectAllCheckboxArgs> {
  checkboxId = `select-all-checkbox-${guidFor(this)}`;

  get allItemsSelected(): boolean {
    return this.args.totalItemCount > 0 && this.args.selectedItemCount === this.args.totalItemCount;
  }

  get someItemsSelected(): boolean {
    return this.args.selectedItemCount > 0;
  }

  get checkbox(): HTMLInputElement | null {
    return <HTMLInputElement>document.getElementById(this.checkboxId);
  }

  @action
  updateState(): void {
    if (this.checkbox) {
      this.checkbox.indeterminate = this.someItemsSelected && !this.allItemsSelected;
    }
  }
}

import Model, { attr, belongsTo } from '@ember-data/model';
import type CompanyModel from 'garaje/models/company';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('company-configuration')
class CompanyConfigurationModel extends Model {
  @belongsTo('company') declare company: CompanyModel;

  @attr('number') declare sessionInactivityTimeout: number | null;
}

export default CompanyConfigurationModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'company-configuration': CompanyConfigurationModel;
  }
}

import type NativeArray from '@ember/array/-private/native-array';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import type { AsyncHasMany } from '@ember-data/model';
import Model from '@ember-data/model';
import type AbstractSignInField from 'garaje/models/abstract/abstract-sign-in-field';

export default class AbstractSignInPageModel<T extends AbstractSignInField> extends Model {
  declare signInFields: AsyncHasMany<T>;

  @computed('signInFields.@each.isCustom')
  get customFields(): Promise<NativeArray<T>> {
    return this.signInFields.then((signInFields) => {
      return signInFields.filterBy('isCustom');
    });
  }

  @computed('signInFields.@each.kind')
  get hasPhone(): Promise<T | undefined> {
    return this.signInFields.then((signInFields) => {
      return signInFields.findBy('kind', 'phone');
    });
  }
}

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { addDays, addMonths, addYears, format, getUnixTime, startOfMonth, subMonths } from 'date-fns';
import { toZonedTime, fromZonedTime } from 'date-fns-tz';
import type StateService from 'garaje/services/state';

interface FormModalDatePickerArgs {
  startDate: string;
  setDate: (date: Date) => void;
}

export default class ModalFormDatePicker extends Component<FormModalDatePickerArgs> {
  @service declare state: StateService;

  @tracked dateSelected = false;
  @tracked showCalendar = false;
  @tracked showingDate: Date = this.currentDateAtOfficeTimezone;
  @tracked calendarDate: Date = this.currentDateAtOfficeTimezone;

  constructor(owner: unknown, args: FormModalDatePickerArgs) {
    super(owner, args);
    this.initialize();
  }

  initialize(): void {
    const { startDate, setDate } = this.args;
    if (startDate) {
      this.showingDate = new Date(startDate);
      this.calendarDate = new Date(startDate);
      setDate(this.calendarDate);
      this.dateSelected = true;
    }
  }

  @action
  reset(): void {
    this.dateSelected = false;
    this.showCalendar = false;
    this.showingDate = this.currentDateAtOfficeTimezone;
    this.calendarDate = this.currentDateAtOfficeTimezone;
  }

  @action
  selectDate(selectedDate: { unix: () => number }): void {
    const epochMilli = selectedDate.unix() * 1000;
    this.dateSelected = true;
    this.showingDate = new Date(epochMilli);
    this.args.setDate(this.showingDate);
  }

  @action
  toggleCalendar(value: boolean): void {
    this.showCalendar = value;
  }

  @action
  goBackOneMonth(): void {
    this.calendarDate = subMonths(this.calendarDate, 1);
    this.showCalendar = false;
  }

  @action
  goForwardOneMonth(): void {
    this.calendarDate = addMonths(this.calendarDate, 1);
    this.showCalendar = false;
  }

  get currentMonth(): string {
    return format(startOfMonth(this.calendarDate), 'yyyy-MM-dd');
  }

  @action
  closeCalendar(): void {
    this.reset();
  }

  get currentDateAtOfficeTimezone(): Date {
    const startDate = new Date();
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const officeTimeZone = this.state.currentLocation.timezone || 'UTC';
    const utcDate = fromZonedTime(startDate, userTimeZone);
    return toZonedTime(utcDate, officeTimeZone);
  }

  get showingDateToString(): string {
    if (this.dateSelected) {
      return this.showingDate.toLocaleString('default', {
        timeZone: this.state.currentLocation.timezone,
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      });
    } else {
      return 'Select a date';
    }
  }

  get dateLimits(): { startDate: number; endDate: number } {
    const limits: { startDate: number; endDate: number } = { startDate: 0, endDate: 0 };
    const locationDate = this.currentDateAtOfficeTimezone;
    limits.startDate = getUnixTime(addDays(locationDate, 1));
    limits.endDate = getUnixTime(addYears(locationDate, 2));
    return limits;
  }
}

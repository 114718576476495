/**
 * Used to explicitly map an ED Model to an adapter without relying on creating an adapter
 * with the same name.
 *
 * @param {String} adapterName
 * @returns Adapter
 */
export default function (adapterName) {
  return function (target) {
    target.adapterName = adapterName;
    return target;
  };
}

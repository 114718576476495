import Controller from '@ember/controller';
import { service } from '@ember/service';
import { get } from '@ember/object';
import { alias } from 'macro-decorators';

export default class VisitorsSettingsVisitorTypesFlowFinalScreenController extends Controller {
  @service('global-setting-batch') globalSettingBatchService;
  @service currentAdmin;
  @service currentLocation;

  isEditingCustomFinalMessage = false;

  @alias('globalSettingBatchService.currentGlobalSettingBatch') globalSettingBatch;
  @alias('currentLocation.languagesAvailable') translationLanguagesAvailable;

  get translationEnabledLocales() {
    return [get(this.currentLocation, 'enabledLocales'), get(this.currentLocation, 'locale')].flat().uniq();
  }
}

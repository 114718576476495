import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class WorkplaceAnnouncementsIndexRoute extends Route {
  title = 'Workplace · Announcements · Envoy';

  @service state;
  @service store;
  @service abilities;
  @service featureConfig;
  @service router;

  beforeModel() {
    if (this.abilities.can('visit announcements for workplace') && this.featureConfig.isEnabled('announcements')) {
      return;
    }
    this.router.transitionTo('unauthorized');
  }

  async model() {
    const { currentLocation } = this.state;
    const adapterOptions = { locationId: currentLocation.id };

    await this.store.findAll('announcement', { adapterOptions, reload: true });

    return hash({
      announcements: currentLocation.announcements,
    });
  }
}

import gql from 'graphql-tag';

import DeskFragment from './DeskFragment';

export default gql`
  fragment DeskMapFeatureFragment on DeskMapFeature {
    desk {
      ...DeskFragment
    }
  }
  ${DeskFragment}
`;

// utilization {
//   numberOfReservations
// }

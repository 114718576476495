import { attr } from '@ember-data/model';

/**
 * @returns {*}
 */
export default function addressAttrs(Class) {
  const DecoratedClass = class extends Class {
    @attr('string') address;
    @attr('string') addressLineOne;
    @attr('string') addressLineTwo;
    @attr('string') city;
    @attr('string') state;
    @attr('string') zip;
    @attr('string') country;
    @attr('number') latitude;
    @attr('number') longitude;
  };

  // reassign the original class name as the name of the decorated class
  Object.defineProperty(DecoratedClass, 'name', { value: Class.name });

  return DecoratedClass;
}

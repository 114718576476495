import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type EmergencyNotificationConfigurationModel from 'garaje/models/emergency-notification-configuration';
import type LocationModel from 'garaje/models/location';
import type StateService from 'garaje/services/state';
import { hash } from 'rsvp';

export interface EmergencyResponseSettingsRouteModel {
  currentLocation: LocationModel;
  emergencyNotificationConfiguration?: EmergencyNotificationConfigurationModel;
}
export default class EmergencyResponseSettingsRoute extends Route {
  @service declare state: StateService;
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  beforeModel(): void {
    if (this.abilities.cannot('manage-settings communications')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  async model(): Promise<EmergencyResponseSettingsRouteModel> {
    const currentLocation = this.state.currentLocation;
    const emergencyNotificationConfiguration = currentLocation.getEmergencyNotificationConfiguration({});

    return hash({
      currentLocation,
      emergencyNotificationConfiguration,
    });
  }
}

// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { equal, or } from '@ember/object/computed';
import Model, { attr } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('hub')
class EventReportModel extends Model {
  /**
   * Slack DM, Email, SMS
   */
  @attr('string') declare title: string;
  @attr('string') declare name: 'email_notification' | 'slack_notification' | 'sms_notification';

  /**
   * Map of statuses based on integer values
   */
  @attr('string') declare status: 'queued' | 'in_progress' | 'done' | 'failed' | 'expired' | 'ignored';
  @attr('string') declare statusMessage: 'Sent' | 'Failed to send' | 'Not sent';
  /**
   * e.g. "This person has turned off SMS notifications", empty unless message did not send
   */
  @attr('string') declare failureReason: string;

  @attr('date') declare startedAt: Date;
  @attr('date') declare endedAt: Date;

  /**
   * e.g. vr:entry:3
   */
  @attr('string') declare identifier: string;

  // computed properties
  @equal('status', 'queued') queued!: boolean;
  @equal('status', 'in_progress') inProgress!: boolean;
  @equal('status', 'done') done!: boolean;
  @equal('status', 'failed') failed!: boolean;
  @equal('status', 'expired') expired!: boolean;
  @equal('status', 'ignored') ignored!: boolean;

  @or('done', 'failed', 'expired', 'ignored') hasTerminalStatus!: boolean;
  @or('failed', 'expired', 'ignored') didNotSend!: boolean;

  get sourceId(): string {
    return `event-report/${this.id}`;
  }
}

export default EventReportModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'event-report': EventReportModel;
  }
}

import Component from '@glimmer/component';
import { get, set } from '@ember/object';
import { htmlSafe } from '@ember/template';

/**
 * @param {Object}                              field
 * @param {Object}                              output
 * @param {Object}                              install
 * @param {Object}                              plugin
 * @param {Service<CurrentLocation>}            currentLocation
 */
export default class PluginConfigField extends Component {
  constructor() {
    super(...arguments);

    if (this.args.field.key) {
      const initialValue = get(this.args.install, `config.${this.args.field.key}`);
      const value = initialValue === undefined ? this.defaultValue : initialValue;
      this.value = value;
    }
  }

  get label() {
    const { label, type } = this.args.field;
    return ['mapping', 'resource-mapping'].includes(type) ? `${label} to ${this.args.field.schema.label}` : label;
  }

  get safeNote() {
    return htmlSafe(this.args.field.note ?? '');
  }

  get componentForType() {
    const { type, multi, open } = this.args.field;
    const prefix = 'plugin-config-field';
    switch (type) {
      case 'select':
        if (multi) {
          return `${prefix}/${type}/multi`;
        }
        break;
      case 'link':
        return `${prefix}/${type}/${open}`;
      case 'resource':
        if (multi) {
          return `${prefix}/${type}/multi`;
        }
        break;
      case 'divider':
        return `${prefix}/note`;
    }
    return `${prefix}/${type}`;
  }

  get defaultValue() {
    const { type, multi, default_value: defaultValue } = this.args.field;
    if (defaultValue !== undefined) {
      return defaultValue;
    }
    switch (type) {
      case 'text':
        return '';
      case 'textarea':
        return '';
      case 'select':
        if (multi) {
          return [];
        }
        break;
      case 'resource':
        if (multi) {
          return [];
        }
        break;
      case 'password':
        return '';
      case 'mapping':
        return {};
      case 'checkbox':
        return false;
      default:
        return null;
    }
    return null;
  }

  get value() {
    return this.args.output[this.args.field.key];
  }

  set value(value) {
    set(this.args.output, `${this.args.field.key}`, value);
  }
}

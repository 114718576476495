import type { Permission } from './ui-permissions';

export type Role = {
  id: RoleId;
  companyId: string;
  name: string;
  type: RoleType;
  description: string;
  roleScope: RoleScopeType;
  legacyRole: LegacyRole;
  copiedFrom: CopiedFrom;
  parentRoleId: string;
  deactivated: boolean;
  permissionSets: Array<RolePermission>;
  assignmentCounts?: AssignmentCountsForRole;
};

export type RolePermission = {
  permissionSetId: string;
  effect: 'allow';
  permissions: Array<Permission>;
};

export type PermissionSet = {
  permissionSetId: string;
  effect: 'allow';
};

export type AssignmentCountsForRole = {
  user_count: number;
  employee_group_role_exists?: boolean;
};

export type AssignmentCountsPerRole =
  | {
      [key: string]: AssignmentCountsForRole;
    }
  | Record<string, never>;

export enum RoleType {
  DEFAULT = 'default',
  CUSTOM = 'custom',
}

export enum RoleScopeType {
  COMPANY = 'company',
  LOCATION = 'location',
  ZONE = 'zone',
}

export function getLegacyRoleName(roleName: string): string {
  switch (roleName) {
    case 'Security Admin':
      return LegacyRole.SECURITY_ADMIN;
    case 'Front Desk Admin':
      return LegacyRole.FRONT_DESK_ADMIN;
    case 'Billing Admin':
      return LegacyRole.BILLING_ADMIN;
    case 'Property Admin':
      return LegacyRole.ZONE_ADMIN;
    case 'Property Security Admin':
      return LegacyRole.ZONE_SECURITY_ADMIN;
    default:
      return roleName;
  }
}

enum LegacyRole {
  GLOBAL_ADMIN = 'Global Admin',
  LOCATION_ADMIN = 'Location Admin',
  FRONT_DESK_ADMIN = 'Receptionist',
  DELIVERIES_ADMIN = 'Deliveries Admin',
  SECURITY_ADMIN = 'Security',
  BILLING_ADMIN = 'Billing',
  ZONE_SECURITY_ADMIN = 'Zone Security Admin',
  ZONE_ADMIN = 'Zone Admin',
}

enum CopiedFrom {
  GLOBAL_ADMIN = 'Global Admin',
  LOCATION_ADMIN = 'Location Admin',
  FRONT_DESK_ADMIN = 'Front Desk Admin',
  DELIVERIES_ADMIN = 'Deliveries Admin',
  SECURITY_ADMIN = 'Security Admin',
  BILLING_ADMIN = 'Billing Admin',
  PROPERTY_SECURITY_ADMIN = 'Property Security Admin',
  PROPERTY_ADMIN = 'Property Admin',
}

export enum RoleId {
  GLOBAL_ADMIN = 'role_globalAdmin',
  BILLING_ADMIN = 'role_billingAdmin',
  ANALYTICS_VIEWER = 'role_analyticsViewer',
  LOCATION_ADMIN = 'role_locationAdmin',
  FRONT_DESK_ADMIN = 'role_frontDeskAdmin',
  SECURITY_ADMIN = 'role_securityAdmin',
  DELIVERIES_ADMIN = 'role_deliveriesAdmin',
  ZONE_ADMIN = 'role_zoneAdmin',
  ZONE_SECURITY_ADMIN = 'role_zoneSecurityAdmin',
  COMPANY_START_FROM_SCRATCH = 'role_companyStartFromScratch',
  LOCATION_START_FROM_SCRATCH = 'role_locationStartFromScratch',
  // ** not an admin **
  EMPLOYEE = 'role_employee',
}

export const roleIds: Array<string> = [
  RoleId.GLOBAL_ADMIN,
  RoleId.BILLING_ADMIN,
  RoleId.ANALYTICS_VIEWER,
  RoleId.LOCATION_ADMIN,
  RoleId.FRONT_DESK_ADMIN,
  RoleId.SECURITY_ADMIN,
  RoleId.DELIVERIES_ADMIN,
  RoleId.ZONE_ADMIN,
  RoleId.ZONE_SECURITY_ADMIN,
];

export type RoleAssignmentResponse = {
  data: Array<unknown>;
};

export function getRoleIcon(roleId: RoleId): string {
  if (!roleId) {
    return '';
  }

  switch (roleId) {
    case RoleId.GLOBAL_ADMIN:
      return '/assets/images/admin-icons/globe.svg';
    case RoleId.BILLING_ADMIN:
      return '/assets/images/admin-icons/billing.svg';
    case RoleId.ANALYTICS_VIEWER:
      return '/assets/images/admin-icons/analytics.svg';
    case RoleId.LOCATION_ADMIN:
      return '/assets/images/admin-icons/location.svg';
    case RoleId.FRONT_DESK_ADMIN:
      return '/assets/images/admin-icons/front-desk.svg';
    case RoleId.SECURITY_ADMIN:
      return '/assets/images/admin-icons/security.svg';
    case RoleId.DELIVERIES_ADMIN:
      return '/assets/images/admin-icons/deliveries.svg';
    case RoleId.ZONE_ADMIN:
      return '/assets/images/admin-icons/zone.svg';
    case RoleId.ZONE_SECURITY_ADMIN:
      return '/assets/images/admin-icons/zone-security.svg';
    default:
      return '/assets/images/admin-icons/custom.svg';
  }
}

/* eslint-disable ember/use-ember-get-and-set */
import Service, { service } from '@ember/service';
import type ZoneModel from 'garaje/models/zone';
import type CurrentAdminService from 'garaje/services/current-admin';
import { type DeviceResponse } from 'garaje/services/devices-manager';
import type DevicesManagerService from 'garaje/services/devices-manager';
import type PubnubService from 'garaje/services/pubnub';
import type { PubnubChannel, PubnubInstance } from 'garaje/services/pubnub';

interface ChannelConnectInviteEventData {
  event: 'new_connect_invite' | 'updated_connect_invite';
  connect_invite: {
    data: Record<string, string>;
  };
}

interface ChannelConnectDeletedInviteEventData {
  event: 'deleted_connect_invite';
  connect_invite: {
    data: Record<string, string>;
  };
}

interface ChannelConnectInviteReviewedEventData {
  event: 'reviewed_connect_invite';
  action: 'approve' | 'deny';
  reviewer_name: string;
  tenant_name: string;
  visitor_name: string;
}

export type ChannelConnectEventData =
  | ChannelConnectInviteEventData
  | ChannelConnectDeletedInviteEventData
  | ChannelConnectInviteReviewedEventData;

export default class CurrentZoneService extends Service {
  @service declare currentAdmin: CurrentAdminService;
  @service declare devicesManager: DevicesManagerService;
  @service declare pubnub: PubnubService;

  #pubnubConfig?: PubnubChannel;
  #pubnubInstance?: PubnubInstance;

  #timeTokens = new Map<string, string>();

  zone?: ZoneModel | null;

  /**
   * Used to indicate that the main zones list is
   * stale and needs to be refreshed when re-entering the the current-zone route.
   */
  zonesAreStale = false;

  async setup(zone: ZoneModel): Promise<void> {
    if (zone.id !== this.zone?.id) {
      this.teardown();
    }

    await this.#setupPubnub(zone.id);
    this.zone = zone;
  }

  teardown(): void {
    this.#teardownPubnub();
    this.zone = null;
  }

  async #setupPubnub(zoneId: string) {
    const pubnubConfig = await this.pubnub.fetchChannels.perform({
      userId: this.currentAdmin.id,
      zoneId,
    });
    this.#pubnubConfig = pubnubConfig;
    const pubnub = this.pubnub.initialize(pubnubConfig.cipherKey, 'current-zone');
    this.#pubnubInstance = pubnub;

    const devicesManager = this.devicesManager;
    pubnub.subscribe({
      channel: pubnubConfig.channelDevices,
      message(data: string) {
        const payload = <DeviceResponse>JSON.parse(data);
        devicesManager.handleEvent(payload);
      },
    });
  }

  subscribeToConnectInvites(onMessage: (payload: ChannelConnectEventData) => unknown): void {
    if (!this.#pubnubConfig || !this.#pubnubInstance) return;

    const channel = this.#pubnubConfig.channelConnectInvites;

    this.#pubnubInstance.subscribe({
      channel,
      timetoken: this.#timeTokens.get(channel) ?? '0',
      message: (data: string, [, timeToken]) => {
        this.#timeTokens.set(channel, timeToken);
        onMessage(<ChannelConnectEventData>JSON.parse(data));
      },
    });
  }

  unsubscribeToConnectInvites(): void {
    if (!this.#pubnubConfig || !this.#pubnubInstance) return;

    this.#pubnubInstance.unsubscribe({
      channel: [this.#pubnubConfig.channelConnectInvites],
    });
  }

  #teardownPubnub() {
    if (!this.#pubnubConfig || !this.#pubnubInstance) return;

    this.#pubnubInstance.unsubscribe({
      channel: [this.#pubnubConfig.channelDevices],
    });
  }
}

import { action } from '@ember/object';
import Component from '@glimmer/component';

interface MailerTemplateEditorAttributeCheckboxComponentSignature {
  Args: {
    onChangeValue?: (value: boolean) => void;
  };
}

export default class MailerTemplateEditorAttributeCheckboxComponent extends Component<MailerTemplateEditorAttributeCheckboxComponentSignature> {
  @action
  onChangeValue(e: Event): void {
    this.args.onChangeValue?.((<HTMLInputElement>e.target).checked);
  }
}

import Component from '@glimmer/component';

const FEATURES = {
  ndaEnabled: {
    src: '/assets/images/features/signature.svg',
    alt: 'NDA signing icon',
    title: 'NDA signing',
    subtitle: 'Visitors sign your NDA directly on the iPad',
  },
  visitorBadgePrinting: {
    src: '/assets/images/features/printer.svg',
    alt: 'Badge printer icon',
    title: 'Badge printing',
    subtitle: 'Automatically print badges as visitors sign in',
  },

  slack: {
    src: '/assets/images/sidebar/notification-sidebar-slack.svg',
    alt: 'Slack logo',
    title: 'Slack host notifications',
    subtitle: 'Alert employees via Slack when visitors arrive',
  },
  spark: {
    src: '/assets/images/features/integrations.svg',
    alt: '',
    title: 'Cisco Spark host notification',
    subtitle: 'Alert employees via Spark when visitors arrive',
  },
  chatter: {
    src: '/assets/images/features/logo/salesforce.svg',
    alt: '',
    title: 'Salesforce Chatter host notification',
    subtitle: 'Alert employees via Chatter when visitors arrive',
  },
  employees: {
    src: '/assets/images/empty-state/empty-employees-icon.svg',
    alt: 'Employees icon',
    title: 'Unlimited employees',
    subtitle: 'Everyone in your company can host visitors',
  },
  locations: {
    src: '/assets/images/plans/standard.svg',
    alt: 'Locations icon',
    title: 'Manage up to 5 locations',
    subtitle: "You'll need a paid subscription for each location",
  },
  visitorPhotos: {
    src: '/assets/images/features/selfie.svg',
    alt: 'Visitor photo icon',
    title: 'Visitor photos',
    subtitle: 'Capture photos for enhanced security',
  },
  smsNotification: {
    src: '/assets/images/features/sms.svg',
    alt: 'SMS icon',
    title: 'SMS host notifications',
    subtitle: 'Alert employees via SMS when visitors arrive',
  },
  analytics: {
    src: '/assets/images/features/analytics.svg',
    alt: 'Analytics icon',
    title: 'Analytics',
    subtitle: 'In-app analytics allow you to view sign-in trends',
  },
  googleSync: {
    src: '/assets/images/features/logo/google.svg',
    alt: '',
    title: 'Google employee sync integration',
    subtitle: 'Automatically sync employees from Google',
  },
  okta: {
    src: '/assets/images/features/logo/okta.svg',
    alt: '',
    title: 'Okta employee sync integration',
    subtitle: 'Automatically sync employees from Okta',
  },
  oneLogin: {
    src: '/assets/images/features/logo/onelogin.svg',
    alt: '',
    title: 'OneLogin employee sync integration',
    subtitle: 'OneLogin employee sync integration',
  },
  activeDirectory: {
    src: '/assets/images/features/integrations.svg',
    alt: '',
    title: 'Active Directory employee sync integration',
    subtitle: 'Automatically sync employees from AD',
  },
  salesforce: {
    src: '/assets/images/features/logo/salesforce.svg',
    alt: '',
    title: 'Salesforce integration',
    subtitle: 'Create a new lead for every Envoy visitor',
  },
  box: {
    src: '/assets/images/features/logo/box.svg',
    alt: '',
    title: 'Box integration',
    subtitle: 'File signed NDA automatically to Box',
  },
  dropbox: {
    src: '/assets/images/features/logo/dropbox.svg',
    alt: '',
    title: 'Dropbox integration',
    subtitle: 'Send signed NDAs automatically to Dropbox',
  },
  slideshow: {
    src: '/assets/images/features/slideshow.svg',
    alt: 'Slideshow icon',
    title: 'Slideshow',
    subtitle: 'Cycle full-screen images on your iPad',
  },
  saml: {
    src: '/assets/images/features/integrations.svg',
    alt: '',
    title: 'SAML single sign-on',
    subtitle: 'Enable single sign-on for increased security',
  },
  dashboardSignIn: {
    src: '/assets/images/features/pen.svg',
    alt: 'Pen icon',
    title: 'Sign in visitors from the dashboard',
    subtitle: 'Let VIP visitors skip the iPad',
  },
  assistants: {
    src: '/assets/images/features/assistant.svg',
    alt: '',
    title: 'Assistants',
    subtitle: 'Assign assistants to receive host notifications',
  },
  preregistration: {
    src: '/assets/images/features/prereg.svg',
    alt: 'Pre-registration icon',
    title: 'NDA can be signed before arrival',
    subtitle: 'Invited visitors can sign the NDA in advance',
  },
  plusOne: {
    src: '/assets/images/features/group-sign-in.svg',
    alt: 'Group sign-in icon',
    title: 'Plus one sign-in',
    subtitle: 'Allow one visitor to sign in on behalf of a group',
  },
  notifications: {
    src: '/assets/images/features/custom-host-notifications.svg',
    alt: 'Pencil icon',
    title: 'Custom host notification text',
    subtitle: 'Edit the content of your host notifications',
  },
  mailChimp: {
    src: '/assets/images/features/integrations.svg',
    alt: '',
    title: 'MailChimp integration',
    subtitle: 'Automatically add visitors to a MailChimp list',
  },
  unlimitedLocations: {
    src: '/assets/images/plans/premium.svg',
    alt: 'Locations icon',
    title: 'Manage unlimited locations',
    subtitle: "You'll need a paid subscription for each location",
  },
  customBadges: {
    src: '/assets/images/features/badge.svg',
    alt: 'Visitor badge',
    title: 'Create custom color badges',
    subtitle: "We'll print your design in color and ship for free",
  },
  customCSS: {
    src: '/assets/images/features/customcss.svg',
    alt: 'CSS browser icon',
    title: 'Customize badge CSS',
    subtitle: 'Take control over your badge layout and design',
  },

  flows: {
    src: '/assets/images/features/multi-visitor-types.svg',
    alt: 'Visitor type icon',
    title: 'Visitor types',
    subtitle: 'Custom sign-in flows based on purpose of visit',
  },
};

/**
 * @param {Boolean}   isBig
 * @param {String}    feature
 */
export default class FeaturePrompt extends Component {
  constructor() {
    super(...arguments);
    this.features = FEATURES;
  }

  get featureObject() {
    return this.features[this.args.feature];
  }

  get src() {
    return this.featureObject.src;
  }

  get title() {
    return this.featureObject.title;
  }

  get subtitle() {
    return this.featureObject.subtitle;
  }

  get alt() {
    return this.featureObject.alt;
  }
}

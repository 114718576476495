import ApplicationAdapter from './application';

export default class VisitorDocumentAdapter extends ApplicationAdapter {
  urlForCreateRecord(modelName, snapshot) {
    let url = this.buildURL(modelName, null, snapshot, 'v3');

    url +=
      '?include=user-document-template,user-document-links,user-document-attachments,user-document-attachments.user-document-template-attachment';

    return url;
  }
}

/* eslint-disable ember/require-tagless-components */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed, action, set } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import { service } from '@ember/service';
import type { DetailedChangeset } from 'ember-changeset/types';
import type { Task } from 'ember-concurrency';
import type SubscriptionModel from 'garaje/models/subscription';
import type StateService from 'garaje/services/state';

import type { SupportedAgreements } from '../component';
import type { SaveOptions } from '../edit-text/component';

const RESIGN_DAYS = 30;
const RESIGN_OPTIONS = ['', 'days', 'always'];

export default class ReSignComponent extends Component {
  declare changeset: DetailedChangeset<SupportedAgreements>;
  declare updateAndSaveTask: Task<void, [SaveOptions<keyof SupportedAgreements>]>;
  declare disabled: boolean;
  declare trackLegalDocument: () => Promise<void>;
  declare model: SupportedAgreements;
  declare _resignOption: string;
  declare propagable: boolean;
  declare vrSubscription: SubscriptionModel;

  @service declare state: StateService;

  isEditingResign = false;
  agreementDurationError = '';
  defaultDays = RESIGN_DAYS;

  @computed('agreementDurationError', 'changeset.{isDirty,isInvalid}', 'resignOption', 'updateAndSaveTask.isRunning')
  get isSaveResignDisabled(): boolean {
    const isInvalid = this.changeset.isInvalid;
    const isClean = !this.changeset.isDirty;
    const isRunning = this.updateAndSaveTask.isRunning;
    const noOption = this.resignOption === '';
    const hasError = this.agreementDurationError !== '';
    return isInvalid || isClean || isRunning || noOption || hasError;
  }

  get hasAccessToIncludesNdaFeature(): boolean {
    return Boolean(this.state.features?.includesNda);
  }

  @computed('changeset.duration', 'disabled', 'isEditingResign')
  get shouldOpenResignPanel(): boolean {
    return this.isEditingResign || (this.disabled && this.changeset.duration! > 0);
  }

  setupResignOption(): string {
    const days = this.changeset.duration;
    return days === null ? 'always' : days === 0 ? '' : 'days';
  }

  applyChangeset(changeset: DetailedChangeset<SupportedAgreements>): void {
    changeset.execute();
    changeset.rollback();
  }

  rollbackChangeset(changeset: DetailedChangeset<SupportedAgreements>): void {
    changeset.rollback();
  }

  isValidResignDays(value: string | number): boolean {
    const days = typeof value === 'string' ? parseInt(value, 10) : value;
    return !isNaN(days) && days > 0;
  }

  @action
  rollbackResignSetting(changeset: DetailedChangeset<SupportedAgreements>): void {
    this.rollbackChangeset(changeset);
    set(this, 'resignOption', this.setupResignOption());
    set(this, 'defaultDays', RESIGN_DAYS);
    set(this, 'agreementDurationError', '');
  }

  @action
  saveResignSetting(changeset: DetailedChangeset<SupportedAgreements>): void {
    this.applyChangeset(changeset);
    void this.updateAndSaveTask.perform({ model: this.model }).then(() => {
      set(this, 'isEditingResign', false);
      void this.trackLegalDocument();
    });
  }

  @action
  resignDaysOptionChanged(changeset: DetailedChangeset<SupportedAgreements>): void {
    set(this, 'resignOption', 'days');
    set(changeset, 'duration', this.defaultDays);
    // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
    scheduleOnce('afterRender', this, () => document.querySelector<HTMLInputElement>('#days-input')?.focus());
  }

  @action
  resignAlwaysOptionChanged(changeset: DetailedChangeset<SupportedAgreements>): void {
    set(this, 'resignOption', 'always');
    set(changeset, 'duration', null);
    if (!this.isValidResignDays(this.resignDays)) {
      set(this, 'defaultDays', RESIGN_DAYS);
    }
    set(this, 'agreementDurationError', '');
  }

  @action
  disableResignSetting(changeset: DetailedChangeset<SupportedAgreements>): void {
    set(this, 'resignOption', '');
    set(this, 'agreementDurationError', '');
    set(this, 'defaultDays', RESIGN_DAYS);
    set(changeset, 'duration', 0);
    if (this.changeset.isDirty) {
      this.applyChangeset(changeset);
      void this.updateAndSaveTask.perform({ model: this.model, propagable: this.propagable }).then(() => {
        set(this, 'isEditingResign', false);
        void this.trackLegalDocument();
      });
    } else {
      set(this, 'isEditingResign', false);
    }
  }

  @action
  validateResignDays(days: string): void {
    set(this, 'agreementDurationError', this.isValidResignDays(days) ? '' : 'Minimum is 1');
  }

  @computed('changeset.duration', 'defaultDays')
  get resignDays(): string | number {
    const defaultDays = this.defaultDays;
    const days = this.changeset.duration;
    const neverResign = days === 0;
    const alwaysResign = days === null;
    return neverResign || alwaysResign ? defaultDays : days;
  }

  set resignDays(value: string) {
    let _val: number | null = parseInt(value, 10);
    _val = isNaN(_val) ? null : _val;
    set(this.changeset, 'duration', _val);
    set(this, 'defaultDays', _val ? _val : RESIGN_DAYS);
  }

  get resignOption(): string {
    const value = this._resignOption;
    if (RESIGN_OPTIONS.includes(value)) {
      return value;
    } else {
      return this.setupResignOption();
    }
  }

  set resignOption(value: string) {
    if (RESIGN_OPTIONS.includes(value)) {
      this._resignOption = value;
    }
  }
}

import Component from '@glimmer/component';
import { action, set, setProperties } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import { RECEPTIONIST } from 'garaje/utils/roles';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class PreRegRequired extends Component {
  @service impressions;
  @service store;

  @tracked hasReceptionist = false;
  @tracked isOpen = false;

  /**
   * enables / disables button visibility
   *
   * @returns {boolean} disabled state
   */
  get isDisableButtonVisible() {
    const { location } = this.args;

    return (
      this.isOpen &&
      location.preRegistrationRequiredEnabled &&
      !('preRegistrationRequiredEnabled' in location.changedAttributes())
    );
  }

  @action
  cancel() {
    const { location } = this.args;
    if (location.hasDirtyAttributes) {
      location.rollbackAttributes();
    }
    this.isOpen = false;
  }

  @action
  toggleHostApprovalEnabled() {
    const { location } = this.args;
    set(location, 'hostApprovalEnabled', !location.hostApprovalEnabled);
  }

  @action
  enablePreregistrationRequired() {
    const { location } = this.args;
    set(location, 'preRegistrationRequiredEnabled', true);
    this.isOpen = true;
  }

  @action
  async disablePreregistrationRequired() {
    const { location } = this.args;
    setProperties(location, { preRegistrationRequiredEnabled: false, hostApprovalEnabled: false });
    await this.saveLocation();
  }

  @action
  async saveLocation() {
    const { location, saveTask } = this.args;
    await saveTask.perform(location);
    this.isOpen = false;
    await this.impressions.postImpression.perform(
      IMPRESSION_NAMES.VR_INVITES_PRE_REGISTRATION_REQUIRED[
        location.preRegistrationRequiredEnabled ? 'ENABLED' : 'DISABLED'
      ],
    );
  }

  /**
   * Task used for loading receptionists from the server if they don't already exist in the store
   */
  @dropTask
  *loadFrontDeskAdmin() {
    const { location } = this.args;
    const cachedReceptionists = this.store.peekAll('location-role').filter((role) => role.roleName === RECEPTIONIST);
    let receptionists = [];

    if (!cachedReceptionists.length) {
      receptionists = yield this.store.query('location-role', {
        filter: {
          location: location.id,
          roles: RECEPTIONIST,
        },
      });
    }

    this.hasReceptionist = cachedReceptionists.length > 0 || receptionists.length > 0;
  }
}

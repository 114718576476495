import { isBlank } from '@ember/utils';
import JSONAPISerializer from 'ember-data/serializers/json-api';

export default class LocationSubscriptionSerializer extends JSONAPISerializer {
  attrs = {
    requireFinanceTeamIntervention: {
      serialize: false,
    },
  };

  normalize() {
    const normalized = super.normalize(...arguments);
    const { attributes } = normalized.data;

    if (!isBlank(attributes.couponPercentOff) || !isBlank(attributes.couponAmountOff)) {
      attributes.coupon = {
        percentOff: attributes.couponPercentOff,
        amountOff: attributes.couponAmountOff,
      };
    }

    return normalized;
  }

  modelNameFromPayloadKey(payloadKey) {
    if (payloadKey === 'subscriptions') {
      return 'location-subscription';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }

  payloadKeyFromModelName(modelName) {
    if (modelName === 'location-subscription') {
      return 'subscriptions';
    } else {
      return super.payloadKeyFromModelName(...arguments);
    }
  }
}

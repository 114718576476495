import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type ScimPrimaryLocationMappingModel from 'garaje/models/scim-primary-location-mapping';
import type AjaxService from 'garaje/services/ajax';
import urlBuilder from 'garaje/utils/url-builder';
import _camelCase from 'lodash/camelCase';
import _mapKeys from 'lodash/mapKeys';

interface DistinctMappingValues {
  mappingColumn: string;
  distinctValues: Array<string>;
}

export default class ScimPrimaryLocationService extends Service {
  @service declare ajax: AjaxService;

  @tracked distinctMappingValues: DistinctMappingValues | undefined = undefined;

  async fetchDistinctMappingValues(scimPrimaryLocationConfigId: string): Promise<void> {
    if (scimPrimaryLocationConfigId) {
      const url: string = urlBuilder.v2.distinctMappingValues(scimPrimaryLocationConfigId);
      const response: Record<string, unknown> = await this.ajax.request(url, { type: 'GET' });
      this.distinctMappingValues = <DistinctMappingValues>(<unknown>_mapKeys(response, function (_value, key) {
        return _camelCase(key);
      }));
    }
  }

  unmappedDistinctValues(primaryLocMappings: Array<ScimPrimaryLocationMappingModel>): Array<string> {
    const unmappedDistinctValues: Array<string> = [];

    this.distinctMappingValues?.distinctValues.forEach((distinctValue: string) => {
      let existsInMapping = false;
      primaryLocMappings.forEach((primaryLocMapping: ScimPrimaryLocationMappingModel) => {
        if (!primaryLocMapping.isNew && distinctValue === primaryLocMapping.scimAttributeValue) {
          existsInMapping = true;
        }
      });

      if (!existsInMapping) {
        unmappedDistinctValues.push(distinctValue);
      }
    });

    return unmappedDistinctValues;
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { alias } from 'macro-decorators';
import { action, get } from '@ember/object';
import { dropTask, all } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

/**
 * @param {Function} onDeactivate
 * @param {Function} onClose
 */
export default class DeactivateDesksModal extends Component {
  @service state;
  @service store;
  @service flashMessages;
  @service featureFlags;

  @tracked listView = true;
  @tracked selectedDeskLocation;
  @tracked selectedFloor;
  @tracked selectedDesks = [];
  @tracked showConfirmDeactivation = false;
  @tracked maximumDesks = 25;

  @alias('state.activeDeskLocations') activeDeskLocations;
  @alias('state.currentLocation') currentLocation;

  constructor() {
    super(...arguments);

    this.loadLocationsWithFloors.perform();
  }

  get desksForDeactivation() {
    const numberOfDesksToDeactivate = this.activeDesksCount - this.args.maximumDesks;

    return numberOfDesksToDeactivate < this.selectedDesks.length
      ? 0
      : numberOfDesksToDeactivate - this.selectedDesks.length;
  }

  get activeDesksCount() {
    if (this.featureFlags.isEnabled('locationBilling')) {
      return this.activeDeskLocations.find((dl) => dl.id === this.currentLocation.id)?.assignableDesksQuantity || 0;
    }
    return this.activeDeskLocations.reduce((acc, dl) => acc + dl.assignableDesksQuantity, 0);
  }

  get deskLocationOptions() {
    if (this.featureFlags.isEnabled('locationBilling')) {
      return this.activeDeskLocations.filter((dl) => dl.id === this.currentLocation.id);
    }
    return this.activeDeskLocations.filter((dl) => dl.assignableDesksQuantity > 0);
  }

  get floorOptions() {
    return !this.selectedDeskLocation
      ? []
      : get(this, 'selectedDeskLocation.location.floors').filter((f) => get(f, 'desks').isAny('enabled'));
  }

  get activeDesks() {
    return !this.selectedFloor ? [] : this.selectedFloor.desks.filterBy('enabled');
  }

  get selectedDesksOnFloor() {
    return !this.selectedFloor ? [] : this.selectedDesks.filter((d) => get(d, 'floor.id') == this.selectedFloor.id);
  }

  @action
  setSelectedDeskLocation(location) {
    this.selectedDeskLocation = location;
    this.selectedFloor = get(this, 'selectedDeskLocation.location.floors.firstObject');
  }

  @action
  selectedDidChange(selected) {
    this.selectedDesks = selected;
  }

  @action
  clearAll() {
    this.selectedDesks.clear();
  }

  @action
  selectAllDesks(select = true) {
    select
      ? (this.selectedDesks = [...this.selectedDesks, ...this.activeDesks])
      : this.selectedDesks.removeObjects(this.selectedDesksOnFloor);
  }

  @action
  toggleSingleDesk(desk) {
    if (!this.selectedDesks.includes(desk)) {
      this.selectedDesks.pushObject(desk);
    } else {
      this.selectedDesks.removeObject(desk);
    }
  }

  @action
  deactivateSingleDesk(desk) {
    this.selectedDesks = [desk];
    this.showConfirmDeactivation = true;
  }

  @action
  deactivateAllDesksOnSelectedFloor() {
    this.selectedDesks = [...this.activeDesks];
    this.showConfirmDeactivation = true;
  }

  @dropTask
  *bulkDeactiveDesksTask() {
    this.showConfirmDeactivation = false;

    try {
      const desksToUpdate = [];

      this.selectedDesks
        .filter((desk) => desk.enabled)
        .forEach((desk) => {
          const locationId = get(desk, 'floor.location.id');
          desksToUpdate[locationId] = desksToUpdate[locationId] ?? [];

          desksToUpdate[locationId].push({
            enabled: false,
            id: desk.id,
            name: desk.name,
            floor: get(desk.floor, 'name'),
            assignedTo: desk.assignedTo,
            xPos: desk.xPos,
            yPos: desk.yPos,
          });
        });

      yield all(desksToUpdate.map((payload) => this.selectedFloor.bulkDesksUpdate(payload)));
      yield all(this.selectedDesks.mapBy('floor').uniqBy('id').invoke('reload', { include: 'desks' }));
      this.flashMessages.showAndHideFlash('success', 'Desk saved!');
      this.selectedDesks.clear();
      yield this.state.setDeskLocationsTask.perform();

      if (!this.deskLocationOptions.includes(this.selectedDeskLocation)) {
        this.selectedDeskLocation = this.deskLocationOptions.firstObject;
      }

      if (!this.floorOptions.includes(this.selectedFloor)) {
        this.selectedFloor = this.floorOptions.firstObject;
      }

      if (typeof this.args.onDeactivate === 'function') {
        this.args.onDeactivate();
      }
    } catch (e) {
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
    }
  }

  @dropTask
  *loadLocationsWithFloors() {
    yield all(this.deskLocationOptions.map((dl) => get(dl, 'location')));
    yield all(
      this.deskLocationOptions.map((dl) =>
        this.store.query('floor', { filter: { 'location-id': get(dl, 'location.id') } }),
      ),
    );

    this.selectedDeskLocation = this.deskLocationOptions.firstObject;
    this.selectedFloor = this.floorOptions.firstObject;
  }
}

import DeskFragment from 'garaje/graphql/fragments/employee-schedule/ScheduleDeskFragment';
import ScheduleEmployeeFragment from 'garaje/graphql/fragments/employee-schedule/ScheduleEmployeeFragment';
import gql from 'graphql-tag';

export default gql`
  fragment InviteFragment on Invite {
    id
    expectedArrivalTime
    preregistrationLink
    preregistrationStatusLink
    privateNotes
    additionalGuests
    isPresigned
    arrived
    touchlessSignInAvailableTime
    touchlessSignInPresenceRequired
    guestUpdatedAt
    entry {
      desk {
        ...DeskFragment
      }
    }
    entryId
    entrySignedOutAt
    employeeInvite @include(if: $includeEmployeeInvite) {
      id
      delegatedBooker {
        id
        name
        email
      }
    }
    employee {
      ...EmployeeFragment
    }
    visitor {
      avatarUrl
      email
      fullName
      phoneNumber
    }
    userData {
      field
      value
    }
    location {
      id
      name
      address
      logoUrl
      companyName
      timezone
    }
    flow {
      id
      name
      employeeCentric
    }
    approvalStatus {
      status
    }
  }
  ${DeskFragment}
  ${ScheduleEmployeeFragment}
`;

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type StateService from 'garaje/services/state';

export default class AttendanceRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare router: RouterService;

  title = 'Attendance by Employee · Analytics · Envoy';

  beforeModel(): void {
    if (this.abilities.cannot('visit attendance in global-analytics')) {
      void this.router.transitionTo('unauthorized');
    }
  }
}

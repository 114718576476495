import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { type TaskInstance } from 'ember-concurrency';
import type DeviceConfigStaticPageModel from 'garaje/models/device-config-static-page';
import type PropertyDeviceConfigModel from 'garaje/models/property-device-config';
import type ZoneModel from 'garaje/models/zone';

import type PropertySettingsKioskController from './controller';

export interface PropertySettingsKioskRouteModel {
  config: PropertyDeviceConfigModel;
  deviceConfigStaticPages: DeviceConfigStaticPageModel[];
}

export default class PropertySettingsKioskRoute extends Route {
  @service declare store: Store;

  beforeModel(): TaskInstance<PropertyDeviceConfigModel | undefined> {
    const property = <ZoneModel>this.modelFor('property');
    return property.loadConfigTask.perform();
  }

  async model(): Promise<PropertySettingsKioskRouteModel> {
    const property = <ZoneModel>this.modelFor('property');
    let deviceConfigStaticPages: DeviceConfigStaticPageModel[] = [];

    const records = await this.store.query('device-config-static-page', {
      filter: {
        property: property.id,
      },
      include:
        'device-config-static-page-attributes,device-config-static-page-attributes.device-config-static-page-attribute-translations',
    });

    deviceConfigStaticPages = records.toArray();

    return { config: property.config!, deviceConfigStaticPages };
  }

  resetController(controller: PropertySettingsKioskController, isExiting: boolean): void {
    if (isExiting) {
      controller.backTo = null;
    }
  }
}

import { A } from '@ember/array';
import { assert } from '@ember/debug';
import Service from '@ember/service';

const PLANS = [
  {
    id: 'basic',
    name: 'basic',
    features: [
      'Up to 50 employees',
      'Customizable sign-in flow',
      'Email notifications',
      'Unlimited visitors',
      'Unlimited iPads',
      '1 location',
    ],
  },
  {
    id: 'standard-1',
    name: 'standard',
    features: ['Badge printing', 'Legal documents', 'Unlimited employees', 'Slack notifications', 'Up to 5 locations'],
  },
  {
    id: 'premium',
    name: 'premium',
    features: [
      'Customized branding',
      'Directory integrations',
      'Multiple sign-in flows',
      'SMS notifications',
      'Visitor photos',
    ],
  },
  {
    id: 'enterprise',
    name: 'enterprise',
    features: ['Block list and watch list', 'ID scanning', 'Guest Wi-Fi integrations', 'Access control integrations'],
  },
];

export default class PlanFeaturesService extends Service {
  plans = A(PLANS);

  planIdToName(id: string): string {
    const plan = this.plans.findBy('id', id);
    if (typeof plan === 'undefined') {
      assert(`The plan id [${id}] you provided does not exist`);
    }
    return plan.name;
  }
}

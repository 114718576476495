import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import { GLOBAL_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_PROPAGATE_ROLES = [GLOBAL_ADMIN];

export default class GlobalSettingBatchAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare skinnyLocations: SkinnyLocationsService;

  get canPropagate(): boolean {
    const { roleNames } = this.currentAdmin;
    const totalLocations = this.skinnyLocations.currentCompanyLocations?.length;

    const hasCorrectRoles = isPresent(_intersection(CAN_PROPAGATE_ROLES, roleNames));

    return hasCorrectRoles && totalLocations > 1;
  }
}

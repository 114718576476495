// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import config from 'garaje/config/environment';
import type DeliveryModel from 'garaje/models/delivery';
import AjaxService from 'garaje/services/ajax';

export default class extends AjaxService {
  namespace = '/a/deliveries/api/v2';

  @computed('session.{data.authenticated.access_token,isAuthenticated}')
  get headers(): Record<string, string> {
    const headers: Record<string, string> = {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
      'X-Forwarded-Host': config.hostName,
    };

    if (this.session.isAuthenticated) {
      headers['Authorization'] = `Bearer ${<string>this.session.data.authenticated['access_token']}`;
    }

    return headers;
  }

  bulkUpdateAttribute(deliveries: DeliveryModel[], attribute: string, value: unknown): Promise<unknown> {
    const attributes: Record<string, unknown> = {};
    attributes[attribute] = value;
    const payload = {
      data: deliveries.map((delivery) => {
        return {
          type: 'deliveries',
          id: delivery.id,
          attributes,
        };
      }),
    };

    return this.patch('/deliveries/bulk', { data: payload });
  }

  bulkUpdateRelationship(deliveries: DeliveryModel[], relationship: string, id: string): Promise<unknown> {
    const relationships: Record<string, unknown> = {};
    relationships[relationship] = {
      data: { id },
    };
    const payload = {
      data: deliveries.map((delivery) => {
        return {
          type: 'deliveries',
          id: delivery.id,
          relationships,
        };
      }),
    };

    return this.patch('/deliveries/bulk', { data: payload });
  }

  bulkDelete(deliveries: DeliveryModel[]): Promise<unknown> {
    const payload = {
      ids: this.concatIds(deliveries),
    };

    return this.del('/deliveries/bulk', { data: payload });
  }

  bulkRenotify(deliveries: DeliveryModel[]): Promise<unknown> {
    const payload = {
      ids: this.concatIds(deliveries),
    };

    return this.post('/deliveries/bulk-renotify', { data: payload });
  }

  concatIds(deliveries: DeliveryModel[]): string {
    return deliveries.reduce(function (ids, delivery) {
      const deliveryId = delivery.id;
      return ids ? [ids, deliveryId].join(',') : deliveryId;
    }, '');
  }
}

import { helper } from '@ember/component/helper';

/**
 * The resource map uses leaflet latlng on a 1px equals 1 mapUnit scale
 * The backend saves coordinates on a
 */

export type LatLngObject = {
  lat: number;
  lng: number;
};

/**
 * Converts envoy x/y to leaflet Latlng
 *
 * @returns {object}
 */
export function toLatLngArray([[x, y], mapDimensions]: [number[], number[]]): number[] {
  const [mapWidth, mapHeight] = mapDimensions;
  return [-y! * mapHeight!, x! * mapWidth!];
}

export function toLatLngObject([x, y]: number[], mapDimensions: number[]): LatLngObject {
  const [mapWidth, mapHeight] = mapDimensions;
  return { lat: -y! * mapHeight!, lng: x! * mapWidth! };
}

export function toCoords({ lng, lat }: LatLngObject, [mapWidth, mapHeight]: number[]): { xPos: number; yPos: number } {
  return {
    xPos: lng / mapWidth!,
    yPos: Math.abs(lat / mapHeight!),
  };
}

export default helper(toLatLngArray);

import { get, set, action } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import type Model from '@ember-data/model';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import type LocationModel from 'garaje/models/location';
import type NotificationPreferenceModel from 'garaje/models/notification-preference';
import type PluginModel from 'garaje/models/plugin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import { SLACK_V2_PLUGIN_KEY } from 'garaje/utils/enums';
import type { ValueOf } from 'type-fest';

interface NotificationPreference {
  id: number;
  plugin: PluginModel;
  preference: NotificationPreferenceModel;
}

interface IntegrationsToggleComponentSignature {
  Args: {
    title: string;
    location: LocationModel;
    pluginPreferences: NotificationPreference[];
  };
}

export default class IntegrationsToggleComponent extends Component<IntegrationsToggleComponentSignature> {
  @service declare flashMessages: FlashMessagesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;

  @tracked title;

  slackV2PluginKey = SLACK_V2_PLUGIN_KEY;

  constructor(owner: unknown, args: IntegrationsToggleComponentSignature['Args']) {
    super(owner, args);
    this.title = this.args.title ?? 'Integrations';
  }

  get canAccessCall(): boolean {
    // eslint-disable-next-line ember/no-get
    return Boolean(get(this.args.location, 'company.callEnabled'));
  }

  get canAccessSlack(): boolean {
    return this.state.vrSubscription!.canAccessSlack;
  }

  get hasOldSlack(): boolean {
    // eslint-disable-next-line ember/no-get
    return !isEmpty(get(this.args.location, 'company.slackIntegration.id'));
  }

  get newSlack(): NotificationPreference[] {
    return this.args.pluginPreferences?.filter((preference) => preference.plugin.key === this.slackV2PluginKey);
  }

  get hasNewSlack(): boolean {
    return !isEmpty(this.newSlack);
  }

  get hasSlackEnabled(): boolean {
    return this.hasOldSlack || this.hasNewSlack;
  }

  saveTask = dropTask(async <T extends Model>(property: keyof T, value: ValueOf<T>, model: T) => {
    model = model || this.args.location;
    set(model, property, value);

    try {
      await model.save();

      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (_e) {
      model.rollbackAttributes();
    }
  });

  @action
  changeValue(model: Model, property: keyof Model, value: ValueOf<Model>): boolean {
    void this.saveTask.perform(property, value, model);

    return true;
  }
}

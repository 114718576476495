class ScimField {
  label = null;
  name = null;
  operators = [];

  constructor(args = {}) {
    Object.assign(this, args);
  }
}

export default ScimField;

import { action } from '@ember/object';
import { service } from '@ember/service';
import { type SyncHasMany } from '@ember-data/model';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type ConnectVisitorContactModel from 'garaje/models/connect-visitor-contact';
import type VisitorContactModel from 'garaje/models/visitor-contact';
import type FlashMessagesService from 'garaje/services/flash-messages';

type ContactType = VisitorContactModel | ConnectVisitorContactModel;

interface InviteContactPopoverArgs {
  /**
   * tenant name override to render
   */
  tenantName?: string;
  /**
   * Contacts to render
   */
  visitorContacts: ContactType[] | SyncHasMany<ContactType>;
  /**
   * photo url to override to render
   */
  photoUrl?: string;
}

export default class InviteContactPopover extends Component<InviteContactPopoverArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare store: Store;

  @tracked contactIndex = 0;

  get currentContact(): VisitorContactModel | ConnectVisitorContactModel | undefined {
    return Array.isArray(this.args.visitorContacts)
      ? this.args.visitorContacts[this.contactIndex]
      : this.args.visitorContacts.objectAt(this.contactIndex);
  }

  get currentTenantName(): string | undefined {
    if (this.args.tenantName) return this.args.tenantName;
    const tenantId = this.currentContact?.belongsTo('tenant').id();
    const tenant = tenantId ? this.store.peekRecord('tenant', tenantId) : undefined;

    return tenant?.name;
  }

  get photoUrl(): string | undefined {
    if (this.currentContact && 'photoUrl' in this.currentContact) return this.currentContact.photoUrl;
    return this.args.photoUrl;
  }

  @action
  copySuccess(): void {
    this.flashMessages.showAndHideFlash('success', 'Email copied.');
  }
}

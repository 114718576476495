import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { Task } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import type UserModel from 'garaje/models/user';
import type CurrentLocationService from 'garaje/services/current-location';
import type { RecordArray } from 'garaje/utils/type-utils';

interface PrinterNotificationsComponentSignature {
  Args: {
    /**
     * set to true if there are no printer at the location
     */
    hasPrinters: boolean;
    /**
     * set to true if printer notification is enabled
     */
    isEnabled: boolean;
    /**
     * list of users who will receive notifications
     */
    printerContacts: RecordArray<UserModel>;
    /**
     * task to save printer notifications contacts
     */
    savePrinterContactsTask: Task<void, []>;
    /**
     * task to search for users with keywords
     */
    searchUsersTask: Task<void, [string, UserModel[] | undefined]>;
    /**
     * task to enable/disable printer notifications
     */
    togglePrinterNotificationsEnabledTask: Task<void, []>;
  };
}

export default class PrinterNotificationsComponent extends Component<PrinterNotificationsComponentSignature> {
  @service declare currentLocation: CurrentLocationService;

  @tracked isOpen = false;
  @tracked hasChangedPrinterContacts = false;
  @tracked newPrinterContacts?: UserModel[];

  constructor(owner: unknown, args: PrinterNotificationsComponentSignature['Args']) {
    super(owner, args);

    const printerContacts = this.args.printerContacts;
    this.newPrinterContacts = printerContacts.toArray();
  }

  searchTask = task(async (term: string) => {
    return await this.args.searchUsersTask.perform(term, this.newPrinterContacts);
  });

  @action
  addPrinterContacts(users: UserModel[]): void {
    this.hasChangedPrinterContacts = true;
    this.newPrinterContacts = users;
  }
}

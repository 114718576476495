import Route from '@ember/routing/route';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class EmployeesImportRoute extends Route {
  @service abilities;
  @service currentLocation;
  @service router;
  @service state;

  titleToken = 'Import employees';

  beforeModel() {
    if (this.abilities.cannot('manage employees')) {
      this.router.transitionTo('unauthorized');
    }
  }

  async model() {
    return hash({
      isConnectedToProperty: this.state.currentLocation.isConnectedToProperty(),
    });
  }

  activate() {
    super.activate(...arguments);
    // running in next run loop to avoid active getting removed by ember default behavior when coming from employees
    next(this, function () {
      this.controller.isActive = true;
    });
  }

  deactivate() {
    super.deactivate(...arguments);
    this.controller.isActive = false;
  }
}

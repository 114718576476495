import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type LocationModel from 'garaje/models/location';
import type VfdScheduleModel from 'garaje/models/vfd-schedule';
import type { VfdScheduleRule } from 'garaje/models/vfd-schedule';
import type StateService from 'garaje/services/state';
import SimpleTime from 'garaje/utils/simple-time';
import TimeRange from 'garaje/utils/time-range';
import { TrackedArray, TrackedObject } from 'tracked-built-ins';

interface VirtualFrontDeskSettingsCallOperationsRouteModel {
  location: LocationModel;
  schedule: VfdScheduleModel;
}

export default class VirtualFrontDeskSettingsCallOperationsRoute extends Route {
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: Store;

  async model(): Promise<VirtualFrontDeskSettingsCallOperationsRouteModel | undefined> {
    const location = this.state.currentLocation;
    const vfdConfiguration = await location.getVfdConfiguration();
    if (!vfdConfiguration) {
      void this.router.transitionTo('virtual-front-desk');
      return;
    }

    let schedule = vfdConfiguration.schedule;
    if (!schedule) {
      schedule = this.store.createRecord('vfd-schedule', {
        configuration: vfdConfiguration,
        rules: this.#createDefaultRules(),
      });
    }

    return {
      location,
      schedule: vfdConfiguration.schedule,
    };
  }

  // Define a default set of schedule rules to use if nothing was returned from the server.
  // The default schedule is 24/7.
  #createDefaultRules(): VfdScheduleRule[] {
    const rules = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'].map((day) => {
      const interval = new TimeRange(SimpleTime.StartOfDayMidnight, SimpleTime.EndOfDayMidnight);
      return new TrackedObject({
        enabled: true,
        recurringRule: `FREQ=WEEKLY;BYDAY=${day}`,
        intervals: new TrackedArray([interval]),
      });
    });
    return new TrackedArray(rules);
  }
}

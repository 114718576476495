import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class SigninPreviewSetupModal extends Component {
  @service statsig;

  @action
  closeModal() {
    this.statsig.logEvent(`kiosk-demo-preview-modal-close-button-clicked`);
    this.args.close();
  }

  @action
  showIpadDemoModal() {
    this.statsig.logEvent(`kiosk-demo-preview-modal-accept-button-clicked`);
    this.args.acceptDemo();
  }
}

import Select from '../select/component';
import { action, get } from '@ember/object';
import { task, dropTask } from 'ember-concurrency';
import config from 'garaje/config/environment';

/**
 * @param {Object}                              field
 * @param {Object}                              output
 * @param {Object}                              install
 * @param {Object}                              plugin
 * @param {Service<CurrentLocation>}            currentLocation
 * @param {Function}                            updateValue
 */
export default class Resource extends Select {
  limit = 20;

  get resourceName() {
    return get(this.args.field, 'label_path');
  }

  @dropTask
  *fetchOptions(page = 1, search = '') {
    const offset = (page - 1) * this.limit;
    try {
      const prefix = this.args.field.location_subresource ? `locations/${get(this.args, 'currentLocation.id')}/` : '';
      const resourceUrl = `${config.apiOverwrittenHost}/a/visitors/api/v3/${prefix}${this.args.field.resource}`;
      const filters = {
        sort: this.args.field.sort_field || 'name',
        'page[limit]': this.limit,
        'page[offset]': offset,
      };
      if (this.args.field.searchable) {
        filters[`filter[${this.args.field.search_field || 'query'}]`] = search;
      }
      const { data } = yield this.ajax.request(resourceUrl, {
        headers: { accept: 'application/vnd.api+json' },
        contentType: 'application/vnd.api+json',
        data: filters,
      });
      return data;
    } catch (e) {
      let msg = '';
      try {
        msg = e.payload[0].detail.split('\n')[0];
      } catch (e) {
        // continue regardless of error
      }
      this.error = msg || 'Error loading options.';
      this.flashMessages.showFlash('error', `Error loading select options. ${msg}`);
    }
  }

  @task
  *fetchOption(id) {
    const selectedUrl = `${config.apiOverwrittenHost}/a/visitors/api/v3/${this.args.field.resource}/${id}`;
    const { data } = yield this.ajax.request(selectedUrl, {
      headers: { accept: 'application/vnd.api+json' },
      contentType: 'application/vnd.api+json',
    });
    return data;
  }

  @dropTask
  *fetchMissingSelection() {
    if (!this.value) {
      return;
    }
    try {
      return yield this.fetchOption.perform(this.value);
    } catch (e) {
      let msg;
      try {
        msg = e.payload[0].detail.split('\n')[0];
      } catch (e) {
        // continue regardless of error
      }
      this.error = msg ?? 'Error loading select value.';
      this.flashMessages.showFlash('error', `Error loading select value. ${msg}`);
    }
  }

  @action
  onChange(selected) {
    this.selected = selected;
    this.args.updateValue(selected ? selected.id : null);
  }

  @action
  onLoaded(options) {
    const selected = options.find(({ id }) => id == this.args.value);
    if (selected) {
      this.loading = false;
      return this.onChange(selected);
    }
    this.setInitialSelection.perform();
  }

  optionsAreEqual(a, b) {
    return (a && a.id) === (b && b.id);
  }
}

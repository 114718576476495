import { action } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { type Task, dropTask } from 'ember-concurrency';
import type EmployeeModel from 'garaje/models/employee';
import type EmployeeLocationModel from 'garaje/models/employee-location';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { filterBy, sortBy } from 'macro-decorators';

interface EditEmployeeLocationsSignature {
  Args: {
    employeeLocations: Array<EmployeeLocationModel>;
    employee: EmployeeModel;
    locations: Array<SkinnyLocationModel>;
    deleteEmployeeLocation: Task<void, [EmployeeLocationModel]>;
    disabled: boolean;
    defaultLocationId: string | null | undefined;
    showDeleteEmployeeModal?: () => void;
  };
}

interface AvailableLocation {
  location: SkinnyLocationModel;
  disabled: boolean;
  nameWithCompanyName: string;
}

export default class EditEmployeeLocations extends Component<EditEmployeeLocationsSignature> {
  @service declare flashMessages: FlashMessagesService;
  @service declare store: StoreService;

  @filterBy('args.employeeLocations', 'isNew', false) filteredEmployeeLocations: Array<EmployeeLocationModel> = [];
  @sortBy('filteredEmployeeLocations', 'manuallyAdded') sortedEmployeeLocations: Array<EmployeeLocationModel> = [];

  get availableLocations(): Array<AvailableLocation> {
    const employeeLocationIds = this.filteredEmployeeLocations.map((employeeLocation) =>
      employeeLocation.belongsTo('location').id(),
    );

    const employeeLocations: Array<AvailableLocation> = [];
    this.args.locations.forEach((location: SkinnyLocationModel) => {
      // only show locations that employee doesn't belong to yet
      if (!employeeLocationIds.includes(location.id)) {
        const disabled = !isEmpty(location.disabledToEmployeesAt);
        let nameWithCompanyName = location.nameWithCompanyName;
        if (disabled) {
          nameWithCompanyName += ' (Disabled)';
        }

        employeeLocations.push({
          location,
          disabled,
          nameWithCompanyName,
        });
      }
    });

    return employeeLocations.sort((a: AvailableLocation, b: AvailableLocation) => {
      if (!a.disabled && b.disabled) {
        return -1;
      }
      if (a.disabled && !b.disabled) {
        return 1;
      }
      return 0;
    });
  }

  get sortedLocationsWithDefault(): Array<EmployeeLocationModel> {
    const sortedLocationsWithDefault = this.sortedEmployeeLocations;

    if (this.args.defaultLocationId) {
      const index = sortedLocationsWithDefault.findIndex(
        (employeeLocation: EmployeeLocationModel) =>
          employeeLocation.locationId?.toString() === this.defaultLocationIdString,
      );

      if (index && index !== -1) {
        const defaultLocation: Array<EmployeeLocationModel> | undefined = sortedLocationsWithDefault.splice(index, 1);
        if (defaultLocation?.length === 1 && defaultLocation[0]) {
          sortedLocationsWithDefault.unshift(defaultLocation[0]);
        }
      }
    }

    return sortedLocationsWithDefault;
  }

  get defaultLocationIdString(): string {
    return this.args.defaultLocationId?.toString() || '';
  }

  get canAddLocation(): boolean {
    if (this.args.disabled) return false;
    return this.availableLocations.length > 0;
  }

  @action
  async deleteEmployeeLocation(employeeLocation: EmployeeLocationModel): Promise<void> {
    if (this.args.employeeLocations.length === 1 && this.args.showDeleteEmployeeModal) {
      this.args.showDeleteEmployeeModal();
    } else {
      await this.args.deleteEmployeeLocation.perform(employeeLocation);
    }
  }

  addToLocationTask = dropTask(async ({ location }: { location: SkinnyLocationModel }): Promise<void> => {
    const newEmployeeLocation: EmployeeLocationModel = this.store.createRecord('employee-location', {
      location,
      employee: this.args.employee,
      manuallyAdded: true,
    });

    try {
      await newEmployeeLocation.save();
      this.flashMessages.showAndHideFlash('success', 'Employee location added');
    } catch (error) {
      const errorText = parseErrorForDisplay(error);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });
}

import Service from '@ember/service';
import { getOwner } from '@ember/application';

/*
  Temporary class so we can use dependency injection to share the uploader
  between engines, until we can refactor the uploader to just upload into
  the browser and move the call to the server to the store/adapter where it
  belongs
*/
export default class UploaderFactory extends Service {
  createUploader(args) {
    return getOwner(this).factoryFor('util:uploader').create(args);
  }
}

import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import type FlowModel from 'garaje/models/flow';
import { FlowType } from 'garaje/utils/enums';
import _sortBy from 'lodash/sortBy';
import { cached } from 'tracked-toolbox';

interface MultiFlowSelectComponentArgs {
  flows: FlowModel[];
  selectedFlows: FlowModel[];
  triggerClass?: string;

  selectedFlowsChanged: (flows: FlowModel[]) => unknown;
}

const VISITOR_FLOW_TYPES = [FlowType.VISITOR_SCREENING, FlowType.GLOBAL_CHILD, FlowType.PROPERTY_WALKUP];

const DEFAULT_FLOW_TYPE = FlowType.VISITOR_SCREENING;

// Flow#type comes back from the real API as `null` for Visitor flows but the FlowType enum uses '' for VISITOR_SCREENING
function flowType(flow: FlowModel): FlowType {
  return flow.type ?? DEFAULT_FLOW_TYPE;
}

export default class MultiFlowSelectComponent extends Component<MultiFlowSelectComponentArgs> {
  get allVisitorFlowsCheckboxId(): string {
    return `${guidFor(this)}-all-visitor-flows`;
  }

  @cached
  get employeeFlows(): FlowModel[] {
    return this.args.flows.filter((flow) => flowType(flow) === FlowType.EMPLOYEE_SCREENING) ?? [];
  }

  get hasAllEmployeeFlowsSelected(): boolean {
    return (
      this.selectedEmployeeFlows.length > 0 &&
      this.employeeFlows.every((flow) => this.selectedEmployeeFlows.includes(flow))
    );
  }

  @cached
  get hasAllVisitorFlowsSelected(): boolean {
    return (
      this.selectedVisitorFlows.length > 0 &&
      this.visitorFlows.every((flow) => this.selectedVisitorFlows.includes(flow))
    );
  }

  get hasNoEmployeeFlowsSelected(): boolean {
    return this.selectedEmployeeFlows.length === 0;
  }

  get hasNoVisitorFlowsSelected(): boolean {
    return this.selectedVisitorFlows.length === 0;
  }

  get hasSomeEmployeeFlowsSelected(): boolean {
    return this.selectedEmployeeFlows.length > 0;
  }

  get hasSomeVisitorFlowsSelected(): boolean {
    return this.selectedVisitorFlows.length > 0 && this.selectedVisitorFlows.length !== this.visitorFlows.length;
  }

  get labelText(): string {
    if (this.args.selectedFlows.length === 1) {
      // when only one flow is selected, use its name as the label
      return this.args.selectedFlows[0]!.name;
    } else if (this.hasAllVisitorFlowsSelected && this.hasNoEmployeeFlowsSelected) {
      return 'All Visitor types';
    } else if (this.hasAllEmployeeFlowsSelected && this.hasNoVisitorFlowsSelected) {
      return 'All Employee types';
    }
    return 'Multiple types';
  }

  @cached
  get selectedEmployeeFlows(): FlowModel[] {
    return this.args.selectedFlows.filter((flow) => flowType(flow) === FlowType.EMPLOYEE_SCREENING);
  }

  @cached
  get selectedVisitorFlows(): FlowModel[] {
    return this.args.selectedFlows.filter((flow) => VISITOR_FLOW_TYPES.includes(flowType(flow)));
  }

  get tooltipText(): string | null {
    if (this.args.selectedFlows.length === 0) return null;
    return this.args.selectedFlows
      .map((flow) => flow.name)
      .sort()
      .join(', ');
  }

  @cached
  get visitorFlows(): FlowModel[] {
    return this.args.flows.filter((flow) => VISITOR_FLOW_TYPES.includes(flowType(flow)));
  }

  @action
  selectOrDeselectAllVisitorFlows(): void {
    if (this.hasAllVisitorFlowsSelected) {
      // deselect all
      this.updateVisitorFlowSelection([]);
    } else {
      // select all
      this.updateVisitorFlowSelection(this.visitorFlows);
    }
  }

  @action
  updateEmployeeFlowSelection(selectedFlows: FlowModel[]): void {
    const allSelectedFlows = [...this.selectedVisitorFlows, ...selectedFlows];

    this.args.selectedFlowsChanged(allSelectedFlows);
  }

  @action
  updateVisitorFlowSelection(selectedFlows: FlowModel[]): void {
    const allSelectedFlows = _sortBy([...this.selectedEmployeeFlows, ...selectedFlows], 'id');

    this.args.selectedFlowsChanged(allSelectedFlows);
  }
}

import Model, { attr, belongsTo } from '@ember-data/model';
import type VfdConfiguration from 'garaje/models/vfd-configuration';
import type TimeRange from 'garaje/utils/time-range';

export type VfdScheduleRule = {
  enabled: boolean;
  recurringRule: string;
  intervals: TimeRange[];
};

class VfdSchedule extends Model {
  @belongsTo('vfd-configuration', { async: false }) declare configuration: VfdConfiguration;

  @attr('vfd-schedule-rules') declare rules: VfdScheduleRule[];
}

export default VfdSchedule;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface VfdSchedule {
    'vfd-schedule': VfdSchedule;
  }
}

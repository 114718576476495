// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import Model, { attr } from '@ember-data/model';
import type { ValueOf } from 'type-fest';

// Hard coded for now until we can get from the api
export const GROUP_OPERATION_OPTIONS = {
  AND: 'and',
  OR: 'or',
} as const;

export const RULE_OPERATION_OPTIONS = {
  IS: 'is',
  IS_NOT: 'is not',
} as const;

export const RULE_ACTION_OPTIONS = {
  DENY: 'deny_entry',
  ALERT: 'alert',
  REQUIRE_APPROVAL: 'require_approval',
} as const;

export type GroupOperator = ValueOf<typeof GROUP_OPERATION_OPTIONS>;
export type RuleAction = ValueOf<typeof RULE_ACTION_OPTIONS>;
export type RuleOperation = ValueOf<typeof RULE_OPERATION_OPTIONS>;

export default class SignInFieldActionRuleGroup extends Model {
  @attr('array', { defaultValue: () => [] }) declare actions: RuleAction[];
  @attr('string', { defaultValue: GROUP_OPERATION_OPTIONS.AND })
  declare groupOperator: GroupOperator;
  /**
   * if the rule is being ignored / needs review
   */
  @attr('boolean', { defaultValue: false }) declare ignore: boolean;
  /**
   * `purpose` applicable for alert
   */
  @attr('immutable', { defaultValue: () => ({}) }) declare metadata: Record<string, unknown>;

  /**
   * helper for whether there is an alert action selected
   */
  @computed('actions.[]')
  get hasAlertAction(): boolean {
    return this.actions.includes(RULE_ACTION_OPTIONS.ALERT);
  }
}

import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { Changeset } from 'ember-changeset';
import { type DetailedChangeset } from 'ember-changeset/types';
import type ConnectInviteModel from 'garaje/models/connect-invite';
import type ZoneModel from 'garaje/models/zone';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';

import type PropertyVisitorsInvitesEditCheckinController from './controller';

export type DateChangeset = DetailedChangeset<{
  value: Date;
}>;

export interface EditCheckinRouteModel {
  invite: ConnectInviteModel;
  checkedInAtChangeset: DateChangeset;
  property: ZoneModel;
}

export default class PropertyVisitorsInvitesEditCheckinRoute extends Route {
  @service declare store: Store;
  @service declare transitionConfirm: TransitionConfirmService;

  declare controller: PropertyVisitorsInvitesEditCheckinController;

  async model({ connect_invite_id }: { connect_invite_id: string }): Promise<EditCheckinRouteModel> {
    const invite = await this.store.findRecord('connect-invite', connect_invite_id);
    return {
      invite,
      checkedInAtChangeset: <DateChangeset>Changeset({ value: invite.checkedInAt }),
      property: <ZoneModel>this.modelFor('property'),
    };
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    const { checkedInAtChangeset } = <EditCheckinRouteModel>this.modelFor(this.routeName);

    if (transition.to.name === this.routeName || !checkedInAtChangeset.isDirty) return;

    void this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        checkedInAtChangeset.rollback();
      },
    });
  }
}

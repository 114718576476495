import Component from '@glimmer/component';
import { action } from '@ember/object';
import { loadGoogleMaps } from 'garaje/utils/script-loader';

/**
 * @param {String}              value
 * @param {Function}            onSelect
 */
export default class AddressInput extends Component {
  autocomplete;
  element;

  constructor() {
    super(...arguments);
    this.installAutocomplete();
  }

  @action
  handleInsert(el) {
    this.element = el;
  }

  installAutocomplete() {
    loadGoogleMaps().then(() => {
      if (!window.google) {
        return;
      }

      this.autocomplete = new window.google.maps.places.Autocomplete(this.element, { types: ['geocode'] });

      window.google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
        if (this.args.onSelect) {
          this.args.onSelect(this.autocomplete.getPlace().formatted_address);
        }
        if (this.args.getPlaceInfo) {
          const placeInfo = this.processPlace(this.autocomplete.getPlace());
          this.args.getPlaceInfo(placeInfo);
        }
      });
    });
  }

  processPlace(place) {
    let line1 = '',
      city = '',
      state = '',
      zip = '',
      country = '';

    place.address_components?.forEach((component) => {
      const types = component.types;

      if (types.includes('street_number')) {
        line1 = component.long_name;
      }

      if (types.includes('route')) {
        line1 += ' ' + component.long_name;
      }

      if (types.includes('locality')) {
        city = component.long_name;
      }

      if (types.includes('administrative_area_level_1')) {
        state = component.short_name;
      }

      if (types.includes('postal_code')) {
        zip = component.long_name;
      }

      if (types.includes('country')) {
        country = component.short_name;
      }
    });

    return { line1, city, state, zip, country };
  }

  willDestroy() {
    super.willDestroy(...arguments);
    if (this.autocomplete) {
      this.autocomplete.unbindAll();
    }
  }
}

import { isBlank } from '@ember/utils';
import { isArray } from '@ember/array';
import flash from './flash';

function handleEmberDataEror(reason) {
  let errorReason;
  let responseJSON = reason.errors;
  if (responseJSON) {
    if (isArray(responseJSON)) {
      // if it is a jsonapi error then take out the title
      responseJSON = responseJSON.map(function (err) {
        return err.detail || err.title || err.message || err;
      });

      responseJSON = responseJSON.uniq();
      // error is an array, join them
      errorReason = responseJSON.join(', ');
    } else if (typeof responseJSON === 'string') {
      // error is a string
      errorReason = responseJSON;
    }
  } else {
    // fallback
    errorReason = reason.message;
  }

  return errorReason;
}

function handleXhrError(reason) {
  let errorReason;
  const { responseJSON } = reason;
  if (responseJSON) {
    if (isArray(responseJSON.error)) {
      // error is an array, join them
      errorReason = responseJSON.error.join(', ');
    } else if (typeof responseJSON.error === 'string') {
      // error is a string
      errorReason = responseJSON.error;
    }
  } else {
    // fallback
    errorReason = reason.responseText;
  }

  return errorReason;
}

/**
 *
 * @param {*} error - error to parse
 * @returns {string} parsed error reason
 */
export const parseErrorForDisplay = (error) => {
  let errorReason;

  if (error.responseJSON) {
    errorReason = handleXhrError(error);
  } else {
    errorReason = handleEmberDataEror(error);
  }
  if (isBlank(errorReason)) {
    // still no errorReason, use statusText
    errorReason = error.statusText;
  }

  if (isBlank(errorReason)) {
    // still no errorReason, use error
    errorReason = error.error;
  }

  if (isBlank(errorReason)) {
    // still no errorReason, use reason
    errorReason = error;
  }

  return errorReason;
};

export default function (promise, context, successMessage, handleSuccess = true, errorType = 'error') {
  let callback = function () {};

  if (handleSuccess) {
    callback = () => {
      successMessage = successMessage || 'Saved!';
      flash.call(context, 'success', successMessage);
    };
  }

  promise.then(callback).catch((error) => {
    flash.call(context, errorType, parseErrorForDisplay(error));
  });
}

import { action, get } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type LocationModel from 'garaje/models/location';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import type ZoneModel from 'garaje/models/zone';
import type MetricsService from 'garaje/services/metrics';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';

interface LocationSwitcherComponentArgs {
  canCreateLocations: boolean;
  canVisitGlobal: boolean;
  canVisitConnect: boolean;
  showGlobalMenu: boolean;
  showMyLocations: boolean;
  showConnectMenu: boolean;
  showZones: boolean;
  currentLocation?: LocationModel;
  currentZone: ZoneModel | null;
  zones: ZoneModel[];
  addLocation: () => void;
  switchLocationFromMenu: (location: SkinnyLocationModel) => void;
  switchZoneFromMenu: (zone: ZoneModel) => void;
  transitionToLocationOverview: () => void;
}

export default class LocationSwitcherComponent extends Component<LocationSwitcherComponentArgs> {
  @service declare skinnyLocations: SkinnyLocationsService;
  @service declare metrics: MetricsService;

  get selectedName(): string | undefined {
    const skinnyLocation = this.skinnyLocations.content?.findBy('id', this.args.currentLocation?.id);

    if (this.args.currentZone) return this.args.currentZone.name;
    // eslint-disable-next-line ember/no-get
    return this.args.showZones && skinnyLocation && <string>get(skinnyLocation, 'property.id')
      ? this.args.currentLocation?.name
      : this.args.currentLocation?.nameWithCompanyName;
  }

  @tracked nameWasTruncated = false;

  /**
   * Fires analytics event when user opens location picker
   */
  @action
  trackOpenLocationSwitcher(): void {
    this.metrics.trackEvent('Expand Location Picker Clicked', {
      location_id: this.args.currentLocation?.id,
    });
  }
}

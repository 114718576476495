import Component from '@glimmer/component';
import { set } from '@ember/object';
import _uniqBy from 'lodash/uniqBy';
import { inject as service } from '@ember/service';
import { all, dropTask, task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { pluralize } from 'ember-inflector';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

/**
 * @param {Array<Device>}             selectedIPads
 * @param {Function}                  operationSuccessful
 * @param {Function}                  hideModal
 * @param {Array<SkinnyLocation>}     locations
 */
export default class GlobalDevicesIpadsMoveLocationModal extends Component {
  @service flashMessages;
  defaultLocationFilter = { name: 'Choose a location' };

  @tracked selectedLocation;
  @tracked name;

  get uniqLocations() {
    return _uniqBy(this.args.locations, 'id');
  }

  @task
  *changeLocationTask(ipad, skinnyLocation) {
    try {
      const realLocation = yield skinnyLocation.realLocation();
      set(ipad, 'location', realLocation);
      yield ipad.save();
    } catch (error) {
      throw parseErrorForDisplay(error);
    }
  }

  @dropTask
  *changeLocationsTask() {
    const newLocation = this.selectedLocation;
    const iPads = this.args.selectedIPads;
    const changeLocationTask = this.changeLocationTask;

    try {
      yield all(iPads.map((ipad) => changeLocationTask.perform(ipad, newLocation)));

      const successMessage = `Successfully updated ${pluralize(iPads.length, 'iPad')}`;
      this.flashMessages.showFlash('success', successMessage);

      this.args.operationSuccessful();
      this.args.hideModal();
    } catch (error) {
      this.flashMessages.showFlash('error', error);
    }
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type ConnectFloorModel from 'garaje/models/connect-floor';
import type ZoneModel from 'garaje/models/zone';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type { RecordArray } from 'garaje/utils/type-utils';

import type PropertySettingsFloorsController from './controller';

export type PropertySettingsFloorsRouteModel = {
  property: ZoneModel;
  floors: RecordArray<ConnectFloorModel>;
};

export default class PropertySettingsFloorsRoute extends Route {
  @service declare featureFlags: FeatureFlagsService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  beforeModel(): void {
    if (!this.featureFlags.isEnabled('connect-floors')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  async model(): Promise<PropertySettingsFloorsRouteModel> {
    const property = <ZoneModel>this.modelFor('property');

    const floors = await this.store.query('connect-floor', {
      filter: {
        property: property.id,
      },
      sort: '-position',
    });

    return {
      floors,
      property,
    };
  }

  resetController(controller: PropertySettingsFloorsController): void {
    controller.floorToConfirmDeletion = null;
  }
}

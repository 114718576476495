import LocationFragment from 'garaje/graphql/fragments/roomba/LocationFragment';
import gql from 'graphql-tag';

export default gql`
  mutation UpdateRoomsLocationRoomCheckInEnabledOverride($locationId: ID!, $roomCheckInEnabledOverride: Boolean) {
    updateRoomsLocationRoomCheckInEnabledOverride(
      locationId: $locationId
      roomCheckInEnabledOverride: $roomCheckInEnabledOverride
    ) {
      ...LocationFragment
    }
  }
  ${LocationFragment}
`;

import { Changeset } from 'ember-changeset';
import { type DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import type GroupInviteModel from 'garaje/models/group-invite';
import buildGroupInviteValidations from 'garaje/validations/group-invite';

type GroupInviteChangeset = DetailedChangeset<GroupInviteModel>;

export default function (groupInvite: GroupInviteModel, options = {}): GroupInviteChangeset {
  const validations = buildGroupInviteValidations(options);
  const validator = lookupValidator(validations);

  return <GroupInviteChangeset>Changeset(groupInvite, validator, validations);
}

import Component from '@glimmer/component';
import { type DetailedChangeset } from 'ember-changeset/types';
import type EmployeeModel from 'garaje/models/employee';
import type EntryModel from 'garaje/models/entry';
import type InviteModel from 'garaje/models/invite';
import { empty, not, and } from 'macro-decorators';

type EntryOrInviteChangeset = DetailedChangeset<EntryModel | InviteModel>;
interface EntriesHostFieldArgs {
  allowClear?: boolean;
  changeset: EntryOrInviteChangeset;
  disabled?: boolean;
  errors?: unknown[];
  label: string;
  required?: boolean;
  selected: EmployeeModel;
  search: (term: string) => unknown;
  update: (model: EntryOrInviteChangeset) => unknown;
  validated?: boolean;
}
export default class EntriesHostField extends Component<EntriesHostFieldArgs> {
  placeholder = 'Choose a Host';

  @empty('args.errors') declare hasEmptyErrors: boolean;
  @not('hasEmptyErrors') declare hasErrors: boolean;
  @and('args.validated', 'hasErrors') declare isDisplayingErrors: boolean;
}

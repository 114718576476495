import { isBlank, isNone } from '@ember/utils';
import type { ValidatorMap } from 'ember-changeset-validations';
import { validatePresence, validateLength } from 'ember-changeset-validations/validators';

function checkNumber(value: number) {
  if (isNaN(value)) return 'Value must be a number';
  if (value < 0) return 'Value cannot be negative';

  return true;
}

interface ValidationOptions {
  requireArea?: boolean;
  requireSuites?: boolean;
}

export default function buildZoneValidations(options: ValidationOptions = {}): ValidatorMap {
  const validations: ValidatorMap = {
    name: validatePresence({ presence: true, ignoreBlank: true, message: 'This is a required field' }),
    areaSqFt: (_key: string, newValue: number | undefined) => {
      if (options.requireArea && isBlank(newValue)) return 'This is a required field';
      if (!isNone(newValue)) return checkNumber(newValue);
      return true;
    },
  };

  if (options.requireSuites) {
    validations['suites'] = validateLength({ min: 1, message: 'This is a required field' });
  }

  return validations;
}

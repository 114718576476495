import gql from 'graphql-tag';

export default gql`
  mutation CreateAnnouncementMutation($announcementInput: AnnouncementInput!) {
    createAnnouncement(announcementInput: $announcementInput) {
      id
      statistics {
        sentCount {
          employeesCount
          visitorsCount
        }
      }
    }
  }
`;

/* eslint-disable ember/no-computed-properties-in-native-classes */
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import { APP } from 'garaje/utils/enums';
import { camelize } from '@ember/string';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('billing-v2')
class LocationSubscriptionEstimateModel extends Model {
  @belongsTo('location', { async: false }) location;
  @attr('array') products;
  @hasMany('subscription-estimate', { async: false }) subscriptionEstimates;

  @computed('subscriptionEstimates.@each.nextBillingAt')
  get nextBillingAt() {
    return get(this, 'subscriptionEstimates.firstObject.nextBillingAt');
  }

  @computed('subscriptionEstimates.@each.app')
  get visitorsSubscriptionEstimate() {
    return get(this, 'subscriptionEstimates').find((se) => se.app === APP.VISITORS);
  }

  @computed('subscriptionEstimates.@each.app')
  get deliveriesSubscriptionEstimate() {
    return get(this, 'subscriptionEstimates').find((se) => se.app === APP.DELIVERIES);
  }

  @computed('subscriptionEstimates.@each.app')
  get roomsSubscriptionEstimate() {
    return get(this, 'subscriptionEstimates').find((se) => se.app === APP.ROOMS);
  }

  @alias('visitorsSubscriptionEstimate.currentInvoiceEstimate') visitorsCurrentInvoiceEstimate;
  @alias('visitorsSubscriptionEstimate.nextInvoiceEstimate') visitorsNextInvoiceEstimate;

  @alias('deliveriesSubscriptionEstimate.currentInvoiceEstimate') deliveriesCurrentInvoiceEstimate;
  @alias('deliveriesSubscriptionEstimate.nextInvoiceEstimate') deliveriesNextInvoiceEstimate;

  @alias('roomsSubscriptionEstimate.currentInvoiceEstimate') roomsCurrentInvoiceEstimate;
  @alias('roomsSubscriptionEstimate.nextInvoiceEstimate') roomsNextInvoiceEstimate;

  @computed(
    'visitorsCurrentInvoiceEstimate.amountDue',
    'deliveriesCurrentInvoiceEstimate.amountDue',
    'roomsCurrentInvoiceEstimate.amountDue',
  )
  get invoiceEstimatesAmountDue() {
    const { visitorsCurrentInvoiceEstimate, deliveriesCurrentInvoiceEstimate, roomsCurrentInvoiceEstimate } = this;
    let amountDue = 0;
    if (visitorsCurrentInvoiceEstimate) {
      amountDue = amountDue + visitorsCurrentInvoiceEstimate.amountDue;
    }
    if (deliveriesCurrentInvoiceEstimate) {
      amountDue = amountDue + deliveriesCurrentInvoiceEstimate.amountDue;
    }
    if (roomsCurrentInvoiceEstimate) {
      amountDue = amountDue + roomsCurrentInvoiceEstimate.amountDue;
    }
    return amountDue;
  }

  @computed('visitorsNextInvoiceEstimate', 'deliveriesNextInvoiceEstimate', 'roomsNextInvoiceEstimate')
  get invoiceEstimatesTotal() {
    const { visitorsNextInvoiceEstimate, deliveriesNextInvoiceEstimate, roomsNextInvoiceEstimate } = this;
    let total = 0;
    if (visitorsNextInvoiceEstimate) {
      total = total + visitorsNextInvoiceEstimate.total;
    }
    if (deliveriesNextInvoiceEstimate) {
      total = total + deliveriesNextInvoiceEstimate.total;
    }
    if (roomsNextInvoiceEstimate) {
      total = total + roomsNextInvoiceEstimate.total;
    }
    return total;
  }
  @computed(
    'visitorsNextInvoiceEstimate.@each.discounts',
    'deliveriesCurrentInvoiceEstimate.@each.discounts',
    'roomsCurrentInvoiceEstimate.@each.discounts',
  )
  get invoiceEstimateDiscounts() {
    const { visitorsNextInvoiceEstimate, deliveriesNextInvoiceEstimate, roomsNextInvoiceEstimate } = this;
    const used = [];
    const totals = {};
    if (visitorsNextInvoiceEstimate) {
      for (const discount of visitorsNextInvoiceEstimate.discounts.toArray()) {
        const id = camelize(discount.description);
        if (used.includes(id)) {
          totals[id].amount = totals[id].amount + discount.amount;
        } else {
          totals[id] = {};
          totals[id].description = discount.description;
          totals[id].amount = discount.amount;
          used.push(id);
        }
      }
    }
    if (deliveriesNextInvoiceEstimate) {
      for (const discount of deliveriesNextInvoiceEstimate.discounts.toArray()) {
        const id = camelize(discount.description);
        if (used.includes(id)) {
          totals[id].amount = totals[id].amount + discount.amount;
        } else {
          totals[id] = {};
          totals[id].description = discount.description;
          totals[id].amount = discount.amount;
          used.push(id);
        }
      }
    }
    if (roomsNextInvoiceEstimate) {
      for (const discount of roomsNextInvoiceEstimate.discounts.toArray()) {
        const id = camelize(discount.description);
        if (used.includes(id)) {
          totals[id].amount = totals[id].amount + discount.amount;
        } else {
          totals[id] = {};
          totals[id].description = discount.description;
          totals[id].amount = discount.amount;
          used.push(id);
        }
      }
    }
    return totals;
  }
}

export default LocationSubscriptionEstimateModel;

import Component from '@glimmer/component';
import { service } from '@ember/service';
import { gt, alias } from 'macro-decorators';

export default class ImportEmployeeDirectory extends Component {
  @service visitorsOnboarding;

  @alias('visitorsOnboarding.s3Url') s3Url;

  @gt('visitorsOnboarding.totalEmployees', 1) hasEmployees;
  @gt('visitorsOnboarding.totalAdmins', 1) hasAdmins;

  constructor() {
    super(...arguments);
    this.visitorsOnboarding.loadEmployeesTask.perform();
    this.visitorsOnboarding.loadUserRolesTask.perform();
  }
}

import GenericResponseFragment from 'garaje/graphql/fragments/employee-schedule/GenericResponseFragment';
import gql from 'graphql-tag';

export default gql`
  mutation ReleaseDeskReservations($reservationIds: [ID!]!) {
    releaseDeskReservations(reservationIds: $reservationIds) {
      ...GenericResponseFragment
    }
  }
  ${GenericResponseFragment}
`;

import { isPresent } from '@ember/utils';
import config from 'garaje/config/environment';
import { get } from '@ember/object';

const { apiOverwrittenHost: host } = config;

function _replaceNamespace(link) {
  if (isPresent(link) && link.includes(host) && !link.includes(`${host}/a/`)) {
    link = link.replace('/api/', '/a/visitors/api/');
  }
  return link;
}

export default function formatRelationshipsLinks(relationships) {
  Object.keys(relationships).forEach((key) => {
    let related = get(relationships[key], 'links.related');
    let self = get(relationships[key], 'links.self');
    self = _replaceNamespace(self);
    related = _replaceNamespace(related);
    if (isPresent(related)) {
      relationships[key].links.related = related;
    }
    if (isPresent(self)) {
      relationships[key].links.self = self;
    }
  });

  return relationships;
}

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';

import urlBuilder from 'garaje/utils/url-builder';
import droppableUpload from 'garaje/utils/decorators/droppable-upload';

const MAX_SLIDESHOWS = 10;

/**
 * @param {object}                        [configChangeset]
 * @param {Function}                      finalizeSlideshow
 * @param {boolean}                       isUpgradeRequired
 * @param {object}                        [location]
 * @param {Function}                      onToggleOnlyShowSlideshow
 * @param {Function}                      onCreateSlideshow
 * @param {Reference}                     parent
 * @param {Function}                      refreshConfig
 * @param {Function}                      removeSlideshows
 * @param {object[]}                      slideshows
 * @param {string}                        welcomeImage
 * @param {object}                        [zone]
 */
@droppableUpload
class CustomizeSlideshowImages extends Component {
  @service messageBus;
  @service store;
  @service flashMessages;

  @tracked isEditingSlideshow = false;
  @tracked isUploading = false;
  @tracked pendingUpload = null;

  slideshowUploadUrl = urlBuilder.v2.slideshowUploadUrl();

  get slideshows() {
    return this.args.slideshows.toArray();
  }

  get visibleSlideshows() {
    return this.slideshows.filter((slideshow) => !slideshow.isDeleted);
  }

  get savedSlideshows() {
    return this.slideshows.filter((slideshow) => !slideshow.isNew);
  }

  get slideshowsToDelete() {
    return this.slideshows.filter((slideshow) => slideshow.isDeleted);
  }

  get isDisabled() {
    return this.isSaving || !this.isDirty;
  }

  get isSaving() {
    return this.saveTask.isRunning || this.isUploading;
  }

  get isDirty() {
    return (
      this.slideshows.some((slideshow) => slideshow.isDeleted || slideshow.hasDirtyAttributes) ||
      this.args.configChangeset?.isDirty
    );
  }

  get isDroppable() {
    return this.isEditingSlideshow && !this.isMaxSlideshows;
  }

  get maxSlideshows() {
    return MAX_SLIDESHOWS - this.visibleSlideshows.length;
  }

  get remainingSlideshowSlots() {
    return MAX_SLIDESHOWS - this.savedSlideshows.length;
  }

  get isMaxSlideshows() {
    return this.maxSlideshows <= 0;
  }

  get slideshowExtra() {
    const { location, zone } = this.args;

    const extra = {};

    if (zone) {
      extra['zone_id'] = zone.id;
    } else if (location) {
      extra['location_id'] = location.id;
    }

    return extra;
  }

  get onlyShowSlideshowCopy() {
    const { location } = this.args;
    if (this.args.welcomeImage) {
      return 'Welcome Image';
    } else if (location.logo) {
      return 'Company Logo';
    } else {
      return 'Company Name';
    }
  }

  @action
  handleDidInsertElement() {
    // install expand handler
    this.messageBus.on('expandSlideshow', this, this.enableSlideshow);
  }

  @action
  handleWillDestroy() {
    this.messageBus.off('expandSlideshow', this, this.enableSlideshow);
  }

  @action
  setPendingUpload(newPendingFiles, pendingUpload) {
    this.pendingUpload = pendingUpload;
    newPendingFiles?.forEach(({ preview, file }) => {
      // if preview fails for some reason, use file name to ensure delete button renders
      this.args.onCreateSlideshow(preview?.src ?? file.name);
    });
  }

  @action
  delete(slideshow) {
    if (slideshow.isNew) {
      const pendingFile = this.pendingUpload?.pendingFiles?.find(
        (item) => item.preview?.src === slideshow.image || item.preview?.src === slideshow.url,
      );

      pendingFile?.clear();
      slideshow.rollbackAttributes();
    } else {
      slideshow.deleteRecord();
    }
  }

  @action
  cancel() {
    this.isEditingSlideshow = false;
    for (const slideshow of this.args.slideshows.toArray()) {
      slideshow.rollbackAttributes();
    }
    this.reset();
  }

  @action
  enableSlideshow() {
    this.isEditingSlideshow = true;
  }

  @action
  allUploaded() {
    this.isEditingSlideshow = false;
    this.isUploading = false;
    this.reset();
    this.args.refreshConfig();
  }

  @action
  reset() {
    this.pendingUpload = null;
  }

  @action
  showImage(slideshow) {
    const hasSrc = !!slideshow.url || !!slideshow.image;
    return (hasSrc && !this.isUploading) || !slideshow.isNew;
  }

  @dropTask
  *saveTask() {
    if (this.pendingUpload) {
      this.isUploading = true;
      this.pendingUpload.upload();
    }

    if (this.slideshowsToDelete.length) {
      yield this.args.removeSlideshows(this.slideshowsToDelete);
      this.isEditingSlideshow = false;
      this.reset();
    }

    if (this.args.configChangeset?.isDirty) {
      yield this.args.onToggleOnlyShowSlideshow();
      this.isEditingSlideshow = false;
    }
  }
}

export default CustomizeSlideshowImages;

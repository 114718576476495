import { service } from '@ember/service';
import { Ability } from 'ember-can';
import { isProductDisabled } from 'garaje/helpers/product-disabled';
import type AuthzService from 'garaje/services/authz';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { Permission } from 'garaje/utils/ui-permissions';

export default class OccupancyAnalyticAbility extends Ability {
  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;
  @service declare authz: AuthzService;

  get canVisit(): boolean {
    if (isProductDisabled('occupancy')) {
      return false;
    }

    const canAccessOccupancyAnalytics =
      !!this.state.features?.canAccessOccupancyAnalytics || this.featureFlags.isEnabled('occupancy-analytics');

    return (
      canAccessOccupancyAnalytics &&
      this.authz.hasPermissionAtCurrentLocation(Permission.ANALYTICS_LOCAL_OCCUPANCY_DASH_READ)
    );
  }
}

import { action } from '@ember/object';
import Component from '@glimmer/component';
import type { DetailedChangeset } from 'ember-changeset/types';
import type { TaskGroup } from 'ember-concurrency';
import { dropTaskGroup, task } from 'ember-concurrency';
import mailerTemplateChangeset from 'garaje/changesets/mailer-template';
import type MailerBlockModel from 'garaje/models/mailer-block';
import type MailerTemplateModel from 'garaje/models/mailer-template';
import { DEFAULT_FONT_FACE } from 'garaje/models/mailer-template';
import { cached } from 'tracked-toolbox';

export interface MailerTemplateUpdateArgs {
  mailerTemplateChangeset: DetailedChangeset<MailerTemplateModel>;
  mailerBlocks: MailerBlockModel[] | null;
}

export interface MailerTemplateEditorComponentSignature {
  Args: {
    mailerTemplate?: MailerTemplateModel;
    title?: string;
    isOpen?: boolean;
    onUpdate?: () => void;
    onClickSave?: (args: MailerTemplateUpdateArgs) => Promise<void>;
    onClickDelete?: (mailerTemplate: MailerTemplateModel) => Promise<void>;
    onClickClose?: () => Promise<void>;
  };

  Element: HTMLElement;
}

export default class MailerTemplateEditorComponent extends Component<MailerTemplateEditorComponentSignature> {
  defaultFont = DEFAULT_FONT_FACE;

  @cached
  get mailerTemplateChangeset(): DetailedChangeset<MailerTemplateModel> {
    return mailerTemplateChangeset(this.args.mailerTemplate!);
  }

  get hasChanges(): boolean {
    const { mailerBlocks } = <MailerTemplateModel>this.args.mailerTemplate;
    const { mailerTemplateChangeset } = this;
    const isBlocksDirty = mailerBlocks && mailerBlocks.some((block) => block.isDirty);

    return Boolean(mailerTemplateChangeset.isDirty || isBlocksDirty);
  }

  get isSaveDisabled(): boolean {
    const { mailerTemplateChangeset, hasChanges, updateTaskGroup } = this;

    return !hasChanges || !mailerTemplateChangeset.isValid || updateTaskGroup.isRunning;
  }

  @dropTaskGroup updateTaskGroup!: TaskGroup<unknown>;

  saveTask = task({ group: 'updateTaskGroup' }, async (): Promise<void> => {
    const { mailerTemplateChangeset } = this;
    const { mailerBlocks } = <MailerTemplateModel>this.args.mailerTemplate;

    await this.args.onClickSave?.({ mailerTemplateChangeset, mailerBlocks });
  });

  deleteTask = task({ group: 'updateTaskGroup' }, async (): Promise<void> => {
    await this.args.onClickDelete?.(this.args.mailerTemplate!);
  });

  @action
  updateFont(font: string): void {
    this.mailerTemplateChangeset.templateFontFace = font;
    this.mailerTemplateChangeset.execute();
    this.args.onUpdate?.();
  }

  @action
  updateAlignment(alignment: string): void {
    this.mailerTemplateChangeset.templateTextAlignment = alignment;
    this.mailerTemplateChangeset.execute();
    this.args.onUpdate?.();
  }

  @action
  async handleClose(): Promise<void> {
    try {
      await this.args.onClickClose?.();

      this.#rollbackChanges();
      this.args.onUpdate?.();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  #rollbackChanges() {
    this.mailerTemplateChangeset.rollback();
    this.args.mailerTemplate?.rollbackAttributes();
    this.args.mailerTemplate?.mailerBlocks?.forEach((block) => block.rollbackAttributes());
  }
}

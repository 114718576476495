import config from 'garaje/config/environment';

const _testing = config.environment === 'test';

export default function zeroForTests(n: number, testing: boolean = _testing): number {
  if (testing) {
    return 0;
  } else {
    return n;
  }
}

import { A } from '@ember/array';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import type { Task } from 'ember-concurrency';
import { dropTask } from 'ember-concurrency';
import type LocationModel from 'garaje/models/location';
import type UserModel from 'garaje/models/user';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import isEqual from 'lodash/isEqual';

interface ConnectNotificationsVisitorComponentArgs {
  location: LocationModel;
  searchUsersTask: Task<unknown, [term: string, contacts: UserModel[]]>;
}

export default class ConnectNotificationsVisitorComponent extends Component<ConnectNotificationsVisitorComponentArgs> {
  @service declare store: Store;
  @service declare flashMessages: FlashMessagesService;

  get contacts(): UserModel[] {
    if (this.loadContactsTask.isRunning) return [];
    return this.location.multiTenancyVisitorContacts.toArray();
  }

  @tracked _contactIds: string[] = [];
  @tracked isOpen = false;
  @tracked location!: DetailedChangeset<LocationModel>;

  get contactsChanged(): boolean {
    return !isEqual(this._contactIds, A(this.contacts).mapBy('id'));
  }

  constructor(owner: unknown, args: ConnectNotificationsVisitorComponentArgs) {
    super(owner, args);
    void this.loadContactsTask.perform(this.args.location);
  }

  loadContactsTask = dropTask(async (location: LocationModel) => {
    try {
      const users = await location.multiTenancyVisitorContacts;
      this._contactIds = users.mapBy('id');
      this.location = Changeset(this.args.location);
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  enableTask = dropTask(async () => {
    if (!this.contacts.length) {
      this.isOpen = true;
      return;
    }

    await this.saveLocation(true);
    this.isOpen = true;
  });

  saveTask = dropTask(async () => {
    await this.saveLocation(!!this.contacts.length);
    void this.loadContactsTask.perform(this.args.location);
  });

  searchTask = dropTask(async (term: string) => {
    return await this.args.searchUsersTask.perform(term, this.contacts);
  });

  @action
  async saveLocation(enabled: boolean): Promise<void> {
    try {
      const contactsChanged = !!A(this.location.changes).findBy('key', 'multiTenancyVisitorContacts');

      if (isPresent(enabled)) {
        this.location.multiTenancyVisitorNotificationsEnabled = enabled;
      }

      await this.location.save();

      if (!enabled) {
        this.isOpen = false;
      }

      if (contactsChanged) {
        this.flashMessages.showAndHideFlash('success', 'Saved!');
      }
    } catch (e) {
      this.location.rollback();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @action
  onDestroy(): void {
    this.location.rollback();
  }

  @action
  cancel(): void {
    this.isOpen = false;
    this.location.rollback();
  }
}

import { Scalars } from './feature-config-types';
import { DeskMapFeature } from './map-features-types';
import { WorkplaceGroup } from 'garaje/graphql/generated/employee-schedule-types';

enum DatePeriod {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export enum WeekDay {
  SUN = 'SUN',
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
}

export type WorkplaceAnalyticsOccupancyOnsiteMissionGrid = {
  department: Scalars['String'];
  deactivated: Scalars['DateTime'];
  created: Scalars['DateTime'];
  mission: Scalars['Int'];
  period: DatePeriod;
  mandatoryDays: Array<WeekDay>;
};

export type EmployeeAttendanceInsights = {
  meetsAttendancePolicy: Scalars['Boolean'];
};

export type EmployeeAttendancePerformanceBasic = {
  averageWeeklyAttendance: Scalars['Int'];
  onsiteMission: WorkplaceAnalyticsOccupancyOnsiteMissionGrid;
  insights: EmployeeAttendanceInsights;
};

export type Floor = {
  id: Scalars['ID'];
  name: Scalars['String'];
  floorPlanUrl: Scalars['String'];
};

export type Reservation = {
  __typename?: 'Reservation';
  /** @deprecated booking_source is no longer used. */
  bookingSource: Scalars['String'];
  canceledAt: Scalars['DateTime'];
  /** @deprecated canceled_at field now used rather than cancelled. */
  cancelled: Scalars['Boolean'];
  checkInTime: Scalars['DateTime'];
  checkOutTime: Scalars['DateTime'];
  desk: Desk;
  employee: Employee;
  endTime: Scalars['Int'];
  entryId: Scalars['ID'];
  id: Scalars['ID'];
  inviteId: Scalars['ID'];
  isPartialDay: Scalars['Boolean'];
  locationId: Scalars['ID'];
  startTime: Scalars['Int'];
  userEmail: Scalars['String'];
  userId: Scalars['ID'];
};

export type Amenity = {
  __typename?: 'Amenity';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Desk = {
  __typename?: 'Desk';
  amenities: Array<Amenity>;
  assignedEmployee: Employee;
  /** @deprecated use assignedEmployee instead */
  assignedTo: Scalars['String'];
  /** @deprecated use enabled attribute instead */
  isAssignable: Scalars['Boolean'];
  deskAvailability: Scalars['String'];
  /** @deprecated Use the MapFeature and MapFloor types instead */
  floor: Floor;
  floorId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  neighborhood: Scalars['String'];
  neighborhoodId?: Scalars['Int'];
  enabled: Scalars['Boolean'];
  reservations: Reservation;
  /** @deprecated Desk geometry has been moved to the MapFeature type */
  x: Scalars['Float'];
  /** @deprecated Desk geometry has been moved to the MapFeature type */
  y: Scalars['Float'];
  mapFeature: DeskMapFeature;
};

export type AssignedAndUnassignedEmployeesMeta = {
  employeesPopulatedByScim: Scalars['Boolean'];
};

export type AssignedAndUnassignedEmployees = {
  __typename?: 'AssignedAndUnassignedEmployees';
  unassignedEmployees: Array<Employee>;
  assignedEmployees: Array<Employee>;
  meta: AssignedAndUnassignedEmployeesMeta;
};

export type AssignmentFilterInput = {
  locationId: Scalars['ID'];
};

export type GQLAssignment = {
  id: Scalars['ID'];
  deskId: Scalars['ID'];
  startTime: Scalars['DateTime'];
  employee: { id: Scalars['ID'] };
  scheduledBy: { id: Scalars['ID'] };
};

export type GetAssignedAndUnassignedEmployeesInput = {
  locationId: Scalars['ID'];
  draftId?: Scalars['ID'];
};

export type SpaceDashboardInput = {
  filter: {
    companyIds: Array<Scalars['ID']>;
    locationIds?: Array<Scalars['ID']>;
    start: Scalars['DateTime'];
    end: Scalars['DateTime'];
  };
  setting: {
    weekdays: Array<WeekDay>;
  };
};
export type Employee = {
  __typename?: 'Employee';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  department: Scalars['String'];
  assignedDesks: Array<Desk>;
  locations: Array<Location>;
  phoneNumber: Scalars['String'];
  profilePictureUrl: Scalars['String'];
  userId: Scalars['ID'];
  favorite: Scalars['Boolean'];
  hideFromEmployeeSchedule: Scalars['Boolean'];
  signInStatus: Scalars['Boolean'];
  workplaceGroups: Array<WorkplaceGroup>;
  attendancePerformance: EmployeeAttendancePerformanceBasic | null;
};
export type InsightsMeta = {
  numUnassignedMeetingAttendancePolicy: Scalars['Int'];
  numAssignedMeetingAttendancePolicy: Scalars['Int'];
  employeeAttendanceTrackingEnabled: Scalars['Boolean'];
};

export type GetAssignmentsQuery = {
  __typename?: 'Query';
  // eslint-disable-next-line no-unused-vars
  assignments(assignmentsInput: AssignmentFilterInput): GQLAssignment[];
};

export type GetAssignedAndUnassignedEmployeesQuery = {
  __typename?: 'Query';
  getAssignedAndUnassignedEmployees(
    // eslint-disable-next-line no-unused-vars
    employeeInput: GetAssignedAndUnassignedEmployeesInput,
    // eslint-disable-next-line no-unused-vars
    spaceInput: SpaceDashboardInput,
  ): AssignedAndUnassignedEmployees;
};

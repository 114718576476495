import { helper } from '@ember/component/helper';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
} from 'date-fns';

/**
 * Formats a given date into a human-readable relative time string.
 *
 * @param {Date} date - The date to be formatted.
 * @returns {string} A formatted string representing the time elapsed since the given date.
 *
 * The function returns:
 * - "Just Now" if the time difference is less than a minute.
 * - "{n} minute(s) ago" if the time difference is less than an hour.
 * - "{n} hour(s) ago" if the time difference is less than a day.
 * - "{n} day(s) ago" if the time difference is less than a month.
 * - "{n} month(s) ago" if the time difference is less than a year.
 * - "{n} year(s) ago" for all other cases.
 */
export function formatRelativeTime([date]: [Date]): string {
  const now = new Date();
  const years = differenceInYears(now, date);
  const months = differenceInMonths(now, date);
  const days = differenceInDays(now, date);
  const hours = differenceInHours(now, date);
  const minutes = differenceInMinutes(now, date);

  let value, unit;
  if (years > 1) {
    value = years;
    unit = 'year';
  } else if (months >= 1) {
    value = months;
    unit = 'month';
  } else if (days >= 1) {
    value = days;
    unit = 'day';
  } else if (hours >= 1) {
    value = hours;
    unit = 'hour';
  } else if (minutes >= 1) {
    value = minutes;
    unit = 'minute';
  } else {
    return 'Just Now';
  }

  const formatter = new Intl.RelativeTimeFormat('en', { style: 'long' });
  return formatter.format(-value, <Intl.RelativeTimeFormatUnit>unit);
}

export default helper(formatRelativeTime);

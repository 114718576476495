import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import type AnnouncementTemplateModel from 'garaje/models/announcement-template';
import type StateService from 'garaje/services/state';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';
import announcementTemplateValidations from 'garaje/validations/announcement-template';

export interface CommunicationsEditTemplateParams {
  template_id: string;
}

export interface CommunicationsEditTemplateModel {
  announcementTemplate: AnnouncementTemplateModel;
  changeset: DetailedChangeset<AnnouncementTemplateModel>;
  templateId: string;
}

export default class CommunicationsEditTemplateRoute extends Route {
  @service declare store: Store;
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare transitionConfirm: TransitionConfirmService;

  async model({ template_id }: CommunicationsEditTemplateParams): Promise<CommunicationsEditTemplateModel | void> {
    try {
      // If editing an existing template, load the template's information
      const existingTemplate = await this.store.findRecord('announcement-template', template_id, {
        include: 'employee-groups,employees',
      });

      // If the template's not found, redirect to the unauthorized page
      if (!existingTemplate) {
        return void this.router.transitionTo('communications.templates');
      }

      const validator = lookupValidator(announcementTemplateValidations);

      return {
        announcementTemplate: existingTemplate,
        changeset: Changeset(existingTemplate, validator, announcementTemplateValidations),
        templateId: template_id,
      };
    } catch (_error) {
      void this.router.transitionTo('unauthorized');
    }
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    const { changeset } = <CommunicationsEditTemplateModel>this.modelFor(this.routeName);

    // If leaving this route with unsaved changes, show confirmation modal
    if (changeset.isDirty) {
      return void this.transitionConfirm.displayConfirmTask.perform(transition, {
        header: 'Unsaved changes',
        body: 'Are you sure you want to discard all changes made to this template?',
        confirmText: 'Discard changes',
        cancelText: 'Keep editing',
        continue() {
          changeset.rollback();
        },
      });
    }
  }
}

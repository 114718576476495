import DeliveriesSerializer from './deliveries';

export default class DeliverySerializer extends DeliveriesSerializer {
  attrs = {
    creator: {
      serialize: false,
    },
    pickedUpBy: {
      serialize: false,
    },
    pickedUpByKiosk: {
      serialize: false,
    },
    imageUrl: {
      serialize: false,
    },
    signOutPhotoThumbnailUrl: {
      serialize: false,
    },
    signOutPhotoUrl: {
      serialize: false,
    },
    signOutSignatureUrl: {
      serialize: false,
    },
    thumbnailUrl: {
      serialize: false,
    },
  };
}

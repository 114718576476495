import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type DetailedChangeset } from 'ember-changeset/types';
import { task, lastValue, all } from 'ember-concurrency';
import { type Select } from 'ember-power-select/components/power-select';
import type TenantModel from 'garaje/models/tenant';
import type TenantConnectionRequestModel from 'garaje/models/tenant-connection-request';
import type ZoneModel from 'garaje/models/zone';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { alias } from 'macro-decorators';
import { localCopy } from 'tracked-toolbox';
import { isChangeset } from 'validated-changeset';

export type TenantChangeset = DetailedChangeset<TenantModel>;

interface EditTenantComponentArgs {
  /**
   * renders invite tenant form
   */
  inviteTenant?: boolean;
  /**
   * closure called when closing dialog
   */
  onClose?: () => unknown;
  /**
   * closure called when tenant is saved
   */
  onSave?: () => unknown;
  /**
   * zone model
   */
  property: ZoneModel;
  /**
   * refreshes the token for an existing tenant
   */
  refreshToken?: boolean;
  /**
   * tenant changeset or model
   */
  tenant: TenantChangeset | TenantModel;
}

export default class EditTenantComponent extends Component<EditTenantComponentArgs> {
  @service declare store: Store;
  @service declare flashMessages: FlashMessagesService;
  @service declare state: StateService;
  @service declare featureFlags: FeatureFlagsService;

  @alias('args.tenant') tenant!: EditTenantComponentArgs['tenant'];
  @tracked suggestionTerm: string | null = null;
  @tracked powerSelectAPI!: Select;
  @tracked isSuiteNameDistinct = true;
  @tracked showInviteSkip = false;
  @tracked showAdditionalInfo = true;

  @lastValue('getSuites') suites?: NativeArray<ZoneModel>;

  @localCopy('args.inviteTenant', false) inviteTenant!: boolean;

  get tenantConnectionRequest(): TenantConnectionRequestModel | undefined {
    if (isChangeset(this.tenant)) {
      return (<TenantChangeset>this.tenant)._content.tenantConnectionRequest;
    } else {
      return this.tenant.tenantConnectionRequest;
    }
  }

  get title(): string {
    if (<boolean>(<unknown>this.tenant?.isNew)) {
      return 'Add Tenant';
    } else {
      return 'Edit Tenant';
    }
  }

  get isDisabled(): boolean {
    let isPristine: boolean | undefined;

    if (isChangeset(this.tenant)) {
      const tenant = <TenantChangeset>this.tenant;
      isPristine = tenant.isPristine;
    } else {
      isPristine = !this.tenant.hasDirtyAttributes;
    }

    return !!(
      this.saveTask.isRunning ||
      (isPristine && this.tenant?.suites.every((suite) => !suite.hasDirtyAttributes) && this.tenant?.id)
    );
  }

  constructor(owner: unknown, args: EditTenantComponentArgs) {
    super(owner, args);
    if (this.args.property && !this.inviteTenant && !this.args.refreshToken) void this.getSuites.perform();
  }

  @action
  onClose(): void {
    this.args.onClose?.();
  }

  @action
  createSuite(name: string): void {
    const { actions } = this.powerSelectAPI;

    const suite = this.store.createRecord('zone', {
      name,
      parent: this.args.property,
      company: this.state.currentCompany,
    });

    this.tenant.suites = [...A(this.tenant.suites).toArray(), suite];

    this.suggestionTerm = null;
    actions.close();
  }

  @action
  onChangeSuites(suites: ZoneModel[]): void {
    const [suiteToRemove] = this.tenant.suites.filter((suite) => !suites.includes(suite));
    this.tenant.suites = suites;

    if (suiteToRemove?.isNew) void suiteToRemove.destroyRecord();
  }

  @action
  onInputSuites(value: string): void {
    this.isSuiteNameDistinct = !!this.suites?.every((suite) => suite.name !== value);
    this.suggestionTerm = value.trim();
  }

  @action
  onCloseSuites(): void {
    this.suggestionTerm = null;
    this.isSuiteNameDistinct = true;
  }

  saveTask = task(async () => {
    if (isChangeset(this.tenant)) {
      const tenant = <TenantChangeset>this.tenant;
      await tenant.validate();

      if (tenant.isInvalid) return;
    }

    const tenantIsNew = <boolean>(<unknown>this.tenant.isNew);

    try {
      const newSuites = A(this.tenant.suites.filter((suite) => suite.isNew));
      await all(newSuites.invoke('save'));
      await this.tenant.save();

      this.args.onSave?.();

      if (tenantIsNew) {
        this.flashMessages.showAndHideFlash('success', 'Tenant added.');

        this.inviteTenant = true;
        this.showInviteSkip = true;
      } else {
        this.flashMessages.showAndHideFlash('success', 'Tenant updated.');
        this.onClose();
      }
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  getSuites = task(async () => {
    try {
      return await this.store.query('zone', {
        filter: { parent: this.args.property.id },
        page: {
          limit: 200,
        },
        sort: 'name',
      });
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      return;
    }
  });
}

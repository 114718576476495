import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import ManageCreateRoleRoute from 'garaje/pods/manage/create-role/route';

class LocationOverviewManageCreateRoleRoute extends ManageCreateRoleRoute {
  controllerName = 'manage/create-role';
  templateName = 'manage/create-role';

  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  beforeModel(): void {
    if (this.abilities.cannot('create-on-global custom-roles')) {
      void this.router.transitionTo('unauthorized');
    }
  }
}

export default LocationOverviewManageCreateRoleRoute;

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { get } from '@ember/object';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class SettingsVisitorEmailsRoute extends Route {
  @service abilities;
  @service state;
  @service transitionConfirm;
  @service router;
  @service store;

  titleToken = 'Visitor emails';

  beforeModel() {
    if (this.abilities.cannot('visit settings')) {
      this.router.transitionTo('dashboard');
    } else if (this.abilities.cannot('visit visitor-emails')) {
      this.router.transitionTo('visitors.settings');
    }
  }

  async model() {
    const currentLocation = get(this.state, 'currentLocation');
    const mailerTemplates = await this.store.findAll('mailer-template');
    return hash({ currentLocation, mailerTemplates });
  }

  @routeEvent
  routeWillChange(transition) {
    const controller = this.controller;
    const { visitorGuideHasChanges, surveyConfigHasChanges, model } = controller;
    const { currentLocation } = model;
    // no need to display confirmation modal when nothing is changed
    if (
      !visitorGuideHasChanges &&
      !surveyConfigHasChanges &&
      !currentLocation.hasDirtyAttributes &&
      !currentLocation.hasDirtyFlows()
    ) {
      return;
    }
    // display confirmation modal if settings are dirty
    get(this.transitionConfirm, 'displayConfirmTask').perform(transition, {
      continue() {
        if (visitorGuideHasChanges) {
          controller.send('rollbackVisitorGuide');
        }
        if (surveyConfigHasChanges) {
          controller.send('rollbackSurveyConfig');
        }
        if (currentLocation.hasDirtyAttributes) {
          currentLocation.rollbackAttributes();
        }
        if (currentLocation.hasDirtyFlows()) {
          get(currentLocation, 'flows').forEach((flow) => flow.rollbackAttributes());
        }
      },
    });
  }
}

import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { pluralize } from 'ember-inflector';
import { type PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type TenantModel from 'garaje/models/tenant';
import type ZoneModel from 'garaje/models/zone';
import { isPaginated } from 'garaje/utils/is-paginated';
import zft from 'garaje/utils/zero-for-tests';

export type ModelTypes = TenantModel | ZoneModel;

export interface MultiZonesSelectorArgs {
  disabledMessage(model: ModelTypes): string | null;
  isDisabled(model: ModelTypes): boolean;
  loading: boolean;
  selectedIds?: string;
  zones: ModelTypes[] | PaginatedRecordArray<ModelTypes>;
  filteredZones?: PaginatedRecordArray<ModelTypes>;
  onSelected: (selected: ModelTypes) => unknown;
  onDeselectAll: () => unknown;
  onSearch?: (query: string) => unknown;
  typeOfZone: string;
}

/**
 * Dropdown selector for tenants or suites
 */
export default class MultiZonesSelector extends Component<MultiZonesSelectorArgs> {
  @tracked searchQuery = '';

  get itemsById(): Record<string, ModelTypes> {
    return this.args.zones.reduce<Record<string, ModelTypes>>((acc, zone) => {
      acc[zone.id] = zone;
      return acc;
    }, {});
  }

  get isPaginated(): boolean {
    return isPaginated(this.args.zones);
  }

  get anySelected(): boolean {
    return !!this.selectedZones?.length;
  }

  get selectedText(): string | undefined {
    const totalSelected = this.selectedZones.length;
    if (isBlank(this.selectedZones) || totalSelected === this.args.zones.length) {
      return `All ${pluralize(this.args.typeOfZone)}`;
    } else if (totalSelected === 1) {
      const firstItem = this.selectedZones[0];
      // if response is paginated, there is a possibility of the record being outside of the page
      return firstItem && 'outsidePage' in firstItem ? `1 ${this.args.typeOfZone}` : firstItem?.name;
    } else {
      return `${totalSelected} ${pluralize(this.args.typeOfZone)}`;
    }
  }

  get selectedZones(): Array<ModelTypes | { outsidePage: true }> {
    const ids = (this.args.selectedIds || '').split(',').filter(Boolean);
    return ids.map((id) => {
      return this.itemsById[id] ?? { outsidePage: true };
    });
  }

  get filteredZones(): MultiZonesSelectorArgs['zones'] {
    if (isBlank(this.searchQuery)) {
      return this.args.zones;
    }

    if (this.isPaginated && this.args.filteredZones) return this.args.filteredZones;

    return this.args.zones.reduce<ModelTypes[]>((filteredOpts, zone) => {
      if (this.#matchesSearchQuery(zone.name)) {
        filteredOpts.push(zone);
        return filteredOpts;
      }

      return filteredOpts;
    }, []);
  }

  get renderSearch(): boolean {
    return <number>this.args.zones.length >= 4 || !!this.searchQuery;
  }

  @action
  handleSearch(event: InputEvent): void {
    const val = (<HTMLInputElement>event.target).value;
    // eslint-disable-next-line @typescript-eslint/unbound-method
    debounce(this, this.setSearchQuery, val, zft(250));
  }

  setSearchQuery(value: string): void {
    if (this.isPaginated) {
      this.args.onSearch?.(value);
    }

    this.searchQuery = value;
  }

  /**
   * Helper that matches a string against this.searchQuery. Returns true if
   * there's a string overlap, false if not.
   */
  #matchesSearchQuery(str: string): boolean {
    return str.toLowerCase().includes(this.searchQuery.toLowerCase());
  }
}

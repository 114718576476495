import { TextEditor } from '@envoy/polarwind-react';
import { FieldValues, Path, useController } from 'react-hook-form';

export interface FormTextEditorProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  required?: string;
  disabled?: boolean;
  maxLength?: { value: number; message: string };
  variables?: { label: string; value: string }[];
  placeholder?: string;
  multiline?: number;
  testId?: string;
  tooltip?: string;
}

export function FormTextEditor<T extends FieldValues>(props: FormTextEditorProps<T>) {
  const {
    field,
    fieldState: { error },
  } = useController({ name: props.name, rules: { required: props.required, maxLength: props.maxLength } });

  return (
    <TextEditor
      label={props.label}
      required={!!props.required}
      disabled={props.disabled}
      placeholder={props.placeholder}
      value={field.value}
      variables={props.variables}
      onBlur={() => field.onBlur()}
      onChange={(value) => field.onChange(value)}
      error={error?.message}
      testId={props.testId}
      tooltip={props.tooltip}
      key={JSON.stringify(props.variables)}
    />
  );
}

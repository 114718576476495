import Model, { attr, belongsTo } from '@ember-data/model';
import { apiAction } from '@mainmatter/ember-api-actions';
import type CompanyModel from 'garaje/models/company';
import adapter from 'garaje/utils/decorators/adapter';

// This is specifically used for billing
@adapter('billing-v1')
class CompanyBillingAddressModel extends Model {
  @belongsTo('company') declare company: CompanyModel;

  @attr('string') declare line1: string | null;
  @attr('string') declare city: string | null;
  @attr('string') declare state: string | null;
  @attr('string') declare zip: string | null;
  @attr('string') declare country: string | null;

  async confirm(): Promise<unknown> {
    return await apiAction(this, { method: 'POST', path: 'confirm' });
  }
}

export default CompanyBillingAddressModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'company-billing-address': CompanyBillingAddressModel;
  }
}

import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type { AsyncBelongsTo, SyncHasMany } from '@ember-data/model';

import type TicketCategoryEmailModel from './ticket-category-email';
import type TicketConfigurationModel from './ticket-configuration';

class TicketCategoryModel extends Model {
  @attr('string') declare name: string;

  @belongsTo('ticket-configuration') declare ticketConfiguration: AsyncBelongsTo<TicketConfigurationModel>;
  @hasMany('ticket-category-email', { async: false })
  declare ticketCategoryEmails: SyncHasMany<TicketCategoryEmailModel>;
}

export default TicketCategoryModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'ticket-category': TicketCategoryModel;
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

/**
 * @param {Object}                    categories
 * @param {Resource}                  placingResource
 */
export default class AvailableResourcesListView extends Component {
  @tracked collapsedCategories = [];

  @action
  setCollapsedCategories(category) {
    if (this.collapsedCategories.includes(category)) {
      this.collapsedCategories = this.collapsedCategories.filter((collapsedCategory) => collapsedCategory != category);
    } else {
      this.collapsedCategories = [...this.collapsedCategories, category];
    }
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type FlowModel from 'garaje/models/flow';
import type LocationModel from 'garaje/models/location';
import type GlobalSettingBatchService from 'garaje/services/global-setting-batch';
import type StateService from 'garaje/services/state';
import { hash } from 'rsvp';

export interface VisitorTypesFlowRouteModel {
  allFlowsForCurrentLocation: FlowModel[];
  currentLocation: LocationModel;
  flow: FlowModel;
  vrSubscription: StateService['vrSubscription'];
}

export default class VisitorTypesFlowRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service('global-setting-batch') declare globalSettingBatchService: GlobalSettingBatchService;
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  model(params: { flow_id: string }): Promise<VisitorTypesFlowRouteModel> {
    const { vrSubscription, currentLocation } = this.state;
    const allFlowsForCurrentLocation = this.state.loadFlows({ reload: false, locationId: currentLocation.id });
    let include = 'sign-in-field-page,agreement-page,agreement-page.agreements,badge,photo-page,summary-page';
    if (this.abilities.can('configure data privacy settings')) {
      include += ',visitor-document-contacts,invite-approval-contacts';
    }

    const flow = this.store.findRecord('flow', params.flow_id, {
      include,
      reload: true,
    });

    return hash({
      allFlowsForCurrentLocation,
      currentLocation,
      flow,
      vrSubscription,
    });
  }

  // @ts-ignore
  afterModel(
    { currentLocation, flow }: VisitorTypesFlowRouteModel,
    _transition: Transition,
  ): Transition<unknown> | void {
    if (this.abilities.can('propagate global-setting-batch') && !flow.isGlobalChild) {
      this.globalSettingBatchService.createGlobalSettingBatch({ parent: flow, signInFields: [] });
    }

    // if we change location, we want to make sure that the flow the
    // user is looking at, belongs to "currentLocation".
    if (flow.belongsTo('location').id() !== currentLocation.id) {
      return this.router.transitionTo('visitors.settings.visitor-types.index');
    }
  }
}

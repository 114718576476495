import Report from 'garaje/models/report';
import _defaults from 'lodash/defaults';

export default class Blacklist extends Report {
  declare blacklistFilterId: string | null;
  declare blacklistFilterSource: string | null;
  declare blacklistFilterSourceId: number | null;
  declare blacklistKeyword: string | null;
  declare status: string | null;

  constructor(attrs: Partial<Blacklist> = {}) {
    super(attrs);
    const props = { ...attrs };
    _defaults(props, {
      blacklistFilterId: null,
      blacklistFilterSource: null,
      blacklistFilterSourceId: null,
      blacklistKeyword: null,
      status: null,
    });
    Object.assign(this, props);
  }
}

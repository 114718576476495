import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';

import type ZoneModel from './zone';

export default class PrinterConnection extends Model {
  @attr('boolean')
  declare global: boolean;

  @attr('string')
  declare name: string;

  @attr('string')
  declare hostname: string;

  @attr('string')
  declare state: string;

  @attr('string')
  declare externalResourceVendor: string;

  @attr('string')
  declare externalResourceUid: string;

  @attr('date')
  declare createdAt: Date;

  @belongsTo('company')
  declare company: AsyncBelongsTo<Model>;

  @belongsTo('location')
  declare location: AsyncBelongsTo<Model>;

  @belongsTo('zone')
  declare zone: AsyncBelongsTo<ZoneModel>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'printer-connection': PrinterConnection;
  }
}

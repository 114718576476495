import Component from '@glimmer/component';
import { action, get, set } from '@ember/object';

/**
 * @param {Array}               options
 * @param {Function}            onUpdate
 */
export default class HeaderSelector extends Component {
  @action
  toggleHeaders(option) {
    const show = get(option, 'show');
    set(option, 'show', !show);
    this.args.onUpdate?.(option);
  }
}

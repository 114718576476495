import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import { Permission, PermissionType } from 'garaje/utils/ui-permissions';

export default class EmployeeLocationAbility extends Ability {
  @service declare authz: AuthzService;

  @tracked locationId = '';

  get canDelete(): boolean {
    return this.authz.hasPermission(
      PermissionType.LOCATION,
      this.locationId,
      Permission.ACCOUNT_MANAGEMENT_LOCATION_UPDATE,
    );
  }
}

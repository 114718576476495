import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { didCancel } from 'ember-concurrency';
import { get, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { format } from 'date-fns';
import { fetchCapacity } from 'garaje/utils/locations-capacity';

class WorkplaceEntriesIndexRoute extends Route {
  @service state;
  @service router;
  @service store;

  titleToken = '';

  async model() {
    const { currentLocation, workplaceSubscription } = this.state;
    const { capacityLimitEnabled } = currentLocation;
    const blocklistContacts = get(currentLocation, 'blocklistContacts');
    const idScanContacts = get(currentLocation, 'idScanContacts');
    const initial_entries = this.modelFor('workplace.entries');
    const controller = this.controllerFor('workplace.entries');

    const props = {
      refresh: '',
      page: 0,
      limit: 25,
      totalLoadedEntries: 0,
      date: initial_entries.date,
      query: initial_entries.query,
      filter: initial_entries.filter,
      sort: initial_entries.sort,
      selectedFlow: initial_entries.selectedFlow,
    };

    setProperties(controller, props);
    await controller.loadBosses.perform();
    await controller.loadEntries.perform().catch((e) => {
      if (!didCancel(e)) {
        throw e;
      }
      return this.router.transitionTo('workplace.entries');
    });
    const entries = this.modelFor('workplace.entries');

    let { date } = this.paramsFor('workplace.entries');
    date = date || format(new Date(), 'yyyy-MM-dd');

    let locationsCapacity = null;

    if (capacityLimitEnabled) {
      locationsCapacity = fetchCapacity(this.store, currentLocation, date);
    }

    return hash({
      blocklistContacts,
      currentLocation,
      entries,
      idScanContacts,
      locationsCapacity,
      workplaceSubscription,
      loadEntries: controller.loadEntriesRestartableTask,
      workplaceDays: controller.workplaceDays,
    });
  }
}

export default WorkplaceEntriesIndexRoute;

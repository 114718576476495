import { A } from '@ember/array';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import { dropTask, type Task } from 'ember-concurrency';
import type ConnectLocationConfigurationModel from 'garaje/models/connect-location-configuration';
import type LocationModel from 'garaje/models/location';
import type UserModel from 'garaje/models/user';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST } from 'garaje/utils/roles';
import isEqual from 'lodash/isEqual';

interface ConnectNotificationsVisitorComponentArgs {
  location: LocationModel;
  searchUsersTask: Task<UserModel[], [term: string, contacts: UserModel[]]>;
  connectLocationConfiguration: ConnectLocationConfigurationModel;
}

export default class ConnectNotificationsVisitorComponent extends Component<ConnectNotificationsVisitorComponentArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare store: StoreService;

  @tracked isOpen = false;
  @tracked _contactIds: string[] = [];
  @tracked locationChangeset!: DetailedChangeset<LocationModel>;
  @tracked connectLocationConfigurationChangeset!: DetailedChangeset<ConnectLocationConfigurationModel>;

  get canEdit(): boolean {
    return (
      this.connectLocationConfigurationChangeset.connectWalkInApprovalContactsEnabled && !this.enableTask.isRunning
    );
  }

  get contacts(): UserModel[] {
    if (this.loadContactsTask.isRunning) return [];
    const connectWalkInApprovalContacts = this.locationChangeset.connectWalkInApprovalContacts;
    if (Array.isArray(connectWalkInApprovalContacts)) return connectWalkInApprovalContacts;
    return connectWalkInApprovalContacts.toArray();
  }

  get contactsChanged(): boolean {
    return !isEqual(this._contactIds, A(this.contacts).mapBy('id'));
  }

  constructor(owner: unknown, args: ConnectNotificationsVisitorComponentArgs) {
    super(owner, args);
    void this.loadContactsTask.perform(this.args.location);
  }

  enableTask = dropTask(async () => {
    if (!this.contacts.length) {
      this.isOpen = true;
      return;
    }

    await this.saveLocation(true);
    this.isOpen = true;
  });

  loadContactsTask = dropTask(async (location: LocationModel) => {
    try {
      this.connectLocationConfigurationChangeset = Changeset(this.args.connectLocationConfiguration);
      const users = await location.connectWalkInApprovalContacts;
      this._contactIds = users.map((user) => user.id);
      this.locationChangeset = Changeset(this.args.location);
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  searchTask = dropTask(async (term: string) => {
    const userRoles = await this.store.query('user-role', {
      filter: {
        name: term,
        location: this.args.location.id,
        roles: [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST].join(),
        confirmedUsers: true,
      },
      include: 'user',
    });

    // deduplicate users, since a single user may have multiple matching roles and we only want to show them once
    const allUsers = await Promise.all(userRoles.map((role) => role.user));
    const uniqueUsers = new Set(allUsers);
    return Array.from(uniqueUsers);
  });

  saveTask = dropTask(async () => {
    await this.saveLocation(!!this.contacts.length);
    void this.loadContactsTask.perform(this.args.location);
  });

  @action
  async saveLocation(enabled: boolean): Promise<void> {
    try {
      const contactsChanged = !!A(this.locationChangeset.changes).findBy('key', 'connectWalkInApprovalContacts');
      if (isPresent(enabled)) {
        this.connectLocationConfigurationChangeset.connectWalkInApprovalContactsEnabled = enabled;
      }

      await this.connectLocationConfigurationChangeset.save();
      await this.locationChangeset.save();
      if (!enabled) {
        this.isOpen = false;
      }

      if (contactsChanged) {
        this.flashMessages.showAndHideFlash('success', 'Saved!');
      }
    } catch (e) {
      this.locationChangeset.rollback();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @action
  cancel(): void {
    this.isOpen = false;
    this.locationChangeset.rollback();
    this.connectLocationConfigurationChangeset.rollback();
  }

  @action
  setContacts(users: UserModel[]): void {
    this.locationChangeset.connectWalkInApprovalContacts = users;
  }
}

import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';

import type SettingsEmailTemplatesShowController from '../controller';
import type { SettingsEmailTemplatesShowRouteModel } from '../route';

export default class SettingsEmailTemplatesShowIndexRouteParams extends Route {
  declare controller: SettingsEmailTemplatesShowController;
  controllerName = 'visitors.settings.email-templates.show';

  setupController(
    controller: SettingsEmailTemplatesShowController,
    model: SettingsEmailTemplatesShowRouteModel,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);
    void controller.previewEmailTask.perform();
    void transition.promise.finally(function () {
      controller.tempSelectId = null;
    });
  }
}

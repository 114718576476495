import Route from '@ember/routing/route';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';

export default class VisitorsStartTrialRoute extends Route {
  @service state;
  @service router;

  titleToken = 'Visitors';

  redirect() {
    const { vrSubscription } = this.state;
    if (isPresent(vrSubscription)) {
      this.router.transitionTo('visitors.entries');
    }
  }
}

import { service } from '@ember/service';
import ApplicationSerializer from './application';

export default class ZoneSerializer extends ApplicationSerializer {
  @service featureFlags;

  attrs = {
    createdAt: {
      serialize: false,
    },
    updatedAt: {
      serialize: false,
    },
    coverPhotoUrl: {
      serialize: false,
    },
  };

  serialize(snapshot) {
    const payload = super.serialize(...arguments);
    const changedAttributes = snapshot.changedAttributes();

    // Only send `coverPhoto` if it has changed
    if (!changedAttributes['coverPhoto']) {
      delete payload.data.attributes['cover-photo'];
    }

    // Only send `logo` if it has changed
    if (!changedAttributes['logo']) {
      delete payload.data.attributes['logo'];
    }

    if (changedAttributes['address'] && payload.data.attributes.address.timezone) {
      payload.data.attributes.address['time_zone'] = payload.data.attributes.address.timezone;
      delete payload.data.attributes.address.timezone;
    }

    // The backend expects the frontend to manage the value of the `multiple-languages-enabled` flag, but since
    // there is no on/off toggle in the UI for this, its value can be inferred from whether the `enabled-locales`
    // array is empty or not. Note that the default locale for a property is never included in the `enabled-locales`
    // array, so if the array is not empty it means at least two locales are enabled (the default plus the one(s)
    // listed in the array).
    payload.data.attributes['multiple-languages-enabled'] = payload.data.attributes['enabled-locales'].length > 0;

    return payload;
  }
}

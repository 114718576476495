import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import type { SafeString } from '@ember/template/-private/handlebars';
import Component from '@glimmer/component';
import type AccessEventModel from 'garaje/models/access-event';
import type CurrentAdminService from 'garaje/services/current-admin';
import type MetricsService from 'garaje/services/metrics';

/**
 * @param {String}                  menuHeight
 * @param {Boolean}                 isSelectAllIndeterminate
 * @param {Boolean}                 allSelected
 * @param {Function}                clearAccessEvents
 * @param {Array<AccessEventModel>} accessEvents
 */

interface BulkActionsAccessEventsMenuComponentArgs {
  menuHeight: number;
  isSelectAllIndeterminate: boolean;
  allSelected: boolean;
  clearAccessEvents: () => void;
  accessEvents?: AccessEventModel[];
}

export default class BulkActionsMenu extends Component<BulkActionsAccessEventsMenuComponentArgs> {
  @service declare currentAdmin: CurrentAdminService;
  @service declare metrics: MetricsService;

  get heightStyle(): SafeString {
    return htmlSafe(`top: -13px;height:${this.args.menuHeight}`);
  }
}

import { assert } from '@ember/debug';
import { A, isArray } from '@ember/array';
import { get, set } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { defer, resolve } from 'rsvp';
import { task } from 'ember-concurrency';

const confirmTransferTask = () => {
  return task({
    destination: null,
    isTransferToNewLocation: false,

    *perform({ destination, origins = [] }) {
      const deferred = defer();

      const destinationLocationId = destination.belongsTo('location').id();

      const isTransferToNewLocation = origins.some((deliveryArea) => {
        return deliveryArea.belongsTo('location').id() !== destinationLocationId;
      });

      set(this, 'destination', destination);
      set(this, 'isTransferToNewLocation', isTransferToNewLocation);

      this.abort = () => deferred.resolve(false);
      this.continue = () => deferred.resolve(true);

      return yield deferred.promise;
    },
  });
};

const findAllDeliveryAreasForLocation = (store, location) => {
  return store.query('delivery-area', {
    filter: {
      location: get(location, 'id'),
    },
  });
};

const findAllDeliveryAreasForMultipleLocations = (store, locations) => {
  assert('The second argument should be an array', isArray(locations));
  const locationIds = locations.mapBy('id');

  if (isEmpty(locations)) {
    return resolve(A());
  }

  return store.query('delivery-area', {
    filter: {
      location: locationIds.join(','),
    },
  });
};

const findAllDeliveryAreasForCompany = (store) => {
  return store.findAll('delivery-area');
};

export {
  confirmTransferTask,
  findAllDeliveryAreasForLocation,
  findAllDeliveryAreasForCompany,
  findAllDeliveryAreasForMultipleLocations,
};

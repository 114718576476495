import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type GlobalOverviewAccessService from 'garaje/services/global-overview-access';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import type VisitorsOnboardingService from 'garaje/services/visitors-onboarding';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

import type EmployeesController from './controller';

export default class LocationOverviewEmployeesRoute extends Route {
  @service declare globalOverviewAccess: GlobalOverviewAccessService;
  @service declare router: RouterService;
  @service declare skinnyLocations: SkinnyLocationsService;
  @service declare visitorsOnboarding: VisitorsOnboardingService;

  titleToken = 'Employees';

  beforeModel(): void {
    if (!this.globalOverviewAccess.canVisitGlobalEmployees) {
      return void this.router.transitionTo('unauthorized');
    }
  }

  async model(): Promise<void> {
    const locations = this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
    await this.visitorsOnboarding.loadSteps();
    await locations;
  }

  setupController(controller: EmployeesController, model: void, transition: Transition): void {
    super.setupController(controller, model, transition);
    void this.visitorsOnboarding.loadImpressionsTask.perform();
  }
}

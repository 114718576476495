import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import type MailerBlockModel from 'garaje/models/mailer-block';
import type MailerSectionModel from 'garaje/models/mailer-section';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

interface MailerTemplateEditorMailerSectionComponentSignature {
  Args: {
    mailerSection: MailerSectionModel;
    onUpdate?: () => void;
  };
}

export default class MailerTemplateEditorMailerSectionComponent extends Component<MailerTemplateEditorMailerSectionComponentSignature> {
  @service declare flashMessages: FlashMessagesService;

  @action
  reorderSection(mailerBlocks: MailerBlockModel[]): void {
    mailerBlocks.forEach((mailerBlock, position) => {
      if (!mailerBlock.isDeleted) mailerBlock.position = position;
    });

    this.args.onUpdate?.();
  }

  addBlockTask = task(async () => {
    const {
      flashMessages,
      args: { mailerSection },
    } = this;
    const customBlock = mailerSection.createHTMLBlock();

    try {
      await customBlock.save();

      flashMessages.showAndHideFlash('success', `New block "${customBlock.title}" created!`);

      this.args.onUpdate?.();
    } catch (e) {
      customBlock.rollbackAttributes();

      flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}

import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class EmailTemplateSettingsService extends Service {
  @tracked title;
  @tracked description;
  @tracked sidebarTitle;
  @tracked sidebarHeader;
  @tracked sidebarBody;

  setSidebarComponents(data) {
    this.title = data.title;
    this.description = data.description;
    this.sidebarTitle = data.sidebarTitle;
    this.sidebarHeader = data.header;
    this.sidebarBody = data.body;
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type FeatureFlagsService from 'garaje/services/feature-flags';

export default class ConnectAnalyticsOccupancyRoute extends Route {
  title = 'Connect · Analytics · Envoy';

  @service declare featureFlags: FeatureFlagsService;
  @service declare router: RouterService;

  redirect(): void {
    const canAccess =
      this.featureFlags.isEnabled('connect-occupancy-analytics') ||
      this.featureFlags.isEnabled('connect-occupancy-analytics-live');

    if (!canAccess) {
      void this.router.transitionTo('unauthorized');
    }
  }
}

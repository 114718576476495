import { camelize, dasherize } from '@ember/string';

export default class NotificationMessage {
  hostMessage = null;
  delegatedMessage = null;
  message = null;
  for = null;

  constructor(attrs) {
    Object.getOwnPropertyNames(attrs).forEach((key) => (this[camelize(key)] = attrs[key]));
  }

  serialize() {
    return Object.getOwnPropertyNames(this).reduce((acc, key) => {
      acc[dasherize(key)] = this[key];
      return acc;
    }, {});
  }
}

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import handsontable from 'handsontable';

export default class DesksShowRoute extends Route {
  @service store;
  @service router;
  @service state;
  @service featureFlags;
  @service abilities;

  beforeModel() {
    if (this.abilities.cannot('view-floor-details desks')) {
      this.router.transitionTo('unauthorized');
    }
  }

  async model({ floor_id }) {
    const floor = this.store.findRecord('floor', floor_id);
    const desks = this.store.query('desk', {
      filter: {
        'location-id': this.state.currentLocation.id,
        'floor-id': floor_id,
      },
      include: 'amenities',
    });
    return hash({ floor, desks, handsontable });
  }

  redirect({ floor }) {
    if (!floor) {
      this.router.transitionTo('desks.landing-page');
    }
  }
}

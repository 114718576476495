import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';

export default class CommunicationsTemplatesRoute extends Route {
  @service declare store: Store;
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  beforeModel(): void {
    if (this.abilities.cannot('manage-announcement-templates global-communications')) {
      this.router.transitionTo('unauthorized');
    }
  }

  async model(): Promise<void> {
    await this.store.findAll('announcement-template-category', { reload: true });
  }
}

import Transform from '@ember-data/serializer/transform';

// Converts place-id in the JSON to placeId in the app
export default class LocalityTransform extends Transform {
  deserialize(serialized) {
    serialized.placeId = serialized['place-id'];
    delete serialized['place-id'];
    return serialized;
  }

  serialize(deserialized) {
    deserialized['place-id'] = deserialized.placeId;
    delete deserialized.placeId;
    return deserialized;
  }
}

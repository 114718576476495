import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type { AsyncHasMany, AsyncBelongsTo } from '@ember-data/model';
import type CompanyModel from 'garaje/models/company';
import type LocationModel from 'garaje/models/location';
import type WorkplaceDayModel from 'garaje/models/workplace-day';

class DeskLocationModel extends Model {
  @belongsTo('location') declare location: AsyncBelongsTo<LocationModel>;
  @belongsTo('company', { inverse: null }) declare company: AsyncBelongsTo<CompanyModel>;
  @hasMany('workplace-day') declare workplaceDays: AsyncHasMany<WorkplaceDayModel>;

  @attr('boolean') declare active: boolean;
  @attr('number') declare desksQuantity: number;
  @attr('number') declare assignableDesksQuantity: number;
  @attr('boolean') declare autoAssignDesk: boolean;
  @attr('string') declare checkInRequiredBy: string;
  @attr('number') declare checkInDuration: number;
  @attr('string') declare timeZone: string;
}

export default DeskLocationModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'desk-location': DeskLocationModel;
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';

/**
 * @param {Class<TicketCategoryEmailChangeset>}     changeset           Required - Ticket category email
 * @param {Array<TicketCategory>}                   ticketCategories    Required - List of categories
 * @param {Function}                                onDelete            Required - Function to call when delete is clicked
 */
export default class TicketCategoryEmailFormComponent extends Component {
  @tracked emailErrors = [];

  defaultCategory = { name: 'All Categories' };

  get categories() {
    return [this.defaultCategory, ...this.args.ticketCategories];
  }

  get selectedCategory() {
    return this.args.changeset.ticketCategory || this.defaultCategory;
  }

  @action
  focusInput(element) {
    element.focus();
  }

  @action
  async onTicketCategoryEmailChange(email) {
    set(this.args.changeset, 'email', email);
    await this.args.changeset.validate();

    const errors = this.args.changeset.errors[0];
    this.emailErrors = errors ? [errors.validation[0]] : [];
  }

  @action
  onCategorySelected(category) {
    if (category == this.defaultCategory) {
      set(this.args.changeset, 'ticketCategory', null);
    } else {
      set(this.args.changeset, 'ticketCategory', category);
    }
  }
}

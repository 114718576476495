import gql from 'graphql-tag';

export default gql`
  fragment SelfCertifyFragment on SelfCertify {
    fullName
    expectedArrivalTime
    email
    locationId
    capacityReached
    userData {
      field
      value
    }
  }
`;

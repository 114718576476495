import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import type MailerTemplateModel from 'garaje/models/mailer-template';

interface CreateMailerTemplateModalComponentSignature {
  Args: {
    mailerTemplate: MailerTemplateModel;
    overlayClass: string;
    onSave?: (title: string) => Promise<void>;
    onClose?: () => void;
  };
}

export default class CreateMailerTemplateModalComponent extends Component<CreateMailerTemplateModalComponentSignature> {
  @tracked title = '';

  get isValidTitle(): boolean {
    return this.title.trim().length > 0;
  }

  submitTask = dropTask(async (e: Event): Promise<void> => {
    e.preventDefault();

    await this.args.onSave?.(this.title);
  });
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action } from '@ember/object';

export default class extends Component {
  @tracked sortBy = 'Name';
  @tracked sortDirection = 'asc';

  queryModelName = 'global-flow';

  get queryParams() {
    const sortBy = this.sortBy.toLowerCase();
    const sort = this.sortDirection === 'asc' ? sortBy : `-${sortBy}`;
    return {
      include: 'locations,flows',
      fields: {
        'global-flows': 'name,description,locations,flows',
        locations: 'name',
        flows: 'name',
      },
      sort,
      perPage: 15,
      startingPage: 0,
      perPageParam: 'page[limit]',
      pageParam: 'page[offset]',
      countParam: 'meta.total',
      storeFindMethod: 'queryTransient',
    };
  }

  @action
  sortGlobalFlows(sortBy, sortDirection) {
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
  }
}

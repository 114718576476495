import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { setProperties } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProductPlansIndexRoute extends Route {
  @service store;

  model() {
    const { activeRoomsQuantity, exceedsRoomsBasicLimit } = this.modelFor('billing');
    const { paymentSource, subscription } = this.modelFor('billing.product-plans');

    let plans;
    switch (subscription.app) {
      case 'visitors':
        plans = hash({
          standard: this.store.findRecord('plan', 'standard-1'),
          premium: this.store.findRecord('plan', 'premium'),
          enterprise: this.store.findRecord('plan', 'enterprise'),
        });
        break;
      case 'desks':
        plans = hash({
          standard: this.store.findRecord('plan', 'desks-standard'),
        });
        break;
      case 'deliveries':
        plans = hash({
          standard: this.store.findRecord('plan', 'deliveries-standard'),
        });
        break;
      case 'rooms':
        plans = hash({
          standard: this.store.findRecord('plan', 'rooms-standard'),
        });
        break;
      case 'empxp':
        plans = hash({
          standard: this.store.findRecord('plan', 'empxp-standard'),
          premium: this.store.findRecord('plan', 'empxp-premium'),
          'premium-plus': this.store.findRecord('plan', 'empxp-premium-plus'),
        });
        break;
    }

    return hash({ activeRoomsQuantity, exceedsRoomsBasicLimit, paymentSource, subscription, plans });
  }

  setupController(controller) {
    super.setupController(...arguments);

    setProperties(controller, {
      isEditingCC: false,
      upgradeApp: null,
      upgradePlan: null,
    });
  }
}

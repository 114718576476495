import Controller from '@ember/controller';
import { service } from '@ember/service';
import type Link from 'ember-link/link';
import type LinkManagerService from 'ember-link/services/link-manager';
import type UserDocumentService from 'garaje/services/user-document';

interface Tab {
  label: string;
  link: Link;
}

export default class ProfileController extends Controller {
  @service declare linkManager: LinkManagerService;
  @service('user-document') declare userDocumentService: UserDocumentService;

  get tabs(): Tab[] {
    const { linkManager } = this;

    const tabs = [
      {
        label: 'Edit profile',
        link: linkManager.createUILink({
          route: 'profile',
        }),
      },
      {
        label: 'Notification settings',
        link: linkManager.createUILink({
          route: 'profile.notifications',
        }),
      },
      {
        label: 'Onboarding',
        link: linkManager.createUILink({
          route: 'profile.onboarding',
        }),
      },
    ];

    if (this.userDocumentService.isActive('covid-19-vaccine-card')) {
      tabs.push({
        label: 'Vaccinations',
        link: linkManager.createUILink({
          route: 'profile.document',
          models: ['covid-19-vaccine-card'],
        }),
      });
    }

    if (this.userDocumentService.isActive('covid-19-test-result')) {
      tabs.push({
        label: 'Test results',
        link: linkManager.createUILink({
          route: 'profile.document',
          models: ['covid-19-test-result'],
        }),
      });
    }

    return tabs;
  }
}

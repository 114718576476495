const completeUrlRegex = /^(http|https)/;

/**
 * Parse a URL string into an object that defines its structure
 *
 * The returned object will have the following properties:
 *```
 *   href: the full URL
 *   protocol: the request protocol
 *   hostname: the target for the request
 *   port: the port for the request
 *   pathname: any URL after the host
 *   search: query parameters
 *   hash: the URL hash
 *```
 * @param str
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function parseURL(str: string) {
  const element = document.createElement('a');
  element.href = str;
  const fullObject = element;

  const desiredProps = {
    href: fullObject.href,
    protocol: fullObject.protocol,
    hostname: fullObject.hostname,
    port: fullObject.port,
    pathname: fullObject.pathname,
    search: fullObject.search,
    hash: fullObject.hash,
  };

  return desiredProps;
}

/**
 * Returns true if both `a` and `b` have the same protocol, hostname, and port.
 * @param  a
 * @param  b
 * @function haveSameHost
 */
export function haveSameHost(a: string, b: string): boolean {
  const urlA = parseURL(a);
  const urlB = parseURL(b);

  return urlA.protocol === urlB.protocol && urlA.hostname === urlB.hostname && urlA.port === urlB.port;
}

/**
 * Checks if the URL is already a full URL
 * @param  url
 */
export function isFullURL(url: string): boolean {
  return !!url.match(completeUrlRegex);
}

/**
 * Checks if the given string starts with '/'
 * @param {string} string The string to check
 */
export function startsWithSlash(string: string): boolean {
  return string.charAt(0) === '/';
}

/**
 * Checks if the given string ends with '/'
 * @param string The string to check
 * @function endsWithSlash
 */
export function endsWithSlash(string: string): boolean {
  return string.charAt(string.length - 1) === '/';
}

/**
 * Remove a leading slash from the given string
 * @param string The string to remove the slash from
 */
export function removeLeadingSlash(string: string): string {
  if (startsWithSlash(string)) {
    return string.substring(1);
  }

  return string;
}

/**
 * Remove a trailing slash from the given string
 * @param string The string to remove the slash from
 * @function removeTrailingSlash
 */
export function removeTrailingSlash(string: string): string {
  if (endsWithSlash(string)) {
    return string.slice(0, -1);
  }

  return string;
}

/**
 * Strip slashes from the given path
 * @param path The path to remove slashes from
 */
export function stripSlashes(path: string): string {
  // make sure path starts with `/`
  if (startsWithSlash(path)) {
    path = removeLeadingSlash(path);
  }

  // remove end `/`
  if (endsWithSlash(path)) {
    path = removeTrailingSlash(path);
  }
  return path;
}

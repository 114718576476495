import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { all, dropTask } from 'ember-concurrency';

import { pluralize } from 'ember-inflector';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

export default class LocationsGroupsNewController extends Controller {
  @service flashMessages;
  @service metrics;
  @service store;

  @tracked groupsToCreate = [];

  reset() {
    this.groupsToCreate.forEach((group) => group.unloadRecord());
    this.groupsToCreate = [this.store.createRecord('group')];
  }

  /**
   * This CP returns wheter or not all the groups to be created have a name set
   *
   * @return {Boolean}
   */
  get readyToSubmit() {
    return this.groupsToCreate.every((group) => isPresent(group.name));
  }

  @dropTask
  *saveGroupsTask() {
    try {
      const groupsCreated = yield all(this.groupsToCreate.map((group) => group.save()));
      const message = `${pluralize(groupsCreated.length, 'group')} created!`;

      this.metrics.trackEvent('Group Action Taken', {
        action: 'groups_created',
        new_group_names: groupsCreated.map((group) => group.name),
      });

      this.flashMessages.showAndHideFlash('success', message);
      this.groupsToCreate = [];
      this.transitionToRoute('location-overview.locations'); // eslint-disable-line ember/no-deprecated-router-transition-methods
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }

  @action
  addGroup() {
    this.groupsToCreate = [...this.groupsToCreate, this.store.createRecord('group')];
  }

  @action
  deleteNewGroup(index) {
    const [removedRecord] = this.groupsToCreate.splice(index, 1);
    this.groupsToCreate = [...this.groupsToCreate];
    removedRecord.unloadRecord();
  }

  @action
  closeModal() {
    return this.transitionToRoute('location-overview.locations'); // eslint-disable-line ember/no-deprecated-router-transition-methods
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { capitalize } from '@ember/string';
import { task } from 'ember-concurrency';
import { APP } from 'garaje/utils/enums';

export default class CustomizedChecklistTab extends Component {
  @service('personalizedChecklists') personalizedChecklistsService;
  @service statsig;

  @tracked showChecklist = false;

  checklistMetadata = this.args.checklistMetadata;
  app = this.checklistMetadata.app;
  title = `${capitalize(this.app)}: Setup guide`;

  constructor() {
    super(...arguments);

    if (this.app === APP.VISITORS) {
      this.showChecklist =
        this.personalizedChecklistsService.currentUser.visitorsProductVideosEnabled &&
        this.personalizedChecklistsService.displayForVisitors;
    }

    if (this.showChecklist) this.personalizedChecklistsService.loadDataTask.perform(this.checklistMetadata, false);
  }

  get personalizedTabChecklists() {
    return this.personalizedChecklistsService.checklistData;
  }

  get tabMetadata() {
    return this.personalizedChecklistsService.tabMetadata;
  }

  onCloseTask = task({ drop: true }, async () => {
    this.showChecklist = false;
    this.statsig.logEvent('customized-checklist', 'closed');
    await this.personalizedChecklistsService.toggleVisitorsProductVideosEnabledTask.perform();
  });
}

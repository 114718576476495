import { validatePresence } from 'ember-changeset-validations/validators';
import { get } from '@ember/object';

export default function buildFlowFieldValidations(field) {
  const validations = [];

  if (get(field, 'required') && !(get(field, 'allowVisitorRespondents') ?? true)) {
    validations.push(
      validatePresence({
        presence: true,
        message: 'This is a required field',
      }),
    );
  }

  return { value: validations };
}

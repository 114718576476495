import { helper } from '@ember/component/helper';
import { makeArray } from '@ember/array';

export default helper(function arrayContains([array, value, key]) {
  const _array = makeArray(array);
  if (!key) {
    return _array.includes(value);
  } else {
    return _array.mapBy(key).includes(value);
  }
});

import Service, { inject as service } from '@ember/service';
import type {
  Audience,
  GQLAudiences,
  GQLDeliveryMethod,
  GQLTemplate,
  Location,
  TemplateGroup,
} from '@envoy/components-communication';
import { Audiences } from '@envoy/components-communication';
import type { DropdownOptionType } from '@envoy/polarwind-react';
import type AbilitiesService from 'ember-can/services/abilities';
import {
  type GetSkinnyLocationsQuery,
  type GetSkinnyLocationsVariables,
  type GetAnnouncementTemplateNamesForLocationByCategoryQuery,
  type PopulateAnnouncementTemplateQuery,
  type PopulateAnnouncementTemplateVariables,
  type GetAnnouncementAudienceSizesQuery,
  type GetAnnouncementAudienceSizesVariables,
  type GetAnnouncementTemplateNamesForLocationByCategoryVariables,
} from 'garaje/graphql/generated/announcement-types';
import type { EmployeesQueryType, EmployeesQueryVariablesType } from 'garaje/graphql/generated/employees-types';
import type {
  SearchEmployeeGroupsQueryType,
  SearchEmployeeGroupsQueryVariablesType,
} from 'garaje/graphql/generated/search-employee-groups-types';
import announcementAudienceSizesQuery from 'garaje/graphql/queries/AnnouncementAudienceSizesQuery';
import announcementTemplateNamesForLocationByCategoryQuery from 'garaje/graphql/queries/AnnouncementTemplateNamesForLocationByCategory';
import EmployeesQuery from 'garaje/graphql/queries/EmployeesQuery';
import populateAnnouncementTemplateQuery from 'garaje/graphql/queries/PopulateAnnouncementTemplateQuery';
import SearchEmployeeGroupsQuery from 'garaje/graphql/queries/SearchEmployeeGroupsQuery';
import skinnyLocationsQuery from 'garaje/graphql/queries/SkinnyLocationsQuery';
import type ApolloService from 'garaje/services/apollo-extension';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type MetricsService from 'garaje/services/metrics';
import type ReactAnalytics from 'garaje/services/react-analytics';
import type StateService from 'garaje/services/state';

enum EmployeeAudienceType {
  Self = 'self',
  AllEmployeesWithReservation = 'allEmployeesWithReservation',
  AllEmployeesCheckedIn = 'allEmployeesCheckedIn',
  AllEmployeesAtLocation = 'allEmployeesAtLocation',
  AllEmployeesAtDefaultLocation = 'allEmployeesAtDefaultLocation',
}

enum VisitorAudienceType {
  AllVisitorsWithReservation = 'allVisitorsWithReservation',
  AllVisitorsCheckedIn = 'allVisitorsCheckedIn',
  AllVisitorsSignedOut = 'allVisitorsSignedOut',
}

const audienceTypeMap: Record<EmployeeAudienceType | VisitorAudienceType, Audiences> = {
  [EmployeeAudienceType.Self]: Audiences.Self,
  [EmployeeAudienceType.AllEmployeesWithReservation]: Audiences.AllEmployeesWithReservation,
  [EmployeeAudienceType.AllEmployeesCheckedIn]: Audiences.AllEmployeesCheckedIn,
  [EmployeeAudienceType.AllEmployeesAtLocation]: Audiences.AllEmployeesAtLocation,
  [EmployeeAudienceType.AllEmployeesAtDefaultLocation]: Audiences.AllEmployeesAtDefaultLocation,
  [VisitorAudienceType.AllVisitorsWithReservation]: Audiences.AllVisitorsWithReservation,
  [VisitorAudienceType.AllVisitorsCheckedIn]: Audiences.AllVisitorsCheckedIn,
  [VisitorAudienceType.AllVisitorsSignedOut]: Audiences.AllVisitorsSignedOut,
};

export default class CommunicationsService extends Service {
  @service declare apolloExtension: ApolloService;
  @service declare metrics: MetricsService;
  @service declare state: StateService;
  @service declare abilities: AbilitiesService;
  @service declare reactAnalytics: ReactAnalytics;
  @service declare featureFlags: FeatureFlagsService;
  @service declare featureConfig: FeatureConfigService;

  get analytics(): ReactAnalytics {
    return this.reactAnalytics;
  }

  // Checks if the user has access to the visitor audience options
  get showVisitorSelect(): boolean {
    return this.abilities.can('access visitors emergency features for communications');
  }

  // Checks if the user has access to the employee audience options
  get showEmployeeSelect(): boolean {
    return this.abilities.can('access employees emergency features for communications');
  }

  // Checks if the user can select custom employees
  get showCustomEmployeeSelect(): boolean {
    const featureFlagEnabled = this.featureFlags.isEnabled('communications-send-form-custom-employees-web');
    return featureFlagEnabled && (this.canSelectScimGroups || this.canSelectIndividualEmployees);
  }

  get showTotalRecipientCount(): boolean {
    return this.featureFlags.isEnabled('communications-send-form-total-recipient-count-web');
  }

  // Checks if the user has access to the employee scim groups options
  get canSelectScimGroups(): boolean {
    return this.featureConfig.isEnabled('communications.employeeGroups');
  }

  // Checks if the user can select individual employees
  get canSelectIndividualEmployees(): boolean {
    return this.featureFlags.isEnabled('communications-select-individual-employees-web');
  }

  // Checks if the user can multi select audiences
  get canMultiSelectAudiences(): boolean {
    return this.featureFlags.isEnabled('emno-recipient-groups-web');
  }

  async canSendEmergencyNotifications(): Promise<boolean> {
    const emergencyNotificationConfiguration = await this.state.currentLocation.getEmergencyNotificationConfiguration();
    return emergencyNotificationConfiguration.emergencyNotificationsEnabledAt !== null;
  }

  async getAvailableTemplates(locationId: string): Promise<TemplateGroup[]> {
    try {
      const result = await this.apolloExtension.query<
        GetAnnouncementTemplateNamesForLocationByCategoryQuery,
        GetAnnouncementTemplateNamesForLocationByCategoryVariables,
        'announcementTemplateNamesForLocationByCategory'
      >(
        {
          query: announcementTemplateNamesForLocationByCategoryQuery,
          variables: {
            locationId,
          },
          fetchPolicy: 'network-only',
        },
        'announcementTemplateNamesForLocationByCategory',
      );

      return result;
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql getTemplateCategories query. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async getTemplate(locationId: string, templateId: string): Promise<GQLTemplate> {
    try {
      const populateAnnouncementTemplate = await this.apolloExtension.query<
        PopulateAnnouncementTemplateQuery,
        PopulateAnnouncementTemplateVariables,
        'populateAnnouncementTemplate'
      >(
        {
          query: populateAnnouncementTemplateQuery,
          variables: {
            announcementTemplateId: templateId,
            locationId,
          },
        },
        'populateAnnouncementTemplate',
      );

      this.metrics.trackEvent('COMMUNICATIONS_POPULATED_ANNOUNCEMENT_TEMPLATE', {
        template: populateAnnouncementTemplate,
      });

      const defaultEmployees: Required<GQLTemplate['customSelections']> = (
        populateAnnouncementTemplate?.defaultEmployees || []
      ).map((employee) => ({
        label: employee.name,
        subtitle: employee.email,
        value: employee.id.toString(),
        type: 'EMPLOYEE',
      }));

      const defaultEmployeeGroups: Required<GQLTemplate['customSelections']> = (
        populateAnnouncementTemplate?.defaultEmployeeGroups || []
      ).map((group) => ({
        label: group.name,
        value: group.id.toString(),
        type: 'GROUP',
      }));

      const customSelections = [...defaultEmployeeGroups, ...defaultEmployees];

      return {
        title: populateAnnouncementTemplate.title,
        message: populateAnnouncementTemplate.message,
        deliveryMethods: populateAnnouncementTemplate.defaultChannels as GQLDeliveryMethod[],
        employeeGroup: (populateAnnouncementTemplate.defaultEmployeeAudiences as Audiences[]) || [],
        visitorGroup: (populateAnnouncementTemplate.defaultVisitorAudiences as Audiences[]) || [],
        markAsSafe: populateAnnouncementTemplate.markAsSafe,
        customSelections,
      };
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql getTemplate query. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async getAudiences(locationId: string): Promise<GQLAudiences> {
    try {
      const result = await this.apolloExtension.query<
        GetAnnouncementAudienceSizesQuery,
        GetAnnouncementAudienceSizesVariables,
        'announcementAudienceSizes'
      >(
        {
          query: announcementAudienceSizesQuery,
          variables: {
            locationId,
          },
        },
        'announcementAudienceSizes',
      );

      const { employeeAudienceCount, visitorAudienceCount } = result[0]!;

      // this determines the order of values in dropdown too
      return {
        employees: [
          this.getAudienceValue(EmployeeAudienceType.Self, employeeAudienceCount),
          this.getAudienceValue(EmployeeAudienceType.AllEmployeesCheckedIn, employeeAudienceCount),
          this.getAudienceValue(EmployeeAudienceType.AllEmployeesWithReservation, employeeAudienceCount),
          this.getAudienceValue(EmployeeAudienceType.AllEmployeesAtDefaultLocation, employeeAudienceCount),
          this.getAudienceValue(EmployeeAudienceType.AllEmployeesAtLocation, employeeAudienceCount),
        ],
        visitors: [
          this.getAudienceValue(VisitorAudienceType.AllVisitorsCheckedIn, visitorAudienceCount),
          this.getAudienceValue(VisitorAudienceType.AllVisitorsWithReservation, visitorAudienceCount),
          this.getAudienceValue(VisitorAudienceType.AllVisitorsSignedOut, visitorAudienceCount),
        ],
      };
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql getAudiences query. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async getEmployeeOptions(
    query: string,
    page: number,
  ): Promise<{ results: (DropdownOptionType & { type: string })[]; hasMore: boolean }> {
    const LIMIT = 20;

    const variables = {
      query,
      // page needs to be incremented since JSON API spec starts at 1 instead of 0
      page: page + 1,
      locationIDs: [this.state.currentLocation.id],
      sort: 'name',
      limit: LIMIT,
    };

    const result = await this.apolloExtension.query<EmployeesQueryType, EmployeesQueryVariablesType, 'employees'>(
      {
        query: EmployeesQuery,
        fetchPolicy: 'network-only',
        variables,
      },
      'employees',
    );

    const employees = result || [];

    return {
      results: employees.map((employee) => ({
        label: employee.name,
        subtitle: employee.email,
        value: employee.id.toString(),
        type: 'EMPLOYEE',
      })),
      hasMore: employees.length === LIMIT,
    };
  }

  async getEmployeeGroupOptions(
    query: string,
    page: number,
  ): Promise<{ results: DropdownOptionType[]; hasMore: boolean }> {
    const LIMIT = 20;
    const variables = {
      term: query,
      offset: page * LIMIT,
      locationId: this.state.currentLocation.id,
      typeFilters: [],
      limit: LIMIT,
    };

    const result = await this.apolloExtension.query<
      SearchEmployeeGroupsQueryType,
      SearchEmployeeGroupsQueryVariablesType,
      'searchEmployeeGroups'
    >(
      {
        query: SearchEmployeeGroupsQuery,
        fetchPolicy: 'network-only',
        variables,
      },
      'searchEmployeeGroups',
    );
    const groups = result.groups || [];

    return {
      results: groups.map((group) => ({
        label: group.name,
        value: group.id.toString(),
        type: group.type,
        memberCount: group.memberCount,
      })),
      hasMore: groups.length === LIMIT,
    };
  }

  async getLocations(): Promise<Location[]> {
    try {
      const result = await this.apolloExtension.query<
        GetSkinnyLocationsQuery,
        GetSkinnyLocationsVariables,
        'locations'
      >(
        {
          query: skinnyLocationsQuery,
          variables: {},
        },
        'locations',
      );

      return result;
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql getTemplateCategories query. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  private getAudienceValue(type: EmployeeAudienceType | VisitorAudienceType, counts: Record<string, number>): Audience {
    return {
      id: audienceTypeMap[type],
      count: counts[type],
    };
  }
}

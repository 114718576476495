import { service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isPast, isSameDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { dropTask } from 'ember-concurrency';
import type ConnectInviteModel from 'garaje/models/connect-invite';
import { type PrintRequest, type CheckInOptions } from 'garaje/models/connect-invite';
import type PropertyPrinterModel from 'garaje/models/property-printer';
import type VisitorContactModel from 'garaje/models/visitor-contact';
import type ZoneModel from 'garaje/models/zone';
import type BadgePrintingService from 'garaje/services/badge-printing';
import type ConnectInvitesService from 'garaje/services/connect-invites';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { GLOBAL_ADMIN, ZONE_ADMIN, ZONE_SECURITY } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';
import { not } from 'macro-decorators';

const DATE_FORMAT = 'MMM d, yyyy - h:mm a';

interface ApprovalStatus {
  icon: string;
  copy: string;
}

interface CheckInTaskOptions {
  print?: boolean;
}

interface InvitesTableRowArgs {
  /**
   * invite model
   */
  invite: ConnectInviteModel;
  /**
   * zone model
   */
  property: ZoneModel;
  /**
   * property printer model
   */
  printer?: PropertyPrinterModel;
  /**
   * array of visitor contact models
   */
  visitorContacts: VisitorContactModel[];
  /**
   * action to perform after invite is checked in
   */
  onCheckIn: (invite: ConnectInviteModel) => unknown;
  /**
   * closure for  when edit check in button is clicked
   */
  onEditCheckIn: () => unknown;
  /**
   * closure for when edit button is clicked
   */
  onEdit: () => unknown;
  /**
   * array of objects (with, at minimum, a `content` property) representing additional columns to display in the table.
   */
  extraColumns: unknown[];
}

export default class PropertyVisitorsInvitesTableRow extends Component<InvitesTableRowArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare badgePrinting: BadgePrintingService;
  @service declare connectInvites: ConnectInvitesService;
  @service declare currentAdmin: CurrentAdminService;

  @tracked showReset = false;
  @tracked showDelete = false;
  @tracked showPhoto = false;

  @not('args.printer') noPrinterSelected!: boolean;

  get autoPrintingDisabled(): boolean {
    return !!(this.args.property.badge && !this.args.property.badge.autoPrintEnabled);
  }

  get canCheckInAndPrint(): boolean {
    return !this.args.invite.checkedInAt && this.autoPrintingDisabled && !this.noPrinterSelected;
  }

  get canCheckInAndSkipPrint(): boolean {
    return !this.args.invite.checkedInAt && !this.autoPrintingDisabled && !this.noPrinterSelected;
  }

  get canPrint(): boolean {
    return !this.noPrinterSelected;
  }

  get canCheckIn(): boolean {
    const invite = this.args.invite;
    // invite can be checked-in if there were no checks, or everything was approved
    return !invite.approvalStatus || isEmpty(invite.approvalStatus.failedReport) || invite.approved;
  }

  get checkedInAt(): string | null {
    return this.args.invite.checkedInAt
      ? formatInTimeZone(this.args.invite.checkedInAt, this.args.property.timezone, DATE_FORMAT)
      : null;
  }

  get expectedArrival(): string | null {
    return this.args.invite.expectedArrivalTime
      ? formatInTimeZone(this.args.invite.expectedArrivalTime, this.args.property.timezone, DATE_FORMAT)
      : null;
  }

  get hostName(): string | null {
    return isPresent(this.args.invite.hostName) ? this.args.invite.hostName : this.args.invite.inviterName;
  }

  get approvalStatus(): ApprovalStatus {
    const { invite } = this.args;
    switch (invite.approvalStatus?.status) {
      case 'approved':
        return { copy: 'Access approved', icon: 'checkmark-small-gray' };
      case 'denied':
        return { copy: 'Access denied', icon: 'cross-small-red' };
      case 'review':
        return { copy: 'Pending approval from tenant', icon: 'gray-dash-small' };
      default:
        return { copy: 'No match', icon: 'checkmark-small-gray' };
    }
  }

  get menuItemClass(): string {
    if (!this.args.invite.checkedInAt || this.disableAllInviteOptions) {
      return '!text-carbon-30';
    }
    return '';
  }
  get disableAllInviteOptions(): boolean {
    const { roleNames } = this.currentAdmin;
    if (isPresent(_intersection([GLOBAL_ADMIN, ZONE_ADMIN], roleNames))) return false;
    // disable all options if the user is only a property security admin, the arrival time has passed, and it's not the same day
    if (
      isPresent(_intersection([ZONE_SECURITY], roleNames)) &&
      isPast(this.args.invite.expectedArrivalTime) &&
      !isSameDay(new Date(), this.args.invite.expectedArrivalTime)
    )
      return true;
    return false;
  }

  checkInTask = dropTask(async ({ print }: CheckInTaskOptions) => {
    const { printer, invite } = this.args;

    const forceEnablePrint = typeof print === 'boolean' && print;
    const forceDisabledPrint = typeof print === 'boolean' && !print;

    const isPrinting =
      printer && !forceDisabledPrint && (this.args.property.badge?.autoPrintEnabled || forceEnablePrint);

    const checkInOptions: CheckInOptions = { 'checked-in-at': new Date() };
    const request: PrintRequest = { print: false };
    checkInOptions['print-requests'] = [request];

    try {
      await invite.checkIn(checkInOptions);

      this.flashMessages.showAndHideFlash(
        'success',
        'Visitor checked in successfully',
        isPrinting ? 'Printing badge...' : undefined,
      );

      await this.connectInvites.triggerCheckinHooks([invite.id], this.args.property);

      if (this.args.printer && this.args.property.badge && isPrinting) {
        await this.badgePrinting.print({
          printer: this.args.printer,
          badge: this.args.property.badge,
          visit: invite,
        });
      }

      this.args.onCheckIn?.(invite);
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  printTask = dropTask(async () => {
    if (!this.args.printer || !this.args.property.badge) return;
    try {
      await this.badgePrinting.print({
        visit: this.args.invite,
        badge: this.args.property.badge,
        printer: this.args.printer,
      });

      this.flashMessages.showAndHideFlash('success', 'Printing badge');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  deleteCheckedInAtTask = dropTask(async () => {
    try {
      await this.args.invite.checkIn({
        'checked-in-at': null,
      });

      this.flashMessages.showAndHideFlash('success', 'Arrival status updated.');
      this.showReset = false;
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  deleteInviteAtTask = dropTask(async () => {
    try {
      await this.args.invite.destroyRecord().then(() => {
        // destroyRecord will also destroy the component. This block will not run if placed outside of callback.
        this.flashMessages.showAndHideFlash('success', 'Deleted');
        this.showReset = false;
      });
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}

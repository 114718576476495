import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import type ArrayProxy from '@ember/array/proxy';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { scheduleOnce } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type LocationModel from 'garaje/models/location';
import type TenantModel from 'garaje/models/tenant';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type IsOpenService from 'garaje/services/is-open';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

const DEFAULT_SORT_BY = 'propertyCompanyName';
const DEFAULT_SORT_DIRECTION = 'A-Z';

interface ConnectConfigComponentArgs {
  /**
   * a list of tenants
   */
  tenants?: ArrayProxy<TenantModel>;
  /**
   * closure called when a tenant is successfully connected
   */
  onConnectionSuccess?: () => unknown;
  /**
   * closure called when a tenant is successfully disconnected
   */
  onDisconnectionSuccess?: () => unknown;
  /**
   * user's current location
   */
  currentLocation?: LocationModel;
}

export default class ConnectConfigComponent extends Component<ConnectConfigComponentArgs> {
  @service declare isOpen: IsOpenService;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessagesService;

  @tracked sortBy = DEFAULT_SORT_BY;
  @tracked sortDirection = DEFAULT_SORT_DIRECTION;

  @tracked tenantToDisconnect: TenantModel | null = null;
  @tracked showConnectProperty = false;

  _element!: HTMLDivElement;

  get filteredTenants(): NativeArray<TenantModel> {
    return A(this.sortedTenants).rejectBy('status', 'disconnected');
  }

  get sortedTenants(): TenantModel[] | undefined {
    const sortedTenants = this.args.tenants?.sortBy(this.sortBy);
    return this.sortDirection === DEFAULT_SORT_DIRECTION ? sortedTenants : sortedTenants?.reverse();
  }

  get isManageRoute(): boolean {
    const currentRoute = this.router.currentRouteName;
    return !!currentRoute?.match(/^manage.+/);
  }

  get connectPermissionsRoute(): string {
    const route = 'location-settings.connect-permissions';
    return this.isManageRoute ? 'manage.' + route : route;
  }

  @action
  async cancel(): Promise<void> {
    const newRoute = this.isManageRoute ? 'manage.location-settings' : 'location-settings';
    await this.router.transitionTo(newRoute);
    this.bringBackToView();
  }

  @action
  async edit(): Promise<void> {
    const currentRoute = this.router.currentRouteName;
    const segments = currentRoute?.split('.') || [];
    segments.pop();
    const newRoute = segments.join('.') + '.connect';

    await this.router.transitionTo(newRoute);
    this.bringBackToView();
  }

  @action
  registerElement(element: HTMLDivElement): void {
    this._element = element;

    if (this.isOpen.isLocationConnectOpen) {
      this.bringBackToView();
    }
  }

  disconnectTenantTask = task(async () => {
    try {
      await this.tenantToDisconnect?.disconnect();
      this.tenantToDisconnect = null;
      this.flashMessages.showAndHideFlash('success', 'Successfully disconnected.');
      this.args?.onDisconnectionSuccess?.();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  @action
  bringBackToView(): void {
    const scrollIntoView = () => {
      this._element?.scrollIntoView();
    };

    scheduleOnce('routerTransitions', this, scrollIntoView);
  }

  @action
  onConnectionSuccess(): void {
    this.args.onConnectionSuccess?.();
  }
}

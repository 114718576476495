import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { reads } from '@ember/object/computed';

/**
 * @param {String}                  locationId
 * @param {Function}                onChange
 * @param {Model}                   visitorSurveyConfiguration
 */
export default class VisitorEmailsVisitorSurveyVsEditing extends Component {
  @reads('args.visitorSurveyConfiguration.allVisitorTypesEnabled') allVisitorTypesEnabled;
  @reads('args.visitorSurveyConfiguration.enabledVisitorTypes') enabledVisitorTypes;

  @tracked showVisitorTypesList;

  constructor() {
    super(...arguments);
    this.showVisitorTypesList = !this.allVisitorTypesEnabled;
  }

  @action
  async searchAdmins(name) {
    const model = this.args.visitorSurveyConfiguration;
    return await model.searchAdmins.perform(this.args.locationId, name);
  }

  @action
  updateSurveyContacts(contacts) {
    this.args.onChange({ hasChanges: true });
    const contactIds = contacts.map((c) => parseInt(c.userId || c.id, 10));
    set(this.args.visitorSurveyConfiguration, 'contactIds', contactIds);
    const _contacts = contacts.map((contact) => {
      return { userId: contact.userId || contact.id, fullName: contact.fullName };
    });
    set(this.args.visitorSurveyConfiguration, 'contacts', _contacts);
  }

  @action
  checkAllVisitorTypes() {
    if (!this.allVisitorTypesEnabled) {
      this.args.onChange({ hasChanges: true });
      this.args.visitorSurveyConfiguration.visitorTypes.forEach((vt) => set(vt, 'visitorSurveyEnabled', true));
    }
    this.showVisitorTypesList = false;
  }

  @action
  visitorTypeChecked(visitorType, { target }) {
    this.args.onChange({ hasChanges: true });
    set(visitorType, 'visitorSurveyEnabled', target.checked);
  }
}

// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { sort } from '@ember/object/computed';
import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import { belongsTo, hasMany } from '@ember-data/model';
import AbstractSignInFieldPageModel from 'garaje/models/abstract/abstract-sign-in-field-page';
import type EmployeeScreeningFlowModel from 'garaje/models/employee-screening-flow';
import type EmployeeSignInFieldModel from 'garaje/models/employee-sign-in-field';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('sign-in-field-page')
class EmployeeSignInFieldPageModel extends AbstractSignInFieldPageModel<EmployeeSignInFieldModel> {
  @belongsTo('employee-screening-flow') declare flow: AsyncBelongsTo<EmployeeScreeningFlowModel>;
  @hasMany('employee-sign-in-field') declare signInFields: AsyncHasMany<EmployeeSignInFieldModel>;

  sortPosition = ['position'];
  @sort('signInFields', 'sortPosition') declare topLevelFields: EmployeeSignInFieldModel[];
}

export default EmployeeSignInFieldPageModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'employee-sign-in-field-page': EmployeeSignInFieldPageModel;
  }
}

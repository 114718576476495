import { helper } from '@ember/component/helper';

const regex = /_+|-+/g;
const replacement = ' ';
const NAME_MAPPING = {
  'Been Here Before?': 'Returning visitor',
  'Pre-registration': 'Invited',
  Blacklist: 'Block list',
  NDA: 'Legal docs',
  'ID scan': 'ID scan',
  'Wi-Fi': 'Wi-Fi',
  'Employee Registration': 'Employee registration',
  'ID Checked': 'ID checked',
};

/**
 * Maps FE copy for dashboard field names to server side option names
 *
 * @param {string} name option name
 * @returns {string} presentation name
 */
export function dashboardFieldName([name]) {
  if (name === undefined || name === null) {
    return '';
  }
  if (Array.isArray(name)) {
    name = name[0];
  }
  if (NAME_MAPPING[name]) {
    return NAME_MAPPING[name];
  }
  if (name.split(' ').length > 1) {
    const result = name.toLowerCase().replace(regex, replacement);
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  return name;
}

export default helper(dashboardFieldName);

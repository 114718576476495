import { validateLength, validatePresence } from 'ember-changeset-validations/validators';

export default {
  name: validatePresence({ presence: true, message: 'Please name your template' }),
  defaultChannels: validateLength({ min: 1, message: 'You must select at least 1 delivery method' }),
  announcementTemplateCategory: validatePresence({ presence: true, message: 'Please select a category' }),
  message: [
    validatePresence({ presence: true, message: 'Write a message to save as a template' }),
    validateLength({ max: 320, message: 'Your message must be under 320 characters.' }),
  ],
  locations: validateLength({
    min: 1,
    message: 'You must select at least one location to publish.',
  }),
};

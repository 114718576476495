import Component from '@glimmer/component';
import { defer } from 'rsvp';
import { dropTask } from 'ember-concurrency';

export default class AmenityCardComponent extends Component {
  @dropTask
  showAmenityModalTask = {
    *perform() {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = async () => {
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };
}

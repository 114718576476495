import CalendarPermissionsStatusFragment from 'garaje/graphql/fragments/roomba/CalendarPermissionsStatusFragment';
import DeviceFragment from 'garaje/graphql/fragments/roomba/DeviceFragment';
import LocationFragment from 'garaje/graphql/fragments/roomba/LocationFragment';
import RoomFragment from 'garaje/graphql/fragments/roomba/RoomFragment';
import RoomStatusFragment from 'garaje/graphql/fragments/roomba/RoomStatusFragment';
import gql from 'graphql-tag';

export default gql`
  query GetRoomsPaginated($filter: RoomsPageFilterInput!, $sort: RoomsSortOrder!, $pagination: PaginationInput) {
    paginatedRooms(filter: $filter, sort: $sort, pagination: $pagination) {
      totalCount
      items {
        ...RoomFragment
        ...RoomStatusFragment
        calendarPermissionsStatus {
          ...CalendarPermissionsStatusFragment
        }
        devices {
          ...DeviceFragment
        }
        location {
          ...LocationFragment
        }
      }
    }
  }
  ${RoomFragment}
  ${RoomStatusFragment}
  ${DeviceFragment}
  ${LocationFragment}
  ${CalendarPermissionsStatusFragment}
`;

/* eslint-disable ember/no-get */
import { get } from '@ember/object';
import type ObjectProxy from '@ember/object/proxy';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { pluralize } from 'ember-inflector';
import type LocationModel from 'garaje/models/location';
import type ScimPrimaryLocationConfigurationModel from 'garaje/models/scim-primary-location-configuration';
import type ScimPrimaryLocationMappingModel from 'garaje/models/scim-primary-location-mapping';
import type LocationsService from 'garaje/services/locations';
import type ScimPrimaryLocationService from 'garaje/services/scim-primary-location';
import type StoreService from 'garaje/services/store';

interface PrimaryLocationMappingsArgs {
  primaryLocationMappings: Array<ScimPrimaryLocationMappingModel>;
  primaryLocationConfig: ScimPrimaryLocationConfigurationModel;
  locations: Array<ObjectProxy<LocationModel>>;
}

export default class PrimaryLocationMappings extends Component<PrimaryLocationMappingsArgs> {
  @service declare locations: LocationsService;
  @service declare scimPrimaryLocation: ScimPrimaryLocationService;
  @service declare store: StoreService;

  constructor(owner: unknown, args: PrimaryLocationMappingsArgs) {
    super(owner, args);
    this.createPrimaryLocationMappings();
  }

  get summaryText(): string {
    const distinctValues = this.scimPrimaryLocation.distinctMappingValues?.distinctValues;
    const numOfValues: number = distinctValues ? distinctValues.length : 0;
    const unmappedValues = this.scimPrimaryLocation.unmappedDistinctValues(this.args.primaryLocationMappings);

    const ids: Array<string> = [];
    this.args.primaryLocationMappings.forEach((mapping: ScimPrimaryLocationMappingModel) => {
      if (mapping.location) {
        ids.push(get(mapping.location, 'id'));
      }
    });

    const numOfUnmappedValues: number = unmappedValues ? unmappedValues.length : 0;
    const numOfMappedValues: number = numOfValues - numOfUnmappedValues;
    const numOfUniqueLocations = new Set(ids).size;

    let summaryText = '';

    if (numOfMappedValues > 0) {
      summaryText = `${pluralize(numOfMappedValues, 'value')} mapped to ${pluralize(
        numOfUniqueLocations,
        'Envoy location',
      )}`;
    }

    if (numOfUnmappedValues > 0) {
      summaryText = summaryText.concat(
        `${summaryText ? ', ' : ''}${pluralize(numOfUnmappedValues, 'value')} unmatched`,
      );
    }

    return summaryText;
  }

  get unmappedValues(): Array<string> {
    return this.scimPrimaryLocation.unmappedDistinctValues(this.args.primaryLocationMappings);
  }

  get primaryLocationMappings(): Array<ScimPrimaryLocationMappingModel> {
    const mappings: Array<ScimPrimaryLocationMappingModel> = [];

    this.args.primaryLocationMappings.forEach((primaryLocationMapping: ScimPrimaryLocationMappingModel) => {
      mappings.push(primaryLocationMapping);
    });

    // newly created records (unmapped) should appear at the top
    return mappings.reverse();
  }

  createPrimaryLocationMappings(): void {
    this.unmappedValues.forEach((value: string) => {
      let exists: boolean = false;
      this.args.primaryLocationMappings.forEach((mapping) => {
        if (<boolean>(<unknown>mapping.isNew) && mapping.scimAttributeValue === value) {
          exists = true;
        }
      });

      if (!exists) {
        const primaryLocationMapping = this.store.createRecord('scim-primary-location-mapping', {
          scimAttributeValue: value,
        });
        primaryLocationMapping.scimPrimaryLocationConfiguration = this.args.primaryLocationConfig;
      }
    });
  }
}

import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type BadgeModel from 'garaje/models/badge';
import type ConfigModel from 'garaje/models/config';
import type PhotoPageModel from 'garaje/models/photo-page';
import type StateService from 'garaje/services/state';
import { hash } from 'rsvp';

import type { VisitorTypesFlowRouteModel } from '../route';

export interface VisitorsSettingsVisitorTypesFlowVisitorPhotosRouteModel {
  config: ConfigModel;
  badge: BadgeModel;
  photoPage: PhotoPageModel;
  vrSubscription: VisitorTypesFlowRouteModel['vrSubscription'];
  flow: VisitorTypesFlowRouteModel['flow'];
}

export default class VisitorsSettingsVisitorTypesFlowVisitorPhotosRoute extends Route {
  @service declare state: StateService;

  model(): Promise<VisitorsSettingsVisitorTypesFlowVisitorPhotosRouteModel> {
    const { flow, vrSubscription } = <VisitorTypesFlowRouteModel>this.modelFor('visitors.settings.visitor-types.flow');
    const photoPage = flow.photoPage;
    const badge = flow.badge;
    const { currentLocation } = this.state;
    const config = currentLocation.config;

    return hash({ config, flow, badge, photoPage, vrSubscription });
  }
}

import Controller from '@ember/controller';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { defer } from 'rsvp';

export default class CommunicationsTemplateListController extends Controller {
  @service declare router: RouterService;

  @dropTask
  createTemplateTask = {
    *perform(this: {
      context: CommunicationsTemplateListController;
      abort?: () => void;
      continue?: (template?: string) => void;
    }): Generator<Promise<boolean>, void, void> {
      const deferred = defer<boolean>();

      this.abort = () => deferred.resolve(false);

      this.continue = (example_id?: string) => {
        deferred.resolve(true);

        void this.context.router.transitionTo('location-overview.communications.templates.new', {
          queryParams: { example_id },
        });
      };

      return yield deferred.promise;
    },
  };
}

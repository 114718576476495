import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import type ConnectFloorModel from 'garaje/models/connect-floor';
import type AjaxService from 'garaje/services/ajax';
import type FlashMessagesService from 'garaje/services/flash-messages';
import urlBuilder from 'garaje/utils/url-builder';

import { type PropertySettingsFloorsRouteModel } from './route';

export default class PropertySettingsFloorsController extends Controller {
  @service declare ajax: AjaxService;
  @service declare flashMessages: FlashMessagesService;
  @service declare store: StoreService;

  declare model: PropertySettingsFloorsRouteModel;

  @tracked floorToConfirmDeletion: ConnectFloorModel | null = null;

  deleteFloorTask = dropTask(async () => {
    const floor = this.floorToConfirmDeletion;
    if (!floor) return;

    try {
      await floor.destroyRecord();
      this.floorToConfirmDeletion = null;
      this.flashMessages.showAndHideFlash('success', 'Floor deleted successfully.');
      await this.model.floors.update();
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      this.flashMessages.showAndHideFlash('error', 'Something went wrong, please try again.');
    }
  });

  @action
  cancelDeletion(): void {
    this.floorToConfirmDeletion = null;
  }

  @action
  confirmDeletion(floor: ConnectFloorModel): void {
    this.floorToConfirmDeletion = floor;
  }

  @action
  async reorderFloors(orderedFloors: ConnectFloorModel[] /*, movedFloor: ConnectFloorModel */): Promise<void> {
    const url = urlBuilder.connect.v1.reorderFloors(this.model.property.id);
    try {
      const payload: object = await this.ajax.request(url, {
        type: 'POST',
        contentType: 'application/vnd.api+json',
        data: { data: orderedFloors.map((floor) => ({ type: 'connect-floors', id: floor.id })).reverse() },
      });
      this.store.pushPayload(payload);
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      this.flashMessages.showAndHideFlash('error', 'Something went wrong, please try again.');
    }
  }
}

/* eslint-disable ember/no-computed-properties-in-native-classes */
import AbstractSignInFieldActionModel from 'garaje/models/abstract/abstract-sign-in-field-action';
import { belongsTo } from '@ember-data/model';
import { computed, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('sign-in-field-action')
class GlobalSignInFieldActionModel extends AbstractSignInFieldActionModel {
  @belongsTo('global-sign-in-field') signInField; // Parent
  @belongsTo('global-sign-in-field', { inverse: 'actionableSignInFieldActions' }) actionableSignInField;
  @belongsTo('global-sign-in-field-action-rule-group') signInFieldActionRuleGroup;

  @alias('signInField.options') signInFieldOptions;

  /**
   * @return {DropdownOption}
   */
  @computed('signInFieldValue', 'signInField.options.@each.id', '_dropdownOption', 'signInFieldOptions')
  get dropdownOption() {
    if (this._dropdownOption) {
      // When creating a new action model, we need to cache this `obj` until we have
      // a proper option id
      return this._dropdownOption;
    }
    const options = get(this, 'signInFieldOptions');
    return options && options.length ? options.find((opt) => opt.id === this.signInFieldValue) : [];
  }
}

export default GlobalSignInFieldActionModel;

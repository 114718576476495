/* eslint-disable ember/no-get */
import Controller from '@ember/controller';
import { action, get } from '@ember/object';
import { later } from '@ember/runloop';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import { task, dropTask } from 'ember-concurrency';
import config from 'garaje/config/environment';
import type UserModel from 'garaje/models/user';
import type CurrentAdminService from 'garaje/services/current-admin';
import type CurrentLocationService from 'garaje/services/current-location';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type SessionService from 'garaje/services/session';
import updateAndSaveTask from 'garaje/utils/decorators/update-and-save-task';
import UserValidations from 'garaje/validations/user';
import { reject } from 'rsvp';

class ProfileIndexController extends updateAndSaveTask(Controller) {
  declare model: UserModel;

  @service declare currentLocation: CurrentLocationService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare flashMessages: FlashMessagesService;
  @service declare session: SessionService;
  @service declare store: StoreService;

  validated = false;
  @tracked showPasswordFields = false;
  @tracked changeset!: DetailedChangeset<UserModel>;

  _buildChangeset(model: UserModel): void {
    const validator = lookupValidator(UserValidations);
    this.changeset = Changeset(model, validator, UserValidations);
  }

  get isEmailChanged(): boolean {
    return Boolean(this.changeset.change['email']);
  }

  get passwordRequired(): boolean {
    return this.showPasswordFields || this.isEmailChanged;
  }

  get emptyPassword(): boolean {
    return !this.changeset.password;
  }

  get defaultCountry(): string {
    return config.environment === 'test' ? 'us' : 'auto';
  }

  get canAddEmail(): boolean {
    const roleNames = this.currentAdmin.roleNames;
    return roleNames.includes('Global Admin');
  }

  get isConfirmationMatching(): boolean {
    return this.changeset.newPassword === this.changeset.newPasswordConfirmation;
  }

  get saveDisabled(): boolean {
    const { passwordRequired, emptyPassword, showPasswordFields, isConfirmationMatching } = this;

    if (passwordRequired && emptyPassword) {
      return true;
    }

    if (showPasswordFields && !isConfirmationMatching) {
      return true;
    }

    const { isDirty, isValid } = this.changeset;

    return !(isDirty && isValid);
  }

  @action
  resetProperties(): void {
    this.showPasswordFields = false;
    this.changeset.rollback();
    this.model.rollbackAttributes();
    this.resetPasswordEditingMode();
  }

  resetPasswordEditingMode(): void {
    this.model.store.push({
      data: {
        type: 'user',
        id: get(this.changeset, 'id'),
        attributes: {
          password: undefined,
          newPassword: undefined,
          newPasswordConfirmation: undefined,
        },
      },
    });
  }

  save = dropTask(async () => {
    const changeset = this.changeset;

    await changeset.validate();

    if (!get(changeset, 'isValid')) {
      return false;
    }

    const passwordChanged = get(changeset, 'change.newPassword');
    const newPassword = get(changeset, 'newPassword');

    try {
      await this.updateAndSaveTask.perform(this.changeset);
    } catch (_e) {
      return reject(false);
    }
    this.resetProperties();
    if (passwordChanged && newPassword) {
      await this.session.authenticate('authenticator:application', {
        email: get(changeset, 'email'),
        companyId: this.session.companyId,
        password: newPassword,
      });
    }

    return true;
  });

  @action
  passwordDidChange(): void {
    if (<boolean>(<unknown>this.changeset.isLoaded) && this.model && isBlank(this.changeset.password)) {
      // TODO: refactor this so its not observers based.
      this.model.store.push({
        data: {
          type: 'user',
          id: get(this.changeset, 'id'),
          attributes: { newPassword: undefined, newPasswordConfirmation: undefined },
        },
      });
    }
  }

  @action
  togglePasswordFields(): void {
    this.showPasswordFields = !this.showPasswordFields;
    later(function () {
      document.querySelector<HTMLInputElement>('[data-test-profile-password]')?.focus();
    }, 0);
  }

  @action
  handlePhotoError(error: string): void {
    this.flashMessages.showFlash('error', error);
  }

  handleProfilePhotoChangeTask = task({ drop: true }, async (value: string) => {
    const message = value === null ? 'Profile Photo Removed!' : 'Profile Photo Saved!';
    this.model.userPhoto = value;

    try {
      await this.model.save();
      this.flashMessages.showAndHideFlash('success', message);
    } catch (_) {
      this.flashMessages.showFlash('error', 'Error saving changes. Please try again.');
    }
  });
}

export default ProfileIndexController;

import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { isBlank } from '@ember/utils';
import { task } from 'ember-concurrency';
import { isCompanyRole } from 'garaje/utils/roles';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { RoleId, RoleScopeType } from 'garaje/utils/custom-roles';

/**
 * @param {Array<Object>}     locations
 * @param {Object}            employeeGroupRole
 * @param {String}            groupRoleToEdit
 * @param {Function}          updateGroupRoleToEdit
 * @param {Function}          setDeleteDialog
 */
class AdminRoleMappingsRow extends Component {
  @service authz;
  @service featureFlags;
  @service flashMessages;

  @tracked selectedRole = this.getInitialSelectedRole();
  @tracked selectedLocations = this.getInitialSelectedLocations();
  @tracked disableLocationDropdown = false;

  get isEditing() {
    return this.args.groupRoleToEdit === this.args.employeeGroupRole.id;
  }

  get isDisabled() {
    return !isBlank(this.args.groupRoleToEdit);
  }

  get formattedLocations() {
    const { roleName, locations, customRoleId } = this.args.employeeGroupRole;

    let isACompanyRole = isCompanyRole(roleName);

    this.authz.roles.forEach((role) => {
      if (role.id === customRoleId && role.roleScope === RoleScopeType.COMPANY) {
        isACompanyRole = true;
      }
    });

    if (isACompanyRole) {
      return 'All locations';
    }

    const formattedLocations = [];
    locations.forEach((loc) => {
      const name = isBlank(loc.disabledToEmployeesAt) ? loc.name : `${loc.name} (Disabled)`;
      formattedLocations.push(name);
    });

    return formattedLocations.join(', ');
  }

  get roleOptions() {
    const companyRoles = [];
    const locationRoles = [];
    // no support for zone roles through scim right now
    this.authz.roles.forEach((role) => {
      if (role.id !== RoleId.EMPLOYEE && !role.deactivated) {
        if (role.roleScope === RoleScopeType.COMPANY && role.id !== RoleId.COMPANY_START_FROM_SCRATCH) {
          companyRoles.push(role);
        } else if (role.roleScope === RoleScopeType.LOCATION && role.id !== RoleId.LOCATION_START_FROM_SCRATCH) {
          locationRoles.push(role);
        }
      }
    });

    return [
      {
        groupName: 'Company roles',
        options: companyRoles,
      },
      {
        groupName: 'Location roles',
        options: locationRoles,
      },
    ];
  }

  get isSaveDisabled() {
    return isBlank(this.selectedRole) || !(this.selectedLocations && this.selectedLocations.length > 0);
  }

  get roleName() {
    let roleName = '';
    const roleId = this.args.employeeGroupRole.customRoleId;

    this.authz.roles.forEach((role) => {
      if (role.id === roleId) {
        roleName = role.name;
      }
    });

    return roleName;
  }

  @action
  onEditClick() {
    this.args.updateGroupRoleToEdit(this.args.employeeGroupRole.id);

    let isACompanyRole = false;
    this.authz.roles.forEach((role) => {
      if (role.id === this.args.employeeGroupRole.customRoleId && role.roleScope === RoleScopeType.COMPANY) {
        isACompanyRole = true;
      }
    });
    if (isACompanyRole) {
      this.selectedLocations = [{ name: 'All locations' }];
      this.disableLocationDropdown = true;
    }
  }

  @action
  onDeleteClick() {
    this.args.setDeleteDialog(true, this.args.employeeGroupRole);
  }

  @action
  onEditCancelClick() {
    this.args.updateGroupRoleToEdit('');
    this.reset();
  }

  @action
  updateSelectedRole(role) {
    if (role.roleScope === RoleScopeType.COMPANY) {
      // company roles are not tied to locations, so reset the dropdown
      this.selectedLocations = [{ name: 'All locations' }];
      this.disableLocationDropdown = true;
    } else if (this.selectedRole.roleScope === RoleScopeType.COMPANY && role.roleScope !== RoleScopeType.COMPANY) {
      this.selectedLocations = [];
      this.disableLocationDropdown = false;
    }

    this.selectedRole = role;
  }

  updateMapping = task({ drop: true }, async () => {
    const employeeGroupRole = this.args.employeeGroupRole;
    employeeGroupRole.customRoleId = this.selectedRole.id;
    employeeGroupRole.roleName = null;
    employeeGroupRole.locations =
      this.selectedRole.roleScope === RoleScopeType.COMPANY ? [] : this.selectedLocations.map((loc) => loc.content);

    try {
      await employeeGroupRole.save();
      this.flashMessages.showAndHideFlash('success', 'Group mapping updated');
      this.reset();
      this.args.updateGroupRoleToEdit('');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  getInitialSelectedLocations() {
    const selectedLocations = [];
    this.args.employeeGroupRole.locations?.forEach((groupRoleLoc) => {
      this.args.locations.forEach((loc) => {
        if (get(loc, 'id') === get(groupRoleLoc, 'id')) {
          selectedLocations.push(loc);
        }
      });
    });

    return selectedLocations;
  }

  getInitialSelectedRole() {
    let selectedRole = '';

    const roleId = this.args.employeeGroupRole.customRoleId;
    this.authz.roles.forEach((role) => {
      if (role.id === roleId) {
        selectedRole = role;
      }
    });

    return selectedRole;
  }

  reset() {
    this.selectedRole = this.getInitialSelectedRole();
    this.selectedLocations = this.getInitialSelectedLocations();
    this.disableLocationDropdown = false;
  }
}

export default AdminRoleMappingsRow;

import { startOfDay, addHours, format, addMinutes } from 'date-fns';

// interval should be minutes between time interval
export function hourOptions(interval, withElevenFiftyNine = false) {
  const options = [];
  const mmtMidnight = startOfDay(new Date());
  for (let hour = 0; hour < 24; hour++) {
    let time = addHours(mmtMidnight, hour);
    let minute = 0;
    while (minute < 60) {
      options.push({ value: format(time, 'HH:mm:ss'), label: format(time, 'h:mm aaa') });
      time = addMinutes(time, interval);
      minute += interval;
    }
  }
  if (withElevenFiftyNine) {
    options.push({ value: '23:59:00', label: '11:59 pm' });
  }
  return options;
}

export function startTimeOptions(endTime, interval, withElevenFiftyNine = false) {
  const allHoursOptions = hourOptions(interval, withElevenFiftyNine);
  return allHoursOptions.filter((option) => {
    if (option.value.localeCompare(endTime) >= 0) {
      return false;
    }
    return true;
  });
}

export function endTimeOptions(startTime, interval, withElevenFiftyNine = false) {
  const allHoursOptions = hourOptions(interval, withElevenFiftyNine);
  return allHoursOptions.filter((option) => {
    if (option.value.localeCompare(startTime) === 1) {
      return true;
    }
    return false;
  });
}

export function getPartialTimes(date, selectedTime) {
  const startHrMin = selectedTime.startTime.split(':');
  const endHrMin = selectedTime.endTime.split(':');
  const startTime = addMinutes(addHours(date, startHrMin[0]), startHrMin[1]);
  const endTime = addMinutes(addHours(date, endHrMin[0]), endHrMin[1]);
  return [startTime, endTime];
}

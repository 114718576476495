import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set, get } from '@ember/object';
import { timeout, restartableTask, dropTask } from 'ember-concurrency';
import zft from 'garaje/utils/zero-for-tests';
import { MIN_CAPACITY_LIMIT, MAX_CAPACITY_LIMIT } from 'garaje/validations/location';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { APP } from 'garaje/utils/enums';
import { CohoWorkplaceEventNames } from 'garaje/services/coho';

/**
 * @param {Model} changeset the changeset for location settings
 * @param {Function} registerChangeset method to register the changeset on the controller for dirty transition checking
 * @param {Function} onSearchUsers searches users by term
 * @param {Class<Location>} location Location
 * @param {Boolean} [disabled] If true, open button is disabled
 */
export default class VisitorsSettingsCapacityCapacityLimitComponent extends Component {
  minCapacityLimit = MIN_CAPACITY_LIMIT;
  maxCapacityLimit = MAX_CAPACITY_LIMIT;

  @service workplaceMetrics;
  @service coho;

  @tracked isSettingsPanelOpen = false;

  constructor() {
    super(...arguments);
    this.changeset = this.args.changeset;
    this.args.registerChangeset(this.args.changeset);
  }

  /**
   * Ensures that PowerSelectMultiple never holds a reference to the capacityContacts hasMany relationship
   *
   * @returns {Array} List of selected capacityContacts user models in changeset
   */
  get selectedCapacityContacts() {
    return get(this.args.changeset, 'capacityContacts').toArray();
  }

  get isDirty() {
    const { location: currentLocation, changeset } = this.args;
    const errors = get(changeset, 'errors');

    if (errors.length > 0 || get(currentLocation, 'company.hasDirtyAttributes') || get(changeset, 'isDirty')) {
      return true;
    }

    return false;
  }

  /**
   * Sets the setCapacityLimit value in the location changeset
   *
   * @param {string} capacityLimit Capacity limit input value
   */
  @action
  setCapacityLimit(capacityLimit) {
    set(this.args.changeset, 'capacityLimit', parseInt(capacityLimit) || null);
    get(this.args.changeset, 'capacityLimit');
  }

  /**
   * Sets the capacityContacts value in the location changeset
   *
   * @param {Array} capacityContacts List of user models to save as capacity contacts
   */
  @action
  setCapacityContacts(capacityContacts) {
    set(this.args.changeset, 'capacityContacts', capacityContacts);
  }

  /**
   * Constrains the capacityLimit input to MAX and MIN values
   *
   * @param {object} event input event
   * @param {object} event.target capacity limit input element
   */
  @action
  constrainCapacity({ target }) {
    let { valueAsNumber } = target;
    valueAsNumber = Math.min(valueAsNumber, MAX_CAPACITY_LIMIT);
    valueAsNumber = Math.max(valueAsNumber, MIN_CAPACITY_LIMIT);
    target.value = valueAsNumber;
  }

  /**
   * Shows settings panel
   */
  @action
  showSettings() {
    this.workplaceMetrics.trackEvent('LOCATION_SETTINGS_CAPACITY_SETTINGS_EDIT_BUTTON_CLICKED');
    this.isSettingsPanelOpen = true;
  }

  /**
   * Hides settings panel
   */
  @action
  hideSettings() {
    this.isSettingsPanelOpen = false;
  }

  /**
   * Rolls back the changeset
   */
  @action
  rollback() {
    this.args.changeset.rollback();
  }

  /**
   * Task for searching users
   *
   * @param {string} term Search term
   * @returns {Promise<Array>} Promise for an array of search results
   */
  @restartableTask
  *searchUsersTask(term) {
    yield timeout(zft(250));
    const capacityContacts = get(this.args.changeset, 'capacityContacts');
    const users = yield this.args.onSearchUsers(term);
    return users.reject((user) => capacityContacts.includes(user));
  }

  /**
   * Task for saving location model changes
   *
   * @param {object} event DOM event
   */
  @dropTask
  *saveTask(event) {
    this.workplaceMetrics.trackEvent('LOCATION_SETTINGS_CAPACITY_SETTINGS_SAVE_BUTTON_CLICKED');
    event.preventDefault();
    const { changeset } = this.args;
    const { onSubmit, onConfirmCapacity } = this.args;
    const { capacityLimit } = changeset.change;
    yield changeset.validate();

    let shouldSaveChanges = changeset.isValid;
    if (isPresent(capacityLimit)) {
      shouldSaveChanges = yield onConfirmCapacity(capacityLimit);
    }
    if (shouldSaveChanges) {
      this.coho.sendEvent(CohoWorkplaceEventNames.SetCapacityLimit, { product: APP.WORKPLACE });
      yield onSubmit(changeset);
    }
  }

  /**
   * Task for disabling capacityLimit and saving location model
   *
   * @param {object} event DOM event
   */
  @dropTask
  *disableTask(event) {
    const { changeset } = this.args;
    set(changeset, 'capacityLimit', null);
    yield changeset.validate();
    changeset.rollbackInvalid();
    yield this.saveTask.perform(event);
    this.hideSettings();
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type AuthzService from 'garaje/services/authz';
import type RoleAssignmentsService from 'garaje/services/role-assignments';

interface ModelParams {
  role_id: string;
}

class ManageViewRoleRoute extends Route {
  @service declare authz: AuthzService;
  @service declare roleAssignments: RoleAssignmentsService;
  @service declare router: RouterService;
  @service declare abilities: AbilitiesService;

  titleToken = 'Roles and Permissions';

  beforeModel(): void {
    if (this.abilities.cannot('view custom-roles')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  async model({ role_id }: ModelParams): Promise<unknown> {
    const role = await this.authz.fetchRole(role_id);
    const matrix = await this.authz.fetchMatrix(role_id);
    await this.roleAssignments.fetchAssignmentCountsPerRole();
    const assignmentCountsPerRole = this.roleAssignments.assignmentCountsPerRole;
    if (role !== undefined) {
      if (role.id in assignmentCountsPerRole) {
        role.assignmentCounts = assignmentCountsPerRole[role.id];
      } else {
        role.assignmentCounts = {
          user_count: 0,
        };
      }
    }

    return { role, matrix };
  }
}

export default ManageViewRoleRoute;

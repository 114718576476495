import ApplicationSerializer from './application';

export default class EmployeeAgreementPageSerializer extends ApplicationSerializer {
  modelNameFromPayloadKey(key) {
    if (key === 'agreement-pages') {
      return 'employee-agreement-page';
    } else if (key === 'agreements') {
      return 'employee-agreement';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }

  payloadKeyFromModelName(modelName) {
    if (modelName === 'employee-agreement-page') {
      return 'agreement-pages';
    } else if (modelName === 'employee-agreement') {
      return 'agreements';
    } else {
      return super.payloadKeyFromModelName(...arguments);
    }
  }
}

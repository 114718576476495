import Component from '@glimmer/component';
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import Papa from 'papaparse';

/**
 * @param {Function}            action
 * @param {Function}            toggleProcessing
 */
export default class CsvParse extends Component {
  @action
  onChange(event) {
    this.args.toggleProcessing();
    let reader;
    const files = event.target.files;
    if (files && files[0]) {
      reader = new FileReader();
      reader.onload = (function (self) {
        return function (e) {
          let uploadedFile;
          // e.srcElement is undefined in Firefox
          if (typeof e.srcElement !== 'undefined') {
            uploadedFile = e.srcElement.result;
          } else {
            uploadedFile = e.target.result;
          }
          next(() => {
            return self.parseCsv(uploadedFile, files[0]);
          });
        };
      })(this);
      return reader.readAsText(files[0]);
    } else {
      this.args.toggleProcessing();
    }
  }

  @action
  parseCsv(file, originalFile) {
    const self = this;
    Papa.parse(file, {
      dynamicTyping: true,
      skipEmptyLines: true,
      header: this.isHeaderPresent(file),
      transformHeader: (header) => header.trim().toLowerCase().replace(/\s+/g, '_'),
      complete(results) {
        self.args.action(results, originalFile);
        self.args.toggleProcessing();
      },
    });
  }

  isHeaderPresent(file) {
    // The heuristic to determine if the uploaded file contains a header is that it starts
    // with name, email, phone_number. It should be case insensitive and spacing between
    // are optional.
    const headerRegex = /^name\s*,\s*email\s*,\s*phone[_\s]+number/i;
    return file.match(headerRegex);
  }
}

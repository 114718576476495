import { addDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

interface AnnouncementQueryParams {
  page: {
    limit: number;
    offset: number;
  };
  filter: { [key: string]: string };
  include: string;
  fields: {
    announcements: string;
    employees: string;
  };
  sort: string;
}

export function getAnnouncementQueryParams(
  locationIds?: string[],
  startDate?: Date,
  endDate?: Date,
  limit = 25,
  offset = 0,
): AnnouncementQueryParams {
  const baseParams: AnnouncementQueryParams = {
    page: { limit, offset },
    include: 'creator-employee',
    filter: {
      archived: 'null',
    },
    fields: {
      announcements: 'id,created-at,title,message,statistics,creator-employee,location',
      employees: 'id,name',
    },
    sort: '-createdAt',
  };

  if (locationIds && locationIds.length > 0) {
    baseParams.filter['location'] = locationIds?.join(',');
  }

  if (startDate && endDate) {
    const newEndDate = addDays(endDate, 1);
    baseParams.filter['datetime-from'] = formatInTimeZone(startDate, 'UTC', 'yyyy-MM-dd');
    baseParams.filter['datetime-to'] = formatInTimeZone(newEndDate, 'UTC', 'yyyy-MM-dd');
  }

  return baseParams;
}

import { validatePresence, validateFormat } from 'ember-changeset-validations/validators';

export default {
  name: validatePresence({
    presence: true,
    message: 'This is a required field',
  }),
  email: validateFormat({
    type: 'email',
    allowBlank: false,
  }),
};

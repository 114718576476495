import ApplicationAdapter from './application';

export default class UserDocumentAdapter extends ApplicationAdapter {
  urlForCreateRecord(modelName, snapshot) {
    let url = this.buildURL(modelName, null, snapshot, 'v3');
    url +=
      '?include=user-document-template,user-document-links,user-document-attachments,user-document-location-contexts,user-document-attachments.user-document-template-attachment';
    return url;
  }

  urlForUpdateRecord(id, modelName, snapshot) {
    const changedAttributes = snapshot.changedAttributes();
    let url = this.buildURL(modelName, id, snapshot, 'v3');
    const inputFieldsData = changedAttributes?.inputFieldsData;
    if (inputFieldsData && inputFieldsData[0]?.issue_date) {
      url += '?include=user-document-location-contexts';
    }
    return url;
  }
}

/* eslint-disable ember/classic-decorator-hooks */
/* eslint-disable ember/no-computed-properties-in-native-classes */
/* eslint-disable no-setter-return */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import EmberObject, { action, computed, get, set } from '@ember/object';
import { Promise as EmberPromise } from 'rsvp';
import { isArray } from '@ember/array';
import { dasherize } from '@ember/string';
import { isPresent } from '@ember/utils';
import { equal, reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';

import urlBuilder from 'garaje/utils/url-builder';
import popupFlow from 'garaje/utils/popup-flow';

export default class IntegrationsPlatformPluginComponent extends Component {
  @service flashMessages;
  @service state;
  @service store;

  @equal('mode', 'box') boxMode;
  @reads('plugin.manifest.setup_flow') flows;
  @reads('state.currentLocation') currentLocation;
  @reads('state.currentCompany') currentCompany;

  @computed('integration', 'install')
  get configureOn() {
    return this.install && this.integration === `install-${this.install.id}`;
  }
  set configureOn(value) {
    return value;
  }

  @computed('currentFlowStep', 'flows.[]')
  get isLastFlowStep() {
    return get(this, 'flows.length') - 1 === this.currentFlowStep;
  }

  @computed('install.{configureStep,updatedAt}')
  get currentFlowStep() {
    let step = 0;
    if (get(this, 'install')) {
      step = get(this, 'install.configureStep') || 0;
    }
    return step;
  }
  set currentFlowStep(value) {
    return value;
  }

  // TODO: remove this once we implement improved sync RFC
  @computed('currentSyncIntegration', 'plugin.{category,name}')
  get pluginDisabled() {
    const currentSyncIntegration = get(this, 'currentSyncIntegration');
    if (
      currentSyncIntegration &&
      get(this, 'plugin.category') === 'sync' &&
      get(this, 'plugin.name') !== currentSyncIntegration
    ) {
      return true;
    }
    return false;
  }

  @computed('plugin.manifest.{uses,setup_flow}')
  get isExternalOnlyPlugin() {
    const uses = get(this, 'plugin.manifest.uses') || [];
    const setupFlow = get(this, 'plugin.manifest.setup_flow') || [];
    return uses.includes('external') && setupFlow.length === 0;
  }

  @computed('plugin.manifest.external_url')
  get pluginExternalURL() {
    return get(this, 'plugin.manifest.external_url');
  }

  @computed('installs.@each.plugin', 'plugin.id')
  get pluginInstalls() {
    return get(this, 'installs').filterBy('plugin.id', get(this, 'plugin.id'));
  }

  @computed('install.status')
  get isInstalled() {
    const status = get(this, 'install.status');
    return status == 'active' || status == 'pending';
  }

  @computed('install', 'flowToShow.{type,app}')
  get oAuth2Config() {
    const flow = get(this, 'flowToShow');
    if (get(flow, 'type') !== 'oauth2') {
      return null;
    }
    const app = get(flow, 'app').toUpperCase();
    const prefix = `OAUTH2_${app}_`;
    const config = EmberObject.create(get(this, 'install.config'));
    const out = EmberObject.create({});
    for (const key in config) {
      if (key.indexOf(prefix) === 0) {
        set(out, key.replace(prefix, ''), config[key]);
      }
    }
    return out;
  }

  @computed('providedInstall', 'pluginInstalls.@each.status')
  get install() {
    if (get(this, 'providedInstall')) {
      return get(this, 'providedInstall');
    } else {
      return this.pluginInstalls.find((install) => install.status !== 'uninstalled');
    }
  }
  set install(value) {
    return value;
  }

  @computed('plugin')
  get hasConfiguration() {
    return get(this, 'plugin.manifest.setup_flow').length > 0;
  }

  @computed('currentFlowStep.{output,schema}', 'plugin', 'install.{authorizedScopes,status}')
  get flowToShow() {
    const requestedScopes = get(this, 'plugin.manifest.scopes') || [];
    const authorizedScopes = get(this, 'install.authorizedScopes') || [];
    const foundScopes = requestedScopes.filter((scope) => {
      return authorizedScopes.includes(scope);
    });
    const authorized = foundScopes.length === requestedScopes.length;

    if (!authorized && get(this, 'install.status')) {
      const actionsAndResources = {};
      requestedScopes.forEach((scope) => {
        const [resource, action] = scope.split('.');
        if (!actionsAndResources[resource]) {
          actionsAndResources[resource] = [];
        }
        actionsAndResources[resource].push(action);
      });
      return EmberObject.create({
        type: 'authorize',
        label: 'Authorize',
        scopes: Object.keys(actionsAndResources).map((resource) => {
          const action = actionsAndResources[resource];
          const actionDisplay = actionsAndResources[resource].join(' and ');
          const resourceDisplay = resource.split('-').join(' ');
          return {
            display: `${actionDisplay} ${resourceDisplay}`,
            value: `${resource}.${action}`,
          };
        }),
      });
    }

    const step = this.currentFlowStep;
    const flow = EmberObject.create(get(this, 'plugin.manifest.setup_flow')[step]);
    let schema = {};
    if (get(flow, 'schema')) {
      schema = get(this, `plugin.manifest.schema.${get(flow, 'schema')}`);
      set(flow, 'schema', schema);
      set(flow, 'output', {});
    }
    return EmberObject.create(flow);
  }

  @computed('currentFlowStep', 'install.config')
  get isCurrentAuthSuccess() {
    const authFlowComplete = get(this, 'install.config.AUTH_FLOW_COMPLETE') ?? {};
    return isPresent(authFlowComplete) && isPresent(authFlowComplete[this.currentFlowStep]);
  }

  @computed('plugin.id', 'currentCompany.adhocPlugins')
  get hasAdhocOverride() {
    if (this.isAuthorized) {
      return true;
    }
    const pluginId = get(this, 'plugin.id');
    const adhocPlugins = get(this, 'currentCompany.adhocPlugins');

    return isArray(adhocPlugins) && adhocPlugins.includes(pluginId);
  }

  @computed('plugin.name')
  get featureName() {
    return `${dasherize(this.plugin.name)}_plugin`;
  }

  init() {
    super.init(...arguments);
    this.plugin = get(this, 'providedPlugin') || get(this, 'install.plugin');
  }

  // Actions ------------------------------------------------------------

  /**
   * confirmUninstall - Make sure the user wants to uninstall the plugin
   *
   * @return {type}  description
   */
  confirmUninstall() {
    return window.confirm('Are you sure you want to uninstall this plugin?');
  }

  /**
   * disableConfigure - disable the configure mode
   *
   * @protected
   */
  @action
  disableConfigure() {
    if (get(this, 'closeAction')) {
      get(this, 'closeAction')();
    } else {
      set(this, 'configureOn', false);
    }
  }

  /**
   * enableConfigure - switch to configure mode
   * Configuration mode opens up a configure form which displays
   * a configuration ux based off the plugin manifest.
   *
   * @protected
   */
  @action
  enableConfigure() {
    if (get(this, 'openAction')) {
      // Bubbling action to `integrations/index/route`
      this.openAction(`install-${get(this, 'install.id')}`);
    } else {
      set(this, 'configureOn', true);
    }
  }

  /**
   * Task to uninstall the current plugin
   * @protected
   * @task `uninstallPluginTask`
   */
  @dropTask
  *uninstallPluginTask() {
    if (!this.confirmUninstall()) {
      return;
    }

    set(this.install, 'status', 'uninstalled');

    yield this.install.save();

    this.disableConfigure();
    // eslint-disable-next-line ember/classic-decorator-no-classic-methods
    this.notifyPropertyChange('currentFlowStep');

    if (this.integrationDisconnected) {
      this.integrationDisconnected();
    }

    this.flashMessages.showFlash('success', 'Uninstall successful');
  }

  /**
   * chooseFlowStep - switch the active flow step
   *
   * @protected
   * @param integer index index of the step to switch to
   */
  @action
  chooseFlowStep(index) {
    const current = get(this, 'install.configureStep');
    if (current < index) {
      this.flashMessages.showFlash('warning', 'You must complete the current step before progressing.');
    } else {
      set(this, 'currentFlowStep', index);
    }
  }

  /**
   * Task to handle action for current flow step
   * @protected
   * @task `doPluginConfigureTask`
   */
  @dropTask
  *doPluginConfigureTask() {
    const step = get(this, 'flowToShow');
    yield new EmberPromise((resolve, reject) => {
      if (get(step, 'type') === 'oauth2' || get(step, 'action') === 'oauth2') {
        this.oauth2Handler(resolve, reject);
      } else if (get(step, 'type') === 'form') {
        this.formHandler(resolve, reject);
      } else if (get(step, 'type') === 'callback-auth' || get(step, 'action') === 'callback-auth') {
        this.callbackAuthHandler(resolve, reject);
      }
    });
  }

  /**
   * @protected
   * @task `authorizeScopesTask`
   */
  @dropTask
  *authorizeScopesTask() {
    try {
      const response = yield this.install.authorizeScopesAction();
      if (this.isDestroyed) {
        return;
      }
      this.store.push(this.store.normalize('plugin-install', response.data));
      this.flashMessages.showFlash('success', 'Saved successfully!');
      // eslint-disable-next-line ember/classic-decorator-no-classic-methods
      this.notifyPropertyChange('currentFlowStep');
      if (get(this, 'install.status') === 'active' && this.closeAction) {
        this.closeAction();
      }
    } catch (err) {
      if (this.isDestroyed) {
        return;
      }
      this.flashMessages.showFlash('error', get(err, 'errors.firstObject.detail'));
    }
  }

  // doPluginConfigure Handlers Helpers ---------------------------------------------

  /**
   * install the plugin if not already and return true if a new instalation started
   * @private
   * @task `_installIfMissingTask`
   */
  @dropTask
  *_installIfMissingTask() {
    if (get(this, 'isInstalled')) {
      return false;
    }

    const out = this.store.createRecord('plugin-install', {
      plugin: this.plugin,
      location: this.currentLocation,
    });

    const install = yield out.save();

    if (!get(this, 'install')) {
      yield get(this, 'installs').update();
    } else {
      set(this, 'providedInstall', install);
    }

    return true;
  }

  /**
   * make sure install model is up to date
   * @private
   * @task `_refreshInstallsTask`
   */
  @dropTask
  *_refreshInstallsTask() {
    const data = yield this.store.query('plugin-install', get(this, 'installs.query'));

    if (get(this, 'pluginInstalls.length')) {
      set(this, 'installs', data);
    } else {
      yield get(this, 'installs').update();
    }
  }

  /**
   * _completeAuthFlow - set the current auth flow as completed
   *
   * @private
   */
  _completeAuthFlow() {
    const install = get(this, 'install');
    const authFlowComplete = get(this, 'install.config.AUTH_FLOW_COMPLETE') ?? {};
    authFlowComplete[get(this, 'currentFlowStep')] = true;
    set(this, 'install.config.AUTH_FLOW_COMPLETE', authFlowComplete);
    if (get(this, 'isLastFlowStep')) {
      set(this, 'install.status', 'active');
    }
    install.save();
  }

  // doPluginConfigure Handlers ---------------------------------------------

  /**
   * oauth2Handler - handle the oauth login action
   *
   * @private
   * @param  {type} resolve resolve the doPluginConfigure promise
   * @param  {type} reject  reject the doPluginConfigure promise
   */
  oauth2Handler(resolve, reject) {
    const locationId = get(this, 'currentLocation.id');
    const plugin = get(this, 'plugin');
    const step = get(this, 'flowToShow');
    popupFlow(
      urlBuilder.pluginOAuth2Url(get(plugin, 'key'), get(step, 'app'), locationId),
      750,
      500,
      'oauth.connected',
    ).then(
      () => {
        if (get(this, 'isDestroyed')) {
          return;
        }

        this.store
          .query('plugin-install', get(this, 'installs.query'))
          .then((data) => {
            if (get(this, 'pluginInstalls.length')) {
              set(this, 'installs', data);
              return EmberPromise.resolve();
            } else {
              return get(this, 'installs').update();
            }
          })
          .then(() => {
            if (get(this, 'install.status') !== 'active') {
              this.enableConfigure();
            }
            this.flashMessages.showFlash('success', 'Authenticated successfully!');
            resolve();
          });
      },
      (reason) => {
        if (get(this, 'isDestroyed')) {
          return;
        }

        this.flashMessages.showFlash('error', (reason && reason.message) || 'Canceled');
        reject(reason);
      },
    );
  }

  /**
   * callbackAuthHandler - handle the custom login button
   *
   * @private
   * @param  {type} resolve resolve the doPluginConfigure promise
   * @param  {type} reject  reject the doPluginConfigure promise
   */
  callbackAuthHandler(resolve, reject) {
    const locationId = get(this, 'currentLocation.id');
    const plugin = get(this, 'plugin');
    const step = get(this, 'flowToShow');
    const pluginAuthFlowUrl = urlBuilder.pluginCallbackAuthUrl(get(plugin, 'key'), get(step, 'route'), locationId);
    const popupFlowPromise = popupFlow('about:blank', 750, 500);
    this._installIfMissingTask.perform().then(() => {
      const install = get(this, 'install');
      set(this, 'install.status', 'pending');
      install.save();
      window._popupFlowWindow.location = pluginAuthFlowUrl;
    });
    const unlessDestroyed = (f) => {
      return (data) => {
        if (!get(this, 'isDestroyed')) {
          return f(data);
        }
        return EmberPromise.resolve();
      };
    };
    popupFlowPromise
      .then(unlessDestroyed(() => this._refreshInstallsTask.perform()))
      .then(unlessDestroyed(() => this._completeAuthFlow()))
      .then(
        unlessDestroyed(() => {
          this.flashMessages.showFlash('success', 'Authenticated successfully!');
          this.enableConfigure();
          resolve();
        }),
      )
      .catch(
        unlessDestroyed((reason) => {
          this.flashMessages.showFlash('error', (reason && reason.message) || 'Canceled');
          reject(reason);
        }),
      );
  }

  /**
   * formHandler - handle form submissions
   *
   * @private
   * @param  {type} resolve resolve the doPluginConfigure promise
   * @param  {type} reject  reject the doPluginConfigure promise
   */
  formHandler(resolve, reject) {
    const install = get(this, 'install');
    this._installIfMissingTask.perform().then((isNewInstall) => {
      if (get(this, 'isDestroyed')) {
        return;
      }

      if (isNewInstall) {
        if (get(this, 'install.status') !== 'active') {
          this.enableConfigure();
        }
        return;
      }
      const fields = get(this, 'flowToShow.schema');
      const config = get(this, 'flowToShow.output');
      const unfulfilled = fields.find(({ is_required: required, key }) => required && !config[key]);
      if (unfulfilled) {
        this.flashMessages.showFlash('error', `${unfulfilled.label} is required.`);
        return reject();
      }
      install
        .setupAction({
          data: {
            attributes: config,
          },
          meta: {
            'configure-step': get(this, 'currentFlowStep'),
          },
        })
        .then(
          (response) => {
            if (get(this, 'isDestroyed')) {
              return;
            }

            this.store.push(this.store.normalize('plugin-install', response.data));
            this.flashMessages.showFlash('success', 'Saved successfully!');
            // eslint-disable-next-line ember/classic-decorator-no-classic-methods
            this.notifyPropertyChange('currentFlowStep');
            if (get(this, 'install.status') === 'active' && get(this, 'closeAction')) {
              get(this, 'closeAction')();
            }
            resolve();
          },
          (err) => {
            if (get(this, 'isDestroyed')) {
              return;
            }

            this.flashMessages.showFlash('error', get(err, 'errors.firstObject.detail'));
            reject();
          },
        );
    });
  }
}

/* eslint-disable no-console */
import Service, { service } from '@ember/service';
import type Store from '@ember-data/store';
import type cookies from 'ember-simple-auth/session-stores/cookies';
import fetch from 'fetch';
import type SessionService from 'garaje/services/session';
import urlBuilder from 'garaje/utils/url-builder';

export default class SigmaUrlService extends Service {
  @service declare cookies: cookies;
  @service declare session: SessionService;
  @service declare store: Store;

  async getLocationSigmaUrl(companyId: string, locationId: string, dashboard: string): Promise<string> {
    const url = urlBuilder.analytics.getLocationSigmaUrl(companyId, locationId, dashboard);
    let response;
    try {
      const csrfToken = this.cookies.read('csrf_token') as string;
      const headers = {
        'X-CSRF-Token': csrfToken,
      };
      response = await fetch(url, {
        credentials: 'include',
        headers,
        method: 'GET',
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch Sigma url ${response.status}`);
      }
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      console.log(`Error fetching Sigma url: ${e}`);
    }
    const finalUrl = (await (response as Response).text()) || '';
    return finalUrl;
  }

  async getGlobalSigmaUrl(companyId: string, dashboard: string): Promise<string> {
    const url = urlBuilder.analytics.getGlobalSigmaUrl(companyId, dashboard);
    let response;
    try {
      const csrfToken = this.cookies.read('csrf_token') as string;
      const headers = {
        'X-CSRF-Token': csrfToken,
      };
      response = await fetch(url, {
        credentials: 'include',
        headers,
        method: 'GET',
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch Sigma url ${response.status}`);
      }
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      console.log(`Error fetching Sigma url: ${e}`);
    }
    const finalUrl = (await (response as Response).text()) || '';
    return finalUrl;
  }
}

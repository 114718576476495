import gql from 'graphql-tag';

export default gql`
  fragment ConnectionFragment on Connection {
    email
    id
    provider
    roomsCount
  }
`;

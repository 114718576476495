import ApplicationSerializer from './application';

export default class PropertyPrinterSerializer extends ApplicationSerializer {
  /*
   * Our API currently does not support `queryRecord` (i.e. is
   * only returning an array of data instead of a single element
   * for queryRecord), so here we are implementing it by overriding
   * `normalizeQueryRecordResponse` to take the response from
   * `normalizeQueryResponse` and simply return the first result.
   */
  normalizeQueryRecordResponse() {
    const response = this.normalizeQueryResponse(...arguments);
    response.data = response.data[0] || null;
    return response;
  }
}

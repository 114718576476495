import Model, { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

import type Company from './company';

export type FilterType = 'locations' | 'company' | 'unfiltered';

@adapter('integration')
class GoogleAppModel extends Model {
  @belongsTo('company', { async: true })
  declare company: AsyncBelongsTo<Company>;

  @attr({ defaultValue: true })
  declare connected: boolean;

  @attr('boolean')
  declare enabled: boolean;

  @attr('string')
  declare email: string;

  @attr('string')
  declare errorState: string;

  @attr('string')
  declare errorMessage: string;

  @attr('string')
  declare createdAt: string;

  @attr('string')
  declare lastSyncAt: string;

  @attr('boolean')
  declare lastSyncSucceeded: boolean;

  @attr('string')
  declare accessToken: string;

  @attr('string')
  declare refreshToken: string;

  @attr('string')
  declare googleId: string;

  @attr({ defaultValue: () => ({ type: 'unfiltered' }) })
  declare filters: { type: FilterType; data?: Record<string, unknown> };

  name = 'Google Apps';

  get connectedBy(): string {
    return this.email;
  }

  get domain(): string | undefined {
    return (this.email || '').split('@')[1];
  }
}

export default GoogleAppModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'google-app': GoogleAppModel;
  }
}

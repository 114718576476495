import Component from '@glimmer/component';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { localCopy } from 'tracked-toolbox';

export default class EmployeesUserDocumentSummaryComponent extends Component {
  @tracked isShowingHistory = false;
  @localCopy('args.userDocumentTemplateConfiguration') configuration;

  get relevantUserDocuments() {
    const { userDocuments = A() } = this.args;
    const { configuration } = this;

    return userDocuments.filterBy('identifier', configuration?.identifier || '');
  }

  get userDocumentsSorted() {
    return this.relevantUserDocuments.sortBy('issueDate', 'createdAt').reverse();
  }

  get featuredUserDocument() {
    return this.userDocumentsSorted.firstObject;
  }
}

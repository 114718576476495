import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';

export default class LocationOverviewGlobalDevicesIndexRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  beforeModel(): void {
    if (this.abilities.can('read global-devices')) {
      void this.router.transitionTo('location-overview.global-devices.ipads');
    } else if (this.abilities.can('view-nav-item global-printers')) {
      void this.router.transitionTo('location-overview.global-devices.printers');
    } else {
      void this.router.transitionTo('unauthorized');
    }
  }
}

import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { dropTask, task } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';
import type PrinterConnection from 'garaje/models/printer-connection';
import type PrinterConnectionOptionModel from 'garaje/models/printer-connection-option';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

import type { PropertyDevicesPrintersConnectionsShowRouteModel } from './route';

export default class PropertyDevicesPrintersConnectionsShowController extends Controller {
  declare model: PropertyDevicesPrintersConnectionsShowRouteModel;

  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;
  @service declare store: Store;

  @action
  async gotoConnection(printerConnection: PrinterConnection): Promise<void> {
    void this.router.transitionTo('property.devices.printers.connections.show', printerConnection.id);
    await this.fetchPrinterConnectionOptionsTask.perform();
  }

  @action
  gotoNewConnectionSetup(): void {
    void this.router.transitionTo('property.devices.printers.connections.new');
  }

  @action
  async destroyPrinterConnection(printerConnection: PrinterConnection): Promise<void> {
    await this.destroyPrinterConnectionTask.perform(printerConnection);
  }

  @action
  async createPrinters(printerConnection: PrinterConnection, options: PrinterConnectionOptionModel[]): Promise<void> {
    const failed = [];

    const property = this.model.property;

    await Promise.all(
      options.map((option) => {
        const printer = this.store.createRecord('property-printer', {
          property,
          printerConnection,
          connectionType: 'printnode',
          name: option.name,
          model: 'Envoy Print',
          externalResourceUid: option.id,
        });

        return printer.save().catch(() => failed.push(option.name));
      }),
    );

    if (failed.length) {
      this.flashMessages.showFlash(
        'error',
        `Something went wrong while pairing ${pluralize(failed.length, 'printer')}`,
      );
      // reload PrinterConnectionOptions so that any that _did_ succeed are no longer available to select
      await this.fetchPrinterConnectionOptionsTask.perform();
    } else {
      this.flashMessages.showAndHideFlash(
        'success',
        `Paired ${pluralize(options.length, 'Printer', { withoutCount: true })}`,
      );
      // Reload the 'property.devices' route before we transition so that it gets the updated list of printers from the API
      await this.router.refresh('property.devices');
      void this.router.transitionTo('property.devices.printers.index');
    }
  }

  fetchPrinterConnectionOptionsTask = dropTask(async () => {
    return await this.store.query('printer-connection-option', {
      filter: {
        'exclude-in-use': true,
        'printer-connection-id': this.model.printerConnection.id,
        'zone-id': this.model.property.id,
      },
    });
  });

  destroyPrinterConnectionTask = task(async (printerConnection: PrinterConnection) => {
    try {
      await printerConnection.destroyRecord();

      this.flashMessages.showAndHideFlash('success', 'Printer connection unlinked!');

      void this.router.transitionTo('property.devices.printers.connections.index', {
        queryParams: {
          lastPrinterConnectionId: this.model.printerConnection.id,
        },
      });
    } catch (e) {
      this.flashMessages.showFlash('error', 'Deletion failed', parseErrorForDisplay(e));
    }
  });
}

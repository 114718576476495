import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';

const DEFAULT_KIND = 'none';
const CUSTOM_KIND = 'message';

export default class CustomDeniedMessageComponent extends Component {
  @service transitionConfirm;

  @tracked isPanelOpen = false;

  @dropTask
  *submitTask() {
    yield this.args.onSubmit(this.args.summaryPage);
  }

  @action
  resetDefault() {
    const { summaryPage } = this.args;
    setProperties(summaryPage, {
      kind: DEFAULT_KIND,
      message: get(summaryPage, 'defaultMessage'),
    });
    summaryPage.validate();
  }

  @action
  contentChanged(text) {
    const { summaryPage } = this.args;
    setProperties(summaryPage, {
      kind: text === get(summaryPage, 'defaultMessage') ? DEFAULT_KIND : CUSTOM_KIND,
      message: text,
    });
    summaryPage.validate();
  }

  @action
  expandPanel() {
    this.isPanelOpen = true;
  }

  @action
  collapsePanel() {
    const { summaryPage, rollback } = this.args;
    if (summaryPage.isDirty) {
      return this.transitionConfirm.displayConfirmTask.perform(null, {
        header: 'You have unsaved changes',
        continue: () => {
          rollback(summaryPage);
          this.isPanelOpen = false;
        },
      });
    }
    this.isPanelOpen = false;
  }
}

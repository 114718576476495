import ReservationWithAmenitiesFragment from 'garaje/graphql/fragments/employee-schedule/ReservationWithAmenitiesFragment';
import gql from 'graphql-tag';

export default gql`
  query ReservationById($reservationId: ID!) {
    reservationById(reservationId: $reservationId) {
      ...ReservationWithAmenitiesFragment
    }
  }
  ${ReservationWithAmenitiesFragment}
`;

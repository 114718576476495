import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import { task } from 'ember-concurrency';
import { alias } from 'macro-decorators';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import _isEmpty from 'lodash/isEmpty';
import _omitBy from 'lodash/omitBy';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';

const MODAL_CLOSED_THRESHOLD = 3;

export default class VisitorsCouponOfferModal extends Component {
  tagName = 'visitors-coupon-offer-modal';
  @service statsig;
  @service store;
  @service state;
  @service flashMessages;
  @service router;
  @service growthFeatureSetup;
  @service impressions;

  @alias('state.vrSubscription') subscription;
  @alias('state.currentCompany') currentCompany;
  @alias('subscription.isBasicPlan') isBasicPlan;

  @tracked showModal = false;
  @tracked showBanner = false;
  @tracked offers = [];
  @tracked statsigConfig;

  constructor() {
    super(...arguments);
    this.initStatsigConfig();
    this.getOffers.perform();
    this.fetchDataToConditionallyRender.perform();
  }

  initStatsigConfig() {
    if (this.subscription.cancelled || this.isBasicPlan) {
      const targetedConfig = this.statsig.getConfig('targeted_visitor_coupon_config');
      if (!_isEmpty(_omitBy(targetedConfig?.value, _isEmpty))) {
        this.statsigConfig = targetedConfig;
        return;
      }
    }

    if (this.subscription.subscribed && this.isBasicPlan) {
      const experimentConfig = this.statsig.getExperiment('visitor_basic_coupon_experiment');
      if (!_isEmpty(_omitBy(experimentConfig?.value, _isEmpty))) {
        this.statsigConfig = experimentConfig;
      }
    }
  }

  fetchDataToConditionallyRender = task({ drop: true }, async () => {
    if (!this.statsigConfig) {
      return;
    }

    const closedImpressionDays = await this.impressions.getImpressions.perform(
      IMPRESSION_NAMES.VISITORS_COUPON_OFFER_MODAL_CLOSED,
    );
    let closedImpressionsCount = 0;
    for (const impression of closedImpressionDays) {
      closedImpressionsCount += impression.count;
    }

    if (closedImpressionDays && closedImpressionsCount < MODAL_CLOSED_THRESHOLD) {
      this.showModal = true;
    } else {
      this.showBanner = true;
    }
  });

  getOffers = task({ drop: true }, async () => {
    const items = [];
    if (this.statsigConfig) {
      if (!isBlank(this.statsigConfig.value['standard_coupon_set'])) {
        items.push({
          couponSetId: this.statsigConfig.value['standard_coupon_set'],
          percentage: this.statsigConfig.value['standard_coupon_percentage'],
          plan: this.store.findRecord('plan', 'standard-1'),
          features: [
            'Unlimited visitors',
            'Badge printing',
            'Legal documents',
            'Host notifications',
            'Unlimited employees',
          ],
        });
      }

      if (!isBlank(this.statsigConfig.value['premium_coupon_set'])) {
        items.push({
          couponSetId: this.statsigConfig.value['premium_coupon_set'],
          percentage: this.statsigConfig.value['premium_coupon_percentage'],
          plan: this.store.findRecord('plan', 'premium'),
          features: [
            'All Standard features',
            'Visitor photos',
            'Analytics dashboard',
            'Invite-only entry',
            'Multiple sign-in flows',
            'Capacity management',
            'Directory integrations',
            'Alerts',
          ],
        });
      }
    }
    this.offers = items;
  });

  get gridClass() {
    return `grid-cols-${this.offers.length} ${this.offers.length === 1 ? 'px-10' : ''}`;
  }

  get title() {
    let titleText = 'Your first year is on us';
    if (this.subscription.cancelled) {
      titleText = 'We’ve missed you';
    } else if (this.offers.length === 1) {
      titleText = 'Here is your special offer';
    }
    return titleText;
  }

  get subTitle() {
    let subTitleText = 'Get all these features, with a special discount for your first year.';
    if (this.subscription.cancelled) {
      subTitleText = 'Rediscover all these features and more with a special discount.';
    }
    return subTitleText;
  }

  generateCouponCodeTask = task({ drop: true }, async ({ couponSetId, plan }) => {
    const company = this.state.currentCompany;

    const couponCode = this.store.createRecord('coupon-code', {
      company: company,
      couponSetId: couponSetId,
    });

    try {
      await couponCode.save();
      const plan_id = get(plan, 'id');

      this.router.transitionTo('billing.subscribe', {
        queryParams: { period: 'yearly', plan: plan_id, coupon: couponCode.id },
      });
      this.trackOfferClicked(plan_id);
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  onCloseTask = task({ drop: true }, async () => {
    this.showModal = false;
    await this.impressions.postImpression.perform(IMPRESSION_NAMES.VISITORS_COUPON_OFFER_MODAL_CLOSED);
  });

  @action
  async trackOfferViewed() {
    await this.impressions.postImpression.perform(IMPRESSION_NAMES.VISITORS_COUPON_OFFER_MODAL_VIEWED);
  }

  async trackOfferClicked(plan_id) {
    await this.impressions.postImpression.perform(
      `${IMPRESSION_NAMES.VISITORS_COUPON_OFFER_MODAL_CLICKED}-${plan_id.toUpperCase()}`,
    );
  }
}

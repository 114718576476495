import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

const NAMES = ['Adolfo', 'Alex', 'Ryan', 'Eric', 'Esteban', 'Yining'];

export default class FormExamples extends Component {
  @tracked isChecked = false;
  @tracked textareaValue = 'The quick brown fox jumped over the lazy dog.';
  @tracked selectedName = 'Ryan';

  names = NAMES;

  @action
  updateToggle(value) {
    this.isChecked = value;
  }
}

import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';

/**
 * @param {String}        scimProvider
 * @param {Function}      closeEnterpriseDialog
 * @param {Function}      displayRequestSentDialog
 */
class EnterpriseDialog extends Component {
  @service flashMessages;
  @service currentAdmin;
  @service ajax;
  @service metrics;

  get helpArticleLink() {
    switch (this.args.scimProvider) {
      case 'onelogin':
        return 'https://envoy.help/en/articles/3453393-onelogin-application#h_9ff111675a';
      case 'azure':
        return 'https://envoy.help/en/articles/3453335-microsoft-entra-id-integration#h_ce739a590a';
      default:
        // okta
        return 'https://envoy.help/en/articles/3453325-okta#h_4b60a66c59';
    }
  }

  createSalesforceLead = task({ drop: true }, async () => {
    this.requestContactClicked();

    try {
      const salesforceLeadData = {
        data: {
          type: 'salesforce-leads',
          attributes: {
            email: this.currentAdmin.email,
            'lead-source': 'SCIM Sync Admins Enterprise Upgrade',
            name: this.currentAdmin.fullName,
          },
        },
      };

      await this.ajax.post(urlBuilder.v3.salesforceLeads.createUrl(), { data: salesforceLeadData });

      this.args.closeEnterpriseDialog();
      this.args.displayRequestSentDialog();
    } catch (e) {
      this.flashMessages.showFlash('error', 'Try again', parseErrorForDisplay(e));
      throw e;
    }
  });

  requestContactClicked() {
    this.metrics.trackEvent('CTA Clicked', {
      cta_id: 'scim-admins-enterprise-upgrade-modal',
      cta_type: 'modal',
      cta_clickable_type: 'button',
      cta_intent: 'upgrade',
      cta_clickable_text: 'Request to be contacted',
    });
  }
}

export default EnterpriseDialog;

// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action, set } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { equal } from '@ember/object/computed';
import moment from 'moment-timezone';

const DEFAULT_FILTER = 'All Time';
/**
 * @param {Function}           setDateRange
 * @param {String}             dateRangeFilter
 */
export default class DateRangeSelect extends Component {
  @equal('dateRangeFilter', DEFAULT_FILTER) isDefaultFilter;

  dateRangeFilterOptions = [
    'Today',
    'Yesterday',
    'Last 7 days',
    'Last 30 days',
    'Last 90 days',
    'Past year',
    'All Time',
  ];
  dateRangeFilter = DEFAULT_FILTER;

  @action
  selectDateRange(option) {
    set(this, 'dateRangeFilter', option);

    if (option === 'All Time') {
      this.setDateRange(option, '', '');
      return;
    }

    let start = moment();
    let end = moment();
    switch (option) {
      case 'Yesterday':
        start = moment().subtract(1, 'days');
        end = moment().subtract(1, 'days');
        break;
      case 'Last 7 days':
        start = moment().subtract(7, 'days');
        break;
      case 'Last 30 days':
        start = moment().subtract(30, 'days');
        break;
      case 'Last 90 days':
        start = start.subtract(90, 'days');
        break;
      case 'Past year':
        start = moment().subtract(1, 'year');
        break;
    }

    start = start.startOf('day').format();
    end = end.endOf('day').format();

    this.setDateRange(option, start, end);
  }
}

import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { INVITE_STATE } from 'garaje/utils/enums';
import _cloneDeep from 'lodash/cloneDeep';
import { format, subMinutes, fromUnixTime } from 'date-fns';

/**
 * @param {gqlEntry}          entry             graphql representation of an entry
 * @param {gqlReservation}    reservation       graphql representation of a reservation
 * @param {gqlInvite}         invite            graphql representation of an invite
 * @param {number}            maxOccupancy      amount allowed in the office
 * @param {number}            peopleRegistered  total amount of people with invites for the day (not desk reservations)
 */
export default class ReservationCardComponent extends Component {
  @service flashMessages;
  @service state;
  @service metrics;
  @service registrations;
  @service featureConfig;

  @tracked unscheduleModalIsOpen = false;
  @tracked unscheduleError = false;
  INVITE_STATE_ENUM = INVITE_STATE;

  getLocationTime(time) {
    const dateTime = fromUnixTime(time);
    return subMinutes(dateTime, this.state.minutesBetweenTimezones(dateTime));
  }

  get partialTimeDisplay() {
    const { reservation } = this.args;
    const startTime = format(this.getLocationTime(reservation.startTime), 'h:mma');
    const endTime = format(this.getLocationTime(reservation.endTime), 'h:mma');

    return `${startTime} - ${endTime}`;
  }

  get isEditReservationConfigEnabled() {
    return this.featureConfig.isEnabled('deskReservations.editing');
  }

  get isCancelReservationConfigEnabled() {
    return this.featureConfig.isEnabled('deskReservations.canceling');
  }

  get isCheckInReservationConfigEnabled() {
    return this.featureConfig.isEnabled('deskReservations.checkingIn');
  }

  get showDelegatedBookerName() {
    return this.delegatedBookerName;
  }

  get delegatedBookerName() {
    const { invite } = this.args;
    if (!invite?.employee?.email || !invite?.visitor?.email) {
      return null;
    }

    if (invite.employee.email === invite.visitor.email) {
      return null;
    }

    return invite.employee.name;
  }

  unscheduleTask = task({ drop: true }, async () => {
    this.unscheduleError = false;

    try {
      const { date, allReservationsForDay } = this.args;
      const reservationIds = allReservationsForDay.map((res) => res.id);
      const { invite, unscheduledReservation } = await this.registrations.unschedulePartialDay(date, {
        reservationIds,
      });
      this.metrics.trackEvent('Web Employee Schedule - Unschedule', {
        desk_id: unscheduledReservation?.desk?.id,
        invite_date: invite.expectedArrivalTime,
        invite_id: invite.id,
        invite_location_id: invite.location.id,
        reservation_id: unscheduledReservation?.id,
      });
      this.unscheduleModalIsOpen = false;
      this.flashMessages.showAndHideFlash('success', 'Reservation released');
    } catch (e) {
      this.unscheduleError = true;
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e.errors?.firstObject));
    }
  });

  releaseDeskReservationTask = task({ drop: true }, async () => {
    const { reservation } = this.args;

    try {
      await this.registrations.releaseDeskPartialDay({ reservation });
      this.metrics.trackEvent('Web Employee Schedule - Release Desk', {
        reservation_id: reservation.id,
        desk_id: reservation.desk.id,
      });
      this.flashMessages.showAndHideFlash('success', 'Desk released');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e.errors?.firstObject));
    }
  });

  checkInReservationTask = task({ drop: true }, async () => {
    const { reservation, date } = this.args;

    try {
      await this.registrations.checkInReservation(date, { reservationId: reservation.id });
      this.metrics.trackEvent('Web Employee Schedule - Check In Reservation', {
        reservation_id: reservation.id,
      });
      this.flashMessages.showAndHideFlash('success', 'Checked in reservation');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e.errors?.firstObject));
    }
  });

  checkOutReservationTask = task({ drop: true }, async () => {
    const { reservation, date } = this.args;

    try {
      await this.registrations.checkOutReservation(date, { reservationId: reservation.id });
      this.metrics.trackEvent('Web Employee Schedule - Check Out Reservation', {
        reservation_id: reservation.id,
      });
      this.flashMessages.showAndHideFlash('success', 'Checked out reservation');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e.errors?.firstObject));
    }
  });

  @action
  closeUnscheduleModal() {
    this.unscheduleError = false;
    this.unscheduleModalIsOpen = false;
  }
}

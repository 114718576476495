import Component from '@glimmer/component';
import type AnnouncementRecipientModel from 'garaje/models/announcement-recipient';

type AnnouncementRecipientListType = {
  recipients: AnnouncementRecipientModel[];
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  hasMoreData: boolean;
  markAsSafeRequired?: boolean;

  changeSort(field: string, direction: string): unknown;
  loadMoreData(): void;
};

type HeaderType = {
  title: string;
  sortable?: boolean;
  sortKey?: string;
};

export default class AnnouncementRecipientList extends Component<AnnouncementRecipientListType> {
  get headers(): HeaderType[] {
    const HEADERS = [
      {
        title: 'Recipient',
        sortable: true,
        sortKey: 'name',
      },
      {
        title: 'Phone number',
      },
      {
        title: 'Sent status',
      },
    ];

    if (this.args.markAsSafeRequired) {
      HEADERS.push({
        title: 'Mark as safe',
      });
    }

    return HEADERS;
  }
}

import { service } from '@ember/service';
import Component from '@glimmer/component';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type IsOpenService from 'garaje/services/is-open';

export default class SideBarConnectMenuComponent extends Component {
  @service declare isOpen: IsOpenService;
  @service declare featureFlags: FeatureFlagsService;

  get occupancyAnalyticsEnabled(): boolean {
    return (
      this.featureFlags.isEnabled('connect-occupancy-analytics') ||
      this.featureFlags.isEnabled('connect-occupancy-analytics-live')
    );
  }
}

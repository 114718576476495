import AbstractSignInFieldActionRuleGroupModel from 'garaje/models/abstract/abstract-sign-in-field-action-rule-group';
import { belongsTo, hasMany } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('sign-in-field-action-rule-group')
class GlobalSignInFieldActionRuleGroupModel extends AbstractSignInFieldActionRuleGroupModel {
  @belongsTo('global-flow') flow;
  @hasMany('global-sign-in-field-action') signInFieldActions;
  @hasMany('global-sign-in-field-actions-contact') signInFieldActionsContacts;
}

export default GlobalSignInFieldActionRuleGroupModel;

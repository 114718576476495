import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class LocationOverviewEmployeesDirectoryAddEmployeeNewRoute extends Route {
  @service featureFlags;
  @service state;
  @service transitionConfirm;
  @service store;
  @service router;

  queryParams = {
    prefill: null,
  };

  get addEmployeeWithSync() {
    return this.featureFlags.isEnabled('add-employee-with-sync');
  }

  model() {
    const { currentLocation, currentCompany, vrSubscription } = this.state;
    const employee = this.store.createRecord('employee', {
      name: '',
      locations: [currentLocation],
      company: currentCompany,
      assistants: [],
      hideFromHostList: false,
      ccEmployee: false,
      manuallyAdded: true,
      doNotSync: this.addEmployeeWithSync,
    });

    return hash({ employee, vrSubscription });
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.setupChangeset();
  }

  @routeEvent
  routeWillChange(transition) {
    const controller = this.controller;
    const errors = controller.changeset.errors;

    // this cover the case of
    // 1. when email is found in other location (then hasChanges should be false)
    // 2. there is an error is the form, but the form is not dirty
    for (let idx = 0; idx < errors.length; idx++) {
      if (errors[idx].validation === 'Email found in other location') {
        return;
      }
    }

    // no need to display confirmation modal when nothing is changed
    if (!controller.changeset.isDirty) {
      return;
    }

    // display confirmation modal if the form is dirty
    this.transitionConfirm.displayConfirmTask.perform(transition, {
      header: 'Unsaved changes',
      body: 'Are you sure you want to discard creating this employee?',
      confirmText: 'Discard changes',
      cancelText: 'Keep editing',
      continue() {
        controller.changeset.rollback();
      },
    });
  }
}

import { set } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import type EmployeeRegistrationConfigurationModel from 'garaje/models/employee-registration-configuration';
import type LocationModel from 'garaje/models/location';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StoreService from 'garaje/services/store';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';

interface EaDelegatedReservationManagementArgs {
  location: LocationModel;
  employeeRegistrationConfiguration: EmployeeRegistrationConfigurationModel;
}

export default class EaDelegatedReservationManagement extends Component<EaDelegatedReservationManagementArgs> {
  @service declare featureFlags: FeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare store: StoreService;
  @service declare workplaceMetrics: WorkplaceMetricsService;

  updateConfigurationTask = task({ drop: true }, async (value: boolean) => {
    const { employeeRegistrationConfiguration } = this.args;
    set(employeeRegistrationConfiguration, 'eaDelegatedBookingEnabled', value);

    try {
      await employeeRegistrationConfiguration.save();
      this.workplaceMetrics.trackEvent('WORKPLACE_SETTINGS_EA_DELEGATED_RESERVATION_SETTING_TOGGLED', {
        value,
        locationId: this.args?.location?.id,
      });
    } catch (e) {
      this.workplaceMetrics.logMonitorError({
        event: 'WORKPLACE_SETTINGS_FAILED_TO_TOGGLE_EA_DELEGATED_RESERVATION_SETTING',
        debugExtras: {
          locationId: this.args?.location?.id,
        },
        error: e,
      });
      this.flashMessages.showFlash('error', 'Error', 'Something went wrong while saving those changes.');
    }
  });
}

// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { createSteps, updateSteps } from './deliveries-onboarding-steps';
import { action, set } from '@ember/object';

export default class StepperExamplesComponent extends Component {
  tagName = '';

  completed = false;

  steps = updateSteps(createSteps(), { completed: true }, 2);

  @action
  reviewStep(_step) {}

  @action
  updateStep(step) {
    set(step, 'reviewed', true);
  }

  @action
  onDismiss() {
    set(this, 'completed', true);
  }
}

import { get, set } from '@ember/object';

// We need to manually handle the propagation of files being changed because the file upload
// doesn't use the normal ED `save` method, so we can't benefit from `PropagableChangesTracker`.
export default function (globalSettingBatch, updatedKey, attr) {
  if (globalSettingBatch) {
    const updatedAttributes = get(globalSettingBatch, `${updatedKey}.updatedAttributes`);
    if (updatedAttributes) {
      const updatedAttributesKeys = new Set(updatedAttributes);
      updatedAttributesKeys.add(attr);
      set(globalSettingBatch, `${updatedKey}.updatedAttributes`, [...updatedAttributesKeys]);
    }
  }
}

import Inflector from 'ember-inflector';

export function initialize(/* application */) {
  const inflector = Inflector.inflector;

  inflector.uncountable('graphql/user-data');
  inflector.uncountable('average-monthly-visitor-count');
  inflector.uncountable('delivery-limit');
}

export default {
  name: 'custom-inflector-rules',
  initialize,
};

import ApplicationSerializer from './application';

export default class SummaryPageSerializer extends ApplicationSerializer {
  attrs = {
    // don't include image in the payload. API for this is not done
    // yet so we can improve this once we have that feature
    image: {
      serialize: false,
    },
    defaultMessage: {
      serialize: false,
    },
  };
}

import Controller from '@ember/controller';
import config from 'garaje/config/environment';
import { service } from '@ember/service';
import { get, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class DevDashboardController extends Controller {
  @service currentLocation;
  @service messageBus;
  @service flashMessages;
  @service state;
  @service localStorage;
  @tracked loginRequired = false;

  constructor() {
    super(...arguments);
    this.messageBus.on('embedded-app-message', this.handleMessage);
  }

  get iframeUrl() {
    return `${config.devDashboardHost}/entrypoint?loginRequired=${this.loginRequired}`;
  }

  get context() {
    return {
      currentLocation: {
        id: get(this.currentLocation, 'id'),
      },
    };
  }

  @action
  handleMessage(/*message*/) {
    // add messages here if needed
  }

  @action
  trackLoggedIn() {
    /**
     * Issue: When user logs out from the Garaje app there is no way Next.js app knows it also needs to logout.
     * Due to this the cookies of the logout user may persist.
     * Solution: After login, force login the user on his first visit to this app. This refreshes all the cookies
     * To know if the user is visiting for the first time we will maintain a global state in localstorage called 'dd_logged_in'
     * On Login -> dd_logged_in does not exists in localstorage -> its first visit
     * On first visit -> SignIn, set dd_logged_in = true in localstorage
     * On Logout -> Remove dd_logged_in
     */

    const ddLoggedIn = this.localStorage.getItem('dd_logged_in');

    if (!ddLoggedIn) {
      this.loginRequired = true;
      this.localStorage.setItem('dd_logged_in', true);
    } else {
      this.loginRequired = false;
    }
  }
}

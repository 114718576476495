import type ArrayProxy from '@ember/array/proxy';
import EmberObject, { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type GlobalAgreementModel from 'garaje/models/global-agreement';
import type GlobalAgreementPageModel from 'garaje/models/global-agreement-page';
import type GlobalFlowModel from 'garaje/models/global-flow';
import type GlobalSignInFieldModel from 'garaje/models/global-sign-in-field';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { hash } from 'rsvp';
import { v4 as uuid } from 'uuid';

import type { LocationOverviewSignInFlowsShowRouteModel } from '../route';

export interface LocationOverviewSignInFlowsShowLegalDocumentsRouteModel {
  agreements: ArrayProxy<GlobalAgreementModel> | undefined;
  agreementPage: GlobalAgreementPageModel;
  flow: GlobalFlowModel;
  signInFields: ArrayProxy<GlobalSignInFieldModel> | undefined;
  tracking: EmberObject & {
    jobId: string;
    currentCompanyId: string;
    currentLocationId?: string;
    eventName?: string;
  };
}

export default class LocationOverviewSignInFlowsShowLegalDocumentsRoute extends Route {
  @service declare metrics: MetricsService;
  @service declare state: StateService;

  model(): Promise<LocationOverviewSignInFlowsShowLegalDocumentsRouteModel> {
    const { globalFlow } = <LocationOverviewSignInFlowsShowRouteModel>(
      this.modelFor('location-overview.sign-in-flows.show')
    );
    const agreementPage = globalFlow.agreementPage;
    // eslint-disable-next-line ember/use-ember-get-and-set
    const agreements = agreementPage.get('agreements');
    // eslint-disable-next-line ember/use-ember-get-and-set
    const signInFields = globalFlow.signInFieldPage.get('signInFields');
    const tracking = EmberObject.create({
      jobId: uuid(),
      currentCompanyId: this.state.currentCompany.id,
    });

    return hash({
      agreements,
      agreementPage,
      flow: globalFlow,
      signInFields,
      tracking,
    });
  }

  @action
  trackAddLegalDocStarted(): void {
    const model = <LocationOverviewSignInFlowsShowLegalDocumentsRouteModel>this.modelFor(this.routeName);
    this.metrics.trackEvent('Global Flow - Legal Docs - Go To Add Legal Document Button Clicked', {
      button_text: 'add another document',
      company_id: model.tracking.currentCompanyId,
      job_id: model.tracking.jobId,
    });

    model.tracking.eventName = 'Global Flow - Legal Docs - Add Legal Document Save Clicked';
  }

  @action
  trackEditLegalDocStarted(): void {
    const model = <LocationOverviewSignInFlowsShowLegalDocumentsRouteModel>this.modelFor(this.routeName);
    model.tracking.eventName = 'Global Flow - Legal Docs - Legal Document Edited';
  }
}

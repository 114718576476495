import Model, { attr } from '@ember-data/model';
import { apiAction } from '@mainmatter/ember-api-actions';

class BannerModel extends Model {
  @attr('string') declare componentName: string;

  async dismiss(): Promise<unknown> {
    return await apiAction(this, { method: 'POST', path: 'dismiss' });
  }
}

export default BannerModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    banner: BannerModel;
  }
}

/* eslint-disable ember/no-computed-properties-in-native-classes */
import { computed, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import type { AsyncBelongsTo } from '@ember-data/model';
import { belongsTo } from '@ember-data/model';
import AbstractSignInFieldActionModel from 'garaje/models/abstract/abstract-sign-in-field-action';
import type DropdownOptionFragment from 'garaje/models/dropdown-option';
import type EmployeeSignInFieldModel from 'garaje/models/employee-sign-in-field';
import type EmployeeSignInFieldActionRuleGroupModel from 'garaje/models/employee-sign-in-field-action-rule-group';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('sign-in-field-action')
class EmployeeSignInFieldActionModel extends AbstractSignInFieldActionModel {
  /**
   * Parent
   */
  @belongsTo('employee-sign-in-field') declare signInField: AsyncBelongsTo<EmployeeSignInFieldModel>;
  @belongsTo('employee-sign-in-field', { inverse: 'actionableSignInFieldActions' })
  declare actionableSignInField: AsyncBelongsTo<EmployeeSignInFieldModel>;
  @belongsTo('employee-sign-in-field-action-rule-group')
  declare signInFieldActionRuleGroup: AsyncBelongsTo<EmployeeSignInFieldActionRuleGroupModel>;

  @alias('signInField.options') declare signInFieldOptions: EmployeeSignInFieldModel['options'];

  @computed('signInFieldValue', 'signInField.options.@each.id', '_dropdownOption', 'signInFieldOptions')
  get dropdownOption(): DropdownOptionFragment | undefined {
    if (this._dropdownOption) {
      // When creating a new action model, we need to cache this `obj` until we have
      // a proper option id
      return this._dropdownOption;
    }
    // eslint-disable-next-line ember/no-get
    const options = get(this, 'signInFieldOptions');
    return options && options.length ? options.find((opt) => opt.id === this.signInFieldValue) : undefined;
  }
}

export default EmployeeSignInFieldActionModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'employee-sign-in-field-action': EmployeeSignInFieldActionModel;
  }
}

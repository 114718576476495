import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type PrinterModel from 'garaje/models/printer';

import type { VisitorsDevicesPrintersNewRouteModel } from './route';

export default class VisitorsDevicesPrintersNewController extends Controller {
  declare model: VisitorsDevicesPrintersNewRouteModel;

  @service declare router: RouterService;
  @service declare store: StoreService;

  @action
  queryForBluetoothPrinter(): Promise<PrinterModel> {
    return this.store.queryRecord('printer', {
      filter: {
        connectionType: 'bluetooth',
        location: this.model.location.id,
      },
    });
  }

  @action
  redirectToPrinterList(): void {
    void this.router.transitionTo('visitors.devices.printers.index');
  }
}

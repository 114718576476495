import Model, { attr } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

interface Roles {
  // depends on the type of role what's put into the array
  [roleName: string]: Array<Location | Zone | Company>;
}

interface Location {
  id: string;
  name: string;
}

interface Zone {
  id: string;
  name: string;
}

interface Company {
  id: string;
  name: string;
}

@adapter('v2')
class UserRolesDigestModel extends Model {
  @attr('string') declare email: string;
  @attr('string') declare fullName: string;
  @attr('date') declare lastSeen: Date;
  @attr('string') declare avatar: string;
  @attr('string') declare userPhotoUrl: string;
  @attr declare roles: Roles;
  @attr('boolean') declare hasSyncedRole: boolean;
  @attr('boolean') declare hasManuallyAddedRole: boolean;
}

export default UserRolesDigestModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-roles-digest': UserRolesDigestModel;
  }
}

import type { JSONAPIDocument, Relationship } from 'jsonapi/types';

interface Attributes {
  'scheduled-by': number;
  'should-send-notification': boolean;
  'start-time': Date;
}

interface Relationships {
  employee?: {
    data: Relationship;
  };
  desk: {
    data: Relationship;
  };
}

type Assignment = JSONAPIDocument<Attributes, Relationships> & { type: 'assignment' };

function createAssignmentStorePayload(assignment: Assignment): { data: Assignment } {
  return {
    data: {
      id: assignment.id,
      type: 'assignment',
      attributes: assignment.attributes,
      relationships: assignment.relationships,
    },
  };
}

export default createAssignmentStorePayload;

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { TrackedArray } from 'tracked-built-ins';

import config from 'garaje/config/environment';

const EVENT_NAME = 'iframe-modal-message';

function warn() {
  if (config.environment === 'production') return;
  console.warn(...arguments); // eslint-disable-line no-console
}

export default class IFrameModalContainer extends Component {
  @service connectInvites;
  @service flashMessages;
  @service messageBus;

  eventName = EVENT_NAME;
  iframeModalQueue = new TrackedArray();

  get iframeModalUrl() {
    return this.iframeModalQueue[0];
  }

  get isShowingIframeModal() {
    return !!this.iframeModalUrl;
  }

  // this needs @action because it can be called from the template, not just `handleMessage`
  @action
  closeModal() {
    this.iframeModalQueue.shift();
  }

  @action
  handleMessage(message) {
    switch (message.event) {
      case 'closeModal':
        this.closeModal();
        break;
      case 'refreshConnectInvites':
        this.refreshConnectInvites();
        break;
      case 'showModal':
        this.showModal(message.url);
        break;
      case 'showToast':
        this.showToast(message);
        break;
      default:
        if (config.environment !== 'production') {
          warn('IframeModalContainer: received unknown message of type "%s"', message.event);
        }
    }
  }

  refreshConnectInvites() {
    this.connectInvites.refreshData.perform();
  }

  @action
  registerEventListener() {
    this.messageBus.on(EVENT_NAME, this.handleMessage);
  }

  showModal(url) {
    if (url) {
      this.iframeModalQueue.push(url);
    }
  }

  showToast(event) {
    const { details, message, type } = event;
    const recognizedTypes = ['error', 'success', 'warning'];
    if (recognizedTypes.includes(type)) {
      this.flashMessages.showAndHideFlash(type, message, details, false, true);
    } else {
      warn('IFrameModalContainer: showToast event triggered with unknown type "%s"', type);
    }
  }

  @action
  unregisterEventListener() {
    this.messageBus.off(EVENT_NAME, this.handleMessage);
  }
}

import ApplicationSerializer from './application';

export default class InviteSerializer extends ApplicationSerializer {
  attrs = {
    createdAt: {
      serialize: false,
    },
    creator: {
      serialize: false,
    },
    visitorDocuments: {
      serialize: false,
    },
    locationNames: {
      serialize: false,
    },
    approvalStatus: {
      serialize: false,
    },
  };
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    invite: InviteSerializer;
  }
}

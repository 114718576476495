import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { formatInTimeZone } from 'date-fns-tz';
import { dropTask } from 'ember-concurrency';
import type LocationModel from 'garaje/models/location';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import type TenantModel from 'garaje/models/tenant';
import type CurrentZoneService from 'garaje/services/current-zone';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MessageBusService from 'garaje/services/message-bus';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import type StateService from 'garaje/services/state';
import {
  type ConnectionRequestConfigModalDefinition,
  type ConnectionRequestConfigModalArgs,
} from 'garaje/utils/connect-modal-definitions';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

interface Option {
  location: SkinnyLocationModel;
  disabled: boolean;
}

interface ConnectionRequestConfigComponentArgs {
  /**
   * closure called when modal is closed
   */
  onClose: (identifiers: string | string[]) => unknown;
  /**
   * configuration passed into configuration class
   */
  configuration: ConnectionRequestConfigModalArgs;
  /**
   * configuration class used to construct modal
   */
  configurationClass: typeof ConnectionRequestConfigModalDefinition;
}

const DATE_TIME_FORMAT = "MM/dd/yyyy 'at' hh:mmaaa z";

export default class ConnectionRequestConfigComponent extends Component<ConnectionRequestConfigComponentArgs> {
  @service declare skinnyLocations: SkinnyLocationsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare messageBus: MessageBusService;
  @service declare state: StateService;
  @service declare currentZone: CurrentZoneService;

  get tenantsByLocation(): Record<string, TenantModel> {
    return (
      this.state.tenantConnections?.reduce<Record<string, TenantModel>>((hash, tenant) => {
        hash[tenant.locationId] = tenant;
        return hash;
      }, {}) ?? {}
    );
  }

  get options(): Option[] {
    return this.skinnyLocations.currentCompanyLocations.map((location) => {
      return { location, disabled: !!this.tenantsByLocation[location.id] };
    });
  }

  get enabledOptions(): Option[] {
    return this.options.filter((option) => !option.disabled);
  }

  get showForm(): boolean {
    return (this.enabledOptions.length !== 1 && !this.claimTokenTask.isRunning) || this.showConfirm;
  }

  get showIframe(): boolean {
    return !!(this.authUrl && !this.showConfirm);
  }

  get title(): string {
    return this.showConfirm
      ? 'Are you sure you don’t want to connect?'
      : `Connect with ${this.args.configuration.tenantConnectionRequest.tenant.propertyName}`;
  }

  get timezone(): string {
    return (
      this.currentZone.zone?.timezone ??
      this.state.currentLocation?.timezone ??
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
  }

  get formattedExpiration(): string | undefined {
    const expiresAt = this.args.configuration.tenantConnectionRequest.expiresAt;
    return expiresAt ? formatInTimeZone(expiresAt, this.timezone, DATE_TIME_FORMAT) : undefined;
  }

  @tracked selectedOption?: Option;
  @tracked authUrl?: string;

  @tracked showConfirm = false;

  constructor(owner: unknown, args: ConnectionRequestConfigComponentArgs) {
    super(owner, args);

    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.messageBus.on('embedded-app-message', this.onMessage);

    const bypassedLocation = this.getBypassedLocation();
    // if there is only a single location that can be connected, or component
    // is being used to connect to a specific location, skip selection step
    if (bypassedLocation) {
      void this.claimTokenTask.perform(bypassedLocation);
    }
  }

  getBypassedLocation(): SkinnyLocationModel | LocationModel | undefined {
    let bypassedLocation: SkinnyLocationModel | LocationModel | undefined;

    if (this.args.configuration.tenantConnectionRequest.pendingLocation) {
      bypassedLocation = this.args.configuration.tenantConnectionRequest.pendingLocation;
    } else if (this.enabledOptions.length === 1) {
      bypassedLocation = this.enabledOptions[0]?.location;
    }
    return bypassedLocation;
  }

  @action
  onMessage({ status }: { status: string }): void {
    if (status === 'oauth.connected') {
      this.flashMessages.showAndHideFlash('success', 'Connected to property successfully');
      // reload locations to bring in new property rel
      void this.skinnyLocations.loadAllTask.perform(true).catch(throwUnlessTaskDidCancel);
      this.onClose(true);
    } else if (status === 'oauth.rejected') {
      this.onClose();
    }
  }

  @action
  onClose(forceClose = false): void {
    if (this.showConfirm || forceClose === true) {
      // remove pending location from connection request
      if (this.args.configuration.tenantConnectionRequest.pendingLocation) {
        this.args.configuration.tenantConnectionRequest.pendingLocation = undefined;
      }

      this.args.onClose(this.args.configurationClass.identifier);
    } else {
      this.showConfirm = true;
    }
  }

  claimTokenTask = dropTask(async (location: SkinnyLocationModel | LocationModel) => {
    try {
      this.authUrl = await this.args.configuration.tenantConnectionRequest.claimToken(location);
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));

      // force close modal if there is an error and the user doesn't have a visible location selector form
      if (!this.showForm) this.onClose(true);
    }
  });

  willDestroy(...args: Parameters<Component['willDestroy']>): void {
    super.willDestroy(...args);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.messageBus.off('embedded-app-message', this.onMessage);
  }
}

import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { set } from '@ember/object';
import urlBuilder from 'garaje/utils/url-builder';
import { alias } from 'macro-decorators';

export default class DesksShowController extends Controller {
  @service state;
  @service store;
  @service flashMessages;
  @service ajax;
  @service metrics;

  @alias('state.desksSubscription') desksSubscription;

  /**
   * @returns {void}
   */
  @dropTask
  *handleFileSelectTask() {
    try {
      yield this.flashMessages.showAndHideFlash('success', 'File selected');
    } catch (err) {
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(err));
    }
  }

  /**
   * @type {Class<Desk>} desk
   * @returns {void}
   */
  @dropTask
  *toggleEnabledTask(deskId, enabled) {
    const desk = this.store.peekRecord('desk', deskId);
    set(desk, 'enabled', enabled);

    try {
      yield desk.save();
      yield this.flashMessages.showAndHideFlash('success', 'Desk saved!');
    } catch (e) {
      this.flashMessages.showFlash('Error saving desk', parseErrorForDisplay(e));
      yield desk.rollbackAttributes();
    }
  }

  /**
   * @type {File} Required. The file to upload
   * @returns {void}
   */
  @dropTask
  *uploadFloorplanTask(file) {
    const { id: floorId } = this.model.floor;
    const uploadUrl = urlBuilder.maps.rasterImageUrl(floorId);

    const formData = new FormData();
    formData.append('file', file);

    try {
      yield this.ajax.request(uploadUrl, {
        accept: 'application/vnd.api+json',
        contentType: false,
        data: formData,
        processData: false,
        type: 'PATCH',
      });

      yield this.model.floor.reload();
      this.metrics.trackEvent('Floor plan successfully uploaded', { floorId });
      this.flashMessages.showAndHideFlash('success', 'File uploaded!');
    } catch (err) {
      this.metrics.trackEvent('Error uploading floor plan', { error: err });
      this.flashMessages.showAndHideFlash('Error uploading file', parseErrorForDisplay(err));
    }
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

/**
 * @param {String}              provider
 * @param {Array<Object>}       locations
 * @param {Array<Object>}       employeeGroupRoles
 * @param {Function}            setDeleteDialog
 */
class AdminRoleMappings extends Component {
  @tracked employeeGroupRoleId = '';
  @tracked addAdminGroup = !(this.args.employeeGroupRoles && this.args.employeeGroupRoles.length > 0);
}

export default AdminRoleMappings;

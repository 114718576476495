import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

class BillingIndexRoute extends Route {
  @service featureConfig;
  @service flashMessages;
  @service state;
  @service router;
  @service store;

  queryParams = {
    showPlan: {
      replace: true,
    },
  };

  async model({ showPlan }) {
    if (showPlan) {
      return this.router.transitionTo('billing.change-plan', showPlan);
    }

    const {
      currentCompany,
      deliveriesSubscription,
      roomsSubscription,
      vrSubscription,
      desksSubscription,
      workplaceSubscription,
      vfdSubscription,
      activeDeskLocations,
      realDeliveriesSubscription,
      realRoomsSubscription,
      realDesksSubscription,
    } = this.state;
    const { activeRoomsQuantity, deliveryLimit, hasSufficientRoomsForDiscount, paymentSource, paymentSources } =
      this.modelFor('billing');

    const deliveryAreas = this.featureConfig.isEnabled('deliveries')
      ? this.store.findAll('delivery-area', { backgroundReload: true })
      : [];

    const planEstimates = this.store
      .queryRecord('estimate', { filter: { company_id: currentCompany.id } })
      .catch((_error) => {});

    const companyInfo = this.store.queryRecord('company-info', { id: currentCompany.id }).catch((_error) => {
      return { contacts: [] };
    });

    return hash({
      activeRoomsQuantity,
      company: currentCompany,
      deliveriesSubscription,
      deliveryAreas,
      planEstimates,
      deliveryLimit,
      hasSufficientRoomsForDiscount,
      roomsSubscription,
      paymentSource,
      paymentSources,
      vrSubscription,
      desksSubscription,
      workplaceSubscription,
      vfdSubscription,
      activeDeskLocations,
      realDeliveriesSubscription,
      realRoomsSubscription,
      realDesksSubscription,
      companyInfo,
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    const eligibleForRoomsDiscount =
      model.vrSubscription &&
      model.vrSubscription.isSubscribed &&
      model.roomsSubscription &&
      model.roomsSubscription.onTrial;

    if (eligibleForRoomsDiscount) {
      this.showRoomsDiscountMessage(model);
    }

    controller.fetchPayments.perform();
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      set(controller, 'showPlan', null);
    }
  }

  showRoomsDiscountMessage(model) {
    const { hasSufficientRoomsForDiscount } = model;
    const title = 'Before you buy Rooms, we’d love to talk!';

    this.flashMessages.showFlashComponent('info', title, 'flash-message/rooms-discount', {
      title,
      hasSufficientRoomsForDiscount,
    });
  }
}

export default BillingIndexRoute;

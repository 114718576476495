import { A } from '@ember/array';
import { get } from '@ember/object';
import { isPresent } from '@ember/utils';
import macro from 'macro-decorators';

/**
 * Used for grouping objects by a given property
 *
 * @param {Object} collection
 * @param {String} property
 * @returns {{items: Array, property: String, value: String}[]} groupped array
 */
export default function groupBy(collection, property) {
  return macro(function () {
    const groups = A();
    const items = get(this, collection);

    if (items) {
      items.forEach((item) => {
        const value = get(item, property);
        const group = groups.findBy('value', value);

        if (isPresent(group)) {
          get(group, 'items').push(item);
        } else {
          groups.push({
            property,
            value,
            items: [item],
          });
        }
      });
    }

    return groups;
  });
}

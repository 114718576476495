import ProtectSettingsPushNotificationsTimedReminderComponent from '../timed-reminder/component';

export default class ProtectSettingsPushNotificationsSignInReminderComponent extends ProtectSettingsPushNotificationsTimedReminderComponent {
  defaultTime = '9:00 am';

  get isToggledOn() {
    const { isPushNotificationEnabled, isDesksNotificationEnabled } = this.args;

    return isPushNotificationEnabled || isDesksNotificationEnabled;
  }

  get isDisabled() {
    const { isDisabled, isDesksNotificationEnabled } = this.args;

    return isDisabled || isDesksNotificationEnabled;
  }
}

import { service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import type ConnectLocationConfigurationModel from 'garaje/models/connect-location-configuration';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

interface ConnectConfigAutoSignInComponentArgs {
  /**
   * a connect location configuration model
   */
  configuration: ConnectLocationConfigurationModel;
}

export default class ConnectConfigAutoSignInComponent extends Component<ConnectConfigAutoSignInComponentArgs> {
  @service declare flashMessages: FlashMessagesService;

  toggleTask = dropTask(async () => {
    const { configuration } = this.args;
    try {
      configuration.automaticEntryCreationOnSignIn = !configuration.automaticEntryCreationOnSignIn;
      await configuration.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      // record will never be new so we can safely roll it back
      configuration.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}

import { service } from '@ember/service';
import Component from '@glimmer/component';
import type StateService from 'garaje/services/state';

const TRIAL_PROGRESS_BAR_MAXIMUM_PROGRESS_PERCENTAGE = 98;
const TRIAL_PROGRESS_BAR_ORANGE_THRESHOLD = 50;
const TRIAL_PROGRESS_BAR_RED_THRESHOLD = 75;
const NUM_MILLISECONDS_IN_ONE_DAY = 86400000;

export default class TrialProgressStatusBarComponent extends Component {
  @service declare state: StateService;

  get numDaysLeftInTrial(): number {
    if (!this.state.workplaceSubscription?.onTrial) {
      return 0;
    }

    return this.state.workplaceSubscription.trialDaysLeft;
  }

  get trialProgressPercentage(): number {
    if (
      !this.state.workplaceSubscription?.trialStartDate ||
      !this.state.workplaceSubscription.trialEndDate ||
      !this.state.workplaceSubscription?.onTrial
    ) {
      return TRIAL_PROGRESS_BAR_MAXIMUM_PROGRESS_PERCENTAGE;
    }

    const totalDaysInTrial = Math.ceil(
      (this.state.workplaceSubscription.trialEndDate.getTime() -
        this.state.workplaceSubscription.trialStartDate.getTime()) /
        NUM_MILLISECONDS_IN_ONE_DAY,
    );

    const trialProgressPercentage =
      totalDaysInTrial <= 0 ? 100 : Math.ceil(((totalDaysInTrial - this.numDaysLeftInTrial) / totalDaysInTrial) * 100);

    return Math.min(trialProgressPercentage, TRIAL_PROGRESS_BAR_MAXIMUM_PROGRESS_PERCENTAGE);
  }

  get trialProgressBarColor(): string {
    if (this.trialProgressPercentage < TRIAL_PROGRESS_BAR_ORANGE_THRESHOLD) {
      return 'bg-cilantro-50';
    }
    if (this.trialProgressPercentage < TRIAL_PROGRESS_BAR_RED_THRESHOLD) {
      return 'bg-papaya-30';
    }
    return 'bg-red-60';
  }

  shouldPluralizeDaysLeftInTrial(): boolean {
    return this.numDaysLeftInTrial !== 1;
  }
}

import { service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import type PropertyBadgeModel from 'garaje/models/property-badge';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

interface AutomaticPrinterSettingsArgs {
  badge: PropertyBadgeModel;
}

export default class PropertySettingBadgesAutomaticPrinterSettingsComponent extends Component<AutomaticPrinterSettingsArgs> {
  @service declare flashMessages: FlashMessagesService;

  toggleTask = task({ drop: true }, async () => {
    this.args.badge.autoPrintEnabled = !this.args.badge.autoPrintEnabled;

    try {
      await this.args.badge.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      this.args.badge.rollbackAttributes();
    }
  });
}

/* eslint-disable ember/no-computed-properties-in-native-classes */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action, computed, setProperties, set, get } from '@ember/object';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isBlank, isPresent } from '@ember/utils';
import { all, dropTask } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
/**
 * List of locations with group assignment capabilities
 * @param {Array<Group>}             groups           List of available groups to be assigned
 * @param {Array<SkinnyLocation>}    locations        List of skinny-locations
 */

export default class LocationsTableComponent extends Component {
  @service flashMessages;
  @service store;
  @service localStorage;
  @service windowLocation;
  @service contextSwitcher;
  @service state;

  sortField = 'nameWithCompanyName';
  sortDirection = 'asc';
  selectedLocations = [];
  showBulkModalAssignment = false;

  /**
   * Groups sorted by `name` to show in the dropdown selector
   */
  sortProperties = ['name'];
  @sort('groups', 'sortProperties') sortedGroups;

  /**
   * This CP filters the passed `@locations` using the `this.searchFilter`
   *
   * @return {Array<Location>}
   */
  @computed('locations.@each.nameWithCompanyName', 'searchFilter')
  get filteredLocations() {
    if (isBlank(this.searchFilter)) {
      return this.locations;
    }
    const lowerSearchFilter = this.searchFilter.toLowerCase();
    return this.locations.filter((location) => location.nameWithCompanyName.toLowerCase().includes(lowerSearchFilter));
  }

  @computed('filteredLocations.@each.{nameWithCompanyName,groups}', 'sortDirection', 'sortField')
  get sortedLocations() {
    const sorted = this.filteredLocations.sortBy(this.sortField);
    if (this.sortDirection === 'desc') {
      sorted.reverse();
    }
    return sorted;
  }

  @computed('selectedLocations.[]', 'locations.[]')
  get allCheckState() {
    if (this.selectedLocations.length === this.locations.length) {
      return 'checked';
    } else if (this.selectedLocations.length > 0) {
      return 'indeterminate';
    }
    return '';
  }

  /**
   * This CP sets a `min-height` value to the locations table so when filtering
   * it doesn't jump and keep a minimum height
   *
   * @return {Integer}
   */
  @computed('locations.[]')
  get minHeight() {
    const perRow = 63;
    return this.locations.length * perRow;
  }
  /**
   *
   * @return {Boolean}
   */
  @computed('selectedLocations.@each.group')
  get anyRemovableLocation() {
    return this.selectedLocations.any((location) => isPresent(location.group));
  }

  /**
   * @return {Boolean}
   */
  get workplaceSubscription() {
    return this.state.workplaceSubscription || this.state.vrSubscription?.canAccessProtectLegacyFeatures;
  }

  /**
   * @task `removeFromGroupTask`
   */
  @dropTask
  *removeFromGroupTask() {
    const locationsWithGroup = this.selectedLocations.filter((location) => isPresent(location.group));
    if (locationsWithGroup.length === 0) {
      return;
    }
    try {
      yield all(
        locationsWithGroup.map((location) => {
          const group = location.group;
          get(group, 'locations').removeObject(location);
          group.save();
        }),
      );

      // If the real location is already loaded, reload it.
      yield this._reloadPresentRealLocationsTask.perform(locationsWithGroup);

      const message = `${pluralize(locationsWithGroup.length, 'location')} removed from groups.`;
      this.flashMessages.showAndHideFlash('success', message);
      set(this, 'selectedLocations', []);
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }

  /**
   * @task `assignGroupTask`
   *
   * @param {Group} selectedGroup
   */
  @dropTask
  *assignGroupTask(selectedGroup) {
    const locationsWithGroup = this.selectedLocations.filter((location) => isPresent(location.group));
    try {
      // Remove any existing group associations
      yield all(
        locationsWithGroup.map((location) => {
          const group = location.group;
          get(group, 'locations').removeObject(location);
          return group.save();
        }),
      );

      this.selectedLocations.map((location) => get(selectedGroup, 'locations').addObject(location));
      yield selectedGroup.save();

      // If the real location is already loaded, reload it.
      yield this._reloadPresentRealLocationsTask.perform(this.selectedLocations);

      this.flashMessages.showAndHideFlash('success', 'Saved');
      set(this, 'showBulkModalAssignment', false);
      set(this, 'selectedLocations', []);
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }

  @action
  sortByGroups(sortField, sortDirection) {
    setProperties(this, { sortField, sortDirection });
  }

  /**
   * @param {SkinnyLocation} location
   */
  @action
  switchLocation(location, e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    this.contextSwitcher.locationId = location.id;
    this.windowLocation.assign('/');
  }

  /**
   * @param {SkinnyLocation} location
   */
  @action
  onLocationSelected(location) {
    if (this.selectedLocations.includes(location)) {
      set(
        this,
        'selectedLocations',
        this.selectedLocations.filter((selectedLocation) => selectedLocation !== location),
      );
    } else {
      set(this, 'selectedLocations', [...this.selectedLocations, location]);
    }
  }

  /**
   * @param {Event} e
   */
  @action
  toggleAllSelected({ target }) {
    if (this.selectedLocations.length === 0) {
      set(this, 'selectedLocations', [...this.locations]);
    } else {
      set(this, 'selectedLocations', []);
    }
    target.checked = this.selectedLocations.length > 0;
    target.indeterminate = this.allCheckState === 'indeterminate';
  }

  /**
   * When a group is assigned or removed, if the real location (not the `skinnyLocation`) is already in the store
   * we need to reload it so the association matches.
   * @task `_reloadPresentRealLocationsTask`
   * @param {SkinnyLocation} locations
   */
  @dropTask
  *_reloadPresentRealLocationsTask(locations) {
    for (const location of locations) {
      const realLocation = this.store.peekRecord('location', location.id);
      if (realLocation) {
        yield realLocation.reload();
      }
    }
  }
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import { capitalize } from '@ember/string';
import Component from '@glimmer/component';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';

export default class BillingAndTrialInfoComponent extends Component {
  @service declare statsig: StatsigService;
  @service declare state: StateService;
  @service declare workplaceMetrics: WorkplaceMetricsService;

  get shouldShowTrialStatus(): boolean {
    return !this.state.workplaceSubscription?.subscribed;
  }

  get billingInfoBodyString(): string {
    if (this.state.workplaceSubscription?.subscribed) {
      if (this.state.workplaceSubscription.isPremiumPlusPlan) {
        return ' You are using Workplace Premium Plus.';
      }
      return `You are using Workplace ${capitalize(
        this.state.workplaceSubscription.plan,
      )}. Upgrade to Workplace Premium Plus using the billing portal linked below.`;
    }

    if (this.state.workplaceSubscription?.onTrial) {
      return 'You will not be auto-billed at the end of the trial. After your trial is over, you’ll be asked if you’d like to buy Workplace Premium.';
    }

    if (this.state.workplaceSubscription?.cancelled) {
      return 'You have cancelled your Workplace subscription. Please re-purchase Envoy Workplace.';
    }

    return 'Your trial is over, please purchase Envoy Workplace.';
  }

  get trialEndDateString(): string {
    if (!this.state.workplaceSubscription?.trialEndDate) {
      return '';
    } else if (!this.state.workplaceSubscription?.onTrial) {
      return `Trial ended ${this.state.workplaceSubscription.trialEndDate.toDateString()}`;
    }
    return `Trial ends ${this.state.workplaceSubscription?.trialEndDate.toDateString()}`;
  }

  @action
  logBillingInfoClickedAction(): void {
    this.workplaceMetrics.trackEvent('WORKPLACE_TRIAL_SETUP_GUIDE_BILLING_LINK_CLICKED');
  }
}

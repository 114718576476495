import Model, { attr, hasMany, type AsyncHasMany } from '@ember-data/model';
import type SkinnyLocationModel from 'garaje/models/skinny-location';

class GroupModel extends Model {
  @hasMany('skinny-location') declare locations: AsyncHasMany<SkinnyLocationModel>;
  @attr('string') declare name: string;

  get totalLocations(): number {
    // @ts-ignore - TS & .hasMany don't play well together when the relationship name differs from the model name
    return this.hasMany('locations').ids().length;
  }
}

export default GroupModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    group: GroupModel;
  }
}

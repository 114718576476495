import { attr } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';
import serializer from 'garaje/utils/decorators/serializer';

import Integration from './integration';

@adapter('v1')
@serializer('ams')
class BoxModel extends Integration {
  @attr('string') declare grantId: string;
}

export default BoxModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    box: BoxModel;
  }
}

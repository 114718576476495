import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { validate } from 'ember-validators';

/**
 * @param {String}                    ariaLabelledBy
 * @param {String}                    ariaLabel
 * @param {Boolean}                   bgReset
 * @param {Boolean}                   disabled
 * @param {Function}                  onChange
 * @param {String}                    placeholder
 * @param {Array<String>}             selected
 * @param {String}                    tagLabel
 * @param {String}                    validationType // https://github.com/offirgolan/ember-validators#usage
 * @param {Array}                     validationOptions // https://github.com/offirgolan/ember-validators#usage
 * @param {String}                    validationDescription // https://github.com/offirgolan/ember-validators#usage
 */
export default class TagsInput extends Component {
  @tracked tagLabel;

  constructor() {
    super(...arguments);
    this.tagLabel = this.args.tagLabel ?? 'tag';
  }

  get _selected() {
    return this.args.selected?.map((item) => ({ value: item }));
  }

  @action
  showSearchAsValidatedTagOption(value) {
    if (this.args.validationType) {
      const validation = validate(this.args.validationType, value, this.args.validationOptions);
      const disabled = validation !== true;
      return [{ value, disabled }];
    } else {
      return [{ value }];
    }
  }

  @action
  handleKeyDown(select, event) {
    if (event && (event.key === 'Tab' || event.key === 'Enter') && select.isOpen) {
      event.preventDefault();

      if (select.results && select.results[0] && !select.results[0].disabled) {
        select.actions.choose(select.results[0], event);
      }

      select.actions.close(event);
      return false;
    }
  }

  @action
  handleChange(options = [], select) {
    const didRemoveAnOption = options.length < this.args.selected?.length;
    if (didRemoveAnOption) {
      select.actions && select.actions.open(); // refocus the TagsInput after removing a tag
    }
    this.args.onChange(options.map((opt) => opt.value));
  }
}

import AbstractVisitorContactModel from 'garaje/models/abstract/abstract-visitor-contact';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('multi-tenancy')
class ConnectVisitorContactModel extends AbstractVisitorContactModel {}

export default ConnectVisitorContactModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'connect-visitor-contact': ConnectVisitorContactModel;
  }
}

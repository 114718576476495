import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import type AjaxService from 'garaje/services/ajax';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';

interface EnterpriseDialogArgs {
  closeEnterpriseDialog: () => void;
  displayRequestSentDialog: () => void;
}

class EnterpriseDialog extends Component<EnterpriseDialogArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare ajax: AjaxService;
  @service declare metrics: MetricsService;

  createSalesforceLeadTask = dropTask(async () => {
    this.requestContactClicked();

    try {
      const salesforceLeadData = {
        data: {
          type: 'salesforce-leads',
          attributes: {
            email: this.currentAdmin.email,
            'lead-source': 'Custom Roles Enterprise Upgrade',
            name: this.currentAdmin.fullName,
          },
        },
      };

      await this.ajax.post(urlBuilder.v3.salesforceLeads.createUrl(), { data: salesforceLeadData });
      this.args.displayRequestSentDialog();
    } catch (e) {
      this.flashMessages.showFlash('error', 'Try again', parseErrorForDisplay(e));
    }
  });

  requestContactClicked(): void {
    this.metrics.trackEvent('CTA Clicked', {
      cta_id: 'custom-roles-enterprise-upgrade-modal',
      cta_type: 'modal',
      cta_clickable_type: 'button',
      cta_intent: 'upgrade',
      cta_clickable_text: 'Request to be contacted',
    });
  }
}

export default EnterpriseDialog;

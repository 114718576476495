/* eslint-disable ember/no-computed-properties-in-native-classes */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { computed, get, action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class PaymentRow extends Component {
  @service ajax;
  @service windowLocation;
  @service state;

  tagName = 'tr';
  typeSubscriptions = 'subscriptions';
  typeAddons = 'addons';

  @alias('payment.date') date;
  @alias('payment.companyName') companyName;
  @alias('payment.invoiceItems') invoiceItems;
  @alias('payment.isChargebeePayment') isChargebeePayment;
  @alias('payment.pdf') pdf;
  @alias('payment.refunded') refunded;
  @alias('state.billingCompany.hasChildren') hasChildren;

  @computed('payment.amount')
  get amount() {
    return get(this, 'payment.amount') / 100;
  }

  @computed('payment.amountRefunded')
  get amountRefunded() {
    return get(this, 'payment.amountRefunded') / 100;
  }

  @computed('payment.{status,amountRefunded,amount}', 'refunded')
  get status() {
    const status = get(this, 'payment.status');
    if (status === 'upcoming') {
      return { classes: 'paymentIcon icon-next', text: 'Next payment' };
    } else if (status === 'failed') {
      return { classes: 'paymentIcon icon-alert', text: 'Failed' };
    } else if (status === 'paid') {
      if (get(this, 'refunded')) {
        if (get(this, 'payment.amountRefunded') === get(this, 'payment.amount')) {
          return { classes: 'paymentIcon icon-refund', text: 'Refunded' };
        } else {
          return { classes: 'paymentIcon icon-refund icon-refund-partial', text: 'Partially refunded' };
        }
      }
      return { classes: 'paymentIcon icon-paid', text: 'Paid' };
    }
    return null;
  }

  @action
  async downloadPdf() {
    if (this.isChargebeePayment) {
      const response = await this.ajax.request(this.pdf);
      window.open(response.url, '_blank');
    } else {
      window.open(this.pdf, '_blank');
    }
  }
}

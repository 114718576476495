import GenericResponseFragment from 'garaje/graphql/fragments/employee-schedule/GenericResponseFragment';
import gql from 'graphql-tag';

export default gql`
  mutation DeleteInviteReservation($inviteId: ID!, $reservationId: ID!) {
    deleteInvite(id: $inviteId) {
      id
    }
    releaseDeskReservation(reservationId: $reservationId) {
      ...GenericResponseFragment
    }
  }
  ${GenericResponseFragment}
`;

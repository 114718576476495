import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DesksGetStartedRoute extends Route {
  @service state;
  @service router;
  @service abilities;
  @service featureConfig;

  beforeModel() {
    if (this.abilities.cannot('visit-get-started desks')) {
      this.router.transitionTo('unauthorized');
    }
  }

  model() {
    return this.modelFor('desks');
  }

  redirect({ floors }) {
    const { currentLocationEnabledDesks } = this.state;
    if (!this.featureConfig.isEnabled('desks') || !currentLocationEnabledDesks) {
      return this.router.transitionTo('desks.landing-page');
    } else if (floors?.length) {
      return this.router.transitionTo('desks.index');
    }
  }
}

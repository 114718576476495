import Controller from '@ember/controller';
import { service } from '@ember/service';
import type Store from '@ember-data/store';

import { type PropertySettingsKioskFinalScreenRouteModel } from './route';

const DEFAULT =
  "![](https://envoyuserentries.s3.amazonaws.com/summary_attributes/defaults/connect/thanks_for_checking_in.svg)\n\n# Thanks for checking in! We'll let them know that you're here.\n\n### You can now leave the kiosk.\n";
const VISITOR_DEFAULT =
  '![](https://envoyuserentries.s3.amazonaws.com/summary_attributes/defaults/connect/i_have_a_qr_code.png)\n\n# Welcome!\n\n### Please see the front desk to get your badge assigned.\n';

export default class PropertySettingsKioskFinalScreenController extends Controller {
  @service declare store: Store;

  declare model: PropertySettingsKioskFinalScreenRouteModel;

  get hasMultipleLanguages(): boolean {
    return this.model.property.enabledLocales.size > 0;
  }

  get locales(): string[] {
    const property = this.model.property;
    return [property.locale, ...property.enabledLocales];
  }

  defaultText = DEFAULT;
  visitorDefaultText = VISITOR_DEFAULT;
}

import { Audiences } from '../types';

import { MESSAGE_AUDIENCE_TYPES } from './message-audience-types';

export const MESSAGE_VISITOR_AUDIENCES = [
  {
    label: MESSAGE_AUDIENCE_TYPES[Audiences.AllVisitorsCheckedIn],
    value: Audiences.AllVisitorsCheckedIn,
  },
  {
    label: MESSAGE_AUDIENCE_TYPES[Audiences.AllVisitorsWithReservation],
    value: Audiences.AllVisitorsWithReservation,
  },
  {
    label: MESSAGE_AUDIENCE_TYPES[Audiences.AllVisitorsSignedOut],
    value: Audiences.AllVisitorsSignedOut,
  },
];

import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import groupBy from 'garaje/utils/decorators/group-by';
import { dependentKeyCompat } from '@ember/object/compat';
import { filter } from 'macro-decorators';
import { CohoWorkplaceEventNames } from 'garaje/services/coho';
import { APP } from 'garaje/utils/enums';

export default class ChangelogsIndexController extends Controller {
  @service infinity;
  @service coho;

  @tracked hasClickedLoadMore;

  @dependentKeyCompat
  get sortedLogs() {
    return this.notEmptylogs.sortBy('createdAt').reverse();
  }

  @action
  logViewToCoho() {
    this.coho.sendEvent(CohoWorkplaceEventNames.ActivityLogChecked, { product: APP.WORKPLACE });
  }

  @groupBy('sortedLogs', 'day') logsByDay;
  @filter('model', (changelog) => isPresent(get(changelog, 'items'))) notEmptylogs;

  /*
    @param {InfinityModel} changelogs
  */
  @task
  *loadMoreLogs(changelogs) {
    yield this.infinity.infinityLoad(changelogs);
    this.hasClickedLoadMore = false;
  }
}

import { get } from '@ember/object';
import { validatePresence, validateFormat, validateNumber } from 'ember-changeset-validations/validators';
import { isPromise } from 'validated-changeset';

function checkHost(context, employee) {
  if (get(context, 'hostSignInField.required') && !employee) {
    return 'Employee is required';
  }

  return true;
}

export default function buildInviteValidations(context) {
  return {
    fullName: validatePresence({
      presence: true,
      message: 'This is a required field',
    }),
    email: validateFormat({
      type: 'email',
      allowBlank: true,
      message: 'Please use a valid email address',
    }),
    additionalGuests: validateNumber({
      integer: true,
      gte: 0,
      message: 'Must be a number higher or equal to 0',
    }),
    employee(key, newValue) {
      if (isPromise(newValue)) {
        return newValue.then(function (employee) {
          return checkHost(context, employee);
        });
      } else {
        return checkHost(context, newValue);
      }
    },
  };
}

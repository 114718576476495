import { action } from '@ember/object';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout, lastValue } from 'ember-concurrency';
import type EntryModel from 'garaje/models/entry';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type { RecordArray } from 'garaje/utils/type-utils';

interface VisitorSearchComponentSignature {
  Args: {
    searchValue?: string;
    setQuery: (val?: string) => void;
    searchValueHasChanged: () => void;
    updateSearchValue: (val: string) => void;
  };
}

const MIN_SEARCH_LENGTH = 3;

export default class VisitorSearchComponent extends Component<VisitorSearchComponentSignature> {
  @service declare featureFlags: FeatureFlagsService;
  @service declare store: StoreService;

  minSearchLength = MIN_SEARCH_LENGTH;

  @tracked showEnterToSearchHint = false;

  @lastValue('doSearchVisitors') visitors?: RecordArray<EntryModel>;

  get uniqueSearchVisitors(): string[] {
    const visitors = this.visitors?.toArray() ?? [];
    return visitors.reduce<string[]>((acc, curr) => {
      const name = curr.fullName;

      if (!acc.includes(name)) {
        acc = [...acc, name];
      }

      return acc;
    }, []);
  }

  doSearchVisitors = restartableTask(async (val: string = '') => {
    // do not search if input is less than 3 characters
    if (val.length < MIN_SEARCH_LENGTH) {
      return [];
    }

    await timeout(200);

    const params = {
      filter: {
        query: val,
      },
    };

    return await this.store.query('entry', params);
  });

  @action
  searchVisitors(val: string): void {
    void this.doSearchVisitors.perform(val);
  }

  @action
  filterByVisitor(event: KeyboardEvent): void {
    // Enter
    if (event.key === 'Enter') {
      const target = <HTMLInputElement>event.target;

      if (target.value.length < MIN_SEARCH_LENGTH) {
        // search on enter
        this.setSearchValue(target.value, true);
      } else {
        // traditional visitor search
        const searchVal = this.args.searchValue;
        this.args.setQuery(searchVal);
      }

      event.stopPropagation();
    }

    // Space
    if (event.key === ' ' || event.key === 'Spacebar') {
      // Stop the default handler from running on input of space
      // https://ember-basic-dropdown.com/docs/trigger-events
      event.stopPropagation();
    }
  }

  @action
  setSearchValue(val: string, performSearch = false): void {
    this.showEnterToSearchHint = false;

    if (val.length && val.length < MIN_SEARCH_LENGTH && !performSearch) {
      this.showEnterToSearchHint = true;
      return;
    }

    this.args.updateSearchValue(val);
    this.args.searchValueHasChanged();
  }
}

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class GlobalAnalyticsVisitorsDashboardRoute extends Route {
  @service abilities;
  @service featureFlags;
  @service router;
  @service state;

  beforeModel() {
    const { vrSubscription } = this.state;
    if (this.abilities.cannot('visit-visitors global-analytics') || !vrSubscription.canAccessGlobalAnalytics) {
      this.router.transitionTo('unauthorized');
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.showModal = false;
  }
}

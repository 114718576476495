import RSVP from 'rsvp';
import { isEmpty, typeOf } from '@ember/utils';
import { validatePresence, validateFormat, validateNumber } from 'ember-changeset-validations/validators';
import { get } from '@ember/object';

export default function buildInviteValidations(options = {}) {
  const defaults = {
    hostFieldIsRequired: false,
    checkEmailRequired: false,
    store: null,
  };

  options = Object.assign({}, defaults, options);

  const validations = {
    // using flowName breaks computed properties and getter in single-prereg component
    flowCheck(_key, _newValue, _oldValue, changes, content) {
      if (!content.flowName && !changes?.flowName) {
        return 'This is a required field';
      }

      return true;
    },
    fullName: validatePresence({
      presence: true,
      message: 'This is a required field',
    }),
    email: [
      validateFormat({
        type: 'email',
        allowBlank: true,
        message: 'Please use a valid email address',
      }),
    ],
    expectedArrivalTime: [
      validatePresence({
        presence: true,
        message: 'This is a required field',
      }),
      async function validateCapacity() {
        const { validateCapacity } = options;
        if (typeOf(validateCapacity) === 'function') {
          return await validateCapacity(...arguments);
        }
        return true;
      },
    ],
    additionalGuests: validateNumber({
      integer: true,
      gte: 0,
      message: 'Must be a number higher or equal to 0',
    }),
    employee(key, newValue) {
      return RSVP.resolve(newValue).then((employee) => {
        if (options.hostFieldIsRequired && !employee) {
          return 'Employee is required';
        }

        return true;
      });
    },
  };

  if (options.checkEmailRequired) {
    validations.email.push(function validateRequired(key, newValue, oldValue, changes, content) {
      const { flowName } = changes ?? {};
      if (!flowName) {
        const emailField = get(content ?? {}, 'flow.signInFieldPage.signInFields')?.findBy('isEmail');
        if (emailField?.required) {
          return isEmpty(newValue) ? 'This is a required field' : true;
        }
      } else {
        const flow = options.store?.peekAll('flow').find((flow) => {
          return flow.name === flowName;
        });
        if (!flow) {
          return true;
        }

        const emailField = get(flow, 'signInFieldPage.signInFields').findBy('isEmail');
        if (emailField?.required) {
          return isEmpty(newValue) ? 'This is a required field' : true;
        }
      }

      return true;
    });
  }

  return validations;
}

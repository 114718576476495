import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class BillingPeriodController extends Controller {
  queryParams = ['viaLabel', 'viaObject'];
  viaLabel = null;
  viaObject = null;

  @tracked hasPaymentMethod;

  @action
  transitionToBilling() {
    this.transitionToRoute('billing'); // eslint-disable-line ember/no-deprecated-router-transition-methods
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import LOCALE_OPTIONS from 'garaje/utils/locale-options';
import { action, get, set } from '@ember/object';
import { all, dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { filter } from 'macro-decorators';

/**
 * @param {Array<String>|required}                                enabledLocales ie. ['en', 'ru', 'de'] etc...
 * @param {String|required}                                       locale 'en', etc...
 * @param {Task|required}                                         saveTask
 * @param {function|optional}                                     onClose
 * @param {Array<Object{customTranslations}>|required}            translatables An aray of objects adhereing to a translatable interface. Must have a customTranslations prop.
 */

export default class ManageTranslationsComponent extends Component {
  @service metrics;
  @service state;

  @tracked selectedLocale = null;

  constructor() {
    super(...arguments);
    const firstLocale = this.args.enabledLocales[0];
    this.localeOptions = LOCALE_OPTIONS;
    this.selectedLocale = this.localeOptions.find((locale) => locale.value === firstLocale);
    this.reloadTranslatablesTask.perform();
  }

  @filter('args.translatables', (translatable) => !translatable.isDeleted && !translatable.isNew) filteredTranslatables;

  get defaultLocale() {
    return this.localeOptions.find((locale) => locale.value === this.args.locale).label;
  }

  get availableLocales() {
    return this.localeOptions.filter((option) => this.args.enabledLocales.includes(option.value));
  }

  @action
  changeLocale(locale) {
    this.selectedLocale = locale;
  }

  @dropTask
  *reloadTranslatablesTask() {
    yield all(this.filteredTranslatables.map((field) => field.reload()));
  }

  @dropTask
  *saveAndCloseTask() {
    const { saveTask, onClose } = this.args;

    yield saveTask.perform();

    this.trackTranslationEvent();

    if (typeof onClose === 'function') {
      onClose();
    }
  }

  @action
  updateTranslatable(translatable, field, value) {
    set(translatable, field, Object.assign({}, value));
  }

  trackTranslationEvent() {
    const translatable = this.filteredTranslatables.firstObject;
    if (translatable.isGlobal === false) {
      const { currentLocation, currentUser } = this.state;
      const timestamp = Math.floor(new Date().getTime() / 1000);
      const trackingEventProperties = {
        company_id: get(currentLocation, 'company.id'),
        location_id: get(currentLocation, 'id'),
        user_id: get(currentUser, 'id'),
        flow_id: translatable.id,
        translations: translatable.customTranslations,
        timestamp,
      };
      this.metrics.trackEvent('Visitor Type Name Translated', trackingEventProperties);
    }
  }
}

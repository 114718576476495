import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type PrinterConnection from 'garaje/models/printer-connection';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import { App } from 'garaje/utils/enums';
import { GLOBAL_ADMIN, ZONE_ADMIN } from 'garaje/utils/roles';
import { Permission } from 'garaje/utils/ui-permissions';

// legacy roles that can delete printer connections in Connect
const CONNECT_DELETE_ROLES = [GLOBAL_ADMIN, ZONE_ADMIN];

export default class PrinterConnectionAbility extends Ability {
  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;

  /**
   * `context` is used to tell where this ability is being used, and therefore what method should be used
   * to check permissions. For the moment, this ability is used by both Visitors and Connect; each has different
   * rules for whether a user can perform an action. (For Visitors it requires a certain Authz role at the
   * current location; for Connect, it's based on legacy role names, but even with Authz, the check will need
   * to be made at the current _zone_ and not current _location_, so it will still be different.)
   */
  declare context?: App;

  declare model: PrinterConnection;

  get canConfigureGlobal(): boolean {
    return this.authz.hasPermissionAtCurrentCompany(Permission.VISITORS_GLOBAL_PRINTER_PASSCODE_CREATE);
  }

  get canCreate(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_PRINTER_PASSCODE_CREATE);
  }

  get canDelete(): boolean {
    if (!this.model) return false;

    if (this.context === App.CONNECT) {
      return this.currentAdmin.roleNames.some((role) => CONNECT_DELETE_ROLES.includes(role));
    }

    if (this.model.global) {
      return this.authz.hasPermissionAtCurrentCompany(Permission.VISITORS_GLOBAL_PRINTER_CONNECTION_DELETE);
    }
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_PRINTER_CONNECTION_DELETE);
  }

  get canRead(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_PRINTER_CONNECTION_READ);
  }
}

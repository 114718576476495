import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class TabDataComponent extends Component {
  @tracked tabDescription;
  @tracked tabResources;

  constructor() {
    super(...arguments);
    this.setTabDescription();
    this.setTabResources();
  }

  setTabDescription() {
    this.tabDescription = this.args.tabMetadata?.description;
  }

  setTabResources() {
    this.tabResources = this.args.tabMetadata?.resources;
  }
}

import Transform from '@ember-data/serializer/transform';
import { TrackedArray } from 'tracked-built-ins';
import _identity from 'lodash/identity';

export default class TrackedArrayTransform extends Transform {
  deserialize(array, options = {}) {
    if (Array.isArray(array)) {
      const deserializeItem = options.deserializeItem || _identity;
      array = array.map((item) => deserializeItem(item));

      return new TrackedArray(array);
    } else {
      return new TrackedArray();
    }
  }

  serialize(array, options = {}) {
    if (Array.isArray(array)) {
      const serializeItem = options.serializeItem || _identity;
      array = array.map((item) => serializeItem(item));

      return Array.from(array);
    } else {
      return [];
    }
  }
}

import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import type LocationModel from 'garaje/models/location';
import type CurrentAdminService from 'garaje/services/current-admin';
import { reads } from 'macro-decorators';

interface LocationsListArgs {
  /**
   * Array of locations to show in a list
   */
  locations: Array<LocationModel>;
  /**
   * Name of list, used for testing only
   */
  name: string;
  /**
   * Whether to show title on list
   */
  showTitle: boolean;
  /**
   * Display title for list
   */
  title: string;
  /**
   * Triggers the basic dropdown to close
   */
  closeMenu: () => unknown;
  /**
   * Action to transition to location
   */
  switchLocationForMenu: () => unknown;
  currentLocation?: LocationModel;
}

export default class LocationsList extends Component<LocationsListArgs> {
  @service declare currentAdmin: CurrentAdminService;

  @reads('currentAdmin.isGlobalAdmin') isGlobalAdmin!: boolean;

  showTitle: boolean;

  constructor(owner: unknown, args: LocationsListArgs) {
    super(owner, args);

    this.showTitle = this.args.showTitle ?? true;
  }

  get locationsToShow(): Array<LocationModel> {
    return this.args.locations.filter((location: LocationModel) => this.showLocationToEmployees(location));
  }

  showLocationToEmployees(location: LocationModel): boolean {
    return isBlank(location.disabledToEmployeesAt) || this.isGlobalAdmin;
  }
}

import Controller from '@ember/controller';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import type CohoService from 'garaje/services/coho';
import { CohoWorkplaceEventNames } from 'garaje/services/coho';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import { APP } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

import type { CommunicationsNewTemplateModel } from './route';

export default class CommunicationsNewTemplateController extends Controller {
  declare model: CommunicationsNewTemplateModel;

  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;
  @service declare metrics: MetricsService;
  @service declare coho: CohoService;

  saveTask = dropTask(async () => {
    try {
      const changeset = this.model.changeset;
      await changeset.validate();

      if (changeset.isInvalid) {
        return;
      }

      await changeset.save();
      this.metrics.trackEvent('Created announcement template', { announcementTemplateId: changeset.id });
      this.coho.sendEvent(CohoWorkplaceEventNames.EMNOCreateTemplate, { product: APP.WORKPLACE });
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      void this.router.transitionTo('location-overview.communications.templates');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}

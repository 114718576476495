import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';

export default class VisitorsDevicesIndexRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  redirect(): void {
    if (this.abilities.can('visit devices')) {
      void this.router.transitionTo('visitors.devices.ipads');
    } else if (this.abilities.can('view-nav-item printers')) {
      void this.router.transitionTo('visitors.devices.printers');
    } else {
      void this.router.transitionTo('unauthorized');
    }
  }
}

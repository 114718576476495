import ApplicationSerializer from 'garaje/serializers/application';

export default class ConnectFloorSerializer extends ApplicationSerializer {
  attrs = {
    position: {
      serialize: false,
    },
  };
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'connect-floor': ConnectFloorSerializer;
  }
}

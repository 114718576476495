import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { IMPRESSION_NAMES, PLAN_LEVEL } from 'garaje/utils/enums';
import { task } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { capitalize } from '@ember/string';
import { pluralize } from 'ember-inflector';

const NUM_STEPS = 2;

export default class VisitorsLocationGatingModal extends Component {
  @service abilities;
  @service impressions;
  @service metrics;
  @service('skinnyLocations') skinnylocationsService;
  @service state;
  @service router;

  @tracked locationsToDeactivateCount;
  @tracked closed = false;
  @tracked step = 1;

  get contentComponent() {
    if (this.abilities.cannot('visit billing')) {
      return 'visitors/location-gating-modal/non-billing-admin';
    }

    return `visitors/location-gating-modal/billing-admin/step-${this.step}`;
  }

  get ctaEventProperties() {
    return {
      cta_id: this.ctaId || 'location-gating-modal',
      cta_type: 'modal',
      growth_team_project: true,
    };
  }

  get ctaId() {
    if (this.abilities.cannot('visit billing')) {
      return 'location-gating-modal_non-billing-admin';
    }

    return `location-gating-modal_step-${this.step}`;
  }

  get activeLocations() {
    return this.skinnylocationsService.active ?? [];
  }

  get shouldDisplay() {
    // don't display modal if subscription doesn't exist or if the modal is set to closed
    return this.subscription && !this.closed;
  }

  get isCompliant() {
    // the "cancelled" case is to prevent the modal from showing up on the trial ended page
    if (this.subscription == null || this.activeLocations == null || this.subscription.cancelled) return true;

    if (this.args.isUpgrading) return this.locationsToDeactivateCount === 0;

    return !this.shouldGateLocations();
  }

  shouldGateLocations() {
    // if the subscription is not cancelled, then we will compute this later on
    this.locationsToDeactivateCount = this.subscription.cancelled ? this.activeLocations.length - 1 : null;

    // gate locations if the user has active location on cancelled subscription
    if (this.subscription.cancelled) {
      if (this.subscription.isBasicPlan && this.activeLocations.length <= 1) return false;
      return this.activeLocations.length > 0;
    }

    // gate locations if active locations exceeds subscription qty
    return this.activeLocations.length > this.subscription.quantity;
  }

  get subscription() {
    return this.state.vrSubscription;
  }

  get billingRoute() {
    if (
      this.subscription.plan === PLAN_LEVEL.STANDARD ||
      this.subscription.plan === PLAN_LEVEL.STANDARD + '-1' ||
      this.subscription.plan === PLAN_LEVEL.PREMIUM
    ) {
      return 'billing.index.add-quantity';
    }
    return 'billing.product-plans';
  }

  get quantityDetails() {
    if (this.subscription && this.activeLocations != null) {
      const unitType = `${capitalize(pluralize(this.subscription.unitType))}: `;
      const paidStatus = this.subscription.cancelled ? 'unpaid' : this.subscription.isBasicPlan ? 'free' : 'paid';
      const paidQuantity = `${this.subscription.quantity || '0'} ${paidStatus}, `;
      const activeQuantity = `${this.activeLocations.length} active.`;

      return `${unitType}${paidQuantity}${activeQuantity}`;
    } else {
      return null;
    }
  }

  get impressionType() {
    if (this.args.isUpgrading) {
      if (this.step <= 1) return IMPRESSION_NAMES.LOCATION_GATING_MODAL_SHOWN_ON_UPGRADE;
      else return IMPRESSION_NAMES.LOCATION_GATING_MODAL_DEACTIVATE_LOCATIONS_SHOWN_ON_UPGRADE;
    } else {
      if (this.step <= 1) return IMPRESSION_NAMES.LOCATION_GATING_MODAL_SHOWN;
      else return IMPRESSION_NAMES.LOCATION_GATING_MODAL_DEACTIVATE_LOCATIONS_SHOWN;
    }
  }

  get canDismiss() {
    return this.args.isUpgrading;
  }

  @action
  setValues() {
    if (this.abilities.cannot('visit billing')) {
      this.step = 0;
    } else {
      if (this.args.isUpgrading) this.locationsToDeactivateCount = this.args.locationsToDeactivateCount;
    }
  }

  @action
  moveToNextStep() {
    this.step = Math.min(this.step + 1, NUM_STEPS);
  }

  @action
  moveToPreviousStep() {
    this.step = Math.max(this.step - 1, 1);
  }

  @action
  close() {
    this.closed = true;
    if (this.args.onClose) {
      this.args.onClose();
    }
  }

  @action
  redirectOnClose() {
    this.close();
    this.router.transitionTo('protected');
  }

  @action
  logCTAClicked(event) {
    const buttonText = event.target.textContent.trim();
    this.metrics.trackEvent('CTA Clicked', {
      ...this.ctaEventProperties,
      cta_clickable_type: 'button',
      cta_clickable_text: buttonText,
    });

    return event;
  }

  logCTAViewed = task({ drop: true }, async () => {
    try {
      this.metrics.trackEvent('CTA Viewed', this.ctaEventProperties);

      const contentType = this.step === 0 ? 'non-admin' : this.args.plan;

      await this.impressions.postImpression.perform(this.impressionType[contentType.toUpperCase()]);
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
    }
  });

  logGetMoreLocations = task({ drop: true }, async () => {
    try {
      await this.impressions.postImpression.perform(
        IMPRESSION_NAMES.LOCATION_GATING_MODAL_GET_MORE_LOCATIONS_CLICKED[this.args.plan.toUpperCase()],
      );
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
    }
  });
}

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 * @param {Array}                             changesetFields
 * @param {Array}                             signInFields
 * @param {Changeset<SignInField>}            field
 * @param {Boolean}                           isRTL
 * @param {Boolean}                           isDisabled
 * @param {Function}                          deleteFieldAction
 * @param {Function}                          toggleRecalculateContainer
 * @param {Function}                          getDefaultStoreResponse
 * @param {Boolean}                           embedded
 * @param {Object}                            handleItem
 */
export default class extends Component {
  @service state;

  get isConditionalParent() {
    if (this.args.embedded) {
      return true;
    }

    const { vrSubscription } = this.state;

    if (!vrSubscription) {
      return false;
    }

    return vrSubscription.canAccessConditionalSignInFields;
  }
}

import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { dropTask } from 'ember-concurrency';

export default class IntegrationsEnabledController extends Controller {
  @service currentLocation;
  @service flashMessages;
  @service integrationsManager;
  @service state;

  queryParams = ['integration', 'scrollTo'];

  @tracked integration = '';
  @tracked scrollTo = null; // null or a dom id, used to scroll to an element when the page loads

  get activeInstalls() {
    return get(this.model, 'pluginInstalls').filter(function (install) {
      return get(install, 'status') !== 'uninstalled';
    });
  }

  @action
  integrationDisconnected(reloadCompany = false) {
    const { currentLocation } = this.state;
    const hasCore = this.integrationsManager.getIntegrations(currentLocation).length;
    const hasPlatform = this.activeInstalls.length > 0;

    if (hasCore || hasPlatform) {
      window.scrollTo(0, 0);
    } else {
      this.transitionToRoute('integrations.index'); // eslint-disable-line ember/no-deprecated-router-transition-methods
    }

    if (reloadCompany) {
      get(currentLocation, 'company.content').reload();
    }
  }

  @dropTask
  *saveDirectoryProvider(model) {
    try {
      yield model.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.transitionToRoute('employees.sync-settings'); // eslint-disable-line ember/no-deprecated-router-transition-methods
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @action
  setIntegration(integration) {
    this.integration = integration;
  }

  @action
  clearIntegration() {
    this.integration = '';
  }
}

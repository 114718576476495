import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import { or, reads } from 'macro-decorators';
import moment from 'moment-timezone';

const DEFAULT_PAGE_SIZE = 30;
const DEFAULT_DATE_RANGE = 'Today';

export default class LocationOverviewVisitorLogController extends Controller {
  @service declare skinnyLocations: SkinnyLocationsService;

  queryParams = [
    'endDate',
    'locationIds',
    'pageNumber',
    'pageSize',
    'query',
    'signedOut',
    'sortBy',
    'sortDirection',
    'startDate',
    'selectedDateRange',
  ];

  pageNumber = 1;
  pageSize = DEFAULT_PAGE_SIZE;
  defaultRecordCount = 0;
  locationIds = '';
  @tracked query = '';

  @tracked selectedDateRange = DEFAULT_DATE_RANGE;
  @tracked loadingData = false;
  @tracked startDate = moment().startOf('day').format();
  @tracked endDate = moment().endOf('day').format();

  @reads('model.entries.meta.total') metaRecordCount!: number;
  @or('metaRecordCount', 'defaultRecordCount') recordCount!: number;

  @action
  setDateRange(option: string, start: string, end: string): void {
    this.startDate = start;
    this.endDate = end;
    this.selectedDateRange = option;
  }
}

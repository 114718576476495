import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type PropertyPrinterModel from 'garaje/models/property-printer';
import type ZoneModel from 'garaje/models/zone';

import type { PropertyDevicesRouteModel } from '../../route';

export interface DevicesPrintersNewRouteModel {
  hasBluetoothPrinter: boolean;
  printer: PropertyPrinterModel | null;
  printers: PropertyDevicesRouteModel['printers'];
  property: ZoneModel;
}

export default class DevicesPrintersNewRoute extends Route {
  @service declare router: RouterService;
  @service declare store: Store;

  model(): DevicesPrintersNewRouteModel {
    const printers = (<PropertyDevicesRouteModel>this.modelFor('property.devices')).printers;
    const property = <ZoneModel>this.modelFor('property');
    const hasBluetoothPrinter = printers.any((printer) => printer.connectionType === 'bluetooth');
    const printer = hasBluetoothPrinter
      ? null
      : this.store.createRecord('property-printer', {
          property,
        });

    return {
      hasBluetoothPrinter,
      printer,
      printers,
      property,
    };
  }

  redirect({ hasBluetoothPrinter }: DevicesPrintersNewRouteModel): void {
    if (hasBluetoothPrinter) {
      // do not allow user to add multiple printers if they already have a Bluetooth printer
      void this.router.transitionTo('property.devices.printers.index');
    }
  }
}

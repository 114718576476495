import type TenantConnectionRequestModel from 'garaje/models/tenant-connection-request';

import { BaseLoginModalDefinition } from './base-login-modal-definition';
import type { OpenableModal } from './base-login-modal-definition';

export interface ConnectionRequestConfigModalArgs {
  tenantConnectionRequest: TenantConnectionRequestModel;
}

export class ConnectionRequestConfigModalDefinition
  extends BaseLoginModalDefinition<ConnectionRequestConfigModalArgs>
  implements OpenableModal
{
  static priority = 1;
  static identifier = 'connection-request-config';

  get active(): boolean {
    const { tenantConnectionRequest, isModalDismissed } = this.configuration;
    return !!(tenantConnectionRequest && !isModalDismissed) || !!tenantConnectionRequest?.pendingLocation;
  }
}

import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import { GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

export default class ChangelogAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;

  get canVisit(): boolean {
    if (this.featureFlags.isEnabled('audit-log')) return false;

    const { roleNames: roles } = this.currentAdmin;
    const allowedRoles = [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST];

    return isPresent(_intersection(allowedRoles, roles));
  }
}

import { get } from '@ember/object';
import { service } from '@ember/service';
import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import { apiAction } from '@mainmatter/ember-api-actions';
import type { Identifier } from 'garaje/models/user-document-template';
import type UserDocumentTemplateModel from 'garaje/models/user-document-template';
import type UserDocumentTemplateConfigurableModel from 'garaje/models/user-document-template-configurable';
import type FeatureFlagsService from 'garaje/services/feature-flags';

class UserDocumentTemplateConfigurationModel extends Model {
  @service declare featureFlags: FeatureFlagsService;

  @belongsTo('user-document-template') declare userDocumentTemplate: AsyncBelongsTo<UserDocumentTemplateModel>;

  @attr('boolean', { defaultValue: false }) declare active: boolean;
  @attr('boolean') declare optional: boolean;

  // These fields are only used on CREATE. This relationship is polymorphic
  @attr('string') declare userDocumentTemplateConfigurableType: string;
  @attr('number') declare userDocumentTemplateConfigurableId: number;

  // polymorphic link
  @belongsTo('user-document-template-configurable', { polymorphic: true })
  declare userDocumentTemplateConfigurable: AsyncBelongsTo<UserDocumentTemplateConfigurableModel>;

  @attr('number') declare issueDateValidityOffsetInDays: number;
  @attr('boolean', { defaultValue: false }) declare hideIfVaccinated: boolean;

  get identifier(): Identifier {
    // eslint-disable-next-line ember/no-get
    return <Identifier>get(this, 'userDocumentTemplate.identifier');
  }

  get defaultStatus(): string {
    return 'not_uploaded';
  }

  get defaultStatusText(): string {
    return 'Not submitted';
  }

  async denyAll(options: { 'end-date': string }): Promise<unknown> {
    return apiAction(this, { method: 'POST', path: 'deny_all', data: options });
  }
}

export default UserDocumentTemplateConfigurationModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-document-template-configuration': UserDocumentTemplateConfigurationModel;
  }
}

import { tracked } from '@glimmer/tracking';

type ParseOptions = {
  midnightIsEndOfDay?: boolean;
};

/**
 * This class represents a time (hour & minute only). It's intended as a "value type" for a time,
 * when you may be operating to/from strings but want to query/manipulate times without having
 * to perform string manipulation every time.
 */
export default class SimpleTime {
  @tracked declare hour: number;
  @tracked declare minute: number;

  constructor(hour: number, minute: number) {
    this.#validateTime(hour, minute);
    this.hour = hour;
    this.minute = minute;
  }

  static readonly StartOfDayMidnight = new SimpleTime(0, 0);
  static readonly EndOfDayMidnight = new SimpleTime(24, 0);

  /**
   * Return a new SimpleTime instance representing the result of adding
   * the given number of hours to the given time.
   */
  static addHours(time: SimpleTime, amount: number): SimpleTime {
    const newHour = time.hour + amount;
    // clamp to midnight
    if (newHour < 0) {
      return SimpleTime.clone(SimpleTime.StartOfDayMidnight);
    } else if (newHour > 23) {
      return SimpleTime.clone(SimpleTime.EndOfDayMidnight);
    }
    return new SimpleTime(newHour, time.minute);
  }

  /**
   * Make a copy of the given time.
   */
  static clone(time: SimpleTime): SimpleTime {
    return new SimpleTime(time.hour, time.minute);
  }

  /**
   * Parse a string representing a 24-hour time (e.g. "10:45 pm") into a SimpleTime instance.
   */
  static parse12Hour(value: string, options: ParseOptions = {}): SimpleTime {
    const [time, amPm] = value.split(' ');
    const [hour, minute] = time!.split(':');

    const isPm = amPm!.toLowerCase() === 'pm';

    let actualHour = parseInt(hour!, 10);
    if (actualHour === 12 && !isPm) {
      actualHour = 0;
    } else if (actualHour !== 12 && isPm) {
      actualHour = actualHour + 12;
    }

    if (options.midnightIsEndOfDay && actualHour === 0 && minute === '00') {
      return SimpleTime.clone(SimpleTime.EndOfDayMidnight);
    }

    return new SimpleTime(actualHour, parseInt(minute!));
  }

  /**
   * Parse a string representing a 24-hour time (e.g. "23:55") into a SimpleTime instance.
   */
  static parse24Hour(value: string, options: ParseOptions = {}): SimpleTime {
    const [hour, minute] = value.split(':');
    if (!hour || !minute) {
      throw new RangeError(`You tried to parse "${value}" as a time but it wasn't in HH:MM format`);
    }
    if (options.midnightIsEndOfDay && hour === '00' && minute === '00') {
      return SimpleTime.clone(SimpleTime.EndOfDayMidnight);
    }
    return new SimpleTime(parseInt(hour, 10), parseInt(minute, 10));
  }

  /**
   * Return the total number of minutes (from midnight) for this time.
   */
  asMinutes(): number {
    return this.hour * 60 + this.minute;
  }

  /**
   * Make a copy of this time.
   */
  clone(): SimpleTime {
    return new SimpleTime(this.hour, this.minute);
  }

  /* Comparison operations */

  /**
   * Check whether the current time is later than the specified time.
   */
  isAfter(otherTime: SimpleTime): boolean {
    if (this.hour > otherTime.hour) return true;
    if (this.hour < otherTime.hour) return false;
    return this.minute > otherTime.minute;
  }

  /**
   * Check whether the current time is earlier than the specified time.
   */
  isBefore(otherTime: SimpleTime): boolean {
    if (this.hour < otherTime.hour) return true;
    if (this.hour > otherTime.hour) return false;
    return this.minute < otherTime.minute;
  }

  /**
   * Check whether the current time is different than specified time.
   */
  isNot(otherTime: SimpleTime): boolean {
    return !this.isSame(otherTime);
  }

  /**
   * Check whether the current time is the same as the specified time.
   */
  isSame(otherTime: SimpleTime): boolean {
    return this.hour === otherTime.hour && this.minute === otherTime.minute;
  }

  /**
   * Return a string representation of the current time, as a 24-hour time.
   */
  toString(): string {
    // when converting a string, both midnights are converted to "00:00"
    const hour = this.hour !== 24 ? this.hour : 0;
    return `${hour.toString().padStart(2, '0')}:${this.minute.toString().padStart(2, '0')}`;
  }

  #validateTime(hour: number, minute: number) {
    if (hour < 0 || hour > 24) {
      throw new RangeError(`Invalid time value: hour must be between 0 and 24, you passed ${hour}`);
    }
    if (hour === 24 && minute !== 0) {
      throw new RangeError(`Invalid time value: when hour is 24, minute must be 0, and you passed ${minute}`);
    }
    if (minute < 0 || minute >= 60) {
      throw new RangeError(`Invalid time value: minute must be between 0 and 59, you passed ${minute}`);
    }
  }
}

import JSONAPISerializer from 'ember-data/serializers/json-api';

export default class extends JSONAPISerializer {
  modelNameFromPayloadKey(payloadKey) {
    if (payloadKey === 'devices') {
      return 'room-device';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }
}

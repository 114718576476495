import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import urlBuilder from 'garaje/utils/url-builder';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AnalyticsController extends Controller {
  @service state;
  @service messageBus;
  @service flashMessages;
  @service skinnyLocations;

  @tracked showModal = false;
  @tracked showDeleteModal = false;
  @tracked modalIframeUrl;
  @tracked deleteIframeUrl;

  constructor() {
    super(...arguments);
    this.messageBus.on('embedded-app-message', this.handleMessage);
  }
  /**
   * @return {String}
   */
  get iframeUrl() {
    const {
      currentCompany: { id: companyId },
    } = this.state;
    const locationIds = this.skinnyLocations.currentCompanyLocations.map((it) => it.id).join(',');
    return urlBuilder.embeddedReportListGlobalAnalyticsUrl(companyId, locationIds);
  }

  @action
  toggleShowModal() {
    this.showModal = !this.showModal;
  }
  @action
  toggleShowDeleteModal() {
    this.showDeleteModal = !this.showDeleteModal;
  }
  @action
  handleMessage(message) {
    const locationId = message?.query?.location_id;
    const companyId = message?.query?.company_id;
    const modalId = message?.query?.modal_id;
    const reportId = message?.query?.report_id;
    const flowId = null;
    const closeAll = message?.closeAll;
    const start = message.query?.start;
    const end = message.query?.end;
    if (message.event === 'showScheduleReportsModal') {
      this.modalIframeUrl = urlBuilder.embeddedScheduleReportsModalUrl(companyId, locationId, flowId, start, end);
      this.toggleShowModal();
    } else if (message.event === 'showEditModal') {
      this.modalIframeUrl = urlBuilder.embeddedEditScheduleReportsModalUrl(companyId, locationId, modalId);
      this.toggleShowModal();
    } else if (message.event === 'showDeleteModal') {
      this.deleteIframeUrl = urlBuilder.embeddedDeleteScheduleReportsModalUrl(reportId, companyId);
      this.toggleShowDeleteModal();
    } else if (message.event === 'closeScheduleReportsModal') {
      this.toggleShowModal();
    } else if (message.event === 'closeDeleteModal') {
      this.toggleShowDeleteModal();
      if (closeAll) this.toggleShowModal();
    } else if (message.event === 'showToaster') {
      const type = message.type;
      const msg = message.msg;
      this.flashMessages.showAndHideFlash(type, msg);
    }
  }
}

import ApplicationSerializer from './application';

export default class GlobalSummaryPageSerializer extends ApplicationSerializer {
  attrs = {
    defaultMessage: {
      serialize: false,
    },
  };

  modelNameFromPayloadKey(key) {
    if (key === 'flows') {
      return 'global-flow';
    } else if (key === 'summary-pages') {
      return 'global-summary-page';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }

  payloadKeyFromModelName(modelName) {
    if (modelName === 'global-flow') {
      return 'flows';
    } else if (modelName === 'global-summary-page') {
      return 'summary-pages';
    } else {
      return super.payloadKeyFromModelName(...arguments);
    }
  }
}

import { belongsTo, hasMany } from '@ember-data/model';
import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import { isValid, format, parse } from 'date-fns';
import AbstractDocumentModel, { DATE_FORMAT } from 'garaje/models/abstract/abstract-document';
import type UserModel from 'garaje/models/user';
import type UserDocumentLocationContextModel from 'garaje/models/user-document-location-context';

export default class UserDocumentModel extends AbstractDocumentModel {
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel> | UserModel;

  @hasMany('user-document-location-context', { async: false })
  declare userDocumentLocationContexts: AsyncHasMany<UserDocumentLocationContextModel>;

  get userDocumentLocationContext(): UserDocumentLocationContextModel | undefined {
    return this.userDocumentLocationContexts.findBy(
      'location.id' as keyof UserDocumentLocationContextModel,
      this.state.currentLocation?.id,
    );
  }

  get isExpired(): boolean {
    return this.userDocumentLocationContext?.expired || false;
  }

  get expirationDate(): string | undefined {
    const { userDocumentLocationContext } = this;

    if (userDocumentLocationContext) {
      return userDocumentLocationContext.expirationDate;
    }

    return undefined;
  }

  get parsedExpirationDate(): Date | undefined {
    const { expirationDate } = this;
    if (!expirationDate) {
      return undefined;
    }

    const parsedExpirationDate = parse(expirationDate, DATE_FORMAT, new Date());

    return isValid(parsedExpirationDate) ? parsedExpirationDate : undefined;
  }

  get isExpiringSoon(): boolean {
    const { parsedExpirationDate, isExpired } = this;

    if (isExpired || !parsedExpirationDate) {
      return false;
    }

    const today = format(new Date(), DATE_FORMAT);
    const expires = format(parsedExpirationDate, DATE_FORMAT);

    return expires === today;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-document': UserDocumentModel;
  }
}

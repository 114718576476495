import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { GetRoomsQuery } from 'garaje/graphql/generated/roomba-types';
import type RoomsPollingService from 'garaje/services/rooms-polling';

interface DevicePairingStatusArgs {
  room: GetRoomsQuery['rooms'][number];
}
export default class DevicePairingStatus extends Component<DevicePairingStatusArgs> {
  @service declare roomsPolling: RoomsPollingService;

  @tracked shouldShowPairingCodeInput = false;
  @tracked inputValue = '';

  @action
  showPairingCodeInput(): void {
    this.shouldShowPairingCodeInput = true;
  }
}

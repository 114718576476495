import { helper } from '@ember/component/helper';
import { format as _format, parse } from 'date-fns';
import moment from 'moment-timezone';

/**
  Return the formatted date string in the given format.
  https://date-fns.org/v2.25.0/docs/format
*/
type NamedArgs = { parseFormat?: string };
type PositionalArgs = [date: Date | string | moment.Moment, format: string, options?: object];
export function dateFormat([date, format, options = {}]: PositionalArgs, { parseFormat }: NamedArgs = {}): string {
  let _date: Date;

  // TODO: remove this if we don't use moment anymore
  if (moment.isMoment(date)) {
    _date = date.toDate();
  } else if (typeof date === 'string') {
    _date = typeof parseFormat === 'string' ? parse(date, parseFormat, new Date(), options) : new Date(date);
  } else {
    _date = date ?? new Date();
  }

  return _format(_date, format, options);
}

export default helper(dateFormat);

import Component from '@glimmer/component';
import { A } from '@ember/array';
import { service } from '@ember/service';

export default class EmployeesConfigOptions extends Component {
  @service abilities;
  @service featureFlags;
  @service router;

  get reviewableDocumentLinks() {
    const { userDocumentLinks = A() } = this.args;
    return userDocumentLinks.filterBy('approvalStatus', 'review');
  }

  get activeDocumentConfigurations() {
    const { userDocumentTemplateConfigurations = A() } = this.args;
    return userDocumentTemplateConfigurations.filterBy('active');
  }

  get syncSettingsRoute() {
    const scimSettingsRoute = `${this.isGlobalOverview ? 'location-overview.' : ''}employees.scim-settings`;
    return this.featureFlags.isEnabled('appStore') ? scimSettingsRoute : 'visitors.settings.integrations.enabled';
  }

  get isGlobalOverview() {
    return this.router.currentRouteName?.includes('location-overview');
  }

  get activeUserDocumentTemplates() {
    return this.activeDocumentConfigurations.mapBy('userDocumentTemplate').uniqBy('identifier');
  }

  get reviewableUserDocumentTemplates() {
    return this.reviewableDocumentLinks.mapBy('userDocument.userDocumentTemplate').uniqBy('identifier');
  }

  get canViewMoreFilters() {
    return this.args.hasManuallyAddedFilter || this.canViewDocumentStatusFilter;
  }

  get canViewDocumentStatusFilter() {
    return (
      this.activeDocumentConfigurations?.length > 0 &&
      !this.isGlobalOverview &&
      this.abilities.can('review user-document-links')
    );
  }
}

import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { inject as service } from '@ember/service';
import { filterBy } from 'macro-decorators';
import { defer } from 'rsvp';

/**
 * @param {Class<Floor>}    floor            Required. Floor model
 * @param {Function}        handleClose      Required. Callback on close
 */
export default class FloorPlanMap extends Component {
  @service flashMessages;

  /**
   * @returns {Array<Desk>} All desks that do not have a position
   */
  @filterBy('args.floor.desks', 'placed', false) toPlaceDesks;

  @dropTask
  showRemoveMapConfirmationModal = {
    *perform() {
      const deferred = defer();
      this.abort = () => deferred.resolve(false);
      this.continue = async () => {
        await this.context.deleteMapTask.perform();
        deferred.resolve(true);
      };

      return yield deferred.promise;
    },
  };

  @dropTask
  *deleteMapTask() {
    try {
      yield this.args.floor.deleteFloorPlan();
      yield this.args.floor.reload();
      this.flashMessages.showAndHideFlash('success', 'Deleted!');
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showFlash('error', errorText);
    }
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { action, set } from '@ember/object';
import { underscore } from '@ember/string';
import { isPresent, isEmpty } from '@ember/utils';
import eventsLogger from 'garaje/utils/decorators/events-logger';
import { scheduleOnce } from '@ember/runloop';

/**
 * @param {Model<MapFeature>}           feature
 * @param {Function}                    removeFeature
 * @param {Function}                    back
 * @param {Object}                      availableResources
 * @param {boolean}                     isDraftMap
 */
@eventsLogger
class DeliveryAreaForm extends Component {
  @service store;

  @tracked validations = {};

  get selectedDeliveryArea() {
    const { feature } = this.args;

    return feature.externalId && this.store.peekRecord('delivery-area', feature.externalId);
  }

  get availableDeliveryAreas() {
    const { availableResources } = this.args;

    return availableResources.deliveryAreas;
  }

  @action
  onInsert() {
    scheduleOnce('afterRender', this, 'setDefaultValues');
  }

  @action
  onUpdate() {
    scheduleOnce('afterRender', this, 'setDefaultValues');
  }

  setDefaultValues() {
    const { feature } = this.args;

    if (feature.isNew && isPresent(this.availableDeliveryAreas) && isEmpty(feature.externalId)) {
      const deliveryArea = this.availableDeliveryAreas.firstObject;
      this.onDeliveryAreaSelect(deliveryArea);
    }
  }

  @action
  toggleEnabled(state) {
    const { isDraftMap, feature } = this.args;
    set(feature, 'enabled', state);

    if (state) {
      this.logEnabled({
        resource_type: underscore(feature.type),
        isDraft: isDraftMap,
      });
    } else {
      this.logDisabled({
        resource_type: underscore(feature.type),
        isDraft: isDraftMap,
      });
    }
  }

  @action
  onDeliveryAreaSelect(deliveryArea) {
    const { feature } = this.args;

    set(feature, 'name', deliveryArea.name);
    set(feature, 'externalId', deliveryArea.id);

    this.validate();
  }

  @action
  validate() {
    const { feature } = this.args;

    if (!feature.externalId) {
      set(feature, 'hasError', true);
      set(this.validations, 'deliveryArea', 'Please select a delivery area');
    } else {
      set(this.validations, 'deliveryArea', null);
      set(feature, 'hasError', false);
    }
  }
}

export default DeliveryAreaForm;

import { isBlank } from '@ember/utils';

const defaults = { optionsProp: 'options', searchProp: 'searchFilter' };

/**
 * Returns a decorator that returns a descriptor with a filter getter
 @ @param {optionsProp?: string; searchProp?: string}
 *
 * @return {Descriptor}
 */

export function filterOptions(opts) {
  const { optionsProp, searchProp } = { ...defaults, ...opts };

  return function () {
    // NOTE return a descriptor with a getter to handle the filtering
    return {
      get() {
        if (isBlank(this[searchProp])) {
          return this[optionsProp];
        }

        const isFiltered = (option) => option.name.toLowerCase().includes(this[searchProp].toLowerCase());

        return this[optionsProp].reduce((opts, option) => {
          // NOTE if isParent = true, we want to filter the children by searchFilter
          if (option.isParent) {
            // NOTE if searchFilter matches group name, return group with ALL children
            if (isFiltered(option)) {
              opts.push(option);
            } else {
              // else return group if any matching children with just those that match
              const filtered = option.children.filter(isFiltered);

              if (filtered.length > 0) {
                opts.push({ ...option, children: filtered });
              }
            }
          } else {
            if (isFiltered(option)) {
              opts.push(option);
            }
          }

          return opts;
        }, []);
      },
    };
  };
}

import Transform from '@ember-data/serializer/transform';

class Currency extends Transform {
  deserialize(serialized: number): number {
    return serialized / 100;
  }

  serialize(deserialized: number): number {
    return deserialized * 100;
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    currency: Currency;
  }
}

export default Currency;

import { ApolloLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { service } from '@ember/service';
import ApolloBaseService from 'ember-apollo-client/services/apollo';
import type cookies from 'ember-simple-auth/session-stores/cookies';
import type MetricsService from 'garaje/services/metrics';

class ApolloService extends ApolloBaseService {
  @service declare cookies: cookies;
  @service declare metrics: MetricsService;

  clientOptions(): object {
    const errorLink = this.errorLink;
    const csrfLink = this.csrfLink();
    const httpLink = this.httpLink();
    // Ensure all links are defined before composing
    const links = [errorLink, csrfLink, httpLink];

    return {
      link: ApolloLink.from(links),
      cache: this.cache(),
    };
  }

  get errorLink(): ApolloLink {
    return onError(({ graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
          // Send error details to Datadog
          this.metrics.logMonitorEvent('DASHBOARD_GQL_ERROR', { message });
        });
      }
    });
  }

  csrfLink(): ApolloLink {
    return setContext(() => ({
      headers: {
        'X-CSRF-Token': this.cookies.read('csrf_token'),
      },
    }));
  }

  httpLink(): ApolloLink {
    return super.link();
  }
}

export default ApolloService;

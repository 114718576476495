// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { set } from '@ember/object';
import { isBlank } from '@ember/utils';

/**
 * @param {Object}            parent
 */
export default class TokenizedButton extends Component {
  classNameBindings = ['noMargin:mr-0:mr-2', ':flex-none', ':relative'];

  // eslint-disable-next-line ember/classic-decorator-hooks
  init() {
    super.init(...arguments);
    // set parent view
    if (!isBlank(this.parent)) {
      set(this, 'target', this.parent);
    }
  }
}

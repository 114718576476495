// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { sort } from '@ember/object/computed';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import Service, { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency';
import type BannerModel from 'garaje/models/banner';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type IsOpenService from 'garaje/services/is-open';
import type StateService from 'garaje/services/state';
import { TrackedObject } from 'tracked-built-ins';

// these are routes that we shouldn't even try loading growth  banners on; it's
// meant for transitional routes only - the banner backend should be responsible
// for returning no banners for routes that shouldn't display them.
const GROWTH_BANNERS_IGNORED_ROUTES = ['error', 'loading'];

// Ugly way to filter out banners which should be showed ONLY for basic subscriptions
const BASIC_ONLY_BANNERS = ['growth/visitors-basic-to-premium-trial'];

interface Banner {
  name: string;
  weight: number;
}

export default class BannerManagerService extends Service {
  @service declare featureFlags: FeatureFlagsService;
  @service declare isOpen: IsOpenService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: StoreService;

  sortOrder = ['weight'];
  @sort('banners', 'sortOrder') sorted!: Banner[];

  @tracked growthBanner: BannerModel | null = null;
  @tracked banners: Banner[] = [];
  _meta: Record<string, Record<string, unknown>>;

  constructor(properties: Record<string, unknown>) {
    super(properties);
    this._meta = new TrackedObject({});
    void this.loadGrowthBanner.perform();
    this.router.on('routeDidChange', (transition) => this.handleRouteChange(transition));
  }

  get metaFor(): Record<string, Record<string, unknown>> {
    return this._meta;
  }

  // banners with less weight will appear first
  add(name: string, weight = Infinity): void {
    const banner = this.banners.find((b) => b.name === name);

    if (!banner) {
      this.banners = [...this.banners, { name, weight }];
    }
  }

  addMetaFor(name: string, meta: Record<string, unknown>): void {
    const values = { ...this.metaFor[name], ...meta };
    this.metaFor[name] = values;
  }

  remove(name: string): void {
    this.banners = this.banners.filter((b) => b.name !== name);

    delete this.metaFor[name];
  }

  handleRouteChange(transition: Transition): void {
    const hideBanners = (<Record<string, boolean>>transition.to.metadata)?.['hideBanners'];
    this.banners.forEach(({ name }) => {
      this.addMetaFor(name, { hidden: hideBanners });
    });

    if (hideBanners) {
      this.growthBanner = null;
    } else {
      void this.loadGrowthBanner.perform();
    }
  }

  loadGrowthBanner = restartableTask(async () => {
    if (!this.featureFlags.isEnabled('growthBanners')) return;

    const route = this.router.currentRouteName;
    if (GROWTH_BANNERS_IGNORED_ROUTES.includes(route)) return;

    // this check (whether banners should be fetched based on the current
    // Visitors subscription plan) is meant to be temporary and should be removed
    // once the backend supports subscription-based targeting.
    if (!this._shouldFetchBannersBasedOnSubscription()) {
      this.growthBanner = null;
      return;
    }

    // this check (whether banners should be fetched based on the current route)
    // is meant to be temporary and should be removed once the banner backend
    // supports route-based targeting. The difference between this check and
    // the above check for inclusion in GROWTH_BANNERS_IGNORED_ROUTES is that
    // this removes any existing banner, while the other check (which is meant
    // to prevent trying to fetch a banner on a transient route) does not, to
    // prevent banners from disappearing and then reappearing (as they're loaded
    // anew) on page transitions.
    if (!this._shouldFetchBannersOnCurrentRoute()) {
      this.growthBanner = null;
      return;
    }

    try {
      let growthBanners: BannerModel[] = (
        await this.store.query('banner', {
          filter: {
            route: this.router.currentRouteName,
            location_id: this.state.currentLocation.id,
          },
        })
      ).toArray();

      const currentPlan = this.state.visitorsSubscription?.normalizedPlanName;

      if (currentPlan === 'standard') {
        growthBanners = growthBanners.filter((banner) => !BASIC_ONLY_BANNERS.includes(banner.componentName));
      }

      this.growthBanner = growthBanners.length ? growthBanners[0]! : null;
    } catch {
      // hide any current banner if request fails
      this.growthBanner = null;
    }
  });

  /*
   * At the moment the banner backend does not support targeting by route.
   * Since initially growth banners are only being shown on the Dashboard index
   * and visitors pages, until the backend adds targeting-by-route support we
   * have to constrain it here.
   */
  _shouldFetchBannersOnCurrentRoute(): boolean {
    return this.router.isActive('dashboard') || this.isOpen.isVizRegOpen;
  }

  /*
   * At the moment the banner backend does not support targeting by subscription.
   * Since initially, growth banners are only being targeted to users on Basic or
   * Standard plans for Visitors, until the backend adds targeting-by-subscription
   * support we need to constrain it here.
   */
  _shouldFetchBannersBasedOnSubscription(): boolean {
    const currentPlan = this.state.visitorsSubscription?.normalizedPlanName;
    if (!currentPlan || currentPlan === 'premium' || currentPlan === 'enterprise') {
      return false;
    }
    return true;
  }
}

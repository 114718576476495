import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';

class BillingChangePlanRoute extends Route {
  @service state;
  @service router;
  @service store;
  @service flashMessages;
  @service impressions;

  beforeModel() {
    const { paymentSource } = this.modelFor('billing');
    const { vrSubscription } = this.state;
    if (paymentSource.isACH) {
      return this.router.transitionTo('billing');
    }

    if (vrSubscription && vrSubscription.subscribed) {
      return this.router.transitionTo('billing.plans');
    }

    this.logBillingPlansPageViewed.perform();
  }

  model({ plan }) {
    return hash({
      highlightedPlan: this.store.findRecord('plan', plan),
    });
  }

  logBillingPlansPageViewed = task({ drop: true }, async () => {
    try {
      await this.impressions.postImpression.perform(IMPRESSION_NAMES.BILLING_CHANGE_PLANS_PAGE_VIEWED);
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', e);
    }
  });
}

export default BillingChangePlanRoute;

/* eslint-disable ember/no-computed-properties-in-native-classes */
import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

import type DropdownOption from '../dropdown-option';

import type AbstractSignInField from './abstract-sign-in-field';
import type { RuleOperation } from './abstract-sign-in-field-action-rule-group';

export default class AbstractSignInFieldActionModel extends Model {
  declare signInField: AsyncBelongsTo<AbstractSignInField>;

  @tracked declare _dropdownOption: DropdownOption | undefined;

  /**
   * Sign in field value that triggers action. `option id `.
   */
  @attr('string') declare signInFieldValue: string;
  /**
   * Action to be taken
   */
  @attr('string', { defaultValue: 'show' }) declare action: 'show' | 'deny_entry';
  @attr('string') declare matchOperator: RuleOperation;

  @equal('action', 'show') isConditional!: boolean;
  /**
   * v1: all rules are setup to deny_entry on the backend
   */
  @equal('action', 'deny_entry') isRule!: boolean;

  @computed('signInFieldValue', 'signInField.options.@each.id', '_dropdownOption')
  get dropdownOption(): DropdownOption | undefined {
    if (this._dropdownOption) {
      // When creating a new action model, we need to cache this `obj` until we have
      // a proper option id
      return this._dropdownOption;
    }
    // eslint-disable-next-line ember/use-ember-get-and-set
    const options = this.signInField.get('options') || [];
    return options.find((opt) => opt.id === this.signInFieldValue);
  }
}

import Model, { attr, belongsTo, type AsyncBelongsTo, hasMany, type AsyncHasMany } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

import type HelpdeskQuestion from './helpdesk-question';
import type HomepageItem from './homepage-item';

@adapter('growth')
class HelpdeskItem extends Model {
  @attr('string')
  declare identifier: string;

  @attr('string')
  declare itemType: string;

  @attr('string')
  declare title: string;

  @attr('string')
  declare description: string;

  @attr('boolean')
  declare enabled: boolean;

  @attr('number')
  declare rank: number;

  @attr('immutable', {
    defaultValue: () => ({}),
  })
  declare metadata: object;

  @attr('string')
  declare completionMethod: string;

  @attr('boolean')
  declare completed: boolean;

  @belongsTo('homepage-item')
  declare homepageItem: AsyncBelongsTo<HomepageItem>;

  @hasMany('helpdesk-question')
  declare helpdeskQuestions: AsyncHasMany<HelpdeskQuestion>;
}

export default HelpdeskItem;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'helpdesk-item': HelpdeskItem;
  }
}

import { helper } from '@ember/component/helper';
import { get } from '@ember/object';

export function entryApprovalMessage(entry) {
  // This helper requires that the entry location and badge async relationships both be resolved prior to being passed to this function
  const isNotificationsEnabled = get(entry, 'location.hostNotificationsEnabled');
  const isBadgePrintingEnabled = get(entry, 'flow.badge.enabled');
  if (isNotificationsEnabled && isBadgePrintingEnabled) {
    return 'Notifying host and printing badge';
  } else if (isNotificationsEnabled && !isBadgePrintingEnabled) {
    return 'Notifying host';
  } else if (!isNotificationsEnabled && isBadgePrintingEnabled) {
    return 'Printing badge';
  }
  return '';
}

export default helper(entryApprovalMessage);

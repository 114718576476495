import Transform from '@ember-data/serializer/transform';
import { type VfdScheduleRule } from 'garaje/models/vfd-schedule';
import SimpleTime from 'garaje/utils/simple-time';
import TimeRange from 'garaje/utils/time-range';
import { TrackedArray, TrackedObject } from 'tracked-built-ins';

type RawVfdScheduleData = {
  enabled: boolean;
  'recurring-rule': string;
  intervals: Array<{ from: string; to: string }>;
};

class VfdScheduleRulesTransform extends Transform {
  deserialize(data: RawVfdScheduleData[]): VfdScheduleRule[] {
    const rules = data.map((rule) => {
      const intervals = rule.intervals.map(
        (interval) =>
          new TimeRange(
            SimpleTime.parse24Hour(interval.from),
            SimpleTime.parse24Hour(interval.to, { midnightIsEndOfDay: true }),
          ),
      );
      return new TrackedObject({
        enabled: rule.enabled,
        recurringRule: rule['recurring-rule'],
        intervals: new TrackedArray(intervals),
      });
    });
    return new TrackedArray(rules);
  }

  serialize(rules: VfdScheduleRule[]): RawVfdScheduleData[] {
    return rules.map((rule) => ({
      enabled: rule.enabled,
      'recurring-rule': rule.recurringRule,
      intervals: rule.intervals
        .filter((interval) => interval.isValid)
        .map((interval) => ({
          from: interval.from!.toString(),
          to: interval.to!.toString(),
        })),
    }));
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    'vfd-schedule-rules': VfdScheduleRulesTransform;
  }
}

export default VfdScheduleRulesTransform;

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, get } from '@ember/object';
import { task } from 'ember-concurrency';
import { formatScimProvider } from 'garaje/utils/format-scim-provider';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

/**
 * @param {Object}        employeeGroupRole
 * @param {Function}      setDeleteDialog
 */
class DeleteDialog extends Component {
  @service flashMessages;
  @service state;

  get formattedProvider() {
    const { currentCompany } = this.state;
    const scimIntegration = get(currentCompany, 'scimIntegration');
    if (scimIntegration) {
      const provider = get(scimIntegration, 'provider');
      return formatScimProvider(provider);
    } else {
      return 'SCIM';
    }
  }

  @action
  closeDialog() {
    this.args.setDeleteDialog(false, '');
  }

  deleteMapping = task({ drop: true }, async () => {
    const employeeGroupRole = this.args.employeeGroupRole;

    try {
      await employeeGroupRole.destroyRecord();
      this.flashMessages.showAndHideFlash('success', 'Group mapping deleted');
      this.closeDialog();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}

export default DeleteDialog;

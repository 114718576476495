import { service } from '@ember/service';
import { compare } from '@ember/utils';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { restartableTask } from 'ember-concurrency';
import type UserModel from 'garaje/models/user';
import uniqBy from 'lodash/uniqBy';

type VirtualFrontDeskLocationsTableRowUserDropdownComponentArgs = {
  disabled?: boolean;
  selectedUser: UserModel | null;
  selectUser: (user: UserModel) => void;
};

export default class VirtualFrontDeskLocationsTableRowUserDropdownComponent extends Component<VirtualFrontDeskLocationsTableRowUserDropdownComponentArgs> {
  @service declare store: Store;

  searchUsersTask = restartableTask(async (query: string) => {
    const userRoles = (
      await this.store.query('user-role', {
        filter: {
          confirmedUsers: true,
          name: query,
        },
        include: 'user',
      })
    ).toArray();

    const users = await Promise.all(userRoles.map((userRole) => userRole.user));
    const sortedUsers = users.sort((a, b) => compare(a.fullName, b.fullName));
    return uniqBy(sortedUsers, 'id');
  });
}

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type { TaskInstance } from 'ember-concurrency';
import type BadgeModel from 'garaje/models/badge';
import type ConfigModel from 'garaje/models/config';
import type FlowModel from 'garaje/models/flow';
import type CurrentAdminService from 'garaje/services/current-admin';
import updateAndSaveTask from 'garaje/utils/decorators/update-and-save-task';
import { reads } from 'macro-decorators';

import type { VisitorsSettingsVisitorTypesFlowBadgeRouteModel } from './route';

class VisitorsSettingsVisitorTypesFlowBadgeController extends updateAndSaveTask(Controller) {
  declare model: VisitorsSettingsVisitorTypesFlowBadgeRouteModel;

  @service declare currentAdmin: CurrentAdminService;

  // this does not look right on the default value
  @reads('model.flow', 'model.config.localizedFields') flow!: FlowModel | ConfigModel['localizedFields'];

  get canConfigureBadgePrinting(): boolean {
    return Boolean(this.model.vrSubscription?.canEnableBadgePrinting && isPresent(this.model.printer));
  }

  @action
  rollback(): void {
    this.model.badge.rollbackAttributes();
  }

  @action
  save(badge: BadgeModel, e: Event): TaskInstance<void> {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    return this.updateAndSaveTask.perform(badge);
  }

  @action
  _redirectToEnvoy(path: string): void {
    this.send('redirectToEnvoy', path);
  }
}

export default VisitorsSettingsVisitorTypesFlowBadgeController;

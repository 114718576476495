import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import { notEmpty } from 'macro-decorators';
import { localCopy } from 'tracked-toolbox';

interface UiInputFieldComponentArgs {
  disabled?: boolean;
  errors?: string[] | string;
  required?: boolean;
  update?: (value: string) => unknown;
  value?: string;
  isReadonly?: boolean;
  type?: string;
  dir?: string;
  elementId?: string;
  placeholder?: string;
}

export default class UiInputFieldComponent extends Component<UiInputFieldComponentArgs> {
  @localCopy('args.disabled', false) disabled!: boolean;
  @localCopy('args.required', false) required!: boolean;
  @localCopy('args.autofocus', false) autofocus!: boolean;
  @localCopy('args.errors', []) errors!: UiInputFieldComponentArgs['errors'];
  @localCopy('args.type', 'text') type!: string;

  get elementId(): string {
    return this.args.elementId ?? this.#elementId;
  }

  #elementId = guidFor(this);

  // Actions
  @localCopy('args.update', null) update!: (value: string) => unknown;

  @notEmpty('errors') hasErrors!: boolean;
}

import Component from '@glimmer/component';
import { equal, match } from 'macro-decorators';

export default class PlatformJobFailure extends Component {
  @equal('args.platformJob.failureReason', 'No reason given') noReasonGiven;
  @match('args.platformJob.failureReason', /^Non-JSON body returned/) invalidMessageBody;

  get reason() {
    if (this.noReasonGiven) {
      return 'Unknown. No reason was provided.';
    }

    if (this.invalidMessageBody) {
      return 'Error. Could not parse invalid message body.';
    }

    return this.args.platformJob.failureReason;
  }
}

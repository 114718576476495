import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type FeatureConfigService from 'garaje/services/feature-config';
import type StateService from 'garaje/services/state';

export default class LandingPageRoute extends Route {
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare featureConfig: FeatureConfigService;

  titleToken = 'Rooms';
  beforeModel(): void {
    if (this.featureConfig.isEnabled('rooms.accessRoomsTab')) {
      void this.router.transitionTo('roomba.list');
    }
  }
}

import Model, { attr } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('v3')
class NotificationLogModel extends Model {
  @attr('boolean') declare successful: boolean;
  @attr('date') declare createdAt: Date;
}

export default NotificationLogModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'notification-log': NotificationLogModel;
  }
}

import LocationFragment from 'garaje/graphql/fragments/roomba/LocationFragment';
import gql from 'graphql-tag';

export default gql`
  mutation UpdateLocationRoomsCheckInWindow($locationId: ID!, $roomsCheckInWindow: Int!, $roomsPreCheckInWindow: Int!) {
    updateLocationRoomsCheckInWindow(
      locationId: $locationId
      roomsCheckInWindow: $roomsCheckInWindow
      roomsPreCheckInWindow: $roomsPreCheckInWindow
    ) {
      ...LocationFragment
    }
  }
  ${LocationFragment}
`;

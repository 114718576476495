import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class LocationBillingRoute extends Route {
  @service statsig;

  beforeModel(transition) {
    const cid = transition.to.queryParams?.cid;
    this.statsig.setProperty('company', { id: parseInt(cid) || null });
    this.statsig.setProperty('user', { id: 1 });
  }

  afterModel(_model, transition) {
    const cid = transition.to.queryParams?.cid;
    this.statsig.logEvent('kiosk-demo-signin-anon-page-viewed', parseInt(cid) || null);
  }
}

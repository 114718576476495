import map from 'lodash/map';
import find from 'lodash/find';
import forOwn from 'lodash/forOwn';
import isArray from 'lodash/isArray';
import camelCase from 'lodash/camelCase';
import { singularize } from 'ember-inflector';
import EmberObject from '@ember/object';

/**
 * Example json api doc payload as input and a normalized/formatted response object as output
 *
 * A query for:
 * 
 *
    store.queryTransient('flow', {
      include: 'location',
      fields: {
        flows: 'name,type,location',
        locations: 'name',
      },
    });
 *
 *
 * might response with:
 * 
 * 
    {
      "data": [
          {
              "id": "320",
              "type": "flows",
              "attributes": {
                  "name": "My Flow"
                  "type": "Flows::GlobalChild"
              },
              "relationships": {
                  "location": {
                      "links": {
                          "self": "https://dashboard.envoy.dev/api/v3/flows/320/relationships/location",
                          "related": "https://dashboard.envoy.dev/api/v3/flows/320/location"
                      },
                      "data": {
                          "type": "locations",
                          "id": "45"
                      }
                  }
              }
          }
      ],
      "included": [
          {
              "id": "45",
              "type": "locations",
              "links": {
                  "self": "https://dashboard.envoy.dev/api/v3/locations/45"
              },
              "attributes": {
                  "name": "My Location"
              }
          },
      ],
      "meta": {
          "limit": 20,
          "offset": 0,
          "total": 11
      },
      "links": {
          "first": "https://dashboard.envoy.dev/api/v3/flows?fields%5Bflows%5D=name%2Clocation%2Cglobal-flow&fields%5Bglobal-flows%5D=name&fields%5Blocations%5D=name&include=location%2Cglobal-flow&page%5Blimit%5D=20&page%5Boffset%5D=0&sort=location.name%2Ctype%2Cname",
          "last": "https://dashboard.envoy.dev/api/v3/flows?fields%5Bflows%5D=name%2Clocation%2Cglobal-flow&fields%5Bglobal-flows%5D=name&fields%5Blocations%5D=name&include=location%2Cglobal-flow&page%5Blimit%5D=20&page%5Boffset%5D=0&sort=location.name%2Ctype%2Cname"
      }
    }
 *
 *
 * But will be normalized as:
 *
 * 
    {
      "data": [
          {
              "id": "320",
              "type": "flow",
              "data": {
                  "name": "My Flow",
                  "type": "Flows::GlobalChild",
                  "location": {
                      "id": "45",
                      "type": "location",
                      "data": {
                          "name": "My Location"
                      }
                  }
              }
          },
      ],
      "meta": {
          "limit": 20,
          "offset": 0,
          "total": 11
      }
    }
 *
 */

export default class Formatter {
  constructor() {
    this.payload = {};
  }

  deserialize(payload) {
    this.payload = payload;

    let data;

    if (isArray(payload.data)) {
      data = this.deserializeCollection(payload);
    } else {
      data = this.deserializeOne(payload.data);
    }

    return {
      data,
      meta: payload.meta,
    };
  }

  deserializeOne(item) {
    const formatted = {};
    formatted.id = item.id;
    formatted.type = singularize(item.type);
    formatted.data = {};

    forOwn(item.attributes, (value, key) => {
      formatted.data[camelCase(key)] = value;
    });

    if (item.relationships) {
      for (const key in item.relationships) {
        let relationship;

        if (isArray(item.relationships[key].data)) {
          if (item.relationships[key].data) {
            relationship = this.resolveRelationCollection(item.relationships[key].data);
          } else {
            relationship = null;
          }
        } else {
          if (item.relationships[key].data) {
            relationship = this.resolveRelation(item.relationships[key].data);
          } else {
            relationship = null;
          }
        }

        formatted.data[camelCase(key)] = relationship;
      }
    }

    return EmberObject.create(formatted);
  }

  deserializeCollection(data) {
    const result = map(data.data, (item) => {
      return this.deserializeOne(item);
    });

    return result;
  }

  resolveRelation(data) {
    const included = find(this.payload.included, data);

    if (included) {
      return this.deserializeOne(find(this.payload.included, data));
    }

    return null;
  }

  resolveRelationCollection(relations) {
    return map(relations, (relation) => {
      return this.resolveRelation(relation);
    });
  }
}

import type { LatLngExpression } from 'leaflet';

export const PENDING_UNASSIGNMENT = 'Pending unassignment';

type BoundingBox = {
  point1: {
    lat: number;
    long: number;
  };
  point2: {
    lat: number;
    long: number;
  };
};
export function getAutoGenerationModalPosition(boundingBox: BoundingBox): LatLngExpression {
  const topLeft = boundingBox.point1;
  const topRight = { lat: topLeft.lat, long: boundingBox.point2.long };
  return [Math.max(boundingBox.point1.lat, boundingBox.point2.lat), (topRight.long - topLeft.long) / 2 + topLeft.long];
}

import Component from '@glimmer/component';
import type { VfdContactMethodTeamsMetadata } from 'garaje/models/vfd-contact-method';

type VirtualFrontDeskLocationsTableRowSlackChannelComponentArgs = {
  channel: VfdContactMethodTeamsMetadata | null;
};

export default class VirtualFrontDeskLocationsTableRowTeamsChannelComponent extends Component<VirtualFrontDeskLocationsTableRowSlackChannelComponentArgs> {
  get channelName(): string | null {
    if (this.args.channel) {
      return '#' + this.args.channel['channel-name'];
    }
    return null;
  }
}

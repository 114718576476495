// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { and, none, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Model, { attr, belongsTo, hasMany, type AsyncHasMany } from '@ember-data/model';
import type StoreService from '@ember-data/store';
import type Location from 'garaje/models/location';
import type AjaxService from 'garaje/services/ajax';
import adapter from 'garaje/utils/decorators/adapter';
import serializer from 'garaje/utils/decorators/serializer';
import urlBuilder from 'garaje/utils/url-builder';

import type RoomDevice from './room-device';

@adapter('rooms-v1')
@serializer('rooms')
class Room extends Model {
  @service declare ajax: AjaxService;
  @service declare store: StoreService;

  // Relations
  @belongsTo('company-config') companyConfig: unknown;

  @hasMany('room-device')
  declare devices: AsyncHasMany<RoomDevice>;

  @belongsTo('location')
  location!: Location;

  // Attributes
  @attr('boolean')
  declare active?: boolean;

  @attr('string')
  declare calendarId?: string;

  @attr('boolean')
  declare claimable?: boolean;

  @attr('string')
  declare displayName?: string;

  @attr('string')
  declare imageUrl?: string;

  @attr('boolean')
  declare isOnline?: boolean;

  @attr('boolean')
  declare maskMeetingNames?: boolean;

  @attr('string')
  declare status?: string;

  @attr('string')
  declare deviceStatus?: string;

  @attr('date')
  declare calendarPermissionsCheckedAt?: Date;

  @attr('number')
  declare timeAvailableInMinutes?: number;

  @attr('boolean', { allowNull: true, defaultValue: null })
  declare calendarReadPermission?: boolean;

  @attr('boolean', { allowNull: true, defaultValue: null })
  declare calendarWritePermission?: boolean;

  // Computed
  @and('calendarReadPermission', 'calendarWritePermission')
  declare hasPermissions?: boolean;
  @none('calendarReadPermission')
  declare missingReadPermissions?: boolean;
  @none('calendarWritePermission')
  declare missingWritePermissions?: boolean;
  @or('missingReadPermissions', 'missingWritePermissions')
  declare isMissingPermissions?: boolean;

  async checkCalendarPermissions(): Promise<void> {
    const url = urlBuilder.v2.roomsCalendarPermissionsCheck(this.id);
    const response = await this.ajax.request<Record<string, unknown>>(url, {
      type: 'POST',
      contentType: 'application/vnd.api+json',
    });
    this.store.pushPayload('room', response);
  }
}

export default Room;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    room: Room;
  }
}

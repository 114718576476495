// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { alias, and, not } from '@ember/object/computed';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { resolve } from 'rsvp';
import { task } from 'ember-concurrency';

// eslint-disable-next-line ember/no-classic-classes
export default Component.extend({
  featureFlags: service(),
  flashMessages: service(),
  skinnyLocations: service(),
  store: service(),
  ajax: service(),
  state: service(),

  addQuantityCTAText: 'Purchase a location',
  currentSubscription: alias('subscription'),
  willChargeToAddOrActivateLocation: and('currentSubscription.isSubscribed', 'hasInsufficientQuantity'),
  willNotChargeToAddOrActivateLocation: and('currentSubscription.isSubscribed', 'hasSufficientQuantity'),
  hasSufficientQuantity: not('hasInsufficientQuantity'),
  blockSelfServe: and('willChargeToAddOrActivateLocation', 'state.billingCompany.blockSelfServe'),
  hasInsufficientQuantity: computed(
    'currentSubscription.quantity',
    'skinnyLocations.active.[]',
    'featureFlags.locationBilling',
    function () {
      if (this.featureFlags.isEnabled('locationBilling')) {
        /**
         * TODO
         * Until we are billing by visitor entrances, VR subscriptions on location billing have a max quantity of 1
         */
        return (get(this, 'currentSubscription.quantity') ?? 0) < 1;
      } else {
        return (get(this, 'currentSubscription.quantity') ?? 0) < get(this, 'skinnyLocations.active.length') + 1;
      }
    },
  ),

  canActivate: computed('currentSubscription.{isBasicUser,quantity}', 'skinnyLocations.active.[]', function () {
    return (
      get(this, 'currentSubscription.isBasicUser') &&
      (get(this, 'currentSubscription.quantity') ?? 0) <= get(this, 'skinnyLocations.active.length')
    );
  }),
  enableLocation: task(function* () {
    const currentSubscription = this.currentSubscription;
    let promise;

    if (!this.featureFlags.isEnabled('locationBilling') && this.hasInsufficientQuantity) {
      const subscriptionData = {};
      const adapter = this.store.adapterFor('subscription');

      promise = this.ajax
        .request(adapter.urlForUpdateRecord(get(currentSubscription, 'id'), 'subscription'), {
          type: 'PATCH',
          contentType: 'application/vnd.api+json',
          data: JSON.stringify({
            data: {
              type: 'subscriptions',
              id: get(currentSubscription, 'id'),
              attributes: Object.assign(subscriptionData, {
                quantity: get(this, 'skinnyLocations.active.length') + 1,
              }),
            },
          }),
        })
        .then((payload) => {
          const serializer = this.store.serializerFor('subscription');
          const normalized = serializer.normalizeUpdateRecordResponse(
            this.store,
            'subscription',
            payload,
            get(currentSubscription, 'id'),
          );

          this.store.push(normalized);
        })
        .catch(function (e) {
          currentSubscription.rollbackAttributes();
          throw e;
        });
    } else {
      promise = resolve();
    }

    const model = this.currentLocation;
    promise.then(function () {
      set(model, 'disabled', false);
      return model.save();
    });

    try {
      yield promise;
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }),
});

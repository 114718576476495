// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { get, computed } from '@ember/object';

export default class RecordSelectSelectAllComponent extends Component {
  tagName = 'input';

  attributeBindings = ['checked', 'indeterminate', 'type'];

  classNames = ['mx-1'];

  type = 'checkbox';

  @computed('models.length', 'selectedModels.length')
  get indeterminate() {
    return this.selectedModels.length > 0 && this.selectedModels.length < this.models.length;
  }

  @computed('models.length', 'selectedModels.length')
  get checked() {
    return this.selectedModels.length > 0 && this.selectedModels.length === this.models.length;
  }

  input(evt) {
    if (get(evt, 'target.checked')) {
      get(this, 'onSelectAll')();
    } else {
      get(this, 'onDeselectAll')();
    }
  }
}

import { A } from '@ember/array';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { isBlank, isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { timeout, restartableTask } from 'ember-concurrency';
import type LocationModel from 'garaje/models/location';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import type ZoneModel from 'garaje/models/zone';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import zft from 'garaje/utils/zero-for-tests';
import { gt, reads } from 'macro-decorators';

const DEFAULT_NUMBER_OF_SKELETON_LOADING_BARS = 3;
const RECENT_LOCATIONS_MENU_THRESHOLD = 9;

interface LocationSwitcherContentComponentArgs {
  addLocation: () => void;
  /**
   * Ability for `can "create locations"`
   */
  canCreateLocations: boolean;
  /**
   * Ability for `can "visit global-locations"`
   */
  canVisitGlobal: boolean;
  /**
   * Ability for `can "visit my-locations"`
   */
  canVisitMyLocations: boolean;
  /**
   * Ability for `can "visit connect"`
   */
  canVisitConnect: boolean;
  /**
   * Triggers the basic dropdown to close
   */
  closeMenu: () => void;
  currentLocation?: LocationModel;
  isLoading: boolean;
  locations: LocationModel[];
  recentLocations: LocationModel[];
  showGlobalMenu: boolean;
  showZones: boolean;
  showMyLocations: boolean;
  showConnectMenu: boolean;
  /**
   * Transition to the selected location
   */
  switchLocationFromMenu: (location: SkinnyLocationModel) => void;
  /**
   * Transition to the selected zone
   */
  switchZoneFromMenu: (zone: ZoneModel) => void;
  /**
   * Transition to the location overview screen
   */
  transitionToLocationOverview: () => void;
  /**
   * array of zone models representing properties
   */
  zones: ZoneModel[];
}

export default class LocationSwitcherContentComponent extends Component<LocationSwitcherContentComponentArgs> {
  @service declare state: StateService;
  @service declare metrics: MetricsService;
  @service declare router: RouterService;

  @tracked numberOfLocations: number | null = null;
  @tracked searchText = '';

  @gt('args.locations.length', RECENT_LOCATIONS_MENU_THRESHOLD) showRecentLocationsMenu!: boolean;

  @reads('args.locations.length') locationsCount!: number;
  @reads('args.zones.length') zonesCount!: number;

  get aboveMinimumLocationsThreshold(): boolean {
    if (this.zonesCount >= 1) return true;
    if (this.locationsCount > 1) return true;

    return false;
  }

  get activeLocations(): LocationModel[] {
    return this.filteredLocations.filter((location) => isEmpty(location.disabledToEmployeesAt));
  }

  // the `disabled` field is when there is no visitors subscription at that location, we don't want
  // to display that info outside of the billing page, so we're using the `disabledToEmployeesAt`
  get disabledLocations(): LocationModel[] {
    return this.filteredLocations.filter((location) => !isEmpty(location.disabledToEmployeesAt));
  }

  /**
   * Filters locations based on the current value of 'searchText'
   */
  get filteredLocations(): LocationModel[] {
    const { searchText } = this;

    if (isBlank(searchText)) {
      return this.args.locations;
    }

    const regexp = new RegExp(searchText, 'i');

    const currentLocationId = this.args.currentLocation?.id;
    return this.args.locations.filter((location) => {
      return location.nameWithCompanyName.search(regexp) !== -1 || location.id === currentLocationId;
    });
  }

  /**
   * Filters out the current location from the list of recent locations
   */
  get recentLocationsExceptCurrent(): LocationModel[] {
    const currentLocationId = this.args.currentLocation?.id;
    return A(this.args.recentLocations).rejectBy('id', currentLocationId);
  }

  constructor(owner: unknown, args: LocationSwitcherContentComponentArgs) {
    super(owner, args);
    const currentCompany = this.state.currentCompany;
    // TODO: we should probably change this strategy. using current company's location's
    // is not a good proxy for the number of locations to load in. as you know, we load
    // locations from other companies too. one suggestion is to cache the last
    // locations.length into localstorage, falling back to the
    // DEFAULT_NUMBER_OF_SKELETON_LOADING_BARS if it hasn't been set.
    // 9/6/19 - @kamal
    if (currentCompany) {
      const numberOfLocations = currentCompany.hasMany('locations').ids().length;
      this.numberOfLocations = numberOfLocations;
    } else {
      this.numberOfLocations = DEFAULT_NUMBER_OF_SKELETON_LOADING_BARS;
    }
  }

  /**
   * Debounces the user's search
   * Triggers analytics call after user pauses typing
   */
  mutateSearchTextTask = restartableTask(async (text: string) => {
    await timeout(zft(200));

    this.searchText = text;

    if (!isBlank(text)) {
      this.metrics.trackEvent('Location Picker Search Term Entered', {
        location_id: this.args.currentLocation?.id,
        search_term: text,
      });
    }
  });

  @action
  transitionToVisitorLog(): void {
    void this.router.transitionTo('my-locations.visitor-log');
  }

  @action
  transitionToConnect(): void {
    void this.router.transitionTo('connect');
  }
}

import ApplicationSerializer from './application';

export default class VisitorGuideSectionSerializer extends ApplicationSerializer {
  attrs = {
    bodyLabel: { serialize: false },
    bodyPlaceholder: { serialize: false },
    iconType: { serialize: false },
    title: { serialize: false },
  };
}

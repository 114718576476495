import ApplicationSerializer from './application';

export default class EmployeeSigninFieldPageSerializer extends ApplicationSerializer {
  // This is used for the `hasMany` relationship between flows and skinny-locations
  // envoy-web doesn't know anything about the skinny-locations model.
  modelNameFromPayloadKey(key) {
    if (key === 'flows') {
      return 'employee-screening-flow';
    } else if (key === 'sign-in-field-pages') {
      return 'employee-sign-in-field-page';
    } else if (key === 'sign-in-fields') {
      return 'employee-sign-in-field';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }

  payloadKeyFromModelName(modelName) {
    if (modelName === 'employee-screening-flow') {
      return 'flows';
    } else if (modelName === 'employee-sign-in-field-page') {
      return 'sign-in-field-pages';
    } else if (modelName === 'employee-sign-in-field') {
      return 'sign-in-fields';
    } else {
      return super.payloadKeyFromModelName(...arguments);
    }
  }
}

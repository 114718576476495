import ApplicationSerializer from './application';
import { camelize } from '@ember/string';

export default class UIHookSerializer extends ApplicationSerializer {
  normalizeResponse(store, primaryModelClass, payload) {
    payload.data = (payload.data || []).map((attributes) => {
      return {
        type: 'ui-hook',
        attributes,
      };
    });

    delete payload.relationships;

    return super.normalizeResponse(...arguments);
  }

  extractId(modelClass, { attributes }) {
    const id = attributes.uiHookId;
    delete attributes.uiHookId;
    return id;
  }

  keyForAttribute(attr) {
    return camelize(attr);
  }
}

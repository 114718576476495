import FlowSerializer from './flow';

export default class GlobalFlowSerializer extends FlowSerializer {
  attrs = {
    locations: { serialize: true },
  };
  // This is used for the `hasMany` relationship between flows and skinny-locations
  // envoy-web doesn't know anything about the skinny-locations model.
  modelNameFromPayloadKey(key) {
    if (key === 'summary-pages') {
      return 'global-summary-page';
    } else if (key === 'locations') {
      return 'skinny-location';
    } else if (key === 'agreement-pages') {
      return 'global-agreement-page';
    } else if (key === 'sign-in-field-pages') {
      return 'global-sign-in-field-page';
    } else if (key === 'sign-in-field-action-rule-groups') {
      return 'global-sign-in-field-action-rule-group';
    } else if (key === 'badges') {
      return 'global-badge';
    } else if (key === 'photo-pages') {
      return 'global-photo-page';
    } else if (key === 'visual-compliance-configurations') {
      return 'global-visual-compliance-configuration';
    } else if (key === 'id-scan-pages') {
      return 'global-id-scan-page';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }

  normalizeResponse(store, primaryModelClass, payload) {
    if (payload.included && payload.included.length) {
      payload.included.forEach((item) => {
        const prefixClasses = [
          'summary-pages',
          'sign-in-field-pages',
          'agreement-pages',
          'agreements',
          'sign-in-field-actions',
          'sign-in-field-action-rule-groups',
          'sign-in-field-actions-contacts',
          'sign-in-fields',
          'badges',
          'photo-pages',
          'id-scan-pages',
          'visual-compliance-configurations',
        ];
        if (prefixClasses.includes(item.type)) {
          item.type = `global-${item.type}`;
        }
      });
    } else if (payload.data?.relationships) {
      if (payload.data.relationships['agreement-page']) {
        payload.data.relationships['agreement-page'].type = `global-agreement-pages`;
      }
      if (payload.data.relationships['summary-page']) {
        payload.data.relationships['summary-page'].type = `global-summary-pages`;
      }
      if (payload.data.relationships['sign-in-field-page']) {
        payload.data.relationships['sign-in-field-page'].type = `global-sign-in-field-pages`;
      }
      if (
        payload.data.relationships['sign-in-field-action-rule-groups'] &&
        payload.data.relationships['sign-in-field-action-rule-groups'].data?.length
      ) {
        const data = payload.data.relationships['sign-in-field-action-rule-groups'].data;
        data.forEach(function (item) {
          item.type = `global-sign-in-field-action-rule-groups`;
        });
      }
      if (payload.data.relationships['badge']) {
        payload.data.relationships['badge'].type = `global-badges`;
      }
      if (payload.data.relationships['photo-page']) {
        payload.data.relationships['photo-page'].type = `global-photo-pages`;
      }
      if (payload.data.relationships['id-scan-page']) {
        payload.data.relationships['id-scan-page'].type = `global-id-scan-pages`;
      }
      if (payload.data.relationships['visual-compliance-configuration']) {
        payload.data.relationships['visual-compliance-configuration'].type = `global-visual-compliance-configurations`;
      }
    }

    return super.normalizeResponse(...arguments);
  }

  payloadKeyFromModelName(key) {
    if (key === 'global-summary-page') {
      return 'summary-pages';
    } else if (key === 'global-agreement-page') {
      return 'agreement-pages';
    } else if (key === 'skinny-location') {
      return 'locations';
    } else if (key === 'global-sign-in-field-page') {
      return 'sign-in-field-pages';
    } else if (key === 'global-sign-in-field-action-rule-group') {
      return 'sign-in-field-action-rule-groups';
    } else if (key === 'global-badge') {
      return 'badges';
    } else if (key === 'global-photo-page') {
      return 'photo-pages';
    } else if (key === 'global-id-scan-page') {
      return 'id-scan-pages';
    } else if (key === 'global-visual-compliance-configuration') {
      return 'visual-compliance-configurations';
    } else {
      return super.payloadKeyFromModelName(...arguments);
    }
  }
}

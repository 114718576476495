import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AnnouncementModel from 'garaje/models/announcement';

type AnnouncementOverviewWrapperArgsType = {
  model: AnnouncementModel;
};

export default class AnnouncementOverviewWrapper extends Component<AnnouncementOverviewWrapperArgsType> {
  @tracked isExpanded = true;

  get totalRecipients(): number {
    const employeeRecipients = this.args.model.statistics.employee_recipients;
    const visitorRecipients = this.args.model.statistics.visitor_recipients;

    return employeeRecipients + visitorRecipients;
  }

  get notMarkedAsSafe(): number {
    const markedAsSafeRecipients = this.args.model.statistics.marked_as_safe_recipients;

    return this.totalRecipients - markedAsSafeRecipients;
  }

  get sent(): number {
    const sentEmployeeRecipients = this.args.model.statistics.sent_employee_recipients;
    const sentVisitorRecipients = this.args.model.statistics.sent_visitor_recipients;

    return sentEmployeeRecipients + sentVisitorRecipients;
  }

  get notSent(): number {
    return this.totalRecipients - this.sent;
  }
}

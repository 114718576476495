import Route from '@ember/routing/route';
import { get, set, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { hash } from 'rsvp';
import moment from 'moment-timezone';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import { routeEvent } from 'garaje/utils/decorators/route';

class VisitorsEntryRoute extends Route {
  @service abilities;
  @service state;
  @service flow;
  @service skinnyLocations;
  @service router;
  @service featureFlags;
  @service store;

  beforeModel() {
    const { vrSubscription } = this.state;
    if (!(isPresent(vrSubscription) && this.abilities.can('visit dashboard'))) {
      if (this.abilities.can('visit dashboard')) {
        this.router.transitionTo('visitors.start-trial');
      } else if (this.abilities.can('visit billing')) {
        this.router.transitionTo('billing');
      } else if (this.abilities.can('visit security-desk')) {
        this.router.transitionTo('security-desk');
      } else {
        this.router.transitionTo('unauthorized');
      }
    }

    this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
  }

  async model(params) {
    let printers = [];
    const { vrSubscription, currentLocation } = this.state;
    const { blocklistContacts, idScanContacts, config } = currentLocation;

    if (this.abilities.can('visit printers') && vrSubscription.canEnableBadgePrinting) {
      printers = this.store.query('printer', {
        filter: { location: currentLocation.id, enabled: true },
        sort: 'status',
      });
    }

    const connectedTenants = await currentLocation.getPropertyConnections();
    const include = [];

    if (connectedTenants.length) include.push('invite-multi-tenancy-visitor-notification-logs');

    if (this.state.features?.canAccessMultipleHosts) {
      include.push('employee', 'additional-hosts');
    }

    const entry = await currentLocation
      .findEntry(params.entry_id, include) // eslint-disable-line camelcase
      .catch((err) => {
        if (err.errors && /404/.test(err.errors.toString())) {
          this.router.transitionTo('visitors.entries');
        } else {
          throw err;
        }
      });

    const { entryLocation } = await hash({ entryLocation: entry.location, entryEmployee: entry.employee });

    if (entryLocation) {
      this.maybeSwitchCurrentLocation(currentLocation, entryLocation);
    }

    if (entry.currentFlow) {
      const hasAnyUserDocumentTemplateConfigurations = get(
        entry,
        'currentFlow.activeUserDocumentTemplateConfigurations.length',
      );

      const signInFieldPage = await get(entry, 'currentFlow.signInFieldPage');
      await signInFieldPage.signInFields;

      if (get(entry, 'currentFlow.isProtect')) {
        await this.flow.getFlowWithIncludes(get(entry, 'currentFlow.id'));
      } else if (hasAnyUserDocumentTemplateConfigurations) {
        // Fetch associated Visitor Documents only if:
        //   1. There are any relevant UserDocumentTemplateConfigurations
        //   2. The Visitor Document feature flag is enabled
        try {
          entry.visitorDocuments = await this.store.query('visitor-document', {
            include:
              'user-document-template,user-document-attachments,user-document-attachments.user-document-template-attachment',
            filter: { 'entry-id': entry.id },
          });
        } catch (_) {
          // UserDocumentTemplateConfigurations will load for everyone until
          // [BE] Jira task https://envoycom.atlassian.net/browse/VIS-4390 is completed.
          // For now, don't bomb out if the visitor-document request fails.
        }
      }
    }

    return hash({
      agreeableNdas: entry.agreeableNdas,
      entry,
      config,
      connectedTenants,
      eventReports: [],
      blocklistContacts,
      idScanContacts,
      printers,
      vrSubscription,
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    setProperties(controller, {
      model: model.entry,
      connectedTenants: model.connectedTenants,
      config: model.agreeableNdas,
      agreeableNdas: model.agreeableNdas,
      agreements: model.agreeableNdas.map((agreeableNda) => agreeableNda.agreement),
      printers: model.printers,
      vrSubscription: model.vrSubscription,
      eventReports: model.eventReports,
      blocklistContacts: model.blocklistContacts,
      idScanContacts: model.idScanContacts,
    });

    controller._buildUserDataChangeset();
    controller._buildStaticFields();
    controller.loadPreviousEntries.perform();
    controller.pollEventReports.cancelAll();
    controller.pollEventReports.perform();
  }

  resetController(controller) {
    set(controller, 'previousEntries', []);
    controller.pollEventReports.cancelAll();
  }

  deactivate() {
    super.deactivate(...arguments);
    this.controller.pollEventReports.cancelAll();
  }

  titleToken() {
    return `${get(this.controller, 'model.fullName')} · ${moment(get(this.controller, 'model.signInTime')).format(
      'MM-DD-YYYY',
    )}`;
  }

  maybeSwitchCurrentLocation(currentLocation, entryLocation) {
    if (currentLocation.id !== entryLocation.id) {
      // eslint-disable-next-line ember/no-controller-access-in-routes
      const protectedController = this.controllerFor('protected');
      protectedController.send('switchLocation', entryLocation.id);
    }
  }

  @routeEvent
  routeWillChange(transition) {
    const hasUnsavedChanges = get(this.controller, 'hasChanges');

    if (hasUnsavedChanges && !window.confirm('You will lose any unsaved changes. Are you sure you want to continue?')) {
      transition.abort();

      return false;
    }

    this.controller.rollbackChanges();
    return true;
  }
}

export default VisitorsEntryRoute;

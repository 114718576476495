import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import { isProductDisabled } from 'garaje/helpers/product-disabled';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import {
  EMPLOYEE,
  GLOBAL_ADMIN,
  LOCATION_ADMIN,
  RECEPTIONIST,
  BILLING,
  SECURITY,
  DELIVERIES_ADMIN,
} from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_MANAGE_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN];
const CAN_VISIT_ROLES = [RECEPTIONIST, EMPLOYEE, GLOBAL_ADMIN, LOCATION_ADMIN, BILLING, SECURITY, DELIVERIES_ADMIN];

export default class EmployeeScheduleAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;
  @service declare featureFlags: FeatureFlagsService;

  get canVisit(): boolean {
    if (isProductDisabled('desks')) {
      return false;
    }

    const {
      state: { currentLocationEnabledDesks, currentLocation, features },
      currentAdmin: { roleNames: roles },
    } = this;

    const isAllowed =
      (!!features?.canAccessDesksApplication && !!currentLocationEnabledDesks?.active) ||
      !!features?.canAccessWorkplaceApplication ||
      !!features?.canAccessProtectLegacyFeatures;

    if (isPresent(_intersection(CAN_MANAGE_ROLES, roles))) {
      return isAllowed;
    } else if (isPresent(_intersection(CAN_VISIT_ROLES, roles))) {
      return isAllowed && !!currentLocation?.employeeScreeningEnabled;
    }
    return false;
  }
}

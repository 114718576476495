import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { and, reads } from 'macro-decorators';
import { inject as service } from '@ember/service';
import { task, restartableTask } from 'ember-concurrency';
import { capitalize } from '@ember/string';

/**
 * @param {Class}       plan
 * @param {Boolean}     isHighlighted
 * @param {Boolean}     isOpen
 * @param {Array}       plans
 * @param {Function}    changeTrialTask
 * @param {String}      currentPlanName
 */
export default class TrialPlan extends Component {
  @service store;
  @service state;

  @tracked planDetails = {};
  @tracked isOpen;

  @reads('state.vrSubscription') subscription;
  @and('isCurrentPlan', 'subscription.hasTrialDaysLeft', 'planDetails.yearlyPrice') canShowTrialDaysLeft;

  constructor() {
    super(...arguments);
    this.isOpen = this.args.isOpen;
    this.fetchPlanDetails.perform();
  }

  @restartableTask
  *fetchPlanDetails() {
    const planDetails = yield this.store.findRecord('plan', this.args.plan.id);
    this.planDetails = planDetails;
  }

  get isCurrentPlan() {
    return this.args.currentPlanName === this.args.plan.id;
  }

  get isDowngrade() {
    const plans = this.args.plans.map((plan) => plan.id);
    return plans.indexOf(this.args.plan.id) < plans.indexOf(this.args.currentPlanName);
  }

  get isUpgrade() {
    return !this.isDowngrade && !this.isCurrentPlan;
  }

  // Returns array of plans with features you would be gaining / losing
  // with these feature nested in a `featureSets` array that breaks the
  // features evenly into two cols
  get planPreviews() {
    const lowerBound = this.isDowngrade ? this.args.plan.id : this.args.currentPlanName;
    const upperBound = this.isDowngrade ? this.args.currentPlanName : this.args.plan.id;

    const planPreviews = this.featuresBetweenTwoPlans(lowerBound, upperBound);
    planPreviews.forEach(function (planPreview) {
      const halfMarker = Math.ceil(planPreview.features.length / 2);
      const firstHalf = planPreview.features.slice(0, halfMarker);
      const secondHalf = planPreview.features.slice(firstHalf.length);

      planPreview.featureSets = [firstHalf, secondHalf];
    });

    return planPreviews;
  }

  get iconClass() {
    switch (this.args.plan.name) {
      case 'legacy':
      case 'basic':
      case 'free':
        return 'icon-legacyPlan';
      case 'standard':
      case 'standard-1':
        return 'icon-standardPlan';
      case 'premium':
      case 'premium-1':
        return 'icon-premiumPlan';
      case 'deliveries':
        return 'icon-deliveriesPlan';
      case 'vr':
        return 'icon-vrPlan';
      case 'enterprise':
      case 'enterprise-1':
        return 'icon-enterprisePlan';
      default:
        return 'icon-legacyPlan';
    }
  }

  // Given a lower and a higher plan, this function will
  // return an array of plans, excluding the lower plan
  // but including the high plan
  featuresBetweenTwoPlans(lowerPlan, higherPlan) {
    const plans = this.args.plans;
    const lowerBoundIndex = plans.mapBy('id').indexOf(lowerPlan);
    const upperBoundIndex = plans.mapBy('id').indexOf(higherPlan);
    return this.args.plans.filter((plan, index) => {
      return index > lowerBoundIndex && index <= upperBoundIndex;
    });
  }

  @task
  *updatePlanTask() {
    let msg;
    const isUpgrade = this.isUpgrade;
    const planName = capitalize(this.args.plan.name);
    const isComingFromBasic = this.args.currentPlanName === 'basic';

    if (isUpgrade && isComingFromBasic) {
      msg = `Plan upgraded to 14-day trial of ${planName}`;
    } else if (isUpgrade) {
      msg = `Plan upgraded to ${planName}`;
    } else {
      msg = `Plan downgraded to ${planName}`;
    }
    yield this.args.changeTrialTask.perform(this.planDetails, msg, isUpgrade);
  }
}

/* eslint-disable ember/no-side-effects */
import { action, get } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import type ComputedProperty from '@ember/object/computed';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { capitalize } from '@ember/string';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { humanize } from 'ember-cli-string-helpers/helpers/humanize';
import { dropTask, type Task, type TaskInstance } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';
import type { EmployeesOverviewSidePanel } from 'garaje/graphql/generated/map-features-types';
import type { ResourceUtilization } from 'garaje/graphql/generated/resource-utilization-types';
import type AmenityModel from 'garaje/models/amenity';
import type AreaMapModel from 'garaje/models/area-map';
import type DeliveryAreaModel from 'garaje/models/delivery-area';
import type DeskModel from 'garaje/models/desk';
import type DeskLocationModel from 'garaje/models/desk-location';
import type DraftModel from 'garaje/models/draft';
import type EmployeeModel from 'garaje/models/employee';
import type LocationModel from 'garaje/models/location';
import type MapFeatureModel from 'garaje/models/map-feature';
import type MapFloorModel from 'garaje/models/map-floor';
import type NeighborhoodModel from 'garaje/models/neighborhood';
import type RoomModel from 'garaje/models/room';
import type AjaxService from 'garaje/services/ajax';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import type StoreService from 'garaje/services/store';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import type L from 'leaflet';
import compact from 'lodash/compact';
import { alias, filter, filterBy } from 'macro-decorators';
import { defer } from 'rsvp';
import { cached } from 'tracked-toolbox';

type PlacingResource = {
  type: string;
  geometryType: string;
  icon: string;
  disabled: boolean;
  tooltip: string;
  active: boolean;
  onClick: () => void;
};

interface ResourceMapContainerArgs {
  areaMap: AreaMapModel;
  mapFloor: MapFloorModel;
  mapFeatures: MapFeatureModel[];
  setMapFeatures: (features: MapFeatureModel[]) => void;
  deletableFeatures: MapFeatureModel[];
  setDeletableFeatures: (features: MapFeatureModel[]) => void;
  isLoading: boolean;
  amenities: AmenityModel[];
  deliveryAreas: DeliveryAreaModel[];
  desks: DeskModel[];
  resourceInsights: ResourceUtilization;
  unplacedDesks: DeskModel[];
  employees: EmployeeModel[];
  rooms: RoomModel[];
  neighborhoods: NeighborhoodModel[];
  draft: DraftModel;
  selectedFeature: MapFeatureModel | null;
  onSelectedFeatureChange: (feature: MapFeatureModel | null) => void;
  loadMoreEmployeesTask: Task<void, []>;
  hasMoreEmployeePages: boolean;
  updateResourceOverview: (op: string, feature: MapFeatureModel) => void;
  updateEmployeeOverview: (op: string, employee: EmployeeModel) => void;
  selectedPanelView: string;
  selectedEmployeeEmail: string;
  gqlEmployees: EmployeesOverviewSidePanel;
  isDraftMap: boolean;
  placingResource: PlacingResource | null;
  leafletMap: L.Map;
  setLeafletMap: (map: unknown) => void;
  hasShownAutoGenerationTooltip: boolean;
}

interface MyTaskInstance<T> extends TaskInstance<T> {
  abort: () => void;
  continue: () => void;
}

interface MyDeskModel extends DeskModel {
  hasDirtyRelationships: boolean;
}

export default class ResourceMapContainer extends Component<ResourceMapContainerArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare featureConfig: FeatureConfigService;
  @service declare store: StoreService;
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare ajax: AjaxService;
  @service declare metrics: MetricsService;
  @service declare abilities: AbilitiesService;
  @service declare currentAdmin: CurrentAdminService;

  @alias('state.activeDeskLocations') declare activeDeskLocations: DeskLocationModel[];
  @alias('state.currentLocation') declare currentLocation: LocationModel;
  @alias('state.billingCompany.blockSelfServe') declare blockSelfServe: boolean;

  // eslint-disable-next-line ember/no-get
  @filter('args.desks', (desk: MyDeskModel) => get(desk, 'hasDirtyAttributes') || desk.hasDirtyRelationships)
  declare dirtyDesks: ComputedProperty<MyDeskModel[]>;
  @filterBy('args.desks', 'isDeleted', false) useableDesks: DeskModel[] = [];
  @filterBy('useableDesks', 'enabled', false) inactiveDesksOnFloor: DeskModel[] = [];
  @filterBy('args.mapFeatures', 'hasDirtyAttributes') dirtyFeatures: MapFeatureModel[] = [];
  @filter('args.mapFeatures', (feature: MapFeatureModel) => feature.hasError || !feature.name)
  errorFeatures: MapFeatureModel[] = [];

  @tracked _selectedNeighborhoods: NeighborhoodModel[] = [];
  @tracked employeesPage = 0;
  @tracked employeesCount = 0;
  @tracked limit = 25;
  @tracked paymentSources = [];
  @tracked mapFeatures: MapFeatureModel[] = this.args.mapFeatures; // eslint-disable-line ember/no-tracked-properties-from-args
  @tracked activeDesksCount: number = this.desksQuantity;
  @tracked placingResource: PlacingResource | null = null;
  @tracked isCreatingMapFloor = true;
  @tracked shouldResetMap = false;
  @tracked nextDeskToPlace = this.args.unplacedDesks[0]; // eslint-disable-line ember/no-tracked-properties-from-args
  @tracked isSnappingEnabled = true;
  @tracked showSettingsPanel = false;
  @tracked sidebarIsOpen = false;

  get selectedNeighborhoods(): NeighborhoodModel[] {
    if (this._selectedNeighborhoods.length || this.abilities.can('edit maps')) {
      return this._selectedNeighborhoods;
    }

    const neighborhoods = this.args.neighborhoods;
    const mapAbility = this.abilities.abilityFor('map') as { editableNeighborhoodIds: string[] };

    return compact(mapAbility?.editableNeighborhoodIds.map((id) => neighborhoods.find((n) => n.id === id)));
  }

  get shouldShowAutoPlacementTooltip(): boolean {
    return (
      this.args.placingResource?.type === 'desk' &&
      !this.args.hasShownAutoGenerationTooltip &&
      this.featureFlags.isEnabled('map-setup-improvements-web')
    );
  }

  get deskUsageModalText(): string {
    if (navigator.userAgent.indexOf('Mac') !== -1) {
      return 'Place multiple desks by holding command ⌘ while dragging a rectangle over the desired area';
    } else {
      return 'Place multiple desks by holding control ^ while dragging a rectangle over the desired area';
    }
  }

  get desksQuantity(): number {
    if (this.featureFlags.isEnabled('locationBilling')) {
      return this.activeDeskLocations.find((dl) => dl.id === this.currentLocation?.id)?.assignableDesksQuantity || 0;
    }
    return this.activeDeskLocations.reduce((acc, deskLocation) => acc + deskLocation.assignableDesksQuantity, 0);
  }

  get maximumDesksForCurrentPlan(): number {
    return this.featureConfig.getLimit('desks.unlimitedDesks') || 0;
  }

  get hasMaxActiveDesks(): boolean {
    if (this.featureConfig.isEnabled('desks.unlimitedDesks')) {
      return false;
    }
    return this.activeDesksCount >= this.maximumDesksForCurrentPlan;
  }

  get totalDesksForActivation(): number {
    return this.activeDesksCount + this.inactiveDesksOnFloor.length;
  }

  get overexceedingDesksCount(): number {
    return this.totalDesksForActivation - this.maximumDesksForCurrentPlan;
  }

  @cached
  get filteredMapFeatures(): MapFeatureModel[] {
    const { mapFeatures } = this.args;
    return mapFeatures.filter((feature) => {
      if (feature.type === 'desk') {
        if (isEmpty(this.selectedNeighborhoods) || this.featureFlags.isEnabled('neighborhoods-on-map-web')) {
          return true;
        }
        if (feature.externalId) {
          const desk = this.store.peekRecord('desk', feature.externalId);

          if (desk) {
            const neighborhoodName = desk?.neighborhoodName || '';
            const neighborhoodNames = this.selectedNeighborhoods.map((n) => n.name);

            return neighborhoodNames.includes(neighborhoodName);
          }
        }
        return false;
      }
      return true;
    });
  }

  @cached
  get placedDesks(): DeskModel[] {
    const { unplacedDesks } = this.args;
    return this.useableDesks.filter((desk) => !unplacedDesks.includes(desk));
  }

  get resourceDetails(): object {
    const {
      loadMoreEmployeesTask,
      hasMoreEmployeePages,
      amenities,
      desks,
      employees,
      neighborhoods,
      draft,
      resourceInsights,
    } = this.args;
    return {
      amenities,
      desks,
      employees,
      neighborhoods,
      draft,
      selectedNeighborhoods: this.selectedNeighborhoods,
      loadMoreEmployees: loadMoreEmployeesTask,
      hasMoreEmployees: hasMoreEmployeePages,
      hasMaxActiveDesks: this.hasMaxActiveDesks,
      updateActiveDesksCount: (change: number) => (this.activeDesksCount += change),
      activeDesksCount: this.activeDesksCount,
      maximumDesksForCurrentPlan: this.maximumDesksForCurrentPlan,
      nextDeskToPlace: this.nextDeskToPlace,
      setNextDeskToPlace: () => {
        this.nextDeskToPlace = this.args.unplacedDesks.find((desk) => desk.id !== this.nextDeskToPlace?.id);
      },
      resourceInsights,
    };
  }

  // TODO: duplicate code from controller
  _placedResourcesIds(type: string): string[] {
    const { mapFeatures } = this.args;
    return mapFeatures.filter((feature) => feature.type === type && feature.externalId).map((f) => f.externalId);
  }

  // TODO: duplicate code from controller
  get availableRooms(): RoomModel[] {
    const { rooms } = this.args;
    const placedRoomIds = this._placedResourcesIds('room');
    return rooms.filter(({ id }) => !placedRoomIds.includes(id));
  }

  // TODO: duplicate code from controller
  get availableDeliveryAreas(): DeliveryAreaModel[] {
    const { deliveryAreas } = this.args;
    const placedDeliveryAreasIds: string[] = this._placedResourcesIds('delivery-area');
    return deliveryAreas.filter(({ id }) => !placedDeliveryAreasIds.includes(id));
  }

  get hasMoreEmployeePages(): boolean {
    return this.employeesPage * this.limit < this.employeesCount;
  }

  get allResourcesText(): string {
    return this.selectedResource ? `all ${this.pluralizedResourceTitle}` : 'everything';
  }

  get pluralResourceText(): string {
    return this.pluralizedResourceTitle ?? 'resources';
  }

  get capitalizedResource(): string {
    return capitalize(this.allResourcesText);
  }

  get pluralizedResourceTitle(): string | null {
    if (this.selectedResource) {
      return this.selectedResource.type === 'cafe'
        ? 'cafes'
        : pluralize(humanize([this.selectedResource?.type])).toLowerCase();
    }
    return null;
  }

  // used only for bulk activate/deactivate/delete logic
  get selectedResource(): MapFeatureModel | PlacingResource | null {
    const { selectedFeature, placingResource } = this.args;
    return selectedFeature ?? placingResource;
  }

  @cached
  get nonDeskFeaturesOnFloor(): MapFeatureModel[] {
    return this.featuresOnFloor.filter((feature) => feature.type !== 'desk');
  }

  get isEveryFeatureOnFloorEnabled(): boolean {
    if (isEmpty(this.featuresOnFloor)) return true;

    const isEveryDeskAssignable =
      !this.featureConfig.isEnabled('desks.allowEnabling') || this.placedDesks.every((desk) => desk.enabled === true);

    if (this.selectedResource) {
      if (this.selectedResource.type !== 'desk') {
        return this.featuresOnFloor
          .filter((feature) => feature.type === this.selectedResource?.type)
          .every((feature) => feature.enabled === true);
      }
      return isEveryDeskAssignable;
    }
    return this.nonDeskFeaturesOnFloor.every((feature) => feature.enabled === true) && isEveryDeskAssignable;
  }

  @cached
  get featuresOnFloor(): MapFeatureModel[] {
    const { mapFloor, mapFeatures } = this.args;
    return mapFeatures.filter((feature) => {
      return feature.belongsTo('mapFloor').id() === mapFloor.id;
    });
  }

  get isEveryFeatureOnFloorDisabled(): boolean {
    if (isEmpty(this.featuresOnFloor)) return true;

    const isEveryDeskUnassignable = this.placedDesks.every((desk) => desk.enabled === false);

    if (this.selectedResource) {
      if (this.selectedResource.type !== 'desk') {
        return this.featuresOnFloor
          .filter((feature) => feature.type === this.selectedResource?.type)
          .every((feature) => feature.enabled === false);
      }
      return isEveryDeskUnassignable;
    }

    return this.nonDeskFeaturesOnFloor.every((feature) => feature.enabled === false) && isEveryDeskUnassignable;
  }

  get isEverythingDeleted(): boolean {
    if (this.selectedResource) {
      return isEmpty(this.featuresOnFloor.filter((feature) => feature.type === this.selectedResource?.type));
    }
    return isEmpty(this.featuresOnFloor);
  }

  get sortedMapFloors(): Array<MapFloorModel | string> {
    const { areaMap } = this.args;
    const mapFloors: Array<MapFloorModel | string> = [...areaMap.mapFloors.sortBy('ordinality')];
    if (this.abilities.can('manage floor maps')) {
      mapFloors.push('create');
    }
    return mapFloors;
  }

  @action
  setIsSidebarOpen(): void {
    this.sidebarIsOpen = false;
  }

  @action
  setSelectedNeighborhood(selected: NeighborhoodModel[]): void {
    this._selectedNeighborhoods = selected;
  }

  @action
  searchNeighborhoods(searchTerm: string): NeighborhoodModel[] {
    return this.args.neighborhoods.filter((neighborhood) =>
      neighborhood.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }

  @action
  onMapFloorChange(mapFloor: MapFloorModel): void {
    const { onSelectedFeatureChange, isDraftMap } = this.args;
    onSelectedFeatureChange(null);
    this._selectedNeighborhoods = [];
    const route = isDraftMap ? 'spaces.maps.drafts.area-map.show' : 'spaces.maps.edit.show';
    void this.router.transitionTo(route, mapFloor.id);
  }

  @action
  onDeskChange(desk: DeskModel): void {
    this.nextDeskToPlace = desk;
  }

  setAssignableValueOnDesks(value: boolean): void {
    this.useableDesks.forEach((desk) => {
      if (desk.enabled !== value) {
        desk.enabled = value;
        this.activeDesksCount += value ? 1 : -1;
      }
    });
  }

  @action
  deactivateEverything(): void {
    const { updateResourceOverview } = this.args;

    const type = this.selectedResource?.type;
    this.metrics.trackEvent('Deactivating Everything', { type });

    if (type === 'desk' || !type) this.setAssignableValueOnDesks(false);

    //desk features should always be active so that desks with enabled: false will still render in the mobile app
    if (type) {
      if (type !== 'desk') this.deactivateFeatures(this.featuresOnFloor.filter((feature) => feature.type === type));
    } else {
      this.deactivateFeatures(this.nonDeskFeaturesOnFloor);
    }

    this.featuresOnFloor.forEach((feature) => {
      updateResourceOverview('update', feature);
    });

    this.flashMessages.showAndHideFlash(
      'success',
      `${this.capitalizedResource} deactivated on this floor. Click Save.`,
    );
  }

  @action
  activateEverything(): void {
    const { updateResourceOverview } = this.args;
    const type = this.selectedResource?.type;
    this.metrics.trackEvent('Activating Everything', { type });
    if (type === 'desk') {
      this.activateAllDesks();
    } else if (!type) {
      this.activateFeatures(this.featuresOnFloor);
      this.activateAllDesks();
    } else {
      this.activateFeatures(this.featuresOnFloor.filter((feature) => feature.type === type));
      this.flashMessages.showAndHideFlash(
        'success',
        `${this.capitalizedResource} activated on this floor. Click Save.`,
      );
    }

    this.featuresOnFloor.forEach((feature) => {
      updateResourceOverview('update', feature);
    });
  }

  @action
  activateAllDesks(): void {
    if (!this.featureConfig.isEnabled('desks.allowEnabling')) return;

    if (this.totalDesksForActivation > this.maximumDesksForCurrentPlan) {
      this.showBulkEnableDesksInvalidTask.perform();
      return;
    }
    this.setAssignableValueOnDesks(true);
    this.flashMessages.showAndHideFlash('success', `${this.capitalizedResource} activated on this floor. Click Save.`);
  }

  deleteAllDesksOnFloor(): void {
    this.placedDesks.forEach((desk) => {
      desk.deleteRecord();
      if (desk.enabled) {
        this.activeDesksCount -= 1;
      }
    });
  }

  @action
  activateFeatures(features: MapFeatureModel[]): void {
    features.forEach((feature) => (feature.enabled = true));
  }

  @action
  deactivateFeatures(features: MapFeatureModel[]): void {
    features.forEach((feature) => (feature.enabled = false));
  }

  @action
  deleteEverything(): void {
    const {
      mapFloor,
      mapFeatures,
      setMapFeatures,
      deletableFeatures,
      setDeletableFeatures,
      onSelectedFeatureChange,
      updateResourceOverview,
    } = this.args;

    const type = this.selectedResource?.type;
    this.metrics.trackEvent('Deleting Everything', { type });
    let resourcesToDelete: MapFeatureModel[] = [];

    if (type) {
      if (type === 'desk') this.deleteAllDesksOnFloor();

      const featuresToDelete = this.featuresOnFloor.filter((feature) => feature.type === type);
      resourcesToDelete = [...deletableFeatures, ...featuresToDelete];
      setDeletableFeatures(resourcesToDelete);
      setMapFeatures(mapFeatures.filter((feature) => !featuresToDelete.includes(feature)));
    } else {
      this.deleteAllDesksOnFloor();
      resourcesToDelete = [...deletableFeatures, ...this.featuresOnFloor];
      setDeletableFeatures(resourcesToDelete);
      setMapFeatures(mapFeatures.filter((feature) => feature.belongsTo('mapFloor').id() !== mapFloor.id));
    }

    resourcesToDelete.forEach((feature) => {
      updateResourceOverview('delete', feature);
    });

    this.flashMessages.showAndHideFlash('success', `${this.capitalizedResource} deleted on this floor. Click Save.`);
    onSelectedFeatureChange(null);
  }

  @dropTask
  showBulkEnableDesksInvalidTask: {
    perform(): Generator<Promise<boolean>, void, void>;
  } = {
    *perform(this: {
      context: ResourceMapContainer;
      abort?: () => void;
      continue?: () => void;
    }): Generator<Promise<boolean>, void, void> {
      const deferred = defer<boolean>();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = () => {
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  @dropTask
  showMapFloorModalTask: {
    perform(isCreating: boolean): Generator<Promise<boolean>, void, void>;
    last?: MyTaskInstance<Promise<boolean>>;
  } = {
    *perform(
      this: {
        context: ResourceMapContainer;
        abort?: () => void;
        continue?: () => void;
      },
      isCreating = true,
    ): Generator<Promise<boolean>, void, void> {
      this.context.isCreatingMapFloor = isCreating;
      const deferred = defer<boolean>();

      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = () => {
        this.context.args.onSelectedFeatureChange(null);
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  @dropTask
  confirmDeactivateEverythingTask = {
    *perform(this: {
      context: ResourceMapContainer;
      abort?: () => void;
      continue?: () => void;
    }): Generator<Promise<boolean>, void, void> {
      const deferred = defer<boolean>();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = () => {
        this.context.deactivateEverything();
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  @dropTask
  confirmDeleteEverythingTask = {
    *perform(this: {
      context: ResourceMapContainer;
      abort?: () => void;
      continue?: () => void;
    }): Generator<Promise<boolean>, void, void> {
      const deferred = defer<boolean>();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = () => {
        this.context.deleteEverything();
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  @dropTask
  showMapFloorDeleteConfirmationTask: {
    perform(): Generator<Promise<boolean>, void, void>;
  } = {
    *perform(this: {
      context: ResourceMapContainer;
      abort?: () => void;
      continue?: () => Promise<void>;
    }): Generator<Promise<boolean>, void, void> {
      const self = this.context;

      self.showMapFloorModalTask.last?.abort();

      const deferred = defer<boolean>();

      this.abort = () => {
        deferred.resolve(false);
        self.showMapFloorModalTask.perform(false);
      };

      this.continue = async () => {
        const { mapFloor, isDraftMap } = self.args;
        try {
          await mapFloor.destroyRecord();
          this.context.metrics.trackEvent('Deleting Floor', { isDraft: true });
        } catch (e) {
          self.flashMessages.showAndHideFlash('error', 'Error deleting floor', parseErrorForDisplay(e));
        }

        self.showMapFloorModalTask.last?.continue();
        const route = isDraftMap ? 'spaces.maps.drafts.area-map' : 'spaces.maps.edit';
        void self.router.transitionTo(route);

        deferred.resolve(true);
      };

      return yield deferred.promise;
    },
  };
}

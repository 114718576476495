import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StatsigService from 'garaje/services/statsig';

export default class VfdWelcomeModal extends Component {
  @service declare currentAdmin: CurrentAdminService;
  @service declare router: RouterService;
  @service declare statsig: StatsigService;

  constructor(...args: unknown[]) {
    // @ts-ignore
    super(...args);
    this.statsig.logEvent('vfd-purchased-welcome-modal-viewed');
  }

  @action
  logDismiss(): void {
    this.statsig.logEvent('vfd-purchase-welcome-modal-dismissed');
  }

  @action
  async redirectToVfdLocations(): Promise<void> {
    this.statsig.logEvent('vfd-purchase-welcome-modal-setup-link-clicked');
    await this.router.replaceWith(this.router.currentRouteName, { queryParams: { vfdPurchased: null } });
    void this.router.transitionTo('location-overview.virtual-front-desk.locations');
  }
}

import { get } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { type SafeString } from '@ember/template/-private/handlebars';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { hash, task, lastValue } from 'ember-concurrency';
import type LocationModel from 'garaje/models/location';
import type NotificationPreferenceModel from 'garaje/models/notification-preference';
import type UserModel from 'garaje/models/user';
import _sortBy from 'lodash/sortBy';
import { localCopy } from 'tracked-toolbox';

interface NotificationPreferencesLoaderComponentSignature {
  Args: {
    /**
     * If true, a preference for email will always be returned, even if no matching plugin is present.
     */
    alwaysIncludeEmail?: boolean;
    /**
     * If true, a preference for SMS will always be returned, even if no matching plugin is present.
     */
    alwaysIncludeSms?: boolean;
    /**
     * One of `@location` or `@user` MUST be provided. `@location` has precedence if both are passed.
     */
    location?: LocationModel;
    notificationType: string;
    /**
     * One of `@location` or `@user` MUST be provided. `@location` has precedence if both are passed.
     */
    user?: UserModel;
  };
}

export type PluginPreference = {
  plugin: {
    iconSmall: SafeString;
    key: string;
    name: string;
  };
  preference: NotificationPreferenceModel;
};

export default class NotificationPreferencesLoaderComponent extends Component<NotificationPreferencesLoaderComponentSignature> {
  @service declare store: Store;

  @localCopy('args.notificationType', 'host_notification') declare notificationType: string;
  @lastValue('query') declare pluginPreferences: PluginPreference[] | undefined;

  constructor(owner: unknown, args: NotificationPreferencesLoaderComponentSignature['Args']) {
    super(owner, args);
    void this.query.perform();
  }

  get hasSomeEnabled(): boolean {
    const preferenceValues = this.query.lastSuccessful?.value ?? [];
    return preferenceValues.some((p) => p.preference.preferenceValue);
  }

  get modelType(): string {
    return this.args.location ? 'location' : 'user';
  }

  get model(): LocationModel | UserModel {
    return this.args.location ?? this.args.user!;
  }

  query = task(async () => {
    const { installedPlugins, notificationPreferences } = await hash({
      notificationPreferences: this.store.query('notification-preference', {
        filter: {
          relation_type: this.modelType,
          relation_id: get(this.model, 'id'), // eslint-disable-line ember/no-get
          notification_type: this.notificationType,
        },
      }),
      installedPlugins: this.store.query('plugin', {
        include: 'plugin',
        filter: {
          notification_channels: true,
        },
        page: {
          limit: 100,
        },
      }),
    });

    const preferences = _sortBy(installedPlugins.slice(), 'name').map((plugin) => {
      return {
        plugin: {
          iconSmall: plugin.iconSmall,
          key: plugin.key,
          name: plugin.name,
        },
        // There is no real relationship between plugin, model and
        // notification-preferences which force us to do the
        // binding manually.
        preference: this.findOrCreateNotificationPreference(plugin.key, notificationPreferences.slice()),
      };
    });

    if (this.args.alwaysIncludeSms && !installedPlugins.find((plugin) => plugin.key === 'sms')) {
      preferences.unshift({
        plugin: {
          iconSmall: htmlSafe('<img src="/assets/images/features/sms.svg" alt="" role="presentation">'),
          key: 'sms',
          name: 'SMS',
        },
        preference: this.findOrCreateNotificationPreference('sms', notificationPreferences.slice()),
      });
    }

    if (this.args.alwaysIncludeEmail && !installedPlugins.find((plugin) => plugin.key === 'email')) {
      preferences.unshift({
        plugin: {
          iconSmall: htmlSafe('<img src="/assets/images/features/email.svg" alt="" role="presentation">'),
          key: 'email',
          name: 'Email',
        },
        preference: this.findOrCreateNotificationPreference('email', notificationPreferences.slice()),
      });
    }

    return preferences;
  });

  findOrCreateNotificationPreference(
    pluginKey: string,
    notificationPreferences: NotificationPreferenceModel[],
  ): NotificationPreferenceModel {
    const preference = notificationPreferences.find((pref) => pref.notificationPlatformKey === pluginKey);

    if (preference) return preference;

    return this.store.createRecord('notification-preference', {
      notificationPlatformKey: pluginKey,
      notificationType: this.notificationType,
      preferenceValue: false,
      relationId: get(this.model, 'id'), // eslint-disable-line ember/no-get
      relationType: this.modelType,
    });
  }
}

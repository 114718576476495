import type EmberArray from '@ember/array';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type Task, type TaskInstance, task } from 'ember-concurrency';
import type TicketCategoryModel from 'garaje/models/ticket-category';
import type TicketConfigurationModel from 'garaje/models/ticket-configuration';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type StoreService from 'garaje/services/store';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

/**
 * @param {Class<TicketConfiguration>}  ticketConfiguration         Required - Ticket configuration model
 * @param {Array<TicketCategories>}     ticketCategories            Required - Exisitng categories
 * @param {Task}                        showCreateTicketModalTask   Required - Task that controls the modal
 */

interface CreateCategoryModalArgs {
  ticketConfiguration: TicketConfigurationModel;
  ticketCategories: EmberArray<TicketCategoryModel>;
  showCreateTicketModalTask: Task<void, []>;
}

interface MyTaskInstance extends TaskInstance<void> {
  continue: () => void;
  abort: () => void;
}

export default class CreateCategoryModalComponent extends Component<CreateCategoryModalArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;
  @service declare store: StoreService;

  @tracked newCategoryName = '';
  @tracked categoryNameError: Array<string> = [];

  @action
  inputInserted(element: HTMLElement): void {
    element.focus();
  }

  createCategoryTask = task({ drop: true }, async (closeModal) => {
    const { showCreateTicketModalTask, ticketConfiguration } = this.args;

    if (!this.newCategoryName) {
      this.categoryNameError = ['Please enter a category name'];
    } else if (this.isDuplicateName()) {
      this.categoryNameError = ['Category name already exists'];
    }

    if (!this.categoryNameError.length) {
      const ticketCategory = this.store.createRecord('ticket-category', {
        ticketConfiguration,
        name: this.newCategoryName,
      });
      try {
        await ticketCategory.save();

        this.metrics.trackEvent('Ticketing category created', {
          locationId: ticketConfiguration.belongsTo('location').id(),
          categoryName: this.newCategoryName,
        });

        this.flashMessages.showAndHideFlash('success', 'Category created successfully!');
      } catch (e) {
        this.flashMessages.showAndHideFlash('error', 'Error', parseErrorForDisplay(e));
      }

      this.newCategoryName = '';
      this.categoryNameError = [];
      if (closeModal) {
        const createTask = showCreateTicketModalTask.last as MyTaskInstance;
        createTask.continue();
      }
    }
  });

  createCategoryAndCloseTask = task({ drop: true }, async (e: Event) => {
    e.preventDefault();
    await this.createCategoryTask.perform(true);
  });

  @action
  onCancel(): void {
    const { showCreateTicketModalTask } = this.args;
    this.newCategoryName = '';
    this.categoryNameError = [];
    const createTask = showCreateTicketModalTask.last as MyTaskInstance;
    createTask.abort();
  }

  @action
  onCategoryNameChange(name: string): void {
    this.newCategoryName = name;
    if (this.isDuplicateName()) {
      this.categoryNameError = ['Category name already exists'];
    } else {
      this.categoryNameError = [];
    }
  }

  isDuplicateName(): boolean {
    const { ticketCategories } = this.args;
    return ticketCategories.any((category) => category.name.toLowerCase() === this.newCategoryName.toLowerCase());
  }
}

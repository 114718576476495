import Component from '@glimmer/component';

const SORT_HEADERS = Object.freeze({
  NAME: 'Name',
  LAST_SEEN: 'Last seen',
});

interface TableHeaderArgs {
  sortBy: string;
  sortAction: (field: string, direction: string) => void;
}

export default class TableHeader extends Component<TableHeaderArgs> {
  sortHeaders = SORT_HEADERS;

  get sortField(): string {
    return this.args.sortBy.replace(/^[-|+]/g, '');
  }

  get sortDirection(): string {
    return this.args.sortBy.startsWith('-') ? 'desc' : 'asc';
  }
}

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default class LocationOverviewSignInFlowsShowLegalDocumentsNewRoute extends Route {
  @service store;

  model() {
    const { agreementPage, flow, tracking, signInFields } = this.modelFor(
      'location-overview.sign-in-flows.show.legal-documents',
    );

    const agreement = this.store.createRecord('global-agreement', { agreementPage });

    return hash({
      agreement,
      agreementPage,
      flow,
      tracking,
      signInFields,
    });
  }
}

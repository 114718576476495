import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';

export default class VfdTrialEndModalComponent extends Component {
  @service declare statsig: StatsigService;
  @service declare state: StateService;
  @service declare currentAdmin: CurrentAdminService;

  @tracked showModal = false;

  constructor(...args: unknown[]) {
    // @ts-ignore
    super(...args);

    const { vfdSubscription } = this.state;
    const trialAlmostOver = (vfdSubscription?.onTrial && vfdSubscription?.trialDaysLeft < 4) ?? false;
    if (
      this.currentAdmin.isGlobalAdmin &&
      trialAlmostOver &&
      localStorage.getItem('vfd-trial-end-modal-dismissed') !== 'true'
    ) {
      this.showModal = true;
    }
  }

  @action dismiss(): void {
    this.statsig.logEvent('vfd-trial-end-modal-dismissed');
    localStorage.setItem('vfd-trial-end-modal-dismissed', 'true');
    this.showModal = false;
  }

  @action logCTAViewed(): void {
    this.statsig.logEvent('vfd-trial-end-modal-viewed');
  }

  get features(): string[] {
    return [
      'Video intercom on iPad kiosk',
      'Multi-channel staff notifications (Slack, Teams, SMS, email)',
      'Comprehensive call log',
      'Call transcripts',
      'Call routing (Coming soon)',
      'Hours of operation (Coming soon)',
      'Call insights (Coming soon)',
    ];
  }
}

import Controller from '@ember/controller';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import type CohoService from 'garaje/services/coho';
import { CohoWorkplaceEventNames } from 'garaje/services/coho';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import { APP } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { defer } from 'rsvp';

import type { CommunicationsEditTemplateModel } from './route';

export default class CommunicationsEditTemplateController extends Controller {
  declare model: CommunicationsEditTemplateModel;

  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;
  @service declare router: RouterService;
  @service declare coho: CohoService;

  saveTask = dropTask(async () => {
    try {
      const changeset = this.model.changeset;
      await changeset.validate();

      if (changeset.isInvalid) {
        return;
      }

      await changeset.save();

      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.coho.sendEvent(CohoWorkplaceEventNames.EMNOEditTemplate, { product: APP.WORKPLACE });
      this.metrics.trackEvent('Edited announcement template', { announcementTemplateId: changeset.id });
      void this.router.transitionTo('communications.templates.list');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  @dropTask
  deleteTask: {
    perform(): Generator<Promise<unknown>, unknown, unknown>;
  } = {
    *perform(this: {
      context: CommunicationsEditTemplateController;
      abort: () => void;
      continue: () => Promise<void>;
    }) {
      const deferred = defer();

      this.abort = () => deferred.resolve(false);
      this.continue = async () => {
        try {
          const announcementTemplate = this.context.model.announcementTemplate;
          const announcementTemplateId = announcementTemplate.id;

          await announcementTemplate.destroyRecord();

          this.context.flashMessages.showAndHideFlash('success', 'Deleted!');
          this.context.metrics.trackEvent('Deleted announcement template', { announcementTemplateId });
          void this.context.router.transitionTo('communications.templates');

          deferred.resolve(true);
        } catch (e) {
          this.context.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
        }
      };

      return yield deferred.promise;
    },
  };
}

import ApplicationSerializer from './application';

export default class extends ApplicationSerializer {
  serialize() {
    const json = super.serialize(...arguments);
    if (!json.data.attributes.password) {
      delete json.data.attributes.password;
    }
    return json;
  }
}

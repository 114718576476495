import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';

/**
 *
 * @param {Location} location
 */
export default class WebRegistrationComponent extends Component {
  @service store;

  /**
   * @type {boolean} Controls settings panel expand / collapse
   */
  @tracked isPanelOpen = false;

  @tracked access = null;

  constructor() {
    super(...arguments);
    this.loadUrlTask.perform();
  }

  @dropTask
  *loadUrlTask() {
    const result = yield this.store.query('protect-access-code', {
      filter: {
        location: this.args.location.id,
      },
    });
    this.access = result.firstObject;
  }

  @dropTask
  *generateUrlTask() {
    if (this.access) {
      yield this.access.destroyRecord();
    }
    const access = this.store.createRecord('protect-access-code', {
      allowNonEmployees: true,
      location: this.args.location,
    });
    yield access.save();
    this.access = access;
  }
}

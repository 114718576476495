import Service, { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type Store from '@ember-data/store';
import { addDays } from 'date-fns';
import { task, dropTask } from 'ember-concurrency';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type LocationBillingService from 'garaje/services/location-billing';
import type LocationsService from 'garaje/services/locations';
import type StateService from 'garaje/services/state';
import type SubscriptionsService from 'garaje/services/subscriptions';
import { APP, PLAN_LEVEL } from 'garaje/utils/enums';
import { startingSubscriptionPlanId } from 'garaje/utils/starting-subscription-plan-id';
import { alias } from 'macro-decorators';

export default class ProductActivationService extends Service {
  @service declare featureFlags: FeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare locationBilling: LocationBillingService;
  @service declare locations: LocationsService;
  @service declare state: StateService;
  @service declare store: Store;
  @service declare subscriptions: SubscriptionsService;

  @alias('state.visitorsSubscription') visitorsSubscription!: StateService['visitorsSubscription'];
  @alias('state.workplaceSubscription') workplaceSubscription!: StateService['workplaceSubscription'];
  @alias('state.currentCompany') currentCompany!: StateService['currentCompany'];
  @alias('state.currentLocation') currentLocation!: StateService['currentLocation'];
  @alias('state.currentUser') user!: StateService['currentUser'];

  startVisitorsTrialTask = dropTask(async () => {
    if (this.featureFlags.isEnabled('locationBilling')) {
      const subscriptionBatch = this.store.createRecord('subscription-batch', {
        company: this.currentCompany,
        products: [
          {
            'subscription-plan-id': startingSubscriptionPlanId({ app: APP.VISITORS, state: this.state }),
            trial: true,
          },
        ],
        status: 'pending',
      });
      const batchResp = await subscriptionBatch.save();
      await this.locationBilling.pollForSubscriptionBatchTask.perform(subscriptionBatch);

      return batchResp;
    } else {
      // Create VR Subscription
      const subscription = this.store.createRecord('subscription', {
        app: APP.VISITORS,
        plan: PLAN_LEVEL.PREMIUM,
        company: this.currentCompany,
      });
      return await subscription.save();
    }
  });

  startDesksTrialTask = dropTask(async () => {
    const desksSubscription = this.store.createRecord('subscription', {
      app: APP.DESKS,
      plan: PLAN_LEVEL.STANDARD,
      company: this.currentCompany,
    });
    const subResp = await desksSubscription.save();

    await this.createDeskLocationRecordTask.perform();

    return subResp;
  });

  startRoomsTrialTask = dropTask(async () => {
    if (this.featureFlags.isEnabled('locationBilling')) {
      const subscriptionBatch = this.store.createRecord('subscription-batch', {
        company: this.currentCompany,
        products: [
          {
            'subscription-plan-id': startingSubscriptionPlanId({ app: APP.ROOMS, state: this.state }),
            trial: true,
          },
        ],
        status: 'pending',
      });
      const batchResp = await subscriptionBatch.save();
      await this.locationBilling.pollForSubscriptionBatchTask.perform(subscriptionBatch);

      return batchResp;
    } else {
      const subscription = this.store.createRecord('subscription', {
        app: APP.ROOMS,
        plan: PLAN_LEVEL.STANDARD,
        company: this.currentCompany,
      });

      return await subscription.save();
    }
  });

  startDeliveriesTrialTask = dropTask(async () => {
    const currentLocation = this.state.currentLocation;

    const existingDeliveryAreas = await this.store.query('delivery-area', {
      location: currentLocation?.id,
    });

    const existingDeliveryArea = existingDeliveryAreas.toArray()[0];

    if (isPresent(existingDeliveryArea)) {
      return existingDeliveryArea;
    }

    await this.createDeliveryAreaRecordTask.perform();

    // Based on https://github.com/envoy/deliveries/blob/56a850946d6bb5cede6e143a7b7112c0e9a3da94/app/services/billing/company_subscription_manager.rb#L10
    const trialPeriod = 14;
    return {
      app: APP.DELIVERIES,
      trialDaysLeft: trialPeriod,
      trialEndDate: addDays(new Date(), trialPeriod),
      plan: PLAN_LEVEL.BASIC,
    };
  });

  startWorkplaceTrialTask = dropTask(async () => {
    const subscription = this.store.createRecord('subscription', {
      app: APP.WORKPLACE,
      plan: PLAN_LEVEL.PREMIUM,
      company: this.currentCompany,
    });
    await subscription.save();

    const existingDeliveryAreas = await this.store.query('delivery-area', {
      filter: { location: this.currentLocation?.id },
    });
    if (existingDeliveryAreas.length === 0) {
      await this.createDeliveryAreaRecordTask.perform();
    }
    await this.createDeskLocationRecordTask.perform();
  });

  createDeskLocationRecordTask = dropTask(async () => {
    const newDeskLocation = this.store.createRecord('desk-location', {
      active: true,
      timeZone: this.currentLocation?.timezone,
      company: this.currentCompany,
      location: this.currentLocation,
    });
    return await newDeskLocation.save();
  });

  createDeliveryAreaRecordTask = dropTask(async () => {
    const newDeliveryArea = this.store.createRecord('delivery-area', {
      address: this.currentLocation?.address,
      location: this.currentLocation,
    });

    return await newDeliveryArea.save();
  });

  enableDeliveriesTask = task(async () => {
    try {
      await this.findOrCreateDefaultDeliveryAreaTask.perform();
      this.flashMessages.showAndHideFlash('success', 'Deliveries enabled!');
      await this.state.initializeState.perform();
    } catch (_e) {
      this.flashMessages.showFlash('error', "We're sorry, something went wrong.");
    }
  });

  findOrCreateDefaultDeliveryAreaTask = dropTask(async () => {
    const currentLocation = this.state.currentLocation;

    const existingDeliveryAreas = await this.store.query('delivery-area', {
      location: currentLocation.id,
    });

    const existingDeliveryArea = existingDeliveryAreas.toArray()[0];

    if (isPresent(existingDeliveryArea)) {
      return existingDeliveryArea;
    }

    if (this.featureFlags.isEnabled('locationBilling')) {
      const locations = this.locations.active.map(({ id }) => ({ id, quantity: 1 }));
      const subscriptionBatch = this.store.createRecord('subscription-batch', {
        company: this.state.currentCompany,
        products: [
          {
            'subscription-plan-id': startingSubscriptionPlanId({ app: APP.DELIVERIES, state: this.state }),
            trial: false,
            locations,
          },
        ],
        status: 'pending',
      });
      await subscriptionBatch.save();
      await this.locationBilling.pollForSubscriptionBatchTask.perform(subscriptionBatch);
    }

    const newDeliveryArea = this.store.createRecord('delivery-area', {
      name: 'Default Delivery Area',
      address: currentLocation?.address,
      location: currentLocation,
    });

    return await newDeliveryArea.save();
  });
}

import Model, { attr, belongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

import type HomepageGroup from './homepage-group';
import type HomepageItem from './homepage-item';

@adapter('growth')
class HomepageItemGroupMapping extends Model {
  @attr('number')
  declare homepageItemRank: number;

  @attr('number')
  declare homepageItemWidth: number;

  @attr('number')
  declare homepageItemHeight: number;

  @attr('boolean')
  declare enabled: boolean;

  @belongsTo('homepage-item', { async: false })
  declare homepageItem: HomepageItem;

  @belongsTo('homepage-group', { async: false })
  declare homepageGroup: HomepageGroup;

  get width(): number {
    return this.homepageItem.width || this.homepageItemWidth;
  }

  get height(): number {
    return this.homepageItem.height || this.homepageItemHeight;
  }

  get rank(): number {
    return this.homepageItem.userDefinedRank || this.homepageItemRank;
  }
}

export default HomepageItemGroupMapping;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'homepage-item-group-mapping': HomepageItemGroupMapping;
  }
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import type Store from '@ember-data/store';
import { restartableTask, task, type TaskInstance, type Task } from 'ember-concurrency';
import type InfinityService from 'ember-infinity/services/infinity';
import ExtendedInfinityModel, { type PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type ConnectEmployeeModel from 'garaje/models/connect-employee';
import type TenantModel from 'garaje/models/tenant';
import type ZoneModel from 'garaje/models/zone';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

interface LoadDirectoryResponse {
  employees: PaginatedRecordArray<ConnectEmployeeModel>;
  params: DirectoryQueryParams;
}

export interface PropertyDirectoryIndexRouteModel {
  loadDirectoryTask: Task<LoadDirectoryResponse | undefined, [DirectoryQueryParams]>;
  loadTenantsTask: Task<PaginatedRecordArray<TenantModel>, [search?: string]>;
  allTenantsTaskInstance: TaskInstance<PaginatedRecordArray<TenantModel>>;
  property: ZoneModel;
}

export interface DirectoryQueryParams {
  pageNumber: number;
  pageSize: number;
  sortBy: string;
  sortDirection: string;
  queryName: string;
  tenantIds: string;
  roleTypes: string;
}

const ORDER_DESC_VALS = ['Z-A', 'Most to least recent'];

export default class PropertyDirectoryIndexRoute extends Route {
  @service declare store: Store;
  @service declare infinity: InfinityService;
  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;

  queryParams = {
    pageNumber: { refreshModel: true },
    sortBy: { refreshModel: true },
    sortDirection: { refreshModel: true },
    queryName: { refreshModel: true },
    tenantIds: { refreshModel: true },
    roleTypes: { refreshModel: true },
  };

  beforeModel(): void {
    const property = <ZoneModel>this.modelFor('property');
    this.#checkParamValidity(property);
  }

  model(params: DirectoryQueryParams): PropertyDirectoryIndexRouteModel {
    void this.loadDirectoryTask.perform(params);
    const allTenantsTaskInstance = this.loadTenantsTask.perform();
    const property = <ZoneModel>this.modelFor('property');

    return {
      loadDirectoryTask: this.loadDirectoryTask,
      loadTenantsTask: this.loadTenantsTask,
      allTenantsTaskInstance,
      property,
    };
  }

  loadDirectoryTask = restartableTask(async (params: DirectoryQueryParams) => {
    const { pageSize, pageNumber, sortBy, sortDirection, queryName, tenantIds, roleTypes } = params;
    const offset = pageSize * pageNumber - pageSize;
    const property = <ZoneModel>this.modelFor('property');

    const orderDescending = ORDER_DESC_VALS.includes(sortDirection);
    const sortOrder = orderDescending ? '-' : '';

    const filter: Record<string, unknown> = {};

    if (!isEmpty(queryName)) {
      filter['query-name'] = queryName;
    }

    if (!isEmpty(tenantIds)) {
      filter['tenant-roles.tenant'] = tenantIds;
    } else {
      filter['property'] = property.id;
    }

    if (!isEmpty(roleTypes)) {
      filter['tenant-roles.role-type'] = roleTypes;
    }

    try {
      const employees = <PaginatedRecordArray<ConnectEmployeeModel>>await this.store.query('connect-employee', {
        include: 'tenant-roles.tenant,connect-visitor-contacts',
        filter,
        page: {
          limit: pageSize,
          offset,
        },
        sort: `${sortOrder}${sortBy.toLowerCase()}`,
      });

      return { employees, params, property };
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      return;
    }
  });

  loadTenantsTask = task(async (search?: string) => {
    const { allTenantsTaskInstance, property } =
      <PropertyDirectoryIndexRouteModel | undefined>this.modelFor(this.routeName) ?? {};
    const currentProperty = <ZoneModel>this.modelFor('property');
    const tenantUnchanged = currentProperty.id === property?.id;

    if (allTenantsTaskInstance?.value && tenantUnchanged && isEmpty(search)) return allTenantsTaskInstance.value;

    const filter: Record<string, unknown> = { property: currentProperty.id };

    if (!isEmpty(search)) {
      filter['name'] = search;
    }

    const tenants = await this.infinity.model(
      'tenant',
      {
        include: 'tenant-permissions-configuration',
        filter,
        sort: '-tenant-permissions-configuration.employees-read,name',
        perPage: 20,
        perPageParam: 'page[limit]',
        pageParam: 'page[offset]',
        startingPage: 0,
        countParam: 'meta.total',
      },
      ExtendedInfinityModel,
    );

    return tenants;
  });

  #checkParamValidity(currentProperty: ZoneModel) {
    const lastSuccess = this.loadDirectoryTask.lastSuccessful?.value;

    if (!lastSuccess) return;
    const { property: lastProperty } = lastSuccess;

    if (lastProperty.id !== currentProperty.id) {
      void this.router.replaceWith('property.directory.index', {
        queryParams: {
          pageNumber: 1,
          tenantIds: '',
          queryName: '',
          roleTypes: '',
          sortBy: 'Name',
          sortDirection: 'A-Z',
        },
      });
    }
  }
}

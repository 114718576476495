import { action } from '@ember/object';
import Component from '@glimmer/component';
import type { Task } from 'ember-concurrency';
import { restartableTask, timeout } from 'ember-concurrency';
import zft from 'garaje/utils/zero-for-tests';
import { localCopy } from 'tracked-toolbox';

interface BlocklistFiltersSearchInputComponentSignature {
  Args: {
    /**
     * Name of input, used for testing only
     */
    name: string;
    /**
     * The value of the search-input
     */
    value: string;
    /**
     * Placeholder text for UI-INPUT component
     */
    placeholder: string;
    /**
     * When to show the clear button
     */
    showClear: boolean;
    /**
     * When to show the input itself
     */
    enabled: boolean;
    /**
     * Task to send filterText to parent component
     */
    onChange: Task<void, [string]>;
  };
}

/**
 * Contextual component used for creating and editing basic BlocklistFilter information
 */
export default class BlocklistFiltersSearchInputComponent extends Component<BlocklistFiltersSearchInputComponentSignature> {
  @localCopy('args.value') value?: string;

  /**
   * Needed to ensure search input is focused
   */
  delayedFocus = restartableTask(async () => {
    const blocklistFilterSearchInput = document.getElementById('blocklist-search-input');
    if (blocklistFilterSearchInput) {
      await timeout(zft(500));
      blocklistFilterSearchInput.focus();
    }
  });

  /**
   * Clears the search text
   */
  @action
  clearSearchText(): void {
    this.value = '';
    void this.args.onChange.perform(this.value);
  }
}

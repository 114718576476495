const PAIRABLE_PRINTERS = [
  {
    printerModel: 'Brother QL-820NWB',
    printerImage: '/assets/images/printers/brother-ql820nwb.png',
    bluetoothEnabled: true,
  },
  {
    printerModel: 'Brother QL-720NW',
    printerImage: '/assets/images/printers/brother-ql720nw.png',
    bluetoothEnabled: false,
  },
  {
    printerModel: 'Brother QL-710W',
    printerImage: '/assets/images/printers/brother-ql710w.png',
    bluetoothEnabled: false,
  },
];

export default PAIRABLE_PRINTERS;

export type PairablePrinter = (typeof PAIRABLE_PRINTERS)[number];

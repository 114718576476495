import { assert } from '@ember/debug';
import { isBlank } from '@ember/utils';
import { computed, get } from '@ember/object';

/**
 * Compare the two app versions.
 * Returns -1 if the first is lower than the second, 1 if it is greater, or 0 if they are the same.
 * Useful for passing to Array.prototype.sort().
 *
 * @function
 * @param {string} a - app version (in "X.Y.Z" format)
 * @param {string} b - app version (in "X.Y.Z" format)
 */
function appVersionComparator(a, b) {
  const isValid = isAppVersionValid(a) && isAppVersionValid(b);
  assert('App versions must be valid.', isValid);

  const aParts = a.split('.').map((number) => parseInt(number, 10));
  const bParts = b.split('.').map((number) => parseInt(number, 10));

  for (let index = 0; index < aParts.length; index++) {
    if (aParts[index] > bParts[index]) {
      return 1;
    }
    if (aParts[index] < bParts[index]) {
      return -1;
    }
  }

  return 0;
}

/**
 * Higher order function that returns a computed property that can be used to check if an app update is required
 *
 * @function
 * @param {string} dependentKey - dependent key where the iPads are location. `Ex: model.ipads.@each{isIpad,appVersion}`
 * @param {string} minimumIpadVersionNeeded - Number of the iPad version
 */

function isAppUpdateRequired(dependentKey, minimumIpadVersionNeeded) {
  return computed(`${dependentKey}.@each{isIpad,appVersion}`, function () {
    // return true if at least one iPad does not meet required app version
    const versions = (get(this, dependentKey) ?? [])
      .filter((ipad) => get(ipad, 'isIpad'))
      .map((ipad) => get(ipad, 'appVersion'));

    const areAllIpadVersionsValid = versions.every((version) => isAppVersionValid(version));
    if (!areAllIpadVersionsValid) {
      return false;
    }
    return versions.any((version) => !isAppVersionGreaterEqual(version, minimumIpadVersionNeeded));
  });
}

/**
 * If the app version is invalid, return false
 * Return true if the current app version is greater or equal than the target app version
 * Otherwise, return false
 *
 * @function
 * @param {string} currentAppVersion - The current app version in string format
 * @param {string} targetAppVersion - The target app version in string format
 */
function isAppVersionGreaterEqual(currentAppVersion, targetAppVersion) {
  return appVersionComparator(currentAppVersion, targetAppVersion) >= 0;
}

/**
 * Return true if app version string is valid
 *
 * @function
 * @param {string} appVersion - The app version in string format
 */
function isAppVersionValid(appVersion) {
  if (isBlank(appVersion)) {
    return false;
  }
  return RegExp(/^\d+(\.\d+){0,2}\.\d+$/).test(appVersion);
}

export { appVersionComparator, isAppVersionValid, isAppVersionGreaterEqual, isAppUpdateRequired };

import Controller from '@ember/controller';
import { inject as controller } from '@ember/controller';
import { action } from '@ember/object';

export default class AppStoreNoPathController extends Controller {
  @controller apps;

  get iframeUrl() {
    return this.apps.iframeUrl;
  }

  get context() {
    return this.apps.context;
  }

  @action
  trackLoggedIn() {
    return this.apps.trackLoggedIn();
  }
}

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AnnouncementsLandingPageComponent extends Controller {
  @service workplaceMetrics;

  /**
   * @type {boolean}
   */
  @tracked shouldShowNewAnnouncementModal = false;

  @action
  showNewAnnouncementModal() {
    this.workplaceMetrics.trackEvent('NEW_ANNOUNCEMENT_BUTTON_CLICKED', {
      location: 'announcements_landing_page',
    });
    this.shouldShowNewAnnouncementModal = true;
  }

  @action
  hideNewAnnouncementModal() {
    this.workplaceMetrics.trackEvent('NEW_ANNOUNCEMENT_MODAL_CLOSED', {
      location: 'announcements_landing_page',
    });
    this.shouldShowNewAnnouncementModal = false;
  }
}

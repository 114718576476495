import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type GlobalOverviewAccessService from 'garaje/services/global-overview-access';

export default class LocationOverviewManageAuditLogRoute extends Route {
  @service declare globalOverviewAccess: GlobalOverviewAccessService;
  @service declare router: RouterService;

  titleToken = 'Audit Log';

  beforeModel(): void {
    if (!this.globalOverviewAccess.canVisitAuditLog) {
      void this.router.transitionTo('unauthorized');
    }
  }
}

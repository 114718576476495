import DeskFragment from 'garaje/graphql/fragments/employee-schedule/ScheduleDeskFragment';
import gql from 'graphql-tag';

export default gql`
  fragment ReservationFragment on Reservation {
    desk {
      ...DeskFragment
    }
    id
    startTime
    endTime
    checkInTime
    checkOutTime
    isPartialDay
  }
  ${DeskFragment}
`;

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import type GroupModel from 'garaje/models/group';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';

export default class LocationOverviewVirtualFrontDeskCallLogController extends Controller {
  @service declare abilities: AbilitiesService;
  @service declare skinnyLocations: SkinnyLocationsService;
  @service declare store: StoreService;

  queryParams = ['from', 'until', 'location', 'page', 'sortBy', 'sortDirection'];

  @tracked from: string = '';
  @tracked until: string = '';
  @tracked location: string = '';

  @tracked page = 1;
  @tracked sortBy = 'started-at';
  @tracked sortDirection = 'desc';

  get startDate(): Date | null {
    return this.from ? new Date(this.from) : null;
  }

  get endDate(): Date | null {
    return this.until ? new Date(this.until) : null;
  }

  set startDate(date: Date) {
    // Use `toJSON` to avoid invalid date errors
    this.from = date.toJSON();
  }

  set endDate(date: Date) {
    // Use `toJSON` to avoid invalid date errors
    this.until = date.toJSON();
  }

  get groups(): GroupModel[] {
    if (!this.abilities.can('view locations-grouping')) return [];

    return this.store.peekAll('group').toArray();
  }

  @action
  handleDatesChanged(startDate: Date, endDate: Date): void {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  @action
  handlePage(page: number): void {
    this.page = page;
  }

  @action
  handleSort(sortBy: string, sortDirection: 'asc' | 'desc'): void {
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
  }
}

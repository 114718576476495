import gql from 'graphql-tag';

export default gql`
  fragment CompanyFragment on Company {
    id
    name
    pairingCode
    cancelUnattendedRecurringMeeting
    roomCheckInEnabled
    settingsAccessCode
    roomResizerEnabled
    onsiteRoomRecaptureEnabled
    devicesTimeFormat
  }
`;

import Service, { service } from '@ember/service';
import type cookies from 'ember-simple-auth/session-stores/cookies';

const PROTECTED_METHODS = ['POST', 'PUT', 'PATCH', 'DELETE'];

type CsrfToken = string | null | undefined;

interface HeadersHash {
  headers: Record<string, string>;
}

export default class CookieAuthService extends Service {
  @service declare cookies: cookies;

  // Decorator mean to be used with ember-fetch
  decorate(method: string, hash: HeadersHash): HeadersHash {
    const csrfToken: CsrfToken = this.cookies.read('csrf_token') as CsrfToken;
    const isProtectedMethod = PROTECTED_METHODS.includes(method);

    if (csrfToken) {
      // explicitly type-checking what was already happening in runtime
      if (!hash['headers']) {
        throw Error('No headers in hash object.');
      }

      hash.headers['Authorization'] = '';
      if (isProtectedMethod) {
        hash.headers['X-CSRF-Token'] = csrfToken;
      }
    }

    return hash;
  }

  jQueryDecorator(_url: string, method: string, hash: Record<string, unknown>): Record<string, unknown> {
    hash['xhrFields'] = { withCredentials: true };
    const csrfToken: CsrfToken = this.cookies.read('csrf_token') as CsrfToken;
    const isProtectedMethod = PROTECTED_METHODS.includes(method);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { beforeSend } = hash;
    hash['beforeSend'] = (xhr: Record<string, unknown>) => {
      if (csrfToken) {
        // @ts-ignore
        xhr.setRequestHeader('Authorization', '');
        if (isProtectedMethod) {
          // @ts-ignore
          xhr.setRequestHeader('X-CSRF-Token', csrfToken);
        }
      }
      if (beforeSend) {
        // @ts-ignore to preserve historical runtime code
        beforeSend(xhr);
      }
    };
    return hash;
  }
}

import { service } from '@ember/service';
import Component from '@glimmer/component';
import type AbilitiesService from 'ember-can/services/abilities';
import type FlowModel from 'garaje/models/flow';
import type SignInFieldActionRuleGroupModel from 'garaje/models/sign-in-field-action-rule-group';

interface RulesListArgs {
  handleDelete: (rule: SignInFieldActionRuleGroupModel) => unknown;
  handleEdit: (rule: SignInFieldActionRuleGroupModel) => unknown;
  flow: FlowModel;
  isGlobalChild: boolean;
  isLocationAdmin: boolean;
  isProtect: boolean;
  rules: SignInFieldActionRuleGroupModel[];
}

export default class RulesList extends Component<RulesListArgs> {
  @service declare abilities: AbilitiesService;

  get canAddRule(): boolean {
    // cannot add rules if this flow is a child of a global flow
    if (this.args.isGlobalChild) return false;

    // If this is a Protect flow, user cannot add rules if they're only a location admin.
    // This is pre-custom-role logic built on the assumption that this component would only
    // ever be available to Global Admin or Location Admin users, and Location Admins would
    // not be able to add rules. With custom roles currently partially implemented, it's
    // not clear whether this assumption still holds.
    if (this.args.isProtect && this.args.isLocationAdmin) return false;

    // otherwise, user can add rules if they're able to edit this flow in general
    return this.abilities.can('edit visitor-type', this.args.flow);
  }

  get canDeleteRule(): boolean {
    // cannot delete rules if this flow is a child of a global flow
    if (this.args.isGlobalChild) return false;

    // If this is a Protect flow, user cannot delete rules if they're only a location admin.
    // This is pre-custom-role logic built on the assumption that this component would only
    // ever be available to Global Admin or Location Admin users, and Location Admins would
    // not be able to add rules. With custom roles currently partially implemented, it's
    // not clear whether this assumption still holds.
    if (this.args.isProtect && this.args.isLocationAdmin) return false;

    // otherwise, user can add rules if they're able to edit this flow in general
    return this.abilities.can('edit visitor-type', this.args.flow);
  }

  get canEditRule(): boolean {
    // cannot edit rules if this flow is a child of a global flow
    if (this.args.isGlobalChild) return false;

    // otherwise, user can edit rules if they're able to edit this flow in general
    return this.abilities.can('edit visitor-type', this.args.flow);
  }
}

import Route from '@ember/routing/route';
import Ember from 'ember';
import DS from 'ember-data';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { getStatusCode, getErrorDescription, getErrorDetail } from 'garaje/utils/parse-error';
import { scheduleOnce } from '@ember/runloop';
import config from 'garaje/config/environment';
const { AdapterError, ServerError, TimeoutError } = DS;

class ErrorRoute extends Route {
  @service metrics;
  @service online;
  @service router;
  @service sessionStorage;

  setupController(_controller, error) {
    super.setupController(...arguments);
    if (
      error instanceof AdapterError ||
      error instanceof ServerError ||
      error instanceof TimeoutError ||
      error instanceof TypeError ||
      error instanceof SyntaxError ||
      error instanceof Error
    ) {
      this._handleTracking(error);
      /* eslint-disable ember/no-incorrect-calls-with-inline-anonymous-functions */
      scheduleOnce('afterRender', () => {
        if (config.environment !== 'test') {
          Ember.onerror(error);
        }
      });
      /* eslint-enable ember/no-incorrect-calls-with-inline-anonymous-functions */
    }
  }

  _handleTracking(error) {
    const props = {
      current_url: this.router.currentURL,
      http_status_code: getStatusCode(error),
      error_description: getErrorDescription(error),
      error_detail: getErrorDetail(error),
      online_status: this.online.status,
    };
    this.metrics.trackEvent('Error State Reached', props);
    this.metrics.logMonitorError({ event: 'ERROR_STATE_REACHED', debugExtras: props });
    const errorStateEnteredAt = this.sessionStorage.getItem('error_state_entered_at');
    if (isEmpty(errorStateEnteredAt)) {
      this.sessionStorage.setItem('error_state_entered_at', Date.now());
    }
  }
}

export default ErrorRoute;

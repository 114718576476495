import CompanyFragment from 'garaje/graphql/fragments/roomba/CompanyFragment';
import gql from 'graphql-tag';

export default gql`
  mutation UpdateRoomsCompanyRoomResizerEnabled($companyId: ID!, $roomResizerEnabled: Boolean!) {
    updateRoomsCompanyRoomResizerEnabled(companyId: $companyId, roomResizerEnabled: $roomResizerEnabled) {
      ...CompanyFragment
    }
  }
  ${CompanyFragment}
`;

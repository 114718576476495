import { helper } from '@ember/component/helper';
import { get } from '@ember/object';
import { isPresent } from '@ember/utils';

export function groupBy([groupObjPath, groupAttr, array]) {
  const groups = [];

  if (array) {
    array.forEach(function (item) {
      const currentItem = get(item, groupObjPath);
      const group = groups.find((g) => {
        return g.item[groupAttr] === currentItem[groupAttr];
      });

      if (isPresent(group)) {
        group.items.push(item);
      } else {
        groups.push({ item: currentItem, items: [item] });
      }
    });
  }

  return groups;
}
export default helper(groupBy);

import Controller from '@ember/controller';
import { service } from '@ember/service';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type SigmaUrlService from 'garaje/services/sigma-url';
import type StateService from 'garaje/services/state';
import type UserFeatureFlagsService from 'garaje/services/user-feature-flags';
import urlBuilder from 'garaje/utils/url-builder';

export default class GlobalAnalyticsDesksIndexController extends Controller {
  @service declare state: StateService;
  @service declare flashMessages: FlashMessagesService;
  @service declare sigmaUrl: SigmaUrlService;
  @service declare userFeatureFlags: UserFeatureFlagsService;

  get iframeUrl(): Promise<string | undefined> {
    const companyId = this.state?.currentCompany?.id;

    if (!companyId) {
      return Promise.resolve(undefined);
    }

    return this.getSigmaIframeUrl(companyId);
  }

  get legacyIframeUrl(): string {
    const companyId = this.state?.currentCompany?.id;

    return urlBuilder.embeddedDesksGlobalAnalyticsUrl(companyId);
  }

  async getSigmaIframeUrl(companyId: string): Promise<string | undefined> {
    try {
      return this.sigmaUrl.getGlobalSigmaUrl(companyId, 'desks');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error loading dashboard');
      // eslint-disable-next-line no-console
      console.error(e);
      return;
    }
  }
}

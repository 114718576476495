import Route from '@ember/routing/route';
import { hash } from 'rsvp';

import type { VisitorTypesFlowRouteModel } from '../route';

export default class VisitorsSettingsVisitorTypesFlowPlusOneRoute extends Route {
  model(): Promise<Pick<VisitorTypesFlowRouteModel, 'flow' | 'vrSubscription' | 'currentLocation'>> {
    const { flow, vrSubscription, currentLocation } = <VisitorTypesFlowRouteModel>(
      this.modelFor('visitors.settings.visitor-types.flow')
    );

    return hash({
      flow,
      vrSubscription,
      currentLocation,
    });
  }
}

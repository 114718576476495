import { registerDestructor } from '@ember/destroyable';
import config from 'garaje/config/environment';

// This instance initializer exists to clear the MockUiHooks data when a new instance comes up.
// This happens between individual tests, for example. Since the MockUiHooks cache is set up in
// an initializer (not an instance initializer) it is not set up/torn down between individual tests.
// We can use an instance initialize to attach a destructor to the app instance and perform cleanup
// at the end. We need to do it at the end, rather than the beginning, because Mirage scenarios
// populate the MockUiHooks service when Mirage itself is started in an initializer, and so if we
// called 'reset' during initializion here, we would get rid of that data.
export function initialize(appInstance) {
  if (config.mirageEnabled && window._GarajeUiHookResponses) {
    registerDestructor(appInstance, () => {
      window._GarajeUiHookResponses.reset();
    });
  }
}

export default {
  initialize,
  name: 'mock-ui-hooks-reset',
};

import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class LocationOverviewSignInFlowsLegalDocumentsNewController extends Controller {
  @action
  addNewLegalDocument() {
    this.send('refresh');
    window.scrollTo(0, 0);
  }
}

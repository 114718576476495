import { service } from '@ember/service';
import Component from '@glimmer/component';
import type FeatureFlagsService from 'garaje/services/feature-flags';

export default class IntegrationsMenuComponent extends Component {
  @service declare featureFlags: FeatureFlagsService;

  get showAppsSettings(): boolean {
    return (
      this.featureFlags.isEnabled('show-acs-failure-alert-toggle') ||
      this.featureFlags.isEnabled('show-data-missing-alert-toggle')
    );
  }
}

import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { isBlank, isPresent } from '@ember/utils';
import _intersection from 'lodash/intersection';
import { inject as service } from '@ember/service';
import { alias, equal } from 'macro-decorators';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { cached } from 'tracked-toolbox';
import { filterOptions } from 'garaje/utils/decorators/filter-options';

/**
 * List of selectable locations (`Checkboxes`) with search capabilities.
 * @param {Array<Group>}       groups              List of available groups
 */
export default class GlobalFlowLocationsModal extends Component {
  @service skinnyLocations;
  @service flow;
  @service state;
  @service store;
  @service flashMessages;
  @service currentAdmin;
  @service infinityPubsub;

  @tracked selectedLocations = [];
  @tracked unselectedLocations = [];
  @tracked searchFilter = '';

  @alias('skinnyLocations.manageableByCurrentAdmin') locations;

  @equal('selectedLocations.length', 0) noneSelected;

  constructor() {
    super(...arguments);
    this.selectedLocations = this.args.globalFlowObj.data.locations.toArray();
  }

  /**
   * @return {Boolean}
   */
  get allSelected() {
    return this.locations?.length === this.selectedLocations?.length;
  }

  /**
   * @return {Boolean}
   */
  get isDirty() {
    return this.args.globalFlowObj.locations?.length != this.selectedLocations?.length;
  }

  /**
   * This CP filters the `this.options` using the `this.searchFilter`
   *
   * @return {Array<Location>}
   */
  @cached
  @filterOptions()
  filteredOptions;

  /**
   * This CP builds an array of `options` with a structure easier to loop through.
   *
   * @return {Array<{ isParent: Boolean, location: Location | group: Group, state: String, name: String }>}
   */
  @cached
  get options() {
    const options = [];
    // All the locations that do not belong to a group
    const locationsWithoutGroup = this.locations.filter((location) => !location.group || isBlank(this.args.groups));
    locationsWithoutGroup.forEach((location) =>
      options.push({ name: location.nameWithCompanyName, location, isParent: false }),
    );

    if (isPresent(this.args.groups)) {
      // All the available groups with their states
      this.args.groups.forEach((group) => {
        if (group.totalLocations > 0) {
          const option = { isParent: true, name: group.name, group, state: this._groupState(group) };

          option.children = group.locations
            .filter((groupLocation) => this.locations.includes(groupLocation))
            .map((groupLocation) => {
              return { name: groupLocation.nameWithCompanyName, location: groupLocation, isParent: false };
            });
          if (option.children.length > 0) {
            options.push(option);
          }
        }
      });
    }
    options.sort((op1, op2) => op1.name - op2.name);
    return options;
  }

  /**
   * Handles the `group` selected action. If the `group` is in an `indeterminate` state, we only send the already
   * selected locations as we want to clear the group. Otherwise we send all locations for that group.
   *
   * @param {Object}  optionGroup
   */
  @action
  onGroupSelected(optionGroup) {
    const { state, children } = optionGroup;
    let toSelect = children;
    if (state === 'indeterminate') {
      toSelect = children.filter(({ location }) => {
        return this.selectedLocations.findIndex((selected) => selected.id === location.id) > -1;
      });
    }
    toSelect.forEach((locationOption) => {
      if (!locationOption.roleName) {
        this.onLocationToggled(locationOption.location);
      }
    });
  }

  /**
   * Determines the state of a given `group` based on the `selectedLocations`
   * @param {Group}            group
   * @return {String | null}
   *                           `checked`       if all the locations of this group are selected
   *                           `indeterminate` if at least one location is selected
   */
  _groupState(group) {
    const gropLocationIds = get(group, 'locations').map((location) => location.id);
    const selectedLocationIds = this.selectedLocations.map((location) => location.id);
    const totalIntersected = _intersection(gropLocationIds, selectedLocationIds).length;
    // If the difference is empty it means all locations are selected
    if (totalIntersected === group.totalLocations) {
      return 'checked';
    } else if (totalIntersected > 0) {
      return 'indeterminate';
    }
  }

  /**
   * Handles the selection or removal of a location
   *
   * @param {object} location LocationModel
   * @param {MouseEvent} _evt Location checkbox click event
   */
  @action
  onLocationToggled(location, _evt) {
    const selectedLocations = this.selectedLocations;
    if (selectedLocations.findIndex((selected) => selected.id === location.id) < 0) {
      this.selectedLocations = [location, ...selectedLocations];
    } else {
      this.unselectedLocations.push(location);
      const newSelectedLocations = this.selectedLocations.filter((selected) => selected.id !== location.id);
      this.selectedLocations = newSelectedLocations;
    }
    if (this.state.currentLocation.id === location.id) {
      this.shouldReloadCurrentLocation = !this.shouldReloadCurrentLocation;
    }
  }

  @action
  onDeselectAll() {
    this.selectedLocations = [];
  }

  @action
  onSelectAll() {
    this.selectedLocations = [...this.skinnyLocations.manageableByCurrentAdmin];
  }

  @task
  *onSaveTask() {
    try {
      const selectedLocationIds = this.selectedLocations.mapBy('id');
      const globalFlow = yield this.store.findRecord('global-flow', this.args.globalFlowObj.id, {
        include: 'locations,flows',
      });
      const locations = yield this.store.query('skinny-location', { filter: { id: selectedLocationIds.join(',') } });
      globalFlow.locations = locations;
      yield globalFlow.save();
      this.args.close();
      this.infinityPubsub.trigger('flow-refresh');
      this.infinityPubsub.trigger('global-flow-refresh');
    } catch (err) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(err));
    }
  }
}

import gql from 'graphql-tag';

export default gql`
  fragment LocationFragment on Location {
    name
    id
    roomsShouldEmailGlobalAdmins
    roomsShouldEmailLocationAdmins
    roomsNotificationEmails
  }
`;

import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type DeviceModel from 'garaje/models/device';
import type LocationModel from 'garaje/models/location';
import type StateService from 'garaje/services/state';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

import type SettingsWelcomeScreenMultipleLanguagesController from './controller'; // TODO: refactor to avoid neeing to use resetController (and thus import this type)

interface SettingsWelcomeScreenMultipleLanguagesRouteModel {
  ipads: RecordArray<DeviceModel>;
  location: LocationModel;
}

// NOTE: this file is extended by a vfd settings route
export default class SettingsWelcomeScreenMultipleLanguagesRoute extends Route {
  @service declare state: StateService;

  titleToken = 'Multiple languages';

  model(): Promise<SettingsWelcomeScreenMultipleLanguagesRouteModel> {
    const location = <LocationModel>this.modelFor('current-location');
    const { ipads } = <{ ipads: RecordArray<DeviceModel> }>this.modelFor('visitors.settings');
    return hash({ location, ipads });
  }

  resetController(controller: SettingsWelcomeScreenMultipleLanguagesController, isExiting: boolean): void {
    if (isExiting) {
      controller.isEditing = false;
      controller.isShowingAddedMessage = false;
    }
  }
}

import { helper } from '@ember/component/helper';

type Options = {
  /**
   * Optional currency code to use for formatting the value. Defaults to 'USD' if not specified.
   */
  currency?: string;

  /**
   * Controls whether or not a value with 0 cents should include the cents portion.
   * For example, given the value 1234;
   * - with `removeZeroCents: true`, returns '$1234'.
   * - with `removeZeroCents: false` (or unspecified), returns '$1234.00'
   */
  removeZeroCents?: boolean;
};
export function formattedCurrency(input: number | string, options: Options = {}): string {
  let value: number;
  if (typeof input === 'number') {
    value = input;
  } else {
    value = parseFloat(input);
  }

  if (isNaN(value)) {
    return '';
  }

  const formatOptions: Intl.NumberFormatOptions = {
    currency: options.currency ?? 'USD',
    currencySign: 'accounting',
    style: 'currency',
    // @ts-ignore
    trailingZeroDisplay: options.removeZeroCents ? 'stripIfInteger' : 'auto',
  };

  const numberFormat = new Intl.NumberFormat('en-US', formatOptions);
  return numberFormat.format(value);
}

export default helper(function ([input]: [input: number | string], options: Options = {}) {
  return formattedCurrency(input, options);
});

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { alias, or, lte, and } from 'macro-decorators';

export default class RoombaWrapperController extends Controller {
  @service declare metrics: MetricsService;
  @service declare state: StateService;

  @alias('state.roomsSubscription')
  declare roomsSubscription: StateService['roomsSubscription'];
  @or('roomsSubscription.onExpiredTrial', 'onTrialExpiringSoon')
  declare showTrialBanner: boolean;
  @lte('roomsSubscription.trialDaysLeft', 14)
  declare trialExpiringSoon: boolean;
  @and('roomsSubscription.onTrial', 'trialExpiringSoon')
  declare onTrialExpiringSoon: boolean;
  @alias('state.realRoomsSubscription')
  declare realRoomsSubscription: StateService['roomsSubscription'];

  get roomsTrialWarningHeader(): string | null {
    if (this.roomsSubscription && this.onTrialExpiringSoon) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `You have ${this.roomsSubscription?.trialDaysLeft} days remaining in your free trial`;
    } else if (this.roomsSubscription?.onExpiredTrial) {
      return 'Your trial has expired';
    } else {
      return null;
    }
  }
  get roomsTrialWarningBody(): string | null {
    if (this.onTrialExpiringSoon) {
      return 'To continue using Rooms once your trial has ended, please click Purchase to add your payment method.';
    } else if (this.roomsSubscription?.onExpiredTrial) {
      return `You’ll need to purchase rooms to reactivate your service.`;
    } else {
      return null;
    }
  }
  get ctaTrackingEventOptions(): Record<string, unknown> {
    return {
      // @ts-ignore where does app come from?
      product: this.roomsSubscription?.app,
      cta_id: 'rooms_trial_banner',
      cta_type: 'banner',
      cta_title: this.roomsTrialWarningHeader,
      cta_body: this.roomsTrialWarningBody,
      cta_clickable_type: 'button',
      cta_clickable_text: 'Purchase',
    };
  }
  @action
  trackTrialBannerCTAViewed(): void {
    this.metrics.trackEvent('cta_viewed', this.ctaTrackingEventOptions);
  }
  @action
  trackTrialBannerCTAClicked(): void {
    this.metrics.trackEvent('cta_clicked', this.ctaTrackingEventOptions);
  }
}

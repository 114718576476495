/* eslint-disable ember/no-component-lifecycle-hooks */
/* eslint-disable ember/classic-decorator-hooks */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { dropTask, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';
import { action, setProperties, set } from '@ember/object';
import { toUp, toDown } from 'ember-animated/transitions/move-over';
import { isBlank } from '@ember/utils';
import _sample from 'lodash/sample';

import config from 'garaje/config/environment';
import urlBuilder from 'garaje/utils/url-builder';
const DATA_POLLING_TIMEOUTS = [3500, 4500, 6500, 7500, 8500];

export default class DashboardProductBoxVisitorsComponent extends Component {
  @service ajax;
  @service metrics;
  @service state;
  @service session;
  @service featureFlags;

  @alias('state.currentLocation.preRegistrationEnabled') preRegistrationEnabled;

  init() {
    super.init(...arguments);
    if (this.subscription) {
      this.initTask.perform();
      set(this, 'pollingInstance', this.pollForChangesTask.perform());
    }
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    if (this.pollingInstance) {
      this.pollingInstance.cancel();
    }
  }

  /**
   * @return {EmberAnimated Transition}
   */
  rules({ oldItems, newItems }) {
    return oldItems[0] < newItems[0] ? toDown : toUp;
  }

  /**
   * initTask
   * Task used for the initial loading state
   */
  @dropTask
  *initTask() {
    yield this.fetchBoxDataTask.perform();
  }

  /**
   * pollForChangesTask
   */
  @dropTask
  *pollForChangesTask() {
    while (config.environment !== 'test') {
      yield timeout(_sample(DATA_POLLING_TIMEOUTS));
      yield this.fetchBoxDataTask.perform();
    }
  }

  /**
   * fetchBoxDataTask
   */
  @dropTask
  *fetchBoxDataTask() {
    const url = urlBuilder.v2.visitorsLandingPage(this.location.id);
    try {
      const {
        data: {
          'currently-signed-in': currentlySignedIn,
          'currently-signed-in-change': currentlySignedInChange,
          'expected-today': expectedToday,
          'expected-today-change': expectedTodayChange,
        },
      } = yield this.ajax.request(url);
      setProperties(this, { currentlySignedIn, currentlySignedInChange, expectedToday, expectedTodayChange });
    } catch (error) {
      if (error && error.status === 401) {
        this.pollingInstance.cancel();
        yield this.session.invalidate();
      }
      /* eslint-disable no-console */
      console.log({ error });
      /* eslint-enable no-console */
      if (isBlank(this.currentlySignedIn)) {
        setProperties(this, {
          currentlySignedIn: 0,
          currentlySignedInChange: 0,
          expectedToday: 0,
          expectedTodayChange: 0,
        });
      }
    }
  }

  @action
  setStarted(step) {
    set(step, 'started', true);
  }

  /**
   * Handles `3-Up Start Trial Button Clicked` tracking
   * @param {Event} evt
   */
  @action
  trackStartTrialClicked(_evt) {
    this.metrics.trackEvent('3-Up Start Trial Button Clicked', { product: 'visitors' });
  }
}

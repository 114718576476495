import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import type UserDocumentService from 'garaje/services/user-document';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import { hash } from 'rsvp';

interface ProfileRouteModel {
  user: CurrentAdminService['user'];
  userDocumentTemplateConfigurations: UserDocumentService['userDocumentTemplateConfigurations'];
}

export default class ProfileRoute extends Route {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;
  @service('user-document') declare userDocumentService: UserDocumentService;

  titleToken = 'Profile';

  async model(): Promise<ProfileRouteModel> {
    if (!this.userDocumentService.initialize.lastSuccessful?.value) {
      try {
        await (this.userDocumentService.initialize.lastRunning
          ? this.userDocumentService.initialize.lastRunning
          : this.userDocumentService.initialize.perform());
      } catch (e) {
        throwUnlessTaskDidCancel(e);
      }
    }

    return hash({
      user: this.currentAdmin.user.reload(),
      userDocumentTemplateConfigurations: this.userDocumentService.userDocumentTemplateConfigurations,
    });
  }
}

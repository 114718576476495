import LocationFragment from 'garaje/graphql/fragments/roomba/LocationFragment';
import gql from 'graphql-tag';

export default gql`
  mutation UpdateRoomsLocationNotificationEmails($locationId: ID!, $notificationEmails: [String!]) {
    updateRoomsLocationNotificationEmails(locationId: $locationId, notificationEmails: $notificationEmails) {
      ...LocationFragment
    }
  }
  ${LocationFragment}
`;

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';

import type { PrinterConnectionsRouteModel } from '../route';

export default class VisitorsDevicesPrintersConnectionsNew extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  beforeModel(): void {
    if (this.abilities.cannot('create printer-connections')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  model(): PrinterConnectionsRouteModel {
    return this.modelFor('visitors.devices.printers.connections') as PrinterConnectionsRouteModel;
  }
}

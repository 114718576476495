import ApplicationSerializer from './application';

export default class GroupInviteSerializer extends ApplicationSerializer {
  attrs = {
    jobId: {
      serialize: false,
    },
    creator: {
      serialize: false,
    },
    childInviteLocations: {
      serialize: false,
    },
  };

  keyForRelationship(key: string, relationship: string, method: string): string {
    if (key === 'creator') return 'user';

    return super.keyForRelationship(key, relationship, method);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'group-invite': GroupInviteSerializer;
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { all, dropTask } from 'ember-concurrency';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
/**
 * @param {Model<Location>}           location
 */
export default class VisitorEmailsWelcomeEmail extends Component {
  @service flashMessages;
  @service metrics;

  @tracked isOpen = false;
  @tracked isEditing = true;
  @tracked isPreviewing = false;
  @tracked showUnsavedEditsWarning = false;
  @tracked visitorTypes;

  constructor() {
    super(...arguments);
    this.loadVisitorTypes.perform();
  }

  @dropTask
  *loadVisitorTypes() {
    try {
      const visitorTypes = yield get(this.args.location, 'flows');
      this.visitorTypes = visitorTypes.filter((vt) => vt.enabled);
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', e);
    }
  }

  @dropTask
  *saveTask() {
    try {
      const dirtyVisitorTypes = this.visitorTypes.filter((vt) => vt.hasDirtyAttributes);
      const hasDirtyWelcomeEmailPreference = this.args.location.hasDirtyWelcomeEmailPreference();
      if (hasDirtyWelcomeEmailPreference || dirtyVisitorTypes.length) {
        yield this.args.location.save();
        yield all(dirtyVisitorTypes.map((vt) => vt.save()));
        this.metrics.trackEvent('Welcome Email Edited - Location', {
          location_id: this.args.location.id,
          location_name: this.args.location.name,
          recipients_edited: hasDirtyWelcomeEmailPreference,
        });
      }
      this.isOpen = false;
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', e);
    }
  }

  @action
  toEditing() {
    this.isEditing = true;
    this.isPreviewing = false;
    this.showUnsavedEditsWarning = false;
  }

  @action
  toPreviewing() {
    this.isEditing = false;
    this.isPreviewing = true;
    this.showUnsavedEditsWarning = false;
  }

  @action
  onContinue() {
    if (this.args.location.hasDirtyAttributes) {
      this.args.location.rollbackAttributes();
    }
    if (this.args.location.hasDirtyFlows()) {
      this.visitorTypes.forEach((vt) => vt.rollbackAttributes());
    }
    this.showUnsavedEditsWarning = false;
    this.isOpen = false;
  }

  @action
  cancel() {
    if (this.args.location.hasDirtyAttributes || this.args.location.hasDirtyFlows()) {
      this.showUnsavedEditsWarning = true;
    } else {
      this.onContinue();
    }
  }
}

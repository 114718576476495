import DS from 'ember-data';

const { JSONAPISerializer } = DS;

export default class PaymentSourceSerializer extends JSONAPISerializer {
  attrs = {
    brand: {
      serialize: false,
    },
    last4: {
      serialize: false,
    },
    expiryMonth: {
      serialize: false,
    },
    expiryYear: {
      serialize: false,
    },
  };

  extractErrors(store, typeClass, payload) {
    payload.errors?.forEach((error) => {
      // handle missing error source as ember data internals now expects them
      if (!error.source) error.source = { pointer: '/data' };
    });

    return super.extractErrors(...arguments);
  }
}

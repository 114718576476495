import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { Changeset } from 'ember-changeset';
import { type DetailedChangeset } from 'ember-changeset/types';
import { dropTask } from 'ember-concurrency';
import type ConnectFloorModel from 'garaje/models/connect-floor';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import { cached } from 'tracked-toolbox';

import { type PropertySettingsFloorsNewRouteModel } from './route';

export default class PropertySettingsFloorsNewController extends Controller {
  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: StoreService;

  declare model: PropertySettingsFloorsNewRouteModel;

  @cached
  get floorChangeset(): DetailedChangeset<ConnectFloorModel> {
    return Changeset(this.model.floor);
  }

  saveTask = dropTask(async (addAnother?: boolean) => {
    const floor = this.floorChangeset;
    try {
      await floor.save();
      this.flashMessages.showAndHideFlash('success', `Floor "${floor.name}" has been added.`);
      await this.model.floors.update();
      if (addAnother) {
        this.model.floor = this.store.createRecord('connect-floor', {
          company: this.state.currentCompany,
          property: this.model.property,
        });
      } else {
        void this.router.transitionTo('property.settings.floors');
      }
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      this.flashMessages.showAndHideFlash('error', 'Something went wrong, please try again.');
    }
  });

  @action
  cancel(): void {
    void this.router.transitionTo('property.settings.floors');
  }
}

import Component from '@glimmer/component';
import { reads, gt } from 'macro-decorators';

/**
 * @param {Class<Entry|Invite>}  content
 */
export default class BlocklistColumn extends Component {
  @reads('args.content.approvalStatus.status') status;
  @reads('args.content.approvalStatus.reviewerName') reviewerName;
  @reads('args.content.approvalStatus.reviewedAt') reviewedAt;
  @gt('args.content.approvalStatus.blocklistReport.length', 0) isEnabled;

  get isBlocklistEntry() {
    const enabled = this.isEnabled;
    if (!enabled) {
      return false;
    }

    const reports = this.args.content?.approvalStatus.blocklistReport;
    return reports[0]?.result === 'fail';
  }
}

import GenericResponseFragment from 'garaje/graphql/fragments/employee-schedule/GenericResponseFragment';
import gql from 'graphql-tag';

export default gql`
  mutation DeleteInviteReservations($inviteId: ID!, $reservationIds: [ID!]!) {
    deleteInvite(id: $inviteId) {
      id
    }
    releaseDeskReservations(reservationIds: $reservationIds) {
      ...GenericResponseFragment
    }
  }
  ${GenericResponseFragment}
`;

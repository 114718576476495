import Controller, { inject as controller } from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { dropTask, task, restartableTask, lastValue } from 'ember-concurrency';
import { DELIVERIES_PAUSED_ALERT_TITLE, DELIVERIES_PAUSED_ALERT_MESSAGE } from 'garaje/models/delivery-limit';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { alias, or, and, equal, reads } from 'macro-decorators';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import urlBuilder from 'garaje/utils/url-builder';

const DEFAULT_PAGE_SIZE = 10;

export default class BillingIndexController extends Controller {
  @service metrics;
  @service state;
  @service featureFlags;
  @service flashMessages;
  @service skinnyLocations;
  @service store;
  @service impressions;
  @service billing;
  @service router;

  @controller('billing.subscribe') subscribeController;

  queryParams = ['showPlan', 'vfdPurchased'];

  addPaymentMethodCTAText = 'Add a payment method';

  @alias('state.billingCompany.hasChildren') hasChildren;

  @tracked isEditingCC = false;
  @tracked isChargeBeeModal = false;
  @tracked paymentSourceToDelete = null;
  @tracked needReloadPaymentSources = false;
  @tracked showPlan = null;
  @tracked showContactBdrBanner = true;
  @tracked hostedPageLink = '';

  @tracked showPlanCostEstimateModal = false;
  @tracked planCostEstimateModalContent = null;

  @tracked viewLocationsApp = null;
  @tracked showContactsModal = false;

  @tracked showVfdWelcomeModal = this.shouldShowVfdWelcomeModal;

  @tracked pageNumber = 1;
  @tracked pageSize = DEFAULT_PAGE_SIZE;

  @lastValue('fetchPayments') payments;

  @or(
    'model.vrSubscription.isSubscribed',
    'model.realDeliveriesSubscription.isSubscribed',
    'model.realRoomsSubscription.isSubscribed',
    'model.realDesksSubscription.isSubscribed',
    'model.workplaceSubscription.isSubscribed',
  )
  isSubscribed;
  @alias('model.paymentSources') paymentSources;
  @alias('model.paymentSource') paymentSource;
  @alias('state.billingCompany.blockSelfServe') blockSelfServe;
  @equal('paymentSources.length', 1) onlyOnePaymentSource;
  @and('isSubscribed', 'onlyOnePaymentSource') cannotDeletePaymentSource;

  @reads('payments.meta.page-count') pageCount;
  @reads('payments.meta.record-count') recordCount;

  deliveriesPausedAlertTitle = DELIVERIES_PAUSED_ALERT_TITLE;
  deliveriesPausedAlertMessage = DELIVERIES_PAUSED_ALERT_MESSAGE;

  // We need to get the subscription having the CC information
  get subscriptionForCC() {
    if (isPresent(this.model.vrSubscription)) {
      return this.model.vrSubscription;
    } else if (isPresent(this.model.deliveriesSubscription)) {
      return this.model.deliveriesSubscription;
    } else if (isPresent(this.model.roomsSubscription)) {
      return this.model.roomsSubscription;
    } else if (isPresent(this.model.desksSubscription)) {
      return this.model.desksSubscription;
    } else {
      return this.model.workplaceSubscription;
    }
  }

  get workplaceActiveUsersIframe() {
    const companyId = get(this.model, 'company.id');
    return urlBuilder.embeddedWorkplaceActiveUsersModalUrl(companyId, true);
  }

  get shouldShowActiveUsersChart() {
    return this.featureFlags.isEnabled('billing-workplace-active-users-chart') && !!this.model.workplaceSubscription;
  }

  get shouldShowInsightsSection() {
    return this.model.planEstimates;
  }

  get shouldShowVfdWelcomeModal() {
    return this.vfdPurchased === 'true';
  }

  @action
  didInsertElement() {
    const queryParams = this.router.currentRoute.queryParams;
    if (queryParams.editAddress) {
      this.scrollToAddress();
      this.router.replaceWith({ queryParams: { editAddress: null } });
    }
  }

  @action scrollToAddress() {
    const section = document.getElementById('billing-address-section');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }

  @restartableTask
  *fetchPayments() {
    const companyId = get(this.model, 'company.id');

    this.store.unloadAll('payment');

    const payments = yield this.store.query('payment', {
      filter: { company: companyId },
      page: { number: this.pageNumber, size: this.pageSize },
    });

    return payments;
  }

  @dropTask
  *makePrimaryPaymentSource(ps) {
    try {
      const oldPrimary = this.paymentSources.find((source) => source.isPrimary);
      ps.paymentSourceRole = 'primary';
      ps.stripeTempToken = null;
      yield ps.save();

      if (oldPrimary) {
        set(oldPrimary, 'isPrimary', false);
      }
      this.flashMessages.showAndHideFlash('success', 'Payment source is set as primary!');
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showFlash('error', errorText);
    }
  }

  @dropTask
  *reloadPaymentSources() {
    if (this.needReloadPaymentSources) {
      const reloadPaymentSources = yield this.billing.loadPaymentSources.perform(true);
      set(this, 'paymentSources', reloadPaymentSources);
      set(this, 'paymentSource', reloadPaymentSources.firstObject);
      this.send('refreshModel');

      this.needReloadPaymentSources = false;
    }
  }

  @action
  updatePageNumber(pageNumber) {
    this.pageNumber = pageNumber;
    this.fetchPayments.perform();
  }

  @action
  addCreditCardClicked() {
    this.isEditingCC = true;
    this.logCreditCardButtonClicked.perform();
  }

  logCreditCardButtonClicked = task({ drop: true }, async () => {
    try {
      await this.impressions.postImpression.perform(IMPRESSION_NAMES.ADD_CREDIT_CARD_CLICKED);
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', e);
    }
  });

  get ctaTrackingEventOptions() {
    return {
      product: this.model.deliveriesSubscription.app,
      cta_id: 'deliveries_paused_banner',
      cta_type: 'banner',
      cta_clickable_type: 'button',
      cta_clickable_text: 'Upgrade',
      cta_title: 'Your package scanning has been paused',
      cta_body: 'You’ve hit the 100 package limit and need to upgrade to Deliveries Premium to continue scanning.',
      cta_intent: 'upgrade',
    };
  }

  @action
  trackDeliveriesUpgradeBannerViewed() {
    this.metrics.trackEvent('CTA Viewed', this.ctaTrackingEventOptions);
  }

  @action
  trackDeliveriesUpgradeBannerClicked() {
    this.metrics.trackEvent('CTA Clicked', this.ctaTrackingEventOptions);
  }

  @action
  closeContactBdrBanner() {
    this.showContactBdrBanner = false;
  }

  @action
  openChargeBeeWalletModal() {
    this.isEditingCC = false;
    this.isChargeBeeModal = true;
  }

  @action
  closeChargeBeeWalletModal() {
    this.isChargeBeeModal = false;
    this.reloadPaymentSources.perform();
  }

  @action
  closeDeletePaymentMethodModal() {
    this.paymentSourceToDelete = null;
    this.reloadPaymentSources.perform();
  }

  @action
  closePaymentMethodModal() {
    this.isEditingCC = false;
    this.reloadPaymentSources.perform();
  }

  @action
  openPlanCostEstimateModal(content) {
    this.planCostEstimateModalContent = content;
    this.showPlanCostEstimateModal = true;
  }

  @action
  closePlanCostEstimateModal() {
    this.showPlanCostEstimateModal = false;
  }

  @action
  openViewLocationsModal(app) {
    this.viewLocationsApp = app;
  }

  @action
  closeViewLocationsModal() {
    this.viewLocationsApp = null;
  }

  @action
  openContactsModal() {
    this.showContactsModal = true;
  }

  @action
  closeContactsModal() {
    this.showContactsModal = false;
  }

  @action
  closeVfdWelcomeModal() {
    this.showVfdWelcomeModal = false;
    this.router.replaceWith({ queryParams: { vfdPurchased: null } });
  }
}

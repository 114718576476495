import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class PaymentHistory extends Component {
  @service store;
  @service state;

  @tracked invoices = [];

  constructor() {
    super(...arguments);
    const companyId = this.state.currentCompany.id;
    this.invoices = this.store.query('invoice', { filter: { company: companyId } });
  }
}

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { task } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';
import Papa from 'papaparse';

/**
 * @param {Number} importedCount            - number of records that have been imported so far
 * @param {Task}   saveBlocklistFiltersTask - ember-concurrency task to `perform` to save blocklist filters
 */
export default class BlocklistCsvImportComponent extends Component {
  @service('blocklistFilterImporter') importer;
  @service flashMessages;
  @service store;

  @tracked fileName = '';
  @tracked records = [];

  get csvFile() {
    const csvData = Papa.unparse(this.records, {
      header: false,
      columns: [
        'id',
        'fullName',
        'description',
        'reason',
        'aliases',
        'emails',
        'phoneNumbers',
        'otherKeywords',
        'imageUrl',
        'externalId',
      ],
    });
    const blob = new Blob([csvData]);
    return new File([blob], 'block-list.csv', { type: 'text/csv' });
  }

  @action
  clearBlocklistFilters() {
    this.fileName = '';
    this.records = [];
  }

  @task
  *importBlocklistFiltersTask(file) {
    this.fileName = file.name;
    const result = yield this.importer.importRecordsFromCsvFile.perform(file);
    this.records = result.filter(Boolean);
    const wrongRows = result.length - this.records.length;
    if (wrongRows > 0 && this.records.length > 0) {
      const message = `${pluralize(wrongRows, 'row')} ${wrongRows > 1 ? 'were' : 'was'} not loaded`;
      this.flashMessages.showFlash(
        'error',
        message,
        'Please check that all rows in your file have a reason for blocking and at least one matching field',
      );
    }
  }

  @task
  *saveBlocklistFiltersTask() {
    try {
      yield this.args.saveBlocklistFiltersTask.perform(this.csvFile);
    } catch {
      this.clearBlocklistFilters();
    }
  }
}

/* eslint-disable ember/no-computed-properties-in-native-classes */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action, computed, get, set, setProperties } from '@ember/object';
import { equal, alias, empty } from '@ember/object/computed';
import { service } from '@ember/service';
import { next } from '@ember/runloop';
import { resolve } from 'rsvp';
import { dropTask } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';

/**
 * @param {String}                    integration
 * @param {Array}                     filterOptions
 * @param {Array}                     directoryGroups
 * @param {Array}                     locations
 * @param {Model<Company>}            company
 * @param {Boolean}                   canEdit
 * @param {Function}                  save
 * @param {Function}                  handleFailedQuery
 * @param {Function}                  hideFlash
 * @param {Function}                  showFlash
 * @param {Function}                  addFilter
 * @param {Function}                  removeFilter
 * @param {Function}                  preview
 * @param {Boolean}                   previewEnabled
 */
export default class CompanyFilter extends Component {
  @service flashMessages;
  @service syncManager;

  @equal('integration.filterType', 'company') isActive;
  @alias('isActive') isOpen;
  @empty('company.employeesLastSyncAt') neverSynced;

  filterOperations = [
    { name: 'equals', value: '=' },
    { name: 'contains', value: '=~' },
  ];

  filters = [];

  @computed('integration.filters.[]', 'filterOptions.[]')
  get availableFilters() {
    const filters = get(this, 'integration.filters').mapBy('name');

    return get(this, 'filterOptions').reject(function (filter) {
      return filters.indexOf(filter.name) >= 0;
    });
  }

  @computed('locations.[]')
  get companyHasOneLocation() {
    return get(this, 'locations.length') === 1;
  }

  doSearch(query, deferred) {
    get(this, 'searchGroup')(query, deferred);
  }

  setFilter() {
    return get(this, 'integration').save();
  }

  setFilterGroup(filter, { group_id }) {
    set(filter, 'value', group_id);
  }

  @dropTask
  *saveTask() {
    return yield this.setFilter().then(() => {
      set(this, 'search', null);
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    });
  }

  @dropTask
  *testTask() {
    this.hideFlash?.();

    let promise;

    if (!get(this, 'integration.filters.length')) {
      this.flashMessages.showAndHideFlash('error', 'Select at least 1 filter!');

      promise = resolve(false);
    } else {
      promise = yield get(
        this,
        'preview',
      )(get(this, 'integration')).then(
        (data) => {
          set(this, 'search', { results: data });
        },
        (response) => {
          get(this, 'handleFailedQuery')(response);
        },
      );
    }
    return promise;
  }

  @action
  activate() {
    if (get(this, 'canEdit') && !get(this, 'isActive')) {
      set(this, 'search', null);
      set(this, 'integration.filterType', 'company');

      const attrs = get(this, 'integration').changedAttributes();

      if (get(this, 'integration.hasDirtyAttributes') && !attrs.filterType) {
        get(this, 'integration').rollbackAttributes();
      } else {
        set(this, 'integration.filters', new TrackedArray());
      }
    }
  }

  @action
  cancel() {
    get(this, 'integration').rollbackAttributes();
    setProperties(this, { search: null });
  }

  @action
  _addFilter(filterName) {
    const filter = get(this, 'availableFilters').findBy('name', filterName);

    next(() => {
      get(this, 'addFilter')(filter);
    });
  }
}

import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_MANAGE_VISITOR_EMAILS = [GLOBAL_ADMIN, LOCATION_ADMIN];

export default class VisitorEmailAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;

  get canVisit(): boolean {
    return this.canManageVisitorGuide || this.canManageSurveyEmail || this.canManageWelcomeEmail;
  }

  get canVisitFlow(): boolean {
    return this.canManageSurveyEmail || this.canManageWelcomeEmail;
  }

  get canManageSurveyEmail(): boolean {
    return this.canManageVisitorEmails;
  }

  get canCustomizeEmail(): boolean {
    if (!this.canManageVisitorEmails) return false;

    const { vrSubscription, currentCompany } = this.state;
    return Boolean(
      this.featureFlags.isEnabled('email-customization', {
        createdAt: currentCompany.createdAt?.getTime(),
      }) &&
        (vrSubscription?.isPremiumPlan || vrSubscription?.isEnterprisePlan),
    );
  }

  get canManageVisitorEmails(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_MANAGE_VISITOR_EMAILS, roleNames));
  }

  get canManageWelcomeEmail(): boolean {
    if (!this.featureFlags.isEnabled('lobbyXpTfvEmail')) {
      return false;
    }
    const hasFeatureFlag = this.featureFlags.isEnabled('welcomeEmail');
    return hasFeatureFlag && this.canManageVisitorEmails;
  }

  get canManageVisitorGuide(): boolean {
    if (this.featureFlags.isEnabled('lobbyXp') && !this.featureFlags.isEnabled('lobbyXpTfvEmail')) {
      return false;
    }
    return this.canManageVisitorEmails;
  }
}

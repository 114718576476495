import { helper } from '@ember/component/helper';
import { isRTLLocale } from 'garaje/utils/locale-options';

/**
 * @param {String}           locale
 */

function isRtlLocale([locale]) {
  return isRTLLocale(locale);
}

export default helper(isRtlLocale);

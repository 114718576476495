import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type { GetRoomsLocationWithConfigsQuery } from 'garaje/graphql/generated/roomba-types';
import type CompanyConfig from 'garaje/models/company-config';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import type RoombaMetricsService from 'garaje/services/roomba-metrics';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import toOxfordComma from 'garaje/utils/to-oxford-comma';
import uniq from 'lodash/uniq';

const SUCCESS_MESSAGE = 'Saved!';

/**
 * @param {Model<CompanyConfig>}                companyConfig
 * @param {Model<RoomLocationConfig>}           roomLocationConfig
 */
export default class RoombaSettingsIpadStatusComponent extends Component<{
  roomLocationConfig: GetRoomsLocationWithConfigsQuery['location'];
  companyConfig: CompanyConfig;
}> {
  @service declare flashMessages: FlashMessagesService;
  @service declare roombaMetrics: RoombaMetricsService;
  @service declare state: StateService;
  @service declare roombaGraphql: RoombaGraphqlService;

  @tracked isEditing = false;

  get sentenceFragments(): string[] {
    const fragments = [];

    const {
      roomsShouldEmailGlobalAdmins: globalAdminNotificationsEnabled,
      roomsShouldEmailLocationAdmins: locationAdminNotificationsEnabled,
      roomsNotificationEmails: notificationEmails,
    } = this.args.roomLocationConfig;

    if (globalAdminNotificationsEnabled) {
      fragments.push('global admins');
    }

    if (locationAdminNotificationsEnabled) {
      fragments.push('location admins');
    }

    if (notificationEmails?.length) {
      fragments.push('specified email addresses');
    }

    return fragments;
  }

  get dynamicDescription(): string {
    if (!this.sentenceFragments.length) {
      return 'Device notifications are currently disabled.';
    }
    return `Device notifications will be sent to ${toOxfordComma(this.sentenceFragments)}.`;
  }

  private get commonMetricsParameters(): { [property: string]: unknown } {
    return {
      locationId: this.state.currentLocation?.id,
    };
  }

  toggleLocalAdminNotifications = task({ drop: true }, async () => {
    try {
      const config = this.args.roomLocationConfig;
      this.roombaMetrics.trackEvent('device_notifications_for_local_admins_toggled', {
        locationAdminNotificationsEnabled: !config.roomsShouldEmailLocationAdmins,
        globalAdminNotificationsEnabled: config.roomsShouldEmailGlobalAdmins,
        notificationEmails: config.roomsNotificationEmails,
        ...this.commonMetricsParameters,
      });

      await this.roombaGraphql.updateRoomsLocationShouldEmailLocationAdmins(
        config.id,
        !config.roomsShouldEmailLocationAdmins,
      );

      this.flashMessages.showAndHideFlash('success', SUCCESS_MESSAGE);
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  toggleGlobalAdminNotifications = task({ drop: true }, async () => {
    try {
      const config = this.args.roomLocationConfig;

      this.roombaMetrics.trackEvent('device_notifications_for_global_admins_toggled', {
        locationAdminNotificationsEnabled: config.roomsShouldEmailLocationAdmins,
        globalAdminNotificationsEnabled: !config.roomsShouldEmailGlobalAdmins,
        notificationEmails: config.roomsNotificationEmails,
        ...this.commonMetricsParameters,
      });

      await this.roombaGraphql.updateRoomsLocationShouldEmailGlobalAdmins(
        config.id,
        !config.roomsShouldEmailGlobalAdmins,
      );

      this.flashMessages.showAndHideFlash('success', SUCCESS_MESSAGE);
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  saveEmail = task({ drop: true }, async (emails: string[]) => {
    try {
      const uniqueEmails = uniq(emails);
      this.roombaMetrics.trackEvent('device_notifications_additional_emails_updated', {
        additionalEmails: uniqueEmails,
        ...this.commonMetricsParameters,
      });

      await this.roombaGraphql.updateRoomsLocationNotificationEmails(this.args.roomLocationConfig.id, uniqueEmails);

      this.flashMessages.showAndHideFlash('success', SUCCESS_MESSAGE);
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  @action
  toggleEditing(): void {
    this.isEditing = !this.isEditing;
  }
}

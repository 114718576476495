import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { underscore } from '@ember/string';
import { isEmpty } from '@ember/utils';
import eventsLogger from 'garaje/utils/decorators/events-logger';
import { next } from '@ember/runloop';
import { service } from '@ember/service';

/**
 * @param {Model<MapFeature>}           feature
 * @param {Function}                    removeFeature
 * @param {Function}                    back
 * @param {Array}                       floorMapFeatures
 * @param {boolean}                     isDraftMap
 */
@eventsLogger
class VisitorAreaForm extends Component {
  @service featureFlags;

  @tracked visitorAreaNameError = [];
  @tracked inputElement;

  @action
  inputInserted(element) {
    this.element = element;
    this.focusInput();
  }

  @action
  focusInput() {
    next(() => {
      this.element.focus();
    });
  }

  @action
  validate() {
    const { feature, floorMapFeatures } = this.args;
    const changedAttrs = feature.changedAttributes();
    const existingVisitorAreasNames = floorMapFeatures
      .filter((f) => f.type === 'visitor-area' && f !== feature)
      .mapBy('name');

    if (isEmpty(feature.name) && (Object.keys(changedAttrs).includes('name') || feature.hasError || !feature.isValid)) {
      this.visitorAreaNameError = ['Please enter a visitor area name'];
      set(feature, 'hasError', true);
    } else {
      if (existingVisitorAreasNames.includes(feature.name)) {
        this.visitorAreaNameError = ['Visitor area name already exists'];
        set(feature, 'hasError', true);
      } else {
        this.visitorAreaNameError = [];
        set(feature, 'hasError', false);
      }
    }
  }

  @action
  toggleEnabled(state) {
    const { feature, isDraftMap } = this.args;
    set(feature, 'enabled', state);

    if (state) {
      this.logEnabled({
        resource_type: underscore(feature.type),
        isDraft: isDraftMap,
      });
    } else {
      this.logDisabled({
        resource_type: underscore(feature.type),
        isDraft: isDraftMap,
      });
    }
  }

  @action
  onVisitorAreaNameChange(name) {
    const { feature, updateResourceOverview } = this.args;
    set(feature, 'name', name);

    if (updateResourceOverview) {
      updateResourceOverview('update', feature);
    }
    this.validate();
  }
}

export default VisitorAreaForm;

import Model, { belongsTo } from '@ember-data/model';
import type User from 'garaje/models/user';

import type ZoneModel from './zone';

export default class BlacklistContactUser extends Model {
  @belongsTo('contact-configurable', { polymorphic: true }) declare contactConfigurable: ZoneModel;
  @belongsTo('user') declare user: User;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'blacklist-contact-user': BlacklistContactUser;
  }
}

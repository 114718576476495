import { service } from '@ember/service';
import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import type Store from '@ember-data/store';
import type MailerSectionModel from 'garaje/models/mailer-section';
import _isEqual from 'lodash/isEqual';
import { cached } from 'tracked-toolbox';

export interface MailerBlockSettingAttribute {
  [attribute: string]: MailerBlockSettingAttributeValues;
}

export interface AttributeValueOption {
  identifier: string;
  name: string;
}

export interface MailerBlockSettingAttributeValues {
  type: string;
  title: string;
  input_validation: 'string';
  locale_file_fallback_key: string | null;
  locale_interpolation_variables?: string[];
  value?: string;
  defaultValue?: string;
  options?: AttributeValueOption[];
}

export default class MailerBlockModel extends Model {
  @service declare store: Store;

  @belongsTo('mailer-section') declare mailerSection: AsyncBelongsTo<MailerSectionModel>;

  // Attributes
  @attr('array') declare requiredAttributes: string[];
  @attr('string') declare title: string;
  @attr('string') declare identifier: string;
  @attr('boolean') declare deactivatable: boolean;
  @attr('boolean') declare sortable: boolean;
  @attr('boolean') declare editable: boolean;

  @attr('immutable', { defaultValue: () => ({}) }) declare settings: { attributes: MailerBlockSettingAttribute[] };
  @attr('number') declare position: number;
  @attr('boolean', { defaultValue: true }) declare active: boolean;

  @cached
  get envoyDefaultBlock(): MailerBlockModel | undefined {
    const { identifier, store } = this;

    const mailerSectionIdentifier = this.mailerSection.content?.identifier;
    const envoyDefaultTemplateId = this.mailerSection.content?.mailerTemplate.content?.envoyDefaultId || '';

    const envoyDefaultTemplate = envoyDefaultTemplateId
      ? store.peekRecord('mailer-template', envoyDefaultTemplateId)
      : null;
    const mailerBlocks = (envoyDefaultTemplate || {}).mailerBlocks ?? [];

    return mailerBlocks.find((block) => {
      return block.mailerSection.content?.identifier === mailerSectionIdentifier && block.identifier === identifier;
    });
  }

  get isDefault(): boolean {
    const { active, position, settings, envoyDefaultBlock } = this;

    if (!envoyDefaultBlock) return true;
    if (active !== envoyDefaultBlock.active) return false;
    if (position !== envoyDefaultBlock.position) return false;

    return _isEqual(settings, envoyDefaultBlock.settings);
  }

  get isDirty(): boolean {
    const { isDeleted, hasDirtyAttributes } = this;

    // Use Boolean() to convert ComputedProperty<boolean, boolean>
    // eslint-disable-next-line no-extra-boolean-cast
    if (Boolean(isDeleted)) return true;
    if (!hasDirtyAttributes) return false;

    const changes = <{ [key: string]: [unknown, unknown] }>this.changedAttributes();
    const changedAttrs = Object.keys(changes);

    // The settings attribute has a complex array/object structure to compare
    // Deep compare all changes to detect dirty state
    for (let i = 0; i < changedAttrs.length; i++) {
      const key = `${changedAttrs[i]}`;

      if (changes[key]?.length === 2 && !_isEqual(changes[key][0], changes[key][1])) return true;
    }

    return false;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'mailer-block': MailerBlockModel;
  }
}

import Controller from '@ember/controller';
import { action } from '@ember/object';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type { DetailedChangeset } from 'ember-changeset/types';
import type UserDocumentModel from 'garaje/models/user-document';
import type UserDocumentTemplateAttachmentModel from 'garaje/models/user-document-template-attachment';
import type StateService from 'garaje/services/state';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { alias } from 'macro-decorators';

import type { ProfileDocumentUploadRouteModel } from './route';

interface Step {
  route: string;
  label: string;
  position: number;
  substeps?: Step[];
}

export default class UploadController extends Controller {
  declare model: ProfileDocumentUploadRouteModel;

  @service declare transitionConfirm: TransitionConfirmService;
  @service declare state: StateService;

  // doubling up the focus trapper with the unsaved changes dialog and the main dialog causes tests to hang
  // and accessibility issues as the focus trappers compete for the page focus continuously
  // https://github.com/steveszc/ember-focus-trapper#things-to-be-aware-of
  @tracked disableFocusTrapper = false;
  @tracked currentDocumentTemplateAttachment?: UserDocumentTemplateAttachmentModel;
  @alias('state.currentLocation') currentLocation!: StateService['currentLocation'];

  get logoUrl(): string {
    return this.currentLocation.logoUrl;
  }

  get companyName(): string | null | undefined {
    return this.currentLocation.companyName;
  }

  get hideLogo(): boolean {
    return false;
  }

  get steps(): Step[] {
    const substeps = this.model.userDocumentTemplate?.userDocumentTemplateAttachments.map((attachment, index) => {
      return {
        route: 'profile.document.upload.attachment',
        label: attachment.title,
        position: attachment.position ?? index,
      };
    });

    return [
      { route: 'profile.document.upload.attachment', label: 'Add document', position: 1, substeps },
      { route: 'profile.document.upload.review', label: 'Review document', position: 2 },
    ];
  }

  cleanupModels(userDocument: DetailedChangeset<UserDocumentModel>): void {
    userDocument.userDocumentAttachments.toArray().forEach((attachment) => {
      attachment.rollbackAttributes();
    });

    if (<boolean>(<unknown>userDocument.isNew)) {
      void userDocument._content.destroyRecord();
    } else {
      userDocument.rollback();
    }
  }

  @action
  confirmNavigation(transition: Transition): void {
    this.disableFocusTrapper = true;

    void this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue: () => {
        this.cleanupModels(this.model.userDocument!);
        this.model.userDocument = undefined;
        this.disableFocusTrapper = false;
      },
      abort: () => {
        this.disableFocusTrapper = false;
      },
    });
  }
}

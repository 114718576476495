import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type { Resolved } from 'ember-concurrency';

import type PropertyVisitorsIndexRoute from '../../index/route';

export default class PropertyVisitorsBlocklistImportRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  beforeModel(): void {
    if (this.abilities.cannot('manage property blocklist')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  model(): Resolved<ReturnType<PropertyVisitorsIndexRoute['model']>> {
    return this.modelFor('property.visitors');
  }
}

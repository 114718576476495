import { validatePresence, validateNumber } from 'ember-changeset-validations/validators';

export const MIN_CAPACITY_LIMIT = 1;
export const MAX_CAPACITY_LIMIT = 100000;

export default {
  address: validatePresence({ presence: true, message: 'This is a required field' }),
  companyName: validatePresence({ presence: true, message: 'This is a required field' }),
  name: validatePresence({ presence: true, message: 'This is a required field' }),
  capacityLimit: validateNumber({
    allowBlank: true,
    integer: true,
    gte: MIN_CAPACITY_LIMIT,
    lte: MAX_CAPACITY_LIMIT,
    message: `Must be a number between ${MIN_CAPACITY_LIMIT} and ${MAX_CAPACITY_LIMIT}`,
  }),
};

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import checkType from 'garaje/utils/check-file-type';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { inject as service } from '@ember/service';
import { EMPLOYEE_COUNT } from 'garaje/utils/enums';

export default class EmployeeImportModal extends Component {
  @service employeeUpload;
  @service flashMessages;
  @service featureFlags;

  @tracked deleteOrImport;
  @tracked parsedObjects = null;
  @tracked originalFile = null;

  menuOptions = [
    { title: 'Manually Delete', value: 'delete' },
    { title: 'Import new CSV', value: 'import' },
  ];

  get isOverEmployeeLimit() {
    return this.args.employeesCount > EMPLOYEE_COUNT.EMPLOYEE_LIMIT;
  }

  get employeesOverLimit() {
    return this.isOverEmployeeLimit ? Math.round(this.args.employeesCount - EMPLOYEE_COUNT.EMPLOYEE_LIMIT) : null;
  }

  @action
  openDeleteOrImport(option) {
    if (option.value === 'delete') {
      this.args.close();
    } else if (document.getElementById('importSelect')) {
      document.getElementById('importSelect').click();
    }
  }

  @action
  async uploadCsvToServer() {
    try {
      await this.employeeUpload.uploadEmployeesCSV(this.originalFile);

      this.parsedObjects = null;

      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
    this.args.closeViaImport();
  }

  @action
  toggleProcessing() {
    this.isProcessing = !this.isProcessing;
  }

  @action
  handleCsvResults(contents, originalFile) {
    this.originalFile = originalFile;
    const checkResult = checkType(originalFile, ['csv']);

    if (checkResult.isValid) {
      this.parsedObjects = contents.data?.map(([name, email, phone, assistant]) => ({
        name,
        email,
        phone,
        assistant,
      }));
      if (this.parsedObjects.length > EMPLOYEE_COUNT.EMPLOYEE_LIMIT) {
        this.args.close(this.parsedObjects);
      } else {
        this.uploadCsvToServer();
      }
    } else {
      const text = `Only files with extensions .csv are allowed. You uploaded a file with extension ${checkResult.extension}.`;
      this.flashMessages.showFlash('error', text);
    }
  }
}

import InviteFragment from 'garaje/graphql/fragments/employee-schedule/InviteFragment';
import ReservationFragment from 'garaje/graphql/fragments/employee-schedule/ReservationFragment';
import gql from 'graphql-tag';

export default gql`
  mutation CreateInviteReservation(
    $deskId: ID
    $invite: NewInviteInput
    $inviteId: ID
    $includeEmployeeInvite: Boolean!
  ) {
    createInviteReservation(deskId: $deskId, invite: $invite, inviteId: $inviteId) {
      invite {
        ...InviteFragment
      }
      reservation {
        ...ReservationFragment
      }
    }
  }
  ${InviteFragment}
  ${ReservationFragment}
`;

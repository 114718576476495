import { get } from '@ember/object';
import { routeEvent } from 'garaje/utils/decorators/route';

/**
 *
 * @returns {Class}
 */
function dirtyCheck(Class) {
  return class DirtyCheck extends Class {
    _checkState(transition) {
      let doTransition = true;
      const _dirtyObject = this._dirtyObject ?? 'model';
      const _dirtyAttr = this._dirtyAttr ?? 'hasDirtyAttributes';
      const model = get(this.controller, _dirtyObject);

      /*
       * There can be scenarios where a model is not defined. For
       * example, the model is only loaded if a feature flag is on.
       */
      if (model && get(model, _dirtyAttr)) {
        doTransition = false;
        transition.abort();
        const confirmableTask = this.controller.showConfirmationModalTask;
        confirmableTask.perform().then(() => {
          if (model.rollback) {
            model.rollback();
          } else if (model.rollbackAttributes) {
            model.rollbackAttributes();
          } else {
            throw new Error('Dirty object should include a rollback function.');
          }

          transition.retry();
        });
      }

      return doTransition;
    }

    @routeEvent
    routeWillChange(transition) {
      return this._checkState(transition);
    }
  };
}

export default dirtyCheck;

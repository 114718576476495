import _defaults from 'lodash/defaults';
import { getProperties } from '@ember/object';
import { typeOf } from '@ember/utils';

export default class ScimMapping {
  constructor(attrs = {}) {
    const props = { ...attrs };

    _defaults(props, {
      label: null, // string
      name: null, // string
      value: null, // string
      'location-id': null, // string
      operation: '=', // string
    });

    // The server sends this as a number but it needs to be a string
    if (typeOf(props['location-id']) === 'number') {
      props['location-id'] = props['location-id'].toString();
    }

    Object.assign(this, props);
  }

  serialize() {
    const props = getProperties(this, Object.getOwnPropertyNames(this));
    if (typeOf(props['location-id']) === 'string') {
      props['location-id'] = parseInt(props['location-id']);
    }
    return props;
  }
}

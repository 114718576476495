export enum Audiences {
  Self = 'SELF',
  AllEmployeesWithReservation = 'ALL_EMPLOYEES_WITH_RESERVATION',
  AllEmployeesCheckedIn = 'ALL_EMPLOYEES_CHECKED_IN',
  AllEmployeesAtLocation = 'ALL_EMPLOYEES_AT_LOCATION',
  AllEmployeesAtDefaultLocation = 'ALL_EMPLOYEES_AT_DEFAULT_LOCATION',
  AllVisitorsCheckedIn = 'ALL_VISITORS_CHECKED_IN',
  AllVisitorsWithReservation = 'ALL_VISITORS_WITH_RESERVATION',
  AllVisitorsSignedOut = 'ALL_VISITORS_SIGNED_OUT',
}

export type Audience = {
  id: Audiences;
  count?: number;
};

export type GQLAudiences = {
  employees: Audience[];
  visitors: Audience[];
};

import Component from '@glimmer/component';
import { set } from '@ember/object';
import { task, all } from 'ember-concurrency';

/**
 * @param {Array<Floor>}    options                   Required. List of floors. To allow for creating floors add option 'create' to the array
 * @param {Function}        onFloorChange             Required. Callback when floor is toggled
 * @param {Floor}           currentFloor              Required. Currently selected floor
 * @param {Boolean}         disabled                  Optional. Disables the toggle when true
 * @param {Function}        onCreateFloor             Optional. Handles 'Create new floor' selection when enabled
 * @param {Boolean}         canUpdateFloorOrder       Optional. Floors in dropdown will be sortable
 * @param {Boolean}         canEditFloor              Optional. Edit button will appear next to the toggle. Floor information will be editable
 * @param {Function}        onEditFloorClick          Optional. Action for when the edit button is clicked
 **/

export default class FloorToggle extends Component {
  updateFloorPositionTask = task({ drop: true }, async (floors) => {
    await all(
      floors.map((floor, index) => {
        set(floor, 'floorNumber', index + 1);
        return floor.save();
      }),
    );
  });
}

import { action } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { formatDistanceToNow } from 'date-fns';
import { task } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';
import type ScimIntegrationModel from 'garaje/models/scim-integration';
import type ScimMapping from 'garaje/models/scim-mapping';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { formatScimProvider } from 'garaje/utils/format-scim-provider';
import urlBuilder from 'garaje/utils/url-builder';

interface ScimConfigBoxArgs {
  model: ScimIntegrationModel;
  integration: string;
  openAction: (value: string) => void;
  closeAction: () => void;
  integrationDisconnected: () => void;
}

export default class ScimConfigBox extends Component<ScimConfigBoxArgs> {
  @service declare currentAdmin: CurrentAdminService;
  @service declare flashMessages: FlashMessagesService;

  @tracked showConfirmDisconnectDialog: boolean = false;
  @tracked showConfirmRegenerateDialog: boolean = false;

  get connected(): boolean {
    return !isEmpty(this.args.model);
  }

  get isOpen(): boolean {
    return this.args.integration === 'scim';
  }

  get formattedProvider(): string {
    const { model } = this.args;
    return model && model.provider ? formatScimProvider(model.provider) : '';
  }

  get learnMoreLink(): string {
    switch (this.args.model.provider) {
      case 'onelogin':
        return 'https://envoy.help/en/articles/3453393-onelogin-application';
      case 'azure':
        return 'https://envoy.help/en/articles/3453335-microsoft-entra-id-integration';
      default:
        // okta
        return 'https://envoy.help/en/articles/3453325-okta';
    }
  }

  get pillText(): string {
    const model = this.args.model;
    if (model) {
      const numOfLocations = model.locationMapping?.length || 0;
      return numOfLocations > 0 ? pluralize(numOfLocations, 'location') : 'All Users';
    } else {
      return '';
    }
  }

  get lastSyncedAt(): string {
    if (this.args.model?.lastSyncAt) {
      const formattedText = formatDistanceToNow(new Date(this.args.model.lastSyncAt), { addSuffix: true });
      return `Last synced ${formattedText}`;
    } else {
      return '';
    }
  }

  get scimBaseUrl(): string {
    return urlBuilder.scimBaseUrl();
  }

  isValid(model: ScimIntegrationModel): boolean {
    if (!this.args.model.locationMapping) {
      return true;
    }

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return !model.locationMapping.any((mapping: ScimMapping) => {
      // @ts-ignore
      return isEmpty(mapping.value);
    });
  }

  @action
  close(): void {
    // @ts-ignore
    if (this.args.model?.isNew) {
      void this.args.model.destroyRecord();
      this.args.integrationDisconnected();
    }

    this.args.closeAction();
  }

  @action
  open(): void {
    this.args.openAction('scim');
  }

  @action
  copySuccess(): void {
    this.flashMessages.showAndHideFlash('success', 'Copied');
  }

  saveTask = task({ drop: true }, async () => {
    try {
      if (this.isValid(this.args.model)) {
        await this.args.model.save();
        this.flashMessages.showAndHideFlash('success', 'Saved!');
        this.args.closeAction();
      } else {
        this.flashMessages.showFlash('error', 'Please add a value for every chosen filter.');
      }
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}

// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { get, set } from '@ember/object';

/**
 * Form for purchasing additional subscription quantity
 * @param {String}              billingPeriodCTAText Text for change billing period CTA
 * @param {Function}            cancelDowngradeTask cancel downgrade task
 * @param {Function}            onUpgrade function called when upgrade occurs
 * @param {Array}               plans array of plans
 * @param {Class<Subscription>} subscription A subscription model
 */

export default class PlansRooms extends Component {
  // eslint-disable-next-line ember/classic-decorator-hooks
  init() {
    super.init(...arguments);
    // this is a one-time set so that if we cancel the downgrade plan the plans don't get in a weird button state as soon as the downgrade plan is nulled out
    set(this, 'downgradePlanName', get(this, 'subscription.downgradePlan.normalizedPlanLevel'));
  }
}

import { modifier } from 'ember-modifier';
import { iframeResize } from 'iframe-resizer';

const noop = () => {};

export default modifier((element, [onMessage = noop, onResized = noop, options = {}]) => {
  if (!element.id) {
    // eslint-disable-next-line no-console
    console.warn('Refusing to initialize iframeResize without an ID');
    return;
  }
  iframeResize(
    {
      heightCalculationMethod: 'taggedElement',
      onMessage,
      onResized,
      warningTimeout: 0,
      ...options,
    },
    element,
  );
});

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';

/**
 * @param {Function} closeAction - the function to hide the modal
 */
export default class BuyNowModal extends Component {
  @service impressions;

  @action
  logBuyNowModalStandardClicked() {
    this.args.closeAction();
    this.impressions.postImpression.perform(IMPRESSION_NAMES.VISTORS_BUY_NOW.STANDARD_CLICKED);
  }

  @action
  logBuyNowModalPremiumClicked() {
    this.args.closeAction();
    this.impressions.postImpression.perform(IMPRESSION_NAMES.VISTORS_BUY_NOW.PREMIUM_CLICKED);
  }

  @action
  dismiss() {
    this.args.closeAction();
    this.impressions.postImpression.perform(IMPRESSION_NAMES.VISTORS_BUY_NOW.MODAL_CLOSED);
  }
}

import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AnalyticsProtectIndexRoute extends Route {
  title = 'Protect · Analytics · Envoy';

  @service abilities;
  @service featureFlags;
  @service router;

  redirect() {
    if (this.abilities.cannot('visit protect analytics')) {
      return this.router.transitionTo('analytics.protect.upsell');
    }
  }
}

import CompanyFragment from 'garaje/graphql/fragments/roomba/CompanyFragment';
import gql from 'graphql-tag';

export default gql`
  mutation UpdateRoomsCompanyOnsiteRoomRecaptureEnabled($companyId: ID!, $onsiteRoomRecaptureEnabled: Boolean!) {
    updateRoomsCompanyOnsiteRoomRecaptureEnabled(
      companyId: $companyId
      onsiteRoomRecaptureEnabled: $onsiteRoomRecaptureEnabled
    ) {
      ...CompanyFragment
    }
  }
  ${CompanyFragment}
`;

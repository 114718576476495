import { isPresent } from '@ember/utils';
import _intersection from 'lodash/intersection';

/**
 * Checks if a list of roles contains any of the permitted roles
 *
 * @param collection array to search
 * @param values     values to search for in the array
 *
 * @returns Whether or not the collection contains any of the values
 */
export default function includesAny<T>(collection: T[], ...values: T[]): boolean {
  return isPresent(_intersection(collection, values));
}

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { Changeset } from 'ember-changeset';
import { type DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import type ConnectInviteModel from 'garaje/models/connect-invite';
import type ZoneModel from 'garaje/models/zone';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';
import buildInviteValidations from 'garaje/validations/connect-invite';

import { type InvitesModel } from '../route';

interface InviteEditModel {
  inviteChangeset: DetailedChangeset<ConnectInviteModel>;
  currentProperty: ZoneModel;
  loadTenantsTask: InvitesModel['loadTenantsTask'];
}

export default class PropertyVisitorsInvitesEditRoute extends Route {
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare transitionConfirm: TransitionConfirmService;

  async model({ connect_invite_id }: { connect_invite_id: string }): Promise<InviteEditModel> {
    const invite = await this.store.findRecord('connect-invite', connect_invite_id, { include: 'suites,tenant' });
    const validations = buildInviteValidations({ isBulk: false });
    const validator = lookupValidator(validations);

    const inviteChangeset = Changeset(invite, validator, validations);

    return {
      inviteChangeset,
      currentProperty: <ZoneModel>this.modelFor('property'),
      loadTenantsTask: (<InvitesModel>this.modelFor('property.visitors.invites')).loadTenantsTask,
    };
  }

  afterModel({ inviteChangeset }: InviteEditModel): void {
    if (!inviteChangeset.isPropertyInvite) {
      void this.router.transitionTo('property');
    }
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    const { inviteChangeset } = <InviteEditModel>this.modelFor(this.routeName);

    if (transition.to.name === this.routeName || !inviteChangeset.isDirty) return;

    void this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        inviteChangeset.rollback();
      },
    });
  }
}

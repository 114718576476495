import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type WindowLocationService from 'garaje/services/window-location';

interface KeyFeatureInfoBoxArgs {
  title: string;
  description: string;
  buttonText: string;
  buttonRoute: string;
  imageRoute: string;
  onClick?: () => void;
}

export default class WorkplacePlanPriceInfoComponent extends Component<KeyFeatureInfoBoxArgs> {
  @service declare router: RouterService;
  @service declare windowLocation: WindowLocationService;

  constructor(owner: WorkplacePlanPriceInfoComponent, args: KeyFeatureInfoBoxArgs) {
    super(owner, args);
  }

  @action
  async redirectToRoute(): Promise<void> {
    this.args.onClick?.();
    await this.router.transitionTo(this.args.buttonRoute);
  }

  @action
  redirectToExternalPage(): void {
    this.args.onClick?.();
    this.windowLocation.assign(this.args.buttonRoute);
  }
}

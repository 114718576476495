import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type GlobalFlowModel from 'garaje/models/global-flow';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

export interface LocationOverviewSignInFlowsShowRouteModel {
  globalFlow: GlobalFlowModel;
}

export default class LocationOverviewSignInFlowsShowRoute extends Route {
  @service declare skinnyLocations: SkinnyLocationsService;
  @service declare store: StoreService;

  async model(params: { global_flow_id: string }): Promise<LocationOverviewSignInFlowsShowRouteModel> {
    const include = [
      'agreement-page',
      'agreement-page.agreements',
      'badge',
      'id-scan-page',
      'locations',
      'photo-page',
      'sign-in-field-page',
      'sign-in-field-page.sign-in-fields',
      'sign-in-field-page.sign-in-fields.sign-in-field-actions',
      'summary-page',
      'visitor-document-contacts',
      'visual-compliance-configuration',
    ].join(',');

    const globalFlow = await this.store.findRecord('global-flow', params.global_flow_id, { include, reload: true });

    // skinnyLocations must be loaded before computing `globalFlow.translationEnabledLocales`
    // which is referenced everywhere we manage translations on global flows
    this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);

    return { globalFlow };
  }
}

export const isRecordYourFirstDeliveryCompleted = async (store, locationId) => {
  const steps = await store.query('setup-guide-step', {
    filter: { title: 'Record your first delivery' },
  });
  const completed = await store.query('locations-setup-guide-step', {
    filter: {
      location: locationId,
      setupGuideStep: steps.firstObject?.id,
      completed: true,
    },
  });
  return completed && completed.length > 0;
};

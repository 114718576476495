import Component from '@glimmer/component';
import type VfdScheduleModel from 'garaje/models/vfd-schedule';
import { Day } from 'garaje/utils/enums';
import SimpleTime from 'garaje/utils/simple-time';

interface VirtualFrontDeskSettingsHoursOfOperationsGridSignature {
  Args: {
    schedule: VfdScheduleModel;
  };
}

// Days of the week, in the order they should appear
const DAYS = [Day.Monday, Day.Tuesday, Day.Wednesday, Day.Thursday, Day.Friday, Day.Saturday, Day.Sunday];

export default class VirtualFrontDeskSettingsHoursOfOperationsGrid extends Component<VirtualFrontDeskSettingsHoursOfOperationsGridSignature> {
  days = DAYS;

  get alwaysOpen(): boolean {
    // Check that every day:
    // 1) is enabled, and
    // 2) has a single interval that covers midnight to midnight
    return this.args.schedule.rules.every((rule) => {
      if (!rule.enabled) return false;
      if (rule.intervals.length !== 1) return false;
      return (
        rule.intervals[0]!.from!.isSame(SimpleTime.StartOfDayMidnight) &&
        rule.intervals[0]!.to!.isSame(SimpleTime.EndOfDayMidnight)
      );
    });
  }
}

import DeskFragment from 'garaje/graphql/fragments/employee-schedule/ScheduleDeskFragment';
import gql from 'graphql-tag';

export default gql`
  query AvailableDesksForLocation($date: DateTime!, $locationId: ID!) {
    availableDesksForLocation(date: $date, locationId: $locationId) {
      ...DeskFragment
    }
  }
  ${DeskFragment}
`;

import Component from '@glimmer/component';
import { action } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { uniqBy } from '@ember/object/computed';

const DEFAULT_PAGE_SIZE = 25;

/**
 * @param {String}                    locationId
 * @param {Function}                  changeLocationId
 * @param {String}                    wifiNetwork
 * @param {Function}                  changeWifiNetwork
 * @param {Array}                     wifiNetworks
 * @param {String}                    deviceType
 * @param {Function}                  changeDeviceType
 * @param {Array}                     deviceTypes
 * @param {Array<SkinnyLocation>}     locations
 * @param {Number}                    pageNumber
 * @param {Number}                    totalRecords
 * @param {Function}                  setPageNumber
 */
export default class GlobalDevicesIpadsFilters extends Component {
  /*
   * Filters
   */
  defaultWifiNetworkFilter = 'All Wi-Fi networks';
  defaultDeviceType = 'All iPad types';
  defaultStatus = 'All statuses';
  @uniqBy('args.locations', 'id') uniqLocations;
  defaultLocationFilter = { name: 'All locations', id: '' };

  /*
   * Defaults
   */
  get wifiNetworks() {
    return this.args.wifiNetworks ?? [];
  }
  get deviceTypes() {
    return this.args.deviceTypes ?? [];
  }
  get pageNumber() {
    return this.args.pageNumber ?? 1;
  }
  get pageSize() {
    return this.args.pageSize ?? DEFAULT_PAGE_SIZE;
  }
  get totalRecords() {
    return this.args.totalRecords ?? 0;
  }
  get statuses() {
    return this.args.statuses ?? ['operational', 'offline'];
  }

  get wifiNetworkOptions() {
    return [this.defaultWifiNetworkFilter, ...this.wifiNetworks];
  }

  get deviceTypeOptions() {
    return [this.defaultDeviceType, ...this.deviceTypes];
  }

  get locationOptions() {
    return [this.defaultLocationFilter, ...this.uniqLocations];
  }

  get statusOptions() {
    return [this.defaultStatus, ...this.statuses];
  }

  /*
   * Actions
   */
  changeIfNotDefault(newValue, defaultValue, changeFunction) {
    // If it's the default vaulue, we want to remove the filter
    if (newValue === defaultValue) {
      changeFunction('');
    } else {
      changeFunction(newValue);
    }
  }

  @action
  onChangeWifiNetwork(newValue) {
    this.changeIfNotDefault(newValue, this.defaultWifiNetworkFilter, this.args.changeWifiNetwork);
  }

  @action
  onChangeDeviceType(newValue) {
    this.changeIfNotDefault(newValue, this.defaultDeviceType, this.args.changeDeviceType);
  }

  @action
  onChangeStatus(newValue) {
    this.changeIfNotDefault(newValue, this.defaultStatus, this.args.changeStatus);
  }
}

import ApplicationSerializer from './application';

export default class PropertyBadgeSerializer extends ApplicationSerializer {
  attrs = {
    defaultCss: {
      serialize: false,
    },
    defaultHtml: {
      serialize: false,
    },
    html: {
      serialize: false,
    },
    image: {
      serialize: false,
    },
    sampleLogoPath: {
      serialize: false,
    },
    samplePhotoPath: {
      serialize: false,
    },
  };
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'property-badge': PropertyBadgeSerializer;
  }
}

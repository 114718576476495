import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class extends Component {
  @tracked markers = [];
  @tracked activeDesks = [];
  @tracked currentFloor;
  @tracked isReady = false;

  @action
  toggleIsReady(bool) {
    this.isReady = bool;
  }

  @action
  handleUpdateDesk(newPos) {
    // eslint-disable-next-line no-console
    console.log(newPos);
  }

  @action
  onFloorChange(floor) {
    this.currentFloor = floor;
  }
}

import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { Changeset } from 'ember-changeset';
import { type BufferedChangeset } from 'ember-changeset/types';
import type PropertyDeviceConfigModel from 'garaje/models/property-device-config';
import type ZoneModel from 'garaje/models/zone';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';

import { type PropertySettingsKioskRouteModel } from '../route';

export interface WelcomeScreenRouteModel {
  config: PropertyDeviceConfigModel;
  changeset: BufferedChangeset;
  property: ZoneModel;
}

export default class PropertySettingsKioskWelcomeScreenRoute extends Route {
  @service declare transitionConfirm: TransitionConfirmService;

  // eslint-disable-next-line @typescript-eslint/require-await
  async model(): Promise<WelcomeScreenRouteModel> {
    const property = <ZoneModel>this.modelFor('property');
    const { config } = <PropertySettingsKioskRouteModel>this.modelFor('property.settings.kiosk');

    return {
      config,
      changeset: Changeset(config),
      property,
    };
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    const { changeset, config } = <WelcomeScreenRouteModel>this.modelFor(this.routeName);
    const dirtySlides = config.welcomeScreenSlides.filter(
      (changeset) => changeset.isDeleted || changeset.hasDirtyAttributes,
    );

    if (!changeset.isDirty && !dirtySlides.length) return;
    // display confirmation modal if settings are dirty
    void this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        if (changeset.isDirty) changeset.rollback();
        if (dirtySlides.length) {
          for (const changeset of dirtySlides) {
            changeset.rollbackAttributes();
          }
        }
      },
    });
  }
}

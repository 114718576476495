import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AnalyticsVisitorsUpsellRoute extends Route {
  @service abilities;
  @service featureFlags;
  @service router;

  titleToken = 'Analytics';

  buildRouteInfoMetadata() {
    return { hideBanners: true };
  }

  redirect() {
    if (this.abilities.can('visit-visitors analytic')) {
      return this.router.transitionTo('analytics.visitors.index');
    }
  }
}

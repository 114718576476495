// This helps create a reasonably safe and pretty accurate preview
// for legal documents as they might appear on the iPad Kiosk
// (or to some extent, the Invites App) in cases where a customer
// enters raw HTML into the Markdown content of their document.
// See: app/pods/visitors/settings/visitor-types/legal-document/edit-text/component.js
export const sanitizeMarkdown = function (inputValue: string): string {
  const prohibitedElements = ['iframe', 'object'];
  const replacedElements = ['img', 'input', 'script', 'style'];
  const disallowedElements = [...prohibitedElements, ...replacedElements];

  function stringToHTML(text: string): HTMLBodyElement {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');

    return <HTMLBodyElement>(doc.body || document.createElement('body'));
  }

  function removeDisallowedNodes(doc: HTMLBodyElement) {
    disallowedElements.forEach((selector) => {
      const nodes = doc.querySelectorAll(selector);

      for (const node of nodes) {
        if (prohibitedElements.includes(selector)) {
          node.remove();
        } else {
          const textNode = node.textContent || '';

          node.replaceWith(textNode);
        }
      }
    });
  }

  function replaceNodesWithText(doc: HTMLBodyElement) {
    const nodes = doc.querySelectorAll('*');

    for (const node of nodes) {
      const textNode = node.outerHTML.replace(/[\u00A0-\u9999<>&]/g, (i) => '&#' + i.charCodeAt(0) + ';');

      node.replaceWith(textNode);
    }
  }

  function decodeHTMLEntities(str: string): string {
    const textArea = document.createElement('textarea');

    textArea.innerHTML = str;

    return textArea.value;
  }

  const html = stringToHTML(inputValue);

  removeDisallowedNodes(html);
  replaceNodesWithText(html);

  return decodeHTMLEntities(html.innerHTML);
};

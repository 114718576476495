import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type { Task } from 'ember-concurrency';
import type LocationModel from 'garaje/models/location';
import type MetricsService from 'garaje/services/metrics';

interface LocationsSearchInputComponentArgs {
  /**
   * Name of input, used for testing only
   */
  name: string;
  /**
   * Currently selected location
   */
  currentLocation?: LocationModel;
  /**
   * Placeholder text for UI-INPUT component
   */
  placeholder: string;
  /**
   * When to show the clear button
   */
  showClear: boolean;
  /**
   * When to show the input itself
   */
  enabled: boolean;
  /**
   * Task to send filterText to parent component
   */
  onChange: Task<unknown, [string]>;
  /**
   * The value of the search-input
   */
  value: string;
}

/**
 * Contextual component used for creating and editing basic Location information
 */
export default class LocationsSearchInputComponent extends Component<LocationsSearchInputComponentArgs> {
  @service declare metrics: MetricsService;

  focus(): void {
    const locationsSearchInput = document.getElementById('locations-search-input');
    if (locationsSearchInput) {
      locationsSearchInput.focus();
    }
  }

  /**
   * Clears the search text
   * Triggers analytics call when user resets their search
   */
  @action
  clearSearchText(): void {
    this.metrics.trackEvent('Clear Location Picker Search Term Clicked', {
      location_id: this.args.currentLocation?.id,
    });
    void this.args.onChange.perform('');
  }
}

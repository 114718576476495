import ScheduleDeskFragment from 'garaje/graphql/fragments/employee-schedule/ScheduleDeskFragment';
import ScheduleEmployeeFragment from 'garaje/graphql/fragments/employee-schedule/ScheduleEmployeeFragment';
import gql from 'graphql-tag';

export default gql`
  fragment EntryFragment on Entry {
    id
    signedInAt
    signedOutAt
    nda
    agreementRefused
    isDelivery
    photoUrl
    privateNotes
    additionalGuests
    flowName
    employee {
      ...EmployeeFragment
    }
    visitor {
      avatarUrl
      email
      fullName
      phoneNumber
    }
    userData {
      field
      value
    }
    location {
      id
      name
      address
      logoUrl
      timezone
    }
    flow {
      id
      name
      employeeCentric
      agreementPage {
        enabled
      }
    }
    desk {
      ...DeskFragment
    }
  }
  ${ScheduleEmployeeFragment}
  ${ScheduleDeskFragment}
`;

import Modifier from 'ember-modifier';
import { registerDestructor } from '@ember/destroyable';
import { service } from '@ember/service';

/**
 *
 * @param {Event} event - The event that was triggered
 * @param {object} messageBus - message bus service
 */
function displayModal(event, messageBus) {
  event.preventDefault();

  const element = event.currentTarget;
  const url = element.dataset.modalUrl;

  messageBus.trigger('iframe-modal-message', {
    event: 'showModal',
    url,
  });
}

/**
 *
 * @param {UiHookHandlersModifier} instance - modifier context
 */
function cleanup(instance) {
  for (const element of instance.elements) {
    element.removeEventListener('click', instance.eventHandler);
  }
}

export default class UiHookHandlersModifier extends Modifier {
  @service messageBus;

  /**
   * @type {Array<HTMLButtonElement | HTMLAnchorElement>}
   */
  elements = [];
  eventHandler = () => {};

  modify(element) {
    this.elements = Array.from(element.querySelectorAll('a[data-modal-url], button[data-modal-url]'));
    if (this.elements.length === 0) return;

    this.eventHandler = (event) => displayModal(event, this.messageBus);
    registerDestructor(this, cleanup);

    for (const el of this.elements) {
      el.addEventListener('click', this.eventHandler);
    }
  }
}

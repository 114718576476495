import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class BillingDowngradeRoute extends Route {
  @service featureUsage;
  @service state;
  @service store;

  titleToken = 'Downgrade';

  model({ plan: targetPlanId }) {
    const features = this.featureUsage.features(targetPlanId);
    const targetPlan = this.store.findRecord('plan', targetPlanId);
    const currentPlan = this.store.findRecord('plan', this.state.vrSubscription.plan);
    const locations = this.store.findAll('location', { include: 'flows' });

    return hash({
      currentPlan,
      features,
      locations,
      targetPlan,
    });
  }
}

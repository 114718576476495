import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { set, action } from '@ember/object';
import { task, dropTaskGroup, dropTask } from 'ember-concurrency';
import { and, or, notEmpty } from 'macro-decorators';
import { SLACK_V2_PLUGIN_KEY, MSTEAMS_V2_PLUGIN_KEY, IMPRESSION_NAMES } from 'garaje/utils/enums';

/**
 * @param {Function}                          closeFeature
 * @param {Array<FallbackContact>}            fallbackContacts
 * @param {Function}                          onUpdateFallbackContacts
 * @param {Function}                          reloadFallbackContacts
 * @param {String}                            feature
 * @param {Array}                             pluginPreferences
 * @param {Class<Location>}                   location
 * @param {Function}                          openFeature
 * @param {Class<Subscription>}               vrSubscription
 * @param {Function}                          searchUsersTask
 * @param {boolean}                           locationIsConnectedToProperty
 */
export default class HostOptions extends Component {
  @service featureFlags;
  @service flashMessages;
  @service impressions;
  @service state;
  @service statsig;

  @notEmpty('slackPlugins') hasSlackEnabled;
  @notEmpty('msTeamsPlugins') hasMSTeamsEnabled;
  @and('hasMSTeamsEnabled', 'featureFlags.msTeamsHostReplies') msTeamsHostRepliesEnabled;
  @and('featureFlags.twoWayHostMessaging', 'hasHostRepliesCompatiblePlugin') showHostRepliesSection;
  @or('hasSlackEnabled', 'hasMSTeamsEnabled') hasHostRepliesCompatiblePlugin;

  @dropTaskGroup toggleTasks;

  slackV2PluginKey = SLACK_V2_PLUGIN_KEY;
  msTeamsV2PluginKey = MSTEAMS_V2_PLUGIN_KEY;

  get slackPlugins() {
    return this.args.pluginPreferences?.filterBy('plugin.key', this.slackV2PluginKey);
  }

  get msTeamsPlugins() {
    return this.args.pluginPreferences?.filterBy('plugin.key', this.msTeamsV2PluginKey);
  }

  get canShowAddEmployeesTooltip() {
    if (
      this.args?.location?.hostNotificationsEnabled &&
      this.state?._companyMeta['employees-count'] === 1 &&
      this.featureFlags.isEnabled('growth_employee_directory_empty_state')
    ) {
      return true;
    }
    return false;
  }

  @dropTask
  *saveLocation(location) {
    try {
      yield location.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      location.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @task({ group: 'toggleTasks' })
  *toggleEmailNotificationEnabled(value) {
    set(this.args.location, 'emailNotificationEnabled', value);
    yield this.saveLocation.perform(this.args.location);
    yield this.impressions.postImpression.perform(
      IMPRESSION_NAMES.HOST_OPTIONS_EMAIL_NOTIFICATION[value ? 'ENABLED' : 'DISABLED'],
    );
  }

  @task({ group: 'toggleTasks' })
  *toggleSMSNotificationEnabled(value) {
    set(this.args.location, 'smsNotificationEnabled', value);
    yield this.saveLocation.perform(this.args.location);
    yield this.impressions.postImpression.perform(
      IMPRESSION_NAMES.HOST_OPTIONS_SMS_NOTIFICATION[value ? 'ENABLED' : 'DISABLED'],
    );
  }

  @task({ group: 'toggleTasks' })
  *toggleHostNotificationsEnabled(value) {
    set(this.args.location, 'hostNotificationsEnabled', value);
    yield this.saveLocation.perform(this.args.location);
    yield this.impressions.postImpression.perform(
      IMPRESSION_NAMES.HOST_OPTIONS_HOST_NOTIFICATIONS[value ? 'ENABLED' : 'DISABLED'],
    );
  }

  @task({ group: 'toggleTasks' })
  *toggleNotifyReceptionistsOnHostReplyEnabled(value) {
    set(this.args.location, 'notifyReceptionistsOnHostReplyEnabled', value);
    yield this.saveLocation.perform(this.args.location);
    yield this.impressions.postImpression.perform(
      IMPRESSION_NAMES.HOST_OPTIONS_RECEPTIONIST_NOTIFICATION[value ? 'ENABLED' : 'DISABLED'],
    );
  }

  @action
  trackTooltipShown() {
    this.statsig.logEvent('employee-host-tooltip-seen');
  }

  @action
  trackTooltipLinkClick() {
    this.statsig.logEvent('employee-host-tooltip-directory-clicked');
  }
}

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class ProtectLandingPageRoute extends Route {
  title = 'Protect · Envoy';

  @service abilities;
  @service router;

  beforeModel() {
    if (this.abilities.can('visit workplace')) {
      return this.router.transitionTo('workplace');
    }
    if (this.abilities.cannot('visit landing for protect')) {
      return this.router.transitionTo('protect');
    }
  }
}

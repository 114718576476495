import ApplicationSerializer from './application';

export default class extends ApplicationSerializer {
  // We need to change this so ED look up the appropriate model for that part of the payload.
  // It comes as `versions` from the API
  modelNameFromPayloadKey() {
    const key = super.modelNameFromPayloadKey(...arguments);

    if (key === 'version') {
      return 'changelog';
    } else {
      return key;
    }
  }

  normalizeResponse(_store, _primaryModelClass, payload, _id, _requestType) {
    if (payload && payload.meta && payload.meta.total && payload.meta.limit) {
      payload = this._calulateTotalPages(payload);
    }
    return super.normalizeResponse(...arguments);
  }

  normalize(model, hash) {
    const attributes = hash.attributes;
    // We need to manually camelCase `updated-attributes` as it's an attrt object type
    Object.keys(attributes).forEach((key) => {
      if (attributes[key]?.['updated-attributes']) {
        attributes[key].updatedAttributes = attributes[key]['updated-attributes'];
        delete attributes[key]['updated-attributes'];
      }
    });
    return super.normalize(...arguments);
  }

  // The current setup we have for pagination (offset pagination) on Versions model (JSONAPI::Resource)
  // doesn't support total_pages.
  _calulateTotalPages(payload) {
    const total = payload.meta.total;
    const limit = payload.meta.limit;
    /* eslint-disable dot-notation */
    payload.meta['total_pages'] = limit > 0 ? Math.ceil(total / limit) : 0;
    return payload;
  }
}

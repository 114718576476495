import gql from 'graphql-tag';

export default gql`
  query SearchEmployeeGroups(
    $limit: Int!
    $locationId: ID!
    $offset: Int!
    $term: String!
    $typeFilters: [EmployeeGroupEnum!]!
  ) {
    searchEmployeeGroups(
      limit: $limit
      locationId: $locationId
      offset: $offset
      term: $term
      typeFilters: $typeFilters
    ) {
      groups {
        id
        name
        type
        memberCount
      }
      total
    }
  }
`;

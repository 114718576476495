import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type DeviceModel from 'garaje/models/device';
import type FlashMessagesService from 'garaje/services/flash-messages';

import type { PropertyDevicesIpadsNewRouteModel } from './route';

export default class PropertyDevicesIpadsNewController extends Controller {
  declare model: PropertyDevicesIpadsNewRouteModel;

  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;
  @service declare store: Store;

  @action
  reinitializeDevice(): DeviceModel {
    return this.store.createRecord('device', {
      zone: this.model.property,
    });
  }

  @action
  reloadDevicesAndRedirect(): void {
    void this.router.refresh('property.devices');
    this.flashMessages.showAndHideFlash('success', 'iPad added successfully');
    void this.router.transitionTo('property.devices.ipads.index');
  }
}

import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';

export default class MapsEditIndexRoute extends Route {
  @service router;
  @service abilities;

  async beforeModel() {
    if (this.abilities.cannot('manage-floor maps')) {
      this.router.transitionTo('unauthorized');
    }

    const { areaMap } = this.modelFor('spaces.maps');
    const firstMapFloor = await get(areaMap, 'mapFloors').sortBy('ordinality').firstObject;
    if (firstMapFloor) {
      this.transitionTo('spaces.maps.edit.show', firstMapFloor.id); // eslint-disable-line ember/no-deprecated-router-transition-methods
    }
  }

  model() {
    const { areaMap } = this.modelFor('spaces.maps');
    return {
      areaMap,
    };
  }
}

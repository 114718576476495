import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { all, task } from 'ember-concurrency';
import { scheduleOnce } from '@ember/runloop';
import iframeWriteDoc from 'garaje/utils/iframe-write-doc';

/**
 * @param {Model}                 visitorSurveyConfiguration
 * @param {Function}              onPreview
 */
export default class VisitorEmailsVisitorSurveyVsPreviewingWebView extends Component {
  @service flashMessages;

  @tracked positivePreviewHTML;
  @tracked negativePreviewHTML;

  constructor() {
    super(...arguments);
    this.loadPreviewHTMLTask.perform();
  }

  @task
  *loadPreviewHTMLTask() {
    try {
      const [{ body: positiveBody }, { body: negativeBody }] = yield all([
        this.args.visitorSurveyConfiguration.getWebviewPreview({ selection: 'positive' }),
        this.args.visitorSurveyConfiguration.getWebviewPreview({ selection: 'negative' }),
      ]);

      this.positivePreviewHTML = positiveBody;
      this.negativePreviewHTML = negativeBody;
      scheduleOnce('afterRender', this, iframeWriteDoc, 'positive', this.positivePreviewHTML);
      scheduleOnce('afterRender', this, iframeWriteDoc, 'negative', this.negativePreviewHTML);
      scheduleOnce('afterRender', this, this.args.onPreview, 'webview');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', e);
    }
  }
}

import Component from '@glimmer/component';
import type AnnouncementModel from 'garaje/models/announcement';
import type { ANNOUNCEMENT_EMPLOYEE_AUDIENCE } from 'garaje/utils/enums';
import { ANNOUNCEMENT_CHANNEL_NAME, ANNOUNCEMENT_RECIPIENT_GROUP_NAME } from 'garaje/utils/enums';

type AnnouncementDetailsArgsType = {
  model: AnnouncementModel;
};

export default class AnnouncementDetails extends Component<AnnouncementDetailsArgsType> {
  get deliveryMethods(): string[] {
    return this.args.model.announcementChannels.map((c) => this.formatChannelName(c.channel));
  }

  get recipientGroups(): string[] {
    const { announcementAudiences, location, employeeGroups } = this.args.model;
    const locationName = location.content?.name || '';
    const genericAudiences = announcementAudiences
      .filter((a) => a.employeeAudience !== 'employee_group')
      .map((a) =>
        this.formatRecipientGroup(
          (a.visitorAudience || a.employeeAudience) as ANNOUNCEMENT_EMPLOYEE_AUDIENCE,
          locationName,
        ),
      );
    const employeeGroupNames = employeeGroups.map((group) => group.displayName);

    return [...genericAudiences, ...employeeGroupNames];
  }

  formatChannelName(channel: keyof typeof ANNOUNCEMENT_CHANNEL_NAME): string {
    return ANNOUNCEMENT_CHANNEL_NAME[channel];
  }

  formatRecipientGroup(recipientGroup: ANNOUNCEMENT_EMPLOYEE_AUDIENCE, locationName: string): string {
    return ANNOUNCEMENT_RECIPIENT_GROUP_NAME[recipientGroup]?.replace('%{location_name}', locationName);
  }
}

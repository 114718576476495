import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { get, set } from '@ember/object';
import { dropTask, timeout } from 'ember-concurrency';
import config from 'garaje/config/environment';
import zft from 'garaje/utils/zero-for-tests';
import { isRecordYourFirstDeliveryCompleted } from 'garaje/utils/setup-guide-steps';

const POLL_FOR_COMPLETED_TIMEOUT = 20000;

export default class DeliveriesLogTutorialRoute extends Route {
  @service state;
  @service router;
  @service store;

  beforeModel() {
    return this.checkOnboardingReadiness.perform();
  }

  model() {
    const currentLocation = this.modelFor('current-location');
    return { currentLocation };
  }

  setupController(_controller, _model) {
    super.setupController(...arguments);
    set(this.state, 'showDeliveriesOnboarding', false);
    this.pollForOnboardingReadiness.perform();
  }

  @dropTask({
    cancelOn: 'deactivate',
  })
  *pollForOnboardingReadiness() {
    const isReady = get(this, 'checkOnboardingReadiness.lastSuccessful.value');
    while (!isReady && config.environment !== 'test') {
      yield timeout(zft(POLL_FOR_COMPLETED_TIMEOUT));
      yield this.checkOnboardingReadiness.perform();
    }
  }

  @dropTask({
    cancelOn: 'deactivate',
  })
  *checkOnboardingReadiness() {
    const { id, deliveriesOnboardingComplete } = this.modelFor('current-location');
    let done = deliveriesOnboardingComplete;
    if (!done) {
      done = yield isRecordYourFirstDeliveryCompleted(this.store, id);
    }
    if (done) {
      // redirect to deliveries to ensure setup bar is added
      // should result on transition to deliveries log
      this.router.transitionTo('deliveries');
    }
    return done;
  }
}

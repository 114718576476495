import { set } from '@ember/object';
import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import type CompanyModel from 'garaje/models/company';
import type EmployeeModel from 'garaje/models/employee';
import type LocationModel from 'garaje/models/location';
import type UserModel from 'garaje/models/user';
import adapter from 'garaje/utils/decorators/adapter';
import displayRoleName from 'garaje/utils/decorators/display-role-name';
import { isCompanyRole } from 'garaje/utils/roles';

@displayRoleName
@adapter('v2')
class CompanyRoleModel extends Model {
  @belongsTo('company') declare company: CompanyModel;
  @belongsTo('employee') declare employee: EmployeeModel;
  @belongsTo('user') declare user: UserModel;

  @attr('date') declare confirmedAt: Date;
  @attr('date') declare createdAt: Date;
  @attr('date') declare lastLogin: Date;
  @attr('date') declare lastSeenAt: Date;
  @attr('string') declare roleName: string;
  @attr('boolean') declare manuallyAdded: boolean;

  isGlobalAdmin = true;

  updateRole(roleName: string, location: LocationModel): Promise<unknown> {
    if (!isCompanyRole(roleName)) {
      return this.relegateToLocationRole(roleName, location);
    } else {
      set(this, 'roleName', roleName);

      return this.save().then(
        (model) => {
          return model;
        },
        (err) => {
          this.rollbackAttributes();
          throw err;
        },
      );
    }
  }

  relegateToLocationRole(roleName: string, location: LocationModel): Promise<unknown> {
    return (<AsyncBelongsTo<UserModel>>(<unknown>this.user))
      .then((user) => {
        return user.setRole(roleName, location);
      })
      .then((member) => {
        return this.destroyRecord().catch((err) => {
          void member.destroyRecord();
          throw err;
        });
      });
  }
}

export default CompanyRoleModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'company-role': CompanyRoleModel;
  }
}

import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_VISIT_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN];

export default class AdvanceSettingAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;

  get canVisit(): boolean {
    const { roleNames: roles } = this.currentAdmin;
    return isPresent(_intersection(CAN_VISIT_ROLES, roles));
  }

  get canVisitGlobal(): boolean {
    const { roleNames: roles } = this.currentAdmin;
    return isPresent(_intersection([GLOBAL_ADMIN], roles));
  }
}

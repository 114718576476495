import $ from 'jquery';
import SortableItem from 'ember-sortable/components/sortable-item';

export default SortableItem.extend({
  // fix-me, input should fire the draggable
  _startDrag(event) {
    if (
      $(event.target).siblings('input').length ||
      event.target.tagName === 'INPUT' ||
      event.target.tagName === 'TEXTAREA' ||
      event.target.tagName === 'TEXTAREA' ||
      event.target.getAttribute('data-ember-action')
    ) {
      return event;
    } else {
      this._super(event);
    }
  },

  // fix ember-sortable leaving sortable-items with inline style transition: none which
  // made the next drag and drop not have any transitions since the inline style had
  // higher specificity
  thaw() {
    this._super();
    const el = this.element;
    if (!el) {
      return;
    }
    el.style.transition = '';
  },
});

import Component from '@glimmer/component';
import { get } from '@ember/object';
import { service } from '@ember/service';

/**
 * @param {Flow}                    flow
 * @param {Boolean}                 fieldsDisabled
 * @param {attr<Locality>}          locality
 * @param {Array<Changesets>}       userDataChangeset
 * @param {String}                  fieldIdPrefix
 * @param {Function}                doSearch
 * @param {Function}                setHostAndUserData
 * @param {Function}                updateUserDataField
 * @param {Function}                updateLocality
 */

export default class EntryFieldsComponent extends Component {
  @service state;

  get hasAcccessToMultipleHostsFeature() {
    return !!this.state.features?.canAccessMultipleHosts;
  }

  get renderableFieldChangesets() {
    return this.args.userDataChangeset.filter((fieldChangeset) => {
      if (get(fieldChangeset, 'isTopLevel') || get(fieldChangeset, 'isTopLevel') === undefined) {
        return true;
      }

      const action = get(fieldChangeset, 'actionableSignInFieldActions.firstObject');

      // Do not attempt to automatically async fetch related signInField
      // If signInField record isn't already fulfilled, it will probably not load
      const isApplicableAction = action && action.belongsTo('signInField').value() && get(action, 'signInField.id');

      if (!isApplicableAction) {
        return false;
      }

      const changesetParent = this.args.userDataChangeset.findBy('id', get(action, 'signInField.id'));

      return changesetParent && get(changesetParent, 'value') === get(action, 'dropdownOption.value');
    });
  }
}

import Component from '@glimmer/component';

/**
 * Display list of items with ellipsis and tooltips if the list is long
 *
 * @param {Array} itemList list of items to render
 */
export default class EllipsisWithTooltip extends Component {
  showUpto = 3;
  showUptoInTooltip = 10;

  get itemsToShow() {
    return this.args.itemList.slice(0, this.showUpto).join(', ');
  }

  get itemsToShowInTooltip() {
    return this._itemsInTooltip.slice(0, this.showUptoInTooltip);
  }

  get numItemsHiddenInTooltip() {
    return Math.max(this._itemsInTooltip.length - this.showUptoInTooltip, 0);
  }

  get ellipsisText() {
    return `${this.args.itemList.length - this.showUpto} more...`;
  }

  get _itemsInTooltip() {
    return this.args.itemList.slice(this.showUpto);
  }
}

import _cloneDeep from 'lodash/cloneDeep';
import zft from 'garaje/utils/zero-for-tests';
import { get } from '@ember/object';
import { isBlank } from '@ember/utils';
import { task, timeout } from 'ember-concurrency';
import { getOwner } from '@ember/application';

// When need to clear the extra filters because when using this task direclty with
// {{power-select search=(perform genericSearcherTask)}} it passes a second argument with
// information no needed
function cleanExtraFilters(extraFilters) {
  const allowedFilters = ['except', 'locations']; // Add any other filter when needed
  const cleaned = {};
  allowedFilters.forEach((filterName) => {
    if (get(extraFilters, filterName)) {
      cleaned[filterName] = get(extraFilters, filterName);
    }
  });
  return cleaned; // cleaned filters with only allowed keys
}

/**
 * Create and return a fresh Ember Concurrency Task that will search for a resource
 *
 *    Example:
 *    ```js
 *      import genericSearcherTask from 'garaje/utils/generic-searcher';
 *      //..
 *      searchGenericTask: genericSearcherTask(resource, {
 *        filter: {
 *          deleted: false,
 *         excludeHidden: false
 *        },
 *        prefix: true
 *      }).restartable(),
 *      //..
 *    ```
 * @function
 * @param {object} [{filter: {...}}] - An object with the query options like `filter` and `prefix`
 * @returns {Task} - An Ember Concurrency task
 */
function genericSearcherTask(
  resource,
  query = {
    filter: {},
  },
) {
  return task({
    *perform(term, extraFilters = {}, opts = {}) {
      if (isBlank(term)) {
        return [];
      }

      yield timeout(zft(250));

      const clonedQuery = _cloneDeep(query);

      // Clean manually passed filters
      extraFilters = cleanExtraFilters(extraFilters);

      // Add query term and extra filters
      clonedQuery.filter = {
        ...clonedQuery.filter,
        ...extraFilters,
        query: term,
      };
      const applicationInstance = getOwner(this.context);
      const state = applicationInstance.lookup('service:state');
      const store = applicationInstance.lookup('service:store');

      if (!opts.withoutLocation) {
        if (isBlank(get(clonedQuery, 'filter.location-id'))) {
          clonedQuery.filter['location-id'] = state.currentLocation.id;
        }
      }
      const results = yield store.query(resource, clonedQuery);

      return results.uniq();
    },
  });
}

export default genericSearcherTask;

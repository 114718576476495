import Component from '@glimmer/component';
import { format } from 'date-fns';
import { APPROVAL_STATUS } from 'garaje/utils/enums';

export default class PassScreenComponent extends Component {
  get isApproved() {
    return this.args.invite.approvalStatus?.status == APPROVAL_STATUS.APPROVED;
  }

  get formattedDate() {
    return format(this.args.expectedArrivalTime, 'eeee, MMMM do yyyy');
  }

  get covid19VaccineDocumentStatus() {
    switch (this.args.covid19VaccineDocumentLink?.approvalStatus) {
      case 'approved':
        return 'Approved';
      case 'denied':
        return 'Requires re-upload';
      case 'review':
        return 'Pending review';
      default:
        return 'Not submitted';
    }
  }

  get covid19VaccineDocumentStatusIcon() {
    switch (this.args.covid19VaccineDocumentLink?.approvalStatus) {
      case 'approved':
        return 'checkmark-circle-green';
      case 'denied':
        return 'cross-incircle-gray';
      case 'review':
        return 'clock-2';
      default:
        return 'exclamation-point';
    }
  }
}

import { service } from '@ember/service';
import Component from '@glimmer/component';
import type FeatureFlagService from 'garaje/services/feature-flags';

interface ObjectWithName {
  name: string;
  [key: string]: unknown;
}

interface AccordionSectionArgs {
  data: Array<ObjectWithName> | object;
  lookupKey: string;
  selectedNodeId: string;
  onLastNodeClick: () => void;
  path: string;
  expandedMap: string[];
}

export default class AccordionSection extends Component<AccordionSectionArgs> {
  @service declare featureFlags: FeatureFlagService;

  get dataType(): string {
    const { data } = this.args;
    if (data instanceof Array) {
      return 'array';
    }

    if (data instanceof Object) {
      return 'object';
    }
    if (typeof data === 'number') {
      return 'number';
    }
    return 'string';
  }

  get isNodeSelected(): boolean {
    const { selectedNodeId, lookupKey, data } = this.args;
    if (selectedNodeId && lookupKey in data) {
      // @ts-ignore
      return selectedNodeId === data[lookupKey];
    }
    return false;
  }

  get sortedArrayData(): Array<unknown> {
    if (this.args.data instanceof Array) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return this.args.data.sort((a, b) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' });
      });
    }
    return [];
  }
}

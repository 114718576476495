import gql from 'graphql-tag';

import DeskMapFeatureFragment from '../fragments/DeskMapFeatureFragment';
import RoomMapFeatureFragment from '../fragments/RoomMapFeatureFragment';

export default gql`
  query MapFeatures($areaMapID: ID!, $floorID: ID!) {
    areaMap(id: $areaMapID) {
      id
      floors(ids: [$floorID]) {
        id
        features(includeDisabled: true) {
          id
          name
          notes
          enabled
          type

          ... on DeskMapFeature {
            ...DeskMapFeatureFragment
          }

          ... on RoomMapFeature {
            ...RoomMapFeatureFragment
          }
        }
      }
    }
  }
  ${DeskMapFeatureFragment}
  ${RoomMapFeatureFragment}
`;

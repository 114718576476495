import { tracked } from '@glimmer/tracking';
import type SimpleTime from 'garaje/utils/simple-time';

/**
 * This class represents a range of times (start + end times). It provides methods
 * for checking whether ranges overlap, or where the range falls relative to a time.
 */
export default class TimeRange {
  @tracked declare from: SimpleTime | null;
  @tracked declare to: SimpleTime | null;

  constructor(from: SimpleTime | null, to: SimpleTime | null) {
    this.from = from;
    this.to = to;
  }

  /**
   * Make a deep copy of this time range.
   */
  clone(): TimeRange {
    return new TimeRange(this.from?.clone() ?? null, this.to?.clone() ?? null);
  }

  /**
   * Return the length of the range, in minutes.
   */
  get duration(): number {
    this._assertValid();
    return this.to!.asMinutes() - this.from!.asMinutes();
  }

  /**
   * Return a boolean indicating whether the range is valid (true) or not (false).
   * A valid range is one which has both start and end times, and where the start time
   * is earlier than (or the same as) the end time.
   */
  get isValid(): boolean {
    return !!this.from && !!this.to && (this.from.isSame(this.to) || this.from.isBefore(this.to));
  }

  /**
   * Check whether this range overlaps any part of the given range.
   */
  overlaps(range: TimeRange): boolean {
    this._assertValid();
    range._assertValid();

    // doesn't overlap if this range is entirely before the given range
    if (this.startsBefore(range.from!) && this.endsAtOrBefore(range.from!)) return false;

    // doesn't overlap if this range starts at or after the end of the given range
    if (this.startsAtOrAfter(range.to!)) return false;

    return true;
  }

  /**
   * Check whether this range starts later than the given time.
   */
  startsAfter(time: SimpleTime): boolean {
    this._assertValid();
    return this.from!.isAfter(time);
  }

  /**
   * Check whether this range starts at or later than the given time.
   */
  startsAtOrAfter(time: SimpleTime): boolean {
    this._assertValid();
    return this.from!.isSame(time) || this.from!.isAfter(time);
  }

  /**
   * Check whether this range starts at or earlier than the given time.
   */
  startsAtOrBefore(time: SimpleTime): boolean {
    this._assertValid();
    return this.from!.isSame(time) || this.from!.isBefore(time);
  }

  /**
   * Check whether this range starts earlier than the given time.
   */
  startsBefore(time: SimpleTime): boolean {
    this._assertValid();
    return this.from!.isBefore(time);
  }

  /**
   * Check whether this range ends later than the given time.
   */
  endsAfter(time: SimpleTime): boolean {
    this._assertValid();
    return this.to!.isAfter(time);
  }

  /**
   * Check whether this range ends at or later than the given time.
   */
  endsAtOrAfter(time: SimpleTime): boolean {
    this._assertValid();
    return this.to!.isSame(time) || this.to!.isAfter(time);
  }

  /**
   * Check whether this range ends at or earlier than the given time.
   */
  endsAtOrBefore(time: SimpleTime): boolean {
    this._assertValid();
    return this.to!.isSame(time) || this.to!.isBefore(time);
  }

  /**
   * Check whether this range ends earlier than the given time.
   */
  endsBefore(time: SimpleTime): boolean {
    this._assertValid();
    return this.to!.isBefore(time);
  }

  _assertValid(): void {
    if (!this.from || !this.to) {
      throw new Error('Cannot perform operations on an TimeRange that does not have both `from` and `to`!');
    }
  }
}

import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';
import { routeEvent } from 'garaje/utils/decorators/route';

const CONFIRMATION_MESSAGE = 'Are you sure you want to leave with unsaved changes?';

export default class EmployeesAdminRolesEditRoute extends Route {
  @service abilities;
  @service router;
  @service store;

  beforeModel() {
    if (this.abilities.cannot('manage admin-roles')) {
      this.router.transitionTo('unauthorized');
    }
  }

  model({ user_id }) {
    const user = this.store.findRecord('user', user_id, { reload: true });
    const roleAssignments = this.store.query('role-assignment', { filter: { 'user-id': user_id } });
    return RSVP.hash({ user, roleAssignments });
  }

  setupController(controller, { user }) {
    super.setupController(...arguments);
    controller.fetchRolesTask.perform(user);
  }

  resetController(controller) {
    controller.reset();
  }

  @routeEvent
  routeWillChange(transition) {
    const hasUnsavedChanges = get(this.controller, 'hasUnsavedChanges');

    if (hasUnsavedChanges && !confirm(CONFIRMATION_MESSAGE)) {
      transition.abort();
    } else {
      return true;
    }
  }
}

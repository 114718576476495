import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { Permission } from 'garaje/utils/ui-permissions';

export default class GlobalFlowAbility extends Ability {
  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;

  get canVisit(): boolean {
    if (!this.state.visitorsSubscription?.canAccessGlobalSignInFlows) return false;

    if (this.featureFlags.isEnabled('custom-roles-garaje-authz-vis3-flows')) {
      return this.authz.hasPermissionAtCurrentCompany(Permission.VISITORS_FLOW_INSPECT);
    } else {
      return this.currentAdmin.isGlobalAdmin;
    }
  }

  get canEdit(): boolean {
    if (!this.state.visitorsSubscription?.canAccessGlobalSignInFlows) return false;

    if (this.featureFlags.isEnabled('custom-roles-garaje-authz-vis3-flows')) {
      return this.authz.hasPermissionAtCurrentCompany(Permission.VISITORS_GLOBAL_FLOW_UPDATE);
    } else {
      return this.currentAdmin.isGlobalAdmin;
    }
  }
}

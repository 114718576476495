import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';

import type { PropertyDevicesRouteModel } from '../../route';

export default class PropertyDevicePrintersIndexRoute extends Route {
  @service declare router: RouterService;

  beforeModel(): void {
    const printers = (<PropertyDevicesRouteModel>this.modelFor('property.devices')).printers;
    if (printers.length === 0) {
      void this.router.replaceWith('property.devices.printers.new');
    }
  }

  model(): PropertyDevicesRouteModel {
    return <PropertyDevicesRouteModel>this.modelFor('property.devices');
  }
}

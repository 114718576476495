import type Model from '@ember-data/model';
import type Store from '@ember-data/store';
import type TenantModel from 'garaje/models/tenant';
import type TenantConnectionRequestModel from 'garaje/models/tenant-connection-request';
import ApplicationSerializer from 'garaje/serializers/application';
import type { SinglePayload, IncludedData, SingleResponse } from 'jsonapi/response';

export default class TenantSerializer extends ApplicationSerializer {
  attrs = {
    logo: {
      serialize: false,
    },
    propertyName: {
      serialize: false,
    },
    propertyCompanyName: {
      serialize: false,
    },
    status: {
      serialize: false,
    },
    token: {
      serialize: false,
    },
    tenantConnectionRequest: {
      serialize: false,
    },
    tenantPermissionsConfiguration: {
      serialize: false,
    },
    locationId: {
      serialize: false,
    },
  };

  normalizeSingleResponse(
    store: Store,
    primaryModelClass: Model,
    response: SingleResponse<TenantModel>,
    id: string | number,
    requestType: string,
  ): ReturnType<ApplicationSerializer['normalizeSingleResponse']> {
    const includedToken: IncludedData<TenantConnectionRequestModel> | undefined = response.included?.find(
      (included) => included.type === 'tenant-connection-requests',
    );
    if (includedToken) {
      response.data.attributes.token = includedToken.attributes.token;
    }

    return super.normalizeSingleResponse(store, primaryModelClass, response, id, requestType);
  }

  serializeIntoHash(...args: Parameters<ApplicationSerializer['serializeIntoHash']>): void {
    const [hash, , snapshot, options] = args;
    const payload = <SinglePayload<TenantModel>>this.serialize(snapshot, options!);

    // only remove property serialization when updating a tenant
    if (payload.data.id) {
      delete payload.data.relationships['property'];
    }

    Object.assign(hash, payload);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    tenant: TenantSerializer;
  }
}

import type moment from 'moment-timezone';

// via https://github.com/moment/moment/pull/1794#issuecomment-205199332
function roundDate(date: moment.Moment, type: 'minutes', offset: number): moment.Moment {
  const val = date[type]();
  let roundedVal;
  if (val % offset === 0) {
    roundedVal = val;
  } else {
    roundedVal = Math.ceil((val + 1) / offset) * offset;
  }
  return date[type](roundedVal);
}

export { roundDate };
export default roundDate;

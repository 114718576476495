import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type AcsLocationModel from 'garaje/models/acs-location';
import type StateService from 'garaje/services/state';

import type AppsSettingsController from './controller';

export interface AppsSettingsRouteModel {
  notificationSetting: AcsLocationModel | null;
}

export default class AppsSettingsRoute extends Route {
  @service declare state: StateService;
  @service declare store: Store;
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  beforeModel(): void {
    if (this.abilities.cannot('visit workplace')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  model(): AppsSettingsRouteModel {
    return {
      notificationSetting: null,
    };
  }

  setupController(controller: AppsSettingsController, model: AppsSettingsRouteModel, transition: Transition): void {
    super.setupController(controller, model, transition);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    void controller.loadNotificationSettings.perform();
  }
}

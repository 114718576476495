import Model, { attr, belongsTo } from '@ember-data/model';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';

class LocationsCapacityModel extends Model {
  @attr('date') capacityDate;
  @attr('number') capacityLimit;
  @attr('number') onSiteCount;
  @attr('number') expectedCount;
  @attr('number') inCount;
  @attr('number') outCount;
  @belongsTo('location') location;
  @belongsTo('company') company;
  @computed('onSiteCount', 'expectedCount')
  get capacityUsed() {
    return this.onSiteCount + this.expectedCount;
  }
  @computed('capacityUsed', 'capacityLimit')
  get capacityRatio() {
    return this.capacityLimit >= 0 ? this.capacityUsed / this.capacityLimit : 0;
  }
  @computed('capacityRatio')
  get hasCapacity() {
    return this.capacityRatio < 1;
  }
}

export default LocationsCapacityModel;

import { debounce } from '@ember/runloop';

import config from 'garaje/config/environment';

function sendHide(type, flash) {
  if (type !== 'error') {
    if (this.hideFlash) {
      this.hideFlash();
    } else if (this.flashMessages && flash) {
      this.flashMessages.hideFlash(flash);
    } else {
      this.send('hideFlash');
    }
  }
}

export default function flash(type, message, duration = 3000) {
  let flash;

  if (!this) {
    return;
  }

  // Check if context implements showFlash or has flashMessages is
  // inject into the context where this is being called so we don't
  // have to pass down the showFlash/hideFlash actions.
  if (this.showFlash) {
    this.showFlash(type, message);
  } else if (this.flashMessages) {
    flash = this.flashMessages.showFlash(type, message);
  } else {
    this.send('showFlash', type, message);
  }

  config.environment === 'test' ? sendHide.call(this, type, flash) : debounce(this, sendHide, type, flash, duration);
}

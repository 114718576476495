import Component from '@glimmer/component';

export default class DeskTextDisplay extends Component {
  get floorDisplay() {
    const floor = this.args.floor;
    if (!floor) return null;

    const regex = new RegExp('Floor', 'i');
    if (regex.test(floor)) {
      return `, ${floor}`;
    } else {
      return `, Floor ${floor}`;
    }
  }

  get neighborhoodDisplay() {
    const neighborhood = this.args.neighborhood;
    if (!neighborhood) return null;

    return `(${neighborhood})`;
  }
}

import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import VisitorsDevicesIpadsIndexRoute from 'garaje/pods/visitors/devices/ipads/index/route';
import { hash } from 'rsvp';

import type { VfdDevicesIpadsRouteModel } from '../route';

export default class VirtualFrontDeskDevicesIpadsIndexRoute extends VisitorsDevicesIpadsIndexRoute {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  controllerName = 'visitors/devices/ipads/index';
  templateName = 'visitors/devices/ipads/index';

  model(): Promise<VfdDevicesIpadsRouteModel> {
    const { location, printers, ipads, subscription } = <VfdDevicesIpadsRouteModel>(
      this.modelFor('virtual-front-desk.devices.ipads')
    );
    return hash({
      ipads,
      location,
      printers,
      subscription,
    });
  }

  afterModel({ ipads }: VfdDevicesIpadsRouteModel): void {
    if (ipads.length === 0 && this.abilities.can('create devices')) {
      void this.router.transitionTo('virtual-front-desk.devices.ipads.new');
    }
  }
}

import Controller from '@ember/controller';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { get, action } from '@ember/object';
import { compareAsc, getDay } from 'date-fns';
import { getReadableScheduleEligibilityOffset } from 'garaje/utils/getReadableScheduleEligibilityOffset';

export default class ScheduleIndexController extends Controller {
  @service state;
  @service registrations;
  @service localStorage;
  @service featureConfig;
  @service abilities;
  @service performanceLoadTime;

  queryParams = ['selectedInviteId'];

  @tracked selectedInviteId = null;

  get days() {
    return this.registrations.days.sort((a, b) => compareAsc(a.date, b.date));
  }

  get isPartialDayEnabled() {
    return this.abilities.can('use partial day booking desk');
  }

  get showRequiredDocumentExpiredBanner() {
    const noRequiredDocumentApproval = this.days.find((day) => !day.hasRequiredDocumentApproval);
    return noRequiredDocumentApproval;
  }

  get hasDesks() {
    return this.state.currentLocationEnabledDesks?.desksQuantity > 0;
  }

  get desksEnabled() {
    const { currentLocationEnabledDesks } = this.state;
    return (
      !!this.state.features?.canAccessDesksApplication &&
      currentLocationEnabledDesks &&
      currentLocationEnabledDesks.active
    );
  }

  get bookDeskEnabled() {
    return (
      this.desksEnabled &&
      this.screeningFlowEnabled &&
      this.hasDesks &&
      this.featureConfig.isEnabled('deskReservations.booking')
    );
  }

  get screeningFlowEnabled() {
    return this.state.currentLocation.employeeScreeningEnabled;
  }

  get selectedDay() {
    // must check selectedInviteId because some screeningCards have an id of null in production
    if (!this.selectedInviteId) {
      return null;
    }

    // The day doesn't have an ID, so have to select on a related object's id
    return this.days.find((day) => day.screeningCard.id == this.selectedInviteId);
  }

  get indexedDays() {
    return this.days.map((day, index) => {
      return { ...day, index };
    });
  }

  get weeks() {
    const days = get(this, 'indexedDays');
    if (days.length == 0) return [];
    let i = 0;
    const daysLeftAtStart = 7 - getDay(days[0].date);
    const weeks = [days.slice(0, daysLeftAtStart)];
    i += daysLeftAtStart;
    while (i < days.length) {
      weeks.push(days.slice(i, i + 7));
      i += 7;
    }
    return weeks;
  }

  get shouldLoadDays() {
    const { scheduleEligibilityOffset } = this.state.currentLocation;
    if (scheduleEligibilityOffset == null) {
      return true;
    }
    return this.daysToLoad > 0;
  }

  get daysToLoad() {
    const { scheduleEligibilityOffset } = this.state.currentLocation;

    if (scheduleEligibilityOffset == null) {
      return 30;
    }

    const offsetInDays = -scheduleEligibilityOffset / 1440 - this.days.length;
    return Math.min(offsetInDays, 30);
  }

  get readableScheduleEligibilityOffset() {
    return getReadableScheduleEligibilityOffset(this.state.currentLocation.scheduleEligibilityOffset);
  }

  get isScheduleEligibilityOffsetNotificationVisible() {
    const { scheduleEligibilityOffset } = this.state.currentLocation;
    const offset = this.storedScheduleEligibilityOffset;

    return offset !== scheduleEligibilityOffset && scheduleEligibilityOffset !== null;
  }

  get storedScheduleEligibilityOffset() {
    const { id } = this.state.currentLocation;

    try {
      let offsets = this.localStorage.getItem('schedule_eligibility_offsets');
      offsets = offsets ? JSON.parse(offsets) : [];
      const { offset } = offsets.find((l) => l.locationId === id) || {};

      return offset;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    return undefined;
  }

  get showDbeamEaBanner() {
    return this.abilities.can('visit dbeam');
  }

  removeStoredScheduleEligibilityOffset() {
    const { id } = this.state.currentLocation;

    try {
      let offsets = this.localStorage.getItem('schedule_eligibility_offsets');
      offsets = offsets ? JSON.parse(offsets) : [];
      const index = offsets.findIndex((l) => l.locationId === id);
      if (index > -1) {
        offsets.splice(index, 1);
      }

      this.localStorage.setItem('schedule_eligibility_offsets', JSON.stringify(offsets));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  @action
  closeScheduleEligibilityOffsetNotification() {
    const { id, scheduleEligibilityOffset } = this.state.currentLocation;
    const locationItem = { locationId: id, offset: scheduleEligibilityOffset };

    try {
      let offsets = this.localStorage.getItem('schedule_eligibility_offsets');
      offsets = offsets ? JSON.parse(offsets) : [];
      const index = offsets.findIndex((l) => l.locationId === id);
      if (index > -1) {
        offsets.splice(index, 1, locationItem);
      } else {
        offsets.pushObject({ locationId: id, offset: scheduleEligibilityOffset });
      }

      this.localStorage.setItem('schedule_eligibility_offsets', JSON.stringify(offsets));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  @action
  logLoadTime() {
    this.performanceLoadTime.logPageUsableMetric();
  }
}
